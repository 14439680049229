/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import ScrollLock from 'react-scrolllock';
import { config, useSpring } from 'react-spring';
import { Subscription } from 'rxjs';
import { isAlpha } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useExplorerState } from 'src/hooks/useExplorerState';
import { useKaikas } from 'src/hooks/useKaikas';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMakePressable } from 'src/hooks/useMakePressable';
import { useMarketPlaceState } from 'src/hooks/useMarketPlaceState';
import { useRpc } from 'src/hooks/useRpc';
import { ClientType_e } from 'src/hooks/useScreenWidthHelper';
import { useStompJS } from 'src/hooks/useStompJS';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { CbtUserCertTp_i, CbtUserLanguage_e } from 'src/model/rpcModel';
import { RPC_USER_ACCOUNT_MESSAGE_LIST } from 'src/model/rpcType';
import { stringsEn } from 'src/redux/modules/localSettingsModule';
import { KaikasPromiseReject_e } from 'src/res/constants';
import R from 'src/res/R';
import { CbtStompMsgCode_e, CbtStompMsgType_e, messageService } from 'src/rx/messageService';
import { DevInfo } from 'src/ui/base_component/DevInfo';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import TxtBtnWithMouseOverAni from 'src/ui/base_component/TxtBtnWithMouseOverAni';
import { KaikasBtn } from 'src/ui/common_component/button/KaikasBtn';
import { LoginBtn } from 'src/ui/common_component/button/LoginBtn';
import { LogoutBtn } from 'src/ui/common_component/button/LogoutBtn';
import { MenuBtn } from 'src/ui/common_component/button/MenuBtn';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { KaikasSelectedAddress } from 'src/ui/common_component/KaikasSelectedAddress';
import { UserThumb } from 'src/ui/common_component/UserThumb';
import { CLAY, CommonColumnBox, COMMONHEADER_LAYOUT, SettingPopupBox as PopupBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/
export enum Menu {
  Explorer = 'Explorer',
  AssetCreator = 'AssetCreator',
  Marketplace = 'Marketplace',
}

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function CommonHeader(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMenu, setMenu] = useState<Menu | undefined>(undefined);
  const [sIsLangPopupOpen, setIsLangPopupOpen] = useState(false);
  const [sIsMyPopupOpen, setIsMyPopupOpen] = useState(false);
  const [sIsMobileMainMenuOpen, setIsMobileMainMenuOpen] = useState(false);

  const [sForceRender, setForceRender] = useState(false);
  const [sScrollLock, setScrollLock] = useState(false);

  const [sNewNotiBadge, setNewNotiBadge] = useState(false);
  const [sShowNotiPopup, setShowNotiPopup] = useState(false);

  const [sNotiList, setNotiList] = useState<RPC_USER_ACCOUNT_MESSAGE_LIST.Rx>();
  const [sNotiListLoading, setNotiListLoading] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const notiBtnRef = useRef(null);
  const notiPopupRef = useRef(null);
  const langPopupRef = useRef(null);
  const langBtnRef = useRef(null);
  const myPopupRef = useRef(null);
  const myBtnRef = useRef(null);
  const mobileMainMenuPopupRef = useRef(null);
  const mobileMainMenuBtnRef = useRef(null);

  const devInfoRef = useRef(null);
  const devInfoPivotRef = useRef(null);

  useMakePressable([
    {
      elementKey: 'my',
      elementRefList: [myBtnRef, myPopupRef],
      onInsidePress: ({ e, hitRef }) => {
        if (myBtnRef.current === hitRef.current) {
          if (sIsMyPopupOpen) {
            closeMyPopup();
          } else {
            openMyPopup();
          }
        }
      },
      onOutsidePress: (e) => {
        closeMyPopup();
        // DBGMSG(`$$$$MAKE_CLICKABLE stopPropagation`);
        // e.stopImmediatePropagation();
        // e.stopPropagation();
        // e.preventDefault();
      },
    },
    {
      elementKey: 'lang',
      elementRefList: [langPopupRef, langBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        if (langBtnRef.current === hitRef.current) {
          if (sIsLangPopupOpen) {
            closeLangPopup();
          } else {
            openLangPopup();
          }
        }
      },
      onOutsidePress: (e) => {
        closeLangPopup();
        // DBGMSG(`$$$$MAKE_CLICKABLE stopPropagation`);
        // e.stopImmediatePropagation();
        // e.stopPropagation();
        // e.preventDefault();
      },
    },
    {
      elementKey: 'mobileMainMenu',
      elementRefList: [mobileMainMenuPopupRef, mobileMainMenuBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        if (mobileMainMenuBtnRef.current === hitRef.current) {
          if (sIsMobileMainMenuOpen) {
            closeMobileMainMenuPopup();
          } else {
            openMobileMainMenuPopup();
          }
        }
      },
      onOutsidePress: (e) => {
        closeMobileMainMenuPopup();
        // DBGMSG(`$$$$MAKE_CLICKABLE stopPropagation`);
        // e.stopImmediatePropagation();
        // e.stopPropagation();
      },
    },
    {
      elementKey: 'noti',
      elementRefList: [notiBtnRef, notiPopupRef],
      onInsidePress: ({ e, hitRef }) => {
        // if (notiBtnRef.current === hitRef.current) {
        if (sShowNotiPopup === false) {
          setNotiList(undefined);
          fetchNotiList();
        }
        setShowNotiPopup(!sShowNotiPopup);

        // }
      },
      onOutsidePress: (e) => {
        if (sShowNotiPopup) {
          setNewNotiBadge(false);
        }
        setShowNotiPopup(false);
      },
    },
  ]);

  /**************************************
   * !! hooks
   **************************************/
  const location = useLocation();
  const hHistory = useHistory();
  // const { contentsWidth, clientType, scale } = useScreenWidthHelper();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hKaikas = useKaikas();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hKaikasConnectWrapper = useKaikasSvc();
  const hMyPageState = useUserDetailMineState();
  const hExplorerState = useExplorerState();
  const hMarketPlaceState = useMarketPlaceState();
  const hStompJS = useStompJS();

  /**************************************
   * !! animation
   **************************************/
  // popup 에니메이션, lang / my 에 공통적용됨
  const [sLangPopupAnimProps, setLangPopupAnimProps] = useSpring(() => ({
    to: { opacity: 0 },
    // config: { ...config.gentle },
  }));

  const [sMyPopupAnimProps, setMyPopupAnimProps] = useSpring(() => ({
    to: { opacity: 0 },
    // config: { ...config.gentle },
  }));

  // mobile menu 에니메이션, lang / my 에 공통적용됨
  const [sMobileMainMenuAnimProps, setMobileMainMenuAnimProps] = useSpring(() => ({
    to: { opacity: 0 },
    // config: { ...config.gentle },
  }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    let subscription: Subscription | undefined = undefined;
    if (hAuth.isLogin) {
      subscription = messageService.onMessage().subscribe((message) => {
        if (message.kind === 'ReceiveStompMsg') {
          DBGMSG(`set NewNotiBadge`);
          setNewNotiBadge(true);
        }
      });
    }

    return () => {
      subscription?.unsubscribe();
    };
  }, [hAuth.authActs]);

  useEffect(() => {
    switch (location.pathname) {
      case Nav.ExplorerScreeen:
      case Nav.HomeScreen:
        setMenu(Menu.Explorer);
        break;

      case Nav.AssetCreatorScreen:
      case Nav.MyCollectionCScreen:
      case Nav.MyCollectionRScreen:
      case Nav.MyCollectionUScreen:
      case Nav.MySchemaCScreen:
      case Nav.MySchemaRScreen:
      case Nav.MySchemaUScreen:
      case Nav.MyAssetGroupCScreen:
        setMenu(Menu.AssetCreator);
        break;

      case Nav.MarketPlaceScreen:
      case Nav.MarketAssetRScreen:
      case Nav.MarketSchemaRScreen:
      case Nav.UserDetailScreen:
        setMenu(Menu.Marketplace);
        break;
      case Nav.UserDetailMineScreen:
        setMenu(undefined);
        break;
    }

    return () => {};
  }, [location.pathname]);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchNotiList = async () => {
    try {
      setNotiListLoading(true);
      const res = await hRpc.cobaltRpc.rpcUserAccountMessageList({});
      setNotiList(res);
    } catch (err) {
    } finally {
      setNotiListLoading(false);
    }
  };
  const openLangPopup = () => {
    // DBGMSG(`$$$$MAKE_CLICKABLE openLangPopup`);
    if (sIsLangPopupOpen) return;

    DBGMSG('setPopupAnimProps');
    setLangPopupAnimProps({
      to: { opacity: 1 },
      onStart: () => {
        DBGMSG('onStart');
        setIsLangPopupOpen(true);
      },
    });
  };

  const closeLangPopup = () => {
    if (!sIsLangPopupOpen) return;

    setLangPopupAnimProps({
      to: { opacity: 0 },
      onRest: () => {
        DBGMSG('onRest');
        setIsLangPopupOpen(false);
      },
    });
  };

  const openMyPopup = () => {
    if (sIsMyPopupOpen) return;

    DBGMSG('setPopupAnimProps');
    setMyPopupAnimProps({
      to: { opacity: 1 },
      onStart: () => {
        DBGMSG('onStart');
        setIsMyPopupOpen(true);
      },
    });
  };

  const closeMyPopup = () => {
    if (!sIsMyPopupOpen) return;

    setMyPopupAnimProps({
      to: { opacity: 0 },
      onRest: () => {
        DBGMSG('onRest');
        setIsMyPopupOpen(false);
      },
    });
  };

  const openMobileMainMenuPopup = () => {
    if (sIsMobileMainMenuOpen) return;

    setMobileMainMenuAnimProps({
      to: { opacity: 1 },
      config: { ...config.gentle, duration: 50 },
      onStart: () => {
        setIsMobileMainMenuOpen(true);
        setScrollLock(true);
      },
    });
  };

  const closeMobileMainMenuPopup = () => {
    if (!sIsMobileMainMenuOpen) return;

    setMobileMainMenuAnimProps({
      to: { opacity: 0 },
      config: { ...config.gentle, duration: 50 },
      onRest: () => {
        setIsMobileMainMenuOpen(false);
        setScrollLock(false);
      },
    });
  };

  const changeLang = (lang: CbtUserLanguage_e) => {
    if (hR.lang === lang) {
      return;
    }

    hR.changeLang(lang);

    // 로그인 된상태로 변경시 서버에 요청
    if (hAuth.isLogin) hRpc.cobaltRpc.rpcUserAccountLangSet({ lang });
  };

  const connectKaikas = () => {
    try {
      hKaikasConnectWrapper.kaikasConnectAndRewardAddrReg();
    } catch (e) {
      DBGMSGW(e);
      if (typeof e === 'number') {
        switch (e) {
          case KaikasPromiseReject_e.KaikasNotFound:
            hKaikas.openInstallSite();
            break;
        }
      }
    }
  };

  /**************************************
   * !! render function
   **************************************/
  const renderKaikasWallet = () => {
    if (!hKaikas.kaikasState.user?.selectedAddress) return null;

    return (
      <KaikasSelectedAddress
        address={hKaikas.kaikasState.user.selectedAddress}
        onClick={() => {
          // klaytn scope로 이동
          if (hKaikas.getNetworkVersion() === 'MAINNET') {
            window.open(Utils.string.brewKlaytnUserAccountUrl({ accountAddress: hKaikas.kaikasState.user!.selectedAddress, isMainnet: true }));
          } else if (hKaikas.getNetworkVersion() === 'BAOBAB') {
            window.open(Utils.string.brewKlaytnUserAccountUrl({ accountAddress: hKaikas.kaikasState.user!.selectedAddress, isMainnet: false }));
          }
        }}
      />
    );
  };

  const renderLangPopup = () => {
    if (!sIsLangPopupOpen) return null;

    const renderMenu = ({ txt: aTxt, lang: aLang }: { txt: string; lang: CbtUserLanguage_e }) => {
      return (
        <TxtBtnWithMouseOverAni
          size={COMMONHEADER_LAYOUT.langPopup.langFontSize}
          color={hR.lang === aLang ? R.colors.purpleishBlue : R.colors.black}
          text={aTxt}
          isBold
          onMouseDefalutProp={{ backgroundColor: 'white' }}
          onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
          onMouseLeaveProp={{ backgroundColor: 'white' }}
          containerStyle={{
            alignSelf: 'stretch',
            height: COMMONHEADER_LAYOUT.langPopup.langHeight,
            paddingLeft: COMMONHEADER_LAYOUT.langPopup.langLeftPadding,
            justifyContent: 'center',
            // ...devOutlineF
          }}
          onClick={() => {
            changeLang(aLang);
            closeLangPopup();
          }}
        />
      );
    };

    return (
      <PopupBox
        ref={langPopupRef}
        style={{
          width: COMMONHEADER_LAYOUT.langPopup.width,
          paddingTop: COMMONHEADER_LAYOUT.langPopup.vPadding,
          paddingBottom: COMMONHEADER_LAYOUT.langPopup.vPadding,
          position: 'absolute',
          top: COMMONHEADER_LAYOUT.height,
          // right: COMMONHEADER_LAYOUT.marginRight,
          right: ScreenInfo.isMobile() ? 45 : 0,
          zIndex: 100,
          marginTop: -10,
          alignItems: 'center',
          ...sLangPopupAnimProps,
        }}
      >
        <FlexColumnDiv style={{ flex: 1 }}>
          {renderMenu({ txt: R.strings.SETTINGS_ENGLISH, lang: CbtUserLanguage_e.EN })}
          {renderMenu({ txt: R.strings.SETTINGS_JAPANESE, lang: CbtUserLanguage_e.JA })}
          {renderMenu({ txt: R.strings.SETTINGS_KOREAN, lang: CbtUserLanguage_e.KO })}
        </FlexColumnDiv>
      </PopupBox>
    );
  };

  const renderNoti = (args: {
    //
    type: CbtStompMsgType_e;
    code: CbtStompMsgCode_e;
    content: string;
    time: number;
  }) => {
    let isSucc = false;
    switch (args.code) {
      case CbtStompMsgCode_e.ASSET_COLLECTION_CREATE_SUCCESS:
      case CbtStompMsgCode_e.ASSET_COLLECTION_EDIT_SUCCESS:
      case CbtStompMsgCode_e.ASSET_SCHEMA_CREATE_SUCCESS:
      case CbtStompMsgCode_e.ASSET_SCHEMA_EDIT_SUCCESS:
      case CbtStompMsgCode_e.ASSET_CREATE_SUCCESS:
      case CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS:
      case CbtStompMsgCode_e.BILL_CHARGE_POINT_SUCCESS:
        isSucc = true;
        break;
      default:
        isSucc = false;
    }
    return (
      <>
        <FlexRowDiv style={{ alignItems: 'center' }}>
          <Img src={[isSucc ? R.images.common_ic_noti_success3x : R.images.common_ic_noti_fail3x, 40, 40]} />
          <WIDTH size={12} />
          <FlexColumnDiv
            style={{
              width: 416,
              justifyContent: 'space-between',
              // ...devOutlineF
            }}
          >
            <TextCon text={args.content} size={12} color={R.colors.black} isBold />
            <HEIGHT size={2} />
            <TextCon text={`${Utils.date.brewFomatString(args.time)}`} size={10} color={R.colors.blueGrey} />
          </FlexColumnDiv>
        </FlexRowDiv>

        <HEIGHT size={15} />
      </>
    );
  };

  const renderDesktopUserLoginSection = () => {
    //  #########################################################################################
    //  로그인 상태 ################################################################################
    //  #########################################################################################
    if (hAuth.isLogin) {
      return (
        <FlexRowDiv
          style={{
            //
            marginLeft: 'auto',
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            // zIndex: 100,
          }}
        >
          {/* 알림 */}
          <FlexColumnDiv ref={notiBtnRef}>
            <ImgBtn
              src={[R.images.gnb_btn_notification3x, 40, 40]}
              hoverSrc={R.images.gnb_btn_notification_hover3x}
              // containerStyle={Object.assign({}, sNewNotiBadge ? { borderWidth: 1, borderColor: 'red', borderStyle: 'solid' } : undefined)}
              onClick={() => {
                // if (!sShowNotiPopup) {
                //   setShowNotiPopup(true);
                //   setNewNotiBadge(false);
                // }
              }}
            />
            {sNewNotiBadge && (
              <FlexDiv
                style={{ position: 'absolute', right: 4, top: 4, width: 6, height: 6, borderRadius: 3, backgroundColor: R.colors.pastelRed }}
                // onClick={() => {}}
              />
            )}
            <Overlay
              target={langBtnRef.current}
              show={sShowNotiPopup}
              placement="bottom-end"
              // placement="auto"
            >
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    zIndex: 100,
                  }}
                >
                  <CommonColumnBox
                    ref={notiPopupRef}
                    style={{
                      width: 500,
                      height: 380,
                      marginTop: -10,
                      // ...devOutlineFG,
                      overflow: 'scroll',
                    }}
                  >
                    <TextCon
                      text={hR.strings.COMMON_NOTI_TITLE}
                      containerStyle={{ marginLeft: 20, marginTop: 20, marginBottom: 20 }}
                      color={R.colors.black}
                      size={14}
                      isBold
                    />
                    <FlexColumnDiv style={{ paddingLeft: 12 }}>
                      {sNotiList &&
                        sNotiList.message_list.map((noti) => {
                          return renderNoti(noti);
                        })}
                      {sNotiListLoading && <DataFetching width={40} height={40} />}
                    </FlexColumnDiv>
                  </CommonColumnBox>
                </div>
              )}
            </Overlay>
          </FlexColumnDiv>

          <WIDTH size={6} />

          {/* 카이카스 지갑 */}
          {hKaikas.isConnectedKaikas() ? (
            renderKaikasWallet()
          ) : (
            <KaikasBtn
              onClick={() => {
                DBGMSG('KaikasBtn');
                if (hKaikas.isAvailable()) {
                  // if (hKaikasConnectWrapper.checkAvailableKlatnNetwork()) {
                  connectKaikas();
                  // }
                } else {
                  hKaikas.openInstallSite();
                }
              }}
            />
          )}
          <WIDTH size={6} />

          {/* 사용자 썸네일 */}
          <FlexDiv ref={myBtnRef}>
            <UserThumb
              userThumb={null}
              userId={hAuth.authState.authCommonRes!.ucode}
              size={COMMONHEADER_LAYOUT.circleBtnSize}
              fontSize={16}
              onClick={() => {
                DBGMSG('onClick');
                if (sIsMyPopupOpen) {
                  // close는 useOutsideClick에서 처리, open 만 핸들링한다
                  // closePopup();
                } else {
                  openMyPopup();
                }
              }}
            />
          </FlexDiv>
        </FlexRowDiv>
      );
    } else {
      //  #########################################################################################
      //  로그아웃 상태 ################################################################################
      //  #########################################################################################
      // hExplorerState.act.clearExplorerState();
      return (
        <FlexColumnDiv style={{ marginLeft: 'auto', justifyContent: 'center' }}>
          <LoginBtn />
        </FlexColumnDiv>
      );
    }
  };

  /**************************************
   * !! render conf
   **************************************/
  if (ScreenInfo.clientType === ClientType_e.Desktop || ScreenInfo.clientType === ClientType_e.Tablet) {
    return (
      <>
        <FlexRowDiv
          style={{
            width: COMMONHEADER_LAYOUT.width,
            // ...devOutlineF,
          }}
        >
          {/* {isAlpha() && <DevInfo />} */}
          <FlexRowDiv
            style={{
              // backgroundColor: R.colors.devColor1a,
              width: COMMONHEADER_LAYOUT.width,
              height: COMMONHEADER_LAYOUT.height,
              ...devOutline,
              // ...devOutlineF,
            }}
          >
            {/* 로고 이미지 */}
            <ImgBtn
              src={[R.images.gnb_logo_beta3x, COMMONHEADER_LAYOUT.logoSize.width, COMMONHEADER_LAYOUT.logoSize.height]}
              onClick={async () => {
                DBGMSG('logo click');
                hHistory.push(Nav.HomeScreen);

                // firebase analytics 테스트
                // const fa = firebase.analytics();
                // fa.logEvent('logo click');

                // stomp 재연결
                // hStompJS.stompDisconnect();
                // await hStompJS.stompCleanup();
                // hStompJS.stompConnect();
              }}
            />
            <div style={{ width: 20 }} />

            {/* 메뉴 */}
            <MenuBtn
              menu={Menu.Explorer}
              curMenu={sMenu}
              onClick={(e) => {
                setMenu(Menu.Explorer);
                hExplorerState.act.clearExplorerState();
                hHistory.push(Nav.ExplorerScreeen);
              }}
            />
            <MenuBtn
              menu={Menu.AssetCreator}
              curMenu={sMenu}
              onClick={(e) => {
                if (hAuth.authCommonRes && hAuth.authCommonRes.cert_tp === CbtUserCertTp_i.Normal) {
                  setMenu(Menu.AssetCreator);
                  hHistory.push(Nav.AssetCreatorBlockedScreen);
                } else {
                  setMenu(Menu.AssetCreator);
                  hHistory.push(Nav.AssetCreatorScreen);
                }
              }}
            />
            <MenuBtn
              menu={Menu.Marketplace}
              curMenu={sMenu}
              onClick={(e) => {
                setMenu(Menu.Marketplace);
                hMarketPlaceState.act.clearMarketPlaceState();
                hHistory.push(Nav.getMarketPlaceInit());
              }}
            />

            {renderDesktopUserLoginSection()}

            <WIDTH size={6} />

            {/* locale 설정 버튼 */}
            <FlexDiv ref={langBtnRef}>
              <ImgBtn
                src={[R.images.gnb_btn_language3x, COMMONHEADER_LAYOUT.circleBtnSize, COMMONHEADER_LAYOUT.circleBtnSize]}
                hoverSrc={R.images.gnb_btn_language_hover3x}
                onClick={() => {
                  DBGMSG('onClick');
                  // if (sIsLangPopupOpen) {
                  //   // close는 useOutsideClick에서 처리, open 만 핸들링한다
                  //   // closePopup();
                  // } else {
                  //   openLangPopup();
                  // }
                }}
              />
            </FlexDiv>
          </FlexRowDiv>

          {/* locale 설정 팝업 */}
          {renderLangPopup()}

          {/* My 팝업 */}
          {sIsMyPopupOpen && (
            <PopupBox
              ref={myPopupRef}
              style={{
                width: 138,
                // height: 13 + 36 + 36 + 36 + 13,
                paddingTop: 13,
                paddingBottom: 13,
                // visibility: sSettingsPopupOpen ? 'visible' : 'hidden',
                position: 'absolute',
                top: CLAY.COMMON_HEADER_HEIGHT,
                right: 46,
                zIndex: 100,
                marginTop: -10,
                alignItems: 'center',
                ...sMyPopupAnimProps,
              }}
            >
              <FlexRowDiv style={{ width: '100%' }}>
                <FlexColumnDiv style={{}}>
                  {/* <Txt
                  size={12}
                  color={R.colors.blueGrey}
                  text={hLocalSettings.strings.HEADER_SETTINGS_LANG}
                  isBold
                  containerStyle={{ height: 46, paddingLeft: 20, justifyContent: 'center' }}
                /> */}
                  {/* <HEIGHT size={13} /> */}
                  <TxtBtnWithMouseOverAni
                    size={14}
                    color={hR.lang === CbtUserLanguage_e.EN ? R.colors.purpleishBlue : R.colors.black}
                    text={hR.strings.HEADER_MYPAGE}
                    isBold
                    onMouseDefalutProp={{ backgroundColor: 'white' }}
                    onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
                    onMouseLeaveProp={{ backgroundColor: 'white' }}
                    containerStyle={{
                      width: 138,
                      height: 36,
                      paddingLeft: 20,
                      justifyContent: 'center',
                      // ...devOutlineF
                    }}
                    onClick={() => {
                      hMyPageState.act.clearState();
                      hHistory.push(Nav.UserDetailMineScreen);
                      setMenu(undefined);
                      closeMyPopup();
                    }}
                  />
                  <TxtBtnWithMouseOverAni
                    size={14}
                    color={hR.lang === CbtUserLanguage_e.JA ? R.colors.purpleishBlue : R.colors.black}
                    text={hR.strings.HEADER_LOGOUT}
                    isBold
                    onMouseDefalutProp={{ backgroundColor: 'white' }}
                    onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
                    onMouseLeaveProp={{ backgroundColor: 'white' }}
                    containerStyle={{
                      width: 138,
                      height: 36,
                      paddingLeft: 20,
                      justifyContent: 'center',
                      // ...devOutlineF
                    }}
                    onClick={() => {
                      DBGMSG('logout');
                      hAuth.authActs.logout();
                      closeMyPopup();
                      hHistory.replace(Nav.HomeScreen);
                    }}
                  />
                </FlexColumnDiv>
              </FlexRowDiv>
            </PopupBox>
          )}
        </FlexRowDiv>
        <Overlay target={langBtnRef.current} show={isAlpha()} placement={'right-start'}>
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                zIndex: 100,
              }}
            >
              <DevInfo />
            </div>
          )}
        </Overlay>
      </>
    );
  } else {
    return (
      <FlexColumnDiv
        style={{
          transformOrigin: '0% 0%',
          transform: `scale(${ScreenInfo.scale})`,
          // ...devOutlineF,
        }}
      >
        <FlexRowDiv
          style={{
            width: MOBILE_WIDTH,
            height: CLAY.COMMON_HEADER_HEIGHT,
            paddingLeft: MOBILE_HORIZONTAL_PADDING,
            paddingRight: MOBILE_HORIZONTAL_PADDING,
            backgroundColor: R.colors.bgGrey,
            // borderBottomWidth: 1,
            // borderBottomStyle: 'solid',
            // borderBottomColor: R.colors.line,
            // transformOrigin: '0% 0%',
            // transform: `scale(${ScreenInfo.scale})`,
            // ...devOutlineF,
          }}
        >
          {/* 로고 */}
          <ImgBtn
            src={[R.images.gnb_logo_beta3x, COMMONHEADER_LAYOUT.logoSize.width, COMMONHEADER_LAYOUT.logoSize.height]}
            onClick={() => {
              hHistory.push(Nav.HomeScreen);
            }}
          />

          <FlexRowDiv style={{ alignItems: 'center', marginLeft: 'auto' }}>
            {/* <TextCon
              text={'stomp echo'}
              color={R.colors.cloudyBlue}
              size={12}
              onConClick={() => {
                if (hAuth.authInfo) {
                  messageService.sendMessage({
                    kind: 'PublishStompMsg',
                    code: CbtStompMsgCode_e.GENERAL,
                    type: CbtStompMsgType_e.ECHO,
                    from: hAuth.authInfo.mkey,
                    to: hAuth.authInfo.mkey,
                    content: 'test',
                    time: Utils.date.now().getTime(),
                  });
                }
              }}
            /> */}
            {/* 알림 */}
            {hAuth.isLogin && (
              <FlexColumnDiv style={{}}>
                <ImgBtn
                  src={[R.images.gnb_btn_notification3x, 28, 28]}
                  hoverSrc={R.images.gnb_btn_notification_hover3x}
                  // containerStyle={Object.assign({}, undefined)}
                  onClick={() => {
                    setNewNotiBadge(false);
                    hHistory.push(Nav.NotiListScreen);
                  }}
                />
                {sNewNotiBadge && (
                  <FlexDiv
                    style={{ position: 'absolute', right: 2, top: 2, width: 5, height: 5, borderRadius: 2.5, backgroundColor: R.colors.pastelRed }}
                    onClick={() => {}}
                  />
                )}
              </FlexColumnDiv>
            )}

            <WIDTH size={6} />

            {/* locale 설정 버튼 */}
            <FlexDiv ref={langBtnRef} style={{}}>
              <ImgBtn
                src={[R.images.gnb_btn_language3x, COMMONHEADER_LAYOUT.circleBtnSize, COMMONHEADER_LAYOUT.circleBtnSize]}
                hoverSrc={R.images.gnb_btn_language_hover3x}
              />
            </FlexDiv>

            <WIDTH size={6} />

            {/* Menu 버튼 */}
            <FlexDiv ref={mobileMainMenuBtnRef}>
              <ImgBtn
                src={[
                  sIsMobileMainMenuOpen ? R.images.gnb_btn_menu_close3x : R.images.gnb_btn_menu3x,
                  COMMONHEADER_LAYOUT.circleBtnSize,
                  COMMONHEADER_LAYOUT.circleBtnSize,
                ]}
              />
            </FlexDiv>
          </FlexRowDiv>
        </FlexRowDiv>

        {/* 언어 설정 팝업 */}
        {renderLangPopup()}

        {/* Main menu 팝업 */}
        {sIsMobileMainMenuOpen && (
          <AnimatedFlexColumnDiv
            ref={mobileMainMenuPopupRef}
            style={{
              width: MOBILE_WIDTH,
              height: ScreenInfo.windowInnerHeight,
              backgroundColor: R.colors.dialogBg,
              zIndex: 100,
              position: 'absolute',
              top: CLAY.COMMON_HEADER_HEIGHT + 1,
              ...sMobileMainMenuAnimProps,
            }}
          >
            <FlexColumnDiv
              style={{
                alignSelf: 'stretch',
                backgroundColor: R.colors.bgGrey,
              }}
            >
              {/* memu */}
              <FlexColumnDiv style={{ padding: 12 }}>
                <TextCon
                  text={hR.strings.HEADER_MENU_EXPLORE}
                  size={12}
                  color={R.colors.black}
                  isBold
                  onConClick={() => {
                    hExplorerState.act.clearExplorerState();
                    hHistory.push(Nav.ExplorerScreeen);
                    closeMobileMainMenuPopup();
                  }}
                  containerStyle={{ padding: 8 }}
                />
                <TextCon
                  text={hR.strings.HEADER_MENU_ASSET_CREATOR}
                  size={12}
                  color={R.colors.black}
                  isBold
                  onConClick={() => {
                    hHistory.push(Nav.AssetCreatorScreen);
                    closeMobileMainMenuPopup();
                  }}
                  containerStyle={{ padding: 8 }}
                />
                <TextCon
                  text={hR.strings.HEADER_MENU_MARKET_PLACE}
                  size={12}
                  color={R.colors.black}
                  isBold
                  onConClick={() => {
                    hMarketPlaceState.act.clearMarketPlaceState();
                    hHistory.push(Nav.getMarketPlaceInit());
                    closeMobileMainMenuPopup();
                  }}
                  containerStyle={{ padding: 8 }}
                />
                {/* 마이페이지 */}
                {hAuth.isLogin && (
                  <TextCon
                    text={stringsEn.HEADER_MYPAGE}
                    size={12}
                    color={R.colors.black}
                    isBold
                    onConClick={() => {
                      hMyPageState.act.clearState();
                      hHistory.push(Nav.UserDetailMineScreen);
                      closeMobileMainMenuPopup();
                    }}
                    containerStyle={{ padding: 8 }}
                  />
                )}
              </FlexColumnDiv>
              <HEIGHT size={20} />
              <FlexDiv style={{ marginLeft: 20 }}>
                {hAuth.isLogin ? (
                  <LogoutBtn
                    onClick={() => {
                      closeMobileMainMenuPopup();
                      hMyPageState.act.clearState();
                      hExplorerState.act.clearExplorerState();
                    }}
                  />
                ) : (
                  <LoginBtn
                    onClick={() => {
                      closeMobileMainMenuPopup();
                    }}
                  />
                )}
              </FlexDiv>

              <HEIGHT size={20} />
            </FlexColumnDiv>
            <HLINE size={'100%'} />
            <FlexColumnDiv
              style={{
                alignSelf: 'stretch',
                flex: 1,
                backgroundColor: R.colors.dialogBg,
              }}
              onClick={() => {
                closeMobileMainMenuPopup();
              }}
            />
          </AnimatedFlexColumnDiv>
        )}

        <ScrollLock isActive={sScrollLock} />
      </FlexColumnDiv>
    );
  }
}
