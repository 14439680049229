/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { ServerECode_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { CLAY, CommonColumnBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/
enum State_e {
  BeforePasswordCheck,
  AfterPasswordCheck,
}

/**************************************
 * !! constant
 **************************************/
const DT_BACTBTN_MARGIN_BOTTOM = 30;
const DT_BOX_WIDTH = 582;
const DT_INPUT_WIDTH = 522;
const DT_INPUT_HEIGHT = 44;
const DT_BOX_HAPADDING = 30;
const DT_BTN_WIDTH = 355;
const DT_BTN_HEIGHT = 48;
const DT_BTN_FONTSIZE = 14;
const DT_BOTTOM_SPACE = 348;
const DT_PASSWORD_VISIBLE_IC_SIZE = 30;
const DT_CHECH_IC_SIZE = 20;

const MB_BACTBTN_MARGIN_BOTTOM = 20;
const MB_BOX_WIDTH = CLAY.BODY_WIDTH;
const MB_BOX_BODY_WIDTH = 256;
const MB_INPUT_HEIGHT = 38;
const MB_BOX_HAPADDING = 12;
const MB_BTN_WIDTH = MB_BOX_BODY_WIDTH;
const MB_BTN_HEIGHT = 44;
const MB_BTN_FONTSIZE = 12;
const MB_BOTTOM_SPACE = 60;
const MB_PASSWORD_VISIBLE_IC_SIZE = 24;
const MB_CHECH_IC_SIZE = 18;

const BACTBTN_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_BACTBTN_MARGIN_BOTTOM : DT_BACTBTN_MARGIN_BOTTOM;
const BOX_WIDTH = ScreenInfo.isMobile() ? MB_BOX_WIDTH : DT_BOX_WIDTH;
const BOX_BODY_WIDTH = ScreenInfo.isMobile() ? MB_BOX_BODY_WIDTH : DT_INPUT_WIDTH;
const INPUT_HEIGHT = ScreenInfo.isMobile() ? MB_INPUT_HEIGHT : DT_INPUT_HEIGHT;
const BOX_HAPADDING = ScreenInfo.isMobile() ? MB_BOX_HAPADDING : DT_BOX_HAPADDING;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_FONTSIZE = ScreenInfo.isMobile() ? MB_BTN_FONTSIZE : DT_BTN_FONTSIZE;
const BOTTOM_SPACE = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE : DT_BOTTOM_SPACE;
const PASSWORD_VISIBLE_IC_SIZE = ScreenInfo.isMobile() ? MB_PASSWORD_VISIBLE_IC_SIZE : DT_PASSWORD_VISIBLE_IC_SIZE;
const CHECH_IC_SIZE = ScreenInfo.isMobile() ? MB_CHECH_IC_SIZE : DT_CHECH_IC_SIZE;

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserChangeFishCodeScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sNextBtnEnable, setNextBtnEnable] = useState(false);
  const [sChangeBtnEnable, setChangeBtnEnable] = useState(false);
  const [sState, setState] = useState<State_e>(State_e.BeforePasswordCheck);

  const [sCurPassword, setCurPassword] = useState<string>('');
  const [sCurPasswordValidation, setCurPasswordValidation] = useState<{
    isOk: boolean;
    emsg?: string;
  }>({ isOk: false });
  const [sCurPasswordVisible, setCurPasswordVisible] = useState(false);
  const [sCurPasswordFocus, setCurPasswordFocus] = useState(false);

  const [sNewCode, setNewCode] = useState<string>('');
  const [sNewCodeFocus, setNewCodeFocus] = useState(false);
  const [sNewCodeInputEnable, setNewCodeInputEnable] = useState(true);

  const [sComplete, setComplete] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const curPasswordInputRef = useRef<HTMLInputElement | null>(null);
  const newCodeInputRef = useRef<HTMLInputElement | null>(null);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User Change Fishcode - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hRpc = useRpc();
  const hR = useLocalSettings();
  const hHisotry = useHistory();
  const hUserDetailMineState = useUserDetailMineState();
  // const { pageWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    if (sCurPassword.length > 0) {
      setNextBtnEnable(true);
    } else {
      setNextBtnEnable(false);
    }

    return () => {};
  }, [sCurPassword.length]);

  useEffect(() => {
    if (sNewCode.length > 0) {
      setChangeBtnEnable(true);
    } else {
      setChangeBtnEnable(false);
    }

    return () => {};
  }, [sNewCode.length]);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const renderState = () => {
    switch (sState) {
      case State_e.BeforePasswordCheck:
        return (
          <>
            {/* 현재 */}
            <TextCon
              text={hR.strings.MY_FISH_SECURE_CODE_CHANGE_PASSWORD}
              size={CLAY.DEFAULT_FONT_SIZE}
              color={R.colors.black}
              isBold
              containerStyle={{ alignSelf: 'flex-start' }}
            />
            <HEIGHT size={14} />
            <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'center' }}>
              <SRoundInputText
                width={BOX_BODY_WIDTH}
                height={INPUT_HEIGHT}
                fontSize={CLAY.DEFAULT_FONT_SIZE}
                getRef={(inputRef) => {
                  curPasswordInputRef.current = inputRef;
                }}
                value={sCurPassword}
                inputType={sCurPasswordVisible ? 'text' : 'password'}
                placeHolder={hR.strings.MY_FISH_SECURE_CODE_CHANGE_NEXT_PLACEHOLDE}
                onChange={(e) => {
                  setCurPassword(e.target.value);
                  setCurPasswordValidation({ isOk: true });
                }}
                onFocus={() => {
                  setCurPasswordFocus(true);
                }}
                onBlur={() => {
                  setCurPasswordFocus(false);
                }}
              />
              <ImgBtn
                src={[
                  sCurPasswordVisible ? R.images.common_btn_visible3x : R.images.common_btn_not_visible3x,
                  PASSWORD_VISIBLE_IC_SIZE,
                  PASSWORD_VISIBLE_IC_SIZE,
                ]}
                containerStyle={{ position: 'absolute', right: 1, padding: 7 }}
                onClick={() => {
                  setCurPasswordVisible(!sCurPasswordVisible);
                  curPasswordInputRef.current?.focus();
                }}
              />
            </FlexRowDiv>
            {sCurPasswordValidation && !sCurPasswordValidation.isOk && sCurPasswordValidation.emsg && (
              <>
                <HEIGHT size={14} />
                <TextCon
                  text={sCurPasswordValidation.emsg}
                  size={CLAY.DEFAULT_FONT_SIZE}
                  color={R.colors.pastelRed}
                  containerStyle={{ alignSelf: 'flex-start' }}
                />
              </>
            )}
            <HEIGHT size={40} />
            <RoundTxtBtn
              width={BTN_WIDTH}
              height={BTN_HEIGHT}
              fontSize={BTN_FONTSIZE}
              text={hR.strings.MY_FISH_SECURE_CODE_CHANGE_NEXT}
              containerStyle={{ alignSelf: 'center' }}
              enable={sNextBtnEnable}
              onClick={async () => {
                const pwHash = await Utils.crypto.sha256(sCurPassword);
                hRpc.cobaltRpc
                  .rpcUserAccountPasswordCheck({ password: pwHash })
                  .then((res) => {
                    setState(State_e.AfterPasswordCheck);
                    setTimeout(() => {
                      newCodeInputRef.current?.focus();
                    }, 100);
                  })
                  .catch((e) => {
                    const errInfo = getRpcErrorInfo(e);
                    if (errInfo) {
                      switch (errInfo.ecode) {
                        case ServerECode_e.INVALID_USER_PASSWORD:
                          setCurPasswordValidation({ isOk: false, emsg: hR.strings.MY_FISH_SECURE_CODE_CHANGE_PASSWORD_WRONG });

                          // 연속해서 클릭하는것 방지
                          setNextBtnEnable(false);
                          setTimeout(() => {
                            setNextBtnEnable(true);
                          }, 1000);
                          return;

                        default:
                          setCurPasswordValidation({ isOk: false, emsg: `${errInfo.ecode}: ${errInfo.emsg}` });
                          break;
                      }
                    }
                  });
              }}
            />
          </>
        );
      case State_e.AfterPasswordCheck:
        return (
          <>
            {/* 현재 PASSWORD */}
            <TextCon
              text={hR.strings.MY_FISH_SECURE_CODE_CHANGE_NEW}
              size={15}
              color={R.colors.black}
              isBold
              containerStyle={{ alignSelf: 'flex-start' }}
            />
            <HEIGHT size={14} />
            <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'center' }}>
              <SRoundInputText
                width={BOX_BODY_WIDTH}
                height={INPUT_HEIGHT}
                fontSize={CLAY.DEFAULT_FONT_SIZE}
                getRef={(inputRef) => {
                  newCodeInputRef.current = inputRef;
                }}
                value={sNewCode}
                placeHolder={hR.strings.MY_FISH_SECURE_CODE_CHANGE_NEW_PLACEHOLDER}
                onChange={(e) => {
                  setNewCode(e.target.value);
                }}
                onFocus={() => {
                  setNewCodeFocus(true);
                }}
                onBlur={() => {
                  setNewCodeFocus(false);
                }}
                disabled={sNewCodeInputEnable === false}
              />
            </FlexRowDiv>
            {sComplete && (
              <>
                <HEIGHT size={12} />
                <FlexColumnDiv
                  style={{
                    alignSelf: 'stretch',
                    borderRadius: 15,
                    backgroundColor: R.colors.lightGrey,
                    padding: 14,
                  }}
                >
                  <TextCon text={hR.strings.MY_FISH_SECURE_CODE_CHANGE_OK_MSG_1} size={12} color={R.colors.purpleishBlue} isBold />
                  <HEIGHT size={2} />
                  <TextCon text={hR.strings.MY_FISH_SECURE_CODE_CHANGE_OK_MSG_2} size={12} color={R.colors.purpleishBlue} isBold />
                </FlexColumnDiv>
              </>
            )}
            <HEIGHT size={40} />
            <RoundTxtBtn
              width={BTN_WIDTH}
              height={BTN_HEIGHT}
              fontSize={BTN_FONTSIZE}
              text={hR.strings.MY_FISH_SECURE_CODE_CHANGE_OK_BTN}
              containerStyle={{ alignSelf: 'center' }}
              enable={sChangeBtnEnable}
              onClick={() => {
                if (sComplete) {
                  if (timeoutRef.current !== null) {
                    // 타이머 해제
                    clearTimeout(timeoutRef.current);
                    timeoutRef.current = null;

                    //
                    hUserDetailMineState.act.changeState({
                      user: {
                        needUpdate: true,
                      },
                    });
                    hHisotry.replace(Nav.UserDetailMineScreen);
                  }
                } else {
                  // RPC 호출
                  hRpc.cobaltRpc.rpcUserAccountAccountAntiphishingReset({ ap_cd: sNewCode }).then((res) => {
                    setComplete(true);
                    setNewCodeInputEnable(false);
                    timeoutRef.current = setTimeout(() => {
                      hUserDetailMineState.act.changeState({
                        user: {
                          needUpdate: true,
                        },
                      });
                      hHisotry.replace(Nav.UserDetailMineScreen);
                    }, 10000);
                  });
                }
              }}
            />
          </>
        );
    }
  };

  /**************************************
   * !! render conf
   **************************************/

  DBGMSG('render');
  return (
    <MobileScalableFlexColumn>
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: CLAY.PAGE_WIDTH,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          paddingLeft: CLAY.HPADDING,
          paddingRight: CLAY.HPADDING,
          ...devOutline,
        }}
      >
        <BackBtn />
        <HEIGHT size={BACTBTN_MARGIN_BOTTOM} />
        <CommonColumnBox
          style={{
            alignSelf: 'center',
            width: BOX_WIDTH,
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: BOX_HAPADDING,
            paddingRight: BOX_HAPADDING,
          }}
        >
          <TextCon
            text={hR.strings.MY_FISH_SECURE_CODE_CHANGE_TITLE}
            size={CLAY.TITLE_SIZE_IN_BOX}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'center' }}
          />
          <HEIGHT size={40} />
          {renderState()}
        </CommonColumnBox>
        <HEIGHT size={552} />
      </FlexColumnDiv>
    </MobileScalableFlexColumn>
  );
}
