// import classNames from 'classnames/bind';
import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';

// import styles from 'src/ui/dlg/jsx/WithdrawJsx.module.scss';

// const cx = classNames.bind(styles);
// const cx = classNames('testtest2');

export type YesNoJsxProps = {
  text: string;
  onYes?: () => void;
  onNo?: () => void;
  yes?: string;
  no?: string;
};

export const YesNoJsx = (dlg: YesNoJsxProps) => {
  const hR = useLocalSettings();
  return (
    <div
      className={ScreenInfo.isMobile() ? 'yesno_jsx_m' : 'yesno_jsx'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={'text'}>{dlg.text}</div>
      <div className={'yesno_btn'}>
        <div
          className={'yes_btn'}
          onClick={() => {
            dlg.onYes && dlg.onYes();
          }}
        >
          {dlg.yes ?? 'YES'}
        </div>
        <div
          className={'no_btn'}
          onClick={() => {
            dlg.onNo && dlg.onNo();
          }}
        >
          {dlg.no ?? 'NO'}
        </div>
      </div>
    </div>
  );
};
