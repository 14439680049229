/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMouseEnterEffectType1 } from 'src/hooks/useMouseEnterEffectType1';
import { CbtAssetType_e, CbtAttributeType_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import {
  AnimatedFlexRowDiv,
  CommonInput,
  devOutlineF,
  devOutlineFF,
  FlexColumnDiv,
  FlexDiv,
  FlexRowDiv,
  VLINE,
  WIDTH,
} from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { ATTRIBUTE_NAME_WIDTH } from 'src/ui/common_component/SchemaAttribute';
import { CommonColumnBox, COMMON_BOX_HORIZONTAL_PADDING, CLAY, PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import { DBGMSG, Utils } from 'src/util/utils';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const TextArea = styled.textarea`
  font-family: ${R.fonts.SpoqaJP};
  color: ${R.colors.black};
  border-width: 0px;
  background-color: ${R.colors.transparent};
  ::placeholder {
    color: ${R.colors.cloudyBlue};
  }
  &:focus {
    outline: none;
  }
`;

export const SchemaAttributeDropdown = styled(CommonColumnBox)`
  z-index: 1000000;
`;

type Props = {
  idx?: number;
  assetType?: CbtAssetType_e;
  attrName: string;
  attrValue?: string;
  attrType: CbtAttributeType_e;
  nameColor?: string;
  valueColor?: string;
  isAnim?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDropDownOpenClick?: () => void;
  onFileSelect?: () => void;
  onOutsideClick?: () => void;
  onDeleteClick?: () => void;
  isFileSelected?: boolean;
};

export function SchemaAttributeInput({
  idx,
  assetType,
  attrName,
  attrValue,
  attrType,
  nameColor = R.colors.black,
  valueColor = R.colors.black,
  isAnim = false,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onFileSelect,
  onDeleteClick,
  isFileSelected,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sIsInputFocus, setIsInputFocus] = useState(false);
  const [sShowTooltip, setShowTooltip] = useState(false);

  /**************************************
   * !! hook
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! ref
   **************************************/
  const inputTxtRef = useRef<HTMLInputElement>(null);
  // useOutsideClick(
  //   [dropdownRef],
  //   {
  //     insideClick: () => {
  //       DBGMSG('insideClick');
  //     },
  //     outsideClick: () => {
  //       DBGMSG('outsideClick');
  //       onOutsideClick && onOutsideClick();
  //     },
  //   },
  //   'SchemaAtt DropdownPopup'
  // );

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const renderAtt = (attrType: CbtAttributeType_e) => {
    if (ScreenInfo.isMobile()) {
      switch (attrType) {
        case CbtAttributeType_e.TEXT:
          return (
            <TextArea
              // fontSize={fontSize}
              style={{
                alignSelf: 'flex-end',
                minWidth: 110 + 220,
                minHeight: 10,
                maxHeight: 149,
                fontSize: 10,
                paddingTop: 11,
                paddingLeft: 11,
                paddingRight: 11,
                resize: Utils.svc.isReqAttrName(attrName) ? 'unset' : undefined,
              }}
              placeholder={
                Utils.svc.isReqAttrName(attrName) ? hR.strings.AC_ASSET_CREATE_ATTR_TYPE_NFTNAME : hR.strings.AC_ASSET_CREATE_ATTR_TYPE_TEXT
              }
              value={attrValue}
              onFocus={() => {
                DBGMSG('Input onFocus');
                onFocus && onFocus();
                setIsInputFocus(true);
              }}
              onBlur={() => {
                DBGMSG('Input onBlur');
                onBlur && onBlur();
                setIsInputFocus(false);
              }}
              onChange={(e) => {
                DBGMSG(`onChange: ${e.target.value}`);
                onChange && onChange(e);
              }}
              onKeyPress={(e) => {
                DBGMSG(`onKeyPress code:${e.code}`);
                DBGMSG(`onKeyPress key:${e.key}`);
                onKeyDown && onKeyDown(e);
              }}
            />
          );
        case CbtAttributeType_e.NUMBER:
          return (
            <CommonInput
              ref={inputTxtRef}
              style={{
                width: 127,
                fontWeight: 'bold',
                paddingLeft: 11,
                fontSize: 10,
                ...devOutline,
                // ...devOutlineF,
              }}
              // TODO
              // placeholder={`${typeTxt} ${attrType === CbtAttributeType_e.TEXT ? '(20자 이내)' : ''}`}
              placeholder={`${typeTxt}`}
              value={attrValue}
              onFocus={() => {
                DBGMSG('Input onFocus');
                onFocus && onFocus();
                setIsInputFocus(true);
              }}
              onBlur={() => {
                DBGMSG('Input onBlur');
                onBlur && onBlur();
                setIsInputFocus(false);
              }}
              onChange={(e) => {
                DBGMSG(`onChange: ${e.target.value}`);
                onChange && onChange(e);
              }}
              onKeyPress={(e) => {
                DBGMSG(`onKeyPress code:${e.code}`);
                DBGMSG(`onKeyPress key:${e.key}`);
                onKeyDown && onKeyDown(e);
              }}
            />
          );
        case CbtAttributeType_e.IMAGE:
        case CbtAttributeType_e.AUDIO:
        case CbtAttributeType_e.VIDEO:
          return (
            <FlexRowDiv
              style={{
                width: 127,
                alignSelf: 'stretch',
                alignItems: 'center',
                paddingLeft: 11,
                // ...devOutlineF,
              }}
            >
              {isFileSelected ? (
                <>
                  <TextCon
                    text={attrValue}
                    size={12}
                    color={R.colors.black}
                    textStyle={{
                      width: 79,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  />
                  <ImgBtn
                    src={[R.images.creator_btn_delete3x, 20, 20]}
                    onClick={() => {
                      onDeleteClick && onDeleteClick();
                    }}
                    containerStyle={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      marginRight: 8,
                    }}
                  />
                </>
              ) : (
                <FlexColumnDiv style={{ flex: 1 }}>
                  <FlexRowDiv>
                    {/* TODO string resource */}
                    <TextCon
                      text={`${typeTxt} ${typeTxtDetail}`}
                      size={10}
                      color={sShowTooltip ? R.colors.purpleishBlue : R.colors.cloudyBlue}
                      onConMouseEnter={(e) => {
                        setShowTooltip(true);
                        e.stopPropagation();
                      }}
                      onConMouseLeave={(e) => {
                        setShowTooltip(false);
                      }}
                    />

                    <ImgBtn
                      src={[R.images.creator_btn_add3x, 20, 20]}
                      containerStyle={{ marginLeft: 'auto', marginRight: 8 }}
                      onClick={() => {
                        onFileSelect && onFileSelect();
                      }}
                    />
                  </FlexRowDiv>
                </FlexColumnDiv>
              )}
            </FlexRowDiv>
          );
      }
    } else {
      switch (attrType) {
        case CbtAttributeType_e.TEXT:
          return (
            <TextArea
              // fontSize={fontSize}
              style={{
                alignSelf: 'flex-end',
                minWidth: 110 + 220,
                minHeight: 40,
                maxHeight: 149,
                fontSize: 14,
                paddingTop: 22,
                paddingLeft: 14,
                paddingRight: 14,
                resize: Utils.svc.isReqAttrName(attrName) ? 'unset' : undefined,
                // ...devOutlineFF,
              }}
              placeholder={
                Utils.svc.isReqAttrName(attrName) ? hR.strings.AC_ASSET_CREATE_ATTR_TYPE_NFTNAME : hR.strings.AC_ASSET_CREATE_ATTR_TYPE_TEXT
              }
              value={attrValue}
              onFocus={() => {
                DBGMSG('Input onFocus');
                onFocus && onFocus();
                setIsInputFocus(true);
              }}
              onBlur={() => {
                DBGMSG('Input onBlur');
                onBlur && onBlur();
                setIsInputFocus(false);
              }}
              onChange={(e) => {
                DBGMSG(`onChange: ${e.target.value}`);
                onChange && onChange(e);
              }}
              onKeyPress={(e) => {
                DBGMSG(`onKeyPress code:${e.code}`);
                DBGMSG(`onKeyPress key:${e.key}`);
                onKeyDown && onKeyDown(e);
              }}
            />
          );
        case CbtAttributeType_e.NUMBER:
          return (
            <CommonInput
              ref={inputTxtRef}
              style={{
                width: 358,
                fontWeight: 'bold',
                paddingLeft: 14,
                ...devOutline,
                // ...devOutlineF,
              }}
              // TODO
              // placeholder={`${typeTxt} ${attrType === CbtAttributeType_e.TEXT ? '(20자 이내)' : ''}`}
              placeholder={`${typeTxt}`}
              value={attrValue}
              onFocus={() => {
                DBGMSG('Input onFocus');
                onFocus && onFocus();
                setIsInputFocus(true);
              }}
              onBlur={() => {
                DBGMSG('Input onBlur');
                onBlur && onBlur();
                setIsInputFocus(false);
              }}
              onChange={(e) => {
                DBGMSG(`onChange: ${e.target.value}`);
                onChange && onChange(e);
              }}
              onKeyPress={(e) => {
                DBGMSG(`onKeyPress code:${e.code}`);
                DBGMSG(`onKeyPress key:${e.key}`);
                onKeyDown && onKeyDown(e);
              }}
            />
          );
        case CbtAttributeType_e.IMAGE:
        case CbtAttributeType_e.AUDIO:
        case CbtAttributeType_e.VIDEO:
          return (
            <FlexRowDiv
              style={{
                width: 358,
                alignSelf: 'stretch',
                alignItems: 'center',
                paddingLeft: 14,
                // ...devOutlineF,
              }}
            >
              {isFileSelected ? (
                <>
                  <TextCon
                    text={Utils.string.shortString(attrValue!, 15, 15)}
                    size={14}
                    color={R.colors.black}
                    textStyle={{
                      width: 300,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  />
                  <ImgBtn
                    src={[R.images.creator_btn_delete3x, 24, 24]}
                    onClick={() => {
                      onDeleteClick && onDeleteClick();
                    }}
                    containerStyle={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      marginRight: 10,
                    }}
                  />
                </>
              ) : (
                <FlexColumnDiv style={{ flex: 1 }}>
                  <FlexRowDiv>
                    <TextCon
                      text={Utils.string.replacePlaceholder({
                        target: hR.strings.AC_ASSET_CREATE_FILE_UPLOAD_SPEC,
                        placeholder: R.strings.PLACEHOLDER_ATTR_TTYPE,
                        txt: typeTxt,
                      })}
                      size={14}
                      color={sShowTooltip ? R.colors.purpleishBlue : R.colors.cloudyBlue}
                      onConMouseEnter={(e) => {
                        e.stopPropagation();
                      }}
                      onConMouseLeave={(e) => {}}
                    />
                    <WIDTH size={4} />

                    {/* 툴팁 ic */}
                    {(function () {
                      switch (attrType) {
                        case CbtAttributeType_e.IMAGE:
                          if (Utils.svc.isReqAttrName(attrName) && assetType === CbtAssetType_e.VIDEO) {
                            return (
                              <TooltipImg
                                onShow={(isShow) => {
                                  setShowTooltip(isShow);
                                }}
                                placement={'right-start'}
                                tooltipString={hR.strings.AC_ASSET_CREATE_FILE_VIDEO_THUMB_IMAGE_SPEC_TOOLTIP}
                              />
                            );
                          } else if (Utils.svc.isReqAttrName(attrName) && assetType === CbtAssetType_e.AUDIO) {
                            return (
                              <TooltipImg
                                onShow={(isShow) => {
                                  setShowTooltip(isShow);
                                }}
                                placement={'right-start'}
                                tooltipString={hR.strings.AC_ASSET_CREATE_FILE_AUDIO_COVER_IMAGE_SPEC_TOOLTIP}
                              />
                            );
                          } else {
                            return (
                              <TooltipImg
                                onShow={(isShow) => {
                                  setShowTooltip(isShow);
                                }}
                                placement={'right-start'}
                                tooltipString={hR.strings.AC_ASSET_CREATE_FILE_IMAGE_SPEC_TOOLTIP}
                              />
                            );
                          }
                        case CbtAttributeType_e.AUDIO:
                          return (
                            <TooltipImg
                              onShow={(isShow) => {
                                setShowTooltip(isShow);
                              }}
                              placement={'right-start'}
                              tooltipString={hR.strings.AC_ASSET_CREATE_FILE_AUDIO_SPEC_TOOLTIP}
                            />
                          );
                        case CbtAttributeType_e.VIDEO:
                          return (
                            <TooltipImg
                              onShow={(isShow) => {
                                setShowTooltip(isShow);
                              }}
                              placement={'right-start'}
                              tooltipString={hR.strings.AC_ASSET_CREATE_FILE_VIDEO_SPEC_TOOLTIP}
                            />
                          );
                      }
                    })()}

                    <RoundTxtBtn
                      width={87}
                      height={36}
                      fontSize={12}
                      isBold={false}
                      text={hR.strings.AC_ASSET_CREATE_FILE}
                      containerStyle={{ marginLeft: 'auto', marginRight: 10 }}
                      onClick={() => {
                        onFileSelect && onFileSelect();
                      }}
                    />
                  </FlexRowDiv>
                </FlexColumnDiv>
              )}
            </FlexRowDiv>
          );
      }
    }
  };

  /**************************************
   * !! animation
   **************************************/
  const { hAnimProps, mouseEnter, mouseLeave } = useMouseEnterEffectType1();
  const typeTxt = hR.strings.getAttributeTypeText(attrType);
  const typeTxtDetail = hR.strings.getAttributeTypeDetailText(attrType);

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv
        style={{
          width: CLAY.FORM_SIZE.WIDTH,
          height: 60,
          paddingBottom: 10,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              alignItems: 'center',
              height: 60,
              borderRadius: 15,
            },
            sIsInputFocus ? PropsSRoundEnabled : PropsSRoundDisabled,
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
        >
          <FlexDiv style={{ width: ATTRIBUTE_NAME_WIDTH, paddingLeft: 12 }}>
            <TextCon text={Utils.svc.convertIfReqAttrName(attrName)} isBold color={R.colors.black} size={12} />
            <WIDTH size={3} />
            {Utils.svc.isReqAttrName(attrName) && <TextCon text={'*'} size={12} color={R.colors.purpleishPink} />}
          </FlexDiv>

          <VLINE size={40} />

          <FlexRowDiv
            style={{
              width: 128,
              alignSelf: 'stretch',
              alignItems: 'center',
              cursor: attrType === CbtAttributeType_e.TEXT ? 'text' : 'auto',
            }}
            onClick={(e) => {
              if (inputTxtRef.current) {
                inputTxtRef.current.focus();
              }
            }}
          >
            {attrType && renderAtt(attrType)}
          </FlexRowDiv>
        </AnimatedFlexRowDiv>
      </FlexColumnDiv>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          width: ScreenInfo.contentsWidth / 2,
          paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
          paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
          paddingBottom: 10,

          // ...devOutlineF,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              alignItems: 'center',
              minHeight: 68,
              borderRadius: 15,
            },
            sIsInputFocus ? PropsSRoundEnabled : PropsSRoundDisabled,
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
        >
          <FlexDiv style={{ width: ATTRIBUTE_NAME_WIDTH, paddingLeft: 14 }}>
            <TextCon text={Utils.svc.convertIfReqAttrName(attrName)} isBold color={R.colors.black} size={14} />
            <WIDTH size={3} />
            {Utils.svc.isReqAttrName(attrName) && <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />}
          </FlexDiv>

          <FlexColumnDiv style={{ paddingTop: 9, paddingBottom: 9, height: '100%' }}>
            <VLINE size={'100%'} />
          </FlexColumnDiv>

          <FlexRowDiv
            style={{
              width: 358,
              alignSelf: 'stretch',
              alignItems: 'center',
              cursor: attrType === CbtAttributeType_e.TEXT ? 'text' : 'auto',
            }}
            onClick={(e) => {
              if (inputTxtRef.current) {
                inputTxtRef.current.focus();
              }
            }}
          >
            {attrType && renderAtt(attrType)}
          </FlexRowDiv>
        </AnimatedFlexRowDiv>
      </FlexColumnDiv>
    );
}
