import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import { isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useDlg } from 'src/hooks/useDlg';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { FlexColumnDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { AsyncGuideJsx, AsyncGuideJsxProps } from 'src/ui/dlg/jsx/AsyncGuideJsx';
import { BuyJsx, BuyJsxProps } from 'src/ui/dlg/jsx/BuyJsx';
import { InqueryJsx, InqueryJsxProps } from 'src/ui/dlg/jsx/InqueryJsx';
import { MintMoreJsx, MintMoreJsxProps } from 'src/ui/dlg/jsx/MintMoreJsx';
import { OkDlgJsx, OkDlgJsxProps } from 'src/ui/dlg/jsx/OkDlgJsx';
import { PaymentwallResultJsx } from 'src/ui/dlg/jsx/PaymentwallResultJsx';
import { PaymentWallWidgetJsx } from 'src/ui/dlg/jsx/PaymentWallWidgetJsx';
import { PointChargeJsx, PointChargeJsxProps } from 'src/ui/dlg/jsx/PointChargeJsx';
import { PointChargeListJsx, PointChargeListJsxProps } from 'src/ui/dlg/jsx/PointChargeListJsx';
import { RegPaypalJsx, RegPaypalJsxProps } from 'src/ui/dlg/jsx/RegPaypalJsx';
import { ReportJsx, ReportJsxProps } from 'src/ui/dlg/jsx/ReportJsx';
import { RewardAddrChangeJsx, RewardAddrChangeJsxProps } from 'src/ui/dlg/jsx/RewardAddrChangeJsx';
import { RewardAddrChangeSuccJsx, RewardAddrChangeSuccJsxProps } from 'src/ui/dlg/jsx/RewardAddrChangeSuccJsx';
import { SellJsx, SellJsxProps } from 'src/ui/dlg/jsx/SellJsx';
import { WalletConnectJsx, WalletConnectJsxArgs } from 'src/ui/dlg/jsx/WalletConnectJsx';
import { PayoutJsx, PayoutJsxProps } from 'src/ui/dlg/jsx/PayoutJsx';
import { CbltFeeJsx, CbltFeeJsxProps } from 'src/ui/dlg/jsx/CbltFeeJsx';
import { SimpleJsx, SimpleJsxProps } from 'src/ui/dlg/jsx/SimpleJsx';
import { YesNoJsx, YesNoJsxProps } from 'src/ui/dlg/jsx/YesNoJsx';

enum DlgKey_e {
  RewardAddrChangeDlg = 'RewardAddrChangeDlg',
  RewardAddrChangeSuccDlg = 'RewardAddrChangeSuccDlg',
  WalletConnectDlgJsx = 'WalletConnectDlgJsx',
  OkDlgJsx = 'OkDlgJsx',
  ChargePointListDlg = 'ChargePointListDlg',
  PaymentwallWidetDlg = 'PaymentwallWidetDlg',
  PgPointChargeSucc = 'PaymentwallSucc',
  PgPointChargeFail = 'PaymentwallFail',
  PurchasePageMove = 'PurchasePageMove',
  FileUploading = 'FileUploading',
  MintMoreJsx = 'MintMoreJsx',
  AsyncGuideJsx = 'AsyncGuideJsx',
  SellJsx = 'SellJsx',
  BuyJsx = 'BuyJsx',
  RunningSmartContract = 'RunningSmartContract',
  BuyResult = 'BuyResult',
  RunningBuyProcessing = 'RunningBuyProcessing',
  Loading = 'Loading',
  Report = 'Report',
  Inquery = 'Inquery',
  PointCharge = 'PointCharge',
  Withdraw = 'Withdraw',
  RegPaypal = 'RegPaypal',
  CbltFee = 'CbltFee',
  Simple = 'Simple',
  YesNo = 'YesNo',
}

export const useDlgFactory = () => {
  const hDlg = useDlg();
  const hR = useLocalSettings();

  const hideDlgJsxMultiByKey = (key: string) => {
    // hDlg.act.hideDlgJsxMultiByKey({ key });
    hDlg.act.closeDlgJsxMultiByKey({ key });
  };
  const hideDlgJsxMultiLast = () => {
    hDlg.act.hideDlgJsxMultiLast();
  };
  const closeDlgJsxMultiByKey = (key: string) => {
    hDlg.act.closeDlgJsxMultiByKey({ key });
  };
  const closeDlgJsxMultiLast = () => {
    hDlg.act.closeDlgJsxMultiLast();
  };

  return {
    manualControl: {
      hideDlgJsxMultiByKey,
      hideDlgJsxMultiLast,
      closeDlgJsxMultiByKey,
      closeDlgJsxMultiLast,
    },

    // ####################################
    // Simple
    // ####################################
    showSimple: (props: SimpleJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.Simple,
        jsx: (
          <SimpleJsx
            {...props}
            onClose={
              props.onClose ??
              (() => {
                hideDlgJsxMultiByKey(DlgKey_e.Simple);
              })
            }
          />
        ),
        onBackdropClick: () => {
          hideDlgJsxMultiByKey(DlgKey_e.Simple);
          props.onClose && props.onClose();
        },
      });
    },
    hideSimple: () => {
      hideDlgJsxMultiByKey(DlgKey_e.Simple);
    },
    closeSimple: () => {
      closeDlgJsxMultiByKey(DlgKey_e.Simple);
    },

    // ####################################
    // YesNo
    // ####################################
    showYesNo: (props: YesNoJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.YesNo,
        jsx: <YesNoJsx {...props} />,
      });
    },
    hideYesNo: () => {
      hideDlgJsxMultiByKey(DlgKey_e.YesNo);
    },
    closeYesNo: () => {
      closeDlgJsxMultiByKey(DlgKey_e.YesNo);
    },

    // ####################################
    // 입금/리워드 주소 변경 다이얼로그
    // ####################################
    showRewardAddrChangeDlg: (props: RewardAddrChangeJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.RewardAddrChangeDlg,
        jsx: <RewardAddrChangeJsx {...props} />,
        onBackdropClick: () => {},
      });
    },

    hideRewardAddrChangeDlg: () => {
      hideDlgJsxMultiByKey(DlgKey_e.RewardAddrChangeDlg);
    },

    closeRewardAddrChangeDlg: () => {
      closeDlgJsxMultiByKey(DlgKey_e.RewardAddrChangeDlg);
    },

    // ####################################
    // 입금/리워드 주소 변경 성공 다이얼로그
    // ####################################
    showRewardAddrChangeSuccDlg: (props: RewardAddrChangeSuccJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.RewardAddrChangeSuccDlg,
        jsx: <RewardAddrChangeSuccJsx {...props} />,
        onBackdropClick: () => {},
      });
    },

    closeRewardAddrChangeSuccDlg: () => {
      closeDlgJsxMultiByKey(DlgKey_e.RewardAddrChangeSuccDlg);
    },

    hideRewardAddrChangeSuccDlg: () => {
      hideDlgJsxMultiByKey(DlgKey_e.RewardAddrChangeSuccDlg);
    },

    // ####################################
    // 지갑연결 다이얼로그
    // ####################################
    showWalletConnectDlgJsx: ({ props, onBackdropClick }: { props: WalletConnectJsxArgs; onBackdropClick: () => void }) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.WalletConnectDlgJsx,
        onBackdropClick,
        jsx: <WalletConnectJsx {...props} />,
      });
    },

    closeWalletConnectDlgJsx: () => {
      closeDlgJsxMultiByKey(DlgKey_e.WalletConnectDlgJsx);
    },

    hideWalletConnectDlgJsx: () => {
      hideDlgJsxMultiByKey(DlgKey_e.WalletConnectDlgJsx);
    },

    // ####################################
    // 포인트 충전하기 목록
    // ####################################
    showPointChargeListDlg: (props: PointChargeListJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.ChargePointListDlg,
        jsx: <PointChargeListJsx {...props} />,
        // onBackdropClick: () => {},
        paddingTop: 10,
      });
    },
    closePointChargeListDlg: () => {
      closeDlgJsxMultiByKey(DlgKey_e.ChargePointListDlg);
    },

    hidePointChargeListDlg: () => {
      hideDlgJsxMultiByKey(DlgKey_e.ChargePointListDlg);
    },

    // ####################################
    // 포인트 충전하기
    // ####################################
    showPointChargeDlg: (props: PointChargeJsxProps) => {
      let currency: string;
      switch (hR.lang) {
        case CbtUserLanguage_e.KO:
        case CbtUserLanguage_e.JA:
        case CbtUserLanguage_e.EN:
          currency = 'USD';
          break;
      }
      const clientId = isProd() ? Constants.PAYPAL_CLIENT_ID_PROD : Constants.PAYPAL_CLIENT_ID_ALPHA;
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.PointCharge,
        jsx: (
          <PayPalScriptProvider
            options={{
              'client-id': clientId,
              currency,
            }}
          >
            <PointChargeJsx {...props} />
          </PayPalScriptProvider>
        ),
        // paddingTop: 10,
        // onBackdropClick: props.onClose,
      });
    },
    closePointChargeDlg: () => {
      closeDlgJsxMultiByKey(DlgKey_e.PointCharge);
    },

    hidePointChargeDlg: () => {
      hideDlgJsxMultiByKey(DlgKey_e.PointCharge);
    },

    // ####################################
    // PaymentWall Widget
    // ####################################
    showPaymentwallWidetDlg: ({
      widgetUrl,
      onCloseBtn,
      onSuccPayment,
      onFailPayment,
    }: {
      widgetUrl: string;
      onCloseBtn: () => void;
      onSuccPayment: () => void;
      onFailPayment: () => void;
    }) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.PaymentwallWidetDlg,
        jsx: (
          <PaymentWallWidgetJsx
            url={widgetUrl}
            onCloseBtn={() => {
              onCloseBtn();
              hideDlgJsxMultiByKey(DlgKey_e.PaymentwallWidetDlg);
            }}
            onSuccPayment={onSuccPayment}
            onFailPayment={onFailPayment}
          />
        ),
        onBackdropClick: onCloseBtn,
        paddingTop: 10,
      });
    },

    hidePaymentwallWidetDlg: () => {
      hideDlgJsxMultiByKey(DlgKey_e.PaymentwallWidetDlg);
    },

    closePaymentwallWidetDlg: () => {
      closeDlgJsxMultiByKey(DlgKey_e.PaymentwallWidetDlg);
    },

    // ####################################
    // 포인트 충전 성공
    // ####################################
    showPgPointChargeSucc: ({ transaction_id }: { transaction_id: string }) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.PgPointChargeSucc,
        jsx: (
          <PaymentwallResultJsx
            paymentwall_result={'succ'}
            transaction_id={transaction_id}
            onOk={() => {
              hideDlgJsxMultiByKey(DlgKey_e.PgPointChargeSucc);
            }}
          />
        ),
      });
    },

    hidePgPointChargeSucc: () => {
      hideDlgJsxMultiByKey(DlgKey_e.PgPointChargeSucc);
    },

    closePgPointChargeSucc: () => {
      closeDlgJsxMultiByKey(DlgKey_e.PgPointChargeSucc);
    },

    // ####################################
    // 포인트 충전 실패
    // ####################################
    showPgPointChargeFail: () => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.PgPointChargeFail,
        jsx: (
          <PaymentwallResultJsx
            paymentwall_result={'fail'}
            onOk={() => {
              hideDlgJsxMultiByKey(DlgKey_e.PgPointChargeFail);
            }}
          />
        ),
      });
    },

    hidePgPointChargeFail: () => {
      hideDlgJsxMultiByKey(DlgKey_e.PgPointChargeFail);
    },
    closePgPointChargeFail: () => {
      closeDlgJsxMultiByKey(DlgKey_e.PgPointChargeFail);
    },

    // ####################################
    // 결제 페이지로 이동합니다.
    // ####################################
    showPurchasePageMove: () => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.PurchasePageMove,
        jsx: (
          <FlexColumnDiv style={{ width: 276, height: 148, justifyContent: 'center', alignItems: 'center' }}>
            <TextCon text={hR.strings.DLG_WILL_MOVE_PAYMENTPAGE_TITLE} color={R.colors.black} size={16} isBold />
            <TextCon text={hR.strings.DLG_WILL_MOVE_PAYMENTPAGE_TEXT} color={R.colors.blueGrey} size={14} />
            <HEIGHT size={10} />
            <Img src={[R.images.creator_ic_loading1x, 30, 30]} />
          </FlexColumnDiv>
        ),
        onBackdropClick: () => {},
      });
    },
    hidePurchasePageMove: () => {
      hideDlgJsxMultiByKey(DlgKey_e.PurchasePageMove);
    },
    closePurchasePageMove: () => {
      closeDlgJsxMultiByKey(DlgKey_e.PurchasePageMove);
    },

    // ####################################
    // 업로드
    // ####################################
    showFileUploading: () => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.FileUploading,
        jsx: (
          <FlexColumnDiv style={{ width: 276, height: 148, justifyContent: 'center', alignItems: 'center' }}>
            <TextCon text={hR.strings.AC_ASSET_CREATE_DLG_FILE_UPLOADING_TITLE} color={R.colors.black} size={16} isBold />
            <TextCon text={hR.strings.AC_ASSET_CREATE_DLG_FILE_UPLOADING_TEXT} color={R.colors.blueGrey} size={14} />
            <HEIGHT size={10} />
            <Img src={[R.images.creator_ic_loading1x, 30, 30]} />
          </FlexColumnDiv>
        ),
        onBackdropClick: () => {},
      });
    },
    hideFileUploading: () => {
      hideDlgJsxMultiByKey(DlgKey_e.FileUploading);
    },
    closeFileUploading: () => {
      closeDlgJsxMultiByKey(DlgKey_e.FileUploading);
    },

    // ####################################
    // 추가발행 다이얼로그
    // ####################################
    showMintMoreJsx: (props: MintMoreJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.MintMoreJsx,
        jsx: <MintMoreJsx {...props} />,
      });
    },

    hideMintMoreJsx: () => {
      hideDlgJsxMultiByKey(DlgKey_e.MintMoreJsx);
    },
    closeMintMoreJsx: () => {
      closeDlgJsxMultiByKey(DlgKey_e.MintMoreJsx);
    },

    // ####################################
    // 비동기 처리 안내 다이얼로그
    // ####################################
    showAsyncGuideJsx: (props: AsyncGuideJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.AsyncGuideJsx,
        jsx: <AsyncGuideJsx {...props} />,
        onBackdropClick: () => {
          // props.onOk && props.onOk();// 배경을 클릭하면 ok할지..
        },
      });
    },
    hideAsyncGuideJsx: () => {
      hideDlgJsxMultiByKey(DlgKey_e.AsyncGuideJsx);
    },
    closeAsyncGuideJsx: () => {
      closeDlgJsxMultiByKey(DlgKey_e.AsyncGuideJsx);
    },

    // ####################################
    // OK 다이얼로그
    // ####################################
    showSimpleOk: (args: { text: string; backdropClickClose?: boolean; key?: string; onOk?: () => void }) => {
      const props: OkDlgJsxProps = {
        text: args.text,
        textSize: ScreenInfo.isMobile() ? 12 : 16,
        textBold: true,
        width: ScreenInfo.isMobile() ? 280 : 380,
        height: ScreenInfo.isMobile() ? 154 : 216,
        btnWidth: ScreenInfo.isMobile() ? 240 : 300,
        btnTxt: hR.strings.COMMON_OK_BTN_TXT,
        btnTxtSize: ScreenInfo.isMobile() ? 12 : 14,
        onOk: () => {
          hideDlgJsxMultiByKey(args.key ?? DlgKey_e.OkDlgJsx);
          args.onOk && args.onOk();
        },
      };
      hDlg.act.showDlgJsxMulti({
        key: args.key ?? DlgKey_e.OkDlgJsx,
        jsx: <OkDlgJsx {...props} />,
        onBackdropClick: () => {
          if (args.backdropClickClose) hideDlgJsxMultiByKey(args.key ?? DlgKey_e.OkDlgJsx);
        },
      });
    },

    showOkDlgJsx: (props: OkDlgJsxProps, key?: string) => {
      hDlg.act.showDlgJsxMulti({
        key: key ?? DlgKey_e.OkDlgJsx,
        jsx: <OkDlgJsx {...props} />,
        onBackdropClick: () => {
          // props.key && hideDlgJsxMultiByKey(props.key);
        },
      });
    },

    closeOkDlgJsx: (key: string | undefined = undefined) => {
      closeDlgJsxMultiByKey(key ?? DlgKey_e.OkDlgJsx);
    },

    hideOkDlgJsx: (key: string | undefined = undefined) => {
      hideDlgJsxMultiByKey(key ?? DlgKey_e.OkDlgJsx);
    },

    // ####################################
    // Test
    // ####################################
    showTest: (props: OkDlgJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: 'test',
        jsx: <OkDlgJsx {...props} />,
      });
    },

    // ####################################
    // 판매하기/판매편집 다이얼로그
    // ####################################
    showSellJsx: (props: SellJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.SellJsx,
        jsx: <SellJsx {...props} />,
        paddingTop: 10,
      });
    },
    hideSellJsx: () => {
      hideDlgJsxMultiByKey(DlgKey_e.SellJsx);
    },
    closeSellJsx: () => {
      closeDlgJsxMultiByKey(DlgKey_e.SellJsx);
    },

    // ####################################
    // 구매하기 다이얼로그
    // ####################################
    showBuyJsx: (props: BuyJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.BuyJsx,
        jsx: <BuyJsx {...props} />,
        paddingTop: 10,
      });
    },
    hideBuyJsx: () => {
      hideDlgJsxMultiByKey(DlgKey_e.BuyJsx);
    },
    closeBuyJsx: () => {
      closeDlgJsxMultiByKey(DlgKey_e.BuyJsx);
    },

    // ####################################
    // 스마트컨트랙트 실행중
    // ####################################
    showRunningSmartContract: () => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.RunningSmartContract,
        jsx: (
          <FlexColumnDiv style={{ width: 380, height: 206, justifyContent: 'center', alignItems: 'center' }}>
            <Img src={[R.images.common_ic_running1x, 50, 50]} />
            <HEIGHT size={20} />
            <TextCon text={'Smart Contract'} size={22} color={R.colors.purpleishBlue} isBold />
            <HEIGHT size={5} />
            <TextCon text={hR.strings.DLG_RUNNING_SMART_CONTRACT} size={14} color={R.colors.black} />
          </FlexColumnDiv>
        ),
        onBackdropClick: () => {},
      });
    },
    hideRunningSmartContract: () => {
      hideDlgJsxMultiByKey(DlgKey_e.RunningSmartContract);
    },
    closeRunningSmartContract: () => {
      closeDlgJsxMultiByKey(DlgKey_e.RunningSmartContract);
    },

    // ####################################
    // 포인트로 구매 실행중
    // ####################################
    showRunningBuyProcessing: () => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.RunningBuyProcessing,
        jsx: (
          <FlexColumnDiv style={{ width: 276, height: 148, justifyContent: 'center', alignItems: 'center' }}>
            <TextCon text={hR.strings.DLG_RUNNING_BUY_PROCESS_TITLE} color={R.colors.black} size={16} isBold />
            <TextCon text={hR.strings.DLG_RUNNING_BUY_PROCESS_TEXT} color={R.colors.blueGrey} size={14} />
            <HEIGHT size={10} />
            <Img src={[R.images.creator_ic_loading1x, 30, 30]} />
          </FlexColumnDiv>
        ),
        onBackdropClick: () => {},
      });
    },
    hideRunningBuyProcessing: () => {
      hideDlgJsxMultiByKey(DlgKey_e.RunningBuyProcessing);
    },
    closeRunningBuyProcessing: () => {
      closeDlgJsxMultiByKey(DlgKey_e.RunningBuyProcessing);
    },

    // ####################################
    // 결제 성공/실패
    // ####################################
    showBuyResult: ({ isSucc, onOk }: { isSucc: boolean; onOk?: () => void }) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.BuyResult,
        jsx: (
          <FlexColumnDiv
            style={{
              width: ScreenInfo.isMobile() ? 280 : 380,
              height: ScreenInfo.isMobile() ? 186 : 290,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Img
              src={[
                isSucc ? R.images.point_ic_success3x : R.images.point_ic_fail3x,
                ScreenInfo.isMobile() ? 38 : 50,
                ScreenInfo.isMobile() ? 38 : 50,
              ]}
            />
            <HEIGHT size={ScreenInfo.isMobile() ? 10 : 20} />
            <TextCon
              text={isSucc ? hR.strings.DLG_BUY_SUCCESS_TITLE : hR.strings.DLG_BUY_FAILURE_TITLE}
              size={ScreenInfo.isMobile() ? 12 : 16}
              color={R.colors.black}
              isBold
            />
            <HEIGHT size={5} />
            <TextCon
              text={isSucc ? hR.strings.DLG_BUY_SUCCESS_TEXT : hR.strings.DLG_BUY_FAILURE_TEXT}
              size={ScreenInfo.isMobile() ? 10 : 14}
              color={R.colors.black}
            />
            <HEIGHT size={ScreenInfo.isMobile() ? 20 : 40} />
            <RoundTxtBtn
              width={ScreenInfo.isMobile() ? 240 : 300}
              height={ScreenInfo.isMobile() ? 38 : 48}
              text={hR.strings.COMMON_OK_BTN_TXT}
              onClick={() => {
                hideDlgJsxMultiByKey(DlgKey_e.BuyResult);
                onOk && onOk();
              }}
            />
          </FlexColumnDiv>
        ),
        onBackdropClick: () => {},
      });
    },
    hideBuyResult: () => {
      hideDlgJsxMultiByKey(DlgKey_e.BuyResult);
    },
    closeBuyResult: () => {
      closeDlgJsxMultiByKey(DlgKey_e.BuyResult);
    },

    // ####################################
    // 로딩
    // ####################################
    showLoading: () => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.Loading,
        jsx: <DataFetching />,
        bgTransparent: true,
      });
    },
    hideLoading: () => {
      hideDlgJsxMultiByKey(DlgKey_e.Loading);
    },
    closeLoading: () => {
      closeDlgJsxMultiByKey(DlgKey_e.Loading);
    },

    // ####################################
    // 신고하기
    // ####################################
    showReport: (props: ReportJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.Report,
        jsx: <ReportJsx {...props} />,
        // bgTransparent: true,
      });
    },
    hideReport: () => {
      hideDlgJsxMultiByKey(DlgKey_e.Report);
    },
    closeReport: () => {
      closeDlgJsxMultiByKey(DlgKey_e.Report);
    },

    // ####################################
    // 공유하기
    // ####################################
    showShare: (props: ReportJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.Report,
        jsx: <ReportJsx {...props} />,
        // bgTransparent: true,
      });
    },
    hideShare: () => {
      hideDlgJsxMultiByKey(DlgKey_e.Report);
    },
    closeShare: () => {
      closeDlgJsxMultiByKey(DlgKey_e.Report);
    },

    // ####################################
    // 문의하기
    // ####################################
    showInquery: (props: InqueryJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.Inquery,
        jsx: <InqueryJsx {...props} />,
        // bgTransparent: true,
      });
    },
    hideInquery: () => {
      hideDlgJsxMultiByKey(DlgKey_e.Inquery);
    },
    closeInquery: () => {
      closeDlgJsxMultiByKey(DlgKey_e.Inquery);
    },

    // ####################################
    // 인출하기
    // ####################################
    showWithdraw: (props: PayoutJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.Withdraw,
        jsx: <PayoutJsx {...props} />,
        // bgTransparent: true,
      });
    },
    hideWithdraw: () => {
      hideDlgJsxMultiByKey(DlgKey_e.Withdraw);
    },
    closeWithdraw: () => {
      closeDlgJsxMultiByKey(DlgKey_e.Withdraw);
    },

    // ####################################
    // 페이팔 계좌 등록
    // ####################################
    showRegPaypal: (props: RegPaypalJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.RegPaypal,
        jsx: <RegPaypalJsx {...props} />,
        onBackdropClick: () => {},
        // bgTransparent: true,
      });
    },
    hideRegPaypal: () => {
      hideDlgJsxMultiByKey(DlgKey_e.RegPaypal);
    },
    closeRegPaypal: () => {
      closeDlgJsxMultiByKey(DlgKey_e.RegPaypal);
    },

    // ####################################
    // CBLT 수수료 지불하기
    // ####################################
    showCbltFee: (props: CbltFeeJsxProps) => {
      hDlg.act.showDlgJsxMulti({
        key: DlgKey_e.CbltFee,
        jsx: <CbltFeeJsx {...props} />,
        onBackdropClick: () => {},
        // bgTransparent: true,
      });
    },
    hideCbltFee: () => {
      hideDlgJsxMultiByKey(DlgKey_e.CbltFee);
    },
    closeCbltFee: () => {
      closeDlgJsxMultiByKey(DlgKey_e.CbltFee);
    },
  };
};
