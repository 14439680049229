/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { DisplaySrc_t } from 'src/model/model';
import { CbtAttributeType_e, CbtColCertifyType_e, CbtPgInfo_i } from 'src/model/rpcModel';
import { RPC_ASSETMGR_VIEW, RPC_ASSET_CREATOR_VIEW, RPC_MP_SALE_HISTORY } from 'src/model/rpcType';
import { UserDetailMenuType_e } from 'src/redux/modules/userDetailModule';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { AssetDisplay } from 'src/ui/common_component/AssetDisplay/AssetDisplay';
import { AssetDisplayThumb, WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { LabelValue } from 'src/ui/common_component/LabelValue';
import { LabelValueMint } from 'src/ui/common_component/LabelValueMint';
import { PriceAdv } from 'src/ui/common_component/PriceAdv';
import { Title } from 'src/ui/common_component/Title';
import { UserThumbName } from 'src/ui/common_component/UserThumbName';
import { CLAY, CommonColumnBox } from 'src/ui/layout_constant';
import {
  ARROW_BTN_PADDING,
  ARROW_BTN_SIZE,
  ASSETDISPLAY_MARGIN,
  ASSETDISPLAY_SIZE,
  ASSETDISPLAY_THUMB_CNT,
  ASSETDISPLAY_THUMB_LIST_SIZE,
  ASSETDISPLAY_THUMB_SIZE,
  ASSET_NAME_FONT_SIZE,
  ASSET_NAME_MARGIN_BOTTOM,
  BACTBTN_MARGIN_BOTTOM,
  BOTTOM_SPACE,
  BOX_MARGIN_BOTTOM,
  BOX_MARGIN_TOP,
  BOX_PADDING_H,
  BTN_FONT_SIZE,
  BTN_HEIGHT,
  BTN_WIDTH,
  COL_FONT_SIZE,
  COL_IMG_SIZE,
  COL_NAME_HEIGHT,
  COL_NAME_MARGIN_BOTTOM,
  LEFT_CONTAINER_HEIGHT,
  LEFT_CONTAINER_WIDTH,
  LV_FLEX_DIR,
  LV_MARGIN_V,
  LV_TITLE_FONTSIZE,
  LV_TITLE_HEIGHT,
  LV_TITLE_WIDTH,
  LV_VALUE_FONTSIZE,
  LV_VALUE_HEIGHT,
  LV_VALUE_WIDTH,
  OVERBOX_INFO_PADDING_LEFT,
  RIGHT_CONTAINER_WIDHT,
  SALE_BOX_HEIGHT,
  SALE_FONT_SIZE,
  SCHM_NAME_FONTSIZE,
  SCHM_NAME_HEIGHT,
  SCHM_NAME_VMARGIN,
  TH_DATE_WIDHT,
  TH_EVETN_WIDHT,
  TH_FROM_WIDHT,
  TH_ITEM_HEIGHT,
  TH_MARGIN_BOTTOM,
  TH_PAGING_HEIGHT,
  TH_PAGING_MARIGN_V,
  TH_PRICE_WIDHT,
  TH_TITLE_MARIGN_BOTTOM,
  TH_TITLE_MARIGN_TOP,
  TH_TO_WIDHT,
  TH_WIDTH,
} from 'src/ui/screen/AssetCreator/MyIssueAssetRScreen';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! Constant
 **************************************/
// MyAssetRScreen 과 공유

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function AssetmgrAssetRScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sQS, setQS] = useState<{ sid: number; entity_id: number; asset_id: number; no_sale_ctl?: boolean }>();
  const [sFetchedDetailData, setFetchedDetailData] = useState<RPC_ASSETMGR_VIEW.Rx>();
  const [sFetchedHistoryData, setFetchedHistoryData] = useState<RPC_MP_SALE_HISTORY.Rx>();
  const [sTradingPageno, setTradingPageno] = useState(0);

  const [sSelectedDisplayThumbIdx, setSelectedDisplayThumbIdx] = useState(0); // 선택되어 있는 thumb idx
  const [sSliderCurIdx, setSliderCurIdx] = useState(0); // Slider의 idx
  const [sDisplaySrcList, setDisplaySrcList] = useState<DisplaySrc_t[]>();

  /**************************************
   * !! ref
   **************************************/
  const assetDisplayThumbListSliderRef = useRef<Slider>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`Asset Inventory - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();

  const hDlgFactory = useDlgFactory();
  const hAuth = useAuth();
  const hMyPageState = useUserDetailMineState();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string; entity_id?: string; asset_id?: string; no_sale_ctl?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid && query.entity_id && query.asset_id) {
      DBGMSG(`$$$$QS sid: ${query.sid}`);
      DBGMSG(`$$$$QS entity_id: ${query.entity_id}`);
      DBGMSG(`$$$$QS asset_id: ${query.asset_id}`);
      const sid = parseInt(query.sid);
      const entity_id = parseInt(query.entity_id);
      const asset_id = parseInt(query.asset_id);

      if (query.no_sale_ctl) {
        if (query.no_sale_ctl === 'true') {
          setQS({ sid, entity_id, asset_id, no_sale_ctl: true });
        }
      } else {
        setQS({ sid, entity_id, asset_id });
      }

      fetchDetailData({ sid, entity_id, asset_id });
      fetchHistoryData({ sid, entity_id, asset_id });
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const fetchDetailData = async ({ sid, entity_id, asset_id }: { sid: number; entity_id: number; asset_id: number }) => {
    try {
      const res = await hRpc.cobaltRpc.rpcAssetmgrView({ sid, entity_id, asset_id });
      setFetchedDetailData(res);

      const playerSrcList = Utils.svc.brewDisplaySrcList({ assetType: res.ast_info.schm_info.asset_type, attrList: res.ast_info.attr_list });
      setDisplaySrcList(playerSrcList);
    } catch (err) {
      hRpc.hanleCommonError({
        err,
        onOk: () => {
          hHistory.goBack();
        },
      });
    }
  };

  const fetchHistoryData = async ({
    sid,
    entity_id,
    asset_id,
    pg_info,
  }: {
    sid: number;
    entity_id: number;
    asset_id: number;
    pg_info?: CbtPgInfo_i;
  }) => {
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleHistory({ sid, entity_id, asset_id, pg_info });
      setFetchedHistoryData(res);
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    }
  };

  const reload = () => {
    if (!sQS) return;
    fetchDetailData(sQS);
    fetchHistoryData(sQS);
  };

  // 뒤로 돌아가기 했을때 판매정보 반영을 위해
  const updateMypageInventoryAstUpdate = async () => {
    if (!sQS) return;
    if (!sFetchedDetailData) return;
    try {
      hDlgFactory.showLoading();
      const res = await hRpc.cobaltRpc.rpcAssetmgrView({
        sid: sQS.sid,
        entity_id: sQS.entity_id,
        asset_id: sQS.asset_id,
      });
      // 카드 업데이트
      // sale_id 변경,,,
      hMyPageState.changeInventoryAstSaleInfo(
        { asset_id: sFetchedDetailData.ast_info.asset_id, asset_key: sFetchedDetailData.ast_info.asset_key },
        { sale_id: res.ast_info.sale_id, sid: sQS.sid, entity_id: sQS.entity_id, asset_id: sQS.asset_id }
      );
    } catch (err) {
    } finally {
      hDlgFactory.hideLoading();
    }
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  if (!sQS || !sFetchedDetailData) return <DataFetching />;

  if (!sDisplaySrcList) {
    DBGMSGW(sDisplaySrcList);
    return null;
  }

  // 소유자 여부
  const isOwner = hAuth.authCommonRes?.ucode === sFetchedDetailData.ast_info.owner.ucode;

  DBGMSG('render');
  return (
    <>
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          <BackBtn />
          <HEIGHT size={BACTBTN_MARGIN_BOTTOM} />

          <FlexRowDiv style={{ width: CLAY.PAGE_WIDTH, flexWrap: 'wrap' }}>
            {/* left container */}
            <FlexDiv
              style={{
                width: LEFT_CONTAINER_WIDTH,
                // ...devOutlineF
              }}
            >
              <CommonColumnBox style={{ width: LEFT_CONTAINER_WIDTH, height: LEFT_CONTAINER_HEIGHT }}>
                {/******************************************
                 * AssetDisplay
                 *******************************************/}
                <FlexDiv
                  style={{
                    margin: ASSETDISPLAY_MARGIN,
                  }}
                >
                  <AssetDisplay
                    key={`${sSelectedDisplayThumbIdx}`}
                    playerSrc={sDisplaySrcList[sSelectedDisplayThumbIdx]}
                    width={ASSETDISPLAY_SIZE}
                    height={ASSETDISPLAY_SIZE}
                    onImgFullscreen={(src) => {
                      // setFullImgViewer({ isOpen: true, src });
                      // setFullScreenImgSrc(src);
                      // if (!ScreenInfo.isMobile()) {
                      //   setFullScreenViewerOnOff(true);
                      // } else {
                      //   // setMobileFullScreenViewerOnOff(true);
                      //   window.open(src);
                      // }
                    }}
                    isBlock={sFetchedDetailData.ast_info.blocked}
                  />
                </FlexDiv>
                {/* AssetDisplay end */}

                <FlexRowDiv
                  style={{
                    width: LEFT_CONTAINER_WIDTH,
                    alignItems: 'center',
                    justifyContent: 'center',
                    // ...devOutlineF,
                  }}
                >
                  {/* < left 버튼 */}
                  <ImgBtn
                    src={[
                      sSliderCurIdx === 0 ? R.images.common_btn_back_disabled3x : R.images.common_btn_back_abled3x,
                      ARROW_BTN_SIZE,
                      ARROW_BTN_SIZE,
                    ]}
                    containerStyle={{
                      //
                      padding: ARROW_BTN_PADDING,
                      flex: '0 0 auto',
                      // opacity: 1 + sOptMediaOnlyAttrList.length > 4 ? 1 : 0,
                      // ...devOutlineF,
                    }}
                    onClick={() => {
                      if (sSliderCurIdx === 0) return;
                      assetDisplayThumbListSliderRef.current?.slickPrev();
                    }}
                  />

                  {/*******************************************
                   * AssetDisplay Thumb 리스트
                   *******************************************/}
                  <FlexColumnDiv
                    // {...hDrag()}
                    style={{
                      width: ASSETDISPLAY_THUMB_LIST_SIZE,
                      overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤?이 생김
                      justifyContent: 'center',
                      // ...devOutlineF,
                    }}
                  >
                    <Slider
                      ref={assetDisplayThumbListSliderRef}
                      infinite={false}
                      speed={400}
                      slidesToShow={ASSETDISPLAY_THUMB_CNT}
                      swipeToSlide={true}
                      slidesToScroll={1}
                      beforeChange={(cur, next) => {
                        DBGMSG(`cur:${cur} next:${next}`);
                        setSliderCurIdx(next);
                      }}
                      afterChange={(cur) => {
                        DBGMSG(`cur:${cur}`);
                        setSliderCurIdx(cur);
                      }}
                    >
                      {sDisplaySrcList.map((displaySrc, idx) => {
                        return (
                          <AssetDisplayThumb
                            whereToUse={WhereToUse_e.ForSlider}
                            key={`${idx}`}
                            width={ASSETDISPLAY_THUMB_SIZE}
                            height={ASSETDISPLAY_THUMB_SIZE}
                            playerSrc={displaySrc}
                            onClick={() => {
                              setSelectedDisplayThumbIdx(idx);
                            }}
                            selected={sSelectedDisplayThumbIdx === idx}
                          />
                        );
                      })}
                    </Slider>
                  </FlexColumnDiv>
                  {/* AssetDisplay 리스트 end */}

                  {/* > right 버튼 */}
                  <ImgBtn
                    src={[
                      sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length
                        ? R.images.common_btn_next_disabled3x
                        : R.images.common_btn_next_abled3x,
                      ARROW_BTN_SIZE,
                      ARROW_BTN_SIZE,
                    ]}
                    containerStyle={{
                      padding: ARROW_BTN_PADDING,
                      flex: '0 0 auto',
                      // ...devOutlineF,
                    }}
                    onClick={() => {
                      if (sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length) return;
                      assetDisplayThumbListSliderRef.current?.slickNext();
                    }}
                  />
                </FlexRowDiv>
              </CommonColumnBox>
            </FlexDiv>

            {/* right container */}
            <FlexColumnDiv
              style={{
                width: RIGHT_CONTAINER_WIDHT,
                marginTop: ScreenInfo.isMobile() ? 20 : undefined,
                marginLeft: ScreenInfo.isDesktop() ? 30 : undefined,
                // ...devOutlineF
              }}
            >
              {/* 박스위 정보 */}
              <FlexColumnDiv style={{ paddingLeft: OVERBOX_INFO_PADDING_LEFT }}>
                {/* 컬렉션 정보 */}
                <FlexRowDiv
                  style={{ height: COL_NAME_HEIGHT, cursor: 'pointer' }}
                  onClick={() => {
                    hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: sFetchedDetailData.ast_info.col_info.cid }));
                  }}
                >
                  {sFetchedDetailData.ast_info.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
                    <>
                      <Img src={[R.images.common_ic_verify3x, COL_IMG_SIZE, COL_IMG_SIZE]} />
                      <WIDTH size={6} />
                    </>
                  )}
                  <TextCon text={sFetchedDetailData.ast_info.col_info.dp_name} isBold size={COL_FONT_SIZE} color={R.colors.purpleishBlue} />
                </FlexRowDiv>
                <HEIGHT size={COL_NAME_MARGIN_BOTTOM} />
                {/* 어셋 이름 */}
                <TextCon
                  text={Utils.svc.getAssetNameFromAttrList(sFetchedDetailData.ast_info.attr_list)}
                  isBold
                  size={ASSET_NAME_FONT_SIZE}
                  color={R.colors.black}
                />
                <HEIGHT size={ASSET_NAME_MARGIN_BOTTOM} />
                <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row' }}>
                  {/* 소유자 */}
                  <FlexDiv style={{ flex: 1 }}>
                    <UserThumbName
                      thumb={null}
                      kind={'OWNER'}
                      userId={sFetchedDetailData.ast_info.owner.ucode}
                      onClick={() => {
                        hHistory.push(Nav.brewUserDetailScreenPath({ ucode: sFetchedDetailData.ast_info.owner.ucode, menu: UserDetailMenuType_e.INVEN }));
                      }}
                    />
                  </FlexDiv>
                  {ScreenInfo.isMobile() && <HEIGHT size={8} />}
                  {/* 원작자 */}
                  <FlexDiv style={{ flex: 1 }}>
                    <UserThumbName
                      thumb={null}
                      kind={'CREATOR'}
                      userId={sFetchedDetailData.ast_info.creator.ucode}
                      onClick={() => {
                        hHistory.push(Nav.brewUserDetailScreenPath({ ucode: sFetchedDetailData.ast_info.creator.ucode, menu: UserDetailMenuType_e.INVEN }));
                      }}
                    />
                  </FlexDiv>
                </FlexDiv>
              </FlexColumnDiv>
              <HEIGHT size={BOX_MARGIN_TOP} />

              {/* 박스 */}
              <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDHT }}>
                {/* 스키마 디스플레이 이름 */}
                <TextCon
                  size={SCHM_NAME_FONTSIZE}
                  color={R.colors.purpleishBlue}
                  isBold
                  text={sFetchedDetailData.ast_info.schm_info.dp_name}
                  textStyle={{ height: SCHM_NAME_HEIGHT }}
                  containerStyle={{
                    paddingLeft: BOX_PADDING_H,
                    paddingBottom: SCHM_NAME_VMARGIN,
                    paddingTop: SCHM_NAME_VMARGIN,
                  }}
                  onConClick={() => {
                    hHistory.push(Nav.brewMarketSchemaRScreenPath({ sid: sFetchedDetailData.ast_info.schm_info.sid }));
                  }}
                />
                {/* 라인 ----------------------------------------------------  */}
                <HLINE size={'100%'} color={R.colors.line} />
                <FlexColumnDiv style={{ paddingLeft: BOX_PADDING_H, paddingRight: BOX_PADDING_H }}>
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 에셋 아이디 */}
                  <LabelValue
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_ASSET_ID}
                    value={`${sFetchedDetailData.ast_info.asset_key}`}
                    titleSize={LV_TITLE_FONTSIZE}
                    valueSize={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 에셋타입 */}
                  <LabelValue
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_ASSET_TYPE}
                    value={hR.strings.getAssetType(sFetchedDetailData.ast_info.schm_info.asset_type)}
                    titleSize={LV_TITLE_FONTSIZE}
                    valueSize={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 발행수 */}
                  <LabelValueMint
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_MINT_CNT}
                    value={{
                      nOf: sFetchedDetailData.ast_info.mint_no,
                      total: sFetchedDetailData.ast_info.issue_tcnt,
                      can_addition: sFetchedDetailData.ast_info.can_addition,
                      mintMore: `${sFetchedDetailData.ast_info.can_addition ? `(${hR.strings.AC_ASSET_READ_MINT_MORE})` : ''}`,
                    }}
                    titleSize={LV_TITLE_FONTSIZE}
                    value1Size={LV_VALUE_FONTSIZE}
                    value2Size={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  {sFetchedDetailData.ast_info.attr_list.map((att, idx) => {
                    switch (att.type) {
                      case CbtAttributeType_e.TEXT:
                      case CbtAttributeType_e.NUMBER:
                        return (
                          <>
                            <HEIGHT size={LV_MARGIN_V} />
                            <LabelValue
                              flexDir={LV_FLEX_DIR}
                              title={Utils.svc.convertIfReqAttrName(att.name)}
                              value={`${att.value}`}
                              titleSize={LV_TITLE_FONTSIZE}
                              valueSize={LV_VALUE_FONTSIZE}
                              titleWidth={LV_TITLE_WIDTH}
                              valueWidth={LV_VALUE_WIDTH}
                              titleHeight={LV_TITLE_HEIGHT}
                              valueHeight={LV_VALUE_HEIGHT}
                            />
                          </>
                        );

                      case CbtAttributeType_e.IMAGE:
                      case CbtAttributeType_e.AUDIO:
                      case CbtAttributeType_e.VIDEO:
                        return (
                          <>
                            <HEIGHT size={LV_MARGIN_V} />
                            <LabelValue
                              flexDir={LV_FLEX_DIR}
                              title={Utils.svc.convertIfReqAttrName(att.name)}
                              value={`${Utils.string.shortString(Utils.ipfs.getIpfsHash(att.value), 10, 10)}`}
                              titleSize={LV_TITLE_FONTSIZE}
                              valueSize={LV_VALUE_FONTSIZE}
                              titleWidth={LV_TITLE_WIDTH}
                              valueWidth={LV_VALUE_WIDTH}
                              titleHeight={LV_TITLE_HEIGHT}
                              valueHeight={LV_VALUE_HEIGHT}
                            />
                          </>
                        );
                    }
                  })}
                  <HEIGHT size={LV_MARGIN_V} />
                </FlexColumnDiv>
              </CommonColumnBox>

              <HEIGHT size={BOX_MARGIN_BOTTOM} />

              {/* 판매중 */}
              {sQS.no_sale_ctl !== true && isOwner && sFetchedDetailData.ast_info.sale_id > 0 && (
                <>
                  <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDHT, height: SALE_BOX_HEIGHT, justifyContent: 'center' }}>
                    <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row', marginLeft: BOX_PADDING_H }}>
                      <FlexRowDiv>
                        <TextCon
                          isBold
                          size={SALE_FONT_SIZE}
                          color={R.colors.black}
                          text={Utils.string.parsePlaceholder(hR.strings.AC_ASSET_READ_ON_SALE, R.strings.PLACEHOLDER_SALEID).begin}
                        />
                        <TextCon isBold size={SALE_FONT_SIZE} color={R.colors.purpleishBlue} text={`#${sFetchedDetailData.ast_info.sale_id}`} />
                        <TextCon
                          isBold
                          size={SALE_FONT_SIZE}
                          color={R.colors.black}
                          text={Utils.string.parsePlaceholder(hR.strings.AC_ASSET_READ_ON_SALE, R.strings.PLACEHOLDER_SALEID).end}
                        />
                      </FlexRowDiv>
                      {ScreenInfo.isMobile() && <HEIGHT size={16} />}
                      <RoundTxtBtn
                        enable={!sFetchedDetailData.ast_info.blocked}
                        text={hR.strings.AC_ASSET_READ_SALE_EDIT_BTN}
                        width={BTN_WIDTH}
                        height={BTN_HEIGHT}
                        fontSize={BTN_FONT_SIZE}
                        containerStyle={{ marginLeft: 'auto', marginRight: BOX_PADDING_H }}
                        onClick={async () => {
                          if (!sQS) return;

                          try {
                            // const res = await hRpc.cobaltRpc.rpcMpSaleEditView({
                            //   sale_id: sFetchedData.ast_info.sale_id,
                            // });

                            hDlgFactory.showSellJsx({
                              // fetched: res,
                              req: {
                                mode: 'EDIT',
                                tx: { sale_id: sFetchedDetailData.ast_info.sale_id },
                              },
                              onSuccess: async ({ prc_info }) => {
                                DBGMSG('onSale');
                                DBGMSG(prc_info);
                                reload();
                                hDlgFactory.hideSellJsx();
                                updateMypageInventoryAstUpdate();
                                hMyPageState.changeSaleListSaleInfo({ sale_id: sFetchedDetailData.ast_info.sale_id }, { prc_info });
                              },
                              onSaleStop: () => {
                                DBGMSG('onStop');
                                hDlgFactory.hideSellJsx();
                                reload();
                                updateMypageInventoryAstUpdate();
                                hMyPageState.changeSaleListSaleInfo({ sale_id: sFetchedDetailData.ast_info.sale_id }, { saleStop: true });
                              },
                              onClose: () => {
                                DBGMSG('onClose');
                                hDlgFactory.hideSellJsx();
                              },
                            });
                          } catch (e) {}
                        }}
                      />
                    </FlexDiv>
                  </CommonColumnBox>
                  <HEIGHT size={BOX_MARGIN_BOTTOM} />
                </>
              )}

              {/* 판매전 */}
              {sQS.no_sale_ctl !== true && isOwner && sFetchedDetailData.ast_info.sale_id === 0 && (
                <>
                  <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDHT, height: SALE_BOX_HEIGHT, justifyContent: 'center' }}>
                    <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row', marginLeft: BOX_PADDING_H }}>
                      <TextCon isBold size={SALE_FONT_SIZE} color={R.colors.black} text={hR.strings.AC_ASSET_READ_BEFORE_SALE} />
                      {ScreenInfo.isMobile() && <HEIGHT size={16} />}
                      <RoundTxtBtn
                        enable={!sFetchedDetailData.ast_info.blocked}
                        text={hR.strings.AC_ASSET_READ_SALE_BTN}
                        width={BTN_WIDTH}
                        height={BTN_HEIGHT}
                        fontSize={BTN_FONT_SIZE}
                        containerStyle={{ marginLeft: 'auto', marginRight: BOX_PADDING_H }}
                        onClick={async () => {
                          if (!sQS) return;

                          try {
                            // const res = await hRpc.cobaltRpc.rpcMpSaleAddView({
                            //   sid: sQS.sid,
                            //   entity_id: sQS.entity_id,
                            //   asset_id: sQS.asset_id,
                            // });

                            hDlgFactory.showSellJsx({
                              // fetched: res,
                              req: {
                                mode: 'NEW',
                                tx: {
                                  sid: sQS.sid,
                                  entity_id: sQS.entity_id,
                                  asset_id: sQS.asset_id,
                                },
                              },
                              onSuccess: async ({ prc_info: priceInfo }) => {
                                DBGMSG('onSale');
                                DBGMSG(priceInfo);
                                reload();
                                hDlgFactory.hideSellJsx();
                                updateMypageInventoryAstUpdate();
                              },
                              onSaleStop: () => {
                                DBGMSG('onStop');
                                hDlgFactory.hideSellJsx();
                              },
                              onClose: () => {
                                DBGMSG('onClose');
                                hDlgFactory.hideSellJsx();
                              },
                            });
                          } catch (e) {}
                        }}
                      />
                    </FlexDiv>
                  </CommonColumnBox>
                  <HEIGHT size={BOX_MARGIN_BOTTOM} />
                </>
              )}
            </FlexColumnDiv>
          </FlexRowDiv>

          {/* 거래내역 */}
          <HEIGHT size={TH_TITLE_MARIGN_TOP} />
          <Title title={hR.strings.AC_ASSET_READ_TRADING_HISTORY} />
          <HEIGHT size={TH_TITLE_MARIGN_BOTTOM} />

          <CommonColumnBox
            style={{
              width: CLAY.BODY_WIDTH,
              minHeight: 430,
              overflowX: 'scroll',
              // ...devOutline,
            }}
          >
            <FlexRowDiv style={{ width: TH_WIDTH }}>
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_EVENT}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_EVETN_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_PRICE}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_PRICE_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_FROM}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_FROM_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_TO}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_TO_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_DATE}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_DATE_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
            </FlexRowDiv>

            {sFetchedHistoryData &&
              sFetchedHistoryData.trd_history.map((trading, idx) => {
                return (
                  <>
                    {/* 라인 */}
                    <HLINE size={TH_WIDTH} />
                    <FlexRowDiv>
                      <TextCon
                        text={hR.strings.MARKET_ASSET_READ_TRADING_HISTORY_EVENT_SALE}
                        size={CLAY.DEFAULT_FONT_SIZE}
                        color={R.colors.black}
                        isBold
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_EVETN_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                      <PriceAdv
                        prc_info={trading.prc_info}
                        icSizeWidth={18}
                        icSizeHeight={20}
                        priceFontSize={14}
                        unitFontSize={14}
                        isBold={false}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_PRICE_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                      <UserThumbName
                        userId={trading.from.ucode}
                        thumb={trading.from.prof}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_FROM_WIDHT, paddingLeft: BOX_PADDING_H }}
                        isBold={false}
                      />
                      <UserThumbName
                        userId={trading.to.ucode}
                        thumb={trading.to.prof}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_TO_WIDHT, paddingLeft: BOX_PADDING_H }}
                        isBold={false}
                      />
                      <TextCon
                        text={trading.time === 0 ? '-' : Utils.date.brewFomatString(trading.time)}
                        size={CLAY.DEFAULT_FONT_SIZE}
                        color={R.colors.black}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_DATE_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                    </FlexRowDiv>
                  </>
                );
              })}
            <HLINE size={TH_WIDTH} />

            {sFetchedHistoryData && sFetchedHistoryData.trd_history.length === 0 && (
              <FlexColumnDiv
                style={{
                  flex: 1,
                  alignSelf: 'stretch',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // ...devOutlineF,
                }}
              >
                <TextCon text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_NO_DATA} isBold color={R.colors.blueGrey} size={CLAY.DEFAULT_FONT_SIZE} />
              </FlexColumnDiv>
            )}
          </CommonColumnBox>

          <HEIGHT size={TH_MARGIN_BOTTOM} />

          {/* 페이징 */}
          {sFetchedHistoryData && sFetchedHistoryData.trd_history.length > 0 && (
            <FlexRowDiv style={{ alignSelf: 'center' }}>
              <ImgBtn src={[R.images.common_btn_back_disabled3x, ARROW_BTN_SIZE, ARROW_BTN_SIZE]} onClick={() => {}} />
              <FlexRowDiv
                style={{
                  width: 100,
                  height: TH_PAGING_HEIGHT,
                  borderRadius: TH_PAGING_HEIGHT / 2,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: R.colors.line,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: TH_PAGING_MARIGN_V,
                  marginLeft: TH_PAGING_MARIGN_V,
                }}
              >
                <TextCon text={`${sTradingPageno}`} isBold size={CLAY.DEFAULT_FONT_SIZE} color={R.colors.black} />
              </FlexRowDiv>
              <ImgBtn
                src={[R.images.common_btn_next_disabled3x, ARROW_BTN_SIZE, ARROW_BTN_SIZE]}
                onClick={() => {
                  if (!sFetchedHistoryData) return;
                  if (sFetchedHistoryData.pg_info.last_row_id === -1) return;
                  if (!sQS) return;

                  const pg_info = sFetchedHistoryData.pg_info;

                  fetchHistoryData({ ...sQS, pg_info });
                }}
              />
            </FlexRowDiv>
          )}

          <HEIGHT size={BOTTOM_SPACE} />
        </FlexColumnDiv>
        {/* <DlgSale asset={sAssetInfo} show={sDlgSale} /> */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </MobileScalableFlexColumn>

      {/* 전체보기 */}
      {/* {sFullImgViewer.isOpen && sFullImgViewer.src && (
        <Lightbox wrapperClassName="custom_modal_image" mainSrc={sFullImgViewer.src} onCloseRequest={() => setFullImgViewer({ isOpen: false })} />
      )} */}
    </>
  );
}
