/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { DisplaySrc_t } from 'src/model/model';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv } from 'src/ui/base_component/etc';
import { AssetDisplayThumbOptattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbOptattr';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export enum WhereToUse_e {
  ForSlider,
  ForMyAssetCard,
  ForMarketAssetCard,
  ForDlg,
  ForNormalCard,
}

type Props = {
  whereToUse: WhereToUse_e;
  key?: string;
  playerSrc: DisplaySrc_t;
  width?: number;
  height?: number;
  onClick?: () => void;
  selected?: boolean;
  hiddenBorder?: boolean;
  isBlock?: boolean;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DEFAULT_WIDTH = 276;
const DEFAUL_HEIGHT = 276;

/**************************************
 * !! styled component
 **************************************/

export function AssetDisplayThumb({
  //
  whereToUse,
  key,
  playerSrc,
  width = DEFAULT_WIDTH,
  height = DEFAUL_HEIGHT,
  onClick,
  selected = false,
  hiddenBorder = false,
  isBlock = false,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  // const [sIsPlay, setIsPlay] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const renderAssetDisplayThumb = () => {
    switch (playerSrc.kind) {
      case 'ReqImage':
      case 'ReqAudio':
      case 'ReqVideo':
        return (
          <AssetDisplayThumbReqattr
            playerSrc={playerSrc}
            width={width}
            height={height}
            // 오디오일경우에는 padding이 생기도록 함
            scaleIfAudio={whereToUse === WhereToUse_e.ForSlider ? 0.9 : undefined}
            isBlock={isBlock}
            whereToUse={whereToUse}
          />
        );
      case 'OptImage':
      case 'OptAudio':
      case 'OptVideo':
        return (
          <AssetDisplayThumbOptattr
            //
            playerSrc={playerSrc}
            width={width}
            height={height}
          />
        );
    }
  };

  return (
    <FlexColumnDiv
      key={key}
      style={Object.assign({
        width: width,
        height: height,
        justifyContent: 'center',
        alignItems: 'center',
        margin: hiddenBorder ? 0 : 5,
        backgroundColor: 'white',
        borderRadius: hiddenBorder ? undefined : 10,
        borderWidth: hiddenBorder ? 0 : 2,
        borderStyle: hiddenBorder ? undefined : 'solid',
        borderColor: selected ? R.colors.purpleishBlue : R.colors.line,
        flex: '0 0 auto',
        overflow: 'hidden',
        cursor: onClick ? 'pointer' : 'inherit',
      })}
      onClick={onClick}
    >
      {renderAssetDisplayThumb()}
    </FlexColumnDiv>
  );
}
