/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useEffect, useState } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useRpc } from 'src/hooks/useRpc';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG } from 'src/util/utils';
import { CSSProperties } from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  saleId: number;
  width?: number;
  height?: number;
  defaultIsFavorite?: boolean;
  containerStyle?: CSSProperties;
  onToggle?: (favorite: boolean) => void;
  enable?: boolean;
  rounded: boolean;
};

export function FavoriteBtn({
  //
  saleId,
  defaultIsFavorite,
  width,
  height,
  containerStyle,
  onToggle,
  enable = true,
  rounded,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseOver, setMouseOver] = useState(false);
  const [sIsInterest, setIsInterest] = useState(false);
  const [sIsRounded, setIsRounded] = useState(false);
  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hRpc = useRpc();
  const hUserDetailMineState = useUserDetailMineState();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    defaultIsFavorite && setIsInterest(defaultIsFavorite);
    rounded && setIsRounded(true);
    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <FlexColumnDiv
      style={{ ...containerStyle }}
      onMouseEnter={() => {
        !ScreenInfo.isMobile() && setMouseOver(true);
      }}
      onMouseLeave={() => {
        !ScreenInfo.isMobile() && setMouseOver(false);
      }}
      onClick={(e) => {
        if (!enable) return;

        const orgIsInterest = sIsInterest;
        const newInterest = !orgIsInterest;
        setIsInterest(newInterest);

        hRpc.cobaltRpc
          .rpcMpSaleInteres({ sale_id: saleId })
          .then((res) => {
            DBGMSG('interest rpc suc');
            onToggle && onToggle(newInterest);
            hUserDetailMineState.act.changeState({ menuCnt: { needUpdate: true } });
          })
          .catch((e) => {
            // 원래대로 복귀
            //todo
            setIsInterest(orgIsInterest);
            DBGMSG('interest rpc fail');
            const rpcErrInfo = getRpcErrorInfo(e);
            if (rpcErrInfo) {
            }
          });

        e.stopPropagation();
      }}
    >
      {enable ? (
        sIsRounded ? (
          <ImgBtn
            src={[
              // sMouseOver || sIsFavorite ? R.images.common_btn_bookmark_abled3x : R.images.common_btn_bookmark_disabled3x,
              sIsInterest ? R.images.common_btn_bookmark_abled3x : R.images.common_btn_bookmark_disabled3x,
              width,
              height,
            ]}
            hoverSrc={sIsInterest ? undefined : R.images.common_btn_bookmark_hover3x}
          />
        ) : (
          <ImgBtn
            src={[
              // sMouseOver || sIsFavorite ? R.images.common_btn_bookmark_abled3x : R.images.common_btn_bookmark_disabled3x,
              sIsInterest ? R.images.asset_ic_bookmark_abled3x : R.images.asset_ic_bookmark_disabled3x,
              width,
              height,
            ]}
            containerStyle={{ borderWidth: 1, borderStyle: 'solid', borderColor: R.colors.line, borderRadius: '5px' }}
            // hoverSrc={sIsInterest ? undefined : R.images.common_btn_bookmark_hover3x}
          />
        )
      ) : (sIsRounded ? (
        <Img src={[R.images.common_btn_bookmark_disabled3x, width, height]} />
      ) : (
        <Img
          src={[R.images.asset_ic_bookmark_disabled3x, width, height]}
          containerStyle={{ borderWidth: 1, borderStyle: 'solid', borderColor: R.colors.line, borderRadius: '5px' }}
        />
      ))}
    </FlexColumnDiv>
  );
}
