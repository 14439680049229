/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ReactChild, ReactChildren, ReactFragment, ReactPortal, useEffect } from 'react';
import { config, useSpring } from 'react-spring';
import { AnimatedFlexColumnActiveDiv, devOutlineF } from 'src/ui/base_component/etc';
import { DBGMSG } from 'src/util/utils';
import { CSSProperties } from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

type Props = {
  containerStyle?: CSSProperties;
  isAnim?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseDefalutProp?: CSSProperties;
  onMouseEnterProp?: CSSProperties;
  onMouseLeaveProp?: CSSProperties;
  children?: ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
};

/**************************************
 * !! constant
 **************************************/
/**************************************
 * !! styled component
 **************************************/

export function AFlexColumnDiv({ containerStyle, isAnim = false, onClick, onMouseDefalutProp, onMouseEnterProp, onMouseLeaveProp, children }: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    console.debug('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // console.debug('useEffect() release');
    };
  }, []);

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return {
      backgroundColor: 'white',
      marginTop: 0,
      boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
      // ...onMouseDefalutProp,
      config: { ...config.gentle, duration: 0 },
    };
  });

  // console.debug('render');
  return (
    <AnimatedFlexColumnActiveDiv
      style={{
        cursor: onClick ? 'pointer' : 'inherit',
        ...containerStyle,
        ...hMouseProps,
        ...devOutline,
      }}
      onMouseEnter={() => {
        // DBGMSG(`onMouseEnter: `);
        // set({ backgroundColor: R.colors.paleGrey, transform: 'scale(1.005)' });
        isAnim &&
          set({
            backgroundColor: 'white',
            // marginTop: -1,
            // marginTop: 0,
            boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.20)`,
            // ...onMouseEnterProp,
          });
      }}
      onMouseLeave={() => {
        // DBGMSG(`onMouseLeave: `);
        isAnim &&
          set({
            backgroundColor: 'white',
            marginTop: 0,
            boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
            // ...onMouseLeaveProp,
          });
      }}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClick && onClick(e);
      }}
    >
      {children}
    </AnimatedFlexColumnActiveDiv>
  );
}
