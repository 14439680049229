/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useFileCheck } from 'src/hooks/useFileCheck';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { RPC_ASSET_CREATOR_COLLECTION_EDIT_VIEW } from 'src/model/rpcType';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { SCollectionImgSettingsBtn } from 'src/ui/common_component/button/SCollectionImgSettingsBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { SRoundInputTextArea } from 'src/ui/common_component/SRoundInputTextArea';
import { SRoundInputWithBtn } from 'src/ui/common_component/SRoundInputWithBtn';
import { Title } from 'src/ui/common_component/Title';
import { CLAY, CommonColumnBox } from 'src/ui/layout_constant';
import { MYCOLC_LAY } from 'src/ui/screen/AssetCreator/MyCollectionCScreen';
import { Nav } from 'src/ui/screen/Nav';
import { fileUpload } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MyCollectionUScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sData, setData] = useState<RPC_ASSET_CREATOR_COLLECTION_EDIT_VIEW.Rx>();
  const [sCollectionImg, setCollectionImg] = useState<{ src: string; uploadedHash: string }>(); // 컬렉션 이미지
  const [sDisplayName, setDisplayName] = useState<string>(''); // 디스플레이 이름
  const [sWebsiteUrl, setWebsiteUrl] = useState<string>(''); // 웹사이트 URL
  const [sDescription, setDescription] = useState<string>(''); // 설명
  const [sAdminList, setAdminList] = useState<string[]>([]); // 관리자 계정

  const [sUpdateBtnEnabled, setUpdateBtnEnabled] = useState(true);

  const [sShowColImgTooltip, setShowColImgTooltip] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const collectionImgInputRef = useRef<HTMLInputElement>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hFileChecker = useFileCheck();
  const hDlgFactory = useDlgFactory();
  const hStorage = useLocalStorage();
  // useCheckUserCertTpAndBlock();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { cid?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.cid) {
      DBGMSG(`$$$$QS id: ${query.cid}`);
      const collectionId = parseInt(query.cid);
      fetchData(collectionId);
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    if (sDisplayName.length > 0 && sCollectionImg?.src && sCollectionImg?.uploadedHash) {
      if (
        sDisplayName !== sData?.col_info.dp_name ||
        sCollectionImg?.uploadedHash !== Utils.ipfs.getIpfsHash(sData?.col_info.dp_img_url) ||
        sWebsiteUrl !== sData?.col_info.svc_url ||
        sDescription !== sData?.col_info.desc ||
        sAdminList !== sData?.col_info.admin_list
        //
      ) {
        // create 버튼 활성화 체크
        setUpdateBtnEnabled(true);
      } else {
        setUpdateBtnEnabled(false);
      }
    } else {
      setUpdateBtnEnabled(false);
    }
  }, [sDisplayName, sCollectionImg, sWebsiteUrl, sAdminList, sDescription]);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async (collectionId: number) => {
    const res = await hRpc.cobaltRpc.rpcAssetCreatorCollectionEditView(collectionId);
    setData(res);

    setCollectionImg({ src: res.col_info.dp_img_url, uploadedHash: Utils.ipfs.getIpfsHash(res.col_info.dp_img_url) });
    setDisplayName(res.col_info.dp_name);
    setWebsiteUrl(res.col_info.svc_url ?? '');
    setDescription(res.col_info.desc ?? '');
    setAdminList(res.col_info.admin_list);
  };

  const isOwner = useCallback(
    (ucode: string) => {
      return hAuth.authCommonRes?.ucode === ucode;
    },
    [hAuth.authCommonRes?.ucode]
  );

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  if (!sData) return null;

  const commonRender = () => {
    return (
      <>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: ScreenInfo.contentsWidth,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          {/* Back 버튼 */}
          <BackBtn />

          {/* 타이틀 */}
          <Title title={`${hR.strings.AC_COLLECTION_UPDATE_TITLE} - ${sData.col_info.dp_name}`} />

          <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

          {/* 박스 */}
          <CommonColumnBox style={{ width: MYCOLC_LAY.BOX_WIDTH, alignItems: 'flex-start' }}>
            <HEIGHT size={MYCOLC_LAY.COL_IMG_VPADDING} />

            {/* 컬렉션 이미지 */}
            <FlexColumnDiv
              style={{
                alignSelf: 'center',
                // ...devOutlineF,
              }}
            >
              <FlexRowDiv>
                <TextCon size={14} color={R.colors.black} isBold text={hR.strings.AC_COLLECTION_CREATE_IMAGE} />
                <TextCon size={14} color={R.colors.purpleishPink} text={'*'} />
                <WIDTH size={3} />
                <TextCon
                  size={14}
                  color={sShowColImgTooltip ? R.colors.purpleishBlue : R.colors.cloudyBlue}
                  text={hR.strings.AC_COLLECTION_CREATE_IMAGE_UPLOAD_SPEC}
                />
                <WIDTH size={3} />
                <TooltipImg
                  onShow={(isShow) => {
                    setShowColImgTooltip(isShow);
                  }}
                  placement={'right-start'}
                  tooltipString={hR.strings.AC_COLLECTION_CREATE_COL_IMG_TOOLTIP}
                />
              </FlexRowDiv>
              <HEIGHT size={24} />
              <SCollectionImgSettingsBtn
                size={MYCOLC_LAY.COL_IMG_SIZE}
                img={sCollectionImg?.src}
                onClick={(e) => {
                  collectionImgInputRef.current?.click();
                  DBGMSG(collectionImgInputRef.current);
                }}
              />
              <input
                ref={collectionImgInputRef}
                type={'file'}
                style={{ display: 'none' }}
                accept={Constants.NFT_IMG_ACCEPT_FILETYPE.map((ext) => '.' + ext).join(', ')}
                onChange={async (e) => {
                  const { localUrl, fileObj, type } = await Utils.file.loadInputFile(e);
                  DBGMSG(`$$$$INPUT  localUrl: ${localUrl}`);
                  DBGMSG(`$$$$INPUT file.name: ${fileObj.name}`);
                  DBGMSG(`$$$$INPUT file.size: ${fileObj.size.toLocaleString()}`);
                  DBGMSG(`$$$$INPUT file.type: ${fileObj.type}`);
                  DBGMSG(`$$$$INPUT      type: ${type}`);

                  let result = await hFileChecker.checkFileSpec({
                    target: { localUrl, fileObj, fileType: type },
                    spec: { imgWidth: 200, imgHeight: 200, sizeInMB: 2, fileTypeList: ['png', 'jpg', 'gif', 'apng'] },
                  });

                  if (result) {
                    fileUpload(fileObj)
                      .then((res) => {
                        DBGMSG(res);
                        setCollectionImg({ src: localUrl, uploadedHash: res });
                      })
                      .catch((e) => {
                        DBGMSGW(e);
                      });
                  } else {
                    if (collectionImgInputRef.current) collectionImgInputRef.current.value = '';
                  }
                }}
              />
            </FlexColumnDiv>

            <HEIGHT size={MYCOLC_LAY.COL_IMG_VPADDING} />

            <FlexRowDiv style={{ width: MYCOLC_LAY.BOX_WIDTH, flexWrap: 'wrap' }}>
              <FlexColumnDiv
                style={{
                  width: MYCOLC_LAY.COLUMN_WIDTH,
                  paddingLeft: CLAY.BOX_PADDING,
                  paddingRight: CLAY.BOX_PADDING,
                  // ...devOutlineF,
                }}
              >
                {/* 컬렉션 이름 */}
                <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row' }}>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_NAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <FlexRowDiv>
                    <TextCon text={hR.strings.AC_COLLECTION_CREATE_NAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                    <WIDTH size={3} />
                    <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                  </FlexRowDiv>
                </FlexDiv>

                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sData.col_info.name}
                  placeHolder={hR.strings.AC_COLLECTION_CREATE_CANNOT_EDIT}
                  disabled={true}
                />
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 디스플레이 이름 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_DISPNAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_DISPNAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                  <WIDTH size={5} />
                  <TooltipImg placement={'right-start'} tooltipString={hR.strings.AC_COLLECTION_CREATE_TOOLTIP_DISPNAME} />{' '}
                </FlexRowDiv>

                <HEIGHT size={14} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sDisplayName}
                  onChange={(e) => {
                    if (e.target.value.length <= 20) {
                      setDisplayName(e.target.value);
                    }
                  }}
                />
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 인세 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_ROYALTY} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <TextCon
                    text={`${Utils.string.parsePlaceholder(hR.strings.AC_COLLECTION_CREATE_ROYALTY_GUIDE, '{MAX}').begin}${
                      hStorage.getData().max_royalty
                    }${Utils.string.parsePlaceholder(hR.strings.AC_COLLECTION_CREATE_ROYALTY_GUIDE, '{MAX}').end}`}
                    size={CLAY.FORM_FONT_SIZE}
                    color={R.colors.black}
                  />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                  <WIDTH size={5} />
                  <TooltipImg placement={'right-start'} tooltipString={hR.strings.AC_COLLECTION_CREATE_TOOLTIP_ROYALTY} />
                </FlexRowDiv>

                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sData.col_info.royalty.toString()}
                  placeHolder={hR.strings.AC_COLLECTION_CREATE_CANNOT_EDIT}
                  disabled={true}
                />
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 웹 사이트 URL */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_WEBSITE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                </FlexRowDiv>

                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sWebsiteUrl}
                  onChange={(e) => {
                    if (e.target.value.length > 100) return;
                    setWebsiteUrl(e.target.value);
                  }}
                />
              </FlexColumnDiv>

              <FlexColumnDiv
                style={{
                  width: MYCOLC_LAY.COLUMN_WIDTH,
                  paddingLeft: CLAY.BOX_PADDING,
                  paddingRight: CLAY.BOX_PADDING,
                  // ...devOutlineF,
                }}
              >
                {ScreenInfo.isMobile() && <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />}

                {/* 설명 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_DESC} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                </FlexRowDiv>
                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputTextArea
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  value={sDescription}
                  minHeight={110}
                  onChange={(e) => {
                    DBGMSG(e.target.value);
                    setDescription(e.target.value);
                  }}
                />
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 관리자 설정 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_ADMIN} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                </FlexRowDiv>
                <HEIGHT size={14} />
                <SRoundInputWithBtn
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  defaultValue={hAuth.authState.authCommonRes?.ucode}
                  btnType={isOwner(sData.col_info.creator.ucode) ? '+' : undefined}
                  onBtnClick={() => {
                    setAdminList([...sAdminList, '']);
                  }}
                  disabled={!isOwner(sData.col_info.creator.ucode)}
                />
                {sAdminList.map((owner, idx) => {
                  return (
                    <>
                      <HEIGHT size={10} />
                      <SRoundInputWithBtn
                        fontSize={CLAY.FORM_FONT_SIZE}
                        width={CLAY.FORM_SIZE.WIDTH}
                        height={CLAY.FORM_SIZE.HEIGHT}
                        btnType={'-'}
                        defaultValue={sAdminList[idx]}
                        onBtnClick={() => {
                          DBGMSG(`idx:${idx}`);
                          DBGMSG([...sAdminList.slice(0, idx)]);
                          DBGMSG([...sAdminList.slice(idx + 1)]);
                          setAdminList([...sAdminList.slice(0, idx), ...sAdminList.slice(idx + 1)]);
                        }}
                        onChange={(e) => {
                          setAdminList([...sAdminList.slice(0, idx), e.target.value, ...sAdminList.slice(idx + 1)]);
                          // setCollectionName(e.target.value);
                        }}
                      />
                    </>
                  );
                })}
              </FlexColumnDiv>
            </FlexRowDiv>

            <HEIGHT size={CLAY.TEXT_BTN_VMARGIN_IN_BOX} />

            {/* Collection 편집 버튼 */}
            <RoundTxtBtn
              width={CLAY.TEXT_BTN_SIZE_IN_BOX.WIDTH}
              height={CLAY.TEXT_BTN_SIZE_IN_BOX.HEIGHT}
              fontSize={CLAY.TEXT_BTN_FONT_SIZE_IN_BOX}
              enable={sUpdateBtnEnabled}
              text={hR.strings.AC_COLLECTION_UPDATE_BTN}
              containerStyle={{ alignSelf: 'center' }}
              onClick={async () => {
                DBGMSG('edit');

                try {
                  await hRpc.cobaltRpc.rpcAssetCreatorCollectionEdit({
                    cid: sData.col_info.cid,
                    dp_img_url: sCollectionImg?.uploadedHash,
                    dp_name: sDisplayName,
                    svc_url: sWebsiteUrl,
                    desc: sDescription,
                    admin_list: sAdminList,
                    // TODO 관리자 리스트 변경됬는지 체크
                  });
                  hDlgFactory.showAsyncGuideJsx({
                    title: hR.strings.DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TITLE,
                    text: hR.strings.DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TXT,
                    onOk: () => {
                      hDlgFactory.closeAsyncGuideJsx();
                      hHistory.replace(Nav.AssetCreatorScreen);
                    },
                  });
                } catch (e) {}
              }}
            />
            <HEIGHT size={CLAY.TEXT_BTN_VMARGIN_IN_BOX} />
          </CommonColumnBox>
          <HEIGHT size={MYCOLC_LAY.BOTTOM_MARGIN} />
        </FlexColumnDiv>
        {/* 공통푸터 */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    return commonRender();
  } else {
    return <MobileScalableFlexColumn>{commonRender()}</MobileScalableFlexColumn>;
  }
}
