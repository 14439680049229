/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/****************************************/
// state
/****************************************/
export type ColStateModule_t = {
  needUpdate: boolean;
  col_list?: {
    cid: number;
    cert_tp: number;
    dp_name: string;
  }[];
};

const colListStateModuleInitState: ColStateModule_t = {
  needUpdate: true,
};

const colListSlice = createSlice({
  name: 'colListState',
  initialState: colListStateModuleInitState,
  reducers: {
    clear: (state) => {
      state = { ...colListStateModuleInitState };
      return state;
    },
    setState: (state, action: PayloadAction<ColStateModule_t>) => {
      state = { ...state, ...action.payload, needUpdate: false };
      return state;
    },
    changeState: (state, action: PayloadAction<Partial<ColStateModule_t>>) => {
      state = { ...state, ...action.payload, needUpdate: false };
      return state;
    },
  },
});

export default colListSlice;
