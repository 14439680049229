/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect } from 'react';
import { useEffectOnce, useLocation } from 'react-use';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { DisplaySrc_t } from 'src/model/model';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { AssetDisplayOptattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayOptattr';
import { AssetDisplayReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayReqattr';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  key?: string;
  playerSrc: DisplaySrc_t;
  width?: number;
  height?: number;
  isBorder?: boolean;
  onClick?: () => void;
  scaleIfAudio?: number;
  noFullscreenIfImage?: boolean;
  onImgFullscreen?: (src: string) => void;
  isBlock?: boolean;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DEFAULT_WIDTH = 502;
const DEAFULT_HEIGHT = 502;

/**************************************
 * !! styled component
 **************************************/

export function AssetDisplay({
  //
  key,
  playerSrc,
  width = DEFAULT_WIDTH,
  height = DEAFULT_HEIGHT,
  onClick,
  scaleIfAudio = 1,
  noFullscreenIfImage = false,
  onImgFullscreen,
  isBlock = false,
}: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hLocation = useLocation();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    return () => {};
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const renderAssetDisplay = () => {
    switch (playerSrc.kind) {
      case 'ReqImage':
      case 'ReqAudio':
      case 'ReqVideo':
        return (
          <AssetDisplayReqattr
            playerSrc={playerSrc}
            width={width}
            height={height}
            // 오디오일경우에는 padding이 생기도록 함
            scaleIfAudio={scaleIfAudio}
            disableFullScreenImg={noFullscreenIfImage}
            onImgFullscreen={onImgFullscreen}
          />
        );
      case 'OptImage':
      case 'OptAudio':
      case 'OptVideo':
        return (
          <AssetDisplayOptattr
            //
            playerSrc={playerSrc}
            width={width}
            height={height}
          />
        );
    }
  };

  return (
    <FlexColumnDiv
      style={{
        width: width,
        height: height,
        justifyContent: 'center',
        alignItems: 'center',

        backgroundColor: 'white',
        flex: '0 0 auto',
        overflow: 'hidden',
        cursor: onClick ? 'pointer' : 'inherit',
      }}
      onClick={onClick}
    >
      <FlexDiv style={{ filter: isBlock ? 'blur(3px)' : undefined }}>{renderAssetDisplay()}</FlexDiv>
      {(Constants.forceBlock || isBlock) && (
        <FlexColumnDiv
          style={{
            //
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          }}
        >
          <Img src={[R.images.common_ic_warning3x, 20, 20]} />
          <HEIGHT size={6} />
          <TextCon text={hR.strings.MARKET_ASSET_CARD_BLOCK} color={R.colors.black} size={14} textStyle={{ textAlign: 'center' }} isBold />
          <HEIGHT size={16} />
          <TextCon
            text={hR.strings.COMMON_INQUERY}
            color={R.colors.purpleishBlue}
            size={14}
            textStyle={{ textAlign: 'center' }}
            isBold
            isUnderline
            onConClick={() => {
              window.location.href = `mailto:${Constants.SUPPORT_EMAIL_ADDRESS}`;
            }}
          />
        </FlexColumnDiv>
      )}
    </FlexColumnDiv>
  );
}
