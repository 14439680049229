import { combineReducers } from 'redux';
import authModule from 'src/redux/modules/authModule';
import myMemoSlice from 'src/redux/modules/myMemoModule';
import rpcModule from 'src/redux/modules/rpcModule';
import dialogSlice from 'src/redux/modules/dlgModule';
import localSettingsSlice from 'src/redux/modules/localSettingsModule';
import marketPlaceSlice from 'src/redux/modules/marketPlaceStateModule';
import userDetailSlice from 'src/redux/modules/userDetailModule';
import userDetailMineSlice from 'src/redux/modules/userDetailMineModule';
import kaikasSlice from 'src/redux/modules/kaikasModule';
import explorerSlice from 'src/redux/modules/explorerStateModule';
import colListSlice from 'src/redux/modules/colStateModule';
import marketColSlice from 'src/redux/modules/marketColModule';
import marketSchmSlice from 'src/redux/modules/marketSchmModule';

const rootReducer = combineReducers({
  // state name : reducer
  rpcState: rpcModule.reducer,
  myMemoState: myMemoSlice.reducer,
  authState: authModule.reducer,
  resourceState: localSettingsSlice.reducer,
  dialogState: dialogSlice.reducer,
  explorerState: explorerSlice.reducer,
  marketPlaceState: marketPlaceSlice.reducer,
  userDetailState: userDetailSlice.reducer,
  userDetailMineState: userDetailMineSlice.reducer,
  kaikasState: kaikasSlice.reducer,
  colListState: colListSlice.reducer,
  marketColState: marketColSlice.reducer,
  marketSchmState: marketSchmSlice.reducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
