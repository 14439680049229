/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF, FlexRowDiv, WIDTH } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { UserThumb } from 'src/ui/common_component/UserThumb';
import { DBGMSG } from 'src/util/utils';
import { CSSProperties } from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

//##########################################################################################################
// UserThumbName
//##########################################################################################################
const DT_CIRCLE_SIZE = 34;
const DT_FONTSIZE = 16;
const DT_ID_FONTSIZE = 14;
const DT_ID_MARGIN_BOTTOM = 2;
const DT_BETWEEN_SPACE = 6;

const MB_CIRCLE_SIZE = 28;
const MB_FONTSIZE = 12;
const MB_ID_FONTSIZE = 12;
const MB_ID_MARGIN_BOTTOM = 2;
const MB_BETWEEN_SPACE = 4;

const CIRCLE_SIZE = ScreenInfo.isMobile() ? MB_CIRCLE_SIZE : DT_CIRCLE_SIZE;
const FONTSIZE = ScreenInfo.isMobile() ? MB_FONTSIZE : DT_FONTSIZE;
const ID_FONTSIZE = ScreenInfo.isMobile() ? MB_ID_FONTSIZE : DT_ID_FONTSIZE;
const ID_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_ID_MARGIN_BOTTOM : DT_ID_MARGIN_BOTTOM;
const BETWEEN_SPACE = ScreenInfo.isMobile() ? MB_BETWEEN_SPACE : DT_BETWEEN_SPACE;

type Props = {
  thumb: string | null;
  kind?: 'OWNER' | 'CREATOR' | 'SELLER' | 'BUYER';
  userId: string;
  onClick?: () => void;
  containerStyle?: CSSProperties;
  isBold?: boolean;
};

export function UserThumbName({ thumb, kind, userId, onClick, containerStyle, isBold = true }: Props) {
  const hR = useLocalSettings();
  return (
    <FlexRowDiv
      style={{
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'inherit',
        ...containerStyle,
        ...devOutline,
      }}
      onClick={() => {
        DBGMSG(`onClick ${userId}`);
        onClick && onClick();
      }}
    >
      <UserThumb userThumb={thumb} userId={userId} size={CIRCLE_SIZE} fontSize={FONTSIZE} />
      <WIDTH size={BETWEEN_SPACE} />
      {kind && kind === 'OWNER' && (
        <>
          <TextCon size={ID_FONTSIZE} color={R.colors.black} text={hR.strings.COMMON_OWNER} containerStyle={{ marginBottom: ID_MARGIN_BOTTOM }} />
          <WIDTH size={BETWEEN_SPACE} />
        </>
      )}
      {kind && kind === 'CREATOR' && (
        <>
          <TextCon size={ID_FONTSIZE} color={R.colors.black} text={hR.strings.COMMON_CREATOR} containerStyle={{ marginBottom: ID_MARGIN_BOTTOM }} />
          <WIDTH size={BETWEEN_SPACE} />
        </>
      )}
      {kind && kind === 'SELLER' && (
        <>
          <TextCon size={ID_FONTSIZE} color={R.colors.black} text={hR.strings.COMMON_SELLER} containerStyle={{ marginBottom: ID_MARGIN_BOTTOM }} />
          <WIDTH size={BETWEEN_SPACE} />
        </>
      )}
      {kind && kind === 'BUYER' && (
        <>
          <TextCon size={ID_FONTSIZE} color={R.colors.black} text={hR.strings.COMMON_BUYER} containerStyle={{ marginBottom: ID_MARGIN_BOTTOM }} />
          <WIDTH size={BETWEEN_SPACE} />
        </>
      )}
      <TextCon size={ID_FONTSIZE} color={R.colors.purpleishBlue} isBold={isBold} text={userId} containerStyle={{ marginBottom: ID_MARGIN_BOTTOM }} />
    </FlexRowDiv>
  );
}
