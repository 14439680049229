/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RPC_MP_SALE_LIST } from 'src/model/rpcType';
import { RootState } from 'src/redux/modules';
import marketPlaceSlice, { MarketPlaceState_t } from 'src/redux/modules/marketPlaceStateModule';
import { DBGMSGW, Utils } from 'src/util/utils';

export function useMarketPlaceState() {
  const marketPlaceState = useSelector((state: RootState) => state.marketPlaceState);
  const dispatch = useDispatch();

  const setMarketPlaceState = useCallback(
    (args: MarketPlaceState_t) => {
      dispatch(marketPlaceSlice.actions.setState(args));
    },
    [dispatch]
  );
  const changeMarketPlaceState = useCallback(
    (args: Partial<MarketPlaceState_t>) => {
      dispatch(marketPlaceSlice.actions.changeState({ ...args }));
    },
    [dispatch]
  );
  const clearMarketPlaceState = useCallback(() => {
    dispatch(marketPlaceSlice.actions.clear());
  }, [dispatch]);

  // 인벤토리 상태 AST 특정 카드의 판매정보 변경
  const changeMarketPlaceInfo = useCallback(
    (target: { sale_id: number }, newInfo: { favorite: boolean }) => {
      if (marketPlaceState.resultSale_list.length === 0) {
        DBGMSGW(`marketPlaceState.resultSale_list.length === 0`);
        return;
      }

      const foundIdx = marketPlaceState.resultSale_list.findIndex((ast) => ast.sale_id === target.sale_id);

      if (foundIdx === -1) {
        DBGMSGW(`foundIdx === -1`);
        return;
      }

      const newList = Utils.deepCopy<RPC_MP_SALE_LIST.Rx['sale_list']>(marketPlaceState.resultSale_list);
      // 판매정보 업데이트
      newList[foundIdx].favorite = newInfo.favorite;

      changeMarketPlaceState({
        resultSale_list: newList,
      });
    },
    [marketPlaceState.resultSale_list]
  );

  return {
    changeMarketPlaceInfo,
    act: {
      setMarketPlaceState,
      changeMarketPlaceState,
      clearMarketPlaceState,
    },
    state: marketPlaceState,
  };
}
