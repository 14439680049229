/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { MarketAssetRead_i, DisplaySrc_t } from 'src/model/model';
import { CbtAssetType_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, FlexRowDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  playerSrc: DisplaySrc_t;
  width?: number;
  height?: number;
  scaleIfAudio?: number;
  // isAnim?: boolean;
  onClick?: () => void;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! constant
 **************************************/
const WIDTH = 276;
const HEIGHT = 276;

/**************************************
 * !! styled component
 **************************************/

export function AssetDisplayThumbOptattr({ playerSrc, width = WIDTH, height = HEIGHT, scaleIfAudio = 1 }: Props) {
  /**************************************
   * !! state
   **************************************/
  // const [sIsPlay, setIsPlay] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const render = () => {
    let img: string | undefined;
    let src: string | undefined;
    let assetType: CbtAssetType_e | undefined;

    switch (playerSrc.kind) {
      case 'OptImage':
        assetType = CbtAssetType_e.IMAGE;
        img = playerSrc.img;
        break;
      case 'OptAudio':
        assetType = CbtAssetType_e.AUDIO;
        src = playerSrc.audio;
        break;
      case 'OptVideo':
        assetType = CbtAssetType_e.VIDEO;
        src = playerSrc.video;
        break;
    }

    if (!assetType) return null;

    switch (assetType) {
      case CbtAssetType_e.IMAGE:
        if (!img) return null;
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            <Img
              src={[img, undefined, undefined]}
              containerStyle={{
                flex: '0 0 auto',
                // ...devOutlineF,
              }}
              imgStyle={{
                width: width,
                height: height,
                overflow: 'hidden',
                objectFit: 'contain',
                // ...devOutlineF,
              }}
              onClick={() => {}}
            />
          </FlexRowDiv>
        );

      case CbtAssetType_e.VIDEO:
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              alignItems: 'center',
              justifyContent: 'center',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            <Img
              src={[R.images.common_ic_video_asset3x, 40, 40]}
              containerStyle={{
                flex: '0 0 auto',
                // ...devOutlineF,
              }}
            />
          </FlexRowDiv>
        );

      case CbtAssetType_e.AUDIO:
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              alignItems: 'center',
              justifyContent: 'center',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            <Img
              src={[R.images.common_ic_audio_asset3x, 40, 40]}
              containerStyle={{
                flex: '0 0 auto',
                // ...devOutlineF,
              }}
            />
          </FlexRowDiv>
        );
    }
  };

  return render();
}
