/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Nav } from 'src/ui/screen/Nav';

export function ScrollToTop() {
  const hLocation = useLocation();

  useEffect(() => {
    // switch (hLocation.pathname) {
    //   case Nav.ROOT:
    //   case Nav.HomeScreen:
    //   case Nav.ExplorerScreeen:
    //   case Nav.AssetCreatorScreen:
    //   case Nav.MarketPlaceScreen:
    //   case Nav.MyCollectionCScreen:
    //   case Nav.MyCollectionRScreen:
    //   case Nav.MyCollectionUScreen:
    //   case Nav.MySchemaCScreen:
    //   case Nav.MySchemaRScreen:
    //   case Nav.MySchemaUScreen:
    //   case Nav.MyAssetGroupCScreen:
    //   case Nav.MyAssetGroupListScreen:
    //   case Nav.MyIssueAssetRScreen:
    //   case Nav.MarketAssetRScreen:
    //   case Nav.MarketCollectionRScreen:
    //   case Nav.MarketSchemaRScreen:
    //   case Nav.MarketPaymentScreen:
    //   case Nav.UserLoginScreen:
    //   case Nav.UserJoinScreen:
    //   case Nav.UserChangeFishCodeScreen:
    //   case Nav.UserChangePasswordScreen:
    //   case Nav.UserResetPasswordScreen:
    //   case Nav.UserBan:
    //   case Nav.NotiListScreen:
    //   case Nav.UserTermsOfUseScreen:
    //   case Nav.UserPrivacyPolicyScreen:
    //   case Nav.UserRefundPolicyScreen:
    //   case Nav.AssetmgrAssetRScreen:
    //   case Nav.UserDetailScreen:
    //   case Nav.UserInventoryAstgDetailScreen:
    //   case Nav.UserIssueAstgDetailScreen:
    //   case Nav.UserDetailMineScreen:
    //   case Nav.MyInventoryAstgDetailScreen:
    //   case Nav.MyIssueAstgDetailScreen:
    //   case Nav.MyPropertyHistoryScreen:
    window.scrollTo(0, 0);
    // break;
    // }

    return () => {};
  }, [hLocation.pathname]);

  return null;
}
