/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useState } from 'react';
import { devOutlineF } from 'src/ui/base_component/etc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { RoundTxtBtn4 } from 'src/ui/common_component/button/RoundTxtBtn4';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  onClick: () => void;
};

export function KaikasBtn({ onClick }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseOver, setMouseOver] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <RoundTxtBtn4
      text={hR.strings.HEADER_KAIKAS_WALLET_CONNECT}
      width={140}
      height={40}
      fontSize={12}
      onClick={() => {
        onClick();
      }}
    />
  );
}
