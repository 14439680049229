/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { AppConf } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { ClientType_e } from 'src/hooks/useScreenWidthHelper';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtn2 } from 'src/ui/common_component/button/RoundTxtBtn2';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {};

export function DevInfo(props: Props) {
  const renderClientInfo = () => {
    const rdd = Utils.device.reactDeviceDetect;
    // desktop
    if (Utils.device.isDesktop || Utils.device.isTablet) {
      return (
        <FlexColumnDiv style={{ width: 250 }}>
          <TextCon text={`device: ${rdd.deviceType}`} size={10} isBold />
          <TextCon text={`os: ${rdd.osName} ${rdd.osVersion}`} size={10} isBold />
          <TextCon text={`browser: ${rdd.browserName} ${rdd.browserVersion}`} size={10} isBold />
          <TextCon text={`phase: ${AppConf.PHASE}`} size={10} isBold />
          <TextCon text={`version: ${AppConf.VERSION} (${AppConf.VERSION_CODE})`} size={10} isBold />
          <TextCon text={`\nversion note`} size={10} isBold />
          <TextCon text={`${AppConf.VERSION_NOTE}`} size={10} isBold containerStyle={{ marginLeft: 10 }} />
        </FlexColumnDiv>
      );
    }
    // mobile
    else if (Utils.device.isMobile) {
      return (
        <>
          <TextCon text={'device: mobile'} size={12} isBold />
          <TextCon text={`os: ${rdd.osName} ${rdd.osVersion}`} size={12} isBold />
          <TextCon text={`browser: ${rdd.browserName} ${rdd.browserVersion}`} size={12} isBold />
          <TextCon text={`phase: ${AppConf.PHASE}`} size={12} isBold />
          <TextCon text={`version: ${AppConf.VERSION} (${AppConf.VERSION_CODE})`} size={12} isBold />
          <TextCon text={`\nversion note`} size={12} isBold />
          <TextCon text={`${AppConf.VERSION_NOTE}`} size={12} isBold />
        </>
      );
      // } else if (Utils.device.isTablet) {
      //   return (
      //     <>
      //       <TextCon text={'tablet'} size={12} isBold />
      //       <TextCon text={`${rdd.osName} ${rdd.osVersion}`} size={12} isBold />
      //       <TextCon text={`${rdd.browserName} ${rdd.browserVersion}`} size={12} isBold />
      //       <TextCon text={`${AppConf.PHASE}`} size={12} isBold />
      //       <TextCon text={`${AppConf.VERSION} (${AppConf.VERSION_CODE})`} size={12} isBold />
      //     </>
      //   );
    } else {
      return (
        <>
          <TextCon text={'else unknown'} size={12} isBold />
          <TextCon text={`${rdd.osName} ${rdd.osVersion}`} size={12} isBold />
          <TextCon text={`${rdd.browserName} ${rdd.browserVersion}`} size={12} isBold />
          <TextCon text={`${AppConf.PHASE}`} size={12} isBold />
          <TextCon text={`${AppConf.VERSION} (${AppConf.VERSION_CODE})`} size={12} isBold />
        </>
      );
    }
  };

  if (ScreenInfo.clientType === ClientType_e.Desktop || ScreenInfo.clientType === ClientType_e.Tablet) {
    return (
      <FlexColumnDiv
        style={{
          //
          position: 'absolute',
          top: 20,
          right: -270,
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: R.colors.devColor1a,
        }}
      >
        {/* <TextCon text={`window: ${ScreenInfo.windowInnerWidth} x ${ScreenInfo.windowInnerHeight}`} size={12} isBold /> */}
        {/* <TextCon text={`content: ${ScreenInfo.contentsWidth}`} size={12} isBold /> */}
        {renderClientInfo()}
      </FlexColumnDiv>
    );
  } else if (ScreenInfo.clientType === ClientType_e.Mobile) {
    return (
      <FlexColumnDiv
        style={{
          //
          position: 'absolute',
          bottom: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: R.colors.devColor1a,
        }}
      >
        <TextCon text={`window: ${ScreenInfo.windowInnerWidth} x ${ScreenInfo.windowInnerHeight}`} size={12} isBold />
        <TextCon text={`content: ${ScreenInfo.contentsWidth}`} size={12} isBold />
        <TextCon text={`window.innerWidth: ${window.innerWidth}`} size={12} isBold />
        {renderClientInfo()}
      </FlexColumnDiv>
    );
  } else {
    return null;
  }
}
