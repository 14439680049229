/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isAlpha, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { CbtCurrencyInfo_i, CbtCurrency_e, CbtReqPriceInfo_i } from 'src/model/rpcModel';
import { RPC_MP_SALE_ADD_VIEW, RPC_MP_SALE_EDIT_VIEW } from 'src/model/rpcType';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { PriceWithCurrencyInput } from 'src/ui/common_component/PriceWithCurrencyInput';
import { CommonColumnBox, CommonLightGrayBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

/**************************************
 * !! constant
 **************************************/
// DESKTOP
const DT_DLG_WIDTH = 888;
const DT_HPADDING = 25;
const DT_BODY_WIDTH = DT_DLG_WIDTH - DT_HPADDING * 2;
const DT_PADDING_TOP = 30;
const DT_PADDING_BOTTOM = 40 - 15;
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;
const DT_TITLE_HEIGHT = 33;
const DT_TITLE_FOTNSIZE = 22;
const DT_TITLE_MARGIN_BOTTOM = 40 - 15;
const DT_ASSET_DISPLAY_BORDER_BOX = 276;
const DT_ASSET_DISPLAY_BORDER_BOX_MARGIN = 15;
const DT_ASSET_DISPLAY_SIZE = 236;
const DT_LEFT_CONTAINER_WIDTH = DT_ASSET_DISPLAY_BORDER_BOX + DT_ASSET_DISPLAY_BORDER_BOX_MARGIN * 2;
const DT_LEFT_CONTAINER_HEIGHT = DT_ASSET_DISPLAY_BORDER_BOX + DT_ASSET_DISPLAY_BORDER_BOX_MARGIN * 2;
const DT_FORM_FONT_SIZE = 14;
const DT_FORM_INPUT_WIDTH = 502;
const DT_FORM_INPUT_HEIGHT = 44;
const DT_FORM_INPUT_MARGIN_TOP = 14;
const DT_FORM_INPUT_HMARGIN = 15;
const DT_FORM_INPUT_BETWEEN = 30;
const DT_FEE_WIDTH = 335;
const DT_FEE_WIDTH_PADDING_LEFT = 14;
const DT_FEE_VALUE_WIDTH = 166;
const DT_FEE_VALUE_WIDTH_PADDING_LEFT = 13;
const DT_RIGHT_CONTAINER_WIDTH = DT_FORM_INPUT_WIDTH + DT_FORM_INPUT_HMARGIN * 2;
const DT_RIGHT_CONTAINER_HEIGHT = undefined;
const DT_START_BTN_WIDTH = 252;
const DT_STOP_BTN_WIDTH = 155;
const DT_EDIT_BTN_WIDTH = 335;
const DT_BTN_HEIGHT = 48;
const DT_BTN_FOTNSIZE = 14;
const DT_BTN_MARGIN_V = 40;
const DT_TOP_FLEX = 1;
const DT_BOTTOM_FLEX = 3;
const DT_CONTAINER_BETWEEN = 0;
const DT_KLAYTN_ADDRESS_REG_GUIDE_FONTSIZE = 14;
const DT_KLAYTN_ADDRESS_REG_GUIDE_MARGIN_BOTTOM = 26;
const DT_KLAYTN_ADDRESS_FONTSIZE = 14;
const DT_KLAYTN_ADDRESS_MARGIN_BOTTOM = 40;
const DT_MINIMUM_LIMIT_FONT_SIZE = 12;

// MOBILE
const MB_DLG_WIDTH = 280;
const MB_HPADDING = 12;
const MB_BODY_WIDTH = MB_DLG_WIDTH - MB_HPADDING * 2;
const MB_PADDING_TOP = 16;
const MB_PADDING_BOTTOM = 20;
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;
const MB_TITLE_HEIGHT = 20;
const MB_TITLE_FOTNSIZE = 14;
const MB_TITLE_MARGIN_BOTTOM = 16;
const MB_ASSET_DISPLAY_BORDER_BOX = 158;
const MB_ASSET_DISPLAY_BORDER_BOX_MARGIN = 0;
const MB_ASSET_DISPLAY_SIZE = 134;
const MB_LEFT_CONTAINER_WIDTH = 158;
const MB_LEFT_CONTAINER_HEIGHT = 158;
const MB_FORM_FONT_SIZE = 12;
const MB_FORM_INPUT_WIDTH = 256;
const MB_FORM_INPUT_HEIGHT = 38;
const MB_FORM_INPUT_MARGIN_TOP = 12;
const MB_FORM_INPUT_HMARGIN = 0;
const MB_FORM_INPUT_BETWEEN = 20;
const MB_FEE_WIDTH = 184;
const MB_FEE_WIDTH_PADDING_LEFT = 12;
const MB_FEE_VALUE_WIDTH = 71;
const MB_FEE_VALUE_WIDTH_PADDING_LEFT = 11;
const MB_RIGHT_CONTAINER_WIDTH = MB_FORM_INPUT_WIDTH;
const MB_RIGHT_CONTAINER_HEIGHT = undefined;
const MB_START_BTN_WIDTH = 256;
const MB_STOP_BTN_WIDTH = 124;
const MB_EDIT_BTN_WIDTH = 124;
const MB_BTN_HEIGHT = 38;
const MB_BTN_FOTNSIZE = 12;
const MB_BTN_MARGIN_V = 20;
const MB_TOP_FLEX = 1;
const MB_BOTTOM_FLEX = 1;
const MB_CONTAINER_BETWEEN = 16;
const MB_KLAYTN_ADDRESS_REG_GUIDE_FONTSIZE = 10;
const MB_KLAYTN_ADDRESS_REG_GUIDE_MARGIN_BOTTOM = 20;
const MB_KLAYTN_ADDRESS_FONTSIZE = 9;
const MB_KLAYTN_ADDRESS_MARGIN_BOTTOM = 20;
const MB_MINIMUM_LIMIT_FONT_SIZE = 10;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const HPADDING = ScreenInfo.isMobile() ? MB_HPADDING : DT_HPADDING;
const BODY_WIDTH = ScreenInfo.isMobile() ? MB_BODY_WIDTH : DT_BODY_WIDTH;
const PADDING_TOP = ScreenInfo.isMobile() ? MB_PADDING_TOP : DT_PADDING_TOP;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
const CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;
const TITLE_HEIGHT = ScreenInfo.isMobile() ? MB_TITLE_HEIGHT : DT_TITLE_HEIGHT;
const TITLE_FOTNSIZE = ScreenInfo.isMobile() ? MB_TITLE_FOTNSIZE : DT_TITLE_FOTNSIZE;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const ASSET_DISPLAY_BORDER_BOX = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX : DT_ASSET_DISPLAY_BORDER_BOX;
const ASSET_DISPLAY_BORDER_BOX_MARGIN = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX_MARGIN : DT_ASSET_DISPLAY_BORDER_BOX_MARGIN;
const ASSET_DISPLAY_SIZE = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_SIZE : DT_ASSET_DISPLAY_SIZE;
const LEFT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_WIDTH : DT_LEFT_CONTAINER_WIDTH;
const LEFT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_HEIGHT : DT_LEFT_CONTAINER_WIDTH;
const FORM_FONT_SIZE = ScreenInfo.isMobile() ? MB_FORM_FONT_SIZE : DT_FORM_FONT_SIZE;
const FORM_INPUT_WIDTH = ScreenInfo.isMobile() ? MB_FORM_INPUT_WIDTH : DT_FORM_INPUT_WIDTH;
const FORM_INPUT_HEIGHT = ScreenInfo.isMobile() ? MB_FORM_INPUT_HEIGHT : DT_FORM_INPUT_HEIGHT;
const FORM_INPUT_MARGIN_TOP = ScreenInfo.isMobile() ? MB_FORM_INPUT_MARGIN_TOP : DT_FORM_INPUT_MARGIN_TOP;
const FORM_INPUT_HMARGIN = ScreenInfo.isMobile() ? MB_FORM_INPUT_HMARGIN : DT_FORM_INPUT_HMARGIN;
const FORM_INPUT_BETWEEN = ScreenInfo.isMobile() ? MB_FORM_INPUT_BETWEEN : DT_FORM_INPUT_BETWEEN;
const FEE_WIDTH = ScreenInfo.isMobile() ? MB_FEE_WIDTH : DT_FEE_WIDTH;
const FEE_WIDTH_PADDING_LEFT = ScreenInfo.isMobile() ? MB_FEE_WIDTH_PADDING_LEFT : DT_FEE_WIDTH_PADDING_LEFT;
const FEE_VALUE_WIDTH = ScreenInfo.isMobile() ? MB_FEE_VALUE_WIDTH : DT_FEE_VALUE_WIDTH;
const FEE_VALUE_WIDTH_PADDING_LEFT = ScreenInfo.isMobile() ? MB_FEE_VALUE_WIDTH_PADDING_LEFT : DT_FEE_VALUE_WIDTH_PADDING_LEFT;

const RIGHT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_WIDTH : DT_RIGHT_CONTAINER_WIDTH;
const RIGHT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_HEIGHT : DT_RIGHT_CONTAINER_HEIGHT;
const START_BTN_WIDTH = ScreenInfo.isMobile() ? MB_START_BTN_WIDTH : DT_START_BTN_WIDTH;
const STOP_BTN_WIDTH = ScreenInfo.isMobile() ? MB_STOP_BTN_WIDTH : DT_STOP_BTN_WIDTH;
const EDIT_BTN_WIDTH = ScreenInfo.isMobile() ? MB_EDIT_BTN_WIDTH : DT_EDIT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_FOTNSIZE = ScreenInfo.isMobile() ? MB_BTN_FOTNSIZE : DT_BTN_FOTNSIZE;
const BTN_MARGIN_V = ScreenInfo.isMobile() ? MB_BTN_MARGIN_V : DT_BTN_MARGIN_V;
const TOP_FLEX = ScreenInfo.isMobile() ? MB_TOP_FLEX : DT_TOP_FLEX;
const BOTTOM_FLEX = ScreenInfo.isMobile() ? MB_BOTTOM_FLEX : DT_BOTTOM_FLEX;
const CONTAINER_BETWEEN = ScreenInfo.isMobile() ? MB_CONTAINER_BETWEEN : DT_CONTAINER_BETWEEN;
const KLAYTN_ADDRESS_REG_GUIDE_FONTSIZE = ScreenInfo.isMobile() ? MB_KLAYTN_ADDRESS_REG_GUIDE_FONTSIZE : DT_KLAYTN_ADDRESS_REG_GUIDE_FONTSIZE;
const KLAYTN_ADDRESS_REG_GUIDE_MARGIN_BOTTOM = ScreenInfo.isMobile()
  ? MB_KLAYTN_ADDRESS_REG_GUIDE_MARGIN_BOTTOM
  : DT_KLAYTN_ADDRESS_REG_GUIDE_MARGIN_BOTTOM;
const KLAYTN_ADDRESS_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_KLAYTN_ADDRESS_MARGIN_BOTTOM : DT_KLAYTN_ADDRESS_MARGIN_BOTTOM;
const KLAYTN_ADDRESS_FONTSIZE = ScreenInfo.isMobile() ? MB_KLAYTN_ADDRESS_FONTSIZE : DT_KLAYTN_ADDRESS_FONTSIZE;
const MINIMUM_LIMIT_FONT_SIZE = ScreenInfo.isMobile() ? MB_MINIMUM_LIMIT_FONT_SIZE : DT_MINIMUM_LIMIT_FONT_SIZE;

export type SellJsxProps = {
  req: { mode: 'NEW'; tx: RPC_MP_SALE_ADD_VIEW.Tx } | { mode: 'EDIT'; tx: RPC_MP_SALE_EDIT_VIEW.Tx };
  // fetched: RPC_MP_SALE_ADD_VIEW.Rx | RPC_MP_SALE_EDIT_VIEW.Rx;
  onSuccess: (arg: { prc_info: CbtReqPriceInfo_i }) => void;
  onSaleStop: () => void;
  onClose: () => void;
};

export function SellJsx(props: SellJsxProps) {
  /**************************************
   * !! state
   **************************************/
  const [sPrice, setPrice] = useState<string>('');
  const [sCurCurrInfo, setCurCurrInfo] = useState<CbtCurrencyInfo_i>();
  const [sMinimumLimitMsg, setMinimumLimitMsg] = useState<string>();
  const [sSellBtnEnable, setSellBtnEnable] = useState(false);

  const [sRewardAddress, setRewardAddress] = useState<string | null>(null);
  const [sFetchedData, setFetchedData] = useState<RPC_MP_SALE_ADD_VIEW.Rx | RPC_MP_SALE_EDIT_VIEW.Rx>();
  const [sAgreePolicy, setAgreePolicy] = useState(false);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hAuth = useAuth();
  const hKaikasSvc = useKaikasSvc();
  const hDlgFactory = useDlgFactory();
  const hHistory = useHistory();
  const hMyPageState = useUserDetailMineState();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    hKaikasSvc.getRewardAddr().then((addr) => {
      setRewardAddress(addr);
    });

    fetchData();
  }, []);

  useEffect(() => {
    if (!sFetchedData) {
      return () => {};
    }

    if (isEditMode()) {
      const fetched = sFetchedData as RPC_MP_SALE_EDIT_VIEW.Rx;
      setCurCurrInfo(fetched.prc_info.curr_info);
      setPrice(Utils.currency.getPriceForDP({ prcInfo: fetched.prc_info, noComma: true }));
    } else {
      // default KLAY
      const defaultCurrency = sFetchedData.curr_list.find((curr) => curr.currency === CbtCurrency_e.KLAY);
      setCurCurrInfo(defaultCurrency);
      setPrice('');
    }

    return () => {};
  }, [sFetchedData]);

  useEffect(() => {
    // minimum price 체크
    let isValidPrice = false;
    if (sPrice.length > 0) {
      if (parseInt(sPrice) < 10) {
        isValidPrice = false;
        if (!sCurCurrInfo?.currency) {
          DBGMSG(`what`);
          return;
        }
        setMinimumLimitMsg(
          `${Utils.string.parsePlaceholder(hR.strings.DLG_SELL_MINIMUM_LIMIT, '{화폐단위}').begin} ${
            Utils.svc.getCurrencyDpInfo(sCurCurrInfo.currency).unit
          } ${Utils.string.parsePlaceholder(hR.strings.DLG_SELL_MINIMUM_LIMIT, '{화폐단위}').end}`
        );
      } else {
        isValidPrice = true;
        setMinimumLimitMsg(undefined);
      }
    } else {
      // 입력전
      setMinimumLimitMsg(undefined);
      isValidPrice = false;
    }

    // 판매하기 / 판매수정 완료버튼 enable/disable
    if (isEditMode()) {
      if (
        sPrice.length === 0 || // 입력 하지 않은경우
        isValidPrice === false || // minimum price 체크
        sPrice === '0' || // input 에서 이미 차단됨
        (sCurCurrInfo?.currency === CbtCurrency_e.KLAY && (sRewardAddress === null || sRewardAddress.length === 0)) // Klay로 선택되있고 입금주소가 등록되어 있지 않을경우
      ) {
        setSellBtnEnable(false);
      } else {
        setSellBtnEnable(true);
      }
    } else {
      if (
        sPrice.length === 0 || // 입력 하지 않은경우
        isValidPrice === false || // minimum price 체크
        sPrice === '0' || // input 에서 이미 차단됨
        sAgreePolicy === false || // 동의사항체크
        (sCurCurrInfo?.currency === CbtCurrency_e.KLAY && (sRewardAddress === null || sRewardAddress.length === 0)) // Klay로 선택되있고 입금주소가 등록되어 있지 않을경우
      ) {
        DBGMSG('setSellBtnEnable(false);');
        setSellBtnEnable(false);
      } else {
        DBGMSG('setSellBtnEnable(true);');
        setSellBtnEnable(true);
      }
    }

    return () => {};
  }, [hKaikasSvc, sCurCurrInfo?.currency, sPrice, sRewardAddress, sAgreePolicy]); // dlg 정보가 변경되면 실행되는 effect

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async () => {
    if (props.req.mode === 'NEW') {
      try {
        hDlgFactory.showLoading();
        const res = await hRpc.cobaltRpc.rpcMpSaleAddView({
          sid: props.req.tx.sid,
          entity_id: props.req.tx.entity_id,
          asset_id: props.req.tx.asset_id,
        });
        setFetchedData(res);
      } catch (e) {
        const errinfo = getRpcErrorInfo(e);
        if (errinfo) {
          hDlgFactory.showSimpleOk({
            text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}`,
            onOk: () => {
              hDlgFactory.hideSellJsx();
            },
          });
        }
      } finally {
        hDlgFactory.hideLoading();
      }
    } else {
      try {
        hDlgFactory.showLoading();
        const res = await hRpc.cobaltRpc.rpcMpSaleEditView({
          sale_id: props.req.tx.sale_id,
        });
        setFetchedData(res);
      } catch (e) {
        const errinfo = getRpcErrorInfo(e);
        if (errinfo) {
          hDlgFactory.showSimpleOk({
            text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}`,
            onOk: () => {
              hDlgFactory.hideSellJsx();
            },
          });
        }
      } finally {
        hDlgFactory.hideLoading();
      }
    }
  };

  const getCalcPrice = (): string => {
    if (!sFetchedData) return '';
    if (!sCurCurrInfo) return '';
    if (hAuth.authCommonRes?.ucode !== sFetchedData.ast_info.creator) {
      // let stat = '';
      // if (isAlpha()) {
      //   stat = `${parseFloat(sPrice)} - ${parseFloat(sPrice)} x (${sFetchedData.fee_info.cl_frt / sFetchedData.fee_info.cl_fdcm}% + ${
      //     sFetchedData.fee_info.pf_frt / sFetchedData.fee_info.pf_fdcm
      //   }%)   계산식 alpha/beta에서만 보임`;
      // }

      return `${
        parseFloat(sPrice) -
        parseFloat(sPrice) *
          (sFetchedData.fee_info.cl_frt / sFetchedData.fee_info.cl_fdcm / 100 + sFetchedData.fee_info.pf_frt / sFetchedData.fee_info.pf_fdcm / 100)
      } ${Utils.svc.getCurrencyDpInfo(sCurCurrInfo.currency).unit}`;
    } else {
      // let stat = '';
      // if (isAlpha()) {
      //   stat = `${parseFloat(sPrice)} - ${parseFloat(sPrice)} x ${
      //     sFetchedData.fee_info.pf_frt / sFetchedData.fee_info.pf_fdcm
      //   }%   계산식 alpha/beta에서만 보임`;
      // }
      return `${parseFloat(sPrice) - parseFloat(sPrice) * (sFetchedData.fee_info.pf_frt / sFetchedData.fee_info.pf_fdcm / 100)} ${
        Utils.svc.getCurrencyDpInfo(sCurCurrInfo.currency).unit
      }`;
    }
  };

  const isNewMode = () => {
    return props.req.mode === 'NEW';
  };

  const isEditMode = () => {
    return props.req.mode === 'EDIT';
  };

  // 입금/리워드 주소가 등록되어있는지 여부
  const hasRegisterdKlaytnAddr = () => {
    return sRewardAddress !== null && sRewardAddress.length !== 0;
  };

  const openNewWindowKlaytnScope = (accountAddr: string) => {
    if (isAlpha()) {
      const klaytnScopeUrl = Utils.string.brewKlaytnUserAccountUrl({ accountAddress: accountAddr, isMainnet: false });
      window.open(klaytnScopeUrl);
    } else if (isProd()) {
      const klaytnScopeUrl = Utils.string.brewKlaytnUserAccountUrl({ accountAddress: accountAddr, isMainnet: true });
      window.open(klaytnScopeUrl);
    }
  };

  if (!sFetchedData) {
    return null;
  }

  if (!sCurCurrInfo) {
    return null;
  }

  const req = props.req;

  return (
    <>
      {/* 다이얼로그 박스 */}
      <FlexColumnDiv
        style={{
          width: DLG_WIDTH,
          alignItems: 'center',
          paddingLeft: HPADDING,
          paddingRight: HPADDING,
          paddingTop: PADDING_TOP,
          paddingBottom: PADDING_BOTTOM,
          zIndex: 1000000,
        }}
        onClick={(e) => {
          DBGMSG('onDialog');
          e.stopPropagation();
        }}
      >
        {/* x close 버튼 */}
        <ImgBtn
          src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
          containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
          onClick={() => {
            props.onClose();
          }}
        />

        {/* 타이틀 */}
        <TextCon
          text={req.mode === 'NEW' ? hR.strings.DLG_SELL_TITLE : hR.strings.DLG_SELL_TITLE_EDIT}
          size={TITLE_FOTNSIZE}
          color={R.colors.black}
          containerStyle={{ height: TITLE_HEIGHT }}
          isBold
        />

        <HEIGHT size={TITLE_MARGIN_BOTTOM} />

        <FlexRowDiv
          style={{
            alignSelf: 'flex-start',
            flexWrap: 'wrap',
            width: BODY_WIDTH,
            alignItems: 'flex-start',
            justifyContent: 'center',
            // ...devOutlineFB,
          }}
        >
          {/* left */}
          <FlexDiv
            style={{
              width: LEFT_CONTAINER_WIDTH,
              height: LEFT_CONTAINER_HEIGHT,
              marginBottom: CONTAINER_BETWEEN,
              // ...devOutlineFG,
            }}
          >
            {/* 에셋 이미지 */}
            <CommonColumnBox
              style={{
                width: ASSET_DISPLAY_BORDER_BOX,
                height: ASSET_DISPLAY_BORDER_BOX,
                justifyContent: 'center',
                alignItems: 'center',
                margin: ASSET_DISPLAY_BORDER_BOX_MARGIN,
                // ...devOutlineF,
              }}
            >
              <AssetDisplayThumbReqattr
                playerSrc={Utils.svc.brewAssetDisplayThumbReqattr({
                  assetType: sFetchedData.ast_info.schm_info.asset_type,
                  img: sFetchedData.ast_info.nft_img,
                })}
                width={ASSET_DISPLAY_SIZE}
                height={ASSET_DISPLAY_SIZE}
                whereToUse={WhereToUse_e.ForDlg}
              />
            </CommonColumnBox>
          </FlexDiv>

          {/* right */}
          <FlexDiv
            style={{
              width: RIGHT_CONTAINER_WIDTH,
              height: RIGHT_CONTAINER_HEIGHT,
              //
              // ...devOutlineFG,
            }}
          >
            <FlexColumnDiv style={{ marginLeft: FORM_INPUT_HMARGIN, marginRight: FORM_INPUT_HMARGIN }}>
              {/* 판매 가격 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.DLG_SELL_PRICE} color={R.colors.black} size={FORM_FONT_SIZE} isBold />
                <WIDTH size={3} />
                <TextCon text={'*'} color={R.colors.purpleishPink} size={FORM_FONT_SIZE} isBold />
              </FlexRowDiv>

              <HEIGHT size={FORM_INPUT_MARGIN_TOP} />

              {/* 가격 / Currency 입력 */}
              <PriceWithCurrencyInput
                value={sPrice}
                defaultCurrency={sCurCurrInfo.currency}
                onDropdownMenuSelect={(currency) => {
                  setCurCurrInfo(currency);
                }}
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    setPrice('');
                    return;
                  }

                  const regex: RegExp = Constants.REGEXP_IS_VALID_KLAY;
                  const regexTestRet = regex.test(e.target.value);
                  DBGMSG(`$$$REGEX ${regex}: ${e.target.value} ${regexTestRet}`);
                  if (!regexTestRet) {
                    DBGMSG(`$$$REGEX regexTestRet: ${regexTestRet}`);
                    return;
                  }
                  setPrice(e.target.value);
                }}
                currencyInfoList={sFetchedData.curr_list}
              />

              <FlexColumnDiv style={{ height: FORM_INPUT_BETWEEN, paddingTop: 2 }}>
                <TextCon text={sMinimumLimitMsg} color={R.colors.pastelRed} size={MINIMUM_LIMIT_FONT_SIZE} />
              </FlexColumnDiv>

              {/* 수수료 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.DLG_SELL_FEE} color={R.colors.black} size={FORM_FONT_SIZE} isBold />
                {/* <WIDTH size={1} /> */}
                {/* <Txt text={'*'} color={R.colors.purpleishPink} size={14} isBold /> */}
              </FlexRowDiv>
              <HEIGHT size={FORM_INPUT_MARGIN_TOP} />

              {/* 수수료 - 컬렉션 인세 */}
              {hAuth.authCommonRes?.ucode !== sFetchedData.ast_info.creator && (
                <>
                  <CommonLightGrayBox style={{ width: FORM_INPUT_WIDTH }}>
                    <FlexRowDiv style={{ height: FORM_INPUT_HEIGHT }}>
                      <TextCon
                        text={hR.strings.DLG_SELL_ROYALTY}
                        color={R.colors.black}
                        size={FORM_FONT_SIZE}
                        containerStyle={{ width: FEE_WIDTH, paddingLeft: FEE_WIDTH_PADDING_LEFT }}
                      />
                      <TextCon
                        text={`${sFetchedData.fee_info.cl_frt / sFetchedData.fee_info.cl_fdcm}%`}
                        color={R.colors.black}
                        size={FORM_FONT_SIZE}
                        containerStyle={{ width: FEE_VALUE_WIDTH, paddingLeft: FEE_VALUE_WIDTH_PADDING_LEFT }}
                        isBold
                      />
                    </FlexRowDiv>
                  </CommonLightGrayBox>
                  <HEIGHT size={10} />
                </>
              )}

              {/* 수수료 - 컬렉션 수수료 */}
              <CommonLightGrayBox style={{ width: FORM_INPUT_WIDTH }}>
                <FlexRowDiv style={{ height: FORM_INPUT_HEIGHT }}>
                  <TextCon
                    text={hR.strings.DLG_SELL_PLATFORM_FEE}
                    color={R.colors.black}
                    size={FORM_FONT_SIZE}
                    containerStyle={{ width: FEE_WIDTH, paddingLeft: FEE_WIDTH_PADDING_LEFT }}
                  />
                  <TextCon
                    text={`${sFetchedData.fee_info.pf_frt / sFetchedData.fee_info.pf_fdcm}%`}
                    color={R.colors.black}
                    size={FORM_FONT_SIZE}
                    containerStyle={{ width: FEE_VALUE_WIDTH, paddingLeft: FEE_VALUE_WIDTH_PADDING_LEFT }}
                    isBold
                  />
                </FlexRowDiv>
              </CommonLightGrayBox>

              <HEIGHT size={FORM_INPUT_BETWEEN} />

              {/* 예상 판매 수익 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.DLG_SELL_CALC_PRICE} color={R.colors.black} size={FORM_FONT_SIZE} isBold />
              </FlexRowDiv>
              <HEIGHT size={FORM_INPUT_MARGIN_TOP} />
              <CommonLightGrayBox>
                <FlexRowDiv style={{ width: FORM_INPUT_WIDTH, height: FORM_INPUT_HEIGHT }}>
                  {sPrice.length > 0 && (
                    <TextCon
                      text={getCalcPrice()}
                      color={R.colors.purpleishBlue}
                      size={FORM_FONT_SIZE}
                      containerStyle={{ paddingLeft: FEE_WIDTH_PADDING_LEFT }}
                      isBold
                    />
                  )}
                </FlexRowDiv>
              </CommonLightGrayBox>

              {/* Klay를 선택했을 경우, 입금/리워드 주소 */}
              {sCurCurrInfo?.currency === CbtCurrency_e.KLAY && (
                <>
                  {hasRegisterdKlaytnAddr() ? (
                    <>
                      {/* KLAYTN 리워드/입금 주소 */}
                      <HEIGHT size={FORM_INPUT_BETWEEN} />
                      <FlexRowDiv>
                        <TextCon text={hR.strings.DLG_SELL_KLAY_ADDRESS} color={R.colors.black} size={FORM_FONT_SIZE} isBold />
                      </FlexRowDiv>
                      <HEIGHT size={10} />

                      <CommonLightGrayBox>
                        <FlexRowDiv style={{ width: FORM_INPUT_WIDTH, height: FORM_INPUT_HEIGHT }}>
                          <TextCon
                            text={sRewardAddress!}
                            color={R.colors.purpleishBlue}
                            size={KLAYTN_ADDRESS_FONTSIZE}
                            containerStyle={{ paddingLeft: FEE_WIDTH_PADDING_LEFT }}
                            onConClick={() => {
                              openNewWindowKlaytnScope(sRewardAddress!);
                            }}
                          />
                        </FlexRowDiv>
                      </CommonLightGrayBox>
                    </>
                  ) : (
                    <>
                      {/* KLAYTN 리워드/입금 주소 첫 등록 가이드 */}
                      <HEIGHT size={FORM_INPUT_BETWEEN} />
                      <TextCon
                        text={hR.strings.DLG_SELL_KLAY_ADDRESS_REG_GUIDE}
                        color={R.colors.purpleishBlue}
                        size={KLAYTN_ADDRESS_REG_GUIDE_FONTSIZE}
                        containerStyle={{ width: FORM_INPUT_WIDTH, justifyContent: 'center' }}
                        isBold
                        onConClick={async () => {
                          if (ScreenInfo.isMobile()) {
                            hDlgFactory.showSimpleOk({ text: hR.strings.DLG_KAIKAS_ONLY_DESKTOP_SELL });
                          } else {
                            try {
                              try {
                                await hKaikasSvc.kaikasConnectAndRewardAddrReg(true);
                                const rewardAddr = await hKaikasSvc.getRewardAddr();
                                DBGMSG('###################################');
                                DBGMSG(rewardAddr);
                                setRewardAddress(rewardAddr);
                              } catch (err) {
                                DBGMSG('###################################');
                              }
                            } catch (err) {}
                          }
                        }}
                      />
                      <HEIGHT size={KLAYTN_ADDRESS_REG_GUIDE_MARGIN_BOTTOM} />
                    </>
                  )}
                </>
              )}

              {/* cPoint를 선택했을 경우, 리워드 주소 */}
              {sCurCurrInfo?.currency === CbtCurrency_e.cPOINT && (
                <>
                  <HEIGHT size={FORM_INPUT_BETWEEN} />
                  <FlexRowDiv>
                    <TextCon text={hR.strings.DLG_SELL_KLAY_ADDRESS_REWARD} color={R.colors.black} size={FORM_FONT_SIZE} isBold />
                  </FlexRowDiv>
                  <HEIGHT size={10} />

                  <CommonLightGrayBox>
                    <FlexRowDiv style={{ width: FORM_INPUT_WIDTH, height: FORM_INPUT_HEIGHT }}>
                      <TextCon
                        text={hasRegisterdKlaytnAddr() ? sRewardAddress! : hR.strings.DLG_SELL_KLAY_ADDRESS_REWARD_GUIDE}
                        color={hasRegisterdKlaytnAddr() ? R.colors.purpleishBlue : R.colors.blueGrey}
                        size={KLAYTN_ADDRESS_FONTSIZE}
                        containerStyle={{ paddingLeft: FEE_WIDTH_PADDING_LEFT }}
                        onConClick={() => {
                          if (hasRegisterdKlaytnAddr()) {
                            openNewWindowKlaytnScope(sRewardAddress!);
                          } else {
                            props.onClose();
                            hMyPageState.act.clearState();
                            hHistory.push(Nav.UserDetailMineScreen);
                          }
                        }}
                      />
                    </FlexRowDiv>
                  </CommonLightGrayBox>
                </>
              )}

              {/* 유의사항에 동의 */}
              {isNewMode() && (
                <>
                  <HEIGHT size={30} />
                  <FlexRowDiv style={{ alignItems: 'flex-start' }}>
                    <ImgBtn
                      src={[
                        sAgreePolicy ? R.images.join_btn_check_abled3x : R.images.join_btn_check_disabled3x,
                        ScreenInfo.isMobile() ? 18 : 22,
                        ScreenInfo.isMobile() ? 18 : 22,
                      ]}
                      onClick={() => {
                        setAgreePolicy((prev) => !prev);
                      }}
                    />
                    <WIDTH size={ScreenInfo.isMobile() ? 6 : 8} />
                    <TextCon text={hR.strings.DLG_SELL_AGREE_POLICY_TITLE} color={R.colors.black} size={ScreenInfo.isMobile() ? 10 : 12} isBold />
                  </FlexRowDiv>
                  <HEIGHT size={ScreenInfo.isMobile() ? 10 : 14} />
                  <CommonLightGrayBox style={{ padding: ScreenInfo.isMobile() ? 10 : 14 }}>
                    {hR.strings.DLG_SELL_AGREE_POLICY_TEXT.split('\n').map((substr) => {
                      return (
                        <>
                          <FlexRowDiv style={{ alignItems: 'flex-start' }}>
                            <TextCon
                              text={substr.slice(0, 2)}
                              size={ScreenInfo.isMobile() ? 10 : 12}
                              color={R.colors.blueGrey}
                              lineSpace={ScreenInfo.isMobile() ? 15 : 22}
                            />
                            <TextCon
                              text={substr.slice(2)}
                              size={ScreenInfo.isMobile() ? 10 : 12}
                              color={R.colors.blueGrey}
                              // textStyle={{}}
                              lineSpace={ScreenInfo.isMobile() ? 15 : 22}
                            />
                          </FlexRowDiv>
                          <HEIGHT size={5} />
                        </>
                      );
                    })}
                  </CommonLightGrayBox>
                </>
              )}

              <HEIGHT size={KLAYTN_ADDRESS_MARGIN_BOTTOM} />

              <FlexRowDiv style={{ justifyContent: 'center' }}>
                {/* 판매하기 NEW */}
                {req.mode === 'NEW' ? (
                  <RoundTxtBtn
                    text={hR.strings.DLG_SELL_OK_BTN}
                    height={BTN_HEIGHT}
                    width={START_BTN_WIDTH}
                    fontSize={BTN_FOTNSIZE}
                    enable={sSellBtnEnable}
                    onClick={() => {
                      DBGMSG(`onClick`);
                      if (!sCurCurrInfo) {
                        DBGMSGW('error');
                        return;
                      }

                      DBGMSG(sCurCurrInfo);

                      // 참고: https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97602968
                      // 'sub_curr_list' | 'pay_method_type' | 'dp_type' 필드는 바인딩하지 않는다.
                      const currInfo = { ...sCurCurrInfo, dp_type: undefined, pay_method_type: undefined, sub_curr_list: undefined } as Omit<
                        CbtCurrencyInfo_i,
                        'sub_curr_list' | 'pay_method_type' | 'dp_type'
                      >;

                      hDlgFactory.showLoading();

                      hRpc.cobaltRpc
                        .rpcMpSaleAdd({
                          sid: req.tx.sid,
                          entity_id: req.tx.entity_id,
                          asset_id: req.tx.asset_id,
                          prc_info: {
                            curr_info: currInfo,
                            price: Utils.currency.reflectInCurrInfo({ priceTxt: sPrice, curr_info: sCurCurrInfo }),
                          },
                        })
                        .then((res) => {
                          props.onSuccess({
                            prc_info: {
                              curr_info: currInfo,
                              price: Utils.currency.reflectInCurrInfo({ priceTxt: sPrice, curr_info: sCurCurrInfo }),
                            },
                          });
                          hMyPageState.act.changeState({ menuCnt: { needUpdate: true } });
                        })
                        .finally(() => {
                          hDlgFactory.hideLoading();
                        });
                    }}
                  />
                ) : (
                  // 판매중 EDIT
                  // 판매중지 / 판매 버튼
                  <>
                    <RoundTxtBtnAdv
                      label={hR.strings.DLG_SELL_STOP_BTN}
                      width={STOP_BTN_WIDTH}
                      height={BTN_HEIGHT}
                      bgColor={'white'}
                      fontColor={R.colors.purpleishBlue}
                      borderColor={R.colors.purpleishBlue}
                      fontSize={BTN_FOTNSIZE}
                      onClick={() => {
                        hDlgFactory.showLoading();
                        hRpc.cobaltRpc
                          .rpcMpSaleEdit({
                            sale_id: req.tx.sale_id,
                            cancel_sale: true,
                          })
                          .then((res) => {
                            props.onSaleStop();
                            hMyPageState.act.changeState({ menuCnt: { needUpdate: true } });
                          })
                          .finally(() => {
                            hDlgFactory.hideLoading();
                          });
                      }}
                    />
                    <WIDTH size={12} />
                    <RoundTxtBtn
                      text={hR.strings.DLG_SELL_OK_BTN}
                      height={BTN_HEIGHT}
                      width={EDIT_BTN_WIDTH}
                      fontSize={BTN_FOTNSIZE}
                      containerStyle={{ flex: 1 }}
                      enable={sSellBtnEnable}
                      onClick={() => {
                        DBGMSG(`onClick`);
                        if (!sCurCurrInfo) return;

                        hDlgFactory.showLoading();
                        hRpc.cobaltRpc
                          .rpcMpSaleEdit({
                            sale_id: req.tx.sale_id,
                            prc_info: {
                              curr_info: sCurCurrInfo,
                              price: Utils.currency.reflectInCurrInfo({ priceTxt: sPrice, curr_info: sCurCurrInfo }),
                            },
                            cancel_sale: false,
                          })
                          .then((res) => {
                            props.onSuccess({
                              prc_info: {
                                curr_info: sCurCurrInfo,
                                price: Utils.currency.reflectInCurrInfo({ priceTxt: sPrice, curr_info: sCurCurrInfo }),
                              },
                            });
                            hMyPageState.act.changeState({ menuCnt: { needUpdate: true } });
                          })
                          .finally(() => {
                            hDlgFactory.hideLoading();
                          });
                      }}
                    />
                  </>
                )}
              </FlexRowDiv>
              <HEIGHT size={20} />
            </FlexColumnDiv>
          </FlexDiv>
        </FlexRowDiv>
      </FlexColumnDiv>
    </>
  );
}
