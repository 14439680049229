/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { MyBoughtCard, MyBoughtCardProps } from 'src/ui/screen/AssetMgr/card/MyBoughtCard';
import { MYPAGE_SOLD_CARD_PADDING } from 'src/ui/screen/AssetMgr/card/MySoldCardForList';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/

export function MyBoughtCardForList(props: MyBoughtCardProps) {
  return (
    <FlexDiv
      style={{
        padding: MYPAGE_SOLD_CARD_PADDING,
        justifyContent: 'center',
        alignItems: 'center',
        ...devOutline,
      }}
    >
      <MyBoughtCard {...props} />
    </FlexDiv>
  );
}
