/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { TooltipJsx } from 'src/ui/base_component/TooltipJsx';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  title?: string;
  value?: string | string[];
  flexDir?: 'row' | 'column';
  titleColor?: string;
  valueColor?: string;
  titleSize?: number;
  valueSize?: number;
  titleHeight?: number | string | undefined;
  valueHeight?: number | string | undefined;
  titleWidth?: number | string | undefined;
  valueWidth?: number | string | undefined;
  between?: number;
  valueOnClick?: (v: string) => void;
  valueMouseOverTitle?: string;
  titleTooltipString?: string;
  valueBottomTooltipJsx?: JSX.Element;
};

export function LabelValue({
  title = 'title',
  value = 'value',
  flexDir = 'row',
  titleColor = R.colors.blueGrey,
  valueColor = R.colors.black,
  titleSize = 14,
  valueSize = 14,
  titleHeight = undefined,
  valueHeight = undefined,
  titleWidth = undefined,
  valueWidth = undefined,
  between = 0,
  valueOnClick,
  valueMouseOverTitle,
  titleTooltipString,
  valueBottomTooltipJsx,
}: Props) {
  const renderValue = (v: string) => {
    return (
      <TextCon
        size={valueSize}
        color={valueColor}
        isBold
        text={v}
        // textStyle={{ width: '100%' }}
        containerStyle={{ minHeight: valueHeight, width: valueWidth, wordBreak:'break-all', ...devOutline }}
        onConClick={
          valueOnClick
            ? () => {
                valueOnClick(v);
              }
            : undefined
        }
      />
    );
  };

  return (
    <FlexDiv style={{ flexDirection: flexDir, ...devOutline }}>
      {/* Title */}
      <FlexRowDiv style={{ height: titleHeight, width: titleWidth }}>
        <TextCon size={titleSize} color={titleColor} text={title} containerStyle={{ ...devOutline }} />
        <WIDTH size={3} />
        {titleTooltipString && <TooltipImg tooltipString={titleTooltipString} placement={'right-start'} />}
      </FlexRowDiv>
      <WIDTH size={between} />

      <FlexDiv title={valueMouseOverTitle}>
        {/* Value */}
        {typeof value === 'string' ? (
          <>
            {valueBottomTooltipJsx ? (
              <>
                <TooltipJsx jsx={renderValue(value)} tooltipJsx={valueBottomTooltipJsx} placement={'bottom-start'} />
              </>
            ) : (
              renderValue(value)
            )}
          </>
        ) : (
          value.map((v) => {
            return renderValue(v);
          })
        )}
      </FlexDiv>
    </FlexDiv>
  );
}
