/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/modules';
import dialogSlice, { DlgStateJsxMulti_i } from 'src/redux/modules/dlgModule';

export function useDlg() {
  const dialogState = useSelector((state: RootState) => state.dialogState);
  const dispatch = useDispatch();

  /***************************************************
   * dlgJsxMulti
   ****************************************************/
  const showDlgJsxMulti = useCallback(
    (args: Omit<DlgStateJsxMulti_i, 'showHideTrigger'>) => {
      dispatch(dialogSlice.actions.showJsxMulti(args));
    },
    [dispatch]
  );

  const hideDlgJsxMultiLast = useCallback(() => {
    dispatch(dialogSlice.actions.hideJsxMultiLast());
  }, [dispatch]);

  const closeDlgJsxMultiLast = useCallback(() => {
    dispatch(dialogSlice.actions.closeJsxMultiLast());
  }, [dispatch]);

  const hideDlgJsxMultiByKey = useCallback(
    ({ key }: { key: string }) => {
      dispatch(dialogSlice.actions.hideJsxMultiByKey({ key }));
    },
    [dispatch]
  );

  const closeDlgJsxMultiByKey = useCallback(
    ({ key }: { key: string }) => {
      dispatch(dialogSlice.actions.closeJsxMultiByKey({ key }));
    },
    [dispatch]
  );

  return {
    act: {
      showDlgJsxMulti,
      hideDlgJsxMultiLast,
      closeDlgJsxMultiLast,
      hideDlgJsxMultiByKey,
      closeDlgJsxMultiByKey,
    },
    state: dialogState,
  };
}
