/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { DBGMSG, Utils } from 'src/util/utils';
import { CSSProperties } from 'styled-components';
import { UserThumb } from 'src/ui/common_component/UserThumb';
import ScreenInfo from 'src/context/screen_constants';
import { useKaikas } from 'src/hooks/useKaikas';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const DT_THUMB_SIZE = 100;
const DT_THUMB_MARGIN_BOTTOM = 10;
const DT_THUMB_FONTSIZE = 40;
const DT_ID_MARGIN_BOTTOM = 6;
const DT_ID_FONTSIZE = 24;
const DT_BLOCKCHAIN_LINK_FONTSIZE = 14;

const MB_THUMB_SIZE = 60;
const MB_THUMB_MARGIN_BOTTOM = 8;
const MB_THUMB_FONTSIZE = 24;
const MB_ID_MARGIN_BOTTOM = 4;
const MB_ID_FONTSIZE = 16;
const MB_BLOCKCHAIN_LINK_FONTSIZE = 10;

const THUMB_SIZE = ScreenInfo.isMobile() ? MB_THUMB_SIZE : DT_THUMB_SIZE;
const THUMB_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_THUMB_MARGIN_BOTTOM : DT_THUMB_MARGIN_BOTTOM;
const THUMB_FONTSIZE = ScreenInfo.isMobile() ? MB_THUMB_FONTSIZE : DT_THUMB_FONTSIZE;
const ID_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_ID_MARGIN_BOTTOM : DT_ID_MARGIN_BOTTOM;
const ID_FONTSIZE = ScreenInfo.isMobile() ? MB_ID_FONTSIZE : DT_ID_FONTSIZE;
const BLOCKCHAIN_LINK_FONTSIZE = ScreenInfo.isMobile() ? MB_BLOCKCHAIN_LINK_FONTSIZE : DT_BLOCKCHAIN_LINK_FONTSIZE;

type Props = {
  prof: string | null;
  ucode: string;
  userWalletAddress: string;
  size?: number;
  fontSize?: number;
  fontSize2?: number;
  onClick?: () => void;
  containerStyle?: CSSProperties;
  showEditBtn?: boolean;
  onEditBtnClick?: () => void;
};

export function UserThumbMain({
  //
  prof: userThumb,
  ucode: userId,
  userWalletAddress,
  size = THUMB_SIZE,
  fontSize = THUMB_FONTSIZE,
  fontSize2 = ID_FONTSIZE,
  onClick,
  containerStyle,
  showEditBtn = false,
  onEditBtnClick,
}: Props) {
  const hR = useLocalSettings();
  const hKaikas = useKaikas();

  return (
    <FlexColumnDiv
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'inherit',
        ...containerStyle,
        ...devOutline,
      }}
      onClick={() => {
        DBGMSG(`TODO: ${userId}`);
        onClick && onClick();
      }}
    >
      <UserThumb userThumb={userThumb} userId={userId} size={size} fontSize={fontSize} showEditBtn={showEditBtn} onEditBtnClick={onEditBtnClick} />
      <HEIGHT size={THUMB_MARGIN_BOTTOM} />
      <TextCon text={userId} size={fontSize2} color={R.colors.black} isBold />
      <HEIGHT size={ID_MARGIN_BOTTOM} />
      <TextCon
        text={`${hR.strings.COMMON_VIEW_ON_BLOCKS} >`}
        size={BLOCKCHAIN_LINK_FONTSIZE}
        color={R.colors.blueGrey}
        isBold
        onConClick={() => {
          window.open(userWalletAddress);
          // if (hKaikas.getNetworkVersion() === 'MAINNET') {
          //   window.open(Utils.string.brewKlaytnUserAccountUrl({ accountAddress: userWalletAddress, isMainnet: true }));
          // } else if (hKaikas.getNetworkVersion() === 'BAOBAB') {
          //   window.open(Utils.string.brewKlaytnUserAccountUrl({ accountAddress: userWalletAddress, isMainnet: false }));
          // }
        }}
      />
    </FlexColumnDiv>
  );
}
