/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, MouseEventHandler, useRef, useState } from 'react';
import R from 'src/res/R';
import { FlexColumnDiv } from 'src/ui/base_component/etc';
import { CLAY } from 'src/ui/layout_constant';

type Props = {
  icSize?: number;
  containerStyle?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function TooltipIc({ icSize, containerStyle, onClick }: Props) {
  const targetRef = useRef(null);

  return (
    <FlexColumnDiv
      style={{
        justifyContent: 'center',
        ...containerStyle,
      }}
      onClick={(e) => {
        onClick && onClick(e);
      }}
    >
      <img
        ref={targetRef}
        src={R.images.common_btn_tooltip_normal3x}
        // src={R.images.common_btn_tooltip_hover3x}
        alt=""
        style={{
          //
          width: icSize ?? CLAY.TOOLTIP_IC_SIZE,
          height: icSize ?? CLAY.TOOLTIP_IC_SIZE,
          boxSizing: 'border-box',
          userSelect: 'none',
        }}
      />
    </FlexColumnDiv>
  );
}
