/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useEffect, useState } from 'react';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, SBorderOffDiv, SBorderOnDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  width?: number;
  height?: number;
  radius?: number;
  forceEffect?: boolean;
  onEffect?: (on: boolean) => void;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
  onMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  onMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
};

export function SAddBtn({ width = 160, height = 160, radius = 80, forceEffect = false, onEffect, onClick, onMouseEnter, onMouseLeave }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseEnter, setMouseEnter] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  const isEffect = sMouseEnter || forceEffect;

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    onEffect && onEffect(isEffect);
    return () => {};
  }, [isEffect, onEffect]);

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  return (
    <FlexColumnDiv
      style={{
        alignItems: 'center',

        // ...devOutlineF
      }}
    >
      {/*  Collection 대표이미지 */}
      {isEffect ? (
        <SBorderOnDiv
          style={{ width, height, borderRadius: radius, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onMouseEnter={(e) => {
            setMouseEnter(true);
            onMouseEnter && onMouseEnter(e);
          }}
          onMouseLeave={(e) => {
            setMouseEnter(false);
            onMouseLeave && onMouseLeave(e);
          }}
          onClick={(e) => {
            DBGMSG(`onClick`);
            onClick && onClick(e);
          }}
        >
          <Img src={[R.images.creator_ic_plus3x, 24, 24]} />
        </SBorderOnDiv>
      ) : (
        <SBorderOffDiv
          style={{ width, height, borderRadius: radius, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onMouseEnter={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
        >
          <Img src={[R.images.creator_ic_plus3x, 24, 24]} />
        </SBorderOffDiv>
      )}
    </FlexColumnDiv>
  );
}
