/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppConf } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikas } from 'src/hooks/useKaikas';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { CbtInqueryType_e } from 'src/model/rpcModel';
import { RPC_SUPPORT_ZENDESK_ADDTASK } from 'src/model/rpcType';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { InqueryTypeCombo } from 'src/ui/common_component/InqueryTypeCombo';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { SRoundInputTextArea } from 'src/ui/common_component/SRoundInputTextArea';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
// DESKTOP
const DT_DLG_WIDTH = 582;
const DT_DLG_HEIGHT = 792;
const DT_DEFAULT_FONT_SIZE = 14;
const DT_HPADDING = 40;
const DT_FORM_HEIGHT = 44;
const DT_BODY_WIDTH = DT_DLG_WIDTH - DT_HPADDING * 2;
const DT_PADDING_TOP = 30;
const DT_PADDING_BOTTOM = 40;
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;
const DT_TITLE_FOTNSIZE = 24;
const DT_TITLE_MARGIN_BOTTOM = 40;
const DT_ASSET_DISPLAY_BORDER_BOX = 276;
const DT_ASSET_DISPLAY_BORDER_BOX_MARGIN = 15;
const DT_ASSET_DISPLAY_SIZE = 236;
const DT_LEFT_CONTAINER_WIDTH = DT_ASSET_DISPLAY_BORDER_BOX + DT_ASSET_DISPLAY_BORDER_BOX_MARGIN * 2;
const DT_LEFT_CONTAINER_HEIGHT = DT_ASSET_DISPLAY_BORDER_BOX + DT_ASSET_DISPLAY_BORDER_BOX_MARGIN * 2;
const DT_FORM_INPUT_WIDTH = 502;
const DT_FORM_INPUT_HMARGIN = 15;

const DT_RIGHT_CONTAINER_WIDTH = DT_FORM_INPUT_WIDTH + DT_FORM_INPUT_HMARGIN * 2;
const DT_RIGHT_CONTAINER_HEIGHT = undefined;
const DT_BOX_PADDING = 24;
const DT_BETWEEN_TEXT_BOX = 24;
const DT_KEY_WIDTH = 152;
const DT_VALUE_WIDTH = 302;
const DT_BTN_WIDTH = 300;
const DT_BTN_HEIGHT = 48;
const DT_BTN_FOTNSIZE = 14;
const DT_CONTAINER_BETWEEN = 0;
const DT_KLAYTN_ADDRESS_MARGIN_BOTTOM = 30;
const DT_COL_IC_SIZE = 24;

// MOBILE
const MB_DLG_WIDTH = 280;
const MB_DLG_HEIGHT = 598;
const MB_DEFAULT_FONT_SIZE = 12;
const MB_HPADDING = 12;
const MB_FORM_HEIGHT = 42;
const MB_BODY_WIDTH = MB_DLG_WIDTH - MB_HPADDING * 2;
const MB_PADDING_TOP = 16;
const MB_PADDING_BOTTOM = 20;
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;
const MB_TITLE_FOTNSIZE = 14;
const MB_TITLE_MARGIN_BOTTOM = 16;
const MB_ASSET_DISPLAY_BORDER_BOX = 158;
const MB_ASSET_DISPLAY_BORDER_BOX_MARGIN = 0;
const MB_ASSET_DISPLAY_SIZE = 134;
const MB_LEFT_CONTAINER_WIDTH = 158;
const MB_LEFT_CONTAINER_HEIGHT = 158;
const MB_FORM_INPUT_WIDTH = 256;
const MB_RIGHT_CONTAINER_WIDTH = MB_FORM_INPUT_WIDTH;
const MB_RIGHT_CONTAINER_HEIGHT = undefined;
const MB_BOX_PADDING = 16;
const MB_BETWEEN_TEXT_BOX = 16;
const MB_KEY_WIDTH = 100;
const MB_VALUE_WIDTH = 124;
const MB_BTN_WIDTH = 256;
const MB_BTN_HEIGHT = 38;
const MB_BTN_FOTNSIZE = 12;
const MB_CONTAINER_BETWEEN = 16;
const MB_KLAYTN_ADDRESS_MARGIN_BOTTOM = 20;
const MB_COL_IC_SIZE = 20;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const DLG_HEIGHT = ScreenInfo.isMobile() ? MB_DLG_HEIGHT : DT_DLG_HEIGHT;
const DEFAULT_FONT_SIZE = ScreenInfo.isMobile() ? MB_DEFAULT_FONT_SIZE : DT_DEFAULT_FONT_SIZE;
const HPADDING = ScreenInfo.isMobile() ? MB_HPADDING : DT_HPADDING;
const FORM_HEIGHT = ScreenInfo.isMobile() ? MB_FORM_HEIGHT : DT_FORM_HEIGHT;
const BODY_WIDTH = ScreenInfo.isMobile() ? MB_BODY_WIDTH : DT_BODY_WIDTH;
const PADDING_TOP = ScreenInfo.isMobile() ? MB_PADDING_TOP : DT_PADDING_TOP;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
const CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;
const TITLE_FOTNSIZE = ScreenInfo.isMobile() ? MB_TITLE_FOTNSIZE : DT_TITLE_FOTNSIZE;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const ASSET_DISPLAY_BORDER_BOX = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX : DT_ASSET_DISPLAY_BORDER_BOX;
const ASSET_DISPLAY_BORDER_BOX_MARGIN = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX_MARGIN : DT_ASSET_DISPLAY_BORDER_BOX_MARGIN;
const ASSET_DISPLAY_SIZE = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_SIZE : DT_ASSET_DISPLAY_SIZE;
const LEFT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_WIDTH : DT_LEFT_CONTAINER_WIDTH;
const LEFT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_HEIGHT : DT_LEFT_CONTAINER_WIDTH;

const RIGHT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_WIDTH : DT_RIGHT_CONTAINER_WIDTH;
const RIGHT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_HEIGHT : DT_RIGHT_CONTAINER_HEIGHT;
const BOX_PADDING = ScreenInfo.isMobile() ? MB_BOX_PADDING : DT_BOX_PADDING;
const BETWEEN_TEXT_BOX = ScreenInfo.isMobile() ? MB_BETWEEN_TEXT_BOX : DT_BETWEEN_TEXT_BOX;
const KEY_WIDTH = ScreenInfo.isMobile() ? MB_KEY_WIDTH : DT_KEY_WIDTH;
const LV_VALUE_WIDTH = ScreenInfo.isMobile() ? MB_VALUE_WIDTH : DT_VALUE_WIDTH;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_FOTNSIZE = ScreenInfo.isMobile() ? MB_BTN_FOTNSIZE : DT_BTN_FOTNSIZE;
const CONTAINER_BETWEEN = ScreenInfo.isMobile() ? MB_CONTAINER_BETWEEN : DT_CONTAINER_BETWEEN;
const KLAYTN_ADDRESS_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_KLAYTN_ADDRESS_MARGIN_BOTTOM : DT_KLAYTN_ADDRESS_MARGIN_BOTTOM;
const COL_IC_SIZE = ScreenInfo.isMobile() ? MB_COL_IC_SIZE : DT_COL_IC_SIZE;

export type InqueryJsxProps = {
  onSuccess: () => void;
  onClose: () => void;
  defaultSelected?: CbtInqueryType_e;
};

export function InqueryJsx(props: InqueryJsxProps) {
  /**************************************
   * !! state
   **************************************/
  const [sType, setType] = useState<CbtInqueryType_e>(CbtInqueryType_e.Problem);
  const [sName, setName] = useState('');
  const [sEmail, setEmail] = useState('');
  const [sSubject, setSubject] = useState('');
  const [sBody, setBody] = useState('');
  const [sBtnEnable, setBtnEnable] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const nameRef = useRef<HTMLInputElement | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();
  const hKaikas = useKaikas();
  const hRpc = useRpc();
  const hKaikasSvc = useKaikasSvc();
  const hHistory = useHistory();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    if (sName.length > 0 && sEmail.length > 0 && sSubject.length > 0 && sBody.length > 0) {
      setBtnEnable(true);
    } else {
      setBtnEnable(false);
    }
    return () => {};
  }, [sBody.length, sEmail.length, sName.length, sSubject.length]);

  useEffect(() => {
    if (props.defaultSelected) {
      setType(props.defaultSelected);
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }

    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const isValidateEmail = (address: string) => {
    if (address.length > 0) {
      // email형식 체크
      if (!Constants.REGEXP_IS_EMAIL.test(address)) {
        DBGMSG(`$$$REGEX address type error`);
        return false;
      }
      return true;
    } else {
      // 입력이 없을 경우 reset
    }
  };

  const handleInquerySendBtn = async () => {
    const rdd = Utils.device.reactDeviceDetect;
    try {
      await hRpc.cobaltRpc.rpcSupportZendeskAddtask({
        name: sName,
        email: sEmail,
        subject: sSubject,
        body: sBody,
        type: sType,
        lang: hR.lang,
        device: rdd.deviceType,
        os: `${rdd.osName} ${rdd.osVersion}`,
        browser: `${rdd.browserName} ${rdd.browserVersion}`,
        phase: `${AppConf.PHASE}`,
        version: `${AppConf.VERSION} (${AppConf.VERSION_CODE})`,
      });
      props.onSuccess();
    } catch (err) {
      hRpc.hanleCommonError({ err });
    }
  };

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv
        style={{
          width: DLG_WIDTH,
          height: DLG_HEIGHT,
          alignItems: 'center',
          paddingLeft: HPADDING,
          paddingRight: HPADDING,
          paddingTop: PADDING_TOP,
          paddingBottom: PADDING_BOTTOM,
          // ...devOutlineFB,
        }}
        onClick={(e) => {
          DBGMSG('onDialog');
          e.stopPropagation();
        }}
      >
        {/* close 버튼 */}
        <ImgBtn
          src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
          containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
          onClick={() => {
            props.onClose();
          }}
        />

        <HEIGHT size={10} />

        {/* 타이틀 */}
        <TextCon text={hR.strings.INQUERY_TITLE} size={14} color={R.colors.black} isBold />

        <HEIGHT size={20} />

        <FlexColumnDiv style={{ alignSelf: 'stretch' }}>
          {/* 문의 유형 */}
          <FlexColumnDiv>
            <FlexRowDiv>
              <TextCon
                text={hR.strings.INQUERY_TYPE}
                size={MB_DEFAULT_FONT_SIZE}
                color={R.colors.black}
                containerStyle={{ height: MB_FORM_HEIGHT }}
                isBold
              />
              <TextCon text={'*'} size={MB_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: MB_FORM_HEIGHT }} isBold />
            </FlexRowDiv>
            <InqueryTypeCombo
              curInqueryType={sType}
              onSelect={(type) => {
                setType(type);
              }}
            />
          </FlexColumnDiv>

          {/* 이름 */}
          <FlexColumnDiv>
            <FlexRowDiv>
              <TextCon
                text={hR.strings.INQUERY_NAME}
                size={MB_DEFAULT_FONT_SIZE}
                color={R.colors.black}
                containerStyle={{ height: MB_FORM_HEIGHT }}
                isBold
              />
              <TextCon text={'*'} size={MB_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: MB_FORM_HEIGHT }} isBold />
            </FlexRowDiv>
            <SRoundInputText
              getRef={(ref) => {
                nameRef.current = ref;
              }}
              fontSize={MB_DEFAULT_FONT_SIZE}
              width={MB_FORM_INPUT_WIDTH}
              height={MB_FORM_HEIGHT}
              value={sName}
              placeHolder={hR.strings.INQUERY_NAME_PLACEHOLDER}
              onBlur={() => {}}
              onChange={(e) => {
                if (e.target.value.length >= 30) return;
                setName(e.target.value);
              }}
              isBold
            />
          </FlexColumnDiv>
          {/* 이메일 */}
          <FlexColumnDiv>
            <FlexRowDiv>
              <TextCon
                text={hR.strings.INQUERY_EMAIL}
                size={MB_DEFAULT_FONT_SIZE}
                color={R.colors.black}
                containerStyle={{ height: MB_FORM_HEIGHT }}
                isBold
              />
              <TextCon text={'*'} size={MB_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: MB_FORM_HEIGHT }} isBold />
            </FlexRowDiv>
            <SRoundInputText
              fontSize={MB_DEFAULT_FONT_SIZE}
              width={MB_FORM_INPUT_WIDTH}
              height={MB_FORM_HEIGHT}
              value={sEmail}
              placeHolder={hR.strings.INQUERY_EMAIL_PLACEHOLDER}
              onBlur={() => {}}
              onChange={(e) => {
                if (e.target.value.length >= 100) return;
                setEmail(e.target.value);
                if (e.target.value.length <= 100 && e.target.value.length !== 0) {
                  isValidateEmail(e.target.value);
                }
              }}
              isBold
            />
          </FlexColumnDiv>

          {/* 문의 내용 */}
          <FlexColumnDiv>
            <FlexRowDiv>
              <TextCon
                text={hR.strings.INQUERY_SUBJECT}
                size={MB_DEFAULT_FONT_SIZE}
                color={R.colors.black}
                containerStyle={{ height: MB_FORM_HEIGHT }}
                isBold
              />
              <TextCon text={'*'} size={MB_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: MB_FORM_HEIGHT }} isBold />
            </FlexRowDiv>
            <SRoundInputText
              fontSize={MB_DEFAULT_FONT_SIZE}
              width={MB_FORM_INPUT_WIDTH}
              height={FORM_HEIGHT}
              value={sSubject}
              placeHolder={hR.strings.INQUERY_SUBJECT_PLACEHOLDER}
              onBlur={() => {}}
              onChange={(e) => {
                if (e.target.value.length >= 100) return;
                setSubject(e.target.value);
              }}
            />
            <HEIGHT size={12} />

            <SRoundInputTextArea
              //
              fontSize={MB_DEFAULT_FONT_SIZE}
              width={MB_FORM_INPUT_WIDTH}
              value={sBody}
              height={140}
              placeHolder={hR.strings.INQUERY_BODY_PALCEHOLDER}
              resizeNone
              onChange={(e) => {
                if (e.target.value.length >= 1000) return;
                setBody(e.target.value);
              }}
            />
          </FlexColumnDiv>
          {/* 보내기 버튼 */}
          <FlexRowDiv style={{ alignSelf: 'center', paddingTop: 20 }}>
            <RoundTxtBtn
              enable={sBtnEnable}
              text={hR.strings.INQUERY_BTN}
              width={MB_FORM_INPUT_WIDTH}
              height={MB_FORM_HEIGHT}
              fontSize={MB_DEFAULT_FONT_SIZE}
              onClick={() => {
                handleInquerySendBtn();
              }}
            />
          </FlexRowDiv>
        </FlexColumnDiv>
      </FlexColumnDiv>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          width: DLG_WIDTH,
          height: DLG_HEIGHT,
          alignItems: 'center',
          paddingLeft: HPADDING,
          paddingRight: HPADDING,
          paddingTop: PADDING_TOP,
          paddingBottom: PADDING_BOTTOM,
          // ...devOutlineFB,
        }}
        onClick={(e) => {
          DBGMSG('onDialog');
          e.stopPropagation();
        }}
      >
        {/* close 버튼 */}
        <ImgBtn
          src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
          containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
          onClick={() => {
            props.onClose();
          }}
        />

        <HEIGHT size={10} />

        {/* 타이틀 */}
        <TextCon text={hR.strings.INQUERY_TITLE} size={TITLE_FOTNSIZE} color={R.colors.black} isBold />

        <HEIGHT size={TITLE_MARGIN_BOTTOM} />

        <FlexColumnDiv style={{ alignSelf: 'stretch' }}>
          {/* 문의 유형 */}
          <FlexRowDiv>
            <TextCon
              text={hR.strings.INQUERY_TYPE}
              size={DT_DEFAULT_FONT_SIZE}
              color={R.colors.black}
              containerStyle={{ height: FORM_HEIGHT }}
              isBold
            />
            <TextCon text={'*'} size={DT_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: FORM_HEIGHT }} isBold />
            <FlexDiv style={{ marginLeft: 'auto' }}>
              <InqueryTypeCombo
                curInqueryType={sType}
                onSelect={(type) => {
                  setType(type);
                }}
              />
            </FlexDiv>
          </FlexRowDiv>

          <HEIGHT size={12} />
          {/* 이름 */}
          <FlexRowDiv>
            <TextCon
              text={hR.strings.INQUERY_NAME}
              size={DT_DEFAULT_FONT_SIZE}
              color={R.colors.black}
              containerStyle={{ height: FORM_HEIGHT }}
              isBold
            />
            <TextCon text={'*'} size={DT_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: FORM_HEIGHT }} isBold />
            <FlexDiv style={{ marginLeft: 'auto' }}>
              <SRoundInputText
                fontSize={DT_DEFAULT_FONT_SIZE}
                width={380}
                height={FORM_HEIGHT}
                value={sName}
                placeHolder={hR.strings.INQUERY_NAME_PLACEHOLDER}
                onBlur={() => {}}
                onChange={(e) => {
                  if (e.target.value.length >= 30) return;
                  setName(e.target.value);
                }}
              />
            </FlexDiv>
          </FlexRowDiv>
          <HEIGHT size={12} />
          {/* 이메일 */}
          <FlexRowDiv>
            <TextCon
              text={hR.strings.INQUERY_EMAIL}
              size={DT_DEFAULT_FONT_SIZE}
              color={R.colors.black}
              containerStyle={{ height: FORM_HEIGHT }}
              isBold
            />
            <TextCon text={'*'} size={DT_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: FORM_HEIGHT }} isBold />
            <FlexDiv style={{ marginLeft: 'auto' }}>
              <SRoundInputText
                fontSize={DT_DEFAULT_FONT_SIZE}
                width={380}
                height={FORM_HEIGHT}
                value={sEmail}
                placeHolder={hR.strings.INQUERY_EMAIL_PLACEHOLDER}
                onBlur={() => {}}
                onChange={(e) => {
                  if (e.target.value.length >= 100) return;
                  setEmail(e.target.value);
                  if (e.target.value.length <= 100 && e.target.value.length !== 0) {
                    isValidateEmail(e.target.value);
                  }
                }}
              />
            </FlexDiv>
          </FlexRowDiv>
          <HEIGHT size={12} />
          {/* 문의 내용 */}
          <FlexColumnDiv>
            <FlexRowDiv>
              <TextCon
                text={hR.strings.INQUERY_SUBJECT}
                size={DT_DEFAULT_FONT_SIZE}
                color={R.colors.black}
                containerStyle={{ height: FORM_HEIGHT }}
                isBold
              />
              <TextCon text={'*'} size={DT_DEFAULT_FONT_SIZE} color={R.colors.purpleishPink} containerStyle={{ height: FORM_HEIGHT }} isBold />
            </FlexRowDiv>
            <SRoundInputText
              fontSize={DT_DEFAULT_FONT_SIZE}
              width={502}
              height={FORM_HEIGHT}
              value={sSubject}
              placeHolder={hR.strings.INQUERY_SUBJECT_PLACEHOLDER}
              onBlur={() => {}}
              onChange={(e) => {
                if (e.target.value.length >= 100) return;
                setSubject(e.target.value);
              }}
            />
            <HEIGHT size={12} />
            <SRoundInputTextArea
              //
              fontSize={DT_DEFAULT_FONT_SIZE}
              width={502}
              value={sBody}
              height={280}
              placeHolder={hR.strings.INQUERY_BODY_PALCEHOLDER}
              resizeNone
              onChange={(e) => {
                if (e.target.value.length >= 1000) return;
                setBody(e.target.value);
              }}
            />
          </FlexColumnDiv>
          {/* 보내기 버튼 */}
          <FlexRowDiv style={{ alignSelf: 'center', paddingTop: 40 }}>
            <RoundTxtBtn
              enable={sBtnEnable}
              text={hR.strings.INQUERY_BTN}
              width={355}
              height={48}
              fontSize={DT_DEFAULT_FONT_SIZE}
              onClick={() => {
                handleInquerySendBtn();
              }}
            />
          </FlexRowDiv>
        </FlexColumnDiv>
      </FlexColumnDiv>
    );
}
