import React from 'react';
import { useEffect } from 'react';
import { useKaikas } from 'src/hooks/useKaikas';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';

export type WalletConnectJsxArgs = {
  onConnect: () => void;
};

export const WalletConnectJsx = ({ onConnect }: WalletConnectJsxArgs) => {
  const hR = useLocalSettings();
  const hKaikas = useKaikas();

  useEffect(() => {
    return () => {};
  }, [hKaikas.kaikasState.isEnable]);

  return (
    <FlexColumnDiv
      style={{
        width: 378,
        height: 365,
        padding: 40,
        alignItems: 'cetner',
        // ...devOutlineF
      }}
    >
      <TextCon
        text={hR.strings.WALLET_CONNECT}
        textStyle={{ textAlign: 'center' }}
        color={R.colors.black}
        size={22}
        isBold
        containerStyle={{
          // ...devOutlineF,
          justifyContent: 'center',
        }}
      />
      <HEIGHT size={30} />
      <Img src={[R.images.common_iv_kaikas_wallet3x, 144, 144]} />
      <FlexRowDiv style={{ marginTop: 'auto', justifyContent: 'center' }}>
        <RoundTxtBtn
          text={hR.strings.WALLET_CONNECT_BTN}
          width={298}
          height={48}
          fontSize={14}
          onClick={() => {
            onConnect();
          }}
        />
      </FlexRowDiv>
    </FlexColumnDiv>
  );
};
