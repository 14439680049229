/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useMemo } from 'react';
import { useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import ScreenInfo from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { DisplaySrc_t } from 'src/model/model';
import { CbtAssetCardInfo_i, CbtAssetType_e, CbtColCertifyType_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { AssetDisplay } from 'src/ui/common_component/AssetDisplay/AssetDisplay';
import { AssetDisplayThumb, WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtnAdvType2 } from 'src/ui/common_component/button/RoundTxtBtnAdvType2';
import {
  PropsCommonBox,
  SPRING_PROPS_ANIM_1_Default,
  SPRING_PROPS_ANIM_1_OnMouseEnter,
  SPRING_PROPS_ANIM_1_OnMouseLeave,
} from 'src/ui/layout_constant';
import { DBGMSG, DBGMSGW } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export type AssetCardProps = {
  assetInfo: CbtAssetCardInfo_i;
  width?: number | string;
  height?: number | string;
  isAnim?: boolean;
  onClick?: () => void;
  enableSaleBtn?: boolean; // 판매하기 / 판매편집 버튼 보이게 할건지
  onSale?: boolean; // 판매중인지
  onRegSale?: () => void; // 판매하기 등록 성공
  onEditSale?: () => void; // 판매하기 등록 성공
};

/**************************************
 * !! constant
 **************************************/
// DESKTOP
const DT_CARD_WIDTH = 276;
const DT_CARD_HEIGHT = 396;
const DT_CARD_HEIGHT_WITH_SALEBTN = 414;
const DT_CARD_TOP_PADDING = 20;
const DT_CARD_HPADDING = 20;
const DT_CARD_BODY_WIDTH = DT_CARD_WIDTH - DT_CARD_HPADDING * 2;
const DT_COL_IMG_SIZE = 24;
const DT_COL_IMG_VPADDING = 20;
const DT_COL_NAME_FOTNSIZE = 14;
const DT_ASSET_NAME_HEIGHT = 20;
const DT_ASSET_NAME_FONTSIZE = 14;
const DT_ASSET_NAME_BOTTOM_MARGIN = 8;
const DT_OWNER_HEIGHT = 24;
const DT_OWNER_FONTSIZE = 16;
const DT_SALE_BTN_FONTSIZE = 12;
const DT_SALE_BTN_HEIGHT = 34;

// MOBILE
const MB_CARD_WIDTH = 280;
const MB_CARD_HEIGHT = 390;
const MB_CARD_HEIGHT_WITH_SALEBTN = 394;
const MB_CARD_TOP_PADDING = 16;
const MB_CARD_HPADDING = 16;
const MB_CARD_BODY_WIDTH = MB_CARD_WIDTH - MB_CARD_HPADDING * 2;
const MB_COL_IMG_SIZE = 20;
const MB_COL_IMG_VPADDING = 16;
const MB_COL_NAME_FOTNSIZE = 12;
const MB_ASSET_NAME_HEIGHT = 18;
const MB_ASSET_NAME_FONTSIZE = 12;
const MB_ASSET_NAME_BOTTOM_MARGIN = 6;
const MB_OWNER_HEIGHT = 20;
const MB_OWNER_FONTSIZE = 14;
const MB_SALE_BTN_FONTSIZE = 10;
const MB_SALE_BTN_HEIGHT = 28;

export const ASSET_CARD_WIDTH = ScreenInfo.isMobile() ? MB_CARD_WIDTH : DT_CARD_WIDTH;
export const ASSET_CARD_HEIGHT = ScreenInfo.isMobile() ? MB_CARD_HEIGHT : DT_CARD_HEIGHT;
export const ASSET_CARD_HEIGHT_WITH_SALEBTN = ScreenInfo.isMobile() ? MB_CARD_HEIGHT_WITH_SALEBTN : DT_CARD_HEIGHT_WITH_SALEBTN;
const CARD_TOP_PADDING = ScreenInfo.isMobile() ? MB_CARD_TOP_PADDING : DT_CARD_TOP_PADDING;
const CARD_HPADDING = ScreenInfo.isMobile() ? MB_CARD_HPADDING : DT_CARD_HPADDING;
const CARD_BODY_WIDTH = ScreenInfo.isMobile() ? MB_CARD_BODY_WIDTH : DT_CARD_BODY_WIDTH;
const COL_IMG_SIZE = ScreenInfo.isMobile() ? MB_COL_IMG_SIZE : DT_COL_IMG_SIZE;
const COL_IMG_VPADDING = ScreenInfo.isMobile() ? MB_COL_IMG_VPADDING : DT_COL_IMG_VPADDING;
const COL_NAME_FOTNSIZE = ScreenInfo.isMobile() ? MB_COL_NAME_FOTNSIZE : DT_COL_NAME_FOTNSIZE;
const ASSET_NAME_HEIGHT = ScreenInfo.isMobile() ? MB_ASSET_NAME_HEIGHT : DT_ASSET_NAME_HEIGHT;
const ASSET_NAME_FONTSIZE = ScreenInfo.isMobile() ? MB_ASSET_NAME_FONTSIZE : DT_ASSET_NAME_FONTSIZE;
const OWENER_MARGIN_TOP = ScreenInfo.isMobile() ? MB_ASSET_NAME_BOTTOM_MARGIN : DT_ASSET_NAME_BOTTOM_MARGIN;
const OWNER_HEIGHT = ScreenInfo.isMobile() ? MB_OWNER_HEIGHT : DT_OWNER_HEIGHT;
const OWNER_FONTSIZE = ScreenInfo.isMobile() ? MB_OWNER_FONTSIZE : DT_OWNER_FONTSIZE;
const SALE_BTN_FONTSIZE = ScreenInfo.isMobile() ? MB_SALE_BTN_FONTSIZE : DT_SALE_BTN_FONTSIZE;
const SALE_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_SALE_BTN_HEIGHT : DT_SALE_BTN_HEIGHT;

export function AssetCard({ assetInfo, isAnim = false, onClick, enableSaleBtn = false, onSale, onRegSale, onEditSale }: AssetCardProps) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hDlgFactory = useDlgFactory();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    // DBGMSG('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  let assetDpInfo: DisplaySrc_t;

  if (assetInfo.nft_aud) {
    assetDpInfo = { kind: 'ReqAudio', img: assetInfo.nft_img, audio: assetInfo.nft_aud };
  } else if (assetInfo.nft_vid) {
    assetDpInfo = { kind: 'ReqVideo', img: assetInfo.nft_img, video: assetInfo.nft_vid };
  } else {
    assetDpInfo = { kind: 'ReqImage', img: assetInfo.nft_img };
  }

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  return (
    <AnimatedFlexColumnDiv
      style={{
        width: ASSET_CARD_WIDTH,
        // height: ASSET_CARD_HEIGHT,
        cursor: onClick ? 'pointer' : 'auto',
        ...PropsCommonBox,
        ...hMouseProps,
        // ...devOutlineF,
      }}
      onMouseEnter={() => {
        // DBGMSG(`onMouseEnter: `);
        // set({ backgroundColor: R.colors.paleGrey, transform: 'scale(1.005)' });
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
      }}
      onMouseLeave={() => {
        // DBGMSG(`onMouseLeave: `);
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
      }}
      onClick={() => {
        DBGMSG('onClick');
        onClick && onClick();
      }}
    >
      {/* 상단부 */}
      <FlexColumnDiv style={{ alignItems: 'center' }}>
        {/*  Collection display 이름 */}
        <FlexRowDiv style={{ alignItems: 'center', width: CARD_BODY_WIDTH, marginTop: CARD_TOP_PADDING, ...devOutline }}>
          <FlexDiv style={{ height: COL_IMG_SIZE }}>
            {assetInfo.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
              <Img src={[R.images.common_ic_verify3x, COL_IMG_SIZE, COL_IMG_SIZE]} containerStyle={{ marginRight: 4 }} />
            )}
          </FlexDiv>
          <TextCon
            color={R.colors.black}
            isBold
            size={COL_NAME_FOTNSIZE}
            text={assetInfo.col_info.dp_name}
            containerStyle={{}}
            textStyle={{ width: CARD_BODY_WIDTH - COL_IMG_SIZE - 4, overflow: 'hidden', textOverflow: 'ellipsis' }}
          />
        </FlexRowDiv>

        <HEIGHT size={COL_IMG_VPADDING} />

        {/*  Asset 대표이미지 */}
        <AssetDisplayThumb
          whereToUse={WhereToUse_e.ForMyAssetCard}
          playerSrc={assetDpInfo}
          width={CARD_BODY_WIDTH}
          height={CARD_BODY_WIDTH}
          hiddenBorder
          isBlock={assetInfo.blocked === true}
        />

        <HEIGHT size={COL_IMG_VPADDING} />

        {/*  Asset 이름 */}
        <FlexRowDiv
          style={{
            height: ASSET_NAME_HEIGHT,
            alignItems: 'center',
            width: CARD_BODY_WIDTH,
            // marginTop: 20,
            ...devOutline,
          }}
        >
          <TextCon
            color={R.colors.black}
            isBold
            size={ASSET_NAME_FONTSIZE}
            text={assetInfo.nft_name}
            textStyle={{ width: CARD_BODY_WIDTH, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          />
        </FlexRowDiv>

        {/*  판매 등록 / 판매 편집 */}
        {enableSaleBtn ? (
          <>
            <HEIGHT size={COL_IMG_VPADDING} />
            <FlexRowDiv
              style={{
                width: CARD_BODY_WIDTH,
                ...devOutline,
              }}
            >
              <RoundTxtBtnAdvType2
                text={onSale ? hR.strings.COMMON_SALE_EDIT_BTN : hR.strings.COMMON_SALE_BTN}
                width={CARD_BODY_WIDTH}
                fontSize={SALE_BTN_FONTSIZE}
                height={SALE_BTN_HEIGHT}
                enable={!assetInfo.blocked}
                onClick={(e) => {
                  if (assetInfo.sale_id === undefined) {
                    DBGMSGW('what');
                    return;
                  }

                  // 판매 편집
                  if (onSale) {
                    hDlgFactory.showSellJsx({
                      // fetched: res,
                      req: {
                        mode: 'EDIT',
                        tx: { sale_id: assetInfo.sale_id },
                      },
                      onSuccess: ({ prc_info: priceInfo }) => {
                        DBGMSG('onSale');
                        DBGMSG(priceInfo);
                        // reload();
                        hDlgFactory.hideSellJsx();
                        onEditSale && onEditSale();
                      },
                      onSaleStop: () => {
                        DBGMSG('onStop');
                        hDlgFactory.hideSellJsx();
                        // reload();
                        onEditSale && onEditSale();
                      },
                      onClose: () => {
                        DBGMSG('onClose');
                        hDlgFactory.hideSellJsx();
                      },
                    });
                  } else {
                    // 판매 등록
                    if (assetInfo.sale_id !== 0) {
                      DBGMSGW('what');
                      e.stopPropagation();
                      return;
                    }

                    if (assetInfo.sid === undefined || assetInfo.entity_id === undefined) {
                      DBGMSGW('what');
                      e.stopPropagation();
                      return;
                    }

                    hDlgFactory.showSellJsx({
                      // fetched: res,
                      req: {
                        mode: 'NEW',
                        tx: {
                          sid: assetInfo.sid,
                          entity_id: assetInfo.entity_id,
                          asset_id: assetInfo.asset_id,
                        },
                      },
                      onSuccess: ({ prc_info: priceInfo }) => {
                        DBGMSG('onSale');
                        DBGMSG(priceInfo);
                        // reload();
                        hDlgFactory.hideSellJsx();
                        onRegSale && onRegSale();
                      },
                      onSaleStop: () => {
                        DBGMSG('onStop');
                        hDlgFactory.hideSellJsx();
                      },
                      onClose: () => {
                        DBGMSG('onClose');
                        hDlgFactory.hideSellJsx();
                      },
                    });
                  }
                  e.stopPropagation();
                }}
              />
            </FlexRowDiv>
          </>
        ) : (
          <>
            <HEIGHT size={OWENER_MARGIN_TOP} />
            <FlexRowDiv
              style={{
                height: OWNER_HEIGHT,
                alignItems: 'center',
                width: CARD_BODY_WIDTH,
                ...devOutline,
              }}
            >
              <TextCon
                color={R.colors.purpleishBlue}
                isBold
                size={OWNER_FONTSIZE}
                // text={'에셋 이름이 길면 이렇게 처리해주세요...'}
                text={assetInfo.owner}
              />
            </FlexRowDiv>
          </>
        )}
        <HEIGHT size={COL_IMG_VPADDING} />
      </FlexColumnDiv>
    </AnimatedFlexColumnDiv>
  );
}
