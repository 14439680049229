/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtResPriceInfo_i } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { PriceSimple } from 'src/ui/common_component/user/PriceSimple';
import { CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/

type Props = {
  incomeTxt: string;
  spendTxt: string;
  balanceTxt: string;
  income: CbtResPriceInfo_i;
  spend: CbtResPriceInfo_i;
  balance: CbtResPriceInfo_i;
  roundDecimalPoint?: number;
};

export function ProperyOverview({
  //
  incomeTxt,
  spendTxt,
  balanceTxt,
  balance,
  income,
  spend,
  roundDecimalPoint = CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
}: Props) {
  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv style={{}}>
        {/* 총 받은 POINT */}
        <FlexRowDiv
          style={{
            marginTop: 18,
            // marginBottom: 18,
            marginLeft: 16,
            marginRight: 16,
            justifyContent: 'space-between',
            height: 47,
            alignItems: 'flex-end',
          }}
        >
          <TextCon
            //
            text={incomeTxt}
            size={10}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <PriceSimple prc_info={income} priceFontSize={16} unitFontSize={10} />
        </FlexRowDiv>
        <HEIGHT size={22} />
        {/* 총 사용 POINT */}
        <FlexRowDiv
          style={{
            marginLeft: 16,
            marginRight: 16,
            justifyContent: 'space-between',
            height: 47,
            alignItems: 'flex-end',
          }}
        >
          <TextCon
            //
            text={spendTxt}
            size={10}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <PriceSimple prc_info={spend} priceFontSize={16} unitFontSize={10} />
        </FlexRowDiv>
        <HEIGHT size={22} />
        {/* POINT 잔고 */}
        <FlexRowDiv
          style={{
            marginLeft: 16,
            marginRight: 16,
            justifyContent: 'space-between',
            height: 47,
            alignItems: 'flex-end',
          }}
        >
          <TextCon
            //
            text={balanceTxt}
            size={10}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <PriceSimple prc_info={balance} priceFontSize={16} unitFontSize={10} />
        </FlexRowDiv>
        <HEIGHT size={18} />
      </FlexColumnDiv>
    );
  } else {
    return (
      <>
        <FlexRowDiv>
          <FlexColumnDiv
            style={{
              width: ScreenInfo.contentsWidth / 3,
              height: 166,
              paddingLeft: 50,
              justifyContent: 'center',
              // ...devOutlineF
            }}
          >
            <TextCon text={incomeTxt} size={16} color={R.colors.blueGrey} />
            <HEIGHT size={4} />
            <PriceSimple prc_info={income} />
          </FlexColumnDiv>
          <FlexColumnDiv
            style={{
              width: ScreenInfo.contentsWidth / 3,
              height: 166,
              paddingLeft: 50,
              justifyContent: 'center',
              // ...devOutlineF
            }}
          >
            <TextCon text={spendTxt} size={16} color={R.colors.blueGrey} />
            <HEIGHT size={4} />
            <PriceSimple prc_info={spend} />
          </FlexColumnDiv>
          <FlexColumnDiv
            style={{
              width: ScreenInfo.contentsWidth / 3,
              height: 166,
              paddingLeft: 50,
              justifyContent: 'center',
              // ...devOutlineF
            }}
          >
            <TextCon text={balanceTxt} size={16} color={R.colors.blueGrey} />
            <HEIGHT size={4} />
            <PriceSimple prc_info={balance} />
          </FlexColumnDiv>
        </FlexRowDiv>
      </>
    );
  }
}
