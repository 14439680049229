/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CbtMarketSearchSort_e } from 'src/model/model';
import { CbtPgInfo_i } from 'src/model/rpcModel';
import {
  RPC_ASSETMGR_BOUGHT_LIST,
  RPC_ASSETMGR_COUNT,
  RPC_ASSETMGR_INVENTORY_ASSET_LIST,
  RPC_ASSETMGR_INVENTORY_LIST,
  RPC_ASSETMGR_ISSUE_ASSET_LIST,
  RPC_ASSETMGR_ISSUE_LIST,
  RPC_ASSETMGR_SALE_LIST,
  RPC_ASSETMGR_SOLD_LIST,
} from 'src/model/rpcType';

/****************************************/
// state
/****************************************/
export enum UserDetailMenuType_e {
  INVEN = 'INVEN',
  ISSUE = 'ISSUE',
  SALE = 'SALE',
  SOLD = 'SOLD',
  BOUGHT = 'BOUGHT',
}

export type UserDetailState_t = {
  // 사용자정보
  user: {
    ucode?: string;
    userInfo?: {
      scope?: string;
    };
  };

  menu: UserDetailMenuType_e;
  querySearch: string;
  querySortOrder: CbtMarketSearchSort_e;

  // 자산개수
  menuCnt: {
    needUpdate: boolean;
    cnt?: RPC_ASSETMGR_COUNT.Rx;
  };

  // 인벤토리
  inventory: {
    astgNeedUpdate: boolean;
    astNeedUpdate: boolean;
    astg?: {
      // inventory 에셋그룹 목록
      lastRx: RPC_ASSETMGR_INVENTORY_LIST.Rx;
      lastPgInfo: CbtPgInfo_i;
      list: RPC_ASSETMGR_INVENTORY_LIST.Rx['astg_list'];
    };
    ast?: {
      // inventory 에셋그룹 상세 목록
      lastRx: RPC_ASSETMGR_INVENTORY_ASSET_LIST.Rx;
      lastPgInfo: CbtPgInfo_i;
      list: RPC_ASSETMGR_INVENTORY_ASSET_LIST.Rx['ast_list'];
    };
  };

  // 발행 에셋
  issue: {
    astgNeedUpdate: boolean;
    astNeedUpdate: boolean;
    astg?: {
      // ISSUE 에셋그룹 목록
      lastRx: RPC_ASSETMGR_ISSUE_LIST.Rx;
      lastPgInfo: CbtPgInfo_i;
      list: RPC_ASSETMGR_ISSUE_LIST.Rx['astg_list'];
    };
    ast?: {
      // ISSUE 에셋그룹 상세 목록
      lastRx: RPC_ASSETMGR_ISSUE_ASSET_LIST.Rx;
      lastPgInfo: CbtPgInfo_i;
      list: RPC_ASSETMGR_ISSUE_ASSET_LIST.Rx['ast_list'];
    };
  };

  // 판매 중
  sale: {
    listNeedUpdate: boolean;
    lastRx?: RPC_ASSETMGR_SALE_LIST.Rx;
    lastPgInfo?: CbtPgInfo_i;
    list?: RPC_ASSETMGR_SALE_LIST.Rx['sale_list'];
  };

  // 판매 됨
  sold: {
    listNeedUpdate: boolean;
    lastRx?: RPC_ASSETMGR_SOLD_LIST.Rx;
    lastPgInfo?: CbtPgInfo_i;
    list?: RPC_ASSETMGR_SOLD_LIST.Rx['sold_list'];
  };

  // 구매함
  bought: {
    listNeedUpdate: boolean;
    lastRx?: RPC_ASSETMGR_BOUGHT_LIST.Rx;
    lastPgInfo?: CbtPgInfo_i;
    list?: RPC_ASSETMGR_BOUGHT_LIST.Rx['sold_list'];
  };
};

const initialState: UserDetailState_t = {
  user: {},
  menu: UserDetailMenuType_e.INVEN,
  querySearch: '',
  querySortOrder: CbtMarketSearchSort_e.ListingNewest,
  menuCnt: {
    needUpdate: true,
  },
  // 인벤토리
  inventory: {
    astgNeedUpdate: true,
    astNeedUpdate: true,
  },
  // 생성 에셋
  issue: {
    astgNeedUpdate: true,
    astNeedUpdate: true,
  },
  // 판매 중
  sale: { listNeedUpdate: true },
  // 판매 됨
  sold: { listNeedUpdate: true },
  // 구매함
  bought: { listNeedUpdate: true },
};

const userDetailSlice = createSlice({
  name: 'userDetailState',
  initialState: initialState,
  reducers: {
    clear: (state) => {
      state = {
        ...initialState,
      };
      return state;
    },
    setState: (state, action: PayloadAction<UserDetailState_t>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    changeState: (state, action: PayloadAction<Partial<UserDetailState_t>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export default userDetailSlice;
