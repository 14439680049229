/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, HTMLProps } from 'react';
import { animated, config, useSpring } from 'react-spring';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexRowDiv } from 'src/ui/base_component/etc';
import { DBGMSG } from 'src/util/utils';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

interface Props {
  key?: string | number;
  ref?: any;
  text?: string;
  size?: string | number;
  color?: string;
  isBold?: boolean;
  isItelic?: boolean;
  isUnderline?: boolean;
  fontFamily?: string;
  letterSpacing?: number | string;
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  onMouseDefalutProp?: CSSProperties;
  onMouseEnterProp?: CSSProperties;
  onMouseLeaveProp?: CSSProperties;
  enable?: boolean;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
}

const AnimatedText = styled(animated.span)`
  font-family: ${(props: Props) => props.fontFamily || R.fonts.SpoqaJP};
  margin-right: ${(props: Props) => (props.isItelic ? 2 : 0)};
  font-size: ${(props: Props) => `${props.size}px`};
  font-weight: ${(props: Props) => (props.isBold ? 'bold' : 'normal')};
  font-style: ${(props: Props) => (props.isItelic ? 'italic' : 'normal')};
  text-decoration: ${(props: Props) => (props.isUnderline ? 'underline' : 'none')};
  color: ${(props: Props) => props.color ?? R.colors.rn.blue};
  letter-spacing: ${(props: Props) => props.letterSpacing};
  white-space: pre-wrap;
`;

export default function AnimatedBtn(props: Props) {
  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return {
      ...props.onMouseDefalutProp,
      config: { ...config.gentle, duration: 50 },
    };
  });

  return (
    <AnimatedFlexColumnDiv
      // key={props.key}
      style={{
        cursor: 'pointer',
        ...devOutline,
        ...props.containerStyle,
        ...hMouseProps,
        // backgroundColor: R.colors.devColor4a,
      }}
      onMouseEnter={() => {
        DBGMSG('onMouseEnter');
        props.enable !== false && set({ ...props.onMouseEnterProp });
      }}
      onMouseLeave={() => {
        DBGMSG('onMouseLeave');
        props.enable !== false && set({ ...props.onMouseLeaveProp });
      }}
      onClick={props.onClick}
    >
      <FlexRowDiv style={{ alignItems: 'center' }}>
        <AnimatedText {...props} style={props.textStyle}>
          {props.text}
        </AnimatedText>
      </FlexRowDiv>
    </AnimatedFlexColumnDiv>
  );
}
