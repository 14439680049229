/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties } from 'react';
import { FlexDiv } from 'src/ui/base_component/etc';
import { DBGMSG } from 'src/util/utils';

type Props = {
  src: [string | null, number | string | undefined, number | string | undefined];
  onClick?: () => void;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
  border?: CSSProperties;
  getRef?: (ref: any) => void;
};

export function Img(props: Props) {
  return (
    <FlexDiv
      style={{
        justifyContent: 'center',
        // alignItems: props.imgStyle?.objectFit === 'contain' ? undefined : 'center',
        // alignSelf: 'center',
        ...props.containerStyle,
        // ...devOutlineFF,
      }}
      onClick={(e) => {
        props.onClick && props.onClick();
      }}
    >
      <img
        // ref={(ref) => {
        //   DBGMSG('ref');
        //   DBGMSG(ref);
        //   if (props.getRef !== undefined) {
        //     props.getRef(ref);
        //   }
        // }}
        src={props.src[0] === null ? `https://placehold.it/${props.src[1]}x${props.src[2]}` : props.src[0]}
        alt=""
        draggable={false}
        style={{
          width: props.src[1],
          height: props.src[2],
          ...props.border,
          ...props.imgStyle,
          userSelect: 'none',

          // -moz-user-select: none;
          // -webkit-user-select: none;
          // -ms-user-select: none;
          // user-select: none;
          // -webkit-user-drag: none;
          // user-drag: none;
          // -webkit-touch-callout: none;
        }}
      />
    </FlexDiv>
  );
}
