/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties, HTMLProps, useState } from 'react';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF } from 'src/ui/base_component/etc';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  text?: string;
  width?: number;
  height?: number;
  fontsize?: number;
  containerStyle?: CSSProperties;
  onClick: HTMLProps<HTMLDivElement>['onClick'];
  enable?: boolean;
  ic?: string;
};

export function NormalBtn({
  //
  text,
  width,
  height,
  fontsize,
  onClick,
  containerStyle,
  enable = true,
  ic,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseOver, setMouseOver] = useState(false);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <RoundTxtBtnAdv
      enable={enable}
      label={text ?? 'btn'}
      width={width}
      height={height}
      fontSize={fontsize}
      fontColor={R.colors.white}
      bgColor={sMouseOver ? R.colors.blueBlue : R.colors.purpleishBlue}
      // borderColor={sMouseOver ? R.colors.purpleishBlue : R.colors.line}
      ic={ic}
      onClick={(e) => {
        onClick && onClick(e);
      }}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      containerStyle={containerStyle}
    />
  );
}
