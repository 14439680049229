/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useEffect, useRef } from 'react';
import SockJS from 'sockjs-client';
import {
  getStompClient,
  getStompLastPingPongTime,
  getStompSubcription,
  getStompWebSocket,
  setStompClient,
  setStompLastPingPongTime,
  setStompSubcription,
  setStompWebSocket,
} from 'src/App';
import { AppConf, isAlpha } from 'src/conf/AppConf';
import { useAuth } from 'src/hooks/useAuth';
import { AppEventType, CbtStompMsgCode_e, CbtStomp_i, messageService } from 'src/rx/messageService';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import Stomp from 'stompjs';

export function useStompJS() {
  const { authActs, authState, isLogin } = useAuth();
  const isConnectingRef = useRef(false);

  const stompCleanup = () => {
    const stompWebSocket = getStompWebSocket();
    const stompClient = getStompClient();
    const stompSubcription = getStompSubcription();

    return new Promise((resolve, reject) => {
      DBGMSG(`$$$$STOMPJS stompCleanup begin`);
      // StompClient 정리
      if (stompClient) {
        DBGMSG(`$$$$STOMPJS StompClient connected: ${stompClient.connected}`);
        DBGMSG(stompClient);
        DBGMSG(stompSubcription);
        stompSubcription?.unsubscribe();

        setStompSubcription(null);
        if (stompClient.connected) {
          stompClient.disconnect(() => {
            DBGMSG(`$$$$STOMPJS disconnect callback`);
            setStompClient(null);

            // 웹소켓 정리
            // https://developer.mozilla.org/ko/docs/Web/API/WebSocket/readyState
            // WebSocket.CONNECTING
            // WebSocket.OPEN
            // WebSocket.CLOSED
            // WebSocket.CLOSING
            if (stompWebSocket) {
              DBGMSG(`$$$$STOMPJS WebSocket readyState: ${stompWebSocket.readyState}`);
              DBGMSG(stompWebSocket);
              stompWebSocket.close();
              setStompWebSocket(null);
            }

            setStompWebSocket(null);
            setStompClient(null);
            setStompSubcription(null);
            resolve(true);
          });
        } else {
          setStompWebSocket(null);
          setStompClient(null);
          setStompSubcription(null);
          resolve(true);
        }
      } else {
        DBGMSG(`$$$$STOMPJS StompClient not connected return`);
        setStompWebSocket(null);
        setStompClient(null);
        setStompSubcription(null);
        resolve(true);
      }
    });
  };

  // stomp 연결
  const stompConnect = async () => {
    if (isConnectingRef.current === true) {
      DBGMSG(`$$$$STOMPJS already connecting`);
      return;
    }
    isConnectingRef.current = true;
    DBGMSG(`$$$$STOMPJS authState.authStatus : ${authState.authStatus}`);
    DBGMSG(`$$$$STOMPJS authState.authStatus : ${isLogin}`);
    const stompWebSocket = getStompWebSocket();
    const stompClient = getStompClient();
    const stompSubcription = getStompSubcription();

    DBGMSG(`$$$$STOMPJS stompConnect begin`);
    // 이미 연결중인지 체크
    if (stompClient) {
      DBGMSG(`$$$$STOMPJS stompConnect stompClient.connected: `, stompClient.connected);
      if (stompClient.connected) {
        DBGMSG(`$$$$STOMPJS already connected: ${stompClient.connected}`);
        isConnectingRef.current = false;
        return;
      }
    }

    await stompCleanup();

    const accessToken = authState.authCommonRes?.token;
    const mkey = authState.authCommonRes?.mkey;
    if (!accessToken || !mkey) {
      DBGMSG('$$$$STOMPJS what');
      isConnectingRef.current = false;
      return;
    }

    try {
      DBGMSG(`$$$$STOMPJS accessToken: ${accessToken}`);
      DBGMSG(`$$$$STOMPJS mkey: ${mkey}`);

      // const socket = new SockJS('https://cdap-alpha-rpc.cocone.jp/ws');
      const socketUrl = `${AppConf.HOST_URLS.rpc}/ws`;
      const webSocket = new SockJS(socketUrl);
      const _stompClient = Stomp.over(webSocket);
      setStompWebSocket(webSocket);
      setStompClient(_stompClient);
      DBGMSG(`$$$$STOMPJS stompClient: `, JSON.stringify(_stompClient, undefined, 4));

      _stompClient.debug = (debug: string) => {
        DBGMSG('$$$$STOMPJS ', debug);
        if (debug.includes('>>> PING') || debug.includes('<<< PONG')) {
          setStompLastPingPongTime(Utils.date.now().getTime());
        } else if (debug.includes('>>> SUBSCRIBE') || debug.includes('<<< CONNECTED')) {
          DBGMSG('$$$$STOMPJS ', debug);
        }
      };
      _stompClient.connect(
        {
          'X-Auth-Token': accessToken,
        },
        (frame) => {
          DBGMSG('$$$$STOMPJS Connected Success');
          stompSuccessCallback(_stompClient, accessToken, mkey, frame);
          isConnectingRef.current = false;
        },
        async (error) => {
          DBGMSG('$$$$STOMPJS error: ' + error);
          DBGMSG('$$$$STOMPJS stompClient.connected: ' + _stompClient?.connected);
          isConnectingRef.current = false;
          // DBGMSG(`$$$$STOMPJS stompReconnect try: (${accessToken}, ${mkey}) end`);
          // await Utils.sleep.mssleep(10000);
          // DBGMSG(`$$$$STOMPJS delay 10 sec`);
          // stompReconnect({ socketUrl, accessToken, mkey, stompSuccessCallback });
          // stompConnect();
        }
      );
      DBGMSG(`$$$$STOMPJS try connectStomp(${accessToken}, ${mkey}) end`);
    } catch (error) {
      DBGMSGW(error);
    } finally {
    }
  };

  // const stompReconnect = ({
  //   socketUrl,
  //   accessToken,
  //   mkey,
  //   stompSuccessCallback,
  // }: {
  //   socketUrl: string;
  //   accessToken: string;
  //   mkey: string;
  //   stompSuccessCallback: (accessToken: string, mkey: string, frame?: Stomp.Frame) => any;
  // }) => {
  //   DBGMSG(`$$$$STOMPJS stompReconnect(${accessToken}, ${mkey})`);
  //   DBGMSG(`$$$$STOMPJS stompCleanup`);
  //   stompCleanup();

  //   const webSocket = new SockJS(socketUrl);
  //   const stompClient = Stomp.over(webSocket);
  //   setStompWebSocket(webSocket);
  //   setStompClient(stompClient);
  //   stompClient.connect(
  //     {
  //       'X-Auth-Token': accessToken,
  //     },
  //     (frame) => {
  //       // clearInterval(reconInv);
  //       stompSuccessCallback(accessToken, mkey, frame);
  //     },
  //     () => {
  //       DBGMSG(`$$$$STOMPJS stompReconnect(${accessToken}, ${mkey})`);
  //     }
  //   );
  // };

  const stompSuccessCallback = (stompClient: Stomp.Client, accessToken: string, mkey: string, frame?: Stomp.Frame) => {
    try {
      DBGMSG('$$$$STOMPJS stompSuccessCallback: ' + frame);
      if (isAlpha()) messageService.sendMessage({ kind: 'NormalMsg', text: 'STOMPJS Connected (only alpha)' });

      // SUBSCRIBE
      const stompSubcription = stompClient.subscribe(
        '/topic/msg/user/' + mkey,
        async (messageOutput) => {
          const stompMsg = JSON.parse(messageOutput.body) as CbtStomp_i;
          DBGMSG(`$$$$STOMPJS type: ${stompMsg.type}`);
          DBGMSG(`$$$$STOMPJS from: ${stompMsg.from}`);
          DBGMSG(`$$$$STOMPJS code: ${stompMsg.code}`);
          DBGMSG(`$$$$STOMPJS to: ${stompMsg.to}`);
          DBGMSG(`$$$$STOMPJS content: ${stompMsg.content}`);
          DBGMSG(`$$$$STOMPJS time: ${stompMsg.time}`);

          switch (stompMsg.code) {
            case CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS:
            case CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_FAIL:
              DBGMSG(`$$$$STOMPJS ASSET_ADDITIONAL_CREATE_SUCCESS 5000ms delay `);
              await Utils.sleep.mssleep(5000);
              break;
          }
          messageService.sendMessage({ kind: 'ReceiveStompMsg', ...stompMsg });
        },
        { id: mkey }
      );

      setStompSubcription(stompSubcription ?? null);
    } catch (err) {
      DBGMSGW(`$$$$STOMPJS ${err}`);
    }
  };

  const stompDisconnect = () => {
    DBGMSG(`$$$$STOMPJS stompDisconnect`);
    stompCleanup();
  };
  return {
    stompCleanup,
    stompConnect,
    // stompReconnect,
    stompSuccessCallback,
    stompDisconnect,
  };
}
