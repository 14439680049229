/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useMemo } from 'react';
import { AppConf } from 'src/conf/AppConf';
import { DBGMSG, Utils } from 'src/util/utils';

const STORAGE_LOCAL_KEY = `${AppConf.APPNAME}_${AppConf.PHASE}_local`;

// local storage data
export interface LocalStorage_i {
  max_royalty: string;
  time: number;
  // [key: string]: string;
}

export function useLocalStorage() {
  const _getData = () => {
    return Utils.storage.loadLocalStorage<LocalStorage_i>(STORAGE_LOCAL_KEY);
  };
  const _saveData = (localData: LocalStorage_i) => {
    return Utils.storage.saveLocalStorage<LocalStorage_i>(STORAGE_LOCAL_KEY, localData);
  };
  const _clearData = () => {
    return Utils.storage.clearLocalStorage(STORAGE_LOCAL_KEY);
  };

  const defaultData = useMemo<LocalStorage_i>(() => {
    return { max_royalty: ' ', time: 0 };
  }, []);

  const wrapper = {
    /**
     * 설정 정보
     */
    getData: () => {
      const org = _getData();
      return org ?? defaultData;
    },

    /**
     * 설정 정보
     */
    setData: (data: Partial<LocalStorage_i>) => {
      DBGMSG(`$$$$ Local Data: ${JSON.stringify(data, undefined, 2)}`);
      const org = _getData();
      if (org) _saveData({ ...org, ...data, time: new Date().getTime() });
      else _saveData({ ...defaultData, ...data, time: new Date().getTime() });
    },

    /**
     * 설정 정보 유무 체크
     */
    isExistData: () => {
      if (_getData()) {
        return true;
      }
      return false;
    },

    // debug 용
    debugPrint: () => {
      DBGMSG(`$$$$ Local Data: ${JSON.stringify(_getData(), undefined, 2)}`);
    },
  };

  return {
    ...wrapper,
  };
}
