/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties, HTMLProps } from 'react';
import { useSpring } from 'react-spring';
import R from 'src/res/R';
import { AnimatedFlexDiv, devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { SPRING_PROPS_ANIM_2_Default, SPRING_PROPS_ANIM_2_OnMouseEnter, SPRING_PROPS_ANIM_2_OnMouseLeave } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export type RoundTxtBtnProps = {
  text?: string;
  enable?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
  isBold?: boolean;
  fontColor?: string;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
  containerStyle?: CSSProperties;
  onMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  onMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
  titleTooltip?: string;
};

export function RoundTxtBtn({
  //
  text = 'label',
  enable = true,
  width = 152,
  height = 48,
  fontSize = 14,
  isBold = true,
  onClick,
  containerStyle,
  onMouseEnter,
  onMouseLeave,
  titleTooltip,
}: RoundTxtBtnProps) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_2_Default;
  });

  // console.debug('render');
  return (
    <AnimatedFlexDiv
      title={titleTooltip}
      style={Object.assign(
        {
          width,
          height,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: enable ? R.colors.purpleishBlue : R.colors.paleGrey,
          borderRadius: height / 2,

          cursor: onClick && enable ? 'pointer' : 'inherit',
          flex: '0 0 auto',
          ...containerStyle,
          // ...hMouseProps
        },
        enable ? hMouseProps : undefined
      )}
      onClick={(e: any) => {
        if (!enable) {
          return;
        }
        DBGMSG(`onClick: ${text}`);
        onClick && onClick(e);
      }}
      onMouseEnter={(e: any) => {
        onMouseEnter && onMouseEnter(e);
        enable && set(SPRING_PROPS_ANIM_2_OnMouseEnter);
      }}
      onMouseLeave={(e: any) => {
        onMouseLeave && onMouseLeave(e);
        enable && set(SPRING_PROPS_ANIM_2_OnMouseLeave);
      }}
    >
      <TextCon
        text={text}
        size={fontSize}
        isBold={isBold}
        color={enable ? 'white' : R.colors.cloudyBlue}
        useSelectNone
        containerStyle={{
          cursor: onClick && enable ? 'pointer' : 'inherit',
        }}
      />
    </AnimatedFlexDiv>
  );
}
