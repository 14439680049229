/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikas } from 'src/hooks/useKaikas';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMarketSchmState } from 'src/hooks/useMarketSchmState';
import { useRpc } from 'src/hooks/useRpc';
import { MarketAssetCard_i } from 'src/model/model';
import { CbtPgInfo_i } from 'src/model/rpcModel';
import { RPC_MP_SALE_SCHEMA_ASSET_LIST } from 'src/model/rpcType';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { CommonFooter, COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import {
  MarketAssetCardForList,
  MARKET_ASSET_CARD_CONTAINER_HEIGHT_FOR_LIST,
  MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST,
} from 'src/ui/common_component/MarketAssetCardForList';
import { SchemaCard } from 'src/ui/common_component/SchemaCard';
import { Title } from 'src/ui/common_component/Title';
import { CLAY, CommonColumnBox, COMMON_CARD_COLUMN_CNT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MarketSchemaRScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sNextPageStatus, setNextPageStatus] = useState<string>('');
  const [sFirstPageLoading, setFirstPageLoading] = useState<boolean>();

  /**************************************
   * !! ref
   **************************************/
  const cacheRef = useRef<{
    lastRx: RPC_MP_SALE_SCHEMA_ASSET_LIST.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_MP_SALE_SCHEMA_ASSET_LIST.Rx['sale_list'];
  }>();

  const firstPageLoadingRef = useRef(false);
  const nextPageLoadingRef = useRef(false);
  const qsRef = useRef<{ sid: number }>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`MarketPlace Schema - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hMarketSchmState = useMarketSchmState();
  const location = useLocation();
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hKaikas = useKaikas();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  const restoreCache = () => {
    if (!hMarketSchmState.state.lastRx || !hMarketSchmState.state.lastPgInfo || !hMarketSchmState.state.list) return;

    cacheRef.current = {
      lastRx: hMarketSchmState.state.lastRx,
      lastPgInfo: hMarketSchmState.state.lastPgInfo,
      list: hMarketSchmState.state.list,
    };
    return true;
  };

  const saveCache = ({
    lastRx,
    lastPgInfo,
    list,
  }: {
    lastRx: RPC_MP_SALE_SCHEMA_ASSET_LIST.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_MP_SALE_SCHEMA_ASSET_LIST.Rx['sale_list'];
  }) => {
    cacheRef.current = {
      lastRx,
      lastPgInfo,
      list,
    };
  };

  const clearCache = () => {
    cacheRef.current = undefined;
  };

  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid) {
      DBGMSG(`$$$$QS id: ${query.sid}`);
      const sid = parseInt(query.sid);
      qsRef.current = { sid };
      if (hMarketSchmState.state.sid !== sid) {
        hMarketSchmState.act.setState({
          listNeedUpdate: false,
          sid,
          list: undefined,
        });
        fetchSchmInfoData(sid);
        fetchAstListData({ sid });
      } else {
        // 캐시 복구
        restoreCache();
      }
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchSchmInfoData = async (sid: number) => {
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleSchemaView({ sid: sid });
      hMarketSchmState.act.changeState({
        detail: res,
      });
    } catch (err) {}
  };

  const fetchAstListData = async ({ sid, pg_info }: { sid: number; pg_info?: CbtPgInfo_i }) => {
    if (firstPageLoadingRef.current) return;

    setFirstPageLoading(true);
    firstPageLoadingRef.current = true;

    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleSchemaAssetList({ sid, pg_info });
      saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: res.sale_list });
      hMarketSchmState.act.changeState({
        listNeedUpdate: false,
        lastRx: res,
        lastPgInfo: res.pg_info,
        list: res.sale_list,
      });

      if (res.pg_info) {
        DBGMSG('$$$$PAGE', res.pg_info);
      }
    } catch (err) {
      hRpc.hanleCommonError({ err });
    } finally {
      setFirstPageLoading(false);
      firstPageLoadingRef.current = false;
    }
    // const res = await hRpc.cobaltRpc.rpcMpSaleSchemaAssetList({ sid, pg_info });
    // fetchedAstListRef.current = res;
    // setMarketAssetCardList(
    //   res.sale_list.map<MarketAssetCard_i>((ast) => {
    //     return {
    //       sale_id: ast.sale_id,
    //       asset_id: ast.ast_info.asset_id,
    //       asset_type: Utils.svc.getAssetType({ nft_img: ast.ast_info.nft_img, nft_aud: ast.ast_info.nft_aud, nft_vid: ast.ast_info.nft_vid }),
    //       nft_name: ast.ast_info.nft_name,
    //       col_info: ast.ast_info.col_info,
    //       nft_img: ast.ast_info.nft_img,
    //       owner: ast.ast_info.owner,
    //       favorite: ast.favorite,
    //       prc_info: ast.prc_info,
    //     };
    //   })
    // );
  };

  const getLastRowId = () => {
    if (!cacheRef.current) return;
    if (cacheRef.current.lastPgInfo.last_row_id === -1 && cacheRef.current.lastPgInfo.cursor !== null) {
      DBGMSGW(`lastPgInfoRef.current.last_row_id !== 1 && lastPgInfoRef.current.cursor !== null`);
    }
    return cacheRef.current.lastPgInfo.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  // 다음페이지 요청
  const fetchAstListDataNextPage = async () => {
    if (!qsRef.current) {
      DBGMSGW(`qsRef.current is undefined`);
      return;
    }
    if (!cacheRef.current) {
      DBGMSGW(`cacheRef.current is undefined`);
      return;
    }

    const cursor = cacheRef.current.lastPgInfo.cursor;
    const lastPgInfo = cacheRef.current.lastPgInfo;
    const oldList = cacheRef.current.list;

    if (cursor) {
      try {
        setNextPageStatus('loading');
        const res = await hRpc.cobaltRpc.rpcMpSaleSchemaAssetList({ sid: qsRef.current.sid, pg_info: { ...lastPgInfo, cursor } });

        // const marketAssetCardList = res.sale_list.map<MarketAssetCard_i>((ast) => {
        //   return {
        //     sale_id: ast.sale_id,
        //     asset_id: ast.ast_info.asset_id,
        //     asset_type: Utils.svc.getAssetType({ nft_img: ast.ast_info.nft_img, nft_aud: ast.ast_info.nft_aud, nft_vid: ast.ast_info.nft_vid }),
        //     nft_name: ast.ast_info.nft_name,
        //     col_info: ast.ast_info.col_info,
        //     nft_img: ast.ast_info.nft_img,
        //     owner: ast.ast_info.owner,
        //     favorite: ast.favorite,
        //     prc_info: ast.prc_info,
        //   };
        // });

        const newList = [...oldList, ...res.sale_list];
        saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: newList });

        hMarketSchmState.act.changeState({
          ...hMarketSchmState.state,
          ...cacheRef.current,
        });

        setNextPageStatus('');
      } catch (err) {
        setNextPageStatus('loading fail');
      } finally {
      }
    } else {
    }
  };

  // 스크롤 리스너
  const scrollListener = (e: ScrollParams) => {
    DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (!qsRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'qsRef.current is undefined');
      return;
    }

    if (!cacheRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'cacheRef.current is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return triggeredRef.current is true `);
      return;
    }

    if (atBottom) {
      nextPageLoadingRef.current = true;
      fetchAstListDataNextPage()
        .then(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage then');
        })
        .catch(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage catch');
        })
        .finally(() => {
          nextPageLoadingRef.current = false;
        });
    }
  };
  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  /**************************************
   * !! render function
   **************************************/
  // 에셋 카드 렌더링
  const cellRenderer = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    if (!hMarketSchmState.state.list) return null;
    const index = rowIndex * COMMON_CARD_COLUMN_CNT + columnIndex;
    if (index >= hMarketSchmState.state.list.length) return null;

    const ast = hMarketSchmState.state.list[index];

    const info: MarketAssetCard_i = {
      sale_id: ast.sale_id,
      asset_id: ast.ast_info.asset_id,
      asset_type: Utils.svc.getAssetType({ nft_img: ast.ast_info.nft_img, nft_aud: ast.ast_info.nft_aud, nft_vid: ast.ast_info.nft_vid }),
      nft_name: ast.ast_info.nft_name,
      col_info: ast.ast_info.col_info,
      nft_img: ast.ast_info.nft_img,
      owner: ast.ast_info.owner,
      favorite: ast.favorite,
      prc_info: ast.prc_info,
    };
    const zIndex = hMarketSchmState.state.list.length - index;

    return (
      // 중요!
      <div
        key={key}
        style={{
          ...style,
        }}
      >
        <MobileScalableFlexColumn>
          <MarketAssetCardForList
            idx={zIndex}
            data={info}
            isAnim
            onClick={() => {
              DBGMSG('AssetCardForList onClick!!');
              const id = (info as MarketAssetCard_i).sale_id;
              hHistory.push(Nav.brewMarketAssetRScreenPath({ sale_id: id }));
            }}
            onBuyClick={() => {
              // if (!hAuth.isLogin) {
              //   hHistory.push(Nav.UserLoginScreen);
              //   return;
              // }
              // DBGMSG(`onBuyClick: asset_id-${(item as MarketAssetCard_i).asset_id} ${(item as MarketAssetCard_i).asset_name}`);
              // if (!ScreenInfo.isDesktop()) {
              //   hDlg.act.showDlgJSX({
              //     dlgJSXInfo: {
              //       jsx: (
              //         <FlexColumnDiv>
              //           <TextCon text={`구매하기는 카이카스 지갑 연결을 통해 가능합니다.`} color={R.colors.black} size={10} />
              //           <FlexRowDiv>
              //             <TextCon
              //               text={`PC 웹 브라우저`}
              //               isUnderline
              //               color={R.colors.purpleishBlue}
              //               size={10}
              //               onConClick={() => {
              //                 hKaikas.openInstallSite();
              //               }}
              //             />
              //             <TextCon text={`를 이용해 지갑을 연결해주세요.`} color={R.colors.black} size={10} />
              //           </FlexRowDiv>
              //         </FlexColumnDiv>
              //       ),
              //     },
              //   });
              //   return;
              // }
              // hDlg.act.showDlgBuy({ saleId: (item as MarketAssetCard_i).sale_id, onBuyClick: () => {} });
            }}
          />
        </MobileScalableFlexColumn>
      </div>
    );
  };

  // Asset 목록 렌더링
  const renderAssetList = () => {
    if (!hMarketSchmState.state.list) return null;
    DBGMSG('renderList');
    return (
      <FlexColumnDiv
        style={{
          width: MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT,
          alignSelf: 'center',
          userSelect: 'none',
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <FlexColumnDiv>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <Grid
                      onScroll={(params) => {
                        onChildScroll(params);
                        throttleScrollListener(params);
                      }}
                      isScrolling={isScrolling}
                      autoHeight
                      cellRenderer={cellRenderer}
                      columnWidth={MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale}
                      columnCount={COMMON_CARD_COLUMN_CNT}
                      height={height}
                      rowHeight={MARKET_ASSET_CARD_CONTAINER_HEIGHT_FOR_LIST * ScreenInfo.scale}
                      rowCount={Math.ceil(hMarketSchmState.state.list!.length / COMMON_CARD_COLUMN_CNT)}
                      // rowCount={1}
                      // scrollToColumn={scrollToColumn}
                      // scrollToRow={scrollToRow}
                      scrollTop={scrollTop}
                      width={MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT}
                    />
                  </div>
                )}
              </AutoSizer>
            </FlexColumnDiv>
          )}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (!hMarketSchmState.state.detail) return <DataFetching />;

  DBGMSG('render');

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <FlexColumnDiv
            style={{
              backgroundColor: R.colors.bgGrey,
              width: MOBILE_WIDTH,
              alignItems: 'flex-start',
              paddingTop: 20,
              paddingLeft: CLAY.HPADDING,
              paddingRight: CLAY.HPADDING,
              // ...devOutlineF,
            }}
          >
            {/* Back 버튼 */}
            <BackBtn />

            {/* 타이틀 */}
            <Title title={`${hR.strings.AC_SCHEMA_READ_TITLE} - ${hMarketSchmState.state.detail.schm_info.dp_name}`} />

            <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

            {/* 스키마 카드 */}
            <SchemaCard schemaInfo={hMarketSchmState.state.detail.schm_info} />

            <HEIGHT size={12} />

            {/* 속성 리스트 */}
            {hMarketSchmState.state.detail.schm_info.attributes.map((att, idx) => {
              const typeTxt: string = hR.strings.getAttributeTypeText(att.type);
              const typeDetailTxt: string = hR.strings.getAttributeTypeDetailText(att.type);

              return (
                <>
                  <FlexRowDiv key={idx} style={{ alignItems: 'stretch' }}>
                    <CommonColumnBox style={{ width: 135, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={12} color={R.colors.black} isBold text={Utils.svc.convertIfReqAttrName(att.name)} />
                    </CommonColumnBox>
                    <WIDTH size={10} />
                    <CommonColumnBox style={{ width: 135, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={12} color={R.colors.black} isBold text={typeTxt} containerStyle={{ textAlign: 'center' }} />
                      <TextCon size={10} color={R.colors.black} isBold text={typeDetailTxt} containerStyle={{ textAlign: 'center' }} />
                    </CommonColumnBox>
                  </FlexRowDiv>
                  <HEIGHT size={10} />
                </>
              );
            })}

            {/* 에셋 */}
            <HEIGHT size={20} />
            <FlexRowDiv style={{ alignItems: 'baseline', height: 24, alignSelf: 'stretch' }}>
              <TextCon text={hR.strings.MARKET_SCHEMA_READ_ASSET_LIST_TITLE} size={16} color={R.colors.black} isBold />
              <WIDTH size={3} />
              {/* <TextCon text={`(todo)`} size={12} color={R.colors.black} /> */}
            </FlexRowDiv>
          </FlexColumnDiv>
        </MobileScalableFlexColumn>

        <HEIGHT size={14} />
        {renderAssetList()}
        <HEIGHT size={46} />

        <HEIGHT size={60} />
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      </>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          ...devOutline,
        }}
      >
        <FlexRowDiv
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            DBGMSG('back');
            hHistory.goBack();
          }}
        >
          <Img src={[R.images.common_ic_arrow_back3x, 20, 20]} />
          <TextCon text={hR.strings.AC_SCHEMA_READ_BACK} size={14} color={R.colors.black} isBold />
        </FlexRowDiv>

        <HEIGHT size={30} />
        <FlexRowDiv style={{ alignSelf: 'stretch', ...devOutline }}>
          <FlexColumnDiv style={{ ...devOutline }}>
            {/* Schema 카드 */}
            <SchemaCard schemaInfo={hMarketSchmState.state.detail.schm_info} />
            <HEIGHT size={30} />
          </FlexColumnDiv>
          <WIDTH size={30} />
          <FlexColumnDiv style={{ flex: 1, ...devOutline }}>
            <FlexColumnDiv style={{ paddingLeft: 10 }}>
              {/* schema 이름 */}
              <Title title={`${hR.strings.AC_SCHEMA_READ_TITLE} - ${hMarketSchmState.state.detail.schm_info.dp_name}`} />
            </FlexColumnDiv>
            <HEIGHT size={20} />
            <FlexRowDiv style={{ alignItems: 'stretch' }}>
              <TextCon
                size={16}
                color={R.colors.blueGrey}
                isBold
                text={hR.strings.AC_SCHEMA_READ_ATTR_NAME}
                containerStyle={{ flex: 1, paddingLeft: 10 }}
              />
              <WIDTH size={30} />
              <TextCon
                size={16}
                color={R.colors.blueGrey}
                isBold
                text={hR.strings.AC_SCHEMA_READ_ATTR_TYPE}
                containerStyle={{ flex: 1, paddingLeft: 10 }}
              />
            </FlexRowDiv>
            <HEIGHT size={24} />

            {/* 속성 리스트 */}
            {hMarketSchmState.state.detail.schm_info.attributes.map((att, idx) => {
              const typeTxt: string = hR.strings.getAttributeTypeText(att.type);
              const typeDetailTxt: string = hR.strings.getAttributeTypeDetailText(att.type);

              return (
                <>
                  <FlexRowDiv key={idx} style={{ alignItems: 'stretch' }}>
                    <CommonColumnBox style={{ flex: 1, height: 68, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={16} color={R.colors.black} isBold text={Utils.svc.convertIfReqAttrName(att.name)} />
                    </CommonColumnBox>
                    <WIDTH size={30} />
                    <CommonColumnBox style={{ flex: 1, height: 68, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={16} color={R.colors.black} isBold text={`${typeTxt}${typeDetailTxt}`} />
                    </CommonColumnBox>
                  </FlexRowDiv>
                  <HEIGHT size={10} />
                </>
              );
            })}
          </FlexColumnDiv>
        </FlexRowDiv>

        <HEIGHT size={80} />
        <FlexRowDiv style={{ alignItems: 'baseline', height: 50 }}>
          <TextCon text={hR.strings.MARKET_SCHEMA_READ_ASSET_LIST_TITLE} size={30} color={R.colors.black} isBold />
          {/* <WIDTH size={5} />
          <TextCon text={`(todo)`} size={20} color={R.colors.black} /> */}
        </FlexRowDiv>
        <HEIGHT size={15} />

        {renderAssetList()}

        <HEIGHT size={120} />
      </FlexColumnDiv>
    );
}
