// import classNames from 'classnames/bind';
import React from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikas } from 'src/hooks/useKaikas';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import R from 'src/res/R';
import { DBGMSG } from 'src/util/utils';

// import styles from 'src/ui/dlg/jsx/WithdrawJsx.module.scss';

// const cx = classNames.bind(styles);
// const cx = classNames('testtest2');

export type CbltFeeJsxProps = {
  reqId: string;
  onOk: (args: { isSuccess: boolean; bl_tx_hash?: string }) => void;
};

export const CbltFeeJsx = (dlg: CbltFeeJsxProps) => {
  /**************************************
   * !! hooks
   **************************************/
  const hKaikas = useKaikas();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hDlgFactory = useDlgFactory();

  return (
    <div
      className={'cblt_fee_jsx'}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      {/* <img
        src={R.images.common_btn_close3x}
        className={'close_btn'}
        alt={''}
        onClick={() => {
          dlg.onclose && dlg.onclose();
        }}
      /> */}
      <div className={'title'}>{hR.strings.MY_CBLT_FEE_DLG_TITLE}</div>
      <img className={'img'} src={R.images.cashout_iv_cblt3x} alt={''} />
      <div className={'txt1'}>{hR.strings.MY_CBLT_FEE_DLG_SUB_TITLE}</div>
      <div className={'txt2'}>{hR.strings.MY_CBLT_FEE_DLG_SUB_DESC}</div>
      <div
        className={'btn'}
        onClick={async () => {
          // CBLT 보내기 스마트 컨트랙트 실행
          if (!hAuth.authCommonRes) {
            return;
          }
          hDlgFactory.showRunningSmartContract();

          try {
            const blTxHash: string | undefined = await hKaikas.payCbltUsingCobaltContract({
              userCode: hAuth.authCommonRes.ucode,
              reqId: dlg.reqId,
            });
            DBGMSG(`blTxHash: ${blTxHash}`);
            dlg.onOk({ isSuccess: true, bl_tx_hash: blTxHash });
            hDlgFactory.hideRunningSmartContract();
          } catch (e) {
            DBGMSG(`fail`);
            dlg.onOk({ isSuccess: false });
            hDlgFactory.hideRunningSmartContract();
          }
        }}
      >
        {hR.strings.MY_CBLT_FEE_DLG_BTN}
      </div>
    </div>
  );
};
