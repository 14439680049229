/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMakePressable } from 'src/hooks/useMakePressable';
import { useMouseEnterEffectType1 } from 'src/hooks/useMouseEnterEffectType1';
import { CbtInqueryType_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import AnimatedBtn from 'src/ui/common_component/button/AnimatedBtn';
import { CommonColumnBox } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const DT_INPUT_WIDTH = 380;
const DT_INPUT_HEIGHT = 44;
const DT_FONTSIZE = 14;
const DT_DROPDOWN_IC_SIZE = 24;
const DT_DROPDOWN_FONTSIZE = 12;
const DT_DROPDOWN_PADDING_LEFT = 14;
const DT_DROPDOWN_MENU_HEIGHT = 30;
const DT_DROPDOWN_MENU_PADIING_LEFT = 10;
const DT_DROPDOWN_MENU_FONTSIZE = 12;
const DT_DROPDOWN_PADDING_V = 15;

const MB_INPUT_WIDTH = 256;
const MB_INPUT_HEIGHT = 38;
const MB_FONTSIZE = 10;
const MB_DROPDOWN_IC_SIZE = 16;
const MB_DROPDOWN_FONTSIZE = 10;
const MB_DROPDOWN_PADDING_LEFT = 12;
const MB_DROPDOWN_MENU_HEIGHT = 27;
const MB_DROPDOWN_MENU_PADIING_LEFT = 12;
const MB_DROPDOWN_MENU_FONTSIZE = 10;
const MB_DROPDOWN_PADDING_V = 41 - 27;

const INPUT_WIDTH = ScreenInfo.isMobile() ? MB_INPUT_WIDTH : DT_INPUT_WIDTH;
const INPUT_HEIGHT = ScreenInfo.isMobile() ? MB_INPUT_HEIGHT : DT_INPUT_HEIGHT;
const FONTSIZE = ScreenInfo.isMobile() ? MB_FONTSIZE : DT_FONTSIZE;
const DROPDOWN_IC_SIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_IC_SIZE : DT_DROPDOWN_IC_SIZE;
const DROPDOWN_FONTSIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_FONTSIZE : DT_DROPDOWN_FONTSIZE;
const DROPDOWN_PADDING_LEFT = ScreenInfo.isMobile() ? MB_DROPDOWN_PADDING_LEFT : DT_DROPDOWN_PADDING_LEFT;
const DROPDOWN_MENU_HEIGHT = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_HEIGHT : DT_DROPDOWN_MENU_HEIGHT;
const DROPDOWN_MENU_PADIING_LEFT = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_PADIING_LEFT : DT_DROPDOWN_MENU_PADIING_LEFT;
const DROPDOWN_MENU_FONTSIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_FONTSIZE : DT_DROPDOWN_MENU_FONTSIZE;
const DROPDOWN_PADDING_V = ScreenInfo.isMobile() ? MB_DROPDOWN_PADDING_V : DT_DROPDOWN_PADDING_V;

const Dropdown = styled(CommonColumnBox)`
  z-index: 1000000;
`;

type Props = {
  width?: number | string;
  isAnim?: boolean;
  curInqueryType: CbtInqueryType_e;
  onSelect?: (selectedType: CbtInqueryType_e) => void;
  enable?: boolean;
};

export function InqueryTypeCombo({
  //
  width = '100%',
  isAnim = false,
  curInqueryType,
  onSelect,
  enable = true,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sShowDropDown, setShowDropDown] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const dropdownMenuRef = useRef(null);
  const dropdownPivotRef = useRef(null);
  const dropdownBtnRef = useRef(null);

  /**************************************
   * !! hook
   **************************************/
  const hR = useLocalSettings();
  useMakePressable([
    {
      elementKey: 'currencyCombo',
      elementRefList: [dropdownMenuRef, dropdownBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        DBGMSG('CurrencyCombo', 'onInsideClick');
        if (dropdownBtnRef.current === hitRef.current) {
          if (!sShowDropDown) setShowDropDown(true);
        }
        e.stopPropagation();
      },
      onOutsidePress: () => {
        DBGMSG('CurrencyCombo', 'onOutsideClick');
        if (!sShowDropDown) return;
        setShowDropDown(false);
      },
    },
  ]);

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const getInqueryTxt = (inqueryType: CbtInqueryType_e) => {
    switch (inqueryType) {
      case CbtInqueryType_e.Problem:
        return hR.strings.INQUERY_TYPE_PROBLEM;
      case CbtInqueryType_e.Task:
        return hR.strings.INQUERY_TYPE_TASK;
      case CbtInqueryType_e.Question:
        return hR.strings.INQUERY_TYPE_QUESTION;
      case CbtInqueryType_e.Incident:
        return hR.strings.INQUERY_TYPE_INCIDENT;
    }
  };

  /**************************************
   * !! render function
   **************************************/
  const renderDropDownMenu = (inqueryType: CbtInqueryType_e) => {
    return (
      <AnimatedBtn
        size={DROPDOWN_MENU_FONTSIZE}
        color={curInqueryType === inqueryType ? R.colors.purpleishBlue : R.colors.black}
        isBold
        text={getInqueryTxt(inqueryType)}
        onMouseDefalutProp={{ backgroundColor: 'white' }}
        onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
        onMouseLeaveProp={{ backgroundColor: 'white' }}
        containerStyle={{
          width: '100%',
          height: DROPDOWN_MENU_HEIGHT,
          paddingLeft: DROPDOWN_MENU_PADIING_LEFT,
          justifyContent: 'center',
          // ...devOutlineF
        }}
        onClick={(e) => {
          DBGMSG(`InqueryTypeCombo `, 'onClick');
          onSelect && onSelect(inqueryType);
          setTimeout(() => {
            setShowDropDown(false);
          }, 0);
        }}
      />
    );
  };

  /**************************************
   * !! animation
   **************************************/
  const { hAnimProps, mouseEnter, mouseLeave } = useMouseEnterEffectType1();

  return (
    <FlexColumnDiv
      style={{
        width: INPUT_WIDTH,
        // ...devOutlineF,
      }}
    >
      <FlexRowDiv
        ref={dropdownPivotRef}
        style={{
          width: INPUT_WIDTH,
          // height: 1,
          // backgroundColor: R.colors.devColor1a,
          position: 'absolute',
          top: 0,
        }}
      />
      <AnimatedFlexRowDiv
        style={Object.assign(
          {
            alignItems: 'center',
            width: INPUT_WIDTH,
            height: INPUT_HEIGHT,
            backgroundColor: R.colors.bgGrey,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: R.colors.line,
            borderRadius: 15,
            // ...devOutline,
            // paddingLeft: 14,
            // paddingRight: 14,
          },
          isAnim ? hAnimProps : undefined
        )}
        onMouseEnter={isAnim ? mouseEnter : undefined}
        onMouseLeave={isAnim ? mouseLeave : undefined}
      >
        {/* filter */}
        <FlexRowDiv
          style={{ width: INPUT_WIDTH, alignItems: 'center', flex: 1, cursor: 'pointer' }}
          onClick={(e) => {
            if (!enable) return;
            setShowDropDown(true);
            e.stopPropagation();
          }}
        >
          <FlexRowDiv style={{ paddingLeft: DROPDOWN_PADDING_LEFT, alignItems: 'center' }}>
            <TextCon text={getInqueryTxt(curInqueryType)} isBold size={FONTSIZE} color={R.colors.black} />
          </FlexRowDiv>
          <FlexRowDiv style={{ height: INPUT_HEIGHT, alignItems: 'center', marginLeft: 'auto' }}>
            <Img src={[R.images.common_btn_drop_down3x, DROPDOWN_IC_SIZE, DROPDOWN_IC_SIZE]} containerStyle={{ paddingRight: 6 }} />
          </FlexRowDiv>

          <Overlay target={dropdownPivotRef.current} show={sShowDropDown} placement={'bottom-start'}>
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  zIndex: 100,
                }}
              >
                <Dropdown ref={dropdownMenuRef} style={{ width: INPUT_WIDTH }}>
                  <HEIGHT size={DROPDOWN_PADDING_V} />
                  <FlexColumnDiv style={{ flex: 1, justifyContent: 'center' }}>
                    {renderDropDownMenu(CbtInqueryType_e.Problem)}
                    {renderDropDownMenu(CbtInqueryType_e.Task)}
                    {renderDropDownMenu(CbtInqueryType_e.Question)}
                    {renderDropDownMenu(CbtInqueryType_e.Incident)}
                  </FlexColumnDiv>
                  <HEIGHT size={DROPDOWN_PADDING_V} />
                </Dropdown>
              </div>
            )}
          </Overlay>
        </FlexRowDiv>
      </AnimatedFlexRowDiv>
    </FlexColumnDiv>
  );
}
