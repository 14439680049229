/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { AppConf } from 'src/conf/AppConf';
import { AuthCommonRes_t } from 'src/model/model';
import Constants from 'src/res/constants';
import { DBGMSG, Utils } from 'src/util/utils';

const STORAGE_LOCAL_AUTH_INFO_KEY = `${AppConf.APPNAME}_${AppConf.PHASE}_local_auth_info`;

// 토큰정보
// export interface LocalAuthInfo_i extends AuthCommonRes_t {
export interface LocalAuthInfo_i extends AuthCommonRes_t {
  time: number;
  email: string;
}

export function useAuthStorage() {
  const getAuthInfo = () => {
    // return Utils.storage.loadLocalStorage<LocalAuthInfo_i>(STORAGE_LOCAL_AUTH_INFO_KEY);
    return Utils.storage.loadSessionStorage<LocalAuthInfo_i>(STORAGE_LOCAL_AUTH_INFO_KEY);
  };
  const _saveAuthInfo = (tokenInfo: LocalAuthInfo_i) => {
    // return Utils.storage.saveLocalStorage<LocalAuthInfo_i>(STORAGE_LOCAL_AUTH_INFO_KEY, tokenInfo);
    return Utils.storage.saveSessionStorage<LocalAuthInfo_i>(STORAGE_LOCAL_AUTH_INFO_KEY, tokenInfo);
  };
  const _clearAuthInfo = () => {
    // return Utils.storage.clearLocalStorage(STORAGE_LOCAL_AUTH_INFO_KEY);
    return Utils.storage.clearSessionStorage(STORAGE_LOCAL_AUTH_INFO_KEY);
  };

  const authStorageActs = {
    /**
     * AUTH 정보 저장
     */
    saveAuthInfo: ({ email, authCommonRes }: { email: string; authCommonRes: AuthCommonRes_t }) => {
      DBGMSG(`$$$$AUTH_STORAGE saveLocalAuthInfo: ${JSON.stringify(authCommonRes, undefined, 4)}`);
      _saveAuthInfo({ ...authCommonRes, time: new Date().getTime(), email });
    },

    /**
     * 토큰 업데이트
     */
    updateOnlyToken: (newToken: string) => {
      DBGMSG(`$$$$AUTH_STORAGE updateOnlyToken: ${newToken}`);
      const authInfo = getAuthInfo();
      if (authInfo) {
        _saveAuthInfo({ ...authInfo, token: newToken, time: new Date().getTime() });
      }
    },

    /**
     * AUTH 정보 유무 체크
     */
    isExistLocalAuthInfo: () => {
      if (getAuthInfo()) {
        return true;
      }
      return false;
    },

    /**
     * 토큰 valid 체크
     */
    isValidTokenInfo: () => {
      const tokenInfo = getAuthInfo();
      if (tokenInfo) {
        DBGMSG(`$$$$AUTH_STORAGE time ${Utils.date.brewFomatString(tokenInfo.time)}`);
        const diffInSeconds = Utils.date.diffInSeconds(new Date(), tokenInfo.time);
        DBGMSG(`$$$$AUTH_STORAGE token check ${diffInSeconds} seconds elapsed`);

        if (diffInSeconds <= Constants.LOGIN_TIMEOUT) {
          DBGMSG(`$$$$AUTH_STORAGE token check ${diffInSeconds} <= ${Constants.LOGIN_TIMEOUT} (true)`);
          return true;
        }
        DBGMSG(`$$$$AUTH_STORAGE token check ${diffInSeconds} <= ${Constants.LOGIN_TIMEOUT} (false)`);
      }
      DBGMSG(`$$$$AUTH_STORAGE token check not found`);
      return false;
    },

    // debug 용
    debugPrint: () => {
      DBGMSG(`$$$$AUTH_STORAGE info: ${JSON.stringify(getAuthInfo(), undefined, 2)}`);
    },
    clearAuthInfo: _clearAuthInfo,
  };

  return {
    getAuthInfo,
    ...authStorageActs,
  };
}
