import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useSubscribeStompMsg } from 'src/hooks/useSubscribeStompMsg';
import { RPC_PAYMENT_CHARGE_POINT_LIST } from 'src/model/rpcType';
import R from 'src/res/R';
import { CbtStompMsgCode_e } from 'src/rx/messageService';
import { FlexColumnDiv } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import { DBGMSG } from 'src/util/utils';

export type PaymentWallWidgetJsxProps = {
  url: string;
  onCloseBtn: () => void;
  onSuccPayment: () => void;
  onFailPayment: () => void;
};

// Desktop
const DT_DLG_WIDTH = 582;
const DT_DLG_MIN_HEIGHT = 807;
const DT_PADDING = 30;
const DT_DEFULAT_FONT = 14;
const DT_TITLE_FONT = 22;
const DT_TITLE_MARGIN_BOTTOM = 20;
const DT_BTN_WIDTH = 300;
const DT_BTN_HEIGHT = 48;
const DT_BTN_MARGIN_TOP = 30;
const DT_TOP_SPACE_FLEX = 1;
const DT_BOTTOM_SPACE_FLEX = 3;
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;

// Mobile
const MB_DLG_WIDTH = 250;
const MB_DLG_MIN_HEIGHT = 232;
const MB_PADDING = 20;
const MB_DEFULAT_FONT = 10;
const MB_TITLE_FONT = 16;
const MB_TITLE_MARGIN_BOTTOM = 16;
const MB_BTN_WIDTH = 200;
const MB_BTN_HEIGHT = 40;
const MB_BTN_MARGIN_TOP = 20;
const MB_TOP_SPACE_FLEX = 1;
const MB_BOTTOM_SPACE_FLEX = 1;
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const DLG_MIN_HEIGHT = ScreenInfo.isMobile() ? MB_DLG_MIN_HEIGHT : DT_DLG_MIN_HEIGHT;
const DLG_DEFAULT_PADDING = ScreenInfo.isMobile() ? MB_PADDING : DT_PADDING;
const DEFULAT_FONT = ScreenInfo.isMobile() ? MB_DEFULAT_FONT : DT_DEFULAT_FONT;
const TITLE_FONT = ScreenInfo.isMobile() ? MB_TITLE_FONT : DT_TITLE_FONT;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BTN_MARGIN_TOP : DT_BTN_MARGIN_TOP;
const TOP_SPACE_FLEX = ScreenInfo.isMobile() ? MB_TOP_SPACE_FLEX : DT_TOP_SPACE_FLEX;
const BOTTOM_SPACE_FLEX = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE_FLEX : DT_BOTTOM_SPACE_FLEX;
const CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
const CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;

export const PaymentWallWidgetJsx = (props: PaymentWallWidgetJsxProps) => {
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [sFetchedData, setFetchedData] = useState<RPC_PAYMENT_CHARGE_POINT_LIST.Rx>();
  const [sLoading, setLoading] = useState<boolean>();
  const [sSelectedIdx, setSelectedIdx] = useState<number>();
  const [sPaymentWidgetUrl, setPaymentWidgetUrl] = useState<string>();

  const onMessageReceivedFromIframe = useCallback((event) => {
    DBGMSG(`onMessageReceivedFromIframe: `,event);
    DBGMSG(event);
    if (event.origin !== 'https://api.paymentwall.com') return;
    DBGMSG(event);
    DBGMSG(event.data); // event.data 'PaymentwallWidgetSuccessHandler()'
    props.onSuccPayment();

    // todo
    // const eventData = JSON.parse(event.data);
    // if (eventData.event === 'paymentSuccess') {
    //   alert('Thank you for paying ' + eventData.data.amount + ' ' + eventData.data.currency);
    // }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe);
    // const win = window.open(props.url);
    // win.i.addEventListener('message', onMessageReceivedFromIframe);
    return () => {
      window.removeEventListener('message', onMessageReceivedFromIframe);
    };
  }, [onMessageReceivedFromIframe]);

  // useEffect(() => {
  //   if (iframeRef.current) {
  //     iframeRef.current.addEventListener(
  //       'message',
  //       function (event) {
  //         DBGMSG(`############event############`);
  //         DBGMSG(event);
  //         // if (event.origin !== 'https://api.paymentwall.com') return;
  //         // var eventData = JSON.parse(event.data);
  //         // if (eventData.event === 'paymentSuccess') {
  //         //   // handle the successful payment scenario
  //         //   alert('Thank you for paying ' + eventData.data.amount + ' ' + eventData.data.currency);
  //         // }
  //       },
  //       false
  //     );
  //   }

  //   return () => {};
  // }, [iframeRef]);

  useSubscribeStompMsg({
    onMsg: (stompMsg) => {
      switch (stompMsg.code) {
        case CbtStompMsgCode_e.BILL_CHARGE_POINT_SUCCESS:
          props.onSuccPayment();
          break;
        case CbtStompMsgCode_e.BILL_CHARGE_POINT_FAIL:
          props.onFailPayment();
          break;
      }
    },
    targetCodeList: [CbtStompMsgCode_e.BILL_CHARGE_POINT_SUCCESS, CbtStompMsgCode_e.BILL_CHARGE_POINT_FAIL],
  });

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        // minHeight: DLG_MIN_HEIGHT,
        // height: 90,
        padding: DLG_DEFAULT_PADDING,
        paddingLeft: 40,
        paddingRight: 40,
        alignItems: 'center',
        // ...devOutlineFF,
        // marginTop: ScreenInfo.isMobile() ? 79 : undefined,
        // marginBottom: ScreenInfo.isMobile() ? 79 : undefined,
      }}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      {/* close 버튼 */}
      <ImgBtn
        src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
        containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
        onClick={() => {
          props.onCloseBtn();
        }}
      />

      <iframe
        ref={iframeRef}
        title={'PaymentWallWidget'}
        id={'PaymentWallWidget'}
        src={props.url}
        style={{ width: DLG_WIDTH, height: 807, borderWidth: 0 }}
      />
    </FlexColumnDiv>
  );
};
