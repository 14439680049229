/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

export class Constants {
  static readonly NFT_REQ_ATTRNAME_NAME = 'nftName';
  static readonly NFT_REQ_ATTRNAME_IMAGE = 'nftImage';
  static readonly NFT_REQ_ATTRNAME_AUDIO = 'nftAudioSrc';
  static readonly NFT_REQ_ATTRNAME_VIDEO = 'nftVideoSrc';
  static readonly LOGIN_TIMEOUT = 60 * 60; // in seconds
  static readonly ZINDEX_DROPDOWN = 1000; // in seconds
  static readonly REGEXP_ISNOT_ALPHA_NUM = /[^a-zA-Z0-9]/;
  static readonly REGEXP_IS_ALPHA_NUM_SPACE = /^[a-zA-Z0-9]+[a-zA-Z0-9 ]*$|^$/;
  static readonly REGEXP_IS_ALPHA_NUM_WITHIN_20 = /^[a-zA-Z0-9 ]{0,20}$/;
  static readonly REGEXP_IS_NUM_WITHIN_0_TO_10 = /^(?:[0-9]|10)$|^$/;
  static readonly REGEXP_IS_VALID_NUM = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
  // static readonly REGEXP_IS_VALID_KLAY = /^([1-9]([0-9])*([.][0-9]{0,3})?|[.][0-9]{0,3})$/;
  static readonly REGEXP_IS_VALID_KLAY = /^([1-9]([0-9])*([.][0-9]{0,3})?)$/;
  static readonly REGEXP_IS_VALID_INT = /^[1-9][0-9]*$|^$/;
  static readonly REGEXP_IS_EMAIL =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  static readonly REGEXP_IS_VALID_KLAY_ACCOUNT_ADDRESS = /^0[x][a-f0-9]{40}$/i;
  static readonly REGEXP_IS_VALID_USD_AMOUNT = /^([1-9]([0-9])*([.][0-9]{0,3})?)$/;
  static readonly NFT_IMG_ACCEPT_FILETYPE = ['jpg', 'jpeg', 'png', 'apng', 'gif'];
  static readonly NFT_AUDIO_ACCEPT_FILETYPE = ['mp3'];
  static readonly NFT_VIDEO_ACCEPT_FILETYPE = ['mp4'];
  static readonly EMAIL_AUTH_CODE_RETRY_DELAY_MS = 5000; // 5초동안 기다려야함
  static readonly COLLECTION_IMG_ACCEPT_FILETYPE = Constants.NFT_IMG_ACCEPT_FILETYPE;
  static readonly KAIKAS_INSTALL_URL_ = 'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi';
  static readonly KAIKAS_INSTALL_URL_EN = 'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?hl=en';
  static readonly SUPPORT_EMAIL_ADDRESS = 'support@cobalt.zone';
  
  static readonly PAYPAL_CLIENT_ID_ALPHA = 'AfLlhHkRBa5YAdaJQPXJSR3tjztCQBbf_ZV5Znz_OxU2KvKKwxxHh4ZtQ4gPylESXtYePsE5TvX5624P';
  static readonly PAYPAL_CLIENT_ID_PROD = 'AVRzUYj43wVSF0GtsJwsBob1B-H-G8-wSAl3rBidZ-NOtjjBLnsa6Ftz1yJlL0uCF-T02mQYI5x16p8A';
  static readonly SERVICE_NOT_AVAILABLE_URL_EN = 'notAvailable.html';
  static readonly SERVICE_MAINTENANCE_URL_EN = 'maintenance.html';

  static forceBlock = false;
}

// reject type
export enum KaikasPromiseReject_e {
  KaikasNotFound = 1000,
  BAOBAB_NETWORK_IS_NOT_ALLOWED = 2000,
  MAINET_NETWORK_IS_NOT_ALLOWED = 3000,
}

export default Constants;

/**
 * 로그인 타임아웃 시간
 */
// export const LOGIN_TIMEOUT = isAlpha() ? 1 * 60 : 60 * 60; // in seconds
// const LOGIN_TIMEOUT = 60 * 60; // in seconds

// /**
//  * LINE Channel ID Real
//  */
// const LINE_GETTING_ACCESS_TOKEN_URL = 'https://api.line.me/oauth2/v2.1/token';
// const LINE_GETTING_PROFILE_URL = 'https://api.line.me/v2/profile';
// const LINE_CHANNEL_ID = '1655807031';
// const LINE_CLIENT_SECRET = '92eebe798a9c860d51ab5b73d0401229'; // ??
// // const LINE_CALLBACK_URL = 'https://localhost:3000/login_test';
// const LINE_CALLBACK_URL = 'https://cdap-alpha-rsc.cocone.jp/login_test';

// /**
//  * LINE Channel ID Dev
//  */
// const LINE_CHANNEL_ID_DEV = '1655828097';
// const LINE_CLIENT_SECRET_DEV = '443f3489f1fa83808e0f49b9de1889c2'; // ??
// const LINE_CALLBACK_URL_DEV = 'https://localhost:3000/login_test';

// export const Constants = {
// LOGIN_TIMEOUT,
// LINE_GETTING_ACCESS_TOKEN_URL,
// LINE_GETTING_PROFILE_URL,
// LINE_CHANNEL_ID: isProduction() ? LINE_CHANNEL_ID : LINE_CHANNEL_ID_DEV,
// LINE_CLIENT_SECRET: isProduction() ? LINE_CLIENT_SECRET : LINE_CLIENT_SECRET_DEV,
// LINE_CALLBACK_URL: isProduction() ? LINE_CALLBACK_URL : LINE_CALLBACK_URL_DEV,
// };
