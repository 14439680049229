/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { MOBILE_HORIZONTAL_PADDING } from 'src/context/screen_constants';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { MarektAssetCard, MarketAssetCardProps, MARKET_ASSET_CARD_HEIGHT, MARKET_ASSET_CARD_WIDTH } from 'src/ui/common_component/MarketAssetCard';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
export const ASSET_CARD_PADDING_FOR_SLIDING = MOBILE_HORIZONTAL_PADDING;
export const MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_SLIDING = MARKET_ASSET_CARD_WIDTH + ASSET_CARD_PADDING_FOR_SLIDING * 2;
export const MARKET_ASSET_CARD_CONTAINER_HEIGHT_FOR_SLIDING = MARKET_ASSET_CARD_HEIGHT + ASSET_CARD_PADDING_FOR_SLIDING * 2;

export function MarketAssetCardForSlider(props: MarketAssetCardProps & { key?: React.Key; scale?: number; idx?: number }) {
  const scale = props.scale ?? 1;
  return (
    <FlexDiv
      key={props.key}
      style={{
        padding: ASSET_CARD_PADDING_FOR_SLIDING,
        justifyContent: 'center',
        alignItems: 'center',
        height: MARKET_ASSET_CARD_CONTAINER_HEIGHT_FOR_SLIDING * scale,
        ...devOutline,
      }}
    >
      <MarektAssetCard {...props} />
    </FlexDiv>
  );
}
