/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useUserDetailState } from 'src/hooks/useUserDetailState';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { MyInventoryAstgList } from 'src/ui/screen/AssetMgr/list/MyInventoryAstgList';
import { UserInventoryAstgList } from 'src/ui/screen/AssetMgr/list/UserInventoryAstgList';
import { UserIssueAstgList } from 'src/ui/screen/AssetMgr/list/UserIssueAstgList';
import { UserSaleAstList } from 'src/ui/screen/AssetMgr/list/UserSaleAstList';
import { UserSoldAstList } from 'src/ui/screen/AssetMgr/list/UserSoldAstList';
import { UserThumbMain } from 'src/ui/common_component/UserThumbMain';
import { CLAY } from 'src/ui/layout_constant';
import { DBGMSG, DBGMSGW } from 'src/util/utils';
import { UserBoughtAstList } from 'src/ui/screen/AssetMgr/list/UserBoughtAstList';
import { UserDetailMenuType_e } from 'src/redux/modules/userDetailModule';
import { useForceRender } from 'src/hooks/useForceRender';
import { Nav } from 'src/ui/screen/Nav';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
const DT_USER_THUMB_MAIN_MARGIN_BOTTOM = 40;
const DT_MENU_WIDTH = 80;
const DT_MENU_HEIGHT = 40;
const DT_SPACE_BETWEEN_MENU = 80;
const DT_MENU_FONT_HEIGHT = 14;
const DT_MENU_CNT_FONT_HEIGHT = 12;
const DT_MENU_MARGIN_BOTTOM = 20;
const DT_SEARCH_MARGIN_BOTTOM = 20;

const MB_USER_THUMB_MAIN_MARGIN_BOTTOM = 30;
const MB_MENU_WIDTH = 65;
const MB_MENU_HEIGHT = 30;
const MB_SPACE_BETWEEN_MENU = 16;
const MB_MENU_FONT_HEIGHT = 10;
const MB_MENU_CNT_FONT_HEIGHT = 10;
const MB_MENU_MARGIN_BOTTOM = 5;
const MB_SEARCH_MARGIN_BOTTOM = 10;

export const USER_THUMB_MAIN_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_USER_THUMB_MAIN_MARGIN_BOTTOM : DT_USER_THUMB_MAIN_MARGIN_BOTTOM;
export const ACCOUNT_MENU_MIN_WIDTH = ScreenInfo.isMobile() ? MB_MENU_WIDTH : DT_MENU_WIDTH;
export const ACCOUNT_MENU_HEIGHT = ScreenInfo.isMobile() ? MB_MENU_HEIGHT : DT_MENU_HEIGHT;
export const ACCOUNT_SPACE_BETWEEN_MENU = ScreenInfo.isMobile() ? MB_SPACE_BETWEEN_MENU : DT_SPACE_BETWEEN_MENU;
export const ACCOUNT_MENU_FONT_HEIGHT = ScreenInfo.isMobile() ? MB_MENU_FONT_HEIGHT : DT_MENU_FONT_HEIGHT;
export const ACCOUNT_MENU_CNT_FONT_HEIGHT = ScreenInfo.isMobile() ? MB_MENU_CNT_FONT_HEIGHT : DT_MENU_CNT_FONT_HEIGHT;
export const ACCOUNT_MENU_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_MENU_MARGIN_BOTTOM : DT_MENU_MARGIN_BOTTOM;
export const ACCOUNT_SEARCH_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_SEARCH_MARGIN_BOTTOM : DT_SEARCH_MARGIN_BOTTOM;

type Props = {};

export interface UserDetailScreenQS {
  ucode: string;
  menu?: UserDetailMenuType_e;
}

export function UserDetailScreen(props: Props) {
  /**************************************
   * !! ref
   **************************************/
  const qsRef = useRef<UserDetailScreenQS>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User Detail - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hUserDetailState = useUserDetailState();
  const hRpc = useRpc();
  const hForceRender = useForceRender();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/

  useEffect(() => {
    const parseQS = (qsMenu: string) => {
      switch (qsMenu) {
        case UserDetailMenuType_e.INVEN:
          return UserDetailMenuType_e.INVEN;
        case UserDetailMenuType_e.ISSUE:
          return UserDetailMenuType_e.ISSUE;
        case UserDetailMenuType_e.SALE:
          return UserDetailMenuType_e.SALE;
        case UserDetailMenuType_e.SOLD:
          return UserDetailMenuType_e.SOLD;
        case UserDetailMenuType_e.BOUGHT:
          return UserDetailMenuType_e.BOUGHT;
        default:
          return undefined;
      }
    };

    DBGMSG('useEffect() init');

    // query string parsing
    const query: { menu?: string; ucode?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.ucode) {
      DBGMSG(`$$$$QS id: ${query.ucode}`);
      const ucode = query.ucode;
      qsRef.current = { ucode };
      if (hUserDetailState.state.user.ucode !== query.ucode) {
        hUserDetailState.act.clearState();
        hUserDetailState.act.changeState({ user: { ucode: query.ucode } });
      }
      fetchMenuCnt();
    } else {
      // ucode 없이 접근
      return;
    }

    if (query.menu) {
      DBGMSG(`$$$$QS menu: ${query.menu}`);
      const menu = parseQS(query.menu);
      if (!menu) {
        DBGMSGW('unknowk menu');
        return () => {};
      }
      qsRef.current = { menu, ucode: query.ucode };
      hUserDetailState.changeMenu(menu);
      hForceRender.forceRender();
    } else {
      // key 없이 접근
      hHistory.replace(Nav.brewUserDetailScreenPath({ menu: hUserDetailState.state.menu, ucode: query.ucode }));
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, [hLocation.search]);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchMenuCnt = async () => {
    try {
      const res = await hRpc.cobaltRpc.rpcAssetmgrCount({ ucode: qsRef.current?.ucode });
      hUserDetailState.act.changeState({
        menuCnt: {
          needUpdate: false,
          cnt: res,
        },
      });
      return res;
    } catch (e) {}

    // await
    return;
  };

  /**************************************
   * !! render function
   **************************************/

  const renderList = () => {
    if (!qsRef.current) return null;

    switch (qsRef.current.menu) {
      case UserDetailMenuType_e.INVEN:
        return <UserInventoryAstgList ucode={qsRef.current.ucode} />;
      case UserDetailMenuType_e.ISSUE:
        return <UserIssueAstgList ucode={qsRef.current.ucode} />;
      case UserDetailMenuType_e.SALE:
        return <UserSaleAstList ucode={qsRef.current.ucode} />;
      case UserDetailMenuType_e.SOLD:
        return <UserSoldAstList ucode={qsRef.current.ucode} />;
      case UserDetailMenuType_e.BOUGHT:
        return <UserBoughtAstList ucode={qsRef.current.ucode} />;
    }
  };

  /**************************************
   * !! render conf
   **************************************/
  // if (!hUserDetailState.state.user.userInfo) {
  //   return null;
  // }

  if (!qsRef.current) {
    DBGMSGW(qsRef.current);
    return null;
  }

  DBGMSG('render');
  return (
    <>
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          <FlexColumnDiv style={{ alignSelf: 'center' }}>
            {/* 사용자의 섬네일 , 아이디 , nft 지갑 주소 */}
            <UserThumbMain
              ucode={qsRef.current.ucode}
              prof={null}
              userWalletAddress={hUserDetailState.state.menuCnt.cnt?.scope_url ?? ''}
              containerStyle={{}}
            />
            <HEIGHT size={USER_THUMB_MAIN_MARGIN_BOTTOM} />
          </FlexColumnDiv>

          {/* 메뉴 */}
          <FlexRowDiv style={{ width: CLAY.BODY_WIDTH, overflowX: 'scroll' }}>
            {/* 인벤토리 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                if (!qsRef.current) return;

                DBGMSG(hR.strings.MARKET_ACCOUNT_READ_MENU_INVENTORY);
                hUserDetailState.queryClear();
                hHistory.replace(Nav.brewUserDetailScreenPath({ ucode: qsRef.current.ucode, menu: UserDetailMenuType_e.INVEN }));
                hUserDetailState.resetInventoryAstg();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MARKET_ACCOUNT_READ_MENU_INVENTORY}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailState.state.menu === UserDetailMenuType_e.INVEN ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailState.state.menuCnt.cnt.inventory})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailState.state.menu === UserDetailMenuType_e.INVEN ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 생성에셋 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                if (!qsRef.current) return;

                DBGMSG(hR.strings.MARKET_ACCOUNT_READ_MENU_CREATED);
                hUserDetailState.queryClear();
                hHistory.replace(Nav.brewUserDetailScreenPath({ ucode: qsRef.current.ucode, menu: UserDetailMenuType_e.ISSUE }));
                hUserDetailState.resetIssueAstg();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MARKET_ACCOUNT_READ_MENU_CREATED}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailState.state.menu === UserDetailMenuType_e.ISSUE ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailState.state.menuCnt.cnt.issued})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailState.state.menu === UserDetailMenuType_e.ISSUE ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 판매중 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                if (!qsRef.current) return;
                DBGMSG(hR.strings.MARKET_ACCOUNT_READ_MENU_ON_SALE);
                hUserDetailState.queryClear();
                hHistory.replace(Nav.brewUserDetailScreenPath({ ucode: qsRef.current.ucode, menu: UserDetailMenuType_e.SALE }));
                hUserDetailState.resetSaleList();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MARKET_ACCOUNT_READ_MENU_ON_SALE}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailState.state.menu === UserDetailMenuType_e.SALE ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailState.state.menuCnt.cnt.sale})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailState.state.menu === UserDetailMenuType_e.SALE ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 판매됨 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                if (!qsRef.current) return;
                DBGMSG(hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD);
                hUserDetailState.queryClear();
                hHistory.replace(Nav.brewUserDetailScreenPath({ ucode: qsRef.current.ucode, menu: UserDetailMenuType_e.SOLD }));
                hUserDetailState.resetSoldList();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailState.state.menu === UserDetailMenuType_e.SOLD ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailState.state.menuCnt.cnt.sold})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailState.state.menu === UserDetailMenuType_e.SOLD ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 구매함 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                if (!qsRef.current) return;
                DBGMSG(hR.strings.MARKET_ACCOUNT_READ_MENU_PURCHASED);
                hUserDetailState.queryClear();
                hHistory.replace(Nav.brewUserDetailScreenPath({ ucode: qsRef.current.ucode, menu: UserDetailMenuType_e.BOUGHT }));
                hUserDetailState.resetBoughtList();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MARKET_ACCOUNT_READ_MENU_PURCHASED}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailState.state.menu === UserDetailMenuType_e.BOUGHT ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailState.state.menuCnt.cnt.bought})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailState.state.menu === UserDetailMenuType_e.BOUGHT ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>
          </FlexRowDiv>
          <HEIGHT size={ACCOUNT_MENU_MARGIN_BOTTOM} />
        </FlexColumnDiv>
      </MobileScalableFlexColumn>

      {renderList()}
    </>
  );
}
