import React, { useEffect, useState } from 'react';
import { isAlpha, isBeta } from 'src/conf/AppConf';
import { useAuth } from 'src/hooks/useAuth';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { FlexColumnDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { Utils } from 'src/util/utils';

export type RewardAddrChangeSuccJsxProps = {
  onClose: () => void;
};

export const RewardAddrChangeSuccJsx = ({ onClose }: RewardAddrChangeSuccJsxProps) => {
  const [sRewardAddr, setRewardAddr] = useState<string | null>();

  const hR = useLocalSettings();
  const hKaikasConnectWrapper = useKaikasSvc();

  useEffect(() => {
    hKaikasConnectWrapper.getRewardAddr().then((addr) => {
      setRewardAddr(addr);
    });

    return () => {};
  }, []);

  return (
    <FlexColumnDiv
      style={{
        width: 380,
        height: 224,
        padding: 40,
        alignItems: 'cetner',
        // ...devOutlineF
      }}
    >
      <FlexColumnDiv style={{ height: 56, justifyContent: 'space-between' }}>
        <TextCon
          text={hR.strings.WALLET_REWARD_ADDR_CHANGE_DONE}
          textStyle={{ textAlign: 'center' }}
          color={R.colors.black}
          size={16}
          isBold
          containerStyle={{
            // ...devOutlineF,
            justifyContent: 'center',
          }}
        />
        <TextCon
          text={hR.strings.WALLET_REWARD_ADDR_CHANGE_DONE_DESC}
          color={R.colors.blueGrey}
          size={14}
          containerStyle={{
            // ...devOutlineF,
            justifyContent: 'center',
          }}
        />
      </FlexColumnDiv>
      <FlexRowDiv style={{ marginTop: 'auto' }}>
        <RoundTxtBtn
          text={hR.strings.WALLET_REWARD_ADDR_CHANGE_DONE_OK}
          width={300}
          height={48}
          fontSize={14}
          onClick={() => {
            onClose();
          }}
        />
      </FlexRowDiv>

      {(isAlpha() || isBeta()) && (
        <FlexColumnDiv style={{ position: 'absolute', top: 300, width: 700, backgroundColor: R.colors.bgGrey, padding: 10 }}>
          <TextCon text={'alpha/beta에서만 보임'} size={14} isBold />
          <TextCon text={sRewardAddr ?? ''} size={14} color={R.colors.pastelRed} isBold />
        </FlexColumnDiv>
      )}
    </FlexColumnDiv>
  );
};
