/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AppConf, isAlpha, isProd } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_CONTENT_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { useRpc } from 'src/hooks/useRpc';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { RoundTxtBtn2 } from 'src/ui/common_component/button/RoundTxtBtn2';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { KlayAddrInput } from 'src/ui/common_component/KlayAddrInput';
import { MyIssueAstgList } from 'src/ui/screen/AssetMgr/list/MyIssueAstgList';
import { MyInventoryAstgList } from 'src/ui/screen/AssetMgr/list/MyInventoryAstgList';
import { MySaleAstList } from 'src/ui/screen/AssetMgr/list/MySaleAstList';
import { MyBoughtAstList } from 'src/ui/screen/AssetMgr/list/MyBoughtAstList';
import { MySoldAstList } from 'src/ui/screen/AssetMgr/list/MySoldAstList';
import { BalanceWithTitle } from 'src/ui/common_component/user/BalanceWithTitle';
import { UserSettings } from 'src/ui/screen/AssetMgr/UserSettings';
import { UserThumbMain } from 'src/ui/common_component/UserThumbMain';
import { CLAY, CommonColumnBox, CommonRowBox } from 'src/ui/layout_constant';

import { DBGMSG, DBGMSGW } from 'src/util/utils';
import { MyFavoriteAssetList } from 'src/ui/screen/AssetMgr/list/MyFavoriteAssetList';
import {
  ACCOUNT_MENU_HEIGHT,
  USER_THUMB_MAIN_MARGIN_BOTTOM,
  ACCOUNT_MENU_MIN_WIDTH,
  ACCOUNT_SPACE_BETWEEN_MENU,
  ACCOUNT_MENU_FONT_HEIGHT,
  ACCOUNT_MENU_CNT_FONT_HEIGHT,
  ACCOUNT_MENU_MARGIN_BOTTOM,
} from 'src/ui/screen/AssetMgr/UserDetailScreen';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { Nav } from 'src/ui/screen/Nav';
import { MenuType_e } from 'src/ui/screen/User/MyPropertyHistoryScreen';
import qs from 'qs';
import { useForceRender } from 'src/hooks/useForceRender';
import { UserDetailMineMenuType_e } from 'src/redux/modules/userDetailMineModule';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const DT_BOX_WIDTH = 582;
const DT_BOX_HEIGHT = 226;
const DT_CURRENCY_IC_WIDTH = 18;
const DT_CURRENCY_IC_HEIGHT = 20;
const DT_DEFAULT_FONTSIZE_IN_BOX = 14;
const DT_DEFAULT_BOX_PADDING = 20;
const DT_REG_BTN_WIDTH = 236;
const DT_REG_BTN_HEIGHT = 34;
const DT_REG_BTN_FONTSIZE = 12;
const DT_CHANGE_BTN_WIDTH = 85;
const DT_CHANGE_BTN_HEIGHT = 28;
const DT_CHANGE_BTN_FONTSIZE = 12;
const DT_PRICE_FONTSIZE = 22;
const DT_PRICE_SYMBOL_FONTSIZE = 12;
const DT_BALANCE_CONTAINER_WIDTH = (582 - 43) / 2;
const DT_KLAY_ADDRESS_REG_GUIDE_FONTSIZE = 14;
const DT_MENU_MARGIN_TOP = 80 - ACCOUNT_MENU_HEIGHT / 2;

const MB_BOX_WIDTH = MOBILE_CONTENT_WIDTH;
const MB_BOX_HEIGHT = 146;
const MB_CURRENCY_IC_WIDTH = 16;
const MB_CURRENCY_IC_HEIGHT = 18;
const MB_DEFAULT_FONTSIZE_IN_BOX = 10;
const MB_DEFAULT_BOX_PADDING = 16;
const MB_REG_BTN_WIDTH = 179;
const MB_REG_BTN_HEIGHT = 28;
const MB_REG_BTN_FONTSIZE = 10;
const MB_CHANGE_BTN_WIDTH = 77;
const MB_CHANGE_BTN_HEIGHT = 28;
const MB_CHANGE_BTN_FONTSIZE = 10;
const MB_PRICE_FONTSIZE = 16;
const MB_PRICE_SYMBOL_FONTSIZE = 10;
const MB_BALANCE_CONTAINER_WIDTH = MOBILE_CONTENT_WIDTH - MB_DEFAULT_BOX_PADDING * 2;
const MB_KLAY_ADDRESS_REG_GUIDE_FONTSIZE = 10;
const MB_MENU_MARGIN_TOP = 30 - ACCOUNT_MENU_HEIGHT / 2;

const BOX_WIDTH = ScreenInfo.isMobile() ? MB_BOX_WIDTH : DT_BOX_WIDTH;
const BOX_HEIGHT = ScreenInfo.isMobile() ? MB_BOX_HEIGHT : DT_BOX_HEIGHT;
const CURRENCY_IC_WIDTH = ScreenInfo.isMobile() ? MB_CURRENCY_IC_WIDTH : DT_CURRENCY_IC_WIDTH;
const CURRENCY_IC_HEIGHT = ScreenInfo.isMobile() ? MB_CURRENCY_IC_HEIGHT : DT_CURRENCY_IC_HEIGHT;
const DEFAULT_FONTSIZE_IN_BOX = ScreenInfo.isMobile() ? MB_DEFAULT_FONTSIZE_IN_BOX : DT_DEFAULT_FONTSIZE_IN_BOX;
const DEFAULT_BOX_PADDING = ScreenInfo.isMobile() ? MB_DEFAULT_BOX_PADDING : DT_DEFAULT_BOX_PADDING;
const REG_BTN_WIDTH = ScreenInfo.isMobile() ? MB_REG_BTN_WIDTH : DT_REG_BTN_WIDTH;
const REG_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_REG_BTN_HEIGHT : DT_REG_BTN_HEIGHT;
const CHANGE_BTN_WIDTH = ScreenInfo.isMobile() ? MB_CHANGE_BTN_WIDTH : DT_CHANGE_BTN_WIDTH;
const CHANGE_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_CHANGE_BTN_HEIGHT : DT_CHANGE_BTN_HEIGHT;
const CHANGE_BTN_FONTSIZE = ScreenInfo.isMobile() ? MB_CHANGE_BTN_FONTSIZE : DT_CHANGE_BTN_FONTSIZE;
const REG_BTN_FONTSIZE = ScreenInfo.isMobile() ? MB_REG_BTN_FONTSIZE : DT_REG_BTN_FONTSIZE;

const PRICE_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_FONTSIZE : DT_PRICE_FONTSIZE;
const PRICE_SYMBOL_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_SYMBOL_FONTSIZE : DT_PRICE_SYMBOL_FONTSIZE;
const BALANCE_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_BALANCE_CONTAINER_WIDTH : DT_BALANCE_CONTAINER_WIDTH;
const KLAY_ADDRESS_REG_GUIDE_FONTSIZE = ScreenInfo.isMobile() ? MB_KLAY_ADDRESS_REG_GUIDE_FONTSIZE : DT_KLAY_ADDRESS_REG_GUIDE_FONTSIZE;
const MENU_MARGIN_TOP = ScreenInfo.isMobile() ? MB_MENU_MARGIN_TOP : DT_MENU_MARGIN_TOP;

type Props = {};

export interface UserDetailMineScreenQS {
  menu: UserDetailMineMenuType_e;
}

export function UserDetailMineScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  // new asset list
  const [sIsRewardAddrEditMode, setIsRewardAddrEditMode] = useState(false);
  const [sRewardAddr, setRewardAddr] = useState<string>('');

  const [sRewardAddrChangeBtnEnable, setRewardAddrChangeBtnEnable] = useState(false);
  const [sIsInvalidRewardAddr, setIsInvalidRewardAddr] = useState<boolean | undefined>(undefined);

  /**************************************
   * !! ref
   **************************************/
  const rewardAddrInputRef = useRef<HTMLInputElement | null>(null);
  const rollbackRewardAddr = useRef<string | undefined>();
  const qsRef = useRef<UserDetailMineScreenQS>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User MyPage - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hUserDetailMineState = useUserDetailMineState();
  const hAuth = useAuth();
  const hKaikasConnectWrapper = useKaikasSvc();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hForceRender = useForceRender();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    const parseQS = (qsMenu: string) => {
      switch (qsMenu) {
        case UserDetailMineMenuType_e.INVEN:
          return UserDetailMineMenuType_e.INVEN;
        case UserDetailMineMenuType_e.ISSUE:
          return UserDetailMineMenuType_e.ISSUE;
        case UserDetailMineMenuType_e.SALE:
          return UserDetailMineMenuType_e.SALE;
        case UserDetailMineMenuType_e.SOLD:
          return UserDetailMineMenuType_e.SOLD;
        case UserDetailMineMenuType_e.BOUGHT:
          return UserDetailMineMenuType_e.BOUGHT;
        case UserDetailMineMenuType_e.FAVORITE:
          return UserDetailMineMenuType_e.FAVORITE;
        case UserDetailMineMenuType_e.SETTINGS:
          return UserDetailMineMenuType_e.SETTINGS;
        default:
          return undefined;
      }
    };

    DBGMSG('useEffect() init');

    // query string parsing
    const query: { menu?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.menu) {
      DBGMSG(`$$$$QS menu: ${query.menu}`);

      const menu = parseQS(query.menu);
      if (!menu) {
        DBGMSGW('unknowk menu');
        return () => {};
      }
      qsRef.current = { menu };
      hUserDetailMineState.changeMenu(menu);
      hForceRender.forceRender();
    } else {
      // key 없이 접근
      hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: hUserDetailMineState.state.menu }));
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, [hLocation.search]);

  useEffect(() => {
    DBGMSG('useEffect()');

    // 리워드/입금 주소는 변경이 가능하기 때문에
    // redux의 상태를 그대로 바로 사용하지
    // sRewardAddr로 관리하기 때문에 초기값을 설정해주어야함
    setRewardAddr(hUserDetailMineState.state.user.userInfo?.reward_addr ?? '');

    return () => {
      DBGMSG('useEffect()');
    };
  }, []);

  useEffect(() => {
    DBGMSG('useEffect() hMyPageState.state.user.needUpdate');

    if (hUserDetailMineState.state.user.needUpdate) {
      fetchUserInfo();
    }

    return () => {
      DBGMSG('useEffect() hMyPageState.state.user.needUpdate');
    };
  }, [hUserDetailMineState.state.user.needUpdate]);

  useEffect(() => {
    DBGMSG('useEffect() hMyPageState.state.menuCnt.needUpdate');

    if (hUserDetailMineState.state.menuCnt.needUpdate) {
      fetchMenuCnt();
    }

    return () => {
      DBGMSG('useEffect() hMyPageState.state.menuCnt.needUpdate');
    };
  }, [hUserDetailMineState.state.menuCnt.needUpdate]);

  // 리워드/입금 주소 변경버튼 활성화 처리
  useEffect(() => {
    if (!hUserDetailMineState.state.user.userInfo) {
      return () => {};
    }

    if (
      sIsRewardAddrEditMode === true && // editMode 일경우
      sRewardAddr !== hUserDetailMineState.state.user.userInfo.reward_addr && // 기존주소와 다른지 체크
      sIsInvalidRewardAddr !== true // 유효한지
    ) {
      setRewardAddrChangeBtnEnable(true);
      return () => {};
    }
    setRewardAddrChangeBtnEnable(false);

    return () => {};
  }, [hUserDetailMineState.state.user.userInfo, sIsRewardAddrEditMode, sRewardAddr, sIsInvalidRewardAddr]);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchUserInfo = async () => {
    try {
      const res = await hRpc.cobaltRpc.rpcUserAccountInfoMutable({});
      hUserDetailMineState.act.changeState({
        user: {
          needUpdate: false,
          userInfo: res,
        },
      });
      setRewardAddr(res.reward_addr ?? '');
      return res;
    } catch (e) {}

    // await
    return;
  };

  const fetchMenuCnt = async () => {
    try {
      const res = await hRpc.cobaltRpc.rpcAssetmgrCount({});
      hUserDetailMineState.act.changeState({
        menuCnt: {
          needUpdate: false,
          cnt: res,
        },
      });
      return res;
    } catch (e) {}

    // await
    return;
  };

  const onRewardAddrEditMode = () => {
    setTimeout(() => {
      setIsRewardAddrEditMode(true);
      setIsInvalidRewardAddr(undefined);
      rewardAddrInputRef.current?.select();
      rewardAddrInputRef.current?.focus();
      rollbackRewardAddr.current = sRewardAddr;
    }, 100);
  };

  const offRewardAddrEditMode = () => {
    setTimeout(() => {
      setIsRewardAddrEditMode(false);
      setIsInvalidRewardAddr(undefined);
      rewardAddrInputRef.current?.setSelectionRange(0, 0);
      rewardAddrInputRef.current?.blur();

      // 포커스 해제시 원래 주소로 복구
      if (rollbackRewardAddr.current) {
        setRewardAddr(rollbackRewardAddr.current);
      }
    }, 100);
  };

  const payoutBtn = () => {
    if (!hUserDetailMineState.state.user.userInfo) return;
    hDlgFactory.showWithdraw({
      usdBalance: hUserDetailMineState.state.user.userInfo.balance_info.cusd,
      onOk: () => {
        hDlgFactory.hideWithdraw();
      },
    });
  };

  /**************************************
   * !! render function
   **************************************/

  const renderList = () => {
    if (!qsRef.current) return null;
    switch (qsRef.current.menu) {
      case UserDetailMineMenuType_e.INVEN:
        return <MyInventoryAstgList />;
      case UserDetailMineMenuType_e.ISSUE:
        return <MyIssueAstgList />;
      case UserDetailMineMenuType_e.SALE:
        return <MySaleAstList />;
      case UserDetailMineMenuType_e.SOLD:
        return <MySoldAstList />;
      case UserDetailMineMenuType_e.BOUGHT:
        return <MyBoughtAstList />;
      case UserDetailMineMenuType_e.FAVORITE:
        return <MyFavoriteAssetList />;
      case UserDetailMineMenuType_e.SETTINGS:
        if (!hAuth.authCommonRes) {
          return null;
        }
        return <UserSettings />;
    }
  };

  // 리워드 주소 등록후
  const renderRewardAddrTitle = () => {
    return (
      <FlexRowDiv
        style={{
          alignSelf: 'flex-start',
          // ...devOutlineFG
        }}
      >
        <TextCon text={hR.strings.MY_KLAY_ADDRESS_TITLE} size={DEFAULT_FONTSIZE_IN_BOX} color={R.colors.black} isBold />
        <WIDTH size={4} />
        <TooltipImg tooltipString={hR.strings.MY_KLAY_ADDRESS_TITLE_TOOLTIP} placement={ScreenInfo.isMobile() ? 'top' : 'right'} />
      </FlexRowDiv>
    );
  };

  // // 리워드 주소 등록전
  // const renderBeforeRewardAddrReg = () => {
  //   return (
  //     <TextCon
  //       text={hR.strings.MY_KLAY_ADDRESS_TITLE_TOOLTIP}
  //       size={KLAY_ADDRESS_REG_GUIDE_FONTSIZE}
  //       color={R.colors.black}
  //       isBold
  //       containerStyle={{
  //         width: BOX_WIDTH,
  //         // ...devOutlineFG,
  //         justifyContent: 'center',
  //       }}
  //     />
  //   );
  // };

  // 주소 입력 input
  const renderRewardAddrInput = () => {
    return (
      <KlayAddrInput
        key={`${hUserDetailMineState.state.user.userInfo?.reward_addr}`}
        getRef={(ref) => {
          rewardAddrInputRef.current = ref;
        }}
        placeHolder={hR.strings.MY_KLAY_ADDRESS_INPUT_PLACEHOLDER}
        value={sRewardAddr}
        disabled={sIsRewardAddrEditMode === false}
        onChange={(e) => {
          if (e.target.value.length > 42) return;
          setRewardAddr(e.target.value);
        }}
        onFocus={(e) => {
          DBGMSG('KlaytAddressInput onFocus');
        }}
        onBlur={(e) => {
          DBGMSG('onBlur');
          // 포커스 잃었을 경우, 변경하기 모드를 해제
          offRewardAddrEditMode();
        }}
        onIsinvalidAddress={(isInvalidAddress) => {
          setIsInvalidRewardAddr(isInvalidAddress);
        }}
      />
    );
  };

  // 주소 입력 시작/완료 버튼 (edit mode에 따라 버튼 속성이 다름)
  const renderRewardAddrChangeBtnWrapper = () => {
    if (!hUserDetailMineState.state.user.userInfo) return;

    if (
      hUserDetailMineState.state.user.userInfo.reward_addr === null ||
      (isAlpha() && hUserDetailMineState.state.user.userInfo.reward_addr.length === 0)
    ) {
      // 미등록 상태
      return renderRewardAddrChangeBtn({
        btnTxt: hR.strings.MY_KLAY_ADDRESS_INPUT_REG_BTN,
        onClick: async () => {
          hKaikasConnectWrapper.kaikasConnectAndRewardAddrReg(true);
        },
      });
    }

    if (!sIsRewardAddrEditMode) {
      // 변경시작 버튼
      return renderRewardAddrChangeBtn({
        btnTxt: hR.strings.MY_KLAY_ADDRESS_INPUT_CHANGE_BTN,
        onClick: () => {
          onRewardAddrEditMode();
        },
      });
    } else {
      // 변경완료 버튼
      return renderRewardAddrChangeBtn({
        btnTxt: hR.strings.MY_KLAY_ADDRESS_INPUT_DONE_BTN,
        onClick: async () => {
          rollbackRewardAddr.current = undefined;
          offRewardAddrEditMode();
          await hKaikasConnectWrapper.regRewardAddr(sRewardAddr);
          fetchUserInfo();
        },
      });
    }
  };

  const renderRewardAddrChangeBtn = (args: { btnTxt: string; onClick: () => void }) => {
    return (
      <RoundTxtBtn2
        text={args.btnTxt}
        width={CHANGE_BTN_WIDTH}
        height={CHANGE_BTN_HEIGHT}
        fontSize={CHANGE_BTN_FONTSIZE}
        onClick={(e) => {
          args.onClick();
        }}
        enable={sIsRewardAddrEditMode ? sRewardAddrChangeBtnEnable : true}
      />
    );
  };

  // 입금 주소 등록하기 버튼
  // const renderFirstRewardAddrRegBtn = () => {
  //   return (
  //     <RoundTxtBtn
  //       text={hR.strings.MY_KLAY_ADDRESS_INPUT_PLACEHOLDER}
  //       width={REG_BTN_WIDTH}
  //       height={REG_BTN_HEIGHT}
  //       fontSize={REG_BTN_FONTSIZE}
  //       onClick={() => {
  //         hKaikasConnectWrapper.kaikasConnectAndRewardAddrReg(true);
  //       }}
  //     />
  //   );
  // };

  /**************************************
   * !! render conf
   **************************************/
  if (!hAuth.isLogin) {
    return <TextCon text={'not login'} />;
  }

  if (!hUserDetailMineState.state.user.userInfo) {
    return <DataFetching />;
  }

  DBGMSG('render');
  return (
    <>
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          // key={}
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          <FlexColumnDiv style={{ alignSelf: 'center' }}>
            {/* 사용자의 섬네일 , 아이디 , nft 지갑 주소 */}
            <UserThumbMain
              ucode={hAuth.authCommonRes!.ucode}
              prof={hUserDetailMineState.state.user.userInfo.prof}
              userWalletAddress={hUserDetailMineState.state.menuCnt.cnt?.scope_url ?? ''}
              containerStyle={{}}
              // showEditBtn
              onEditBtnClick={() => {
                // todo
              }}
            />
            <HEIGHT size={USER_THUMB_MAIN_MARGIN_BOTTOM} />
          </FlexColumnDiv>

          <FlexRowDiv>
            <TextCon text={hR.strings.MY_MY_BALANCE_TITLE} color={R.colors.black} size={20} isBold />
            <WIDTH size={8} />
            <RoundTxtBtn2
              width={87}
              height={36}
              fontSize={12}
              text={hR.strings.MY_MY_BALANCE_BTN}
              onClick={() => {
                hHistory.push(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.POINT }));
              }}
            />
          </FlexRowDiv>
          <HEIGHT size={27} />

          {/* balacne 정보 */}
          {ScreenInfo.isDesktop() || ScreenInfo.isTablet() ? (
            <FlexRowDiv
              style={{
                width: CLAY.BODY_WIDTH,
                justifyContent: 'space-between',
                // ...devOutlineFB,
              }}
            >
              <CommonRowBox
                style={{
                  width: BOX_WIDTH,
                  height: BOX_HEIGHT,
                  padding: DEFAULT_BOX_PADDING,
                  alignItems: 'flex-start',
                  // ...devOutlineFG
                }}
              >
                {/* 포인트 잔고 */}
                <FlexColumnDiv
                  key={`${hUserDetailMineState.state.user.userInfo.balance_info.cpoint.price}`}
                  style={{ flex: 1, alignSelf: 'stretch', alignItems: 'flex-start' }}
                >
                  <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.cpoint} />
                  {/* 충전하기 버튼 */}
                  <RoundTxtBtn2
                    enable={false}
                    fontSize={12}
                    // text={hR.strings.MY_BALANCE_CPOINT_CHARGE}
                    text={hR.strings.MY_BALANCE_CPOINT_CHARGE}
                    width={228}
                    height={34}
                    onClick={() => {
                      hDlgFactory.showPointChargeListDlg({
                        myPointBalanceInfo: hUserDetailMineState.state.user.userInfo!.balance_info.cpoint,
                        onChargeBtn: ({ widgetUrl, prd_id }) => {
                          hDlgFactory.closePointChargeListDlg();
                          hDlgFactory.showPaymentwallWidetDlg({
                            widgetUrl,
                            onCloseBtn: () => {
                              hDlgFactory.hidePaymentwallWidetDlg();
                            },
                            onSuccPayment: () => {
                              fetchUserInfo();
                              hDlgFactory.showPgPointChargeSucc({
                                transaction_id: '',
                              });
                            },
                            onFailPayment: () => {},
                          });
                        },
                        onCloseBtn: () => {
                          hDlgFactory.hidePointChargeListDlg();
                        },
                      });
                    }}
                  />
                </FlexColumnDiv>

                {/* USD 잔고 */}
                <FlexColumnDiv
                  // key={`${hMyPageState.state.user.userInfo.balance_info.cusd.price}`}
                  style={{
                    flex: 1,
                    alignSelf: 'stretch',
                    alignItems: 'flex-start',
                    // ...devOutlineF
                  }}
                >
                  <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.cusd} />
                  {/* 인출하기 */}

                  <FlexRowDiv style={{ height: 34 }}>
                    {/* TODO 개발 완료될때까지 알파에만 표시 */}
                    {isAlpha() && (
                      <>
                        <RoundTxtBtn2
                          fontSize={12}
                          text={hR.strings.MY_BALANCE_CUSD_WITHDRAWAL}
                          width={184}
                          height={34}
                          onClick={() => {
                            payoutBtn();
                          }}
                          enable={true}
                        />
                        <WIDTH size={10} />
                        <ImgBtn src={[R.images.mypage_btn_cashout_history_normal3x, 34, 34]} onClick={() => {}} />
                      </>
                    )}
                  </FlexRowDiv>
                </FlexColumnDiv>
              </CommonRowBox>

              <CommonColumnBox
                style={{
                  width: BOX_WIDTH,
                  height: BOX_HEIGHT,
                  padding: DEFAULT_BOX_PADDING,
                  alignItems: 'flex-start',
                  // ...devOutlineF,
                }}
              >
                <FlexRowDiv
                  key={`${hUserDetailMineState.state.user.userInfo.balance_info.klay.price} ${hUserDetailMineState.state.user.userInfo.balance_info.cblt.price}`}
                  style={{ height: 85 }}
                >
                  {/* 클레이 잔고 */}
                  <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.klay} />

                  {/* cblt 잔고 */}
                  <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.cblt} />
                </FlexRowDiv>
                <HLINE size={'100%'} />

                <FlexColumnDiv>
                  <HEIGHT size={24} />
                  {renderRewardAddrTitle()}
                  <HEIGHT size={20} />
                  <FlexRowDiv>
                    {renderRewardAddrInput()}
                    <WIDTH size={27} />
                    {renderRewardAddrChangeBtnWrapper()}
                  </FlexRowDiv>
                </FlexColumnDiv>
              </CommonColumnBox>
            </FlexRowDiv>
          ) : (
            <>
              <CommonColumnBox style={{ width: BOX_WIDTH, height: 155, padding: 16 }}>
                {/* 포인트 잔고 */}
                <FlexRowDiv style={{ alignItems: 'flex-end' }}>
                  <FlexDiv style={{ width: 119 + 60 }}>
                    <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.cpoint} />
                  </FlexDiv>
                  {/* 충전하기 버튼 */}
                  <RoundTxtBtn2
                    enable={false}
                    fontSize={10}
                    text={hR.strings.MY_BALANCE_CPOINT_CHARGE}
                    width={61}
                    height={24}
                    containerStyle={{ marginLeft: 'auto' }}
                    onClick={() => {
                      hDlgFactory.showPointChargeListDlg({
                        myPointBalanceInfo: hUserDetailMineState.state.user.userInfo!.balance_info.cpoint,
                        onChargeBtn: ({ widgetUrl, prd_id }) => {
                          hDlgFactory.closePointChargeListDlg();

                          // Payment Wallet Widget
                          hDlgFactory.showPaymentwallWidetDlg({
                            widgetUrl,
                            onCloseBtn: () => {
                              hDlgFactory.hidePaymentwallWidetDlg();
                            },
                            onSuccPayment: () => {
                              fetchUserInfo();
                              hDlgFactory.showPgPointChargeSucc({
                                transaction_id: '',
                              });
                            },
                            onFailPayment: () => {},
                          });
                        },
                        onCloseBtn: () => {
                          hDlgFactory.hidePointChargeListDlg();
                        },
                      });
                    }}
                  />
                </FlexRowDiv>

                <HEIGHT size={21} />

                {/* USD 잔고 */}
                <FlexRowDiv style={{ alignItems: 'flex-end' }}>
                  <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.cusd} />
                  {/* 인출하기 */}
                  <FlexRowDiv style={{ height: 24 }}>
                    {/* TODO 개발 완료될때까지 알파에만 표시 */}
                    {isAlpha() && (
                      <>
                        <RoundTxtBtn2
                          //
                          fontSize={10}
                          text={hR.strings.MY_BALANCE_CUSD_WITHDRAWAL}
                          width={61}
                          height={24}
                          onClick={() => {
                            payoutBtn();
                          }}
                          enable={true}
                          containerStyle={{ marginLeft: 'auto' }}
                        />
                        <WIDTH size={4} />
                        <ImgBtn src={[R.images.mypage_btn_cashout_history_normal3x, 24, 24]} onClick={() => {}} />
                      </>
                    )}
                  </FlexRowDiv>
                </FlexRowDiv>
              </CommonColumnBox>
              <HEIGHT size={12} />
              <CommonColumnBox style={{ width: BOX_WIDTH, height: 280, padding: 16 }}>
                <FlexRowDiv style={{ height: 51 }}>
                  {/* 클레이 잔고 */}
                  <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.klay} />
                </FlexRowDiv>

                <HEIGHT size={21} />

                {/* cblt 잔고 */}
                <FlexRowDiv style={{ height: 51 }}>
                  <BalanceWithTitle prc_info={hUserDetailMineState.state.user.userInfo.balance_info.cblt} />
                </FlexRowDiv>
                <HEIGHT size={20} />
                <HLINE size={'100%'} />
                <HEIGHT size={12} />
                {renderRewardAddrTitle()}
                <HEIGHT size={11} />
                <FlexColumnDiv
                  style={{
                    width: BOX_WIDTH,
                    alignSelf: 'center',
                    alignItems: 'center',
                    // ...devOutlineF
                  }}
                >
                  {renderRewardAddrInput()}
                  <HEIGHT size={12} />
                  {renderRewardAddrChangeBtnWrapper()}
                </FlexColumnDiv>
              </CommonColumnBox>
            </>
          )}
          {/* balacne 정보 end */}

          <HEIGHT size={MENU_MARGIN_TOP} />

          {/* 메뉴 */}
          <FlexRowDiv
            style={{
              width: CLAY.BODY_WIDTH,
              height: ScreenInfo.isMobile() ? ACCOUNT_MENU_HEIGHT : undefined,
              overflowX: ScreenInfo.isMobile() ? 'scroll' : 'visible',
              // overflowY: 'visible',
              // ...devOutlineFG
            }}
          >
            {/* 인벤토리 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                DBGMSG(hR.strings.MY_MENU_INVENTORY);
                hUserDetailMineState.queryClear();
                hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: UserDetailMineMenuType_e.INVEN }));
                hUserDetailMineState.resetInventoryAstg();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MY_MENU_INVENTORY}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  isBold
                  color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.INVEN ? R.colors.black : R.colors.veryLightGrey}
                />
                <WIDTH size={3} />

                {hUserDetailMineState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailMineState.state.menuCnt.cnt.inventory})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.INVEN ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 생성에셋 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                DBGMSG(hR.strings.MY_MENU_CREATED);
                hUserDetailMineState.queryClear();
                hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: UserDetailMineMenuType_e.ISSUE }));
                hUserDetailMineState.resetIssueAstg();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MY_MENU_CREATED}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={qsRef.current?.menu === UserDetailMineMenuType_e.ISSUE ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailMineState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailMineState.state.menuCnt.cnt.issued})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.ISSUE ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 판매중 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                DBGMSG(hR.strings.MY_MENU_FOR_SALE);
                hUserDetailMineState.queryClear();
                hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: UserDetailMineMenuType_e.SALE }));
                hUserDetailMineState.resetSaleList();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MY_MENU_FOR_SALE}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.SALE ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailMineState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailMineState.state.menuCnt.cnt.sale})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.SALE ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 판매됨 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                DBGMSG(hR.strings.MY_MENU_SOLD);
                hUserDetailMineState.queryClear();
                hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: UserDetailMineMenuType_e.SOLD }));
                hUserDetailMineState.resetSoldList();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MY_MENU_SOLD}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.SOLD ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailMineState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailMineState.state.menuCnt.cnt.sold})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.SOLD ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 구매함 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                DBGMSG(hR.strings.MY_MENU_PURCHASED);
                hUserDetailMineState.queryClear();
                hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: UserDetailMineMenuType_e.BOUGHT }));
                hUserDetailMineState.resetBoughtList();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MY_MENU_PURCHASED}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.BOUGHT ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailMineState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailMineState.state.menuCnt.cnt.bought})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.BOUGHT ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 즐겨찾기 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                DBGMSG(hR.strings.MY_MENU_FAVORITE);
                hUserDetailMineState.queryClear();
                hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: UserDetailMineMenuType_e.FAVORITE }));
                hUserDetailMineState.resetFavoriteList();
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MY_MENU_FAVORITE}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.FAVORITE ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
                <WIDTH size={3} />
                {hUserDetailMineState.state.menuCnt.cnt && (
                  <TextCon
                    text={`(${hUserDetailMineState.state.menuCnt.cnt.favorite})`}
                    size={ACCOUNT_MENU_CNT_FONT_HEIGHT}
                    color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.FAVORITE ? R.colors.black : R.colors.veryLightGrey}
                  />
                )}
              </FlexRowDiv>
            </FlexRowDiv>

            {/* 내계정 */}
            <FlexRowDiv
              style={{
                flex: '0 0 auto',
                height: ACCOUNT_MENU_HEIGHT,
                minWidth: ACCOUNT_MENU_MIN_WIDTH,
                marginRight: ACCOUNT_SPACE_BETWEEN_MENU,
                cursor: 'pointer',
                alignItems: 'center',
                // ...devOutlineF
              }}
              onClick={() => {
                DBGMSG(hR.strings.MY_MENU_MYACCOUNT);
                hHistory.replace(Nav.brewUserDetailMineScreenPath({ menu: UserDetailMineMenuType_e.SETTINGS }));
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineFB
                }}
              >
                <TextCon
                  text={hR.strings.MY_MENU_MYACCOUNT}
                  size={ACCOUNT_MENU_FONT_HEIGHT}
                  color={hUserDetailMineState.state.menu === UserDetailMineMenuType_e.SETTINGS ? R.colors.black : R.colors.veryLightGrey}
                  isBold
                />
              </FlexRowDiv>
            </FlexRowDiv>
          </FlexRowDiv>
          <HEIGHT size={ACCOUNT_MENU_MARGIN_BOTTOM} />
        </FlexColumnDiv>
      </MobileScalableFlexColumn>

      {renderList()}
      {ScreenInfo.isMobile() && (
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      )}
    </>
  );
}
