/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect } from 'react';
import { useDlg } from 'src/hooks/useDlg';
import { devOutlineF } from 'src/ui/base_component/etc';
import { DlgJsxMulti } from 'src/ui/dlg/DlgJsxMulti';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {};

export function DlgContainer(props: Props) {
  /**************************************
   * !! hooks
   **************************************/
  const hDialog = useDlg();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    if (hDialog.state.dlgJsxMultiList.length > 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [hDialog.state.dlgJsxMultiList.length]);

  // RPC 상태에따라 자동으로 로딩표시가 뜨게 함.
  // 동시에 뜨면 안되는, 다이얼로그들이 있어서 사용하지 않음, ('결제중입니다.' 등)
  // useEffect(() => {
  //   if (!hRpc.rpcState.rpcInfoList) return () => {};

  //   const lastIdx = hRpc.rpcState.rpcInfoList.length - 1;
  //   const startIdx = lastIdx - 2 < 0 ? 0 : lastIdx - 2;
  //   const targetList = hRpc.rpcState.rpcInfoList.slice(startIdx, lastIdx + 1);
  //   DBGMSG(`$$$$targetList`);
  //   DBGMSG(`$$$$targetList`, targetList);

  //   if (targetList.some((rpcInfo) => rpcInfo.rpcStatus === RpcStatus.InProgress)) {
  //     hDlgFactory.showLoading();
  //   } else {
  //     hDlgFactory.hideLoading();
  //   }

  //   return () => {};
  // }, [hRpc.rpcState.pendingCnt]);

  return (
    <>
      {hDialog.state.dlgJsxMultiList.map((dlgStateJsxMulti) => {
        return <DlgJsxMulti dlg={dlgStateJsxMulti} />;
      })}
    </>
  );
}
