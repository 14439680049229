/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDebounce, useEffectOnce } from 'react-use';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, devOutlineF, WIDTH } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import { CLAY, PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const InputWithBorder = styled.input`
  font-family: ${R.fonts.SpoqaJP};
  color: ${R.colors.black};
  border-width: 0px;
  background-color: ${R.colors.transparent};
  ::placeholder {
    color: ${R.colors.cloudyBlue};
  }
  &:focus {
    outline: none;
  }
`;

type Props = {
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  fontSize?: number;
  placeHolder?: string;
  defaultValue?: string;
  type?: 'number' | 'text' | 'url';
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;

  btnType?: '+' | '-';
  onBtnClick?: () => void;
  disabled?: boolean;
};

export function SRoundInputWithBtn({
  width = '100%',
  height = 44,
  borderRadius = 14,
  fontSize = 14,
  placeHolder,
  defaultValue,
  type = 'text',
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  btnType,
  onBtnClick = () => {
    DBGMSG(`${onBtnClick}:${btnType}`);
  },
  disabled = false,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sInputTxt, setInputTxt] = useState('');
  const [debouncedSearchTxt, setDebouncedSearchTxt] = useState(sInputTxt);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [sIsCollectionInputFocus, setIsCollectionInputFocus] = useState<boolean>(false);

  /**************************************
   * !! ref
   **************************************/
  const collectionInputRef = useRef<HTMLInputElement | null>(null);
  const isCollectionInputFocusRef = useRef<boolean>(false);

  /**************************************
   * !! hooks
   **************************************/
  const [,] = useDebounce(
    () => {
      setIsTyping(false);
      if (sInputTxt === debouncedSearchTxt) return;
      setDebouncedSearchTxt(sInputTxt);
      DBGMSG(`debouncedInputText:${sInputTxt}`);
    },
    1000,
    [sInputTxt]
  );

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    if (defaultValue) setInputTxt(defaultValue);

    return () => {
      DBGMSG('useEffect() release');
    };
  }, [defaultValue]);
  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  DBGMSG('render');
  return (
    <AnimatedFlexRowDiv
      style={Object.assign(
        {
          width: width,
          height: height,
          borderRadius: borderRadius,
          justifyContent: 'flex-start',
          alignItems: 'center',
        },
        sIsCollectionInputFocus ? PropsSRoundEnabled : PropsSRoundDisabled
      )}
    >
      {/* input */}
      <InputWithBorder
        // fontSize={fontSize}
        style={{
          fontSize: fontSize,
          // width: 'stre',
          flex: 1,
          paddingBottom: 3,
          paddingLeft: 14,
          paddingRight: 14,
          // ...devOutlineFF,
        }}
        ref={(input) => {
          collectionInputRef.current = input;
        }}
        type={type}
        min={type === 'number' ? 0 : undefined}
        max={type === 'number' ? 10 : undefined}
        step={type === 'number' ? 1 : undefined}
        maxLength={type === 'text' ? 20 : undefined}
        pattern="^\d*(\.\d{0,2})?$"
        value={sInputTxt}
        placeholder={placeHolder}
        disabled={disabled}
        onFocus={() => {
          DBGMSG('Input onFocus');
          isCollectionInputFocusRef.current = true;
          setIsCollectionInputFocus(true);
          onFocus && onFocus();
        }}
        onBlur={() => {
          DBGMSG('Input onBlur');
          isCollectionInputFocusRef.current = false;
          setIsCollectionInputFocus(false);
          onBlur && onBlur();
        }}
        onChange={(e) => {
          DBGMSG(`onChange: ${e.target.value}`);
          setIsTyping(true);

          if (type === 'number') {
            if (e.target.value === '.') return;
            setInputTxt(e.target.value);
          } else {
            setInputTxt(e.target.value);
          }

          onChange && onChange(e);
        }}
        onKeyPress={(e) => {
          DBGMSG(`onKeyPress code:${e.code}`);
          DBGMSG(`onKeyPress key:${e.key}`);
        }}
        // onKeyDown={(e) => {
        //   DBGMSG(`onKeyDown: ${e.key}`);
        //   if (e.key === 'Enter') {
        //     DBGMSG('Enter');
        //     // if (searchTxt.length !== 0) handleSearch(searchTxt);
        //   }
        //   onKeyDown && onKeyDown(e);
        // }}
        // returnKeyType="search"
        // clearButtonMode="while-editing"
      />
      {btnType && (
        <ImgBtn
          src={[btnType === '+' ? R.images.creator_btn_add3x : R.images.creator_btn_delete3x, CLAY.NORMAL_IC_SIZE, CLAY.NORMAL_IC_SIZE]}
          onClick={() => {
            onBtnClick && onBtnClick();
          }}
          containerStyle={{
            // ...devOutlineF,
            position: 'absolute',
            right: 8,
          }}
        />
      )}
      <WIDTH size={10} />
    </AnimatedFlexRowDiv>
  );
}
