import parseAPNG from 'apng-js';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import {
  add,
  differenceInDays,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  Duration,
  format,
  intervalToDuration,
  isSameDay,
  isSameMonth,
  isToday,
  isYesterday,
  sub,
} from 'date-fns';
// import {invalideRegexpes} from 'src/res/invalidRegex';
import _ from 'lodash';
import * as _reactDeviceDetect from 'react-device-detect';
import rfdc from 'rfdc';
import {
  AssetAttribute_i,
  AttributeInfoWithValue_i,
  DisplaySrcForm_t,
  DisplaySrc_t,
  ReqAudioSrc_i,
  ReqImageSrc_i,
  ReqVideoSrc_i,
} from 'src/model/model';
import {
  CbtAssetType_e,
  CbtAttributeType_e,
  CbtAttributeValue_i,
  CbtCurrencyInfo_i,
  CbtCurrency_e,
  CbtReqPriceInfo_i,
  CbtResPriceInfo_i,
} from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { CbtStompMsgCode_e } from 'src/rx/messageService';
import stringLength from 'string-length';
import { v4 as uuidv4 } from 'uuid';

export const DBGMSG = console.log;
export const DBGMSGI = console.info;
export const DBGMSGW = console.warn;
export const DBGMSGE = console.error;
export const DBGMSGAS = console.assert;

export function random(begin: number, end: number) {
  // return Math.trunc(Math.random() * (end - start) + start);
  return Math.floor(Math.random() * (end - begin + 1)) + begin;
}

export function randomChoice(items: any[]) {
  const index = random(0, items.length - 1);
  return items[index];
}

export function textLength(text: string) {
  return stringLength(text);
}

export function replaceName(text: string, name: string) {
  return text.replace(/%name%/g, name);
}

export function isJapaneseAlphaNum(text: string): boolean {
  if (text.length === 0) return false;
  // https://stackoverflow.com/questions/15033196/using-javascript-to-check-whether-a-string-contains-japanese-characters-includi/15034560#15034560
  // 3000-303f Japanese-style punctuation
  // 3040-309f 히라가나
  // 30a0-30ff full 카타카나
  // ff00-ff65 full Roman
  // ff66-ff9f half 카타카나
  // 4e00-9faf Common and uncommon kanji
  // 3400-4dbf CJK unified ideographs Extension A - Rare Kanji

  // 31F0-31FF Katakana Phonetic Extensions
  // 2E80-2FD5 Kanji Radicals

  // [々〆〤]  ??
  const REGEX_JAPANESE: RegExp =
    /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]|[a-zA-Z0-9]|[ａ-ｚＡ-Ｚ０-９]/g;

  let unmatchedTxt: string = '';

  const everyRes = Array.from(text).every((ch) => {
    const testRes = REGEX_JAPANESE.test(text);
    console.debug(`${ch}:${testRes}`);
    if (testRes === false) {
      unmatchedTxt = ch;
    }
    return testRes;
  });

  console.debug(`everyRes:${everyRes}`);
  unmatchedTxt && console.debug(`unmatchedTxt:${unmatchedTxt}`);
  return everyRes;

  // const matchRes: RegExpMatchArray | null = text.match(REGEX_JAPANESE);
  // console.debug(`matchRes(${matchRes?.length}) length:(${text.length})`);
  // if (matchRes?.length === text.length) return true;
  // else return false;
  // const hasJapanese = (str: string) => REGEX_JAPANESE.test(str);
}

// !! ====================================================================================
// !! string 관련
// !! ====================================================================================
function zeroPadding(num: number, length: number): string {
  return (Array(length).join('0') + num).slice(-length);
}

// 0x88a970f06647c52b050581d51e2db5fa67c7d6f3
// 0x88a970f···c7d6f3
const shortKlatynAddress = (address: string): string => {
  const start = address.substring(0, 7);
  const end = address.substring(36);
  return `${start}···${end}`;
};

// 0x39652321f2b2a492cc395233f7f988ac06a60a1ed759d8d0380851d04a6873fe
// 0x88a970f···c7d6f3
const shortKlatynTxHash = (txHash: string): string => {
  const start = txHash.substring(0, 7);
  const end = txHash.substring(60);
  return `${start}···${end}`;
};

// 앞 개수 headCnt
// 뒤 개수 tailCnt
const shortString = (target: string, headCnt: number, tailCnt: number): string => {
  const start = target.substring(0, headCnt);
  const end = target.substring(target.length - tailCnt);
  return `${start}···${end}`;
};

const brewKlaytnUserAccountUrl = ({ accountAddress, isMainnet }: { accountAddress: string; isMainnet: boolean }): string => {
  if (isMainnet) return `https://scope.klaytn.com/account/${accountAddress}?tabId=txList`;
  else return `https://baobab.scope.klaytn.com/account/${accountAddress}?tabId=txList`;
};

const parsePlaceholder = (target: string, placeholder: string) => {
  const idx = target.indexOf(placeholder);
  if (idx === -1) {
    return { begin: '', end: '' };
  }
  const phLen = placeholder.length;
  return {
    begin: target.slice(0, idx),
    end: target.slice(phLen + idx),
  };
};

const replacePlaceholder = ({ target, placeholder, txt }: { target: string; placeholder: string; txt: string }) => {
  const parsed = parsePlaceholder(target, placeholder);
  return `${parsed.begin}${txt}${parsed.end}`;
};

// !! ====================================================================================
// !! date 관련
// !! ====================================================================================
const now = (): Date => {
  return new Date();
};

// const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSS (OOOO)';
const DEFAULT_DATE_FORMAT = 'yyyy.MM.dd HH:mm';

const brewFomatString = (dateOrMs: number | Date, fmt?: string): string => {
  const date = getDate(dateOrMs);
  const _fmt = fmt !== undefined ? fmt : DEFAULT_DATE_FORMAT;
  // const fmt = "yyyy-MM-dd'Tasdf'HH:mm:ss.SSS (OOOO)";
  // const fmt2 = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";

  return format(date, _fmt);
};

// const dateDiff = (A_dateOrMs: number | Date, B_dateOrMs: number | Date): Date | number => {};
const dateAddDay = (dateOrMs: number | Date, days: number): Date | number => {
  const date = getDate(dateOrMs);
  const dateAdded = add(date, { days });

  if (dateOrMs instanceof Date) {
    return dateAdded;
  } else {
    return dateAdded.getTime();
  }
};

const dateSubDay = (dateOrMs: number | Date, days: number): Date | number => {
  const date = getDate(dateOrMs);
  const dateAdded = sub(date, { days });

  if (dateOrMs instanceof Date) {
    return dateAdded;
  } else {
    return dateAdded.getTime();
  }
};

const dateAddMonth = (dateOrMs: number | Date, months: number): Date | number => {
  const date = getDate(dateOrMs);
  const dateAdded = add(date, { months });

  if (dateOrMs instanceof Date) {
    return dateAdded;
  } else {
    return dateAdded.getTime();
  }
};

const dateSubMonth = (dateOrMs: number | Date, months: number): Date | number => {
  const date = getDate(dateOrMs);
  const dateAdded = sub(date, { months });

  if (dateOrMs instanceof Date) {
    return dateAdded;
  } else {
    return dateAdded.getTime();
  }
};

const getDate = (dateOrMs: number | Date): Date => {
  let date: Date;
  if (dateOrMs instanceof Date) {
    date = dateOrMs;
  } else {
    date = new Date(dateOrMs);
  }
  return date;
};

const getRemainTimeFromCurrent = (futureDateOrMs: number | Date): Duration => {
  const cur = new Date();
  const diff = getInterval(cur, futureDateOrMs);
  return diff;
};

const getInterval = (startDateOrMs: number | Date, endDateOrMs: number | Date): Duration => {
  const start = getDate(startDateOrMs);
  const end = getDate(endDateOrMs);
  console.debug(`$$$$ getInterval start:${start}`);
  console.debug(`$$$$ getInterval   end:${end}`);
  return intervalToDuration({ start, end });
};

const getDiffDate = (startDateOrMs: number | Date, endDateOrMs: number | Date) => {
  const start = getDate(startDateOrMs);
  const end = getDate(endDateOrMs);

  const start_year = start.getFullYear();
  const start_month = start.getMonth() + 1;
  const start_day = start.getDate();
  const start_hour = start.getHours();
  const start_min = start.getMinutes();
  const start_sec = start.getSeconds();

  const end_year = end.getFullYear();
  const end_month = end.getMonth() + 1;
  const end_day = end.getDate();
  const end_hour = end.getHours();
  const end_min = end.getMinutes();
  const end_sec = end.getSeconds();

  return {
    year: start_year - end_year,
    month: start_month - end_month,
    day: start_day - end_day,
    hour: start_hour - end_hour,
    min: start_min - end_min,
    sec: start_sec - end_sec,
  };
};

const diffInSeconds = (startDateOrMs: number | Date, endDateOrMs: number | Date): number => {
  const start = getDate(startDateOrMs);
  const end = getDate(endDateOrMs);
  return differenceInSeconds(start, end);
};

const diffInMinutes = (startDateOrMs: number | Date, endDateOrMs: number | Date): number => {
  const start = getDate(startDateOrMs);
  const end = getDate(endDateOrMs);

  return differenceInMinutes(start, end);
};

// 시간이 분까지 같은지 체크
// 2020.7.17 3:16:11 === 2020.7.17 3:16:22 -> true
// 2020.7.17 3:16:11 === 2020.7.17 3:17:00 -> false
const isEqualMinute = (startDateOrMs: number | Date, endDateOrMs: number | Date) => {
  const start = getDate(startDateOrMs);
  const end = getDate(endDateOrMs);
  const diff = getDiffDate(start, end);
  if (diff.year === 0 && diff.month === 0 && diff.day === 0 && diff.hour === diff.min) return true;
  return false;
};

const diffInMillSeconds = (startDateOrMs: number | Date, endDateOrMs: number | Date): number => {
  const start = getDate(startDateOrMs);
  const end = getDate(endDateOrMs);
  return differenceInMilliseconds(start, end);
};

const diffIndDays = (startDateOrMs: number | Date, endDateOrMs: number | Date): number => {
  const start = getDate(startDateOrMs);
  const end = getDate(endDateOrMs);
  return differenceInDays(start, end);
};

const _isToday = (dateOrMs: number | Date): boolean => {
  const date = getDate(dateOrMs);
  return isToday(date);
};

const _isYesterday = (dateOrMs: number | Date): boolean => {
  const date = getDate(dateOrMs);
  return isYesterday(date);
};

const _isSameDay = (AdateOrMs: number | Date, BdateOrMs: number | Date): boolean => {
  const Adate = getDate(AdateOrMs);
  const Bdate = getDate(BdateOrMs);
  return isSameDay(Adate, Bdate);
};

const _isSameMonth = (AdateOrMs: number | Date, BdateOrMs: number | Date): boolean => {
  const Adate = getDate(AdateOrMs);
  const Bdate = getDate(BdateOrMs);
  return isSameMonth(Adate, Bdate);
};

const getDateFromYYYYMMDD = (yyyymmdd: string) => {
  if (yyyymmdd.length !== 8) {
    return;
  }

  const yyyy = yyyymmdd.slice(0, 4);
  const mm = yyyymmdd.slice(4, 6);
  const dd = yyyymmdd.slice(6, 8);

  const nYYYY = parseInt(yyyy);
  const nMM = parseInt(mm) - 1;
  const nDD = parseInt(dd);

  return new Date(nYYYY, nMM, nDD);
};

const brewYYYYMMDD = (dateOrMs: number | Date) => {
  const date = getDate(dateOrMs);
  return format(date, 'yyyyMMdd');
};

// const brewCommonDate = (dateOrMs: number | Date) => {
//   const date = getDate(dateOrMs);
//   const year = date.getFullYear();
//   const month = date.getMonth() + 1;
//   const day = date.getDate();

//   return `${year}${R.strings.COMMON_UNIT_YEAR} ${month}${R.strings.COMMON_UNIT_MONTH} ${day}${R.strings.COMMON_UNIT_DAY}`;
// };

// const brewBirth = (y: number, m: number, d: number) => {
//   return `${y}${R.strings.COMMON_UNIT_YEAR} ${m}${R.strings.COMMON_UNIT_MONTH} ${d}${R.strings.COMMON_UNIT_DAY}`;
// };

// const brewCommonDateWithoutYear = (dateOrMs: number | Date) => {
//   const date = getDate(dateOrMs);
//   const month = date.getMonth() + 1;
//   const day = date.getDate();

//   return `${month}${R.strings.COMMON_UNIT_MONTH} ${day}${R.strings.COMMON_UNIT_DAY}`;
// };

const mssleep = async <T>(ms: number, value?: T) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value || true);
    }, ms);
  });
};

const deepCopy = <T>(any: T): T => {
  return rfdc({ proto: true })(any);
  // const copy = rfdc({proto: true})(any);
  // console.debug(`$$$$ deepCopy result : ${JSON.stringify(copy, undefined, 4)} `);
  // return rfdc({proto: true})(any);
};

const _diff = <T>(a: T[], b: T[]) => {
  return _.xor(a, b);
};

// const getPathname = (url: string) => {
//   const urlParse = new Url(url);

//   console.debug(`$$$$ auth: ${urlParse.auth}`);
//   console.debug(`$$$$ hash: ${urlParse.hash}`);
//   console.debug(`$$$$ host: ${urlParse.host}`);
//   console.debug(`$$$$ hostname: ${urlParse.hostname}`);
//   console.debug(`$$$$ href: ${urlParse.href}`);
//   console.debug(`$$$$ origin: ${urlParse.origin}`);
//   console.debug(`$$$$ password: ${urlParse.password}`);
//   console.debug(`$$$$ pathname: ${urlParse.pathname}`);
//   console.debug(`$$$$ port: ${urlParse.port}`);
//   console.debug(`$$$$ protocol: ${urlParse.protocol}`);
//   console.debug(`$$$$ query: ${urlParse.query}`);
//   console.debug(`$$$$ slashes: ${urlParse.slashes}`);
//   console.debug(`$$$$ username: ${urlParse.username}`);
//   return urlParse.pathname;
// };

// const md5hex = (str: string) => {
//   return Crypto.MD5(str).toString();
// }

const viewportSize = () => ({
  width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
});

const randomUUID = () => {
  return uuidv4();
};

// !! ====================================================================================
// !! local storage 관련
// !! ====================================================================================
const saveLocalStorage = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const loadLocalStorage = <T>(key: string) => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value) as T;
  }
  return undefined;
};

const clearLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

// !! ====================================================================================
// !! session storage 관련
// !! ====================================================================================
const saveSessionStorage = <T>(key: string, value: T) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const loadSessionStorage = <T>(key: string) => {
  const value = sessionStorage.getItem(key);
  if (value) {
    return JSON.parse(value) as T;
  }
  return undefined;
};

const clearSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

// !! ====================================================================================
// !! file 관련
// !! ====================================================================================
// input element onchange event 를 받아 파일을 읽고 url과 size를 리턴
export type InputFileType_t = 'png' | 'apng' | 'gif' | 'jpg' | 'mp4' | 'mp3' | 'unknown';
const loadInputFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
  DBGMSG('onInputChange');
  return new Promise<{ localUrl: string; fileObj: File; type: InputFileType_t }>((resolve, reject) => {
    const fileList = event.target.files;

    if (!fileList) {
      DBGMSGW('fileList is undefined');
      return reject('fileList is undefined');
    }
    if (!fileList[0]) {
      DBGMSGW('fileList[0] is undefined');
      return reject('fileList[0] is undefined');
    }

    const fileObj = fileList[0];

    // DBGMSG(`$$$$INPUT name: ${fileObj.name}`);
    // DBGMSG(`$$$$INPUT size: ${fileObj.size}`);
    // DBGMSG(`$$$$INPUT type: ${fileObj.type}`);

    const reader = new FileReader();
    reader.onload = (progressEvent) => {
      DBGMSG(progressEvent);
      if (progressEvent.target) {
        const blob = new Blob([progressEvent.target.result as ArrayBuffer], { type: fileObj.type });
        const createObjURL = window.URL.createObjectURL(blob);
        DBGMSG(`createObjURL: ${createObjURL}`);

        const int32View = new Uint8Array(progressEvent.target.result as ArrayBuffer);
        let fileType: 'png' | 'apng' | 'gif' | 'jpg' | 'mp4' | 'mp3' | 'unknown';
        if (Utils.file.isJpgFile(int32View)) {
          fileType = 'jpg';
        } else if (Utils.file.isPngFile(int32View)) {
          fileType = 'png';
          if (Utils.file.isApngFile(int32View)) {
            fileType = 'apng';
          }
        } else if (Utils.file.isGifFile(int32View)) {
          fileType = 'gif';
        } else if (Utils.file.isMp4File(int32View)) {
          fileType = 'mp4';
        } else if (Utils.file.isMp3File(int32View)) {
          fileType = 'mp3';
        } else {
          fileType = 'unknown';
        }
        // DBGMSG(`$$$$INPUT isGifFile: ${Utils.file.isGifFile(int32View)}`);
        // DBGMSG(`$$$$INPUT isPngFile: ${Utils.file.isPngFile(int32View)}`);
        // DBGMSG(`$$$$INPUT isApngFile: ${Utils.file.isApngFile(int32View)}`);
        // DBGMSG(`$$$$INPUT isJpgFile: ${Utils.file.isJpgFile(int32View)}`);
        // DBGMSG(`$$$$INPUT isMp3File: ${Utils.file.isMp3File(int32View)}`);
        // DBGMSG(`$$$$INPUT isMp4File: ${Utils.file.isMp4File(int32View)}`);

        return resolve({ localUrl: createObjURL, fileObj: fileObj, type: fileType });
      }
      return reject(`audioRef.current && progressEvent.target`);
    };
    reader.readAsArrayBuffer(fileObj);
  });
};

const getImageResResolution = (url: string) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      DBGMSG(`img.naturalWidth: ${img.naturalWidth}`);
      DBGMSG(`img.naturalHeight: ${img.naturalHeight}`);
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    // img.onabort = () => {};
    // img.oncancel = () => {};
    // img.onclose = () => {};
    img.onerror = () => {
      reject();
    };
    img.src = url;
  });
};

const getVideoResResolution = (url: string) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const video = document.createElement('video');
    video.src = url;
    // video.onload = () => {
    video.onloadeddata = () => {
      DBGMSG(`video.videoWidth: ${video.videoWidth}`);
      DBGMSG(`video.videoHeight: ${video.videoHeight}`);
      resolve({ width: video.videoWidth, height: video.videoHeight });
    };
    // img.onabort = () => {};
    // img.oncancel = () => {};
    // img.onclose = () => {};
    video.onerror = () => {
      reject();
    };
    video.src = url;
  });
};

const cvtByteToMegaByte = (byte: number) => {
  return byte / 1024 / 1024;
};

const isJpgFile = (u8a: Uint8Array) => {
  if (u8a[0] === 0xff && u8a[1] === 0xd8 && u8a[2] === 0xff) return true;
  DBGMSG(`maginNumber-------------------------------------------------------------------`);
  DBGMSG(`maginNumber: ${u8a[0].toString(16)} ${String.fromCharCode(u8a[0])}`);
  DBGMSG(`maginNumber: ${u8a[1].toString(16)} ${String.fromCharCode(u8a[1])}`);
  DBGMSG(`maginNumber: ${u8a[2].toString(16)} ${String.fromCharCode(u8a[2])}`);
  DBGMSG(`maginNumber: ${u8a[3].toString(16)} ${String.fromCharCode(u8a[3])}`);
  DBGMSG(`maginNumber: ${u8a[4].toString(16)} ${String.fromCharCode(u8a[4])}`);
  DBGMSG(`maginNumber: ${u8a[5].toString(16)} ${String.fromCharCode(u8a[5])}`);
  DBGMSG(`maginNumber: ${u8a[6].toString(16)} ${String.fromCharCode(u8a[6])}`);
  DBGMSG(`maginNumber: ${u8a[7].toString(16)} ${String.fromCharCode(u8a[7])}`);
  DBGMSG(`maginNumber: ${u8a[8].toString(16)} ${String.fromCharCode(u8a[8])}`);
  DBGMSG(`maginNumber: ${u8a[9].toString(16)} ${String.fromCharCode(u8a[9])}`);
  DBGMSG(`maginNumber: ${u8a[10].toString(16)} ${String.fromCharCode(u8a[10])}`);
  DBGMSG(`maginNumber-------------------------------------------------------------------`);
  return false;
};

const isGifFile = (u8a: Uint8Array) => {
  if (
    (u8a[0] === 0x47 && u8a[1] === 0x49 && u8a[2] === 0x46 && u8a[3] === 0x38 && u8a[4] === 0x37 && u8a[5] === 0x61) ||
    (u8a[0] === 0x47 && u8a[1] === 0x49 && u8a[2] === 0x46 && u8a[3] === 0x38 && u8a[4] === 0x39 && u8a[5] === 0x61)
  )
    return true;
  return false;
};
const isPngFile = (u8a: Uint8Array) => {
  if (
    u8a[0] === 0x89 &&
    u8a[1] === 0x50 &&
    u8a[2] === 0x4e &&
    u8a[3] === 0x47 &&
    u8a[4] === 0x0d &&
    u8a[5] === 0x0a &&
    u8a[6] === 0x1a &&
    u8a[7] === 0x0a
  ) {
    return true;
  }

  return false;
};
const isApngFile = (u8a: Uint8Array) => {
  if (isPngFile(u8a)) {
    const apng = parseAPNG(u8a);
    if (apng instanceof Error) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

const isMp4File = (u8a: Uint8Array) => {
  if (
    (u8a[4] === 0x66 &&
      u8a[5] === 0x74 &&
      u8a[6] === 0x79 &&
      u8a[7] === 0x70 &&
      u8a[8] === 0x69 &&
      u8a[9] === 0x73 &&
      u8a[10] === 0x6f &&
      u8a[11] === 0x6d) ||
    (u8a[4] === 0x66 && u8a[5] === 0x74 && u8a[6] === 0x79 && u8a[7] === 0x70 && u8a[8] === 0x6d && u8a[9] === 0x70 && u8a[10] === 0x34)
  )
    return true;
  return false;
};
const isMp3File = (u8a: Uint8Array) => {
  if (
    (u8a[0] === 0xff && u8a[1] === 0xfb) ||
    (u8a[0] === 0xff && u8a[1] === 0xf3) ||
    (u8a[0] === 0xff && u8a[1] === 0xf2) ||
    (u8a[0] === 0x49 && u8a[1] === 0x44 && u8a[2] === 0x33)
  )
    return true;
  return false;
};

const isImageFile = (u8a: Uint8Array) => {
  if (isJpgFile(u8a) || isGifFile(u8a) || isPngFile(u8a) || isApngFile(u8a)) return true;
  return false;
};

// !! ====================================================================================
// !! crypto 관련
// !! ====================================================================================
const sha256 = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
};

// !! ====================================================================================
// !! service 관련
// !! ====================================================================================

// 필수 인지 체크
const isReqAttr = (attr: AttributeInfoWithValue_i) => {
  if (
    attr.name === Constants.NFT_REQ_ATTRNAME_NAME ||
    attr.name === Constants.NFT_REQ_ATTRNAME_IMAGE ||
    attr.name === Constants.NFT_REQ_ATTRNAME_AUDIO ||
    attr.name === Constants.NFT_REQ_ATTRNAME_VIDEO
  )
    return true;
  return false;
};
const isReqAttrName = (attrName: string) => {
  if (
    attrName === Constants.NFT_REQ_ATTRNAME_NAME ||
    attrName === Constants.NFT_REQ_ATTRNAME_IMAGE ||
    attrName === Constants.NFT_REQ_ATTRNAME_AUDIO ||
    attrName === Constants.NFT_REQ_ATTRNAME_VIDEO
  ) {
    return true;
  }
  return false;
};

// 추가속성 인지 체크
const isOptAttr = (attr: AttributeInfoWithValue_i) => {
  return !isReqAttr(attr);
};

// 미디어 타입인지 체크
const isMediaTypeAttr = (attr: AttributeInfoWithValue_i) => {
  if (
    //
    attr.type === CbtAttributeType_e.IMAGE ||
    attr.type === CbtAttributeType_e.VIDEO ||
    attr.type === CbtAttributeType_e.AUDIO
  )
    return true;
  return false;
};

// 필수 속성 이미지 정보를 get
const getReqImgAttrValue = (attrList: AttributeInfoWithValue_i[]) => {
  if (attrList.length === 0) {
    DBGMSGW('attrList.length === 0');
    return;
  }

  const reqImgAtt = attrList.find((v) => {
    if (v.name === Constants.NFT_REQ_ATTRNAME_IMAGE && v.type === CbtAttributeType_e.IMAGE) return true;
    return false;
  });

  if (!reqImgAtt) {
    DBGMSGW('reqImg is undefined');
    return;
  }

  if (typeof reqImgAtt.value !== 'string') {
    DBGMSGW(`reqImgAtt.value !== 'string'`);
    return;
  }

  return reqImgAtt.value;
};

// 필수 속성 비디오 정보를 get
const getReqVideoAttrValue = (attrList: AttributeInfoWithValue_i[]) => {
  if (attrList.length === 0) {
    DBGMSGW('attrList.length === 0');
    return;
  }
  const reqVideoAtt = attrList.find((v) => {
    if (v.name === Constants.NFT_REQ_ATTRNAME_VIDEO && v.type === CbtAttributeType_e.VIDEO) return true;
    return false;
  });

  if (!reqVideoAtt) {
    DBGMSGW('reqVideoAtt is undefined');
    return;
  }

  if (typeof reqVideoAtt.value !== 'string') {
    DBGMSGW(`reqVideoAtt.value !== 'string'`);
    return;
  }

  return reqVideoAtt.value;
};

// 필수 속성 오디오 정보를 get
const getReqAudioAttrValue = (attrList: AttributeInfoWithValue_i[]) => {
  if (attrList.length === 0) {
    DBGMSGW('attrList.length === 0');
    return;
  }
  const reqAudioAtt = attrList.find((v) => {
    if (v.name === Constants.NFT_REQ_ATTRNAME_AUDIO && v.type === CbtAttributeType_e.AUDIO) return true;
    return false;
  });

  if (!reqAudioAtt) {
    DBGMSGW('reqAudioAtt is undefined');
    return;
  }

  if (typeof reqAudioAtt.value !== 'string') {
    DBGMSGW(`reqAudioAtt.value !== 'string'`);
    return;
  }

  return reqAudioAtt.value;
};

// assetType과 attrList로
// DisplaySrc_t 리스트 생성
const brewDisplaySrcList = ({ assetType, attrList }: { assetType: CbtAssetType_e; attrList: AssetAttribute_i[] }) => {
  DBGMSG(`brewDisplaySrcList ${assetType}`);
  DBGMSG(attrList);

  let brewReqPlayerSrc: DisplaySrc_t;

  // from 필수 속성
  // STEP1 - 필수 속성만 추출 - 미디 타입만 추출
  const reqMediaOnly = attrList.filter(Utils.svc.isReqAttr).filter(Utils.svc.isMediaTypeAttr);
  DBGMSG(reqMediaOnly);

  // STEP2 - value 타입 체크
  for (let i = 0; i < reqMediaOnly.length; i++) {
    if (typeof reqMediaOnly[i].value !== 'string') {
      DBGMSGW(`typeof reqMediaOnly[${i}].value !== 'string'`);
      DBGMSGW(reqMediaOnly[i].name);
      DBGMSGW(reqMediaOnly[i].value);
      DBGMSGW(reqMediaOnly[i].type);
      return;
    }
  }

  // STEP3 - 이미지 정보 추출(이미지는 항상 존재)
  const reqImg = Utils.svc.getReqImgAttrValue(reqMediaOnly);
  if (!reqImg) {
    DBGMSGW('reqImg is undefined');
    return;
  }
  DBGMSG(`reqImg is ${reqImg}`);

  // STEP4 - 에셋타입에 따라 데이터 생성
  switch (assetType) {
    case CbtAssetType_e.IMAGE:
      if (reqMediaOnly.length !== 1) {
        DBGMSGW(`reqMediaOnly.length !== 1`);
        return;
      }

      brewReqPlayerSrc = {
        kind: 'ReqImage',
        img: reqImg,
      };

      break;
    case CbtAssetType_e.AUDIO:
      if (reqMediaOnly.length !== 2) {
        DBGMSGW(`reqMediaOnly.length !== 1`);
        return;
      }

      const reqAudio = Utils.svc.getReqAudioAttrValue(reqMediaOnly);
      if (!reqAudio) {
        DBGMSGW('reqAudio is undefined');
        return;
      }

      brewReqPlayerSrc = {
        kind: 'ReqAudio',
        img: reqImg,
        audio: reqAudio,
      };

      break;
    case CbtAssetType_e.VIDEO:
      if (reqMediaOnly.length !== 2) {
        DBGMSGW(`reqMediaOnly.length !== 1`);
        return;
      }

      const reqVideo = Utils.svc.getReqVideoAttrValue(reqMediaOnly);
      if (!reqVideo) {
        DBGMSGW('reqVideo is undefined');
        return;
      }

      brewReqPlayerSrc = {
        kind: 'ReqVideo',
        img: reqImg,
        video: reqVideo,
      };
      break;
  }

  DBGMSG(brewReqPlayerSrc);

  // from 추가 속성
  // STEP1 - 추가 속성만 추출 - 미디 타입만 추출
  const optMediaOnly = attrList.filter(Utils.svc.isOptAttr).filter(Utils.svc.isMediaTypeAttr);

  // STEP2 - value 타입 체크
  for (let i = 0; i < optMediaOnly.length; i++) {
    if (typeof optMediaOnly[i].value !== 'string') {
      DBGMSGW(`typeof optMediaOnly[${i}].value !== 'string'`);
      DBGMSGW(optMediaOnly[i].name);
      DBGMSGW(optMediaOnly[i].value);
      DBGMSGW(optMediaOnly[i].type);
      return;
    }
  }

  // STEP3 - 데이터 생성
  const brewOptPlayerSrcList = optMediaOnly.map<DisplaySrc_t>((att) => {
    const value = att.value as string;
    if (att.type === CbtAttributeType_e.IMAGE) {
      return {
        kind: 'OptImage',
        name: att.name,
        img: value,
      };
    } else if (att.type === CbtAttributeType_e.AUDIO) {
      return {
        kind: 'OptAudio',
        name: att.name,
        audio: value,
      };
    } else {
      // if (att.type === AttributeType_e.Video) {
      return {
        kind: 'OptVideo',
        name: att.name,
        video: value,
      };
    }
  });

  DBGMSG(brewOptPlayerSrcList);

  const finalPlarerSrcList = [brewReqPlayerSrc, ...brewOptPlayerSrcList];
  DBGMSG(finalPlarerSrcList);
  return finalPlarerSrcList;
};

// assetType과 attr 폼 List로 ( value가 없을 수 있음 )
// DisplaySrc_t 리스트 생성
const brewDisplaySrcFormList = ({ assetType, attrList }: { assetType: CbtAssetType_e; attrList: AttributeInfoWithValue_i[] }) => {
  DBGMSG(`brewPlayerSrcFormList ${assetType}`);
  DBGMSG(attrList);

  let brewReqPlayerSrc: DisplaySrcForm_t;

  // from 필수 속성
  // STEP1 - 필수 속성만 추출 - 미디어 타입만 추출
  const reqMediaOnly = attrList.filter(Utils.svc.isReqAttr).filter(Utils.svc.isMediaTypeAttr);

  // STEP2 - value 타입 체크
  // for (let i = 0; i < reqMediaOnly.length; i++) {
  //   if (typeof reqMediaOnly[i].value !== 'string') {
  //     DBGMSGW(`typeof reqMediaOnly[${i}].value !== 'string'`);
  //     DBGMSGW(reqMediaOnly[i].name);
  //     DBGMSGW(reqMediaOnly[i].value);
  //     DBGMSGW(reqMediaOnly[i].type);
  //     return;
  //   }
  // }

  // STEP3 - 이미지 정보 추출(이미지는 항상 존재)
  const reqImg = Utils.svc.getReqImgAttrValue(reqMediaOnly);
  // if (!reqImg) {
  //   DBGMSGW('reqImg is undefined');
  //   return;
  // }

  // STEP4 - 에셋타입에 따라 데이터 생성
  switch (assetType) {
    case CbtAssetType_e.IMAGE:
      if (reqMediaOnly.length !== 1) {
        DBGMSGW(`reqMediaOnly.length !== 1`);
        return;
      }

      brewReqPlayerSrc = {
        kind: 'ReqImage',
        img: reqImg,
      };

      break;
    case CbtAssetType_e.AUDIO:
      if (reqMediaOnly.length !== 2) {
        DBGMSGW(`reqMediaOnly.length !== 1`);
        return;
      }

      const reqAudio = Utils.svc.getReqAudioAttrValue(reqMediaOnly);
      // if (!reqAudio) {
      //   DBGMSGW('reqAudio is undefined');
      //   return;
      // }

      brewReqPlayerSrc = {
        kind: 'ReqAudio',
        img: reqImg,
        audio: reqAudio,
      };

      break;
    case CbtAssetType_e.VIDEO:
      if (reqMediaOnly.length !== 2) {
        DBGMSGW(`reqMediaOnly.length !== 1`);
        return;
      }

      const reqVideo = Utils.svc.getReqVideoAttrValue(reqMediaOnly);
      // if (!reqVideo) {
      //   DBGMSGW('reqVideo is undefined');
      //   return;
      // }

      brewReqPlayerSrc = {
        kind: 'ReqVideo',
        img: reqImg,
        video: reqVideo,
      };
      break;
  }

  DBGMSG(brewReqPlayerSrc);

  // from 추가 속성
  // STEP1 - 추가 속성만 추출 - 미디 타입만 추출
  const optMediaOnly = attrList.filter(Utils.svc.isOptAttr).filter(Utils.svc.isMediaTypeAttr);

  // STEP2 - value 타입 체크
  // for (let i = 0; i < optMediaOnly.length; i++) {
  //   if (typeof optMediaOnly[i].value !== 'string') {
  //     DBGMSGW(`typeof optMediaOnly[${i}].value !== 'string'`);
  //     DBGMSGW(optMediaOnly[i].name);
  //     DBGMSGW(optMediaOnly[i].value);
  //     DBGMSGW(optMediaOnly[i].type);
  //     return;
  //   }
  // }

  // STEP3 - 데이터 생성
  const brewOptPlayerSrcList = optMediaOnly.map<DisplaySrc_t>((att) => {
    const value = att.value as string;
    if (att.type === CbtAttributeType_e.IMAGE) {
      return {
        kind: 'OptImage',
        name: att.name,
        img: value,
      };
    } else if (att.type === CbtAttributeType_e.AUDIO) {
      return {
        kind: 'OptAudio',
        name: att.name,
        audio: value,
      };
    } else {
      // if (att.type === AttributeType_e.Video) {
      return {
        kind: 'OptVideo',
        name: att.name,
        video: value,
      };
    }
  });

  DBGMSG(brewOptPlayerSrcList);

  const finalPlarerSrcList = [brewReqPlayerSrc, ...brewOptPlayerSrcList];
  DBGMSG(finalPlarerSrcList);
  return finalPlarerSrcList;
};

const filterDisplaySrcList = (displaySrcFormList: DisplaySrcForm_t[]): DisplaySrc_t[] => {
  const displaySrcList = displaySrcFormList.filter((displaySrcForm) => {
    switch (displaySrcForm.kind) {
      case 'ReqImage':
        return displaySrcForm.img !== undefined;
      case 'ReqAudio':
        // return displaySrcForm.img !== undefined && displaySrcForm.audio !== undefined;
        return displaySrcForm.audio !== undefined;
      case 'ReqVideo':
        // return displaySrcForm.img !== undefined && displaySrcForm.video !== undefined;
        return displaySrcForm.video !== undefined;
      case 'OptImage':
        return displaySrcForm.img !== undefined;
      case 'OptAudio':
        return displaySrcForm.audio !== undefined;
      case 'OptVideo':
        return displaySrcForm.video !== undefined;
    }
  });
  return displaySrcList as DisplaySrc_t[];
};

const isReservedAttName = (name: string) => {
  switch (name) {
    case Constants.NFT_REQ_ATTRNAME_NAME:
    case Constants.NFT_REQ_ATTRNAME_IMAGE:
    case Constants.NFT_REQ_ATTRNAME_AUDIO:
    case Constants.NFT_REQ_ATTRNAME_VIDEO:
      return true;
  }
  return false;
};

const getAssetNameFromAttrList = (attrList: CbtAttributeValue_i[]) => {
  const found = attrList.find((att) => att.name === Constants.NFT_REQ_ATTRNAME_NAME);
  if (found) {
    return found.value;
  } else return;
};

const getAssetMainImgFromAttrList = (attrList: CbtAttributeValue_i[]) => {
  const found = attrList.find((att) => att.name === Constants.NFT_REQ_ATTRNAME_IMAGE);
  if (found) {
    return found.value;
  } else return;
};

type fnType = ({
  assetType,
  img,
}: {
  assetType: CbtAssetType_e;
  img: string;
}) => Required<ReqImageSrc_i> | Omit<Required<ReqAudioSrc_i>, 'audio'> | Omit<Required<ReqVideoSrc_i>, 'video'>;

const brewAssetDisplayThumbReqattr: fnType = ({ assetType, img }: { assetType: CbtAssetType_e; img: string }) => {
  let assetDisplaySrc: Required<ReqImageSrc_i> | Omit<Required<ReqAudioSrc_i>, 'audio'> | Omit<Required<ReqVideoSrc_i>, 'video'>;

  switch (assetType) {
    case CbtAssetType_e.IMAGE:
      assetDisplaySrc = { kind: 'ReqImage', img };
      break;
    case CbtAssetType_e.AUDIO:
      assetDisplaySrc = {
        kind: 'ReqAudio',
        img,
      };
      break;
    case CbtAssetType_e.VIDEO:
      assetDisplaySrc = {
        kind: 'ReqVideo',
        img,
      };
      break;
  }

  return assetDisplaySrc;
};

const getAssetType = ({ nft_img, nft_vid, nft_aud }: { nft_img: string; nft_vid?: string | null; nft_aud?: string | null }) => {
  let temp: CbtAssetType_e;
  if (nft_vid) temp = CbtAssetType_e.VIDEO;
  else if (nft_aud) temp = CbtAssetType_e.AUDIO;
  else temp = CbtAssetType_e.IMAGE;
  return temp;
};

const getAssetTypeFromAttrList = (attrList: CbtAttributeValue_i[]) => {
  const imgFound = attrList.find((att) => att.name === Constants.NFT_REQ_ATTRNAME_IMAGE);
  const vidFound = attrList.find((att) => att.name === Constants.NFT_REQ_ATTRNAME_VIDEO);
  const audFound = attrList.find((att) => att.name === Constants.NFT_REQ_ATTRNAME_AUDIO);
  if (vidFound) return CbtAssetType_e.VIDEO;
  else if (audFound) return CbtAssetType_e.AUDIO;
  else return CbtAssetType_e.IMAGE;
};

const convertIfReqAttrName = (attrName: string) => {
  if (attrName === Constants.NFT_REQ_ATTRNAME_NAME) {
    return R.strings.NFT_REQ_ATTRNAME_NAME;
  } else if (attrName === Constants.NFT_REQ_ATTRNAME_VIDEO) {
    return R.strings.NFT_REQ_ATTRNAME_VIDEO;
  } else if (attrName === Constants.NFT_REQ_ATTRNAME_AUDIO) {
    return R.strings.NFT_REQ_ATTRNAME_AUDIO;
  } else if (attrName === Constants.NFT_REQ_ATTRNAME_IMAGE) {
    return R.strings.NFT_REQ_ATTRNAME_IMAGE;
  } else {
    return attrName;
  }
};

const isSuccessStompMsgCode = (code: CbtStompMsgCode_e) => {
  switch (code) {
    case CbtStompMsgCode_e.GENERAL:
      return false;
    case CbtStompMsgCode_e.ASSET_COLLECTION_CREATE_SUCCESS:
    case CbtStompMsgCode_e.ASSET_COLLECTION_EDIT_SUCCESS:
    case CbtStompMsgCode_e.ASSET_SCHEMA_CREATE_SUCCESS:
    case CbtStompMsgCode_e.ASSET_SCHEMA_EDIT_SUCCESS:
    case CbtStompMsgCode_e.ASSET_CREATE_SUCCESS:
    case CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS:
    case CbtStompMsgCode_e.BILL_CHARGE_POINT_SUCCESS:
      return true;
    case CbtStompMsgCode_e.ASSET_COLLECTION_CREATE_FAIL:
    case CbtStompMsgCode_e.ASSET_COLLECTION_EDIT_FAIL:
    case CbtStompMsgCode_e.ASSET_SCHEMA_CREATE_FAIL:
    case CbtStompMsgCode_e.ASSET_SCHEMA_EDIT_FAIL:
    case CbtStompMsgCode_e.ASSET_CREATE_FAIL:
    case CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_FAIL:
    case CbtStompMsgCode_e.BILL_CHARGE_POINT_FAIL:
      return false;
  }
};

export interface CurrencyDpInfo {
  currency: CbtCurrency_e;
  icSrc: string;
  unit: string;
}

const KlayCurrencyDpInfo: CurrencyDpInfo = {
  currency: CbtCurrency_e.KLAY,
  icSrc: R.images.common_ic_klay3x,
  unit: R.strings.CURRENCY_KLAY,
};

const CPointCurrencyDpInfo: CurrencyDpInfo = {
  currency: CbtCurrency_e.cPOINT,
  icSrc: R.images.common_ic_point3x,
  unit: R.strings.CURRENCY_CUSD,
};

const CUsdCurrencyDpInfo: CurrencyDpInfo = {
  currency: CbtCurrency_e.cUSD,
  icSrc: '',
  unit: R.strings.CURRENCY_CUSD,
};

const UnknownCurrencyDpInfo: CurrencyDpInfo = {
  currency: CbtCurrency_e.UNKNOWN,
  icSrc: '',
  unit: '',
};

const getCurrencyDpInfo = (curr: CbtCurrency_e) => {
  switch (curr) {
    case CbtCurrency_e.KLAY:
      return KlayCurrencyDpInfo;
    case CbtCurrency_e.cPOINT:
      return CPointCurrencyDpInfo;
    case CbtCurrency_e.cUSD:
      return CUsdCurrencyDpInfo;
    default:
      return UnknownCurrencyDpInfo;
  }
};

const getCurrencyUnit = (curr: CbtCurrency_e) => {
  switch (curr) {
    case CbtCurrency_e.KLAY:
      return 'KLAY';
    case CbtCurrency_e.cPOINT:
      return 'POINT';
    case CbtCurrency_e.cUSD:
    case CbtCurrency_e.USD:
      return 'USD';
    case CbtCurrency_e.CBLT:
      return 'CBLT';
    default:
      return 'UNKNOWN';
  }
};

// !! ====================================================================================
// !! BigNumber 관련
// !! ====================================================================================
// 최소단위로 변경
// ex)
// 6 decimal
// 1 -> 1_000_000
// 0.001 -> 1_000
const reflectInCurrInfo = (args: { priceTxt: string; curr_info: CbtCurrencyInfo_i }) => {
  BigNumber.config({ EXPONENTIAL_AT: 100 });
  const bnPrice = new BigNumber(args.priceTxt);
  const bn10 = new BigNumber(10);
  const bnDecimal = bn10.pow(args.curr_info.decimal);
  const nbFinalPrice = bnPrice.multipliedBy(bnDecimal);
  DBGMSG('bignumber');
  DBGMSG(`Price: ${bnPrice.toFormat()} ${bnPrice.toString()}`);
  DBGMSG(`Decimal: ${bnDecimal.toFormat()} ${bnDecimal.toString()}`);
  DBGMSG(`FinalPrice: ${nbFinalPrice.toFormat()} ${nbFinalPrice.toString()}`);
  return nbFinalPrice.toString();
};

// decimal을 적용된 소수점 단위로 변경
// ex)
// 6 decimal
// 1_000_000 -> 1
// 1_000 -> 0.001
const getPriceForDP = ({
  prcInfo,
  decimal,
  noComma = false,
}: {
  prcInfo: { price: string; curr_info: CbtCurrencyInfo_i } | null;
  decimal?: number;
  noComma?: boolean;
}) => {
  if (prcInfo === null) return '0';
  BigNumber.config({ EXPONENTIAL_AT: 100, ROUNDING_MODE: BigNumber.ROUND_FLOOR });
  // BigNumber.config({ EXPONENTIAL_AT: 100 });
  const bnPrice = new BigNumber(prcInfo.price);
  const bn10 = new BigNumber(10);
  const bnDecimal = bn10.pow(prcInfo.curr_info.decimal);
  const nbFinalPrice = bnPrice.dividedBy(bnDecimal);

  // for debugging
  // DBGMSG('bignumber');
  // DBGMSG(`Price: ${bnPrice.toFormat()} ${bnPrice.toString()}`);
  // DBGMSG(`Decimal: ${bnDecimal.toFormat()} ${bnDecimal.toString()}`);
  // DBGMSG(`FinalPrice: ${nbFinalPrice.toFormat(decimal)} ${nbFinalPrice.toString()}`);
  // return nbFinalPrice.toFormat(decimal);
  if (noComma) {
    return nbFinalPrice.toString();
  } else {
    if (!decimal) return nbFinalPrice.toFormat();
    if (decimal < 0) return nbFinalPrice.toFormat();

    const prcTxt = nbFinalPrice.toFormat(decimal);
    let result = prcTxt;
    for (let i = 0; i < decimal; i++) {
      if (result.endsWith('0')) {
        result = result.slice(0, result.length - 1);
      }
      if (result.endsWith('.')) {
        result = result.slice(0, result.length - 1);
        break;
      }
    }
    return result;

    // if (prcTxt.endsWith('.00000')) {
    //   return prcTxt.slice(0, prcTxt.length - 6);
    // }
    // if (prcTxt.endsWith('0000')) {
    //   return prcTxt.slice(0, prcTxt.length - 4);
    // }
    // if (prcTxt.endsWith('.000')) {
    //   return prcTxt.slice(0, prcTxt.length - 4);
    // }
    // if (prcTxt.endsWith('00')) {
    //   return prcTxt.slice(0, prcTxt.length - 2);
    // }
    // if (prcTxt.endsWith('0')) {
    //   return prcTxt.slice(0, prcTxt.length - 1);
    // }
    return nbFinalPrice.toFormat(decimal);
  }
};

// !! ====================================================================================
// !! ipfs 관련
// !! ====================================================================================
const getIpfsHash = (url: string) => {
  const lastIdx = url.lastIndexOf('/ipfs/');
  if (lastIdx === -1) return '';

  const ipfsHash = url.slice(lastIdx + 6);
  DBGMSG(ipfsHash);
  return ipfsHash;
};

// !! ====================================================================================
// !! locale 관련
// !! ====================================================================================
const getGeoInfo = async () => {
  return new Promise<{ countryName: string; countryCode: string }>((resolve, reject) => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        let data = response.data;
        return resolve({
          countryName: data.country_name,
          countryCode: data.country_code,
          // countryCode: data.country_calling_code,
        });
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const Utils = {
  date: {
    now,
    getDate,
    brewFomatString,
    // brewCommonDate,
    // brewCommonDateWithoutYear,
    // brewBirth,
    dateAddDay,
    dateSubDay,
    dateAddMonth,
    dateSubMonth,
    diff: getInterval,
    diffIndDays,
    diffInSeconds,
    diffInMinutes,
    diffInMillSeconds,
    getRemainTimeFromCurrent,
    isToday: _isToday,
    isYesterday: _isYesterday,
    isSameDay: _isSameDay,
    isSameMonth: _isSameMonth,
    brewYYYYMMDD,
    getDateFromYYYYMMDD,
    isEqualMinute,
  },
  string: {
    zeroPadding,
    shortKlatynAddress,
    shortKlatynTxHash,
    brewKlaytnUserAccountUrl,
    shortString,
    parsePlaceholder,
    replacePlaceholder,
  },
  sleep: {
    mssleep,
  },
  array: {
    diff: _diff,
  },
  deepCopy,
  url: {
    // getPathname,
  },
  path: {
    // makeUploadPath,
  },
  device: {
    reactDeviceDetect: _reactDeviceDetect,
    isDesktop: _reactDeviceDetect.isDesktop, // is 데스크탑
    isTablet: _reactDeviceDetect.isTablet, // is 테블릿
    isMobile: _reactDeviceDetect.isMobileOnly, // is 모바일
    isMobileSafari: _reactDeviceDetect.isMobileSafari, // is 모바일 사파리
    isMobileChrome: _reactDeviceDetect.isMobileOnly && _reactDeviceDetect.browserName === 'Chrome', // is 모바일 크롬
    isIOS: _reactDeviceDetect.isMobileOnly && _reactDeviceDetect.isIOS, // is iOS
    isAndroid: _reactDeviceDetect.isMobileOnly && _reactDeviceDetect.isAndroid, // is Android
    viewportSize,
  },
  storage: {
    saveLocalStorage,
    loadLocalStorage,
    clearLocalStorage,
    saveSessionStorage,
    loadSessionStorage,
    clearSessionStorage,
  },
  file: {
    loadInputFile,
    getImageResResolution,
    getVideoResResolution,
    cvtByteToMB: cvtByteToMegaByte,
    isJpgFile,
    isGifFile,
    isPngFile,
    isApngFile,
    isImageFile,
    isMp3File,
    isMp4File,
  },
  crypto: {
    sha256,
  },
  svc: {
    isReqAttr,
    isReqAttrName,
    isOptAttr,
    isMediaTypeAttr,
    getReqImgAttrValue,
    getReqVideoAttrValue,
    getReqAudioAttrValue,
    brewDisplaySrcList,
    brewDisplaySrcFormList,
    filterDisplaySrcList,
    isReservedAttName,
    getAssetNameFromAttrList,
    getAssetMainImgFromAttrList,
    brewAssetDisplayThumbReqattr,
    getAssetType,
    getAssetTypeFromAttrList,
    convertIfReqAttrName,
    isSuccessStompMsgCode,
    getCurrencyDpInfo,
    getCurrencyUnit,
  },
  currency: {
    reflectInCurrInfo,
    getPriceForDP,
  },
  ipfs: {
    getIpfsHash,
  },
  locale: {
    getGeoInfo,
  },
  randomUUID,
};

// export class Epoch {
//   private static _instance?: Epoch = undefined;
//   private offset: number = 0;

//   public static readonly Infinity: number = 32503651200;

//   public static get instance(): Epoch {
//     if (this._instance === undefined) {
//       this._instance = new Epoch();
//     }

//     return this._instance;
//   }

//   private getNow(): number {
//     return new Date().getTime() + this.offset;
//   }

//   static readonly DayWeekString = [R.strings.COMMON_SUNDAY, R.strings.COMMON_MONDAY, R.strings.COMMON_TUESDAY, R.strings.COMMON_WEDNESDAY, R.strings.COMMON_THURSDAY, R.strings.COMMON_FRIDAY, R.strings.COMMON_SATURDAY];
//   private static dayOfWeek(d: number): string {
//     return Epoch.DayWeekString[d];
//   }

//   public setOffset(offset: number): void {
//     this.offset = offset;
//   }

//   public static get now(): number {
//     return this.instance.getNow();
//   }

//   public static getOffset(timestamp: number) {
//     const gap = Epoch.now - timestamp;
//     return gap;
//   }

//   public static getDiffMinutesFromNow(timestamp: number) {
//     return Epoch.getOffset(timestamp) / 1000 / 60;
//   }

//   public static getDiffDaysFromNow(timestamp: number) {
//     return Epoch.getDiffMinutesFromNow(timestamp) / 60 / 24;
//   }

//   public static getHMM(timestamp: number, separatorHours: string = ':', separatorMinutes: string = '') {
//     const { hours, minutes } = this.convertTime(timestamp);
//     const ret = hours + separatorHours + zeroPadding(minutes, 2) + separatorMinutes;

//     return ret;
//   }

//   public static getDayOfWeek(timestamp: number) {
//     const { dayOfWeek } = this.convertTime(timestamp);

//     return dayOfWeek;
//   }

//   public static getDateForDM(timestamp: number) {
//     const today = Epoch.convertTime(Epoch.now);

//     const todayZeroTime = new Date(today.year, today.month - 1, today.day, 0, 0).getTime();
//     // console.debug(todayZeroTime);

//     const offset = timestamp - todayZeroTime;

//     if (0 <= offset && offset < 86400000) {
//       // 今日
//       const { hours, minutes } = Epoch.convertTime(timestamp);

//       return hours + ':' + zeroPadding(minutes, 2);
//     } else if (-86400000 <= offset && offset < 0) {
//       // 昨日
//       return R.strings.COMMON_YESTERDAY;
//     } else {
//       const { month, day, dayOfWeek } = Epoch.convertTime(timestamp);
//       return `${month}/${day}(${dayOfWeek})`;
//     }
//   }

//   public static getDiffTimeForDM(timestamp: number) {
//     const gap = this.getOffset(timestamp) / 1000;

//     if (gap < 60) {
//       // 1分以内
//       return R.strings.COMMON_JUST_NOW;
//     } else if (gap < 3600) {
//       // 60分以内
//       const minute = Math.floor(gap / 60);
//       return minute + R.strings.COMMON_BEFORE_MINUTE;
//     } else if (gap < 86400) {
//       // 24時間以内
//       const hour = Math.floor(gap / 3600);
//       return hour + R.strings.COMMON_BEFORE_HOUR;
//     } else if (gap < 31536000) {
//       // １年以内
//       const { month, day } = this.convertTime(timestamp);
//       return month + '/' + day;
//     } else {
//       const { year, month, day } = this.convertTime(timestamp);
//       return year + '/' + month + '/' + day;
//     }
//   }

//   public static makeTimeElapsedString(starttime: number) {
//     const nowTime = new Date();
//     const elapsedTimeS = (nowTime.getTime() - starttime) / 1000;

//     const hh = Math.floor(elapsedTimeS / 3600);
//     const leftS = Math.floor(elapsedTimeS % 3600);
//     const mm = Math.floor(leftS / 60);
//     const ss = Math.floor(leftS % 60);

//     return hh + ':' + ('00' + mm).slice(-2) + ':' + ('00' + ss).slice(-2);
//   }

//   public static convertTime(
//     timestamp: number
//   ): {
//     year: number;
//     month: number;
//     day: number;
//     hours: number;
//     minutes: number;
//     seconds: number;
//     dayOfWeek: string;
//   } {
//     const date = new Date(timestamp);

//     const year = date.getFullYear();
//     const month = date.getMonth() + 1;
//     const day = date.getDate();
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     const seconds = date.getSeconds();
//     const dayOfWeek = Epoch.dayOfWeek(date.getDay());

//     return {
//       year: year,
//       month: month,
//       day: day,
//       hours: hours,
//       minutes: minutes,
//       seconds: seconds,
//       dayOfWeek: dayOfWeek,
//     };
//   }

//   public static availableHours(timestamp: number, hours: number) {
//     return Epoch.availableMinutes(timestamp, hours * 60);
//   }

//   public static availableMinutes(timestamp: number, minutes: number) {
//     return Epoch.getOffset(timestamp) <= minutes * 60 * 1000;
//   }
// }

// const MAX_CURRENCY_VALUE: number = 9999999;
// export function currencyFormat(num: number) {
//   const value = num >= MAX_CURRENCY_VALUE ? MAX_CURRENCY_VALUE : num;
//   return value ? value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0';
// }

// export function formatText(template: string, replacement: any) {
//   if (typeof replacement !== 'object') {
//     // 可変長引数時はreplacementを詰め替え
//     replacement = Array.prototype.slice.call(arguments, 1);
//   }
//   return template.replace(/\{(.+?)\}/g, (m, c) => {
//     return replacement[c] != null ? replacement[c] : m;
//   });
// }

// export function makeYYYYMMDD(date: Date, separatorYear: string = '/', separatorMonth: string = '/', separatorDay: string = '') {
//   const year = date.getFullYear();
//   const month = date.getMonth() + 1;
//   const day = date.getDate();

//   return zeroPadding(year, 4) + separatorYear + zeroPadding(month, 2) + separatorMonth + zeroPadding(day, 2) + separatorDay;
// }

// type Size = {
//   width: number;
//   height: number;
// };

// type Point = {
//   x: number;
//   y: number;
// };

// type Rect = {
//   size: Size;
//   origin: Point;
// };

// export function getDaysInMonth(year: number, month: number): number {
//   return new Date(year, month, 0).getDate();
// }
