/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/modules';
import marketSchmSlice, { MarketSchmState_t } from 'src/redux/modules/marketSchmModule';

export function useMarketSchmState() {
  const marketSchmState = useSelector((state: RootState) => state.marketSchmState);
  const dispatch = useDispatch();

  const setState = useCallback(
    (args: MarketSchmState_t) => {
      dispatch(marketSchmSlice.actions.setState(args));
    },
    [dispatch]
  );
  const changeState = useCallback(
    (args: Partial<MarketSchmState_t>) => {
      dispatch(marketSchmSlice.actions.changeState({ ...args }));
    },
    [dispatch, marketSchmState]
  );
  const clearState = useCallback(() => {
    dispatch(marketSchmSlice.actions.clear());
  }, [dispatch]);

  return {
    act: {
      setState,
      changeState,
      clearState,
    },
    state: marketSchmState,
  };
}
