/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { DBGMSG } from 'src/util/utils';
import { CSSProperties } from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  userThumb: string | null;
  userId: string;
  size?: number;
  fontSize?: number;
  onClick?: () => void;
  containerStyle?: CSSProperties;
  showEditBtn?: boolean;
  onEditBtnClick?: () => void;
};

export function UserThumb({
  //
  userThumb,
  userId,
  size = 100,
  fontSize = 40,
  onClick,
  containerStyle,
  showEditBtn = false,
  onEditBtnClick,
}: Props) {
  const renderAlphabetThumb = () => {
    let backgroundColor = R.colors.rn.blueviolet;
    let fontColor = 'white';
    const alphaId = `${userId[0] ?? '_'}${userId[1] ?? '_'}`;

    // A~Z 65~90
    // a~z 97~122
    let sum = 0;
    for (let i = 0; i < userId.length; i++) {
      sum += userId.charCodeAt(i);
    }
    let hash = sum % 10;

    switch (hash) {
      case 1:
        backgroundColor = '#4735ff';
        break;
      case 2:
        backgroundColor = '#719cf8';
        break;
      case 3:
        backgroundColor = '#81cdf8';
        break;
      case 4:
        backgroundColor = '#63d4d6';
        break;
      case 5:
        backgroundColor = '#7e2eff';
        break;
      case 6:
        backgroundColor = '#ae3df0';
        break;
      case 7:
        backgroundColor = '#fa9cd8';
        break;
      case 8:
        backgroundColor = '#fa8b9e';
        break;
      case 9:
        backgroundColor = '#ffc96b';
        break;
      case 0:
        backgroundColor = '#c8e554';
        break;
    }
    return (
      <FlexRowDiv
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor: backgroundColor,
          justifyContent: 'center',
          alignItems: 'center',
          // ...devOutline,
        }}
      >
        <TextCon text={alphaId} size={fontSize} color={fontColor} isBold containerStyle={{ marginBottom: 2 }} useSelectNone />
      </FlexRowDiv>
    );
  };
  return (
    <FlexColumnDiv
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'inherit',
        ...containerStyle,
        ...devOutline,
      }}
      onClick={() => {
        DBGMSG(`onClick ${userId}`);
        onClick && onClick();
      }}
    >
      {userThumb !== null ? <Img src={[userThumb, size, size]} /> : renderAlphabetThumb()}
      {showEditBtn && (
        <ImgBtn
          src={[R.images.mypage_btn_edit_profile3x, 28, 28]}
          containerStyle={{ position: 'absolute', right: 0, bottom: 0 }}
          onClick={() => {
            onEditBtnClick && onEditBtnClick();
          }}
        />
      )}
    </FlexColumnDiv>
  );
}
