/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { useSpring } from 'react-spring';
import { useTitle } from 'react-use';
import { AppConf, isAlpha, isProd } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useExplorerState } from 'src/hooks/useExplorerState';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMarketPlaceState } from 'src/hooks/useMarketPlaceState';
import { useQsLang } from 'src/hooks/useQsLang';
import { useRpc } from 'src/hooks/useRpc';
import { ClientType_e } from 'src/hooks/useScreenWidthHelper';
import { MarketAssetCard_i } from 'src/model/model';
import R from 'src/res/R';
import AnimatedText from 'src/ui/base_component/AnimatedText';
import { absoluteFillProps, devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import TextConMulti from 'src/ui/base_component/TextConMulti';
import { AFlexColumnDiv } from 'src/ui/common_component/AFlexColumnDiv';
import { CollectionCardForList } from 'src/ui/common_component/CollectionCardForList';
import { CollectionCardForSlider } from 'src/ui/common_component/CollectionCardForSlider';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { MarketAssetCardForList } from 'src/ui/common_component/MarketAssetCardForList';
import { MarketAssetCardForSlider } from 'src/ui/common_component/MarketAssetCardForSlider';
import { CommonColumnBox, CommonRowBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const MAIN_BANNER_WIDTH = 582;
const BANNER_WIDTH = 276;
const BANNER_HEIGHT = 250;
const STATUS_ICON_SIZE = 60;

const bannerShadowProps: CSSProperties = {
  borderRadius: 15,
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.05)',
};

let animationOnceDone = false;

type Props = {};

export function ExplorerScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sNewAssetSlideIdx, setNewAssetSlideIdx] = useState<number>(0);
  const [sRecAssetSlideIdx, setRecAssetSlideIdx] = useState<number>(0);
  const [sCollectionSlideIdx, setCollectionSlideIdx] = useState<number>(0);
  const [sSliderIsSliding, setSliderIsSliding] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const newAssetSliderRef = useRef<Slider | null>(null);
  const recAssetSliderRef = useRef<Slider | null>(null);
  const collectionSliderRef = useRef<Slider | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`Explorer - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hExplorerState = useExplorerState();
  const hMarketPlaceState = useMarketPlaceState();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  useQsLang();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    console.debug('useEffect() init');

    if (hExplorerState.state.needUpdate) fetchData();

    return () => {
      console.debug('useEffect() release');
    };
  }, [hExplorerState.state.needUpdate]);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async () => {
    try {
      const res = await hRpc.cobaltRpc.rpcHomeInfo({});
      hExplorerState.act.changeExplorerState({
        needUpdate: false,
        explorerInfo: res,
      });
    } catch (err) {
      hRpc.hanleCommonError({ err });
    }
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  const { newAssetCnt } = useSpring({
    // reset: true,
    from: { newAssetCnt: 0 },
    newAssetCnt: hExplorerState.state.explorerInfo?.stat.total_asset_count,
    config: { duration: animationOnceDone ? 0 : undefined },
    onRest: () => {
      animationOnceDone = true;
    },
  });
  const { listingCnt } = useSpring({
    // reset: true,
    from: { listingCnt: 0 },
    listingCnt: hExplorerState.state.explorerInfo?.stat.sale_list_count,
    config: { duration: animationOnceDone ? 0 : undefined },
  });
  const { saleCnt } = useSpring({
    // reset: true,
    from: { saleCnt: 0 },
    saleCnt: hExplorerState.state.explorerInfo?.stat.sold_count,
    config: { duration: animationOnceDone ? 0 : undefined },
  });
  const { dollarAmount } = useSpring({
    // reset: true,
    from: { dollarAmount: 0 },
    dollarAmount: hExplorerState.state.explorerInfo
      ? parseInt(Utils.currency.getPriceForDP({ prcInfo: hExplorerState.state.explorerInfo?.stat.sum_sales, noComma: true }))
      : undefined,
    config: { duration: animationOnceDone ? 0 : undefined },
  });
  if (!hExplorerState.state.explorerInfo) {
    return null;
  }

  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    console.debug('render');
    return (
      <FlexColumnDiv
        style={{
          width: ScreenInfo.contentsWidth,
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingTop: 40,
          paddingBottom: 120,
          // height: '100vh',
        }}
      >
        {/* 배너 */}
        <FlexRowDiv
          style={{
            width: '100%',
            height: BANNER_HEIGHT,
            justifyContent: 'space-between',
            ...devOutline,
          }}
        >
          {/* 에셋 발행해보세요! */}
          <AFlexColumnDiv
            isAnim
            containerStyle={{
              width: BANNER_WIDTH,
              height: BANNER_HEIGHT,
              marginTop: 0,
              ...bannerShadowProps,
              ...devOutline,
            }}
            onClick={() => {
              DBGMSG(hR.strings.EXP_MAIN_BANNER_BTN);
              hHistory.push(Nav.AssetCreatorScreen);
            }}
          >
            <Img src={[R.images.explore_iv_banner_13x, BANNER_WIDTH, BANNER_HEIGHT]} containerStyle={{ ...absoluteFillProps }} />
            <FlexColumnDiv style={{ marginLeft: 24, marginTop: 24 }}>
              <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_MAIN_BANNER_BTN} />
              {/* <FlexRowDiv style={{ alignItems: 'center' }}>
                <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_BANNER_EVENT_CBLT} />
              </FlexRowDiv> */}
            </FlexColumnDiv>
          </AFlexColumnDiv>

          {/* CARROT 배너 */}
          <AFlexColumnDiv
            isAnim
            containerStyle={{
              width: BANNER_WIDTH,
              height: BANNER_HEIGHT,
              marginTop: 0,
              ...bannerShadowProps,
              ...devOutline,
            }}
            onClick={() => {
              if (isAlpha()) hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 90 }));
              else if (isProd()) hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 1005 }));
            }}
          >
            <Img src={[R.images.explore_iv_banner_23x, BANNER_WIDTH, BANNER_HEIGHT]} containerStyle={{ ...absoluteFillProps }} />
            <FlexColumnDiv style={{ marginLeft: 24, marginTop: 24 }}>
              <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_BANNER_COLLECTION_TITLE} />
              <FlexRowDiv style={{ alignItems: 'center' }}>
                <Img src={[R.images.common_ic_verify3x, 24, 24]} />
                <WIDTH size={3} />
                <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_BANNER_CARROT_TITLE} />
              </FlexRowDiv>
            </FlexColumnDiv>
          </AFlexColumnDiv>

          {/* 포케코로 배너 */}
          <AFlexColumnDiv
            isAnim
            containerStyle={{
              width: BANNER_WIDTH,
              height: BANNER_HEIGHT,
              marginTop: 0,
              ...bannerShadowProps,
              ...devOutline,
            }}
            onClick={() => {
              if (isAlpha()) {
                // hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 90 }));
              } else if (isProd()) {
                hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 1007 }));
              }
            }}
          >
            <Img src={[R.images.explore_iv_banner_33x, BANNER_WIDTH, BANNER_HEIGHT]} containerStyle={{ ...absoluteFillProps }} />
            <FlexColumnDiv style={{ marginLeft: 24, marginTop: 24 }}>
              <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_BANNER_COLLECTION_TITLE} />
              <FlexRowDiv style={{ alignItems: 'center' }}>
                <Img src={[R.images.common_ic_verify3x, 24, 24]} />
                <WIDTH size={3} />
                <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_BANNER_POKECOLO_TITLE} />
              </FlexRowDiv>
            </FlexColumnDiv>
          </AFlexColumnDiv>

          {/* 이벤트 배너 */}
          <AFlexColumnDiv
            isAnim
            containerStyle={{
              width: BANNER_WIDTH,
              height: BANNER_HEIGHT,
              marginTop: 0,
              ...bannerShadowProps,
              ...devOutline,
            }}
            onClick={() => {}}
          >
            <Img src={[R.images.explore_iv_banner_43x, BANNER_WIDTH, BANNER_HEIGHT]} containerStyle={{ ...absoluteFillProps }} />
            <FlexColumnDiv style={{ marginLeft: 24, marginTop: 24 }}>
              <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_BANNER_EVENT_TITLE} />
              <FlexRowDiv style={{ alignItems: 'center' }}>
                <TextCon color={'white'} isBold size={18} text={hR.strings.EXP_BANNER_EVENT_CBLT} />
              </FlexRowDiv>
            </FlexColumnDiv>
          </AFlexColumnDiv>
        </FlexRowDiv>
        {/* 배너 end */}
        <HEIGHT size={80} />
        {/* 현황판 */}
        {isAlpha() && (
          <>
            <CommonRowBox style={{ alignSelf: 'stretch', paddingLeft: 20, paddingTop: 30, paddingBottom: 30 }}>
              {/* 생성된 asset */}
              <FlexRowDiv
                style={{
                  flex: 1,
                  // ...devOutlineF
                }}
              >
                <Img src={[R.images.explore_iv_asset3x, STATUS_ICON_SIZE, STATUS_ICON_SIZE]} />
                <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                  <TextCon size={14} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_ASSET_CNT} />
                  {/* <TextCon isBold size={22} color={R.colors.txtPrimary}>
              {hExplorerState.state.explorerInfo?.newAssetCnt.toLocaleString() || <Skeleton width={100} height={10} />}
            </TextCon> */}
                  <AnimatedText isBold size={22} color={R.colors.black}>
                    {newAssetCnt.to((n) => n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
                  </AnimatedText>
                </FlexColumnDiv>
                {/* <animated.div>{number.to(n => n.toFixed(2))}</animated.div>
          <animated.div>{number}</animated.div> */}
                {/* <AnimatedText>{number}</AnimatedText> */}
              </FlexRowDiv>

              {/* 오늘 판매 등록 수 */}
              <FlexRowDiv
                style={{
                  flex: 1,
                  // ...devOutlineF
                }}
              >
                <Img src={[R.images.explore_iv_listing3x, STATUS_ICON_SIZE, STATUS_ICON_SIZE]} />
                <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                  <TextCon size={14} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_SALE_CNT} />
                  {/* <TextCon isBold size={22} color={R.colors.txtPrimary}>
              {hExplorerState.state.explorerInfo?.listingCnt.toLocaleString() || <Skeleton width={100} height={10} />}
            </TextCon> */}
                  <AnimatedText isBold size={22} color={R.colors.black}>
                    {listingCnt.to((n) => n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
                  </AnimatedText>
                </FlexColumnDiv>
              </FlexRowDiv>

              {/* 오늘 거래된 수 */}
              <FlexRowDiv
                style={{
                  flex: 1,
                  // ...devOutlineF
                }}
              >
                <Img src={[R.images.explore_iv_sale3x, STATUS_ICON_SIZE, STATUS_ICON_SIZE]} />
                <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                  <TextCon size={14} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_TX_CNT} />
                  {/* <TextCon isBold size={22} color={R.colors.txtPrimary}>
              {hExplorerState.state.explorerInfo?.saleCnt.toLocaleString() || <Skeleton width={100} height={10} />}
            </TextCon> */}
                  <AnimatedText isBold size={22} color={R.colors.black}>
                    {saleCnt.to((n) => n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
                  </AnimatedText>
                </FlexColumnDiv>
              </FlexRowDiv>

              {/* 오늘 거래된 달러 */}
              <FlexRowDiv
                style={{
                  flex: 1,
                  // ...devOutlineF
                }}
              >
                <Img src={[R.images.explore_iv_dollar3x, STATUS_ICON_SIZE, STATUS_ICON_SIZE]} />
                <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                  <TextCon size={14} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_DOLLAR_CNT} />
                  {/* <TextCon isBold size={22} color={R.colors.txtPrimary}>
              {hExplorerState.state.explorerInfo ? `$${hExplorerState.state.explorerInfo?.dollarAmount.toLocaleString()}` : <Skeleton width={100} height={10} />}
            </TextCon> */}
                  <AnimatedText isBold size={22} color={R.colors.black}>
                    {dollarAmount.to((n) => {
                      return `$${n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
                    })}
                  </AnimatedText>
                </FlexColumnDiv>
              </FlexRowDiv>
            </CommonRowBox>
            {/* 현황판 end */}
            <HEIGHT size={80} />
          </>
        )}
        {/* 추천 에셋 목록 */}
        <FlexRowDiv style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          {/* 타이틀 */}
          <TextCon color={R.colors.black} size={30} isBold text={hR.strings.EXP_RECOMMEND_ASSET_SECTION_TITLE} />
        </FlexRowDiv>
        <HEIGHT size={20} />
        <FlexRowDiv
          style={{
            //
            alignItems: 'stretch',
            flexWrap: 'wrap',
            width: ScreenInfo.contentsWidth + 15 * 2,
            // ...devOutlineF,
          }}
        >
          {hExplorerState.state.explorerInfo.reco_list.map((ast) => {
            const item: MarketAssetCard_i = {
              sale_id: ast.sale_id,
              asset_id: ast.ast_info.asset_id,
              asset_type: Utils.svc.getAssetType({
                //
                nft_img: ast.ast_info.nft_img,
                nft_aud: ast.ast_info.nft_aud,
                nft_vid: ast.ast_info.nft_vid,
              }),
              nft_name: ast.ast_info.nft_name,
              col_info: ast.ast_info.col_info,
              nft_img: ast.ast_info.nft_img,
              owner: ast.ast_info.owner,
              favorite: ast.favorite,
              prc_info: ast.prc_info,
              sold: ast.sold,
            };
            return (
              <MarketAssetCardForList
                isAnim
                data={item}
                onClick={() => {
                  hHistory.push(Nav.brewMarketAssetRScreenPath({ sale_id: ast.sale_id }));
                }}
                enableBuyBtn={ast.sold === false}
                onBuyClick={() => {
                  // todo
                }}
              />
            );
          })}
        </FlexRowDiv>
         {/* 새로운 에셋 목록 end */}
        <HEIGHT size={65} />
        {/* New Asset 목록 */}
        <FlexRowDiv style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          {/* 타이틀 */}
          <TextCon color={R.colors.black} size={30} isBold text={hR.strings.EXP_NEW_ASSET_SECTION_TITLE} />
          {/* VIEW ALL 버튼 */}
          <TextCon
            color={R.colors.blueGrey}
            size={16}
            isBold
            text={hR.strings.EXP_NEW_ASSET_VIEW_ALL}
            containerStyle={{ marginLeft: 'auto' }}
            onConClick={() => {
              hMarketPlaceState.act.clearMarketPlaceState();
              hHistory.push(Nav.getMarketPlaceInit());
            }}
          />
        </FlexRowDiv>
        <HEIGHT size={20} />
        <FlexRowDiv
          style={{
            //
            alignItems: 'stretch',
            flexWrap: 'wrap',
            width: ScreenInfo.contentsWidth + 15 * 2,
            // ...devOutlineF,
          }}
        >
          {hExplorerState.state.explorerInfo.sale_list.map((ast) => {
            const item: MarketAssetCard_i = {
              sale_id: ast.sale_id,
              asset_id: ast.ast_info.asset_id,
              asset_type: Utils.svc.getAssetType({
                //
                nft_img: ast.ast_info.nft_img,
                nft_aud: ast.ast_info.nft_aud,
                nft_vid: ast.ast_info.nft_vid,
              }),
              nft_name: ast.ast_info.nft_name,
              col_info: ast.ast_info.col_info,
              nft_img: ast.ast_info.nft_img,
              owner: ast.ast_info.owner,
              favorite: ast.favorite,
              prc_info: ast.prc_info,
            };
            return (
              <MarketAssetCardForList
                isAnim
                data={item}
                onClick={() => {
                  hHistory.push(Nav.brewMarketAssetRScreenPath({ sale_id: ast.sale_id }));
                }}
                onBuyClick={() => {
                  // todo
                }}
              />
            );
          })}
        </FlexRowDiv>
         {/* New Asset 목록 end */}
        <HEIGHT size={65} />
        {/* Collection 목록 */}
        <FlexRowDiv style={{ width: '100%', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          {/* 타이틀 */}
          <TextCon color={R.colors.black} size={30} isBold text={hR.strings.EXP_COLLECTION_SECTION_TITLE} />
        </FlexRowDiv>
        <HEIGHT size={30} />
        <FlexRowDiv
          style={{
            //
            alignItems: 'stretch',
            flexWrap: 'wrap',
            width: ScreenInfo.contentsWidth + 15 * 2,
            // ...devOutlineF,
          }}
        >
          {hExplorerState.state.explorerInfo.col_list.map((col) => {
            return (
              <CollectionCardForList
                isAnim
                collectionInfo={col}
                onClick={() => {
                  hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: col.cid }));
                }}
              />
            );
          })}
        </FlexRowDiv>
        {/* Collection 목록 end */}
      </FlexColumnDiv>
    );
  } else if (ScreenInfo.clientType === ClientType_e.Mobile) {
    // *************************************
    // *** 모바일
    // *************************************
    return (
      <MobileScalableFlexColumn
      // getHeight={(mbHeightInfo) => {
      //   setHeight(mbHeightInfo.scaledHeight);
      // }}
      // key={`${sHeight}`}
      >
        <FlexColumnDiv
          style={{
            width: MOBILE_WIDTH,
            // height: sHeight,
            paddingLeft: MOBILE_HORIZONTAL_PADDING,
            paddingRight: MOBILE_HORIZONTAL_PADDING,
            // ...devOutlineF,
          }}
        >
          <HEIGHT size={20} />

          {/* 배너 */}
          <FlexRowDiv style={{ alignSelf: 'stretch', justifyContent: 'space-between' }}>
            {/* 에셋 발행하기 배너 */}
            <AFlexColumnDiv
              isAnim
              containerStyle={{
                width: 134,
                height: 120,
                marginTop: 0,
                ...bannerShadowProps,
                ...devOutline,
              }}
              onClick={() => {
                hHistory.push(Nav.AssetCreatorScreen);
              }}
            >
              <Img src={[R.images.explore_iv_banner_13x, 134, 120]} containerStyle={{ ...absoluteFillProps }} />
              <TextCon color={'white'} isBold size={10} text={hR.strings.EXP_MAIN_BANNER_BTN} containerStyle={{ marginLeft: 14, marginTop: 14 }} />
            </AFlexColumnDiv>

            <WIDTH size={12} />

            {/* CARROT 배너 */}
            <AFlexColumnDiv
              isAnim
              containerStyle={{
                width: 134,
                height: 120,
                marginTop: 0,
                ...bannerShadowProps,
                ...devOutline,
              }}
              onClick={() => {
                if (isAlpha()) hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 90 }));
                else if (isProd()) hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 1005 }));
              }}
            >
              <Img src={[R.images.explore_iv_banner_23x, 134, 120]} containerStyle={{ ...absoluteFillProps }} />
              <FlexColumnDiv style={{ marginLeft: 14, marginTop: 14 }}>
                <TextCon color={'white'} isBold size={10} text={hR.strings.EXP_BANNER_COLLECTION_TITLE} />
                <FlexRowDiv style={{ alignItems: 'center' }}>
                  <Img src={[R.images.common_ic_verify3x, 14, 14]} />
                  <WIDTH size={3} />
                  <TextCon color={'white'} isBold size={10} text={hR.strings.EXP_BANNER_CARROT_TITLE} />
                </FlexRowDiv>
              </FlexColumnDiv>
            </AFlexColumnDiv>
          </FlexRowDiv>

          <HEIGHT size={12} />

          <FlexRowDiv style={{ alignSelf: 'stretch', justifyContent: 'space-between' }}>
            {/*  포케코로 배너 */}
            <AFlexColumnDiv
              isAnim
              containerStyle={{
                width: 134,
                height: 120,
                marginTop: 0,
                ...bannerShadowProps,
                ...devOutline,
              }}
              onClick={() => {
                if (isAlpha()) {
                  // hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 90 }));
                } else if (isProd()) {
                  hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: 1007 }));
                }
              }}
            >
              <Img src={[R.images.explore_iv_banner_33x, 134, 120]} containerStyle={{ ...absoluteFillProps }} />
              <FlexColumnDiv style={{ marginLeft: 14, marginTop: 14 }}>
                <TextCon color={'white'} isBold size={10} text={hR.strings.EXP_BANNER_COLLECTION_TITLE} />
                <FlexRowDiv style={{ alignItems: 'center' }}>
                  <Img src={[R.images.common_ic_verify3x, 14, 14]} />
                  <WIDTH size={3} />
                  <TextCon color={'white'} isBold size={10} text={hR.strings.EXP_BANNER_POKECOLO_TITLE} />
                </FlexRowDiv>
              </FlexColumnDiv>
            </AFlexColumnDiv>

            <WIDTH size={12} />

            {/* 이벤트 배너 */}
            <AFlexColumnDiv
              isAnim
              containerStyle={{
                width: 134,
                height: 120,
                marginTop: 0,
                ...bannerShadowProps,
                ...devOutline,
              }}
              onClick={() => {}}
            >
              <Img src={[R.images.explore_iv_banner_43x, 134, 120]} containerStyle={{ ...absoluteFillProps }} />
              <TextConMulti
                color={'white'}
                isBold
                size={10}
                text={[hR.strings.EXP_BANNER_EVENT_TITLE, hR.strings.EXP_BANNER_EVENT_CBLT]}
                containerStyle={{ marginLeft: 14, marginTop: 14 }}
              />
            </AFlexColumnDiv>
          </FlexRowDiv>
          {/* 배너 end */}

          {isAlpha() && (
            <>
              <HEIGHT size={30} />

              {/* 현황판 */}
              <CommonColumnBox
                style={{
                  width: 280,
                  height: 296,
                  alignSelf: 'stretch',
                  padding: 18,
                }}
              >
                {/* 생성된 asset */}
                <FlexRowDiv
                  style={{
                    flex: 1,
                  }}
                >
                  <Img src={[R.images.explore_iv_asset3x, 56, 56]} containerStyle={{ marginRight: 10 }} />
                  <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                    <TextCon size={10} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_ASSET_CNT} />
                    <HEIGHT size={2} />
                    <AnimatedText isBold size={16} color={R.colors.black}>
                      {newAssetCnt.to((n) => n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
                    </AnimatedText>
                  </FlexColumnDiv>
                </FlexRowDiv>

                <HEIGHT size={12} />

                {/* 오늘 판매 등록 수 */}
                <FlexRowDiv
                  style={{
                    flex: 1,
                  }}
                >
                  <Img src={[R.images.explore_iv_listing3x, 56, 56]} containerStyle={{ marginRight: 10 }} />
                  <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                    <TextCon size={10} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_SALE_CNT} />
                    <HEIGHT size={2} />
                    <AnimatedText isBold size={16} color={R.colors.black}>
                      {listingCnt.to((n) => n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
                    </AnimatedText>
                  </FlexColumnDiv>
                </FlexRowDiv>

                <HEIGHT size={12} />

                {/* 오늘 거래된 수 */}
                <FlexRowDiv
                  style={{
                    flex: 1,
                  }}
                >
                  <Img src={[R.images.explore_iv_sale3x, 56, 56]} containerStyle={{ marginRight: 10 }} />
                  <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                    <TextCon size={10} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_TX_CNT} />
                    <HEIGHT size={2} />
                    <AnimatedText isBold size={16} color={R.colors.black}>
                      {saleCnt.to((n) => n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
                    </AnimatedText>
                  </FlexColumnDiv>
                </FlexRowDiv>

                <HEIGHT size={12} />

                {/* 오늘 거래된 달러 */}
                <FlexRowDiv
                  style={{
                    flex: 1,
                  }}
                >
                  <Img src={[R.images.explore_iv_dollar3x, 56, 56]} containerStyle={{ marginRight: 10 }} />
                  <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 4 }}>
                    <TextCon size={10} color={R.colors.blueGrey} text={hR.strings.EXP_STATUS_BOARD_DOLLAR_CNT} />
                    <HEIGHT size={2} />
                    <AnimatedText isBold size={16} color={R.colors.black}>
                      {dollarAmount.to((n) => {
                        return `$${n.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
                      })}
                    </AnimatedText>
                  </FlexColumnDiv>
                </FlexRowDiv>
              </CommonColumnBox>
            </>
          )}
          {/* 현황판 end */}
        </FlexColumnDiv>

        <HEIGHT size={30} />

        {/* **************************************** */}
        {/* 추천 Asset 목록 */}
        {/* **************************************** */}
        <FlexRowDiv
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            paddingLeft: MOBILE_HORIZONTAL_PADDING,
            paddingRight: MOBILE_HORIZONTAL_PADDING,
          }}
        >
          {/* 타이틀 */}
          <TextCon color={R.colors.black} size={16} isBold text={hR.strings.EXP_RECOMMEND_ASSET_SECTION_TITLE} />
        </FlexRowDiv>

        <FlexColumnDiv
          style={{
            width: 320,
            justifyContent: 'center',
            // ...devOutlineF,
          }}
        >
          <FlexColumnDiv
            style={{
              width: 320,
              overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤이 생김
              // justifyContent: 'center',
              zIndex: 1, // scaling 되면 zindex을 다시 설정해주어야함
              // ...devOutlineF,
            }}
          >
            <Slider
              ref={(c) => {
                recAssetSliderRef.current = c;
              }}
              infinite={false}
              speed={400}
              slidesToShow={1}
              slidesToScroll={1}
              beforeChange={(cur, next) => {
                DBGMSG(`cur:${cur} next:${next}`);
                setRecAssetSlideIdx(next);
              }}
              afterChange={(cur) => {
                DBGMSG(`cur:${cur}`);
                setRecAssetSlideIdx(cur);
              }}
            >
              {hExplorerState.state.explorerInfo.reco_list.map((ast, idx, reco_list) => {
                const item: MarketAssetCard_i = {
                  sale_id: ast.sale_id,
                  asset_id: ast.ast_info.asset_id,
                  asset_type: Utils.svc.getAssetType({
                    //
                    nft_img: ast.ast_info.nft_img,
                    nft_aud: ast.ast_info.nft_aud,
                    nft_vid: ast.ast_info.nft_vid,
                  }),
                  nft_name: ast.ast_info.nft_name,
                  col_info: ast.ast_info.col_info,
                  nft_img: ast.ast_info.nft_img,
                  owner: ast.ast_info.owner,
                  favorite: ast.favorite,
                  prc_info: ast.prc_info,
                  sold: ast.sold,
                };
                const zIndex = reco_list.length - idx;
                return (
                  <div id={`SliderItem-${idx}`} style={{ zIndex }}>
                    <MarketAssetCardForSlider
                      idx={zIndex}
                      key={idx}
                      // scale={SSC_scale}
                      isAnim
                      data={item}
                      onClick={() => {
                        hHistory.push(Nav.brewMarketAssetRScreenPath({ sale_id: ast.sale_id }));
                      }}
                      onBuyClick={() => {
                        // todo
                      }}
                    />
                  </div>
                );
              })}
            </Slider>
          </FlexColumnDiv>

          <ImgBtn
            src={[R.images.common_btn_back_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingLeft: 5,
              left: 0,
              opacity: sRecAssetSlideIdx !== 0 ? 1 : 0,
              zIndex: 2,
            }}
            onClick={() => {
              recAssetSliderRef.current?.slickPrev();
            }}
          />

          <ImgBtn
            src={[R.images.common_btn_next_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingRight: 5,
              right: 0,
              opacity: sRecAssetSlideIdx < hExplorerState.state.explorerInfo.reco_list.length - 1 ? 1 : 0,
              zIndex: 2,
            }}
            onClick={() => {
              recAssetSliderRef.current?.slickNext();
              DBGMSG(recAssetSliderRef.current?.state);
            }}
          />
        </FlexColumnDiv>

        <HEIGHT size={10} />

        {/* **************************************** */}
        {/* New Asset 목록 */}
        {/* **************************************** */}
        <FlexRowDiv
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            paddingLeft: MOBILE_HORIZONTAL_PADDING,
            paddingRight: MOBILE_HORIZONTAL_PADDING,
          }}
        >
          {/* 타이틀 */}
          <TextCon color={R.colors.black} size={16} isBold text={hR.strings.EXP_NEW_ASSET_SECTION_TITLE} />

          {/* VIEW ALL 버튼 */}
          <TextCon
            color={R.colors.blueGrey}
            size={10}
            isBold
            text={`${hR.strings.EXP_NEW_ASSET_VIEW_ALL} >`}
            containerStyle={{ marginLeft: 'auto' }}
            onConClick={() => {
              hMarketPlaceState.act.clearMarketPlaceState();
              hHistory.push(Nav.getMarketPlaceInit());
            }}
          />
        </FlexRowDiv>

        <FlexColumnDiv
          style={{
            width: 320,
            justifyContent: 'center',
            // ...devOutlineF,
          }}
        >
          <FlexColumnDiv
            style={{
              width: 320,
              overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤이 생김
              // justifyContent: 'center',
              zIndex: 1, // scaling 되면 zindex을 다시 설정해주어야함
              // ...devOutlineF,
            }}
          >
            <Slider
              ref={(c) => {
                newAssetSliderRef.current = c;
              }}
              infinite={false}
              speed={400}
              slidesToShow={1}
              slidesToScroll={1}
              beforeChange={(cur, next) => {
                DBGMSG(`cur:${cur} next:${next}`);
                setNewAssetSlideIdx(next);
              }}
              afterChange={(cur) => {
                DBGMSG(`cur:${cur}`);
                setNewAssetSlideIdx(cur);
              }}
            >
              {hExplorerState.state.explorerInfo.sale_list.map((ast, idx, sale_list) => {
                const item: MarketAssetCard_i = {
                  sale_id: ast.sale_id,
                  asset_id: ast.ast_info.asset_id,
                  asset_type: Utils.svc.getAssetType({
                    //
                    nft_img: ast.ast_info.nft_img,
                    nft_aud: ast.ast_info.nft_aud,
                    nft_vid: ast.ast_info.nft_vid,
                  }),
                  nft_name: ast.ast_info.nft_name,
                  col_info: ast.ast_info.col_info,
                  nft_img: ast.ast_info.nft_img,
                  owner: ast.ast_info.owner,
                  favorite: ast.favorite,
                  prc_info: ast.prc_info,
                };
                const zIndex = sale_list.length - idx;
                return (
                  <div id={`SliderItem-${idx}`} style={{ zIndex }}>
                    <MarketAssetCardForSlider
                      idx={zIndex}
                      key={idx}
                      // scale={SSC_scale}
                      isAnim
                      data={item}
                      onClick={() => {
                        hHistory.push(Nav.brewMarketAssetRScreenPath({ sale_id: ast.sale_id }));
                      }}
                      onBuyClick={() => {
                        // todo
                      }}
                    />
                  </div>
                );
              })}
            </Slider>
          </FlexColumnDiv>

          <ImgBtn
            src={[R.images.common_btn_back_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingLeft: 5,
              left: 0,
              opacity: sNewAssetSlideIdx !== 0 ? 1 : 0,
              zIndex: 2,
            }}
            onClick={() => {
              newAssetSliderRef.current?.slickPrev();
            }}
          />

          <ImgBtn
            src={[R.images.common_btn_next_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingRight: 5,
              right: 0,
              opacity: sNewAssetSlideIdx < hExplorerState.state.explorerInfo.sale_list.length - 1 ? 1 : 0,
              zIndex: 2,
            }}
            onClick={() => {
              newAssetSliderRef.current?.slickNext();
              DBGMSG(newAssetSliderRef.current?.state);
            }}
          />
        </FlexColumnDiv>

        {/* Collection 목록 */}
        <FlexRowDiv
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            paddingLeft: MOBILE_HORIZONTAL_PADDING,
            paddingRight: MOBILE_HORIZONTAL_PADDING,
          }}
        >
          {/* 타이틀 */}
          <TextCon color={R.colors.black} size={16} isBold text={hR.strings.EXP_COLLECTION_SECTION_TITLE} />
        </FlexRowDiv>

        <FlexColumnDiv
          style={{
            width: 320,
            height: 266 + 40,
            justifyContent: 'center',
            // ...devOutlineF,
          }}
        >
          <FlexColumnDiv
            style={{
              width: 320,
              height: 266 + 40,
              overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤이 생김
              // justifyContent: 'center',
              zIndex: 1, // scaling 되면 zindex을 다시 설정해주어야함
              // ...devOutlineF,
            }}
          >
            <Slider
              ref={(c) => {
                collectionSliderRef.current = c;
              }}
              infinite={false}
              speed={400}
              slidesToShow={1}
              slidesToScroll={1}
              beforeChange={(cur, next) => {
                DBGMSG(`cur:${cur} next:${next}`);
                setCollectionSlideIdx(next);
                setSliderIsSliding(true);
              }}
              afterChange={(cur) => {
                DBGMSG(`cur:${cur}`);
                setCollectionSlideIdx(cur);
                setSliderIsSliding(false);
              }}
              onSwipe={() => {
                DBGMSG('onSwipe');
              }}
            >
              {hExplorerState.state.explorerInfo.col_list.map((col, idx, col_list) => {
                const zIndex = col_list.length - idx;
                return (
                  <div id={`SliderItem-${idx}`} style={{ zIndex: zIndex }}>
                    <CollectionCardForSlider
                      isAnim
                      collectionInfo={col}
                      onClick={() => {
                        hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: col.cid }));
                      }}
                      leftBtnEnable={sCollectionSlideIdx !== 0}
                      rightBtnEnable={sCollectionSlideIdx < col_list.length - 1}
                      leftBtnOnClick={() => {
                        collectionSliderRef.current?.slickPrev();
                      }}
                      rightBtnOnClick={() => {
                        collectionSliderRef.current?.slickNext();
                      }}
                      isSliding={sSliderIsSliding}
                    />
                  </div>
                );
              })}
            </Slider>
          </FlexColumnDiv>
          <ImgBtn
            src={[R.images.common_btn_back_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingLeft: 5,
              // top: 108,
              left: 0,
              opacity: sCollectionSlideIdx !== 0 ? 1 : 0,
              zIndex: 2,
            }}
            onClick={() => {
              collectionSliderRef.current?.slickPrev();
            }}
          />

          <ImgBtn
            src={[R.images.common_btn_next_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingRight: 5,
              // top: 108,
              right: 0,
              opacity: sCollectionSlideIdx < hExplorerState.state.explorerInfo.col_list.length - 1 ? 1 : 0,
              zIndex: 2,
              // ...devOutlineF,
            }}
            onClick={() => {
              collectionSliderRef.current?.slickNext();
              DBGMSG(collectionSliderRef.current?.state);
            }}
          />
        </FlexColumnDiv>
        <HEIGHT size={40} />
        {/* 공통푸터 */}
        <CommonFooter />
      </MobileScalableFlexColumn>
    );
  } else {
    return null;
  }
}
