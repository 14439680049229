/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import ScreenInfo, { MOBILE_CONTENT_WIDTH } from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtCurrency_e, CbtReqPriceInfo_i, CbtResPriceInfo_i } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, devOutlineFG, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { CURRENCY_ROUND_DECIMAL_POINT_CBLT, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
const MB_DEFAULT_BOX_PADDING = 16;

const DT_IC_WIDTH = 18;
const MB_IC_WIDTH = 16;
const IC_WIDTH = ScreenInfo.isMobile() ? MB_IC_WIDTH : DT_IC_WIDTH;

const DT_IC_HEIGHT = 20;
const MB_IC_HEIGHT = 18;
const IC_HEIGHT = ScreenInfo.isMobile() ? MB_IC_HEIGHT : DT_IC_HEIGHT;

const DT_PRICE_FONTSIZE = 22;
const MB_PRICE_FONTSIZE = 16;
const PRICE_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_FONTSIZE : DT_PRICE_FONTSIZE;

const DT_DEFAULT_FONTSIZE_IN_BOX = 14;
const MB_DEFAULT_FONTSIZE_IN_BOX = 10;
const DEFAULT_FONTSIZE_IN_BOX = ScreenInfo.isMobile() ? MB_DEFAULT_FONTSIZE_IN_BOX : DT_DEFAULT_FONTSIZE_IN_BOX;

const DT_BALANCE_CONTAINER_WIDTH = (582 - 43) / 2;
const MB_BALANCE_CONTAINER_WIDTH = MOBILE_CONTENT_WIDTH - MB_DEFAULT_BOX_PADDING * 2;
const BALANCE_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_BALANCE_CONTAINER_WIDTH : DT_BALANCE_CONTAINER_WIDTH;

const DT_PRICE_UNIT_FONTSIZE = 12;
const MB_PRICE_UNIT_FONTSIZE = 10;
const PRICE_UNIT_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_UNIT_FONTSIZE : DT_PRICE_UNIT_FONTSIZE;

type Props = {
  prc_info: CbtResPriceInfo_i;
};

export function BalanceWithTitle(props: Props) {
  const [sIc, setIc] = useState<string>();
  const [sLabel, setLabel] = useState<string>('');
  const [sPrice, setPrice] = useState<string>('');
  const [sUnit, setUnit] = useState<string>('');
  const [sTooltip, setTooltip] = useState<string>();
  const hR = useLocalSettings();

  useEffect(() => {
    DBGMSG(props);

    switch (props.prc_info.curr_info.currency) {
      case CbtCurrency_e.KLAY:
        setIc(R.images.common_ic_klay3x);
        setLabel(hR.strings.MY_BALANCE_KLAY_LABEL);
        setPrice(Utils.currency.getPriceForDP({ prcInfo: props.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT }));
        setUnit(R.strings.CURRENCY_KLAY);
        break;
      case CbtCurrency_e.cPOINT:
        setIc(R.images.common_ic_point3x);
        setLabel(hR.strings.MY_BALANCE_CPOINT_LABEL);
        setPrice(Utils.currency.getPriceForDP({ prcInfo: props.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT }));
        setUnit(R.strings.CURRENCY_CPOINT);
        break;
      case CbtCurrency_e.cUSD:
        setLabel(hR.strings.MY_BALANCE_CUSD_LABEL);
        setPrice(Utils.currency.getPriceForDP({ prcInfo: props.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT }));
        setUnit(R.strings.CURRENCY_CUSD);
        break;
      case CbtCurrency_e.CBLT:
        setIc(R.images.common_ic_cblt3x);
        setLabel(hR.strings.MY_BALANCE_CBLT_LABEL);
        setTooltip(hR.strings.MY_BALANCE_CBLT_TOOLTIP);
        setPrice(Utils.currency.getPriceForDP({ prcInfo: props.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_CBLT }));
        setUnit(R.strings.CURRENCY_CBLT);
        break;
    }
    return () => {};
  }, [hR.lang, props]);

  DBGMSG('render');
  return (
    <FlexColumnDiv
      style={{
        flex: 1,
        width: BALANCE_CONTAINER_WIDTH,
        alignSelf: 'stretch',
        // ...devOutlineFG,
      }}
    >
      {/* label */}
      <FlexRowDiv style={{ alignSelf: 'flex-start' }}>
        {sIc && (
          <>
            <Img src={[sIc, IC_WIDTH, IC_HEIGHT]} />
            <WIDTH size={2} />
          </>
        )}
        <TextCon text={sLabel} size={DEFAULT_FONTSIZE_IN_BOX} color={R.colors.black} isBold />

        {/* tooltip */}
        {sTooltip && (
          <>
            <WIDTH size={4} />
            <TooltipImg tooltipString={sTooltip} placement={ScreenInfo.isMobile() ? 'top' : 'right'} />
          </>
        )}
      </FlexRowDiv>

      {ScreenInfo.isMobile() && <HEIGHT size={10} />}

      {/* balace */}
      <FlexColumnDiv
        style={{
          flex: 1,
          justifyContent: 'center',
          // alignItems: ScreenInfo.isMobile() ? 'flex-end' : undefined,
          // ...devOutlineF
        }}
      >
        <FlexRowDiv
          style={{
            alignItems: 'baseline',
            // ...devOutlineF
          }}
        >
          <TextCon text={sPrice} size={PRICE_FONTSIZE} color={R.colors.black} isBold />
          <WIDTH size={4} />
          <TextCon text={sUnit} size={PRICE_UNIT_FONTSIZE} color={R.colors.black} isBold />
        </FlexRowDiv>
      </FlexColumnDiv>
    </FlexColumnDiv>
  );
}
