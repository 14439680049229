/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { CbtUserCertTp_i } from 'src/model/rpcModel';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSGW } from 'src/util/utils';

export function useCheckUserCertTpAndBlock() {
  const hAuth = useAuth();
  const hHistory = useHistory();
  useEffect(() => {
    if (hAuth.authCommonRes) {
      switch (hAuth.authCommonRes.cert_tp) {
        case CbtUserCertTp_i.Normal:
          hHistory.replace(Nav.AssetCreatorBlockedScreen);
          break;
        case CbtUserCertTp_i.Certificated:
        case CbtUserCertTp_i.Partners:
          break;
        default:
          DBGMSGW(`hAuth.authCommonRes.cert_tp`, hAuth.authCommonRes.cert_tp);
          break;
      }
    }
  }, [hAuth.authCommonRes, hAuth.authCommonRes?.cert_tp]);
  return;
}
