import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isAlpha, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { CbtPG_e, CbtResPriceInfo_i, CbtUserLanguage_e } from 'src/model/rpcModel';
import { RPC_PAYMENT_CHARGE_POINT_LIST } from 'src/model/rpcType';
import R from 'src/res/R';
import { FlexColumnDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtn3 } from 'src/ui/common_component/button/RoundTxtBtn3';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, Utils } from 'src/util/utils';

export type PointChargeListJsxProps = {
  myPointBalanceInfo: CbtResPriceInfo_i;
  onCloseBtn: () => void;
  onChargeBtn: (args: { widgetUrl: string; prd_id: string }) => void;
};

// Desktop
const DT_DLG_WIDTH = 582;
const DT_PADDING = 30;
const DT_DLG_PADDING_H = 40;
const DT_DEFULAT_FONT = 16;
const DT_TITLE_FONT = 22;
const DT_POINT_FONT = 18;
const DT_POINT_IMG_SIZE = 78;
const DT_BTN_WIDTH = 320;
const DT_BTN_HEIGHT = 48;
const DT_POINT_BTN_FONT = 16;
const DT_POINT_BTN_WIDTH = 150;
const DT_POINT_BTN_HEIGHT = 48;
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;

// Mobile
const MB_DLG_WIDTH = 280;
const MB_PADDING = 16;
const MB_DLG_PADDING_H = 12;
const MB_DEFULAT_FONT = 12;
const MB_TITLE_FONT = 14;
const MB_POINT_FONT = 14;
const MB_POINT_IMG_SIZE = 60;
const MB_BTN_WIDTH = 256;
const MB_BTN_HEIGHT = 44;
const MB_POINT_BTN_FONT = 12;
const MB_POINT_BTN_WIDTH = 100;
const MB_POINT_BTN_HEIGHT = 36;
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const DLG_DEFAULT_PADDING = ScreenInfo.isMobile() ? MB_PADDING : DT_PADDING;
const DLG_PADDING_H = ScreenInfo.isMobile() ? MB_DLG_PADDING_H : DT_DLG_PADDING_H;
const DEFULAT_FONT = ScreenInfo.isMobile() ? MB_DEFULAT_FONT : DT_DEFULAT_FONT;
const TITLE_FONT = ScreenInfo.isMobile() ? MB_TITLE_FONT : DT_TITLE_FONT;
const POINT_FONT = ScreenInfo.isMobile() ? MB_POINT_FONT : DT_POINT_FONT;
const POINT_IMG_SIZE = ScreenInfo.isMobile() ? MB_POINT_IMG_SIZE : DT_POINT_IMG_SIZE;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const POINT_BTN_FONT = ScreenInfo.isMobile() ? MB_POINT_BTN_FONT : DT_POINT_BTN_FONT;
const POINT_BTN_WIDTH = ScreenInfo.isMobile() ? MB_POINT_BTN_WIDTH : DT_POINT_BTN_WIDTH;
const POINT_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_POINT_BTN_HEIGHT : DT_POINT_BTN_HEIGHT;
const CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
const CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;

const PointAmountImgSrcList = [
  R.images.common_ic_point_13x,
  R.images.common_ic_point_23x,
  R.images.common_ic_point_33x,
  R.images.common_ic_point_43x,
  R.images.common_ic_point_53x,
  R.images.common_ic_point_63x,
];

export const PointChargeListJsx = (props: PointChargeListJsxProps) => {
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hHistory = useHistory();

  const [sFetchedData, setFetchedData] = useState<RPC_PAYMENT_CHARGE_POINT_LIST.Rx>();
  const [sLoading, setLoading] = useState<boolean>();
  const [sSelectedIdx, setSelectedIdx] = useState<number>();
  const [sPaymentWidgetUrl, setPaymentWidgetUrl] = useState<string>();

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const fetchData = async () => {
    hDlgFactory.showLoading();
    try {
      setLoading(true);
      const res = await hRpc.cobaltRpc.rpcPaymentChargePointList({ pgno: CbtPG_e.Paymentwall, lang: hR.lang });
      setFetchedData(res);
    } catch (err) {
      setFetchedData(undefined);
    } finally {
      setLoading(false);
      hDlgFactory.hideLoading();
    }
  };

  const getBtnTxt = (chargePrc: CbtResPriceInfo_i) => {
    return `${Utils.currency.getPriceForDP({ prcInfo: chargePrc, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${R.strings.CURRENCY_CPOINT} ${
      hR.strings.MY_BALANCE_CPOINT_CHARGE
    }`;
  };

  const renderList = () => {
    if (!sFetchedData) return <DataFetching />;

    const list = sFetchedData.prd_list.map((prd, idx) => {
      const _idx = PointAmountImgSrcList.length <= idx ? PointAmountImgSrcList.length - 1 : idx;
      return (
        <>
          <FlexColumnDiv
            style={{
              height: POINT_IMG_SIZE,
              alignSelf: 'stretch',
              // ...devOutlineFB
              justifyContent: 'center',
            }}
          >
            <FlexRowDiv style={{ alignItems: 'center' }}>
              <Img src={[PointAmountImgSrcList[_idx], POINT_IMG_SIZE, POINT_IMG_SIZE]} />
              <FlexColumnDiv>
                <TextCon
                  text={`${Utils.currency.getPriceForDP({ prcInfo: prd.chg_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })}`}
                  size={POINT_FONT}
                  color={R.colors.purpleishBlue}
                  isBold
                />
                {/* 모바일인경우 수수료 */}
                {ScreenInfo.isMobile() && prd.dis_fee_pct && (
                  <>
                    <HEIGHT size={2} />
                    <FlexRowDiv style={{ alignItems: 'baseline' }}>
                      <TextCon
                        text={Utils.string.parsePlaceholder(hR.strings.DLG_CHARGE_FEE_DISCOUNT, '{할인율}').begin}
                        size={10}
                        color={R.colors.black}
                      />
                      <WIDTH size={2} />
                      <TextCon text={prd.dis_fee_pct + '%'} size={10} color={R.colors.purpleishBlue} isBold />
                      <WIDTH size={2} />
                      <TextCon
                        text={Utils.string.parsePlaceholder(hR.strings.DLG_CHARGE_FEE_DISCOUNT, '{할인율}').end}
                        size={10}
                        color={R.colors.black}
                      />
                    </FlexRowDiv>
                  </>
                )}
              </FlexColumnDiv>

              {/* 모바일아닌 경우 수수료 */}
              {!ScreenInfo.isMobile() && prd.dis_fee_pct && (
                <>
                  <WIDTH size={6} />
                  <FlexRowDiv style={{ width: 91, height: 28, justifyContent: 'center', alignItems: 'center' }}>
                    <Img src={[R.images.point_tv_fee3x, 91, 28]} containerStyle={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }} />
                    <FlexRowDiv style={{ alignItems: 'baseline' }}>
                      <TextCon
                        text={Utils.string.parsePlaceholder(hR.strings.DLG_CHARGE_FEE_DISCOUNT, '{할인율}').begin}
                        size={10}
                        color={R.colors.black}
                        letterSpacing={-0.5}
                      />
                      <WIDTH size={2} />
                      <TextCon
                        //
                        text={prd.dis_fee_pct + '%'}
                        size={12}
                        color={R.colors.purpleishBlue}
                        isBold
                        letterSpacing={-0.5}
                      />
                      <WIDTH size={2} />
                      <TextCon
                        text={Utils.string.parsePlaceholder(hR.strings.DLG_CHARGE_FEE_DISCOUNT, '{할인율}').end}
                        size={10}
                        color={R.colors.black}
                        letterSpacing={-0.5}
                      />
                    </FlexRowDiv>
                  </FlexRowDiv>
                </>
              )}

              <RoundTxtBtn3
                text={`${Utils.currency.getPriceForDP({ prcInfo: prd.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${
                  prd.prc_info.curr_info.currency
                }`}
                onClick={() => {
                  setSelectedIdx(idx);
                }}
                enable={true}
                forceHighlight={idx === sSelectedIdx}
                width={POINT_BTN_WIDTH}
                height={POINT_BTN_HEIGHT}
                fontSize={POINT_BTN_FONT}
                containerStyle={{ marginLeft: 'auto' }}
              />
            </FlexRowDiv>
          </FlexColumnDiv>
          <HLINE size={'100%'} />
        </>
      );
    });

    return (
      <>
        {/* 상품 목록 */}
        {list}
        <HEIGHT size={30} />

        {/* 버튼 */}
        {sSelectedIdx === undefined ? (
          <RoundTxtBtn width={BTN_WIDTH} height={BTN_HEIGHT} text={hR.strings.DLG_CHARGE_LIST_BTN} enable={false} />
        ) : (
          <RoundTxtBtn
            width={BTN_WIDTH}
            height={BTN_HEIGHT}
            text={getBtnTxt(sFetchedData.prd_list[sSelectedIdx].chg_info)}
            enable={true}
            onClick={async () => {
              // 목록창을 닫을것인가
              // props.onCloseBtn();

              const imgSrcIdx = PointAmountImgSrcList.length <= sSelectedIdx ? PointAmountImgSrcList.length - 1 : sSelectedIdx;
              const imgSrc = PointAmountImgSrcList[imgSrcIdx];
              hDlgFactory.showPointChargeDlg({
                selectedPrd: sFetchedData.prd_list[sSelectedIdx],
                imgSrc: imgSrc,
                onSuccess: () => {
                  hDlgFactory.hidePointChargeListDlg();
                },
                onClose: () => {
                  hDlgFactory.hidePointChargeDlg();
                },
              });
            }}
          />
        )}
      </>
    );
  };

  if (!sFetchedData) {
    return null;
  }

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        // minHeight: DLG_MIN_HEIGHT,
        // height: 90,
        padding: DLG_DEFAULT_PADDING,
        paddingLeft: DLG_PADDING_H,
        paddingRight: DLG_PADDING_H,
        alignItems: 'center',
        // ...devOutlineFF,
        // marginTop: ScreenInfo.isMobile() ? 79 : undefined,
        // marginBottom: ScreenInfo.isMobile() ? 79 : undefined,
      }}
      onClick={(e) => {
        DBGMSG('onDialog#');
        e.stopPropagation();
      }}
    >
      {/* close 버튼 */}
      <ImgBtn
        src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
        containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
        onClick={(e) => {
          DBGMSG('onClick close');
          props.onCloseBtn();
          e.stopPropagation();
        }}
      />

      {/* 타이틀 */}
      <TextCon text={hR.strings.DLG_CHARGE_LIST_TITLE} size={TITLE_FONT} color={R.colors.black} isBold />
      <HEIGHT size={30} />
      {/* 보유포인트 */}
      <TextCon text={hR.strings.DLG_CHARGE_LIST_MY_POINT_BALANCE} size={DEFULAT_FONT} color={R.colors.black} isBold />
      <HEIGHT size={4} />
      <FlexRowDiv style={{ alignItems: 'center' }}>
        <Img src={[R.images.common_ic_point3x, 18, 20]} />
        <WIDTH size={4} />
        <TextCon
          text={`${Utils.currency.getPriceForDP({
            prcInfo: props.myPointBalanceInfo,
            decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
          })}`}
          size={MB_TITLE_FONT}
          color={R.colors.purpleishBlue}
          isBold
        />
      </FlexRowDiv>
      <HEIGHT size={33} />
      <HLINE size={'100%'} />
      {sLoading && <TextCon text={'Loading'} size={22} color={R.colors.black} isBold />}
      {!sLoading && renderList()}
      
      <HEIGHT size={10} />
    </FlexColumnDiv>
  );
};
