/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { DESKTOP_WIDTH, MOBILE_CONTENT_WIDTH, MOBILE_HORIZONTAL_PADDING } from 'src/context/screen_constants';
import { Utils } from 'src/util/utils';

export enum ClientType_e {
  Desktop,
  Tablet,
  Mobile,
}

export function useScreenWidthHelper() {
  const windowSize = useWindowSize();

  const clientType = useMemo(() => {
    if (Utils.device.isDesktop) {
      return ClientType_e.Desktop;
    } else if (Utils.device.isTablet) {
      return ClientType_e.Tablet;
    } else {
      return ClientType_e.Mobile;
    }
  }, []);

  const contentsWidth = useMemo(() => {
    switch (clientType) {
      case ClientType_e.Desktop:
      case ClientType_e.Tablet:
        return DESKTOP_WIDTH;
      case ClientType_e.Mobile:
        return windowSize.width - MOBILE_HORIZONTAL_PADDING * 2;
    }
  }, [clientType]);

  const scale = useMemo(() => {
    switch (clientType) {
      case ClientType_e.Desktop:
      case ClientType_e.Tablet:
        return DESKTOP_WIDTH;
      case ClientType_e.Mobile:
        return contentsWidth / MOBILE_CONTENT_WIDTH;
    }
  }, [clientType, contentsWidth]);

  return {
    // windowSize,
    // contentsWidth,
    // contentsHeight: windowSize.height,
    // clientType,
    // scale,
  };
}
