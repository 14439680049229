/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { Oval, OverOval } from 'src/ui/common_component/button/SCircelAddBtn';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constants
 **************************************/
const COLLECTION_IMG_SETTING_BTN_SIZE = 162;

type Props = {
  size?: number;
  forceEffect?: boolean;
  img?: string;
  onEffect?: (on: boolean) => void;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function SCollectionImgSettingsBtn({ size: imgSize = COLLECTION_IMG_SETTING_BTN_SIZE, forceEffect = false, img, onEffect, onClick }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseEnter, setMouseEnter] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  const isEffect = sMouseEnter || forceEffect;

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    console.debug('useEffect() init');

    return () => {
      console.debug('useEffect() release');
    };
  }, []);

  useEffect(() => {
    onEffect && onEffect(isEffect);
    return () => {};
  }, [isEffect, onEffect]);

  useEffectOnce(() => {
    console.debug('useEffectOnce() init');
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  console.debug('render');

  return (
    <FlexColumnDiv style={{ alignItems: 'center' }}>
      {/*  Collection 대표이미지 */}
      {isEffect ? (
        // TODO DRY
        // OverOval === Oval
        <OverOval
          style={{
            width: imgSize,
            height: imgSize,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            boxSizing: 'content-box',
          }}
          onMouseEnter={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
          onClick={(e) => {
            DBGMSG(`onClick`);
            onClick && onClick(e);
          }}
        >
          {img ? (
            <Img
              src={[img, imgSize - 2, imgSize - 2]}
              containerStyle={{
                width: imgSize - 2,
                height: imgSize - 2,
                borderRadius: (imgSize - 2) / 2,
                overflow: 'hidden',
                // borderWidth: 2,
                // borderStyle: 'solid',
                // boxSizing: 'content-box'
                // borderColor: isDragging ? R.colors.purpleishBlue : R.colors.line,
              }}
              imgStyle={{
                objectFit: 'cover',
              }}
            />
          ) : (
            <Img src={[R.images.creator_ic_plus3x, 24, 24]} />
          )}
        </OverOval>
      ) : (
        <Oval
          style={{
            width: imgSize,
            height: imgSize,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            boxSizing: 'content-box',
          }}
          onMouseEnter={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
        >
          {img ? (
            <Img
              src={[img, imgSize - 2, imgSize - 2]}
              containerStyle={{
                width: imgSize - 2,
                height: imgSize - 2,
                borderRadius: (imgSize - 2) / 2,
                overflow: 'hidden',
                // borderWidth: 2,
                // borderStyle: 'solid',
                // boxSizing: 'content-box'
                // borderColor: isDragging ? R.colors.purpleishBlue : R.colors.line,
              }}
              imgStyle={{
                objectFit: 'cover',
              }}
            />
          ) : (
            <Img src={[R.images.creator_ic_plus3x, 24, 24]} />
          )}
        </Oval>
      )}
    </FlexColumnDiv>
  );
}
