/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { DisplaySrc_t, MarketAssetRead_i } from 'src/model/model';
import { CbtAssetType_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgAdv } from 'src/ui/base_component/ImgAdv';
import TextCon from 'src/ui/base_component/TextCon';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  assetInfo?: {
    assetType: MarketAssetRead_i['assetType'];
    attributeList?: MarketAssetRead_i['attributeList'];
    assetMainImg?: MarketAssetRead_i['assetMainImg'];
  };
  playerSrc?: DisplaySrc_t;
  width?: number;
  height?: number;
  scaleIfAudio?: number;
  disableFullScreenImg?: boolean;
  onImgFullscreen?: (src: string) => void;
  // isAnim?: boolean;
  onClick?: () => void;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! constant
 **************************************/
const WIDTH = 276;
const HEIGHT = 276;

const DT_ICON_SIZE = 28;
const MB_ICON_SIZE = 24;
const ICON_SIZE = ScreenInfo.isMobile() ? MB_ICON_SIZE : DT_ICON_SIZE;

/**************************************
 * !! styled component
 **************************************/

export function AssetDisplayReqattr({
  assetInfo,
  playerSrc,
  width = WIDTH,
  height = HEIGHT,
  scaleIfAudio = 1,
  disableFullScreenImg = false,
  onImgFullscreen,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  // const [sFullScreenViewerOnOff, setFullScreenViewerOnOff] = useState(false); // 전체화면 보기
  // const [sFullScreenImgSrc, setFullScreenImgSrc] = useState(''); // 전체화면 보기에 보여질 이미지
  const [sFullImgViewer, setFullImgViewer] = useState<{ isOpen: boolean; src?: string }>({ isOpen: false });

  /**************************************
   * !! ref
   **************************************/
  const videoRef = useRef<HTMLVideoElement>(null);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    if (videoRef.current !== null) {
      DBGMSG(`video height : ${videoRef.current.height}`);
      DBGMSG(`video width : ${videoRef.current.width}`);
      DBGMSG(`video videoWidth : ${videoRef.current.videoWidth}`);
      DBGMSG(`video videoHeight : ${videoRef.current.videoHeight}`);

      // videoRef.current.addEventListener('onloadedmetadata', () => {
      // videoRef.current.addEventListener('onload', () => {
      videoRef.current.addEventListener('loadeddata', () => {
        DBGMSG(`video height : ${videoRef.current?.height}`);
        DBGMSG(`video width : ${videoRef.current?.width}`);
        DBGMSG(`video videoWidth : ${videoRef.current?.videoWidth}`);
        DBGMSG(`video videoHeight : ${videoRef.current?.videoHeight}`);
      });
    }
  }, [videoRef]);

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const render = () => {
    let img: string | undefined;
    let assetType: CbtAssetType_e | undefined;
    let audio: string | undefined;
    let video: string | undefined;

    if (!assetInfo && !playerSrc) {
      return null;
    }

    if (assetInfo) {
      assetType = assetInfo.assetType;
      if (assetInfo.attributeList) {
        img = Utils.svc.getReqImgAttrValue(assetInfo.attributeList);
      } else {
        img = assetInfo.assetMainImg;
      }
    } else {
      if (!playerSrc) return null;
      switch (playerSrc.kind) {
        case 'ReqImage':
          assetType = CbtAssetType_e.IMAGE;
          img = playerSrc.img;
          break;
        case 'ReqAudio':
          assetType = CbtAssetType_e.AUDIO;
          img = playerSrc.img;
          audio = playerSrc.audio;
          break;
        case 'ReqVideo':
          assetType = CbtAssetType_e.VIDEO;
          img = playerSrc.img;
          video = playerSrc.video;
          break;
      }
    }

    if (!assetType) return null;

    // 필수항목
    switch (assetType) {
      case CbtAssetType_e.IMAGE:
        if (!img) return null;
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              cursor: 'pointer',
              // ...devOutlineF,
            }}
            onClick={() => {
              DBGMSG(`onClick: ${img}`);
              // setFullScreenViewerOnOff(true);
              if (!img) return;
              onImgFullscreen && onImgFullscreen(img);
              // setFullScreenImgSrc(img);
              setFullImgViewer({ isOpen: true, src: img });
            }}
          >
            <ImgAdv
              src={[img, undefined, undefined]}
              containerStyle={{
                flex: '0 0 auto',
                // ...devOutlineF,
              }}
              imgStyle={{
                width: width,
                height: height,
                overflow: 'hidden',
                objectFit: 'contain',
                // ...devOutlineF,
              }}
            />

            {!disableFullScreenImg && (
              <Img src={[R.images.common_ic_enlarge3x, ICON_SIZE, ICON_SIZE]} containerStyle={{ position: 'absolute', right: 4, top: 4 }} />
            )}
          </FlexRowDiv>
        );
      case CbtAssetType_e.VIDEO:
        if (!video) return null;
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            {/* <Img
              src={[img, undefined, undefined]}
              containerStyle={{
                flex: '0 0 auto',
                // ...devOutlineF,
              }}
              imgStyle={{
                width: width,
                height: height,
                overflow: 'hidden',
                objectFit: 'contain',
                // ...devOutlineF,
              }}
              onClick={() => {}}
            /> */}
            {/* 비디오 아이콘 */}
            <video ref={videoRef} src={video} controls width={width} height={height} poster={img} style={{ outline: 'none', objectFit: 'contain' }} />
          </FlexRowDiv>
        );
      case CbtAssetType_e.AUDIO:
        // if (!audio) return null;
        // 502 : 346 = W : w
        // 346W/502=w
        const audioCoverImgSize = (346 * width) / 502;

        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              alignItems: 'center',
              justifyContent: 'flex-start',
              transform: `scale(${scaleIfAudio},${scaleIfAudio})`,
              // ...devOutlineF,
            }}
            onClick={() => {}}
          >
            {/* 배경 이미지 */}
            <Img
              src={[R.images.asset_detail_iv_audio3x, width, height]}
              // src={[R.images.creator_iv_audio3x, width, height]}
              containerStyle={{
                position: 'absolute',
                // ...devOutlineF,
              }}
            />
            {/* 커버이미지 */}
            {img ? (
              <ImgAdv
                src={[img, undefined, undefined]}
                containerStyle={{
                  backgroundColor: 'white',
                  borderWidth: '0.5px',
                  borderStyle: 'solid',
                  borderColor: R.colors.line,
                  // marginLeft: 6,
                  // ...devOutlineF,
                }}
                imgStyle={{
                  width: audioCoverImgSize,
                  height: audioCoverImgSize,
                  objectFit: 'contain',
                }}
              />
            ) : (
              <FlexColumnDiv
                style={{
                  width: audioCoverImgSize,
                  height: audioCoverImgSize,
                  backgroundColor: R.colors.bgGrey,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: '0.5px',
                  borderStyle: 'solid',
                  borderColor: R.colors.line,
                }}
              >
                <TextCon text={hR.strings.AC_ASSET_CREATE_PREVIEW} size={12} isBold color={R.colors.cloudyBlue} />
              </FlexColumnDiv>
            )}
            {audio && <audio src={audio} controls style={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', outline: 'none' }} />}
          </FlexRowDiv>
        );
    }
  };

  return (
    <>
      {render()}
      {/* 전체보기 */}
      {sFullImgViewer.isOpen && sFullImgViewer.src && (
        <Lightbox wrapperClassName="custom_modal_image" mainSrc={sFullImgViewer.src} onCloseRequest={() => setFullImgViewer({ isOpen: false })} />
      )}
    </>
  );
}
