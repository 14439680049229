/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useEffect, useMemo } from 'react';
import { config, useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import AnimatedText from 'src/ui/base_component/AnimatedText';
import { devOutlineF } from 'src/ui/base_component/etc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { Menu } from 'src/ui/common_component/CommonHeader';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  menu: Menu;
  curMenu: Menu | undefined;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function MenuBtn({ menu, curMenu, onClick }: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  const menuTxt = useMemo(() => {
    switch (menu) {
      case Menu.Explorer:
        return hR.strings.HEADER_MENU_EXPLORE;
      case Menu.AssetCreator:
        return hR.strings.HEADER_MENU_ASSET_CREATOR;
      case Menu.Marketplace:
        return hR.strings.HEADER_MENU_MARKET_PLACE;
    }
  }, [hR.strings.HEADER_MENU_ASSET_CREATOR, hR.strings.HEADER_MENU_EXPLORE, hR.strings.HEADER_MENU_MARKET_PLACE, menu]);

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    if (isActive) set({ color: R.colors.black });
    else set({ color: R.colors.veryLightGrey });

    return () => {};
  }, [curMenu]);

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  const isActive = curMenu === menu;

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    // return { color: R.colors.textMouseLeave, config: { duration: 250 } };
    if (isActive) {
      return {
        from: {
          color: R.colors.black as string,
        },
        // config: { ...config.gentle },
      };
    } else {
      return {
        from: {
          color: R.colors.veryLightGrey as string,
        },
        // config: { ...config.gentle },
      };
    }
  });

  return (
    <>
      <AnimatedText
        text={menuTxt}
        size={16}
        textStyle={hMouseProps}
        isBold
        // useSelectNone
        containerStyle={{
          padding: 20,
          cursor: 'pointer',
          justifyContent: 'center',
          // ...devOutlineF,
        }}
        onConMouseEnter={() => {
          // DBGMSG(`onMouseEnter: ${menu}`);
          !isActive && set({ color: R.colors.black });
        }}
        onConMouseLeave={() => {
          // DBGMSG(`onMouseLeave: ${menu}`);
          !isActive && set({ color: R.colors.veryLightGrey });
        }}
        onConClick={(e) => {
          DBGMSG(`onClick: ${menu}`);
          // !isActive && onClick && onClick(e);
          onClick && onClick(e);
        }}
      />
    </>
  );
}
