/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ScreenInfo from 'src/context/screen_constants';
import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { CbtSmartContractStatus, useKaikas } from 'src/hooks/useKaikas';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { CbtCurrency_e, ServerECode_e } from 'src/model/rpcModel';
import { RPC_MP_SALE_PURCHASE_VIEW } from 'src/model/rpcType';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { LabelValue } from 'src/ui/common_component/LabelValue';
import { LabelValueMint } from 'src/ui/common_component/LabelValueMint';
import { CommonColumnBox, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
// DESKTOP
const DT_DLG_WIDTH = 888;
const DT_HPADDING = 25;
const DT_BODY_WIDTH = DT_DLG_WIDTH - DT_HPADDING * 2;
const DT_PADDING_TOP = 30;
const DT_PADDING_BOTTOM = 40;
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;
const DT_TITLE_HEIGHT = 33;
const DT_TITLE_FOTNSIZE = 22;
const DT_TITLE_MARGIN_BOTTOM = 40 - 15;
const DT_ASSET_DISPLAY_BORDER_BOX = 276;
const DT_ASSET_DISPLAY_BORDER_BOX_MARGIN = 15;
const DT_ASSET_DISPLAY_SIZE = 236;
const DT_LEFT_CONTAINER_WIDTH = DT_ASSET_DISPLAY_BORDER_BOX + DT_ASSET_DISPLAY_BORDER_BOX_MARGIN * 2;
const DT_LEFT_CONTAINER_HEIGHT = DT_ASSET_DISPLAY_BORDER_BOX + DT_ASSET_DISPLAY_BORDER_BOX_MARGIN * 2;
const DT_FORM_FONT_SIZE = 14;
const DT_FORM_INPUT_WIDTH = 502;
const DT_FORM_INPUT_HMARGIN = 15;

const DT_RIGHT_CONTAINER_WIDTH = DT_FORM_INPUT_WIDTH + DT_FORM_INPUT_HMARGIN * 2;
const DT_RIGHT_CONTAINER_HEIGHT = undefined;
const DT_BOX_PADDING = 24;
const DT_BETWEEN_TEXT_BOX = 24;
const DT_KEY_WIDTH = 152;
const DT_VALUE_WIDTH = 302;
const DT_BTN_WIDTH = 300;
const DT_BTN_HEIGHT = 48;
const DT_BTN_FOTNSIZE = 14;
const DT_CONTAINER_BETWEEN = 0;
const DT_KLAYTN_ADDRESS_MARGIN_BOTTOM = 30;
const DT_COL_IC_SIZE = 24;

// MOBILE
const MB_DLG_WIDTH = 280;
const MB_HPADDING = 12;
const MB_BODY_WIDTH = MB_DLG_WIDTH - MB_HPADDING * 2;
const MB_PADDING_TOP = 16;
const MB_PADDING_BOTTOM = 20;
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;
const MB_TITLE_HEIGHT = 20;
const MB_TITLE_FOTNSIZE = 14;
const MB_TITLE_MARGIN_BOTTOM = 16;
const MB_ASSET_DISPLAY_BORDER_BOX = 158;
const MB_ASSET_DISPLAY_BORDER_BOX_MARGIN = 0;
const MB_ASSET_DISPLAY_SIZE = 134;
const MB_LEFT_CONTAINER_WIDTH = 158;
const MB_LEFT_CONTAINER_HEIGHT = 158;
const MB_FORM_FONT_SIZE = 10;
const MB_FORM_INPUT_WIDTH = 256;
const MB_RIGHT_CONTAINER_WIDTH = MB_FORM_INPUT_WIDTH;
const MB_RIGHT_CONTAINER_HEIGHT = undefined;
const MB_BOX_PADDING = 16;
const MB_BETWEEN_TEXT_BOX = 16;
const MB_KEY_WIDTH = 100;
const MB_VALUE_WIDTH = 124;
const MB_BTN_WIDTH = 256;
const MB_BTN_HEIGHT = 38;
const MB_BTN_FOTNSIZE = 12;
const MB_CONTAINER_BETWEEN = 16;
const MB_KLAYTN_ADDRESS_MARGIN_BOTTOM = 20;
const MB_COL_IC_SIZE = 20;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const HPADDING = ScreenInfo.isMobile() ? MB_HPADDING : DT_HPADDING;
const BODY_WIDTH = ScreenInfo.isMobile() ? MB_BODY_WIDTH : DT_BODY_WIDTH;
const PADDING_TOP = ScreenInfo.isMobile() ? MB_PADDING_TOP : DT_PADDING_TOP;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
const CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;
const TITLE_HEIGHT = ScreenInfo.isMobile() ? MB_TITLE_HEIGHT : DT_TITLE_HEIGHT;
const TITLE_FOTNSIZE = ScreenInfo.isMobile() ? MB_TITLE_FOTNSIZE : DT_TITLE_FOTNSIZE;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const ASSET_DISPLAY_BORDER_BOX = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX : DT_ASSET_DISPLAY_BORDER_BOX;
const ASSET_DISPLAY_BORDER_BOX_MARGIN = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX_MARGIN : DT_ASSET_DISPLAY_BORDER_BOX_MARGIN;
const ASSET_DISPLAY_SIZE = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_SIZE : DT_ASSET_DISPLAY_SIZE;
const LEFT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_WIDTH : DT_LEFT_CONTAINER_WIDTH;
const LEFT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_HEIGHT : DT_LEFT_CONTAINER_WIDTH;
const LV_TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_FORM_FONT_SIZE : DT_FORM_FONT_SIZE;

const RIGHT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_WIDTH : DT_RIGHT_CONTAINER_WIDTH;
const RIGHT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_HEIGHT : DT_RIGHT_CONTAINER_HEIGHT;
const BOX_PADDING = ScreenInfo.isMobile() ? MB_BOX_PADDING : DT_BOX_PADDING;
const BETWEEN_TEXT_BOX = ScreenInfo.isMobile() ? MB_BETWEEN_TEXT_BOX : DT_BETWEEN_TEXT_BOX;
const KEY_WIDTH = ScreenInfo.isMobile() ? MB_KEY_WIDTH : DT_KEY_WIDTH;
const LV_VALUE_WIDTH = ScreenInfo.isMobile() ? MB_VALUE_WIDTH : DT_VALUE_WIDTH;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_FOTNSIZE = ScreenInfo.isMobile() ? MB_BTN_FOTNSIZE : DT_BTN_FOTNSIZE;
const CONTAINER_BETWEEN = ScreenInfo.isMobile() ? MB_CONTAINER_BETWEEN : DT_CONTAINER_BETWEEN;
const KLAYTN_ADDRESS_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_KLAYTN_ADDRESS_MARGIN_BOTTOM : DT_KLAYTN_ADDRESS_MARGIN_BOTTOM;
const COL_IC_SIZE = ScreenInfo.isMobile() ? MB_COL_IC_SIZE : DT_COL_IC_SIZE;

export type BuyJsxProps = {
  sale_id: number;
  onSuccess: () => void;
  onClose: () => void;
};

export function BuyJsx(props: BuyJsxProps) {
  /**************************************
   * !! state
   **************************************/
  const [sFetched, setFetched] = useState<RPC_MP_SALE_PURCHASE_VIEW.Rx>();

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hDlg = useDlg();
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();
  const hKaikas = useKaikas();
  const hRpc = useRpc();
  const hKaikasSvc = useKaikasSvc();
  const hHistory = useHistory();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    fetchData();
  }, [props.sale_id]);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async () => {
    hDlgFactory.showLoading();
    try {
      const res = await hRpc.cobaltRpc.rpcMpSalePurchaseView({ sale_id: props.sale_id });
      setFetched(res);
    } catch (err) {
      DBGMSG('BuyJsx:', err);
      hRpc.hanleCommonError({err});
      props.onClose();
    } finally {
      hDlgFactory.hideLoading();
    }
  };

  const handleBuyBtn = async () => {
    if (!sFetched) return;

    if (sFetched.prc_info.curr_info.currency === CbtCurrency_e.KLAY) {
      hDlgFactory.showRunningSmartContract();

      // 사용자가 수동으로 카이카스를 lock한 경우 대응
      await hKaikasSvc.kaikasConnectAndRewardAddrReg();

      // 스마트 컨트랙트 실행
      const ret = await excuteSmartContract();

      hDlgFactory.hideRunningSmartContract();

      if (ret) {
        hDlgFactory.showBuyResult({
          isSucc: true,
          onOk: () => {
            hHistory.replace(Nav.MarketPlaceScreen);
          },
        });
        props.onSuccess();
      } else {
        hDlgFactory.showBuyResult({ isSucc: false, onOk: () => {} });
      }
      props.onClose();
    } else if (sFetched.prc_info.curr_info.currency === CbtCurrency_e.cPOINT) {
      props.onClose();
      hDlgFactory.showPurchasePageMove();
      setTimeout(() => {
        hDlgFactory.hidePurchasePageMove();

        // 별도의 결제화면에서 처리함
        hHistory.push(Nav.brewMarketPayment({ sale_id: sFetched.sale_id }));
      }, 2000);
    } else {
      DBGMSGW('what');
    }
  };

  const getPriceTxt = () => {
    if (!sFetched) return;

    const price = Utils.currency.getPriceForDP({
      prcInfo: sFetched.prc_info,
      decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
    });

    let aboutPrice: string = '';
    if (sFetched.prc_info.sub_price_list && sFetched.prc_info.sub_price_list.length !== 0) {
      aboutPrice = Utils.currency.getPriceForDP({ prcInfo: sFetched.prc_info.sub_price_list[0], decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT });
    }

    switch (sFetched.prc_info.curr_info.currency) {
      case CbtCurrency_e.KLAY:
        return `${price} ${R.strings.CURRENCY_KLAY} (≈ ${aboutPrice} ${R.strings.CURRENCY_CUSD})`;
      case CbtCurrency_e.cPOINT:
        return `${price} ${R.strings.CURRENCY_CUSD} (${aboutPrice} ${R.strings.CURRENCY_CPOINT})`;
      default:
        return '';
    }
  };

  const excuteSmartContract = async () => {
    if (!sFetched) return;

    DBGMSG(`$$$$SMARTCONTRACT`);
    const asset_key = sFetched.ast_info.asset_key;
    const sale_id = sFetched.sale_id;
    const prc_info = sFetched.prc_info;

    let tx_id: string | undefined = undefined;
    let blTxHash: string | undefined = undefined;
    try {
      // 1. 트랜잭션 시작 uuid 취득
      const purchaseBeginRes = await hRpc.cobaltRpc.rpcMpSalePurchaseBegin({ sale_id, prc_info });
      DBGMSG(purchaseBeginRes.tx_id);
      tx_id = purchaseBeginRes.tx_id;

      // 2. 스마트 컨트랙트 시작
      blTxHash = await hKaikas.buyItemUsingCobaltContract({
        pebString: sFetched.prc_info.price,
        assetKey: asset_key,
        uuid: tx_id,
      });
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        switch (errinfo.ecode) {
          case ServerECode_e.INVALID_KLAYTN_ADDRESS:
            hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
            break;
          default:
            break;
        }
      }
    }

    if (!tx_id) {
      DBGMSGW(`tx_id is undefined`);
      return false;
    }
    if (!blTxHash) {
      DBGMSGW(`blTxHash is undefined`);
      return false;
    }

    // 3. 성공여부 알림
    await hRpc.cobaltRpc.rpcMpSalePurchaseEnd({
      sale_id,
      tx_id,
      status: CbtSmartContractStatus.SUCCESS,
      bl_tx_hash: blTxHash,
    });
    return true;
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  if (!sFetched) {
    return null;
  }

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        alignItems: 'center',
        paddingLeft: HPADDING,
        paddingRight: HPADDING,
        paddingTop: PADDING_TOP,
        paddingBottom: PADDING_BOTTOM,
        // ...devOutlineFB,
      }}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      {/* close 버튼 */}
      <ImgBtn
        src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
        containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
        onClick={() => {
          props.onClose();
        }}
      />

      {/* 타이틀 */}
      <TextCon text={hR.strings.DLG_BUY_TITLE} size={TITLE_FOTNSIZE} color={R.colors.black} containerStyle={{ height: TITLE_HEIGHT }} isBold />

      <HEIGHT size={TITLE_MARGIN_BOTTOM} />

      <FlexRowDiv
        style={{
          alignSelf: 'flex-start',
          flexWrap: 'wrap',
          width: BODY_WIDTH,
          alignItems: 'flex-start',
          justifyContent: 'center',
          //
          // ...devOutlineFB,
        }}
      >
        {/* left */}
        <FlexDiv
          style={{
            width: LEFT_CONTAINER_WIDTH,
            height: LEFT_CONTAINER_HEIGHT,
            marginBottom: CONTAINER_BETWEEN,
            // ...devOutlineFG,
          }}
        >
          {/* 에셋 이미지 */}
          <CommonColumnBox
            style={{
              width: ASSET_DISPLAY_BORDER_BOX,
              height: ASSET_DISPLAY_BORDER_BOX,
              justifyContent: 'center',
              alignItems: 'center',
              margin: ASSET_DISPLAY_BORDER_BOX_MARGIN,
              marginTop: ScreenInfo.isMobile() ? undefined : 0,
              // ...devOutlineFG,
            }}
          >
            <AssetDisplayThumbReqattr
              playerSrc={Utils.svc.brewAssetDisplayThumbReqattr({
                assetType: sFetched.ast_info.schm_info.asset_type,
                img: sFetched.ast_info.nft_img,
              })}
              width={ASSET_DISPLAY_SIZE}
              height={ASSET_DISPLAY_SIZE}
              whereToUse={WhereToUse_e.ForDlg}
            />
          </CommonColumnBox>
        </FlexDiv>

        {/* right */}
        <FlexColumnDiv
          style={{
            width: RIGHT_CONTAINER_WIDTH,
            height: RIGHT_CONTAINER_HEIGHT,
            // ...devOutlineFB,
          }}
        >
          <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDTH, paddingTop: BOX_PADDING, paddingBottom: BOX_PADDING }}>
            {/* 판매 아이디 */}
            <TextCon
              text={`${sFetched.sale_id}`}
              size={LV_TITLE_FONTSIZE}
              color={R.colors.black}
              isBold
              containerStyle={{ paddingLeft: BOX_PADDING, paddingRight: BOX_PADDING }}
            />
            <HEIGHT size={BETWEEN_TEXT_BOX} />
            <HLINE size={'100%'} />
            <HEIGHT size={BETWEEN_TEXT_BOX} />
            <FlexColumnDiv
              style={{
                paddingLeft: BOX_PADDING,
                paddingRight: BOX_PADDING,
                // ...devOutlineFB
              }}
            >
              {/* 컬렉션 */}
              <FlexRowDiv>
                <TextCon
                  text={hR.strings.DLG_BUY_COLLECTION}
                  size={LV_TITLE_FONTSIZE}
                  color={R.colors.blueGrey}
                  containerStyle={{
                    width: KEY_WIDTH,
                    // ...devOutlineF
                  }}
                />
                <FlexRowDiv style={{ width: LV_VALUE_WIDTH }}>
                  <Img src={[R.images.common_ic_verify3x, COL_IC_SIZE, COL_IC_SIZE]} />
                  <WIDTH size={6} />
                  <TextCon
                    text={sFetched.ast_info.col_info.dp_name}
                    isBold
                    size={LV_TITLE_FONTSIZE}
                    color={R.colors.purpleishBlue}
                    ellipsisWidth={LV_VALUE_WIDTH - COL_IC_SIZE - 6}
                  />
                </FlexRowDiv>
              </FlexRowDiv>
              <HEIGHT size={BETWEEN_TEXT_BOX} />
              {/* 에셋 이름 */}
              <LabelValue
                title={hR.strings.DLG_BUY_ASSET_NAME}
                value={sFetched.ast_info.nft_name}
                titleSize={LV_TITLE_FONTSIZE}
                titleWidth={KEY_WIDTH}
                valueSize={LV_TITLE_FONTSIZE}
                valueWidth={LV_VALUE_WIDTH}
              />
              <HEIGHT size={BETWEEN_TEXT_BOX} />
              {/* 발행매수 */}
              <LabelValueMint
                title={hR.strings.DLG_BUY_MINT_CNT}
                value={{
                  nOf: sFetched.ast_info.mint_no,
                  total: sFetched.ast_info.issue_tcnt,
                  can_addition: sFetched.ast_info.can_addition,
                  mintMore: `${sFetched.ast_info.can_addition ? `(${hR.strings.COMMON_ASSET_MINT_MORE})` : ''}`,
                }}
                titleSize={LV_TITLE_FONTSIZE}
                titleWidth={KEY_WIDTH}
                value1Size={LV_TITLE_FONTSIZE}
                value2Size={LV_TITLE_FONTSIZE}
                valueWidth={LV_VALUE_WIDTH}
              />
              <HEIGHT size={BETWEEN_TEXT_BOX} />
              {/* 스키마 */}
              <LabelValue
                title={hR.strings.DLG_BUY_SCHEMA}
                value={sFetched.ast_info.schm_info.dp_name}
                titleSize={LV_TITLE_FONTSIZE}
                titleWidth={KEY_WIDTH}
                valueSize={LV_TITLE_FONTSIZE}
                valueWidth={LV_VALUE_WIDTH}
                valueColor={R.colors.purpleishBlue}
              />
              <HEIGHT size={BETWEEN_TEXT_BOX} />
              {/* 판매자 */}
              <LabelValue
                title={hR.strings.DLG_BUY_SELLER}
                value={sFetched.seller}
                titleSize={LV_TITLE_FONTSIZE}
                titleWidth={KEY_WIDTH}
                valueSize={LV_TITLE_FONTSIZE}
                valueWidth={LV_VALUE_WIDTH}
                valueColor={R.colors.purpleishBlue}
              />
              <HEIGHT size={BETWEEN_TEXT_BOX} />
              {/* 가격 */}
              <LabelValue
                title={hR.strings.DLG_BUY_PRICE}
                value={getPriceTxt()}
                titleSize={LV_TITLE_FONTSIZE}
                titleWidth={KEY_WIDTH}
                valueSize={LV_TITLE_FONTSIZE}
                valueWidth={LV_VALUE_WIDTH}
              />
            </FlexColumnDiv>
          </CommonColumnBox>
          <HEIGHT size={KLAYTN_ADDRESS_MARGIN_BOTTOM} />

          {/* 구매하기 버튼 */}
          <RoundTxtBtn
            height={BTN_HEIGHT}
            width={BTN_WIDTH}
            fontSize={BTN_FOTNSIZE}
            // text={hR.strings.MARKET_ASSET_READ_DLG_BTN.replace(
            //   R.strings.PLACEHOLDER_PRICE,
            //   `${Utils.currency.getPriceForDP({
            //     prcInfo: sFetched.prc_info,
            //   })} ${R.strings.getCurrencyUnit(sFetched.prc_info.curr_info.currency)}`
            // )}
            text={hR.strings.DLG_BUY_BTN}
            containerStyle={{ alignSelf: 'center' }}
            onClick={async () => {
              handleBuyBtn();
            }}
          />
        </FlexColumnDiv>
      </FlexRowDiv>
    </FlexColumnDiv>
  );
}
