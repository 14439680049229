/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties } from 'react';
import { devOutlineF, FlexRowDiv, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { PriceInfo_i } from 'src/model/model';
import R from 'src/res/R';
import { CbtCurrency_e, CbtResPriceInfo_i } from 'src/model/rpcModel';
import { Utils } from 'src/util/utils';
import ScreenInfo from 'src/context/screen_constants';
import { CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const DT_PRICE_FONTSIZE = 22;
const DT_PRICESUB_FONTSIZE = 14;
const MB_PRICE_FONTSIZE = 14;
const MB_PRICESUB_FONTSIZE = 10;
const DT_KLAY_IC_WIDTH = 18;
const DT_KLAY_IC_HEIGHT = 20;
const MB_KLAY_IC_WIDTH = 16;
const MB_KLAY_IC_HEIGHT = 18;

const KLAY_IC_WIDTH = ScreenInfo.isMobile() ? MB_KLAY_IC_WIDTH : DT_KLAY_IC_WIDTH;
const KLAY_IC_HEIGHT = ScreenInfo.isMobile() ? MB_KLAY_IC_HEIGHT : DT_KLAY_IC_HEIGHT;

const PRICE_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_FONTSIZE : DT_PRICE_FONTSIZE;
const PRICESUB_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICESUB_FONTSIZE : DT_PRICESUB_FONTSIZE;

type Props = {
  prc_info: CbtResPriceInfo_i;
  icSizeWidth?: number;
  icSizeHeight?: number;
  priceFontSize?: number;
  unitFontSize?: number;
  isBold?: boolean;
  containerStyle?: CSSProperties;
};

export function PriceAdv({
  //
  prc_info,
  icSizeWidth = KLAY_IC_WIDTH,
  icSizeHeight = KLAY_IC_HEIGHT,
  priceFontSize = PRICE_FONTSIZE,
  unitFontSize = PRICESUB_FONTSIZE,
  isBold = true,
  containerStyle,
}: Props) {
  const getUsdDollar = () => {
    if (!prc_info.sub_price_list) return '';
    if (prc_info.sub_price_list.length === 0) return '';
    if (prc_info.sub_price_list[0].curr_info.currency !== CbtCurrency_e.USD) return '';

    return `(≈ ${Utils.currency.getPriceForDP({ prcInfo: prc_info.sub_price_list[0], decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${R.strings.CURRENCY_CUSD})`;
  };
  return (
    <FlexRowDiv
      style={{
        // width: MARKET_ASSET_CARD_BODY_WIDTH,
        // height: PRICE_HEIGHT,
        alignItems: 'center',
        // ...devOutlineF,
        ...containerStyle,
      }}
    >
      {/* 화폐 아이콘 */}
      <Img src={[Utils.svc.getCurrencyDpInfo(prc_info.curr_info.currency).icSrc, icSizeWidth, icSizeHeight]} />
      <WIDTH size={3} />

      {/* 가격 (달러) */}
      <FlexRowDiv
        style={{
          alignItems: 'baseline',
          ...devOutline,
        }}
      >
        <TextCon
          // color={assetInfo.priceInfo.currency === CbtCurrency_e.ETH ? R.colors.purpleishBlue : R.colors.klaytn}
          color={R.colors.black}
          isBold={isBold}
          size={priceFontSize}
          text={`${Utils.currency.getPriceForDP({ prcInfo: prc_info })}`}
        />
        <WIDTH size={3} />
        <TextCon
          // color={assetInfo.priceInfo.currency === CbtCurrency_e.ETH ? R.colors.purpleishBlue : R.colors.klaytn}
          color={R.colors.black}
          isBold={isBold}
          size={unitFontSize}
          text={Utils.svc.getCurrencyDpInfo(prc_info.curr_info.currency).unit}
        />
        <WIDTH size={3} />
        {prc_info.curr_info.currency === CbtCurrency_e.KLAY && (
          <TextCon
            //
            color={R.colors.black}
            size={unitFontSize}
            text={getUsdDollar()}
          />
        )}
      </FlexRowDiv>
    </FlexRowDiv>
  );
}
