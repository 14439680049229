/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { ExplorerScreen_i } from 'src/model/model';
import {
  CbtAssetCardInfo_i,
  CbtAssetGroupCardInfo_i,
  CbtAssetType_e,
  CbtAttributeInfoFull_i,
  CbtAttributeInfo_i,
  CbtAttributeValue_i,
  CbtColCertifyType_e,
  CbtCollectionCardInfo_i,
  CbtCollectionDetailInfo_i,
  CbtCreationStatus_e,
  CbtCurrencyInfo_i,
  CbtEditionType_e,
  CbtInqueryType_e,
  CbtIssueType_e,
  CbtMarketSearchCurrencyFilter_t,
  CbtPayoutAccountStatus_e,
  CbtPayoutFeeType_e,
  CbtPgInfo_i,
  CbtPG_e,
  CbtPrd_i,
  CbtReportReason_e,
  CbtReqPriceInfo_i,
  CbtResPriceInfo_i,
  CbtSchemaCardInfo_i,
  CbtSchemaDetailInfo_i,
  CbtTxStatus_e,
  CbtUserInfo_i,
  CbtUserLanguage_e,
  CommonRx_i,
  REQ_METHOD,
} from 'src/model/rpcModel';
import { CbtStompMsgCode_e, CbtStompMsgType_e } from 'src/rx/messageService';

// ______________________________________________________
// ____/\\\\\\\\\______/\\\\\\\\\\\\\__________/\\\\\\\\\_
// ___/\\\///////\\\___\/\\\/////////\\\_____/\\\////////__
// ___\/\\\_____\/\\\___\/\\\_______\/\\\___/\\\/___________
// ____\/\\\\\\\\\\\/____\/\\\\\\\\\\\\\/___/\\\_____________
// _____\/\\\//////\\\____\/\\\/////////____\/\\\_____________
// ______\/\\\____\//\\\___\/\\\_____________\//\\\____________
// _______\/\\\_____\//\\\__\/\\\______________\///\\\__________
// ________\/\\\______\//\\\_\/\\\________________\////\\\\\\\\\_
// _________\///________\///__\///____________________\/////////__
// ________________________________________________________________
/**
 * [02.01.01] 가입을 위해 사용할 이메일 인증 : 인증 코드 받기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699191
 */
export namespace RPC_USER_CERTIFY_EMAIL {
  export const url = '/user/certify/email';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    email: string;
    lang: CbtUserLanguage_e;
    type?: 0 | 1; // 0 회원 가입을 위한 인증 , 1 인출하기 PayPal 이메일 인증
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.02] 가입을 위해 사용할 이메일 인증 : 메일로 전송받은 인증 코드 확인
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699207
 */
export namespace RPC_USER_CERTIFY_EMAIL_TRY {
  export const url = '/user/certify/email/try';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    email: string;
    auth_code: string;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.03] 회원 가입
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699354
 */
export namespace RPC_USER_SIGNUP {
  export const url = '/user/signup';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    email: string;
    password: string;
    ap_cd: string; // anti fishing
    lang: CbtUserLanguage_e;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.04] 로그인
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699433
 */
export namespace RPC_USER_LOGIN {
  export const url = '/user/login';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    email: string;
    password: string;
    lang: CbtUserLanguage_e;
  }

  export interface Rx extends CommonRx_i {
    banned: boolean;
  }
}

/**
 * [02.01.05] 임시 비밀번호 발급
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699464
 */
export namespace RPC_USER_PASSWORD_TEMP {
  export const url = '/user/password/temp';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    email: string;
    lang: CbtUserLanguage_e;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.06] 사용자 계정 관리 : 비밀번호 변경
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699487
 */
export namespace RPC_USER_ACCOUNT_PASSWORD_RESET {
  export const url = '/user/account/password/reset';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    old_password: string;
    new_password: string;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.07] 사용자 계정 관리 : 피싱방지코드 변경
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96306733
 */
export namespace RPC_USER_ACCOUNT_ANTIPHISHING_RESET {
  export const url = '/user/account/antiphishing/reset';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    ap_cd: string;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.08] 사용자 계정 관리 : 입금용 Klaytn계좌 설정
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96306735
 */
export namespace RPC_USER_ACCOUNT_REWARDADDR_SET {
  export const url = '/user/account/rewardaddr/set';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    reward_addr: string | null;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.09] 사용자 보안 : 패스워드 검증
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96306872
 */
export namespace RPC_USER_ACCOUNT_PASSWORD_CHECK {
  export const url = '/user/account/password/check';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    password: string;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.10] 사용자 설정 언어 저장(/user/account/lang/set/{lang})
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98600571
 */
export namespace RPC_USER_ACCOUNT_LANG_SET {
  export const url = '/user/account/lang/set';
  export const method = REQ_METHOD.GET;

  export interface Tx {
    lang: CbtUserLanguage_e;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.01.11] 사용자 변경 가능 정보 획득(/user/account/info/mutable)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98629150
 */
export namespace RPC_USER_ACCOUNT_INFO_MUTABLE {
  export const url = '/user/account/info/mutable';
  export const method = REQ_METHOD.GET;

  export interface Tx {}

  export interface Rx extends CommonRx_i {
    prof: string | null;
    email: string;
    anti_phishing_code: string;
    scope_url: string;
    reward_addr: string | null | '';
    balance_info: {
      cpoint: CbtResPriceInfo_i;
      cusd: CbtResPriceInfo_i;
      cblt: CbtResPriceInfo_i;
      klay: CbtResPriceInfo_i;
    };
    payout_account: {
      payout_id: string;
      status: CbtPayoutAccountStatus_e; // 0 미인증, 10 이메일 인증 완료, 20 출금 계좌 확인 완료 ( 이 상태만 확인 완료 표시 )
    } | null;
  }
}

/**
 * [02.01.12] 유저 알림 내역 ( /user/account/message/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101005627
 */
export namespace RPC_USER_ACCOUNT_MESSAGE_LIST {
  export const url = '/user/account/message/list';
  export const method = REQ_METHOD.GET;

  export interface Tx {}

  export interface Rx extends CommonRx_i {
    message_list: {
      type: CbtStompMsgType_e;
      code: CbtStompMsgCode_e;
      content: string;
      time: number;
    }[];
  }
}

export enum CbtPropQueryType_e {
  All, // 전체
  Income, // 충전내역
  Spend, // 사용내역
}

export enum CbtPropType_e {
  Income = 1, // 충전
  spend = 2, // 사용
}

export enum CbtPropTypeDetail_e {
  T101 = 101, // 에셋 구매
  T102 = 102, // Paymentwall Cash Out
  T103 = 103, // cash out 출금 수수료
  T201 = 201, // 에셋 판매 수익
  T202 = 202, // 에셋 판매 창작자 인세 수익
  T203 = 203, // 에셋 판매 리워드 (CBLT)
  T204 = 204, // 에셋 구매 리워드(CBLT)
  T205 = 205, // 에셋 판매 창작자 리워드(CBLT)
  T301 = 301, // POINT 충전 (paymentwall)
  T302 = 302, // Admin으로부터 보상 POINT 충전
  T303 = 303, // Admin 테스트 충전
}

/**
 * [02.01.13] Point History ( /user/account/point/history )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101010665
 */
export namespace RPC_USER_ACCOUNT_POINT_HISTORY {
  export const url = '/user/account/point/history';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    type?: CbtPropQueryType_e;
    start: number;
    end: number;
  }

  export interface Rx extends CommonRx_i {
    balance: CbtResPriceInfo_i;
    income: CbtResPriceInfo_i;
    spend: CbtResPriceInfo_i;
    pg_info: CbtPgInfo_i;
    type: CbtPropQueryType_e;
    start: number;
    end: number;
    list: {
      type: CbtPropType_e;
      time: number;
      quantity: CbtResPriceInfo_i;
      ok: boolean;
      data: {
        type: CbtPropTypeDetail_e;
        txid: string;
      };
    }[];
  }
}

/**
 * [02.01.14] USD History ( /user/account/usd/history )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101013781
 */
export namespace RPC_USER_ACCOUNT_USD_HISTORY {
  export const url = '/user/account/usd/history';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    type?: CbtPropQueryType_e;
    start: number;
    end: number;
  }

  export interface Rx extends CommonRx_i {
    balance: CbtResPriceInfo_i;
    income: CbtResPriceInfo_i;
    spend: CbtResPriceInfo_i;
    pg_info: CbtPgInfo_i;
    type: CbtPropQueryType_e;
    start: number;
    end: number;
    list: {
      type: CbtPropType_e;
      time: number;
      quantity: CbtResPriceInfo_i;
      ok: boolean;
      data: {
        type: CbtPropTypeDetail_e;
        txid: string;
      };
    }[];
  }
}

/**
 * [02.01.15] CBLT History ( /user/account/cblt/history )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101015416
 */
export namespace RPC_USER_ACCOUNT_CBLT_HISTORY {
  export const url = '/user/account/cblt/history';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    type?: CbtPropQueryType_e;
    start: number;
    end: number;
  }

  export interface Rx extends CommonRx_i {
    balance: CbtResPriceInfo_i;
    income: CbtResPriceInfo_i;
    spend: CbtResPriceInfo_i;
    pg_info: CbtPgInfo_i;
    type: CbtPropQueryType_e;
    start: number;
    end: number;
    list: {
      type: CbtPropType_e;
      time: number;
      quantity: CbtResPriceInfo_i;
      ok: boolean;
      data: {
        type: CbtPropTypeDetail_e;
        txid: string;
        tx_url: string;
      };
    }[];
  }
}

/**
 * [02.01.16] KLAY History ( /user/account/klay/history )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101015736
 */
export namespace RPC_USER_ACCOUNT_KLAY_HISTORY {
  export const url = '/user/account/klay/history';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    type?: CbtPropQueryType_e;
    start: number;
    end: number;
  }

  export interface Rx extends CommonRx_i {
    balance: CbtResPriceInfo_i;
    income: CbtResPriceInfo_i;
    spend: CbtResPriceInfo_i;
    pg_info: CbtPgInfo_i;
    type: CbtPropQueryType_e;
    start: number;
    end: number;
    list: {
      type: CbtPropType_e;
      time: number;
      quantity: CbtResPriceInfo_i;
      ok: boolean;
      data: {
        type: CbtPropTypeDetail_e;
        txid: string;
        tx_url: string;
      };
    }[];
  }
}

/**
 * [02.01.17_1] 인출 신청 ( /user/account/payout/request )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=103780183
 */
export namespace RPC_USER_ACCOUNT_PAYOUT_REQUEST {
  export const url = '/user/account/payout/request';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    amount: CbtReqPriceInfo_i;
    payout_id: string;
    fee_type: CbtPayoutFeeType_e;
    lang: CbtUserLanguage_e;
  }

  export interface Rx extends CommonRx_i {
    req_id: string;
  }
}

/**
 * [02.01.17_2] CBLT 수수료 인출 / 결과 전송 ( /user/account/payout/finrequest )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=103780229
 */
export namespace RPC_USER_ACCOUNT_PAYOUT_FINREQUEST {
  export const url = '/user/account/payout/finrequest';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    req_id: string;
    bl_tx_hash: string;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.02.01] asset collection 생성
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96311481
 */
export namespace RPC_ASSET_CREATOR_COLLECTION_ADD {
  export const url = '/asset/creator/collection/add';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    name: string;
    dp_img_url: string;
    dp_name: string;
    royalty: number;
    svc_url: string | null;
    desc: string | null;
    admin_list: string[];
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.02.02] asset schema 생성
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96316258
 */
export namespace RPC_ASSET_CREATOR_SCHEMA_ADD {
  export const url = '/asset/creator/schema/add';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    cid: number;
    name: string;
    dp_name: string;
    asset_type: CbtAssetType_e;
    attributes: CbtAttributeInfo_i[];
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.02.03] asset 생성
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96322400
 */
export namespace RPC_ASSET_CREATOR_ADD {
  export const url = '/asset/creator/add';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    supply: number;
    owner: string;
    issue_type: CbtIssueType_e;
    edition_type?: CbtEditionType_e;
    entity_id?: number;
    attr_values: CbtAttributeValue_i[];
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.02.04] 컬렉션 목록
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96336228
 */
export namespace RPC_ASSET_CREATOR_COLLECTION_LIST {
  export const url = '/asset/creator/collection/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    col_list: {
      cid: number;
      cert_tp: CbtColCertifyType_e;
      dp_name: string;
      dp_img_url: string;
      status?: CbtCreationStatus_e;
    }[];
    creatable: boolean;
    max_royalty: number;
  }
}

/**
 * [02.02.05] 컬렉션 보기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96336238
 */
export namespace RPC_ASSET_CREATOR_COLLECTION_VIEW {
  export const url = '/asset/creator/collection/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    cid: number;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    col_info: CbtCollectionDetailInfo_i;
    schm_list: CbtSchemaCardInfo_i[];
    creatable_schm: boolean;
    pg_info: CbtPgInfo_i;
  }
}

/**
 * [02.02.06_1] 컬렉션 편집 정보 보기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97587284
 */
export namespace RPC_ASSET_CREATOR_COLLECTION_EDIT_VIEW {
  export const url = '/asset/creator/collection/edit';
  export const method = REQ_METHOD.GET;

  export interface Tx {}

  export interface Rx extends CommonRx_i {
    col_info: CbtCollectionDetailInfo_i;
  }
}

/**
 * [02.02.06_2] 컬렉션 편집 하기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96336241
 */
export namespace RPC_ASSET_CREATOR_COLLECTION_EDIT {
  export const url = '/asset/creator/collection/edit';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    cid: number;
    dp_img_url?: string;
    dp_name?: string;
    svc_url?: string | null;
    desc?: string | null;
    admin_list?: string[];
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.02.07] 스키마 보기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97585009
 */
export namespace RPC_ASSET_CREATOR_SCHEMA_VIEW {
  export const url = '/asset/creator/schema/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    sid: number;
  }

  export interface Rx extends CommonRx_i {
    schm_info: CbtSchemaDetailInfo_i;
    astg_list: CbtAssetGroupCardInfo_i[];
    pg_info: CbtPgInfo_i;
  }
}

/**
 * [02.02.08_1] 스키마 편집 정보 보기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97585013
 */
export namespace RPC_ASSET_CREATOR_SCHEMA_EDIT_VIEW {
  export const url = '/asset/creator/schema/edit';
  export const method = REQ_METHOD.GET;

  export interface Tx {}

  export interface Rx extends CommonRx_i {
    schm_info: CbtSchemaDetailInfo_i;
  }
}

/**
 * [02.02.08_2] 스키마 편집 하기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97591847
 */
export namespace RPC_ASSET_CREATOR_SCHEMA_EDIT {
  export const url = '/asset/creator/schema/edit';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    dp_name: string;
    attributes: CbtAttributeInfo_i[];
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.02.09] 에셋 그룹의 자산 목록(/asset/creator/group/view)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97587750
 */
export namespace RPC_ASSET_CREATOR_GROUP_VIEW {
  export const url = '/asset/creator/group/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    entity_id: number;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    ast_list: CbtAssetCardInfo_i[];
    pg_info: CbtPgInfo_i;
    astg_info: {
      entity_id: number;
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      attr_list: CbtAttributeInfoFull_i[];
      can_addition: boolean;
      issue_tcnt: number;
    };
  }
}

/**
 * [02.02.10] 자산 상세보기(/asset/creator/view)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97589080
 */
export namespace RPC_ASSET_CREATOR_VIEW {
  export const url = '/asset/creator/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    entity_id: number;
    asset_id: number;
  }

  export interface Rx extends CommonRx_i {
    ast_info: {
      asset_id: number;
      asset_key: string;
      col_info: {
        cid: number;
        cert_tp: CbtColCertifyType_e;
        dp_name: string;
      };
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      attr_list: CbtAttributeInfoFull_i[];
      creator: CbtUserInfo_i;
      owner: CbtUserInfo_i;
      can_addition: boolean;
      mint_no: number;
      issue_tcnt: number;
      sale_id: number;
      blocked: boolean;
    };
  }
}

/**
 * [02.03.01_1] 판매 등록 팝업 표시 정보
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97602980
 */
export namespace RPC_MP_SALE_ADD_VIEW {
  export const url = '/mp/sale/add/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    entity_id: number;
    asset_id: number;
  }

  export interface Rx extends CommonRx_i {
    ast_info: {
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      creator: string;
      entity_id: number;
      asset_id: number;
      asset_key: string;
      nft_img: string;
    };
    curr_list: CbtCurrencyInfo_i[];
    fee_info: {
      cl_fdcm: number;
      cl_frt: number;
      pf_fdcm: number;
      pf_frt: number;
    };
  }
}

/**
 * [02.03.01_2] 판매 등록
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97602968
 */
export namespace RPC_MP_SALE_ADD {
  export const url = '/mp/sale/add';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    entity_id: number;
    asset_id: number;
    prc_info: CbtReqPriceInfo_i;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.03.02_1] 판매 편집 정보 보기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97602970
 */
export namespace RPC_MP_SALE_EDIT_VIEW {
  export const url = '/mp/sale/edit/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
  }

  export interface Rx extends CommonRx_i, RPC_MP_SALE_ADD_VIEW.Rx {
    prc_info: CbtReqPriceInfo_i;
  }
}

/**
 * [02.03.02_2] 판매 편집 하기
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97608296
 */
export namespace RPC_MP_SALE_EDIT {
  export const url = '/mp/sale/edit';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
    prc_info?: CbtReqPriceInfo_i;
    cancel_sale: boolean;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.03.03] 판매 상품 목록
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97611441
 */
export namespace RPC_MP_SALE_LIST {
  export const url = '/mp/sale/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    search?: string;
    cid?: number;
    currency?: CbtMarketSearchCurrencyFilter_t;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    sale_list: {
      sale_id: number;
      seller: string;
      prc_info: CbtResPriceInfo_i;
      ast_info: {
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
      };
      favorite: boolean;
    }[];
  }
}

/**
 * [02.03.04] 판매 상품 상세보기 (/mp/sale/detail )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97611446
 */
export namespace RPC_MP_SALE_DETAIL {
  export const url = '/mp/sale/detail';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
  }

  export interface Rx extends CommonRx_i {
    sale_id: number;
    seller: string;
    prc_info: CbtResPriceInfo_i;
    favorite: boolean;
    ast_info: {
      asset_id: number;
      asset_key: string;
      col_info: {
        cid: number;
        cert_tp: CbtColCertifyType_e;
        dp_name: string;
      };
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      attr_list: CbtAttributeInfoFull_i[];
      creator: {
        ucode: string;
        prof: string | null;
      };
      owner: {
        ucode: string;
        prof: string | null;
      };
      can_addition: boolean;
      mint_no: number;
      issue_tcnt: number;
      sale_id: number;
      blocked: boolean;
    };
    trd_graph: {
      min_prc: CbtResPriceInfo_i;
      max_prc: CbtResPriceInfo_i;
      tr_cnt: number;
      tr_rows:
        | {
            ymd: number;
            cnt: number;
            real_usd_prc: number;
          }[];
    } | null;
    status: CbtTxStatus_e;
  }
}

/**
 * [02.03.05_0] 판매 상품 구매 팝업 표시 정보
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98606637
 */
export namespace RPC_MP_SALE_PURCHASE_VIEW {
  export const url = '/mp/sale/purchase/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
  }

  export interface Rx extends CommonRx_i {
    sale_id: number;
    seller: string;
    prc_info: CbtResPriceInfo_i;
    ast_info: {
      col_info: {
        cid: number;
        cert_tp: CbtColCertifyType_e;
        dp_name: string;
      };
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      entity_id: number;
      asset_id: number;
      asset_key: string;
      nft_name: string;
      nft_img: string;
      can_addition: boolean;
      mint_no: number;
      issue_tcnt: number;
    };
  }
}

/**
 * [02.03.05_1] 판매 상품 klay로 구매 : tx 시작, uuid 발급(/mp/sale/purchase/begin)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97611453
 */
export namespace RPC_MP_SALE_PURCHASE_BEGIN {
  export const url = '/mp/sale/purchase/begin';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
    prc_info: CbtReqPriceInfo_i;
  }

  export interface Rx extends CommonRx_i {
    tx_id: string;
  }
}

/**
 * [02.03.05_2] 판매 상품 klay로 구매 : tx 완료, klay 송금 결과 업데이트(/mp/sale/purchase/end)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97612592
 */
export namespace RPC_MP_SALE_PURCHASE_END {
  export const url = '/mp/sale/purchase/end';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
    tx_id: string;
    status: number;
    bl_tx_hash?: string;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.03.06] 판매 게시글 관심 등록/해제(/mp/sale/interest)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98623553
 */
export namespace RPC_MP_SALE_INTEREST {
  export const url = '/mp/sale/interest';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.03.07] 판매 게시글 신고(/mp/sale/report)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98624249
 */
export namespace RPC_MP_SALE_REPORT {
  export const url = '/mp/sale/report';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sale_id: number;
    reason: CbtReportReason_e;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [02.03.08] NFT 거래내역 ( /mp/sale/history )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100300844
 */
export namespace RPC_MP_SALE_HISTORY {
  export const url = '/mp/sale/history';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    sale_id?: number;
    sid?: number;
    entity_id?: number;
    asset_id?: number;
  }

  export interface Rx extends CommonRx_i {
    trd_history: {
      //
      sale_id: number;
      prc_info: CbtResPriceInfo_i;
      from: CbtUserInfo_i;
      to: CbtUserInfo_i;
      time: number;
    }[];
    pg_info: CbtPgInfo_i;
  }
}

/**
 * [02.03.09] 마켓플레이스 컬렉션 보기 ( /mp/sale/collection/view )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100994347
 */
export namespace RPC_MP_SALE_COLLECTION_VIEW {
  export const url = '/mp/sale/collection/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    cid: number;
  }

  export interface Rx extends CommonRx_i {
    col_info: {
      cid: number;
      cert_tp: number;
      creator: CbtUserInfo_i;
      name: string;
      dp_name: string;
      dp_img_url: string;
      royalty: number;
      svc_url: string;
      desc: string;
      admin_list: string[];
    };
  }
}

/**
 * [02.03.10] 마켓플레이스 컬렉션 애셋 목록 ( /mp/sale/collection/asset/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100994369
 */
export namespace RPC_MP_SALE_COLLECTION_ASSET_LIST {
  export const url = '/mp/sale/collection/asset/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    cid: number;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    sale_list: {
      sale_id: number;
      seller: string;
      ast_info: {
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: number;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
      };
      prc_info: CbtResPriceInfo_i;
      favorite: boolean;
    }[];
  }
}

/**
 * [02.03.11] 마켓플레이스 스키마 보기 ( /mp/sale/schema/view )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100995407
 */
export namespace RPC_MP_SALE_SCHEMA_VIEW {
  export const url = '/mp/sale/schema/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
  }

  export interface Rx extends CommonRx_i {
    schm_info: {
      cid: number;
      sid: number;
      asset_type: number;
      name: string;
      dp_name: string;
      attributes: { name: string; type: number }[];
    };
  }
}

/**
 * [02.03.12] 마켓플레이스 스키마 애셋 목록 ( /mp/sale/schema/asset/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100995403
 */
export namespace RPC_MP_SALE_SCHEMA_ASSET_LIST {
  export const url = '/mp/sale/schema/asset/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    sale_list: {
      sale_id: number;
      seller: string;
      ast_info: {
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: number;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
      };
      prc_info: CbtResPriceInfo_i;
      favorite: boolean;
    }[];
  }
}

/**
 * [02.03.13] 공인 컬렉션 전체 목록 ( /mp/sale/collection/certlist )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101005593
 */
export namespace RPC_MP_COLLECTION_CERTLIST {
  export const url = '/mp/sale/collection/certlist';
  export const method = REQ_METHOD.POST;

  export interface Tx {}

  export interface Rx extends CommonRx_i {
    col_list: {
      cid: number;
      cert_tp: number;
      dp_name: string;
    }[];
  }
}

/**
 * [02.04.01] Point 충전 상품 목록 (/payment/charge/point/list)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98626290
 */
export namespace RPC_PAYMENT_CHARGE_POINT_LIST {
  export const url = '/payment/charge/point/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pgno: CbtPG_e;
    lang?: CbtUserLanguage_e;
  }

  export interface Rx extends CommonRx_i {
    prd_list: CbtPrd_i[];
  }
}

/**
 * [02.04.02] Paymentwall Widget URL ( /payment/charge/point/widget )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98628163
 */
export namespace RPC_PAYMENT_CHARGE_POINT_WIDGET {
  export const url = '/payment/charge/point/widget';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    prd_id: string;
  }

  export interface Rx extends CommonRx_i {
    url: string;
  }
}

/**
 * [02.04.03.01] 주문번호 생성 ( /payment/paypal/charge/point/order )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=102176258
 */
export namespace RPC_PAYMENT_PAYPAL_CHARGE_POINT_ORDER {
  export const url = '/payment/paypal/charge/point/order';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    prd_id: string;
  }

  export interface Rx extends CommonRx_i {
    id: string; // OrderID
  }
}

/**
 * [02.04.03.02] 주문 승인 ( /payment/paypal/charge/point/capture )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=102177046
 */
export namespace RPC_PAYMENT_PAYPAL_CHARGE_POINT_CAPTURE {
  export const url = '/payment/paypal/charge/point/capture';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    ord_id: string;
  }

  export interface Rx extends CommonRx_i {
    debug_id: string;
    error_id: string;
    error_desc: string;
  }
}

/**
 * [02.05] Home ( /home/info )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=99223063
 */
export namespace RPC_HOME_INFO {
  export const url = '/home/info';
  export const method = REQ_METHOD.POST;

  export interface Tx {}

  export interface Rx extends CommonRx_i {
    stat: {
      total_asset_count: number; //	전체 발행된 에셋
      sale_list_count: number; //	오늘 판매 등록 수
      sold_count: number; //	오늘 거래된 수
      sum_sales: CbtResPriceInfo_i; //	오늘 거래액 (USD)
    };
    sale_list: {
      sale_id: number;
      prc_info: CbtResPriceInfo_i;
      seller: string;
      ast_info: {
        sid: number;
        entity_id: number;
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
      };
      favorite: boolean;
    }[];
    reco_list: {
      sale_id: number;
      prc_info: CbtResPriceInfo_i;
      seller: string;
      ast_info: {
        sid: number;
        entity_id: number;
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
      };
      favorite: boolean;
      sold: boolean;
    }[];
    col_list: {
      cid: number;
      cert_tp: CbtColCertifyType_e;
      dp_name: string;
      dp_img_url: string;
      status?: CbtCreationStatus_e;
    }[];
  }
}

/**
 * [02.06.01] 인벤토리 ( /assetmgr/inventory/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100995639
 */
export namespace RPC_ASSETMGR_INVENTORY_LIST {
  export const url = '/assetmgr/inventory/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    search?: string;
    ucode?: string;
  }

  export interface Rx extends CommonRx_i {
    astg_list: {
      entity_id: number;
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      col_info: {
        cid: number;
        cert_tp: CbtColCertifyType_e;
        dp_name: string;
      };
      nft_name: string;
      nft_img: string;
      issue_tcnt: number;
      own_cnt: number;
      time: number;
      blocked: boolean;
    }[];
    pg_info: CbtPgInfo_i;
  }
}

/**
 * [02.06.02] 인벤토리 에셋 상세 목록 ( /assetmgr/inventory/asset/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100996283
 */

export enum CbtSaleStatus_e {
  NOTONSALE = 0, // 미등록
  ONSALE = 101, // 판매중
}

export namespace RPC_ASSETMGR_INVENTORY_ASSET_LIST {
  export const url = '/assetmgr/inventory/asset/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    entity_id: number;
    ucode: string | null;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    ast_list: {
      sale_id: number;
      status: CbtSaleStatus_e;
      ast_info: {
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
        blocked: boolean;
      };
    }[];
    pg_info: CbtPgInfo_i;
    sid: number;
    entity_id: number;
    own_cnt: number;
    asset_name: string;
  }
}

/**
 * [02.06.03] 발행 에셋 ( /assetmgr/issue/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100996349
 */
export namespace RPC_ASSETMGR_ISSUE_LIST {
  export const url = '/assetmgr/issue/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    search?: string;
    ucode?: string;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    astg_list: {
      entity_id: number;
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      col_info: {
        cid: number;
        cert_tp: CbtColCertifyType_e;
        dp_name: string;
      };
      nft_name: string;
      nft_img: string;
      issue_tcnt: number;
      time: number;
      blocked: boolean;
    }[];
    pg_info: CbtPgInfo_i;
  }
}

/**
 * [02.06.04] 발행 에셋 상세 목록 ( /assetmgr/issue/asset/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100996361
 */
export namespace RPC_ASSETMGR_ISSUE_ASSET_LIST {
  export const url = '/assetmgr/issue/asset/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    entity_id: number;
    pg_info?: CbtPgInfo_i;
  }

  export interface Rx extends CommonRx_i {
    ast_list: {
      sale_id: number;
      status: CbtSaleStatus_e;
      ast_info: {
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string; // "UNKNOWN" DB에 유저가 없는 경우 표시
      };
    }[];
    pg_info: CbtPgInfo_i;
    sid: number;
    entity_id: number;
    issue_tcnt: number;
    asset_name: string;
    can_addition: boolean;
    blocked: boolean;
    attr_list: CbtAttributeInfoFull_i[];
  }
}

/**
 * [02.06.05] 애셋 상세 보기 ( /assetmgr/view )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=100997469
 */
export namespace RPC_ASSETMGR_VIEW {
  export const url = '/assetmgr/view';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    sid: number;
    entity_id: number;
    asset_id: number;
  }

  export interface Rx extends CommonRx_i {
    ast_info: {
      asset_id: number;
      asset_key: string;
      col_info: {
        cid: number;
        cert_tp: CbtColCertifyType_e;
        dp_name: string;
      };
      schm_info: {
        sid: number;
        dp_name: string;
        asset_type: CbtAssetType_e;
      };
      attr_list: CbtAttributeInfoFull_i[];
      creator: CbtUserInfo_i;
      owner: CbtUserInfo_i;
      can_addition: boolean;
      mint_no: number;
      issue_tcnt: number;
      sale_id: number;
      blocked: boolean;
    };
  }
}

/**
 * [02.06.06] 판매중 에셋 리스트 ( /assetmgr/sale/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101001479
 */
export namespace RPC_ASSETMGR_SALE_LIST {
  export const url = '/assetmgr/sale/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    seller_code?: string;
    pg_info?: CbtPgInfo_i;
    search?: string;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    sale_list: {
      sale_id: number;
      prc_info: CbtResPriceInfo_i;
      seller: string;
      ast_info: {
        sid: number;
        entity_id: number;
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
        blocked: boolean;
      };
      favorite: boolean;
    }[];
  }
}

/**
 * [02.06.07] 판매 완료 에셋 리스트 ( /assetmgr/sold/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101001875
 */
export namespace RPC_ASSETMGR_SOLD_LIST {
  export const url = '/assetmgr/sold/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    seller_code?: string;
    pg_info?: CbtPgInfo_i;
    search?: string;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    sold_list: {
      sale_id: number;
      prc_info: CbtResPriceInfo_i;
      seller_earn: CbtResPriceInfo_i;
      creator_fee: CbtResPriceInfo_i | null;
      platform_fee: CbtResPriceInfo_i;
      seller_reward: CbtResPriceInfo_i;
      // creator_reward: CbtResPriceInfo_i;
      // buyer_reward: CbtResPriceInfo_i;
      seller: string;
      buyer: string;
      ast_info: {
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
        blocked: boolean;
      };
      tx_hash: string;
      tx_hash_url: string;
      time: number;
    }[];
  }
}

/**
 * [02.06.08] 구매 에셋 리스트 ( /assetmgr/bought/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101003079
 */
export namespace RPC_ASSETMGR_BOUGHT_LIST {
  export const url = '/assetmgr/bought/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    buyer_code?: string;
    pg_info?: CbtPgInfo_i;
    search?: string;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    sold_list: {
      sale_id: number;
      prc_info: CbtResPriceInfo_i;
      buyer_reward: CbtResPriceInfo_i;
      seller: string;
      buyer: string;
      ast_info: {
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
        blocked: boolean;
      };
      tx_hash: string;
      tx_hash_url: string;
      time: number;
    }[];
  }
}

/**
 * [02.06.09] 즐겨찾기 목록 ( /assetmgr/favorite/list )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101004918
 */
export namespace RPC_ASSETMGR_FAVORITE_LIST {
  export const url = '/assetmgr/favorite/list';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    pg_info?: CbtPgInfo_i;
    search?: string;
  }

  export interface Rx extends CommonRx_i {
    pg_info: CbtPgInfo_i;
    sale_list: {
      sale_id: number;
      prc_info: CbtResPriceInfo_i;
      seller: string;
      ast_info: {
        sid: number;
        entity_id: number;
        asset_id: number;
        asset_key: string;
        col_info: {
          cid: number;
          cert_tp: CbtColCertifyType_e;
          dp_name: string;
        };
        nft_name: string;
        nft_img: string;
        nft_aud: string | null;
        nft_vid: string | null;
        owner: string;
        blocked: boolean;
      };
      favorite: boolean;
    }[];
  }
}

/**
 * [02.06.10] MyPage 자산 개수 ( /assetmgr/count )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101005500
 */
export namespace RPC_ASSETMGR_COUNT {
  export const url = '/assetmgr/count';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    ucode?: string;
  }

  export interface Rx extends CommonRx_i {
    inventory: number;
    issued: number;
    sale: number;
    sold: number;
    bought: number;
    favorite?: number | null;
    scope_url: string;
  }
}

/**
 * [02.07.01] Zendesk 문의하기 ( /support/zendesk/addtask )
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=101019785
 */
export namespace RPC_SUPPORT_ZENDESK_ADDTASK {
  export const url = '/support/zendesk/addtask';
  export const method = REQ_METHOD.POST;

  export interface Tx {
    name: string;
    email: string;
    subject: string;
    body: string;
    type: CbtInqueryType_e;
    lang: CbtUserLanguage_e;
    device: string;
    os: string;
    browser: string;
    phase: string;
    version: string;
  }

  export interface Rx extends CommonRx_i {}
}

/**
 * [03.01] Upload 서버 (리소스 업로드)
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96325276
 */
export namespace RPC_UPLOAD {
  export const url = 'https://nft-up.cobalt.zone/apup/upload';
  export const method = REQ_METHOD.POST;

  export interface Tx extends FormData {}

  export interface Rx {
    message: string[];
  }
}

// export namespace RPC_AUTH_TEST_CLIENT {
//   export const url = '/auth/testclient';
//   export const method = REQ_METHOD.GET;

//   export interface Tx {
//     mid: string;
//     serviceId: string;
//     appid: string;
//   }

//   export interface Rx extends CommonRx_i {}
// }
