/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { devOutlineF, FlexColumnDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import R from 'src/res/R';
import { DBGMSG } from 'src/util/utils';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! styled component
 **************************************/
export const Oval = styled(FlexColumnDiv)`
  width: 160px;
  height: 160px;
  border-radius: 80px;

  border-style: dashed;
  border-width: 2px;
  border-color: ${R.colors.line};
  background-color: ${R.colors.bgGrey};
  user-select: none;
`;

export const OverOval = styled(FlexColumnDiv)`
  width: 160px;
  height: 160px;
  border: double 2px transparent;
  border-radius: 80px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #6decd6, ${R.colors.purpleishBlue});
  background-origin: border-box;
  background-clip: content-box, border-box;
  user-select: none;
`;

type Props = {
  forceEffect?: boolean;
  onEffect?: (on: boolean) => void;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function SCircelAddBtn({ forceEffect = false, onEffect, onClick }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseEnter, setMouseEnter] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  const isEffect = sMouseEnter || forceEffect;

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    // DBGMSG('useEffect() init');

    return () => {
      // DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    onEffect && onEffect(isEffect);
    return () => {};
  }, [isEffect, onEffect]);

  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  // DBGMSG('render');
  return (
    <FlexColumnDiv style={{ alignItems: 'center' }}>
      {/*  Collection 대표이미지 */}
      {isEffect ? (
        <OverOval
          style={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onMouseEnter={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
          onClick={(e) => {
            DBGMSG(`onClick`);
            onClick && onClick(e);
          }}
        >
          <Img src={[R.images.creator_ic_plus3x, 24, 24]} />
        </OverOval>
      ) : (
        <Oval
          style={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onMouseEnter={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
        >
          <Img src={[R.images.creator_ic_plus3x, 24, 24]} />
        </Oval>
      )}
    </FlexColumnDiv>
  );
}
