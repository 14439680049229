/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 * auth
 */

import { createAction, createReducer } from '@reduxjs/toolkit';
import { AuthCommonRes_t, AuthStatus } from 'src/model/model';
import { DBGMSGW } from 'src/util/utils';

/****************************************/
// state
/****************************************/
type AuthState = {
  authStatus: AuthStatus;
  authCommonRes?: AuthCommonRes_t;
  email?: string;
};
const initialState: AuthState = {
  authStatus: AuthStatus.Logout,
};

/****************************************/
// action
/****************************************/
const types = {
  TESTLOGIN: 'auth/TESTLOGIN',
  LOGIN: 'auth/LOGIN',
  LOGOUT: 'auth/LOGOUT',
  UPDATE_TOKEN: 'auth/UPDATE_TOKEN',
  UPDATE_AUTH_COMMON_RES: 'auth/UPDATE_AUTH_COMMON_RES',
  // UPDATE_AUTHSTATUS: 'auth/UPDATE_AUTHSTATUS',
  // UPDATE_AUTHINFO: 'auth/UPDATE_AUTHINFO',
};
const actions = {
  testloginAct: createAction(types.TESTLOGIN),
  loginAct: createAction<{
    email?: string;
    authCommonRes: AuthCommonRes_t;
  }>(types.LOGIN),
  logoutAct: createAction(types.LOGOUT),
  updateToken: createAction<{ newToken: string }>(types.UPDATE_TOKEN),
  updateAuthCommonRes: createAction<{ authCommonRes: AuthCommonRes_t }>(types.UPDATE_AUTH_COMMON_RES),
  // updateAuthStatusAct: createAction<{ authStatus: E_AuthStatus }>(types.UPDATE_AUTHSTATUS),
  // updateAuthInfoAct: createAction<{ authInfo: RPC_AUTH_AUTHENTICATON.Rx }>(types.UPDATE_AUTHINFO),
};

/****************************************/
// reducer
/****************************************/
const reducer = createReducer<AuthState>(initialState, (builder) => {
  builder
    // .addCase(actions.testloginAct, (draft, action) => {
    //   draft.authStatus = AuthStatus.Login;
    //   draft.authInfo = { email: 'testLoginEmail@test.test', ucode: 'test', token: 'test', has_rw_addr: false, mkey: '' };
    //   // draft.authInfo = undefined;
    // })
    .addCase(actions.loginAct, (draft, action) => {
      if (action.payload.email) draft.email = action.payload.email;
      draft.authCommonRes = action.payload.authCommonRes;
      draft.authStatus = AuthStatus.Login;
    })
    .addCase(actions.logoutAct, (draft, action) => {
      draft.authStatus = AuthStatus.Logout;
      draft.email = undefined;
      draft.authCommonRes = undefined;
    })
    .addCase(actions.updateToken, (draft, action) => {
      if (draft.authStatus === AuthStatus.Logout) {
        DBGMSGW('not login');
        // return; // todo 로그인 요청 호출하는 rpc 자체일때 logout상태
      }
      if (draft.authCommonRes === undefined) {
        DBGMSGW('authStatus undefined');
        return; // todo test로그인시 auth정보가 없음
      }
      draft.authCommonRes.token = action.payload.newToken;
    })
    .addCase(actions.updateAuthCommonRes, (draft, action) => {
      draft.authCommonRes = action.payload.authCommonRes;
    });
});

const authModule = { reducer, types, actions };
export default authModule;
