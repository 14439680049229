/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useRef } from 'react';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { MarketAssetRead_i, ReqAudioSrc_i, ReqImageSrc_i, ReqVideoSrc_i } from 'src/model/model';
import { CbtAssetType_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgAdv } from 'src/ui/base_component/ImgAdv';
import TextCon from 'src/ui/base_component/TextCon';
import { WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  assetInfo?: {
    asset_type: MarketAssetRead_i['assetType'];
    attributeList?: MarketAssetRead_i['attributeList'];
    assetMainImg?: MarketAssetRead_i['assetMainImg'];
  };
  // playerSrc?: DisplaySrc_t;
  playerSrc?: Required<ReqImageSrc_i> | Omit<Required<ReqAudioSrc_i>, 'audio'> | Omit<Required<ReqVideoSrc_i>, 'video'>;
  width?: number;
  height?: number;
  scaleIfAudio?: number;
  // isAnim?: boolean;
  onClick?: () => void;
  isBlock?: boolean;
  whereToUse: WhereToUse_e;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DISP_DEFAULT_WIDTH = 276;
const DISP_DEFAULT_HEIGHT = 276;

/**************************************
 * !! styled component
 **************************************/

export function AssetDisplayThumbReqattr({
  //
  assetInfo,
  playerSrc,
  width = DISP_DEFAULT_WIDTH,
  height = DISP_DEFAULT_HEIGHT,
  scaleIfAudio = 1,
  isBlock,
  whereToUse,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  // const [sIsPlay, setIsPlay] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const imgRef = useRef<HTMLImageElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const render = () => {
    let img: string | undefined;
    let assetType: CbtAssetType_e | undefined;
    // let audio: string | undefined;
    // let video: string | undefined;

    if (!assetInfo && !playerSrc) {
      DBGMSGW(`!assetInfo && !playerSrc`);
      return null;
    }

    if (assetInfo) {
      assetType = assetInfo.asset_type;
      if (assetInfo.attributeList) {
        img = Utils.svc.getReqImgAttrValue(assetInfo.attributeList);
      } else {
        img = assetInfo.assetMainImg;
      }
    } else {
      if (!playerSrc) {
        DBGMSGW(`!playerSrc`);
        return null;
      }
      switch (playerSrc.kind) {
        case 'ReqImage':
          assetType = CbtAssetType_e.IMAGE;
          img = playerSrc.img;
          break;
        case 'ReqAudio':
          assetType = CbtAssetType_e.AUDIO;
          img = playerSrc.img;
          // audio = playerSrc.audio;
          break;
        case 'ReqVideo':
          assetType = CbtAssetType_e.VIDEO;
          img = playerSrc.img;
          // video = playerSrc.video;
          break;
      }
    }

    if (!assetType) {
      DBGMSGW(`!assetType`);
      return null;
    }

    // 필수항목
    switch (assetType) {
      case CbtAssetType_e.IMAGE:
        // if (!img) return null;
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            <ImgAdv
              // getRef={(ref) => {
              //   imgRef.current = ref;
              //   DBGMSG(`imgRef.current: ${imgRef.current}`);
              //   imgRef.current?.addEventListener('load', () => {
              //     DBGMSG(imgRef.current);
              //     DBGMSG(`naturalWidth: ${imgRef.current?.naturalWidth}`);
              //     DBGMSG(`naturalHeight: ${imgRef.current?.naturalHeight}`);
              //   });
              // }}
              src={[img ?? null, undefined, undefined]}
              containerStyle={{
                flex: '0 0 auto',
                // ...devOutlineF,
              }}
              imgStyle={{
                width: width,
                height: height,
                overflow: 'hidden',
                objectFit: 'contain',
                // ...devOutlineF,
              }}
              onClick={() => {}}
            />
          </FlexRowDiv>
        );
      case CbtAssetType_e.VIDEO:
        // if (!video) return null;
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            {img && (
              <ImgAdv
                src={[img, undefined, undefined]}
                containerStyle={{
                  flex: '0 0 auto',
                  // ...devOutlineF,
                }}
                imgStyle={{
                  width: width,
                  height: height,
                  overflow: 'hidden',
                  objectFit: 'contain',
                  // ...devOutlineF,
                }}
                onClick={() => {}}
              />
            )}
            {/* <video src={video} controls={false} width={width} height={height} poster={img} style={{ outline: 'none', objectFit: 'contain' }} /> */}
            {/* 비디오 아이콘 */}
            <Img
              src={[R.images.common_ic_video3x, 28, 28]}
              containerStyle={{
                position: 'absolute',
                width: 28,
                height: 28,
                top: 4,
                right: 4,
              }}
            />
          </FlexRowDiv>
        );
      case CbtAssetType_e.AUDIO:
        // if (!audio) return null;
        // 236 : 192 = W : w
        // 192W/236=w
        const audioCoverImgSize = (192 * width) / 236;

        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              alignItems: 'center',
              justifyContent: 'flex-start',
              overflow: 'visible',
              transform: `scale(${scaleIfAudio},${scaleIfAudio})`,
              // ...devOutlineF,
            }}
            onClick={() => {}}
          >
            {/* 배경 이미지 */}
            <Img
              src={[R.images.common_iv_audio3x, width, height]}
              containerStyle={{
                position: 'absolute',
                // ...devOutline,
              }}
            />
            {/* 커버이미지 */}
            {img ? (
              <ImgAdv
                src={[img, undefined, undefined]}
                containerStyle={{
                  backgroundColor: 'white',
                  borderWidth: '0.5px',
                  borderStyle: 'solid',
                  borderColor: R.colors.line,
                  // ...devOutlineF,
                }}
                imgStyle={{
                  width: audioCoverImgSize,
                  height: audioCoverImgSize,
                  objectFit: 'contain',
                }}
              />
            ) : (
              <FlexColumnDiv
                style={{
                  width: audioCoverImgSize,
                  height: audioCoverImgSize,
                  backgroundColor: R.colors.bgGrey,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: '0.5px',
                  borderStyle: 'solid',
                  borderColor: R.colors.line,
                }}
              >
                <TextCon text={hR.strings.AC_ASSET_CREATE_PREVIEW} size={10} isBold color={R.colors.cloudyBlue} />
              </FlexColumnDiv>
            )}
          </FlexRowDiv>
        );
    }
  };

  return (
    <FlexColumnDiv>
      <FlexDiv style={{ filter: isBlock ? 'blur(3px)' : undefined }}>{render()}</FlexDiv>
      {(Constants.forceBlock || isBlock) && whereToUse !== WhereToUse_e.ForSlider && (
        <FlexColumnDiv
          style={{
            //
            position: 'absolute',
            left: -10,
            right: -10,
            top: -10,
            bottom: -10,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          }}
        >
          <Img src={[R.images.common_ic_warning3x, 20, 20]} />
          <HEIGHT size={6} />
          <TextCon text={hR.strings.MARKET_ASSET_CARD_BLOCK} color={R.colors.black} size={12} textStyle={{ textAlign: 'center' }} isBold />
        </FlexColumnDiv>
      )}
    </FlexColumnDiv>
  );
}
