/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties } from 'react';
import R from 'src/res/R';
import { AnimatedFlexDiv, devOutlineF } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  label?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  isBold?: boolean;
  fontColor?: string;
  containerStyle?: CSSProperties;
};

export function RoundTxtBtnDone({
  //
  label = 'label',
  width = 152,
  height = 44,
  fontSize = 14,
  isBold = true,
  fontColor = R.colors.purpleishBlue,
  containerStyle,
}: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  return (
    <AnimatedFlexDiv
      style={{
        width,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: height / 2,
        borderStyle: 'solid',
        borderColor: R.colors.line,
        borderWidth: 1,
        flex: '0 0 auto',
        ...containerStyle,
      }}
    >
      <TextCon text={label} size={fontSize} isBold={isBold} color={fontColor} useSelectNone />
    </AnimatedFlexDiv>
  );
}
