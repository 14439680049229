import { CSSProperties, HTMLProps } from 'react';
import R from 'src/res/R';
import styled from 'styled-components';

export interface TextProps {
  fontFamily?: string;
  isItelic?: boolean;
  size?: number;
  isBold?: boolean;
  isUnderline?: boolean;
  color?: string;
  letterSpacing?: number;
  lineSpace?: number;
  useSelectNone?: boolean;
}

export const Text = styled.span`
  font-family: ${(props: TextProps) => props.fontFamily || R.fonts.SpoqaJP};
  margin-right: ${(props: TextProps) => (props.isItelic ? '2px' : '0px')};
  font-size: ${(props: TextProps) => `${props.size}px`};
  font-weight: ${(props: TextProps) => (props.isBold ? 'bold' : 'normal')};
  font-style: ${(props: TextProps) => (props.isItelic ? 'italic' : 'normal')};
  text-decoration: ${(props: TextProps) => (props.isUnderline ? 'underline' : 'none')};
  color: ${(props: TextProps) => props.color ?? R.colors.rn.blue};
  letter-spacing: ${(props: TextProps) => `${props.letterSpacing}px`};
  line-height:  ${(props: TextProps) => `${props.lineSpace}px`};
  /* overflow-wrap: break-word; */
  /* overflow-wrap: normal; */
  white-space: pre-wrap;
  user-select: ${(props: TextProps) => (props.useSelectNone ? 'none' : 'auto')};
  font-size-adjust: none;
  -webkit-text-size-adjust: none;
`;
