/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useState } from 'react';
import { devOutlineF } from 'src/ui/base_component/etc';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { useHistory } from 'react-router';
import { Nav } from 'src/ui/screen/Nav';
import { LOGINOUT_BTN_LAYOUT } from 'src/ui/layout_constant';
import { RoundTxtBtnAdvWithAnim } from 'src/ui/common_component/button/RoundTxtBtnAdvWithAnim';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function LogoutBtn({ onClick }: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hHistory = useHistory();
  const hAuth = useAuth();

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <RoundTxtBtnAdvWithAnim
      width={LOGINOUT_BTN_LAYOUT.width}
      height={LOGINOUT_BTN_LAYOUT.height}
      fontSize={LOGINOUT_BTN_LAYOUT.fontSize}
      label={hR.strings.HEADER_LOGOUT}
      onClick={(e) => {
        hHistory.push(Nav.HomeScreen);
        DBGMSG('logout');
        hAuth.authActs.logout();
        onClick && onClick(e);
      }}
    />
  );
}
