/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import qs from 'qs';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtUserLanguage_e } from 'src/model/rpcModel';

export function useQsLang() {
  const hR = useLocalSettings();
  const hLocation = useLocation();
  
  useEffect(() => {
    // query string parsing
    const query: { lang?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.lang) {
      switch (query.lang) {
        case 'ko':
          hR.changeLang(CbtUserLanguage_e.KO);
          break;
        case 'ja':
          hR.changeLang(CbtUserLanguage_e.JA);
          break;
        case 'en':
          hR.changeLang(CbtUserLanguage_e.EN);
          break;
      }
    } else {
      // key 없이 접근
      return;
    }

    return () => {};
  }, []);
}
