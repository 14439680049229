/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useHistory } from 'react-router';
import { useEffectOnce } from 'react-use';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useForceRender } from 'src/hooks/useForceRender';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn2 } from 'src/ui/common_component/button/RoundTxtBtn2';
import { CLAY } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {};

export function LabScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sState, setState] = useState<{
    url: string | undefined;
    pip: boolean;
    playing: boolean;
    controls: boolean;
    light: boolean;
    volume: number;
    muted: boolean;
    played: number;
    loaded: number;
    duration: number;
    playbackRate: number;
    loop: boolean;
  }>({
    url: undefined,
    pip: false,
    playing: true,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  });

  /**************************************
   * !! ref
   **************************************/
  const reactPlayerRef = useRef<ReactPlayer>(null);

  /**************************************
   * !! hooks
   **************************************/
  const hHistory = useHistory();
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();
  const hForceRender = useForceRender();
  const hMyPageState = useUserDetailMineState();

  // const location = useLocation();
  // const { pageWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  const load = (url: string) => {
    setState((prev) => {
      return {
        ...prev,
        url,
        played: 0,
        loaded: 0,
        pip: false,
      };
    });
  };

  const handlePlayPause = () => {
    setState((prev) => {
      return {
        ...prev,
        playing: !prev.playing,
      };
    });
  };

  const handleStop = () => {
    setState((prev) => {
      return {
        ...prev,
      };
    });
    // setState({ url: null, playing: false });
  };

  const handleToggleControls = () => {
    setState((prev) => {
      return {
        ...prev,
        controls: !prev.controls,
        url: undefined,
      };
    });

    // setState(
    //   {
    //     controls: !state.controls,
    //     url: null,
    //   },
    //   () => load(url)
    // );
  };

  // const handleToggleLight = () => {
  //   setState({ light: !state.light });
  // };

  // const handleToggleLoop = () => {
  //   setState({ loop: !state.loop });
  // };

  // const handleVolumeChange = (e) => {
  //   setState({ volume: parseFloat(e.target.value) });
  // };

  // const handleToggleMuted = () => {
  //   setState({ muted: !state.muted });
  // };

  // const handleSetPlaybackRate = (e) => {
  //   setState({ playbackRate: parseFloat(e.target.value) });
  // };

  // const handleTogglePIP = () => {
  //   setState({ pip: !state.pip });
  // };

  const handlePlay = () => {
    console.log('onPlay');
    setState((prev) => {
      return {
        ...prev,
        playing: true,
      };
    });
  };

  // const handleEnablePIP = () => {
  //   console.log('onEnablePIP');
  //   setState({ pip: true });
  // };

  // const handleDisablePIP = () => {
  //   console.log('onDisablePIP');
  //   setState({ pip: false });
  // };

  const handlePause = () => {
    console.log('onPause');
    setState((prev) => {
      return {
        ...prev,
        playing: false,
      };
    });
  };

  // const handleSeekMouseDown = (e) => {
  //   setState({ seeking: true });
  // };

  // const handleSeekChange = (e) => {
  //   setState({ played: parseFloat(e.target.value) });
  // };

  // const handleSeekMouseUp = (e) => {
  //   setState({ seeking: false });
  //   player.seekTo(parseFloat(e.target.value));
  // };

  const handleProgress = (state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) => {
    console.log('onProgress', state);
    // We only want to update time slider if we are not currently seeking
    // if (!state.seeking) {
    //   setState(state);
    // }
    // };
  };

  // const handleEnded = () => {
  //   console.log('onEnded');
  //   setState({ playing: state.loop });
  // };

  const handleDuration = (duration: number) => {
    console.log('onDuration', duration);
    // setState({ duration });
  };

  // const handleClickFullscreen = () => {
  //   screenfull.request(findDOMNode(player));
  // };

  // renderLoadButton = (url, label) => {
  //   return <button onClick={() => load(url)}>{label}</button>;
  // };

  /**************************************
   * !! render conf
   **************************************/

  DBGMSG('render');
  return (
    <FlexDiv
      style={{
        // backgroundColor: R.colors.rn.black,
        flexDirection: 'column',
        paddingTop: CLAY.COMMON_HEADER_HEIGHT,
        ...devOutline,
      }}
    >
      <TextCon text={'language setting'} />
      <RoundTxtBtn2
        text={'ko'}
        onClick={() => {
          hR.changeLang(CbtUserLanguage_e.KO);
        }}
      />
      <RoundTxtBtn2
        text={'en'}
        onClick={() => {
          hR.changeLang(CbtUserLanguage_e.EN);
        }}
      />
      <RoundTxtBtn2
        text={'ja'}
        onClick={() => {
          hR.changeLang(CbtUserLanguage_e.JA);
        }}
      />

      <RoundTxtBtn2
        text={`강제 block 설정 : ${Constants.forceBlock}`}
        onClick={() => {
          Constants.forceBlock = !Constants.forceBlock;
          hMyPageState.act.clearState();
          hForceRender.forceRender();
        }}
      />

      <RoundTxtBtn2
        text={`카이카스 연동`}
        onClick={() => {
          (window as any).klaytn.enable().then((ret: any) => {
            DBGMSG(ret);
          });
          // const provider = window['klaytn'];
        }}
      />
      {/* https://nft-ipfs.cobalt.zone/ipfs/QmWJvaQL1QygWSv5BCVbzUqihu41eBBs18AcLzfbKJrqK9 */}

      <TextCon text={'React Player'} />

      {/* <ReactPlayer url="https://nft-ipfs.cobalt.zone/ipfs/QmWJvaQL1QygWSv5BCVbzUqihu41eBBs18AcLzfbKJrqK9" /> */}

      <ReactPlayer
        ref={reactPlayerRef}
        className="react-player"
        // width="100%"
        // height="100%"
        playing={sState.playing}
        url={sState.url}
        pip={sState.pip}
        light={sState.light}
        loop={sState.loop}
        playbackRate={sState.playbackRate}
        volume={sState.volume}
        muted={sState.muted}
        onReady={() => console.log('onReady')}
        onStart={() => console.log('onStart')}
        onPlay={handlePlay}
        // onEnablePIP={handleEnablePIP}
        // onDisablePIP={handleDisablePIP}
        onPause={handlePause}
        onBuffer={() => console.log('onBuffer')}
        onSeek={(e) => console.log('onSeek', e)}
        // onEnded={handleEnded}
        onError={(e) => console.log('onError', e)}
        onProgress={handleProgress}
        onDuration={handleDuration}
      />
      <RoundTxtBtn2
        text={`load`}
        onClick={() => {
          load('https://nft-ipfs.cobalt.zone/ipfs/QmWJvaQL1QygWSv5BCVbzUqihu41eBBs18AcLzfbKJrqK9');
        }}
      />
      <RoundTxtBtn2
        text={`play`}
        onClick={() => {
          handlePlay();
        }}
      />
      <RoundTxtBtn2
        text={`pause`}
        onClick={() => {
          handlePause();
        }}
      />
      <RoundTxtBtn2
        text={`|<`}
        onClick={() => {
          reactPlayerRef.current?.seekTo(0);
        }}
      />
    </FlexDiv>
  );
}
