/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

export class PrivacyPolicyStrings {
  static readonly ko = {
    TITLE: `개인정보처리방침`,
    DESC: `POST VOYAGER PTE.LTD.(이하 '회사'라 합니다.)는 회사에서 제공하는 서비스 'Cobalt'(이하 '서비스'라 합니다.)에서 고객정보를 다음과 같이 취급합니다.`,
    TITLE_1: `제1조 (정의)`,
    DETAIL_1: `개인정보라 함은 개인정보보호법에서 말하는 '개인정보'를 가리키며 살아 있는 개인에 관한 정보로서 해당 정보에 포함되는 성명, 생년월일, 주소, 전화번호, 연락처, 기타 기술 등으로 특정 개인을 식별할 수 있는 정보를 말합니다. `,
    TITLE_2: `제2조 (쿠키, IP주소, 광고 식별자)`,
    DETAIL_2: `쿠키, IP 주소 정보 및 광고 식별자(Advertising Identifier(iOS), Google Advertising ID(Android OS))는 이들 단독으로 특정 개인을 식별할 수 없기 때문에 회사는 이들을 개인정보로 취급하지 않습니다. 다만, 이러한 정보와 개인정보가 일체가 되어 사용되는 경우에는 이러한 정보도 개인정보로 간주합니다. 또한 회사는 EU 일반 데이터 보호규칙(General Data Protection Regulation; GDPR)의 적용이 있는 고객에 관해서는 이러한 정보도 GDPR상의 개인 데이터(personal data)에 해당하는 것으로 보아 GDPR에 따라 취급합니다.
쿠키 정보나 광고 식별자는 브라우저나 스마트폰 앱 설정에서 거부할 수 있습니다. 쿠키 거부 시 회사의 서비스를 받을 수 없는 경우에는 그 취지도 서비스상으로 공표합니다.`,
    TITLE_3: `제3조 (제공받는 정보)`,
    DETAIL_3: `회사는 고객이 서비스를 이용함에 있어 다음에 열거된 정보를 취득할 수 있습니다.
• 계정명 기타 프로필 정보
• E메일 주소
• 이용기록, 트랜잭션, 지갑주소
• 금융 기관 계좌 정보
• 성명, 주소, 전화번호, 성별
• 연령
• 단말기종명, OS정보, 사용자 에이전트
• 서비스 이용 정보［Cookie(쿠키)·액세스 로그(IP 주소, 브라우저 종류, 브라우저 언어 등)·광고 식별자(Advertising Identifier, Google Advertising ID) 등］`,
    TITLE_4: `제4조 (이용목적)`,
    DETAIL_4: `회사는 취득한 개인정보를 다음의 목적으로 이용합니다. 또한 취득시에 이용 목적을 한정했을 경우에는 그 범위내에서만 이용합니다.
• 서비스를 원활하게 이용하고, 고객 간의 원활한 소통을 도모하기 위해
• 서비스 이용에 있어 필요한 알림 등을 전달하기 위해
• 어카운트 ID로 이용하기 위해
• 본인 확인 및 정상적인 서비스 제공을 방해하는 부정행위 방지를 위해
• 고객의 문의에 대응하기 위해
• 고객의 트래픽 측정, 행동 측정을 실시하기 위해
• 서비스 내용의 개선, 신서비스 개발을 위해
• 판매금을 입금하기 위해, 입금상황을 확인하기 위해
• 고객에 대한 앙케이트 실시에 의한 통계·분석을 위해
• 서비스 내에서 캠페인 등을 실시했을 경우, 추첨·상품 발송 등을 위해
• 서비스 이용 여부를 판단하기 위해
• 서비스의 이용 상황(이용약관상 금지사항, 부정출품 등)을 조사·분석하기 위해 `,
    TITLE_5: `제5조 (정보제공)`,
    DETAIL_5: `회사는 고객의 개인정보를 다음의 경우를 제외하고는 제3자에게 제공하지 않습니다.
• 본인이 사전에 동의한 경우
• 법률에 의거한 경우
• 고객이 서비스 이용약관을 위반하여 회사의 권리, 재산이나 서비스 등을 보호하기 위하여 개인정보를 공개하지 아니할 수 없다고 판단하기에 충분한 근거가 있는 경우
• 사람의 생명, 신체 또는 재산의 보호를 위해 필요한 경우로서 본인의 동의를 얻기가 곤란한 경우
• 공중위생 향상 또는 아동의 건전한 육성 추진을 위해 특별히 필요한 경우에 본인의 동의를 얻기가 어려운 경우
• 국가기관이나 지방자치단체 또는 그 위탁을 받은 자가 법령이 정한 사무를 수행하는 데 협력할 필요가 있는 경우로서 고객의 동의를 얻는 절차가 해당 사무의 수행에 지장을 줄 우려가 있는 경우
• 회사의 합병, 분할, 영업양도 기타 사유로 개인정보의 제공을 포함한 회사의 사업이 승계되는 경우`,
    TITLE_6: `제6조 (정보위탁)`,
    DETAIL_6: `회사는 이용목적 달성에 필요한 범위 내에서 고객으로부터 취득한 전부 또는 일부의 개인정보를 업무위탁 할 수 있습니다. 회사는 업무위탁처의 적격성을 충분히 심사하며 계약시 개인정보보호를 위한 사항 등을 규정하여 정보가 적정하게 관리되는 체제를 구축할 것입니다.`,
    TITLE_7: `제7조 (정보의 공동이용)`,
    DETAIL_7: `회사는 고객의 정보를 아래에 기재한 기업과 다음과 같이 공동으로 이용합니다.
 
공동으로 이용하는 정보 항목:
'제3조 (제공받는 정보)'에 규정된 정보
 
공동으로 이용하는 자의 범위:
코코네 주식회사(회사의 모회사)
CARROT 주식회사(회사의 자회사)
cocone fukuoka 주식회사 (코코네 주식회사의 자회사)
cocone connect 주식회사 (코코네 주식회사의 자회사)
 
공동이용자의 이용목적:
'제4조 (이용목적)' 달성을 위해
 
관리책임자의 명칭:
회사`,
    TITLE_8: `제8조 (고객의 권리)`,
    DETAIL_8: `고객이 회사에 대하여 서비스 사이트 상에서 확인할 수 없는 개인정보의 제공을 원하는 경우, 다음의 경우를 제외하고 회사가 별도로 정한 절차에 따라 공개를 청구할 수 있습니다. 단, 이력 정보 등의 개인정보 이외의 정보에 대해서는, 원칙으로서 개시하지 않습니다.
1. 공개함으로써 본인 또는 제3자의 생명, 신체, 재산, 기타 권리이익을 해할 우려가 있는 경우·공개함으로써 회사의 업무의 적정한 실시에 현저한 지장을 미칠 우려가 있는 경우
2. 정보를 제공하는 것이 법령에 위반되는 경우
3. 정보제공 요청자가 본인임을 확인할 수 없는 경우
고객은 회사가 보유하고 있는 본인의 개인정보의 내용이 사실이 아니라고 판단한 경우에는 회사가 별도로 정한 절차에 따라 개인정보의 정정·추가·삭제를 청구할 수 있습니다. 이 경우 회사는 이용목적 달성에 필요한 범위 내에서 지체 없이 조사하고, 그 결과에 따라 해당 개인정보를 정정·추가·삭제합니다.
또한 서비스 사이트에서 확인할 수 없는 개인정보 공개가 청구되는 경우에는 회사가 별도로 정한 절차에 따라 공개수수료를 부과하는 경우가 있습니다.
회사는 고객으로부터 임의로 고객의 개인정보를 제공받으나, 고객이 서비스 이용에 필요한 정보를 제공하지 않는 경우 일부 서비스의 이용에 제한이 있을 수 있습니다.`,
    TITLE_9: `제9조 (개인정보보호정책의 개정)`,
    DETAIL_9: `본 개인정보보호정책은 개정될 수 있습니다. 중요한 변경이 있는 경우 고객이 알기 쉬운 방법으로 변경사항을 고지합니다.`,
    TITLE_10: `제10조 (개인정보의 처리 및 보유기간)`,
    DETAIL_10: `회사는 개인정보의 수집 및 이용목적이 달성되거나 이용자의 요청이 있는 경우에는 지체 없이 개인정보를 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
 
1. 관련법령에 의해 보유하는 정보
전자상거래 등에서의 소비자보호에 관한 법률, 통신비밀보호법 등 법령의 규정에 의하여 보존할 필요가 있는 경우에는 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관하며, 이 경우 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
 
1) 전자상거래 등에서의 소비자보호에 대한 법률
• 계약 또는 청약철회 등에 관한 기록: 5년
• 대금결제 및 재화 등의 공급에 관한 기록: 5년
• 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
 
2) 통신비밀보호법
• 웹사이트 방문에 관한 기록: 3개월
 
3) 국세기본법
• 거래장부: 5년
 
2. 동의를 얻어 보관하는 개인정보
E-mal 주소`,
    TITLE_11: `제11조 (개인정보의 파기 절차 및 방법)`,
    DETAIL_11: `회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
다만 법령(제10조 참고)에 따라 보존하여야 하는 경우에는 그러하지 않습니다.
파기 절차 및 방법은 다음과 같습니다.
1) 종이에 출력된 개인정보: 분쇄기로 분쇄하거나 소각
2) 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제`,
    TITLE_12: `제12조 (면책)`,
    DETAIL_12: `Kaikas, Paymentwall, 그 밖에 제3자가 개발한 서비스에 의한 고객의 개인정보 수집 및 사용에 대하여 회사는 책임지지 않습니다.`,
    TITLE_13: `제13조 (문의)`,
    DETAIL_13_1: `개인정보 취급에 관한 사항 및 질문은 이하로 연락 주시기 바랍니다.`,
    DETAIL_13_2: `POST VOYAGER PTE.LTD, support@cobalt.zone`,
    UPDATED: `2021. 08. 01 제정`,
  };
  static readonly ja = {
    TITLE: `プライバシーポリシー`,
    DESC: `POST VOYAGER PTE.LTD.（以下、「当社」といいます。）は、当社が提供するサービス「Cobalt」（以下「サービス」といいます。）において、お客様情報を以下の通り取り扱います。`,

    TITLE_1: `第1条（定義）`,

    DETAIL_1: `個人情報とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、①当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報、または、②個人情報保護法にいう「個人識別符号」を含む情報を指します。`,

    TITLE_2: `第2条（Cookie(クッキー)、IPアドレス情報、広告識別子）`,

    DETAIL_2: `クッキー、IPアドレス情報及び広告識別子（Advertising Identifier(iOS)、Google Advertising ID(Android OS)）については、それらが単独で特定の個人を識別することができないため、当社は、これらを個人情報とは考えておりません。ただし、これらの情報と個人情報が一体となって使用される場合には、これらの情報も個人情報とみなします。また、当社は、EU一般データ保護規則（General Data Protection Regulation; GDPR）の適用のあるお客さまに関しては、これらの情報もGDPR上の個人データ（parsonal data）にあたるものとして、GDPRに従い取り扱います。
なお、クッキー情報や広告識別子については、ブラウザやスマートフォンアプリの設定で拒否することが可能です。クッキーを拒否すると当社のサービスが受けられない場合は、その旨もサービス上において公表します。`,

    TITLE_3: `第3条（ご提供いただく情報）`,

    DETAIL_3: `当社は、お客様がサービスをご利用するにあたり、次に列挙する情報を取得することがあります。
• アカウント名その他プロフィール情報
• メールアドレス
• 利用記録、トランザクション、ウォレットのアドレス
• 金融機関の口座情報
• 氏名、住所、電話番号、性別
• 年齢区分
• 端末機種名、OS情報、ユーザーエージェント
• サービス利用情報［Cookie（クッキー）・アクセスログ（IPアドレス、ブラウザ種類、ブラウザ言語等）・広告識別子（Advertising Identifer、Google Advertising ID）など］`,

    TITLE_4: `第4条（情報の利用目的）`,

    DETAIL_4: `当社は取得した個人情報を以下の目的で利用します。なお、取得時に利用目的を限定した場合は、その範囲内でのみ利用します。
• サービスを円滑にご利用いただき、お客様間での円滑なコミュニケーションを図るため
• サービスに関する連絡やお知らせ等の配信・送付のため
• アカウントのIDとして利用するため
• ご本人確認及び正常なサービス提供を妨害する不正行為防止のため
• お客様からのお問合せ対応を行うため
• お客様のトラフィック測定、行動測定を行うため
• サービス内容の改善、新サービスの開発のため
• 売上金を振り込むため、振込状況を確認するため
• お客様へのアンケート実施による統計・分析のため
• サービス内でキャンペーンを行った際等の抽選・賞品の発送等のため
• サービス利用の可否を判断するため
• 利用規約上の禁止事項や不正出品を含むサービスの利用状況等を調査・分析するため`,

    TITLE_5: `第5条（情報の提供）`,

    DETAIL_5: `当社はお客様の個人情報を以下の場合を除いて第三者に提供することはありません。
• 本人が事前に同意した場合
• 法律に基づく場合
• お客様がサービスの利用規約に違反し、弊社の権利、財産やサービス等を保護するために、個人情報を公開せざるをえないと判断するに足る十分な根拠がある場合
• 人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
• 公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合
• 国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがある場合
• 合併、会社分割、営業譲渡その他の事由によって個人情報の提供を含む当社の事業の承継が行われる場合`,

    TITLE_6: `第6条（情報の預託）`,

    DETAIL_6: `当社は利用目的の達成に必要な範囲内において、お客様から取得した個人情報の全部又は一部を、業務委託先に預託することがあります。その際、業務委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を規定し、情報が適正に管理される体制作りを行います。`,

    TITLE_7: `第7条（情報の共同利用）`,

    DETAIL_7: `当社はお客様の情報を以下に記載する企業と次の通り共同利用いたします。
共同利用する情報の項目
「第3条（ご提供いただく情報）」に規定されている情報
 
共同して利用する者の範囲
ココネ株式会社（当社の親会社）
CARROT株式会社（当社の子会社）
cocone fukuoka株式会社（ココネ株式会社の子会社）
cocone connect株式会社（ココネ株式会社の子会社）
 
共同利用する者の利用目的
「第4条（情報の利用目的）」の達成のため
 
管理責任者の名称
当社`,

    TITLE_8: `第8条（お客様の権利）`,

    DETAIL_8: `お客様は当社に対し、サービスサイト上で確認できない個人情報の開示を求める場合、当社が別途定めた手続きに従って、次の場合を除き開示を請求することができます。ただし、履歴情報などの個人情報以外の情報については，原則として開示いたしません。
（1）開示することで本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合・開示することで当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
（2）開示することが法令に違反することとなる場合
（3）開示の請求が本人からであることが確認できない場合
お客様は、開示の結果、当社保有の個人情報の内容が、事実ではないと判断した場合は、当社が別途定めた手続きに従って、個人情報の訂正・追加・削除を請求することができます。その場合、当社は利用目的の達成に必要な範囲内で遅滞なく調査を行い、その結果に基づき、当該個人情報の訂正・追加・削除を行います。
なお、サービスサイトで確認できない個人情報の開示を請求される場合には、当社が別途定めた手続きに従って、開示手数料をいただく場合があります。
お客様から当社への個人情報の提供は任意ですが、必要な情報をご提供いただけない場合、サービスの一部を利用できない場合があります。`,

    TITLE_9: `第9条（プライバシーポリシーの改定）`,

    DETAIL_9: `本プライバシーポリシーは改定されることがあります。重要な変更につきましては、お客様に対して分かりやすい方法で改定内容を告知いたします。`,

    TITLE_10: `第10条（免責事項）`,

    DETAIL_10: `Kaikas、Paymentwall 、その他第三者が開発したサービスによるお客様の個人情報の収集および使用に関しては、当社は責任を負いません。`,

    TITLE_11: `第11条（お問い合わせ窓口）`,

    DETAIL_11_1: `個人情報の取扱いに関するお問い合わせ、苦情及び相談につきましては、以下の当社「個人情報お問合せ窓口」にご連絡ください。
 
POST VOYAGER PTE.LTD個人情報お問合せ窓口`,
    DETAIL_11_2: `Eメールアドレス：support@cobalt.zone`,

    UPDATED: `2021年8月1日制定`,
  };

  static readonly en = {
    TITLE: `Privacy Policy`,
    DESC: `POST VOYAGER PTE.LTD (hereinafter referred to as \"Company\") treats customer information as follows in the company's service \"Cobalt\" (hereinafter referred to as \"Service\").`,
    TITLE_1: `Article 1 (Definition)`,
    DETAIL_1: `Personal information refers to the 'personal information' referred to in the The Personal Data Protection Act, which identifies a particular person by name, date of birth, address, phone number, contact, or other technology included in the information.`,
    TITLE_2: `Article 2 (Cookie, IP Address, Ad Identifier)`,
    DETAIL_2: `Cookies, IP address information, and ad identifiers (Advertising Identifier (iOS), Google Advertising ID (Android OS) cannot identify specific individuals alone, so the company does not treat them as personal information. However, if such information and personal information are used together, such information is considered personal information. The company also treats customers with EU General Data Protection Regulation (GDPR) under GDPR as such information corresponds to personal data on GDPR.
Cookie information or advertising identifiers can be rejected in the browser or smartphone app settings. If you are unable to receive the company's services in the event of a cookie rejection, the purpose of this is also announced in the service.`,
    TITLE_3: `Article 3 (Information Received)`,
    DETAIL_3: `The company can acquire the information listed below in the customer's use of the service:
• Account Name Other Profile Information
• Email Address
• Usage records, transactions, wallet addresses.
• Financial institution account information
• Name, address, phone number, gender.
• Age
• device name, OS information, user agent
• Service usage informationCCookie, access log (IP address, browser type, browser language, etc.), ad identifier (Advertising Identifier, Google Advertising ID), etc.］`,
    TITLE_4: `Article 4 (Purpose of Use)`,
    DETAIL_4: `The company uses the obtained personal information for the following purposes: Also, if the purpose of use is limited at the time of acquisition, it is only used within that scope.
• To facilitate the use of services and the smooth communication between customers,
• To communicate notifications, etc. needed to use the service
• To use as an account ID.
• To prevent fraud that hinders identification and normal service provision,
• To respond to customer inquiries
• To measure customer traffic, to measure behavior,
• To improve service content and develop new services,
• To deposit the sales, to check the status of the deposit,
• For statistics and analysis based on questionnaires to customers
• For the purpose of drawing and sending of products when a campaign is carried out in the service, etc.
• To determine service availability
• To investigate and analyze the use of services, etc.`,
    TITLE_5: `Article 5 (Information provision)`,
    DETAIL_5: `The company shall not provide customer's personal information to third parties except in the following cases:
• If you have agreed in advance
• In accordance with the law
• Where a customer has sufficient grounds to determine that he/she shall not disclose personal information in order to protect the rights, property, services, etc. of the company in violation of the Terms of Use of Services;
• Where it is necessary for the protection of a person's life, body or property and it is difficult to obtain his/her consent;
• Where it is difficult to obtain his/her consent when it is particularly necessary to improve public hygiene or promote sound nurturing of children;
• Where it is necessary for a national institution, a local government, or a person entrusted thereto to cooperate in performing the affairs prescribed by statute, and the procedure for obtaining customer consent may interfere with the performance of the relevant affairs;
• Where the business of the Company, including the provision of personal information, is succeeded due to the merger, division, and transfer of operations of the Company;`,
    TITLE_6: `Article 6 (Consignment of Information)`,
    DETAIL_6: `The company may entrust all or some personal information acquired from the customer to the extent necessary to achieve the purpose of use. The company will fully examine the eligibility of the entrustment and establish a system in which information is properly managed by stipulating matters for protecting personal information when contracting.`,
    TITLE_7: `Article 7 (Combined Use of Information)`,
    DETAIL_7: `The company will jointly use the customer's information with the company listed below:
 
Common Information Items:
Information provided for in Article 3 (Information received)
 
Scope of co-operators:
Cocone Inc. (mother company)
CARROT Inc. (Subsidiary of the Company)
Cocone fukuoka Inc. (Subsidiary of Cocone Inc.)
Cocone connect, Inc. (Subsidiary of Cocone Inc.)
 
Purpose of use by joint users:
To achieve Article 4 (Purpose of Use),
 
Name of the person in charge of management:
The company`,
    TITLE_8: `Article 8 (Customer's Rights)`,
    DETAIL_8: `If the customer wishes to provide personal information that cannot be verified on the service site, he/she may request disclosure in accordance with the company's procedures, except in the following cases: However, information other than personal information such as history information shall not be initiated in principle.
1. Where disclosure may harm the life, body, property, or other rights interests of the person or third party, or may significantly interfere with the performance of the company's affairs by disclosing such disclosure;
2. In the event that providing information is in violation of the statute;
3. If it is not possible to verify that the person who requested the information is you.
If the customer determines that the details of his/her personal information held by the company are not true, he/she may request correction, addition, or deletion of personal information in accordance with the procedures separately set by the company. In such cases, the company shall investigate without delay within the scope necessary to achieve the purpose of use, and correct, add, or delete the relevant personal information according to the result.
In addition, if a disclosure of personal information is claimed that cannot be found on the service site, a disclosure fee may be imposed in accordance with the company's separately established procedures.
The company receives the customer's personal information at random from the customer, but if the customer does not provide the information necessary to use the service, there may be restrictions on the use of some services.`,
    TITLE_9: `Article 9 (Revision of Privacy Policy)`,
    DETAIL_9: `This Privacy Policy is subject to revision. If there is a significant change, notify the customer of the change in an easy-to-know manner.`,
    TITLE_10: `Article 10 (Exemption)`,
    DETAIL_10: `The company shall not be responsible for the collection and use of personal information of customers by Kaikas, PG, or other services developed by third parties.`,
    TITLE_11: `Article 11 (Inquiry)`,
    DETAIL_11_1: `Please contact us below for personal information handling and questions.`,
    DETAIL_11_2: `POST VOYAGER PTE.LTD, support@cobalt.zone`,
    UPDATED: `2021. 08. 01`,
  };
}
