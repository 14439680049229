/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useCallback, useEffect, useState } from 'react';
import { config, useSpring } from 'react-spring';
import { useWindowScroll, useWindowSize } from 'react-use';
import ScreenInfo, { MOBILE_WIDTH } from 'src/context/screen_constants';
import { useDlg } from 'src/hooks/useDlg';
import { DlgStateJsxMulti_i } from 'src/redux/modules/dlgModule';
import R from 'src/res/R';
import {
  AnimatedFlexColumnDiv,
  devOutlineF,
  devOutlineFB,
  devOutlineFG,
  FlexColumnDiv,
  FlexDiv,
  FlexRowDiv,
  HEIGHT,
} from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { CommonColumnBox } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  dlg: DlgStateJsxMulti_i;
};

export function DlgJsxMulti({ dlg }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sShow, setShow] = useState(false); // 현재 보여짐 상태

  /**************************************
   * !! hooks
   **************************************/
  const { y: scrollY } = useWindowScroll();
  const windowSize = useWindowSize();
  const hDialog = useDlg();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (dlg.showHideTrigger) {
      // redux 상태가 변경으로 show / hide함수를 호출
      show();
    } else {
      hide();
    }
    return () => {};
  }, [dlg.showHideTrigger]); // dlg 정보가 변경되면 실행되는 effect

  /**************************************
   * !! arrow function
   **************************************/
  const show = useCallback(() => {
    set({
      opacity: 1,
      config: { ...config.stiff },
      onStart: () => {
        DBGMSG('$$$DLG show start');
        setShow(true); // 보여지기 시작하면 show상태 true
      },
      onRest: () => {
        DBGMSG('$$$DLG show rest');
      },
    });
  }, []);

  const hide = useCallback(() => {
    set({
      opacity: 0,
      config: { ...config.stiff },
      onStart: () => {
        DBGMSG('$$$DLG hide start');
      },
      onRest: () => {
        DBGMSG('$$$DLG hide rest');
        setShow(false); // 완전히 사라지면 show상태 false
        // 에니메이션이 끝난후 최종적으로 state를 변경함
        if (!dlg.key) {
          DBGMSG('$$$DLG closeDlgJsxMultiLast');
          hDialog.act.closeDlgJsxMultiLast();
        } else {
          DBGMSG(`$$$DLG closeDlgJsxMultiByKey ${dlg.key}`);
          hDialog.act.closeDlgJsxMultiByKey({ key: dlg.key });
        }
      },
    });
  }, [dlg.key]);

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    // default 초기상태
    if (sShow) {
      return {
        opacity: 1,
      };
    } else {
      // rerendering 됬을때 sShow 상태로 초기값을 설정
      return {
        opacity: 0,
      };
    }
  });

  if (!sShow) {
    return null;
  }

  return (
    <AnimatedFlexColumnDiv
      style={{
        position: 'fixed',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: R.colors.dialogBg,
        overflow: 'scroll',
        zIndex: 100,
        // alignItems: 'center',
        // justifyContent: 'center',
        ...devOutlineFB,
        ...hMouseProps,
      }}
      onClick={() => {
        DBGMSG('onBackdropClick');
        if (dlg.onBackdropClick) dlg.onBackdropClick();
        else hide();
      }}
    >
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            width: ScreenInfo.isMobile() ? MOBILE_WIDTH : windowSize.width,
            minHeight: ScreenInfo.isMobile() ? windowSize.height / ScreenInfo.scale : windowSize.height,
            alignItems: 'center',
            // justifyContent: 'flex-start',
            justifyContent: (function () {
              if (ScreenInfo.isMobile()) {
                return dlg.paddingTop === undefined ? 'center' : 'flex-start';
                // return 'center';
              } else {
                return 'center';
              }
            })(),
            paddingTop: ScreenInfo.isMobile() ? dlg.paddingTop : undefined,
            // backgroundColor: R.colors.devColor1a,
          }}
        >
          {/* <HEIGHT size={450} /> */}
          {/* ********************************************** */}
          {/* 다이얼로그 박스 */}
          {/* ********************************************** */}
          <CommonColumnBox
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: dlg.bgTransparent === true ? 'transparent' : undefined,
              borderWidth: dlg.bgTransparent === true ? 0 : 1,
              boxShadow: dlg.bgTransparent === true ? 'none' : 'unset',
              // ...devOutlineFB,
            }}
            onClick={(e) => {
              DBGMSG('onDialog');
              e.stopPropagation();
            }}
          >
            {dlg.jsx}
          </CommonColumnBox>
          {/* ********************************************** */}
        </FlexColumnDiv>
      </MobileScalableFlexColumn>
    </AnimatedFlexColumnDiv>
  );
}
