/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import ScreenInfo, { MOBILE_CONTENT_WIDTH } from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtCurrency_e, CbtReqPriceInfo_i, CbtResPriceInfo_i } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, devOutlineFG, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { CURRENCY_ROUND_DECIMAL_POINT_CBLT, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
const MB_DEFAULT_BOX_PADDING = 16;

const DT_IC_WIDTH = 18;
const MB_IC_WIDTH = 16;
const IC_WIDTH = ScreenInfo.isMobile() ? MB_IC_WIDTH : DT_IC_WIDTH;

const DT_IC_HEIGHT = 20;
const MB_IC_HEIGHT = 18;
const IC_HEIGHT = ScreenInfo.isMobile() ? MB_IC_HEIGHT : DT_IC_HEIGHT;

const DT_PRICE_FONTSIZE = 26;
const MB_PRICE_FONTSIZE = 16;
const PRICE_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_FONTSIZE : DT_PRICE_FONTSIZE;

const DT_DEFAULT_FONTSIZE_IN_BOX = 14;
const MB_DEFAULT_FONTSIZE_IN_BOX = 10;
const DEFAULT_FONTSIZE_IN_BOX = ScreenInfo.isMobile() ? MB_DEFAULT_FONTSIZE_IN_BOX : DT_DEFAULT_FONTSIZE_IN_BOX;

const DT_BALANCE_CONTAINER_WIDTH = (582 - 43) / 2;
const MB_BALANCE_CONTAINER_WIDTH = MOBILE_CONTENT_WIDTH - MB_DEFAULT_BOX_PADDING * 2;
const BALANCE_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_BALANCE_CONTAINER_WIDTH : DT_BALANCE_CONTAINER_WIDTH;

const DT_PRICE_UNIT_FONTSIZE = 16;
const MB_PRICE_UNIT_FONTSIZE = 10;
const PRICE_UNIT_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_UNIT_FONTSIZE : DT_PRICE_UNIT_FONTSIZE;

type Props = {
  prc_info?: CbtResPriceInfo_i;
  price?: string;
  unit?: string;
  priceFontSize?: number;
  unitFontSize?: number;
  isBold?: boolean;
  fontColor?: string;
};

export function PriceSimple(props: Props) {
  // const [sIc, setIc] = useState<string>();
  const [sPrice, setPrice] = useState<string>('');
  const [sUnit, setUnit] = useState<string>('');
  // const [sTooltip, setTooltip] = useState<string>();

  useEffect(() => {
    DBGMSG(props);
    if (props.prc_info) {
      setPrice(
        Utils.currency.getPriceForDP({
          prcInfo: props.prc_info,
          decimal:
            props.prc_info.curr_info.currency === CbtCurrency_e.CBLT ? CURRENCY_ROUND_DECIMAL_POINT_CBLT : CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
        })
      );
      setUnit(Utils.svc.getCurrencyUnit(props.prc_info.curr_info.currency));
    } else if (props.price && props.unit) {
      setPrice(props.price);
      setUnit(props.unit);
    } else {
      DBGMSGW('what');
    }

    return () => {};
  }, []);

  DBGMSG('render');
  return (
    <FlexColumnDiv
      style={{
        justifyContent: 'center',
        // alignItems: ScreenInfo.isMobile() ? 'flex-end' : undefined,
        // ...devOutlineF
      }}
    >
      <FlexRowDiv
        style={{
          alignItems: 'baseline',
          // ...devOutlineF
        }}
      >
        <TextCon
          text={sPrice}
          size={props.priceFontSize ?? PRICE_FONTSIZE}
          color={props.fontColor ?? R.colors.black}
          isBold={props.isBold === undefined ? true : props.isBold}
        />
        <WIDTH size={ScreenInfo.isMobile() ? 2 : 4} />
        <TextCon
          text={sUnit}
          size={props.unitFontSize ?? PRICE_UNIT_FONTSIZE}
          color={props.fontColor ?? R.colors.black}
          isBold={props.isBold === undefined ? true : props.isBold}
        />
      </FlexRowDiv>
    </FlexColumnDiv>
  );
}
