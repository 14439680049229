import 'firebase/analytics';
import firebase from 'firebase/app';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffectOnce, useInterval, useWindowSize } from 'react-use';
import SockJS from 'sockjs-client';
import 'src/sass/app.scss';
import { AppConf, isAlpha, isDev } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useAuthStorage } from 'src/hooks/useAuthStorage';
import { useDlg } from 'src/hooks/useDlg';
import { useKaikas } from 'src/hooks/useKaikas';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { ClientType_e } from 'src/hooks/useScreenWidthHelper';
import { useStompJS } from 'src/hooks/useStompJS';
import { AuthStatus } from 'src/model/model';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { AppEventType, CbtStompMsgCode_e, CbtStomp_i, messageService } from 'src/rx/messageService';
import { devOutlineF, devOutlineFB, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { CommonHeader } from 'src/ui/common_component/CommonHeader';
import { ScrollToTop } from 'src/ui/common_component/ScrollToTop';
import { DlgContainer } from 'src/ui/dlg/DlgContainer';
import { CLAY, COMMONHEADER_LAYOUT, PropsCommonBox } from 'src/ui/layout_constant';
import { AssetCreatorBlockedScreen } from 'src/ui/screen/AssetCreator/AssetCreatorBlockedScreen';
import { AssetCreatorScreen } from 'src/ui/screen/AssetCreator/AssetCreatorScreen';
import { MyAssetGroupCScreen } from 'src/ui/screen/AssetCreator/MyAssetGroupCScreen';
import { MyAssetGroupListScreen } from 'src/ui/screen/AssetCreator/MyAssetGroupListScreen';
import { MyCollectionCScreen } from 'src/ui/screen/AssetCreator/MyCollectionCScreen';
import { MyCollectionRScreen } from 'src/ui/screen/AssetCreator/MyCollectionRScreen';
import { MyCollectionUScreen } from 'src/ui/screen/AssetCreator/MyCollectionUScreen';
import { MyIssueAssetRScreen } from 'src/ui/screen/AssetCreator/MyIssueAssetRScreen';
import { MySchemaCScreen } from 'src/ui/screen/AssetCreator/MySchemaCScreen';
import { MySchemaRScreen } from 'src/ui/screen/AssetCreator/MySchemaRScreen';
import { MySchemaUScreen } from 'src/ui/screen/AssetCreator/MySchemaUScreen';
import { AssetmgrAssetRScreen } from 'src/ui/screen/AssetMgr/AssetmgrAssetRScreen';
import { MyInventoryAstgDetailScreen } from 'src/ui/screen/AssetMgr/MyInventoryAstgDetailScreen';
import { MyIssueAstgDetailScreen } from 'src/ui/screen/AssetMgr/MyIssueAstgDetailScreen';
import { UserDetailMineScreen } from 'src/ui/screen/AssetMgr/UserDetailMineScreen';
import { UserDetailScreen } from 'src/ui/screen/AssetMgr/UserDetailScreen';
import { UserInventoryAstgDetailScreen } from 'src/ui/screen/AssetMgr/UserInventoryAstgDetailScreen';
import { UserIssueAstgDetailScreen } from 'src/ui/screen/AssetMgr/UserIssueAstgDetailScreen';
import { ExplorerScreen } from 'src/ui/screen/Explorer/ExplorerScreen';
import { MarketAssetRScreen } from 'src/ui/screen/Marketplace/MarketAssetRScreen';
import { MarketCollectionRScreen } from 'src/ui/screen/Marketplace/MarketCollectionRScreen';
import { MarketPaymentScreen } from 'src/ui/screen/Marketplace/MarketPaymentScreen';
import { MarketPlaceScreen } from 'src/ui/screen/Marketplace/MarketPlaceScreen';
import { MarketSchemaRScreen } from 'src/ui/screen/Marketplace/MarketSchemaRScreen';
import { Nav } from 'src/ui/screen/Nav';
import { MyPropertyHistoryScreen } from 'src/ui/screen/User/MyPropertyHistoryScreen';
import { NotiListScreen } from 'src/ui/screen/User/NotiListScreen';
import { UserBanScreen } from 'src/ui/screen/User/UserBanScreen';
import { UserChangeFishCodeScreen } from 'src/ui/screen/User/UserChangeFishCodeScreen';
import { UserChangePasswordScreen } from 'src/ui/screen/User/UserChangePasswordScreen';
import { UserJoinScreen } from 'src/ui/screen/User/UserJoinScreen';
import { UserLoginScreen } from 'src/ui/screen/User/UserLoginScreen';
import { UserPrivacyPolicyScreen } from 'src/ui/screen/User/UserPrivacyPolicyScreen';
import { UserRefundPolicyScreen } from 'src/ui/screen/User/UserRefundPolicyScreen';
import { UserResetPasswordScreen } from 'src/ui/screen/User/UserResetPasswordScreen';
import { UserTermsOfUseScreen } from 'src/ui/screen/User/UserTermsOfUseScreen';
import KaikasScreen from 'src/ui/test_screen/KaikasScreen';
import { LabScreen } from 'src/ui/test_screen/LabScreen';
import { PlayScreen } from 'src/ui/test_screen/PlayScreen';
import { TestLoginScreen } from 'src/ui/test_screen/TestLoginScreen';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import Stomp from 'stompjs';

/************************************************************************************
 * StompJs begin
 ************************************************************************************/
let stompWebSocket: WebSocket | null = null;
let stompClient: Stomp.Client | null = null;
let stompSubcription: Stomp.Subscription | null = null;
let stompLastPingPongTime: number = Utils.date.now().getTime();

export const setStompWebSocket = (_stompWebSocket: WebSocket | null) => {
  stompWebSocket = _stompWebSocket;
};
export const setStompClient = (_stompClient: Stomp.Client | null) => {
  stompClient = _stompClient;
};
export const setStompSubcription = (_stompSubcription: Stomp.Subscription | null) => {
  stompSubcription = _stompSubcription;
};
export const setStompLastPingPongTime = (_lastPingPongTime: number) => {
  stompLastPingPongTime = _lastPingPongTime;
};

export const getStompWebSocket = () => {
  return stompWebSocket;
};
export const getStompClient = () => {
  return stompClient;
};
export const getStompSubcription = () => {
  return stompSubcription;
};
export const getStompLastPingPongTime = () => {
  return stompLastPingPongTime;
};
/************************************************************************************
 * StompJs End
 ************************************************************************************/

function App() {
  /**************************************
   * !! state
   **************************************/
  // 새로고침시 true default 값으로, useEffectOnce에서 자동로그인을 시도함
  const [sIsBootstrapping, setIsBootstrapping] = useState(true);

  /**************************************
   * !! hooks
   **************************************/
  const hLocalSettings = useLocalSettings();
  const hAuthStorage = useAuthStorage();
  const { authActs, authState, isLogin } = useAuth();
  const hWindowSize = useWindowSize();
  const hStompJS = useStompJS();

  /**************************************
   * !! effect
   **************************************/

  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((message) => {
      switch (message.kind) {
        // case 'AppEventMsg':
        //   switch (message.type) {
        //     case AppEventType.StompDisconnected:
        //       DBGMSGW(`$$$$MSG AppEventType.StompDisconnected`);
        //       if (isLogin) hStompJS.stompConnect();
        //       break;
        //   }
        //   break;
        case 'NormalMsg':
          DBGMSG(`$$$$MSG onMessage: ${message.text}`);
          notify({ msg: message.text });
          break;
        case 'ReceiveStompMsg':
          notify({ msg: message.content, code: message.code });
          // code(행동)은 각 screen이 구독하여 결정
          break;
        case 'PublishStompMsg':
          stompClient?.send('/app/msg', {}, JSON.stringify(message));
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useInterval(() => {
    if (isLogin) {
      const diffMs = Utils.date.now().getTime() - stompLastPingPongTime;
      if ((stompLastPingPongTime !== 0 && diffMs > 20000) || stompClient?.connected === false) {
        DBGMSGW(
          `$$$$STOMPJS try reconnect ${Utils.date.brewFomatString(
            stompLastPingPongTime,
            'yyyy-MM-dd HH:mm:ss.SSS'
          )} ${stompLastPingPongTime} ${diffMs}ms ${stompClient?.connected}`
        );
        stompLastPingPongTime = 0;
        hStompJS.stompConnect();
      }
    }
  }, 5000);

  useEffectOnce(() => {
    DBGMSG('useEffectOnce');

    // Firebase Analytics 설정
    if (!isDev()) {
      firebase.initializeApp({
        apiKey: 'AIzaSyDWHYP3T936rANKbQNmrzbGIzc8kNRxB44',
        authDomain: 'cobalt-5260b.firebaseapp.com',
        projectId: 'cobalt-5260b',
        storageBucket: 'cobalt-5260b.appspot.com',
        messagingSenderId: '901456309380',
        appId: '1:901456309380:web:aa6e472465272281a8a6d1',
        measurementId: 'G-WJJ1EMN0NJ',
      });
      // const fa = firebase.analytics();
      // fa.logEvent('App log test');
    }

    // 저장해둔 설정 복구
    if (hLocalSettings.isExistSettings()) {
      hLocalSettings.loadFromLocalSettings();
    } else {
      // 나라별 언어 설정 (첫접속시에만)
      Utils.locale.getGeoInfo().then(({ countryCode, countryName }) => {
        DBGMSG(`countryCode: ${countryCode}`);
        DBGMSG(`countryName: ${countryName}`);
        switch (countryCode) {
          case 'KR':
            hLocalSettings.changeLang(CbtUserLanguage_e.KO);
            break;
          case 'JP':
            hLocalSettings.changeLang(CbtUserLanguage_e.JA);
            break;
          default:
            hLocalSettings.changeLang(CbtUserLanguage_e.EN);
            break;
        }
      });
    }

    autoLogin();
  });

  useEffect(() => {
    if (sIsBootstrapping) {
      return;
    }

    if (authState.authStatus === AuthStatus.Login) {
      DBGMSG(`$$$$STOMPJS call stompConnect()`);
      hStompJS.stompConnect();

      // 로그인 됬을때 언어 설정정보 서버에 전송
      // hRpc.cobaltRpc.rpcUserAccountLangSet({ lang: hLocalSettings.lang });
    } else {
      if (stompClient?.connected === true) {
        DBGMSG(`$$$$STOMPJS DISCONNECT`);
        hStompJS.stompDisconnect();
      }
    }

    return () => {};
  }, [authState.authStatus, sIsBootstrapping]);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  const autoLogin = async () => {
    // 유효한 인증정보가 남아 있는지 체크
    const localAuthInfo = hAuthStorage.getAuthInfo();
    DBGMSG(`$$$$AUTH_CHECK ${JSON.stringify(localAuthInfo, undefined, 4)}`);

    // 유효한 인증정보 (새로고침일경우)
    if (localAuthInfo && hAuthStorage.isValidTokenInfo()) {
      DBGMSG(`$$$$AUTH_CHECK token is okey`);

      DBGMSG(`$$$$AUTH_CHECK loginWithLocalAuthInfo`);
      authActs.loginWithLocalAuthInfo(localAuthInfo);
      // 카이카스 연동
      // 이미 연결어 있는 경우, 자동으로 지갑연동
      // if (hKaikas.isAlreadyConnected()) {
      //   DBGMSG(`$$$$KAIKAS isAlreadyConnected`);
      //   try {
      //     await hKaikasConnectWrapper.kaikasConnectAndRewardAddrReg();
      //   } catch (err) {}
      // }
    } else {
      // 유효하지 않은 인증정보 삭제
      hAuthStorage.clearAuthInfo();
      DBGMSGW('logout');
      authActs.logout();
    }
    // 세션체크
    // await Utils.sleep.mssleep(1000);
    setIsBootstrapping(false);
  };

  const notify = async ({ msg, code }: { msg: string; code?: CbtStompMsgCode_e }) => {
    toast(<CbtToast text={msg} code={code} />);
  };

  const renderBootstrapping = () => {
    DBGMSG('renderSessionChecking');
    return (
      <FlexColumnDiv style={{ width: '100%', height: 300, justifyContent: 'center', alignItems: 'center' }}>
        <TextCon text={'session check'} size={20} isBold color={R.colors.cloudyBlue} />
      </FlexColumnDiv>
    );
  };

  const renderScreen = () => {
    DBGMSG('renderScreen');
    return (
      <>
        <ScrollToTop />
        <Switch>
          <Route path={Nav.HomeScreen}>
            <ExplorerScreen />
          </Route>
          {/* Explorer */}
          <Route path={Nav.ExplorerScreeen}>
            <ExplorerScreen />
          </Route>
          {/* AssetCreator */}
          <PrivateRoute path={Nav.AssetCreatorScreen}>
            <AssetCreatorScreen />
          </PrivateRoute>
          {/* AssetCreator Blocked*/}
          <PrivateRoute path={Nav.AssetCreatorBlockedScreen}>
            <AssetCreatorBlockedScreen />
          </PrivateRoute>
          {/* Marketplace */}
          <Route path={Nav.MarketPlaceScreen}>
            <MarketPlaceScreen />
          </Route>

          {/* collection cru */}
          <PrivateRoute path={Nav.MyCollectionCScreen}>
            <MyCollectionCScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MyCollectionRScreen}>
            <MyCollectionRScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MyCollectionUScreen}>
            <MyCollectionUScreen />
          </PrivateRoute>

          {/* schema cru */}
          <PrivateRoute path={Nav.MySchemaCScreen}>
            <MySchemaCScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MySchemaRScreen}>
            <MySchemaRScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MySchemaUScreen}>
            <MySchemaUScreen />
          </PrivateRoute>

          {/* asset cr */}
          <PrivateRoute path={Nav.MyAssetGroupCScreen}>
            <MyAssetGroupCScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MyAssetGroupListScreen}>
            <MyAssetGroupListScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MyIssueAssetRScreen}>
            <MyIssueAssetRScreen />
          </PrivateRoute>

          {/* market */}
          <Route path={Nav.MarketCollectionRScreen}>
            <MarketCollectionRScreen />
          </Route>
          <Route path={Nav.MarketSchemaRScreen}>
            <MarketSchemaRScreen />
          </Route>
          <Route path={Nav.MarketAssetRScreen}>
            <MarketAssetRScreen />
          </Route>
          <PrivateRoute path={Nav.MarketPaymentScreen}>
            <MarketPaymentScreen />
          </PrivateRoute>

          {/* user */}
          <Route path={Nav.UserJoinScreen}>
            <UserJoinScreen />
          </Route>
          <Route path={Nav.UserLoginScreen}>
            <UserLoginScreen />
          </Route>
          <Route path={Nav.UserResetPasswordScreen}>
            <UserResetPasswordScreen />
          </Route>
          <PrivateRoute path={Nav.UserChangeFishCodeScreen}>
            <UserChangeFishCodeScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.UserChangePasswordScreen}>
            <UserChangePasswordScreen />
          </PrivateRoute>
          <Route path={Nav.UserBan}>
            <UserBanScreen />
          </Route>
          <PrivateRoute path={Nav.NotiListScreen}>
            <NotiListScreen />
          </PrivateRoute>
          <Route path={Nav.UserTermsOfUseScreen}>
            <UserTermsOfUseScreen />
          </Route>
          <Route path={Nav.UserPrivacyPolicyScreen}>
            <UserPrivacyPolicyScreen />
          </Route>
          <Route path={Nav.UserRefundPolicyScreen}>
            <UserRefundPolicyScreen />
          </Route>

          {/* Assetmgr */}
          <Route path={Nav.AssetmgrAssetRScreen}>
            <AssetmgrAssetRScreen />
          </Route>
          {/* Market Account */}
          <Route path={Nav.UserDetailScreen}>
            <UserDetailScreen />
          </Route>
          <Route path={Nav.UserInventoryAstgDetailScreen}>
            <UserInventoryAstgDetailScreen />
          </Route>
          <Route path={Nav.UserIssueAstgDetailScreen}>
            <UserIssueAstgDetailScreen />
          </Route>

          {/* My account */}
          <PrivateRoute path={Nav.UserDetailMineScreen}>
            <UserDetailMineScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MyInventoryAstgDetailScreen}>
            <MyInventoryAstgDetailScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MyIssueAstgDetailScreen}>
            <MyIssueAstgDetailScreen />
          </PrivateRoute>
          <PrivateRoute path={Nav.MyPropertyHistoryScreen}>
            <MyPropertyHistoryScreen />
          </PrivateRoute>

          {/* 테스트용 begin */}
          <Route path={Nav.TEST_LOGIN}>{isAlpha() && <TestLoginScreen />}</Route>
          <Route path={Nav.TEST_KAIKAS}>{isAlpha() && <KaikasScreen />}</Route>
          <Route path={Nav.TEST_PLAY}>{isAlpha() && <PlayScreen />}</Route>
          <Route path={Nav.LabScreen}>{isAlpha() && <LabScreen />}</Route>
          {/* 테스트용 end */}

          <Route path="*">
            <Redirect to={Nav.HomeScreen} />
          </Route>
        </Switch>
      </>
    );
  };

  if (ScreenInfo.clientType === ClientType_e.Desktop || ScreenInfo.clientType === ClientType_e.Tablet) {
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          minHeight: hWindowSize.height,
          // ...devOutlineFG
        }}
      >
        {/* 공통헤더 */}
        <FlexColumnDiv
          style={{
            width: hWindowSize.width,
            position: 'fixed',
            zIndex: 10,
            backgroundColor: R.colors.bgGrey,
            // ...devOutlineFB,
          }}
        >
          <FlexRowDiv
            style={{
              width: hWindowSize.width,
            }}
          >
            {/* 왼쪽 여백 */}
            <FlexDiv style={{ flex: 1 }} />
            <FlexColumnDiv
              style={{
                width: CLAY.BODY_WIDTH,
                // ...devOutlineF
                zIndex: 11,
              }}
            >
              <CommonHeader />
            </FlexColumnDiv>

            {/* 오른쪽 여백 */}
            <FlexDiv style={{ flex: 1 }} />
          </FlexRowDiv>
          <HLINE size={'100%'} />
        </FlexColumnDiv>

        {sIsBootstrapping ? (
          renderBootstrapping()
        ) : (
          <FlexRowDiv style={{ flex: 1, paddingTop: COMMONHEADER_LAYOUT.height }}>
            {/* 왼쪽 여백 */}
            <FlexColumnDiv
              style={{
                flex: 1,
              }}
            />

            {/* Body */}
            <FlexColumnDiv
              style={{
                alignItems: 'center',
                width: CLAY.BODY_WIDTH,
                // ...devOutlineF,
              }}
            >
              {renderScreen()}
            </FlexColumnDiv>

            {/* 오른쪽 여백 */}
            <FlexColumnDiv
              style={{
                flex: 1,
              }}
            />
          </FlexRowDiv>
        )}

        {/* 공통푸터 */}
        <FlexColumnDiv
          style={{
            // width: ScreenInfo.windowInnerWidth,
            backgroundColor: R.colors.grayBox,
            marginTop: 'auto',
            // ...devOutlineF,
          }}
        >
          <HLINE size={'100%'} />

          <FlexRowDiv>
            <FlexDiv style={{ flex: 1 }} />
            <CommonFooter />
            <FlexDiv style={{ flex: 1 }} />
          </FlexRowDiv>
        </FlexColumnDiv>

        {/* 다이얼로그 */}
        <DlgContainer />

        <ToastContainer
          style={{ width: 582, top: 106, minHeight: 80, zIndex: 100000000 }}
          toastStyle={{ width: 582, minHeight: 54, fontSize: 12, color: '#121f33', fontWeight: 'bold', ...PropsCommonBox }}
          bodyStyle={{ padding: 0 }}
          position="top-center"
          autoClose={10000}
          // autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          transition={Slide}
          closeButton={false}
          hideProgressBar={true}
        />
      </FlexColumnDiv>
      // </ScreenSizeContext.Provider>
    );
  } else {
    return (
      <>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: MOBILE_WIDTH * ScreenInfo.scale,
            // transform: `scale(1)`,
            // transformOrigin: `top left`,
            zIndex: 1,
          }}
        >
          {/* 공통헤더 */}
          <FlexColumnDiv
            style={{
              position: 'fixed',
              zIndex: 10,
            }}
          >
            <FlexDiv
              style={{
                width: MOBILE_WIDTH * ScreenInfo.scale,
                height: CLAY.COMMON_HEADER_HEIGHT * ScreenInfo.scale,
              }}
            >
              <CommonHeader />
            </FlexDiv>
            <HLINE size={`100%`} />
          </FlexColumnDiv>

          <HEIGHT size={CLAY.COMMON_HEADER_HEIGHT * ScreenInfo.scale} />

          <FlexColumnDiv style={{ zIndex: 1 }}>{sIsBootstrapping ? renderBootstrapping() : renderScreen()}</FlexColumnDiv>
        </FlexColumnDiv>
        {/* 다이얼로그 */}
        <DlgContainer />

        <ToastContainer
          style={{
            width: 320 * ScreenInfo.scale,
            top: 70 * ScreenInfo.scale,
            minHeight: 70 * ScreenInfo.scale,
            zIndex: 100,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          toastStyle={{
            width: 280 * ScreenInfo.scale,
            minHeight: 44 * ScreenInfo.scale,
            fontSize: 9 * ScreenInfo.scale,
            color: '#121f33',
            fontWeight: 'bold',
            ...PropsCommonBox,
          }}
          bodyStyle={{ padding: 0 }}
          position="top-center"
          autoClose={10000}
          // autoClose={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          transition={Slide}
          closeButton={false}
          hideProgressBar={true}
        />
      </>
    );
  }
}

function CbtToast({ text, code }: { text: string; code?: CbtStompMsgCode_e }) {
  if (ScreenInfo.isMobile())
    return (
      <MobileScalableFlexColumn>
        <FlexRowDiv style={{ zIndex: 1000 }}>
          {code && (
            <>
              <Img src={[Utils.svc.isSuccessStompMsgCode(code) ? R.images.common_ic_noti_success3x : R.images.common_ic_noti_fail3x, 28, 28]} />
              <WIDTH size={4} />
            </>
          )}

          <TextCon text={text} isBold size={8} color={R.colors.black} />
        </FlexRowDiv>
      </MobileScalableFlexColumn>
    );
  else
    return (
      <FlexRowDiv style={{ zIndex: 1000 }}>
        {code && (
          <>
            <Img src={[Utils.svc.isSuccessStompMsgCode(code) ? R.images.common_ic_noti_success3x : R.images.common_ic_noti_fail3x, 40, 40]} />
            <WIDTH size={4} />
          </>
        )}
        <WIDTH size={8} />
        <TextCon text={text} isBold size={12} color={R.colors.black} />
      </FlexRowDiv>
    );
}

function PrivateRoute({ children, ...rest }: RouteProps) {
  let hAuth = useAuth();
  return (
    <Route
      //
      {...rest}
      render={({ location }) => {
        if (hAuth.isLogin) return children;
        else {
          return (
            <Redirect
              //
              to={{ pathname: Nav.UserLoginScreen, state: { from: location } }}
            />
          );
        }
      }}
    />
  );
}

export default App;
