/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties } from 'react';
import { useImage } from 'react-image';
import R from 'src/res/R';
import { FlexColumnDiv, FlexDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import ContentLoader, { Facebook } from 'react-content-loader';

type Props = {
  src: [string | null, number | string | undefined, number | string | undefined];
  onClick?: () => void;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
  border?: CSSProperties;
  getRef?: (ref: any) => void;
};

export function ImgAdv(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const { src, isLoading, error } = useImage({
    srcList: [props.src[0] ?? '', props.src[0] ?? '', props.src[0] ?? ''],
    useSuspense: false,
  });

  const renderLoading = () => {
    return (
      <FlexColumnDiv
        style={{
          ...props.containerStyle,
          width: props.src[1] ?? props.imgStyle?.width,
          height: props.src[2] ?? props.imgStyle?.height,
          //     justifyContent: 'center',
          //     alignItems: 'center',
          //     borderRadius: 5,
          //     backgroundColor: R.colors.grayBox,
        }}
      >
        <ContentLoader
          speed={3}
          width={props.src[1] ?? props.imgStyle?.width}
          height={props.src[1] ?? props.imgStyle?.height}
          backgroundColor={R.colors.grayBox}
          foregroundColor={R.colors.veryLightGrey2}
          // viewBox={`0 0 ${props.src[1] ?? props.imgStyle?.width} ${props.src[1] ?? props.imgStyle?.height}`}
        >
          <rect x="0" y="0" rx="5" ry="5" width={props.src[1] ?? props.imgStyle?.width} height={props.src[2] ?? props.imgStyle?.height} />
        </ContentLoader>
      </FlexColumnDiv>
    );
  };

  const renderSuccess = () => {
    return (
      <img
        src={src}
        alt=""
        draggable={false}
        style={{
          width: props.src[1],
          height: props.src[2],
          ...props.border,
          ...props.imgStyle,
          userSelect: 'none',
        }}
      />
    );
  };

  const renderFailure = () => {
    return (
      // 이미지 로딩 실패
      <FlexColumnDiv
        style={{
          ...props.containerStyle,
          width: props.src[1] ?? props.imgStyle?.width,
          height: props.src[2] ?? props.imgStyle?.height,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: R.colors.grayBox,
          borderRadius: 5,
        }}
      >
        <TextCon text={'loading fail'} color={R.colors.blueGrey} size={12} />
      </FlexColumnDiv>
    );
  };

  const renderNull = () => {
    return (
      <FlexColumnDiv
        style={{
          ...props.containerStyle,
          width: props.src[1] ?? props.imgStyle?.width,
          height: props.src[2] ?? props.imgStyle?.height,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: R.colors.grayBox,
          borderRadius: 5,
        }}
      >
        <TextCon text={'preparing'} color={R.colors.blueGrey} size={12} />
      </FlexColumnDiv>
    );
  };

  const renderImg = () => {
    if (props.src[0] === null || props.src[0].length === 0) {
      return renderNull();
    }

    if (isLoading) {
      return renderLoading();
    } else {
      if (src) {
        return renderSuccess();
      } else {
        return renderFailure();
      }
    }
  };

  return (
    <FlexDiv
      style={{
        justifyContent: 'center',
        // alignItems: props.imgStyle?.objectFit === 'contain' ? undefined : 'center',
        // alignSelf: 'center',
        ...props.containerStyle,
        // ...devOutlineFF,
      }}
      onClick={(e) => {
        props.onClick && props.onClick();
      }}
    >
      {renderImg()}
    </FlexDiv>
  );
}
