/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { AssetCard, AssetCardProps, ASSET_CARD_HEIGHT, ASSET_CARD_HEIGHT_WITH_SALEBTN, ASSET_CARD_WIDTH } from 'src/ui/common_component/AssetCard';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
const ASSET_CARD_PADDING_4LIST_DEKTOP = 15;
const ASSET_CARD_PADDING_4LIST_MOBILE = 15;
const ASSET_CARD_PADDING_V_4LIST_MOBILE = 10;

export let AST_CARD_PADDING_H_FOR_LIST: number;
export let AST_CARD_PADDING_V_FOR_LIST: number;
export let AST_CARD_CONTAINER_WIDTH_FOR_LIST: number;
export let AST_CARD_CONTAINER_HEIGHT_FOR_LIST: number;
export let AST_CARD_WITH_SALEBTN_CONTAINER_HEIGHT_FOR_LIST: number;

if (ScreenInfo.isMobile()) {
  AST_CARD_PADDING_H_FOR_LIST = ASSET_CARD_PADDING_4LIST_MOBILE;
  AST_CARD_PADDING_V_FOR_LIST = ASSET_CARD_PADDING_V_4LIST_MOBILE;
  AST_CARD_CONTAINER_WIDTH_FOR_LIST = ASSET_CARD_WIDTH + ASSET_CARD_PADDING_4LIST_MOBILE * 2;
  AST_CARD_CONTAINER_HEIGHT_FOR_LIST = ASSET_CARD_HEIGHT + ASSET_CARD_PADDING_V_4LIST_MOBILE * 2;
  AST_CARD_WITH_SALEBTN_CONTAINER_HEIGHT_FOR_LIST = ASSET_CARD_HEIGHT_WITH_SALEBTN + ASSET_CARD_PADDING_V_4LIST_MOBILE * 2;
} else {
  AST_CARD_PADDING_H_FOR_LIST = ASSET_CARD_PADDING_4LIST_DEKTOP;
  AST_CARD_PADDING_V_FOR_LIST = ASSET_CARD_PADDING_4LIST_DEKTOP;
  AST_CARD_CONTAINER_WIDTH_FOR_LIST = ASSET_CARD_WIDTH + ASSET_CARD_PADDING_4LIST_DEKTOP * 2;
  AST_CARD_CONTAINER_HEIGHT_FOR_LIST = ASSET_CARD_HEIGHT + ASSET_CARD_PADDING_4LIST_DEKTOP * 2;
  AST_CARD_WITH_SALEBTN_CONTAINER_HEIGHT_FOR_LIST = ASSET_CARD_HEIGHT_WITH_SALEBTN + ASSET_CARD_PADDING_4LIST_DEKTOP * 2;
}

export function AssetCardForList(props: AssetCardProps) {
  return (
    <FlexDiv
      style={{
        paddingLeft: ScreenInfo.isMobile() ? ASSET_CARD_PADDING_4LIST_MOBILE : ASSET_CARD_PADDING_4LIST_DEKTOP,
        paddingRight: ScreenInfo.isMobile() ? ASSET_CARD_PADDING_4LIST_MOBILE : ASSET_CARD_PADDING_4LIST_DEKTOP,
        paddingTop: ScreenInfo.isMobile() ? ASSET_CARD_PADDING_V_4LIST_MOBILE : ASSET_CARD_PADDING_4LIST_DEKTOP,
        paddingBottom: ScreenInfo.isMobile() ? ASSET_CARD_PADDING_V_4LIST_MOBILE : ASSET_CARD_PADDING_4LIST_DEKTOP,
        justifyContent: 'center',
        alignItems: 'center',
        ...devOutline,
      }}
    >
      <AssetCard {...props} />
    </FlexDiv>
  );
}
