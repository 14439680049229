import { CSSProperties } from 'react';
import { config, UseSpringProps } from 'react-spring';
import ScreenInfo, { DESKTOP_WIDTH, MOBILE_WIDTH } from 'src/context/screen_constants';
import { ClientType_e } from 'src/hooks/useScreenWidthHelper';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, FlexColumnDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import styled from 'styled-components';

/**
 * UI 레이아웃 관련 Constant
 */
export const DEFAUL_PAGE_ITEM_CNT = 12; // 페이징 개수

export const LIST_HORZONTAL_PADDING = 8; // 좌우 패딩

export const COMMON_BOX_HORIZONTAL_PADDING = 30;

export const CURRENCY_ROUND_DECIMAL_POINT_DEFAULT = 3;
export const CURRENCY_ROUND_DECIMAL_POINT_CBLT = 5;

export const PropsSRoundDisabled: CSSProperties = {
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: R.colors.line,
  backgroundColor: R.colors.bgGrey,
  userSelect: 'none',
  borderRadius: 15,
};

export const PropsSRoundEnabled: CSSProperties = {
  border: 'double 2px transparent',
  backgroundImage: `linear-gradient(white, white), radial-gradient(circle at top left, #6decd6, ${R.colors.purpleishBlue})`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  userSelect: 'none',
  borderRadius: 15,
};

export const PropsCommonBox = {
  borderRadius: 15,
  boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.05)',
  border: `solid 1px ${R.colors.line}`,
  backgroundColor: '#ffffff',
};

// TODO 공통 style typing
const CommonProp = `
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 0.5px ${R.colors.line};
`;

export const CommonRowBox = styled(FlexRowDiv)`
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${R.colors.line};
  background-color: #ffffff;
`;

export const CommonColumnBox = styled(FlexColumnDiv)`
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${R.colors.line};
  background-color: #ffffff;
`;

export const SettingPopupBox = styled(AnimatedFlexRowDiv)`
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${R.colors.line};
  background-color: white;
  overflow: hidden;
`;

export const CommonLightGrayBox = styled(FlexColumnDiv)`
  object-fit: contain;
  border-radius: 15px;
  background-color: ${R.colors.lightGrey};
`;

//##########################################################################################################
// Animation
//##########################################################################################################
export const SPRING_PROPS_ANIM_1_Default: UseSpringProps = {
  boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
  transform: 'translateY(0px)',
  // transform: 'translate3d(0%, 0px, 0px)',
};

export const SPRING_PROPS_ANIM_1_OnMouseEnter: UseSpringProps = {
  boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.10)`,
  transform: 'translateY(-1px)',
  // transform: 'translate3d(-25%, 0px, 0px)',
  config: { ...config.gentle, duration: 0 },
};

export const SPRING_PROPS_ANIM_1_OnMouseLeave: UseSpringProps = {
  boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
  transform: 'translateY(0px)',
  // transform: 'translate3d(0%, 0px, 0px)',
  config: { ...config.gentle, duration: 200 },
};

export const SPRING_PROPS_ANIM_2_Default: UseSpringProps = {
  backgroundColor: R.colors.purpleishBlue,
};

export const SPRING_PROPS_ANIM_2_OnMouseEnter: UseSpringProps = {
  backgroundColor: R.colors.blueBlue,
  // backgroundColor: 'red',
  config: { ...config.gentle, duration: 0 },
};

export const SPRING_PROPS_ANIM_2_OnMouseLeave: UseSpringProps = {
  backgroundColor: R.colors.purpleishBlue,
  config: { ...config.gentle, duration: 100 },
};

export const schemaDispNameTooltipWidth = (lang: CbtUserLanguage_e) => {
  switch (lang) {
    case CbtUserLanguage_e.EN:
      return 240;
    case CbtUserLanguage_e.JA:
      return 240;
    case CbtUserLanguage_e.KO:
      return 220;
  }
};

//##########################################################################################################
// 공통
//##########################################################################################################
// CLAY : Common Layout
export class CLAY {
  static readonly PAGE_WIDTH = ScreenInfo.isMobile() ? MOBILE_WIDTH : DESKTOP_WIDTH;
  static readonly HPADDING = ScreenInfo.isMobile() ? 20 : 0;
  static readonly BODY_WIDTH = ScreenInfo.isMobile() ? CLAY.PAGE_WIDTH - CLAY.HPADDING * 2 : CLAY.PAGE_WIDTH;
  static readonly DEFAULT_FONT_SIZE = ScreenInfo.isMobile() ? 12 : 14;
  static readonly COMMON_HEADER_HEIGHT = ScreenInfo.isMobile() ? 60 : 86;
  static readonly TITLE_PADDING_TOP = ScreenInfo.isMobile() ? 20 : 40;
  static readonly TOOLTIP_IC_SIZE = ScreenInfo.isMobile() ? 16 : 20;
  static readonly TITLE_SIZE_IN_BOX = ScreenInfo.isMobile() ? 16 : 24;
  static readonly VALIDATION_MSG_FONT_SIZE = ScreenInfo.isMobile() ? 10 : 12;

  static readonly BACKBTN_SIZE = ScreenInfo.isMobile() ? 16 : 20;
  static readonly BACKBTN_FONT_SIZE = ScreenInfo.isMobile() ? 10 : 14;
  static readonly TITLE_FONT_SIZE = ScreenInfo.isMobile() ? 16 : 30;
  static readonly TITLE_BOTTOM_MARGIN = ScreenInfo.isMobile() ? 20 : 30;
  static readonly TITLE_HEIGHT = ScreenInfo.isMobile() ? 30 : 52;
  static readonly BOX_PADDING = ScreenInfo.isMobile() ? 16 : 30;
  static readonly BOX_TITLE_FONT_SIZE = ScreenInfo.isMobile() ? 16 : 24;
  static readonly BOX_TITLE_HEIGHT = ScreenInfo.isMobile() ? 30 : 36;
  static readonly FORM_BETWEEN_SPACE = ScreenInfo.isMobile() ? 20 : 24;
  static readonly FORM_FONT_SIZE = ScreenInfo.isMobile() ? 12 : 14;
  static readonly FORM_FONT_HEIGHT = ScreenInfo.isMobile() ? 24 : 26;
  static readonly FORM_INPUT_TOP_MARGIN = ScreenInfo.isMobile() ? 12 : 14;
  static readonly FORM_SIZE = ScreenInfo.isMobile()
    ? {
        WIDTH: 256,
        HEIGHT: 38,
      }
    : { WIDTH: 536, HEIGHT: 44 };

  static readonly FORM_CHK_MSG_TOP_MARGIN = ScreenInfo.isMobile() ? 6 : 10;
  static readonly FORM_CHK_MSG_FONT_SIZE = ScreenInfo.isMobile() ? 8 : 12;
  static readonly TEXT_BTN_VMARGIN_IN_BOX = ScreenInfo.isMobile() ? 34 : 40;
  static readonly TEXT_BTN_SIZE_IN_BOX = ScreenInfo.isMobile()
    ? {
        WIDTH: 256,
        HEIGHT: 44,
      }
    : { WIDTH: 294, HEIGHT: 48 };

  static readonly TEXT_BTN_FONT_SIZE_IN_BOX = ScreenInfo.isMobile() ? 12 : 14;
  static readonly TEXT_BTN_SIZE = ScreenInfo.isMobile()
    ? {
        WIDTH: ScreenInfo.contentsWidth,
        HEIGHT: 44,
      }
    : { WIDTH: 276, HEIGHT: 48 };

  static readonly TEXT_BTN_FONT_SIZE = ScreenInfo.isMobile() ? 12 : 12;
  static readonly NORMAL_IC_SIZE = ScreenInfo.isMobile() ? 20 : 24;
  // static readonly TOOLTIP_IC_SIZE = ScreenInfo.isMobile()? 18 : 20;
  static readonly TOOLTIP_FONT_SIZE = ScreenInfo.isMobile() ? 10 : 12;
  static readonly TOOLTIP_HPADDING = ScreenInfo.isMobile() ? 12 : 14;
  static readonly TOOLTIP_VPADDING = ScreenInfo.isMobile() ? 8 : 10;
}

//##########################################################################################################
// CommonHeader
//##########################################################################################################
type CommonHeaderLayout_t = {
  width: number;
  height: number;
  marginRight: number;
  logoSize: {
    width: number;
    height: number;
  };
  circleBtnSize: number;
  langPopup: {
    width: number;
    height: number;
    langFontSize: number;
    langHeight: number;
    langLeftPadding: number;
    vPadding: number;
  };
};

const MobileCommonHeaderLayout: CommonHeaderLayout_t = {
  width: MOBILE_WIDTH,
  height: CLAY.COMMON_HEADER_HEIGHT,
  marginRight: 46,
  logoSize: {
    width: 92,
    height: 34,
  },
  circleBtnSize: 28,
  langPopup: {
    width: 100,
    height: 109,
    langFontSize: 10,
    langHeight: 27,
    langLeftPadding: 12,
    vPadding: 14,
  },
};

const DesktopCommonHeaderLayout: CommonHeaderLayout_t = {
  width: ScreenInfo.contentsWidth,
  height: CLAY.COMMON_HEADER_HEIGHT,
  marginRight: 0,
  logoSize: {
    width: 132,
    height: 50,
  },
  circleBtnSize: 40,

  langPopup: {
    width: 138,
    height: 134,
    langFontSize: 14,
    langHeight: 36,
    langLeftPadding: 20,
    vPadding: 13,
  },
};
export let COMMONHEADER_LAYOUT: CommonHeaderLayout_t;

//##########################################################################################################
// CollectionCardLayout
//##########################################################################################################
type CollectionCardLayout_t = {
  width: number;
  height: number;
  vpadding: number;
  paddingForList: number;
  widthForList: number;
  heightForList: number;
  collecionNameWidth: number;
  space: number;
  fontSize: {
    collecitonName: number;
  };
  collectionImgSize: number;
  icSize: {
    collection: { width: number; height: number };
  };
};

const MobileCollectionCardLayout: CollectionCardLayout_t = {
  width: 280,
  height: 266,
  vpadding: 34,
  paddingForList: 6,
  widthForList: 280 + 6 * 2,
  heightForList: 266 + 6 * 2,
  collecionNameWidth: 226,
  space: 16,
  fontSize: {
    collecitonName: 12,
  },
  collectionImgSize: 162,
  icSize: {
    collection: {
      width: 20,
      height: 20,
    },
  },
};
const DesktopCollectionCardLayout: CollectionCardLayout_t = {
  width: 276,
  height: 284,
  vpadding: 40,
  paddingForList: 15,
  widthForList: 276 + 15 * 2,
  heightForList: 284 + 15 * 2,
  collecionNameWidth: 222,
  space: 20,
  fontSize: {
    collecitonName: 14,
  },
  collectionImgSize: 160,
  icSize: {
    collection: {
      width: 24,
      height: 24,
    },
  },
};
export let COLLECTION_CARD_LAYOUT: CollectionCardLayout_t;

//##########################################################################################################
// SchemaCardLayout
//##########################################################################################################
type SchemaCardLayout_t = {
  width: number;
  height: number;
  paddingForList: number;
  widthForList: number;
  heightForList: number;
  nameFontSize: number;
  guideFontSize: number;
  icSize: number;
  innerBoxWidth: number;
  innerBoxHeight: number;
  innerBoxRadius: number;
};

const MobileSchemaCardLayout: SchemaCardLayout_t = {
  width: 280,
  height: 194,
  paddingForList: 6,
  widthForList: 280 + 6 * 2,
  heightForList: 194 + 6 * 2,
  nameFontSize: 14,
  guideFontSize: 12,
  icSize: 28,
  innerBoxWidth: 216,
  innerBoxHeight: 126,
  innerBoxRadius: 10,
};
const DesktopSchemaCardLayout: SchemaCardLayout_t = {
  width: 276,
  height: 186,
  paddingForList: 15,
  widthForList: 276 + 15 * 2,
  heightForList: 186 + 15 * 2,
  nameFontSize: 14,
  guideFontSize: 12,
  icSize: 28,
  innerBoxWidth: 216,
  innerBoxHeight: 126,
  innerBoxRadius: 10,
};
export let SCHEMA_CARD_LAYOUT: SchemaCardLayout_t;

//##########################################################################################################
// Login/Logout button
//##########################################################################################################
type LoginoutBtnLayout_t = {
  width: number;
  height: number;
  fontSize: number;
};

const MobileLoginoutBtnLayout: LoginoutBtnLayout_t = {
  width: 128,
  height: 28,
  fontSize: 10,
};

const DesktopLoginoutBtnLayout: LoginoutBtnLayout_t = {
  width: 139,
  height: 40,
  fontSize: 14,
};

export let LOGINOUT_BTN_LAYOUT: LoginoutBtnLayout_t;

//##########################################################################################################
// Collection column count
//##########################################################################################################
const DesktopCollectionColumnCnt = 4;
const MobileCollectionColumnCnt = 1;

export let COMMON_CARD_COLUMN_CNT: number;

//##########################################################################################################
// Login Screen
//##########################################################################################################
type LoginScreenLayout_t = {
  boxWidth: number;
  // boxHeight: number;
  topMargin: number;
  titleTopMargin: number;
  secureGuideVeticalMargin: number;
  horizontalPadding: number;
  titleFontSize: number;
  secureGuideFontSize: number;
  urlFontSize: number;
  urlRoundBoxSize: {
    width: number;
    height: number;
  };
  failMsgTopMargin: number;
  failMsgFontSize: number;
  formFontSize: number;
  formTopMargin: number;
  formInputTopMargin: number;
  formSize: {
    width: number;
    height: number;
  };
  loginBtnVeticalMargin: number;
  loginBtnSize: {
    width: number;
    height: number;
  };
  loginBtnFontSize: number;
  pwResetBtnFontSize: number;
  joinBtnFontSize: number;
  bottomPadding: number;
  bottomMargin: number;
};

const MobileLoginScreenLayout: LoginScreenLayout_t = {
  boxWidth: 280,
  // boxHeight: number,
  topMargin: 20,
  titleTopMargin: 20,
  secureGuideVeticalMargin: 8,
  horizontalPadding: 12,
  titleFontSize: 16,
  secureGuideFontSize: 10,
  urlFontSize: 10,
  urlRoundBoxSize: {
    width: 128,
    height: 26,
  },
  failMsgTopMargin: 10,
  failMsgFontSize: 10,
  formFontSize: 12,
  formTopMargin: 20,
  formInputTopMargin: 12,
  formSize: {
    width: 256,
    height: 38,
  },
  loginBtnVeticalMargin: 20,
  loginBtnSize: {
    width: 256,
    height: 44,
  },
  loginBtnFontSize: 12,
  pwResetBtnFontSize: 10,
  joinBtnFontSize: 10,
  bottomPadding: 30,
  bottomMargin: 40,
};

const DesktopLoginScreenLayout: LoginScreenLayout_t = {
  boxWidth: 582,
  // boxHeight: number,
  topMargin: 40,
  titleTopMargin: 40,
  secureGuideVeticalMargin: 12,
  horizontalPadding: 30,
  titleFontSize: 24,
  secureGuideFontSize: 14,
  urlFontSize: 12,
  urlRoundBoxSize: {
    width: 166,
    height: 30,
  },
  failMsgTopMargin: 30,
  failMsgFontSize: 14,
  formFontSize: 14,
  formTopMargin: 24,
  formInputTopMargin: 14,
  formSize: {
    width: 522,
    height: 44,
  },
  loginBtnVeticalMargin: 40,
  loginBtnSize: {
    width: 355,
    height: 48,
  },
  loginBtnFontSize: 14,
  pwResetBtnFontSize: 12,
  joinBtnFontSize: 12,
  bottomPadding: 40,
  bottomMargin: 334,
};

export let LOGIN_SCREEN_LAYOUT: LoginScreenLayout_t;

//##########################################################################################################
// Join Screen
//##########################################################################################################
type JoinScreenLayout_t = {
  boxWidth: number;
  horizontalPadding: number;
  topMargin: number;
  topPadding: number;
  titleFontSize: number;
  titleBottomMargin: number;
  formFontSize: number;
  formTopMargin: number;
  formInputTopMargin: number;
  formSize: {
    width: number;
    height: number;
  };
  authFormSize: {
    width: number;
    height: number;
  };
  authBtnSize: {
    width: number;
    height: number;
  };
  authBtnFontSize: number;
  validationMsgTopMargin: number;
  validationMsgFontSize: number;
  checkFontSize: number;
  checkBoxSize: number;
  joinBtnVeticalMargin: number;
  joinBtnSize: {
    width: number;
    height: number;
  };
  joinBtFontSize: number;
  bottomMargin: number;
  confirmIcSize: number;
  pwViewIcSize: number;
};

const DesktopJoinScreenLayout: JoinScreenLayout_t = {
  boxWidth: 582,
  horizontalPadding: 30,
  topMargin: 40,
  topPadding: 40,
  titleFontSize: 24,

  titleBottomMargin: 40,
  formFontSize: 14,
  formTopMargin: 24,
  formInputTopMargin: 14,
  formSize: {
    width: 522,
    height: 44,
  },
  authFormSize: {
    width: 330,
    height: 44,
  },
  authBtnSize: {
    width: 180,
    height: 44,
  },
  authBtnFontSize: 14,
  validationMsgTopMargin: 14,
  validationMsgFontSize: 12,
  checkFontSize: 12,
  checkBoxSize: 22,
  joinBtnVeticalMargin: 40,
  joinBtnSize: {
    width: 355,
    height: 48,
  },
  joinBtFontSize: 14,
  bottomMargin: 120,
  confirmIcSize: 20,
  pwViewIcSize: 30,
};

const MobileJoinScreenLayout: JoinScreenLayout_t = {
  boxWidth: 280,
  horizontalPadding: 12,
  topMargin: 20,
  topPadding: 20,
  titleFontSize: 16,

  titleBottomMargin: 20,
  formFontSize: 12,
  formTopMargin: 20,
  formInputTopMargin: 12,
  formSize: {
    width: 256,
    height: 38,
  },
  authFormSize: {
    width: 124,
    height: 38,
  },
  authBtnSize: {
    width: 124,
    height: 38,
  },
  authBtnFontSize: 12,
  validationMsgTopMargin: 6,
  validationMsgFontSize: 8,
  checkFontSize: 10,
  checkBoxSize: 18,
  joinBtnVeticalMargin: 20,
  joinBtnSize: {
    width: 256,
    height: 44,
  },
  joinBtFontSize: 12,
  bottomMargin: 60,
  confirmIcSize: 18,
  pwViewIcSize: 24,
};

export let JOIN_SCREEN_LAYOUT: JoinScreenLayout_t;

//##########################################################################################################
// PW RESET Screen
//##########################################################################################################
type PwResetScreenLayout_t = {
  boxWidth: number;
  horizontalPadding: number;
  topMargin: number;
  topPadding: number;
  titleFontSize: number;
  titleBottomMargin: number;
  formFontSize: number;
  formInputTopMargin: number;
  formSize: {
    width: number;
    height: number;
  };
  validationMsgTopMargin: number;
  validationMsgFontSize: number;
  txtBtnVeticalMargin: number;
  txtBtnSize: {
    width: number;
    height: number;
  };
  txtBtnFontSize: number;
  bottomMargin: number;
};
const DesktopPwResetScreenLayout: PwResetScreenLayout_t = {
  boxWidth: 582,
  horizontalPadding: 30,
  topMargin: 40,
  topPadding: 40,
  titleFontSize: 24,
  titleBottomMargin: 40,
  formFontSize: 14,
  formInputTopMargin: 14,
  formSize: {
    width: 522,
    height: 44,
  },
  validationMsgTopMargin: 14,
  validationMsgFontSize: 12,
  txtBtnVeticalMargin: 30,
  txtBtnSize: {
    width: 355,
    height: 48,
  },
  txtBtnFontSize: 14,
  bottomMargin: 552,
};
const MobilePwResetScreenLayout: PwResetScreenLayout_t = {
  boxWidth: 280,
  horizontalPadding: 12,
  topMargin: 20,
  topPadding: 20,
  titleFontSize: 16,
  titleBottomMargin: 20,
  formFontSize: 12,
  formInputTopMargin: 12,
  formSize: {
    width: 256,
    height: 38,
  },
  validationMsgTopMargin: 6,
  validationMsgFontSize: 8,
  txtBtnVeticalMargin: 20,
  txtBtnSize: {
    width: 256,
    height: 44,
  },
  txtBtnFontSize: 12,
  bottomMargin: 60,
};

export let PWRESET_SCREEN_LAYOUT: PwResetScreenLayout_t;

//##########################################################################################################
// My Schema Create Screen
//##########################################################################################################
type MySchemaCScreenLayout_t = {
  boxWidth: number;
  boxVPadding: number;
  columnWidth: number;
  bottomMargin: number;
};
const DesktopMySchemaCScreenLayout: MySchemaCScreenLayout_t = {
  boxWidth: DESKTOP_WIDTH,
  boxVPadding: 31,
  columnWidth: DESKTOP_WIDTH / 2,
  bottomMargin: 120,
};
const MobileMySchemaCScreenLayout: MySchemaCScreenLayout_t = {
  boxWidth: 280,
  boxVPadding: 34,
  columnWidth: DESKTOP_WIDTH,
  bottomMargin: 60,
};

export let MY_SCHEMA_C_SCREEN_LAYOUT: MySchemaCScreenLayout_t;

//##########################################################################################################
// Common
//##########################################################################################################

switch (ScreenInfo.clientType) {
  case ClientType_e.Desktop:
  case ClientType_e.Tablet:
    COMMONHEADER_LAYOUT = DesktopCommonHeaderLayout;
    COLLECTION_CARD_LAYOUT = DesktopCollectionCardLayout;
    SCHEMA_CARD_LAYOUT = DesktopSchemaCardLayout;
    LOGINOUT_BTN_LAYOUT = DesktopLoginoutBtnLayout;
    COMMON_CARD_COLUMN_CNT = DesktopCollectionColumnCnt;
    LOGIN_SCREEN_LAYOUT = DesktopLoginScreenLayout;
    JOIN_SCREEN_LAYOUT = DesktopJoinScreenLayout;
    PWRESET_SCREEN_LAYOUT = DesktopPwResetScreenLayout;
    MY_SCHEMA_C_SCREEN_LAYOUT = DesktopMySchemaCScreenLayout;
    break;
  case ClientType_e.Mobile:
    COMMONHEADER_LAYOUT = MobileCommonHeaderLayout;
    COLLECTION_CARD_LAYOUT = MobileCollectionCardLayout;
    SCHEMA_CARD_LAYOUT = MobileSchemaCardLayout;
    LOGINOUT_BTN_LAYOUT = MobileLoginoutBtnLayout;
    COMMON_CARD_COLUMN_CNT = MobileCollectionColumnCnt;
    LOGIN_SCREEN_LAYOUT = MobileLoginScreenLayout;
    JOIN_SCREEN_LAYOUT = MobileJoinScreenLayout;
    PWRESET_SCREEN_LAYOUT = MobilePwResetScreenLayout;
    MY_SCHEMA_C_SCREEN_LAYOUT = MobileMySchemaCScreenLayout;
    break;
}
