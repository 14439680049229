// import classNames from 'classnames/bind';
import React, { useState } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { CbtCurrency_e, ServerECode_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW } from 'src/util/utils';

// import styles from 'src/ui/dlg/jsx/WithdrawJsx.module.scss';

// const cx = classNames.bind(styles);
// const cx = classNames('testtest2');

export type RegPaypalJsxProps = {
  orgPayoutAccount?: string;
  onOk?: (addr: string) => void;
  onclose?: () => void;
};

const SectionTitle = ({ main, sub }: { main: string; sub?: string }) => {
  return (
    <div className={'common_section_title'}>
      <span className={'main'}>{main}</span>
      {sub && <span className={'sub'}>{sub}</span>}
    </div>
  );
};

export const RegPaypalJsx = (dlg: RegPaypalJsxProps) => {
  /**************************************
   * !! state
   **************************************/
  const [sEmail, setEmail] = useState<string>('');
  const [sEmailValidation, setEmailValidation] = useState<{
    isTypeError?: boolean; // undefined: 체크전, true: 이메일 형식 잘못됬을 경우, false: 이메일 형식 정상인경우
    emsg?: string; // UI상 표시할 에러메세지
  }>({});

  const [sEmailAuthCode, setEmailAuthCode] = useState<string>(''); // 매직넘버 1029@!
  const [sEmailAuthStatus, setEmailAuthStatus] = useState<{
    authStatus: 'BEFORE_SENDMAIL' | 'AFTER_SENDMAIL' | 'COMPLETE';
    authFailMsg?: string; // 인증 오류 메세제
  }>({ authStatus: 'BEFORE_SENDMAIL' });

  const [sEmailInputFocus, setEmailInputFocus] = useState(false);
  const [sAuthCodeInputFocus, setAuthCodeInputFocus] = useState(false);
  const [sAllBtnHover, setAllBtnHover] = useState(false);
  const [sPlatformFeeMethod, setPlatformFeeMethod] = useState<CbtCurrency_e.USD | CbtCurrency_e.CBLT>(CbtCurrency_e.USD);

  const [sVerifyBtnEnable, setVerifyBtnEnable] = useState(true);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hRpc = useRpc();

  // const hR = useLocalSettings();

  /**************************************
   * !! arrow function
   **************************************/
  const validateEmail = (email: string) => {
    if (email.length > 0) {
      // email형식 체크
      if (!Constants.REGEXP_IS_EMAIL.test(email)) {
        DBGMSG(`$$$REGEX email type error`);
        setEmailValidation({ isTypeError: true, emsg: hR.strings.JOIN_EMAIL_INVALID });
        return;
      }
      if (email === dlg.orgPayoutAccount) {
        setEmailValidation({ isTypeError: true, emsg: hR.strings.MY_PAYPAL_DLG_SECTION_EMAIL_ALREADY });
        return;
      }
      setEmailValidation({ isTypeError: false });
      setEmailAuthStatus({ authStatus: 'BEFORE_SENDMAIL', authFailMsg: undefined });
      setEmailAuthCode('');
    } else {
      // 입력이 없을 경우 reset
      setEmailValidation({});
    }
  };

  /**************************************
   * !! render function
   **************************************/
  const renderEmailAuth = () => {
    switch (sEmailAuthStatus.authStatus) {
      case 'BEFORE_SENDMAIL':
        return (
          <>
            <div className={sAuthCodeInputFocus ? 'auth_code_input_focus' : 'auth_code_input_unfocus'}>
              <input
                value={sEmailAuthCode}
                placeholder={hR.strings.JOIN_EMAIL_AUTH_PLACEHOLDER}
                onFocus={() => {
                  setAuthCodeInputFocus(true);
                }}
                onBlur={() => {
                  setAuthCodeInputFocus(false);
                }}
                onChange={(e) => {
                  setEmailAuthCode(e.target.value);
                }}
              />
            </div>
            <div
              className={sEmail.length > 0 && !sEmailValidation.isTypeError ? 'send_auth_btn_enable' : 'send_auth_btn_disable'}
              onClick={async () => {
                // 이메일 인증메일 전송 RPC 요청
                try {
                  const res = await hRpc.cobaltRpc.rpcUserCertifyEmail({ email: sEmail, lang: hR.lang, type: 1 });
                  setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL' });
                } catch (e) {
                  hRpc.hanleCommonError(e);
                }
              }}
            >
              {hR.strings.JOIN_EMAIL_AUTH_SEND}
            </div>
          </>
        );
      case 'AFTER_SENDMAIL':
        return (
          <>
            <div className={sAuthCodeInputFocus ? 'auth_code_input_focus' : 'auth_code_input_unfocus'}>
              <input
                value={sEmailAuthCode}
                placeholder={hR.strings.JOIN_EMAIL_AUTH_PLACEHOLDER}
                onFocus={() => {
                  setAuthCodeInputFocus(true);
                }}
                onBlur={() => {
                  setAuthCodeInputFocus(false);
                }}
                onChange={(e) => {
                  setEmailAuthCode(e.target.value);
                }}
              />
            </div>
            <div
              className={sEmailAuthCode.length > 0 && sVerifyBtnEnable ? 'send_auth_btn_enable' : 'send_auth_btn_disable'}
              onClick={async () => {
                // 이메일 인증메일 전송 RPC 요청
                try {
                  const res = await hRpc.cobaltRpc.rpcUserCertifyEmailTry({ email: sEmail, auth_code: sEmailAuthCode });
                  setEmailAuthStatus({ authStatus: 'COMPLETE' });
                  // DBGMSGW('error 1');
                } catch (e) {
                  DBGMSGW('error', e);
                  const errInfo = getRpcErrorInfo(e);
                  if (errInfo) {
                    DBGMSGW(JSON.stringify(errInfo, undefined, 4));

                    switch (errInfo.ecode) {
                      case ServerECode_e.CERTIFY_EMAIL_FAILED_BY_CODE_EXPIRED:
                        setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL', authFailMsg: errInfo.emsg });
                        break;
                      case ServerECode_e.CERTIFY_EMAIL_FAILED_BY_INVALID_CODE:
                        setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL', authFailMsg: hR.strings.JOIN_EMAIL_AUTH_INVALID });
                        break;
                    }

                    // 5초동안 버튼을 비활성화
                    setVerifyBtnEnable(false);
                    setTimeout(() => {
                      setVerifyBtnEnable(true);
                    }, Constants.EMAIL_AUTH_CODE_RETRY_DELAY_MS);
                  }
                }
              }}
            >
              {hR.strings.JOIN_EMAIL_AUTH_BTN}
            </div>
          </>
        );
      case 'COMPLETE':
        return (
          <>
            <div className={sAuthCodeInputFocus ? 'auth_code_input_focus' : 'auth_code_input_unfocus'}>
              <input value={sEmailAuthCode} readOnly={true} />
            </div>
            <div className={'auth_complete'}>{hR.strings.JOIN_EMAIL_AUTH_SUCC}</div>
          </>
        );
    }
  };

  /* 인증메일 안내 */
  const renderEmailAuthGuide = () => {
    return (
      <div className={'section_email_auth_guide'}>
        {sEmailAuthStatus.authFailMsg ? (
          // 인증 번호가 일치하지 않습니다.
          <div className={'validation'}>{sEmailAuthStatus.authFailMsg}</div>
        ) : (
          <div className={'validation'}>{`${hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE1_1}\n${hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE1_2}`}</div>
        )}
        <div className={'box'}>
          <div className={'txt'}>{`* ${hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE2_1}`}</div>
          <div className={'txt'}>{`${hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE2_2}`}</div>
          <div className={'txt'}>{`* ${hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE2_3}`}</div>
          <div
            className={'resend_text'}
            onClick={() => {
              hRpc.cobaltRpc.rpcUserCertifyEmail({ email: sEmail, lang: hR.lang, type: 1 }).then((res) => {
                setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL' });
                setEmailAuthCode('');
              });
            }}
          >
            {hR.strings.JOIN_EMAIL_AUTH_RESEND}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={ScreenInfo.isMobile()?'reg_paypal_jsx_m':'reg_paypal_jsx'}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      <img
        src={R.images.common_btn_close3x}
        className={'close_btn'}
        alt={''}
        onClick={() => {
          dlg.onclose && dlg.onclose();
        }}
      />
      <div className={'dlg_title'}>
        <img src={R.images.cashout_iv_paypal_logo3x} alt={''} />
        <div className={'txt'}>{hR.strings.MY_PAYPAL_DLG_TITLE}</div>
      </div>

      {/* PayPal 이메일 */}
      <div className={'section_paypal_email'}>
        <SectionTitle main={hR.strings.MY_PAYPAL_DLG_SECTION_EMAIL} />
        <div className={sEmailInputFocus ? 'email_input_focus' : 'email_input_unfocus'}>
          <input
            value={sEmail}
            placeholder={hR.strings.MY_PAYPAL_DLG_SECTION_EMAIL_PLACEHOLDER}
            onFocus={() => {
              setEmailInputFocus(true);
            }}
            onBlur={() => {
              setEmailInputFocus(false);
            }}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e.target.value);
            }}
            readOnly={sEmailAuthStatus.authStatus === 'COMPLETE'}
          />
        </div>

        {sEmailValidation.emsg && <div className={'validation'}>{sEmailValidation.emsg}</div>}
      </div>

      {/* 이메일 인증하기 */}
      <div className={'section_email_auth_code'}>
        <SectionTitle main={hR.strings.MY_PAYPAL_DLG_SECTION_AUTH_CODE} />
        <div className={'flex_row_div'}>{renderEmailAuth()}</div>
      </div>

      {sEmailAuthStatus.authStatus === 'AFTER_SENDMAIL' && renderEmailAuthGuide()}

      {/* 확인 */}
      <div className={'reg_btn'}>
        <div
          className={sEmailAuthStatus.authStatus === 'COMPLETE' ? 'reg_btn_enable' : 'reg_btn_disable'}
          onClick={() => {
            DBGMSG('ok');
            if (sEmailAuthStatus.authStatus !== 'COMPLETE') return;
            dlg.onOk && dlg.onOk(sEmail);
          }}
        >
          {hR.strings.COMMON_OK_BTN_TXT}
        </div>
      </div>
    </div>
  );
};
