/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */
import React, { useEffect, useRef, useState } from 'react';
import { FlexColumnDiv, FlexDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { existKlaytn, getCaver, getKlaytn } from 'src/util/kaikas';
import { CbtCurrency_e } from 'src/model/rpcModel';

function KaikasScreen() {
  const LOGIN_TIMEOUT = 600;

  // kaikas status
  const [kaikasIsEnabled, setKaikasIsEnabled] = useState<boolean>();
  const [kaikasIsApproved, setKaikasIsApproved] = useState<boolean>();
  const [kaikasIsUnlocked, setKaikasIsUnlocked] = useState<boolean>();

  // klaytn status
  const [klaytnNetworkVersion, setKlaytnNetworkVersion] = useState<string>();
  const [klaytnSelectedAddress, setKlaytnSelectedAddress] = useState<string>();
  const [klaytnIsKaikas, setKlaytnIsKaikas] = useState<boolean>();

  // Login?
  const [connectedAddress, setConnectedAddress] = useState<string>();
  const [loginTimeout, setLoginTimeout] = useState<string>('');
  const loginTimeIntervalID = useRef<NodeJS.Timeout>();
  const ksupdateIntervalID = useRef<NodeJS.Timeout>();

  const klaytn = useRef<any>();
  const caver = useRef<any>();

  const [balanceInKlay, setBalanceInKlay] = useState<number>();
  const [balanceInPeb, setBalanceInPeb] = useState<number>();

  useEffect(() => {
    console.debug(`useEffect`);
    init();

    // 연결상태 타임아웃 NodeJS.Timeout
    loginTimeIntervalID.current = setInterval(() => {
      console.debug();
      const connectedTime = window.sessionStorage.getItem('connectedTime');
      if (connectedTime) {
        const start = parseInt(connectedTime);
        const end = new Date().getTime();
        const diffMs = end - start;
        const diffS = diffMs / 1000;
        setLoginTimeout(`${diffS.toFixed(0)} 초`);
        if (diffS >= LOGIN_TIMEOUT) {
          // if(diffS >= 3){
          disConnectKaikas();
          alert(`${LOGIN_TIMEOUT}초가 지나 자동으로 연결해제(로그아웃)됩니다.`);
        }
      }
    }, 100);

    // Klaystation info update
    // ksupdateIntervalID.current = setInterval(() => {
    //   console.debug();
    //   updateKsInfo();

    //   console.debug(`connectedAddress: ${klaytn.current.selectedAddress}`);

    //   if (klaytn.current.selectedAddress) {
    //     updateKsStakingInfo(klaytn.current.selectedAddress);
    //   }

    //   setKsUpdateTime(Util.date.brewFomatString({}));
    // }, 100);
    return () => {
      if (loginTimeIntervalID.current) {
        clearInterval(loginTimeIntervalID.current);
      }
      if (ksupdateIntervalID.current) {
        clearInterval(ksupdateIntervalID.current);
      }
    };
  }, []);

  const init = async () => {
    console.debug(`init`);
    klaytn.current = getKlaytn();
    caver.current = getCaver();
    console.debug(`klaytn.current: ${klaytn.current}`);
    console.debug(`caver.current: ${caver.current}`);
    if (klaytn.current) {
      // updateKaikas();
      // updateKaikasProps();

      klaytn.current.on('accountsChanged', async function (accounts: any) {
        console.debug(`accountsChanged accounts: ${accounts}`);
        // updateKaikasProps();
        // updateKaikas();

        if (connectedAddress === accounts) {
        } else {
          // 세션 스토리지에 지갑연결 상태 get
          const connected = window.sessionStorage.getItem('kaikas');
          if (connected === 'true') {
            await disConnectKaikas();
            connectKaikas();
          }
          alert('account가 변경되었습니다.');
        }
      });

      klaytn.current.on('networkChanged', async function (accounts: any) {
        console.debug(`accountsChanged accounts: ${accounts}`);
        updateKaikasProps();
        updateKaikas();

        alert('network가 변경되었습니다.');
      });

      // 세션 스토리지에 지갑연결 상태 get
      const connected = window.sessionStorage.getItem('kaikas');
      if (connected === 'true') {
        connectKaikas();
      }
      // 네트워크 변경시 페이지 reload (default : true)
      // klaytn.current.autoRefreshOnNetworkChange = false;
    }
  };

  const connectKaikas = async () => {
    if (existKlaytn()) {
      const res = await klaytn.current.enable();
      console.debug(`enable() res: ${res}`);
      setConnectedAddress(res);

      updateMyBalance();

      // updateKsStakingInfo(res);
      // updateKsPoolInfo();

      // 세션 스토리지에 저장
      window.sessionStorage.setItem('kaikas', 'true');
      window.sessionStorage.setItem('connectedTime', `${new Date().getTime()}`);
    } else {
      alert(`구글 크롬, 네이버 웨일, 최신 마이크로소프트 엣지 브라우저에서만 이용 가능합니다.`);
    }
  };
  const disConnectKaikas = async () => {
    if (existKlaytn()) {
      window.sessionStorage.clear();
      setConnectedAddress(undefined);

      updateKaikasProps();
      updateKaikas();
    } else {
      alert(`구글 크로, 네이버 웨일, 최신 마이크로소프트 엣지 브라우저에서만 이용 가능합니다.`);
    }
  };

  const updateKaikasProps = async () => {
    console.debug(`updateKaikas`);
    if (!existKlaytn()) return;
    const isEnalbed = klaytn.current._kaikas.isEnabled();
    setKaikasIsEnabled(klaytn.current._kaikas.isEnabled());

    const isApproved = await klaytn.current._kaikas.isApproved();
    setKaikasIsApproved(isApproved);

    const isUnlocked = await klaytn.current._kaikas.isUnlocked();
    setKaikasIsUnlocked(isUnlocked);

    console.debug(`isEnalbed: ${isEnalbed}`);
    console.debug(`isApproved: ${isApproved}`);
    console.debug(`isUnlocked: ${isUnlocked}`);
  };

  const updateKaikas = () => {
    console.debug(`updateKlaytn`);
    if (!existKlaytn()) return;

    const networkVersion = klaytn.current.networkVersion;
    const selectedAddress = klaytn.current.selectedAddress;
    const isKaikas = klaytn.current.isKaikas;

    setKlaytnNetworkVersion(networkVersion);
    setKlaytnSelectedAddress(selectedAddress);
    setKlaytnIsKaikas(isKaikas);

    console.debug(`networkVersion: ${networkVersion}`);
    console.debug(`selectedAddress: ${selectedAddress}`);
    console.debug(`isKaikas: ${isKaikas}`);
  };

  const updateMyBalance = async () => {
    if (klaytn.current.selectedAddress === undefined) {
      return;
    }
    console.debug(`getBalance`);
    setBalanceInKlay(undefined);
    setBalanceInPeb(undefined);
    const balance = await caver.current.klay.getBalance(klaytn.current.selectedAddress);

    const _balanceInKlay = caver.current.utils.fromPeb(balance, CbtCurrency_e.KLAY);
    console.debug(`balance(PEB): ${balance}`);
    console.debug(`balance(KLAY): ${_balanceInKlay}`);
    setBalanceInKlay(_balanceInKlay);
    setBalanceInPeb(balance);
  };

  // const updateKsInfo = async (address: string) => {
  //   if (existKlaytn()) {
  //     const res = await klaytn.current.enable();
  //     console.debug(`enable() res: ${res}`);
  //     setConnectedAddress(res);

  //     updateKsStakingInfo(res);
  //     updateKsPoolInfo();
  //   }
  // };

  // const updateKsInfo = () => {
  //   Ks.ks_getPoolState(caver.current).then(async (pool) => {
  //     console.debug(`pool.totalKLAY: ${pool.totalKLAY}`);
  //     console.debug(`pool.totalSKLAY: ${pool.totalSKLAY}`);

  //     setKsPoolKlay((prev) => {
  //       // if (prev && pool.totalKLAY - prev !== 0)
  //       if (prev)
  //         setKsPoolKlayDiff((p) => {
  //           if (p) {
  //             return p + (pool.totalKLAY - prev) / 1000000000000000000;
  //           }
  //           return (pool.totalKLAY - prev) / 1000000000000000000;
  //         });
  //       return pool.totalKLAY;
  //     });
  //     setKsPoolSKlay((prev) => {
  //       // if (prev && pool.totalSKLAY - prev !== 0)
  //       if (prev)
  //         setKsPoolSKlayDiff((p) => {
  //           if (p) {
  //             return p + (pool.totalSKLAY - prev) / 1000000000000000000;
  //           }
  //           return (pool.totalSKLAY - prev) / 1000000000000000000;
  //         });
  //       return pool.totalSKLAY;
  //     });
  //     setKsStakingRatio((prev) => {
  //       // if (prev && pool.totalKLAY / pool.totalSKLAY - prev !== 0) {
  //       if (prev) {
  //         setKsStakingRatioDiff((p) => {
  //           if (p) {
  //             return p + pool.totalKLAY / pool.totalSKLAY - prev;
  //           }
  //           return pool.totalKLAY / pool.totalSKLAY - prev;
  //         });
  //       }
  //       return pool.totalKLAY / pool.totalSKLAY;
  //     });
  //   });

  //   const ks_getStatusJson = async () => {
  //     const KLAYSTATION_STAKING_STATUS_JSON_URL = `https://s.klaystation.io/staking/status.json`;
  //     try {
  //       const res = await fetch(KLAYSTATION_STAKING_STATUS_JSON_URL);
  //       if (res.ok) {
  //         const json = await res.json();
  //         console.info(json);
  //         return {
  //           totalSupply: json['totalSupply'] as string,
  //           totalStaking: json['totalStaking'] as string,
  //           rewardRate: json['rewardRate']['Hashed-Ozys'] as number,
  //           priceUsd: json['priceUsd'] as number,
  //         };
  //       }
  //     } catch (err) {
  //       // console.info(`ks_getStatusJson ${err}`);
  //       console.info(`err: ${err}`);
  //     }
  //     return {
  //       totalSupply: '-1',
  //       totalStaking: '-1',
  //       rewardRate: -1,
  //       priceUsd: -1,
  //     };
  //   };

  //   ks_getStatusJson()
  //     .then((status) => {
  //       console.info(`===================== ${status.totalSupply}`);
  //       setKsJsonInfo({
  //         totalSupply: parseInt(status.totalSupply),
  //         totalStaking: parseInt(status.totalStaking),
  //         priceUsd: status.priceUsd,
  //         rewardRate: status.rewardRate,
  //       });
  //     })
  //     .catch((err) => {
  //       console.info(`======================= ${err}`);
  //     });
  // };

  // const updateKsStakingInfo = (_connectedAddress: string) => {
  //   const connectedAddressCopy = `${_connectedAddress}`; // 복사하지않으면 length가 1인경우가 있음...머지...?
  //   console.debug(`copy address : ${connectedAddressCopy}`);
  //   console.debug(`address length : ${connectedAddressCopy.length}`);
  //   const accountAddress = connectedAddressCopy.slice(2, connectedAddressCopy.length); // 0x 제거
  //   console.debug(`address without 0x : ${accountAddress}`);

  //   // staking 한 총량
  //   Ks.ks_getTotalAmount(caver.current, accountAddress).then((amount) => {
  //     console.debug(`ks amount: ${amount}`);
  //     setTotalMyStakingOrg(amount);
  //   });

  // // deposite 총량
  // ksTest
  //   .ks_getTotalDeposite(caver.current, accountAddress)
  //   .then((deposite) => {
  //     console.debug(`ks deposite: ${deposite}`);
  //     setKsTotalDeposite(deposite);
  //   });

  // Ks.ks_getAllInfo(caver.current, accountAddress).then((info) => {
  //   setKsTotalMyDeposite(info.deposite);
  //   setKsTotalMyStaking(info.totalMyStaking);
  // });

  // history info
  // Ks.ks_getTxCount(caver.current, accountAddress).then(async (count) => {
  //   console.debug(`ks count: ${count}`);

  //   const _txInfoList: KsTxInfo[] = [];
  //   for (let i = 0; i < count; i++) {
  //     // each info
  //     const info = await Ks.ks_getTxInfo(caver.current, accountAddress, i);

  //     console.debug(`ks txInfo: ${info}`);

  //     _txInfoList.push(info);
  //   }
  //   setKsTxInfoList(_txInfoList);
  // });

  // 최상훈 : 88A970f06647c52b050581d51E2dB5Fa67C7D6F3
  // 권주혜 : 983c0425Be8671501DBd5ba8E7A8304D733ea039
  // 한혜진 : 71e1f3e097a8Bf4ad7AdCe940556246BA70516cD
  // Jason: 0b6DBdc00EA48c27041A259D738b92689C39D934
  // const address = testAddress.slice(2, 42);
  // const address = connectedAddress.slice(2, connectedAddress.length);
  // };

  return (
    <FlexDiv style={{ flexDirection: 'column', alignItems: 'flex-start', padding: 5 }}>
      {/* <Txt isBold size={18}>
         kaikasTest
       </Txt>
       <Txt
       size={18}
       color={R.colors.rn.blue}
       text={`window.klaytn is ${
         existKlaytn()
         ? "defined (kaikas available)"
         : "undefined (kaikas not available)"
       }`}
     /> */}
      <FlexColumnDiv style={{ height: 20 }} />
      <TextCon isBold size={30} text={'<Klaytn Provider>'} />
      <TextCon isBold size={21} text={'Properties'} />
      <FlexColumnDiv
        style={{
          marginLeft: 15,
          // borderColor: R.colors.rn.black,
          borderWidth: 1,
          padding: 3,
          marginTop: 3,
          alignItems: 'flex-start',
        }}
      >
        <TextCon size={18} color={R.colors.rn.blue} text={`klaytn.networkVersion: ${klaytnNetworkVersion} ${klaytnNetworkVersion === '8217' ? 'mainnet' : ''}`} />
        {/* <Txt
           size={18}
           color={R.colors.rn.grey}
           text={`main network: 8217, test network: 1001`}
         /> */}
        <TextCon size={18} color={R.colors.rn.blue} text={`klaytn.selectedAddress: ${klaytnSelectedAddress}`} />
        <TextCon size={18} color={R.colors.rn.blue} text={`klaytn.isKaikas: ${klaytnIsKaikas}`} />
      </FlexColumnDiv>
      <div style={{ height: 20 }} />
      <TextCon isBold size={21} text={'klaytn._kaikas Properties'} />
      <FlexColumnDiv
        style={{
          marginLeft: 15,
          borderColor: R.colors.rn.black,
          borderWidth: 1,
          padding: 3,
          marginTop: 3,
          alignItems: 'flex-start',
        }}
      >
        <TextCon size={18} color={R.colors.rn.blue} text={`kaikasIsEnabled: ${kaikasIsEnabled}`} containerStyle={{ marginRight: 50 }} />
        <TextCon size={18} color={R.colors.rn.blue} text={`kaikasIsApproved: ${kaikasIsApproved}`} containerStyle={{ marginRight: 50 }} />
        <TextCon size={18} color={R.colors.rn.blue} text={`kaikasIsUnlocked: ${kaikasIsUnlocked}`} />
      </FlexColumnDiv>

      <div style={{ height: 20 }} />
      <div style={{ height: 20 }} />

      <FlexColumnDiv style={{ alignItems: 'flex-start' }}>
        <div style={{ height: 20 }} />
        <TextCon isBold size={30} text={'< Dashboard >'} />
        {/* Connecting to Kaikas */}
        {connectedAddress ? (
          <FlexColumnDiv>
            <TextCon 
              onConClick={() => {
                disConnectKaikas();
              }}
              containerStyle={{
                // backgroundColor: R.colors.rn.warmGray,
                padding: 10,
                marginTop: 10,
                borderWidth: 1,
                borderColor: R.colors.rn.black,
                borderRadius: 15,
              }}
              isBold
              text={`카이카스 연결해제`}
            />
            <TextCon size={18} color={R.colors.rn.blue} text={` ${loginTimeout} (${LOGIN_TIMEOUT}초 후 자동 연결해제(로그아웃))`} />
          </FlexColumnDiv>
        ) : (
          <TextCon 
            onConClick={() => {
              connectKaikas();
            }}
            containerStyle={{
              // backgroundColor: R.colors.rn.warmGray,
              padding: 10,
              marginTop: 10,
              borderWidth: 1,
              borderColor: R.colors.rn.black,
              borderRadius: 15,
            }}
            text={`카이카스 연결하기`}
            isBold
          ></TextCon>
        )}
        <div style={{ height: 20 }} />
        {connectedAddress && (
          <>
            <TextCon isBold size={21} text={'My Wallet'} />
            <FlexColumnDiv
              style={{
                marginLeft: 15,
                borderColor: R.colors.rn.black,
                borderWidth: 1,
                padding: 3,
                marginTop: 3,
                alignItems: 'flex-start',
              }}
            >
              <TextCon 
                onConClick={() => {
                  updateMyBalance();
                }}
                containerStyle={{
                  // backgroundColor: R.colors.rn.warmGray,
                  padding: 5,
                  alignSelf: 'flex-start',
                  marginTop: 0,
                  borderWidth: 1,
                  borderColor: R.colors.rn.black,
                  borderRadius: 10,
                }}
                isBold
                text={`update`}
              />
              <FlexDiv>
                <TextCon size={18} color={R.colors.rn.blue} text={`connectedAddress: ${connectedAddress}`} />
              </FlexDiv>
              <TextCon size={18} color={R.colors.rn.blue} text={`balance : ${balanceInKlay || '...'} KLAY (${balanceInPeb || '...'} PEB)`} />
            </FlexColumnDiv>
          </>
        )}
      </FlexColumnDiv>
    </FlexDiv>
  );
}

export default KaikasScreen;
