/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties, HTMLProps, useState } from 'react';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF } from 'src/ui/base_component/etc';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  text?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  fontColor?: string;
  containerStyle?: CSSProperties;
  onClick: HTMLProps<HTMLDivElement>['onClick'];
  enable?: boolean;
};

export function RoundTxtBtnAdvType2({
  fontColor = R.colors.purpleishBlue,
  text = 'text',
  width,
  height,
  fontSize: fontsize,
  onClick,
  containerStyle,
  enable = true,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseOver, setMouseOver] = useState(false);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <RoundTxtBtnAdv
      label={text}
      width={width}
      height={height}
      fontSize={fontsize}
      fontColor={fontColor}
      bgColor={'white'}
      borderColor={sMouseOver ? R.colors.purpleishBlue : R.colors.line}
      enable={enable}
      onClick={(e) => {
        if (!enable) return;
        onClick && onClick(e);
      }}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      containerStyle={containerStyle}
    />
  );
}
