/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { AnimatedFlexRowDiv, CommonInput, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, VLINE } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import TxtBtnWithMouseOverAni from 'src/ui/base_component/TxtBtnWithMouseOverAni';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMouseEnterEffectType1 } from 'src/hooks/useMouseEnterEffectType1';
import { useMakePressable } from 'src/hooks/useMakePressable';
import R from 'src/res/R';
import { ATTRIBUTE_NAME_WIDTH } from 'src/ui/common_component/SchemaAttribute';
import { CommonColumnBox, COMMON_BOX_HORIZONTAL_PADDING, CLAY, PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';
import styled from 'styled-components';
import ScreenInfo from 'src/context/screen_constants';
import { CbtAttributeType_e } from 'src/model/rpcModel';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export const SchemaAttributeDropdown = styled(CommonColumnBox)`
  z-index: 1000000;
`;

type Props = {
  idx: number;
  width?: number | string;
  name: string | undefined;
  type: CbtAttributeType_e | undefined;
  nameColor?: string;
  valueColor?: string;
  isAnim?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onDropDownOpenClick?: () => void;
  onTypeSelect?: (selectedType: CbtAttributeType_e) => void;
  onOutsideClick?: () => void;
  onDeleteClick?: () => void;
  isDropDownOpen: boolean;
};

export function SchemaAttributeForm({
  idx,
  width = '100%',
  name,
  type,
  nameColor = R.colors.black,
  valueColor = R.colors.black,
  isAnim = false,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onDropDownOpenClick,
  onTypeSelect,
  onOutsideClick,
  onDeleteClick,
  isDropDownOpen,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sIsInputFocus, setIsInputFocus] = useState(false);
  // const [sInputTxt, setInputTxt] = useState('');

  /**************************************
   * !! ref
   **************************************/
  const dropdownRef = useRef(null);
  // useOutsideClick(
  //   [dropdownRef],
  //   {
  //     insideClick: () => {
  //       DBGMSG('insideClick');
  //     },
  //     outsideClick: () => {
  //       DBGMSG('outsideClick');
  //       onOutsideClick && onOutsideClick();
  //     },
  //   },
  //   'SchemaAtt DropdownPopup'
  // );

  /**************************************
   * !! hook
   **************************************/
  const hR = useLocalSettings();
  // const hOutsideClick = useOutsideClick([dropdownRef], {
  //   insideClick: () => {},
  //   outsideClick: () => {
  //     if (!isDropDownOpen) {
  //       return;
  //     }
  //     onOutsideClick && onOutsideClick();
  //     DBGMSG('outsideClick');
  //   },
  // });

  /**************************************
   * !! render function
   **************************************/
  const renderCommonInput = () => {
    return (
      <CommonInput
        style={{
          //
          fontSize: CLAY.FORM_FONT_SIZE,
          fontWeight: 'bold',
          // ...devOutlineF,
        }}
        placeholder={hR.strings.AC_SCHEMA_CREATE_ATTR_NEW}
        value={name}
        onFocus={() => {
          DBGMSG('Input onFocus');
          onFocus && onFocus();
          setIsInputFocus(true);
        }}
        onBlur={() => {
          DBGMSG('Input onBlur');
          onBlur && onBlur();
          setIsInputFocus(false);
        }}
        onChange={(e) => {
          DBGMSG(`onChange: ${e.target.value}`);
          // setInputTxt(e.target.value);
          onChange && onChange(e);
        }}
        onKeyPress={(e) => {
          DBGMSG(`onKeyPress code:${e.code}`);
          DBGMSG(`onKeyPress key:${e.key}`);
          onKeyDown && onKeyDown(e);
        }}
      />
    );
  };
  const renderDropdownBox = () => {
    return (
      <SchemaAttributeDropdown style={{ width: '100%', height: '100%' }}>
        <FlexColumnDiv style={{ flex: 1, justifyContent: 'center' }}>
          <TxtBtnWithMouseOverAni
            size={12}
            // color={R.colors.purpleishBlue : R.colors.txtPrimary}
            color={type === CbtAttributeType_e.TEXT ? R.colors.purpleishBlue : R.colors.black}
            isBold={type === CbtAttributeType_e.TEXT}
            text={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_TEXT}
            onMouseDefalutProp={{ backgroundColor: 'white' }}
            onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
            onMouseLeaveProp={{ backgroundColor: 'white' }}
            containerStyle={{
              width: '100%',
              height: 30,
              paddingLeft: 20,
              justifyContent: 'center',
              // ...devOutlineF
            }}
            onClick={(e) => {
              onTypeSelect && onTypeSelect(CbtAttributeType_e.TEXT);
            }}
          />
          <TxtBtnWithMouseOverAni
            size={12}
            // color={R.colors.purpleishBlue : R.colors.txtPrimary}
            color={type === CbtAttributeType_e.NUMBER ? R.colors.purpleishBlue : R.colors.black}
            isBold={type === CbtAttributeType_e.NUMBER}
            text={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_NUMBER}
            onMouseDefalutProp={{ backgroundColor: 'white' }}
            onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
            onMouseLeaveProp={{ backgroundColor: 'white' }}
            containerStyle={{
              width: '100%',
              height: 30,
              paddingLeft: 20,
              justifyContent: 'center',
              // ...devOutlineF
            }}
            onClick={(e) => {
              onTypeSelect && onTypeSelect(CbtAttributeType_e.NUMBER);
            }}
          />
          <TxtBtnWithMouseOverAni
            size={12}
            // color={R.colors.purpleishBlue : R.colors.txtPrimary}
            color={type === CbtAttributeType_e.IMAGE ? R.colors.purpleishBlue : R.colors.black}
            isBold={type === CbtAttributeType_e.IMAGE}
            text={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE}
            onMouseDefalutProp={{ backgroundColor: 'white' }}
            onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
            onMouseLeaveProp={{ backgroundColor: 'white' }}
            containerStyle={{
              width: '100%',
              height: 30,
              paddingLeft: 20,
              justifyContent: 'center',
              // ...devOutlineF
            }}
            onClick={(e) => {
              onTypeSelect && onTypeSelect(CbtAttributeType_e.IMAGE);
            }}
          />
          <TxtBtnWithMouseOverAni
            size={12}
            // color={R.colors.purpleishBlue : R.colors.txtPrimary}
            color={type === CbtAttributeType_e.VIDEO ? R.colors.purpleishBlue : R.colors.black}
            isBold={type === CbtAttributeType_e.VIDEO}
            text={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO}
            onMouseDefalutProp={{ backgroundColor: 'white' }}
            onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
            onMouseLeaveProp={{ backgroundColor: 'white' }}
            containerStyle={{
              width: '100%',
              height: 30,
              paddingLeft: 20,
              justifyContent: 'center',
              // ...devOutlineF
            }}
            onClick={() => {
              onTypeSelect && onTypeSelect(CbtAttributeType_e.VIDEO);
            }}
          />
          <TxtBtnWithMouseOverAni
            size={12}
            // color={R.colors.purpleishBlue : R.colors.txtPrimary}
            color={type === CbtAttributeType_e.AUDIO ? R.colors.purpleishBlue : R.colors.black}
            isBold={type === CbtAttributeType_e.AUDIO}
            text={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO}
            onMouseDefalutProp={{ backgroundColor: 'white' }}
            onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
            onMouseLeaveProp={{ backgroundColor: 'white' }}
            containerStyle={{
              width: '100%',
              height: 30,
              paddingLeft: 20,
              justifyContent: 'center',
              // ...devOutlineF
            }}
            onClick={() => {
              onTypeSelect && onTypeSelect(CbtAttributeType_e.AUDIO);
            }}
          />
        </FlexColumnDiv>
      </SchemaAttributeDropdown>
    );
  };

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    console.debug('useEffect() init');
    // name && setInputTxt(name);
    return () => {
      console.debug('useEffect() release');
    };
  }, [name]);

  /**************************************
   * !! animation
   **************************************/
  const { hAnimProps, mouseEnter, mouseLeave } = useMouseEnterEffectType1();
  let typeTxt: string = hR.strings.AC_SCHEMA_CREATE_ATTR_NEW_TYPE;

  switch (type) {
    case null:
      typeTxt = hR.strings.AC_SCHEMA_CREATE_ATTR_NEW_TYPE;
      break;
    case CbtAttributeType_e.TEXT:
      typeTxt = hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_TEXT;
      break;
    case CbtAttributeType_e.NUMBER:
      typeTxt = hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_NUMBER;
      break;
    case CbtAttributeType_e.IMAGE:
      typeTxt = hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE;
      break;
    case CbtAttributeType_e.VIDEO:
      typeTxt = hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO;
      break;
    case CbtAttributeType_e.AUDIO:
      typeTxt = hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO;
      break;
  }

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv
        style={{
          width: width,
          // paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
          // paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
          paddingBottom: 10,
          // ...devOutlineF,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              width: width,
              alignItems: 'center',
              height: 60,
              borderRadius: 15,
            },
            sIsInputFocus ? PropsSRoundEnabled : PropsSRoundDisabled,
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
        >
          <FlexRowDiv
            style={{
              width: width,
              justifyContent: 'flex-start',
              alignItems: 'center',
              alignSelf: 'stretch',
              // paddingLeft: 14,
              // ...devOutlineF,
            }}
          >
            {/* 속성이름 입력 Input */}
            <FlexColumnDiv style={{ width: 128, paddingLeft: 12, paddingRight: 12 }}>
              {/*  */}
              {renderCommonInput()}
            </FlexColumnDiv>

            {/* | */}
            <VLINE size={40} color={R.colors.veryLightGrey2} />

            {/* 속성유형 선택 */}
            <FlexRowDiv
              style={{ alignSelf: 'stretch', alignItems: 'center', flex: 1, cursor: 'pointer' }}
              onClick={(e) => {
                onDropDownOpenClick && onDropDownOpenClick();
              }}
            >
              <TextCon
                text={typeTxt}
                size={CLAY.FORM_FONT_SIZE}
                color={type === null ? R.colors.cloudyBlue : valueColor}
                containerStyle={{ paddingLeft: 12 }}
              />

              <FlexRowDiv style={{ marginLeft: 'auto', alignSelf: 'stretch' }}>
                {/* 삭제 버튼 */}
                <ImgBtn
                  src={[R.images.creator_btn_delete3x, CLAY.NORMAL_IC_SIZE, CLAY.NORMAL_IC_SIZE]}
                  containerStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    paddingLeft: 6,
                    paddingRight: 6,
                    // ...devOutlineF,
                  }}
                  onClick={(e) => {
                    DBGMSG('onDelete click');
                    onDeleteClick && onDeleteClick();
                    e.stopPropagation();
                  }}
                />
                {/* 드롭다운 버튼 */}
                <ImgBtn
                  src={[R.images.common_btn_drop_down3x, CLAY.NORMAL_IC_SIZE, CLAY.NORMAL_IC_SIZE]}
                  containerStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    paddingLeft: 6,
                    paddingRight: 6,
                    // ...devOutlineF,
                  }}
                />
              </FlexRowDiv>
            </FlexRowDiv>
          </FlexRowDiv>
        </AnimatedFlexRowDiv>
        {isDropDownOpen && (
          <AnimatedFlexRowDiv
            ref={dropdownRef}
            style={{
              position: 'absolute',
              width: 100,
              height: 180,
              right: COMMON_BOX_HORIZONTAL_PADDING,
              top: 0,
            }}
          >
            {renderDropdownBox()}
          </AnimatedFlexRowDiv>
        )}
      </FlexColumnDiv>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          width: width,
          paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
          paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
          paddingBottom: 10,
          // ...devOutlineF,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              alignItems: 'center',
              height: 68,
              borderRadius: 15,
            },
            sIsInputFocus ? PropsSRoundEnabled : PropsSRoundDisabled,
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
        >
          <FlexRowDiv
            style={{
              //
              justifyContent: 'flex-start',
              alignSelf: 'stretch',
              width: ATTRIBUTE_NAME_WIDTH,
              paddingLeft: 14,
              // ...devOutlineF,
            }}
          >
            {/* 속성이름 입력 Input */}
            {renderCommonInput()}
          </FlexRowDiv>
          <VLINE size={50} color={R.colors.veryLightGrey2} />
          <FlexRowDiv
            style={{ alignSelf: 'stretch', alignItems: 'center', flex: 1, cursor: 'pointer' }}
            onClick={(e) => {
              onDropDownOpenClick && onDropDownOpenClick();
            }}
          >
            <TextCon text={typeTxt} size={14} color={type === null ? R.colors.cloudyBlue : valueColor} containerStyle={{ paddingLeft: 14 }} />

            <FlexRowDiv style={{ marginLeft: 'auto', alignSelf: 'stretch' }}>
              {/* 삭제 버튼 */}
              <ImgBtn
                src={[R.images.creator_btn_delete3x, 24, 24]}
                containerStyle={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  paddingLeft: 6,
                  paddingRight: 6,
                  // ...devOutlineF,
                }}
                onClick={(e) => {
                  DBGMSG('onDelete click');
                  onDeleteClick && onDeleteClick();
                  e.stopPropagation();
                }}
              />
              {/* 드롭다운 버튼 */}
              <ImgBtn
                src={[R.images.common_btn_drop_down3x, 24, 24]}
                containerStyle={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  paddingLeft: 6,
                  paddingRight: 6,
                  // ...devOutlineF,
                }}
              />
            </FlexRowDiv>
          </FlexRowDiv>
        </AnimatedFlexRowDiv>
        {isDropDownOpen && (
          <AnimatedFlexRowDiv
            ref={dropdownRef}
            style={{
              position: 'absolute',
              width: 358,
              height: 180,
              right: COMMON_BOX_HORIZONTAL_PADDING,
              top: 0,
            }}
          >
            {renderDropdownBox()}
          </AnimatedFlexRowDiv>
        )}
      </FlexColumnDiv>
    );
}
