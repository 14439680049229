/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { AST_CARD_PADDING_H_FOR_LIST, AST_CARD_PADDING_V_FOR_LIST } from 'src/ui/common_component/AssetCardForList';
import { MarektAssetCard, MarketAssetCardProps, MARKET_ASSET_CARD_HEIGHT, MARKET_ASSET_CARD_WIDTH } from 'src/ui/common_component/MarketAssetCard';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
export const MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST = MARKET_ASSET_CARD_WIDTH + AST_CARD_PADDING_H_FOR_LIST * 2;
export const MARKET_ASSET_CARD_CONTAINER_HEIGHT_FOR_LIST = MARKET_ASSET_CARD_HEIGHT + AST_CARD_PADDING_V_FOR_LIST * 2;

export function MarketAssetCardForList(props: MarketAssetCardProps & { key?: React.Key; idx?: number }) {
  return (
    <FlexDiv
      key={props.key}
      style={{
        paddingLeft: AST_CARD_PADDING_H_FOR_LIST,
        paddingRight: AST_CARD_PADDING_H_FOR_LIST,
        paddingTop: AST_CARD_PADDING_V_FOR_LIST,
        paddingBottom: AST_CARD_PADDING_V_FOR_LIST,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: props.idx ? 1000 - props.idx : 0,
        ...devOutline,
      }}
    >
      <MarektAssetCard {...props} />
    </FlexDiv>
  );
}
