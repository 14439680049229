/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps } from 'react';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import R from 'src/res/R';
import ScreenInfo from 'src/context/screen_constants';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  size?: { in: number; out: number };
  checked?: boolean;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

const RADIO_BTN_SIZE_OUT = ScreenInfo.isMobile() ? 20 : 24;
const RADIO_BTN_SIZE_IN = ScreenInfo.isMobile() ? 8 : 10;

export function RadioBtn({ size, checked = false, onClick }: Props) {
  console.debug('render');
  return (
    <FlexDiv
      style={{
        height: size?.out ?? RADIO_BTN_SIZE_OUT,
        width: size?.out ?? RADIO_BTN_SIZE_OUT,
        borderRadius: size?.out ?? RADIO_BTN_SIZE_OUT / 2,
        backgroundColor: 'white',
        borderColor: R.colors.line,
        borderWidth: 1,
        borderStyle: 'solid',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '0 0 auto',
      }}
    >
      {checked && (
        <FlexDiv
          style={{
            height: size?.in ?? RADIO_BTN_SIZE_IN,
            width: size?.in ?? RADIO_BTN_SIZE_IN,
            borderRadius: size?.in ?? RADIO_BTN_SIZE_IN / 2,
            backgroundColor: R.colors.purpleishBlue,
          }}
        />
      )}
    </FlexDiv>
  );
}
