/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useMakePressable } from 'src/hooks/useMakePressable';
import { CbtCurrencyInfo_i, CbtCurrency_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, CommonInput, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgAdv } from 'src/ui/base_component/ImgAdv';
import TextCon from 'src/ui/base_component/TextCon';
import { CommonColumnBox, PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import { CurrencyDpInfo, DBGMSG, Utils } from 'src/util/utils';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

// DESKTOP
const DT_FONT_SIZE = 14;
const DT_KLAY_IC_WIDTH = 18;
const DT_KLAY_IC_HEIGHT = 20;
const DT_FORM_PADDING_H = 14;
const DT_FORM_WIDTH = 502;
const DT_FORM_HEIGHT = 44;
const DT_INPUT_WIDTH = 335 - DT_FORM_PADDING_H * 2;
const DT_DROPDOWN_WIDTH = 166;
const DT_DROPDOWN_HEIGHT = 90;
const DT_DROPDOWN_PADDING_LEFT = 13;
const DT_DROPDOWN_IC_SIZE = 24;

// MOBILE
const MB_FONT_SIZE = 12;
const MB_KLAY_IC_WIDTH = 16;
const MB_KLAY_IC_HEIGHT = 18;
const MB_FORM_PADDING_H = 12;
const MB_FORM_WIDTH = 256;
const MB_FORM_HEIGHT = 38;
const MB_INPUT_WIDTH = 171 - MB_FORM_PADDING_H * 2;
const MB_DROPDOWN_WIDTH = 84;
const MB_DROPDOWN_HEIGHT = 90;
const MB_DROPDOWN_PADDING_LEFT = 5;
const MB_DROPDOWN_IC_SIZE = 16;

const FONT_SIZE = ScreenInfo.isMobile() ? MB_FONT_SIZE : DT_FONT_SIZE;
const KLAY_IC_WIDTH = ScreenInfo.isMobile() ? MB_KLAY_IC_WIDTH : DT_KLAY_IC_WIDTH;
const KLAY_IC_HEIGHT = ScreenInfo.isMobile() ? MB_KLAY_IC_HEIGHT : DT_KLAY_IC_HEIGHT;
const FORM_PADDING_H = ScreenInfo.isMobile() ? MB_FORM_PADDING_H : DT_FORM_PADDING_H;
const FORM_WIDTH = ScreenInfo.isMobile() ? MB_FORM_WIDTH : DT_FORM_WIDTH;
const FORM_HEIGHT = ScreenInfo.isMobile() ? MB_FORM_HEIGHT : DT_FORM_HEIGHT;
const INPUT_WIDTH = ScreenInfo.isMobile() ? MB_INPUT_WIDTH : DT_INPUT_WIDTH;
const DROPDOWN_WIDTH = ScreenInfo.isMobile() ? MB_DROPDOWN_WIDTH : DT_DROPDOWN_WIDTH;
const DROPDOWN_HEIGHT = ScreenInfo.isMobile() ? MB_DROPDOWN_HEIGHT : DT_DROPDOWN_HEIGHT;
const DROPDOWN_PADDING_LEFT = ScreenInfo.isMobile() ? MB_DROPDOWN_PADDING_LEFT : DT_DROPDOWN_PADDING_LEFT;
const DROPDOWN_IC_SIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_IC_SIZE : DT_DROPDOWN_IC_SIZE;

const Dropdown = styled(CommonColumnBox)`
  z-index: 1000000;
`;

type Props = {
  value?: string;
  defaultCurrency?: CbtCurrency_e;
  isAnim?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onDropdownMenuSelect: (selected: CbtCurrencyInfo_i) => void;
  currencyInfoList: CbtCurrencyInfo_i[];
};

export function PriceWithCurrencyInput({
  value,
  defaultCurrency,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onDropdownMenuSelect,
  currencyInfoList,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sSelectedCurrency, setSelectedCurrency] = useState<CbtCurrency_e>();
  const [sMouseEnterCurrency, setMouseEnterCurrency] = useState<CbtCurrency_e>();
  const [sIsFocus, setIsFocus] = useState(false);
  const [sShowDropdownMenu, setShowDropdownMenu] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const currencyDivRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  /**************************************
   * !! hook
   **************************************/
  useMakePressable([
    {
      elementKey: 'currency_dropdown',
      elementRefList: [dropdownMenuRef],
      onInsidePress: ({ e, hitRef }) => {},
      onOutsidePress: (e) => {
        setShowDropdownMenu(false);
      },
    },
  ]);

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    if (currencyInfoList.length === 0) return () => {};
    if (defaultCurrency) {
      const found = currencyInfoList.find((currInfo) => currInfo.currency === defaultCurrency);
      if (found) setSelectedCurrency(defaultCurrency);
      else setSelectedCurrency(currencyInfoList[0].currency);
    } else {
      setSelectedCurrency(currencyInfoList[0].currency);
    }
    return () => {};
  }, []);

  /**************************************
   * !! render
   **************************************/
  const renderDropdownMenuList = () => {
    return currencyInfoList.map((currInfo, idx) => renderDropdownMenu(Utils.svc.getCurrencyDpInfo(currInfo.currency)));
  };
  const renderDropdownMenu = ({ currency, icSrc, unit }: CurrencyDpInfo) => {
    return (
      <FlexRowDiv
        style={{
          height: 30,
          alignSelf: 'stretch',
          backgroundColor: sMouseEnterCurrency === currency ? R.colors.paleGrey : undefined,
          alignItems: 'center',
        }}
        onMouseEnter={() => {
          setMouseEnterCurrency(currency);
        }}
        onMouseLeave={() => {
          setMouseEnterCurrency(undefined);
        }}
        onClick={(e) => {
          const found = currencyInfoList.find((currInfo) => currInfo.currency === currency);
          if (!found) {
            DBGMSG('what');
            return;
          }

          onDropdownMenuSelect(found);
          setSelectedCurrency(currency);
          setTimeout(() => {
            setShowDropdownMenu(false);
          }, 0);
        }}
      >
        <WIDTH size={10} />
        <Img src={[icSrc, 18, 20]} />
        <WIDTH size={2} />
        <TextCon
          size={12}
          color={sSelectedCurrency === currency ? R.colors.purpleishBlue : R.colors.black}
          isBold
          text={unit}
          containerStyle={{
            justifyContent: 'center',
            // ...devOutlineF
          }}
        />
      </FlexRowDiv>
    );
  };

  if (!sSelectedCurrency) {
    return null;
  }

  return (
    <>
      <FlexRowDiv
        style={Object.assign(
          {
            width: FORM_WIDTH,
            height: FORM_HEIGHT,
            alignItems: 'center',
            backgroundColor: R.colors.bgGrey,
          },
          sIsFocus ? PropsSRoundEnabled : PropsSRoundDisabled
        )}
      >
        {/* 가격 입력 */}
        <CommonInput
          type={'number'}
          style={{
            fontSize: FONT_SIZE,
            width: INPUT_WIDTH,
            paddingBottom: 3,
            paddingLeft: FORM_PADDING_H,
            // paddingRight: FORM_PADDING_H,
            // ...devOutlineF,
          }}
          value={value}
          onFocus={() => {
            DBGMSG('Input onFocus');
            onFocus && onFocus();
            setIsFocus(true);
          }}
          onBlur={() => {
            DBGMSG('Input onBlur');
            onBlur && onBlur();
            setIsFocus(false);
          }}
          onChange={(e) => {
            DBGMSG(`onChange: ${e.target.value}`);
            onChange && onChange(e);
          }}
          onKeyPress={(e) => {
            DBGMSG(`onKeyPress code:${e.code}`);
            DBGMSG(`onKeyPress key:${e.key}`);
            onKeyDown && onKeyDown(e);
          }}
        />
        {/* 중간 라인 */}
        <FlexDiv style={{ height: FORM_HEIGHT - 4, width: 1, backgroundColor: R.colors.veryLightGrey2, marginLeft: 'auto' }} />

        {/* currency */}
        <FlexRowDiv
          ref={currencyDivRef}
          style={{
            width: DROPDOWN_WIDTH,
            alignSelf: 'stretch',
            alignItems: 'center',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            paddingLeft: DROPDOWN_PADDING_LEFT,
            // marginLeft: 'auto',
            // paddingLeft: 3,
            //
            // ...devOutlineFG,
          }}
          onClick={(e) => {
            setShowDropdownMenu(true);
          }}
        >
          <Img src={[Utils.svc.getCurrencyDpInfo(sSelectedCurrency).icSrc, KLAY_IC_WIDTH, KLAY_IC_HEIGHT]} />
          <WIDTH size={2} />
          <TextCon text={`${Utils.svc.getCurrencyDpInfo(sSelectedCurrency).unit}`} isBold size={FONT_SIZE} color={R.colors.dark} />
          <Img
            src={[R.images.common_btn_drop_down3x, DROPDOWN_IC_SIZE, DROPDOWN_IC_SIZE]}
            containerStyle={{
              height: '100%',
              marginLeft: 'auto',
              paddingLeft: 6,
              paddingRight: 6,
              alignItems: 'center',

              // ...devOutlineFG
            }}
          />
          {/* 드롭다운 메뉴 */}
          {sShowDropdownMenu && (
            <AnimatedFlexRowDiv
              ref={dropdownMenuRef}
              style={{
                position: 'absolute',
                // backgroundColor: R.colors.devColor1a,
                width: DROPDOWN_WIDTH,
                height: 90,
                right: 0,
                top: 0,
              }}
            >
              <Dropdown style={{ width: '100%', height: '100%' }}>
                <FlexColumnDiv style={{ flex: 1, justifyContent: 'center' }}>
                  {/*  */}
                  {renderDropdownMenuList()}
                </FlexColumnDiv>
              </Dropdown>
            </AnimatedFlexRowDiv>
          )}
        </FlexRowDiv>
      </FlexRowDiv>
    </>
  );
}
