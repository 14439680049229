import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppConf, isProd, RPC_CONTEXT } from 'src/conf/AppConf';
import { CmnTxReqInfo_i, CommonRx_i, REQ_METHOD } from 'src/model/rpcModel';
import { RPC_UPLOAD } from 'src/model/rpcType';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

export const brewRpcUrl = (path: string) => `${AppConf.HOST_URLS.rpc}/${RPC_CONTEXT}${path}`;

// const _axios = axios.create({
//   baseURL: AppConf.HOST_URLS.rpc,
//   // timeout: 5000,
// });

axios.defaults.headers.post['Content-Type'] = 'application/json';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    DBGMSG(config);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    DBGMSG(response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

// export async function rpcWithObj(rpcObj: BaseRpc & { token?: string }) {
//   // url 생성
//   // http로 시작하면 그대로 사용함
//   const url = rpcObj.path.startsWith('http') ? rpcObj.path : brewRpcUrl(rpcObj.path);
//   DBGMSG(`$$$$ make rpc url:${url}`);
//   try {
//     const res = await rawAxiosCall({ url, method: rpcObj.method, tx: rpcObj.tx, token: rpcObj.token });

//     // TODO CommonRx 체크
//     if (!res.data.success) {
//       DBGMSGW(`$$$$ rpc err2:${res.data}`);
//       // const errInfo = getRpcErrorInfo(res.data);
//       // if (errInfo) {
//       //   DBGMSGW(`code: ${errInfo.code}`);
//       //   DBGMSGW(`emsg: ${errInfo.emsg}`);
//       // }
//       return Promise.reject(res.data);
//     }
//     return Promise.resolve(res);
//   } catch (err) {
//     DBGMSGW(`$$$$ rpc err2:${err}`);
//     return Promise.reject(err);
//   }
// }

export async function rpcWithTxReqInfo(args: CmnTxReqInfo_i) {
  // url 생성
  // http로 시작하면 그대로 사용함
  const url = args.url.startsWith('http') ? args.url : brewRpcUrl(args.url);
  DBGMSG(`$$$$RPC url:${url}`);
  try {
    const res = await rawAxiosCall({ url, method: args.method, tx: args.tx, token: args.token });

    // TODO CommonRx 체크
    const errInfo = getRpcErrorInfo(res.data);
    if (errInfo) {
      DBGMSGW(`$$$$RPC code: ${errInfo.ecode}`);
      DBGMSGW(`$$$$RPC emsg: ${errInfo.emsg}`);
      return Promise.reject({ ...res.data, reqInfo: args });
    }

    return Promise.resolve(res);
  } catch (err) {
    DBGMSGW(`$$$$RPC err2:${err}`);
    return Promise.reject(err);
  }
}

export async function rawAxiosCall({
  url,
  tx,
  method = REQ_METHOD.POST, // 설정값이 없으면 (default) POST 요청
  token,
}: {
  url: string;
  tx: object;
  method?: REQ_METHOD;
  token?: string;
}) {
  // form data로 전송
  // const formData = reqParam && getFormData(reqParam);

  // application/x-www-form-urlencoded 포맷으로 전송
  // https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
  // const postData = method === HTTP_METHOD.POST ? qs.stringify(tx) : undefined;

  const postData = method === REQ_METHOD.POST ? tx : undefined;
  const getData = method === REQ_METHOD.GET ? tx : undefined;

  try {
    DBGMSG(`$$$$ >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
$$$$RPC REQUEST $$$$
time: ${Utils.date.brewFomatString(new Date(), 'HH시 mm분 ss초 (SSS)')}
endpoint: ${url}
method: ${method}
token: ${token}
reqParam: \n${JSON.stringify(tx, undefined, 2)}
$$$$ >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`);
    const options: AxiosRequestConfig = {
      method: method === REQ_METHOD.POST ? 'post' : 'get',
      url: url,
      headers: {
        Authorization: `Bearer ${token || ''}`,
      },
      params: getData,
      data: postData,
    };
    const res: AxiosResponse<CommonRx_i> = await axios(options);
    DBGMSG(`$$$$ <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
$$$$RPC RESPONSE $$$$
time: ${Utils.date.brewFomatString(new Date(), 'HH시 mm분 ss초 (SSS)')}
endpoint: ${url}
res(body): \n${JSON.stringify(res.data, undefined, 2)}
$$$$ <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<`);

    return Promise.resolve(res);
  } catch (err) {
    printAxiosErr(err);
    return Promise.reject(err);
  }
}

function isRpcSuccess(res: any) {
  if ('ok' in res) {
    if (typeof res.ok === 'boolean') return res.ok as boolean;
  }
  return false;
}

export function hasEmsg(res: any) {
  console.debug(`isRpcFailure`);
  if ('data' in res) {
    if ('code' in res.data && 'emsg' in res.data) {
      return true;
    }
  }

  return false;
}

export function hasOk(res: any) {
  console.debug(`isRpcFailure2`);
  if ('data' in res) {
    if ('ok' in res.data) {
      console.debug(`isRpcFailure2 ${res.data.ok}`);
      return res.data.ok as boolean;
    }
  }
  console.debug(`isRpcFailure2 undefined`);
  return undefined;
}

export function getRpcErrorInfo(data: any) {
  if (!data) {
    DBGMSGW('getRpcErrorInfo:', data);
    return;
  }
  if (!('success' in data && 'ecode' in data)) {
    return undefined;
  }

  if (data.success || data.ecode === 0) {
    return undefined;
  }

  return {
    reqInfo: data.reqInfo as CmnTxReqInfo_i | undefined,
    ecode: data.ecode,
    emsg: 'emsg' in data ? data.emsg : undefined,
    msg: `${data.ecode}: ${data.emsg}`,
  };
}

export function getToken(data: any) {
  if ('token' in data && data.token !== null) return data.token;
}

const getFormData = (objData: object) => {
  const form = new FormData();
  for (const [key, value] of Object.entries(objData)) {
    !isProd() && DBGMSG(`$$$$ form ${key}: ${value}`);
    form.append(key, `${value}`);
  }
  return form;
};

// let formDataObj: object | undefined;
// if (!isProduction() && formData) {
//   const formDataMap = new Map<string, string>();
//   const pairs = Array.from(formData.entries());
//   for (let pair of pairs) {
//     formDataMap.set(pair[0], pair[1] as string);
//   }
//   formDataObj = Object.fromEntries(formDataMap);
// }

export const fileUpload = async (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const form = new FormData();
    form.append('files', file);

    DBGMSG(`$$$$ >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
$$$$RPC FILEUPLOAD $$$$
time: ${Utils.date.brewFomatString(new Date(), 'HH시 mm분 ss초 (SSS)')}
endpoint: ${RPC_UPLOAD.url}
method: post
reqParam: \n${JSON.stringify(file, undefined, 2)}
$$$$ >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`);
    axios
      .post(RPC_UPLOAD.url, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        DBGMSG(`$$$$ <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
$$$$RPC RESPONSE $$$$
time: ${Utils.date.brewFomatString(new Date(), 'HH시 mm분 ss초 (SSS)')}
endpoint: ${RPC_UPLOAD.url}
res(body): \n${JSON.stringify(res.data, undefined, 2)}
$$$$ <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<`);
        if ('message' in res.data) {
          const message: string = res.data.message;
          const json: string[] = JSON.parse(message);
          resolve(json[0]);
        } else {
          reject('message not found');
        }
      })
      .catch((err) => {
        printAxiosErr(err);
        reject(err);
      });
  });
};

const printAxiosErr = (err: any) => {
  DBGMSGW(`$$$$RPC axios err:${JSON.stringify(err, undefined, 2)}`);
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    DBGMSGW(`$$$$RPC ${err.response.data}`);
    DBGMSGW(`$$$$RPC ${err.response.status}`);
    DBGMSGW(`$$$$RPC ${err.response.headers}`);
  } else if (err.request) {
    // The request was made but no response was received
    // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    DBGMSGW(`$$$$RPC ${err.request}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    DBGMSGW('$$$$RPC Error', err.message);
  }
  DBGMSGW(`$$$$RPC ${err.config}`);
};
