/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { devOutlineF, FlexColumnDiv, FlexDiv, HEIGHT, HLINE } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { DBGMSG } from 'src/util/utils';
import { CLAY } from 'src/ui/layout_constant';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import ScreenInfo from 'src/context/screen_constants';
import { AppConf, isProd } from 'src/conf/AppConf';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import {
  TERMS_OF_USE_TITLE_FONTSIZE,
  TERMS_OF_USE_DESC_FONTSIZE,
  TERMS_OF_USE_SECTION_TITLE_FONTSIZE,
} from 'src/ui/screen/User/UserTermsOfUseScreen';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { useQsLang } from 'src/hooks/useQsLang';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DT_TITLE_FONTSIZE = 30;
const DT_DESC_FONTSIZE = 14;
const DT_REASON_TITLE_FONTSIZE = 22;
const DT_REASON_DESC_FONTSIZE = 14;
const DT_REASON_X_FONTSIZE = 16;
const DT_REASON_X_DESC_FONTSIZE = 16;
const DT_REASON_GUIDE_FONTSIZE = 14;
const DT_TITLE_MARGIN_BOTTOM = 30;
const DT_DESC_MARGIN_BOTTOM = 49;
const DT_REASON_TITLE_MARGIN_TOP = 29;
const DT_REASON_TITLE_MARGIN_BOTTOM = 10;
const DT_REASON_DESC_MARGIN_BOTTOM = 40;
const DT_REASON_X_MARGIN_BOTTOM = 3;
const DT_REASON_X_DESC_MARGIN_BOTTOM = 12;
const DT_REASON_GUIDE_MARGIN_V = 40;
const DT_PADDING_BOTTOM = 128;
const DT_REASON_X_DESC_LEFT_PADDING = 13;

const MB_TITLE_FONTSIZE = 16;
const MB_DESC_FONTSIZE = 10;
const MB_REASON_TITLE_FONTSIZE = 12;
const MB_REASON_DESC_FONTSIZE = 10;
const MB_REASON_X_FONTSIZE = 10;
const MB_REASON_X_DESC_FONTSIZE = 10;
const MB_REASON_GUIDE_FONTSIZE = 10;
const MB_TITLE_MARGIN_BOTTOM = 20;
const MB_DESC_MARGIN_BOTTOM = 20;
const MB_REASON_TITLE_MARGIN_TOP = 20;
const MB_REASON_TITLE_MARGIN_BOTTOM = 5;
const MB_REASON_DESC_MARGIN_BOTTOM = 20;
const MB_REASON_X_MARGIN_BOTTOM = 3;
const MB_REASON_X_DESC_MARGIN_BOTTOM = 10;
const MB_REASON_GUIDE_MARGIN_V = 20;
const MB_PADDING_BOTTOM = 60;
const MB_REASON_X_DESC_LEFT_PADDING = 8;

const TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_TITLE_FONTSIZE : DT_TITLE_FONTSIZE;
const DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_DESC_FONTSIZE : DT_DESC_FONTSIZE;
const REASON_TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_TITLE_FONTSIZE : DT_REASON_TITLE_FONTSIZE;
const REASON_DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_DESC_FONTSIZE : DT_REASON_DESC_FONTSIZE;
const REASON_X_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_X_FONTSIZE : DT_REASON_X_FONTSIZE;
const REASON_X_DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_X_DESC_FONTSIZE : DT_REASON_X_DESC_FONTSIZE;
const REASON_GUIDE_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_GUIDE_FONTSIZE : DT_REASON_GUIDE_FONTSIZE;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_DESC_MARGIN_BOTTOM : DT_DESC_MARGIN_BOTTOM;
const REASON_TITLE_MARGIN_TOP = ScreenInfo.isMobile() ? MB_REASON_TITLE_MARGIN_TOP : DT_REASON_TITLE_MARGIN_TOP;
const REASON_TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_TITLE_MARGIN_BOTTOM : DT_REASON_TITLE_MARGIN_BOTTOM;
const REASON_DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_DESC_MARGIN_BOTTOM : DT_REASON_DESC_MARGIN_BOTTOM;
const REASON_X_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_X_MARGIN_BOTTOM : DT_REASON_X_MARGIN_BOTTOM;
const REASON_X_DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_X_DESC_MARGIN_BOTTOM : DT_REASON_X_DESC_MARGIN_BOTTOM;
const REASON_GUIDE_MARGIN_V = ScreenInfo.isMobile() ? MB_REASON_GUIDE_MARGIN_V : DT_REASON_GUIDE_MARGIN_V;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const REASON_X_DESC_LEFT_PADDING = ScreenInfo.isMobile() ? MB_REASON_X_DESC_LEFT_PADDING : DT_REASON_X_DESC_LEFT_PADDING;

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export const renderIndentedMultiLine = (str: string) => {
  return (
    <>
      {str.split('\n').map((_str) => {
        return (
          <>
            <TextCon
              text={_str}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
              textStyle={
                {
                  // textIndent: ScreenInfo.isMobile() ? -11 : -14,
                }
              }
            />
          </>
        );
      })}
      <HEIGHT size={20} />
    </>
  );
};

export const renderMultiLine = (str: string) => {
  return (
    <>
      {str.split('\n').map((_str) => {
        return (
          <>
            <TextCon
              text={_str}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
              textStyle={
                {
                  // textIndent: ScreenInfo.isMobile() ? -11 : -14,
                }
              }
              // containerStyle={{ marginLeft: ScreenInfo.isMobile() ? -11 : -14 }}
            />
          </>
        );
      })}
      <HEIGHT size={20} />
    </>
  );
};

export const renderSectionTitle = (title: string) => {
  return (
    <>
      <TextCon
        text={title}
        size={TERMS_OF_USE_SECTION_TITLE_FONTSIZE}
        color={R.colors.black}
        isBold
        textStyle={
          {
            // textIndent: ScreenInfo.isMobile() ? -11 : -14,
          }
        }
      />
      <HEIGHT size={20} />
    </>
  );
};
export const renderTitle = (title: string) => {
  return (
    <>
      <HEIGHT size={10} />
      <TextCon
        text={title}
        size={TERMS_OF_USE_TITLE_FONTSIZE}
        color={R.colors.black}
        isBold
        textStyle={
          {
            // textIndent: ScreenInfo.isMobile() ? -11 : -14,
          }
        }
      />
      <HEIGHT size={30} />
    </>
  );
};

export function UserRefundPolicyScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`RefundPolicy - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hR = useLocalSettings();

  /**************************************
   * !! hooks
   **************************************/
  useQsLang();

  const render = () => {
    if (hR.lang === CbtUserLanguage_e.KO) {
      return (
        <>
          {renderTitle(R.refundPolicy.ko.TITLE)}
          {renderSectionTitle(R.refundPolicy.ko.TITLE_1)}
          {renderMultiLine(R.refundPolicy.ko.DETAIL_1)}
          {renderSectionTitle(R.refundPolicy.ko.TITLE_2)}
          {renderMultiLine(R.refundPolicy.ko.DETAIL_2)}
        </>
      );
    } else if (hR.lang === CbtUserLanguage_e.JA) {
      return (
        <>
          {renderTitle(R.refundPolicy.ja.TITLE)}
          {renderSectionTitle(R.refundPolicy.ja.TITLE_1)}
          {renderMultiLine(R.refundPolicy.ja.DETAIL_1)}
          {renderSectionTitle(R.refundPolicy.ja.TITLE_2)}
          {renderMultiLine(R.refundPolicy.ja.DETAIL_2)}
        </>
      );
    } else {
      return (
        <>
          {renderTitle(R.refundPolicy.en.TITLE)}
          {renderSectionTitle(R.refundPolicy.en.TITLE_1)}
          {renderMultiLine(R.refundPolicy.en.DETAIL_1)}
          {renderSectionTitle(R.refundPolicy.en.TITLE_2)}
          {renderMultiLine(R.refundPolicy.en.DETAIL_2)}
        </>
      );
    }
  };

  return (
    <MobileScalableFlexColumn>
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: CLAY.PAGE_WIDTH,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          paddingLeft: CLAY.HPADDING,
          paddingRight: CLAY.HPADDING,
          ...devOutline,
        }}
      >
        {render()}
      </FlexColumnDiv>
      <HEIGHT size={200} />
      {ScreenInfo.isMobile() && <CommonFooter />}
    </MobileScalableFlexColumn>
  );
}
