/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { ServerECode_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { CLAY, CommonColumnBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DT_BACTBTN_MARGIN_BOTTOM = 30;
const DT_BOX_WIDTH = 582;
const DT_INPUT_WIDTH = 522;
const DT_INPUT_HEIGHT = 44;
const DT_BOX_HAPADDING = 30;
const DT_BTN_WIDTH = 355;
const DT_BTN_HEIGHT = 48;
const DT_BTN_FONTSIZE = 14;
const DT_BOTTOM_SPACE = 348;
const DT_PASSWORD_VISIBLE_IC_SIZE = 30;
const DT_CHECH_IC_SIZE = 20;

const MB_BACTBTN_MARGIN_BOTTOM = 20;
const MB_BOX_WIDTH = CLAY.BODY_WIDTH;
const MB_BOX_BODY_WIDTH = 256;
const MB_INPUT_HEIGHT = 38;
const MB_BOX_HAPADDING = 12;
const MB_BTN_WIDTH = MB_BOX_BODY_WIDTH;
const MB_BTN_HEIGHT = 44;
const MB_BTN_FONTSIZE = 12;
const MB_BOTTOM_SPACE = 60;
const MB_PASSWORD_VISIBLE_IC_SIZE = 24;
const MB_CHECH_IC_SIZE = 18;

const BACTBTN_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_BACTBTN_MARGIN_BOTTOM : DT_BACTBTN_MARGIN_BOTTOM;
const BOX_WIDTH = ScreenInfo.isMobile() ? MB_BOX_WIDTH : DT_BOX_WIDTH;
const BOX_BODY_WIDTH = ScreenInfo.isMobile() ? MB_BOX_BODY_WIDTH : DT_INPUT_WIDTH;
const INPUT_HEIGHT = ScreenInfo.isMobile() ? MB_INPUT_HEIGHT : DT_INPUT_HEIGHT;
const BOX_HAPADDING = ScreenInfo.isMobile() ? MB_BOX_HAPADDING : DT_BOX_HAPADDING;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_FONTSIZE = ScreenInfo.isMobile() ? MB_BTN_FONTSIZE : DT_BTN_FONTSIZE;
const BOTTOM_SPACE = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE : DT_BOTTOM_SPACE;
const PASSWORD_VISIBLE_IC_SIZE = ScreenInfo.isMobile() ? MB_PASSWORD_VISIBLE_IC_SIZE : DT_PASSWORD_VISIBLE_IC_SIZE;
const CHECH_IC_SIZE = ScreenInfo.isMobile() ? MB_CHECH_IC_SIZE : DT_CHECH_IC_SIZE;

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserChangePasswordScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sCurPassword, setCurPassword] = useState<string>('');
  const [sCurPasswordDisable, setCurPasswordDisable] = useState(false);
  const [sCurPasswordValidation, setCurPasswordValidation] = useState<{
    isOk: boolean;
    emsg?: string;
  }>({ isOk: false });
  const [sCurPasswordFocus, setCurPasswordFocus] = useState(false);

  const [sNewPassword, setNewPassword] = useState<string>('');
  const [sNewPasswordDisable, setNewPasswordDisable] = useState(false);
  const [sNewPasswordFocus, setNewPasswordFocus] = useState(false);
  const [sNewPasswordValidation, setNewPasswordValidation] = useState<{
    isOk: boolean;
    check1: boolean; // 영문 대문자 포함
    check2: boolean; // 영문 소문자 포함
    check3: boolean; // 숫자 포함
    check4: boolean; // 10자 이상
  }>({
    isOk: false,
    check1: false,
    check2: false,
    check3: false,
    check4: false,
  });

  const [sNewPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
  const [sNewPasswordConfirmDisable, setNewPasswordConfirmDisable] = useState(false);
  const [sNewPasswordConfirmValidation, setNewPasswordConfirmValidation] = useState<{
    isOk: boolean;
    emsg?: string;
  }>({ isOk: false });
  const [sNewPasswordConfirmFocus, setNewPasswordConfirmFocus] = useState(false);

  const [sCurPasswordVisible, setCurPasswordVisible] = useState(false);
  const [sNewPasswordVisible, setNewPasswordVisible] = useState(false);
  const [sNewPasswordConfirmVisible, setNewPasswordConfirmVisible] = useState(false);

  const [sBtnEnable, setBtnEnable] = useState(false);
  const [sComplete, setComplete] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const curPasswordInputRef = useRef<HTMLInputElement | null>(null);
  const newPasswordInputRef = useRef<HTMLInputElement | null>(null);
  const newPasswordConfirmInputRef = useRef<HTMLInputElement | null>(null);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User Change PW - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHisotry = useHistory();
  const hRpc = useRpc();
  const hR = useLocalSettings();
  const hAuth = useAuth();
  // const { pageWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    DBGMSG('useEffect() init');
    if (sCurPasswordValidation.isOk && sNewPasswordValidation.isOk && sNewPasswordConfirmValidation.isOk) {
      setBtnEnable(true);
    } else {
      setBtnEnable(false);
    }
    return () => {
      DBGMSG('useEffect() release');
    };
  }, [sCurPasswordValidation.isOk, sNewPasswordConfirmValidation.isOk, sNewPasswordValidation.isOk]);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const checkPasswordConfirm = (p1: string, p2: string) => {
    if (p1 !== p2) {
      setNewPasswordConfirmValidation({ isOk: false, emsg: hR.strings.MY_PWCHANGE_NEW_PASSWORD_CONFIRM_NO_MATCH });
    } else {
      setNewPasswordConfirmValidation({ isOk: true });
    }
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  if (!hAuth.isLogin) {
    return <TextCon text={'not login'} />;
  }

  DBGMSG('render');
  return (
    <MobileScalableFlexColumn>
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: CLAY.PAGE_WIDTH,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          paddingLeft: CLAY.HPADDING,
          paddingRight: CLAY.HPADDING,
          ...devOutline,
        }}
      >
        <BackBtn />
        <HEIGHT size={BACTBTN_MARGIN_BOTTOM} />
        <CommonColumnBox
          style={{
            alignSelf: 'center',
            width: BOX_WIDTH,
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: BOX_HAPADDING,
            paddingRight: BOX_HAPADDING,
          }}
        >
          <TextCon
            text={hR.strings.MY_PWCHANGE_TITLE}
            size={CLAY.TITLE_SIZE_IN_BOX}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'center' }}
          />
          <HEIGHT size={40} />

          {/* 현재 PASSWORD */}
          <TextCon
            text={hR.strings.MY_PWCHANGE_CUR_PASSWORD}
            size={CLAY.DEFAULT_FONT_SIZE}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={14} />
          <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'center' }}>
            <SRoundInputText
              width={BOX_BODY_WIDTH}
              height={INPUT_HEIGHT}
              fontSize={CLAY.DEFAULT_FONT_SIZE}
              disabled={sCurPasswordDisable}
              getRef={(inputRef) => {
                curPasswordInputRef.current = inputRef;
              }}
              value={sCurPassword}
              inputType={sCurPasswordVisible ? 'text' : 'password'}
              placeHolder={hR.strings.MY_PWCHANGE_CUR_PASSWORD_PLACEHOLDER}
              onChange={(e) => {
                setCurPassword(e.target.value);
                setCurPasswordValidation({ isOk: true });
              }}
              onFocus={() => {
                setCurPasswordFocus(true);
              }}
              onBlur={() => {
                setCurPasswordFocus(false);
              }}
            />
            <ImgBtn
              src={[
                sCurPasswordVisible ? R.images.common_btn_visible3x : R.images.common_btn_not_visible3x,
                PASSWORD_VISIBLE_IC_SIZE,
                PASSWORD_VISIBLE_IC_SIZE,
              ]}
              containerStyle={{ position: 'absolute', right: 1, padding: 7 }}
              onClick={() => {
                setCurPasswordVisible(!sCurPasswordVisible);
                curPasswordInputRef.current?.focus();
              }}
            />
          </FlexRowDiv>
          {sCurPasswordValidation && !sCurPasswordValidation.isOk && sCurPasswordValidation.emsg && (
            <>
              <HEIGHT size={14} />
              <TextCon
                text={sCurPasswordValidation.emsg}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.pastelRed}
                containerStyle={{ alignSelf: 'flex-start' }}
              />
            </>
          )}

          <HEIGHT size={24} />

          {/* New PASSWORD */}
          <TextCon
            text={hR.strings.MY_PWCHANGE_NEW_PASSWORD}
            size={CLAY.DEFAULT_FONT_SIZE}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={14} />
          <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'center' }}>
            <SRoundInputText
              width={BOX_BODY_WIDTH}
              height={INPUT_HEIGHT}
              fontSize={CLAY.DEFAULT_FONT_SIZE}
              disabled={sNewPasswordDisable}
              getRef={(inputRef) => {
                newPasswordInputRef.current = inputRef;
              }}
              value={sNewPassword}
              inputType={sNewPasswordVisible ? 'text' : 'password'}
              placeHolder={hR.strings.MY_PWCHANGE_NEW_PASSWORD_PLACEHOLDER}
              onChange={(e) => {
                setNewPassword(e.target.value);

                const UpperCaseRegex = /[A-Z]/;
                const LowerCaseRegex = /[a-z]/;
                const NumRegex = /[0-9]/;

                const check1 = UpperCaseRegex.test(e.target.value);
                const check2 = LowerCaseRegex.test(e.target.value);
                const check3 = NumRegex.test(e.target.value);
                const check4 = e.target.value.length >= 10;

                setNewPasswordValidation({
                  isOk: check1 && check2 && check3 && check4,
                  check1: check1,
                  check2: check2,
                  check3: check3,
                  check4: check4,
                });
                // checkPasswordConfirm(e.target.value, sNewPasswordConfirm);
              }}
              onFocus={() => {
                setNewPasswordFocus(true);
              }}
              onBlur={() => {
                setNewPasswordFocus(false);
              }}
            />
            {sNewPasswordValidation.isOk && (
              <Img src={[R.images.join_ic_confirm3x, CHECH_IC_SIZE, CHECH_IC_SIZE]} containerStyle={{ position: 'absolute', right: 43 }} />
            )}
            <ImgBtn
              src={[
                sNewPasswordVisible ? R.images.common_btn_visible3x : R.images.common_btn_not_visible3x,
                PASSWORD_VISIBLE_IC_SIZE,
                PASSWORD_VISIBLE_IC_SIZE,
              ]}
              containerStyle={{ position: 'absolute', right: 1, padding: 7 }}
              onClick={() => {
                setNewPasswordVisible(!sNewPasswordVisible);
                newPasswordInputRef.current?.setSelectionRange(sNewPassword.length, sNewPassword.length);
                newPasswordInputRef.current?.focus();
              }}
            />
          </FlexRowDiv>

          <FlexColumnDiv style={{ alignSelf: 'stretch', height: sNewPasswordFocus ? undefined : 0, overflow: 'hidden' }}>
            <HEIGHT size={12} />
            <FlexColumnDiv
              style={{
                alignSelf: 'stretch',
                borderRadius: 15,
                backgroundColor: R.colors.lightGrey,
                padding: 14,
              }}
            >
              <TextCon
                text={hR.strings.MY_PWCHANGE_PASSWORD_GUIDE_1}
                size={12}
                color={sNewPasswordValidation.check1 ? R.colors.purpleishBlue : R.colors.blueGrey}
                isBold={sNewPasswordValidation.check1}
              />
              <HEIGHT size={2} />
              <TextCon
                text={hR.strings.MY_PWCHANGE_PASSWORD_GUIDE_2}
                size={12}
                color={sNewPasswordValidation.check2 ? R.colors.purpleishBlue : R.colors.blueGrey}
                isBold={sNewPasswordValidation.check2}
              />
              <HEIGHT size={2} />
              <TextCon
                text={hR.strings.MY_PWCHANGE_PASSWORD_GUIDE_3}
                size={12}
                color={sNewPasswordValidation.check3 ? R.colors.purpleishBlue : R.colors.blueGrey}
                isBold={sNewPasswordValidation.check3}
              />
              <HEIGHT size={2} />
              <TextCon
                text={hR.strings.MY_PWCHANGE_PASSWORD_GUIDE_4}
                size={12}
                color={sNewPasswordValidation.check4 ? R.colors.purpleishBlue : R.colors.blueGrey}
                isBold={sNewPasswordValidation.check4}
              />
            </FlexColumnDiv>
          </FlexColumnDiv>

          <HEIGHT size={24} />

          {/* PASSWORD 확인 */}
          <TextCon
            text={hR.strings.MY_PWCHANGE_NEW_PASSWORD_CONFIRM}
            size={CLAY.DEFAULT_FONT_SIZE}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={14} />
          <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'center' }}>
            <SRoundInputText
              width={BOX_BODY_WIDTH}
              height={INPUT_HEIGHT}
              fontSize={CLAY.DEFAULT_FONT_SIZE}
              getRef={(inputRef) => {
                newPasswordConfirmInputRef.current = inputRef;
              }}
              disabled={sNewPasswordValidation.isOk === false || sNewPasswordConfirmDisable}
              value={sNewPasswordConfirm}
              inputType={sNewPasswordConfirmVisible ? 'text' : 'password'}
              placeHolder={hR.strings.MY_PWCHANGE_NEW_PASSWORD_CONFIRM_PLACEHOLDER}
              onChange={(e) => {
                setNewPasswordConfirm(e.target.value);
                checkPasswordConfirm(sNewPassword, e.target.value);
              }}
              onFocus={() => {
                setNewPasswordConfirmFocus(true);
              }}
              onBlur={() => {
                setNewPasswordConfirmFocus(false);
              }}
            />
            {sNewPasswordConfirmValidation.isOk && (
              <Img src={[R.images.join_ic_confirm3x, CHECH_IC_SIZE, CHECH_IC_SIZE]} containerStyle={{ position: 'absolute', right: 43 }} />
            )}
            <ImgBtn
              src={[
                sNewPasswordConfirmVisible ? R.images.common_btn_visible3x : R.images.common_btn_not_visible3x,
                PASSWORD_VISIBLE_IC_SIZE,
                PASSWORD_VISIBLE_IC_SIZE,
              ]}
              containerStyle={{ position: 'absolute', right: 1, padding: 7 }}
              onClick={() => {
                setNewPasswordConfirmVisible(!sNewPasswordConfirmVisible);
                newPasswordConfirmInputRef.current?.focus();
              }}
            />
          </FlexRowDiv>
          {sNewPasswordConfirmValidation && !sNewPasswordConfirmValidation.isOk && sNewPasswordConfirmValidation.emsg && (
            <>
              <HEIGHT size={14} />
              <TextCon
                text={sNewPasswordConfirmValidation.emsg}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.pastelRed}
                containerStyle={{ alignSelf: 'flex-start' }}
              />
            </>
          )}
          {sComplete && (
            <>
              <HEIGHT size={12} />
              <FlexColumnDiv
                style={{
                  alignSelf: 'stretch',
                  borderRadius: 15,
                  backgroundColor: R.colors.lightGrey,
                  padding: 14,
                }}
              >
                <TextCon text={hR.strings.MY_PWCHANGE_OK_MSG_1} size={12} color={R.colors.purpleishBlue} isBold />
                <HEIGHT size={2} />
                <TextCon text={hR.strings.MY_PWCHANGE_OK_MSG_2} size={12} color={R.colors.purpleishBlue} isBold />
              </FlexColumnDiv>
            </>
          )}

          <HEIGHT size={40} />
          <RoundTxtBtn
            width={BTN_WIDTH}
            height={BTN_HEIGHT}
            fontSize={BTN_FONTSIZE}
            text={hR.strings.MY_PWCHANGE_OK_BTN}
            containerStyle={{ alignSelf: 'center' }}
            enable={sBtnEnable}
            onClick={async () => {
              if (sComplete) {
                if (timeoutRef.current !== null) {
                  // 타이머 해제
                  clearTimeout(timeoutRef.current);
                  timeoutRef.current = null;
                  hHisotry.replace(Nav.UserDetailMineScreen);
                }
              } else {
                const oldPW = await Utils.crypto.sha256(sCurPassword);
                const newPW = await Utils.crypto.sha256(sNewPassword);

                hRpc.cobaltRpc
                  .rpcUserAccountPasswordReset({ old_password: oldPW, new_password: newPW })
                  .then((r) => {
                    setComplete(true);
                    setCurPasswordDisable(true);
                    setNewPasswordDisable(true);
                    setNewPasswordConfirmDisable(true);
                    timeoutRef.current = setTimeout(() => {
                      hHisotry.replace(Nav.UserDetailMineScreen);
                    }, 10000);
                  })
                  .catch((e) => {
                    const errInfo = getRpcErrorInfo(e);
                    if (errInfo) {
                      switch (errInfo.ecode) {
                        case ServerECode_e.INVALID_USER_PASSWORD:
                          setCurPasswordValidation({ isOk: false, emsg: hR.strings.MY_PWCHANGE_CUR_PASSWORD_WRONG });
                          break;

                        default:
                          break;
                      }
                    }
                  });
              }
            }}
          />
        </CommonColumnBox>
        <HEIGHT size={BOTTOM_SPACE} />
      </FlexColumnDiv>
      {ScreenInfo.isMobile() && <CommonFooter />}
    </MobileScalableFlexColumn>
  );
}
