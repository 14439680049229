/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { CbtReportReason_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { RadioBtn } from 'src/ui/common_component/button/RadioBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

// Desktop
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;
const DT_DLG_WIDTH = 400;
const DT_PADDING_TOP = 30;
const DT_PADDING_BOTTOM = 40;
const DT_PADDING_H = 40;
const DT_DEFULAT_FONT = 16;
const DT_TITLE_FONT = 22;
const DT_TITLE_MARGIN_BOTTOM = 40;
const DT_BTN_WIDTH = 320;
const DT_BTN_HEIGHT = 48;
const DT_BTN_MARGIN_TOP = 22;
const DT_TOP_SPACE_FLEX = 1;
const DT_BOTTOM_SPACE_FLEX = 3;
const DT_BETWEEN_RADIOBTN = 18;

// Mobile
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;
const MB_DLG_WIDTH = 280;
const MB_PADDING_TOP = 16;
const MB_PADDING_BOTTOM = 20;
const MB_PADDING_H = 12;
const MB_DEFULAT_FONT = 12;
const MB_TITLE_FONT = 16;
const MB_TITLE_MARGIN_BOTTOM = 30;
const MB_BTN_WIDTH = 256;
const MB_BTN_HEIGHT = 40;
const MB_BTN_MARGIN_TOP = 18;
const MB_TOP_SPACE_FLEX = 1;
const MB_BOTTOM_SPACE_FLEX = 1;
const MB_BETWEEN_RADIOBTN = 12;

const CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
const CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;
const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const PADDING_TOP = ScreenInfo.isMobile() ? MB_PADDING_TOP : DT_PADDING_TOP;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const PADDING_H = ScreenInfo.isMobile() ? MB_PADDING_H : DT_PADDING_H;
const DEFULAT_FONT = ScreenInfo.isMobile() ? MB_DEFULAT_FONT : DT_DEFULAT_FONT;
const TITLE_FONT = ScreenInfo.isMobile() ? MB_TITLE_FONT : DT_TITLE_FONT;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BTN_MARGIN_TOP : DT_BTN_MARGIN_TOP;
const TOP_SPACE_FLEX = ScreenInfo.isMobile() ? MB_TOP_SPACE_FLEX : DT_TOP_SPACE_FLEX;
const BOTTOM_SPACE_FLEX = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE_FLEX : DT_BOTTOM_SPACE_FLEX;
const BETWEEN_RADIOBTN = ScreenInfo.isMobile() ? MB_BETWEEN_RADIOBTN : DT_BETWEEN_RADIOBTN;

export type ReportJsxProps = {
  saleId: number;
  onReport?: (selected: CbtReportReason_e) => void;
  onBackdropClick?: () => void;
  onHide?: () => void;
};

export function ReportJsx(props: ReportJsxProps) {
  /**************************************
   * !! state
   **************************************/
  const [sSelectedReportReason, setSelectedReportReason] = useState<number>();

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();
  const hRpc = useRpc();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    init();
    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const init = () => {
    setSelectedReportReason(undefined);
  };

  /**************************************
   * !! render function
   **************************************/
  const renderRadioMenu = ({ reportReason, text }: { reportReason: CbtReportReason_e; text: string }) => {
    return (
      <>
        <FlexRowDiv
          style={{
            //
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            ...devOutline,
          }}
          onClick={() => {
            setSelectedReportReason(reportReason);
          }}
        >
          <RadioBtn checked={reportReason === sSelectedReportReason} />
          <WIDTH size={4} />
          <TextCon text={text} size={DEFULAT_FONT} color={R.colors.blueGrey} />
        </FlexRowDiv>
        <HEIGHT size={BETWEEN_RADIOBTN} />
      </>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        paddingBottom: PADDING_BOTTOM,
        paddingTop: PADDING_TOP,
        paddingLeft: PADDING_H,
        paddingRight: PADDING_H,
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      {/* close 버튼 */}
      <ImgBtn
        src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
        containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
        onClick={() => {
          props.onHide && props.onHide();
        }}
      />
      <TextCon text={hR.strings.MARKET_DLG_REPORT_TITLE} size={TITLE_FONT} color={R.colors.black} isBold />
      <HEIGHT size={TITLE_MARGIN_BOTTOM} />

      <FlexColumnDiv style={{ alignItems: 'flex-start', alignSelf: 'flex-start' }}>
        {renderRadioMenu({ reportReason: CbtReportReason_e.SEXUAL, text: hR.strings.MARKET_DLG_REPORT_CASE_1 })}
        {renderRadioMenu({ reportReason: CbtReportReason_e.VIOLENT_OR_DISGUSTING, text: hR.strings.MARKET_DLG_REPORT_CASE_2 })}
        {renderRadioMenu({ reportReason: CbtReportReason_e.HATE_OR_ABUSE, text: hR.strings.MARKET_DLG_REPORT_CASE_3 })}
        {renderRadioMenu({ reportReason: CbtReportReason_e.INFRINGEMENT_OF_RIGHT, text: hR.strings.MARKET_DLG_REPORT_CASE_4 })}
        {renderRadioMenu({ reportReason: CbtReportReason_e.ETC, text: hR.strings.MARKET_DLG_REPORT_CASE_5 })}

        <HEIGHT size={BTN_MARGIN_TOP} />
        <RoundTxtBtn
          text={hR.strings.MARKET_DLG_REPORT_BTN}
          width={BTN_WIDTH}
          height={BTN_HEIGHT}
          fontSize={DEFULAT_FONT}
          enable={sSelectedReportReason !== undefined}
          onClick={() => {
            if (!sSelectedReportReason) {
              return;
            }

            hRpc.cobaltRpc.rpcMpSaleReport({ sale_id: props.saleId, reason: sSelectedReportReason }).then((res) => {
              props.onReport && props.onReport(sSelectedReportReason);
              hDlgFactory.showSimpleOk({ text: hR.strings.MARKET_DLG_REPORT_SUCCESS });
              props.onHide && props.onHide();
            });
          }}
        />
      </FlexColumnDiv>
    </FlexColumnDiv>
  );
}
