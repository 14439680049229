/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import BigNumber from 'bignumber.js';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { CbtSmartContractStatus } from 'src/hooks/useKaikas';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMarketPlaceState } from 'src/hooks/useMarketPlaceState';
import { useMouseEnterEffect } from 'src/hooks/useMouseEnterEffect';
import { useRpc } from 'src/hooks/useRpc';
import { CbtCurrency_e, CbtResPriceInfo_i, ServerECode_e } from 'src/model/rpcModel';
import { RPC_MP_SALE_PURCHASE_VIEW, RPC_USER_ACCOUNT_INFO_MUTABLE } from 'src/model/rpcType';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { LabelValue } from 'src/ui/common_component/LabelValue';
import { CLAY, CommonColumnBox, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MarketPaymentScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sQS, setQS] = useState<{ sale_id: number }>();
  const [sFetchedData, setFetchedData] = useState<RPC_MP_SALE_PURCHASE_VIEW.Rx>();
  const [sMyBalance, setMyBalance] = useState<RPC_USER_ACCOUNT_INFO_MUTABLE.Rx>();

  const [sShowTooltip, setShowTooltip] = useState(false);
  const [sIsChargeBtnMouseEnter, setIsChargeBtnMouseEnter] = useState(false);

  const [sAgreeAllIc, setAllAgreeIc] = useState(false);
  const [sAgree1Ic, setAgree1Ic] = useState(false);
  const [sAgree2Ic, setAgree2Ic] = useState(false);
  const [sAgree3Ic, setAgree3Ic] = useState(false);

  const [sPayBtnEnable, setPayBtnEnable] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`MarketPlace Payment - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hMarketPlaceState = useMarketPlaceState();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlg = useDlg();
  const hDlgFactory = useDlgFactory();

  const {
    hAnimProps: hAnimPropsForPointChargeBtn,
    mouseEnter,
    mouseLeave,
  } = useMouseEnterEffect({
    defProps: {
      borderColor: R.colors.veryLightGrey2,
    },
    enterProps: {
      borderColor: R.colors.purpleishBlue,
    },
  });

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sale_id?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sale_id) {
      DBGMSG(`$$$$QS id: ${query.sale_id}`);
      const sale_id = parseInt(query.sale_id);
      setQS({ sale_id });
      fetchData(sale_id);
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    if (sAgree1Ic && sAgree2Ic && sAgree3Ic) setAllAgreeIc(true);
    else setAllAgreeIc(false);
  }, [sAgree1Ic, sAgree2Ic, sAgree3Ic]);

  useEffect(() => {
    if (!sAgreeAllIc || isLackPoint()) setPayBtnEnable(false);
    else setPayBtnEnable(true);
  }, [sAgreeAllIc, sMyBalance?.balance_info.cpoint]);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async (sale_id: number) => {
    try {
      const res = await hRpc.cobaltRpc.rpcMpSalePurchaseView({ sale_id });
      setFetchedData(res);

      const res2 = await hRpc.cobaltRpc.rpcUserAccountInfoMutable({});
      setMyBalance(res2);
    } catch (err) {}
  };

  const getPriceTxt = () => {
    if (!sFetchedData) return '';

    const price = Utils.currency.getPriceForDP({
      prcInfo: sFetchedData.prc_info,
      decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
    });

    let aboutPrice: string = '';
    if (sFetchedData.prc_info.sub_price_list && sFetchedData.prc_info.sub_price_list.length !== 0) {
      aboutPrice = Utils.currency.getPriceForDP({ prcInfo: sFetchedData.prc_info.sub_price_list[0], decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT });
    }

    switch (sFetchedData.prc_info.curr_info.currency) {
      case CbtCurrency_e.KLAY:
        return `${price} ${R.strings.CURRENCY_KLAY} (≈ ${aboutPrice} ${R.strings.CURRENCY_CUSD})`;
      case CbtCurrency_e.cPOINT:
        return `${price} ${R.strings.CURRENCY_CUSD} (${aboutPrice} ${R.strings.CURRENCY_CPOINT})`;
      default:
        return '';
    }
  };

  const btnHandler = async () => {
    if (!sFetchedData) return false;

    hDlgFactory.showRunningBuyProcessing();

    DBGMSG(`$$$$PURCHASE`);
    const asset_key = sFetchedData.ast_info.asset_key;
    const sale_id = sFetchedData.sale_id;
    const prc_info = sFetchedData.prc_info;

    let tx_id: string | undefined = undefined;
    let blTxHash: string | undefined = undefined;
    try {
      // 1. 트랜잭션 시작 uuid 취득
      const purchaseBeginRes = await hRpc.cobaltRpc.rpcMpSalePurchaseBegin({ sale_id, prc_info });
      DBGMSG(purchaseBeginRes.tx_id);
      tx_id = purchaseBeginRes.tx_id;

      if (!tx_id) {
        DBGMSGW(`tx_id is undefined`);
        return false;
      }

      // 3. 성공여부 알림
      await hRpc.cobaltRpc.rpcMpSalePurchaseEnd({
        sale_id,
        tx_id,
        status: CbtSmartContractStatus.SUCCESS,
        // bl_tx_hash: '',
      });

      hDlgFactory.hideRunningBuyProcessing();
      hDlgFactory.showBuyResult({
        isSucc: true,
        onOk: () => {
          hMarketPlaceState.act.clearMarketPlaceState();
          hHistory.push(Nav.getMarketPlaceInit());
        },
      });
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        switch (errinfo.ecode) {
          case ServerECode_e.INVALID_KLAYTN_ADDRESS:
            hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
            break;
          default:
            break;
        }
      }

      hDlgFactory.hideRunningBuyProcessing();
      hDlgFactory.showBuyResult({
        isSucc: false,
        onOk: () => {},
      });
    } finally {
    }
    return true;
  };

  const calcPriceDiff = (priceA: string, priceB: string) => {
    const bnPriceA = new BigNumber(priceA);
    const bnPriceB = new BigNumber(priceB);
    const diff = bnPriceA.minus(bnPriceB);

    return diff;
  };

  const calcPointPrcInfoDiff = (prcInfoA: CbtResPriceInfo_i, prcInfoB: CbtResPriceInfo_i) => {
    if (prcInfoA.curr_info.currency !== CbtCurrency_e.cPOINT) return;
    if (prcInfoB.curr_info.currency !== CbtCurrency_e.cPOINT) return;

    return calcPriceDiff(prcInfoA.price, prcInfoB.price);
  };

  const getLackPointTxt = () => {
    if (!sFetchedData) return;
    if (!sMyBalance) return;
    const MyPointPrcInfo = sMyBalance.balance_info.cpoint;
    const ProdPrcInfo = sFetchedData.prc_info;

    // must be point
    const diff = calcPointPrcInfoDiff(ProdPrcInfo, MyPointPrcInfo);

    if (diff) {
      if (diff.isNegative()) return;
      return Utils.currency.getPriceForDP({
        prcInfo: { price: diff.toString(), curr_info: ProdPrcInfo.curr_info },
        decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
      });
    } else return;
  };

  const isLackPoint = () => {
    return getLackPointTxt() !== undefined;
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  if (!sFetchedData) return <DataFetching />;

  if (!sMyBalance) return null;

  DBGMSG('render');
  if (ScreenInfo.isMobile()) {
    return (
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'center',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,

            ...devOutline,
          }}
        >
          <FlexRowDiv style={{ alignSelf: 'flex-start' }}>
            <BackBtn />
          </FlexRowDiv>

          <HEIGHT size={20} />

          <CommonColumnBox
            style={{ width: CLAY.BODY_WIDTH, alignItems: 'center', paddingLeft: 12, paddingRight: 12, paddingTop: 20, paddingBottom: 20 }}
          >
            <TextCon text={hR.strings.MARKET_PAYMENT_TITLE} size={16} isBold color={R.colors.black} />
            <HEIGHT size={20} />
            <TextCon
              text={`${hR.strings.MARKET_PAYMENT_ASSET} (${sFetchedData.sale_id})`}
              containerStyle={{ alignSelf: 'flex-start' }}
              size={12}
              isBold
              color={R.colors.black}
            />
            <HEIGHT size={20} />
            <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
              {/* 에셋 이미지 */}
              <CommonColumnBox
                style={{
                  width: 80,
                  height: 80,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5,
                  // margin: ASSET_DISPLAY_BORDER_BOX_MARGIN,
                  // marginTop: ScreenInfo.isMobile() ? undefined : 0,
                  // ...devOutlineFG,
                }}
              >
                <AssetDisplayThumbReqattr
                  playerSrc={Utils.svc.brewAssetDisplayThumbReqattr({
                    assetType: sFetchedData.ast_info.schm_info.asset_type,
                    img: sFetchedData.ast_info.nft_img,
                  })}
                  width={75}
                  height={75}
                  whereToUse={WhereToUse_e.ForNormalCard}
                />
              </CommonColumnBox>
              <WIDTH size={20} />
              <FlexColumnDiv style={{ justifyContent: 'center' }}>
                {/* 에셋 이름 */}
                <TextCon text={sFetchedData.ast_info.nft_name} size={12} color={R.colors.black} isBold />

                <HEIGHT size={8} />
                {/* 가격 */}
                <TextCon text={getPriceTxt()} size={12} color={R.colors.purpleishBlue} isBold />
              </FlexColumnDiv>
            </FlexRowDiv>

            <HEIGHT size={20} />
            <HLINE size={CLAY.BODY_WIDTH} />
            <HEIGHT size={20} />

            {/* 포인트 결제 */}
            <FlexRowDiv
              style={{
                alignSelf: 'stretch',
                alignItems: 'center',

                // ...devOutlineF
              }}
            >
              <Img src={[R.images.common_ic_point3x, 16, 18]} />
              <WIDTH size={2} />
              <TextCon size={12} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_POINT} />
              <TextCon
                size={10}
                color={sShowTooltip ? R.colors.purpleishBlue : R.colors.blueGrey}
                text={hR.strings.MARKET_PAYMENT_POINT_TOOLTIP}
                containerStyle={{ marginLeft: 'auto' }}
                onConMouseEnter={() => {
                  setShowTooltip(true);
                }}
                onConMouseLeave={() => {
                  setShowTooltip(false);
                }}
              />
              <WIDTH size={4} />
              <TooltipImg
                placement={'top-end'}
                tooltipJsx={
                  <FlexColumnDiv style={{ alignItems: 'flex-start' }}>
                    <TextCon size={12} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_POINT_TOOLTIP_TXT1} />
                    <TextCon size={12} color={R.colors.purpleishBlue} isBold text={hR.strings.MARKET_PAYMENT_POINT_TOOLTIP_TXT2} />
                  </FlexColumnDiv>
                }
                onConMouseEnter={() => {
                  setShowTooltip(true);
                }}
                onConMouseLeave={() => {
                  setShowTooltip(false);
                }}
                forceShow={sShowTooltip}
              />
            </FlexRowDiv>

            <HEIGHT size={16} />

            {/* 보유 포인트 */}
            <FlexRowDiv style={{ alignSelf: 'stretch' }}>
              <TextCon size={12} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_MY_POINT_BALANCE} />
              <TextCon
                size={12}
                color={R.colors.purpleishBlue}
                text={`${Utils.currency.getPriceForDP({
                  prcInfo: sMyBalance.balance_info.cpoint,
                  decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
                })} POINT`}
                isBold
                containerStyle={{ marginLeft: 'auto' }}
              />
            </FlexRowDiv>

            {/* 부족 포인트 */}
            {isLackPoint() && (
              <>
                <HEIGHT size={12} />
                <FlexRowDiv style={{ alignSelf: 'stretch' }}>
                  <TextCon size={12} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_LACK_POINT_AMOUNT} />
                  <TextCon
                    size={12}
                    color={R.colors.purpleishBlue}
                    text={`${getLackPointTxt()} POINT`}
                    isBold
                    containerStyle={{ marginLeft: 'auto' }}
                  />
                </FlexRowDiv>
                <HEIGHT size={24} />

                {/* 포인트 충전 */}
                <AnimatedFlexColumnDiv
                  style={{
                    alignSelf: 'stretch',
                    borderWidth: 1,
                    borderColor: R.colors.veryLightGrey2,
                    borderRadius: 5,
                    borderStyle: 'solid',
                    padding: 14,
                    // cursor: 'pointer',
                    ...hAnimPropsForPointChargeBtn,
                  }}
                  onMouseEnter={(e: any) => {
                    // mouseEnter(e);
                    // setIsChargeBtnMouseEnter(true);
                  }}
                  onMouseLeave={(e: any) => {
                    // mouseLeave(e);
                    // setIsChargeBtnMouseEnter(false);
                  }}
                  onClick={() => {
                    // hDlgFactory.showPointChargeListDlg({
                    //   myPointBalanceInfo: sMyBalance.balance_info.cpoint,
                    //   onChargeBtn: ({ widgetUrl, prd_id }) => {
                    //     hDlgFactory.closeOkDlgJsx();
                    //     hDlgFactory.showPaymentwallWidetDlg({
                    //       widgetUrl,
                    //       onCloseBtn: () => {
                    //         DBGMSG(`onCloseBtn`);
                    //         hDlgFactory.hidePointChargeListDlg();
                    //       },
                    //       onSuccPayment: () => {
                    //         if (!sQS) return;
                    //         fetchData(sQS.sale_id);
                    //         hDlgFactory.showPgPointChargeSucc({
                    //           transaction_id: '',
                    //         });
                    //       },
                    //       onFailPayment: () => {
                    //         hDlgFactory.showPgPointChargeFail();
                    //       },
                    //     });
                    //   },
                    //   onCloseBtn: () => {
                    //     DBGMSG('onCloseBtn');
                    //     hDlgFactory.hidePointChargeListDlg();
                    //   },
                    // });
                  }}
                >
                  <FlexRowDiv>
                    <Img src={[sIsChargeBtnMouseEnter ? R.images.pay_btn_point_popup_hover3x : R.images.pay_btn_point_popup3x, 16, 16]} />
                    <WIDTH size={4} />
                    <TextCon size={12} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_CHARGE_POINT} />
                  </FlexRowDiv>
                  <HEIGHT size={6} />
                  <FlexRowDiv>
                    <WIDTH size={26} />
                    <TextCon size={10} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_CHARGE_POINT_GUIDE} />
                  </FlexRowDiv>
                </AnimatedFlexColumnDiv>
              </>
            )}

            <HEIGHT size={20} />
            <HLINE size={CLAY.BODY_WIDTH} />
            <HEIGHT size={20} />

            {/* 결제 금액 */}
            {!isLackPoint() && (
              <>
                <FlexRowDiv style={{ alignSelf: 'stretch' }}>
                  <TextCon size={12} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_PRICE} />
                  <TextCon
                    size={12}
                    color={R.colors.purpleishBlue}
                    text={`${Utils.currency.getPriceForDP({
                      prcInfo: sFetchedData.prc_info,
                      decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
                    })} POINT`}
                    isBold
                    containerStyle={{ marginLeft: 'auto' }}
                  />
                </FlexRowDiv>

                <HEIGHT size={20} />
                <HLINE size={CLAY.BODY_WIDTH} />
                <HEIGHT size={20} />
              </>
            )}

            {/* 동의 */}
            <FlexRowDiv style={{ alignSelf: 'stretch' }}>
              <ImgBtn
                src={[sAgreeAllIc ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 16, 16]}
                onClick={() => {
                  if (sAgreeAllIc) {
                    setAgree1Ic(false);
                    setAgree2Ic(false);
                    setAgree3Ic(false);
                  } else {
                    setAgree1Ic(true);
                    setAgree2Ic(true);
                    setAgree3Ic(true);
                  }
                }}
              />
              <WIDTH size={6} />
              <TextCon size={12} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_AGREE_ALL} />
            </FlexRowDiv>
            <HEIGHT size={24} />
            <HLINE size={'100%'} />
            <HEIGHT size={24} />
            <FlexRowDiv style={{ alignSelf: 'stretch' }}>
              <ImgBtn
                src={[sAgree1Ic ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 16, 16]}
                onClick={() => {
                  setAgree1Ic((prev) => !prev);
                }}
              />
              <WIDTH size={6} />
              <TextCon size={10} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_AGREE_TERMS_OF_USE} />
              <WIDTH size={6} />
              <TextCon
                text={hR.strings.MARKET_PAYMENT_AGREE_TERM_OF_USE_LINK}
                size={10}
                color={R.colors.purpleishBlue}
                isBold
                onConClick={() => {
                  window.open(Nav.brewUserTermsOfUseScreenPath({ lang: hR.lang }));
                }}
              />
            </FlexRowDiv>
            <HEIGHT size={10} />
            <FlexRowDiv style={{ alignSelf: 'stretch' }}>
              <ImgBtn
                src={[sAgree2Ic ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 16, 16]}
                onClick={() => {
                  setAgree2Ic((prev) => !prev);
                }}
              />
              <WIDTH size={6} />
              <TextCon size={10} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_AGREE_REFUND_POLICY} />
              <WIDTH size={6} />
              <TextCon
                text={hR.strings.MARKET_PAYMENT_AGREE_REFUND_POLICY_LINK}
                size={10}
                color={R.colors.purpleishBlue}
                isBold
                onConClick={() => {
                  window.open(Nav.brewUserRefundPolicyScreenPath({ lang: hR.lang }));
                }}
              />
            </FlexRowDiv>
            <HEIGHT size={10} />
            <FlexRowDiv
              style={{
                alignSelf: 'stretch',
                // ...devOutlineF
              }}
            >
              <FlexColumnDiv>
                <FlexRowDiv>
                  <ImgBtn
                    src={[sAgree3Ic ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 16, 16]}
                    onClick={() => {
                      setAgree3Ic((prev) => !prev);
                    }}
                  />
                  <WIDTH size={6} />
                  <TextCon size={10} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_AGREE_PRIVACY_POLICY} />
                </FlexRowDiv>
                <TextCon
                  text={hR.strings.MARKET_PAYMENT_AGREE_PRIVACY_POLICY_LINK}
                  size={10}
                  color={R.colors.purpleishBlue}
                  isBold
                  onConClick={() => {
                    window.open(Nav.brewUserPrivacyPolicyScreenPath({ lang: hR.lang }));
                  }}
                  containerStyle={{ marginLeft: 16 + 6 }}
                />
              </FlexColumnDiv>
            </FlexRowDiv>

            <HEIGHT size={20} />
            <HLINE size={CLAY.BODY_WIDTH} />
            <HEIGHT size={20} />

            <RoundTxtBtn
              text={hR.strings.MARKET_PAYMENT_BTN}
              width={256}
              height={44}
              fontSize={12}
              onClick={() => {
                btnHandler();
              }}
              enable={sPayBtnEnable}
            />
          </CommonColumnBox>
          <HEIGHT size={60} />
          {ScreenInfo.isMobile() && <CommonFooter />}
        </FlexColumnDiv>
      </MobileScalableFlexColumn>
    );
  } else {
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: CLAY.PAGE_WIDTH,
          alignItems: 'center',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          paddingLeft: CLAY.HPADDING,
          paddingRight: CLAY.HPADDING,

          ...devOutline,
        }}
      >
        <FlexDiv style={{ position: 'absolute', left: 0, top: CLAY.TITLE_PADDING_TOP }}>
          <BackBtn />
        </FlexDiv>

        <CommonColumnBox style={{ width: 582, alignItems: 'center', paddingLeft: 30, paddingRight: 30, paddingTop: 40, paddingBottom: 40 }}>
          <TextCon text={hR.strings.MARKET_PAYMENT_TITLE} size={24} isBold color={R.colors.black} />
          <HEIGHT size={40} />
          <TextCon
            text={`${hR.strings.MARKET_PAYMENT_ASSET} (${sFetchedData.sale_id})`}
            containerStyle={{ alignSelf: 'flex-start' }}
            size={16}
            isBold
            color={R.colors.black}
          />
          <HEIGHT size={20} />
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            {/* 에셋 이미지 */}
            <CommonColumnBox
              style={{
                width: 120,
                height: 120,
                justifyContent: 'center',
                alignItems: 'center',
                // margin: ASSET_DISPLAY_BORDER_BOX_MARGIN,
                // marginTop: ScreenInfo.isMobile() ? undefined : 0,
                // ...devOutlineFG,
              }}
            >
              <AssetDisplayThumbReqattr
                playerSrc={Utils.svc.brewAssetDisplayThumbReqattr({
                  assetType: sFetchedData.ast_info.schm_info.asset_type,
                  img: sFetchedData.ast_info.nft_img,
                })}
                width={100}
                height={100}
                whereToUse={WhereToUse_e.ForNormalCard}
              />
            </CommonColumnBox>
            <WIDTH size={20} />
            <FlexColumnDiv style={{ justifyContent: 'center' }}>
              {/* 에셋 이름 */}
              <LabelValue
                title={hR.strings.DLG_BUY_ASSET_NAME}
                value={sFetchedData.ast_info.nft_name}
                titleSize={14}
                titleWidth={135}
                valueSize={14}
                valueWidth={undefined}
              />
              <HEIGHT size={24} />
              {/* 가격 */}
              <LabelValue
                title={hR.strings.DLG_BUY_PRICE}
                value={getPriceTxt()}
                titleSize={14}
                titleWidth={135}
                valueSize={14}
                valueWidth={undefined}
                valueColor={R.colors.purpleishBlue}
              />
            </FlexColumnDiv>
          </FlexRowDiv>

          <HEIGHT size={30} />
          <HLINE size={582} />
          <HEIGHT size={30} />

          {/* 포인트 결제 */}
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            <Img src={[R.images.common_ic_point3x, 18, 20]} />
            <WIDTH size={2} />
            <TextCon size={16} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_POINT} />
            <TextCon
              size={14}
              color={sShowTooltip ? R.colors.purpleishBlue : R.colors.blueGrey}
              text={hR.strings.MARKET_PAYMENT_POINT_TOOLTIP}
              containerStyle={{ marginLeft: 'auto' }}
              onConMouseEnter={() => {
                setShowTooltip(true);
              }}
              onConMouseLeave={() => {
                setShowTooltip(false);
              }}
            />
            <WIDTH size={2} />
            <TooltipImg
              placement={'bottom-end'}
              tooltipJsx={
                <FlexColumnDiv style={{ alignItems: 'flex-start' }}>
                  <TextCon size={12} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_POINT_TOOLTIP_TXT1} />
                  <TextCon size={12} color={R.colors.purpleishBlue} isBold text={hR.strings.MARKET_PAYMENT_POINT_TOOLTIP_TXT2} />
                </FlexColumnDiv>
              }
              onConMouseEnter={() => {
                setShowTooltip(true);
              }}
              onConMouseLeave={() => {
                setShowTooltip(false);
              }}
              forceShow={sShowTooltip}
            />
          </FlexRowDiv>

          <HEIGHT size={24} />

          {/* 보유 포인트 */}
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            <TextCon size={14} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_MY_POINT_BALANCE} />
            <TextCon
              size={14}
              color={R.colors.purpleishBlue}
              text={`${Utils.currency.getPriceForDP({
                prcInfo: sMyBalance.balance_info.cpoint,
                decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
              })} POINT`}
              isBold
              containerStyle={{ marginLeft: 'auto' }}
            />
          </FlexRowDiv>

          {/* 부족 포인트 */}
          {isLackPoint() && (
            <>
              <HEIGHT size={20} />
              <FlexRowDiv style={{ alignSelf: 'stretch' }}>
                <TextCon size={14} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_LACK_POINT_AMOUNT} />
                <TextCon
                  size={14}
                  color={R.colors.purpleishBlue}
                  text={`${getLackPointTxt()} POINT`}
                  isBold
                  containerStyle={{ marginLeft: 'auto' }}
                />
              </FlexRowDiv>
              <HEIGHT size={24} />

              {/* 포인트 충전 */}
              <AnimatedFlexColumnDiv
                style={{
                  alignSelf: 'stretch',
                  borderWidth: 1,
                  borderColor: R.colors.veryLightGrey2,
                  borderRadius: 5,
                  borderStyle: 'solid',
                  padding: 14,
                  // cursor: 'pointer',
                  ...hAnimPropsForPointChargeBtn,
                }}
                onMouseEnter={(e: any) => {
                  // mouseEnter(e);
                  // setIsChargeBtnMouseEnter(true);
                }}
                onMouseLeave={(e: any) => {
                  // mouseLeave(e);
                  // setIsChargeBtnMouseEnter(false);
                }}
                onClick={() => {
                  // hDlgFactory.showPointChargeListDlg({
                  //   myPointBalanceInfo: sMyBalance.balance_info.cpoint,
                  //   onChargeBtn: ({ widgetUrl, prd_id }) => {
                  //     hDlgFactory.closeOkDlgJsx();
                  //     hDlgFactory.showPaymentwallWidetDlg({
                  //       widgetUrl,
                  //       onCloseBtn: () => {
                  //         DBGMSG(`onCloseBtn`);
                  //         hDlgFactory.hidePointChargeListDlg();
                  //       },
                  //       onSuccPayment: () => {
                  //         if (!sQS) return;
                  //         fetchData(sQS.sale_id);
                  //         hDlgFactory.showPgPointChargeSucc({
                  //           transaction_id: '',
                  //         });
                  //       },
                  //       onFailPayment: () => {
                  //         hDlgFactory.showPgPointChargeFail();
                  //       },
                  //     });
                  //   },
                  //   onCloseBtn: () => {
                  //     DBGMSG('onCloseBtn');
                  //     hDlgFactory.hidePointChargeListDlg();
                  //   },
                  // });
                }}
              >
                <FlexRowDiv>
                  <Img src={[sIsChargeBtnMouseEnter ? R.images.pay_btn_point_popup_hover3x : R.images.pay_btn_point_popup3x, 20, 20]} />
                  <WIDTH size={6} />
                  <TextCon size={14} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_CHARGE_POINT} />
                </FlexRowDiv>
                <HEIGHT size={6} />
                <FlexRowDiv>
                  <WIDTH size={26} />
                  <TextCon size={12} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_CHARGE_POINT_GUIDE} />
                </FlexRowDiv>
              </AnimatedFlexColumnDiv>
            </>
          )}

          <HEIGHT size={30} />
          <HLINE size={582} />
          <HEIGHT size={30} />

          {/* 결제 금액 */}
          {!isLackPoint() && (
            <>
              <FlexRowDiv style={{ alignSelf: 'stretch' }}>
                <TextCon size={16} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_PRICE} />
                <TextCon
                  size={16}
                  color={R.colors.purpleishBlue}
                  text={`${Utils.currency.getPriceForDP({
                    prcInfo: sFetchedData.prc_info,
                    decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
                  })} POINT`}
                  isBold
                  containerStyle={{ marginLeft: 'auto' }}
                />
              </FlexRowDiv>

              <HEIGHT size={30} />
              <HLINE size={582} />
              <HEIGHT size={30} />
            </>
          )}

          {/* 동의 */}
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            <ImgBtn
              src={[sAgreeAllIc ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 20, 20]}
              onClick={() => {
                if (sAgreeAllIc) {
                  setAgree1Ic(false);
                  setAgree2Ic(false);
                  setAgree3Ic(false);
                } else {
                  setAgree1Ic(true);
                  setAgree2Ic(true);
                  setAgree3Ic(true);
                }
              }}
            />
            <WIDTH size={6} />
            <TextCon size={14} color={R.colors.black} isBold text={hR.strings.MARKET_PAYMENT_AGREE_ALL} />
          </FlexRowDiv>
          <HEIGHT size={24} />
          <HLINE size={'100%'} />
          <HEIGHT size={24} />
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            <ImgBtn
              src={[sAgree1Ic ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 20, 20]}
              onClick={() => {
                setAgree1Ic((prev) => !prev);
              }}
            />
            <WIDTH size={6} />
            <TextCon size={12} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_AGREE_TERMS_OF_USE} />
            <WIDTH size={6} />
            <TextCon
              text={hR.strings.MARKET_PAYMENT_AGREE_TERM_OF_USE_LINK}
              size={12}
              color={R.colors.purpleishBlue}
              isBold
              onConClick={() => {
                window.open(Nav.brewUserTermsOfUseScreenPath({ lang: hR.lang }));
              }}
            />
          </FlexRowDiv>
          <HEIGHT size={12} />
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            <ImgBtn
              src={[sAgree2Ic ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 20, 20]}
              onClick={() => {
                setAgree2Ic((prev) => !prev);
              }}
            />
            <WIDTH size={6} />
            <TextCon size={12} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_AGREE_REFUND_POLICY} />
            <WIDTH size={6} />
            <TextCon
              text={hR.strings.MARKET_PAYMENT_AGREE_REFUND_POLICY_LINK}
              size={12}
              color={R.colors.purpleishBlue}
              isBold
              onConClick={() => {
                window.open(Nav.brewUserRefundPolicyScreenPath({ lang: hR.lang }));
              }}
            />
          </FlexRowDiv>
          <HEIGHT size={12} />
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            <ImgBtn
              src={[sAgree3Ic ? R.images.pay_btn_check_abled3x : R.images.pay_btn_check_disabled3x, 20, 20]}
              onClick={() => {
                setAgree3Ic((prev) => !prev);
              }}
            />
            <WIDTH size={6} />
            <TextCon size={12} color={R.colors.black} text={hR.strings.MARKET_PAYMENT_AGREE_PRIVACY_POLICY} />
            <WIDTH size={6} />
            <TextCon
              text={hR.strings.MARKET_PAYMENT_AGREE_PRIVACY_POLICY_LINK}
              size={12}
              color={R.colors.purpleishBlue}
              isBold
              onConClick={() => {
                window.open(Nav.brewUserPrivacyPolicyScreenPath({ lang: hR.lang }));
              }}
            />
          </FlexRowDiv>
          <HEIGHT size={30} />
          <HLINE size={582} />
          <HEIGHT size={30} />

          <RoundTxtBtn
            text={hR.strings.MARKET_PAYMENT_BTN}
            width={344}
            height={48}
            onClick={() => {
              btnHandler();
            }}
            enable={sPayBtnEnable}
          />
        </CommonColumnBox>
        <HEIGHT size={120} />
      </FlexColumnDiv>
    );
  }
}
