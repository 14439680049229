/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { DBGMSG, Utils } from 'src/util/utils';
import { CLAY } from 'src/ui/layout_constant';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import ScreenInfo from 'src/context/screen_constants';
import { AppConf, isProd } from 'src/conf/AppConf';
import { CbtInqueryType_e, CbtUserLanguage_e } from 'src/model/rpcModel';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { Text } from 'src/ui/base_component/Text';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DT_TITLE_FONTSIZE = 30;
const DT_DESC_FONTSIZE = 14;
const DT_REASON_TITLE_FONTSIZE = 22;
const DT_REASON_DESC_FONTSIZE = 14;
const DT_REASON_X_FONTSIZE = 16;
const DT_REASON_X_DESC_FONTSIZE = 16;
const DT_REASON_GUIDE_FONTSIZE = 14;
const DT_TITLE_MARGIN_BOTTOM = 30;
const DT_DESC_MARGIN_BOTTOM = 49;
const DT_REASON_TITLE_MARGIN_TOP = 29;
const DT_REASON_TITLE_MARGIN_BOTTOM = 10;
const DT_REASON_DESC_MARGIN_BOTTOM = 40;
const DT_REASON_X_MARGIN_BOTTOM = 3;
const DT_REASON_X_DESC_MARGIN_BOTTOM = 12;
const DT_REASON_GUIDE_MARGIN_V = 40;
const DT_PADDING_BOTTOM = 128;
const DT_REASON_X_DESC_LEFT_PADDING = 13;

const MB_TITLE_FONTSIZE = 16;
const MB_DESC_FONTSIZE = 10;
const MB_REASON_TITLE_FONTSIZE = 12;
const MB_REASON_DESC_FONTSIZE = 10;
const MB_REASON_X_FONTSIZE = 10;
const MB_REASON_X_DESC_FONTSIZE = 10;
const MB_REASON_GUIDE_FONTSIZE = 10;
const MB_TITLE_MARGIN_BOTTOM = 20;
const MB_DESC_MARGIN_BOTTOM = 20;
const MB_REASON_TITLE_MARGIN_TOP = 20;
const MB_REASON_TITLE_MARGIN_BOTTOM = 5;
const MB_REASON_DESC_MARGIN_BOTTOM = 20;
const MB_REASON_X_MARGIN_BOTTOM = 3;
const MB_REASON_X_DESC_MARGIN_BOTTOM = 10;
const MB_REASON_GUIDE_MARGIN_V = 20;
const MB_PADDING_BOTTOM = 60;
const MB_REASON_X_DESC_LEFT_PADDING = 8;

const TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_TITLE_FONTSIZE : DT_TITLE_FONTSIZE;
const DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_DESC_FONTSIZE : DT_DESC_FONTSIZE;
const REASON_TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_TITLE_FONTSIZE : DT_REASON_TITLE_FONTSIZE;
const REASON_DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_DESC_FONTSIZE : DT_REASON_DESC_FONTSIZE;
const REASON_X_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_X_FONTSIZE : DT_REASON_X_FONTSIZE;
const REASON_X_DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_X_DESC_FONTSIZE : DT_REASON_X_DESC_FONTSIZE;
const REASON_GUIDE_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_GUIDE_FONTSIZE : DT_REASON_GUIDE_FONTSIZE;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_DESC_MARGIN_BOTTOM : DT_DESC_MARGIN_BOTTOM;
const REASON_TITLE_MARGIN_TOP = ScreenInfo.isMobile() ? MB_REASON_TITLE_MARGIN_TOP : DT_REASON_TITLE_MARGIN_TOP;
const REASON_TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_TITLE_MARGIN_BOTTOM : DT_REASON_TITLE_MARGIN_BOTTOM;
const REASON_DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_DESC_MARGIN_BOTTOM : DT_REASON_DESC_MARGIN_BOTTOM;
const REASON_X_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_X_MARGIN_BOTTOM : DT_REASON_X_MARGIN_BOTTOM;
const REASON_X_DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_X_DESC_MARGIN_BOTTOM : DT_REASON_X_DESC_MARGIN_BOTTOM;
const REASON_GUIDE_MARGIN_V = ScreenInfo.isMobile() ? MB_REASON_GUIDE_MARGIN_V : DT_REASON_GUIDE_MARGIN_V;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const REASON_X_DESC_LEFT_PADDING = ScreenInfo.isMobile() ? MB_REASON_X_DESC_LEFT_PADDING : DT_REASON_X_DESC_LEFT_PADDING;

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function AssetCreatorBlockedScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator Blocked - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hR = useLocalSettings();
  const hDlgFactory = useDlgFactory();
  // const { pageWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/
  const handleInqueryBtn = () => {
    hDlgFactory.showInquery({
      onSuccess: () => {
        hDlgFactory.hideInquery();
        hDlgFactory.showSimpleOk({ text: hR.strings.INQUERY_SUCCESS });
      },
      onClose: () => {
        hDlgFactory.hideInquery();
      },
      defaultSelected: CbtInqueryType_e.Task,
    });
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  DBGMSG('render');
  if (ScreenInfo.isMobile()) {
    return (
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'center',
            paddingTop: 20,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          {/*  */}
          <TextCon text={hR.strings.AC_BLOCKED_TITLE} color={R.colors.black} isBold size={16} />
          <HEIGHT size={20} />
          <TextCon
            text={hR.strings.AC_BLOCKED_GUIDE_ROW_1}
            color={R.colors.black}
            isBold
            size={10}
            textStyle={{ textAlign: 'center' }}

            // letterSpacing={-0.8}
          />
          <FlexRowDiv style={{ width: 280 }}>
            <span style={{ textAlign: 'center' }}>
              <Text
                color={R.colors.black}
                isBold
                size={10}
                // letterSpacing={-0.8}
              >
                {Utils.string.parsePlaceholder(hR.strings.AC_BLOCKED_GUIDE_ROW_2, hR.strings.AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT).begin}
              </Text>
              <Text
                color={R.colors.purpleishBlue}
                isBold
                size={10}
                // letterSpacing={-0.8}
              >
                {hR.strings.AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT}
              </Text>
              <Text
                color={R.colors.black}
                isBold
                size={10}
                // letterSpacing={-0.8}
              >
                {Utils.string.parsePlaceholder(hR.strings.AC_BLOCKED_GUIDE_ROW_2, hR.strings.AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT).end}
              </Text>
            </span>
          </FlexRowDiv>
          <HEIGHT size={30} />
          <RoundTxtBtn
            text={hR.strings.AC_BLOCKED_BTN}
            fontSize={12}
            width={200}
            height={40}
            onClick={() => {
              handleInqueryBtn();
            }}
          />
          <HEIGHT size={236} />

          {ScreenInfo.isMobile() && <CommonFooter />}
        </FlexColumnDiv>
      </MobileScalableFlexColumn>
    );
  } else {
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: CLAY.PAGE_WIDTH,
          alignItems: 'center',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          paddingLeft: CLAY.HPADDING,
          paddingRight: CLAY.HPADDING,
          ...devOutline,
        }}
      >
        <TextCon text={hR.strings.AC_BLOCKED_TITLE} color={R.colors.black} isBold size={30} />
        <HEIGHT size={30} />
        <TextCon text={hR.strings.AC_BLOCKED_GUIDE_ROW_1} color={R.colors.black} isBold size={14} />
        <FlexRowDiv>
          <TextCon
            text={Utils.string.parsePlaceholder(hR.strings.AC_BLOCKED_GUIDE_ROW_2, hR.strings.AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT).begin}
            color={R.colors.black}
            isBold
            size={14}
          />
          <TextCon text={hR.strings.AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT} color={R.colors.purpleishBlue} isBold size={14} />
          <TextCon
            text={Utils.string.parsePlaceholder(hR.strings.AC_BLOCKED_GUIDE_ROW_2, hR.strings.AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT).end}
            color={R.colors.black}
            isBold
            size={14}
          />
        </FlexRowDiv>
        <HEIGHT size={30} />
        <RoundTxtBtn
          text={hR.strings.AC_BLOCKED_BTN}
          fontSize={14}
          width={255}
          height={45}
          onClick={() => {
            handleInqueryBtn();
          }}
        />
      </FlexColumnDiv>
    );
  }
}
