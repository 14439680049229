/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { RPC_USER_ACCOUNT_MESSAGE_LIST } from 'src/model/rpcType';
import R from 'src/res/R';
import { CbtStompMsgCode_e, CbtStompMsgType_e, CbtStomp_i } from 'src/rx/messageService';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { CLAY } from 'src/ui/layout_constant';
import { Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DT_NOTI_IC_SIZE = 40;
const DT_FONT_SIZE = 12;
const DT_TIME_FONT_SIZE = 10;
const DT_VALUE_WIDTH = 416;
const DT_VALUE_MARGIN_LEFT = 12;
const DT_VALUE_MARGIN_BOTTOM = 2;
const DT_MARGIN_V = 8;

type Props = {};
export function NotiListScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sNotiList, setNotiList] = useState<RPC_USER_ACCOUNT_MESSAGE_LIST.Rx>();
  const [sNotiListLoading, setNotiListLoading] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`Notification - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hR = useLocalSettings();
  const hHistory = useHistory();
  const hRpc = useRpc();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    fetchNotiList();
    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchNotiList = async () => {
    try {
      setNotiListLoading(true);
      const res = await hRpc.cobaltRpc.rpcUserAccountMessageList({});
      setNotiList(res);
    } catch (err) {
    } finally {
      setNotiListLoading(false);
    }
  };

  /**************************************
   * !! render function
   **************************************/
  const renderNoti = (args: {
    //
    type: CbtStompMsgType_e;
    code: CbtStompMsgCode_e;
    content: string;
    time: number;
  }) => {
    let isSucc = false;
    switch (args.code) {
      case CbtStompMsgCode_e.ASSET_COLLECTION_CREATE_SUCCESS:
      case CbtStompMsgCode_e.ASSET_COLLECTION_EDIT_SUCCESS:
      case CbtStompMsgCode_e.ASSET_SCHEMA_CREATE_SUCCESS:
      case CbtStompMsgCode_e.ASSET_SCHEMA_EDIT_SUCCESS:
      case CbtStompMsgCode_e.ASSET_CREATE_SUCCESS:
      case CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS:
      case CbtStompMsgCode_e.BILL_CHARGE_POINT_SUCCESS:
        isSucc = true;
        break;
      default:
        isSucc = false;
    }
    return (
      <>
        <HEIGHT size={DT_MARGIN_V} />
        <FlexRowDiv style={{ alignItems: 'center' }}>
          <Img src={[isSucc ? R.images.common_ic_noti_success3x : R.images.common_ic_noti_fail3x, DT_NOTI_IC_SIZE, DT_NOTI_IC_SIZE]} />
          <WIDTH size={DT_VALUE_MARGIN_LEFT} />
          <FlexColumnDiv
            style={{
              width: DT_VALUE_WIDTH,
              justifyContent: 'space-between',
              // ...devOutlineF
            }}
          >
            <TextCon text={args.content} size={DT_FONT_SIZE} color={R.colors.black} isBold />
            <HEIGHT size={DT_VALUE_MARGIN_BOTTOM} />
            <TextCon text={`${Utils.date.brewFomatString(args.time)}`} size={DT_TIME_FONT_SIZE} color={R.colors.blueGrey} />
          </FlexColumnDiv>
        </FlexRowDiv>

        <HEIGHT size={DT_MARGIN_V} />
      </>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  return (
    <MobileScalableFlexColumn  key={sNotiList?.message_list.length}>
      <FlexColumnDiv
        style={{
          width: MOBILE_WIDTH,
          paddingLeft: MOBILE_HORIZONTAL_PADDING,
          paddingRight: MOBILE_HORIZONTAL_PADDING,
          paddingTop: CLAY.TITLE_PADDING_TOP,
          alignItems: 'flex-start',
        }}
      >
        <BackBtn />
        <TextCon text={hR.strings.COMMON_NOTI_TITLE} containerStyle={{ marginTop: 4, marginBottom: 10 }} color={R.colors.black} size={16} isBold />
        <FlexColumnDiv style={{ width: MOBILE_WIDTH - 24, alignSelf: 'center' }}>
          {sNotiList &&
            sNotiList.message_list.map((noti) => {
              return renderNoti(noti);
            })}
        </FlexColumnDiv>
        <HEIGHT size={200} />
      </FlexColumnDiv>

      {ScreenInfo.isMobile() && <CommonFooter />}
    </MobileScalableFlexColumn>
  );
}
