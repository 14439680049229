/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useDebounce } from 'react-use';
import ScreenInfo, { DESKTOP_WIDTH } from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMakePressable } from 'src/hooks/useMakePressable';
import { useMarketPlaceState } from 'src/hooks/useMarketPlaceState';
import { useMouseEnterEffectType1 } from 'src/hooks/useMouseEnterEffectType1';
import { CbtMarketSearchSort_e } from 'src/model/model';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, CommonInputBold, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import TxtBtnWithMouseOverAni from 'src/ui/base_component/TxtBtnWithMouseOverAni';
import { CommonColumnBox } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const DT_INPUT_WIDTH = DESKTOP_WIDTH;
const DT_INPUT_HEIGHT = 44;
const DT_PADDING_LEFT = 14 - 4;
const DT_INPUT_AREA_WIDTH = 917;
const DT_DROPDOWN_WIDTH = 276;
const DT_SEACH_IC_SIZE = 24;
const DT_SEACH_IC_PADDING = 4;
const DT_SEACH_IC_MARGIN_RIGHT = 10 - 4;
const DT_FONTSIZE = 14;
const DT_DROPDOWN_FONTSIZE = 14;
const DT_DROPDOWN_IC_SIZE = 24;
const DT_DROPDOWN_PADDING_LEFT = 14;
const DT_DROPDOWN_MENU_HEIGHT = 30;
const DT_DROPDOWN_MENU_PADIING_LEFT = 10;
const DT_DROPDOWN_MENU_FONTSIZE = 12;
const DT_DROPDOWN_PADDING_V = 15;

const MB_INPUT_WIDTH = 280;
const MB_INPUT_HEIGHT = 40;
const MB_PADDING_LEFT = 10 - 4;
const MB_INPUT_AREA_WIDTH = 160;
const MB_DROPDOWN_WIDTH = 119;
const MB_SEACH_IC_SIZE = 16;
const MB_SEACH_IC_PADDING = 4;
const MB_SEACH_IC_MARGIN_RIGHT = 0;
const MB_FONTSIZE = 12;
const MB_DROPDOWN_FONTSIZE = 10;
const MB_DROPDOWN_IC_SIZE = 16;
const MB_DROPDOWN_PADDING_LEFT = 12;
const MB_DROPDOWN_MENU_HEIGHT = 27;
const MB_DROPDOWN_MENU_PADIING_LEFT = 10;
const MB_DROPDOWN_MENU_FONTSIZE = 10;
const MB_DROPDOWN_PADDING_V = 41 - 27;

const INPUT_WIDTH = ScreenInfo.isMobile() ? MB_INPUT_WIDTH : DT_INPUT_WIDTH;
const INPUT_HEIGHT = ScreenInfo.isMobile() ? MB_INPUT_HEIGHT : DT_INPUT_HEIGHT;
const PADDING_LEFT = ScreenInfo.isMobile() ? MB_PADDING_LEFT : DT_PADDING_LEFT;
const INPUT_AREA_WIDTH = ScreenInfo.isMobile() ? MB_INPUT_AREA_WIDTH : DT_INPUT_AREA_WIDTH;
const DROPDOWN_WIDTH = ScreenInfo.isMobile() ? MB_DROPDOWN_WIDTH : DT_DROPDOWN_WIDTH;
const SEACH_IC_SIZE = ScreenInfo.isMobile() ? MB_SEACH_IC_SIZE : DT_SEACH_IC_SIZE;
const SEACH_IC_PADDING = ScreenInfo.isMobile() ? MB_SEACH_IC_PADDING : DT_SEACH_IC_PADDING;
const SEACH_IC_MARGIN_RIGHT = ScreenInfo.isMobile() ? MB_SEACH_IC_MARGIN_RIGHT : DT_SEACH_IC_MARGIN_RIGHT;
const FONTSIZE = ScreenInfo.isMobile() ? MB_FONTSIZE : DT_FONTSIZE;
const DROPDOWN_FONTSIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_FONTSIZE : DT_DROPDOWN_FONTSIZE;
const DROPDOWN_IC_SIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_IC_SIZE : DT_DROPDOWN_IC_SIZE;
const DROPDOWN_PADDING_LEFT = ScreenInfo.isMobile() ? MB_DROPDOWN_PADDING_LEFT : DT_DROPDOWN_PADDING_LEFT;
const DROPDOWN_MENU_HEIGHT = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_HEIGHT : DT_DROPDOWN_MENU_HEIGHT;
const DROPDOWN_MENU_PADIING_LEFT = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_PADIING_LEFT : DT_DROPDOWN_MENU_PADIING_LEFT;
const DROPDOWN_MENU_FONTSIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_FONTSIZE : DT_DROPDOWN_MENU_FONTSIZE;
const DROPDOWN_PADDING_V = ScreenInfo.isMobile() ? MB_DROPDOWN_PADDING_V : DT_DROPDOWN_PADDING_V;

type Props = {
  inputText: string;
  isAnim?: boolean;
  inputSort: CbtMarketSearchSort_e;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onDebouncedChange?: (txt: string) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onSort?: (selectedType: CbtMarketSearchSort_e) => void;
  onSearch?: () => void;
  onDeleteClick?: () => void;
  dropDownMenuList?: CbtMarketSearchSort_e[];
  getRef?: (ref: HTMLInputElement | null) => void;
};

export function MarketSearchInput({
  inputText: searchText,
  isAnim = false,
  inputSort: searchOrderBy,
  onFocus,
  onBlur,
  onChange,
  onDebouncedChange,
  onKeyDown,
  onSort,
  onDeleteClick,
  onSearch,
  dropDownMenuList = [
    CbtMarketSearchSort_e.ListingNewest,
    CbtMarketSearchSort_e.ListingOldest,
    CbtMarketSearchSort_e.PriceHighest,
    CbtMarketSearchSort_e.PriceLowest,
  ],
  getRef,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [sIsFocus, setIsFocus] = useState(false);
  const [sShowDropDown, setShowDropDown] = useState(false);

  /**************************************
   * !! hook
   **************************************/
  const [, debounceCancel] = useDebounce(
    () => {
      setIsTyping(false);
      if (searchText === debouncedSearchTxt) return;
      setDebouncedSearchTxt(searchText);
      DBGMSG(`debouncedInputText:${searchText}`);
      onDebouncedChange && onDebouncedChange(searchText);
    },
    1000,
    [searchText]
  );

  const [debouncedSearchTxt, setDebouncedSearchTxt] = useState(searchText);
  const hR = useLocalSettings();

  /**************************************
   * !! ref
   **************************************/
  const dropdownMenuRef = useRef(null);
  const dropdownPivotRef = useRef(null);
  const dropdownBtnRef = useRef(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useMakePressable([
    {
      elementKey: 'sort',
      elementRefList: [dropdownMenuRef, dropdownBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        DBGMSG('MarketSearchInput', 'onInsideClick');
        if (dropdownBtnRef.current === hitRef.current) {
          if (!sShowDropDown) setShowDropDown(true);
        }
        e.stopPropagation();
      },
      onOutsidePress: () => {
        DBGMSG('MarketSearchInput', 'onOutsideClick');
        if (!sShowDropDown) return;
        setShowDropDown(false);
      },
    },
  ]);

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  /**************************************
   * !! render function
   **************************************/
  const renderDropDownMenu = (filter: CbtMarketSearchSort_e) => {
    return (
      <FlexDiv key={`${filter}`}>
        <TxtBtnWithMouseOverAni
          size={DROPDOWN_MENU_FONTSIZE}
          // color={R.colors.purpleishBlue : R.colors.txtPrimary}
          color={searchOrderBy === filter ? R.colors.purpleishBlue : R.colors.black}
          isBold={searchOrderBy === filter}
          text={hR.strings.getSearchOrderBy(filter)}
          onMouseDefalutProp={{ backgroundColor: 'white' }}
          onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
          onMouseLeaveProp={{ backgroundColor: 'white' }}
          containerStyle={{
            width: '100%',
            height: DROPDOWN_MENU_HEIGHT,
            paddingLeft: DROPDOWN_MENU_PADIING_LEFT,
            justifyContent: 'center',
            zIndex: 1000000,
            // ...devOutlineF
          }}
          onClick={(e) => {
            DBGMSG('CurrencyCombo: onClick');
            onSort && onSort(filter);
            setTimeout(() => {
              setShowDropDown(false);
            }, 0);
          }}
        />
      </FlexDiv>
    );
  };

  /**************************************
   * !! animation
   **************************************/
  const { hAnimProps, mouseEnter, mouseLeave } = useMouseEnterEffectType1();

  return (
    <FlexColumnDiv
      style={
        {
          // ...devOutlineF,
        }
      }
    >
      <AnimatedFlexRowDiv
        style={Object.assign(
          {
            alignItems: 'center',
            width: INPUT_WIDTH,
            height: INPUT_HEIGHT,
            backgroundColor: R.colors.bgGrey,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: R.colors.line,
            borderRadius: 15,
            // ...devOutline,
            // paddingLeft: 14,
            // paddingRight: 14,
          },
          isAnim ? hAnimProps : undefined,
          sIsFocus ? { borderColor: R.colors.purpleishBlue, borderWidth: 1.2 } : undefined
        )}
        onMouseEnter={isAnim ? mouseEnter : undefined}
        onMouseLeave={isAnim ? mouseLeave : undefined}
      >
        {/* 검색어 입력 */}
        <FlexRowDiv
          style={{
            width: INPUT_AREA_WIDTH,
            paddingLeft: PADDING_LEFT,
            // ...devOutlineFF
          }}
        >
          <ImgBtn
            src={[R.images.common_ic_search3x, SEACH_IC_SIZE, SEACH_IC_SIZE]}
            containerStyle={{
              padding: SEACH_IC_PADDING,
              // ...devOutlineFF
            }}
            onClick={() => {
              setIsTyping(false);
              setDebouncedSearchTxt(searchText);
              onSearch && onSearch();
              searchInputRef.current && searchInputRef.current.focus();
            }}
          />
          <WIDTH size={SEACH_IC_MARGIN_RIGHT} />
          <CommonInputBold
            ref={(_ref) => {
              searchInputRef.current = _ref;
              // DBGMSG(`ref: `, _ref);
              // DBGMSG(`searchInputRef.current: `, _ref);
              getRef && getRef(_ref);
            }}
            style={{
              //
              fontSize: FONTSIZE,
              fontWeight: 'bold',
              width: '100%',
              ...devOutline,
            }}
            placeholder={hR.strings.COMMON_SEARCH_PLACEHOLDER}
            value={searchText}
            onFocus={() => {
              DBGMSG('Input onFocus');
              onFocus && onFocus();
              setIsFocus(true);
            }}
            onBlur={() => {
              DBGMSG('Input onBlur');
              onBlur && onBlur();
              setIsFocus(false);
            }}
            onChange={(e) => {
              DBGMSG(`onChange: ${e.target.value}`);
              setIsTyping(true);

              // 텍스트를 모두 지웠을때 debounce 없이 처리
              if (e.target.value.length === 0) {
                debounceCancel();
                setDebouncedSearchTxt('');
                DBGMSG(`debouncedInputText:''`);
                onDebouncedChange && onDebouncedChange('');
              }
              onChange && onChange(e);
            }}
            // enter key 처리를 위해 필요함 onChange는 enter키 event가 오지 않음
            onKeyPress={(e) => {
              DBGMSG(`onKeyPress code:${e.code} key:${e.key}`);
              onKeyDown && onKeyDown(e);
              // Enter 키
              // - deboune 없이 처리
              // - 바로 검색
              if (e.code === 'Enter') {
                debounceCancel();
                if (searchText !== debouncedSearchTxt) {
                  setIsTyping(false);
                  setDebouncedSearchTxt(searchText);
                }
                onSearch && onSearch();
              }
            }}
          />
        </FlexRowDiv>
        {/* 중간 라인 */}
        <FlexDiv
          style={Object.assign(
            { height: INPUT_HEIGHT, width: 1, backgroundColor: R.colors.veryLightGrey2 },
            sIsFocus ? { backgroundColor: R.colors.purpleishBlue, width: 1.2 } : undefined
          )}
        />

        {/* filter */}
        <FlexColumnDiv
          style={{
            height: INPUT_HEIGHT,
            justifyContent: 'center',
            // ...devOutlineF
          }}
        >
          <FlexRowDiv
            ref={dropdownPivotRef}
            style={{
              width: DROPDOWN_WIDTH,
              // height: 1,
              // backgroundColor: R.colors.devColor1a,
              position: 'absolute',
              top: 0,
            }}
          />
          <FlexRowDiv
            ref={dropdownBtnRef}
            style={{
              width: DROPDOWN_WIDTH,
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            // onClick={(e) => {
            //   DBGMSG('MarketSearchInput', 'onclick');
            //   setShowDropDown(true);
            //   e.stopPropagation();
            // }}
          >
            <TextCon
              text={`${hR.strings.getSearchOrderBy(searchOrderBy)}`}
              isBold
              size={DROPDOWN_FONTSIZE}
              color={R.colors.purpleishBlue}
              containerStyle={{ paddingLeft: DROPDOWN_PADDING_LEFT }}
            />
            <Img src={[R.images.common_btn_drop_down3x, DROPDOWN_IC_SIZE, DROPDOWN_IC_SIZE]} containerStyle={{ marginLeft: 'auto', padding: 6 }} />
          </FlexRowDiv>
        </FlexColumnDiv>

        <Overlay target={dropdownPivotRef.current} show={sShowDropDown} placement={'bottom-start'}>
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                zIndex: 100,
              }}
            >
              <CommonColumnBox ref={dropdownMenuRef} style={{ width: DROPDOWN_WIDTH + 1 }}>
                <HEIGHT size={DROPDOWN_PADDING_V} />
                <FlexColumnDiv style={{ flex: 1, justifyContent: 'center' }}>
                  {dropDownMenuList.map((menu) => {
                    return renderDropDownMenu(menu);
                  })}
                </FlexColumnDiv>
                <HEIGHT size={DROPDOWN_PADDING_V} />
              </CommonColumnBox>
            </div>
          )}
        </Overlay>
      </AnimatedFlexRowDiv>
    </FlexColumnDiv>
  );
}
