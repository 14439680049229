/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import ScreenInfo, { MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { RPC_ASSET_CREATOR_COLLECTION_LIST } from 'src/model/rpcType';
import { CbtCollectionCardInfo_i, CbtCreationStatus_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { messageService, CbtStompMsgCode_e } from 'src/rx/messageService';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { SAddBtn } from 'src/ui/common_component/button/SAddBtn';
import { CollectionCardForList } from 'src/ui/common_component/CollectionCardForList';
import { CollectionCardNew } from 'src/ui/common_component/CollectionCardNew';
import { CommonFooter, COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { Title } from 'src/ui/common_component/Title';
import { CLAY, COLLECTION_CARD_LAYOUT, CommonColumnBox, COMMON_CARD_COLUMN_CNT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGW } from 'src/util/utils';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { AppConf, isProd } from 'src/conf/AppConf';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/
enum CollectionCardType_e {
  NewCollectionCard,
}

/**************************************
 * !! constant
 **************************************/

type Props = {};

export function AssetCreatorScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sFetchedData, setFetchedData] = useState<RPC_ASSET_CREATOR_COLLECTION_LIST.Rx>();
  const [sCollectionList, setCollectionList] = useState<(CbtCollectionCardInfo_i | CollectionCardType_e)[]>([]);

  const [sNextPageStatus, setNextPageStatus] = useState<string>('');

  /**************************************
   * !! ref
   **************************************/
  const fetchedDataRef = useRef<RPC_ASSET_CREATOR_COLLECTION_LIST.Rx>();
  const nextPageLoadingRef = useRef(false);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  useCheckUserCertTpAndBlock();
  const hStorage = useLocalStorage();

  /**************************************
   * !! animation
   **************************************/
  const [sMouseEnter, setMouseEnter] = useState(false);

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    fetchData();

    const subscription = messageService.onMessage().subscribe((message) => {
      switch (message.kind) {
        case 'ReceiveStompMsg':
          if (
            message.code === CbtStompMsgCode_e.ASSET_COLLECTION_CREATE_SUCCESS ||
            message.code === CbtStompMsgCode_e.ASSET_COLLECTION_CREATE_FAIL ||
            message.code === CbtStompMsgCode_e.ASSET_COLLECTION_EDIT_SUCCESS ||
            message.code === CbtStompMsgCode_e.ASSET_COLLECTION_EDIT_FAIL
          ) {
            fetchData();
          }
          // code(행동)은 각 screen이 구독하여 결정
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  // 첫페이지 요청
  const fetchData = async () => {
    try {
      const res = await hRpc.cobaltRpc.rpcAssetCreatorCollectionList({ pg_info: undefined });
      setFetchedData(res);
      fetchedDataRef.current = res;

      if (res.col_list.length === 0) setCollectionList([]);
      else setCollectionList([CollectionCardType_e.NewCollectionCard, ...res.col_list]);
      hStorage.setData({ max_royalty: `${res.max_royalty}` });
    } catch (err) {
      hRpc.hanleCommonError(err);
    }
  };

  const getLastRowId = () => {
    if (!fetchedDataRef.current) return;
    if (!fetchedDataRef.current.pg_info) return;
    return fetchedDataRef.current.pg_info.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  // 다음페이지 요청
  const fetchItemListMore = async () => {
    if (!fetchedDataRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'sFetcheAstListDataRef is undefined');
      return Promise.reject();
    }

    if (sCollectionList.length === 0) {
      DBGMSG('$$$$PAGE_SCROLL', 'sCollectionList.length === 0');
      return Promise.reject();
    }

    nextPageLoadingRef.current = true;

    const pg_info = fetchedDataRef.current.pg_info;

    try {
      setNextPageStatus('loading');
      const res = await hRpc.cobaltRpc.rpcAssetCreatorCollectionList({ pg_info });
      setFetchedData(res);
      setCollectionList([...sCollectionList, ...res.col_list]);
      setNextPageStatus('');
      nextPageLoadingRef.current = false;
      fetchedDataRef.current = res;
    } catch (err) {
      setNextPageStatus('loading fail');
      nextPageLoadingRef.current = false;
    }
  };

  // 스크롤 리스너
  const scrollListener = (e: ScrollParams) => {
    // DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (!fetchedDataRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'sFetcheAstListDataRef is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;
    // DBGMSG('$$$$PAGE_SCROLL', `clientHeight: ${clientHeight}, scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}`);
    // DBGMSG('$$$$PAGE_SCROLL', `${scrollTop + clientHeight} >= ${scrollHeight - COMMON_FOONTER_HEIGHT}`);
    // DBGMSG('$$$$PAGE_SCROLL', `atBottom: ${atBottom} `);

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return triggeredRef.current is true `);
      return;
    }

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    if (atBottom) {
      fetchItemListMore()
        .then(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage then');
        })
        .catch(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage catch');
        });
    }
  };

  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  /**************************************
   * !! render function
   **************************************/
  // 아이템 렌더링
  const cellRenderer = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    let index: number;
    let item: CbtCollectionCardInfo_i | CollectionCardType_e;
    index = rowIndex * COMMON_CARD_COLUMN_CNT + columnIndex;
    item = sCollectionList[index];

    if (index >= sCollectionList.length) return null;

    // DBGMSG(`typeof item: ${typeof item}`);
    return (
      // 중요!
      <div
        key={key}
        style={{
          ...style,
          // ...devOutlineF,
        }}
      >
        <MobileScalableFlexColumn
          getHeight={({ orgHeight, scaledHeight }) => {
            DBGMSG(`MobileScalableFlexColumn: orgHeight: ${orgHeight} scaledHeight: ${scaledHeight}`);
          }}
        >
          {item === CollectionCardType_e.NewCollectionCard ? (
            <CollectionCardNew
              isAnim
              onClick={() => {
                if (!sFetchedData) return;
                if (sFetchedData.creatable) hHistory.push(Nav.MyCollectionCScreen);
                else {
                  hDlgFactory.showSimpleOk({ text: hR.strings.DLG_EXCEED_COLLECTION_LIMIT });
                }
              }}
              // isAnim
            />
          ) : (
            <CollectionCardForList
              collectionInfo={item}
              isAnim
              onClick={() => {
                if ((item as CbtCollectionCardInfo_i).status === CbtCreationStatus_e.InPreparation) {
                  DBGMSGW('생성중입니다.');
                } else {
                  const id = (item as CbtCollectionCardInfo_i).cid;
                  hHistory.push(Nav.brewCollectionRScreenPath({ cid: id }));
                }
              }}
            />
          )}
        </MobileScalableFlexColumn>
      </div>
    );
  };

  // 모바일용 아이템 목록 렌더링
  // react-virtualized
  // transform을 확대하면 scroll offset 계산이 제대로 되지 않아
  // scale값을 곱하는 방식으로 처리함
  // 참고글 : https://github.com/bvaughn/react-virtualized/issues/610
  const renderList = () => {
    DBGMSG('renderList');
    return (
      <FlexColumnDiv
        style={{
          width: COLLECTION_CARD_LAYOUT.widthForList * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT,
          alignSelf: 'center',
          userSelect: 'none',
          // ...devOutlineF,
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => {
            // DBGMSG(`height: ${height}`);
            return (
              <FlexColumnDiv>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <div ref={registerChild}>
                      <Grid
                        onScroll={(params) => {
                          onChildScroll(params);
                          throttleScrollListener(params);
                        }}
                        isScrolling={isScrolling}
                        autoHeight
                        cellRenderer={cellRenderer}
                        columnWidth={COLLECTION_CARD_LAYOUT.widthForList * ScreenInfo.scale} // 아이템 너비
                        columnCount={COMMON_CARD_COLUMN_CNT}
                        height={height}
                        // noContentRenderer={this._noContentRenderer}
                        // overscanColumnCount={2}
                        // overscanRowCount={2}
                        rowHeight={COLLECTION_CARD_LAYOUT.heightForList * ScreenInfo.scale} // 아이템 높이
                        rowCount={Math.ceil(sCollectionList.length / COMMON_CARD_COLUMN_CNT)}
                        // rowCount={1}
                        // scrollToColumn={scrollToColumn}
                        // scrollToRow={scrollToRow}
                        scrollTop={scrollTop}
                        width={COLLECTION_CARD_LAYOUT.widthForList * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT} // 전체 너비
                      />
                    </div>
                  )}
                </AutoSizer>
              </FlexColumnDiv>
            );
          }}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  if (!sFetchedData) return <DataFetching />;

  /**************************************
   * !! render conf
   **************************************/
  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    DBGMSG('render');
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          ...devOutline,
        }}
      >
        {/* 컬렉션이 없을때 타이틀이 다름 */}
        <Title title={sCollectionList.length === 0 ? hR.strings.AC_COLLECTION_CREATE_TITLE : hR.strings.AC_COLLECTION_LIST_TITLE} />

        <HEIGHT size={15} />

        {sCollectionList.length === 0 ? (
          <>
            <HEIGHT size={15} />
            <CommonColumnBox
              style={{
                //
                cursor: 'pointer',
                width: '100%',
                height: 284,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onMouseEnter={() => {
                setMouseEnter(true);
              }}
              onMouseLeave={() => {
                setMouseEnter(false);
              }}
              onClick={(e) => {
                DBGMSG(`onClick`);
                hHistory.push(Nav.MyCollectionCScreen);
              }}
            >
              <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                <SAddBtn width={160} height={160} radius={80} forceEffect={sMouseEnter} />
                <HEIGHT size={24} />
                <TextCon
                  size={14}
                  color={R.colors.black}
                  text={`${hR.strings.AC_COLLECTION_LIST_DESC_1}\n${hR.strings.AC_COLLECTION_LIST_DESC_2}`}
                  textStyle={{ textAlign: 'center' }}
                  isBold
                />
              </FlexColumnDiv>
            </CommonColumnBox>
          </>
        ) : (
          renderList()
        )}
        <HEIGHT size={95} />
      </FlexColumnDiv>
    );
  } else {
    // ###############################################
    // mobile
    // ###############################################
    return (
      <>
        <MobileScalableFlexColumn
          getHeight={({ orgHeight, scaledHeight }) => {
            DBGMSG(`MobileScalableFlexColumn: orgHeight: ${orgHeight}`);
            DBGMSG(`MobileScalableFlexColumn: scaledHeight: ${scaledHeight}`);
          }}
        >
          <FlexColumnDiv
            style={{
              width: MOBILE_WIDTH,
              paddingLeft: MOBILE_HORIZONTAL_PADDING,
              paddingRight: MOBILE_HORIZONTAL_PADDING,
              paddingTop: CLAY.TITLE_PADDING_TOP,
              alignItems: 'flex-start',
            }}
          >
            <Title
              size={16 * ScreenInfo.scale}
              title={sCollectionList.length === 0 ? hR.strings.AC_COLLECTION_CREATE_TITLE : hR.strings.AC_COLLECTION_LIST_TITLE}
            />
            <HEIGHT size={14 * ScreenInfo.scale} />
          </FlexColumnDiv>
        </MobileScalableFlexColumn>

        <FlexColumnDiv>
          {sCollectionList.length === 0 ? (
            <MobileScalableFlexColumn
              getHeight={({ orgHeight, scaledHeight }) => {
                DBGMSG(`MobileScalableFlexColumn: orgHeight: ${orgHeight}`);
                DBGMSG(`MobileScalableFlexColumn: scaledHeight: ${scaledHeight}`);
              }}
            >
              <FlexColumnDiv style={{ paddingLeft: CLAY.HPADDING, paddingRight: CLAY.HPADDING }}>
                <CommonColumnBox
                  style={{
                    //
                    cursor: 'pointer',
                    width: COLLECTION_CARD_LAYOUT.width,
                    height: 277,
                    justifyContent: 'center',
                    alignItems: 'center',
                    // ...devOutlineF,
                  }}
                  onMouseEnter={() => {
                    setMouseEnter(true);
                  }}
                  onMouseLeave={() => {
                    setMouseEnter(false);
                  }}
                  onClick={(e) => {
                    DBGMSG(`onClick`);
                    hHistory.push(Nav.MyCollectionCScreen);
                  }}
                >
                  <FlexColumnDiv style={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                    <SAddBtn width={162} height={162} radius={81} forceEffect={sMouseEnter} />
                    <HEIGHT size={17} />
                    <TextCon size={10} color={R.colors.black} text={hR.strings.AC_COLLECTION_LIST_DESC_1} isBold />
                    <TextCon size={10} color={R.colors.black} text={hR.strings.AC_COLLECTION_LIST_DESC_2} isBold />
                  </FlexColumnDiv>
                </CommonColumnBox>
              </FlexColumnDiv>
            </MobileScalableFlexColumn>
          ) : (
            renderList()
          )}
          <MobileScalableFlexColumn>
            <HEIGHT size={95} />
          </MobileScalableFlexColumn>
        </FlexColumnDiv>

        {/* <HLINE size={'100%'} /> */}

        {/* 공통푸터 */}
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      </>
    );
  }
}
