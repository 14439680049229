/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { devOutlineF, FlexColumnDiv, FlexDiv, HEIGHT, HLINE } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { DBGMSG } from 'src/util/utils';
import { CLAY } from 'src/ui/layout_constant';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import ScreenInfo from 'src/context/screen_constants';
import { AppConf, isProd } from 'src/conf/AppConf';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DT_TITLE_FONTSIZE = 30;
const DT_DESC_FONTSIZE = 14;
const DT_REASON_TITLE_FONTSIZE = 22;
const DT_REASON_DESC_FONTSIZE = 14;
const DT_REASON_X_FONTSIZE = 16;
const DT_REASON_X_DESC_FONTSIZE = 16;
const DT_REASON_GUIDE_FONTSIZE = 14;
const DT_TITLE_MARGIN_BOTTOM = 30;
const DT_DESC_MARGIN_BOTTOM = 49;
const DT_REASON_TITLE_MARGIN_TOP = 29;
const DT_REASON_TITLE_MARGIN_BOTTOM = 10;
const DT_REASON_DESC_MARGIN_BOTTOM = 40;
const DT_REASON_X_MARGIN_BOTTOM = 3;
const DT_REASON_X_DESC_MARGIN_BOTTOM = 12;
const DT_REASON_GUIDE_MARGIN_V = 40;
const DT_PADDING_BOTTOM = 128;
const DT_REASON_X_DESC_LEFT_PADDING = 13;

const MB_TITLE_FONTSIZE = 16;
const MB_DESC_FONTSIZE = 10;
const MB_REASON_TITLE_FONTSIZE = 12;
const MB_REASON_DESC_FONTSIZE = 10;
const MB_REASON_X_FONTSIZE = 10;
const MB_REASON_X_DESC_FONTSIZE = 10;
const MB_REASON_GUIDE_FONTSIZE = 10;
const MB_TITLE_MARGIN_BOTTOM = 20;
const MB_DESC_MARGIN_BOTTOM = 20;
const MB_REASON_TITLE_MARGIN_TOP = 20;
const MB_REASON_TITLE_MARGIN_BOTTOM = 5;
const MB_REASON_DESC_MARGIN_BOTTOM = 20;
const MB_REASON_X_MARGIN_BOTTOM = 3;
const MB_REASON_X_DESC_MARGIN_BOTTOM = 10;
const MB_REASON_GUIDE_MARGIN_V = 20;
const MB_PADDING_BOTTOM = 60;
const MB_REASON_X_DESC_LEFT_PADDING = 8;

const TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_TITLE_FONTSIZE : DT_TITLE_FONTSIZE;
const DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_DESC_FONTSIZE : DT_DESC_FONTSIZE;
const REASON_TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_TITLE_FONTSIZE : DT_REASON_TITLE_FONTSIZE;
const REASON_DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_DESC_FONTSIZE : DT_REASON_DESC_FONTSIZE;
const REASON_X_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_X_FONTSIZE : DT_REASON_X_FONTSIZE;
const REASON_X_DESC_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_X_DESC_FONTSIZE : DT_REASON_X_DESC_FONTSIZE;
const REASON_GUIDE_FONTSIZE = ScreenInfo.isMobile() ? MB_REASON_GUIDE_FONTSIZE : DT_REASON_GUIDE_FONTSIZE;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_DESC_MARGIN_BOTTOM : DT_DESC_MARGIN_BOTTOM;
const REASON_TITLE_MARGIN_TOP = ScreenInfo.isMobile() ? MB_REASON_TITLE_MARGIN_TOP : DT_REASON_TITLE_MARGIN_TOP;
const REASON_TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_TITLE_MARGIN_BOTTOM : DT_REASON_TITLE_MARGIN_BOTTOM;
const REASON_DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_DESC_MARGIN_BOTTOM : DT_REASON_DESC_MARGIN_BOTTOM;
const REASON_X_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_X_MARGIN_BOTTOM : DT_REASON_X_MARGIN_BOTTOM;
const REASON_X_DESC_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_REASON_X_DESC_MARGIN_BOTTOM : DT_REASON_X_DESC_MARGIN_BOTTOM;
const REASON_GUIDE_MARGIN_V = ScreenInfo.isMobile() ? MB_REASON_GUIDE_MARGIN_V : DT_REASON_GUIDE_MARGIN_V;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const REASON_X_DESC_LEFT_PADDING = ScreenInfo.isMobile() ? MB_REASON_X_DESC_LEFT_PADDING : DT_REASON_X_DESC_LEFT_PADDING;

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserBanScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User Ban - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hR = useLocalSettings();
  // const { pageWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    // query string parsing
    const query: { lang?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.lang) {
      switch (query.lang) {
        case 'ko':
          hR.changeLang(CbtUserLanguage_e.KO);
          break;
        case 'ja':
          hR.changeLang(CbtUserLanguage_e.JA);
          break;
        case 'en':
          hR.changeLang(CbtUserLanguage_e.EN);
          break;
      }
    } else {
      // key 없이 접근
      return;
    }

    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  DBGMSG('render');
  return (
    <MobileScalableFlexColumn>
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: CLAY.PAGE_WIDTH,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          paddingLeft: CLAY.HPADDING,
          paddingRight: CLAY.HPADDING,
          ...devOutline,
        }}
      >
        <FlexColumnDiv style={{ width: CLAY.BODY_WIDTH, alignItems: 'center' }}>
          <TextCon text={hR.strings.USERBAN_TITLE} color={R.colors.black} isBold size={TITLE_FONTSIZE} />
          <HEIGHT size={TITLE_MARGIN_BOTTOM} />
          <TextCon text={hR.strings.USERBAN_DESC} color={R.colors.black} isBold size={DESC_FONTSIZE} textStyle={{ textAlign: 'center' }} />
        </FlexColumnDiv>
        <HEIGHT size={DESC_MARGIN_BOTTOM} />
        <HLINE size={'100%'} />
        <HEIGHT size={REASON_TITLE_MARGIN_TOP} />
        <TextCon text={hR.strings.USERBAN_REASON_TITLE} color={R.colors.black} isBold size={REASON_TITLE_FONTSIZE} />
        <HEIGHT size={REASON_TITLE_MARGIN_BOTTOM} />
        <TextCon text={hR.strings.USERBAN_REASON_DESC} color={R.colors.black} size={REASON_DESC_FONTSIZE} />
        <HEIGHT size={REASON_DESC_MARGIN_BOTTOM} />

        {/* 운영정책 위반 */}
        <TextCon text={hR.strings.USERBAN_REASON_1} color={R.colors.purpleishBlue} isBold size={REASON_X_FONTSIZE} />
        <HEIGHT size={REASON_X_MARGIN_BOTTOM} />
        <TextCon
          text={hR.strings.USERBAN_REASON_1_DESC}
          color={R.colors.black}
          size={REASON_X_DESC_FONTSIZE}
          containerStyle={{ marginLeft: REASON_X_DESC_LEFT_PADDING }}
        />
        <HEIGHT size={REASON_X_DESC_MARGIN_BOTTOM} />

        {/* 신고 누적 */}
        <TextCon text={hR.strings.USERBAN_REASON_2} color={R.colors.purpleishBlue} isBold size={REASON_X_FONTSIZE} />
        <HEIGHT size={REASON_X_MARGIN_BOTTOM} />
        <TextCon
          text={hR.strings.USERBAN_REASON_2_DESC}
          color={R.colors.black}
          size={REASON_X_DESC_FONTSIZE}
          containerStyle={{ marginLeft: REASON_X_DESC_LEFT_PADDING }}
        />
        <HEIGHT size={REASON_X_DESC_MARGIN_BOTTOM} />

        {/* 계정 해킹 방지 */}
        <TextCon text={hR.strings.USERBAN_REASON_3} color={R.colors.purpleishBlue} isBold size={REASON_X_FONTSIZE} />
        <HEIGHT size={REASON_X_MARGIN_BOTTOM} />
        <TextCon
          text={hR.strings.USERBAN_REASON_3_DESC}
          color={R.colors.black}
          size={REASON_X_DESC_FONTSIZE}
          containerStyle={{ marginLeft: REASON_X_DESC_LEFT_PADDING }}
        />
        <HEIGHT size={REASON_X_DESC_MARGIN_BOTTOM} />

        {/* 명의 도용 */}
        <TextCon text={hR.strings.USERBAN_REASON_4} color={R.colors.purpleishBlue} isBold size={REASON_X_FONTSIZE} />
        <HEIGHT size={REASON_X_MARGIN_BOTTOM} />
        <TextCon
          text={hR.strings.USERBAN_REASON_4_DESC}
          color={R.colors.black}
          size={REASON_X_DESC_FONTSIZE}
          containerStyle={{ marginLeft: REASON_X_DESC_LEFT_PADDING }}
        />
        <HEIGHT size={REASON_X_DESC_MARGIN_BOTTOM} />

        {/* 악용할 목적으로 여러 계정 사용 */}
        <TextCon text={hR.strings.USERBAN_REASON_5} color={R.colors.purpleishBlue} isBold size={REASON_X_FONTSIZE} />
        <HEIGHT size={REASON_X_MARGIN_BOTTOM} />
        <TextCon
          text={hR.strings.USERBAN_REASON_5_DESC}
          color={R.colors.black}
          size={REASON_X_DESC_FONTSIZE}
          containerStyle={{ marginLeft: REASON_X_DESC_LEFT_PADDING }}
        />

        <HEIGHT size={REASON_GUIDE_MARGIN_V} />

        <TextCon text={hR.strings.USERBAN_REASON_GUIDE} color={R.colors.black} size={REASON_GUIDE_FONTSIZE} />
        <HEIGHT size={REASON_GUIDE_MARGIN_V} />
        <HLINE size={'100%'} />
        <HEIGHT size={PADDING_BOTTOM} />
      </FlexColumnDiv>
      {ScreenInfo.isMobile() && <CommonFooter />}
    </MobileScalableFlexColumn>
  );
}
