/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CbtPgInfo_i } from 'src/model/rpcModel';
import { RPC_MP_SALE_SCHEMA_ASSET_LIST, RPC_MP_SALE_SCHEMA_VIEW } from 'src/model/rpcType';

/****************************************/
// state
/****************************************/
export type MarketSchmState_t = {
  sid?: number;
  detail?: RPC_MP_SALE_SCHEMA_VIEW.Rx;
  listNeedUpdate: boolean;
  lastRx?: RPC_MP_SALE_SCHEMA_ASSET_LIST.Rx;
  lastPgInfo?: CbtPgInfo_i;
  list?: RPC_MP_SALE_SCHEMA_ASSET_LIST.Rx['sale_list'];
};

const initialState: MarketSchmState_t = {
  listNeedUpdate: true,
};

const marketSchmSlice = createSlice({
  name: 'userMarketSchmState',
  initialState: initialState,
  reducers: {
    clear: (state) => {
      state = {
        ...initialState,
      };
      return state;
    },
    setState: (state, action: PayloadAction<MarketSchmState_t>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    changeState: (state, action: PayloadAction<Partial<MarketSchmState_t>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export default marketSchmSlice;
