/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { CbtAssetType_e, CbtAttributeType_e, ServerECode_e, CbtAttributeInfo_i } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipBoxContainer } from 'src/ui/base_component/TooltipBoxContainer';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RadioBtn } from 'src/ui/common_component/button/RadioBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SchemaAttribute } from 'src/ui/common_component/SchemaAttribute';
import { SchemaAttributeAdd } from 'src/ui/common_component/SchemaAttributeAdd';
import { SchemaAttributeForm } from 'src/ui/common_component/SchemaAttributeForm';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { Title } from 'src/ui/common_component/Title';
import { CommonColumnBox, COMMON_BOX_HORIZONTAL_PADDING, CLAY, MY_SCHEMA_C_SCREEN_LAYOUT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MySchemaCScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sSchemaName, setSchemaName] = useState<string>(''); // 스키마 이름
  const [sSchemaNameValidation, setSchemaNameValidation] = useState<{
    isDupError: boolean; // undefined: 체크전, true: 중복 에러일경우 , false: 중복 에러 아닐경우
  }>({ isDupError: false });
  const [sDisplayName, setDisplayName] = useState<string>(''); // 디스플레이 이름
  const [sAssetType, setAssetType] = useState<CbtAssetType_e>(CbtAssetType_e.IMAGE); // 에셋 유형 (default Image)
  const [sAttributeList, setAttributeList] = useState<
    (Partial<CbtAttributeInfo_i> & {
      validation?: {
        status: boolean;
        emsg: string;
      };
    })[]
  >([]); // 속성 이름과 유형
  const [sCurOpenDropDownIdx, setCurOpenDropDownIdx] = useState<number>(); // 속성 유형 선택 드롭다운

  // 생성 버튼
  const [sCreateBtnEnabled, setCreateBtnEnabled] = useState(false);

  // tooltip show
  const [sDpNameTootipShow, setDpNameTootipShow] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const schemaNameInputRef = useRef<HTMLInputElement | null>(null);
  const qsRef = useRef<{ cid: number }>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlg = useDlg();
  const hDlgFactory = useDlgFactory();
  // useCheckUserCertTpAndBlock();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { cid?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.cid) {
      DBGMSG(`$$$$QS cid: ${query.cid}`);
      const cid = parseInt(query.cid);
      qsRef.current = { cid };
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    DBGMSG('useEffect');
    // 필수 입력 스키마이름, 디스플레이 이름
    if (sSchemaName.length === 0 || sDisplayName.length === 0) {
      setCreateBtnEnabled(false);
      return () => {};
    }

    // validation 상태만 체크
    // 주의: 이름을 입력하지 않은경우도 ok
    const isValidated = sAttributeList.every((attr) => {
      // 이름과 유형중 둘중 하나라도 설정하지 않으면 해당 속성은 무시됨
      if (attr.validation?.status === false) return false;
      return true;
    });

    if (isValidated) setCreateBtnEnabled(true);
    else setCreateBtnEnabled(false);
    return () => {};
  }, [sSchemaName, sDisplayName, sAttributeList]);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const isDupName = ({ idx, name }: { idx: number; name: string }) => {
    // 중복 체크
    const dupItem = sAttributeList.find((attr, _idx) => {
      // attr.name undefined 이거나 빈문자'' 일경우 제외
      return attr.name !== undefined && attr.name.length > 0 && attr.name === name && idx !== _idx;
    });
    DBGMSG('dupItem');
    DBGMSG(dupItem);
    if (dupItem) return true;
    return false;
  };

  const setErrorMsg = ({ idx, isDup, isReserved }: { idx: number; isDup: boolean; isReserved: boolean }) => {
    if (isDup || isReserved) {
      sAttributeList[idx].validation = {
        status: false,
        emsg: isReserved ? hR.strings.AC_SCHEMA_CREATE_ATTR_NAME_RESERVED : hR.strings.AC_SCHEMA_CREATE_ATTR_NAME_DUPLICATED,
      };
    } else {
      sAttributeList[idx].validation = undefined;
    }
  };

  const handleCreateBtn = async () => {
    if (!qsRef.current) {
      DBGMSGW(`qs ${qsRef.current}`);
      return;
    }
    const resultAttrList: CbtAttributeInfo_i[] = [];

    // 필수 속성 추가
    resultAttrList.push({ name: Constants.NFT_REQ_ATTRNAME_NAME, type: CbtAttributeType_e.TEXT });
    switch (sAssetType) {
      case CbtAssetType_e.IMAGE:
        resultAttrList.push({ name: Constants.NFT_REQ_ATTRNAME_IMAGE, type: CbtAttributeType_e.IMAGE });
        break;
      case CbtAssetType_e.AUDIO:
        resultAttrList.push({ name: Constants.NFT_REQ_ATTRNAME_AUDIO, type: CbtAttributeType_e.AUDIO });
        break;
      case CbtAssetType_e.VIDEO:
        resultAttrList.push({ name: Constants.NFT_REQ_ATTRNAME_VIDEO, type: CbtAttributeType_e.VIDEO });
        break;
    }

    // 추가 속성 추가
    const filterList1 = sAttributeList.filter((attr) => {
      // 둘중 하나만 설정하지 않아도 무시됨
      if (!attr.name || !attr.type) return false;
      // 이름이 비어있으면 무시됨
      if (attr.name.length === 0) return false;
      return true;
    });
    const filterList2 = filterList1.map<CbtAttributeInfo_i>((attr) => {
      return { name: attr.name, type: attr.type } as CbtAttributeInfo_i;
    });

    resultAttrList.push(...filterList2);

    try {
      await hRpc.cobaltRpc.rpcAssetCreatorSchemaAdd({
        cid: qsRef.current.cid,
        name: sSchemaName,
        dp_name: sDisplayName,
        asset_type: sAssetType,
        attributes: resultAttrList,
      });

      hDlgFactory.showAsyncGuideJsx({
        title: hR.strings.DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TITLE,
        text: hR.strings.DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TXT,
        onOk: () => {
          hDlgFactory.closeAsyncGuideJsx();
          hHistory.replace(Nav.brewCollectionRScreenPath({ cid: qsRef.current!.cid }));
        },
      });
    } catch (e) {
      const errInfo = getRpcErrorInfo(e);
      if (errInfo) {
        switch (errInfo.ecode) {
          case ServerECode_e.DUPLICATED_ASSET_SCHEMA_NAME:
            setSchemaNameValidation({ isDupError: true });
            schemaNameInputRef.current?.focus();
            break;
          case ServerECode_e.EXCEED_ASSET_SCHEMA_LIMIT_COUNT:
            hDlgFactory.showSimpleOk({ text: hR.strings.DLG_EXCEED_SCHEMA_LIMIT });
            break;

          // hDlg.act.showDlgOk({
          //   dlgOkInfo: { text: hR.strings.AC_SCHEMA_CREATE_DLG_EXEED_COLLECTION_LIMIT },
          //   onOk: () => {},
          //   onBackdropClick: () => {},
          // });
          // break;
          default:
            DBGMSGW(`${errInfo.ecode}`);
            break;
        }
      }
    }
  };

  /**************************************
   * !! render function
   **************************************/
  const renderRadioBtn = (args: { label: string; onClick: () => void; checked: boolean }) => {
    return (
      <FlexRowDiv
        style={{ justifyContent: 'center', alignItems: 'center', padding: 6, cursor: 'pointer', ...devOutline }}
        onClick={() => {
          args.onClick();
        }}
      >
        <RadioBtn checked={args.checked} />
        <WIDTH size={4} />
        <TextCon text={args.label} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
      </FlexRowDiv>
    );
  };

  const renderOptAttributeList = () => {
    return (
      <>
        {sAttributeList.map((att, idx) => {
          return (
            <FlexColumnDiv key={idx}>
              <SchemaAttributeForm
                //
                idx={idx}
                width={ScreenInfo.isMobile() ? CLAY.FORM_SIZE.WIDTH : ScreenInfo.contentsWidth / 2}
                name={att.name ?? ''}
                type={att.type}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
                onBlur={() => {
                  DBGMSG(`onBlur`);
                  DBGMSG(sAttributeList[idx]);
                  if (!sAttributeList[idx]) return;
                  if (!sAttributeList[idx].name) return;
                  const trimedName = sAttributeList[idx].name!.trimEnd();

                  sAttributeList[idx].name = trimedName;

                  const isDup = isDupName({ idx, name: trimedName }); // 중복체크
                  const isReserved = Utils.svc.isReservedAttName(trimedName); // 필수속성명(reserved)인지 체크
                  setErrorMsg({ idx, isDup, isReserved });

                  setAttributeList([...sAttributeList]);
                }}
                onChange={(e) => {
                  if (e.target.value.length > 20) {
                    return;
                  }

                  const regex = Constants.REGEXP_IS_ALPHA_NUM_SPACE;

                  if (!regex.test(e.target.value)) {
                    DBGMSG('reges false: ' + e.target.value);
                    return;
                  }
                  DBGMSG('reges true: ' + e.target.value);

                  const isDup = isDupName({ idx, name: e.target.value }); // 중복 체크
                  const isReserved = Utils.svc.isReservedAttName(e.target.value); // 필수속성명(reserved)인지 체크
                  setErrorMsg({ idx, isDup, isReserved });

                  sAttributeList[idx].name = e.target.value;
                  setAttributeList([...sAttributeList]);
                  DBGMSG(sAttributeList);
                }}
                onKeyDown={(e) => {}}
                isDropDownOpen={sCurOpenDropDownIdx === idx}
                onDropDownOpenClick={() => {
                  setCurOpenDropDownIdx(idx);
                }}
                onTypeSelect={(selectedType) => {
                  DBGMSG(`selectedType:${selectedType}`);
                  sAttributeList[idx].type = selectedType;
                  setAttributeList([...sAttributeList]);
                  setCurOpenDropDownIdx(undefined);
                }}
                onDeleteClick={() => {
                  DBGMSG([...sAttributeList.slice(0, idx)]);
                  DBGMSG([...sAttributeList.slice(idx + 1)]);
                  setAttributeList([...sAttributeList.slice(0, idx), ...sAttributeList.slice(idx + 1)]);
                }}
                onOutsideClick={() => {
                  DBGMSG(`onOutsideClick`);
                  setCurOpenDropDownIdx(undefined);
                }}
              />
              {/* validation 체크 실패 메세지 */}
              {att.validation && att.validation.status === false && (
                <TextCon
                  text={att.validation.emsg}
                  size={12}
                  color={R.colors.pastelRed}
                  containerStyle={{
                    paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
                    marginBottom: 24,
                  }}
                />
              )}
            </FlexColumnDiv>
          );
        })}
      </>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          ...devOutline,
        }}
      >
        {/* Back 버튼 */}
        <BackBtn />

        {/* 타이틀 */}
        <Title title={hR.strings.AC_SCHEMA_CREATE_TITLE} />

        <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

        <HEIGHT size={30} />
        <CommonColumnBox style={{ width: '100%', alignItems: 'flex-start' }}>
          <HEIGHT size={30} />
          <FlexRowDiv style={{ ...devOutline }}>
            <FlexColumnDiv
              style={{
                width: ScreenInfo.contentsWidth / 2,
                paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
                paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
                // ...devOutlineF,
              }}
            >
              {/* 스키마 이름 입력 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME} size={14} color={R.colors.black} isBold />
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME_GUIDE} size={14} color={R.colors.black} />
                <WIDTH size={3} />
                <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />
                {/* <WIDTH size={5} /> */}
                {/* <Img src={[R.images.common_btn_tooltip_normal3x, 20, 20]} /> */}
              </FlexRowDiv>
              <HEIGHT size={14} />
              <SRoundInputText
                fontSize={CLAY.FORM_FONT_SIZE}
                width={CLAY.FORM_SIZE.WIDTH}
                height={CLAY.FORM_SIZE.HEIGHT}
                value={sSchemaName}
                placeHolder={hR.strings.AC_SCHEMA_CREATE_CANNOT_EDIT}
                getRef={(ele) => {
                  schemaNameInputRef.current = ele;
                }}
                onBlur={() => {
                  setSchemaName((prev) => prev.trimEnd());
                }}
                onChange={(e) => {
                  if (e.target.value.length > 20) {
                    return;
                  }

                  const regex = Constants.REGEXP_IS_ALPHA_NUM_SPACE;

                  if (regex.test(e.target.value)) {
                    setSchemaName(e.target.value);
                    setSchemaNameValidation({ isDupError: false });
                  }
                }}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
              />
              {sSchemaNameValidation.isDupError && (
                <>
                  <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                  <TextCon
                    text={hR.strings.AC_SCHEMA_CREATE_NAME_DUPLICATED}
                    size={CLAY.FORM_CHK_MSG_FONT_SIZE}
                    color={R.colors.pastelRed}
                    containerStyle={{ alignSelf: 'flex-start' }}
                  />
                </>
              )}
              <HEIGHT size={24} />

              {/* 에셋 유형 선택 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_TYPE} size={14} color={R.colors.black} isBold />
                <WIDTH size={3} />
                {/* <TextCon text={'*'} size={14} color={R.colors.purpleishPink} /> */}
                {/* <WIDTH size={5} /> */}
                {/* <Img src={[R.images.common_btn_tooltip_normal3x, 20, 20]} /> */}
              </FlexRowDiv>
              <HEIGHT size={14} />
              <FlexRowDiv
                style={{
                  alignItems: 'center',
                  height: 68,
                  backgroundColor: R.colors.bgGrey,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: R.colors.line,
                  borderRadius: 15,
                  // ...devOutline,
                  // paddingLeft: 14,
                  // paddingRight: 14,
                }}
              >
                {/* 이미지 레디오 버튼 */}
                {renderRadioBtn({
                  label: hR.strings.AC_SCHEMA_CREATE_TYPE_IMAGE,
                  onClick: () => {
                    setAssetType(CbtAssetType_e.IMAGE);
                    setCurOpenDropDownIdx(undefined);
                  },
                  checked: sAssetType === CbtAssetType_e.IMAGE,
                })}

                <WIDTH size={90} />

                {/* 비디오 레디오 버튼 */}
                {renderRadioBtn({
                  label: hR.strings.AC_SCHEMA_CREATE_TYPE_VIDEO,
                  onClick: () => {
                    setAssetType(CbtAssetType_e.VIDEO);
                    setCurOpenDropDownIdx(undefined);
                  },
                  checked: sAssetType === CbtAssetType_e.VIDEO,
                })}

                <WIDTH size={90} />

                {/* 오디오 레디오 버튼 */}
                {renderRadioBtn({
                  label: hR.strings.AC_SCHEMA_CREATE_TYPE_AUDIO,
                  onClick: () => {
                    setAssetType(CbtAssetType_e.AUDIO);
                    setCurOpenDropDownIdx(undefined);
                  },
                  checked: sAssetType === CbtAssetType_e.AUDIO,
                })}
              </FlexRowDiv>
              <HEIGHT size={24} />
            </FlexColumnDiv>

            <FlexColumnDiv
              style={{
                width: ScreenInfo.contentsWidth / 2,
                paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
                paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
                // ...devOutlineF,
              }}
            >
              {/* 디스플레이 이름 */}
              {/* 툴팁 */}
              {sDpNameTootipShow && (
                <TooltipBoxContainer
                  offsetLeftIfNotMobile={205}
                  tooltipJSX={
                    <>
                      <TextCon size={CLAY.TOOLTIP_FONT_SIZE} color={R.colors.black} text={hR.strings.AC_SCHEMA_CREATE_TOOLTIP_DISPNAME} />
                    </>
                  }
                />
              )}
              {/* 디스플레이 이름 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME} size={14} color={R.colors.black} isBold />
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME_GUIDE} size={14} color={R.colors.black} />
                <WIDTH size={3} />
                <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />
                <WIDTH size={5} />
                <ImgBtn
                  src={[R.images.common_btn_tooltip_normal3x, CLAY.TOOLTIP_IC_SIZE, CLAY.TOOLTIP_IC_SIZE]}
                  onConMouseEnter={(e) => {
                    DBGMSG(`onConMouseEnter`);
                    setDpNameTootipShow(true);
                  }}
                  onConMouseLeave={(e) => {
                    DBGMSG(`onConMouseLeave`);
                    setDpNameTootipShow(false);
                  }}
                />
              </FlexRowDiv>

              <HEIGHT size={14} />
              <SRoundInputText
                fontSize={CLAY.FORM_FONT_SIZE}
                width={CLAY.FORM_SIZE.WIDTH}
                height={CLAY.FORM_SIZE.HEIGHT}
                value={sDisplayName}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
                onBlur={() => {
                  setDisplayName((prev) => prev.trimEnd());
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 20) {
                    setDisplayName(e.target.value);
                  }
                }}
              />
              <HEIGHT size={24} />
            </FlexColumnDiv>
          </FlexRowDiv>

          {/* 속성 이름과 유형 */}
          <FlexRowDiv
            style={{
              width: ScreenInfo.contentsWidth / 2,
              paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
              paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
              // ...devOutlineF,
            }}
          >
            <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR} size={14} color={R.colors.black} isBold />
            <TextCon
              text={`(${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_1} ${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_2})`}
              size={14}
              color={R.colors.black}
            />
            {/* <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_1} size={14} color={R.colors.black} /> */}
            {/* <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_2} size={14} color={R.colors.black} /> */}
            {/* <TextCon text={')'} size={14} color={R.colors.black} /> */}
          </FlexRowDiv>

          <HEIGHT size={14} />

          <FlexRowDiv
            style={{
              // ...devOutlineF,
              // alignSelf: 'stretch',
              flexWrap: 'wrap',
              width: 1194,
              // marginLeft: -1,
              // marginRight: -1,
            }}
          >
            {/* 에셋 유형에 따른 필수 속성 */}
            <SchemaAttribute
              width={ScreenInfo.contentsWidth / 2}
              name={Constants.NFT_REQ_ATTRNAME_NAME}
              value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_TEXT}
            />
            {sAssetType === CbtAssetType_e.VIDEO && (
              <SchemaAttribute
                width={ScreenInfo.contentsWidth / 2}
                name={Constants.NFT_REQ_ATTRNAME_VIDEO}
                value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO}
              />
            )}
            {sAssetType === CbtAssetType_e.AUDIO && (
              <SchemaAttribute
                width={ScreenInfo.contentsWidth / 2}
                name={Constants.NFT_REQ_ATTRNAME_AUDIO}
                value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO}
              />
            )}
            <SchemaAttribute
              width={ScreenInfo.contentsWidth / 2}
              name={Constants.NFT_REQ_ATTRNAME_IMAGE}
              value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE}
            />

            {/* 추가 속성 */}
            {renderOptAttributeList()}

            {/* 속성 추가 버튼 */}
            <SchemaAttributeAdd
              width={ScreenInfo.contentsWidth / 2}
              onAdd={() => {
                DBGMSG('onAdd');
                setAttributeList([...sAttributeList, { name: undefined, type: undefined, validation: undefined }]);
              }}
            />
          </FlexRowDiv>

          <HEIGHT size={30} />

          {/* 스키마 생성하기 버튼 */}
          <RoundTxtBtn
            width={294}
            height={48}
            fontSize={14}
            text={hR.strings.AC_SCHEMA_CREATE_BTN}
            enable={sCreateBtnEnabled}
            containerStyle={{ alignSelf: 'center' }}
            onClick={() => {
              handleCreateBtn();
            }}
          />
          <HEIGHT size={40} />
        </CommonColumnBox>
        <HEIGHT size={206} />
      </FlexColumnDiv>
    );
  } else {
    return (
      <MobileScalableFlexColumn
        // key를 변경해 다시 마운팅되도록 함
        // 추가 속성 갯수, 에셋 타입이 변경되면 높이가 변하기때문에 다시 높이를 계산해야함
        key={`${sAttributeList.length}-${sAssetType}`}
      >
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: ScreenInfo.contentsWidth,
            alignItems: 'flex-start',
            paddingTop: 20,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          {/* Back 버튼 */}
          <BackBtn />

          {/* 타이틀 */}
          <Title title={hR.strings.AC_SCHEMA_CREATE_TITLE} />

          <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

          {/* 박스 */}
          <CommonColumnBox style={{ width: MY_SCHEMA_C_SCREEN_LAYOUT.boxWidth, alignItems: 'flex-start' }}>
            <HEIGHT size={34} />
            {/* 스키마 이름 입력 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexColumnDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                </FlexRowDiv>
              </FlexColumnDiv>
              <HEIGHT size={CLAY.FORM_INPUT_TOP_MARGIN} />
              <SRoundInputText
                fontSize={CLAY.FORM_FONT_SIZE}
                width={CLAY.FORM_SIZE.WIDTH}
                height={CLAY.FORM_SIZE.HEIGHT}
                value={sSchemaName}
                getRef={(ele) => {
                  schemaNameInputRef.current = ele;
                }}
                placeHolder={hR.strings.AC_SCHEMA_CREATE_CANNOT_EDIT}
                onBlur={() => {
                  setSchemaName((prev) => prev.trimEnd());
                }}
                onChange={(e) => {
                  if (e.target.value.length > 20) {
                    return;
                  }

                  const regex = Constants.REGEXP_IS_ALPHA_NUM_SPACE;

                  if (regex.test(e.target.value)) {
                    setSchemaName(e.target.value);
                    setSchemaNameValidation({ isDupError: false });
                  }
                }}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
              />
              {sSchemaNameValidation.isDupError && (
                <>
                  <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                  <TextCon
                    text={hR.strings.AC_SCHEMA_CREATE_NAME_DUPLICATED}
                    size={CLAY.FORM_CHK_MSG_FONT_SIZE}
                    color={R.colors.pastelRed}
                    containerStyle={{ alignSelf: 'flex-start' }}
                  />
                </>
              )}
            </FlexColumnDiv>
            <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />
            {/* 툴팁 */}
            {sDpNameTootipShow && (
              <TooltipBoxContainer
                offsetLeftIfNotMobile={105}
                tooltipJSX={
                  <>
                    <TextCon size={CLAY.TOOLTIP_FONT_SIZE} color={R.colors.black} text={hR.strings.AC_SCHEMA_CREATE_TOOLTIP_DISPNAME} />
                  </>
                }
              />
            )}
            {/* 디스플레이 이름 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                <WIDTH size={3} />
                <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                <WIDTH size={5} />
                <ImgBtn
                  src={[R.images.common_btn_tooltip_normal3x, CLAY.TOOLTIP_IC_SIZE, CLAY.TOOLTIP_IC_SIZE]}
                  onConMouseEnter={(e) => {
                    DBGMSG(`onConMouseEnter`);
                    setDpNameTootipShow(true);
                  }}
                  onConMouseLeave={(e) => {
                    DBGMSG(`onConMouseLeave`);
                    setDpNameTootipShow(false);
                  }}
                />
              </FlexRowDiv>

              <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
              <SRoundInputText
                fontSize={CLAY.FORM_FONT_SIZE}
                width={CLAY.FORM_SIZE.WIDTH}
                height={CLAY.FORM_SIZE.HEIGHT}
                value={sDisplayName}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 20) {
                    setDisplayName(e.target.value);
                  }
                }}
              />
            </FlexColumnDiv>
            <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />
            {/* 에셋 유형 선택 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_TYPE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <WIDTH size={3} />
              </FlexRowDiv>
              <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
              <FlexColumnDiv
                style={{
                  width: 256,
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddingLeft: 6,
                  height: 112,
                  backgroundColor: R.colors.bgGrey,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: R.colors.line,
                  borderRadius: 15,
                  // ...devOutline,
                  // paddingLeft: 14,
                  // paddingRight: 14,
                }}
              >
                {/* 이미지 레디오 버튼 */}
                {renderRadioBtn({
                  label: hR.strings.AC_SCHEMA_CREATE_TYPE_IMAGE,
                  onClick: () => {
                    setAssetType(CbtAssetType_e.IMAGE);
                    setCurOpenDropDownIdx(undefined);
                  },
                  checked: sAssetType === CbtAssetType_e.IMAGE,
                })}

                <WIDTH size={90} />

                {/* 비디오 레디오 버튼 */}
                {renderRadioBtn({
                  label: hR.strings.AC_SCHEMA_CREATE_TYPE_VIDEO,
                  onClick: () => {
                    setAssetType(CbtAssetType_e.VIDEO);
                    setCurOpenDropDownIdx(undefined);
                  },
                  checked: sAssetType === CbtAssetType_e.VIDEO,
                })}

                <WIDTH size={90} />

                {/* 오디오 레디오 버튼 */}
                {renderRadioBtn({
                  label: hR.strings.AC_SCHEMA_CREATE_TYPE_AUDIO,
                  onClick: () => {
                    setAssetType(CbtAssetType_e.AUDIO);
                    setCurOpenDropDownIdx(undefined);
                  },
                  checked: sAssetType === CbtAssetType_e.AUDIO,
                })}
              </FlexColumnDiv>
            </FlexColumnDiv>
            <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />
            {/* 속성 이름과 유형 */}
            <FlexRowDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexColumnDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <FlexRowDiv style={{ flexWrap: 'wrap' }}>
                  <TextCon
                    text={`(${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_1} ${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_2})`}
                    size={CLAY.FORM_FONT_SIZE}
                    color={R.colors.black}
                  />
                  {/* <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_2} size={CL.formFontSize} color={R.colors.black} /> */}
                  {/* <TextCon text={')'} size={CL.formFontSize} color={R.colors.black} /> */}
                </FlexRowDiv>
              </FlexColumnDiv>
            </FlexRowDiv>
            <HEIGHT size={CLAY.FORM_INPUT_TOP_MARGIN} />
            {/* 에셋 유형에 따른 필수 속성 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <SchemaAttribute
                width={CLAY.FORM_SIZE.WIDTH}
                name={Constants.NFT_REQ_ATTRNAME_NAME}
                value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_TEXT}
              />
              {sAssetType === CbtAssetType_e.VIDEO && (
                <SchemaAttribute
                  width={CLAY.FORM_SIZE.WIDTH}
                  name={Constants.NFT_REQ_ATTRNAME_VIDEO}
                  value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO}
                />
              )}
              {sAssetType === CbtAssetType_e.AUDIO && (
                <SchemaAttribute
                  width={CLAY.FORM_SIZE.WIDTH}
                  name={Constants.NFT_REQ_ATTRNAME_AUDIO}
                  value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO}
                />
              )}
              <SchemaAttribute
                width={CLAY.FORM_SIZE.WIDTH}
                name={Constants.NFT_REQ_ATTRNAME_IMAGE}
                value={hR.strings.AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE}
              />
            </FlexColumnDiv>
            {/* 추가 속성 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>{renderOptAttributeList()}</FlexColumnDiv>
            {/* 속성 추가 버튼 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <SchemaAttributeAdd
                width={CLAY.FORM_SIZE.WIDTH}
                onAdd={() => {
                  DBGMSG('onAdd');
                  setAttributeList([...sAttributeList, { name: undefined, type: undefined, validation: undefined }]);
                }}
              />
            </FlexColumnDiv>
            {/* 스키마 생성하기 버튼 */}
            <HEIGHT size={34} />
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <RoundTxtBtn
                width={CLAY.TEXT_BTN_SIZE_IN_BOX.WIDTH}
                height={CLAY.TEXT_BTN_SIZE_IN_BOX.HEIGHT}
                fontSize={CLAY.TEXT_BTN_FONT_SIZE_IN_BOX}
                text={hR.strings.AC_SCHEMA_CREATE_BTN}
                enable={sCreateBtnEnabled}
                containerStyle={{}}
                onClick={() => {
                  handleCreateBtn();
                }}
              />
            </FlexColumnDiv>
            <HEIGHT size={34} />
          </CommonColumnBox>
        </FlexColumnDiv>
        <HEIGHT size={60} />
        <CommonFooter />
      </MobileScalableFlexColumn>
    );
  }
}
