import { useState } from 'react';

export const useForceRender = () => {
  const [sNum, setNum] = useState(0);

  return {
    sNum,
    forceRender: () => {
      setNum((prev) => ++prev);
    },
  };
};
