/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useMemo } from 'react';
import { useWindowSize } from 'react-use';

const SCREEN_MAX_WIDTH = 600; // 최대 너비
const SCREEN_MIN_WIDTH = 320; // 최소 너비

export function useMobileSizeHelper() {
  const windowSize = useWindowSize();

  const screenWidth = useMemo(() => {
    if (windowSize.width >= SCREEN_MAX_WIDTH) {
      return SCREEN_MAX_WIDTH;
    }
    if (windowSize.width <= SCREEN_MIN_WIDTH) {
      return SCREEN_MIN_WIDTH;
    }
    return windowSize.width;
  }, [windowSize.width]);

  return {
    screenWidth,
    screenHeight: windowSize.height,
    SCREEN_MAX_WIDTH: SCREEN_MAX_WIDTH,
    SCREEN_MIN_WIDTH: SCREEN_MIN_WIDTH,
    windowSize,
  };
}
