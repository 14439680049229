/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { Action, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { isBeta, isProd } from 'src/conf/AppConf';
import { CommonRx_i } from 'src/model/rpcModel';
import rootReducer, { RootState } from './modules';

// thunk 설정
export type PADThunkAction<ReturnType = CommonRx_i> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  Action<string>
>;

export type PADThunkDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

const middlewares = [thunk];

const enhancer =
  isProd() || isBeta() ? applyMiddleware(...middlewares) : composeWithDevTools(applyMiddleware(...middlewares));
export const store = createStore(rootReducer, enhancer);
