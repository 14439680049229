/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useRef, useState } from 'react';
import { devOutlineF, FlexDiv, HEIGHT } from 'src/ui/base_component/etc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { Utils } from 'src/util/utils';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { Overlay } from 'react-bootstrap';
import TxtBtnWithMouseOverAni from 'src/ui/base_component/TxtBtnWithMouseOverAni';
import {
  ETC_DROPDOWN_INPUT_WIDTH,
  ETC_DROPDOWN_PADDING_V,
  ETC_DROPDOWN_MENU_FONTSIZE,
  ETC_DROPDOWN_MENU_HEIGHT,
  ETC_DROPDOWN_MENU_PADIING_LEFT,
} from 'src/ui/common_component/MarketAssetCard';
import { CommonColumnBox } from 'src/ui/layout_constant';
import { useKaikas } from 'src/hooks/useKaikas';
import TextCon from 'src/ui/base_component/TextCon';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  address: string;
  onClick?: () => void;
};

export function KaikasSelectedAddress({ address, onClick }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseOver, setMouseOver] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const reportBtnRef = useRef(null);
  const [sReportMenuOpen, setReportMenuOpen] = useState(false);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hKaikas = useKaikas();

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <>
      <FlexDiv ref={reportBtnRef}>
        <RoundTxtBtnAdv
          label={Utils.string.shortKlatynAddress(address)}
          width={140}
          height={40}
          fontColor={R.colors.purpleishBlue}
          fontSize={12}
          bgColor={hKaikas.getNetworkVersion() === 'MAINNET' ? 'white' : 'yellow'}
          borderColor={sMouseOver ? R.colors.purpleishBlue : R.colors.line}
          onClick={() => {
            onClick && onClick();
          }}
          onMouseEnter={() => {
            setMouseOver(true);
            if (hKaikas.getNetworkVersion() !== 'MAINNET') setReportMenuOpen(true);
          }}
          onMouseLeave={() => {
            setMouseOver(false);
            if (hKaikas.getNetworkVersion() !== 'MAINNET') setReportMenuOpen(false);
          }}
          titleTooltip={Utils.string.brewKlaytnUserAccountUrl({ accountAddress: address, isMainnet: hKaikas.getNetworkVersion() === 'MAINNET' })}
        />
      </FlexDiv>
      <Overlay
        target={reportBtnRef.current}
        show={sReportMenuOpen}
        placement="bottom"
        // placement="auto"
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              zIndex: 100000,
            }}
          >
            <CommonColumnBox
              style={{
                // position: 'absolute',
                // top: BUY_BTN_HEIGHT,
                // left: BUY_BTN_WIDTH + BUY_BTN_MARGIN_RIGHT + LIKE_BTN_WIDTH,
                // bottom: 0,
                // right: 0,
                // width: 140,
                // height: DROPDOWN_INPUT_HEIGHT,
                // ...devOutlineFG,
                padding: 10,
              }}
            >
              <TextCon text={'BAOBAB 네트워크 사용중입니다.'} size={14} color={R.colors.pastelRed} isBold />
            </CommonColumnBox>
          </div>
        )}
      </Overlay>
    </>
  );
}
