import React, { useEffect, useState } from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { ReqImageSrc_i, ReqAudioSrc_i, ReqVideoSrc_i } from 'src/model/model';
import { CbtAssetType_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { SRoundInputNumber } from 'src/ui/common_component/SRoundInputNumber';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { CLAY, CommonColumnBox } from 'src/ui/layout_constant';
import { DBGMSG, Utils } from 'src/util/utils';

export type MintMoreJsxProps = {
  defaultOwner: string;
  totolCnt: number;
  assetType: CbtAssetType_e;
  mainImg: string;
  onClose: () => void;
  onMintClick: (arg: { owner: string; mintCnt: number }) => void;
};

// DESKTOP
const DT_DLG_WIDTH = 686;
const DT_HPADDING = 25;
const DT_BODY_WIDTH = DT_DLG_WIDTH - DT_HPADDING * 2;
const DT_PADDING_TOP = 30;
const DT_PADDING_BOTTOM = 40 - 15;
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;
const DT_TITLE_HEIGHT = 33;
const DT_TITLE_FOTNSIZE = 22;
const DT_TITLE_MARGIN_BOTTOM = 40 - 15;
const DT_ASSET_DISPLAY_BORDER_BOX = 276;
const DT_ASSET_DISPLAY_BORDER_BOX_MARGIN = 15;
const DT_ASSET_DISPLAY_SIZE = 236;
const DT_LEFT_CONTAINER_SIZE = DT_ASSET_DISPLAY_BORDER_BOX + DT_ASSET_DISPLAY_BORDER_BOX_MARGIN * 2;
const DT_FORM_FONT_SIZE = 14;
const DT_FORM_INPUT_WIDTH = 300;
const DT_FORM_INPUT_HEIGHT = 44;
const DT_FORM_INPUT_MARGIN_TOP = 14;
const DT_FORM_INPUT_HMARGIN = 15;
const DT_FORM_INPUT_BETWEEN = 30;
const DT_FORM_INPUT_PADDING_H = 14;
const DT_RIGHT_CONTAINER_WIDTH = DT_FORM_INPUT_WIDTH + DT_FORM_INPUT_HMARGIN * 2;
const DT_RIGHT_CONTAINER_HEIGHT = undefined;
const DT_BTN_WIDTH = 300;
const DT_BTN_HEIGHT = 48;
const DT_BTN_FOTNSIZE = 14;
const DT_BTN_MARGIN_TOP = 40;
const DT_TOP_FLEX = 1;
const DT_BOTTOM_FLEX = 3;
const DT_CONTAINER_BETWEEN = 0;

// MOBILE
const MB_DLG_WIDTH = 280;
const MB_HPADDING = 12;
const MB_BODY_WIDTH = MB_DLG_WIDTH - MB_HPADDING * 2;
const MB_PADDING_TOP = 16;
const MB_PADDING_BOTTOM = 20;
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;
const MB_TITLE_HEIGHT = 20;
const MB_TITLE_FOTNSIZE = 14;
const MB_TITLE_MARGIN_BOTTOM = 16;
const MB_ASSET_DISPLAY_BORDER_BOX = 158;
const MB_ASSET_DISPLAY_BORDER_BOX_MARGIN = 0;
const MB_ASSET_DISPLAY_SIZE = 134;
const MB_LEFT_CONTAINER_SIZE = 158;
const MB_FORM_FONT_SIZE = 12;
const MB_FORM_INPUT_WIDTH = 256;
const MB_FORM_INPUT_HEIGHT = 38;
const MB_FORM_INPUT_MARGIN_TOP = 12;
const MB_FORM_INPUT_HMARGIN = 0;
const MB_FORM_INPUT_BETWEEN = 20;
const MB_FORM_INPUT_PADDING_H = 12;
const MB_RIGHT_CONTAINER_WIDTH = MB_FORM_INPUT_WIDTH;
const MB_RIGHT_CONTAINER_HEIGHT = undefined;
const MB_BTN_WIDTH = 256;
const MB_BTN_HEIGHT = 38;
const MB_BTN_FOTNSIZE = 12;
const MB_BTN_MARGIN_TOP = 20;
const MB_TOP_FLEX = 1;
const MB_BOTTOM_FLEX = 1;
const MB_CONTAINER_BETWEEN = 16;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const HPADDING = ScreenInfo.isMobile() ? MB_HPADDING : DT_HPADDING;
const BODY_WIDTH = ScreenInfo.isMobile() ? MB_BODY_WIDTH : DT_BODY_WIDTH;
const PADDING_TOP = ScreenInfo.isMobile() ? MB_PADDING_TOP : DT_PADDING_TOP;
const PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
const CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
const CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;
const TITLE_HEIGHT = ScreenInfo.isMobile() ? MB_TITLE_HEIGHT : DT_TITLE_HEIGHT;
const TITLE_FOTNSIZE = ScreenInfo.isMobile() ? MB_TITLE_FOTNSIZE : DT_TITLE_FOTNSIZE;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const ASSET_DISPLAY_BORDER_BOX = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX : DT_ASSET_DISPLAY_BORDER_BOX;
const ASSET_DISPLAY_BORDER_BOX_MARGIN = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_BORDER_BOX_MARGIN : DT_ASSET_DISPLAY_BORDER_BOX_MARGIN;
const ASSET_DISPLAY_SIZE = ScreenInfo.isMobile() ? MB_ASSET_DISPLAY_SIZE : DT_ASSET_DISPLAY_SIZE;
const LEFT_CONTAINER_SIZE = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_SIZE : DT_LEFT_CONTAINER_SIZE;
const FORM_FONT_SIZE = ScreenInfo.isMobile() ? MB_FORM_FONT_SIZE : DT_FORM_FONT_SIZE;
const FORM_INPUT_WIDTH = ScreenInfo.isMobile() ? MB_FORM_INPUT_WIDTH : DT_FORM_INPUT_WIDTH;
const FORM_INPUT_HEIGHT = ScreenInfo.isMobile() ? MB_FORM_INPUT_HEIGHT : DT_FORM_INPUT_HEIGHT;
const FORM_INPUT_MARGIN_TOP = ScreenInfo.isMobile() ? MB_FORM_INPUT_MARGIN_TOP : DT_FORM_INPUT_MARGIN_TOP;
const FORM_INPUT_HMARGIN = ScreenInfo.isMobile() ? MB_FORM_INPUT_HMARGIN : DT_FORM_INPUT_HMARGIN;
const FORM_INPUT_BETWEEN = ScreenInfo.isMobile() ? MB_FORM_INPUT_BETWEEN : DT_FORM_INPUT_BETWEEN;
const FORM_INPUT_OVERLIMIT_FONT_SIZE = ScreenInfo.isMobile() ? MB_FORM_INPUT_BETWEEN : DT_FORM_INPUT_BETWEEN;
const FORM_INPUT_PADDING_H = ScreenInfo.isMobile() ? MB_FORM_INPUT_PADDING_H : DT_FORM_INPUT_PADDING_H;
const RIGHT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_WIDTH : DT_RIGHT_CONTAINER_WIDTH;
const RIGHT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_HEIGHT : DT_RIGHT_CONTAINER_HEIGHT;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_FOTNSIZE = ScreenInfo.isMobile() ? MB_BTN_FOTNSIZE : DT_BTN_FOTNSIZE;
const BTN_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BTN_MARGIN_TOP : DT_BTN_MARGIN_TOP;
const TOP_FLEX = ScreenInfo.isMobile() ? MB_TOP_FLEX : DT_TOP_FLEX;
const BOTTOM_FLEX = ScreenInfo.isMobile() ? MB_BOTTOM_FLEX : DT_BOTTOM_FLEX;
const CONTAINER_BETWEEN = ScreenInfo.isMobile() ? MB_CONTAINER_BETWEEN : DT_CONTAINER_BETWEEN;

export const MintMoreJsx = (props: MintMoreJsxProps) => {
  /**************************************
   * !! state
   **************************************/
  const [sMintCnt, setMintCnt] = useState<string>('');
  const [sMintBtnEnabled, setMintBtnEnabled] = useState(false);
  const [sOwner, setOwner] = useState<string>(''); // 에셋 소유자
  const [sIsOverLimit, setIsOverLimit] = useState(false);

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    setIsOverLimit(false);
    if (sMintCnt.length > 0) {
      if (parseInt(sMintCnt) <= 10 && parseInt(sMintCnt) > 0) {
        setMintBtnEnabled(true);
      } else {
        setIsOverLimit(true);
        setMintBtnEnabled(false);
      }
    } else {
      setMintBtnEnabled(false);
    }
    return () => {};
  }, [sMintCnt]);

  useEffect(() => {
    setOwner(props.defaultOwner);
    return () => {};
  }, []);

  /**************************************
   * !! hook
   **************************************/
  const hR = useLocalSettings();

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        alignItems: 'center',
        paddingLeft: HPADDING,
        paddingRight: HPADDING,
        paddingTop: PADDING_TOP,
        paddingBottom: PADDING_BOTTOM,
        zIndex: 1000000,
      }}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      {/* close 버튼 */}
      <ImgBtn
        src={[R.images.common_btn_close3x, CLOSE_BTN_SIZE, CLOSE_BTN_SIZE]}
        containerStyle={{ position: 'absolute', top: 0, right: 0, padding: CLOSE_BTN_PADDING }}
        onClick={() => {
          props.onClose();
        }}
      />

      {/* 타이틀 */}
      <TextCon
        text={hR.strings.AC_ASSET_GROUP_DLG_TITLE}
        size={TITLE_FOTNSIZE}
        color={R.colors.black}
        containerStyle={{ height: TITLE_HEIGHT }}
        isBold
      />

      <HEIGHT size={TITLE_MARGIN_BOTTOM} />

      <FlexRowDiv
        style={{
          alignSelf: 'flex-start',
          flexWrap: 'wrap',
          width: BODY_WIDTH,
          alignItems: 'flex-start',
          justifyContent: 'center',

          // ...devOutlineF,
        }}
      >
        <FlexDiv
          style={{
            width: LEFT_CONTAINER_SIZE,
            height: LEFT_CONTAINER_SIZE,
            //
            // ...devOutlineF,
            marginBottom: CONTAINER_BETWEEN,
          }}
        >
          <CommonColumnBox
            style={{
              width: ASSET_DISPLAY_BORDER_BOX,
              height: ASSET_DISPLAY_BORDER_BOX,
              justifyContent: 'center',
              alignItems: 'center',
              margin: ASSET_DISPLAY_BORDER_BOX_MARGIN,
              marginTop: ScreenInfo.isMobile() ? ASSET_DISPLAY_BORDER_BOX_MARGIN : undefined,
              // ...devOutlineF,
            }}
          >
            <AssetDisplayThumbReqattr
              playerSrc={Utils.svc.brewAssetDisplayThumbReqattr({ assetType: props.assetType, img: props.mainImg })}
              width={ASSET_DISPLAY_SIZE}
              height={ASSET_DISPLAY_SIZE}
              whereToUse={WhereToUse_e.ForDlg}
            />
          </CommonColumnBox>
        </FlexDiv>

        <FlexDiv
          style={{
            width: RIGHT_CONTAINER_WIDTH,
            height: RIGHT_CONTAINER_HEIGHT,
            //
            // ...devOutlineF,
          }}
        >
          <FlexColumnDiv style={{ marginLeft: FORM_INPUT_HMARGIN, marginRight: FORM_INPUT_HMARGIN }}>
            {/* 에셋 소유자 */}
            <FlexRowDiv>
              <TextCon text={hR.strings.AC_ASSET_GROUP_DLG_MINT_OWNER} size={FORM_FONT_SIZE} color={R.colors.black} isBold />
              <WIDTH size={5} />
              <WIDTH size={3} />
              <TextCon text={'*'} size={FORM_FONT_SIZE} color={R.colors.purpleishPink} />
            </FlexRowDiv>

            <HEIGHT size={FORM_INPUT_MARGIN_TOP} />

            <SRoundInputText
              value={sOwner}
              fontSize={FORM_FONT_SIZE}
              width={FORM_INPUT_WIDTH}
              height={FORM_INPUT_HEIGHT}
              // value={sCollectionName}
              onChange={(e) => {
                setOwner(e.target.value);
              }}
            />

            <HEIGHT size={FORM_INPUT_BETWEEN} />
            {/* 추가 발행 매수 */}
            <FlexRowDiv>
              <TextCon text={hR.strings.AC_ASSET_GROUP_DLG_MINT_CNT} size={FORM_FONT_SIZE} color={R.colors.black} isBold />
              <WIDTH size={5} />
              <TextCon text={hR.strings.AC_ASSET_GROUP_DLG_MINT_CNT_GUIDE} size={FORM_FONT_SIZE} color={R.colors.black} />
            </FlexRowDiv>

            <HEIGHT size={FORM_INPUT_MARGIN_TOP} />

            <SRoundInputNumber
              value={sMintCnt}
              fontSize={FORM_FONT_SIZE}
              width={FORM_INPUT_WIDTH}
              height={FORM_INPUT_HEIGHT}
              paddingH={FORM_INPUT_PADDING_H}
              // value={sCollectionName}
              placeHolder={'0'}
              onChange={(e) => {
                const regex: RegExp = Constants.REGEXP_IS_VALID_INT;
                const regexTestRet = regex.test(e.target.value);
                DBGMSG(`$$$REGEX ${regex}: ${e.target.value} ${regexTestRet}`);
                if (!regexTestRet) {
                  DBGMSG(`$$$REGEX regexTestRet: ${regexTestRet}`);
                  return;
                }

                setMintCnt(e.target.value);
              }}
            />

            {sIsOverLimit ? (
              <>
                <TextCon
                  text={hR.strings.AC_ASSET_CREATE_TOOLTIP_MINT}
                  size={CLAY.VALIDATION_MSG_FONT_SIZE}
                  color={R.colors.pastelRed}
                  containerStyle={{ height: FORM_INPUT_BETWEEN, alignSelf: 'flex-start' }}
                />
              </>
            ) : (
              <HEIGHT size={FORM_INPUT_BETWEEN} />
            )}

            {/* 총 발행 매수 */}
            <FlexRowDiv>
              <TextCon text={hR.strings.AC_ASSET_GROUP_DLG_MINT_TOTAL} size={FORM_FONT_SIZE} color={R.colors.black} isBold />
            </FlexRowDiv>
            <HEIGHT size={FORM_INPUT_MARGIN_TOP} />
            <FlexColumnDiv
              style={{
                width: FORM_INPUT_WIDTH,
                height: FORM_INPUT_HEIGHT,
                borderRadius: 15,
                backgroundColor: R.colors.lightGrey,
                justifyContent: 'center',
                paddingLeft: 14,
              }}
            >
              <TextCon
                text={`${props.totolCnt + (sIsOverLimit || sMintCnt.length === 0 ? 0 : parseInt(sMintCnt) ?? 0)}`}
                size={FORM_FONT_SIZE}
                color={R.colors.purpleishBlue}
                isBold
              />
            </FlexColumnDiv>

            {/* 발행하기 버튼 */}
            <RoundTxtBtn
              text={hR.strings.AC_ASSET_GROUP_DLG_MINT_BTN}
              height={BTN_HEIGHT}
              width={BTN_WIDTH}
              fontSize={BTN_FOTNSIZE}
              onClick={() => {
                props.onMintClick && props.onMintClick({ owner: sOwner, mintCnt: parseInt(sMintCnt) });
              }}
              containerStyle={{ marginTop: BTN_MARGIN_TOP }}
              enable={sMintBtnEnabled}
            />
          </FlexColumnDiv>
        </FlexDiv>
      </FlexRowDiv>
    </FlexColumnDiv>
  );
};
