/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/modules';
import explorerSlice, { ExplorerState_t } from 'src/redux/modules/explorerStateModule';

export function useExplorerState() {
  const explorerState = useSelector((state: RootState) => state.explorerState);
  const dispatch = useDispatch();

  const setExplorerState = useCallback(
    (args: ExplorerState_t) => {
      dispatch(explorerSlice.actions.setState(args));
    },
    [dispatch]
  );

  const changeExplorerState = useCallback(
    (args: Partial<ExplorerState_t>) => {
      dispatch(explorerSlice.actions.changeState({ ...args }));
    },
    [dispatch, explorerState]
  );

  const clearExplorerState = useCallback(() => {
    dispatch(explorerSlice.actions.clear());
  }, [dispatch]);

  return {
    act: {
      setExplorerState,
      changeExplorerState,
      clearExplorerState,
    },
    state: explorerState,
  };
}
