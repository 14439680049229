/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDebounce, useEffectOnce } from 'react-use';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, CommonInput, devOutlineF } from 'src/ui/base_component/etc';
import { PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  value: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  fontSize?: number;
  paddingH?: number;
  placeHolder?: string;
  defaultValue?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

export function SRoundInputNumber({
  value,
  width = '100%',
  height = 44,
  borderRadius = 15,
  fontSize = 14,
  paddingH = 14,
  placeHolder,
  defaultValue,
  disabled = false,

  onFocus,
  onBlur,
  onChange,
  onKeyDown,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [debouncedSearchTxt, setDebouncedSearchTxt] = useState(value);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [sInputFocus, setInputFocus] = useState<boolean>(false);

  /**************************************
   * !! ref
   **************************************/
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isInputFocusRef = useRef<boolean>(false);

  /**************************************
   * !! hooks
   **************************************/
  const [,] = useDebounce(
    () => {
      setIsTyping(false);
      if (value === debouncedSearchTxt) return;
      setDebouncedSearchTxt(value);
      DBGMSG(`debouncedInputText:${value}`);
    },
    1000,
    [value]
  );

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    return () => {
      DBGMSG('useEffect() release');
    };
  }, [defaultValue]);
  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  DBGMSG('render');
  return (
    <AnimatedFlexRowDiv
      style={Object.assign(
        {
          width: width,
          height: height,
          borderRadius: borderRadius,
          justifyContent: 'flex-start',
        },
        sInputFocus ? PropsSRoundEnabled : PropsSRoundDisabled,
        disabled ? { backgroundColor: R.colors.lightGrey, borderWidth: 0 } : undefined
      )}
    >
      {/* input */}
      <CommonInput
        style={{
          fontSize,
          width: '100%',
          paddingBottom: 3,
          paddingLeft: paddingH,
          paddingRight: paddingH,
        }}
        ref={(input) => {
          inputRef.current = input;
        }}
        disabled={disabled}
        value={value}
        placeholder={placeHolder}
        onFocus={() => {
          DBGMSG('Input onFocus');
          isInputFocusRef.current = true;
          setInputFocus(true);
          onFocus && onFocus();
        }}
        onBlur={() => {
          DBGMSG('Input onBlur');
          isInputFocusRef.current = false;
          setInputFocus(false);
          onBlur && onBlur();
        }}
        onChange={(e) => {
          DBGMSG(`onChange: ${e.target.value}`);
          setIsTyping(true);
          onChange && onChange(e);
        }}
        onKeyPress={(e) => {
          DBGMSG(`onKeyPress code:${e.code}`);
          DBGMSG(`onKeyPress key:${e.key}`);
          onKeyDown && onKeyDown(e);
        }}
        // onKeyDown={(e) => {
        //   DBGMSG(`onKeyDown: ${e.key}`);
        //   if (e.key === 'Enter') {
        //     DBGMSG('Enter');
        //     // if (searchTxt.length !== 0) handleSearch(searchTxt);
        //   }
        //   onKeyDown && onKeyDown(e);
        // }}
        // returnKeyType="search"
        // clearButtonMode="while-editing"
      />
    </AnimatedFlexRowDiv>
  );
}
