/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { ServerECode_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import TextConMulti from 'src/ui/base_component/TextConMulti';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { CommonColumnBox, LOGIN_SCREEN_LAYOUT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserLoginScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  // const [sEmail, setEmail] = useState<string>('choi_sanghoon@postvoyager.com');
  // const [sPassword, setPassword] = useState<string>('aA12121212');
  const [sEmail, setEmail] = useState<string>('');
  const [sPassword, setPassword] = useState<string>('');
  const [sLoginFailMsg, setLoginFailMsg] = useState<string[]>();
  const [sTooManyIncorrectTryMsg, setTooManyIncorrectTryMsg] = useState<string[]>();

  const [sLoginBtnEnabled, setLoginBtnEnabled] = useState(false);

  const [sFrom, setFrom] = useState<string>(); // 돌아갈 이전 URL

  /**************************************
   * !! ref
   **************************************/
  const passwordInfpuRef = useRef<HTMLInputElement | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User Login - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hAuth = useAuth();
  const hRpc = useRpc();
  // const { pageWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    if (hLocation.state) {
      const { from } = hLocation.state as Nav.ReplaceState;
      DBGMSG(`from: ${from}`);
      setFrom(from);
    }

    // query string parsing
    const query: { email?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.email) {
      DBGMSG(`$$$$QS email: ${query.email}`);
      setEmail(query.email);
      passwordInfpuRef.current && passwordInfpuRef.current.focus();
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    if (sLoginFailMsg || sTooManyIncorrectTryMsg) {
      setLoginBtnEnabled(false);
    }

    if (sEmail.length > 0 && sPassword.length > 0) {
      setLoginBtnEnabled(true);
    } else {
      setLoginBtnEnabled(false);
    }

    return () => {};
  }, [sEmail.length, sLoginFailMsg, sPassword.length, sTooManyIncorrectTryMsg]);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const login = async () => {
    // 테스트 로그인
    // hAuth.authActs.testLogin();
    // hHistory.push(Nav.HomeScreen);

    // email형식 체크
    const regex: RegExp = Constants.REGEXP_IS_EMAIL;
    const regexTestRet = regex.test(sEmail);
    // DBGMSG(`$$$REGEX ${regex}: ${sEmail} ${regexTestRet}`);
    if (!regexTestRet) {
      DBGMSG(`$$$REGEX regexTestRet: ${regexTestRet}`);
      setLoginFailMsg([hR.strings.LOGIN_FAIL_MSG]);
      return;
    }

    const digest = await Utils.crypto.sha256(sPassword);
    hRpc.cobaltRpc
      .rpcUserLogin({ email: sEmail, password: digest, lang: hR.lang })
      .then(async (res) => {
        // 이용정지된 유저
        if (res.banned) {
          hHistory.replace(Nav.UserBan);
          return;
        }

        if (res.ucode === null) {
          DBGMSGW('error');
          return;
        }
        if (res.token === null) {
          DBGMSGW('error');
          return;
        }
        if (res.mkey === null) {
          DBGMSGW('error');
          return;
        }

        hAuth.authActs.login({
          email: sEmail,
          authCommonRes: {
            ucode: res.ucode,
            token: res.token,
            mkey: res.mkey,
            cert_tp: res.cert_tp,
          },
        });

        // 로그인 직후 현재 설정값으로 언어설정
        // hRpc.cobaltRpc.rpcUserAccountLangSet({ lang: hR.lang });

        if (sFrom) {
          hHistory.replace(sFrom);
        } else {
          hHistory.replace(Nav.HomeScreen);
        }
      })
      .catch((e) => {
        const errInfo = getRpcErrorInfo(e);
        if (errInfo) {
          switch (errInfo.ecode) {
            case ServerECode_e.INVALID_USER_PASSWORD: // 패스워드 incorrect
            case ServerECode_e.CANNOT_FIND_USER: // email not found
              setLoginFailMsg([hR.strings.LOGIN_FAIL_MSG]);
              break;

            case ServerECode_e.TRY_LOGIN_TOO_MANY:
            case ServerECode_e.LOCKED_USER_PASSWORD:
              setLoginFailMsg(undefined);
              setTooManyIncorrectTryMsg([hR.strings.LOGIN_TOO_MANY_INCORRECT_TRY_MSG_1, hR.strings.LOGIN_TOO_MANY_INCORRECT_TRY_MSG_2]);
              break;
            default:
              break;
          }
        }
        // setErrorMsg(hR.strings.LOGIN_EMAIL_INVALID);
        // setErrorMsg(hR.strings.LOGIN_EMAIL_TOOMUCHTRY);
      });
  };

  /**************************************
   * !! render function
   **************************************/
  const commonRender = () => {
    return (
      <FlexColumnDiv
        style={{
          alignItems: 'center',
          paddingTop: LOGIN_SCREEN_LAYOUT.topMargin,
          ...devOutline,
        }}
      >
        <CommonColumnBox
          style={{
            //
            width: LOGIN_SCREEN_LAYOUT.boxWidth,
            // justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: LOGIN_SCREEN_LAYOUT.horizontalPadding,
            paddingRight: LOGIN_SCREEN_LAYOUT.horizontalPadding,
          }}
        >
          <HEIGHT size={LOGIN_SCREEN_LAYOUT.titleTopMargin} />

          {/* 타이틀 */}
          <TextCon text={hR.strings.LOGIN_TITLE} color={R.colors.black} isBold size={LOGIN_SCREEN_LAYOUT.titleFontSize} />

          <HEIGHT size={LOGIN_SCREEN_LAYOUT.secureGuideVeticalMargin} />

          {/* 보안주의 메세지 */}
          <TextCon text={hR.strings.LOGIN_SECURE_GUIDE} color={R.colors.blueGrey} size={LOGIN_SCREEN_LAYOUT.secureGuideFontSize} />

          <HEIGHT size={LOGIN_SCREEN_LAYOUT.secureGuideVeticalMargin} />

          {/* 사이트 주소 */}
          <FlexRowDiv
            style={{
              width: LOGIN_SCREEN_LAYOUT.urlRoundBoxSize.width,
              height: LOGIN_SCREEN_LAYOUT.urlRoundBoxSize.height,
              borderRadius: LOGIN_SCREEN_LAYOUT.urlRoundBoxSize.height / 2,
              borderColor: R.colors.line,
              borderWidth: 1,
              borderStyle: 'solid',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Img src={[R.images.login_ic_lock3x, 18, 18]} />
            <WIDTH size={2} />
            <TextCon text={R.strings.COBALT_URL} size={LOGIN_SCREEN_LAYOUT.urlFontSize} color={R.colors.purpleishBlue} />
          </FlexRowDiv>
          {(sLoginFailMsg || sTooManyIncorrectTryMsg) && (
            <>
              <HEIGHT size={LOGIN_SCREEN_LAYOUT.failMsgTopMargin} />
              {sLoginFailMsg && (
                <TextConMulti
                  text={sLoginFailMsg}
                  size={LOGIN_SCREEN_LAYOUT.failMsgFontSize}
                  color={R.colors.pastelRed}
                  containerStyle={{ alignItems: 'center' }}
                />
              )}
              {sTooManyIncorrectTryMsg && (
                <TextConMulti
                  text={sTooManyIncorrectTryMsg}
                  size={LOGIN_SCREEN_LAYOUT.failMsgFontSize}
                  color={R.colors.pastelRed}
                  containerStyle={{ alignItems: 'center' }}
                />
              )}
            </>
          )}
          <HEIGHT size={LOGIN_SCREEN_LAYOUT.formTopMargin} />

          {/* EMAIL */}
          <TextCon
            text={hR.strings.LOGIN_EMAIL}
            size={LOGIN_SCREEN_LAYOUT.formFontSize}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={LOGIN_SCREEN_LAYOUT.formInputTopMargin} />
          <SRoundInputText
            width={LOGIN_SCREEN_LAYOUT.formSize.width}
            height={LOGIN_SCREEN_LAYOUT.formSize.height}
            fontSize={LOGIN_SCREEN_LAYOUT.formFontSize}
            value={sEmail}
            inputType={'email'}
            placeHolder={hR.strings.LOGIN_EMAIL_PLACEHOLDER}
            onChange={(e) => {
              setEmail(e.target.value);
              setTooManyIncorrectTryMsg(undefined);
            }}
            onBlur={() => {}}
          />

          <HEIGHT size={LOGIN_SCREEN_LAYOUT.formTopMargin} />

          {/* PASSWORD */}
          <TextCon
            text={hR.strings.LOGIN_PASSWORD}
            size={LOGIN_SCREEN_LAYOUT.formFontSize}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={LOGIN_SCREEN_LAYOUT.formInputTopMargin} />
          <SRoundInputText
            getRef={(ref) => {
              passwordInfpuRef.current = ref;
            }}
            width={LOGIN_SCREEN_LAYOUT.formSize.width}
            height={LOGIN_SCREEN_LAYOUT.formSize.height}
            fontSize={LOGIN_SCREEN_LAYOUT.formFontSize}
            value={sPassword}
            inputType={'password'}
            placeHolder={hR.strings.LOGIN_PASSWORD_PLACEHOLDER}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              // password 입력후 enter키 입력시 바로 로그인 요청
              if (e.key === 'Enter' && sLoginBtnEnabled) {
                login();
              }
            }}
          />

          <HEIGHT size={LOGIN_SCREEN_LAYOUT.loginBtnVeticalMargin} />

          {/* 로그인 버튼 */}
          <RoundTxtBtn
            enable={sLoginBtnEnabled}
            text={hR.strings.LOGIN_BTN}
            width={LOGIN_SCREEN_LAYOUT.loginBtnSize.width}
            height={LOGIN_SCREEN_LAYOUT.loginBtnSize.height}
            fontSize={LOGIN_SCREEN_LAYOUT.loginBtnFontSize}
            onClick={() => {
              login();
            }}
          />

          <HEIGHT size={LOGIN_SCREEN_LAYOUT.loginBtnVeticalMargin} />

          <FlexRowDiv>
            {/* 비밀번호 재설정 */}
            <TextCon
              text={hR.strings.LOGIN_FIND_PASSWORD}
              containerStyle={{ padding: 10 }}
              isBold
              size={LOGIN_SCREEN_LAYOUT.pwResetBtnFontSize}
              color={R.colors.blueGrey}
              onConClick={() => {
                if (sEmail && sTooManyIncorrectTryMsg) {
                  hHistory.push(Nav.brewUserResetPassword({ email: sEmail }));
                } else {
                  hHistory.push(Nav.UserResetPasswordScreen);
                }
              }}
            />
            <WIDTH size={10} />
            {/* 회원가입하기 */}
            <TextCon
              text={hR.strings.LOGIN_JOIN}
              containerStyle={{ padding: 10 }}
              isBold
              size={LOGIN_SCREEN_LAYOUT.joinBtnFontSize}
              color={R.colors.purpleishBlue}
              onConClick={() => {
                hHistory.push(Nav.UserJoinScreen);
              }}
            />
          </FlexRowDiv>
          <HEIGHT size={LOGIN_SCREEN_LAYOUT.bottomPadding} />
        </CommonColumnBox>
        <HEIGHT size={LOGIN_SCREEN_LAYOUT.bottomMargin} />

        {/* 공통푸터 */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </FlexColumnDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    return commonRender();
  } else {
    return <MobileScalableFlexColumn>{commonRender()}</MobileScalableFlexColumn>;
  }
}
