/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { useEffectOnce, useTitle } from 'react-use';
import { Area, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AppConf, isAlpha, isProd, Phase } from 'src/conf/AppConf';
import ScreenInfo, { DESKTOP_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMakePressable } from 'src/hooks/useMakePressable';
import { useMarketPlaceState } from 'src/hooks/useMarketPlaceState';
import { useRpc } from 'src/hooks/useRpc';
import { DisplaySrc_t } from 'src/model/model';
import { CbtAttributeType_e, CbtColCertifyType_e, CbtCurrency_e, CbtPgInfo_i, CbtTxStatus_e } from 'src/model/rpcModel';
import { RPC_MP_SALE_DETAIL, RPC_MP_SALE_HISTORY } from 'src/model/rpcType';
import { UserDetailMenuType_e } from 'src/redux/modules/userDetailModule';
import R from 'src/res/R';
import { devOutlineF, devOutlineFB, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgAdv } from 'src/ui/base_component/ImgAdv';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipIc } from 'src/ui/base_component/TooltipIc';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import TxtBtnWithMouseOverAni from 'src/ui/base_component/TxtBtnWithMouseOverAni';
import TxtImgBtnWithMouseOverAni from 'src/ui/base_component/TxtImgBtnWithMouseOverAni';
import { AssetDisplay } from 'src/ui/common_component/AssetDisplay/AssetDisplay';
import { AssetDisplayThumb, WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { FavoriteBtn } from 'src/ui/common_component/button/FavoriteBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { PriceHistoryChartTooltip, PriceHistoryChartXAxisTick, PriceHistoryChartYAxisTick } from 'src/ui/common_component/etc';
import { LabelValue } from 'src/ui/common_component/LabelValue';
import { LabelValueMint } from 'src/ui/common_component/LabelValueMint';
import { ETC_DROPDOWN_PADDING_V } from 'src/ui/common_component/MarketAssetCard';
import { PriceAdv } from 'src/ui/common_component/PriceAdv';
import { Title } from 'src/ui/common_component/Title';
import { UserThumbName } from 'src/ui/common_component/UserThumbName';
import { CLAY, CommonColumnBox, CommonRowBox, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! Constant
 **************************************/
// DESKTOP
const DT_BACKBTN_MARGIN_BOTTOM = 30;
const DT_LEFT_CONTAINER_WIDTH = 582;
const DT_LEFT_CONTAINER_HEIGHT = 722;
const DT_LIKE_BTN_WIDTH = 40;
const DT_LIKE_BTN_HEIGHT = 40;
const DT_LIKE_BTN_MARGIN_V = 20;
const DT_ASSETDISPLAY_MARGIN = 40;
const DT_ASSETDISPLAY_SIZE = 502;
const DT_ASSETDISPLAY_THUMB_SIZE = 100;
const DT_ASSETDISPLAY_THUMB_LIST_SIZE = 440;
const DT_ASSETDISPLAY_THUMB_CNT = 4;
const DT_ARROW_BTN_SIZE = 34;
const DT_ARROW_BTN_PADDING = 13;
const DT_RIGHT_CONTAINER_WIDHT = 582;
// Favorite 버튼상단부분
const DT_OVERBOX_INFO_PADDING_LEFT = 10;
const DT_COL_NAME_HEIGHT = 24;
const DT_COL_IMG_SIZE = 24;
const DT_COL_FONT_SIZE = 14;
const DT_COL_NAME_MARGIN_BOTTOM = 10;
const DT_ASSET_NAME_FONT_SIZE = 24;
const DT_ASSET_NAME_MARGIN_BOTTOM = 14;
const DT_BOX_MARGIN_TOP = 30;
const DT_SHARE_DROPDOWN_INPUT_WIDTH = 180;
const DT_SHARE_DROPDOWN_MENU_HEIGHT = 30;
const DT_DROPDOWN_IMAGE_WIDTH = 24;
const DT_DROPDOWN_MENU_PADIING_LEFT = 16;
const DT_DROPDOWN_TEXT_PADDING_LEFT = 6;
const DT_DROPDOWN_MENU_FONTSIZE = 14;
const DT_ETC_DROPDOWN_INPUT_WIDTH = 160;
const DT_ETC_DROPDOWN_MENU_HEIGHT = 30;
// 박스
const DT_SCHM_NAME_HEIGHT = 20;
const DT_SCHM_NAME_FONTSIZE = 14;
const DT_SCHM_NAME_VMARGIN = 24;
const DT_BOX_PADDING_H = 24;
const DT_LV_MARGIN_V = 24; // LV => Label Value
const DT_LV_FLEX_DIR = 'row';
const DT_LV_TITLE_FONTSIZE = 14;
const DT_LV_VALUE_FONTSIZE = 14;
const DT_LV_TITLE_WIDTH = 183;
const DT_LV_VALUE_WIDTH = 351;
const DT_LV_TITLE_HEIGHT = 24;
const DT_LV_VALUE_HEIGHT = 24;
const DT_BOX_MARGIN_BOTTOM = 30;
const DT_TH_ITEM_HEIGHT = 69;
const DT_TH_MARGIN_BOTTOM = 30;
const DT_TH_PAGING_HEIGHT = 34;
const DT_TH_PAGING_MARIGN_V = 6;
const DT_TH_EVETN_WIDHT = 190;
const DT_TH_PRICE_WIDHT = 255;
const DT_TH_FROM_WIDHT = 275;
const DT_TH_TO_WIDHT = 275;
const DT_TH_DATE_WIDHT = 175;
const DT_BOTTOM_SPACE = 120;

const DT_SALE_BOX_WIDTH = DT_RIGHT_CONTAINER_WIDHT;
const DT_SALE_BOX_HEIGHT = 96;
const DT_KLAY_IC_WIDTH = 18;
const DT_KLAY_IC_HEIGHT = 20;
const DT_PRICE_FONTSIZE = 22;
const DT_PRICESUB_FONTSIZE = 14;
const DT_SALE_BTN_WIDTH = 152;
const DT_SALE_BTN_HEIGHT = 48;
const DT_SALE_BTN_FONTSIZE = 14;
const DT_TITLE_MARGIN_TOP = 50;
const DT_TITLE_MARGIN_BOTTIM = 20;

// MOBILE
const MB_BACKBTN_MARGIN_BOTTOM = 20;
const MB_LEFT_CONTAINER_WIDTH = 280;
const MB_LEFT_CONTAINER_HEIGHT = 408;
const MB_LIKE_BTN_WIDTH = 28;
const MB_LIKE_BTN_HEIGHT = 28;
const MB_LIKE_BTN_MARGIN_V = 10;
const MB_ASSETDISPLAY_MARGIN = 16;
const MB_ASSETDISPLAY_SIZE = 248;
const MB_ASSETDISPLAY_THUMB_SIZE = 80;
const MB_ASSETDISPLAY_THUMB_LIST_SIZE = 180;
const MB_ASSETDISPLAY_THUMB_CNT = 2;
const MB_ARROW_BTN_SIZE = 30;
const MB_ARROW_BTN_PADDING = 0;
const MB_RIGHT_CONTAINER_WIDHT = 280;
// Favorite 버튼상단부분
const MB_OVERBOX_INFO_PADDING_LEFT = 0;
const MB_COL_NAME_HEIGHT = 20;
const MB_COL_IMG_SIZE = 20;
const MB_COL_FONT_SIZE = 12;
const MB_COL_NAME_MARGIN_BOTTOM = 8;
const MB_ASSET_NAME_FONT_SIZE = 16;
const MB_ASSET_NAME_MARGIN_BOTTOM = 8;
const MB_BOX_MARGIN_TOP = 20;
const MB_SHARE_DROPDOWN_INPUT_WIDTH = 110;
const MB_SHARE_DROPDOWN_MENU_HEIGHT = 27;
const MB_DROPDOWN_IMAGE_WIDTH = 16;
const MB_DROPDOWN_MENU_PADIING_LEFT = 12;
const MB_DROPDOWN_TEXT_PADDING_LEFT = 4;
const MB_DROPDOWN_MENU_FONTSIZE = 10;
const MB_ETC_DROPDOWN_INPUT_WIDTH = 100;
const MB_ETC_DROPDOWN_MENU_HEIGHT = 27;
// 박스
const MB_SCHM_NAME_HEIGHT = 20;
const MB_SCHM_NAME_FONTSIZE = 12;
const MB_SCHM_NAME_VMARGIN = 15;
const MB_BOX_PADDING_H = 16;
const MB_LV_MARGIN_V = 14; // LV => Label Value
const MB_LV_FLEX_DIR = 'column';
const MB_LV_TITLE_FONTSIZE = 12;
const MB_LV_VALUE_FONTSIZE = 12;
const MB_LV_TITLE_WIDTH = 280 - 32;
const MB_LV_VALUE_WIDTH = MB_LV_TITLE_WIDTH;
const MB_LV_TITLE_HEIGHT = 24;
const MB_LV_VALUE_HEIGHT = 24;
const MB_BOX_MARGIN_BOTTOM = 20;
const MB_TH_ITEM_HEIGHT = 69;
const MB_TH_MARGIN_BOTTOM = 20;
const MB_TH_PAGING_HEIGHT = 30;
const MB_TH_PAGING_MARIGN_V = 4;
const MB_TH_EVETN_WIDHT = 190;
const MB_TH_PRICE_WIDHT = 255;
const MB_TH_FROM_WIDHT = 275;
const MB_TH_TO_WIDHT = 275;
const MB_TH_DATE_WIDHT = 175;
const MB_BOTTOM_SPACE = 60;
const MB_SALE_BOX_WIDTH = MB_RIGHT_CONTAINER_WIDHT;
const MB_SALE_BOX_HEIGHT = 118;
const MB_KLAY_IC_WIDTH = 16;
const MB_KLAY_IC_HEIGHT = 18;
const MB_PRICE_FONTSIZE = 14;
const MB_PRICESUB_FONTSIZE = 10;
const MB_SALE_BTN_WIDTH = 248;
const MB_SALE_BTN_HEIGHT = 44;
const MB_SALE_BTN_FONTSIZE = 12;
const MB_TITLE_MARGIN_TOP = 30;
const MB_TITLE_MARGIN_BOTTIM = 20;

// 공통
const DROPDOWN_INPUT_PADDING_TOP = 6;
const BACKBTN_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_BACKBTN_MARGIN_BOTTOM : DT_BACKBTN_MARGIN_BOTTOM;
const LEFT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_WIDTH : DT_LEFT_CONTAINER_WIDTH;
const LEFT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_HEIGHT : DT_LEFT_CONTAINER_HEIGHT;
const LIKE_BTN_WIDTH = ScreenInfo.isMobile() ? MB_LIKE_BTN_WIDTH : DT_LIKE_BTN_WIDTH;
const LIKE_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_LIKE_BTN_HEIGHT : DT_LIKE_BTN_HEIGHT;
const LIKE_BTN_MARGIN_V = ScreenInfo.isMobile() ? MB_LIKE_BTN_MARGIN_V : DT_LIKE_BTN_MARGIN_V;

const ETC_DROPDOWN_INPUT_WIDTH = ScreenInfo.isMobile() ? MB_ETC_DROPDOWN_INPUT_WIDTH : DT_ETC_DROPDOWN_INPUT_WIDTH;
const ETC_DROPDOWN_MENU_HEIGHT = ScreenInfo.isMobile() ? MB_ETC_DROPDOWN_MENU_HEIGHT : DT_ETC_DROPDOWN_MENU_HEIGHT;
const SHARE_DROPDOWN_INPUT_WIDTH = ScreenInfo.isMobile() ? MB_SHARE_DROPDOWN_INPUT_WIDTH : DT_SHARE_DROPDOWN_INPUT_WIDTH;
const SHARE_DROPDOWN_MENU_HEIGHT = ScreenInfo.isMobile() ? MB_SHARE_DROPDOWN_MENU_HEIGHT : DT_SHARE_DROPDOWN_MENU_HEIGHT;
const DROPDOWN_IMG_WIDTH = ScreenInfo.isMobile() ? MB_DROPDOWN_IMAGE_WIDTH : DT_DROPDOWN_IMAGE_WIDTH;
const DROPDOWN_MENU_PADIING_LEFT = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_PADIING_LEFT : DT_DROPDOWN_MENU_PADIING_LEFT;
const DROPDOWN_TEXT_PADDING_LEFT = ScreenInfo.isMobile() ? MB_DROPDOWN_TEXT_PADDING_LEFT : DT_DROPDOWN_TEXT_PADDING_LEFT;
const DROPDOWN_MENU_FONTSIZE = ScreenInfo.isMobile() ? MB_DROPDOWN_MENU_FONTSIZE : DT_DROPDOWN_MENU_FONTSIZE;

const ASSETDISPLAY_MARGIN = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_MARGIN : DT_ASSETDISPLAY_MARGIN;
const ASSETDISPLAY_SIZE = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_SIZE : DT_ASSETDISPLAY_SIZE;
const ASSETDISPLAY_THUMB_SIZE = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_THUMB_SIZE : DT_ASSETDISPLAY_THUMB_SIZE;
const ASSETDISPLAY_THUMB_LIST_SIZE = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_THUMB_LIST_SIZE : DT_ASSETDISPLAY_THUMB_LIST_SIZE;
const ASSETDISPLAY_THUMB_CNT = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_THUMB_CNT : DT_ASSETDISPLAY_THUMB_CNT;
const ARROW_BTN_SIZE = ScreenInfo.isMobile() ? MB_ARROW_BTN_SIZE : DT_ARROW_BTN_SIZE;
const ARROW_BTN_PADDING = ScreenInfo.isMobile() ? MB_ARROW_BTN_PADDING : DT_ARROW_BTN_PADDING;
const RIGHT_CONTAINER_WIDHT = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_WIDHT : DT_RIGHT_CONTAINER_WIDHT;
const OVERBOX_INFO_PADDING_LEFT = ScreenInfo.isMobile() ? MB_OVERBOX_INFO_PADDING_LEFT : DT_OVERBOX_INFO_PADDING_LEFT;
const COL_NAME_HEIGHT = ScreenInfo.isMobile() ? MB_COL_NAME_HEIGHT : DT_COL_NAME_HEIGHT;
const COL_IMG_SIZE = ScreenInfo.isMobile() ? MB_COL_IMG_SIZE : DT_COL_IMG_SIZE;
const COL_FONT_SIZE = ScreenInfo.isMobile() ? MB_COL_FONT_SIZE : DT_COL_FONT_SIZE;
const COL_NAME_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_COL_NAME_MARGIN_BOTTOM : DT_COL_NAME_MARGIN_BOTTOM;
const ASSET_NAME_FONT_SIZE = ScreenInfo.isMobile() ? MB_ASSET_NAME_FONT_SIZE : DT_ASSET_NAME_FONT_SIZE;
const ASSET_NAME_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_ASSET_NAME_MARGIN_BOTTOM : DT_ASSET_NAME_MARGIN_BOTTOM;
const BOX_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BOX_MARGIN_TOP : DT_BOX_MARGIN_TOP;
const SCHM_NAME_HEIGHT = ScreenInfo.isMobile() ? MB_SCHM_NAME_HEIGHT : DT_SCHM_NAME_HEIGHT;
const SCHM_NAME_FONTSIZE = ScreenInfo.isMobile() ? MB_SCHM_NAME_FONTSIZE : DT_SCHM_NAME_FONTSIZE;
const SCHM_NAME_VMARGIN = ScreenInfo.isMobile() ? MB_SCHM_NAME_VMARGIN : DT_SCHM_NAME_VMARGIN;
const BOX_PADDING_H = ScreenInfo.isMobile() ? MB_BOX_PADDING_H : DT_BOX_PADDING_H;
const LV_MARGIN_V = ScreenInfo.isMobile() ? MB_LV_MARGIN_V : DT_LV_MARGIN_V;
const LV_FLEX_DIR = ScreenInfo.isMobile() ? MB_LV_FLEX_DIR : DT_LV_FLEX_DIR;
const LV_TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_LV_TITLE_FONTSIZE : DT_LV_TITLE_FONTSIZE;
const LV_VALUE_FONTSIZE = ScreenInfo.isMobile() ? MB_LV_VALUE_FONTSIZE : DT_LV_VALUE_FONTSIZE;
const LV_TITLE_WIDTH = ScreenInfo.isMobile() ? MB_LV_TITLE_WIDTH : DT_LV_TITLE_WIDTH;
const LV_VALUE_WIDTH = ScreenInfo.isMobile() ? MB_LV_VALUE_WIDTH : DT_LV_VALUE_WIDTH;
const LV_TITLE_HEIGHT = ScreenInfo.isMobile() ? MB_LV_TITLE_HEIGHT : DT_LV_TITLE_HEIGHT;
const LV_VALUE_HEIGHT = ScreenInfo.isMobile() ? MB_LV_VALUE_HEIGHT : DT_LV_VALUE_HEIGHT;
const BOX_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_BOX_MARGIN_BOTTOM : DT_BOX_MARGIN_BOTTOM;

const TH_ITEM_HEIGHT = ScreenInfo.isMobile() ? MB_TH_ITEM_HEIGHT : DT_TH_ITEM_HEIGHT;
const TH_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TH_MARGIN_BOTTOM : DT_TH_MARGIN_BOTTOM;
const TH_PAGING_HEIGHT = ScreenInfo.isMobile() ? MB_TH_PAGING_HEIGHT : DT_TH_PAGING_HEIGHT;
const TH_PAGING_MARIGN_V = ScreenInfo.isMobile() ? MB_TH_PAGING_MARIGN_V : DT_TH_PAGING_MARIGN_V;
const TH_EVETN_WIDHT = ScreenInfo.isMobile() ? MB_TH_EVETN_WIDHT : DT_TH_EVETN_WIDHT;
const TH_PRICE_WIDHT = ScreenInfo.isMobile() ? MB_TH_PRICE_WIDHT : DT_TH_PRICE_WIDHT;
const TH_FROM_WIDHT = ScreenInfo.isMobile() ? MB_TH_FROM_WIDHT : DT_TH_FROM_WIDHT;
const TH_TO_WIDHT = ScreenInfo.isMobile() ? MB_TH_TO_WIDHT : DT_TH_TO_WIDHT;
const TH_DATE_WIDHT = ScreenInfo.isMobile() ? MB_TH_DATE_WIDHT : DT_TH_DATE_WIDHT;
const BOTTOM_SPACE = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE : DT_BOTTOM_SPACE;

export const MARKET_ASSET_R_SALE_BOX_WIDTH = ScreenInfo.isMobile() ? MB_SALE_BOX_WIDTH : DT_SALE_BOX_WIDTH;
export const MARKET_ASSET_R_SALE_BOX_HEIGHT = ScreenInfo.isMobile() ? MB_SALE_BOX_HEIGHT : DT_SALE_BOX_HEIGHT;
const KLAY_IC_WIDTH = ScreenInfo.isMobile() ? MB_KLAY_IC_WIDTH : DT_KLAY_IC_WIDTH;
const KLAY_IC_HEIGHT = ScreenInfo.isMobile() ? MB_KLAY_IC_HEIGHT : DT_KLAY_IC_HEIGHT;
const PRICE_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_FONTSIZE : DT_PRICE_FONTSIZE;
const PRICESUB_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICESUB_FONTSIZE : DT_PRICESUB_FONTSIZE;
const SALE_BTN_WIDTH = ScreenInfo.isMobile() ? MB_SALE_BTN_WIDTH : DT_SALE_BTN_WIDTH;
const SALE_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_SALE_BTN_HEIGHT : DT_SALE_BTN_HEIGHT;
const SALE_BTN_FONTSIZE = ScreenInfo.isMobile() ? MB_SALE_BTN_FONTSIZE : DT_SALE_BTN_FONTSIZE;
const TITLE_MARGIN_TOP = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_TOP : DT_TITLE_MARGIN_TOP;
const TITLE_MARGIN_BOTTIM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTIM : DT_TITLE_MARGIN_BOTTIM;

const TH_WIDTH = TH_EVETN_WIDHT + TH_PRICE_WIDHT + TH_FROM_WIDHT + TH_TO_WIDHT + TH_DATE_WIDHT;

export type TrdGraphItem_t = {
  org: {
    ymd: number;
    cnt: number;
    real_usd_prc: number;
  };
  x_time: number;
  y_avgPrc: number;
  tooltip: {
    cntLabel: string;
    cntValue: string;
    avgPrcLabel: string;
    avgPrcValue: string;
  };
};

type Props = {};

export function MarketAssetRScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sQS, setQS] = useState<{ sale_id: number }>();
  const [sFetchedDetailData, setFetchedDetailData] = useState<RPC_MP_SALE_DETAIL.Rx>();
  const [sFetchedSaleHistoryData, setFetchedSaleHistoryData] = useState<RPC_MP_SALE_HISTORY.Rx>();
  const [sSaleHistoryList, setSaleHistoryList] = useState<RPC_MP_SALE_HISTORY.Rx['trd_history']>();
  // todo 판매완료
  const [sIsSoldout, setIsSoldout] = useState(false);

  const [sTradingPageno, setTradingPageno] = useState(1);
  const [sIsLastPageSaleHistory, setIsLastPageSaleHistory] = useState<boolean>();

  const [sSelectedDisplayThumbIdx, setSelectedDisplayThumbIdx] = useState(0); // 선택되어 있는 thumb idx
  const [sDisplaySrcList, setDisplaySrcList] = useState<DisplaySrc_t[]>();
  const [sSliderCurIdx, setSliderCurIdx] = useState(0); // 선택되어 있는 thumb idx
  const [sReportMenuOpen, setReportMenuOpen] = useState(false);
  const [sShareMenuOpen, setShareMenuOpen] = useState(false);
  const [sTrdGraphItemList, setTrdGraphItemList] = useState<TrdGraphItem_t[]>();

  /**************************************
   * !! ref
   **************************************/
  const assetDisplayThumbListSliderRef = useRef<Slider>(null);
  const shareBtnRef = useRef(null);
  const sharePopupBtnRef = useRef(null);
  const reportBtnRef = useRef(null);
  const reportPopupBtnRef = useRef(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`MarketPlace Asset Detail - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hKaikasSvc = useKaikasSvc();
  const hDlgFactory = useDlgFactory();
  const hMarketPlaceState = useMarketPlaceState();

  const hAuth = useAuth();
  useMakePressable([
    {
      elementKey: 'report',
      elementRefList: [reportBtnRef, reportPopupBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        // DBGMSG('report onInsidePress');
        if (reportBtnRef.current === hitRef.current) {
          setReportMenuOpen((prev) => !prev);
        }
        e.stopPropagation();
      },
      onOutsidePress: (e) => {
        sReportMenuOpen && setReportMenuOpen(false);
        // DBGMSG('report onOutsidePress');
      },
    },
    {
      elementKey: 'share',
      elementRefList: [shareBtnRef, sharePopupBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        // DBGMSG('share onInsidePress1');
        if (shareBtnRef.current === hitRef.current) {
          // DBGMSG('share onInsidePress1-1');
          setShareMenuOpen((prev) => !prev);
        }
        e.stopPropagation();
      },
      onOutsidePress: (e) => {
        sShareMenuOpen && setShareMenuOpen(false);
        // DBGMSG('share onOutsidePress');
      },
    },
  ]);

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sale_id?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sale_id) {
      DBGMSG(`$$$$QS sale_id: ${query.sale_id}`);
      const sale_id = parseInt(query.sale_id);
      setQS({ sale_id });
      fetchDetailData({ sale_id });
      fetchSaleHistoryData({ sale_id });
    } else {
      // key 없이 접근
      return;
    }

    // setMetaTags({title:`id:${query.sale_id}`, imageUrl:'https://cobalt.zone/log512.png', description:`id:${query.sale_id}}`, })

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    if (sFetchedSaleHistoryData) {
      if (sFetchedSaleHistoryData.pg_info.last_row_id === -1) setIsLastPageSaleHistory(true);
      else setIsLastPageSaleHistory(false);
    }

    return () => {};
  }, [sFetchedSaleHistoryData?.pg_info.last_row_id]);

  /**************************************
   * !! arrow function
   **************************************/
  const isLastPageSaleHistory = () => {
    if (!sFetchedSaleHistoryData) return;
    return sFetchedSaleHistoryData.pg_info.last_row_id === -1;
  };

  const fetchDetailData = async ({ sale_id }: { sale_id: number }) => {
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleDetail({ sale_id });
      setFetchedDetailData(res);
      if (!res) return;
      const playerSrcList = Utils.svc.brewDisplaySrcList({ assetType: res.ast_info.schm_info.asset_type, attrList: res.ast_info.attr_list });
      setDisplaySrcList(playerSrcList);

      if (res.status === CbtTxStatus_e.COMPLETE) {
        setIsSoldout(true);
      }
      if (res.trd_graph) {
        const trGraphItemList = res.trd_graph.tr_rows.map<TrdGraphItem_t>((tr) => {
          let trdate = Utils.date.getDateFromYYYYMMDD(`${tr.ymd}`);
          let x_time: number;

          if (trdate === undefined) {
            DBGMSGW('what');
            x_time = 0;
          } else {
            // x_time = trdate.getTime() / (1000 * 60 * 60 * 24); // 60x60x24
            x_time = trdate.getTime() / 1000; // 60x60x24
          }

          DBGMSG(x_time);
          DBGMSG(tr.real_usd_prc);

          return {
            org: tr,
            x_time,
            y_avgPrc: tr.real_usd_prc,
            tooltip: {
              cntLabel: `${hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_CNT}`,
              cntValue: `${tr.cnt}`,
              avgPrcLabel: `${hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_AVGPRC}`,
              avgPrcValue: `${tr.real_usd_prc}`,
            },
          };
        });
        setTrdGraphItemList(trGraphItemList);
      }
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({
          text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}`,
          onOk: () => {
            hHistory.goBack();
          },
        });
      }
    }
  };

  const fetchSaleHistoryData = async ({ sale_id }: { sale_id: number }) => {
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleHistory({ sale_id });
      setFetchedSaleHistoryData(res);
      setSaleHistoryList(res.trd_history);
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    }
  };

  const fetchSaleHistoryNext = async ({ sale_id, pg_info }: { sale_id: number; pg_info: CbtPgInfo_i }) => {
    if (!sFetchedSaleHistoryData) {
      return;
    }
    if (sFetchedSaleHistoryData.pg_info.last_row_id === -1) {
      return;
    }

    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleHistory({ sale_id, pg_info });
      setFetchedSaleHistoryData(res);
      setSaleHistoryList(res.trd_history);
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    }
  };

  const reload = () => {
    if (!sQS) return;
    fetchDetailData(sQS);
    fetchSaleHistoryData(sQS);
  };

  const getUsdDollar = () => {
    if (!sFetchedDetailData) return '';
    if (!sFetchedDetailData.prc_info.sub_price_list) return '';
    if (sFetchedDetailData.prc_info.sub_price_list.length === 0) return '';
    if (sFetchedDetailData.prc_info.sub_price_list[0].curr_info.currency !== CbtCurrency_e.USD) return '';

    if (sFetchedDetailData.prc_info.curr_info.currency === CbtCurrency_e.KLAY) {
      return `(≈ ${Utils.currency.getPriceForDP({
        prcInfo: sFetchedDetailData.prc_info.sub_price_list[0],
        decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
      })} ${R.strings.CURRENCY_CUSD})`;
    } else {
      return `(${Utils.currency.getPriceForDP({
        prcInfo: sFetchedDetailData.prc_info.sub_price_list[0],
        decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
      })} ${R.strings.CURRENCY_CPOINT})`;
    }
  };

  const handleBuyBtnOnClick = async () => {
    if (!sFetchedDetailData) return;
    if (sFetchedDetailData.prc_info.curr_info.currency === CbtCurrency_e.KLAY)
      hKaikasSvc.handleBuyBtnOnClick({
        sale_id: sFetchedDetailData.sale_id,
        onBuySuccess: () => {
          // reload();
        },
      });
    else if (sFetchedDetailData.prc_info.curr_info.currency === CbtCurrency_e.cPOINT) {
      if (!hAuth.isLogin) {
        hHistory.push(Nav.UserLoginScreen);
        return;
      }

      hDlgFactory.showBuyJsx({
        sale_id: sFetchedDetailData.sale_id,
        onClose: () => {
          hDlgFactory.hideBuyJsx();
        },
        onSuccess: () => {
          hDlgFactory.hideBuyJsx();
          // reload();
        },
      });
    }
  };

  const getMinPrice = () => {
    if (!sFetchedDetailData) return '';
    if (sFetchedDetailData.trd_graph === null) return '';
    return `${Utils.currency.getPriceForDP({ prcInfo: sFetchedDetailData.trd_graph.min_prc, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })}`;
  };

  const getMaxPrice = () => {
    if (!sFetchedDetailData) return '';
    if (sFetchedDetailData.trd_graph === null) return '';
    return `${Utils.currency.getPriceForDP({ prcInfo: sFetchedDetailData.trd_graph.max_prc, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })}`;
  };

  const getTrCnt = () => {
    if (!sFetchedDetailData) return '';
    if (sFetchedDetailData.trd_graph === null) return '';
    return `${sFetchedDetailData.trd_graph.tr_cnt}`;
  };

  const setClipboard = (id: number) => {
    const t = document.createElement('textarea');
    document.body.appendChild(t);
    if (isAlpha()) {
      t.value = `https://wallet-alpha.cobalt.zone/share?sale_id=${id}`;
    } else if (isProd()) {
      t.value = `https://i.cobalt.zone/share?sale_id=${id}`;
    }
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
    // alert(`복사되었습니다.`);
    setShareMenuOpen(false);
  };

  const shareTwitter = (txt: string, id: number) => {
    let message = txt; // 전달할 텍스트
    let url: string;
    if (isAlpha()) {
      url = `https://wallet-alpha.cobalt.zone/share?sale_id=${id}`;
    } else {
      url = `https://i.cobalt.zone/share?sale_id=${id}`;
    }
    window.open(`https://twitter.com/intent/tweet?text=${message}&url=${url}`, '_blank', 'noopener');
    setShareMenuOpen(false);
  };
  // const setMetaTags = ({ title = '기본 타이틀', description = '기본 설명', imageUrl = '기본 사이트 이미지 경로' }) => {
  //   //set title
  //   document.querySelector('meta[property="og:title"]')?.setAttribute('content', `${title}`);
  //   //set description
  //   document.querySelector('meta[property="og:description"]')?.setAttribute('content', description);
  //   //set images
  //   document.querySelector('meta[property="og:image"]')?.setAttribute('content', imageUrl);
  //   //set url
  //   document.querySelector('meta[property="og:url"]')?.setAttribute('content', window.location.href);
  // };

  /**************************************
   * !! render function
   **************************************/
  const renderBtnSet = () => {
    if (sFetchedDetailData) {
      return (
        <FlexRowDiv
          style={{
            position: 'absolute',
            alignSelf: 'flex-end',
            transform: ScreenInfo.isMobile() ? 'translateY(-6px)' : 'none',
            // marginTop: LIKE_BTN_MARGIN_V,
            marginBottom: LIKE_BTN_MARGIN_V,
            zIndex: 1,
            // marginRight: ASSETDISPLAY_MARGIN,
          }}
        >
          {/* Favorite 버튼 */}
          <FavoriteBtn
            saleId={sFetchedDetailData.ast_info.sale_id}
            width={LIKE_BTN_WIDTH}
            height={LIKE_BTN_HEIGHT}
            onToggle={(newInterest) => {
              hMarketPlaceState.changeMarketPlaceInfo({ sale_id: sFetchedDetailData.sale_id }, { favorite: newInterest });
            }}
            defaultIsFavorite={sFetchedDetailData.favorite}
            rounded={false}
          />
          <WIDTH size={4} />
          {/* 공유하기 */}
          <FlexDiv
            ref={shareBtnRef}
            onClick={(e) => {
              DBGMSG('share onClick');

              e.stopPropagation();
            }}
          >
            <ImgBtn
              src={[R.images.asset_ic_share3x, LIKE_BTN_WIDTH, LIKE_BTN_WIDTH]}
              containerStyle={{ borderWidth: 1, borderStyle: 'solid', borderColor: R.colors.line, borderRadius: '5px', alignItems: 'center' }}
            />
          </FlexDiv>

          <Overlay
            target={shareBtnRef.current}
            show={sShareMenuOpen}
            placement="bottom-end"
            // placement="auto"
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  zIndex: 1,
                  paddingTop: DROPDOWN_INPUT_PADDING_TOP,
                }}
              >
                <CommonColumnBox
                  ref={sharePopupBtnRef}
                  style={{
                    // position: 'absolute',
                    // top: BUY_BTN_HEIGHT,
                    // left: BUY_BTN_WIDTH + BUY_BTN_MARGIN_RIGHT + LIKE_BTN_WIDTH,
                    // bottom: 0,
                    // right: 0,
                    width: SHARE_DROPDOWN_INPUT_WIDTH,
                    // height: ETC_DROPDOWN_INPUT_HEIGHT,
                    // ...devOutlineFG,
                  }}
                >
                  <HEIGHT size={ETC_DROPDOWN_PADDING_V} />
                  <TxtImgBtnWithMouseOverAni
                    size={DROPDOWN_MENU_FONTSIZE}
                    color={R.colors.black}
                    isBold
                    // todo
                    text={hR.strings.MARKET_COPY_LINK_BTN}
                    img={R.images.asset_ic_copy3x}
                    imgSize={DROPDOWN_IMG_WIDTH}
                    onMouseDefalutProp={{ backgroundColor: 'white' }}
                    onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
                    onMouseLeaveProp={{ backgroundColor: 'white' }}
                    containerStyle={{
                      width: '100%',
                      height: SHARE_DROPDOWN_MENU_HEIGHT,
                      paddingLeft: DROPDOWN_MENU_PADIING_LEFT,
                      justifyContent: 'center',
                      // ...devOutlineF
                    }}
                    textStyle={{ paddingLeft: DROPDOWN_TEXT_PADDING_LEFT }}
                    onClick={(e) => {
                      DBGMSG(sFetchedDetailData.sale_id);
                      setClipboard(sFetchedDetailData.sale_id);
                      // hDlgFactory.showReport({
                      //   saleId: sFetchedDetailData.ast_info.sale_id,
                      //   onReport: (select) => {
                      //     DBGMSG(`TODO Report RPC call: ${select}`);
                      //   },
                      //   onHide: () => {
                      //     hDlgFactory.hideReport();
                      //   },
                      // });
                      e.stopPropagation();
                    }}
                  />
                  <TxtImgBtnWithMouseOverAni
                    size={DROPDOWN_MENU_FONTSIZE}
                    color={R.colors.black}
                    isBold
                    // todo
                    text={hR.strings.MARKET_SHARE_TWITTER_BTN}
                    img={R.images.asset_ic_twitter3x}
                    imgSize={DROPDOWN_IMG_WIDTH}
                    onMouseDefalutProp={{ backgroundColor: 'white' }}
                    onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
                    onMouseLeaveProp={{ backgroundColor: 'white' }}
                    containerStyle={{
                      width: '100%',
                      height: SHARE_DROPDOWN_MENU_HEIGHT,
                      paddingLeft: DROPDOWN_MENU_PADIING_LEFT,
                      justifyContent: 'center',
                      // ...devOutlineF
                    }}
                    textStyle={{ paddingLeft: DROPDOWN_TEXT_PADDING_LEFT }}
                    onClick={(e) => {
                      shareTwitter(``, sFetchedDetailData.sale_id);
                      // hDlgFactory.showReport({
                      //   saleId: sFetchedDetailData.ast_info.sale_id,
                      //   onReport: (select) => {
                      //     DBGMSG(`TODO Report RPC call: ${select}`);
                      //   },
                      //   onHide: () => {
                      //     hDlgFactory.hideReport();
                      //   },
                      // });
                      // e.stopPropagation();
                    }}
                  />

                  <HEIGHT size={ETC_DROPDOWN_PADDING_V} />
                </CommonColumnBox>
              </div>
            )}
          </Overlay>
          <WIDTH size={4} />
          {/* 신고하기 */}
          {/* todo refactoring */}
          <FlexDiv
            ref={reportBtnRef}
            onClick={(e) => {
              DBGMSG('report onClick');
              e.stopPropagation();
            }}
          >
            <ImgBtn
              src={[R.images.asset_ic_etc3x, LIKE_BTN_WIDTH, LIKE_BTN_WIDTH]}
              containerStyle={{ borderWidth: 1, borderStyle: 'solid', borderColor: R.colors.line, borderRadius: '5px', alignItems: 'center' }}
            />
          </FlexDiv>

          <Overlay
            target={reportBtnRef.current}
            show={sReportMenuOpen}
            placement="bottom-end"

            // placement="auto"
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  zIndex: 1,
                  paddingTop: DROPDOWN_INPUT_PADDING_TOP,
                }}
              >
                <CommonColumnBox
                  ref={reportPopupBtnRef}
                  style={{
                    // position: 'absolute',
                    // top: BUY_BTN_HEIGHT,
                    // left: BUY_BTN_WIDTH + BUY_BTN_MARGIN_RIGHT + LIKE_BTN_WIDTH,
                    // bottom: 0,
                    // right: 0,
                    width: ETC_DROPDOWN_INPUT_WIDTH,
                    // height: ETC_DROPDOWN_INPUT_HEIGHT,
                    // ...devOutlineFG,
                  }}
                >
                  <HEIGHT size={ETC_DROPDOWN_PADDING_V} />
                  <TxtBtnWithMouseOverAni
                    size={DROPDOWN_MENU_FONTSIZE}
                    color={R.colors.black}
                    isBold
                    // todo
                    text={hR.strings.MARKET_REPORT_BTN}
                    onMouseDefalutProp={{ backgroundColor: 'white' }}
                    onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
                    onMouseLeaveProp={{ backgroundColor: 'white' }}
                    containerStyle={{
                      width: '100%',
                      height: ETC_DROPDOWN_MENU_HEIGHT,
                      paddingLeft: DROPDOWN_MENU_PADIING_LEFT,
                      justifyContent: 'center',
                      // ...devOutlineF
                    }}
                    onClick={(e) => {
                      hDlgFactory.showReport({
                        saleId: sFetchedDetailData.ast_info.sale_id,
                        onReport: (select) => {
                          DBGMSG(`TODO Report RPC call: ${select}`);
                        },
                        onHide: () => {
                          hDlgFactory.hideReport();
                        },
                      });
                      e.stopPropagation();
                    }}
                  />

                  <HEIGHT size={ETC_DROPDOWN_PADDING_V} />
                </CommonColumnBox>
              </div>
            )}
          </Overlay>
        </FlexRowDiv>
      );
    }
  };
  /**************************************
   * !! render conf
   **************************************/
  if (!sFetchedDetailData) return <DataFetching />;

  if (!sDisplaySrcList) {
    DBGMSGW(sDisplaySrcList);
    return null;
  }

  // 소유자 여부
  const isOwner = hAuth.authCommonRes?.ucode === sFetchedDetailData.ast_info.owner.ucode;

  DBGMSG('render');
  return (
    <>
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            // ...devOutline,
          }}
        >
          <BackBtn />
          {ScreenInfo.isMobile() && renderBtnSet()}
          <HEIGHT size={BACKBTN_MARGIN_BOTTOM} />

          <FlexRowDiv style={{ width: CLAY.BODY_WIDTH, flexWrap: 'wrap' }}>
            {/* left container */}
            <FlexDiv
              style={{
                width: LEFT_CONTAINER_WIDTH,
                // ...devOutlineF
              }}
            >
              <CommonColumnBox style={{ width: LEFT_CONTAINER_WIDTH, height: LEFT_CONTAINER_HEIGHT }}>
                {/******************************************
                 * AssetDisplay
                 *******************************************/}
                <FlexDiv
                  style={{
                    margin: ASSETDISPLAY_MARGIN,
                    marginTop: 40,
                    //  ...devOutlineF
                  }}
                >
                  <AssetDisplay
                    key={`${sSelectedDisplayThumbIdx}`}
                    playerSrc={sDisplaySrcList[sSelectedDisplayThumbIdx]}
                    width={ASSETDISPLAY_SIZE}
                    height={ASSETDISPLAY_SIZE}
                    onImgFullscreen={(src) => {}}
                    isBlock={sFetchedDetailData.ast_info.blocked}
                  />
                </FlexDiv>
                {/* AssetDisplay end */}

                <FlexRowDiv
                  style={{
                    width: LEFT_CONTAINER_WIDTH,
                    alignItems: 'center',
                    justifyContent: 'center',
                    // ...devOutlineF,
                  }}
                >
                  {/* < left 버튼 */}
                  <ImgBtn
                    src={[
                      sSliderCurIdx === 0 ? R.images.common_btn_back_disabled3x : R.images.common_btn_back_abled3x,
                      ARROW_BTN_SIZE,
                      ARROW_BTN_SIZE,
                    ]}
                    containerStyle={{
                      //
                      padding: ARROW_BTN_PADDING,
                      flex: '0 0 auto',
                      // opacity: 1 + sOptMediaOnlyAttrList.length > 4 ? 1 : 0,
                      // ...devOutlineF,
                    }}
                    onClick={() => {
                      if (sSliderCurIdx === 0) return;
                      assetDisplayThumbListSliderRef.current?.slickPrev();
                    }}
                  />

                  {/*******************************************
                   * AssetDisplay Thumb 리스트
                   *******************************************/}
                  <FlexColumnDiv
                    // {...hDrag()}
                    style={{
                      width: ASSETDISPLAY_THUMB_LIST_SIZE,
                      overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤?이 생김
                      justifyContent: 'center',
                      // ...devOutlineF,
                    }}
                  >
                    <Slider
                      ref={assetDisplayThumbListSliderRef}
                      infinite={false}
                      speed={400}
                      slidesToShow={ASSETDISPLAY_THUMB_CNT}
                      swipeToSlide={true}
                      slidesToScroll={1}
                      beforeChange={(cur, next) => {
                        DBGMSG(`cur:${cur} next:${next}`);
                        setSliderCurIdx(next);
                      }}
                      afterChange={(cur) => {
                        DBGMSG(`cur:${cur}`);
                        setSliderCurIdx(cur);
                      }}
                    >
                      {sDisplaySrcList.map((displaySrc, idx) => {
                        return (
                          <AssetDisplayThumb
                            whereToUse={WhereToUse_e.ForSlider}
                            key={`${idx}`}
                            width={ASSETDISPLAY_THUMB_SIZE}
                            height={ASSETDISPLAY_THUMB_SIZE}
                            playerSrc={displaySrc}
                            onClick={() => {
                              setSelectedDisplayThumbIdx(idx);
                            }}
                            selected={sSelectedDisplayThumbIdx === idx}
                          />
                        );
                      })}
                    </Slider>
                  </FlexColumnDiv>
                  {/* AssetDisplay 리스트 end */}

                  {/* > right 버튼 */}
                  <ImgBtn
                    src={[
                      sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length
                        ? R.images.common_btn_next_disabled3x
                        : R.images.common_btn_next_abled3x,
                      ARROW_BTN_SIZE,
                      ARROW_BTN_SIZE,
                    ]}
                    containerStyle={{
                      padding: ARROW_BTN_PADDING,
                      flex: '0 0 auto',
                      // ...devOutlineF,
                    }}
                    onClick={() => {
                      if (sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length) return;
                      assetDisplayThumbListSliderRef.current?.slickNext();
                    }}
                  />
                </FlexRowDiv>
              </CommonColumnBox>
            </FlexDiv>

            {/* right container */}
            <FlexColumnDiv
              style={{
                width: RIGHT_CONTAINER_WIDHT,
                marginTop: ScreenInfo.isMobile() ? 20 : undefined,
                marginLeft: ScreenInfo.isDesktop() ? 30 : undefined,
                // ...devOutlineF
              }}
            >
              {ScreenInfo.isDesktop() && renderBtnSet()}
              {/* 박스위 정보 */}
              <FlexColumnDiv style={{ paddingLeft: OVERBOX_INFO_PADDING_LEFT }}>
                {/* 컬렉션 정보 */}
                <FlexRowDiv
                  style={{ height: COL_NAME_HEIGHT, cursor: 'pointer' }}
                  onClick={() => {
                    hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: sFetchedDetailData.ast_info.col_info.cid }));
                  }}
                >
                  {sFetchedDetailData.ast_info.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
                    <>
                      <Img src={[R.images.common_ic_verify3x, COL_IMG_SIZE, COL_IMG_SIZE]} />
                      <WIDTH size={6} />
                    </>
                  )}
                  <TextCon text={sFetchedDetailData.ast_info.col_info.dp_name} isBold size={COL_FONT_SIZE} color={R.colors.purpleishBlue} />
                </FlexRowDiv>
                <HEIGHT size={COL_NAME_MARGIN_BOTTOM} />
                {/* 어셋 이름 */}
                <TextCon
                  text={Utils.svc.getAssetNameFromAttrList(sFetchedDetailData.ast_info.attr_list)}
                  isBold
                  size={ASSET_NAME_FONT_SIZE}
                  color={R.colors.black}
                />
                <HEIGHT size={ASSET_NAME_MARGIN_BOTTOM} />
                <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row' }}>
                  {/* 판매자 */}
                  <FlexDiv style={{ flex: 1 }}>
                    <UserThumbName
                      thumb={null}
                      kind={'SELLER'}
                      // userId={sFetchedDetailData.ast_info.owner.ucode}
                      userId={sFetchedDetailData.seller}
                      onClick={() => {
                        hHistory.push(
                          // Nav.brewUserDetailScreenPath({ ucode: sFetchedDetailData.ast_info.owner.ucode, menu: UserDetailMenuType_e.INVEN })
                          Nav.brewUserDetailScreenPath({ ucode: sFetchedDetailData.seller, menu: UserDetailMenuType_e.INVEN })
                        );
                      }}
                    />
                  </FlexDiv>
                  {ScreenInfo.isMobile() && <HEIGHT size={8} />}
                  {/* 원작자 */}
                  <FlexDiv style={{ flex: 1 }}>
                    <UserThumbName
                      thumb={null}
                      kind={'CREATOR'}
                      userId={sFetchedDetailData.ast_info.creator.ucode}
                      onClick={() => {
                        hHistory.push(
                          Nav.brewUserDetailScreenPath({ ucode: sFetchedDetailData.ast_info.creator.ucode, menu: UserDetailMenuType_e.INVEN })
                        );
                      }}
                    />
                  </FlexDiv>
                </FlexDiv>
              </FlexColumnDiv>
              <HEIGHT size={BOX_MARGIN_TOP} />

              {/* 가격 및 구입하기 버튼 */}
              <CommonRowBox
                style={{
                  width: MARKET_ASSET_R_SALE_BOX_WIDTH,
                  height: MARKET_ASSET_R_SALE_BOX_HEIGHT,
                  alignItems: 'cetner',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  padding: BOX_PADDING_H,
                }}
              >
                <FlexRowDiv
                  style={{
                    width: 248,
                    // ...devOutlineF
                  }}
                >
                  {/*  Asset 가격 */}
                  <FlexRowDiv
                    style={{
                      width: 248,
                      // height: PRICE_HEIGHT,
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      // ...devOutlineF,
                    }}
                  >
                    {sIsSoldout ? (
                      <>
                        <TextCon
                          //
                          color={R.colors.pastelRed}
                          isBold
                          size={CLAY.DEFAULT_FONT_SIZE}
                          text={hR.strings.MARKET_ASSET_READ_SOLD}
                          containerStyle={
                            {
                              // ...devOutlineFB,
                            }
                          }
                        />
                      </>
                    ) : (
                      <>
                        {/*  Asset 가격 */}
                        <FlexRowDiv
                          style={{
                            // width: MARKET_ASSET_CARD_BODY_WIDTH,
                            // height: PRICE_HEIGHT,
                            alignItems: 'center',
                            // ...devOutlineF,
                          }}
                        >
                          {/* 화폐 아이콘 */}
                          <Img
                            src={[Utils.svc.getCurrencyDpInfo(sFetchedDetailData.prc_info.curr_info.currency).icSrc, KLAY_IC_WIDTH, KLAY_IC_HEIGHT]}
                          />
                          <WIDTH size={3} />

                          {/* 가격 (달러) */}
                          <FlexRowDiv
                            style={{
                              alignItems: 'baseline',
                              ...devOutline,
                            }}
                          >
                            <TextCon
                              // color={assetInfo.priceInfo.currency === CbtCurrency_e.ETH ? R.colors.purpleishBlue : R.colors.klaytn}
                              color={R.colors.black}
                              isBold
                              size={PRICE_FONTSIZE}
                              text={`${Utils.currency.getPriceForDP({
                                prcInfo: sFetchedDetailData.prc_info,
                                decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
                              })}`}
                            />
                            <WIDTH size={3} />
                            <TextCon
                              // color={assetInfo.priceInfo.currency === CbtCurrency_e.ETH ? R.colors.purpleishBlue : R.colors.klaytn}
                              color={R.colors.black}
                              isBold
                              size={PRICESUB_FONTSIZE}
                              text={Utils.svc.getCurrencyDpInfo(sFetchedDetailData.prc_info.curr_info.currency).unit}
                            />
                            <WIDTH size={3} />
                            <TextCon
                              //
                              color={R.colors.black}
                              size={PRICESUB_FONTSIZE}
                              text={getUsdDollar()}
                            />
                          </FlexRowDiv>
                        </FlexRowDiv>
                      </>
                    )}
                  </FlexRowDiv>
                </FlexRowDiv>

                {ScreenInfo.isMobile() && <HEIGHT size={14} />}

                {/* 구매하기 버튼 */}
                {sIsSoldout ? (
                  <></>
                ) : (
                  // <FlexColumnDiv
                  //   style={{
                  //     justifyContent: 'center',
                  //     // alignItems: 'flex-end',
                  //     marginLeft: 'auto',
                  //     // ...devOutlineF,
                  //   }}
                  // >
                  //   <UserThumbName
                  //     userId={sFetchedDetailData.ast_info.owner.ucode}
                  //     thumb={null}
                  //     kind={'BUYER'}
                  //     onClick={() => {
                  //       hHistory.push(
                  //         Nav.brewUserDetailScreenPath({
                  //           ucode: sFetchedDetailData.ast_info.owner.ucode,
                  //         })
                  //       );
                  //     }}
                  //     containerStyle={{
                  //       width: TH_ITEM_HEIGHT,
                  //       minWidth: 180,
                  //       //
                  //     }}
                  //   />
                  // </FlexColumnDiv>
                  <RoundTxtBtn
                    // enable={false}
                    enable={sFetchedDetailData.ast_info.blocked === false}
                    width={SALE_BTN_WIDTH}
                    height={SALE_BTN_HEIGHT}
                    fontSize={SALE_BTN_FONTSIZE}
                    text={hR.strings.MARKET_ASSET_READ_BUY_BTN}
                    containerStyle={{
                      marginLeft: 'auto',
                      // ...devOutlineF
                    }}
                    onClick={() => {
                      handleBuyBtnOnClick();
                    }}
                  />
                )}
              </CommonRowBox>

              <HEIGHT size={BOX_MARGIN_TOP} />

              {/* 박스 */}
              <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDHT }}>
                {/* 스키마 디스플레이 이름 */}
                <TextCon
                  size={SCHM_NAME_FONTSIZE}
                  color={R.colors.purpleishBlue}
                  isBold
                  text={sFetchedDetailData.ast_info.schm_info.dp_name}
                  textStyle={{ height: SCHM_NAME_HEIGHT }}
                  containerStyle={{
                    paddingLeft: BOX_PADDING_H,
                    paddingBottom: SCHM_NAME_VMARGIN,
                    paddingTop: SCHM_NAME_VMARGIN,
                  }}
                  onConClick={() => {
                    hHistory.push(Nav.brewMarketSchemaRScreenPath({ sid: sFetchedDetailData.ast_info.schm_info.sid }));
                  }}
                />
                {/* 라인 ----------------------------------------------------  */}
                <HLINE size={'100%'} color={R.colors.line} />
                <FlexColumnDiv style={{ paddingLeft: BOX_PADDING_H, paddingRight: BOX_PADDING_H }}>
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 에셋 아이디 */}
                  <LabelValue
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_ASSET_ID}
                    value={`${sFetchedDetailData.ast_info.asset_id}`}
                    titleSize={LV_TITLE_FONTSIZE}
                    valueSize={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 판매 아이디 */}
                  <LabelValue
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.MARKET_ASSET_READ_SALE_ID}
                    value={`${sFetchedDetailData.ast_info.sale_id}`}
                    titleSize={LV_TITLE_FONTSIZE}
                    valueSize={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 에셋타입 */}
                  <LabelValue
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_ASSET_TYPE}
                    value={hR.strings.getAssetType(sFetchedDetailData.ast_info.schm_info.asset_type)}
                    titleSize={LV_TITLE_FONTSIZE}
                    valueSize={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 발행수 */}
                  <LabelValueMint
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_MINT_CNT}
                    value={{
                      nOf: sFetchedDetailData.ast_info.mint_no,
                      total: sFetchedDetailData.ast_info.issue_tcnt,
                      can_addition: sFetchedDetailData.ast_info.can_addition,
                      mintMore: `${sFetchedDetailData.ast_info.can_addition ? `(${hR.strings.AC_ASSET_READ_MINT_MORE})` : ''}`,
                    }}
                    titleSize={LV_TITLE_FONTSIZE}
                    value1Size={LV_VALUE_FONTSIZE}
                    value2Size={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  {sFetchedDetailData.ast_info.attr_list.map((att, idx) => {
                    switch (att.type) {
                      case CbtAttributeType_e.TEXT:
                      case CbtAttributeType_e.NUMBER:
                        return (
                          <>
                            <HEIGHT size={LV_MARGIN_V} />
                            <LabelValue
                              flexDir={LV_FLEX_DIR}
                              title={Utils.svc.convertIfReqAttrName(att.name)}
                              value={`${att.value}`}
                              titleSize={LV_TITLE_FONTSIZE}
                              valueSize={LV_VALUE_FONTSIZE}
                              titleWidth={LV_TITLE_WIDTH}
                              valueWidth={LV_VALUE_WIDTH}
                              titleHeight={LV_TITLE_HEIGHT}
                              valueHeight={LV_VALUE_HEIGHT}
                            />
                          </>
                        );

                      case CbtAttributeType_e.IMAGE:
                      case CbtAttributeType_e.AUDIO:
                      case CbtAttributeType_e.VIDEO:
                        return (
                          <>
                            <HEIGHT size={LV_MARGIN_V} />
                            <LabelValue
                              flexDir={LV_FLEX_DIR}
                              title={Utils.svc.convertIfReqAttrName(att.name)}
                              // value={`${Utils.string.shortString(Utils.ipfs.getIpfsHash(att.value), 10, 10)}`}
                              value={`${Utils.string.shortString(Utils.ipfs.getIpfsHash(att.value), 10, 10)}`}
                              titleSize={LV_TITLE_FONTSIZE}
                              valueSize={LV_VALUE_FONTSIZE}
                              titleWidth={LV_TITLE_WIDTH}
                              valueWidth={LV_VALUE_WIDTH}
                              titleHeight={LV_TITLE_HEIGHT}
                              valueHeight={LV_VALUE_HEIGHT}
                            />
                          </>
                        );
                    }
                  })}
                  <HEIGHT size={LV_MARGIN_V} />
                </FlexColumnDiv>
              </CommonColumnBox>

              <HEIGHT size={BOX_MARGIN_BOTTOM} />
            </FlexColumnDiv>
          </FlexRowDiv>

          {/* 거래내역 */}
          <HEIGHT size={TITLE_MARGIN_TOP} />
          <Title title={hR.strings.AC_ASSET_READ_TRADING_HISTORY} />
          <HEIGHT size={TITLE_MARGIN_BOTTIM} />

          <CommonColumnBox
            style={{
              width: CLAY.BODY_WIDTH,
              minHeight: 430,
              overflowX: 'scroll',
              // ...devOutline,
            }}
          >
            <FlexRowDiv style={{ width: TH_WIDTH }}>
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_EVENT}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_EVETN_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_PRICE}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_PRICE_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_FROM}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_FROM_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_TO}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_TO_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_DATE}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_DATE_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
            </FlexRowDiv>

            {sFetchedSaleHistoryData &&
              sFetchedSaleHistoryData.trd_history.map((trading, idx) => {
                return (
                  <>
                    {/* 라인 */}
                    <HLINE size={'100%'} />
                    <FlexRowDiv>
                      <TextCon
                        text={hR.strings.MARKET_ASSET_READ_TRADING_HISTORY_EVENT_SALE}
                        size={CLAY.DEFAULT_FONT_SIZE}
                        color={R.colors.black}
                        isBold
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_EVETN_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                      <PriceAdv
                        prc_info={trading.prc_info}
                        icSizeWidth={18}
                        icSizeHeight={20}
                        priceFontSize={14}
                        unitFontSize={14}
                        isBold={false}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_PRICE_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                      <UserThumbName
                        userId={trading.from.ucode}
                        thumb={trading.from.prof}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_FROM_WIDHT, paddingLeft: BOX_PADDING_H }}
                        isBold={false}
                        onClick={() => {
                          hHistory.push(Nav.brewUserDetailScreenPath({ ucode: trading.from.ucode, menu: UserDetailMenuType_e.INVEN }));
                        }}
                      />
                      <UserThumbName
                        userId={trading.to.ucode}
                        thumb={trading.to.prof}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_TO_WIDHT, paddingLeft: BOX_PADDING_H }}
                        isBold={false}
                        onClick={() => {
                          hHistory.push(Nav.brewUserDetailScreenPath({ ucode: trading.to.ucode, menu: UserDetailMenuType_e.INVEN }));
                        }}
                      />
                      <TextCon
                        text={trading.time === 0 ? '-' : Utils.date.brewFomatString(trading.time)}
                        size={CLAY.DEFAULT_FONT_SIZE}
                        color={R.colors.black}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_DATE_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                    </FlexRowDiv>
                  </>
                );
              })}
            <HLINE size={CLAY.PAGE_WIDTH} />

            {sFetchedSaleHistoryData && sFetchedSaleHistoryData.trd_history.length === 0 && (
              <FlexColumnDiv
                style={{
                  flex: 1,
                  alignSelf: 'stretch',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // ...devOutlineF,
                }}
              >
                <TextCon text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_NO_DATA} isBold color={R.colors.blueGrey} size={CLAY.DEFAULT_FONT_SIZE} />
              </FlexColumnDiv>
            )}
          </CommonColumnBox>
          <HEIGHT size={TH_MARGIN_BOTTOM} />

          {/* 거래내역 페이징 */}
          {sFetchedSaleHistoryData && sFetchedSaleHistoryData.trd_history.length > 0 && (
            <FlexRowDiv style={{ alignSelf: 'center' }}>
              <ImgBtn
                src={[sTradingPageno === 1 ? R.images.common_btn_back_disabled3x : R.images.common_btn_back_abled3x, ARROW_BTN_SIZE, ARROW_BTN_SIZE]}
                onClick={() => {
                  if (sTradingPageno === 1) return;
                }}
                enable={sTradingPageno !== 1}
              />
              <FlexRowDiv
                style={{
                  width: 100,
                  height: TH_PAGING_HEIGHT,
                  borderRadius: TH_PAGING_HEIGHT / 2,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: R.colors.line,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: TH_PAGING_MARIGN_V,
                  marginLeft: TH_PAGING_MARIGN_V,
                  backgroundColor: R.colors.white,
                }}
              >
                <TextCon text={`${sTradingPageno}`} isBold size={CLAY.DEFAULT_FONT_SIZE} color={R.colors.black} />
              </FlexRowDiv>
              <ImgBtn
                src={[
                  sIsLastPageSaleHistory ? R.images.common_btn_next_disabled3x : R.images.common_btn_next_abled3x,
                  ARROW_BTN_SIZE,
                  ARROW_BTN_SIZE,
                ]}
                onClick={() => {
                  if (sIsLastPageSaleHistory === true) return;
                  if (!sQS) return;
                  fetchSaleHistoryNext({ sale_id: sQS.sale_id, pg_info: sFetchedSaleHistoryData.pg_info });
                }}
                enable={sIsLastPageSaleHistory !== true}
              />
            </FlexRowDiv>
          )}

          {/* 거래 가격 그래프 */}
          <HEIGHT size={TITLE_MARGIN_TOP} />
          {ScreenInfo.isDesktop() || ScreenInfo.isTablet() ? (
            <>
              <FlexRowDiv style={{ alignItems: 'baseline' }}>
                <Title
                  title={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY}
                  containerStyle={
                    {
                      // ...devOutlineF,
                    }
                  }
                />
                <WIDTH size={16} />
                <TooltipIc icSize={16} containerStyle={{ alignSelf: 'center', marginTop: 13 }} />
                <WIDTH size={3} />
                <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_SUB} size={16} color={R.colors.blueGrey} />
              </FlexRowDiv>
            </>
          ) : (
            <>
              <FlexColumnDiv>
                <Title
                  title={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY}
                  containerStyle={
                    {
                      // ...devOutlineF,
                    }
                  }
                />
                <FlexRowDiv style={{ alignItems: 'flex-start' }}>
                  <TooltipIc icSize={16} />
                  <WIDTH size={3} />
                  <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_SUB} size={12} color={R.colors.blueGrey} />
                </FlexRowDiv>
              </FlexColumnDiv>
            </>
          )}

          <HEIGHT size={TITLE_MARGIN_BOTTIM} />
          {(!sTrdGraphItemList || sTrdGraphItemList.length === 0) && (
            <CommonColumnBox
              style={{
                width: CLAY.BODY_WIDTH,
                minHeight: 180,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_NO_DATA} size={14} color={R.colors.blueGrey} isBold />
            </CommonColumnBox>
          )}

          {sTrdGraphItemList && sTrdGraphItemList.length > 0 && (
            <>
              {ScreenInfo.isMobile() ? (
                <FlexColumnDiv style={{ width: CLAY.BODY_WIDTH }}>
                  {/* 최소 거래 가격 */}
                  <FlexRowDiv style={{ width: CLAY.BODY_WIDTH, height: 24 }}>
                    <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_LOWEST} size={12} color={R.colors.blueGrey} isBold />
                    <FlexRowDiv style={{ alignItems: 'baseline', marginLeft: 'auto' }}>
                      <TextCon text={getMinPrice()} size={18} color={R.colors.black} isBold />
                      <WIDTH size={3} />
                      <TextCon text={'USD'} size={12} color={R.colors.black} isBold />
                    </FlexRowDiv>
                  </FlexRowDiv>

                  <HEIGHT size={12} />
                  {/* 최대 거래 가격 */}
                  <FlexRowDiv style={{ width: CLAY.BODY_WIDTH, height: 24 }}>
                    <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_HIGHEST} size={12} color={R.colors.blueGrey} isBold />
                    <FlexRowDiv style={{ alignItems: 'baseline', marginLeft: 'auto' }}>
                      <TextCon text={getMaxPrice()} size={18} color={R.colors.black} isBold />
                      <WIDTH size={3} />
                      <TextCon text={'USD'} size={12} color={R.colors.black} isBold />
                    </FlexRowDiv>
                  </FlexRowDiv>

                  <HEIGHT size={12} />
                  {/* 총 거래된 수 */}
                  <FlexRowDiv style={{ width: CLAY.BODY_WIDTH, height: 24 }}>
                    <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_TX_CNT} size={12} color={R.colors.blueGrey} isBold />
                    <FlexRowDiv style={{ alignItems: 'baseline', marginLeft: 'auto' }}>
                      <TextCon text={getTrCnt()} size={18} color={R.colors.black} isBold />
                    </FlexRowDiv>
                  </FlexRowDiv>
                </FlexColumnDiv>
              ) : (
                <FlexRowDiv style={{ width: CLAY.BODY_WIDTH }}>
                  {/* 최소 거래 가격 */}
                  <FlexColumnDiv style={{ width: CLAY.BODY_WIDTH / 3 }}>
                    <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_LOWEST} size={14} color={R.colors.blueGrey} isBold />
                    <HEIGHT size={10} />
                    <FlexRowDiv style={{ alignItems: 'baseline' }}>
                      <TextCon text={getMinPrice()} size={22} color={R.colors.black} isBold />
                      {/* <TextCon text={'0'} size={22} color={R.colors.black} isBold /> */}
                      <WIDTH size={3} />
                      <TextCon text={'USD'} size={16} color={R.colors.black} isBold />
                    </FlexRowDiv>
                  </FlexColumnDiv>
                  {/* 최대 거래 가격 */}
                  <FlexColumnDiv style={{ width: CLAY.BODY_WIDTH / 3 }}>
                    <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_HIGHEST} size={14} color={R.colors.blueGrey} isBold />
                    <HEIGHT size={10} />
                    <FlexRowDiv style={{ alignItems: 'baseline' }}>
                      <TextCon text={getMaxPrice()} size={22} color={R.colors.black} isBold />
                      {/* <TextCon text={'0'} size={22} color={R.colors.black} isBold /> */}
                      <WIDTH size={3} />
                      <TextCon text={'USD'} size={16} color={R.colors.black} isBold />
                    </FlexRowDiv>
                  </FlexColumnDiv>

                  {/* 총 거래된 수 */}
                  <FlexColumnDiv style={{ width: CLAY.BODY_WIDTH / 3 }}>
                    <TextCon text={hR.strings.MARKET_ASSET_READ_PRICE_HISTORY_TX_CNT} size={14} color={R.colors.blueGrey} isBold />
                    <HEIGHT size={10} />
                    <FlexRowDiv style={{ alignItems: 'baseline' }}>
                      <TextCon text={getTrCnt()} size={22} color={R.colors.black} isBold />
                      {/* <TextCon text={'0'} size={22} color={R.colors.black} isBold /> */}
                    </FlexRowDiv>
                  </FlexColumnDiv>
                </FlexRowDiv>
              )}

              <HEIGHT size={30} />

              {/* Price History - chart */}
              <FlexColumnDiv style={{ width: ScreenInfo.contentsWidth, minHeight: 320, overflow: 'scroll' }}>
                <ResponsiveContainer width={DESKTOP_WIDTH} height="100%">
                  <ComposedChart
                    // width={500}
                    // height={300}
                    data={sTrdGraphItemList}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 10,
                      bottom: 20,
                    }}
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#E7EAFF" stopOpacity={0.2} />
                        <stop offset="95%" stopColor="#E7EAFF" stopOpacity={0.2} />
                      </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} stroke={R.colors.veryLightGrey2} strokeWidth={1} />
                    {/* <CartesianAxis y={1000} width={100}  /> */}
                    <XAxis
                      //
                      stroke={R.colors.veryLightGrey2}
                      strokeWidth={2}
                      type={'number'}
                      dataKey={'x_time'}
                      domain={['dataMin - 4000', 'dataMax']}
                      // label={<Txt text={'kkk'} />}
                      // label={{ value: 'XAxis Label' }}
                      tick={<PriceHistoryChartXAxisTick />}
                      tickLine={false}
                      ticks={[...sTrdGraphItemList.map((v) => v.x_time)]}
                    />
                    <YAxis
                      //
                      orientation={'right'}
                      stroke={R.colors.veryLightGrey2}
                      strokeWidth={2}
                      domain={[0, 'dataMax + 10']}
                      // domain={['dataMin', 'dataMax']}
                      // domain={[1000, 5000]}
                      tickLine={false}
                      tick={<PriceHistoryChartYAxisTick />}
                      interval={0}
                      // minTickGap={1000}
                      // ticks={[...data.map((v) => v.uv)]}
                    />
                    <Tooltip content={<PriceHistoryChartTooltip />} />
                    {/* <Legend /> */}
                    {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                    <Area type="monotone" dataKey={'y_avgPrc'} fillOpacity={1} fill="url(#colorUv)" />
                    <Line
                      type="monotone"
                      dataKey={'y_avgPrc'}
                      stroke={R.colors.purpleishBlue}
                      strokeWidth={3}
                      activeDot={{ r: 10 }}
                      dot={{ stroke: R.colors.purpleishBlue, strokeWidth: 4, fill: R.colors.purpleishBlue }}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </FlexColumnDiv>
            </>
          )}

          <HEIGHT size={BOTTOM_SPACE} />
        </FlexColumnDiv>
        {/* <DlgSale asset={sAssetInfo} show={sDlgSale} /> */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </MobileScalableFlexColumn>
    </>
  );
}
