/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthStorage } from 'src/hooks/useAuthStorage';
import { useKaikas } from 'src/hooks/useKaikas';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { AuthCommonRes_t, AuthStatus } from 'src/model/model';
import { RootState } from 'src/redux/modules';
import authModule from 'src/redux/modules/authModule';
import explorerSlice from 'src/redux/modules/explorerStateModule';
import userDetailMineSlice from 'src/redux/modules/userDetailMineModule';

export function useAuth() {
  const authState = useSelector((state: RootState) => state.authState);
  const dispatch = useDispatch();
  // const thunkDispatch: PADThunkDispatch = useDispatch();
  const hAuthStorage = useAuthStorage();
  const hMyPageState = useUserDetailMineState();

  const hKaikas = useKaikas();

  const authActs = {
    testLogin: useCallback(async () => {
      dispatch(authModule.actions.testloginAct());
    }, [dispatch]),
    login: useCallback(
      async ({ email, authCommonRes }: { email: string; authCommonRes: AuthCommonRes_t }) => {
        hAuthStorage.saveAuthInfo({ email: '', authCommonRes: { ...authCommonRes } });
        dispatch(authModule.actions.loginAct({ email, authCommonRes: authCommonRes }));
      },
      [dispatch]
    ),
    loginWithLocalAuthInfo: useCallback(
      (localAuthInfo: AuthCommonRes_t) => {
        dispatch(authModule.actions.loginAct({ authCommonRes: localAuthInfo }));
      },
      [dispatch]
    ),
    logout: useCallback(() => {
      hAuthStorage.clearAuthInfo();
      dispatch(authModule.actions.logoutAct());
      dispatch(userDetailMineSlice.actions.clear());
      dispatch(explorerSlice.actions.clear());
      hKaikas.disConnectKaikas();
    }, [dispatch, hAuthStorage]),
    updateToken: useCallback(
      (newUserAToken: string) => {
        dispatch(authModule.actions.updateToken({ newToken: newUserAToken }));
      },
      [dispatch]
    ),
    updateAuthCommonRes: useCallback(
      async ({ authCommonRes }: { authCommonRes: AuthCommonRes_t }) => {
        hAuthStorage.saveAuthInfo({ email: '', authCommonRes: { ...authCommonRes } });
        dispatch(authModule.actions.updateAuthCommonRes({ authCommonRes: authCommonRes }));
      },
      [dispatch]
    ),
  };
  const isLogin = useMemo(() => authState.authStatus === AuthStatus.Login && authState.authCommonRes !== undefined, [authState]);

  return {
    authState,
    authActs,
    isLogin,
    authCommonRes: authState.authCommonRes,
    email: authState.email,
    // authTestClientRpc,
    // authenticationRpc,
    // authTestClientTestRpc,
  };
}
