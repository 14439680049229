/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { MySoldCard, MySoldCardProps as MySoldCardProps, MY_SOLD_CARD_HEIGHT } from 'src/ui/screen/AssetMgr/card/MySoldCard';
import { SOLDCARD_HEIGHT, SOLDCARD_WIDTH } from 'src/ui/screen/AssetMgr/card/UserSoldCard';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
export const MYPAGE_SOLD_CARD_PADDING = 15;
export const MYPAGE_SOLD_CARD_CONTAINER_WIDTH = SOLDCARD_WIDTH + MYPAGE_SOLD_CARD_PADDING * 2;
export const MYPAGE_SOLD_CARD_CONTAINER_HEIGHT = MY_SOLD_CARD_HEIGHT + MYPAGE_SOLD_CARD_PADDING * 2;

export function MySoldCardForList(props: MySoldCardProps) {
  return (
    <FlexDiv
      style={{
        padding: MYPAGE_SOLD_CARD_PADDING,
        justifyContent: 'center',
        alignItems: 'center',
        ...devOutline,
      }}
    >
      <MySoldCard {...props} />
    </FlexDiv>
  );
}
