/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, HEIGHT } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { CommonColumnBox, PWRESET_SCREEN_LAYOUT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserResetPasswordScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sEmail, setEmail] = useState<string>('');
  const [sInvalidEmailMsg, setInvalidEmailMsg] = useState(false);

  const [sEmailInputEnable, setEmailInputEnable] = useState(true);
  const [sResetBtnEnabled, setResetBtnEnabled] = useState(false);

  const [sComplete, setComplete] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const emailInputRef = useRef<HTMLInputElement | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User Reset Password - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hR = useLocalSettings();
  const hHistory = useHistory();
  const hRpc = useRpc();
  // const { pageWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { email?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.email) {
      DBGMSG(`TemplatePage email: ${query.email}`);
      const email = query.email;
      setEmail(email);
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    DBGMSG('useEffect()');
    if (sEmail.length > 0 && sInvalidEmailMsg === false) {
      setResetBtnEnabled(true);
    } else {
      setResetBtnEnabled(false);
    }

    return () => {};
  }, [sEmail.length, sInvalidEmailMsg]);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const commonRender = () => {
    return (
      <FlexColumnDiv
        style={{
          alignItems: 'center',
          paddingTop: PWRESET_SCREEN_LAYOUT.topMargin,
          ...devOutline,
        }}
      >
        <CommonColumnBox
          style={{
            //
            width: PWRESET_SCREEN_LAYOUT.boxWidth,
            // justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: PWRESET_SCREEN_LAYOUT.horizontalPadding,
            paddingRight: PWRESET_SCREEN_LAYOUT.horizontalPadding,
          }}
        >
          <HEIGHT size={PWRESET_SCREEN_LAYOUT.topPadding} />

          {/* 타이틀 */}
          <TextCon text={hR.strings.LOGIN_RESET_PASSWORD} color={R.colors.black} isBold size={PWRESET_SCREEN_LAYOUT.titleFontSize} />
          <HEIGHT size={PWRESET_SCREEN_LAYOUT.titleBottomMargin} />

          {/* EMAIL */}
          <TextCon
            text={hR.strings.LOGIN_RESET_EMAIL}
            size={PWRESET_SCREEN_LAYOUT.formFontSize}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={PWRESET_SCREEN_LAYOUT.formInputTopMargin} />
          <SRoundInputText
            fontSize={PWRESET_SCREEN_LAYOUT.formFontSize}
            width={PWRESET_SCREEN_LAYOUT.formSize.width}
            height={PWRESET_SCREEN_LAYOUT.formSize.height}
            getRef={(ref) => {
              emailInputRef.current = ref;
            }}
            value={sEmail}
            inputType={'email'}
            placeHolder={hR.strings.LOGIN_RESET_EMAIL_PLACEHOLDER}
            onChange={(e) => {
              DBGMSG(`onChange: ${e.target.value} length: ${e.target.value.length}`);
              setEmail(e.target.value);
              if (e.target.value.length > 0) {
                if (!Constants.REGEXP_IS_EMAIL.test(e.target.value)) {
                  setInvalidEmailMsg(true);
                  DBGMSG(`invalid`);
                } else {
                  setInvalidEmailMsg(false);
                  DBGMSG(`valid`);
                }
              } else {
                setInvalidEmailMsg(false);
              }
            }}
            onBlur={() => {}}
            disabled={sEmailInputEnable === false}
          />

          {sInvalidEmailMsg && (
            <>
              <HEIGHT size={PWRESET_SCREEN_LAYOUT.validationMsgTopMargin} />
              <TextCon
                text={hR.strings.COMMON_EMAIL_INVALID}
                size={PWRESET_SCREEN_LAYOUT.validationMsgFontSize}
                color={R.colors.pastelRed}
                containerStyle={{
                  alignSelf: 'flex-start',
                  //  ...devOutlineF
                }}
              />
            </>
          )}

          {sComplete && (
            <>
              <HEIGHT size={PWRESET_SCREEN_LAYOUT.validationMsgTopMargin} />
              <FlexColumnDiv
                style={{
                  alignSelf: 'stretch',
                  borderRadius: 15,
                  backgroundColor: R.colors.lightGrey,
                  padding: 14,
                }}
              >
                <TextCon
                  text={hR.strings.LOGIN_RESET_OK_MSG_1}
                  size={PWRESET_SCREEN_LAYOUT.validationMsgFontSize}
                  color={R.colors.purpleishBlue}
                  isBold
                />
                <HEIGHT size={2} />
                <TextCon
                  text={hR.strings.LOGIN_RESET_OK_MSG_2}
                  size={PWRESET_SCREEN_LAYOUT.validationMsgFontSize}
                  color={R.colors.purpleishBlue}
                  isBold
                />
              </FlexColumnDiv>
            </>
          )}

          <HEIGHT size={PWRESET_SCREEN_LAYOUT.txtBtnVeticalMargin} />

          {/* reset 버튼 */}
          <RoundTxtBtn
            fontSize={PWRESET_SCREEN_LAYOUT.txtBtnFontSize}
            width={PWRESET_SCREEN_LAYOUT.txtBtnSize.width}
            height={PWRESET_SCREEN_LAYOUT.txtBtnSize.height}
            enable={sResetBtnEnabled}
            text={hR.strings.LOGIN_RESET_OK_BTN}
            onClick={async () => {
              if (sComplete) {
                if (timeoutRef.current !== null) {
                  // 타이머 해제
                  clearTimeout(timeoutRef.current);
                  timeoutRef.current = null;
                  hHistory.replace(Nav.brewUserLogin({ email: sEmail }));
                }
              } else {
                // RPC 호출
                hRpc.cobaltRpc
                  .rpcUserPasswordTemp({ email: sEmail, lang: hR.lang })
                  .then((res) => {
                    setComplete(true);
                    setEmailInputEnable(false);
                    timeoutRef.current = setTimeout(() => {
                      hHistory.replace(Nav.brewUserLogin({ email: sEmail }));
                    }, 10000);
                  })
                  .catch((err) => {
                    hRpc.hanleCommonError({ err });
                  });
              }
            }}
          />
          <HEIGHT size={PWRESET_SCREEN_LAYOUT.txtBtnVeticalMargin} />
        </CommonColumnBox>
        <HEIGHT size={PWRESET_SCREEN_LAYOUT.bottomMargin} />
        {/* 공통푸터 */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </FlexColumnDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    return commonRender();
  } else {
    return <MobileScalableFlexColumn>{commonRender()}</MobileScalableFlexColumn>;
  }
}
