/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CbtUserLanguage_e } from 'src/model/rpcModel';
import Strings from 'src/res/strings';

// prettier-ignore
export class StringsEn extends Strings {
  readonly LANG = CbtUserLanguage_e.EN;

  // ------------------------------------------------
  // 공통
  // ------------------------------------------------
  readonly COMMON_BACK = `Back`;
  readonly COMMON_COLLECTION = `Collection`;
  readonly COMMON_COLLECTION_NAME = `Collection Name`;
  readonly COMMON_DISP_NAME = `Display Name`;
  readonly COMMON_ROYALTY = `Royalty`;
  readonly COMMON_WEBSITE = `Website URL`;
  readonly COMMON_DESC = `Description`;
  readonly COMMON_ADMIN = `Admin Account`;
  readonly COMMON_CANNOT_EDIT = `Unable to edit`;
  readonly COMMON_ASSET_NAME = `Asset Name`;
  readonly COMMON_ASSET_MINT_CNT = `Minted Quantity`;
  readonly COMMON_ASSET_MINT_NO_MORE = `No additional minted assets`;
  readonly COMMON_ASSET_MINT_MORE = `Able to additionally mint`;
  readonly COMMON_ASSET_TYPE = `Asset Type`;
  readonly COMMON_ASSET_TYPE_IMAGE = `Image`;
  readonly COMMON_ASSET_TYPE_VIDEO = `Video`;
  readonly COMMON_ASSET_TYPE_AUDIO = `Audio`;
  readonly COMMON_SCHEMA = `Schema`;
  readonly COMMON_SCHEMA_NAME = `Schema Name`;
  readonly COMMON_ATTR = `Attribute and Type`;
  readonly COMMON_ATTR_NAME = `Attribute Name`;
  readonly COMMON_ATTR_TYPE = `Attribute Type`;
  readonly COMMON_ATTR_TYPE_TEXT = `Text`;
  readonly COMMON_ATTR_TYPE_NUMBER = `Number`;
  readonly COMMON_ATTR_TYPE_IMAGE = `Image`;
  readonly COMMON_ATTR_TYPE_VIDEO = `Video`;
  readonly COMMON_ATTR_TYPE_AUDIO = `Audio`;
  readonly COMMON_ATTR_TYPE_IMAGE_DETAIL = `(PNG, JPG, GIF, APNG)`;
  readonly COMMON_ATTR_TYPE_VIDEO_DETAIL = `(MP4)`;
  readonly COMMON_ATTR_TYPE_AUDIO_DETAIL = `(MP3)`;
  readonly COMMON_ASSET = `Asset`;
  readonly COMMON_PRICE_HISTORY_LOWEST = `Lowest Transaction Price`;
  readonly COMMON_PRICE_HISTORY_HIGHEST = `Highest Transaction Price`;
  readonly COMMON_PRICE_HISTORY_TX_CNT = `Total Number of Transactions`;
  readonly COMMON_ASSET_TRADING_HISTORY = `Trading History`;
  readonly COMMON_ASSET_TRADING_HISTORY_EVENT = `Event`;
  readonly COMMON_ASSET_TRADING_HISTORY_PRICE = `Price`;
  readonly COMMON_ASSET_TRADING_HISTORY_FROM = `From`;
  readonly COMMON_ASSET_TRADING_HISTORY_TO = `To`;
  readonly COMMON_ASSET_TRADING_HISTORY_DATE = `Date`;
  readonly COMMON_ASSET_TRADING_HISTORY_NO_DATA = `Trading history is not yet available.`;
  readonly COMMON_BUY_BTN = `Buy`;
  readonly COMMON_CREATOR = `Original Creator`;
  readonly COMMON_OWNER = `Owner`;
  readonly COMMON_SELLER = `Seller`;
  readonly COMMON_BUYER = `Buyer`;
  readonly COMMON_PRICE = `Price`;
  readonly COMMON_TX_HASH = `Tx Hash`;
  readonly COMMON_VIEW_ON_BLOCKS = `View on Blocks`;
  readonly COMMON_MENU_INVENTORY = `Inventory`;
  readonly COMMON_MENU_MINTED = `Minted Assets`;
  readonly COMMON_MENU_ON_SALE = `For Sale`;
  readonly COMMON_MENU_SOLD = `Sold`;
  readonly COMMON_MENU_PURCHASED = `Purchased`;
  readonly COMMON_EMAIL = `Email`;
  readonly COMMON_EMAIL_PLACEHOLDER = `Enter Email`;
  readonly COMMON_EMAIL_INVALID = `Please enter a valid email address`;
  readonly COMMON_PASSWORD = `Password`;
  readonly COMMON_PASSWORD_PLACEHOLDER = `Enter Password`;
  readonly COMMON_SEARCH_PLACEHOLDER = `Search`;
  readonly COMMON_SEARCH_ORDERBY_LISTING_NEWEST = `Newest`;
  readonly COMMON_SEARCH_ORDERBY_LISTING_OLDEST = `Oldest`;
  readonly COMMON_SEARCH_ORDERBY_PRICE_HIGHEST = `Highest to lowest`;
  readonly COMMON_SEARCH_ORDERBY_PRICE_LOWEST = `Lowest to highest`;
  readonly COMMON_PASSWORD_GUIDE_1 = `* Must include English capital letters`;
  readonly COMMON_PASSWORD_GUIDE_2 = `* Must include English lower case letters`;
  readonly COMMON_PASSWORD_GUIDE_3 = `* Must include numbers`;
  readonly COMMON_PASSWORD_GUIDE_4 = `* Must be more than 10 characters`;
  readonly COMMON_OK_BTN_TXT = `Confirm`;
  readonly COMMON_REPORT = `Report`;
  readonly COMMON_INQUERY = `Inquire`;
  readonly COMMON_SALE_BTN = `Sell`;
  readonly COMMON_SALE_EDIT_BTN = `Edit sale`;
  readonly COMMON_NOTI_TITLE = `Notification`;

  // ------------------------------------------------
  // 공통헤더
  // ------------------------------------------------
  readonly HEADER_SETTINGS_LANG = `Language`;
  readonly HEADER_SETTINGS_CURRENCY = `Currency`;
  readonly HEADER_MENU_EXPLORE = `Explorer`;
  readonly HEADER_MENU_ASSET_CREATOR = `Asset Creator`;
  readonly HEADER_MENU_MARKET_PLACE = `Marketplace`;
  readonly HEADER_LOGIN_BTN = `Login`;
  readonly HEADER_KAIKAS_WALLET_CONNECT = `Connect wallet`;
  readonly HEADER_MYPAGE = `Mypage`;
  readonly HEADER_LOGOUT = `Logout`;

  // ------------------------------------------------
  // 공통푸터
  // ------------------------------------------------
  readonly FOOTER_TERMS = `Terms of Service`;
  readonly FOOTER_PRIVACY = `Privacy Policy`;
  readonly FOOTER_REFUND_POLICY = `Refund Policy`;
  readonly FOOTER_RAW1 = `Financial instruments and exchange law`;
  readonly FOOTER_RAW2 = `Fund Settlement Act`;
  readonly FOOTER_SUPPORT_EMAIL = `Customer Support Email`;
  readonly FOOTER_SUPPORT_EMAIL_ADDRESS = `support@cobalt.zone`;
  readonly FOOTER_COPYRIGHT = `© Post Voyager. All rights reserved.`;
  readonly FOOTER_INQUERY = `Contact`;

  // ------------------------------------------------
  // Explorer 화면
  // ------------------------------------------------
  readonly EXP_MAIN_BANNER_BTN = `Mint\nmy own asset`;
  readonly EXP_BANNER_COLLECTION_TITLE = `Collection`;
  readonly EXP_BANNER_EVENT_TITLE = `Event`;
  readonly EXP_BANNER_CARROT_TITLE = `NFT by CARROT`;
  readonly EXP_BANNER_POKECOLO_TITLE = `ポケコロアートワーク`;
  readonly EXP_BANNER_EVENT_CBLT = `Get 3 times the reward!`;
  readonly EXP_SUB_BANNER_BTN = `VIEW >`;
  readonly EXP_STATUS_BOARD_ASSET_CNT = `Assets Minted`;
  readonly EXP_STATUS_BOARD_SALE_CNT = `Today's Sale Volume`;
  readonly EXP_STATUS_BOARD_TX_CNT = `Today's Transaction Volume`;
  readonly EXP_STATUS_BOARD_DOLLAR_CNT = `Today’s Transaction in USD`;
  readonly EXP_RECOMMEND_ASSET_SECTION_TITLE = `Suggested assets`;
  readonly EXP_NEW_ASSET_SECTION_TITLE = `New Assets`;
  readonly EXP_NEW_ASSET_VIEW_ALL = `VIEW ALL`;
  readonly EXP_COLLECTION_SECTION_TITLE = `Collection`;

  // ------------------------------------------------
  // AssetCreator
  // ------------------------------------------------
  readonly AC_COLLECTION_LIST_TITLE_IF_EMTPY = `Create a Collection`;
  readonly AC_COLLECTION_LIST_DESC_1 = `You must create a collection in order to mint an asset.`;
  readonly AC_COLLECTION_LIST_DESC_2 = `Create a collection of a large topic first.`;
  readonly AC_COLLECTION_LIST_TITLE = `My Collection`;
  readonly AC_COLLECTION_LIST_NEW = `Create a Collection`;
  readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_1 = `Processing.`;
  readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_2 = `Please wait.`;
  readonly AC_COLLECTION_CREATE_BACK = `Back`;
  readonly AC_COLLECTION_CREATE_TITLE = `Create a Collection`;
  readonly AC_COLLECTION_CREATE_IMAGE = `Collection image`;
  readonly AC_COLLECTION_CREATE_IMAGE_UPLOAD_SPEC = `Image upload format`;
  readonly AC_COLLECTION_CREATE_CANNOT_EDIT = `Unable to edit`;
  readonly AC_COLLECTION_CREATE_NAME = `Collection Name`;
  readonly AC_COLLECTION_CREATE_NAME_GUIDE = `(Only use letters and numbers, under 20 characters duplicates not allowed)`;
  readonly AC_COLLECTION_CREATE_NAME_DUPLICATED = `Collection name is already registered.`;
  readonly AC_COLLECTION_CREATE_DISPNAME = `Display Name`;
  readonly AC_COLLECTION_CREATE_DISPNAME_GUIDE = `(Must be within 20 characters)`;
  readonly AC_COLLECTION_CREATE_ROYALTY = `Royalty`;
  readonly AC_COLLECTION_CREATE_ROYALTY_GUIDE = `(0~{MAX}%)`;
  readonly AC_COLLECTION_CREATE_WEBSITE = `Website URL`;
  readonly AC_COLLECTION_CREATE_DESC = `Description`;
  readonly AC_COLLECTION_CREATE_ADMIN = `Admin Account`;
  readonly AC_COLLECTION_CREATE_ADMIN_INVALID = `This account is unavailable.`;
  readonly AC_COLLECTION_CREATE_BTN = `Create a collection`;
  readonly AC_COLLECTION_CREATE_COL_IMG_TOOLTIP = `• Resolution 200x200 or higher\n• Upload image Format : PNG, JPG, GIF, APNG\n• 2MB Restricted compliance`;
  readonly AC_COLLECTION_CREATE_TOOLTIP_DISPNAME = `Collection name shown to other users`;
  readonly AC_COLLECTION_CREATE_TOOLTIP_ROYALTY = `Royalty paid for transaction`;
  readonly AC_COLLECTION_UPDATE_TITLE = `Collection`;
  readonly AC_COLLECTION_UPDATE_BTN = `Finish editing`;
  readonly AC_COLLECTION_READ_BACK = `Back`;
  readonly AC_COLLECTION_READ_TITLE = `Collection`;
  readonly AC_COLLECTION_READ_EDIT_BTN = `Edit`;
  readonly AC_COLLECTION_READ_OWNER = `Owner`;
  readonly AC_COLLECTION_READ_COLLECTION_NAME = `Collection Name`;
  readonly AC_COLLECTION_READ_DISP_NAME = `Display Name`;
  readonly AC_COLLECTION_READ_ROYALTY = `Royalty`;
  readonly AC_COLLECTION_READ_WEBSITE = `Website URL`;
  readonly AC_COLLECTION_READ_DESC = `Descriptiom`;
  readonly AC_COLLECTION_READ_ADMIN = `Admin Account`;
  readonly AC_COLLECTION_READ_SCHEMA = `Schema`;
  readonly AC_COLLECTION_READ_SCHEMA_DESC_1 = `Schema defines the type of properties the asset will have. `;
  readonly AC_COLLECTION_READ_SCHEMA_DESC_2 = `You can create multiple schemas within a collection.`;
  readonly AC_COLLECTION_READ_SCHEMA_ATTR_CNT = `Attribute`;
  readonly AC_SCHEMA_CREATE_BACK = `Back`;
  readonly AC_SCHEMA_CREATE_TITLE = `Create a schema`;
  readonly AC_SCHEMA_CREATE_NAME = `Schema Name`;
  readonly AC_SCHEMA_CREATE_NAME_GUIDE = `(Only use alphabet letters and numbers, within 20 characters duplicates not allowed)`;
  readonly AC_SCHEMA_CREATE_NAME_DUPLICATED = `Schema name is already registered.`;
  readonly AC_SCHEMA_CREATE_CANNOT_EDIT = `Unable to edit`;
  readonly AC_SCHEMA_CREATE_TYPE = `Asset Type`;
  readonly AC_SCHEMA_CREATE_TYPE_IMAGE = `Image`;
  readonly AC_SCHEMA_CREATE_TYPE_VIDEO = `Video`;
  readonly AC_SCHEMA_CREATE_TYPE_AUDIO = `Audio`;
  readonly AC_SCHEMA_CREATE_DISP_NAME = `Display Name`;
  readonly AC_SCHEMA_CREATE_DISP_NAME_GUIDE = `(Must be within 20 characters)`;
  readonly AC_SCHEMA_CREATE_ATTR = `Attribute and Type`;
  readonly AC_SCHEMA_CREATE_ATTR_GUIDE_1 = `Attribute name consists of letters ・ numbers, and is within 20 characters, `;
  readonly AC_SCHEMA_CREATE_ATTR_GUIDE_2 = `There cannot be duplicates`;
  readonly AC_SCHEMA_CREATE_ATTR_NEW = `New Attribute`;
  readonly AC_SCHEMA_CREATE_ATTR_NEW_TYPE = `New Attribute Type`;
  readonly AC_SCHEMA_CREATE_ATTR_NAME_DUPLICATED = `Attribute name already in use.`;
  readonly AC_SCHEMA_CREATE_ATTR_NAME_RESERVED = `Unable to use attribute name.`;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_TEXT = `Text`;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_NUMBER = `Number`;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE = `Image`;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO = `Video`;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO = `Audio`;
  readonly AC_SCHEMA_CREATE_ATTR_ADD_BTN = `Add new attribute`;
  readonly AC_SCHEMA_CREATE_BTN = `Create schema`;
  readonly AC_SCHEMA_CREATE_TOOLTIP_DISPNAME = `Schema name shown to other users`;
  readonly AC_SCHEMA_UPDATE_TITLE = `Schema`;
  readonly AC_SCHEMA_UPDATE_BTN = `Finish editing`;
  readonly AC_SCHEMA_READ_BACK = `Back`;
  readonly AC_SCHEMA_READ_ATTR_CNT = `Attribute `;
  readonly AC_SCHEMA_READ_EDIT_BTN = `Edit`;
  readonly AC_SCHEMA_READ_TITLE = `Schema`;
  readonly AC_SCHEMA_READ_ATTR_NAME = `Attribute Name`;
  readonly AC_SCHEMA_READ_ATTR_TYPE = `Attribute Type`;
  readonly AC_SCHEMA_READ_ATTR_TYPE_TEXT = `Text`;
  readonly AC_SCHEMA_READ_ATTR_TYPE_NUMBER = `Number`;
  readonly AC_SCHEMA_READ_ATTR_TYPE_IMAGE_DETAIL = `(PNG, JPG, GIF APNG)`;
  readonly AC_SCHEMA_READ_ATTR_TYPE_VIDEO_DETAIL = `(MP4)`;
  readonly AC_SCHEMA_READ_ATTR_TYPE_AUDIO_DETAIL = `(MP3)`;
  readonly AC_SCHEMA_READ_ASSET = `Asset`;
  readonly AC_SCHEMA_READ_NEW_ASSET_DESC = `The asset for the properties of the schema are automatically set and can be minted according to the type.`;
  readonly AC_SCHEMA_READ_NEW_ASSET = `Mint Asset`;
  readonly AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT = `Mint {개수}`;
  readonly AC_ASSET_GROUP_MINT_MORE_BTN = `Additional minted asset`;
  readonly AC_ASSET_GROUP_DLG_TITLE = `Additionally mint`;
  readonly AC_ASSET_GROUP_DLG_MINT_OWNER = `Asset owner`;
  readonly AC_ASSET_GROUP_DLG_MINT_CNT = `Number of additional minted assets`;
  readonly AC_ASSET_GROUP_DLG_MINT_CNT_GUIDE = `(Maximum 10)`;
  readonly AC_ASSET_GROUP_DLG_MINT_TOTAL = `Total number of minted assets`;
  readonly AC_ASSET_GROUP_DLG_MINT_BTN = `Mint`;
  readonly AC_ASSET_CREATE_BACK = `Back`;
  readonly AC_ASSET_CREATE_TITLE = `Mint Asset`;
  readonly AC_ASSET_CREATE_PREVIEW = `Preview`;
  readonly AC_ASSET_CREATE_OWNER = `Asset Owner`;
  readonly AC_ASSET_CREATE_MINT_CNT = `Minted Quantity`;
  readonly AC_ASSET_CREATE_MINT_CNT_GUIDE = `(10 at most)`;
  readonly AC_ASSET_CREATE_MINT_NO_MORE = `No additional minted assets`;
  readonly AC_ASSET_CREATE_MINT_MORE = `Able to additionally mint`;
  readonly AC_ASSET_CREATE_ATTR = `Attribute and Type`;
  readonly AC_ASSET_CREATE_ATTR_TYPE_NFTNAME = `Text (Within 20 characters)`;
  readonly AC_ASSET_CREATE_ATTR_TYPE_TEXT = `Text`;
  readonly AC_ASSER_CREATE_ATTR_TYPE_NUMBER = `Number`;
  readonly AC_ASSET_CREATE_ATTR_TYPE_IMAGE_DETAIL = `Image (PNG, JPG, GIF APNG)`;
  readonly AC_ASSET_CREATE_ATTR_TYPE_VIDEO_DETAIL = `Video (MP4)`;
  readonly AC_ASSET_CREATE_ATTR_TYPE_AUDIO_DETAIL = `Audio (MP3)`;
  readonly AC_ASSET_CREATE_FILE = `Select a file`;
  readonly AC_ASSET_CREATE_FILE_UPLOAD_SPEC = `{속성타입} Upload Format`;
  readonly AC_ASSET_CREATE_FILE_IMAGE_SPEC_TOOLTIP = `• Resolution: 600px by over\n• File types: PNG, JPG, GIF, APNG\n• Up to 10MB`;
  readonly AC_ASSET_CREATE_FILE_VIDEO_THUMB_IMAGE_SPEC_TOOLTIP = `• Resolution: 600px by over\n• File types: PNG, JPG, GIF, APNG\n• Up to 5MB`;
  readonly AC_ASSET_CREATE_FILE_AUDIO_COVER_IMAGE_SPEC_TOOLTIP = `• Resolution: 400px by over\n• File types: PNG, JPG, GIF, APNG\n• Up to 5MB`;
  readonly AC_ASSET_CREATE_FILE_AUDIO_SPEC_TOOLTIP = `• File type: MP3\n• Up to 10MB`;
  readonly AC_ASSET_CREATE_FILE_VIDEO_SPEC_TOOLTIP = `• Resolution: over 600px\n• File type: MP4\n• Up to 10MB`;
  readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_TITLE = `Please check the minimum size.`;
  readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_DESC = `You can only upload over {사이즈} px horizontally and vertically.`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_TITLE = `Please check the file type.`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_DESC = `You can only upload {파일형식 리스트}`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_TITLE = `Please check the maximum size.`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_DESC = `You can only upload the maximum size of {파일사이즈 MB}MB.`;
  readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TITLE = `Uploading file`;
  readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TEXT = `Please wait.`;
  readonly AC_ASSET_CREATE_TERMS1_TITLE = `I agree to collecting and using the following information for minting the asset.`;
  readonly AC_ASSET_CREATE_TERMS1_DETAIL_1 = `• Collection item: Asset name, description, file (image, etc.)\n• Collection item: Asset minting and management`;
  readonly AC_ASSET_CREATE_TERMS1_DETAIL_2 = `• Ownsership and use period: Permanent (violation of regulations, destruction upon request)\n• If consent is refused, an asset cannot be minted.`;
  readonly AC_ASSET_CREATE_TERMS2_TITLE = `I have checked the precautions below and agree to abide by them.`;
  readonly AC_ASSET_CREATE_TERMS2_DETAIL = `• I will not infringe on the intellectual property rights, human rights and personal information of third parties.\n• I acknowledge and agree that if I use my personal information, my personal information can be disclosed, utilized, and provided to third parties.\n• I confirm and agree that I do not mint an asset contrary to the Terms and Conditions and Operating Policies and that I am fully responsible for the asset being issued.\n• I acknowledge and agree that if an asset contains inappropriate images or images, it can be deleted without notice.`;
  readonly AC_ASSET_CREATE_BTN = `Mint Asset`;
  readonly AC_ASSET_CREATE_TOOLTIP_MINT = `Mint up to 10 at a time`;
  readonly AC_ASSET_READ_BACK = `Back`;
  readonly AC_ASSET_READ_OWNER = `Owner`;
  readonly AC_ASSET_READ_CREATOR = `Original Creator`;
  readonly AC_ASSET_READ_ASSET_ID = `Asset ID`;
  readonly AC_ASSET_READ_ASSET_TYPE = `Asset Type`;
  readonly AC_ASSET_READ_MINT_CNT = `Minted Quantity`;
  readonly AC_ASSET_READ_MINT_NO_MORE = `No additional minted assets`;
  readonly AC_ASSET_READ_MINT_MORE = `Able to additionally mint`;
  readonly AC_ASSET_READ_BEFORE_SALE = `This asset is not being sold yet.`;
  readonly AC_ASSET_READ_SALE_BTN = `Sell`;
  readonly AC_ASSET_READ_ON_SALE = `This asset is being sold as {판매ID}. `;
  readonly AC_ASSET_READ_SALE_EDIT_BTN = `Edit sale`;
  readonly AC_ASSET_READ_TRADING_HISTORY = `Trading history`;
  readonly AC_ASSET_READ_TRADING_HISTORY_EVENT = `Event`;
  readonly AC_ASSET_READ_TRADING_HISTORY_PRICE = `Price`;
  readonly AC_ASSET_READ_TRADING_HISTORY_FROM = `From`;
  readonly AC_ASSET_READ_TRADING_HISTORY_TO = `To`;
  readonly AC_ASSET_READ_TRADING_HISTORY_DATE = `Date`;
  readonly AC_ASSET_READ_TRADING_HISTORY_NO_DATA = `Trading history not yet available.`;
  readonly DLG_EXCEED_COLLECTION_LIMIT = `Collection creation count exceeded.\nUp to 5 can be created.`;
  readonly DLG_EXCEED_SCHEMA_LIMIT = `Schema creation count exceeded.\nUp to 100 can be created.`;
  readonly DLG_SELL_TITLE = `Sell Asset`;
  readonly DLG_SELL_TITLE_EDIT = `Edit sale`;
  readonly DLG_SELL_PRICE = `Sale price`;
  readonly DLG_SELL_MINIMUM_LIMIT = `Amount must be over 10 {화폐단위}.`;
  readonly DLG_SELL_FEE = `Fee`;
  readonly DLG_SELL_ROYALTY = `Collection Royalty`;
  readonly DLG_SELL_PLATFORM_FEE = `Platform Fee`;
  readonly DLG_SELL_CALC_PRICE = `Estimated Sales Profit`;
  readonly DLG_SELL_OK_BTN = `Sell`;
  readonly DLG_SELL_KLAY_ADDRESS_REG_GUIDE = `Register the address for recieving KLAY sales.`;
  readonly DLG_SELL_KLAY_ADDRESS = `Deposit Address`;
  readonly DLG_SELL_KLAY_ADDRESS_REWARD = `Address to recieve reward.`;
  readonly DLG_SELL_KLAY_ADDRESS_REWARD_GUIDE = `Please register an address you can recieve reward CBLT on MY PAGE. `;
  readonly DLG_SELL_STOP_BTN = `No longer available`;
  readonly DLG_SELL_AGREE_POLICY_TITLE = `I have confirmed the following precautions and agree to comply with them.`;
  readonly DLG_SELL_AGREE_POLICY_TEXT = `• Buyers (including those who have received resale from buyers) can download the asset and store it on their local computers and other memory devices.\n• Authorizing the buyer to use the product for non-commercial purposes (e.g., print the product on a T-shirt for the purpose of wearing it).\n• The company may use the product to the extent necessary for smooth provision of this service, system construction, improvement, maintenance, advertisement and promotion of this service, and omit the display of the original name by omitting, resizing, and editing part of the product data.\n• Do not submit copies or similar copies of the goods to other services.\n• The user is solely responsible for determining the taxes incurred in all asset transactions provided by this service (including any cases that can be taxed). The company shall not be responsible for the tax obligations incurred by users due to the use of this service.`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_SELL = `The address can be registered by connecting the Kaikas wallet.\nPC Please connect the wallet using the web browser.`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_BUY = `Purchases can be made by connecting the Kaikas wallet.\nPlease use the PC web browser to make a purchase.`;
  readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TITLE = `The asset is being minted.\nIt takes 1~3 minutes to process.`;
  readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TXT = `(It will appear on the list once it is minted.)`;
  readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TITLE = `The collection is being created.\nIt takes 1~3 minutes to process.`;
  readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TXT = `(If it fails it will dissappear from the list.)`;
  readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TITLE = `Additional minted assets are being created.\nIt takes 1~3 minutes to process.`;
  readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TXT = `(If it fails it will dissappear from the list.)`;
  readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TITLE = `Collection edit is being processed. `;
  readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TXT = `(It is fails it will be restored to the previous edit.)`;
  readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TITLE = `Schema is being created. \nIt takes 1~3 minutes to process.`;
  readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TXT = `(If it fails it will dissappear from the list.)`;
  readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TITLE = `Schema edit is being processed.\nIt takes about 1 minute to complete.`;
  readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TXT = `(It is fails it will be restored to the previous edit.)`;
  readonly AC_BLOCKED_TITLE = `First time creating an asset?`;
  readonly AC_BLOCKED_GUIDE_ROW_1 = `Assets are only able to be minted if you are a confirmed creator by Cobalt.  In order to get the confirmation from Cobalt please fill out the KYC and it will be reviewed.`;
  readonly AC_BLOCKED_GUIDE_ROW_2 = `You can request it by selecting [Inquiry > Original Creator Request]. By sending it, we will give your further instructions through email.`;
  readonly AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT = `[Inquiry > Original Creator Request]`;
  readonly AC_BLOCKED_BTN = `Contact Us`;

  // ------------------------------------------------
  // Market place
  // ------------------------------------------------
  readonly MARKET_LIST_SEARCH_PLACEHOLDER = `Search`;
  readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_NEWEST = `Newest`;
  readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_OLDEST = `Oldest`;
  readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_HIGHEST = `Highest to lowest`;
  readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_LOWEST = `Lowest to highest`;
  readonly MARKET_LIST_SEARCH_FILTER_COLLECTION = `Collection`;
  readonly MARKET_LIST_SEARCH_FILTER_CURRENCY = `Sales Currency`;
  readonly ASSET_CARD_BUY_BTN = `Buy`;
  readonly MARKET_ASSET_READ_BACK = `Back`;
  readonly MARKET_ASSET_READ_OWNER = `Seller`;
  readonly MARKET_ASSET_READ_CREATOR = `Original Creator`;
  readonly MARKET_ASSET_READ_SOLD = `This item has been sold.`;
  readonly MARKET_ASSET_READ_BUYER = `Buyer`;
  readonly MARKET_ASSET_READ_BUY_BTN = `Buy`;
  readonly MARKET_ASSET_READ_ASSET_ID = `Asset ID`;
  readonly MARKET_ASSET_READ_SALE_ID = `Sale ID`;
  readonly MARKET_ASSET_READ_ASSET_TYPE = `Asset Type`;
  readonly MARKET_ASSET_READ_MINT_CNT = `Minted Quantity`;
  readonly MARKET_ASSET_READ_MINT_NO_MORE = `No additionally minted assets.`;
  readonly MARKET_ASSET_READ_MINT_MORE = `Able to additionally mint`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY = `Price History Graph`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_SUB = `This graph is based on all the assets that were issued with this asset.`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_LOWEST = `Lowest Transaction Price`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_HIGHEST = `Highest Transaction Price`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TX_CNT = `Total Number of Transactions`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_CNT = `Number of Transactions :`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_AVGPRC = `Average Price : `;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_NO_DATA = `Price history graph not yet available.`;
  readonly MARKET_ASSET_READ_TRADING_HISTORY = this.COMMON_ASSET_TRADING_HISTORY;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT = this.COMMON_ASSET_TRADING_HISTORY_EVENT;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_PRICE = this.COMMON_ASSET_TRADING_HISTORY_PRICE;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_FROM = this.COMMON_ASSET_TRADING_HISTORY_FROM;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_TO = this.COMMON_ASSET_TRADING_HISTORY_TO;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_DATE = this.COMMON_ASSET_TRADING_HISTORY_DATE;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_NO_DATA = this.COMMON_ASSET_TRADING_HISTORY_NO_DATA;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT_SALE = `Sale`;
  readonly MARKET_COPY_LINK_BTN=`Copy Link`;
  readonly MARKET_SHARE_TWITTER_BTN=`Twitter`;
  readonly DLG_BUY_TITLE = `Buy Asset`;
  readonly DLG_BUY_COLLECTION = `Collection`;
  readonly DLG_BUY_ASSET_NAME = `Asset Name`;
  readonly DLG_BUY_MINT_CNT = `Minted Quantity`;
  readonly DLG_BUY_SCHEMA = `Schema`;
  readonly DLG_BUY_SELLER = `Seller`;
  readonly DLG_BUY_PRICE = `Price`;
  readonly DLG_BUY_BTN = `Buy`;
  readonly MARKET_COLLECTION_READ_BACK = `Back`;
  readonly MARKET_COLLECTION_READ_COLLECTION = `Collection`;
  readonly MARKET_COLLECTION_READ_OWNER = `Owner`;
  readonly MARKET_COLLECTION_READ_COLLECTION_NAME = `Collection Name`;
  readonly MARKET_COLLECTION_READ_DISP_NAME = `Display Name`;
  readonly MARKET_COLLECTION_READ_ROYALTY = `Royalty`;
  readonly MARKET_COLLECTION_READ_WEBSITE = `Website URL`;
  readonly MARKET_COLLECTION_READ_DESC = `Description`;
  readonly MARKET_COLLECTION_READ_ADMIN = `Admin Account`;
  readonly MARKET_COLLECTION_READ_ASSET_LIST_TITLE = `Collection's Asset`;
  readonly MARKET_SCHEMA_READ_BACK = `Back`;
  readonly MARKET_SCHEMA_READ_ATTR_CNT = `Attribute`;
  readonly MARKET_SCHEMA_READ_TITLE = `Schema`;
  readonly MARKET_SCHEMA_READ_ATTR_NAME = `Attribute Name`;
  readonly MARKET_SCHEMA_READ_ATTR_TYPE = `Attribute Type`;
  readonly MARKET_SCHEMA_READ_ASSET_LIST_TITLE = `The asset this schema uses`;
  readonly MARKET_ACCOUNT_READ_VIEW_ON_BLOCKS = `View on Blocks`;
  readonly MARKET_ACCOUNT_READ_MENU_INVENTORY = `Inventory`;
  readonly MARKET_ACCOUNT_READ_MENU_CREATED = `Mint Asset`;
  readonly MARKET_ACCOUNT_READ_MENU_ON_SALE = `For Sale`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD = `Sold`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED = `Purchased`;
  readonly MARKET_ACCOUNT_READ_SEARCH_PLACEHOLDER = `Search`;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_NEWEST = `Newest`;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_OLDEST = `Oldest`;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_HIGHEST = `Highest to lowest`;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_LOWEST = `Lowest to highest`;
  readonly MARKET_ACCOUNT_READ_MENU_INVEN_CNT = `You own {개수}.`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_ASSET_ID = `Asset ID`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_DATE = `Date and time of sale`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_SELLER = `Seller`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_BUYER = `Buyer`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_PRICE = `Sale Price`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_TX_HASH = `Tx Hash`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_ASSET_ID = `Asset ID`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_DATE = `Date and time of purchase`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_SELLER = `Seller`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_BUYER = `Buyer`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_PRICE = `Purchased price`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_TX_HASH = `Tx Hash`;
  readonly MARKET_REPORT_BTN = `Report`;
  readonly MARKET_DLG_REPORT_TITLE = `Please select the reason for report.`;
  readonly MARKET_DLG_REPORT_CASE_1 = `Asset displays sexual content`;
  readonly MARKET_DLG_REPORT_CASE_2 = `Asset displays violent or harmful actions`;
  readonly MARKET_DLG_REPORT_CASE_3 = `Asset displays hatred or abuse`;
  readonly MARKET_DLG_REPORT_CASE_4 = `Asset inflicts rights`;
  readonly MARKET_DLG_REPORT_CASE_5 = `Other`;
  readonly MARKET_DLG_REPORT_BTN = `Report`;
  readonly MARKET_DLG_REPORT_SUCCESS = `Your issue has been reported.`;
  readonly MARKET_ASSET_CARD_BLOCK = `This asset has been privated\ndue to multiple reports.`;
  readonly MARKET_DLG_ERROR_ALREADY_OWNER = `You own this asset.`;
  readonly DLG_WILL_MOVE_PAYMENTPAGE_TITLE = `Redirecting to payment`;
  readonly DLG_WILL_MOVE_PAYMENTPAGE_TEXT = `Please wait.`;
  readonly MARKET_PAYMENT_TITLE = `Buy`;
  readonly MARKET_PAYMENT_ASSET = `Assets bought`;
  readonly MARKET_PAYMENT_POINT = `POINT payment`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP = `What is POINT payment?`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT1 = `After charging POINT with USD you can use it to pay`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT2 = `1 USD = 1 POINT`;
  readonly MARKET_PAYMENT_MY_POINT_BALANCE = `POINT balance`;
  readonly MARKET_PAYMENT_LACK_POINT_AMOUNT = `Insufficient amount of POINT`;
  readonly MARKET_PAYMENT_CHARGE_POINT = `POINT charge`;
  readonly MARKET_PAYMENT_CHARGE_POINT_GUIDE = `Please charge the insufficient amount of POINT and process the payment`;
  readonly MARKET_PAYMENT_PRICE = `Total payment`;
  readonly MARKET_PAYMENT_AGREE_ALL = `Agree to all`;
  readonly MARKET_PAYMENT_AGREE_TERMS_OF_USE = `I confirm and agree to the terms and conditions.`;
  readonly MARKET_PAYMENT_AGREE_REFUND_POLICY = `I confirm and agree to the refund policy.`;
  readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY = `I confirm and agree to the privacy policy.`;
  readonly MARKET_PAYMENT_AGREE_TERM_OF_USE_LINK = `View the Terms of Use`;
  readonly MARKET_PAYMENT_AGREE_REFUND_POLICY_LINK = `View the Refund Policy`;
  readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY_LINK = `View the Privacy Policy`;
  readonly MARKET_PAYMENT_BTN = `Pay`;
  readonly DLG_CHARGE_LIST_BTN = `Charge`;
  readonly DLG_CHARGE_LIST_TITLE = `Charge POINT`;
  readonly DLG_CHARGE_FEE_DISCOUNT = `{할인율} Fee discount`;
  readonly DLG_CHARGE_LIST_MY_POINT_BALANCE = `POINT balance`;
  readonly DLG_CHARGE_LIST_TERMS = `Terms of Service`;
  readonly DLG_CHARGE_LIST_REFUND_POLICY = `Privacy Policy`;
  readonly DLG_CHARGE_LIST_PRIVACY = `Refund policy`;
  readonly DLG_CHARGE_LIST_RAW1 = `Financial Instruments Transaction Act`;
  readonly DLG_CHARGE_LIST_RAW2 = `Fund Settlement Act`;
  readonly DLG_RUNNING_SMART_CONTRACT= `Please process the payment on the wallet.`;
  readonly DLG_BUY_SUCCESS_TITLE= `The payment has been processed.`;
  readonly DLG_BUY_SUCCESS_TEXT= `You can check it on the inventory section of MY PAGE.`;
  readonly DLG_BUY_FAILURE_TITLE= `The payment failed.`;
  readonly DLG_BUY_FAILURE_TEXT= `Please try again.`;
  readonly DLG_RUNNING_BUY_PROCESS_TITLE= `Processing payment.`;
  readonly DLG_RUNNING_BUY_PROCESS_TEXT= `Please wait.`;
  readonly DLG_CHARGE_SUCC = `POINT has been charged.`;
  readonly DLG_CHARGE_FAIL = `Failed to charge POINT`;

  // ------------------------------------------------
  // Mypage
  // ------------------------------------------------
  readonly MY_VIEW_ON_BLOCKS = `View on Blocks`;
  readonly MY_MY_BALANCE_TITLE = `My Balance`;
  readonly MY_MY_BALANCE_BTN = `View HIstory`;
  readonly MY_KLAY_ADDRESS_TITLE = `Wallet Address`;
  readonly MY_KLAY_ADDRESS_TITLE_TOOLTIP = `Register address for recieving KLAY sales and rewards`;
  readonly MY_KLAY_ADDRESS_INPUT_PLACEHOLDER = `Enter Deposit Address`;
  readonly MY_KLAY_ADDRESS_INPUT_REG_BTN = `Register`;
  readonly MY_KLAY_ADDRESS_INPUT_CHANGE_BTN = `Change`;
  readonly MY_KLAY_ADDRESS_INPUT_DONE_BTN = `Confirm`;
  readonly MY_KLAY_ADDRESS_INPUT_INVALID = `Please enter a valid address.`;
  readonly MY_KLAY_ADDRESS_INPUT_TOOLTIP = `Please beware in the case of incorrectly inputing the KLAY wallet address or misspelling, the sale payment and reward tokens may get lost.`;
  readonly MY_BALANCE_KLAY_LABEL = `KLAY Balance`;
  readonly MY_BALANCE_CBLT_LABEL = `CBLT Balance`;
  readonly MY_BALANCE_CUSD_LABEL = `USD Balance`;
  readonly MY_BALANCE_CPOINT_LABEL = `POINT Balance`;
  readonly MY_BALANCE_CPOINT_CHARGE = `Charge`;
  readonly MY_BALANCE_CUSD_WITHDRAWAL = `Send`;
  readonly MY_BALANCE_CBLT_TOOLTIP = `Tokens distributed based on ecosystem contribution`;
  readonly MY_MENU_INVENTORY = `Inventory`;
  readonly MY_MENU_CREATED = `Minted Assets`;
  readonly MY_MENU_FOR_SALE = `For sale`;
  readonly MY_MENU_SOLD = `Sold`;
  readonly MY_MENU_PURCHASED = `Purchased`;
  readonly MY_MENU_FAVORITE = `Favorites`;
  readonly MY_MENU_MYACCOUNT = `My account`;
  readonly MY_SEARCH_PLACEHOLDER = `Search`;
  readonly MY_SEARCH_ORDERBY_LISTING_NEWEST = `Newest`;
  readonly MY_SEARCH_ORDERBY_LISTING_OLDEST = `Oldest`;
  readonly MY_SEARCH_ORDERBY_PRICE_HIGHEST = `Highest to lowest`;
  readonly MY_SEARCH_ORDERBY_PRICE_LOWEST = `Lowest to highest`;
  readonly MY_MENU_INVENTORY_STOP_BTN = `No longer available`;
  readonly MY_MENU_INVENTORY_NO_DATA = `Inventory not yet available.`;
  readonly MY_MENU_ISSUE_NO_DATA = `There are no minted assets yet.`;
  readonly MY_MENU_SALE_NO_DATA = `There are no assets for sale yet.`;
  readonly MY_MENU_SOLD_NO_DATA = `There are no assets that have been sold yet.`;
  readonly MY_MENU_BOUGHT_NO_DATA = `There are no purchased assets yet.`;
  readonly MY_MENU_FAVORITE_NO_DATA = `There are no favorited assets yet`;
  readonly MY_MENU_SEARCH_NOT_FOUND = `No results.`;
  readonly MY_MENU_ON_SALE_EDIT_BTN = `Edit Listing`;
  readonly MY_MENU_SOLD_ASSET_ID = `Asset ID`;
  readonly MY_MENU_SOLD_DATE = `Date and Time of Sale`;
  readonly MY_MENU_SOLD_SELLER = `Seller`;
  readonly MY_MENU_SOLD_BUYER = `Buyer`;
  readonly MY_MENU_SOLD_PRICE = `Sale Price`;
  readonly MY_MENU_SOLD_ROYALTY = `Collection Royalty`;
  readonly MY_MENU_SOLD_PLATFORM_FEE = `Platform Fee`;
  readonly MY_MENU_SOLD_PROFIT = `Profit`;
  readonly MY_MENU_SOLD_REWARD = `Reward`;
  readonly MY_MENU_SOLD_REWARD_TOOLTIP = `Token distributed as a sales contribution`;
  readonly MY_MENU_SOLD_TX_HASH = `Tx Hash`;
  readonly MY_MENU_PURCHASED_ASSET_ID = `Asset ID`;
  readonly MY_MENU_PURCHASED_DATE = `Date and Time of Purchase`;
  readonly MY_MENU_PURCHASED_SELLER = `Seller`;
  readonly MY_MENU_PURCHASED_BUYER = `Buyer`;
  readonly MY_MENU_PURCHASED_PRICE = `Purchased price`;
  readonly MY_MENU_PURCHASED_REWARD = `Reward`;
  readonly MY_MENU_PURCHASED_REWARD_TOOLTIP = `Tokens distributed as purchase contributions`;
  readonly MY_MENU_PURCHASED_TX_HASH = `Tx Hash`;
  readonly MY_MENU_MYACCOUNT_SETTINGS = `Manage account`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_EMAIL = `Email`;
  readonly MY_MENU_MYACCOUNT_EMAIL_AUTH_COMPLETE = `Vertification complete`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_ID = `ID`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD = `Password`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD_GUIDE = `Recommended to periodically change for account security`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_FISH_SECURE_CODE = `Anti-Phishing Code`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_CHANGE_BTN = `Change`;
  readonly MY_PWCHANGE_TITLE = `Change Password`;
  readonly MY_PWCHANGE_CUR_PASSWORD = `Current Password`;
  readonly MY_PWCHANGE_CUR_PASSWORD_PLACEHOLDER = `Enter current password`;
  readonly MY_PWCHANGE_CUR_PASSWORD_WRONG = `Current password entered incorrectly`;
  readonly MY_PWCHANGE_NEW_PASSWORD = `New Password`;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_1 = `* Must include English capital letters`;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_2 = `* Must include English lower case letters`;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_3 = `* Must include numbers`;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_4 = `* Must be more than 10 characters`;
  readonly MY_PWCHANGE_NEW_PASSWORD_PLACEHOLDER = `Enter new password`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM = `Confirm new password`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_PLACEHOLDER = `Confirm new password`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_NO_MATCH = `Passwords do not match`;
  readonly MY_PWCHANGE_OK_BTN = `Confirm `;
  readonly MY_PWCHANGE_OK_MSG_1 = `Password has been changed.`;
  readonly MY_PWCHANGE_OK_MSG_2 = `Redirecting to My Page in 10 seconds or by clicking [confirm].`;
  readonly MY_FISH_SECURE_CODE_CHANGE_TITLE = `Change Anti-Phishing Code`;
  readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD = `Confirm Password`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEXT_PLACEHOLDE = `Enter Password`;
  readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD_WRONG = `Current password entered incorrectly`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEXT = `Next`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEW = `New Code`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEW_PLACEHOLDER = `Enter new code`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_BTN = `Confirm`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_1 = `The code has been changed.`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_2 = `Redirecting to My Page in 10 seconds or by clicking [confirm].`;
  readonly MY_PAYOUT_DLG_TITLE = `Send USD`;
  readonly MY_PAYOUT_DLG_USD_BALANCE = `USD Balance`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT = `Sending amount`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_SUB = `(Minimum 100 USD)`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_ALL_BTN = `Apply max`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_MINIMUM = `Only 100 USD or more.`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_INSUFFICIENT = `USD balance is insufficient.`;
  readonly MY_PAYOUT_DLG_SECTION_ACCOUNT = `Sending account`;
  readonly MY_PAYOUT_DLG_SECTION_ACCOUNT_AUTH_DONE = `Account verification complete`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE = `Platform fee`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_SUB = `(Select to pay with USD/CBLT)`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_KAIKAS = `Please connect to wallet to pay with CBLT`;
  readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE = `Transfer fee`;
  readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE_LINK = `See PayPal's fee policy`;
  readonly MY_PAYOUT_DLG_SECTION_GUIDE = `Application instructions`;
  readonly MY_PAYOUT_DLG_SECTION_GUIDE_DETAIL = `* In order to send USD, you need to verify you PayPal account. We sent the verification process through email so please check the Paypal mail you entered.\n* Once the account has been verified, the payment excluding the platfrom fee will be sent.`;
  readonly MY_PAYOUT_DLG_APPLY_BTN = `Apply`;
  readonly MY_PAYPAL_DLG_TITLE = `Account information`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL = `PayPal email`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL_PLACEHOLDER = `Enter email`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL_ALREADY = `It is already a verfiied email.`;
  readonly MY_PAYPAL_DLG_SECTION_AUTH_CODE = `Verify email`;
  readonly MY_CBLT_FEE_DLG_TITLE = `Send platform fee`;
  readonly MY_CBLT_FEE_DLG_SUB_TITLE = `Please send 5 CBLT from your Kaikas wallet.`;
  readonly MY_CBLT_FEE_DLG_SUB_DESC = `If sending the USD is unsuccessful, the CBLT will be refunded.`;
  readonly MY_CBLT_FEE_DLG_BTN = `Pay`;
  readonly MY_PAYOUT_DLG_APPLY_DONE = `The request to send USD has been submitted.`;
  readonly MY_PAYOUT_DLG_APPLY_DONE_2 = `The request to send USD has been submitted.`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY = `The payment for CBLT is available by connecting the Kaikas wallet. \n Please use a PC web browser to connect the wallet.`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY_HIGHLIGH = `PC web browser`;
  readonly DLG_PAYOUT_ID_CHANGE_TITLE = `There is already a verified account. \n In order to change it, it must get verified again. \n Would you like to change it?`;
  readonly DLG_PAYOUT_ID_CHANGE_CHANGE_BTN = `Change`;
  readonly DLG_PAYOUT_ID_CHANGE_CHANGE_CLOSE = `Close`;

  // ------------------------------------------------
  // Join
  // ------------------------------------------------
  readonly JOIN_TITLE = `Sign up / Create an account`;
  readonly JOIN_EMAIL = this.COMMON_EMAIL;
  readonly JOIN_EMAIL_PLACEHOLDER = this.COMMON_EMAIL_PLACEHOLDER;
  readonly JOIN_EMAIL_INVALID = this.COMMON_EMAIL_INVALID;
  readonly JOIN_EMAIL_DUPLICATED = `This email has already been registered.`;
  readonly JOIN_EMAIL_AUTH = `Email Verification`;
  readonly JOIN_EMAIL_AUTH_PLACEHOLDER = `Enter verification code`;
  readonly JOIN_EMAIL_AUTH_SEND = `Send Verification`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_1 = `Authentication mail has been sent.`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_2 = `After checking your inbox, enter the verification code and click "verify"`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_1 = `The authentication mail is only valid for 24 hours from the time it is sent, if it is re-sent the verification code will expire.`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_2 = `Please check your latest email.`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_3 = `If the mail hasn't arrived, please check your spam folder.`;
  readonly JOIN_EMAIL_AUTH_RESEND = `Re-send authentication mail`;
  readonly JOIN_EMAIL_AUTH_BTN = `Verify`;
  readonly JOIN_EMAIL_AUTH_SUCC = `Verification Complete`;
  readonly JOIN_EMAIL_AUTH_INVALID = `The verification code is incorrect.`;
  readonly JOIN_PASSWORD = this.COMMON_PASSWORD;
  readonly JOIN_PASSWORD_PLACEHOLDER = this.COMMON_PASSWORD_PLACEHOLDER;
  readonly JOIN_PASSWORD_GUIDE_1 = this.COMMON_PASSWORD_GUIDE_1;
  readonly JOIN_PASSWORD_GUIDE_2 = this.COMMON_PASSWORD_GUIDE_2;
  readonly JOIN_PASSWORD_GUIDE_3 = this.COMMON_PASSWORD_GUIDE_3;
  readonly JOIN_PASSWORD_GUIDE_4 = this.COMMON_PASSWORD_GUIDE_4;
  readonly JOIN_PASSWORD_CONFIRM = `Confirm Password`;
  readonly JOIN_PASSWORD_CONFIRM_PLACEHOLDER = `Confirm Password`;
  readonly JOIN_PASSWORD_CONFIRM_INVALID = `The password does not match`;
  readonly JOIN_FISH_SECURE_CODE = `Anti-Phishing Code`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_1 = `* Anti-Phishing Code`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_2 = `In order to prevent possible security problems such as fraud mail accounts impersonating cobalt / Please set your own fraud prevention code.`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_3 = `All of the mail that Cobalt send will always come attached with a fraud prevention code.`;
  readonly JOIN_FISH_SECURE_CODE_PLACEHOLDER = `Enter code`;
  readonly JOIN_AGE_OVER_20 = `I am over the age of 20.`;
  readonly JOIN_USAGE_TERM = `I confirm and agree to the terms and conditions.`;
  readonly JOIN_USAGE_TERM_BTN = `Read the terms and conditions`;
  readonly JOIN_BTN = `Sign up`;

  // ------------------------------------------------
  // Login
  // ------------------------------------------------
  readonly LOGIN_TITLE = `Login`;
  readonly LOGIN_SECURE_GUIDE = `Please check if the URL you visited is correct.`;
  readonly LOGIN_EMAIL = this.COMMON_EMAIL;
  readonly LOGIN_EMAIL_PLACEHOLDER = this.COMMON_EMAIL_PLACEHOLDER;
  readonly LOGIN_FAIL_MSG = `Email or password is incorrect.`;
  readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_1 = `Login attempted too many times, account is locked.`;
  readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_2 = `Please reset your password to continue.`;
  readonly LOGIN_PASSWORD = this.COMMON_PASSWORD;
  readonly LOGIN_PASSWORD_PLACEHOLDER = this.COMMON_PASSWORD_PLACEHOLDER;
  readonly LOGIN_BTN = `Login`;
  readonly LOGIN_FIND_PASSWORD = `Reset Password`;
  readonly LOGIN_JOIN = `Sign up / Register`;
  readonly LOGIN_RESET_PASSWORD = `Reset Password`;
  readonly LOGIN_RESET_EMAIL = `Email`;
  readonly LOGIN_RESET_EMAIL_PLACEHOLDER = `Enter Email`;
  readonly LOGIN_RESET_OK_BTN = `Confirm`;
  readonly LOGIN_RESET_OK_MSG_1 = `Your temporary password has been sent to the address.`;
  readonly LOGIN_RESET_OK_MSG_2 = `Redirecting to Login in 10 seconds or by clicking [confirm].`;

  // ------------------------------------------------
  // UserBan
  // ------------------------------------------------
  readonly USERBAN_TITLE = `Account has been suspended.`;
  readonly USERBAN_DESC = `The account will be suspended if the operational policy is repeatedly violated or if a major violation has been found.\nEven the smallest violations can be stopped if they continue to accumulate.`;
  readonly USERBAN_REASON_TITLE = `Reason for account suspension`;
  readonly USERBAN_REASON_DESC = `Common reasons for account suspension include the following.`;
  readonly USERBAN_REASON_1 = `- Violating operational policies `;
  readonly USERBAN_REASON_1_DESC = `Failure to comply with the terms of service and operational policies`;
  readonly USERBAN_REASON_2 = `- Accumulated reports`;
  readonly USERBAN_REASON_2_DESC = `If assets that fall under the report causes are minted and the number of reports has been accumulated.`;
  readonly USERBAN_REASON_3 = `- Account hacking prevention`;
  readonly USERBAN_REASON_3_DESC = `If another user logs into or uses the account `;
  readonly USERBAN_REASON_4 = `- Identity theft`;
  readonly USERBAN_REASON_4_DESC = `If an account is created by stealing the name of an individual or organization`;
  readonly USERBAN_REASON_5 = `- Use of multiple accounts for exploitation purposes`;
  readonly USERBAN_REASON_5_DESC = `If you have created or used multiple accounts for the purpose of violating the policy`;
  readonly USERBAN_REASON_GUIDE = `For this reason, Cobalt can apply the best judgement and suspend the account.\nIn case of further inquiry, please contact us through {Contact}.`;

  // ------------------------------------------------
  // Wallet
  // ------------------------------------------------
  readonly WALLET_REWARD_ADDR_CHAGNE_TITLE = `The wallet address that is currently linked\nand the wallet address that has already been registered is different.\nWould you like to change your registered address to the linked wallet address?`;
  readonly WALLET_REWARD_ADDR_CHAGNE_DESC = `You can receive sales profit and rewards through the registered address.`;
  readonly WALLET_REWARD_ADDR_CHAGNE_BTN = `Change`;
  readonly WALLET_REWARD_ADDR_CLOSE_BTN = `Close`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE = `The registered KLAY wallet address has been changed.`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE_DESC = `(You can check the registered addres on My Page.)`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE_OK = this.COMMON_OK_BTN_TXT;
  readonly WALLET_CONNECT = `Connect Wallet`;
  readonly WALLET_CONNECT_BTN = `Connect`;
  readonly WALLET_CYPRESS_NOT_AVAILABLE_IN_ALPHA = `Cypress: Cannot connect to Klaytn Mainnet.`;
  readonly WALLET_BAOBAB_NOT_AVAILABLE_IN_LIVE = `Baobab: Cannot connect to Klaytn Testnet.`;

  // ------------------------------------------------
  // 거래내역
  // ------------------------------------------------
  readonly PROP_HISTORY_TITLE = `History`;
  readonly PROP_HISTORY_POINT_INCOME = `Total charged POINT`;
  readonly PROP_HISTORY_POINT_SPEND = `Total used POINT`;
  readonly PROP_HISTORY_POINT_BALANCE = `POINT Balance`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_ALL = `All`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_INCOME = `Charge history`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_SPEND = `Used`;
  readonly PROP_HISTORY_POINT_TYPE_INCOME = `Charge`;
  readonly PROP_HISTORY_POINT_TYPE_SPEND = `Use`;
  readonly PROP_HISTORY_USD_INCOME = `Total USD recieved`;
  readonly PROP_HISTORY_USD_SPEND = `Total USD sent`;
  readonly PROP_HISTORY_USD_BALANCE = `USD Balance`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_ALL = `All`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_INCOME = `Received `;
  readonly PROP_HISTORY_USD_QUERY_TYPE_SPEND = `Sent`;
  readonly PROP_HISTORY_USD_TYPE_INCOME = `Received `;
  readonly PROP_HISTORY_USD_TYPE_SPEND = `Sent`;
  readonly PROP_HISTORY_KLAY_INCOME = `Total KLAY recieved`;
  readonly PROP_HISTORY_KLAY_SPEND = `Total KLAY used`;
  readonly PROP_HISTORY_KLAY_BALANCE = `KLAY Balance`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_ALL = `All`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_INCOME = `Received `;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_SPEND = `Used`;
  readonly PROP_HISTORY_KLAY_TYPE_INCOME = `Received `;
  readonly PROP_HISTORY_KLAY_TYPE_SPEND = `Used`;
  readonly PROP_HISTORY_CBLT_INCOME = `Total CBLT recieved`;
  readonly PROP_HISTORY_CBLT_SPEND = `Total used CBLT`;
  readonly PROP_HISTORY_CBLT_BALANCE = `CBLT balance`;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_ALL = `All `;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_INCOME = `Received `;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_SPEND = `Used`;
  readonly PROP_HISTORY_CBLT_TYPE_INCOME = `Received `;
  readonly PROP_HISTORY_CBLT_TYPE_SPEND = `Use`;
  readonly PROP_HISTORY_SUCC = `Success`;
  readonly PROP_HISTORY_FAIL = `Failed`;
  readonly PROP_HISTORY_QUERY_BTN = `Search`;
  readonly PROP_HISTORY_EMPTY_DATA = `There is no history yet.`;
  readonly PROP_HISTORY_QUERY_NOT_FONUD = `No results.`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_101 = `Buy Asset`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_102 = `Paymentwall Cash Out`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_103 = `Cashout withdrawal fee`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_201 = `Asset Sales Profit`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_202 = `Creator's royalty revenue`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_203 = `Asset Sales Reward`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_204 = `Asset Purchase Reward`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_205 = `Creator's asset sales reward`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_301 = `Charge POINT`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_302 = `Reward POINT charging through admin`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_303 = `Admin charge test`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_304 = `Charge POINT`;

  // ------------------------------------------------
  // 문의하기
  // ------------------------------------------------
  readonly INQUERY_TITLE = `Contact Us`;
  readonly INQUERY_TYPE = `What is your issue about?`;
  readonly INQUERY_TYPE_PROBLEM = `Report a concern (Report a bug or user)`;
  readonly INQUERY_TYPE_TASK = `Original creator request`;
  readonly INQUERY_TYPE_QUESTION = `Question`;
  readonly INQUERY_TYPE_INCIDENT = `Regarding Refunds`;
  readonly INQUERY_NAME = `Name`;
  readonly INQUERY_NAME_PLACEHOLDER = `Name of inquirer`;
  readonly INQUERY_EMAIL = `Email`;
  readonly INQUERY_EMAIL_PLACEHOLDER = `Email to recieve responses`;
  readonly INQUERY_SUBJECT = `Message`;
  readonly INQUERY_SUBJECT_PLACEHOLDER = `Subject`;
  readonly INQUERY_BODY_PALCEHOLDER = `Please fill out the specific details of the inquiry.`;
  readonly INQUERY_BTN = `Send`;
  readonly INQUERY_SUCCESS = `The inquiry has been sent.`;

  // ------------------------------------------------
  // 포인트 충전
  // ------------------------------------------------
  readonly DLG_CHARGE_TITLE = `Buy`;
  readonly DLG_CHARGE_UNIT_PRICE = `Item Price`;
  readonly DLG_CHARGE_FEE = `Fee`;
  readonly DLG_CHARGE_TOTAL_PRICE = `Total payment`;
  readonly DLG_CHARGE_FOOTER_TERMS = this.FOOTER_TERMS;
  readonly DLG_CHARGE_FOOTER_PRIVACY = this.FOOTER_PRIVACY;
  readonly DLG_CHARGE_FOOTER_REFUND_POLICY = this.FOOTER_REFUND_POLICY;

  // 기타
  readonly COMMON_UNIT_YEAR = `en년`;
  readonly COMMON_UNIT_MONTH = `en월`;
  readonly COMMON_UNIT_DAY = `en일`;
  readonly COMMON_UNIT_AM = `en오전`;
  readonly COMMON_UNIT_PM = `en오후`;

}
