/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTimeoutFn } from 'react-use';
import { useAuth } from 'src/hooks/useAuth';
import { useAuthStorage } from 'src/hooks/useAuthStorage';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CmnTxReqInfo_i, CommonRx_i, ServerECode_e } from 'src/model/rpcModel';
import {
  RPC_ASSETMGR_BOUGHT_LIST,
  RPC_ASSETMGR_COUNT,
  RPC_ASSETMGR_FAVORITE_LIST,
  RPC_ASSETMGR_INVENTORY_ASSET_LIST,
  RPC_ASSETMGR_INVENTORY_LIST,
  RPC_ASSETMGR_ISSUE_ASSET_LIST,
  RPC_ASSETMGR_ISSUE_LIST,
  RPC_ASSETMGR_SALE_LIST,
  RPC_ASSETMGR_SOLD_LIST,
  RPC_ASSETMGR_VIEW,
  RPC_ASSET_CREATOR_ADD,
  RPC_ASSET_CREATOR_COLLECTION_ADD,
  RPC_ASSET_CREATOR_COLLECTION_EDIT,
  RPC_ASSET_CREATOR_COLLECTION_EDIT_VIEW,
  RPC_ASSET_CREATOR_COLLECTION_LIST,
  RPC_ASSET_CREATOR_COLLECTION_VIEW,
  RPC_ASSET_CREATOR_GROUP_VIEW,
  RPC_ASSET_CREATOR_SCHEMA_ADD,
  RPC_ASSET_CREATOR_SCHEMA_EDIT,
  RPC_ASSET_CREATOR_SCHEMA_EDIT_VIEW,
  RPC_ASSET_CREATOR_SCHEMA_VIEW,
  RPC_ASSET_CREATOR_VIEW,
  RPC_HOME_INFO,
  RPC_MP_COLLECTION_CERTLIST,
  RPC_MP_SALE_ADD,
  RPC_MP_SALE_ADD_VIEW,
  RPC_MP_SALE_COLLECTION_ASSET_LIST,
  RPC_MP_SALE_COLLECTION_VIEW,
  RPC_MP_SALE_DETAIL,
  RPC_MP_SALE_EDIT,
  RPC_MP_SALE_EDIT_VIEW,
  RPC_MP_SALE_HISTORY,
  RPC_MP_SALE_INTEREST,
  RPC_MP_SALE_LIST,
  RPC_MP_SALE_PURCHASE_BEGIN,
  RPC_MP_SALE_PURCHASE_END,
  RPC_MP_SALE_PURCHASE_VIEW,
  RPC_MP_SALE_REPORT,
  RPC_MP_SALE_SCHEMA_ASSET_LIST,
  RPC_MP_SALE_SCHEMA_VIEW,
  RPC_PAYMENT_CHARGE_POINT_LIST,
  RPC_PAYMENT_CHARGE_POINT_WIDGET,
  RPC_PAYMENT_PAYPAL_CHARGE_POINT_CAPTURE,
  RPC_PAYMENT_PAYPAL_CHARGE_POINT_ORDER,
  RPC_SUPPORT_ZENDESK_ADDTASK,
  RPC_USER_ACCOUNT_ANTIPHISHING_RESET,
  RPC_USER_ACCOUNT_CBLT_HISTORY,
  RPC_USER_ACCOUNT_INFO_MUTABLE,
  RPC_USER_ACCOUNT_KLAY_HISTORY,
  RPC_USER_ACCOUNT_LANG_SET,
  RPC_USER_ACCOUNT_MESSAGE_LIST,
  RPC_USER_ACCOUNT_PASSWORD_CHECK,
  RPC_USER_ACCOUNT_PASSWORD_RESET,
  RPC_USER_ACCOUNT_PAYOUT_FINREQUEST,
  RPC_USER_ACCOUNT_PAYOUT_REQUEST,
  RPC_USER_ACCOUNT_POINT_HISTORY,
  RPC_USER_ACCOUNT_REWARDADDR_SET,
  RPC_USER_ACCOUNT_USD_HISTORY,
  RPC_USER_CERTIFY_EMAIL,
  RPC_USER_CERTIFY_EMAIL_TRY,
  RPC_USER_LOGIN,
  RPC_USER_PASSWORD_TEMP,
  RPC_USER_SIGNUP,
} from 'src/model/rpcType';
import { RootState } from 'src/redux/modules';
import { rpcThunk } from 'src/redux/modules/rpcModule';
import { PADThunkDispatch } from 'src/redux/store';
import Constants from 'src/res/constants';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW } from 'src/util/utils';

/**
  rpc 호출 흐름
  useRpc(hook)
  1. hook의 cdapRpc중 하나의 함수 호출
  2. specific function in cdapRpc
     rpc object 생성
     call 함수(mixin) 호출
  3. call in rpc object
     hook의 rpcThunkDispatchWithAToken 함수 호출
     자기 자신(this) rpc object를 인자로 전달
  4. _rpcObjThunkDispatchWithAToken in useRpc
     rpcObjThunk 리덕스 thunk dispatch
 */

export function useRpc() {
  const rpcState = useSelector((state: RootState) => state.rpcState);
  const { isLogin, authActs } = useAuth();
  const hHistory = useHistory();
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();

  const thunkDispatch: PADThunkDispatch = useDispatch();
  const hAuthStorage = useAuthStorage();

  const [isReady, timerCancel, timerReset] = useTimeoutFn(() => {
    if (isLogin) {
      DBGMSGW('logout');
      authActs.logout();
    }
    // }, Constants.LOGIN_TIMEOUT * 1000);
  }, 60 * 60 * 1000);

  const updateAuthCommonRes = async (commonRes: CommonRx_i) => {
    DBGMSG(`$$$$AUTH updateAuthCommonRes1`);
    if (commonRes.ucode === null) {
      return;
    }
    if (commonRes.token === null) {
      return;
    }
    if (commonRes.mkey === null) {
      return;
    }

    // let rewardAddrHash: string | null = null;
    // if (commonRes.reward_addr) {
    //   // 리워드 어드레스 해시로 저장
    //   rewardAddrHash = await Utils.crypto.sha256(commonRes.reward_addr);
    //   DBGMSG(`$$$$AUTH ${commonRes.reward_addr} -> ${rewardAddrHash}`);
    // }

    DBGMSG(`$$$$AUTH updateAuthCommonRes2`);
    authActs.updateAuthCommonRes({
      authCommonRes: {
        ucode: commonRes.ucode,
        token: commonRes.token,
        mkey: commonRes.mkey,
        cert_tp: commonRes.cert_tp,
        // prof: commonRes.prof,
        // reward_addr: commonRes.reward_addr,
        // reward_addr_hash: rewardAddrHash,
      },
    });
  };

  const cmnRpcThunkDispatch = async <Rx extends CommonRx_i>(args: Omit<CmnTxReqInfo_i, 'token'>) => {
    // 로그인 상태 인경우 토큰 체크
    if (isLogin) {
      if (hAuthStorage.getAuthInfo() === undefined) DBGMSGW(`error! hAuthStorage.getUserInfo() undefined`);

      DBGMSG('$$$$AUTH TOKEN VALID CHECK');
      DBGMSG('$$$$AUTH LOGOUT TIMER CANCEL');
      timerCancel(); // 타이머 초기화
      if (!hAuthStorage.isValidTokenInfo()) {
        DBGMSG('$$$$AUTH TOKEN IS INVALID');
        DBGMSG('logout');
        authActs.logout();
        return Promise.reject('TOKEN IS INVALID');
      } else {
        DBGMSG('$$$$AUTH TOKEN IS VALID');
        DBGMSG('$$$$AUTH LOGOUT TIMER RESET');
        timerReset(); // 타이머 재시작
      }
    }

    try {
      // *******************************************
      // 마지막 User Access Token 취득
      // *******************************************
      const token = hAuthStorage.getAuthInfo()?.token;
      DBGMSG(`$$$$AUTH_STORAGE getToken: ${token}`);

      // *******************************************
      // RPC 호출
      // *******************************************
      const res = await thunkDispatch(rpcThunk({ ...args, token }));

      // *******************************************
      // 새로운 User Access Token 저장
      // *******************************************
      await updateAuthCommonRes(res);

      return Promise.resolve(res as Rx);
    } catch (err) {
      const errInfo = getRpcErrorInfo(err);
      if (errInfo) {
        // *******************************************
        // 새로운 User Access Token 저장
        // *******************************************
        await updateAuthCommonRes(err as CommonRx_i);

        // 공통 에러처리부분
        if (errInfo.ecode === ServerECode_e.REQUIRED_LOGIN) {
          DBGMSG('logout');
          authActs.logout();
          hHistory.replace(Nav.UserLoginScreen);
        }
      }
      return Promise.reject(err);
    }
  };

  const cobaltRpc = {
    /**
     * [02.01.01] 가입을 위해 사용할 이메일 인증 : 인증 코드 받기
     */
    rpcUserCertifyEmail: async (tx: RPC_USER_CERTIFY_EMAIL.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_CERTIFY_EMAIL.Rx>({
        url: RPC_USER_CERTIFY_EMAIL.url,
        method: RPC_USER_CERTIFY_EMAIL.method,
        tx,
      });
    },

    /**
     * [02.01.02] 가입을 위해 사용할 이메일 인증 : 메일로 전송받은 인증 코드 확인
     */
    rpcUserCertifyEmailTry: async (tx: RPC_USER_CERTIFY_EMAIL_TRY.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_CERTIFY_EMAIL_TRY.Rx>({
        url: RPC_USER_CERTIFY_EMAIL_TRY.url,
        method: RPC_USER_CERTIFY_EMAIL_TRY.method,
        tx,
      });
    },

    /**
     * [02.01.03] 회원 가입
     */
    rpcUserSignup: async (tx: RPC_USER_SIGNUP.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_SIGNUP.Rx>({
        url: RPC_USER_SIGNUP.url,
        method: RPC_USER_SIGNUP.method,
        tx,
      });
    },

    /**
     * [02.01.04] 로그인
     */
    rpcUserLogin: async (tx: RPC_USER_LOGIN.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_LOGIN.Rx>({
        url: RPC_USER_LOGIN.url,
        method: RPC_USER_LOGIN.method,
        tx,
      });
    },

    /**
     * [02.01.05] 임시 비밀번호 발급
     */
    rpcUserPasswordTemp: async (tx: RPC_USER_PASSWORD_TEMP.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_PASSWORD_TEMP.Rx>({
        url: RPC_USER_PASSWORD_TEMP.url,
        method: RPC_USER_PASSWORD_TEMP.method,
        tx,
      });
    },

    /**
     * [02.01.06] 사용자 계정 관리 : 비밀번호 변경
     */
    rpcUserAccountPasswordReset: async (tx: RPC_USER_ACCOUNT_PASSWORD_RESET.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_PASSWORD_RESET.Rx>({
        url: RPC_USER_ACCOUNT_PASSWORD_RESET.url,
        method: RPC_USER_ACCOUNT_PASSWORD_RESET.method,
        tx,
      });
    },

    /**
     * [02.01.07] 사용자 계정 관리 : 피싱방지코드 변경
     */
    rpcUserAccountAccountAntiphishingReset: async (tx: RPC_USER_ACCOUNT_ANTIPHISHING_RESET.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_ANTIPHISHING_RESET.Rx>({
        url: RPC_USER_ACCOUNT_ANTIPHISHING_RESET.url,
        method: RPC_USER_ACCOUNT_ANTIPHISHING_RESET.method,
        tx,
      });
    },

    /**
     * [02.01.08] 사용자 계정 관리 : 입금용 Klaytn계좌 설정
     */
    rpcUserAccountAccountRewardaddrSet: async (tx: RPC_USER_ACCOUNT_REWARDADDR_SET.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_REWARDADDR_SET.Rx>({
        url: RPC_USER_ACCOUNT_REWARDADDR_SET.url,
        method: RPC_USER_ACCOUNT_REWARDADDR_SET.method,
        tx,
      });
    },

    /**
     * [02.01.09] 사용자 보안 : 패스워드 검증
     */
    rpcUserAccountPasswordCheck: async (tx: RPC_USER_ACCOUNT_PASSWORD_CHECK.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_PASSWORD_CHECK.Rx>({
        url: RPC_USER_ACCOUNT_PASSWORD_CHECK.url,
        method: RPC_USER_ACCOUNT_PASSWORD_CHECK.method,
        tx,
      });
    },

    /**
     * [02.01.10] 사용자 설정 언어 저장(/user/account/lang/set/{lang})
     */
    rpcUserAccountLangSet: async (tx: RPC_USER_ACCOUNT_LANG_SET.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_LANG_SET.Rx>({
        url: `${RPC_USER_ACCOUNT_LANG_SET.url}/${tx.lang}`,
        method: RPC_USER_ACCOUNT_LANG_SET.method,
        tx: {},
      });
    },

    /**
     * [02.01.11] 사용자 변경 가능 정보 획득(/user/account/info/mutable)
     */
    rpcUserAccountInfoMutable: async (tx: RPC_USER_ACCOUNT_INFO_MUTABLE.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_INFO_MUTABLE.Rx>({
        url: RPC_USER_ACCOUNT_INFO_MUTABLE.url,
        method: RPC_USER_ACCOUNT_INFO_MUTABLE.method,
        tx,
      });
    },

    /**
     * [02.01.12] 유저 알림 내역 ( /user/account/message/list )
     */
    rpcUserAccountMessageList: async (tx: RPC_USER_ACCOUNT_MESSAGE_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_MESSAGE_LIST.Rx>({
        url: RPC_USER_ACCOUNT_MESSAGE_LIST.url,
        method: RPC_USER_ACCOUNT_MESSAGE_LIST.method,
        tx,
      });
    },

    /**
     * [02.01.13] Point History ( /user/account/point/history )
     */
    rpcUserAccountPointHistory: async (tx: RPC_USER_ACCOUNT_POINT_HISTORY.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_POINT_HISTORY.Rx>({
        url: RPC_USER_ACCOUNT_POINT_HISTORY.url,
        method: RPC_USER_ACCOUNT_POINT_HISTORY.method,
        tx,
      });
    },

    /**
     * [02.01.14] USD History ( /user/account/usd/history )
     */
    rpcUserAccountUsdHistory: async (tx: RPC_USER_ACCOUNT_USD_HISTORY.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_USD_HISTORY.Rx>({
        url: RPC_USER_ACCOUNT_USD_HISTORY.url,
        method: RPC_USER_ACCOUNT_USD_HISTORY.method,
        tx,
      });
    },

    /**
     * [02.01.15] CBLT History ( /user/account/cblt/history )
     */
    rpcUserAccountCbltHistory: async (tx: RPC_USER_ACCOUNT_CBLT_HISTORY.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_CBLT_HISTORY.Rx>({
        url: RPC_USER_ACCOUNT_CBLT_HISTORY.url,
        method: RPC_USER_ACCOUNT_CBLT_HISTORY.method,
        tx,
      });
    },

    /**
     * [02.01.16] KLAY History ( /user/account/klay/history )
     */
    rpcUserAccountKlayHistory: async (tx: RPC_USER_ACCOUNT_KLAY_HISTORY.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_KLAY_HISTORY.Rx>({
        url: RPC_USER_ACCOUNT_KLAY_HISTORY.url,
        method: RPC_USER_ACCOUNT_KLAY_HISTORY.method,
        tx,
      });
    },

    /**
     * [02.01.17_1] 인출 신청 ( /user/account/payout/request )
     */
    rpcUserAccountPayoutRequest: async (tx: RPC_USER_ACCOUNT_PAYOUT_REQUEST.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_PAYOUT_REQUEST.Rx>({
        url: RPC_USER_ACCOUNT_PAYOUT_REQUEST.url,
        method: RPC_USER_ACCOUNT_PAYOUT_REQUEST.method,
        tx,
      });
    },

    /**
     * [02.01.17_2] CBLT 수수료 인출 / 결과 전송 ( /user/account/payout/finrequest )
     */
    rpcUserAccountPayoutFinrequest: async (tx: RPC_USER_ACCOUNT_PAYOUT_FINREQUEST.Tx) => {
      return cmnRpcThunkDispatch<RPC_USER_ACCOUNT_PAYOUT_FINREQUEST.Rx>({
        url: RPC_USER_ACCOUNT_PAYOUT_FINREQUEST.url,
        method: RPC_USER_ACCOUNT_PAYOUT_FINREQUEST.method,
        tx,
      });
    },

    /**
     * [02.02.01] asset collection 생성
     */
    rpcAssetCreatorCollectionAdd: async (tx: RPC_ASSET_CREATOR_COLLECTION_ADD.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_COLLECTION_ADD.Rx>({
        url: RPC_ASSET_CREATOR_COLLECTION_ADD.url,
        method: RPC_ASSET_CREATOR_COLLECTION_ADD.method,
        tx,
      });
    },

    /**
     * [02.02.02] asset schema 생성
     */
    rpcAssetCreatorSchemaAdd: async (tx: RPC_ASSET_CREATOR_SCHEMA_ADD.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_SCHEMA_ADD.Rx>({
        url: RPC_ASSET_CREATOR_SCHEMA_ADD.url,
        method: RPC_ASSET_CREATOR_SCHEMA_ADD.method,
        tx,
      });
    },

    /**
     * [02.02.03] asset 생성
     */
    rpcAssetCreatorAdd: async (tx: RPC_ASSET_CREATOR_ADD.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_ADD.Rx>({
        url: RPC_ASSET_CREATOR_ADD.url,
        method: RPC_ASSET_CREATOR_ADD.method,
        tx,
      });
    },

    /**
     * [02.02.04] 컬렉션 목록
     */
    rpcAssetCreatorCollectionList: async (tx: RPC_ASSET_CREATOR_COLLECTION_LIST.Tx) => {
      // return mockData.mockAssetCreator;
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_COLLECTION_LIST.Rx>({
        url: RPC_ASSET_CREATOR_COLLECTION_LIST.url,
        method: RPC_ASSET_CREATOR_COLLECTION_LIST.method,
        tx,
      });
    },

    /**
     * [02.02.05] 컬렉션 보기
     */
    rpcAssetCreatorCollectionView: async (tx: RPC_ASSET_CREATOR_COLLECTION_VIEW.Tx) => {
      // return mockData.mockMyCollectionRead;
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_COLLECTION_VIEW.Rx>({
        url: RPC_ASSET_CREATOR_COLLECTION_VIEW.url,
        method: RPC_ASSET_CREATOR_COLLECTION_VIEW.method,
        tx,
      });
    },

    /**
     * [02.02.06_1] 컬렉션 편집 정보 보기
     */
    rpcAssetCreatorCollectionEditView: async (cid: number) => {
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_COLLECTION_EDIT_VIEW.Rx>({
        url: `${RPC_ASSET_CREATOR_COLLECTION_EDIT_VIEW.url}/${cid}`,
        method: RPC_ASSET_CREATOR_COLLECTION_EDIT_VIEW.method,
        tx: {},
      });
    },

    /**
     * [02.02.06_2] 컬렉션 편집 하기
     */
    rpcAssetCreatorCollectionEdit: async (tx: RPC_ASSET_CREATOR_COLLECTION_EDIT.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_COLLECTION_EDIT.Rx>({
        url: RPC_ASSET_CREATOR_COLLECTION_EDIT.url,
        method: RPC_ASSET_CREATOR_COLLECTION_EDIT.method,
        tx,
      });
    },

    /**
     * [02.02.07] 스키마 보기
     */
    rpcAssetCreatorSchemaView: async (tx: RPC_ASSET_CREATOR_SCHEMA_VIEW.Tx) => {
      // return mockData.mockAssetCreatorSchemaView;
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_SCHEMA_VIEW.Rx>({
        url: RPC_ASSET_CREATOR_SCHEMA_VIEW.url,
        method: RPC_ASSET_CREATOR_SCHEMA_VIEW.method,
        tx,
      });
    },

    /**
     * [02.02.08_1] 스키마 편집 정보 보기
     */
    rpcAssetCreatorSchemaEditView: async (sid: number) => {
      // return mockData.mockAssetCreatorSchemaEditView;
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_SCHEMA_EDIT_VIEW.Rx>({
        url: `${RPC_ASSET_CREATOR_SCHEMA_EDIT_VIEW.url}/${sid}`,
        method: RPC_ASSET_CREATOR_SCHEMA_EDIT_VIEW.method,
        tx: {},
      });
    },

    /**
     * [02.02.08_2] 스키마 편집 하기
     */
    rpcAssetCreatorSchemaEdit: async (tx: RPC_ASSET_CREATOR_SCHEMA_EDIT.Tx) => {
      // return mockData.mockAssetCreatorSchemaEditView;
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_SCHEMA_EDIT.Rx>({
        url: RPC_ASSET_CREATOR_SCHEMA_EDIT.url,
        method: RPC_ASSET_CREATOR_SCHEMA_EDIT.method,
        tx,
      });
    },

    /**
     * [02.02.09] 에셋 그룹의 자산 목록(/asset/creator/group/view)
     */
    rpcAssetCreatorGroupView: async (tx: RPC_ASSET_CREATOR_GROUP_VIEW.Tx) => {
      // return mockData.mockAssetCreatorGroupView;
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_GROUP_VIEW.Rx>({
        url: RPC_ASSET_CREATOR_GROUP_VIEW.url,
        method: RPC_ASSET_CREATOR_GROUP_VIEW.method,
        tx,
      });
    },

    /**
     * [02.02.10] 자산 상세보기
     */
    rpcAssetCreatorView: async (tx: RPC_ASSET_CREATOR_VIEW.Tx) => {
      // return mockData.mockAssetCreatorView;
      return cmnRpcThunkDispatch<RPC_ASSET_CREATOR_VIEW.Rx>({
        url: RPC_ASSET_CREATOR_VIEW.url,
        method: RPC_ASSET_CREATOR_VIEW.method,
        tx,
      });
    },

    /**
     * [02.03.01_1] 판매 등록 팝업 표시 정보
     */
    rpcMpSaleAddView: async (tx: RPC_MP_SALE_ADD_VIEW.Tx) => {
      // return mockData.mockMpSaleAddView;
      return cmnRpcThunkDispatch<RPC_MP_SALE_ADD_VIEW.Rx>({
        url: RPC_MP_SALE_ADD_VIEW.url,
        method: RPC_MP_SALE_ADD_VIEW.method,
        tx,
      });
    },

    /**
     * [02.03.01_2] 판매 등록
     */
    rpcMpSaleAdd: async (tx: RPC_MP_SALE_ADD.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_ADD.Rx>({
        url: RPC_MP_SALE_ADD.url,
        method: RPC_MP_SALE_ADD.method,
        tx,
      });
    },

    /**
     * [02.03.02_1] 판매 편집 정보 보기
     */
    rpcMpSaleEditView: async (tx: RPC_MP_SALE_EDIT_VIEW.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_EDIT_VIEW.Rx>({
        url: RPC_MP_SALE_EDIT_VIEW.url,
        method: RPC_MP_SALE_EDIT_VIEW.method,
        tx,
      });
    },

    /**
     * [02.03.02_2] 판매 편집 하기
     */
    rpcMpSaleEdit: async (tx: RPC_MP_SALE_EDIT.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_EDIT.Rx>({
        url: RPC_MP_SALE_EDIT.url,
        method: RPC_MP_SALE_EDIT.method,
        tx,
      });
    },

    /**
     * [02.03.03] 판매 상품 목록
     */
    rpcMpSaleList: async (tx: RPC_MP_SALE_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_LIST.Rx>({
        url: RPC_MP_SALE_LIST.url,
        method: RPC_MP_SALE_LIST.method,
        tx,
      });
    },

    /**
     * [02.03.04] 판매 상품 상세보기 (/mp/sale/detail )
     */
    rpcMpSaleDetail: async (tx: RPC_MP_SALE_DETAIL.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_DETAIL.Rx>({
        url: RPC_MP_SALE_DETAIL.url,
        method: RPC_MP_SALE_DETAIL.method,
        tx,
      });
    },

    /**
     * [02.03.05_0] 판매 상품 구매 팝업 표시 정보
     */
    rpcMpSalePurchaseView: async (tx: RPC_MP_SALE_PURCHASE_VIEW.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_PURCHASE_VIEW.Rx>({
        url: RPC_MP_SALE_PURCHASE_VIEW.url,
        method: RPC_MP_SALE_PURCHASE_VIEW.method,
        tx,
      });
    },

    /**
     * [02.03.05_1] 판매 상품 klay로 구매 : tx 시작, uuid 발급(/mp/sale/purchase/begin)
     */
    rpcMpSalePurchaseBegin: async (tx: RPC_MP_SALE_PURCHASE_BEGIN.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_PURCHASE_BEGIN.Rx>({
        url: RPC_MP_SALE_PURCHASE_BEGIN.url,
        method: RPC_MP_SALE_PURCHASE_BEGIN.method,
        tx,
      });
    },

    /**
     * [02.03.05_2] 판매 상품 klay로 구매 : tx 완료, klay 송금 결과 업데이트(/mp/sale/purchase/end)
     */
    rpcMpSalePurchaseEnd: async (tx: RPC_MP_SALE_PURCHASE_END.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_PURCHASE_END.Rx>({
        url: RPC_MP_SALE_PURCHASE_END.url,
        method: RPC_MP_SALE_PURCHASE_END.method,
        tx,
      });
    },

    /**
     * [02.03.06] 판매 게시글 관심 등록/해제(/mp/sale/interest)
     */
    rpcMpSaleInteres: async (tx: RPC_MP_SALE_INTEREST.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_INTEREST.Rx>({
        url: RPC_MP_SALE_INTEREST.url,
        method: RPC_MP_SALE_INTEREST.method,
        tx,
      });
    },

    /**
     * [02.03.07] 판매 게시글 신고(/mp/sale/report)
     */
    rpcMpSaleReport: async (tx: RPC_MP_SALE_REPORT.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_REPORT.Rx>({
        url: RPC_MP_SALE_REPORT.url,
        method: RPC_MP_SALE_REPORT.method,
        tx,
      });
    },

    /**
     * [02.03.08] NFT 거래내역 ( /mp/sale/history )
     */
    rpcMpSaleHistory: async (tx: RPC_MP_SALE_HISTORY.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_HISTORY.Rx>({
        url: RPC_MP_SALE_HISTORY.url,
        method: RPC_MP_SALE_HISTORY.method,
        tx,
      });
    },

    /**
     * [02.03.09] 마켓플레이스 컬렉션 보기 ( /mp/sale/collection/view )
     */
    rpcMpSaleCollectionView: async (tx: RPC_MP_SALE_COLLECTION_VIEW.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_COLLECTION_VIEW.Rx>({
        url: RPC_MP_SALE_COLLECTION_VIEW.url,
        method: RPC_MP_SALE_COLLECTION_VIEW.method,
        tx,
      });
    },

    /**
     * [02.03.10] 마켓플레이스 컬렉션 애셋 목록 ( /mp/sale/collection/asset/list )
     */
    rpcMpSaleCollectionAssetList: async (tx: RPC_MP_SALE_COLLECTION_ASSET_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_COLLECTION_ASSET_LIST.Rx>({
        url: RPC_MP_SALE_COLLECTION_ASSET_LIST.url,
        method: RPC_MP_SALE_COLLECTION_ASSET_LIST.method,
        tx,
      });
    },

    /**
     * [02.03.11] 마켓플레이스 스키마 보기 ( /mp/sale/schema/view )
     */
    rpcMpSaleSchemaView: async (tx: RPC_MP_SALE_SCHEMA_VIEW.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_SCHEMA_VIEW.Rx>({
        url: RPC_MP_SALE_SCHEMA_VIEW.url,
        method: RPC_MP_SALE_SCHEMA_VIEW.method,
        tx,
      });
    },

    /**
     * [02.03.12] 마켓플레이스 스키마 애셋 목록 ( /mp/sale/schema/asset/list )
     */
    rpcMpSaleSchemaAssetList: async (tx: RPC_MP_SALE_SCHEMA_ASSET_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_SALE_SCHEMA_ASSET_LIST.Rx>({
        url: RPC_MP_SALE_SCHEMA_ASSET_LIST.url,
        method: RPC_MP_SALE_SCHEMA_ASSET_LIST.method,
        tx,
      });
    },

    /**
     * [02.03.13] 공인 컬렉션 전체 목록 ( /mp/sale/collection/certlist )
     */
    rpcMpSaleCollectionCertlist: async (tx: RPC_MP_COLLECTION_CERTLIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_MP_COLLECTION_CERTLIST.Rx>({
        url: RPC_MP_COLLECTION_CERTLIST.url,
        method: RPC_MP_COLLECTION_CERTLIST.method,
        tx,
      });
    },

    /**
     * [02.04.01] Point 충전 상품 목록 (/payment/charge/point/list)
     */
    rpcPaymentChargePointList: async (tx: RPC_PAYMENT_CHARGE_POINT_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_PAYMENT_CHARGE_POINT_LIST.Rx>({
        url: RPC_PAYMENT_CHARGE_POINT_LIST.url,
        method: RPC_PAYMENT_CHARGE_POINT_LIST.method,
        tx,
      });
    },

    /**
     * [02.04.02] Paymentwall Widget URL ( /payment/charge/point/widget )
     */
    rpcPaymentChargePointWidget: async (tx: RPC_PAYMENT_CHARGE_POINT_WIDGET.Tx) => {
      return cmnRpcThunkDispatch<RPC_PAYMENT_CHARGE_POINT_WIDGET.Rx>({
        url: RPC_PAYMENT_CHARGE_POINT_WIDGET.url,
        method: RPC_PAYMENT_CHARGE_POINT_WIDGET.method,
        tx,
      });
    },

    /**
     * [02.04.03.01] 주문번호 생성 ( /payment/paypal/charge/point/order )
     */
    rpcPaymentPaypalChargePointOrder: async (tx: RPC_PAYMENT_PAYPAL_CHARGE_POINT_ORDER.Tx) => {
      return cmnRpcThunkDispatch<RPC_PAYMENT_PAYPAL_CHARGE_POINT_ORDER.Rx>({
        url: RPC_PAYMENT_PAYPAL_CHARGE_POINT_ORDER.url,
        method: RPC_PAYMENT_PAYPAL_CHARGE_POINT_ORDER.method,
        tx,
      });
    },

    /**
     * [02.04.03.02] 주문 승인 ( /payment/paypal/charge/point/capture )
     */
    rpcPaymentPaypalChargePointCapture: async (tx: RPC_PAYMENT_PAYPAL_CHARGE_POINT_CAPTURE.Tx) => {
      return cmnRpcThunkDispatch<RPC_PAYMENT_PAYPAL_CHARGE_POINT_CAPTURE.Rx>({
        url: RPC_PAYMENT_PAYPAL_CHARGE_POINT_CAPTURE.url,
        method: RPC_PAYMENT_PAYPAL_CHARGE_POINT_CAPTURE.method,
        tx,
      });
    },

    /**
     * [02.05] Home ( /home/info )
     */
    rpcHomeInfo: async (tx: RPC_HOME_INFO.Tx) => {
      return cmnRpcThunkDispatch<RPC_HOME_INFO.Rx>({
        url: RPC_HOME_INFO.url,
        method: RPC_HOME_INFO.method,
        tx,
      });
    },

    /**
     * [02.06.01] 인벤토리 ( /assetmgr/inventory/list )
     */
    rpcAssetmgrInventoryList: async (tx: RPC_ASSETMGR_INVENTORY_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_INVENTORY_LIST.Rx>({
        url: RPC_ASSETMGR_INVENTORY_LIST.url,
        method: RPC_ASSETMGR_INVENTORY_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.02] 인벤토리 에셋 상세 목록 ( /assetmgr/inventory/asset/list )
     */
    rpcAssetmgrInventoryAssetList: async (tx: RPC_ASSETMGR_INVENTORY_ASSET_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_INVENTORY_ASSET_LIST.Rx>({
        url: RPC_ASSETMGR_INVENTORY_ASSET_LIST.url,
        method: RPC_ASSETMGR_INVENTORY_ASSET_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.03] 발행 에셋 ( /assetmgr/issue/list )
     */
    rpcAssetmgrIssueList: async (tx: RPC_ASSETMGR_ISSUE_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_ISSUE_LIST.Rx>({
        url: RPC_ASSETMGR_ISSUE_LIST.url,
        method: RPC_ASSETMGR_ISSUE_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.04] 발행 에셋 상세 목록 ( /assetmgr/issue/asset/list )
     */
    rpcAssetmgrIssueAssetList: async (tx: RPC_ASSETMGR_ISSUE_ASSET_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_ISSUE_ASSET_LIST.Rx>({
        url: RPC_ASSETMGR_ISSUE_ASSET_LIST.url,
        method: RPC_ASSETMGR_ISSUE_ASSET_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.05] 인벤토리 에셋 상세 목록 ( /assetmgr/view )
     */
    rpcAssetmgrView: async (tx: RPC_ASSETMGR_VIEW.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_VIEW.Rx>({
        url: RPC_ASSETMGR_VIEW.url,
        method: RPC_ASSETMGR_VIEW.method,
        tx,
      });
    },

    /**
     * [02.06.06] 판매중 에셋 리스트 ( /assetmgr/sale/list )
     */
    rpcAssetmgrSaleList: async (tx: RPC_ASSETMGR_SALE_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_SALE_LIST.Rx>({
        url: RPC_ASSETMGR_SALE_LIST.url,
        method: RPC_ASSETMGR_SALE_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.07] 판매 완료 에셋 리스트 ( /assetmgr/sold/list )
     */
    rpcAssetmgrSoldList: async (tx: RPC_ASSETMGR_SOLD_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_SOLD_LIST.Rx>({
        url: RPC_ASSETMGR_SOLD_LIST.url,
        method: RPC_ASSETMGR_SOLD_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.08] 판매 완료 에셋 리스트 ( /assetmgr/bought/list )
     */
    rpcAssetmgrBoughtList: async (tx: RPC_ASSETMGR_BOUGHT_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_BOUGHT_LIST.Rx>({
        url: RPC_ASSETMGR_BOUGHT_LIST.url,
        method: RPC_ASSETMGR_BOUGHT_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.09] 즐겨찾기 목록 ( /assetmgr/favorite/list )
     */
    rpcAssetmgrFavoriteList: async (tx: RPC_ASSETMGR_FAVORITE_LIST.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_FAVORITE_LIST.Rx>({
        url: RPC_ASSETMGR_FAVORITE_LIST.url,
        method: RPC_ASSETMGR_FAVORITE_LIST.method,
        tx,
      });
    },

    /**
     * [02.06.10] MyPage 자산 개수 ( /assetmgr/count )
     */
    rpcAssetmgrCount: async (tx: RPC_ASSETMGR_COUNT.Tx) => {
      return cmnRpcThunkDispatch<RPC_ASSETMGR_COUNT.Rx>({
        url: RPC_ASSETMGR_COUNT.url,
        method: RPC_ASSETMGR_COUNT.method,
        tx,
      });
    },

    /**
     * [02.07.01] Zendesk 문의하기 ( /support/zendesk/addtask )
     */
    rpcSupportZendeskAddtask: async (tx: RPC_SUPPORT_ZENDESK_ADDTASK.Tx) => {
      return cmnRpcThunkDispatch<RPC_SUPPORT_ZENDESK_ADDTASK.Rx>({
        url: RPC_SUPPORT_ZENDESK_ADDTASK.url,
        method: RPC_SUPPORT_ZENDESK_ADDTASK.method,
        tx,
      });
    },

    /**
     * 알파용 포인트 수도꼭지
     * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98626288
     */
    rpcPaymentFaucetPoint: async (args: { ucode: string; amount: number }) => {
      return axios.get(`https://nft-alpha-rpc.cobalt.zone/nftex/payment/faucet/point/${args.ucode}/${args.amount}`);
    },

    /**
     * 테테스스트트
     * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=98626288
     */
    rpcTest: async () => {
      // return axios.get(`https://nft-alpha-rpc.cobalt.zone/nftex/payment/faucet/point/${args.ucode}/${args.amount}`);
      return axios.post(`https://nft-alpha-rpc.cobalt.zone/nftex/home/info`);
    },

    // /**
    //  * 테스트 로그인  RPC
    //  * @param tx
    //  */
    // rpcAuthTestClient: async (tx: RPC_AUTH_TEST_CLIENT.Tx) => {
    //   return cmnRpcThunkDispatch<RPC_AUTH_TEST_CLIENT.Rx>({
    //     url: RPC_AUTH_TEST_CLIENT.url,
    //     method: RPC_AUTH_TEST_CLIENT.method,
    //     tx,
    //   });
    // },
  };

  const showErrorInfoDlg = ({ err, onOk }: { err: any; onOk?: () => void }) => {
    DBGMSG(`showErrorInfoDlg`);
    const errinfo = getRpcErrorInfo(err);

    if (errinfo) {
      switch (errinfo.ecode) {
        case ServerECode_e.ERR_MP_TRY_PURCHASE_BY_SELLER_SELF:
          hDlgFactory.showSimpleOk({
            text: hR.strings.MARKET_DLG_ERROR_ALREADY_OWNER,
            onOk: () => {
              onOk && onOk();
            },
          });
          break;
        case ServerECode_e.BLOCKED_USER:
        case ServerECode_e.BANNED_USER:
          hHistory.replace(Nav.UserBan);
          break;
        case 6105:
          window.location.href = `${Constants.SERVICE_NOT_AVAILABLE_URL_EN}?lang=${hR.lang}`;
          break;
        case 136:
          hDlgFactory.showSimpleOk({
            text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}`,
            onOk: () => {
              hHistory.push(Nav.AssetCreatorBlockedScreen);
            },
          });
          break;
        case ServerECode_e.CANNOT_FIND_USER:
          hDlgFactory.showSimpleOk({ text: `Can't find user by email` });
          break;
        default:
          hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
          break;
      }
    } else {
      hDlgFactory.showSimpleOk({ text: `network fail` });
    }
  };

  return {
    rpcState,
    cobaltRpc,
    hanleCommonError: showErrorInfoDlg,
  };
}
