/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { AnimatedFlexRowDiv, devOutlineF, FlexColumnDiv, FlexRowDiv, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMouseEnterEffect } from 'src/hooks/useMouseEnterEffect';
import R from 'src/res/R';
import { COMMON_BOX_HORIZONTAL_PADDING, CLAY, PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import ScreenInfo from 'src/context/screen_constants';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  width?: number | string;
  isAnim?: boolean;
  onAdd?: () => void;
};

export function SchemaAttributeAdd({ width = '100%', isAnim = false, onAdd }: Props) {
  const hR = useLocalSettings();
  const { hAnimProps, mouseEnter, mouseLeave } = useMouseEnterEffect({
    defProps: {
      ...PropsSRoundDisabled,
      backgroundColor: R.colors.bgGrey,
      // ...PropsSRoundEnabled,
    },
    enterProps: {
      ...PropsSRoundEnabled,
      backgroundColor: R.colors.bgGrey,
      // ...PropsSRoundDisabled,
    },
  });

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv
        style={{
          width: width,
          // ...devOutlineF,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              width: width,
              alignItems: 'center',
              height: 60,
              backgroundColor: R.colors.bgGrey,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: R.colors.line,
              borderRadius: 15,
              cursor: onAdd ? 'pointer' : 'auto',
              justifyContent: 'center',
              // ...devOutlineF,
              // paddingLeft: 14,
              // paddingRight: 14,
            },
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
          onClick={() => {
            onAdd && onAdd();
          }}
        >
          <FlexRowDiv
            style={
              {
                // ...devOutlineF
              }
            }
          >
            <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR_ADD_BTN} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
            <WIDTH size={4} />
            <Img src={[R.images.creator_btn_add3x, CLAY.NORMAL_IC_SIZE, CLAY.NORMAL_IC_SIZE]} />
          </FlexRowDiv>
        </AnimatedFlexRowDiv>
      </FlexColumnDiv>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          width: width,
          paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
          paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
          paddingBottom: 10,
          // ...devOutlineF,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              alignItems: 'center',
              height: 68,
              backgroundColor: R.colors.bgGrey,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: R.colors.line,
              borderRadius: 15,
              cursor: onAdd ? 'pointer' : 'auto',
              // ...devOutline,
              // paddingLeft: 14,
              // paddingRight: 14,
            },
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
          onClick={() => {
            onAdd && onAdd();
          }}
        >
          {/* <Txt text={name} size={14} color={R.colors.txtPrimary} isBold containerStyle={{ paddingLeft: 14, width: '36%' }} /> */}
          <WIDTH size={'36%'} />
          {/* line */}
          <WIDTH size={1} />

          {/* <FlexDiv style={{ height: 50, width: 1, backgroundColor: R.colors.veryLightGrey2 }} /> */}
          <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR_ADD_BTN} size={14} color={R.colors.black} containerStyle={{ paddingLeft: 14 }} />
          <WIDTH size={4} />
          <Img
            src={[R.images.creator_btn_add3x, 24, 24]}
            // onClick={() => {
            //   onAdd && onAdd();
            // }}
          />
        </AnimatedFlexRowDiv>
      </FlexColumnDiv>
    );
}
