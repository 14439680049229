import React from 'react';
import { isAlpha, isBeta } from 'src/conf/AppConf';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { FlexColumnDiv, FlexRowDiv, WIDTH } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtnAdvType2 } from 'src/ui/common_component/button/RoundTxtBtnAdvType2';

export type RewardAddrChangeJsxProps = {
  orgAddr: string;
  newAddr: string;
  onChange: () => void;
  onClose: () => void;
};

export const RewardAddrChangeJsx = ({ orgAddr, newAddr, onChange, onClose }: RewardAddrChangeJsxProps) => {
  const hR = useLocalSettings();
  return (
    <FlexColumnDiv
      style={{
        width: 450,
        height: 272,
        padding: 40,
        alignItems: 'cetner',
        // ...devOutlineF
      }}
    >
      <TextCon
        text={hR.strings.WALLET_REWARD_ADDR_CHAGNE_TITLE}
        textStyle={{ textAlign: 'center' }}
        color={R.colors.black}
        size={16}
        isBold
        containerStyle={{
          // ...devOutlineF,
          justifyContent: 'center',
        }}
      />
      <TextCon
        text={hR.strings.WALLET_REWARD_ADDR_CHAGNE_DESC}
        color={R.colors.black}
        size={14}
        containerStyle={{
          // ...devOutlineF,
          justifyContent: 'center',
          marginTop: 15,
        }}
      />
      <FlexRowDiv style={{ marginTop: 'auto' }}>
        <RoundTxtBtnAdvType2
          text={hR.strings.WALLET_REWARD_ADDR_CHAGNE_BTN}
          width={180}
          height={48}
          fontSize={14}
          onClick={() => {
            onChange();
          }}
        />
        <WIDTH size={10} />
        <RoundTxtBtn
          text={hR.strings.WALLET_REWARD_ADDR_CLOSE_BTN}
          width={180}
          height={48}
          fontSize={14}
          onClick={() => {
            onClose();
          }}
        />
      </FlexRowDiv>

      {(isAlpha() || isBeta()) && (
        <FlexColumnDiv style={{ position: 'absolute', top: 300, width: 700, backgroundColor: R.colors.bgGrey, padding: 10 }}>
          <TextCon text={'alpha/beta에서만 보임'} size={14} isBold />
          <TextCon text={`${orgAddr} - 기존에 등록된주소`} size={14} color={R.colors.pastelRed} isBold />
          <TextCon text={`${newAddr} - Kaikas에 활성화된 주소`} size={14} color={R.colors.pastelRed} isBold />
        </FlexColumnDiv>
      )}
    </FlexColumnDiv>
  );
};
