/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CbtPgInfo_i } from 'src/model/rpcModel';
import { RPC_MP_SALE_COLLECTION_ASSET_LIST, RPC_MP_SALE_COLLECTION_VIEW } from 'src/model/rpcType';

/****************************************/
// state
/****************************************/
export type MarketColState_t = {
  cid?: number;
  detail?: RPC_MP_SALE_COLLECTION_VIEW.Rx;
  listNeedUpdate: boolean;
  lastRx?: RPC_MP_SALE_COLLECTION_ASSET_LIST.Rx;
  lastPgInfo?: CbtPgInfo_i;
  list?: RPC_MP_SALE_COLLECTION_ASSET_LIST.Rx['sale_list'];
};

const initialState: MarketColState_t = {
  listNeedUpdate: true,
};

const marketColSlice = createSlice({
  name: 'userMarketColState',
  initialState: initialState,
  reducers: {
    clear: (state) => {
      state = {
        ...initialState,
      };
      return state;
    },
    setState: (state, action: PayloadAction<MarketColState_t>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    changeState: (state, action: PayloadAction<Partial<MarketColState_t>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export default marketColSlice;
