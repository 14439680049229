/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettingsStorage } from 'src/hooks/useLocalSettingsStorage';
// import { useRpc } from 'src/hooks/useRpc';
import { DisplayCurrency_e } from 'src/model/model';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import { RootState } from 'src/redux/modules';
import localSettingsSlice from 'src/redux/modules/localSettingsModule';
import { DBGMSG, DBGMSGW } from 'src/util/utils';

export function useLocalSettings() {
  const resourceState = useSelector((state: RootState) => state.resourceState);
  const dispatch = useDispatch();

  // const hRpc = useRpc();
  // const hAuth = useAuth();
  const hLocalSettingsStorage = useLocalSettingsStorage();

  const changeLang = (lang: CbtUserLanguage_e) => {
    dispatch(localSettingsSlice.actions.changeLang({ lang }));
    // 저장함
    hLocalSettingsStorage.setSettings({ lang });

    // 로그인 된상태로 변경시 서버에 요청
    // if (hAuth.isLogin) hRpc.cobaltRpc.rpcUserAccountLangSet({ lang });
  };

  const changeCurrency = useCallback(
    (currency: DisplayCurrency_e) => {
      dispatch(localSettingsSlice.actions.changeCurrency({ currency }));
      // 저장함
      hLocalSettingsStorage.setSettings({ currency });
    },
    [dispatch, hLocalSettingsStorage]
  );

  const loadFromLocalSettings = () => {
    DBGMSG('$$$$ loadFromLocalSettings');
    if (resourceState.isInit) {
      DBGMSGW('already resourceState');
      return;
    }

    // 저장해둔 정보를 불러와 초기값으로 사용함
    if (!hLocalSettingsStorage.isExistSettings()) {
      hLocalSettingsStorage.setSettings({});
    } else {
      const localSettings = hLocalSettingsStorage.getSettings();
      changeLang(localSettings.lang);
      changeCurrency(localSettings.currency);
      dispatch(localSettingsSlice.actions.setIsInit());
    }
  };

  return {
    ...resourceState,
    changeLang,
    changeCurrency,
    loadFromLocalSettings,
    isExistSettings: hLocalSettingsStorage.isExistSettings,
  };
}
