/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, HTMLProps, MouseEventHandler, useState } from 'react';
import { FlexColumnDiv, FlexDiv } from 'src/ui/base_component/etc';
import { DBGMSG } from 'src/util/utils';

type Props = {
  src: [string | null, number | string | undefined, number | string | undefined];
  hoverSrc?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
  onConMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  onConMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
  enable?: boolean;
};

export function ImgBtn({
  //
  src,
  hoverSrc,
  onClick,
  containerStyle,
  imgStyle,
  onConMouseEnter,
  onConMouseLeave,
  enable = true,
}: Props) {
  const [sIsMouseEnter, setIsMouseEnter] = useState(false);
  const getSrc = () => {
    if (hoverSrc) {
      return sIsMouseEnter ? hoverSrc : src[0];
    } else {
      return src[0];
    }
  };
  // console.debug('render');
  return (
    <FlexColumnDiv
      style={{
        justifyContent: 'center',
        // alignItems: imgStyle?.objectFit === 'contain' ? undefined : 'center',
        ...containerStyle,
        cursor: enable ? 'pointer' : 'unset',
        // ...devOutlineFF,
      }}
      onClick={(e) => {
        // DBGMSG(`${getSrc}`);
        if (onClick && enable) {
          onClick(e);
          // e.stopPropagation();
        }
      }}
    >
      <img
        src={getSrc() === null ? `https://placehold.it/${src[1]}x${src[2]}` : getSrc()!}
        alt=""
        style={{
          //
          width: src[1],
          height: src[2],
          boxSizing: 'border-box',
          ...imgStyle,
          userSelect: 'none',
          // objectFit: isContain ? 'contain' : 'fill',
          // borderRadius: 10,
          // borderWidth: 1,
          // borderStyle: 'solid',
          // overflow: 'hidden',
        }}
        onMouseEnter={(e) => {
          if(!enable) return;
          onConMouseEnter && onConMouseEnter(e);
          setIsMouseEnter(true);
        }}
        onMouseLeave={(e) => {
          if(!enable) return;
          onConMouseLeave && onConMouseLeave(e);
          setIsMouseEnter(false);
        }}
      />
    </FlexColumnDiv>
  );
}
