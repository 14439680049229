/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { reject } from 'lodash';
import { useHistory } from 'react-router-dom';
import { isAlpha, isProd, Phase } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikas } from 'src/hooks/useKaikas';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { useRpc } from 'src/hooks/useRpc';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGE, DBGMSGW, Utils } from 'src/util/utils';
import { KaikasPromiseReject_e } from 'src/res/constants';

export function useKaikasSvc() {
  const hKaikas = useKaikas();
  const hAuth = useAuth();
  const hHistory = useHistory();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hMyPageState = useUserDetailMineState();
  const hR = useLocalSettings();
  const hDlg = useDlg();

  // 카이카스 연결
  // 판매금액/리워드 주소 체크
  // 연결된 카이카스 주소를 판매금액/리워드으로 등록
  // 이미 등록되어 있을경우 연결된 카이카스와 비교
  // 다를경우 다이얼로그 팝업
  const kaikasConnectAndRewardAddrReg = async (showWalletDlg?: boolean) => {
    if (!checkAvailableKlatnNetwork()) {
      return;
    }

    return new Promise<boolean>((resolve, reject) => {
      if (showWalletDlg) {
        hDlgFactory.showWalletConnectDlgJsx({
          props: {
            onConnect: async () => {
              try {
                await connect();
                hDlgFactory.hideWalletConnectDlgJsx();
                resolve(true);
              } catch (err) {
                reject();
              }
            },
          },
          onBackdropClick: () => {
            DBGMSG('onBackdropClick');
            hDlgFactory.hideWalletConnectDlgJsx();
            reject();
          },
        });
      } else {
        connect()
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      }
    });
  };

  const getAccountInfo = () => {
    return hRpc.cobaltRpc.rpcUserAccountInfoMutable({});
  };

  const getRewardAddr = async () => {
    try {
      const res = await getAccountInfo();
      return res.reward_addr;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const hasRewardAddr = async () => {
    try {
      const reward_addr = await getRewardAddr();
      return reward_addr !== null && reward_addr.length > 0;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const regRewardAddr = async (addr: string) => {
    DBGMSG('regRewardAddr');
    try {
      const res = await hRpc.cobaltRpc.rpcUserAccountAccountRewardaddrSet({ reward_addr: addr });

      // if (res.reward_addr !== addr) {
      //   DBGMSGE(`error didnt chagne! org:${res.reward_addr} new:${addr}`);
      // }
      return true;
    } catch (error) {
      return false;
    }
  };

  const connect = async () => {
    try {
      // 카이카스 지갑 연동
      const selectedAddress = await hKaikas.connectToKaikas(undefined, undefined);
      DBGMSG(`$$$$KAIKAS connectToKaikas`);

      // 기존에 등록된 입금/리워드 주소 get
      const existingRewardAddr = await getRewardAddr();

      // 기존에 등록된 입금/리워드 주소 등록여부 확인
      if (existingRewardAddr !== null && existingRewardAddr.length > 0) {
        DBGMSG('$$$$KAIKAS org', existingRewardAddr);
        DBGMSG('$$$$KAIKAS new', selectedAddress);

        // 기존에 등록된 입금/리워드 주소 와 카이카스 지갑 주소 비교
        if (existingRewardAddr !== selectedAddress) {
          // 변경할지 물어보는 다이얼로그 show
          hDlgFactory.showRewardAddrChangeDlg({
            newAddr: selectedAddress,
            orgAddr: existingRewardAddr,
            onChange: async () => {
              await regRewardAddr(selectedAddress);
              hMyPageState.act.changeState({
                user: {
                  needUpdate: true,
                },
              });
              hDlgFactory.closeRewardAddrChangeDlg();
              hDlgFactory.showRewardAddrChangeSuccDlg({
                onClose: () => {
                  hDlgFactory.hideRewardAddrChangeSuccDlg();
                },
              });
            },
            onClose: () => {
              hDlgFactory.hideRewardAddrChangeDlg();
            },
          });
        } else {
          DBGMSG('$$$$KAIKAS OK');
        }
      } else {
        // 판매대금/리워드 주소 등록 안되있을 경우, 등록
        DBGMSG('$$$$KAIKAS REG');
        await regRewardAddr(selectedAddress);
      }
    } catch (err) {
      DBGMSGW(err);
      return Promise.reject(err);
    }
  };

  const handleBuyBtnOnClick = async ({ sale_id, onBuySuccess }: { sale_id: number; onBuySuccess: () => void }) => {
    DBGMSG(`handleBuyBtnOnClick()`);
    if (!hAuth.isLogin) {
      hHistory.push(Nav.UserLoginScreen);
      return;
    }

    // Chrome 데스크탑 에서만 가능
    if (!ScreenInfo.isDesktop()) {
      hDlgFactory.showSimpleOk({ text: hR.strings.DLG_KAIKAS_ONLY_DESKTOP_BUY });
      return;
    }

    if (!hKaikas.isAvailable()) {
      hKaikas.openInstallSite();
      return;
    }

    // 카이카스 연결 확인
    if (!hKaikas.isConnectedKaikas()) {
      kaikasConnectAndRewardAddrReg(true);
      return;
    }

    hDlgFactory.showBuyJsx({
      sale_id,
      onClose: () => {
        hDlgFactory.hideBuyJsx();
      },
      onSuccess: () => {
        hDlgFactory.hideBuyJsx();
        onBuySuccess && onBuySuccess();
      },
    });
    // hDlg.act.showDlgBuy({
    //   saleId: sale_id,
    //   onSuccess: () => {
    //     onBuySuccess && onBuySuccess();
    //   },
    // });
  };

  const checkAvailableKlatnNetwork = () => {
    if (hKaikas.isAvailable()) {
      if (isAlpha()) {
        if (hKaikas.getNetworkVersion() === 'BAOBAB') {
          return true;
        } else {
          // hDlgFactory.showSimpleOk({ text: `${Phase.ALPHA}\n${hKaikas.getNetworkVersion()} 에 연결할수 없습니다.` });
          hDlgFactory.showSimpleOk({ text: `${hR.strings.WALLET_CYPRESS_NOT_AVAILABLE_IN_ALPHA}` });
          return false;
        }
      } else if (isProd()) {
        if (hKaikas.getNetworkVersion() === 'MAINNET') {
          return true;
        } else {
          // hDlgFactory.showSimpleOk({ text: `${Phase.PRODUCTION}\n${hKaikas.getNetworkVersion()} 에 연결할수 없습니다.` });
          hDlgFactory.showSimpleOk({ text: `${hR.strings.WALLET_BAOBAB_NOT_AVAILABLE_IN_LIVE}` });
          return false;
        }
      }
      return false;
    }
    return false;
  };

  return {
    checkAvailableKlatnNetwork,
    kaikasConnectAndRewardAddrReg,
    getAccountInfo,
    getRewardAddr,
    hasRewardAddr,
    regRewardAddr,
    handleBuyBtnOnClick,
  };
}
