/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useState } from 'react';
import { devOutlineF } from 'src/ui/base_component/etc';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { useHistory } from 'react-router';
import { Nav } from 'src/ui/screen/Nav';
import { LOGINOUT_BTN_LAYOUT } from 'src/ui/layout_constant';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  label?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  fontColor?: string;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function RoundTxtBtnAdvWithAnim({
  label = 'OK',
  width = 139,
  height = 40,
  fontSize = 14,
  onClick,
  fontColor = R.colors.purpleishBlue,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseOver, setMouseOver] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hHistory = useHistory();

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <RoundTxtBtnAdv
      label={label}
      width={width}
      height={height}
      fontSize={fontSize}
      bgColor={'white'}
      fontColor={fontColor}
      borderColor={sMouseOver ? R.colors.purpleishBlue : R.colors.line}
      // containerStyle={{ marginLeft: 'auto', alignSelf: 'center' }}
      onClick={(e) => {
        hHistory.push(Nav.UserLoginScreen);
        onClick && onClick(e);
      }}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
    />
  );
}
