/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { DisplaySrc_t } from 'src/model/model';
import { CbtAttributeType_e, CbtColCertifyType_e } from 'src/model/rpcModel';
import { RPC_ASSET_CREATOR_VIEW, RPC_MP_SALE_HISTORY } from 'src/model/rpcType';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { AssetDisplay } from 'src/ui/common_component/AssetDisplay/AssetDisplay';
import { AssetDisplayThumb, WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { LabelValue } from 'src/ui/common_component/LabelValue';
import { LabelValueMint } from 'src/ui/common_component/LabelValueMint';
import { PriceAdv } from 'src/ui/common_component/PriceAdv';
import { Title } from 'src/ui/common_component/Title';
import { UserThumbName } from 'src/ui/common_component/UserThumbName';
import { CLAY, CommonColumnBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';
import { UserDetailMenuType_e } from 'src/redux/modules/userDetailModule';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! Constatn
 **************************************/
// DESKTOP
export const DT_BACKBTN_MARGIN_BOTTOM = 30;
export const DT_LEFT_CONTAINER_WIDTH = 582;
export const DT_LEFT_CONTAINER_HEIGHT = 722;
export const DT_ASSETDISPLAY_MARGIN = 40;
export const DT_ASSETDISPLAY_SIZE = 502;
export const DT_ASSETDISPLAY_THUMB_SIZE = 100;
export const DT_ASSETDISPLAY_THUMB_LIST_SIZE = 440;
export const DT_ASSETDISPLAY_THUMB_CNT = 4;
export const DT_ARROW_BTN_SIZE = 34;
export const DT_ARROW_BTN_PADDING = 13;
export const DT_RIGHT_CONTAINER_WIDHT = 582;
// 박스 상단부분
export const DT_OVERBOX_INFO_PADDING_LEFT = 10;
export const DT_COL_NAME_HEIGHT = 24;
export const DT_COL_IMG_SIZE = 24;
export const DT_COL_FONT_SIZE = 14;
export const DT_COL_NAME_MARGIN_BOTTOM = 10;
export const DT_ASSET_NAME_FONT_SIZE = 24;
export const DT_ASSET_NAME_MARGIN_BOTTOM = 14;
export const DT_BOX_MARGIN_TOP = 30;
// 박스
export const DT_SCHM_NAME_HEIGHT = 20;
export const DT_SCHM_NAME_FONTSIZE = 14;
export const DT_SCHM_NAME_VMARGIN = 24;
export const DT_BOX_PADDING_H = 24;
export const DT_LV_MARGIN_V = 24; // LV => Label Value
export const DT_LV_FLEX_DIR = 'row';
export const DT_LV_TITLE_FONTSIZE = 14;
export const DT_LV_VALUE_FONTSIZE = 14;
export const DT_LV_TITLE_WIDTH = 183;
export const DT_LV_VALUE_WIDTH = 351;
export const DT_LV_TITLE_HEIGHT = 24;
export const DT_LV_VALUE_HEIGHT = 24;
export const DT_BOX_MARGIN_BOTTOM = 30;
export const DT_SALE_BOX_HEIGHT = 96;
export const DT_SALE_FONT_SIZE = 14;
export const DT_BTN_WIDTH = 152;
export const DT_BTN_HEIGHT = 48;
export const DT_BTN_FONT_SIZE = 14;
export const DT_TH_TITLE_MARIGN_TOP = 80; // TH => TRADING HISTORY
export const DT_TH_TITLE_MARIGN_BOTTOM = 30;
export const DT_TH_ITEM_HEIGHT = 69;
export const DT_TH_MARGIN_BOTTOM = 30;
export const DT_TH_PAGING_HEIGHT = 34;
export const DT_TH_PAGING_MARIGN_V = 6;
export const DT_TH_EVETN_WIDHT = 190;
export const DT_TH_PRICE_WIDHT = 255;
export const DT_TH_FROM_WIDHT = 275;
export const DT_TH_TO_WIDHT = 275;
export const DT_TH_DATE_WIDHT = 175;
export const DT_BOTTOM_SPACE = 120;

// MOBILE
export const MB_BACTBTN_MARGIN_BOTTOM = 20;
export const MB_LEFT_CONTAINER_WIDTH = 280;
export const MB_LEFT_CONTAINER_HEIGHT = 376;
export const MB_ASSETDISPLAY_MARGIN = 16;
export const MB_ASSETDISPLAY_SIZE = 248;
export const MB_ASSETDISPLAY_THUMB_SIZE = 80;
export const MB_ASSETDISPLAY_THUMB_LIST_SIZE = 180;
export const MB_ASSETDISPLAY_THUMB_CNT = 2;
export const MB_ARROW_BTN_SIZE = 30;
export const MB_ARROW_BTN_PADDING = 0;
export const MB_RIGHT_CONTAINER_WIDHT = 280;
// 박스 상단부분
export const MB_OVERBOX_INFO_PADDING_LEFT = 0;
export const MB_COL_NAME_HEIGHT = 20;
export const MB_COL_IMG_SIZE = 20;
export const MB_COL_FONT_SIZE = 12;
export const MB_COL_NAME_MARGIN_BOTTOM = 8;
export const MB_ASSET_NAME_FONT_SIZE = 16;
export const MB_ASSET_NAME_MARGIN_BOTTOM = 8;
export const MB_BOX_MARGIN_TOP = 20;
// 박스
export const MB_SCHM_NAME_HEIGHT = 20;
export const MB_SCHM_NAME_FONTSIZE = 12;
export const MB_SCHM_NAME_VMARGIN = 15;
export const MB_BOX_PADDING_H = 16;
export const MB_LV_MARGIN_V = 14; // LV => Label Value
export const MB_LV_FLEX_DIR = 'column';
export const MB_LV_TITLE_FONTSIZE = 12;
export const MB_LV_VALUE_FONTSIZE = 12;
export const MB_LV_TITLE_WIDTH = 280 - 32;
export const MB_LV_VALUE_WIDTH = MB_LV_TITLE_WIDTH;
export const MB_LV_TITLE_HEIGHT = 24;
export const MB_LV_VALUE_HEIGHT = 24;
export const MB_BOX_MARGIN_BOTTOM = 20;
export const MB_SALE_BOX_HEIGHT = 118;
export const MB_SALE_FONT_SIZE = 12;
export const MB_BTN_WIDTH = 248;
export const MB_BTN_HEIGHT = 44;
export const MB_BTN_FONT_SIZE = 12;
export const MB_TH_TITLE_MARIGN_TOP = 30; // TH => TRADING HISTORY
export const MB_TH_TITLE_MARIGN_BOTTOM = 20;
export const MB_TH_ITEM_HEIGHT = 69;
export const MB_TH_MARGIN_BOTTOM = 20;
export const MB_TH_PAGING_HEIGHT = 30;
export const MB_TH_PAGING_MARIGN_V = 4;
export const MB_TH_EVETN_WIDHT = 190;
export const MB_TH_PRICE_WIDHT = 255;
export const MB_TH_FROM_WIDHT = 275;
export const MB_TH_TO_WIDHT = 275;
export const MB_TH_DATE_WIDHT = 175;
export const MB_BOTTOM_SPACE = 60;

export const BACTBTN_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_BACTBTN_MARGIN_BOTTOM : DT_BACKBTN_MARGIN_BOTTOM;
export const LEFT_CONTAINER_WIDTH = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_WIDTH : DT_LEFT_CONTAINER_WIDTH;
export const LEFT_CONTAINER_HEIGHT = ScreenInfo.isMobile() ? MB_LEFT_CONTAINER_HEIGHT : DT_LEFT_CONTAINER_HEIGHT;
export const ASSETDISPLAY_MARGIN = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_MARGIN : DT_ASSETDISPLAY_MARGIN;
export const ASSETDISPLAY_SIZE = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_SIZE : DT_ASSETDISPLAY_SIZE;
export const ASSETDISPLAY_THUMB_SIZE = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_THUMB_SIZE : DT_ASSETDISPLAY_THUMB_SIZE;
export const ASSETDISPLAY_THUMB_LIST_SIZE = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_THUMB_LIST_SIZE : DT_ASSETDISPLAY_THUMB_LIST_SIZE;
export const ASSETDISPLAY_THUMB_CNT = ScreenInfo.isMobile() ? MB_ASSETDISPLAY_THUMB_CNT : DT_ASSETDISPLAY_THUMB_CNT;
export const ARROW_BTN_SIZE = ScreenInfo.isMobile() ? MB_ARROW_BTN_SIZE : DT_ARROW_BTN_SIZE;
export const ARROW_BTN_PADDING = ScreenInfo.isMobile() ? MB_ARROW_BTN_PADDING : DT_ARROW_BTN_PADDING;
export const RIGHT_CONTAINER_WIDHT = ScreenInfo.isMobile() ? MB_RIGHT_CONTAINER_WIDHT : DT_RIGHT_CONTAINER_WIDHT;
export const OVERBOX_INFO_PADDING_LEFT = ScreenInfo.isMobile() ? MB_OVERBOX_INFO_PADDING_LEFT : DT_OVERBOX_INFO_PADDING_LEFT;
export const COL_NAME_HEIGHT = ScreenInfo.isMobile() ? MB_COL_NAME_HEIGHT : DT_COL_NAME_HEIGHT;
export const COL_IMG_SIZE = ScreenInfo.isMobile() ? MB_COL_IMG_SIZE : DT_COL_IMG_SIZE;
export const COL_FONT_SIZE = ScreenInfo.isMobile() ? MB_COL_FONT_SIZE : DT_COL_FONT_SIZE;
export const COL_NAME_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_COL_NAME_MARGIN_BOTTOM : DT_COL_NAME_MARGIN_BOTTOM;
export const ASSET_NAME_FONT_SIZE = ScreenInfo.isMobile() ? MB_ASSET_NAME_FONT_SIZE : DT_ASSET_NAME_FONT_SIZE;
export const ASSET_NAME_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_ASSET_NAME_MARGIN_BOTTOM : DT_ASSET_NAME_MARGIN_BOTTOM;
export const BOX_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BOX_MARGIN_TOP : DT_BOX_MARGIN_TOP;
export const SCHM_NAME_HEIGHT = ScreenInfo.isMobile() ? MB_SCHM_NAME_HEIGHT : DT_SCHM_NAME_HEIGHT;
export const SCHM_NAME_FONTSIZE = ScreenInfo.isMobile() ? MB_SCHM_NAME_FONTSIZE : DT_SCHM_NAME_FONTSIZE;
export const SCHM_NAME_VMARGIN = ScreenInfo.isMobile() ? MB_SCHM_NAME_VMARGIN : DT_SCHM_NAME_VMARGIN;
export const BOX_PADDING_H = ScreenInfo.isMobile() ? MB_BOX_PADDING_H : DT_BOX_PADDING_H;
export const LV_MARGIN_V = ScreenInfo.isMobile() ? MB_LV_MARGIN_V : DT_LV_MARGIN_V;
export const LV_FLEX_DIR = ScreenInfo.isMobile() ? MB_LV_FLEX_DIR : DT_LV_FLEX_DIR;
export const LV_TITLE_FONTSIZE = ScreenInfo.isMobile() ? MB_LV_TITLE_FONTSIZE : DT_LV_TITLE_FONTSIZE;
export const LV_VALUE_FONTSIZE = ScreenInfo.isMobile() ? MB_LV_VALUE_FONTSIZE : DT_LV_VALUE_FONTSIZE;
export const LV_TITLE_WIDTH = ScreenInfo.isMobile() ? MB_LV_TITLE_WIDTH : DT_LV_TITLE_WIDTH;
export const LV_VALUE_WIDTH = ScreenInfo.isMobile() ? MB_LV_VALUE_WIDTH : DT_LV_VALUE_WIDTH;
export const LV_TITLE_HEIGHT = ScreenInfo.isMobile() ? MB_LV_TITLE_HEIGHT : DT_LV_TITLE_HEIGHT;
export const LV_VALUE_HEIGHT = ScreenInfo.isMobile() ? MB_LV_VALUE_HEIGHT : DT_LV_VALUE_HEIGHT;
export const BOX_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_BOX_MARGIN_BOTTOM : DT_BOX_MARGIN_BOTTOM;
export const SALE_BOX_HEIGHT = ScreenInfo.isMobile() ? MB_SALE_BOX_HEIGHT : DT_SALE_BOX_HEIGHT;
export const SALE_FONT_SIZE = ScreenInfo.isMobile() ? MB_SALE_FONT_SIZE : DT_SALE_FONT_SIZE;
export const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
export const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
export const BTN_FONT_SIZE = ScreenInfo.isMobile() ? MB_BTN_FONT_SIZE : DT_BTN_FONT_SIZE;
export const TH_TITLE_MARIGN_TOP = ScreenInfo.isMobile() ? MB_TH_TITLE_MARIGN_TOP : DT_TH_TITLE_MARIGN_TOP;
export const TH_TITLE_MARIGN_BOTTOM = ScreenInfo.isMobile() ? MB_TH_TITLE_MARIGN_BOTTOM : DT_TH_TITLE_MARIGN_BOTTOM;
export const TH_ITEM_HEIGHT = ScreenInfo.isMobile() ? MB_TH_ITEM_HEIGHT : DT_TH_ITEM_HEIGHT;
export const TH_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TH_MARGIN_BOTTOM : DT_TH_MARGIN_BOTTOM;
export const TH_PAGING_HEIGHT = ScreenInfo.isMobile() ? MB_TH_PAGING_HEIGHT : DT_TH_PAGING_HEIGHT;
export const TH_PAGING_MARIGN_V = ScreenInfo.isMobile() ? MB_TH_PAGING_MARIGN_V : DT_TH_PAGING_MARIGN_V;
export const TH_EVETN_WIDHT = ScreenInfo.isMobile() ? MB_TH_EVETN_WIDHT : DT_TH_EVETN_WIDHT;
export const TH_PRICE_WIDHT = ScreenInfo.isMobile() ? MB_TH_PRICE_WIDHT : DT_TH_PRICE_WIDHT;
export const TH_FROM_WIDHT = ScreenInfo.isMobile() ? MB_TH_FROM_WIDHT : DT_TH_FROM_WIDHT;
export const TH_TO_WIDHT = ScreenInfo.isMobile() ? MB_TH_TO_WIDHT : DT_TH_TO_WIDHT;
export const TH_DATE_WIDHT = ScreenInfo.isMobile() ? MB_TH_DATE_WIDHT : DT_TH_DATE_WIDHT;
export const BOTTOM_SPACE = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE : DT_BOTTOM_SPACE;

export const TH_WIDTH = TH_EVETN_WIDHT + TH_PRICE_WIDHT + TH_FROM_WIDHT + TH_TO_WIDHT + TH_DATE_WIDHT;

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MyIssueAssetRScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sQS, setQS] = useState<{ sid: number; entity_id: number; asset_id: number }>();
  const [sFetchedDetailData, setFetchedDetailData] = useState<RPC_ASSET_CREATOR_VIEW.Rx>();
  const [sFetchedHistoryData, setFetchedHistoryData] = useState<RPC_MP_SALE_HISTORY.Rx>();
  const [sTradingPageno, setTradingPageno] = useState(0);

  const [sSelectedDisplayThumbIdx, setSelectedDisplayThumbIdx] = useState(0); // 선택되어 있는 thumb idx
  const [sSliderCurIdx, setSliderCurIdx] = useState(0); // Slider의 idx
  const [sDisplaySrcList, setDisplaySrcList] = useState<DisplaySrc_t[]>();

  /**************************************
   * !! ref
   **************************************/
  const assetDisplayThumbListSliderRef = useRef<Slider>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();

  const hDlgFactory = useDlgFactory();
  const hAuth = useAuth();
  // useCheckUserCertTpAndBlock();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string; entity_id?: string; asset_id?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid && query.entity_id && query.asset_id) {
      DBGMSG(`$$$$QS sid: ${query.sid}`);
      DBGMSG(`$$$$QS entity_id: ${query.entity_id}`);
      DBGMSG(`$$$$QS asset_id: ${query.asset_id}`);
      const sid = parseInt(query.sid);
      const entity_id = parseInt(query.entity_id);
      const asset_id = parseInt(query.asset_id);
      setQS({ sid, entity_id, asset_id });
      fetchDetailData({ sid, entity_id, asset_id });
      fetchHistoryData({ sid, entity_id, asset_id });
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const fetchDetailData = async ({ sid, entity_id, asset_id }: { sid: number; entity_id: number; asset_id: number }) => {
    try {
      const res = await hRpc.cobaltRpc.rpcAssetCreatorView({ sid, entity_id, asset_id });
      setFetchedDetailData(res);

      const playerSrcList = Utils.svc.brewDisplaySrcList({ assetType: res.ast_info.schm_info.asset_type, attrList: res.ast_info.attr_list });
      setDisplaySrcList(playerSrcList);
    } catch (err) {
      DBGMSG('$$$$ 1');
      DBGMSG(err);
      hRpc.hanleCommonError({
        err,
        onOk: () => {
          hHistory.goBack();
        },
      });
    }
  };

  const fetchHistoryData = async ({ sid, entity_id, asset_id }: { sid: number; entity_id: number; asset_id: number }) => {
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleHistory({ sid, entity_id, asset_id });
      setFetchedHistoryData(res);
    } catch (e) {
      DBGMSG('$$$$ 2');
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    }
  };

  const reload = () => {
    if (!sQS) return;
    fetchDetailData(sQS);
    fetchHistoryData(sQS);
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  if (!sFetchedDetailData) return <DataFetching />;

  if (!sDisplaySrcList) {
    DBGMSGW(sDisplaySrcList);
    return null;
  }

  // 소유자 여부
  // 내가 발행한 에셋이여도 소유자가 다를수 있음
  const isOwner = hAuth.authCommonRes?.ucode === sFetchedDetailData.ast_info.owner.ucode;

  DBGMSG('render');
  return (
    <>
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          <BackBtn />
          <HEIGHT size={BACTBTN_MARGIN_BOTTOM} />

          <FlexRowDiv style={{ width: CLAY.PAGE_WIDTH, flexWrap: 'wrap' }}>
            {/* left container */}
            <FlexDiv
              style={{
                width: LEFT_CONTAINER_WIDTH,
                // ...devOutlineF
              }}
            >
              <CommonColumnBox style={{ width: LEFT_CONTAINER_WIDTH, height: LEFT_CONTAINER_HEIGHT }}>
                {/******************************************
                 * AssetDisplay
                 *******************************************/}
                <FlexDiv
                  style={{
                    margin: ASSETDISPLAY_MARGIN,
                  }}
                >
                  <AssetDisplay
                    key={`${sSelectedDisplayThumbIdx}`}
                    playerSrc={sDisplaySrcList[sSelectedDisplayThumbIdx]}
                    width={ASSETDISPLAY_SIZE}
                    height={ASSETDISPLAY_SIZE}
                    onImgFullscreen={(src) => {
                      // setFullImgViewer({ isOpen: true, src });
                      // setFullScreenImgSrc(src);
                      // if (!ScreenInfo.isMobile()) {
                      //   setFullScreenViewerOnOff(true);
                      // } else {
                      //   // setMobileFullScreenViewerOnOff(true);
                      //   window.open(src);
                      // }
                    }}
                    isBlock={sFetchedDetailData.ast_info.blocked}
                  />
                </FlexDiv>
                {/* AssetDisplay end */}

                <FlexRowDiv
                  style={{
                    width: LEFT_CONTAINER_WIDTH,
                    alignItems: 'center',
                    justifyContent: 'center',
                    // ...devOutlineF,
                  }}
                >
                  {/* < left 버튼 */}
                  <ImgBtn
                    src={[
                      sSliderCurIdx === 0 ? R.images.common_btn_back_disabled3x : R.images.common_btn_back_abled3x,
                      ARROW_BTN_SIZE,
                      ARROW_BTN_SIZE,
                    ]}
                    containerStyle={{
                      //
                      padding: ARROW_BTN_PADDING,
                      flex: '0 0 auto',
                      // opacity: 1 + sOptMediaOnlyAttrList.length > 4 ? 1 : 0,
                      // ...devOutlineF,
                    }}
                    onClick={() => {
                      if (sSliderCurIdx === 0) return;
                      assetDisplayThumbListSliderRef.current?.slickPrev();
                    }}
                  />

                  {/*******************************************
                   * AssetDisplay Thumb 리스트
                   *******************************************/}
                  <FlexColumnDiv
                    // {...hDrag()}
                    style={{
                      width: ASSETDISPLAY_THUMB_LIST_SIZE,
                      overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤?이 생김
                      justifyContent: 'center',
                      // ...devOutlineF,
                    }}
                  >
                    <Slider
                      ref={assetDisplayThumbListSliderRef}
                      infinite={false}
                      speed={400}
                      slidesToShow={ASSETDISPLAY_THUMB_CNT}
                      swipeToSlide={true}
                      slidesToScroll={1}
                      beforeChange={(cur, next) => {
                        DBGMSG(`cur:${cur} next:${next}`);
                        setSliderCurIdx(next);
                      }}
                      afterChange={(cur) => {
                        DBGMSG(`cur:${cur}`);
                        setSliderCurIdx(cur);
                      }}
                    >
                      {sDisplaySrcList.map((displaySrc, idx) => {
                        return (
                          <AssetDisplayThumb
                            whereToUse={WhereToUse_e.ForSlider}
                            key={`${idx}`}
                            width={ASSETDISPLAY_THUMB_SIZE}
                            height={ASSETDISPLAY_THUMB_SIZE}
                            playerSrc={displaySrc}
                            onClick={() => {
                              setSelectedDisplayThumbIdx(idx);
                            }}
                            selected={sSelectedDisplayThumbIdx === idx}
                          />
                        );
                      })}
                    </Slider>
                  </FlexColumnDiv>
                  {/* AssetDisplay 리스트 end */}

                  {/* > right 버튼 */}
                  <ImgBtn
                    src={[
                      sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length
                        ? R.images.common_btn_next_disabled3x
                        : R.images.common_btn_next_abled3x,
                      ARROW_BTN_SIZE,
                      ARROW_BTN_SIZE,
                    ]}
                    containerStyle={{
                      padding: ARROW_BTN_PADDING,
                      flex: '0 0 auto',
                      // ...devOutlineF,
                    }}
                    onClick={() => {
                      if (sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length) return;
                      assetDisplayThumbListSliderRef.current?.slickNext();
                    }}
                  />
                </FlexRowDiv>
              </CommonColumnBox>
            </FlexDiv>

            {/* right container */}
            <FlexColumnDiv
              style={{
                width: RIGHT_CONTAINER_WIDHT,
                marginTop: ScreenInfo.isMobile() ? 20 : undefined,
                marginLeft: ScreenInfo.isDesktop() ? 30 : undefined,
                // ...devOutlineF
              }}
            >
              {/* 박스위 정보 */}
              <FlexColumnDiv style={{ paddingLeft: OVERBOX_INFO_PADDING_LEFT }}>
                {/* 컬렉션 정보 */}
                <FlexRowDiv style={{ height: COL_NAME_HEIGHT }}>
                  {sFetchedDetailData.ast_info.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
                    <>
                      <Img src={[R.images.common_ic_verify3x, COL_IMG_SIZE, COL_IMG_SIZE]} />
                      <WIDTH size={6} />
                    </>
                  )}
                  <TextCon text={sFetchedDetailData.ast_info.col_info.dp_name} isBold size={COL_FONT_SIZE} color={R.colors.purpleishBlue} />
                </FlexRowDiv>
                <HEIGHT size={COL_NAME_MARGIN_BOTTOM} />
                {/* 어셋 이름 */}
                <TextCon
                  text={Utils.svc.getAssetNameFromAttrList(sFetchedDetailData.ast_info.attr_list)}
                  isBold
                  size={ASSET_NAME_FONT_SIZE}
                  color={R.colors.black}
                />
                <HEIGHT size={ASSET_NAME_MARGIN_BOTTOM} />
                <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row' }}>
                  {/* 소유자 */}
                  <FlexDiv style={{ flex: 1 }}>
                    <UserThumbName
                      thumb={null}
                      kind={'OWNER'}
                      userId={sFetchedDetailData.ast_info.owner.ucode}
                      onClick={() => {
                        hHistory.push(Nav.brewUserDetailScreenPath({ ucode: sFetchedDetailData.ast_info.owner.ucode, menu: UserDetailMenuType_e.INVEN }));
                      }}
                    />
                  </FlexDiv>
                  {ScreenInfo.isMobile() && <HEIGHT size={8} />}
                  {/* 원작자 */}
                  <FlexDiv style={{ flex: 1 }}>
                    <UserThumbName
                      thumb={null}
                      kind={'CREATOR'}
                      userId={sFetchedDetailData.ast_info.creator.ucode}
                      onClick={() => {
                        hHistory.push(Nav.brewUserDetailScreenPath({ ucode: sFetchedDetailData.ast_info.creator.ucode, menu: UserDetailMenuType_e.INVEN }));
                      }}
                    />
                  </FlexDiv>
                </FlexDiv>
              </FlexColumnDiv>
              <HEIGHT size={BOX_MARGIN_TOP} />

              {/* 박스 */}
              <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDHT }}>
                {/* 스키마 디스플레이 이름 */}
                <TextCon
                  size={SCHM_NAME_FONTSIZE}
                  color={R.colors.purpleishBlue}
                  isBold
                  text={sFetchedDetailData.ast_info.schm_info.dp_name}
                  textStyle={{ height: SCHM_NAME_HEIGHT }}
                  containerStyle={{
                    paddingLeft: BOX_PADDING_H,
                    paddingBottom: SCHM_NAME_VMARGIN,
                    paddingTop: SCHM_NAME_VMARGIN,
                  }}
                />
                {/* 라인 ----------------------------------------------------  */}
                <HLINE size={'100%'} color={R.colors.line} />
                <FlexColumnDiv style={{ paddingLeft: BOX_PADDING_H, paddingRight: BOX_PADDING_H }}>
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 에셋 아이디 */}
                  <LabelValue
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_ASSET_ID}
                    value={`${sFetchedDetailData.ast_info.asset_key}`}
                    titleSize={LV_TITLE_FONTSIZE}
                    valueSize={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 에셋타입 */}
                  <LabelValue
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_ASSET_TYPE}
                    value={hR.strings.getAssetType(sFetchedDetailData.ast_info.schm_info.asset_type)}
                    titleSize={LV_TITLE_FONTSIZE}
                    valueSize={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  <HEIGHT size={LV_MARGIN_V} />
                  {/* 발행수 */}
                  <LabelValueMint
                    flexDir={LV_FLEX_DIR}
                    title={hR.strings.AC_ASSET_READ_MINT_CNT}
                    value={{
                      nOf: sFetchedDetailData.ast_info.mint_no,
                      total: sFetchedDetailData.ast_info.issue_tcnt,
                      can_addition: sFetchedDetailData.ast_info.can_addition,
                      mintMore: `${sFetchedDetailData.ast_info.can_addition ? `(${hR.strings.AC_ASSET_READ_MINT_MORE})` : ''}`,
                    }}
                    titleSize={LV_TITLE_FONTSIZE}
                    value1Size={LV_VALUE_FONTSIZE}
                    value2Size={LV_VALUE_FONTSIZE}
                    titleWidth={LV_TITLE_WIDTH}
                    valueWidth={LV_VALUE_WIDTH}
                    titleHeight={LV_TITLE_HEIGHT}
                    valueHeight={LV_VALUE_HEIGHT}
                  />
                  {/* attr */}
                  {sFetchedDetailData.ast_info.attr_list.map((att, idx) => {
                    switch (att.type) {
                      case CbtAttributeType_e.TEXT:
                      case CbtAttributeType_e.NUMBER:
                        return (
                          <>
                            <HEIGHT size={LV_MARGIN_V} />
                            <LabelValue
                              flexDir={LV_FLEX_DIR}
                              title={Utils.svc.convertIfReqAttrName(att.name)}
                              value={`${att.value}`}
                              titleSize={LV_TITLE_FONTSIZE}
                              valueSize={LV_VALUE_FONTSIZE}
                              titleWidth={LV_TITLE_WIDTH}
                              valueWidth={LV_VALUE_WIDTH}
                              titleHeight={LV_TITLE_HEIGHT}
                              valueHeight={LV_VALUE_HEIGHT}
                            />
                          </>
                        );

                      case CbtAttributeType_e.IMAGE:
                      case CbtAttributeType_e.AUDIO:
                      case CbtAttributeType_e.VIDEO:
                        return (
                          <>
                            <HEIGHT size={LV_MARGIN_V} />
                            <LabelValue
                              flexDir={LV_FLEX_DIR}
                              title={Utils.svc.convertIfReqAttrName(att.name)}
                              value={`${Utils.string.shortString(Utils.ipfs.getIpfsHash(att.value), 10, 10)}`}
                              titleSize={LV_TITLE_FONTSIZE}
                              valueSize={LV_VALUE_FONTSIZE}
                              titleWidth={LV_TITLE_WIDTH}
                              valueWidth={LV_VALUE_WIDTH}
                              titleHeight={LV_TITLE_HEIGHT}
                              valueHeight={LV_VALUE_HEIGHT}
                            />
                          </>
                        );
                    }
                  })}
                  <HEIGHT size={LV_MARGIN_V} />
                </FlexColumnDiv>
              </CommonColumnBox>

              <HEIGHT size={BOX_MARGIN_BOTTOM} />

              {/* 판매중 */}
              {isOwner && sFetchedDetailData.ast_info.sale_id > 0 && (
                <>
                  <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDHT, height: SALE_BOX_HEIGHT, justifyContent: 'center' }}>
                    <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row', marginLeft: BOX_PADDING_H }}>
                      <FlexRowDiv>
                        <TextCon
                          isBold
                          size={SALE_FONT_SIZE}
                          color={R.colors.black}
                          text={Utils.string.parsePlaceholder(hR.strings.AC_ASSET_READ_ON_SALE, R.strings.PLACEHOLDER_SALEID).begin}
                        />
                        <TextCon isBold size={SALE_FONT_SIZE} color={R.colors.purpleishBlue} text={`#${sFetchedDetailData.ast_info.sale_id}`} />
                        <TextCon
                          isBold
                          size={SALE_FONT_SIZE}
                          color={R.colors.black}
                          text={Utils.string.parsePlaceholder(hR.strings.AC_ASSET_READ_ON_SALE, R.strings.PLACEHOLDER_SALEID).end}
                        />
                      </FlexRowDiv>
                      {ScreenInfo.isMobile() && <HEIGHT size={16} />}
                      <RoundTxtBtn
                        enable={!sFetchedDetailData.ast_info.blocked}
                        text={hR.strings.AC_ASSET_READ_SALE_EDIT_BTN}
                        width={BTN_WIDTH}
                        height={BTN_HEIGHT}
                        fontSize={BTN_FONT_SIZE}
                        containerStyle={{ marginLeft: 'auto', marginRight: BOX_PADDING_H }}
                        onClick={async () => {
                          if (!sQS) return;

                          try {
                            // const res = await hRpc.cobaltRpc.rpcMpSaleEditView({
                            //   sale_id: sFetchedData.ast_info.sale_id,
                            // });

                            hDlgFactory.showSellJsx({
                              // fetched: res,
                              req: {
                                mode: 'EDIT',
                                tx: { sale_id: sFetchedDetailData.ast_info.sale_id },
                              },
                              onSuccess: ({ prc_info: priceInfo }) => {
                                DBGMSG('onSale');
                                DBGMSG(priceInfo);
                                reload();
                                hDlgFactory.hideSellJsx();
                              },
                              onSaleStop: () => {
                                DBGMSG('onStop');
                                hDlgFactory.hideSellJsx();
                                reload();
                              },
                              onClose: () => {
                                DBGMSG('onClose');
                                hDlgFactory.hideSellJsx();
                              },
                            });
                          } catch (e) {}
                        }}
                      />
                    </FlexDiv>
                  </CommonColumnBox>
                  <HEIGHT size={BOX_MARGIN_BOTTOM} />
                </>
              )}

              {/* 판매전 */}
              {isOwner && sFetchedDetailData.ast_info.sale_id === 0 && (
                <>
                  <CommonColumnBox style={{ width: RIGHT_CONTAINER_WIDHT, height: SALE_BOX_HEIGHT, justifyContent: 'center' }}>
                    <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row', marginLeft: BOX_PADDING_H }}>
                      <TextCon isBold size={SALE_FONT_SIZE} color={R.colors.black} text={hR.strings.AC_ASSET_READ_BEFORE_SALE} />
                      {ScreenInfo.isMobile() && <HEIGHT size={16} />}
                      <RoundTxtBtn
                        enable={!sFetchedDetailData.ast_info.blocked}
                        text={hR.strings.AC_ASSET_READ_SALE_BTN}
                        width={BTN_WIDTH}
                        height={BTN_HEIGHT}
                        fontSize={BTN_FONT_SIZE}
                        containerStyle={{ marginLeft: 'auto', marginRight: BOX_PADDING_H }}
                        onClick={async () => {
                          if (!sQS) return;

                          try {
                            // const res = await hRpc.cobaltRpc.rpcMpSaleAddView({
                            //   sid: sQS.sid,
                            //   entity_id: sQS.entity_id,
                            //   asset_id: sQS.asset_id,
                            // });

                            hDlgFactory.showSellJsx({
                              // fetched: res,
                              req: {
                                mode: 'NEW',
                                tx: {
                                  sid: sQS.sid,
                                  entity_id: sQS.entity_id,
                                  asset_id: sQS.asset_id,
                                },
                              },
                              onSuccess: ({ prc_info: priceInfo }) => {
                                DBGMSG('onSale');
                                DBGMSG(priceInfo);
                                reload();
                                hDlgFactory.hideSellJsx();
                              },
                              onSaleStop: () => {
                                DBGMSG('onStop');
                                hDlgFactory.hideSellJsx();
                              },
                              onClose: () => {
                                DBGMSG('onClose');
                                hDlgFactory.hideSellJsx();
                              },
                            });
                          } catch (e) {}
                        }}
                      />
                    </FlexDiv>
                  </CommonColumnBox>
                  <HEIGHT size={BOX_MARGIN_BOTTOM} />
                </>
              )}
            </FlexColumnDiv>
          </FlexRowDiv>

          {/* 거래내역 */}
          <HEIGHT size={TH_TITLE_MARIGN_TOP} />
          <Title title={hR.strings.AC_ASSET_READ_TRADING_HISTORY} />
          <HEIGHT size={TH_TITLE_MARIGN_BOTTOM} />

          <CommonColumnBox
            style={{
              width: CLAY.BODY_WIDTH,
              minHeight: 430,
              overflowX: 'scroll',
              // ...devOutline,
            }}
          >
            <FlexRowDiv style={{ width: TH_WIDTH }}>
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_EVENT}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_EVETN_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_PRICE}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_PRICE_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_FROM}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_FROM_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_TO}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_TO_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
              <TextCon
                text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_DATE}
                size={CLAY.DEFAULT_FONT_SIZE}
                color={R.colors.blueGrey}
                isBold
                containerStyle={{
                  minWidth: TH_DATE_WIDHT,
                  height: TH_ITEM_HEIGHT,
                  paddingLeft: BOX_PADDING_H,
                  // ...devOutlineF,
                }}
              />
            </FlexRowDiv>

            {sFetchedHistoryData &&
              sFetchedHistoryData.trd_history.map((trading, idx) => {
                return (
                  <>
                    {/* 라인 */}
                    <HLINE size={TH_WIDTH} />
                    <FlexRowDiv>
                      <TextCon
                        text={hR.strings.MARKET_ASSET_READ_TRADING_HISTORY_EVENT_SALE}
                        size={CLAY.DEFAULT_FONT_SIZE}
                        color={R.colors.black}
                        isBold
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_EVETN_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                      <PriceAdv
                        prc_info={trading.prc_info}
                        icSizeWidth={18}
                        icSizeHeight={20}
                        priceFontSize={14}
                        unitFontSize={14}
                        isBold={false}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_PRICE_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                      <UserThumbName
                        userId={trading.from.ucode}
                        thumb={trading.from.prof}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_FROM_WIDHT, paddingLeft: BOX_PADDING_H }}
                        isBold={false}
                      />
                      <UserThumbName
                        userId={trading.to.ucode}
                        thumb={trading.to.prof}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_TO_WIDHT, paddingLeft: BOX_PADDING_H }}
                        isBold={false}
                      />
                      <TextCon
                        text={trading.time === 0 ? '-' : Utils.date.brewFomatString(trading.time)}
                        size={CLAY.DEFAULT_FONT_SIZE}
                        color={R.colors.black}
                        containerStyle={{ height: TH_ITEM_HEIGHT, minWidth: TH_DATE_WIDHT, paddingLeft: BOX_PADDING_H }}
                      />
                    </FlexRowDiv>
                  </>
                );
              })}
            <HLINE size={TH_WIDTH} />

            {sFetchedHistoryData && sFetchedHistoryData.trd_history.length === 0 && (
              <FlexColumnDiv
                style={{
                  flex: 1,
                  alignSelf: 'stretch',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // ...devOutlineF,
                }}
              >
                <TextCon text={hR.strings.AC_ASSET_READ_TRADING_HISTORY_NO_DATA} isBold color={R.colors.blueGrey} size={CLAY.DEFAULT_FONT_SIZE} />
              </FlexColumnDiv>
            )}
          </CommonColumnBox>

          <HEIGHT size={TH_MARGIN_BOTTOM} />

          {/* 페이징 */}
          {/* {sFetchedDetailData.trade_history && sFetchedDetailData.trade_history.length > 0 && (
            <FlexRowDiv style={{ alignSelf: 'center' }}>
              <Img src={[R.images.common_btn_back_disabled3x, ARROW_BTN_SIZE, ARROW_BTN_SIZE]} />
              <FlexRowDiv
                style={{
                  width: 100,
                  height: TH_PAGING_HEIGHT,
                  borderRadius: TH_PAGING_HEIGHT / 2,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: R.colors.line,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: TH_PAGING_MARIGN_V,
                  marginLeft: TH_PAGING_MARIGN_V,
                }}
              >
                <TextCon text={`${sTradingPageno}`} isBold size={CL.DEFAULT_FONT_SIZE} color={R.colors.black} />
              </FlexRowDiv>
              <Img src={[R.images.common_btn_next_disabled3x, ARROW_BTN_SIZE, ARROW_BTN_SIZE]} />
            </FlexRowDiv>
          )} */}

          <HEIGHT size={BOTTOM_SPACE} />
        </FlexColumnDiv>
        {/* <DlgSale asset={sAssetInfo} show={sDlgSale} /> */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </MobileScalableFlexColumn>

      {/* 전체보기 */}
      {/* {sFullImgViewer.isOpen && sFullImgViewer.src && (
        <Lightbox wrapperClassName="custom_modal_image" mainSrc={sFullImgViewer.src} onCloseRequest={() => setFullImgViewer({ isOpen: false })} />
      )} */}
    </>
  );
}
