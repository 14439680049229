/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { config, useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { MyPageSoldCard_i as MySoldCardInfo_i } from 'src/model/model';
import { CbtColCertifyType_e, CbtResPriceInfo_i } from 'src/model/rpcModel';
import { UserDetailMenuType_e } from 'src/redux/modules/userDetailModule';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';
import { LabelValue } from 'src/ui/common_component/LabelValue';
import { CURRENCY_ROUND_DECIMAL_POINT_CBLT, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import {
  SOLDCARD_ASSET_NAME_FONTSIZE,
  SOLDCARD_ASSET_NAME_HEIGHT,
  SOLDCARD_ASSET_NAME_MARGIN_BOTTOM,
  SOLDCARD_BETWEEN,
  SOLDCARD_COL_IMG_SIZE,
  SOLDCARD_COL_IMG_VPADDING,
  SOLDCARD_COL_NAME_FOTNSIZE,
  SOLDCARD_LEFT_CARD_BODY_WIDTH,
  SOLDCARD_LEFT_CARD_HEIGHT,
  SOLDCARD_LEFT_CARD_TOP_PADDING,
  SOLDCARD_LEFT_CARD_WIDTH,
  SOLDCARD_RIGHT_BOX_COMMON_PADDING,
  SOLDCARD_RIGHT_BOX_FONT_HEIGHT,
  SOLDCARD_RIGHT_BOX_FONT_SIZE,
  SOLDCARD_RIGHT_BOX_TITLE_WIDTH,
  SOLDCARD_RIGHT_BOX_VALUE_WIDTH,
  SOLDCARD_RIGHT_BOX_WIDTH,
  SOLDCARD_WIDTH,
} from 'src/ui/screen/AssetMgr/card/UserSoldCard';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, Utils } from 'src/util/utils';

const DT_MY_SOLDCARD_RIGHT_BOX_HEIGHT = 434;
const MB_MY_SOLDCARD_RIGHT_BOX_HEIGHT = 540;

export const MY_SOLD_CARD_RIGHT_BOX_HEIGHT = ScreenInfo.isMobile() ? MB_MY_SOLDCARD_RIGHT_BOX_HEIGHT : DT_MY_SOLDCARD_RIGHT_BOX_HEIGHT;
export const MY_SOLD_CARD_HEIGHT = ScreenInfo.isMobile() ? 354 + 8 + MB_MY_SOLDCARD_RIGHT_BOX_HEIGHT : DT_MY_SOLDCARD_RIGHT_BOX_HEIGHT;

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/
export type MySoldCardProps = {
  mySoldCardInfo: MySoldCardInfo_i;
  isAnim?: boolean;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function MySoldCard({ mySoldCardInfo, isAnim = false, onClick }: MySoldCardProps) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hHistory = useHistory();
  const hAuth = useAuth();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return {
      backgroundColor: 'white',
      marginTop: 0,
      boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
      config: { ...config.gentle, duration: 0 },
    };
  });

  const sumRewardPrice = useCallback(
    ({
      creator_fee,
      platform_fee,
      seller_earn,
    }: {
      creator_fee: CbtResPriceInfo_i;
      platform_fee: CbtResPriceInfo_i;
      seller_earn: CbtResPriceInfo_i;
    }) => {
      DBGMSG();
    },
    []
  );

  // DBGMSG('render');
  return (
    <FlexRowDiv style={{ width: SOLDCARD_WIDTH, flexWrap: 'wrap' }}>
      {/* 왼쪽 에셋카드 */}
      <AnimatedFlexColumnDiv
        style={{
          width: SOLDCARD_LEFT_CARD_WIDTH,
          height: SOLDCARD_LEFT_CARD_HEIGHT,
          cursor: onClick ? 'pointer' : 'inherit',
          borderRadius: 15,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: R.colors.line,
          ...hMouseProps,
          // ...devOutline,
        }}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          onClick && onClick(e);
        }}
      >
        {/* 상단부 */}
        <FlexColumnDiv style={{ alignItems: 'center' }}>
          {/*  Collection display 이름 */}
          <FlexRowDiv
            style={{
              alignItems: 'center',
              width: SOLDCARD_LEFT_CARD_BODY_WIDTH,
              marginTop: SOLDCARD_LEFT_CARD_TOP_PADDING,
              // ...devOutlineF,
              //
            }}
          >
            <FlexDiv style={{ height: SOLDCARD_COL_IMG_SIZE }}>
              {mySoldCardInfo.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
                <Img src={[R.images.common_ic_verify3x, SOLDCARD_COL_IMG_SIZE, SOLDCARD_COL_IMG_SIZE]} containerStyle={{ marginRight: 4 }} />
              )}
            </FlexDiv>
            <TextCon
              color={R.colors.black}
              isBold
              size={SOLDCARD_COL_NAME_FOTNSIZE}
              text={mySoldCardInfo.col_info.dp_name}
              containerStyle={{}}
              textStyle={{ width: SOLDCARD_LEFT_CARD_BODY_WIDTH - SOLDCARD_COL_IMG_SIZE - 4, overflow: 'hidden', textOverflow: 'ellipsis' }}
            />
          </FlexRowDiv>

          <HEIGHT size={SOLDCARD_COL_IMG_VPADDING} />

          {/*  Asset 대표이미지 */}
          <AssetDisplayThumbReqattr
            playerSrc={Utils.svc.brewAssetDisplayThumbReqattr({ assetType: Utils.svc.getAssetType(mySoldCardInfo), img: mySoldCardInfo.nft_img })}
            width={SOLDCARD_LEFT_CARD_BODY_WIDTH}
            height={SOLDCARD_LEFT_CARD_BODY_WIDTH}
            whereToUse={WhereToUse_e.ForNormalCard}
            isBlock={mySoldCardInfo.blocked}
          />
        </FlexColumnDiv>

        {/* 하단부 */}
        <FlexColumnDiv
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {/*  Asset 이름 */}
          <FlexRowDiv
            style={{
              height: SOLDCARD_ASSET_NAME_HEIGHT,
              alignItems: 'center',
              width: SOLDCARD_LEFT_CARD_BODY_WIDTH,
              marginBottom: SOLDCARD_ASSET_NAME_MARGIN_BOTTOM,
              // ...devOutlineF,
            }}
          >
            <TextCon
              color={R.colors.black}
              isBold
              size={SOLDCARD_ASSET_NAME_FONTSIZE}
              // text={'에셋 이름이 길면 이렇게 처리해주세요...'}
              text={mySoldCardInfo.nft_name}
            />
          </FlexRowDiv>
        </FlexColumnDiv>
      </AnimatedFlexColumnDiv>

      {!ScreenInfo.isMobile() && <WIDTH size={SOLDCARD_BETWEEN} />}

      {/* 오른쪽 판매정보 */}
      <AnimatedFlexColumnDiv
        style={{
          width: SOLDCARD_RIGHT_BOX_WIDTH,
          height: MY_SOLD_CARD_RIGHT_BOX_HEIGHT,
          // cursor: onClick ? 'pointer' : 'inherit',
          borderRadius: 15,
          ...hMouseProps,
          ...devOutline,
          marginTop: ScreenInfo.isMobile() ? SOLDCARD_BETWEEN : undefined,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: R.colors.line,
        }}
      >
        <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        {/* 판매 아이디 */}
        <TextCon
          text={`${mySoldCardInfo.sale_id}`}
          color={R.colors.purpleishBlue}
          isBold
          size={14}
          containerStyle={{ height: 20, marginLeft: SOLDCARD_RIGHT_BOX_COMMON_PADDING }}
          onConClick={(e) => {
            onClick && onClick(e);
          }}
        />
        <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        <HLINE size={`100%`} />
        <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        <FlexColumnDiv style={{ marginLeft: SOLDCARD_RIGHT_BOX_COMMON_PADDING }}>
          {/* 에셋 아이디 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MY_MENU_SOLD_ASSET_ID}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={`${mySoldCardInfo.asset_id}`}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 판매일시 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MY_MENU_SOLD_DATE}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={Utils.date.brewFomatString(mySoldCardInfo.txDate)}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.black}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 판매자 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MY_MENU_SOLD_SELLER}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={mySoldCardInfo.seller}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            valueOnClick={
              hAuth.authCommonRes?.ucode === mySoldCardInfo.seller // 판매자는 항상 나...
                ? undefined
                : () => {
                    hHistory.push(Nav.brewUserDetailScreenPath({ ucode: mySoldCardInfo.seller, menu: UserDetailMenuType_e.INVEN }));
                  }
            }
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 구매자 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MY_MENU_SOLD_BUYER}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={mySoldCardInfo.buyer}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            valueOnClick={() => {
              hHistory.push(Nav.brewUserDetailScreenPath({ ucode: mySoldCardInfo.buyer, menu: UserDetailMenuType_e.INVEN }));
            }}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 판매가격 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MY_MENU_SOLD_PRICE}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={`${Utils.currency.getPriceForDP({ prcInfo: mySoldCardInfo.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${
              Utils.svc.getCurrencyDpInfo(mySoldCardInfo.prc_info.curr_info.currency).unit
            }`}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            valueBottomTooltipJsx={
              <FlexColumnDiv style={{ width: 224 }}>
                {/* 컬레션 인세 */}
                {mySoldCardInfo.creator_fee !== null && (
                  <>
                    <FlexRowDiv>
                      <TextCon size={12} color={R.colors.black} text={`${hR.strings.MY_MENU_SOLD_ROYALTY}`} />
                      <TextCon
                        size={12}
                        isBold
                        text={`${Utils.currency.getPriceForDP({
                          prcInfo: mySoldCardInfo.creator_fee,
                          decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
                        })} ${Utils.svc.getCurrencyDpInfo(mySoldCardInfo.creator_fee.curr_info.currency).unit}`}
                        containerStyle={{ marginLeft: 'auto' }}
                      />
                    </FlexRowDiv>
                    <HEIGHT size={5} />
                  </>
                )}
                {/* 플랫폼 수수료 */}
                <FlexRowDiv>
                  <TextCon size={12} color={R.colors.black} text={`${hR.strings.MY_MENU_SOLD_PLATFORM_FEE}`} />
                  <TextCon
                    size={12}
                    isBold
                    text={`${Utils.currency.getPriceForDP({ prcInfo: mySoldCardInfo.platform_fee, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${
                      Utils.svc.getCurrencyDpInfo(mySoldCardInfo.platform_fee.curr_info.currency).unit
                    }`}
                    containerStyle={{ marginLeft: 'auto' }}
                  />
                </FlexRowDiv>
                <HEIGHT size={5} />
                {/* 입금 금액 */}
                <FlexRowDiv>
                  <TextCon size={12} color={R.colors.black} text={`${hR.strings.MY_MENU_SOLD_PROFIT}`} />
                  <TextCon
                    size={12}
                    isBold
                    text={`${Utils.currency.getPriceForDP({ prcInfo: mySoldCardInfo.seller_earn, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${
                      Utils.svc.getCurrencyDpInfo(mySoldCardInfo.seller_earn.curr_info.currency).unit
                    }`}
                    containerStyle={{ marginLeft: 'auto' }}
                  />
                </FlexRowDiv>
              </FlexColumnDiv>
            }
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 리워드 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MY_MENU_SOLD_REWARD}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={`${Utils.currency.getPriceForDP({ prcInfo: mySoldCardInfo.seller_reward, decimal: CURRENCY_ROUND_DECIMAL_POINT_CBLT })} ${
              R.strings.CURRENCY_CBLT
            }`}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            titleTooltipString={hR.strings.MY_MENU_SOLD_REWARD_TOOLTIP}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* Tx Hash */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MY_MENU_SOLD_TX_HASH}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={Utils.string.shortKlatynTxHash(mySoldCardInfo.tx_hash)}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            valueOnClick={() => {
              window.open(mySoldCardInfo.tx_hash_url);
            }}
            valueMouseOverTitle={mySoldCardInfo.tx_hash_url}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        </FlexColumnDiv>
      </AnimatedFlexColumnDiv>
    </FlexRowDiv>
  );
}
