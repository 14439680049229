/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, DOMAttributes, HTMLAttributes, HTMLProps } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { AnimatedFlexColumnDiv, AnimatedFlexDiv, AnimatedFlexRowDiv, devOutlineF, FlexColumnDiv } from 'src/ui/base_component/etc';
import R from 'src/res/R';
import styled from 'styled-components';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export interface AnimatedTxtImgProps {
  key?: string | number;
  ref?: any;
  text?: string;
  img?: string;
  imgSize?: number;
  size?: string | number;
  color?: string;
  isBold?: boolean;
  isItelic?: boolean;
  isUnderline?: boolean;
  fontFamily?: string;
  letterSpacing?: number | string;
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  // onMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  // onMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
  onMouseDefalutProp?: CSSProperties;
  onMouseEnterProp?: CSSProperties;
  onMouseLeaveProp?: CSSProperties;
  enable?: boolean;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
}

const AnimatedText = styled(animated.span)`
  font-family: ${(props: AnimatedTxtImgProps) => props.fontFamily || R.fonts.SpoqaJP};
  margin-right: ${(props: AnimatedTxtImgProps) => (props.isItelic ? 2 : 0)};
  font-size: ${(props: AnimatedTxtImgProps) => `${props.size}px`};
  font-weight: ${(props: AnimatedTxtImgProps) => (props.isBold ? 'bold' : 'normal')};
  font-style: ${(props: AnimatedTxtImgProps) => (props.isItelic ? 'italic' : 'normal')};
  text-decoration: ${(props: AnimatedTxtImgProps) => (props.isUnderline ? 'underline' : 'none')};
  color: ${(props: AnimatedTxtImgProps) => props.color ?? R.colors.rn.blue};
  letter-spacing: ${(props: AnimatedTxtImgProps) => props.letterSpacing};
  white-space: pre-wrap;
`;

export default function TxtImgBtnWithMouseOverAni(props: AnimatedTxtImgProps) {
  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return {
      ...props.onMouseDefalutProp,
      config: { ...config.gentle, duration: 50 },
    };
  });

  const onlyAnimatedTextProps: AnimatedTxtImgProps = {
    ...props,
    containerStyle: undefined,
    onClick: undefined,
    onMouseDefalutProp: undefined,
    onMouseEnterProp: undefined,
    onMouseLeaveProp: undefined,
  };
  return (
    <AnimatedFlexColumnDiv
      // key={props.key}
      style={{
        cursor: 'pointer',
        ...devOutline,
        ...props.containerStyle,
        ...hMouseProps,
        // backgroundColor: R.colors.devColor4a,
      }}
      onMouseEnter={() => {
        // DBGMSG('onMouseEnter');
        props.enable !== false && set({ ...props.onMouseEnterProp });
      }}
      onMouseLeave={() => {
        // DBGMSG('onMouseLeave');
        props.enable !== false && set({ ...props.onMouseLeaveProp });
      }}
      onClick={props.onClick}
    >
      <AnimatedFlexRowDiv>
        {props.img && (
          <AnimatedFlexDiv>
            <img src={props.img} style={{width:props.imgSize, height:props.imgSize}} alt="" />
          </AnimatedFlexDiv>
        )}
        <AnimatedText {...onlyAnimatedTextProps} style={props.textStyle}>
          {props.text}
        </AnimatedText>
      </AnimatedFlexRowDiv>
    </AnimatedFlexColumnDiv>
  );
}
