/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { devOutlineF, FlexColumnDiv, FlexDiv } from 'src/ui/base_component/etc';
import Txt from 'src/ui/base_component/_Txt';
import { useMobileSizeHelper } from 'src/hooks/useMobileWidthHelper';
import R from 'src/res/R';
import { DBGMSG } from 'src/util/utils';
import { CLAY } from 'src/ui/layout_constant';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {};

export function PlayScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const location = useLocation();
  const { screenWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { param_x?: string; param_y?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.param_x && query.param_y) {
      DBGMSG(`TemplatePage param_x: ${query.param_x}`);
      DBGMSG(`TemplatePage param_y: ${query.param_y}`);
      const x = query.param_x;
      const y = parseInt(query.param_x);
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  DBGMSG('render');
  return (
    <FlexColumnDiv
      style={{
        // backgroundColor: R.colors.rn.black1,
        flexDirection: 'column',
        paddingTop: CLAY.COMMON_HEADER_HEIGHT,
        alignItems: 'flex-start',
        padding: 20,
        ...devOutline,
      }}
    >
      <Txt text={'display test'} containerStyle={{ marginBottom: 20 }} />
      <Txt size={24} isBold text={'image'} />
      <Txt text={'jpg 3000x4000'} />
      <img src={'nft_res/nft_res_sample.jpg'} alt="" width={screenWidth} style={{ marginBottom: 30 }}></img>
      <Txt text={'png 677x714'} />
      <img src={'nft_res/nft_res_sample.png'} alt="" width={screenWidth} style={{ marginBottom: 30 }}></img>
      <Txt text={'gif 820x820'} />
      <img src={'nft_res/nft_res_sample.gif'} alt="" width={screenWidth} style={{ marginBottom: 30 }}></img>
      <Txt text={'apng'} />
      <img src={'nft_res/nft_res_sample.apng'} alt="" width={screenWidth} style={{ marginBottom: 30 }}></img>
      <Txt size={24} isBold text={'audio'} />
      <Txt text={'mp3'} />
      <audio src={'nft_res/nft_res_sample.mp3'} controls style={{ marginBottom: 30 }}></audio>
      <Txt text={'aac'} />
      <audio src={'nft_res/nft_res_sample.aac'} controls style={{ marginBottom: 30 }}></audio>
      <Txt size={24} isBold text={'video'} />
      <Txt text={'mp4 H.264 1280x720 '} />
      <video controls width={screenWidth}>
        <source src="nft_res/nft_res_sample.mp4" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </FlexColumnDiv>
  );
}
