/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useDebounce, useEffectOnce } from 'react-use';
import { isAlpha, isBeta } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, CommonInput, devOutlineF, FlexColumnDiv, TooltipBox } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  key?: string;
  getRef?: (instance: HTMLInputElement | null) => void;
  value: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  fontSize?: number;
  placeHolder?: string;
  defaultValue?: string;
  disabled?: boolean;
  inputType?: string;
  paddingH?: number;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onIsinvalidAddress: (isValidAddress: boolean) => void;
};

const DT_INPUT_WIDTH = 430;
const DT_INPUT_HEIGHT = 28;
const DT_INPUT_PADDING_H = 10;
const DT_INPUT_FONTSIZE = 14;
const DT_TOOLTIP_PLACE = 'bottom';
const DT_TOOLTIP_WIDTH = DT_INPUT_WIDTH;
const DT_TOOLTIP_HEIGHT = 64;
const DT_TOOLTIP_FONTSIZE = 12;
const DT_TOOLTIP_PADDING_V = 10;
const DT_TOOLTIP_PADDING_H = 8;
const DT_TOOLTIP_OFFSET = 10;
const DT_INVALID_ERROR_MSG_PLACE = 'top-end';
const DT_INVALID_ERROR_MSG_FONTSIZE = 12;

const MB_INPUT_WIDTH = 264;
const MB_INPUT_HEIGHT = 22;
const MB_INPUT_PADDING_H = 4;
const MB_INPUT_FONTSIZE = 10;
const MB_TOOLTIP_PLACE = 'top';
const MB_TOOLTIP_WIDTH = MB_INPUT_WIDTH;
const MB_TOOLTIP_HEIGHT = 52;
const MB_TOOLTIP_FONTSIZE = 10;
const MB_TOOLTIP_PADDING_V = 8;
const MB_TOOLTIP_PADDING_H = 8;
const MB_TOOLTIP_OFFSET = 6;
const MB_INVALID_ERROR_MSG_PLACE = 'bottom-start';
const MB_INVALID_ERROR_MSG_FONTSIZE = 10;

const INPUT_WIDTH = ScreenInfo.isMobile() ? MB_INPUT_WIDTH : DT_INPUT_WIDTH;
const INPUT_HEIGHT = ScreenInfo.isMobile() ? MB_INPUT_HEIGHT : DT_INPUT_HEIGHT;
const INPUT_PADDING_H = ScreenInfo.isMobile() ? MB_INPUT_PADDING_H : DT_INPUT_PADDING_H;
const INPUT_FONTSIZE = ScreenInfo.isMobile() ? MB_INPUT_FONTSIZE : DT_INPUT_FONTSIZE;
const GUIDE_TOOLTIP_PLACE = ScreenInfo.isMobile() ? MB_TOOLTIP_PLACE : DT_TOOLTIP_PLACE;
const TOOLTIP_WIDTH = ScreenInfo.isMobile() ? MB_TOOLTIP_WIDTH : DT_TOOLTIP_WIDTH;
const TOOLTIP_HEIGHT = ScreenInfo.isMobile() ? MB_TOOLTIP_HEIGHT : DT_TOOLTIP_HEIGHT;
const TOOLTIP_FONTSIZE = ScreenInfo.isMobile() ? MB_TOOLTIP_FONTSIZE : DT_TOOLTIP_FONTSIZE;
const TOOLTIP_PADDING_V = ScreenInfo.isMobile() ? MB_TOOLTIP_PADDING_V : DT_TOOLTIP_PADDING_V;
const TOOLTIP_PADDING_H = ScreenInfo.isMobile() ? MB_TOOLTIP_PADDING_H : DT_TOOLTIP_PADDING_H;
const TOOLTIP_OFFSET = ScreenInfo.isMobile() ? MB_TOOLTIP_OFFSET : DT_TOOLTIP_OFFSET;
const INVALID_ERROR_MSG_PLACE = ScreenInfo.isMobile() ? MB_INVALID_ERROR_MSG_PLACE : DT_INVALID_ERROR_MSG_PLACE;
const INVALID_ERROR_MSG_FONTSIZE = ScreenInfo.isMobile() ? MB_INVALID_ERROR_MSG_FONTSIZE : DT_INVALID_ERROR_MSG_FONTSIZE;

export function KlayAddrInput({
  key,
  getRef,
  value,
  borderRadius = 5,
  placeHolder,
  defaultValue,
  disabled = false,
  inputType,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onIsinvalidAddress,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [debouncedSearchTxt, setDebouncedSearchTxt] = useState(value);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [sInputFocus, setInputFocus] = useState<boolean>(false);

  const [sIsInvalidAddress, setIsInvalidAddress] = useState<boolean | undefined>(undefined);

  /**************************************
   * !! ref
   **************************************/
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isInpufFocusRef = useRef<boolean>(false);
  const targetRef = useRef(null);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const [,] = useDebounce(
    () => {
      setIsTyping(false);
      if (value === debouncedSearchTxt) return;
      setDebouncedSearchTxt(value);
      DBGMSG(`debouncedInputText:${value}`);
    },
    1000,
    [value]
  );

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    DBGMSG('useEffect() init');
    if (isValidKlaytnAddress(value)) {
      setInvalidAddressMsg(false);
    } else {
      setInvalidAddressMsg(true);
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, [value]);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const setInvalidAddressMsg = (isInvalid: boolean) => {
    setIsInvalidAddress(isInvalid);
    onIsinvalidAddress(isInvalid);
  };

  // 올바른 Klaytn 지갑 주소인지 확인
  const isValidKlaytnAddress = (address: string) => {
    if (isAlpha() || isBeta()) {
      if (address.length === 0) return true;
    }
    if (address.length !== 42) return false;
    return Constants.REGEXP_IS_VALID_KLAY_ACCOUNT_ADDRESS.test(address);
  };

  /**************************************
   * !! render function
   **************************************/
  const renderGuide = () => {
    return (
      <Overlay
        target={targetRef.current}
        show={sInputFocus}
        placement={GUIDE_TOOLTIP_PLACE}
        // placement="auto"
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              zIndex: 100,
            }}
          >
            <TooltipBox
              style={{
                marginTop: TOOLTIP_OFFSET,
                marginBottom: TOOLTIP_OFFSET,
                width: TOOLTIP_WIDTH,
                height: TOOLTIP_HEIGHT,
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: TOOLTIP_PADDING_H,
                paddingRight: TOOLTIP_PADDING_H,
                paddingTop: TOOLTIP_PADDING_V,
                paddingBottom: TOOLTIP_PADDING_V,
                zIndex: 100,
              }}
            >
              <TextCon text={hR.strings.MY_KLAY_ADDRESS_INPUT_TOOLTIP} color={R.colors.black} size={TOOLTIP_FONTSIZE} />
            </TooltipBox>
          </div>
        )}
      </Overlay>
    );
  };

  const renderInvalidAddressMsg = () => {
    return (
      <Overlay target={targetRef.current} show={sIsInvalidAddress} placement={INVALID_ERROR_MSG_PLACE}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              zIndex: 100,
            }}
          >
            <FlexColumnDiv style={{}}>
              <TextCon
                text={hR.strings.MY_KLAY_ADDRESS_INPUT_INVALID}
                size={INVALID_ERROR_MSG_FONTSIZE}
                color={R.colors.pastelRed}
                containerStyle={{ paddingBottom: 8, height: 18, alignSelf: 'flex-end' }}
              />
            </FlexColumnDiv>
          </div>
        )}
      </Overlay>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  DBGMSG('render');
  return (
    <>
      <AnimatedFlexRowDiv
        key={key}
        ref={targetRef}
        style={Object.assign(
          {
            width: INPUT_WIDTH,
            height: INPUT_HEIGHT,
            justifyContent: 'flex-start',
            paddingLeft: INPUT_PADDING_H,
            paddingTop: 4,
            paddingBottom: 4,
            paddingRight: INPUT_PADDING_H,
            borderStyle: 'solid',
            borderColor: R.colors.line,
            borderWidth: 1,
            borderRadius: borderRadius,
          },
          sInputFocus
            ? {
                //
                borderColor: R.colors.purpleishBlue,
                // backgroundColor: R.colors.pastelRed,
              }
            : {
                //
                borderColor: R.colors.line,
                // backgroundColor: R.colors.bgGrey,
              },
          disabled
            ? {
                // backgroundColor: R.colors.lightGrey,
                // borderWidth: 1,
                // borderColor: R.colors.lightGrey,
              }
            : undefined
        )}
      >
        <CommonInput
          style={{
            fontSize: INPUT_FONTSIZE,
            width: INPUT_WIDTH,
            // backgroundColor: sInputFocus ? R.colors.pastelRed : R.colors.bgGrey,
            backgroundColor: R.colors.bgGrey,
            color: sInputFocus ? R.colors.purpleishBlue : R.colors.black,
            borderWidth: 0,
            // fontFamily: 'monospace',
            fontFamily: 'Courier'
            // ...devOutlineFF,
          }}
          ref={(input) => {
            inputRef.current = input;
            getRef && getRef(input);
          }}
          type={inputType}
          disabled={disabled}
          value={value}
          placeholder={placeHolder}
          onFocus={(e) => {
            DBGMSG('Input onFocus');
            isInpufFocusRef.current = true;
            setInputFocus(true);
            onFocus && onFocus(e);
          }}
          onBlur={(e) => {
            DBGMSG('Input onBlur');
            isInpufFocusRef.current = false;
            setInputFocus(false);
            onBlur && onBlur(e);
          }}
          onChange={(e) => {
            DBGMSG(`onChange: ${e.target.value}`);
            setIsTyping(true);
            onChange && onChange(e);

            if (e.target.value.length > 42) return;
          }}
          onKeyPress={(e) => {
            DBGMSG(`onKeyPress code:${e.code}`);
            DBGMSG(`onKeyPress key:${e.key}`);
            onKeyDown && onKeyDown(e);
          }}
          // returnKeyType="search"
          // clearButtonMode="while-editing"
        />
        {renderGuide()}
        {renderInvalidAddressMsg()}
      </AnimatedFlexRowDiv>
    </>
  );
}
