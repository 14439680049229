/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { RPC_MP_SALE_ADD_VIEW, RPC_MP_SALE_EDIT_VIEW } from 'src/model/rpcType';
import {
  CbtAssetCardInfo_i,
  CbtAssetType_e,
  CbtAttributeInfo_i,
  CbtAttributeType_e,
  CbtColCertifyType_e,
  CbtCollectionCardInfo_i,
  CbtCollectionDetailInfo_i,
  CbtCreationStatus_e,
  CbtCurrency_e,
  CbtPgInfo_i,
  CbtReqPriceInfo_i,
  CbtResPriceInfo_i,
  CbtUserCertTp_i,
} from 'src/model/rpcModel';

// ##########################################################################################
// enum
// ##########################################################################################
// 페이지 로딩 상태
export enum PageLoadingStatus {
  Unbegun = 'Unbegun',
  Loading = 'Loading',
  Succ = 'Succ',
  Fail = 'Fail',
}

// 로그인 정보
export type AuthCommonRes_t = {
  ucode: string;
  token: string;
  mkey: string;
  cert_tp: CbtUserCertTp_i;
  // prof: string | null;
  // reward_addr: string | null; // 판매 대금 / 리워드 입금용 klaytn 계정 주소 등록 여부
  // reward_addr_hash: string | null; // 판매 대금 / 리워드 입금용 klaytn 계정 주소 등록 여부
};

// 로그인 상태
export enum AuthStatus {
  Login = 'Login',
  Logout = 'Logout',
}

// // Currency type
// export enum CurrencyType_e {
//   ETH = 'ETH',
//   KLAY = 'KLAY',
// }

// 통화 설정 (보기용)
export enum DisplayCurrency_e {
  USD = 'USD',
  JPY = 'JPY',
}

// 마켓플레이스 에셋 정렬
export enum CbtMarketSearchSort_e {
  ListingNewest,
  ListingOldest,
  PriceHighest,
  PriceLowest,
}

// 마켓플레이스 컬렉션 필터
// export enum CbtMarketSearchCollectionFilter_e {
//   All,
//   Pokecolo,
//   Carrot,
//   Stichy,
// }

// ##########################################################################################
// page data type
// ##########################################################################################
// Explorer 페이지
export interface ExplorerScreen_i {
  newAssetCnt: number;
  listingCnt: number;
  saleCnt: number;
  dollarAmount: number;

  newAssetCardList: MarketAssetCard_i[];
  collectionCardList: CbtCollectionCardInfo_i[];
}

// Asset Creator 페이지 & 나의 컬렉션 목록
export interface AssetCreatorScreen_i {
  collectionCardList: CollectionCard_i[];
}

// 나의 컬렉션 편집 페이지
export interface MyCollectionUScreen_i {
  id: number;
  img: string;
  name: string;
  isVarified: boolean;
  displayName: string;
  royaltyFee: number;
  website: string | null;
  desc: string | null;
  owner: string;
  admins: string[];
}

// 스키마 보기 페이지
export interface MySchemaRScreen_i {
  sid: number;
  asset_type: CbtAssetType_e;
  name: string;
  dp_name: string;
  attributeList: CbtAttributeInfo_i[];
  myAssetList: AssetCard_i[];
}

// 스키마 편집 페이지
export interface MySchemaUScreen_i {
  id: number;
  assetType: CbtAssetType_e;
  name: string;
  displayName: string;
  attributeList: CbtAttributeInfo_i[];
}

// 에셋 만들기 페이지
export interface MyAssetCScreen_i {
  schemaId: number;
  assetType: CbtAssetType_e;
  name: string;
  attributeList: AttributeInfoWithValue_i[];

  // collectionId: CobaltTypePool_i['collectionId'];
  // collectionDispName: CobaltTypePool_i['collectionDisplayName'];
  // schemaDisplayName: CobaltTypePool_i['schemaDisplayName'];
}

// My 에셋 보기 페이지
export interface MyAssetRScreen_i {
  id: number;
  assetType: CbtAssetType_e;
  assetName: string;
  displayName: string;
  mintCnt: number;
  canMoreMint: boolean;
  attributeList: AssetAttribute_i[];
  displayImg: string;
  owner: string;
  creator: string;
  isVarified: boolean;
  collectionDisplayName: string;
  schemaDisplayName: string;
  isOnSale: boolean;
  tradingList: Trading_i[];
}

// 마켓플레이스 - 에셋 상세페이지
export interface MarketAssetRead_i {
  sellerId: string;
  creatorId: string;
  assetId: number;
  collectionId: number;
  schemaId: number;
  assetType: CbtAssetType_e;
  assetName: string;
  mintCnt: number;
  canMoreMint: boolean;
  attributeList: AssetAttribute_i[];
  assetMainImg: string;
  owner: string;
  creator: string;
  collectionIsVarified: boolean;
  collectionDisplayName: string;
  schemaDisplayName: string;
  isOnSale: boolean;
  priceHistoryStatus: { lowestPrice: number; highestPrice: number; txCnt: number };
  priceHistoryList: PriceHistory_t[];
  tradingList: Trading_i[];
  marketSaleId: number;
  isFavorite: boolean;
  priceInfo: PriceInfo_i;
}

// 마켓플레이스 - 컬렉션 상세페이지
export interface MarketCollectionRScreen_i {
  col_info: CbtCollectionDetailInfo_i;
  ast_list: CbtAssetCardInfo_i[];
  pg_info: CbtPgInfo_i;
}

// 마켓플레이스 - 스키마 상세페이지
export interface MarketSchemaRScreen_i {
  sid: number;
  asset_type: CbtAssetType_e;
  name: string;
  dp_name: string;
  attributeList: CbtAttributeInfo_i[];
  assetList: MarketAssetCard_i[];
}

// ##########################################################################################
// 공통 type
// ##########################################################################################

export interface CollectionInfo_i {
  cid: number;
  cert_tp: boolean;
  dp_name: string;
  dp_img_url: string;
  status: CbtCreationStatus_e;
}

export interface CollectionCard_i {
  cid: number;
  cert_tp: boolean;
  dp_name: string;
  dp_img_url: string;
  status?: CbtCreationStatus_e;
}

// schema card

// market asset card
export interface MarketAssetCard_i {
  sale_id: number;
  asset_id: number;
  asset_type: CbtAssetType_e;
  nft_name: string;
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  nft_img: string;
  owner: string;
  favorite: boolean;
  prc_info: CbtResPriceInfo_i;
  sold?: boolean;
  blocked?: boolean;
}

// asset card
export interface AssetCard_i {
  assetId: number;
  collectionId: number;
  assetType: CbtAssetType_e;
  assetName: string;
  collectionIsVarified: boolean;
  collectionDisplayName: string;
  assetMainImg: string;
  owner: string;
}

// 인벤토리
export interface CbtAssetGroupInvenCardInfo_i {
  entity_id: number;
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  schm_info: {
    sid: number;
    dp_name: string;
    asset_type: CbtAssetType_e;
  };
  nft_name: string;
  nft_img: string;
  own_cnt: number;
  time: number;
  isBlock?: boolean;
}

// 다른 사용자 - 생성 에셋
export interface CreatedCard_i {
  assetId: number;
  collectionId: number;
  assetType: CbtAssetType_e;
  assetName: string;
  collectionIsVarified: boolean;
  collectionDisplayName: string;
  assetMainImg: string;
  owner: string;
}

// 다른 사용자 - 판매됨
export interface SoldCard_i {
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  nft_name: string;
  nft_img: string;
  nft_aud?: string | null;
  nft_vid?: string | null;
  sale_id: number;
  asset_id: number;
  txDate: number;
  seller: string;
  buyer: string;
  prc_info: CbtReqPriceInfo_i;
  tx_hash: string;
  tx_hash_url: string;
}

// 다른 사용자 - 구매함
export interface UserBoughtCardInfo_i {
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  nft_name: string;
  nft_img: string;
  nft_aud?: string | null;
  nft_vid?: string | null;
  sale_id: number;
  asset_id: number;
  txDate: number;
  seller: string;
  buyer: string;
  prc_info: CbtReqPriceInfo_i;
  tx_hash: string;
  tx_hash_url: string;
}

// 마이 페이지 - 판매됨
export interface MyPageSoldCard_i {
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  nft_name: string;
  nft_img: string;
  nft_aud?: string | null;
  nft_vid?: string | null;
  sale_id: number;
  asset_id: number;
  txDate: number;
  seller: string;
  buyer: string;
  prc_info: CbtReqPriceInfo_i;
  seller_earn: CbtReqPriceInfo_i;
  creator_fee: CbtReqPriceInfo_i | null;
  platform_fee: CbtReqPriceInfo_i;
  seller_reward: CbtReqPriceInfo_i; // 리워드
  tx_hash: string;
  tx_hash_url: string;
  blocked: boolean;
}

// 마이 페이지 - 구매함
export interface MyBoughtCardInfo_i {
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  nft_name: string;
  nft_img: string;
  nft_aud?: string | null;
  nft_vid?: string | null;
  sale_id: number;
  asset_id: number;
  txDate: number;
  seller: string;
  buyer: string;
  prc_info: CbtReqPriceInfo_i;
  buyer_reward: CbtReqPriceInfo_i; // 리워드
  tx_hash: string;
  tx_hash_url: string;
  blocked: boolean;
}

// 사용자 기본정보
export interface UserInfo_i {
  userId: string;
  userThumb?: string | null;
  userWalletAddress: string;
  userDepositWalletAddress: string;
}

// 가격 정보
export interface PriceInfo_i {
  price: number;
  currency: CbtCurrency_e;
  dollar?: number; // ?
}

// 에셋의 속성 정보
export interface AssetAttribute_i {
  name: string;
  type: CbtAttributeType_e;
  required?: boolean;
  value: string;
}

// 이미지 에셋의 필수 속성 정보
export interface ReqImageSrc_i {
  kind: 'ReqImage';
  img?: string;
}

// 오디오 에셋의 필수 속성 정보
export interface ReqAudioSrc_i {
  kind: 'ReqAudio';
  img?: string;
  audio?: string;
}

// 비디오 에셋의 필수 속성 정보
export interface ReqVideoSrc_i {
  kind: 'ReqVideo';
  img?: string;
  video?: string;
}

// 추가 이미지 속성 정보
export interface OptImageSrc_i {
  kind: 'OptImage';
  name?: string;
  img?: string;
}

// 추가 오디오 속성 정보
export interface OptAudioSrc_i {
  kind: 'OptAudio';
  name?: string;
  audio?: string;
}

// 추가 비디오 속성 정보
export interface OptVideoSrc_i {
  kind: 'OptVideo';
  name?: string;
  video?: string;
}

export type DisplaySrc_t =
  | Required<ReqImageSrc_i>
  | Required<ReqAudioSrc_i>
  | Required<ReqVideoSrc_i>
  | Required<OptImageSrc_i>
  | Required<OptAudioSrc_i>
  | Required<OptVideoSrc_i>;

export type DisplaySrcForm_t = ReqImageSrc_i | ReqAudioSrc_i | ReqVideoSrc_i | OptImageSrc_i | OptAudioSrc_i | OptVideoSrc_i;

// price history status
export type PriceHistoryStatus_t = {
  lowestPrice: number;
  highestPrice: number;
  txCnt: number;
};

// price history
export type PriceHistory_t = {
  date: number;
  saleId: string;
  priceInfo: PriceInfo_i;
};

// trading
export interface Trading_i {
  marketSaleId: number;
  priceInfo: PriceInfo_i;
  from: string;
  to: string;
  date: number;
}

// 스키마의 속성정보, 값포함
export interface AttributeInfoWithValue_i {
  name: string;
  type: CbtAttributeType_e;
  value?: string;
}

// ##########################################################################################
// Dialog popup
// ##########################################################################################

// 구매하기 다이얼로그 정보
export interface DlgBuyInfo_i {
  assetId: number; // 에셋 아이디
  assetMainImg: string; // 메인 이미지
  assetType: CbtAssetType_e; // 에셋 타입
  marketSaleId: number; // 판매 아이디
  collectionId: number; // 컬렉션 아이디
  collectionIsVarified: boolean; // 컬렉션 인증 여부
  collectionDisplayName: string; // 컬렉션 disp 이름
  assetName: string; // 에셋 이름
  mintCnt: number; // 발행 매수
  canMoreMint: boolean; // 추가 발행 여부
  schemaId: number; // 스키마 아이디
  schemaDisplayName: string; // 스키마 disp 이름
  sellerId: string; // 판매자
  priceInfo: PriceInfo_i; // 가격
}

// 판매하기 다이얼로그 정보
export interface DlgSellInfo_i {
  req: { mode: 'NEW'; tx: RPC_MP_SALE_ADD_VIEW.Tx } | { mode: 'EDIT'; tx: RPC_MP_SALE_EDIT_VIEW.Tx };
  fetched: RPC_MP_SALE_ADD_VIEW.Rx | RPC_MP_SALE_EDIT_VIEW.Rx;
}
