/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect } from 'react';
import { useImage } from 'react-image';
import { useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtCollectionCardInfo_i, CbtCreationStatus_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { absoluteFillProps, AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgAdv } from 'src/ui/base_component/ImgAdv';
import TextCon from 'src/ui/base_component/TextCon';
import {
  COLLECTION_CARD_LAYOUT,
  PropsCommonBox,
  SPRING_PROPS_ANIM_1_Default,
  SPRING_PROPS_ANIM_1_OnMouseEnter,
  SPRING_PROPS_ANIM_1_OnMouseLeave,
} from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';
import { CSSProperties } from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export type CollectionCardProps = {
  collectionInfo: CbtCollectionCardInfo_i;
  isAnim?: boolean;
  onClick?: () => void;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

export function CollectionCard({ collectionInfo, isAnim = false, onClick }: CollectionCardProps) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    return () => {};
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const collectionImgContainerStyle: CSSProperties = {
    width: COLLECTION_CARD_LAYOUT.collectionImgSize,
    height: COLLECTION_CARD_LAYOUT.collectionImgSize,
    borderColor: R.colors.line,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: COLLECTION_CARD_LAYOUT.collectionImgSize / 2,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const renderCollectionImg = () => {
    return (
      <FlexDiv style={{ width: COLLECTION_CARD_LAYOUT.collectionImgSize, height: COLLECTION_CARD_LAYOUT.collectionImgSize }}>
        <ImgAdv
          src={[collectionInfo.dp_img_url, COLLECTION_CARD_LAYOUT.collectionImgSize, COLLECTION_CARD_LAYOUT.collectionImgSize]}
          containerStyle={{
            ...collectionImgContainerStyle,
          }}
          imgStyle={{
            objectFit: 'cover',
          }}
        />
      </FlexDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // const [hMouseProps, set] = useSpring(() => {
  //   return {
  //     boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
  //     config: { ...config.gentle, duration: 0 },
  //   };
  // });
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  // console.debug('render');
  return (
    <AnimatedFlexColumnDiv
      style={{
        width: COLLECTION_CARD_LAYOUT.width,
        height: COLLECTION_CARD_LAYOUT.height,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: onClick ? 'pointer' : 'auto',
        ...PropsCommonBox,
        ...hMouseProps,
        // ...devOutlineF,
      }}
      onMouseEnter={() => {
        // DBGMSG(`onMouseEnter: `);
        // set({ backgroundColor: R.colors.paleGrey, transform: 'scale(1.005)' });
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
      }}
      onMouseLeave={() => {
        // DBGMSG(`onMouseLeave: `);
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
      }}
      onClick={() => {
        DBGMSG(`onClick`);
        onClick && onClick();
      }}
    >
      {/* <LazyLoadComponent
          style={{ alignItems: 'center' }}
          placeholder={<FlexDiv style={{ width: '100%', height: '100%', backgroundColor: R.colors.devColor1a }} />}
        > */}
      <FlexColumnDiv
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          // ...devOutlineF
        }}
      >
        {/*  Collection 대표이미지 */}
        <FlexRowDiv>
          {renderCollectionImg()}
          {/* 컬렉션 생성 처리중인 상태일때 */}
          {collectionInfo.status === CbtCreationStatus_e.InPreparation && (
            <FlexColumnDiv
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: COLLECTION_CARD_LAYOUT.collectionImgSize,
                height: COLLECTION_CARD_LAYOUT.collectionImgSize,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FlexColumnDiv
                style={{
                  width: COLLECTION_CARD_LAYOUT.collectionImgSize,
                  height: COLLECTION_CARD_LAYOUT.collectionImgSize,
                  borderRadius: COLLECTION_CARD_LAYOUT.collectionImgSize / 2,
                  backgroundColor: 'white',
                  opacity: 0.8,
                  ...absoluteFillProps,
                }}
              />
              <Img src={[R.images.creator_ic_loading1x, 28, 28]} />
              <TextCon text={hR.strings.AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_1} size={10} color={R.colors.blueGrey} isBold />
              <TextCon text={hR.strings.AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_2} size={10} color={R.colors.blueGrey} isBold />
            </FlexColumnDiv>
          )}
        </FlexRowDiv>
        {/* <span>{image.caption}</span> */}
        {/* <Img src={[img, IMAGE_WDITH, IMAGE_HEIGHT]} /> */}

        <HEIGHT size={COLLECTION_CARD_LAYOUT.space} />
        {/*  Collection 이름 */}
        <FlexRowDiv
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: COLLECTION_CARD_LAYOUT.collecionNameWidth,
            // ...devOutlineF,
          }}
        >
          {collectionInfo.cert_tp !== 0 && (
            <>
              <Img
                src={[R.images.common_ic_verify3x, COLLECTION_CARD_LAYOUT.icSize.collection.width, COLLECTION_CARD_LAYOUT.icSize.collection.height]}
              />
              <div style={{ width: 4 }} />
            </>
          )}
          <TextCon
            color={'#121f33'}
            isBold
            size={COLLECTION_CARD_LAYOUT.fontSize.collecitonName}
            // text={'컬렉션 이름이 길면 이렇게 처리...'}
            text={collectionInfo.dp_name}
            containerStyle={
              {
                // ...devOutlineF,
              }
            }
          />
        </FlexRowDiv>
      </FlexColumnDiv>
      {/* </LazyLoadComponent> */}
    </AnimatedFlexColumnDiv>
  );
}
