/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF } from 'src/ui/base_component/etc';
import { RoundTxtBtnProps } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtn2 } from 'src/ui/common_component/button/RoundTxtBtn2';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';
import { Nav } from 'src/ui/screen/Nav';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export function ChangeBtn({
  text: label,
  width = 125,
  height = 34,
  fontSize = 12,
  onClick,
  containerStyle,
  onMouseEnter,
  onMouseLeave,
  enable = true,
}: RoundTxtBtnProps) {
  const [sMouseOver, setMouseOver] = useState(false);
  const hR = useLocalSettings();

  return (
    <RoundTxtBtn2
      text={label ?? hR.strings.MY_MENU_MYACCOUNT_SETTINGS_CHANGE_BTN}
      width={width}
      height={height}
      fontSize={fontSize}
      containerStyle={{ ...containerStyle, ...devOutlineF }}
      onClick={
        enable
          ? (e) => {
              onClick && onClick(e);
            }
          : undefined
      }
    />
  );
}
