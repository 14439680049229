/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CSSProperties } from 'react';
import { animated } from 'react-spring';
// import NumberFormat from 'react-number-format';
// import { animated } from 'react-spring';
import R from 'src/res/R';
import { CLAY } from 'src/ui/layout_constant';
import styled, { keyframes } from 'styled-components';

export const devOutlineF: CSSProperties = {
  borderColor: 'red',
  borderWidth: process.env.NODE_ENV === 'production' ? 0 : 1,
  borderStyle: 'solid',
};

export const devOutlineFB: CSSProperties = {
  borderColor: 'blue',
  borderWidth: process.env.NODE_ENV === 'production' ? 0 : 1,
  borderStyle: 'solid',
};

export const devOutlineFG: CSSProperties = {
  borderColor: 'green',
  borderWidth: process.env.NODE_ENV === 'production' ? 0 : 1,
  borderStyle: 'solid',
};

export const devOutlineFF: CSSProperties = {
  borderColor: 'red',
  borderWidth: 1,
  borderStyle: 'solid',
};

// 애니메이션
const fadeAnim = keyframes`
   from { opacity: 0; }
   to{ opacity: 1; }
 `;

export const DivWithFadeIn = styled.div`
  /* animation-name: ${fadeAnim};
   animation-duration: 1s;
   animation-timing-function: ease;
   animation-iteration-count: 1; */
`;

// export const InputYen = styled(NumberFormat)`
//   border-radius: 10px;
//   border-style: solid;
//   border-width: 1px;
//   background-color: ${R.colors.rn.black2};

//   border-color: ${R.colors.rn.black4};
//   ::placeholder,
//   ::-webkit-input-placeholder {
//     color: ${R.colors.rn.black4};
//   }
//   :-ms-input-placeholder {
//     color: ${R.colors.rn.black4};
//   }
//   &:focus {
//     border-color: ${R.colors.blush};
//   }
// `;

export const StyledInput = styled.input`
  width: 90%;
  padding: grey;
  border: 0px;
  font-size: 12px;
  &:input:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
  }
`;

// export const GradientDiv = styled.div`
//   /* width: 375px; */
//   /* height: 87px; */
//   /* object-fit: contain; */
//   background-image: linear-gradient(to bottom, ${R.colors.setAlpha(R.colors.rn.gray, 0)}, ${R.colors.rn.greenyellow});
// `;

export const FlexDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
`;

export const FlexRowDiv = styled(FlexDiv)`
  flex-direction: row;
`;

export const FlexColumnDiv = styled(FlexDiv)`
  flex-direction: column;
`;

export const absoluteFillProps: React.CSSProperties = {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

export const AnimatedFlexDiv = animated(FlexDiv);
export const AnimatedFlexRowDiv = animated(FlexRowDiv);
export const AnimatedFlexColumnDiv = animated(FlexColumnDiv);
export const AnimatedFlexColumnActiveDiv = styled(AnimatedFlexColumnDiv)`
  &:active {
    color: 'blue';
  }
`;

// WIP
// export const CmnText = styled.div<{
//   key?: string | number;
//   ref?: any;
//   text?: string;
//   size?: number | string;
//   color?: string;
//   isBold?: boolean;
//   fontFamily?: string;
//   letterSpacing?: number | string;
//   containerStyle?: CSSProperties;
//   textStyle?: CSSProperties;
// }>`
//   font-family: ${props => props.fontFamily ?? R.fonts.SpoqaJP};
//   white-space: nowrap;
//   font-size: ${props => props.size ?? '1em'};
//   font-weight: ${props => (props.isBold ? 'bold' : 'normal')};
// `;

const expendBtnAnim = keyframes`
   0% { opacity: 0; width: 0px }
   50% { opacity: 0; width: 36px}
   100% { opacity: 1; width: 36px}
 `;

const collapseBtnAnim = keyframes`
   0% { opacity: 1; width: 36px}
   50% { opacity: 0; width: 36px}
   100% { opacity: 0; width: 0px }
 `;

export const SearchCloseBtnAnim = styled(FlexDiv)<{ anim: boolean }>`
  animation-name: ${(props) => (props.anim ? expendBtnAnim : collapseBtnAnim)};
  animation-duration: 400ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
`;

// export const ClickalbleFlexDiv = styled(FlexDiv)`
//   &:focus {
//     border-color: ${R.colors.blush};
//   }
// `;

// for new badge
export const RedDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #e9563b;
`;

// export const TileItemRareBorder = styled.div`
//   /* box-sizing: content-box; */
//   object-fit: contain;
//   border-radius: 10px;
//   border-style: solid;
//   border-width: 1px;
//   border-image-source: linear-gradient(143deg, #eca99c 2%, rgba(236, 169, 156, 0.3) 16%, rgba(237, 188, 135, 0) 40%, rgba(237, 188, 135, 0.5) 52%, rgba(236, 169, 156, 0) 78%, rgba(236, 169, 156, 0.8));
//   border-image-slice: 1;
//   background-image: linear-gradient(to bottom, #191919, #191919), linear-gradient(143deg, #eca99c 2%, rgba(236, 169, 156, 0.3) 16%, rgba(237, 188, 135, 0) 40%, rgba(237, 188, 135, 0.5) 52%, rgba(236, 169, 156, 0) 78%, rgba(236, 169, 156, 0.8));
//   background-origin: border-box;
//   background-clip: content-box, border-box;
// `;

// export const ListItemRareBorder = styled.div`
//   box-sizing: border-box;
//   object-fit: contain;
//   border-radius: 10px;
//   border-style: solid;
//   border-width: 1px;
//   border-image-source: linear-gradient(123deg, #eca99c 4%, rgba(236, 169, 156, 0.3) 18%, rgba(237, 188, 135, 0) 41%, rgba(237, 188, 135, 0.5) 53%, rgba(236, 169, 156, 0) 79%, rgba(236, 169, 156, 0.8) 100%);
//   border-image-slice: 1;
//   background-image: linear-gradient(to bottom, #191919, #191919), linear-gradient(123deg, #eca99c 4%, rgba(236, 169, 156, 0.3) 18%, rgba(237, 188, 135, 0) 41%, rgba(237, 188, 135, 0.5) 53%, rgba(236, 169, 156, 0) 79%, rgba(236, 169, 156, 0.8) 100%);
//   background-origin: border-box;
//   background-clip: content-box, border-box;
// `;

export const WIDTH = styled.div`
  width: ${(props: { size: number | string }) => {
    if (typeof props.size === 'number') return `${props.size}px`;
    return props.size;
  }};
`;

export const HEIGHT = styled.div`
  height: ${(props: { size: number | string }) => {
    if (typeof props.size === 'number') return `${props.size}px`;
    return props.size;
  }};
`;

export const HLINE = styled(WIDTH)`
  background-color: ${(props: { color?: string; thickness?: number }) => props.color ?? R.colors.line};
  height: ${(props: { color?: string; thickness?: number }) => {
    if (props.thickness) return `${props.thickness}px`;
    else return '1px';
  }};
`;

export const VLINE = styled(HEIGHT)`
  background-color: ${(props: { color?: string; thickness?: number }) => props.color ?? R.colors.line};
  width: ${(props: { color?: string; thickness?: number }) => {
    if (props.thickness) return `${props.thickness}px`;
    else return '1px';
  }};
`;

export const SBorderOffDiv = styled(FlexColumnDiv)`
  border-style: dashed;
  border-width: 2px;
  border-color: ${R.colors.line};
  background-color: ${R.colors.bgGrey};
  user-select: none;
`;

export const SBorderOnDiv = styled(FlexColumnDiv)`
  object-fit: contain;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(134deg, #6decd6 15%, ${R.colors.purpleishBlue} 85%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, white, white), linear-gradient(134deg, #6decd6 15%, ${R.colors.purpleishBlue} 85%);
  /* background-origin: border-box; */
  background-clip: content-box, border-box;
  user-select: none;
  overflow: hidden;
`;

export const CommonInput = styled.input`
  font-family: ${R.fonts.SpoqaJP};
  color: ${R.colors.black};
  border-width: 0px;
  border-radius: 14px;
  background-color: ${R.colors.transparent};
  -webkit-text-size-adjust: none;
  /* background-color: ${R.colors.devColor1a}; */
  ::placeholder {
    color: ${R.colors.cloudyBlue};
    font-weight: normal;
  }
  &:focus {
    outline: none;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CommonInputBold = styled.input`
  font-family: ${R.fonts.SpoqaJP};
  color: ${R.colors.black};
  border-width: 0px;
  border-radius: 14px;
  background-color: ${R.colors.transparent};
  /* background-color: ${R.colors.devColor1a}; */
  ::placeholder {
    color: ${R.colors.cloudyBlue};
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CommonSRoundOffDiv = styled(FlexColumnDiv)`
  border-style: dashed;
  border-width: 2px;
  border-color: ${R.colors.line};
  background-color: ${R.colors.bgGrey};
  user-select: none;
`;

export const CommonSRoundOnDiv = styled(FlexColumnDiv)`
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(134deg, #6decd6 15%, ${R.colors.purpleishBlue} 85%);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, white, white), linear-gradient(134deg, #6decd6 15%, ${R.colors.purpleishBlue} 85%);
  /* background-origin: border-box; */
  background-clip: content-box, border-box;
  user-select: none;
`;

export const TooltipBox = styled(FlexColumnDiv)`
  border-radius: 5px;
  border: solid 1px ${R.colors.purpleishBlue};
  background-color: ${R.colors.paleGrey3};
`;

// export const TooltipBoxContainer = ()=>{}
