/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import { CbtPropQueryType_e } from 'src/model/rpcType';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { NormalBtn } from 'src/ui/common_component/button/NormalBtn';
import { RadioBtn } from 'src/ui/common_component/button/RadioBtn';
import 'src/ui/common_component/user/calender.css';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
registerLocale('ko', ko);
registerLocale('ja', ja);

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/

type Props = {
  queryTypeTxt: {
    all: string;
    income: string;
    spend: string;
  };
  queryType?: CbtPropQueryType_e;
  onQueryType?: (selected: CbtPropQueryType_e) => void;
  start: number;
  onStart?: (start: number) => void;
  end: number;
  onEnd?: (start: number) => void;
  onQueryBtnClick?: () => void;
};

export function ProperyQuery({
  //
  queryTypeTxt,
  queryType,
  onQueryType,
  start,
  onStart,
  end,
  onEnd,
  onQueryBtnClick,
}: Props) {
  const hR = useLocalSettings();
  useEffect(() => {
    return () => {};
  }, []);

  const getCalenderLocale = () => {
    switch (hR.lang) {
      case CbtUserLanguage_e.EN:
        return 'en';
      case CbtUserLanguage_e.KO:
        return ko;
      case CbtUserLanguage_e.JA:
        return ja;
    }
  };

  const startDate = Utils.date.getDateFromYYYYMMDD(start.toString());
  const endDate = Utils.date.getDateFromYYYYMMDD(end.toString());
  DBGMSG(start.toString());
  DBGMSG(end.toString());
  if (!endDate || !startDate) return null;

  const renderDatePicker = (args: { selected: Date; onChange?: (yyyymmdd: number) => void }) => {
    return (
      <DatePicker
        selected={args.selected}
        locale={getCalenderLocale()}
        disabledKeyboardNavigation
        maxDate={Utils.date.now()}
        onChange={(date) => {
          if (!date) return;
          if (!(date instanceof Date)) {
            return;
          }
          try {
            const YYYYMMDD = Utils.date.brewYYYYMMDD(date);
            args.onChange && args.onChange(parseInt(YYYYMMDD));
          } catch (err) {
            DBGMSGW('what');
          }
        }}
        customInput={
          <FlexRowDiv style={{ alignItems: 'center', cursor: 'pointer' }}>
            <TextCon text={Utils.date.brewFomatString(args.selected, 'yyyy.MM.dd')} size={ScreenInfo.isMobile() ? 10 : 14} color={R.colors.black} />
            <WIDTH size={ScreenInfo.isMobile() ? 4 : 6} />
            <Img src={[R.images.history_ic_calendar3x, ScreenInfo.isMobile() ? 18 : 22, ScreenInfo.isMobile() ? 16 : 20]} />
          </FlexRowDiv>
        }
        popperPlacement={'bottom'}
        renderCustomHeader={({ date, prevMonthButtonDisabled, nextMonthButtonDisabled, decreaseMonth, increaseMonth }) => (
          <FlexRowDiv
            style={{
              flex: 1,
              backgroundColor: R.colors.purpleishBlue,
              justifyContent: 'space-between',
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 18,
              paddingBottom: 18,
              overflow: 'hidden',
              // borderRadius: 10,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <div
              className="btn_month btn_month-prev"
              onClick={decreaseMonth}
              // disabled={prevMonthButtonDisabled}
            >
              <ImgBtn src={[R.images.calender_left3x, 30, 30]} />
            </div>
            <TextCon text={`${getYear(date)}.${getMonth(date) + 1}`} color={R.colors.white} size={16} />
            <div
              className="btn_month btn_month-next"
              onClick={increaseMonth}
              // disabled={nextMonthButtonDisabled}
            >
              <ImgBtn src={[R.images.calender_right3x, 30, 30]} />
            </div>
          </FlexRowDiv>
        )}
      />
    );
  };

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv
        style={{
          width: 280,
        }}
      >
        <FlexRowDiv
          style={{
            width: 280,
            marginTop: 13,
            paddingLeft: 11,
            paddingRight: 11,
            justifyContent: 'space-between',
            // ...devOutlineF
          }}
        >
          {/* Radio 버튼 */}
          <FlexRowDiv
            style={{
              // ...devOutlineF,
              alignItems: 'center',
              padding: 5,
              cursor: 'pointer',
            }}
            onClick={() => {
              DBGMSG('radioBtn', CbtPropQueryType_e.All);
              onQueryType && onQueryType(CbtPropQueryType_e.All);
            }}
          >
            <RadioBtn checked={queryType === CbtPropQueryType_e.All} />
            <WIDTH size={6} />
            <TextCon text={queryTypeTxt.all} color={R.colors.black} size={10} />
          </FlexRowDiv>
          <FlexRowDiv
            style={{
              // ...devOutlineF,
              alignItems: 'center',
              padding: 5,
              cursor: 'pointer',
            }}
            onClick={() => {
              DBGMSG('radioBtn', CbtPropQueryType_e.Income);
              onQueryType && onQueryType(CbtPropQueryType_e.Income);
            }}
          >
            <RadioBtn checked={queryType === CbtPropQueryType_e.Income} />
            <WIDTH size={6} />
            <TextCon text={queryTypeTxt.income} color={R.colors.black} size={10} />
          </FlexRowDiv>
          <FlexRowDiv
            style={{
              // ...devOutlineF,
              alignItems: 'center',
              padding: 5,
              cursor: 'pointer',
            }}
            onClick={() => {
              DBGMSG('radioBtn', CbtPropQueryType_e.Spend);
              onQueryType && onQueryType(CbtPropQueryType_e.Spend);
            }}
          >
            <RadioBtn checked={queryType === CbtPropQueryType_e.Spend} />
            <WIDTH size={6} />
            <TextCon text={queryTypeTxt.spend} color={R.colors.black} size={10} />
          </FlexRowDiv>
        </FlexRowDiv>
        <HEIGHT size={13} />
        <FlexRowDiv
          style={{
            flex: 1,
            // width: ScreenInfo.contentsWidth / 3,
            height: 100,
            alignItems: 'center',
            paddingLeft: 16,
            paddingRight: 16,
            // ...devOutlineF
          }}
        >
          <FlexRowDiv
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 94,
              height: 34,
              borderRadius: 10,
              borderColor: R.colors.veryLightGrey2,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          >
            {renderDatePicker({
              selected: startDate,
              onChange: (yyyymmdd) => {
                onStart && onStart(yyyymmdd);
              },
            })}
          </FlexRowDiv>
          <WIDTH size={ScreenInfo.isMobile() ? 4 : 8} />
          <TextCon text={'~'} color={R.colors.black} size={ScreenInfo.isMobile() ? 12 : 14} />
          <WIDTH size={ScreenInfo.isMobile() ? 4 : 8} />
          <FlexRowDiv
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: 94,
              height: 34,
              borderRadius: 10,
              borderColor: R.colors.veryLightGrey2,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          >
            {renderDatePicker({
              selected: endDate,
              onChange: (yyyymmdd) => {
                onEnd && onEnd(yyyymmdd);
              },
            })}
          </FlexRowDiv>
          <ImgBtn
            src={[R.images.history_ic_search3x, 16, 16]}
            onClick={() => {
              onQueryBtnClick && onQueryBtnClick();
            }}
            containerStyle={{
              width: 34,
              height: 34,
              backgroundColor: R.colors.purpleishBlue,
              borderRadius: 10,
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 'auto',
            }}
          />
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  }

  return (
    <FlexRowDiv style={{ width: ScreenInfo.contentsWidth }}>
      {/* Radio 버튼 */}
      <FlexRowDiv
        style={{
          flex: 1,
          // width: ScreenInfo.contentsWidth / 3,
          height: 100,
          paddingLeft: 50,
          // ...devOutlineF
        }}
      >
        <FlexRowDiv
          style={{
            // ...devOutlineF,
            alignItems: 'center',
            alignSelf: 'center',
            padding: 5,
            cursor: 'pointer',
          }}
          onClick={() => {
            DBGMSG('radioBtn', CbtPropQueryType_e.All);
            onQueryType && onQueryType(CbtPropQueryType_e.All);
          }}
        >
          <RadioBtn checked={queryType === CbtPropQueryType_e.All} />
          <WIDTH size={6} />
          <TextCon text={queryTypeTxt.all} color={R.colors.black} size={14} />
        </FlexRowDiv>
        <WIDTH size={40} />
        <FlexRowDiv
          style={{
            // ...devOutlineF,
            alignItems: 'center',
            alignSelf: 'center',
            padding: 5,
            cursor: 'pointer',
          }}
          onClick={() => {
            DBGMSG('radioBtn', CbtPropQueryType_e.Income);
            onQueryType && onQueryType(CbtPropQueryType_e.Income);
          }}
        >
          <RadioBtn checked={queryType === CbtPropQueryType_e.Income} />
          <WIDTH size={6} />
          <TextCon text={queryTypeTxt.income} color={R.colors.black} size={14} />
        </FlexRowDiv>
        <WIDTH size={40} />
        <FlexRowDiv
          style={{
            // ...devOutlineF,
            alignItems: 'center',
            alignSelf: 'center',
            padding: 5,
            cursor: 'pointer',
          }}
          onClick={() => {
            DBGMSG('radioBtn', CbtPropQueryType_e.Spend);
            onQueryType && onQueryType(CbtPropQueryType_e.Spend);
          }}
        >
          <RadioBtn checked={queryType === CbtPropQueryType_e.Spend} />
          <WIDTH size={6} />
          <TextCon text={queryTypeTxt.spend} color={R.colors.black} size={14} />
        </FlexRowDiv>
      </FlexRowDiv>
      <FlexRowDiv
        style={{
          flex: 1,
          // width: ScreenInfo.contentsWidth / 3,
          height: 100,
          paddingLeft: 20,
          alignItems: 'center',
          // ...devOutlineF
        }}
      >
        <FlexRowDiv
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 135,
            height: 40,
            borderRadius: 10,
            borderColor: R.colors.veryLightGrey2,
            borderWidth: 1,
            borderStyle: 'solid',
          }}
        >
          {renderDatePicker({
            selected: startDate,
            onChange: (yyyymmdd) => {
              onStart && onStart(yyyymmdd);
            },
          })}
        </FlexRowDiv>
        <WIDTH size={8} />
        <TextCon text={'~'} color={R.colors.black} size={14} />
        <WIDTH size={8} />
        <FlexRowDiv
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 135,
            height: 40,
            borderRadius: 10,
            borderColor: R.colors.veryLightGrey2,
            borderWidth: 1,
            borderStyle: 'solid',
          }}
        >
          {renderDatePicker({
            selected: endDate,
            onChange: (yyyymmdd) => {
              onEnd && onEnd(yyyymmdd);
            },
          })}
        </FlexRowDiv>
      </FlexRowDiv>
      <FlexRowDiv
        style={{
          flex: 1,
          // width: ScreenInfo.contentsWidth / 3,
          height: 100,
          paddingRight: 50,
          alignItems: 'center',
          justifyContent: 'flex-end',
          // ...devOutlineF,
        }}
      >
        <NormalBtn
          text={hR.strings.PROP_HISTORY_QUERY_BTN}
          ic={R.images.history_ic_search3x}
          onClick={() => {
            onQueryBtnClick && onQueryBtnClick();
          }}
        />
      </FlexRowDiv>
    </FlexRowDiv>
  );
}
