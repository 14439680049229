/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import { config, useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import { AnimatedFlexColumnDiv, devOutlineF, FlexDiv, HEIGHT } from 'src/ui/base_component/etc';
import { SAddBtn } from 'src/ui/common_component/button/SAddBtn';
import { DBGMSG } from 'src/util/utils';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { PropsCommonBox, SPRING_PROPS_ANIM_1_Default, SPRING_PROPS_ANIM_1_OnMouseEnter, SPRING_PROPS_ANIM_1_OnMouseLeave } from 'src/ui/layout_constant';
import { AST_CARD_PADDING_H_FOR_LIST } from 'src/ui/common_component/AssetCardForList';
import { ASSET_CARD_HEIGHT, ASSET_CARD_WIDTH } from 'src/ui/common_component/AssetCard';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  isAnim?: boolean;
  onClick: () => void;
};

/**************************************
 * !! constant
 **************************************/
export const ASSET_INNER_BOX_WIDTH = 236;
export const ASSET_INNER_BOX_HEIGHT = 236;
export const ASSET_INNER_BOX_RADIUS = 10;

/**************************************
 * !! styled component
 **************************************/

export function AssetGroupCardNew({ onClick, isAnim = false }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseEnter, setMouseEnter] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  // const isActive = curMenu === menu;

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  DBGMSG('render');
  return (
    <FlexDiv
      style={{
        padding: AST_CARD_PADDING_H_FOR_LIST,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: R.colors.devColor1a,
      }}
    >
      <AnimatedFlexColumnDiv
        style={{
          width: ASSET_CARD_WIDTH,
          height: ASSET_CARD_HEIGHT,
          cursor: 'pointer',
          alignItems: 'center',
          ...PropsCommonBox,
          ...hMouseProps,
          // ...devOutlineF,
        }}
        onMouseEnter={() => {
          // DBGMSG(`onMouseEnter: `);
          isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
          setMouseEnter(true);
        }}
        onMouseLeave={() => {
          // DBGMSG(`onMouseLeave: `);
          isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
          setMouseEnter(false);
        }}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <HEIGHT size={64} />
        <SAddBtn
          width={ASSET_INNER_BOX_WIDTH}
          height={ASSET_INNER_BOX_HEIGHT}
          radius={ASSET_INNER_BOX_RADIUS}
          onClick={() => {
            DBGMSG('new schema');
          }}
          forceEffect={sMouseEnter}
        />
        <HEIGHT size={36} />
        <TextCon text={hR.strings.AC_SCHEMA_READ_NEW_ASSET} isBold color={R.colors.black} />
      </AnimatedFlexColumnDiv>
    </FlexDiv>
  );
}
