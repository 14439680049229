/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtPropTypeDetail_e } from 'src/model/rpcType';

export function usePropInfo() {
  const hR = useLocalSettings();
  return {
    getTypeTxt: (typeDetail: CbtPropTypeDetail_e) => {
      switch (typeDetail) {
        case CbtPropTypeDetail_e.T101:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_101;
        case CbtPropTypeDetail_e.T102:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_102;
        case CbtPropTypeDetail_e.T103:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_103;
        case CbtPropTypeDetail_e.T201:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_201;
        case CbtPropTypeDetail_e.T202:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_202;
        case CbtPropTypeDetail_e.T203:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_203;
        case CbtPropTypeDetail_e.T204:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_204;
        case CbtPropTypeDetail_e.T205:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_205;
        case CbtPropTypeDetail_e.T301:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_301;
        case CbtPropTypeDetail_e.T302:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_302;
        case CbtPropTypeDetail_e.T303:
          return hR.strings.PROP_HISTORY_TOKENDATA_TYPE_303;
      }
    },
  };
}
