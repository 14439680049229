/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 * rpc
 */

import { createAction, createReducer } from '@reduxjs/toolkit';
import { CmnTxReqInfo_i } from 'src/model/rpcModel';
import { PADThunkAction } from 'src/redux/store';
import { rpcWithTxReqInfo } from 'src/util/rpcUtil';
import { DBGMSG, Utils } from 'src/util/utils';

export let rpcID = 0;
export enum RpcStatus {
  InProgress = 'RpcStatus_InProgress',
  Success = 'RpcStatus_Success',
  Failure = 'RpcStatus_Failure',
  FailureTimeout = 'RpcStatus_FailureTimeout',
  FailureConnection = 'RpcStatus_FailureConnection',
}
export type RpcInfo = {
  rpcID?: number;
  rpcStatus?: RpcStatus;
  reqTime?: Date;
  reqPath?: string;
  reqParam?: any;
  response?: any;
  connectionFailTime?: Date;
  token?: string;
};

/****************************************/
// state
/****************************************/
type RpcState = {
  rpcInfoList?: RpcInfo[];
  pendingCnt: number;
  reqCnt: number;
  resCnt: number;
};
const initialState: RpcState = {
  rpcInfoList: [],
  pendingCnt: 0,
  reqCnt: 0,
  resCnt: 0,
};

/****************************************/
// action
/****************************************/
const types = {
  UPDATE_STATE: 'rpc/UPDATE_STATE',
  ADD_RPC_INFO_ACT: 'rpc/ADD_RPC_INFO_ACT',
  UPDATE_RPC_INFO_ACT: 'rpc/UPDATE_RPC_INFO_ACT',
  REMOVE_RPC_INFO_ACT: 'rpc/REMOVE_RPC_INFO_ACT',
  REMOVE_ALL_RPC_INFO_ACT: 'rpc/REMOVE_ALL_RPC_INFO_ACT',
};
const actions = {
  updateAct: createAction<{ rpcState: RpcState }>(types.UPDATE_STATE),
  reqRpcInfoAct: createAction<{ rpcInfo: RpcInfo }>(types.ADD_RPC_INFO_ACT),
  resRpcInfoAct: createAction<{ rpcInfo: RpcInfo }>(types.UPDATE_RPC_INFO_ACT),
  removeRpcInfoAct: createAction<{ rpcInfoId: number }>(types.REMOVE_RPC_INFO_ACT),
  removeAllRpcInfoAct: createAction(types.REMOVE_ALL_RPC_INFO_ACT),
};

/****************************************/
// reducer
/****************************************/
const reducer = createReducer<RpcState>(initialState, (builder) => {
  builder
    .addCase(actions.updateAct, (state, action) => {
      state.rpcInfoList = action.payload.rpcState.rpcInfoList;
    })
    .addCase(actions.reqRpcInfoAct, (state, action) => {
      state.rpcInfoList?.push(action.payload.rpcInfo);
      state.reqCnt = state.reqCnt + 1;
      state.pendingCnt = state.pendingCnt + 1;
      // if (state.rpcInfoList && state.rpcInfoList?.length > 30) {
      //   state.rpcInfoList = [];
      // }
    })
    .addCase(actions.resRpcInfoAct, (state, action) => {
      if (state.rpcInfoList) {
        const foundRpcInfoIdx = state.rpcInfoList.findIndex((rpcInfo) => rpcInfo.rpcID === action.payload.rpcInfo.rpcID);
        if (foundRpcInfoIdx !== -1) {
          state.rpcInfoList[foundRpcInfoIdx] = {
            ...state.rpcInfoList[foundRpcInfoIdx],
            ...action.payload.rpcInfo,
          };
          state.resCnt = state.resCnt + 1;
          state.pendingCnt = state.pendingCnt - 1;
        } else {
          console.warn('!!!! not found');
        }
      } else {
        console.warn('!!!! empty');
      }
    })
    .addCase(actions.removeRpcInfoAct, (state, action) => {
      if (state.rpcInfoList) {
        const removed = state.rpcInfoList.filter((rpcInfo) => rpcInfo.rpcID !== action.payload.rpcInfoId);
        state.rpcInfoList = removed;
      } else {
        console.warn('!!!! empty');
      }
    })
    .addCase(actions.removeAllRpcInfoAct, (state, action) => {
      if (state.rpcInfoList) {
        state.rpcInfoList = [];
      } else {
        console.warn('!!!! empty');
      }
    });
});

const rpcModule = { reducer, types, actions };
export default rpcModule;

/****************************************/
// thunk action 생성 함수 정의
/****************************************/
// export const rpcObjThunk = (rpcObj: BaseRpc & { token?: string }): PADThunkAction => async dispatch => {
//   DBGMSG(`$$$$rpcThunk path:${rpcObj.path}`);
//   // DBGMSG(`$$$$rpcThunk param:${JSON.stringify(param || {}, undefined, 2)}`); // Req 파람정보가 없어도 기본값 {}

//   // REQUEST ID 생성
//   rpcID++;
//   DBGMSG(`$$$$rpcThunk reqID:${rpcID}`);

//   const curRpcID = rpcID;

//   let rpcResInfo: RpcInfo = {
//     rpcID: curRpcID,
//     rpcStatus: RpcStatus.InProgress,
//     reqTime: new Date(),
//     reqPath: rpcObj.path,
//     reqParam: rpcObj.tx,
//   };

//   try {
//     dispatch(actions.addRpcInfoAct({ rpcInfo: rpcResInfo }));

//     DBGMSG(`$$$$rpcThunk RpcStatus.IN_PROGRESS`);

//     const res = await rpcWithObj({ ...rpcObj });
//     DBGMSG(`$$$$rpcThunk rpcWithParam wait done`);

//     // dev 강제 delay
//     // await Utils.sleep.mssleep(3000);

//     rpcResInfo = {
//       rpcID: curRpcID,
//       rpcStatus: RpcStatus.Success,
//       response: res,
//     };
//     dispatch(actions.updateRpcInfoAct({ rpcInfo: rpcResInfo }));
//     DBGMSG(`$$$$rpcThunk RpcStatus.SUCCESS`);

//     return res.data;
//   } catch (err) {
//     rpcResInfo = {
//       rpcID: curRpcID,
//       rpcStatus: RpcStatus.Failure,
//       response: err,
//     };
//     dispatch(actions.updateRpcInfoAct({ rpcInfo: rpcResInfo }));
//     DBGMSG(`$$$$rpcThunk RpcStatus.FAILURE`);
//     return Promise.reject(err);
//   } finally {
//     DBGMSG(`$$$$rpcThunk RpcStatus.DONE`);
//   }
// };
export const rpcThunk =
  (args: CmnTxReqInfo_i): PADThunkAction =>
  async (dispatch) => {
    DBGMSG(`$$$$rpcThunk path:${args.url}`);
    // DBGMSG(`$$$$rpcThunk param:${JSON.stringify(param || {}, undefined, 2)}`); // Req 파람정보가 없어도 기본값 {}

    // REQUEST ID 생성
    rpcID++;
    DBGMSG(`$$$$rpcThunk reqID:${rpcID}`);

    const curRpcID = rpcID;

    let rpcResInfo: RpcInfo = {
      rpcID: curRpcID,
      rpcStatus: RpcStatus.InProgress,
      reqTime: new Date(),
      reqPath: args.url,
      reqParam: args.tx,
      token: args.token,
    };

    try {
      dispatch(actions.reqRpcInfoAct({ rpcInfo: rpcResInfo }));

      DBGMSG(`$$$$rpcThunk RpcStatus.IN_PROGRESS`);

      const res = await rpcWithTxReqInfo(args);
      DBGMSG(`$$$$rpcThunk rpcWithParam wait done`);

      // dev 강제 delay
      // await Utils.sleep.mssleep(10000);

      rpcResInfo = {
        rpcID: curRpcID,
        rpcStatus: RpcStatus.Success,
        response: res,
      };
      dispatch(actions.resRpcInfoAct({ rpcInfo: rpcResInfo }));
      DBGMSG(`$$$$rpcThunk RpcStatus.SUCCESS`);

      if (args.postRun) {
        DBGMSG(`$$$$rpcThunk postProcedure call`);
        return args.postRun(res.data);
      }

      return res.data;
    } catch (err) {
      rpcResInfo = {
        rpcID: curRpcID,
        rpcStatus: RpcStatus.Failure,
        response: err,
      };
      dispatch(actions.resRpcInfoAct({ rpcInfo: rpcResInfo }));
      DBGMSG(`$$$$rpcThunk RpcStatus.FAILURE`);
      return Promise.reject(err);
    } finally {
      DBGMSG(`$$$$rpcThunk RpcStatus.DONE`);
    }
  };
