/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import ScreenInfo, { MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useSubscribeStompMsg } from 'src/hooks/useSubscribeStompMsg';
import { RPC_ASSET_CREATOR_SCHEMA_VIEW } from 'src/model/rpcType';
import { CbtAssetGroupCardInfo_i } from 'src/model/rpcModel';
import R from 'src/res/R';
import { CbtStompMsgCode_e, messageService } from 'src/rx/messageService';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { AST_CARD_CONTAINER_HEIGHT_FOR_LIST, AST_CARD_CONTAINER_WIDTH_FOR_LIST } from 'src/ui/common_component/AssetCardForList';
import { AssetGroupCardForList } from 'src/ui/common_component/AssetGroupCardForList';
import { AssetGroupCardNew, ASSET_INNER_BOX_HEIGHT, ASSET_INNER_BOX_RADIUS, ASSET_INNER_BOX_WIDTH } from 'src/ui/common_component/AssetGroupCardNew';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { SAddBtn } from 'src/ui/common_component/button/SAddBtn';
import { CommonFooter, COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { SchemaCard } from 'src/ui/common_component/SchemaCard';
import { Title } from 'src/ui/common_component/Title';
import { CLAY, CommonColumnBox, COMMON_CARD_COLUMN_CNT} from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/
enum AssetGroupCardType_e {
  NewAssetGroupCard,
}

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MySchemaRScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/

  const [sFetchedData, setFetchedData] = useState<RPC_ASSET_CREATOR_SCHEMA_VIEW.Rx>();
  const [sAssetGroupList, setAssetGroupList] = useState<(CbtAssetGroupCardInfo_i | AssetGroupCardType_e)[]>([]);

  const [sNextPageStatus, setNextPageStatus] = useState<string>('');

  /**************************************
   * !! ref
   **************************************/
  const fetchedDataRef = useRef<RPC_ASSET_CREATOR_SCHEMA_VIEW.Rx>();
  const nextPageLoadingRef = useRef(false);
  const qsRef = useRef<{ sid: number }>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  // useCheckUserCertTpAndBlock();

  useSubscribeStompMsg({
    onMsg: (stompMsg) => {
      DBGMSG(`onMsg`);
      if (!qsRef.current) return;
      fetchData(qsRef.current.sid);
      return;
    },
    targetCodeList: [
      CbtStompMsgCode_e.ASSET_CREATE_FAIL,
      CbtStompMsgCode_e.ASSET_CREATE_SUCCESS,
      CbtStompMsgCode_e.ASSET_SCHEMA_EDIT_SUCCESS,
      CbtStompMsgCode_e.ASSET_SCHEMA_EDIT_FAIL,
      CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS,
      CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_FAIL,
    ],
  });

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid) {
      DBGMSG(`$$$$QS id: ${query.sid}`);
      const sid = parseInt(query.sid);
      qsRef.current = { sid };
      fetchData(sid);
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async (schemaId: number) => {
    const res = await hRpc.cobaltRpc.rpcAssetCreatorSchemaView({ sid: schemaId });
    setFetchedData(res);
    fetchedDataRef.current = res;

    if (res.astg_list.length === 0) setAssetGroupList([]);
    else setAssetGroupList([AssetGroupCardType_e.NewAssetGroupCard, ...res.astg_list]);
  };

  const getLastRowId = () => {
    if (!fetchedDataRef.current) return;
    if (!fetchedDataRef.current.pg_info) return;
    return fetchedDataRef.current.pg_info.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  // 다음페이지 요청
  const fetchItemListMore = async () => {
    if (!fetchedDataRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'sFetcheAstListDataRef is undefined');
      return Promise.reject();
    }

    if (sAssetGroupList.length === 0) {
      DBGMSG('$$$$PAGE_SCROLL', 'sCollectionList.length === 0');
      return Promise.reject();
    }

    if (!qsRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'qsRef.current is undefined');
      return;
    }

    nextPageLoadingRef.current = true;

    const pg_info = fetchedDataRef.current.pg_info;

    try {
      setNextPageStatus('loading');
      const res = await hRpc.cobaltRpc.rpcAssetCreatorSchemaView({ sid: qsRef.current.sid, pg_info });
      setFetchedData(res);
      setAssetGroupList([...sAssetGroupList, ...res.astg_list]);
      setNextPageStatus('');
      nextPageLoadingRef.current = false;
      fetchedDataRef.current = res;
    } catch (err) {
      setNextPageStatus('loading fail');
      nextPageLoadingRef.current = false;
    }
  };

  // 스크롤 리스너
  const scrollListener = (e: ScrollParams) => {
    // DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (!fetchedDataRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'sFetcheAstListDataRef is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;
    // DBGMSG('$$$$PAGE_SCROLL', `clientHeight: ${clientHeight}, scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}`);
    // DBGMSG('$$$$PAGE_SCROLL', `${scrollTop + clientHeight} >= ${scrollHeight - COMMON_FOONTER_HEIGHT}`);
    // DBGMSG('$$$$PAGE_SCROLL', `atBottom: ${atBottom} `);

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return triggeredRef.current is true `);
      return;
    }

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    if (atBottom) {
      fetchItemListMore()
        .then(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage then');
        })
        .catch(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage catch');
        });
    }
  };

  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  /**************************************
   * !! render function
   **************************************/
  const cardRender = (item: CbtAssetGroupCardInfo_i | AssetGroupCardType_e) => {
    if (item === AssetGroupCardType_e.NewAssetGroupCard)
      return (
        <AssetGroupCardNew
          isAnim
          onClick={() => {
            sFetchedData && hHistory.push(Nav.brewAssetGroupCScreenPath({ sid: qsRef.current!.sid }));
          }}
        />
      );
    else {
      return (
        <AssetGroupCardForList
          assetGroupInfo={item}
          assetType={sFetchedData!.schm_info.asset_type}
          isAnim
          onClick={() => {
            DBGMSG('AssetCardForList onClick!!');
            const entity_id = (item as CbtAssetGroupCardInfo_i).entity_id;
            const sid = sFetchedData!.schm_info.sid;
            DBGMSG(`entity_id: ${entity_id}`);
            hHistory.push(Nav.brewAssetGroupListScreenPath({ sid, entity_id }));
          }}
        />
      );
    }
  };

  // 아이템 렌더링
  const cellRenderer = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    let index: number;
    let item: CbtAssetGroupCardInfo_i | AssetGroupCardType_e;
    index = rowIndex * COMMON_CARD_COLUMN_CNT + columnIndex;
    item = sAssetGroupList[index];

    if (index >= sAssetGroupList.length) return null;

    // DBGMSG(`typeof item: ${typeof item}`);
    if (ScreenInfo.isMobile()) {
      return (
        // 중요!
        <div key={key} style={style}>
          <MobileScalableFlexColumn>{cardRender(item)}</MobileScalableFlexColumn>
        </div>
      );
    } else {
      return (
        // 중요!
        <div key={key} style={style}>
          {cardRender(item)}
        </div>
      );
    }
  };

  // Asset Group 목록 렌더링
  const renderAssetGroupList = () => {
    DBGMSG('renderList');
    return (
      <FlexColumnDiv
        style={{
          width: AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT,
          alignSelf: 'center',
          userSelect: 'none',
          // ...devOutlineF,
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <FlexColumnDiv>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <Grid
                      onScroll={(params) => {
                        onChildScroll(params);
                        throttleScrollListener(params);
                      }}
                      isScrolling={isScrolling}
                      autoHeight
                      cellRenderer={cellRenderer}
                      columnWidth={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale}
                      columnCount={COMMON_CARD_COLUMN_CNT}
                      height={height}
                      rowHeight={AST_CARD_CONTAINER_HEIGHT_FOR_LIST * ScreenInfo.scale}
                      rowCount={Math.ceil(sAssetGroupList.length / COMMON_CARD_COLUMN_CNT)}
                      scrollTop={scrollTop}
                      width={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT}
                    />
                  </div>
                )}
              </AutoSizer>
            </FlexColumnDiv>
          )}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (!sFetchedData) return <DataFetching />;

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <FlexColumnDiv
            style={{
              backgroundColor: R.colors.bgGrey,
              width: MOBILE_WIDTH,
              alignItems: 'flex-start',
              paddingTop: 20,
              paddingLeft: CLAY.HPADDING,
              paddingRight: CLAY.HPADDING,
              // ...devOutlineF,
            }}
          >
            {/* Back 버튼 */}
            <BackBtn />

            {/* 타이틀 */}
            <Title title={`${hR.strings.AC_SCHEMA_READ_TITLE} - ${sFetchedData.schm_info.dp_name}`} />

            <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

            {/* 스키마 카드 */}
            <SchemaCard schemaInfo={sFetchedData.schm_info} />

            <HEIGHT size={12} />

            {/* 속성 리스트 */}
            {sFetchedData.schm_info.attributes.map((att, idx) => {
              const typeTxt: string = hR.strings.getAttributeTypeText(att.type);
              const typeDetailTxt: string = hR.strings.getAttributeTypeDetailText(att.type);

              return (
                <>
                  <FlexRowDiv key={idx} style={{ alignItems: 'stretch' }}>
                    <CommonColumnBox style={{ width: 135, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={12} color={R.colors.black} isBold text={Utils.svc.convertIfReqAttrName(att.name)} />
                    </CommonColumnBox>
                    <WIDTH size={10} />
                    <CommonColumnBox style={{ width: 135, height: 60, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={12} color={R.colors.black} isBold text={typeTxt} containerStyle={{ textAlign: 'center' }} />
                      <TextCon size={10} color={R.colors.black} isBold text={typeDetailTxt} containerStyle={{ textAlign: 'center' }} />
                    </CommonColumnBox>
                  </FlexRowDiv>
                  <HEIGHT size={10} />
                </>
              );
            })}

            {/* 편집하기 버튼 */}
            <RoundTxtBtn
              width={280}
              height={44}
              fontSize={12}
              text={hR.strings.AC_SCHEMA_READ_EDIT_BTN}
              containerStyle={{ alignSelf: 'center' }}
              onClick={() => {
                DBGMSG('edit');
                hHistory.push(Nav.brewSchemaUScreenPath({ sid: sFetchedData.schm_info.sid }));
              }}
            />

            {/* 에셋 */}
            <HEIGHT size={20} />
            <Title title={hR.strings.AC_SCHEMA_READ_ASSET} />
          </FlexColumnDiv>
        </MobileScalableFlexColumn>

        {/* 이 스미카를 사용하는 에셋 그룹 리스트 */}
        {sAssetGroupList.length === 0 ? (
          <MobileScalableFlexColumn>
            <HEIGHT size={20} />
            <FlexColumnDiv
              style={{ width: MOBILE_WIDTH, paddingLeft: CLAY.HPADDING, paddingRight: CLAY.HPADDING }}
            >
              <CommonColumnBox
                style={{
                  alignSelf: 'stretch',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 40,
                  paddingBottom: 40,

                  // ...devOutlineF,
                }}
              >
                <SAddBtn
                  width={248}
                  height={248}
                  radius={ASSET_INNER_BOX_RADIUS}
                  onClick={() => {
                    DBGMSG('new asset');
                    hHistory.push(Nav.brewAssetGroupCScreenPath({ sid: sFetchedData.schm_info.sid }));
                  }}
                />
                <HEIGHT size={17} />
                <TextCon
                  size={10}
                  color={R.colors.black}
                  isBold
                  text={hR.strings.AC_SCHEMA_READ_NEW_ASSET_DESC}
                  containerStyle={{ width: 190, textAlign: 'center' }}
                />
              </CommonColumnBox>
            </FlexColumnDiv>
          </MobileScalableFlexColumn>
        ) : (
          renderAssetGroupList()
        )}
        <HEIGHT size={60} />
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      </>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          ...devOutline,
        }}
      >
        <FlexRowDiv
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            DBGMSG('back');
            hHistory.goBack();
          }}
        >
          <Img src={[R.images.common_ic_arrow_back3x, 20, 20]} />
          <TextCon text={hR.strings.AC_SCHEMA_READ_BACK} size={14} color={R.colors.black} isBold />
        </FlexRowDiv>

        <HEIGHT size={30} />
        <FlexRowDiv style={{ alignSelf: 'stretch', ...devOutline }}>
          <FlexColumnDiv style={{ ...devOutline }}>
            {/* Schema 카드 */}
            <SchemaCard schemaInfo={sFetchedData.schm_info} />
            <HEIGHT size={30} />

            {/* 편집하기 버튼 */}
            <RoundTxtBtn
              width={276}
              height={48}
              fontSize={14}
              text={hR.strings.AC_SCHEMA_READ_EDIT_BTN}
              containerStyle={{ alignSelf: 'center' }}
              onClick={() => {
                DBGMSG('create');
                hHistory.push(Nav.brewSchemaUScreenPath({ sid: sFetchedData.schm_info.sid }));
              }}
            />
          </FlexColumnDiv>
          <WIDTH size={30} />
          <FlexColumnDiv style={{ flex: 1, ...devOutline }}>
            <FlexColumnDiv style={{ paddingLeft: 10 }}>
              {/* schema 이름 */}
              <Title title={`${hR.strings.AC_SCHEMA_READ_TITLE} - ${sFetchedData.schm_info.dp_name}`} />
            </FlexColumnDiv>
            <HEIGHT size={20} />
            <FlexRowDiv style={{ alignItems: 'stretch' }}>
              <TextCon
                size={16}
                color={R.colors.blueGrey}
                isBold
                text={hR.strings.AC_SCHEMA_READ_ATTR_NAME}
                containerStyle={{ flex: 1, paddingLeft: 10 }}
              />
              <WIDTH size={30} />
              <TextCon
                size={16}
                color={R.colors.blueGrey}
                isBold
                text={hR.strings.AC_SCHEMA_READ_ATTR_TYPE}
                containerStyle={{ flex: 1, paddingLeft: 10 }}
              />
            </FlexRowDiv>
            <HEIGHT size={24} />

            {/* 속성 리스트 */}
            {sFetchedData.schm_info.attributes.map((att, idx) => {
              const typeTxt: string = hR.strings.getAttributeTypeText(att.type);
              const typeDetailTxt: string = hR.strings.getAttributeTypeDetailText(att.type);

              return (
                <>
                  <FlexRowDiv key={idx} style={{ alignItems: 'stretch' }}>
                    <CommonColumnBox style={{ flex: 1, height: 68, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={16} color={R.colors.black} isBold text={Utils.svc.convertIfReqAttrName(att.name)} />
                    </CommonColumnBox>
                    <WIDTH size={30} />
                    <CommonColumnBox style={{ flex: 1, height: 68, justifyContent: 'center', alignItems: 'center' }}>
                      <TextCon size={16} color={R.colors.black} isBold text={`${typeTxt}${typeDetailTxt}`} />
                    </CommonColumnBox>
                  </FlexRowDiv>
                  <HEIGHT size={10} />
                </>
              );
            })}
          </FlexColumnDiv>
        </FlexRowDiv>

        <HEIGHT size={80} />
        <Title title={hR.strings.AC_SCHEMA_READ_ASSET} />
        <HEIGHT size={15} />

        {/* 이 스미카를 사용하는 에셋 리스트 */}
        {sAssetGroupList.length === 0 ? (
          <>
            <HEIGHT size={15} />
            <CommonColumnBox
              style={{
                alignSelf: 'stretch',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 40,
                paddingBottom: 40,
                // ...devOutlineF,
              }}
            >
              <SAddBtn
                width={ASSET_INNER_BOX_WIDTH}
                height={ASSET_INNER_BOX_HEIGHT}
                radius={ASSET_INNER_BOX_RADIUS}
                onClick={() => {
                  DBGMSG('new asset');
                  hHistory.push(Nav.brewAssetGroupCScreenPath({ sid: sFetchedData.schm_info.sid }));
                }}
              />
              <HEIGHT size={24} />
              <TextCon size={14} color={R.colors.black} isBold text={hR.strings.AC_SCHEMA_READ_NEW_ASSET_DESC} containerStyle={{ marginBottom: 2 }} />
            </CommonColumnBox>
          </>
        ) : (
          renderAssetGroupList()
        )}
        <HEIGHT size={120} />
      </FlexColumnDiv>
    );
}
