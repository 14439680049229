/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useDispatch, useSelector } from 'react-redux';
import { AppConf, isAlpha, isProd } from 'src/conf/AppConf';
import { useDlg } from 'src/hooks/useDlg';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtCurrency_e, CbtUserLanguage_e } from 'src/model/rpcModel';
import { RootState } from 'src/redux/modules';
import kaikasSlice, { KaikasDev_i, KaikasUser_i } from 'src/redux/modules/kaikasModule';
import Constants, { KaikasPromiseReject_e } from 'src/res/constants';
import { DBGMSG, DBGMSGW } from 'src/util/utils';

export enum CbtSmartContractStatus {
  // UNKNOWN = 0,
  SUCCESS = 1,
  // FAILURE_ETC = 10,
  // FAILURE_BALANCE = 11,
}

// ref https://ko.docs.klaytn.com/bapp/developer-tools/kaikas
// 다운로드 링크 제공
// ------ https://ko.docs.klaytn.com/bapp/developer-tools/kaikas (한글)
// ------ https://docs.kaikas.io/
// 개발 참조 : https://docs.kaikas.io/

export function useKaikas() {
  // Login?
  const sKaikasState = useSelector((state: RootState) => state.kaikasState);
  const dispatch = useDispatch();
  const hR = useLocalSettings();

  // user 정보 state에 저장
  const _updateKaikasUserState = (kaikasUser: KaikasUser_i) => {
    dispatch(kaikasSlice.actions.setKaikasUser(kaikasUser));
  };

  // dev 정보 state에 저장
  const _updateKaikasDevState = (kaikasDev: KaikasDev_i) => {
    dispatch(kaikasSlice.actions.setKaikasDev(kaikasDev));
  };

  // klaytn, caver 정보 state에 저장
  const _updateKlaytnCaverObjState = (klaytn: any, caver: any) => {
    dispatch(kaikasSlice.actions.setKlaytnCaverObj({ klaytn, caver }));
  };

  // https://docs.kaikas.io/01_getting_started/02_quick_start#caver-browser-detection
  // kaikas object를 이용할수 있는지 체크함
  const isAvailableKlaytnObj = () => {
    // Caver Browser Detection
    const klaytn = (window as any).klaytn;
    if (typeof klaytn === 'undefined') {
      DBGMSGW(`$$$$KAIKAS window.klaytn === 'undefined'`);
      return false;
    }

    // Detecting Kaikas
    // klaytn object 가 kaikas인지 체크
    if (!klaytn.isKaikas) {
      DBGMSGW(`$$$$KAIKAS klaytn.isKaikas === ${klaytn.isKaikas}`);
      return false;
    }

    // DBGMSG(`$$$$KAIKAS klaytn is available`);
    return true;
  };

  // caver object가 존재하는 체크함
  const isAvailableCaverObj = () => {
    if (typeof (window as any).caver !== 'undefined') {
      // DBGMSG(`$$$$KAIKAS (window as any).caver: ${(window as any).caver}`);
      DBGMSG(`$$$$KAIKAS caver is available`);
      return true;
    } else {
      // DBGMSG(`$$$$KAIKAS (window as any).caver: ${(window as any).caver}`);
      return false;
    }
  };

  const isAvailable = () => {
    if (isAvailableKlaytnObj() && isAvailableCaverObj()) {
      return true;
    } else {
      return false;
    }
  };

  const updateAll = () => {
    DBGMSG(`$$$$KAIKAS updateAll`);
    updateKlaytnCaverObj();
    updateKaikasUser();
    updateKaikasDev().then().catch();
  };

  const getKlaytnObj = () => {
    if (!isAvailableKlaytnObj()) return;
    // return (window as any).klaytn;
    return (window as any).klaytn;
  };

  const getCaverObj = () => {
    if (!isAvailableCaverObj()) return;
    return (window as any).caver;
  };

  // ref - https://docs.kaikas.io/01_getting_started/02_quick_start#connecting-to-kaikas
  // Connecting to Kaikas
  const connectToKaikas = async (
    onAccountsChanged: ((accountsChanged: string) => void) | undefined,
    onNetworkChanged: ((networkChanged: number) => void) | undefined
  ) => {
    DBGMSG(`$$$$KAIKAS connectToKaikas`);

    const klaytnObj = getKlaytnObj();
    if (klaytnObj === undefined) return Promise.reject(KaikasPromiseReject_e.KaikasNotFound);

    // ***************************************
    // baobab 네트워크
    // ***************************************
    if (isAlpha()) {
      if (getNetworkVersion() === 'BAOBAB') {
        // return true;
      } else {
        return Promise.reject(KaikasPromiseReject_e.MAINET_NETWORK_IS_NOT_ALLOWED);
      }
    } else if (isProd()) {
      if (getNetworkVersion() === 'MAINNET') {
        // return true;
      } else {
        return Promise.reject(KaikasPromiseReject_e.BAOBAB_NETWORK_IS_NOT_ALLOWED);
      }
    } else {
      return Promise.reject();
    }

    DBGMSG(`$$$$KAIKAS call sKaikasState.klaytn.enable()`);
    // Connecting" or "logging in" to Kaikas means you can get access to user's Klaytn accounts.
    const accounts = await klaytnObj.enable();
    // This promise-returning function resolves with an array of hex-prefixed Klaytn addresses,
    // which can be used as general account references when sending transactions.

    DBGMSG(`$$$$KAIKAS klatyn.enable() ret`, accounts);
    DBGMSG(`$$$$KAIKAS klatyn.enable() ret accounts.length: ${accounts.length}`);
    DBGMSG(`$$$$KAIKAS klatyn.enable() ret accounts[0]: ${accounts[0]}`);
    DBGMSG(`$$$$KAIKAS klaytnObj.selectedAddress ${klaytnObj.selectedAddress}`);

    if (accounts.length < 0) {
      DBGMSGW(`$$$$KAIKAS what`);
      DBGMSGW(`$$$$KAIKAS accounts.length < 0`);
      return Promise.reject('$$$$KAIKAS accounts.length<0');
    }

    // selectedAddress 업데이트 되어야함
    // _updateKaikasUserState({ selectedAddress: accounts[0], networkVersion: klaytnObj.networkVersion });
    DBGMSG('$$$$KAIKAS updateAll()');
    updateAll();

    // 메인넷 변경시 이벤트 리스너
    klaytnObj.on('networkChanged', async function (e: any) {
      DBGMSG(`$$$$KAIKAS networkChanged e: ${e}`);
      DBGMSG(e);
      // klaytn.networkVersion
      // 8217 : mainnet
      // 1001 : baobab
    });

    // 계정 변경시 이벤트 리스너
    klaytnObj.on('accountsChanged', async function (accounts: any) {
      DBGMSG(`$$$$KAIKAS accountsChanged accounts: ${accounts[0]}`);
      DBGMSG(accounts);
      DBGMSG(typeof accounts);
      // updateKaikasProps();
      // updateKaikas();

      if (sKaikasState.user && sKaikasState.user.selectedAddress === accounts[0]) {
        DBGMSGW('기존계정과 동일');
      } else {
        DBGMSGW('주소가 변경되었습니다.');
        _updateKaikasUserState({ selectedAddress: accounts[0], networkVersion: klaytnObj.networkVersion });
        // onAccountsChanged(accounts[0]);
      }
    });

    dispatch(kaikasSlice.actions.setEnable());

    return accounts[0] as string;
  };

  const disConnectKaikas = async () => {
    DBGMSG(`$$$$KAIKAS disConnectKaikas`);
    if (!isAvailableKlaytnObj()) {
      return;
    }

    if (isConnectedKaikas()) dispatch(kaikasSlice.actions.clear());
  };

  const isConnectedKaikas = () => {
    // if (klaytnObj === undefined) return;

    // if (klaytnObj && sKaikasState.caver && sKaikasState.user && sKaikasState.user.selectedAddress) {
    //   return true;
    // } else return false;
    if (!sKaikasState.isEnable) return;
    const klaytnObj = getKlaytnObj();
    if (klaytnObj === undefined) return;
    if (klaytnObj.selectedAddress === undefined) return;

    return sKaikasState.isEnable;
  };

  const isAlreadyConnected = () => {
    DBGMSG(`$$$$KAIKAS isAutoKaikasConnectNeed`);

    if (sKaikasState.isEnable) {
      DBGMSGW(`$$$$KAIKAS already enabled`);
      return false;
    }

    const klaytnObj = getKlaytnObj();
    if (klaytnObj === undefined) return;

    if (!klaytnObj) {
      DBGMSGW(`$$$$KAIKAS !klaytnObj`);
      return false;
    }

    if (!klaytnObj.selectedAddress) {
      DBGMSGW(`$$$$KAIKAS !klaytnObj.selectedAddress`);
      return false;
    }

    if (!klaytnObj._kaikas.isEnabled()) {
      DBGMSGW(`$$$$KAIKAS !klaytnObj._kaikas.isEnabled()`);
      return false;
    }

    return true;
  };

  const updateKlaytnCaverObj = () => {
    DBGMSG(`$$$$KAIKAS updateKlaytnCaverObj`);
    const klaytnObj = getKlaytnObj();
    const caverObj = getCaverObj();

    if (klaytnObj === undefined || caverObj === undefined) {
      return;
    }

    DBGMSG(`$$$$KAIKAS klaytn object`);
    DBGMSG(klaytnObj);
    DBGMSG(`$$$$KAIKAS caver object`);
    DBGMSG(caverObj);
    _updateKlaytnCaverObjState(klaytnObj, caverObj);
  };

  const updateKaikasUser = () => {
    DBGMSG(`$$$$KAIKAS updateKaikasUser`);
    const klaytnObj = getKlaytnObj();
    if (klaytnObj === undefined) return;

    // ref - https://docs.kaikas.io/01_getting_started/02_quick_start#getting-user-state
    // main network : 8217
    // test network : 1001  (baobab)
    const networkVersion = klaytnObj.networkVersion;
    const selectedAddress = klaytnObj.selectedAddress;

    DBGMSG(`$$$$KAIKAS networkVersion: ${networkVersion}`);
    DBGMSG(`$$$$KAIKAS selectedAddress: ${selectedAddress}`);

    _updateKaikasUserState({ selectedAddress, networkVersion });
  };

  const updateKaikasDev = async () => {
    DBGMSG(`$$$$KAIKAS updateKaikasDev`);
    const klaytnObj = getKlaytnObj();
    if (klaytnObj === undefined) return Promise.reject();

    const isEnalbed = klaytnObj._kaikas.isEnabled();
    const isApproved = await klaytnObj._kaikas.isApproved();
    const isUnlocked = await klaytnObj._kaikas.isUnlocked();

    DBGMSG(`$$$$KAIKAS isEnalbed: ${isEnalbed}`);
    DBGMSG(`$$$$KAIKAS isApproved: ${isApproved}`);
    DBGMSG(`$$$$KAIKAS isUnlocked: ${isUnlocked}`);

    _updateKaikasDevState({ isEnalbed, isApproved, isUnlocked });
  };

  // ref - https://docs.kaikas.io/02_api_reference/02_caver_methods#caver-klay-getbalance
  const _getBalance = async (address: string) => {
    const caverObj = getCaverObj();
    if (caverObj === undefined) return Promise.reject();

    try {
      DBGMSG(`$$$$KAIKAS _getBalance`);
      const balanceInPeb = await caverObj.klay.getBalance(address);
      const balanceInKlay = caverObj.utils.fromPeb(balanceInPeb, CbtCurrency_e.KLAY);

      DBGMSG(`$$$$KAIKAS ${address} balance(PEB): ${balanceInPeb}`);
      DBGMSG(`$$$$KAIKAS ${address} balance(KLAY): ${balanceInKlay}`);

      return {
        balanceInPeb: balanceInPeb as number,
        balanceInKlay: balanceInKlay as number,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getSelectedAddressBalance = async () => {
    try {
      DBGMSG(`$$$$KAIKAS getBalance`);
      if (!sKaikasState.user) {
        DBGMSGW(`$$$$KAIKAS connectedAddress is undefined`);
        return Promise.reject('connectedAddress is undefined');
      }

      const balance = await _getBalance(sKaikasState.user.selectedAddress);
      return balance;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const openInstallSite = () => {
    if (hR.lang === CbtUserLanguage_e.EN || hR.lang === CbtUserLanguage_e.JA) {
      window.open(Constants.KAIKAS_INSTALL_URL_EN);
    } else {
      window.open(Constants.KAIKAS_INSTALL_URL_);
    }
  };

  /**
   * 구매하기 스마트 컨트랙트
   */
  const buyItemUsingCobaltContract = ({
    pebString,
    assetKey,
    uuid,
    onError,
    onTransactionHash,
    onReceipt,
  }: {
    pebString: string;
    assetKey: string;
    uuid: string;
    onError?: (error: any) => void;
    onTransactionHash?: (txHash: string) => void;
    onReceipt?: (receipt: any) => void;
  }) => {
    return new Promise<string>((resolve, reject) => {
      DBGMSG(`$$$$KAIKAS buyItemInBaobab`);
      const klaytnObj = getKlaytnObj();
      const caverObj = getCaverObj();

      if (klaytnObj === undefined || caverObj === undefined) {
        return;
      }

      if (!isConnectedKaikas()) {
        DBGMSGW(`$$$$KAIKAS is not connected`);
        return;
      }

      DBGMSG(`$$$$KAIKAS caver.klay.Contract`);
      let myContract: any;
      if (isAlpha()) {
        myContract = new caverObj.klay.Contract(CobaltContractAbi, BaobabCobaltContractAddr);
      } else if (isProd()) {
        myContract = new caverObj.klay.Contract(CobaltContractAbi, CypressCobaltContractAddr);
      } else {
        DBGMSGW(`$$$$KAIKAS what ${AppConf.APPNAME}`);
        return;
      }

      DBGMSG(`$$$$KAIKAS caver.utils.toBN`);
      const pebBN = caverObj.utils.toBN(pebString);

      myContract.methods
        .buyItem(assetKey, uuid)
        .send(
          {
            from: klaytnObj.selectedAddress,
            gas: 1000000,
            value: pebBN,
          },
          (error: any, transactionHash: any) => {
            DBGMSG(`$$$$KAIKAS callback error: ${error}`);
            DBGMSG(`$$$$KAIKAS callback transactionHash: ${transactionHash}`);
          }
        )
        .on('error', (error: any) => {
          DBGMSG(`$$$$KAIKAS on error: ${error}`);
          onError && onError(error);
          reject(error);
        })
        .on('transactionHash', (transactionHash: any) => {
          DBGMSG(`$$$$KAIKAS on transactionHash: ${transactionHash}`);
          onTransactionHash && onTransactionHash(transactionHash);
          resolve(transactionHash);
        })
        .on('receipt', (receipt: any) => {
          DBGMSG(`$$$$KAIKAS on receipt`);
          DBGMSG(receipt);
          DBGMSG(receipt.contractAddress); // contains the new contract address
          onReceipt && onReceipt(receipt);
        })
        .then((newContractInstance: any) => {
          DBGMSG(newContractInstance);
          // DBGMSG(newContractInstance.options.address); // instance with the new contract address
        });
    });
  };

  /**
   * CLBT 수수료 결제
   */
  const payfeeByCbltContract = ({
    userCode,
    reqId,
    onError,
    onTransactionHash,
    onReceipt,
  }: {
    userCode: string;
    reqId: string;
    onError?: (error: any) => void;
    onTransactionHash?: (txHash: string) => void;
    onReceipt?: (receipt: any) => void;
  }) => {
    return new Promise<string>(async (resolve, reject) => {
      DBGMSGW(`$$$$KAIKAS payfeeByCblt`);
      const klaytnObj = getKlaytnObj();
      const caverObj = getCaverObj();

      if (klaytnObj === undefined || caverObj === undefined) {
        reject();
        return;
      }

      if (!isConnectedKaikas()) {
        DBGMSGW(`$$$$KAIKAS is not connected`);
        reject();
        return;
      }
      if (!sKaikasState.user) {
        DBGMSGW(`$$$$KAIKAS sKaikasState.user is undefined`);
        reject();
        return;
      }
      DBGMSGW(`$$$$KAIKAS klaytnObj.selectedAddress: ${klaytnObj.selectedAddress}`);

      const amount = caverObj.utils.toPeb(5, 'KLAY'); //5 CBLT decimal 18로 KLAY 동일하므로...

      let myContract: any;
      if (isAlpha()) {
        myContract = new caverObj.klay.Contract(PayFeeCobaltContractAbi, BaobabCbltPaymentAddr);
      } else if (isProd()) {
        myContract = new caverObj.klay.Contract(PayFeeCobaltContractAbi, CypressCbltPaymentAddr);
      } else {
        DBGMSGW(`$$$$KAIKAS what ${AppConf.APPNAME}`);
        reject();
        return;
      }

      DBGMSGW(`$$$$KAIKAS payfeeByCblt feePay`);
      DBGMSGW(`$$$$KAIKAS payfeeByCblt userCode: `, userCode);
      DBGMSGW(`$$$$KAIKAS payfeeByCblt reqId: `, reqId);
      DBGMSGW(`$$$$KAIKAS payfeeByCblt amount: `, amount);
      // 스마트 컨트랙트 실행
      myContract.methods
        .feePayOneTx(userCode, reqId, amount)
        .send(
          {
            from: klaytnObj.selectedAddress,
            gas: 1000000,
            // value: pebBN,
          },
          (error: any, transactionHash: any) => {
            DBGMSGW(`$$$$KAIKAS callback error: ${error}`);
            DBGMSGW(`$$$$KAIKAS callback transactionHash: ${transactionHash}`);
          }
        )
        .on('error', (error: any) => {
          DBGMSGW(`$$$$KAIKAS on error: ${error}`);
          onError && onError(error);
          reject(error);
          return;
        })
        .on('transactionHash', (transactionHash: any) => {
          DBGMSGW(`$$$$KAIKAS on transactionHash: ${transactionHash}`);
          onTransactionHash && onTransactionHash(transactionHash);
          resolve(transactionHash);
        })
        .on('receipt', (receipt: any) => {
          DBGMSGW(`$$$$KAIKAS on receipt`);
          DBGMSGW(receipt);
          // DBGMSGW(receipt.contractAddress); // contains the new contract address
          onReceipt && onReceipt(receipt);
          return;
        })
        .then((newContractInstance: any) => {
          DBGMSGW(`$$$$KAIKAS then`);
          DBGMSGW(newContractInstance);
          // DBGMSG(newContractInstance.options.address); // instance with the new contract address
        });
    });
  };

  const getNetworkVersion = () => {
    const klaytn = getKlaytnObj();

    if (klaytn === undefined) {
      return;
    }

    if (klaytn.networkVersion === 8217) {
      return 'MAINNET';
    } else if (klaytn.networkVersion === 1001) {
      return 'BAOBAB';
    }
    return;
  };

  return {
    // kaikas 이용가능한지 체크
    isAvailable,

    // kaikas 상태 업데이트
    updateAll,

    // kaikas 상태
    kaikasState: sKaikasState,

    // 연결
    connectToKaikas,

    // 연결 상태(redux state 상태만 체크)
    isConnectedKaikas,

    // 연결 해제(상태만 변경됨)
    disConnectKaikas,

    // balance 조회
    getBalance: getSelectedAddressBalance,

    openInstallSite,

    // 새로고침시 자동으로 enable하기위해 체크
    isAlreadyConnected,

    // 스마트 컨트랙트 _ 구매하기
    buyItemUsingCobaltContract,

    // 스마트 컨트랙트 _ CBLT 수수료 결제
    payCbltUsingCobaltContract: payfeeByCbltContract,

    getNetworkVersion,
  };
}

// **************************************************
// 구매하기 스마트 컨트랙트
// **************************************************
const BaobabCobaltContractAddr = '0xBFA355CF763b8822b8B487E55bAD14cd7e623E2E';
const CypressCobaltContractAddr = '0x607408243392277aD2E0C7A5a806DFc60E28618A';

const CobaltContractAbi = [
  { constant: false, inputs: [], name: 'unpause', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function' },
  {
    constant: true,
    inputs: [],
    name: 'getBeneficiary',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: '_beneficiary',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  { constant: false, inputs: [], name: 'pause', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function' },
  { constant: true, inputs: [], name: 'isPaused', outputs: [{ name: '', type: 'bool' }], payable: false, stateMutability: 'view', type: 'function' },
  {
    constant: false,
    inputs: [
      { name: 'assetKey', type: 'string' },
      { name: 'uuid', type: 'string' },
    ],
    name: 'buyItem',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: 'a', type: 'string' },
      { name: 'b', type: 'string' },
    ],
    name: 'compareStrings',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: 'uuid', type: 'string' }],
    name: 'findBuyTx',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: 'beneficiary', type: 'address' }],
    name: 'chagneBeneficiary',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: '_i', type: 'uint256' }],
    name: 'uint2str',
    outputs: [{ name: '_uintAsString', type: 'string' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
  { inputs: [{ name: 'beneficiary', type: 'address' }], payable: false, stateMutability: 'nonpayable', type: 'constructor' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'oldBeneficiary', type: 'address' },
      { indexed: true, name: 'newBeneficiary', type: 'address' },
    ],
    name: 'EventChagneBeneficiary',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'fromAddr', type: 'address' },
      { indexed: true, name: 'toAddr', type: 'address' },
      { indexed: true, name: 'value', type: 'uint256' },
    ],
    name: 'EventBuyItem',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'fromAddr', type: 'address' },
      { indexed: true, name: 'value', type: 'uint256' },
      { indexed: true, name: 'assetKey', type: 'string' },
    ],
    name: 'EventBuyItemDetail',
    type: 'event',
  },
];

// **************************************************
// CBLT 지급 컨트랙트
// **************************************************
// const BaobabCbltContractAddr = '0xdeC7686B48B861939b45baB7055B2d56BF514F88';
const BaobabCbltPaymentAddr = '0xE243Dc766089F901Bbc3f0CcE11f93d3fb5206c3';
const CypressCbltPaymentAddr = '0xE243Dc766089F901Bbc3f0CcE11f93d3fb5206c3';

const PayFeeCobaltContractAbi = [
  {
    constant: false,
    inputs: [{ name: 'amount', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { constant: false, inputs: [], name: 'unpause', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function' },
  {
    constant: true,
    inputs: [],
    name: 'getBeneficiary',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'userCode', type: 'string' },
      { name: 'uuid', type: 'string' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'feePayOneTx',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { constant: false, inputs: [], name: 'pause', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function' },
  {
    constant: true,
    inputs: [{ name: 'addr', type: 'address' }],
    name: 'getCbltAllowance',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: 'uuid', type: 'string' }],
    name: 'findFeePayTx',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: 'addr', type: 'address' }],
    name: 'getCbltBalance',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  { constant: true, inputs: [], name: 'isPaused', outputs: [{ name: '', type: 'bool' }], payable: false, stateMutability: 'view', type: 'function' },
  {
    constant: false,
    inputs: [{ name: 'uuid', type: 'string' }],
    name: 'refundFee',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: 'newOperator', type: 'address' }],
    name: 'setOperator',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: 'a', type: 'string' },
      { name: 'b', type: 'string' },
    ],
    name: 'compareStrings',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: 'beneficiary', type: 'address' }],
    name: 'chagneBeneficiary',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'userCode', type: 'string' },
      { name: 'uuid', type: 'string' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'feePay',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: '_i', type: 'uint256' }],
    name: 'uint2str',
    outputs: [{ name: '_uintAsString', type: 'string' }],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { name: 'beneficiary', type: 'address' },
      { name: 'cbltContract', type: 'address' },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'oldBeneficiary', type: 'address' },
      { indexed: true, name: 'newBeneficiary', type: 'address' },
    ],
    name: 'EventChagneBeneficiary',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'fromAddr', type: 'address' },
      { indexed: true, name: 'value', type: 'uint256' },
      { indexed: true, name: 'uuid', type: 'string' },
    ],
    name: 'EventFeePay',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'fromAddr', type: 'address' },
      { indexed: true, name: 'value', type: 'uint256' },
      { indexed: true, name: 'userCode', type: 'string' },
    ],
    name: 'EventFeePayDetail',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'fromAddr', type: 'address' },
      { indexed: true, name: 'toAddr', type: 'address' },
      { indexed: true, name: 'value', type: 'uint256' },
    ],
    name: 'EventRefundFee',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'fromAddr', type: 'address' },
      { indexed: true, name: 'value', type: 'uint256' },
      { indexed: true, name: 'uuid', type: 'string' },
    ],
    name: 'EventFeeFinish',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'toAddr', type: 'address' },
      { indexed: true, name: 'value', type: 'uint256' },
    ],
    name: 'EventWithdraw',
    type: 'event',
  },
];
