import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineFF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { DBGMSG } from 'src/util/utils';

export type OkDlgJsxProps = {
  title?: string;
  titleSize?: number;
  text?: string;
  textSize?: number;
  textBold?: boolean;
  btnTxt?: string;
  btnTxtSize?: number;
  width?: number;
  height?: number;
  btnWidth?: number;
  btnHeight?: number;
  onOk?: () => void;
  enableOkBtn?: boolean;
};

// Desktop
const DT_DLG_WIDTH = 380;
const DT_DLG_MIN_HEIGHT = 232;
const DT_PADDING = 40;
const DT_DEFULAT_FONT = 14;
const DT_TITLE_FONT = 22;
const DT_TITLE_MARGIN_BOTTOM = 20;
const DT_BTN_WIDTH = 300;
const DT_BTN_HEIGHT = 48;
const DT_BTN_MARGIN_TOP = 30;
const DT_TOP_SPACE_FLEX = 1;
const DT_BOTTOM_SPACE_FLEX = 3;

// Mobile
const MB_DLG_WIDTH = 250;
const MB_DLG_MIN_HEIGHT = 232;
const MB_PADDING = 20;
const MB_DEFULAT_FONT = 10;
const MB_TITLE_FONT = 16;
const MB_TITLE_MARGIN_BOTTOM = 16;
const MB_BTN_WIDTH = 200;
const MB_BTN_HEIGHT = 40;
const MB_BTN_MARGIN_TOP = 20;
const MB_TOP_SPACE_FLEX = 1;
const MB_BOTTOM_SPACE_FLEX = 1;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const DLG_MIN_HEIGHT = ScreenInfo.isMobile() ? MB_DLG_MIN_HEIGHT : DT_DLG_MIN_HEIGHT;
const DLG_DEFAULT_PADDING = ScreenInfo.isMobile() ? MB_PADDING : DT_PADDING;
const DEFULAT_FONT = ScreenInfo.isMobile() ? MB_DEFULAT_FONT : DT_DEFULAT_FONT;
const TITLE_FONT = ScreenInfo.isMobile() ? MB_TITLE_FONT : DT_TITLE_FONT;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BTN_MARGIN_TOP : DT_BTN_MARGIN_TOP;
const TOP_SPACE_FLEX = ScreenInfo.isMobile() ? MB_TOP_SPACE_FLEX : DT_TOP_SPACE_FLEX;
const BOTTOM_SPACE_FLEX = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE_FLEX : DT_BOTTOM_SPACE_FLEX;

export const OkDlgJsx = (dlg: OkDlgJsxProps) => {
  // const hR = useLocalSettings();

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        // minHeight: DLG_MIN_HEIGHT,
        // height: 90,
        padding: DLG_DEFAULT_PADDING,
        marginLeft: ScreenInfo.isMobile() ? 35 : undefined,
        marginRight: ScreenInfo.isMobile() ? 35 : undefined,
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineFF,
        // marginTop: ScreenInfo.isMobile() ? 79 : undefined,
        // marginBottom: ScreenInfo.isMobile() ? 79 : undefined,
      }}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      {dlg.title && (
        <FlexColumnDiv
          style={{
            flex: 1,
            justifyContent: 'center',
            // ...devOutlineFG
          }}
        >
          <TextCon text={dlg.title} size={dlg.titleSize ?? TITLE_FONT} color={R.colors.black} isBold />
        </FlexColumnDiv>
      )}
      {dlg.text && (
        <FlexColumnDiv
          style={
            {
              // ...devOutlineFG
            }
          }
        >
          {dlg.title && <HEIGHT size={TITLE_MARGIN_BOTTOM} />}
          <TextCon
            text={dlg.text}
            size={dlg.textSize ?? DEFULAT_FONT}
            color={R.colors.black}
            textStyle={{ textAlign: 'center' }}
            isBold={dlg.textBold === true}
          />
        </FlexColumnDiv>
      )}

      {dlg.enableOkBtn !== false && (
        <>
          <HEIGHT size={BTN_MARGIN_TOP} />

          <RoundTxtBtn
            text={dlg.btnTxt ?? 'OK'}
            width={dlg.btnWidth ?? BTN_WIDTH}
            height={dlg.btnHeight ?? BTN_HEIGHT}
            fontSize={dlg.btnTxtSize ?? DEFULAT_FONT}
            // containerStyle={{ position: 'absolute', bottom: DLG_DEFAULT_PADDING }}
            onClick={() => {
              dlg.onOk && dlg.onOk();
            }}
          />
        </>
      )}
    </FlexColumnDiv>
  );
};
