/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import React, { useEffect, useState } from 'react';
import { isAlpha } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useSubscribeStompMsg } from 'src/hooks/useSubscribeStompMsg';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { CbtPrd_i, CbtUserLanguage_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { CbtStompMsgCode_e } from 'src/rx/messageService';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { PriceSimple } from 'src/ui/common_component/user/PriceSimple';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGW } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
// DESKTOP
const DT_DLG_WIDTH = 380;
const DT_DLG_HEIGHT = 536;
const DT_PADDING_TOP = 30;
const DT_DEFAULT_FONT_SIZE = 14;
const DT_HPADDING = 40;
const DT_FORM_HEIGHT = 44;
const DT_BODY_WIDTH = DT_DLG_WIDTH - DT_HPADDING * 2;
const DT_PADDING_BOTTOM = 40;
const DT_CLOSE_BTN_SIZE = 24;
const DT_CLOSE_BTN_PADDING = 14;
const DT_TITLE_FOTNSIZE = 24;
const DT_TITLE_MARGIN_BOTTOM = 20;

// MOBILE
const MB_DLG_WIDTH = 280;
const MB_DLG_HEIGHT = 404;
const MB_PADDING_TOP = 16;
const MB_DEFAULT_FONT_SIZE = 12;
const MB_HPADDING = 12;
const MB_FORM_HEIGHT = 42;
const MB_BODY_WIDTH = MB_DLG_WIDTH - MB_HPADDING * 2;
const MB_PADDING_BOTTOM = 20;
const MB_CLOSE_BTN_SIZE = 20;
const MB_CLOSE_BTN_PADDING = 8;
const MB_TITLE_FOTNSIZE = 14;
const MB_TITLE_MARGIN_BOTTOM = 10;

class LAY {
  static readonly DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
  static readonly DLG_HEIGHT = ScreenInfo.isMobile() ? MB_DLG_HEIGHT : DT_DLG_HEIGHT;
  static readonly PADDING_TOP = ScreenInfo.isMobile() ? MB_PADDING_TOP : DT_PADDING_TOP;
  static readonly DEFAULT_FONT_SIZE = ScreenInfo.isMobile() ? MB_DEFAULT_FONT_SIZE : DT_DEFAULT_FONT_SIZE;
  static readonly HPADDING = ScreenInfo.isMobile() ? MB_HPADDING : DT_HPADDING;
  static readonly FORM_HEIGHT = ScreenInfo.isMobile() ? MB_FORM_HEIGHT : DT_FORM_HEIGHT;
  static readonly BODY_WIDTH = ScreenInfo.isMobile() ? MB_BODY_WIDTH : DT_BODY_WIDTH;
  static readonly PADDING_BOTTOM = ScreenInfo.isMobile() ? MB_PADDING_BOTTOM : DT_PADDING_BOTTOM;
  static readonly CLOSE_BTN_SIZE = ScreenInfo.isMobile() ? MB_CLOSE_BTN_SIZE : DT_CLOSE_BTN_SIZE;
  static readonly CLOSE_BTN_PADDING = ScreenInfo.isMobile() ? MB_CLOSE_BTN_PADDING : DT_CLOSE_BTN_PADDING;
  static readonly TITLE_FOTNSIZE = ScreenInfo.isMobile() ? MB_TITLE_FOTNSIZE : DT_TITLE_FOTNSIZE;
  static readonly TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
}
export type PointChargeJsxProps = {
  selectedPrd: CbtPrd_i;
  imgSrc: string;
  onSuccess: () => void;
  onClose: () => void;
};

export function PointChargeJsx(props: PointChargeJsxProps) {
  /**************************************
   * !! state
   **************************************/
  const [sPaymentWallBtnEnter, setPaymentWallBtnEnter] = useState(false);
  const [sPayPalBtnOnInit, setPayPalBtnOnInit] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hUserDetailMineState = useUserDetailMineState();
  const [{ options }, dispatch] = usePayPalScriptReducer();

  useSubscribeStompMsg({
    onMsg: (stompMsg) => {
      switch (stompMsg.code) {
        case CbtStompMsgCode_e.BILL_CHARGE_POINT_SUCCESS:
          break;
        case CbtStompMsgCode_e.BILL_CHARGE_POINT_FAIL:
          break;
      }
    },
    targetCodeList: [CbtStompMsgCode_e.BILL_CHARGE_POINT_SUCCESS, CbtStompMsgCode_e.BILL_CHARGE_POINT_FAIL],
  });

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    let currency: string;
    switch (hR.lang) {
      case CbtUserLanguage_e.KO:
      case CbtUserLanguage_e.JA:
      case CbtUserLanguage_e.EN:
        currency = 'USD';
        break;
    }
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency,
      },
    });
    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const handlePaymentwallBtn = async () => {
    const prd_id = props.selectedPrd.prd_id;
    try {
      const res = await hRpc.cobaltRpc.rpcPaymentChargePointWidget({ prd_id });
      DBGMSG(res.url);
      hDlgFactory.closePointChargeListDlg();
      hDlgFactory.showPaymentwallWidetDlg({
        widgetUrl: res.url,
        onCloseBtn: () => {
          hDlgFactory.hidePaymentwallWidetDlg();
        },
        onSuccPayment: () => {
          hUserDetailMineState.act.changeState({ user: { needUpdate: true } });
          hDlgFactory.showPgPointChargeSucc({
            transaction_id: '',
          });
        },
        onFailPayment: () => {
          // todo
        },
      });
    } catch (err) {
      hRpc.hanleCommonError(err);
    }
  };

  /**************************************
   * !! render function
   **************************************/

  // 페이팔 버튼 렌더링
  const renderPaypalBtn = () => {
    return (
      <>
        <PayPalButtons
          style={{
            //
            height: 45,
            layout: 'horizontal',
            tagline: false,
          }}
          onInit={(data, action) => {
            DBGMSG('onInit');
            setPayPalBtnOnInit(true);
          }}
          createOrder={async (data, action) => {
            try {
              const prd_id = props.selectedPrd.prd_id;
              const res = await hRpc.cobaltRpc.rpcPaymentPaypalChargePointOrder({ prd_id });
              return res.id;
            } catch (err) {
              hRpc.hanleCommonError(err);
              return '';
            }
          }}
          onApprove={async (data, actions) => {
            try {
              const res = await hRpc.cobaltRpc.rpcPaymentPaypalChargePointCapture({ ord_id: data.orderID });
              const errorId = res.error_id;
              if (errorId && errorId === 'INSTRUMENT_DECLINED') {
                return actions.order.restart();
                // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
              }

              // ref
              // https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=102176258

              if (errorId) {
                const errorDesc = res.error_desc;
                let msg = 'Sorry, your transaction could not be processed.';
                if (errorDesc) msg += '\n\n' + errorDesc;
                if (res.debug_id) msg += ' (' + res.debug_id + ')';
                DBGMSGW(msg);
                hDlgFactory.showPgPointChargeFail();
                props.onClose();
              } else {
                hDlgFactory.showPgPointChargeSucc({
                  transaction_id: data.orderID,
                });
                hUserDetailMineState.act.changeState({ user: { needUpdate: true } });
                props.onClose();
                props.onSuccess();
              }
            } catch (err) {
              hRpc.hanleCommonError(err);
            }
          }}
        />
      </>
    );
  };

  const renderBottom = () => {
    return (
      <>
        <HEIGHT size={ScreenInfo.isMobile() ? 20 : 30} />

        <FlexRowDiv style={{ overflowX: 'visible', justifyContent: 'center' }}>
          <>
            <TextCon
              text={hR.strings.DLG_CHARGE_LIST_TERMS}
              size={ScreenInfo.isMobile() ? 10 : 12}
              color={R.colors.cloudyBlue}
              containerStyle={{ flex: '0 0 auto', paddingLeft: ScreenInfo.isMobile() ? 5 : 10, paddingRight: ScreenInfo.isMobile() ? 5 : 10 }}
              onConClick={(e) => {
                DBGMSG('onClick 서비스 이용약관');
                // hHistory.push(Nav.UserTermsOfUseScreen);
                // props.onCloseBtn();
                window.open(Nav.brewUserTermsOfUseScreenPath({ lang: hR.lang }));
                e.stopPropagation();
              }}
            />
            <TextCon text={'|'} size={ScreenInfo.isMobile() ? 10 : 12} color={R.colors.cloudyBlue} />
            <TextCon
              text={hR.strings.DLG_CHARGE_LIST_PRIVACY}
              size={ScreenInfo.isMobile() ? 10 : 12}
              color={R.colors.cloudyBlue}
              containerStyle={{ flex: '0 0 auto', paddingLeft: ScreenInfo.isMobile() ? 5 : 10, paddingRight: ScreenInfo.isMobile() ? 5 : 10 }}
              onConClick={(e) => {
                DBGMSG('onClick 개인정보처리방침');
                // hHistory.push(Nav.UserPrivacyPolicyScreen);
                // props.onCloseBtn();
                window.open(Nav.brewUserPrivacyPolicyScreenPath({ lang: hR.lang }));
                e.stopPropagation();
              }}
            />
            <TextCon text={'|'} size={ScreenInfo.isMobile() ? 10 : 12} color={R.colors.cloudyBlue} />
            <TextCon
              text={hR.strings.DLG_CHARGE_LIST_REFUND_POLICY}
              size={ScreenInfo.isMobile() ? 10 : 12}
              color={R.colors.cloudyBlue}
              containerStyle={{ flex: '0 0 auto', paddingLeft: ScreenInfo.isMobile() ? 5 : 10, paddingRight: ScreenInfo.isMobile() ? 5 : 10 }}
              onConClick={(e) => {
                DBGMSG('onClick 환불정책');
                // hHistory.push(Nav.UserRefundPolicyScreen);
                // props.onCloseBtn();
                window.open(Nav.brewUserRefundPolicyScreenPath({ lang: hR.lang }));
                e.stopPropagation();
              }}
            />
          </>
          {/* <TextCon text={'|'} size={12} color={R.colors.cloudyBlue} />
      <TextCon
        text={hR.strings.DLG_CHARGE_RAW1}
        size={12}
        color={R.colors.cloudyBlue}
        containerStyle={{ paddingLeft: 10, paddingRight: 10 }}
        onConClick={(e) => {
          DBGMSG('onClick 금융상품거래법');
          e.stopPropagation();
        }}
      />
      <TextCon text={'|'} size={12} color={R.colors.cloudyBlue} />
      <TextCon
        text={hR.strings.DLG_CHARGE_RAW2}
        size={12}
        color={R.colors.cloudyBlue}
        containerStyle={{ paddingLeft: 10, paddingRight: 10 }}
        onConClick={(e) => {
          DBGMSG('onClick 자금결제법');
          e.stopPropagation();
        }}
      /> */}
        </FlexRowDiv>
      </>
    );
  };

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv
        style={{
          width: LAY.DLG_WIDTH,
          height: LAY.DLG_HEIGHT,
          alignItems: 'center',
          paddingLeft: LAY.HPADDING,
          paddingRight: LAY.HPADDING,
          paddingTop: LAY.PADDING_TOP,
          paddingBottom: LAY.PADDING_BOTTOM,
          // ...devOutlineFB,
        }}
        onClick={(e) => {
          DBGMSG('onDialog');
          e.stopPropagation();
        }}
      >
        {/* close 버튼 */}
        <ImgBtn
          src={[R.images.common_btn_close3x, LAY.CLOSE_BTN_SIZE, LAY.CLOSE_BTN_SIZE]}
          containerStyle={{ position: 'absolute', top: 0, right: 0, padding: LAY.CLOSE_BTN_PADDING }}
          onClick={() => {
            props.onClose();
          }}
        />

        {/* 타이틀 */}
        <TextCon text={hR.strings.DLG_CHARGE_TITLE} size={14} color={R.colors.black} isBold />
        <HEIGHT size={LAY.TITLE_MARGIN_BOTTOM} />
        <Img src={[props.imgSrc, 60, 60]} />

        {/* 충전포인트 양 */}
        <PriceSimple prc_info={props.selectedPrd.chg_info} fontColor={R.colors.purpleishBlue} priceFontSize={14} unitFontSize={14} />
        <HEIGHT size={20} />

        {/* 라인 */}
        <HLINE size={256} />

        {/* 상품 금액 */}
        <FlexRowDiv style={{ paddingTop: 12, paddingBottom: 8, justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <TextCon text={hR.strings.DLG_CHARGE_UNIT_PRICE} color={R.colors.black} size={12} />
          <PriceSimple prc_info={props.selectedPrd.unit_info} fontColor={R.colors.black} priceFontSize={12} unitFontSize={12} isBold={false} />
        </FlexRowDiv>

        {/* 수수료 */}
        <FlexRowDiv style={{ paddingBottom: 12, justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <FlexRowDiv style={{ alignItems: 'baseline' }}>
            <TextCon text={hR.strings.DLG_CHARGE_FEE} color={R.colors.black} size={12} />
            <WIDTH size={4} />
            <TextCon text={`(${props.selectedPrd.fee_pct}%)`} color={R.colors.black} size={10} />
          </FlexRowDiv>
          <PriceSimple prc_info={props.selectedPrd.fee_info} fontColor={R.colors.black} priceFontSize={12} unitFontSize={12} isBold={false} />
        </FlexRowDiv>

        {/* 라인 */}
        <HLINE size={256} />

        {/* 결제 금액 */}
        <FlexRowDiv style={{ paddingTop: 12, justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <TextCon text={hR.strings.DLG_CHARGE_TOTAL_PRICE} color={R.colors.black} size={12} isBold />
          <PriceSimple prc_info={props.selectedPrd.unit_info} fontColor={R.colors.purpleishBlue} priceFontSize={12} unitFontSize={12} />
        </FlexRowDiv>

        <HEIGHT size={20} />

        {/* paypal 버튼 */}
        <div style={{ minWidth: 256 }}>{renderPaypalBtn()}</div>
        <HEIGHT size={5} />

        {/* 페이먼트월 버튼 */}
        {/* {isAlpha() && (
          <FlexColumnDiv
            style={{
              width: 256,
              height: 45,
              backgroundColor: sPaymentWallBtnEnter ? '#3322C9' : R.colors.purpleishBlue,
              borderRadius: 4,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onMouseEnter={() => {
              setPaymentWallBtnEnter(true);
            }}
            onMouseLeave={() => {
              setPaymentWallBtnEnter(false);
            }}
            onClick={() => {
              handlePaymentwallBtn();
            }}
          >
            <Img src={[R.images.point_btn_paymentwall_logo3x, 160, 45]} />
          </FlexColumnDiv>
        )} */}

        {renderBottom()}
      </FlexColumnDiv>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          width: LAY.DLG_WIDTH,
          minHeight: LAY.DLG_HEIGHT,
          alignItems: 'center',
          paddingLeft: LAY.HPADDING,
          paddingRight: LAY.HPADDING,
          paddingTop: LAY.PADDING_TOP,
          paddingBottom: LAY.PADDING_BOTTOM,
          // ...devOutlineFB,
        }}
        onClick={(e) => {
          DBGMSG('onDialog');
          e.stopPropagation();
        }}
      >
        {/* close 버튼 */}
        <ImgBtn
          src={[R.images.common_btn_close3x, LAY.CLOSE_BTN_SIZE, LAY.CLOSE_BTN_SIZE]}
          containerStyle={{ position: 'absolute', top: 0, right: 0, padding: LAY.CLOSE_BTN_PADDING }}
          onClick={() => {
            props.onClose();
          }}
        />

        {/* 타이틀 */}
        <TextCon text={hR.strings.DLG_CHARGE_TITLE} size={LAY.TITLE_FOTNSIZE} color={R.colors.black} isBold />
        <HEIGHT size={LAY.TITLE_MARGIN_BOTTOM} />
        <Img src={[props.imgSrc, 78, 78]} />

        {/* 충전포인트 양 */}
        <PriceSimple prc_info={props.selectedPrd.chg_info} fontColor={R.colors.purpleishBlue} priceFontSize={18} unitFontSize={18} />
        <HEIGHT size={30} />

        {/* 라인 */}
        <HLINE size={300} />

        {/* 상품 금액 */}
        <FlexRowDiv style={{ paddingTop: 16, paddingBottom: 12, justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <TextCon text={hR.strings.DLG_CHARGE_UNIT_PRICE} color={R.colors.black} size={14} />
          <PriceSimple prc_info={props.selectedPrd.unit_info} fontColor={R.colors.black} priceFontSize={14} unitFontSize={14} isBold={false} />
        </FlexRowDiv>

        {/* 수수료 */}
        <FlexRowDiv style={{ paddingBottom: 16, justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <FlexRowDiv style={{ alignItems: 'baseline' }}>
            <TextCon text={hR.strings.DLG_CHARGE_FEE} color={R.colors.black} size={14} />
            <WIDTH size={6} />
            <TextCon text={`(${props.selectedPrd.fee_pct}%)`} color={R.colors.black} size={10} />
          </FlexRowDiv>
          <PriceSimple prc_info={props.selectedPrd.fee_info} fontColor={R.colors.black} priceFontSize={14} unitFontSize={14} isBold={false} />
        </FlexRowDiv>

        {/* 라인 */}
        <HLINE size={300} />

        {/* 결제 금액 */}
        <FlexRowDiv style={{ paddingTop: 16, justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <TextCon text={hR.strings.DLG_CHARGE_TOTAL_PRICE} color={R.colors.black} size={14} isBold />
          <PriceSimple prc_info={props.selectedPrd.prc_info} fontColor={R.colors.purpleishBlue} priceFontSize={14} unitFontSize={14} />
        </FlexRowDiv>

        <HEIGHT size={30} />

        {/* paypal 버튼 */}
        <div style={{ minWidth: 300, height: 45 }}>{renderPaypalBtn()}</div>
        <HEIGHT size={10} />

        {/* 페이먼트월 버튼 */}
        {/* {isAlpha() && (
          <FlexColumnDiv
            style={{
              width: 300,
              height: 45,
              backgroundColor: sPaymentWallBtnEnter ? '#3322C9' : R.colors.purpleishBlue,
              borderRadius: 4,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onMouseEnter={() => {
              setPaymentWallBtnEnter(true);
            }}
            onMouseLeave={() => {
              setPaymentWallBtnEnter(false);
            }}
            onClick={async () => {
              handlePaymentwallBtn();
            }}
          >
            <Img src={[R.images.point_btn_paymentwall_logo3x, 160, 45]} />
          </FlexColumnDiv>
        )} */}
        {renderBottom()}
      </FlexColumnDiv>
    );
}
