/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties, HTMLProps } from 'react';
import { devOutlineF, FlexDiv, WIDTH } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { DBGMSG } from 'src/util/utils';
import { Img } from 'src/ui/base_component/Img';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  label?: string;
  enable?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
  isBold?: boolean;
  fontColor?: string;
  bgColor?: string;
  borderColor?: string;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
  containerStyle?: CSSProperties;
  onMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  onMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
  titleTooltip?: string;
  ic?: string;
  icSize?: number;
};

export function RoundTxtBtnAdv({
  //
  label = 'label',
  enable = true,
  width = 152,
  height = 48,
  fontSize = 14,
  isBold = true,
  fontColor = 'white',
  bgColor = R.colors.purpleishBlue,
  borderColor = undefined,
  onClick,
  containerStyle,
  onMouseEnter,
  onMouseLeave,
  titleTooltip,
  ic,
  icSize = 17,
}: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <FlexDiv
      title={titleTooltip}
      style={Object.assign(
        {
          width,
          height,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: bgColor,
          borderRadius: height / 2,

          cursor: onClick && enable ? 'pointer' : 'auto',
          ...containerStyle,
        },
        borderColor && {
          borderWidth: 1,
          borderColor: enable ? borderColor : R.colors.line,
          borderStyle: 'solid',
        }
      )}
      onClick={(e) => {
        if (!enable) return;
        DBGMSG(`onClick: ${label}`);
        onClick && onClick(e);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {ic && (
        <>
          <Img src={[ic, icSize, icSize]} />
          <WIDTH size={4} />
        </>
      )}
      <TextCon
        text={label}
        size={fontSize}
        isBold={isBold}
        color={enable ? fontColor : R.colors.cloudyBlue}
        containerStyle={{
          cursor: onClick && enable ? 'pointer' : 'auto',
        }}
        useSelectNone
      />
    </FlexDiv>
  );
}
