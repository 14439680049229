/**
 * @copyright (C) Copyright 2020. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import qs from 'qs';
import { CbtCurrencyInfo_i, CbtUserLanguage_e } from 'src/model/rpcModel';
import { UserDetailMineScreenQS } from 'src/ui/screen/AssetMgr/UserDetailMineScreen';
import { UserDetailScreenQS } from 'src/ui/screen/AssetMgr/UserDetailScreen';
import { MarketPlaceScreenQS } from 'src/ui/screen/Marketplace/MarketPlaceScreen';
import { MyPropertyHistoryScreenQS } from 'src/ui/screen/User/MyPropertyHistoryScreen';
export namespace Nav {
  export interface ReplaceState {
    from: string;
  }
  export const ROOT = '/';

  export const HomeScreen = '/home';
  export const ExplorerScreeen = '/explorerS';
  export const AssetCreatorScreen = '/assetCreator';
  export const AssetCreatorBlockedScreen = '/assetCreatorBlocked';
  export const MarketPlaceScreen = '/marketPlace';

  // collection cru
  export const MyCollectionCScreen = '/myCollectionC';
  export const MyCollectionRScreen = '/myCollectionR';
  export const MyCollectionUScreen = '/myCollectionU';

  // schema cru
  export const MySchemaCScreen = '/mySchemaC';
  export const MySchemaRScreen = '/mySchemaR';
  export const MySchemaUScreen = '/mySchemaU';

  // asset cr
  export const MyAssetGroupCScreen = '/myAssetGroupC';
  export const MyAssetGroupListScreen = '/myAssetGroupList';
  export const MyIssueAssetRScreen = '/myIssueAssetR';

  // Market
  export const MarketAssetRScreen = '/marketAssetR';
  export const MarketCollectionRScreen = '/marketCollectionR';
  export const MarketSchemaRScreen = '/marketSchemaR';
  export const MarketPaymentScreen = '/marketPayment';

  // User
  export const UserLoginScreen = '/userLogin';
  export const UserJoinScreen = '/userJoin';
  export const UserChangeFishCodeScreen = '/userChangeFishCode';
  export const UserChangePasswordScreen = '/userChangePassword';
  export const UserResetPasswordScreen = '/userResetPassword';
  export const UserBan = '/userBan';
  export const NotiListScreen = '/notiList';
  export const UserTermsOfUseScreen = '/userTerms';
  export const UserPrivacyPolicyScreen = '/userPrivacyPolicy';
  export const UserRefundPolicyScreen = '/userRefundPolicy';

  // Aseetmgr
  export const AssetmgrAssetRScreen = '/assetmgrAssetR';
  // Aseetmgr / Market Account
  export const UserDetailScreen = '/userDetail';
  export const UserInventoryAstgDetailScreen = '/userInventoryAstgDetail';
  export const UserIssueAstgDetailScreen = '/userIssueAstgDetail';

  // Aseetmgr / My Account
  export const UserDetailMineScreen = '/userDetailMine';
  export const MyInventoryAstgDetailScreen = '/myInventoryAstgDetail';
  export const MyIssueAstgDetailScreen = '/myIssueAstgDetail';
  export const MyPropertyHistoryScreen = '/myPropertyHistoryScreen';

  export const brewCollectionRScreenPath = (args: { cid: number }) => {
    return `${Nav.MyCollectionRScreen}${getQueryString(args)}`;
  };

  export const brewCollectionUScreenPath = (args: { cid: number }) => {
    return `${Nav.MyCollectionUScreen}${getQueryString(args)}`;
  };

  export const brewSchemaRScreenPath = (args: { sid: number }) => {
    return `${Nav.MySchemaRScreen}${getQueryString(args)}`;
  };

  export const brewSchemaCScreenPath = (args: { cid: number }) => {
    return `${Nav.MySchemaCScreen}${getQueryString(args)}`;
  };
  export const brewSchemaUScreenPath = (args: { sid: number }) => {
    return `${Nav.MySchemaUScreen}${getQueryString(args)}`;
  };

  export const brewAssetGroupCScreenPath = (args: { sid: number }) => {
    return `${Nav.MyAssetGroupCScreen}${getQueryString(args)}`;
  };

  export const brewAssetGroupListScreenPath = (args: { sid: number; entity_id: number }) => {
    return `${Nav.MyAssetGroupListScreen}${getQueryString(args)}`;
  };

  export const brewMyInventoryAssetListScreenPath = (args: { sid: number; entity_id: number }) => {
    return `${Nav.MyInventoryAstgDetailScreen}${getQueryString(args)}`;
  };

  export const brewUserInventoryAssetListScreenPath = (args: { sid: number; entity_id: number; ucode: string }) => {
    return `${Nav.UserInventoryAstgDetailScreen}${getQueryString(args)}`;
  };

  export const brewMyIssueAssetRScreenPath = (args: { sid: number; entity_id: number; asset_id: number }) => {
    return `${Nav.MyIssueAssetRScreen}${getQueryString(args)}`;
  };

  export const brewMarketCollectionRScreenPath = (args: { cid: number }) => {
    return `${Nav.MarketCollectionRScreen}${getQueryString(args)}`;
  };

  export const brewMarketSchemaRScreenPath = (args: { sid: number }) => {
    return `${Nav.MarketSchemaRScreen}${getQueryString(args)}`;
  };

  export const brewMyIssueAstgListScreenPath = (args: { sid: number; entity_id: number }) => {
    return `${Nav.MyIssueAstgDetailScreen}${getQueryString(args)}`;
  };

  export const brewUserIssueAstgListScreenPath = (args: { sid: number; entity_id: number; ucode: string }) => {
    return `${Nav.UserIssueAstgDetailScreen}${getQueryString(args)}`;
  };

  export const brewMarketAssetRScreenPath = (args: { sale_id: number }) => {
    return `${Nav.MarketAssetRScreen}${getQueryString(args)}`;
  };

  export const brewUserDetailScreenPath = (args: UserDetailScreenQS) => {
    return `${Nav.UserDetailScreen}${getQueryString(args)}`;
  };

  export const brewUserResetPassword = (args: { email: string }) => {
    return `${Nav.UserResetPasswordScreen}${getQueryString(args)}`;
  };

  export const brewUserLogin = (args: { email: string }) => {
    return `${Nav.UserLoginScreen}${getQueryString(args)}`;
  };

  const getQueryString = (args: object) => {
    return qs.stringify({ ...args }, { addQueryPrefix: true });
  };

  export const brewMarketPayment = (args: { sale_id: number }) => {
    return `${Nav.MarketPaymentScreen}${getQueryString(args)}`;
  };

  export const getMarketPlaceInit = () => {
    return `${Nav.MarketPlaceScreen}${getQueryString({ is_init: true })}`;
  };

  // export const getUserMypageInit = () => {
  //   return `${Nav.UserMypageScreen}${getQueryString({ is_init: true })}`;
  // };

  export const brewAssetmgrAssetRScreenPath = (args: { sid: number; entity_id: number; asset_id: number; no_sale_ctl?: boolean }) => {
    return `${Nav.AssetmgrAssetRScreen}${getQueryString(args)}`;
  };

  export const brewMyPropertyHistoryScreenPath = (args: MyPropertyHistoryScreenQS) => {
    return `${Nav.MyPropertyHistoryScreen}${getQueryString(args)}`;
  };

  export const brewUserTermsOfUseScreenPath = (args: { lang: CbtUserLanguage_e }) => {
    return `${Nav.UserTermsOfUseScreen}${getQueryString(args)}`;
  };

  export const brewUserPrivacyPolicyScreenPath = (args: { lang: CbtUserLanguage_e }) => {
    return `${Nav.UserPrivacyPolicyScreen}${getQueryString(args)}`;
  };

  export const brewUserRefundPolicyScreenPath = (args: { lang: CbtUserLanguage_e }) => {
    return `${Nav.UserRefundPolicyScreen}${getQueryString(args)}`;
  };

  export const brewUserDetailMineScreenPath = (args: UserDetailMineScreenQS) => {
    return `${Nav.UserDetailMineScreen}${getQueryString(args)}`;
  };
  export const brewMarketPlaceScreenPath = (args: MarketPlaceScreenQS) => {
    return `${Nav.MarketPlaceScreen}${getQueryString(args)}`;
  };

  // test
  export const TEST_LOGIN = '/login_test';
  export const TEST_KAIKAS = '/kaikas_test';
  export const TEST_PLAY = '/play_test';
  export const LabScreen = '/lab';

  // authentication

  // item list

  // item detail

  // market place

  // my shop

  // shop

  // My Page
}

// export const getNavMyItemDetail = (args: { item_id: number; my_assetable_cnt: string }) => {
//   const query = qs.stringify({ ...args }, { addQueryPrefix: true });
//   return `${Nav.MYITEM_DETAIL}${query}`;
// };
