/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { useHistory } from 'react-router-dom';
import ScreenInfo, { MOBILE_CONTENT_WIDTH, MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { ChangeBtn } from 'src/ui/common_component/button/ChangeBtn';
import { CommonColumnBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserSettings(props: Props) {
  const hR = useLocalSettings();
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hMyPageState = useUserDetailMineState();

  if (!hAuth.isLogin) {
    return null;
  }

  if (!hAuth.authCommonRes) {
    return null;
  }

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <FlexColumnDiv
            style={{
              width: MOBILE_WIDTH,
              alignItems: 'flex-start',
              paddingLeft: MOBILE_HORIZONTAL_PADDING,
              paddingRight: MOBILE_HORIZONTAL_PADDING,
              // ...devOutlineF,
            }}
          >
            <HEIGHT size={20} />
            <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS} color={R.colors.black} size={16} isBold />
            <HEIGHT size={20} />
            <CommonColumnBox
              style={{
                width: MOBILE_CONTENT_WIDTH,
                //
                // paddingTop: 20,
                // paddingBottom: 20,
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              {/* 이메일 */}
              <FlexColumnDiv style={{ height: 96, justifyContent: 'center' }}>
                <FlexRowDiv style={{ alignItems: 'baseline' }}>
                  <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_EMAIL} color={R.colors.blueGrey} size={12} containerStyle={{}} />
                  <WIDTH size={5} />
                  <FlexRowDiv>
                    <TextCon
                      text={hR.strings.MY_MENU_MYACCOUNT_EMAIL_AUTH_COMPLETE}
                      color={R.colors.purpleishBlue}
                      size={10}
                      containerStyle={{ marginLeft: 'auto' }}
                      isBold
                    />
                  </FlexRowDiv>
                </FlexRowDiv>
                <HEIGHT size={5} />
                <TextCon text={`${hMyPageState.state.user.userInfo?.email ?? ''}`} color={R.colors.black} size={12} containerStyle={{}} />
              </FlexColumnDiv>

              <HLINE size={'100%'} />

              {/* 아이디 */}
              <FlexColumnDiv style={{ height: 96, justifyContent: 'center' }}>
                <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_ID} color={R.colors.blueGrey} size={12} containerStyle={{}} />
                <HEIGHT size={5} />
                <TextCon text={hAuth.authCommonRes.ucode} color={R.colors.black} size={12} containerStyle={{}} />
              </FlexColumnDiv>

              <HLINE size={'100%'} />

              {/* 비밀번호 */}
              <FlexColumnDiv style={{ height: 96, justifyContent: 'center' }}>
                <FlexRowDiv style={{ alignItems: 'baseline' }}>
                  <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_PASSWORD} color={R.colors.blueGrey} size={12} containerStyle={{}} />
                  <WIDTH size={5} />
                  <ChangeBtn
                    width={61}
                    height={22}
                    fontSize={10}
                    containerStyle={
                      {
                        // ...devOutlineF
                      }
                    }
                    onClick={() => {
                      hHistory.push(Nav.UserChangePasswordScreen);
                    }}
                  />
                </FlexRowDiv>
                <HEIGHT size={5} />
                <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_PASSWORD_GUIDE} color={R.colors.black} size={12} containerStyle={{}} />
              </FlexColumnDiv>

              <HLINE size={'100%'} />

              {/* 피싱 방지 코드 */}
              <FlexColumnDiv style={{ height: 96, justifyContent: 'center' }}>
                <FlexRowDiv style={{ alignItems: 'baseline' }}>
                  <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_FISH_SECURE_CODE} color={R.colors.blueGrey} size={12} containerStyle={{}} />
                  <WIDTH size={5} />
                  <ChangeBtn
                    width={61}
                    height={22}
                    fontSize={10}
                    containerStyle={{ ...devOutlineF }}
                    onClick={() => {
                      hHistory.push(Nav.UserChangeFishCodeScreen);
                    }}
                  />
                </FlexRowDiv>
                <HEIGHT size={5} />
                <TextCon text={hMyPageState.state.user.userInfo?.anti_phishing_code ?? ''} color={R.colors.black} size={12} containerStyle={{}} />
              </FlexColumnDiv>
            </CommonColumnBox>
            <HEIGHT size={60} />
          </FlexColumnDiv>
        </MobileScalableFlexColumn>
      </>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          alignItems: 'center',
          width: '100%',
          // ...devOutlineF,
        }}
      >
        <HLINE size={'100%'} />
        <FlexColumnDiv style={{ width: 888 }}>
          <HEIGHT size={40} />
          <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS} color={R.colors.black} size={20} isBold />
          <HEIGHT size={30} />
          <CommonColumnBox style={{ paddingLeft: 40, paddingRight: 40 }}>
            {/* 이메일 */}
            <FlexRowDiv style={{ height: 103 }}>
              <TextCon
                text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_EMAIL}
                color={R.colors.blueGrey}
                size={16}
                containerStyle={{
                  //
                  minWidth: 185,
                }}
              />
              <FlexRowDiv style={{ width: 623 }}>
                <TextCon text={`${hMyPageState.state.user.userInfo?.email ?? ''}`} color={R.colors.black} size={16} containerStyle={{}} />
                <TextCon
                  text={hR.strings.MY_MENU_MYACCOUNT_EMAIL_AUTH_COMPLETE}
                  color={R.colors.purpleishBlue}
                  size={12}
                  containerStyle={{ marginLeft: 'auto' }}
                  isBold
                />
              </FlexRowDiv>
            </FlexRowDiv>
            <HLINE size={'100%'} />

            {/* ID */}
            <FlexRowDiv style={{ height: 103 }}>
              <TextCon
                text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_ID}
                color={R.colors.blueGrey}
                size={16}
                containerStyle={{
                  //
                  minWidth: 185,
                }}
              />
              <FlexRowDiv style={{ width: 623 }}>
                <TextCon text={hAuth.authCommonRes.ucode} color={R.colors.black} size={16} containerStyle={{}} />
              </FlexRowDiv>
            </FlexRowDiv>
            <HLINE size={'100%'} />

            {/* 비밀번호 */}
            <FlexRowDiv style={{ height: 103 }}>
              <TextCon
                text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_PASSWORD}
                color={R.colors.blueGrey}
                size={16}
                containerStyle={{
                  //
                  minWidth: 185,
                }}
              />
              <FlexRowDiv style={{ width: 623 }}>
                <TextCon text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_PASSWORD_GUIDE} color={R.colors.blueGrey} size={14} containerStyle={{}} />
                <ChangeBtn
                  containerStyle={{
                    marginLeft: 'auto',
                    alignSelf: 'center',
                    // ...devOutlineF
                  }}
                  onClick={() => {
                    hHistory.push(Nav.UserChangePasswordScreen);
                  }}
                />
              </FlexRowDiv>
            </FlexRowDiv>
            <HLINE size={'100%'} />

            {/* 피싱 방지 코드 */}
            <FlexRowDiv style={{ height: 103 }}>
              <TextCon
                text={hR.strings.MY_MENU_MYACCOUNT_SETTINGS_FISH_SECURE_CODE}
                color={R.colors.blueGrey}
                size={16}
                containerStyle={{
                  //
                  minWidth: 185,
                }}
              />
              <FlexRowDiv
                style={{
                  width: 623,
                  // ...devOutlineF
                }}
              >
                <TextCon text={hMyPageState.state.user.userInfo?.anti_phishing_code ?? ''} color={R.colors.black} size={16} containerStyle={{}} />
                <ChangeBtn
                  containerStyle={{
                    marginLeft: 'auto',
                    alignSelf: 'center',
                    // ...devOutlineF
                  }}
                  onClick={() => {
                    hHistory.push(Nav.UserChangeFishCodeScreen);
                  }}
                />
              </FlexRowDiv>
            </FlexRowDiv>
          </CommonColumnBox>
          <HEIGHT size={120} />
        </FlexColumnDiv>
      </FlexColumnDiv>
    );
}
