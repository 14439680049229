/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {
  containerWidth?: number;
  containerHeight?: number;
  width?: number;
  height?: number;
};

export function DataFetching({
  //
  containerWidth = 100,
  containerHeight = 100,
  width = 60,
  height = 60,
}: Props) {
  return (
    <FlexColumnDiv
      style={{
        flex: 1,
        alignSelf: 'center',
        width: containerWidth,
        height: containerHeight,
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineF
      }}
    >
      {/* <Img src={[R.images.loading_Spin_07s_201px1x, 70, 70]} /> */}
      <Img src={[R.images.creator_ic_loading1x, width, height]} />
    </FlexColumnDiv>
  );
}
