/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

export class TermsOfUseStrings {
  static readonly ko = {
    TITLE: `서비스 이용약관`,
    DESC: `POST VOYAGER PTE.LTD. (이하 '회사’라고 합니다.)는 이 웹 사이트상에서 제공하는 서비스 Cobalt(이하 '본 서비스'라고 합니다.)에 대해서, 이 이용 약관(이하 '본 약관'이라고 합니다.)을 통해 회사와 이용자 사이의 권리 및 의무에 대해서 규정합니다.
본 약관은 이용자가 본 서비스를 이용함에 있어 회사와 이용자 사이에 적용되는 계약 내용 입니다.
회사는 본 약관의 전문을 읽고 동의한 이용자에 한하여 본 서비스의 이용을 허락합니다.`,
    TITLE_1: `1. 본 서비스에 대하여`,
    DETAIL_1: `본 서비스는 블록체인 상의 디지털 아이템이나 음성 데이터, 디지털 아트, 동영상 데이터 등의 NFT(Non-Fungible Token)를 사고 팔 수 있는 플랫폼입니다. 이용자 간의 출품, 구입 등에 관해서는 모두 이용자가 직접 책임을 져야 합니다. 회사는 이용자의 출품이 정당한 권리에 근거하는지 여부 등에 대해서 보증하지 않습니다. 또한 회사는 스스로 매매를 하지 않고, 매매의 위탁을 받지도 않으며, 매매의 수행에도 일절 관여하지 않습니다.`,
    TITLE_2: `2. 정의`,
    DETAIL_2: `1. ‘상품’이란 본 서비스의 거래 목적이 되는 디지털 아이템이나 음성 데이터, 디지털 아트, 동영상 데이터 등의 NFT를 말합니다.
2. ‘상품 대금’이란 판매자가 본 서비스 상에서 상품을 출품할 때 등록하는 해당 상품의 가격을 말하며 클레이 또는 포인트로 표시됩니다.
3. ‘판매금’이란 상품 대금에서 플랫폼 수수료 및 원작자 인세를 공제한 가격을 말합니다.
4. ‘이용자’란 본 약관에 동의한 후 본 서비스를 이용하는 자를 말합니다.
5. ‘판매자’란 본 서비스를 통해 상품을 출품하는 이용자를 말합니다.
6. ‘구입자’란 본 서비스를 통해 상품을 구매하는 이용자를 말합니다.
7. ‘원작자’란 상품을 창작한 사람(저작권자 포함, 이에 한정되지 않음) 등을 말합니다.
8. ‘원작자 인세’란 본 서비스 내에서 제품이 재 판매될 때마다 원작자에게 지급되는 보상입니다.
9. ‘디지털 소유권’이라 함은 NFT의 비밀키를 관리하고 NFT 이전 등을 블록체인 상에서 수행할 수 있는 권리를 의미하며 민법상 소유권을 의미하지 않습니다.
10. ‘부정상품·금지상품’이란 데이터 변조, 부정한 도구의 사용 기타 부적절한 방법으로 작성된 상품 및 본 약관에서 회사가 금지하는 사항에 해당하는 상품을 말합니다.
11. ‘포인트’란 본 서비스 내에서 사용할 수 있는 1포인트=1US 달러의 서비스 내 통화를 말합니다.`,
    TITLE_3: `3. 약관의 적용`,
    DETAIL_3: `1. 본 약관은 이용자와 회사 간의 본 서비스 이용에 관련된 모든 관계에 적용됩니다.
2. 회사는 본 서비스에 관하여 본 약관 외에 이용규칙 등 각종 규정(이하 '개별 규정'이라 하며 본 약관과 함께 '본 약관 등'이라 합니다.)을 하는 경우가 있습니다. 이들 개별 규정은 명칭 여하에 관계없이 본 약관의 일부로 구성됩니다.
3. 본 약관의 규정이 전 항의 개별 규정과 모순되는 경우에는 개별 규정에서 특별한 규정이 없는 한 개별 규정의 규정이 우선합니다.
4. 본 약관 등 일부가 법령에 의거 무효 또는 합의하지 않은 것으로 판단될 경우라도, 본 약관 등에 기재된 그 외의 규정은 유효합니다. 또한 본 약관 등의 전부 혹은 일부가 특정 이용자와의 사이에서 무효로 여겨지거나 취소 또는 합의하지 않았다고 판단되는 경우에도 본 약관 등은 회사와 다른 이용자와의 사이에서 유효합니다. `,
    TITLE_4: `4. 약관동의`,
    DETAIL_4: `1. 이용자는 사전에 본 약관 등에 동의한 후에 본 서비스를 이용합니다.
2. 본 약관 등에 동의할 수 없는 경우, 이용자는 본 서비스를 이용할 수 없으므로 그 경우는 즉시 본 서비스의 이용을 중지해 주십시오.
3. 이용자가 본 서비스 이용을 개시한 시점에서 이용자는 본 약관 등에 동의한 것으로 간주됩니다. 이 때 이용자와 회사 사이에 본 약관 등 제반 규정에 따른 계약(이하 '이용계약'이라 합니다.)이 성립합니다.
4. 미성년자는 본 서비스를 이용할 수 없습니다.
5. 이용자가 단독으로 법률행위를 할 능력이 없는 분일 경우 법정대리인의 동의를 얻은 후에 본 서비스를 이용하실 수 있습니다. 본 서비스를 이용하는 경우, 법정대리인이 본 약관 등에 대한 동의를 한 것으로 간주합니다.
6. 본 서비스 이용 시 미성년 또는 단독으로 법률행위를 할 능력이 없는 분(이하 아울러 '제한능력자 등'이라 합니다.)이었던 이용자가 성년이 된 후 또는 행위능력을 취득한 후에 본 서비스를 이용한 경우, 해당 이용자가 미성년자 등이었던 동안의 본  서비스에 관한 일체의 법률행위 및 이용행위를 추인한 것으로 간주합니다.`,
    TITLE_5: `5. 약관의 변경`,
    DETAIL_5: `1. 회사는 다음과 같은 경우에 본 약관 등을 이용자의 동의 없이 변경할 수 있습니다.
    1. 본 약관 등의 변경이 이용자의 일반 이익에 적합할 때.
    2. 본 약관 등의 변경이 계약한 목적에 반하지 않으며, 변경의 필요성, 변경 후의 내용의 상당성, 변경의 내용 기타 변경과 관련된 사정에 비추어 합리적인 것일 때.
2. 회사는 전 항에 의한 본 약관 등을 변경할 때, 변경 사항이 적용된 본 약관 등의 적용일로부터 7일 전부터 적용일자 전날까지, 회원에게 불리하거나 중대한 변경의 경우 적용일 30일 전부터 적용일까지 본 약관 등을 변경하는 이유 및 변경 내용과 그 효력 발생일을 본 서비스에 게시하거나, 그 외에 기타 회사가 적절하다고 판단하는 방법으로 이용자에게 통지합니다.
3. 변경 후의 본 약관 등은 고지한 효력 발생일로부터 그 효력을 발생하게 됩니다.
4. 이용자가 본 약관 등의 변경 후에 본 서비스를 이용하신 경우에는 변경 후의 본 약관 등에 동의한 것으로 간주합니다.
5. 변경된 약관에 동의하지 않는 경우 회사는 이용자와의 계약을 해지할 수 있습니다.`,
    TITLE_6: `6. 계정관리`,
    DETAIL_6: `1. 이용자가 본 서비스 이용시에 이용자 자신에 관한 정보(이하 '등록정보'라 합니다.)를 등록하는 경우, 이용자는 진실, 정확, 완전한 정보를 제공하여야 하며, 항상 최신 정보가 되도록 수정하여야 합니다. 등록 정보에는, 메일 주소 외에 회사가 지정하는 정보가 필요합니다.
2. 이용자가 전항의 수정 등의 조치를 취하지 않았을 경우 또는 이용자가 입력한 등록정보가 부정확·불완전함으로 인하여 이용자가 불이익을 당한 경우라도 회사는 책임을 지지 않습니다.
3. 이용자는 계정에 대해서, 자기의 책임 하에 관리하는 것으로 하고, 이를 제3자에게 이용하게 하거나, 대여, 양도, 명의 변경, 매매 등을 해서는 안 됩니다.
4. 회사는 본 서비스의 이용에 있어 계정을 통해 식별되는 본인이 이용한 것으로 취급할 수 있으며, 해당 이용으로 인해 발생한 결과 및 이에 따른 일체의 책임은 계정을 통해 식별되는 본인에게 귀속됩니다.
5. 계정이 도용되거나 제3자에게 이용되고 있는 것이 판명되었을 경우, 이용자는 즉시 그 사실을 회사에 통지함과 동시에 회사의 지시에 따르는 것으로 합니다.
6. 이용자는 계정의 부정사용으로 인하여 회사 또는 제3자에게 손해가 발생한 경우 회사 및 제3자에게 해당 손해를 배상합니다.
7. 회사는 이용자가 다음 각 호의 어느 하나에 해당하는 경우 또는 해당할 우려가 있는 경우, 사전에 이용자에게 알리지 않고 계정의 생성을 승낙하지 않거나, 계정을 정지 혹은 삭제하거나, 이용자와 회사 사이의 본 서비스에 관한 계약을 해제하는 등, 그 밖에 회사가 필요하고 적절하다고 합리적으로 판단하는 조치를 할 수 있습니다. 회사는, 본 항에 근거해 실시한 행위로 인해 이용자에게 생긴 손해에 대해 일체의 책임을 지지 않습니다.
      1. 폭력단, 폭력단, 폭력단 준구성원, 폭력단 관계기업, 총회꾼 등, 사회운동 표방, 정치활동 표방, 특수지능 폭력집단, 또는 이와 밀접한 관계(자금 및 기타 편익제공행위를 포함하나, 이에 한정되지 않습니다.)를 가진 자 또는 이에 준하는 자인 경우
      2. 회사의 신용을 훼손하는 경우 또는 그 우려가 있다고 회사가 인정한 경우
      3. 적용 법령, 본 약관 또는 개별 이용약관을 위반하거나 위반할 우려가 있다고 회사가 인정한 경우
      4. 수수료 등의 금전채무 불이행이 있었을 경우
      5. 회사로부터의 연락에 대하여 일정기간 답변이 없는 경우
      6. 이용자가 본 서비스에 마지막으로 접속한 날로부터 1년 이상 경과하였을 경우
      7. 전6호외의 사유로 이용자과의 신뢰관계가 상실된 경우 등의 합리적인 판단기준에 따라 회사가 본 서비스의 제공을 하는 것이 적절하지 않다고 판단한 경우
8. 이용자가 본 서비스를 이용함에 있어 갖는 모든 권리는 이유와 상관없이 계정이 삭제된 시점에서 소멸됩니다. 또한 계정을 삭제할 경우 계정을 복구할 수 없으므로 주의하시기 바랍니다.
9. 본 서비스의 계정은 이용자에게 일신전속적으로 귀속됩니다. 이용자가 본 서비스에서 사용하는 모든 이용권은 본 약관 등에 별도의 규정이 있는 경우를 제외하고는 제3자에게 양도, 대여 또는 상속할 수 없습니다.`,
    TITLE_7: `7. 수수료`,
    DETAIL_7: `본 서비스를 이용함에 있어 회사가 별도로 지정하는 플랫폼 업체에의 수수료 및 환불 시 수수료가 발생합니다. 또한 클레이의 GAS 비용, 포인트 구매 및 판매금 청산시에 발생하는 수수료는 이용자 부담입니다.`,
    TITLE_8: `8. 상품의 생성 및 출품`,
    DETAIL_8: `1. 이용자는 상품 생성 후 상품 대금을 클레이 또는 US달러로 설정하여 상품을 출품할 수 있습니다. 출품 후에는 상품 대금 표시 방법을 변경할 수 없습니다.
2. 본 서비스 상에서의 상품 대금 표시는 이용자가 설정한 표시 방법에 따라 표시됩니다.
3. 판매자는 제9조 제1항에서 정하는 매매 계약의 성립 전까지 출품을 취소할 수 있습니다. 또한, 판매자는 매매 계약이 성립된 후 해당 매매 계약을 취소할 수 없습니다.
4. 판매자는 제18조에서 정의하는 부정한 상품이나 금지 상품을 생성 및 출품해서는 안 됩니다.
5. 판매자가 본 약관 등을 위반하여 출품한 경우, 또는 판매자가 실제로 매매 계약을 체결할 의사가 없이 출품했다고 판단했을 경우, 그 밖에 회사가 부적당하다고 판단했을 경우, 회사는 판매자에게 사전에 통지하지 않고 출품을 무효로 할 수 있습니다. 출품이 무효가 되었을 경우, 회사는 해당 거래를 무효로 할 수 있습니다.
6. 회사는 상품에 관해서 제3자가 이의를 제기할 수 있는 루트를 설치할 수 있습니다. 회사는 관련된 이의에 근거하여 회사가 적합하지 않다고 판단했을 경우, 회사의 재량으로 이용자의 승낙을 미리 얻지 않고 상품 출품을 무효로 할 수 있습니다.`,
    TITLE_9: `9. 구매`,
    DETAIL_9: `본 서비스 상에 출품되어 있는 상품에 대해 구매자가 구매 버튼을 누른 시점에 해당 상품에 대한 판매자와 구매자 간의 매매 계약이 동시에 성립하여 해당 상품의 디지털 소유권이 판매자로부터 구매자에게 이전됩니다.
전항의 매매 계약이 성립된 후에는 판매자 및 구매자는 매매 계약을 취소할 수 없으며, 법률에 규정이 있는 경우를 제외하고는 어떠한 이유가 있더라도 성립된 거래의 무효 또는 취소를 주장할 수 없습니다.`,
    TITLE_10: `10. 상품대금의 지불, 판매금의 수령`,
    DETAIL_10: `1. 판매자는 회사에 구매자가 지불한 상품 대금을 대리하여 수령할 수 있는 권한을 부여하며, 회사가 구매자로부터 판매 대금을 수령했을 때 구매자의 상품 대금의 지불 채무는 소멸됩니다.
2. 매매 계약이 성립된 경우, 구매자는 회사가 별도로 지정하는 방법으로 상품 대금을 회사에 지급합니다.
3. 회사는 상품의 양도 기타 매매 계약에 의거한 의무를 판매자가 이행했다고 회사가 판단한 경우, 전항의 규정에 따라 구매자로부터 수령한 상품 대금에서 3%의 수수료 및 0%~10%의 원작자 인세를 공제한 판매금을 판매자의 계좌에 계상하는 것으로 합니다. 계상은 판매자로부터 제4항에서 말하는 판매금에 대한 출금 신청을 받은 후 신속하게 처리합니다.
4. 판매자는 회사에 대해 제2항에 따라 회사가 수령한 판매금에 대해 회사가 별도로 지정하는 절차에 따라 판매자의 계좌에 판매금이 계상된 날로부터 180일 이내에 출금 신청을 해야 합니다. 출금에 필요한 수수료는 판매자가 부담합니다.
5. 판매자는 판매금으로 포인트를 구매할 수 있습니다.
6. 제2항 및 3항의 규정에 관계없이 본 약관 등을 위반한 경우, 또는 기타 회사가 정하는 일정한 이유에 해당하는 경우, 회사는 판매자가 요청한 판매금의 출금을 유보할 수 있습니다. 이 경우 회사의 조사가 완료되지 않는 한 판매금 출금 유보는 계속됩니다.
7. 회사는 제4항의 규정에 의해 판매금을 판매자에 대해 지불함에 있어서 판매자가 회사에 대해 부담하는 채무를 해당 채무의 지불 기한을 불문하고 그에 상당하는 금액으로 상계할 수 있습니다.
8. 회사는 아래의 경우, 판매자가 제4항의 규정에 따른 판매금의 지급을 수령할 권리를 포기한 것으로 간주하고 이를 몰수하거나 수수료를 공제한 금액을 은행 계좌로 이체할 수 있습니다.
      1. 회사가 제4항의 규정에 의한 은행 계좌를 지정하도록 판매자에게 통지했음에도 판매자가 관련된 통지 후 1년 이내에 유효한 은행 계좌를 지정하지 않을 경우
      2. 제4항의 규정에서 정한 기간 내에 매출금의 출금신청을 하지 않는 경우
      3. 회사가 본 서비스를 종료하는 시점에 미수령 상품 대금 총액이 입금 수수료에 미치지 못하는 경우`,
    TITLE_11: `11. 원작자`,
    DETAIL_11: `1. 원작자는 상품을 출품함에 있어 스스로가 해당 상품의 저작권자, 저작인접권자, 기타 권리자 등(이하 '저작권자 등'이라 한다.)이거나 저작권자 등으로부터 정당한 권리 허락을 받고 있으며 해당 상품에 대해 저작권 기타 제3자의 권리를 침해하지 않았음을 보증합니다.
2. 원작자는 별도 회사가 정하는 방식으로 0%~10%의 범위 내에서 원작자 인세를 설정할 수 있습니다.`,
    TITLE_12: `12. 상품의 지식재산권`,
    DETAIL_12: `1. 상품의 저작권을 포함한 지식재산권은 구매자에게 양도되지 않으며 원작자 또는 정당한 권리 보유자에게 귀속됩니다.
2. 상품을 아래의 목적으로 이용하거나 제공하는 것은 지적재산권 침해에 해당되어 금지됩니다
      1. 가정내에서의 이용 범위를 넘어 타인에게 제공하는 경우
      2. 상업적 용도로 이용하는 경우
      3. 상품에 포함된 이미지, 동영상, 음악 등을 변경하거나 가공하여 이용하는 경우. 단 회사가 제휴하고 있는 사업자가 제공하는 콘텐츠로의 이용은 가능합니다.
3. 상품은 구매자에 한해 자신의 로컬 컴퓨터 및 기타 기억기기 등으로 다운로드하여 저장할 수 있습니다. 또한 구매자가 비상업적목적으로 이용하는 것을 허가합니다.
4. 구매자는 상품의 디지털 소유권을 취득하지만 저작권을 보유한 원작자는 그 제작 활동에서 작품 전시나 포트폴리오 등을 통해 작품을 소개하는 것을 허락합니다.
5. 회사는 상품에 대해서 본 서비스의 원활한 제공, 시스템의 구축·개량·유지보수 및 본 서비스의 광고·선전 등에 필요한 범위에서 사용(게재, 편집을 포함하며 이에 한정되지 않습니다.)할 수 있는 것으로 합니다.
6. 회사는 전 항에 따라 상품을 사용하는 경우 상품 데이터의 일부를 생략, 리사이즈, 편집하여 원작자명 표시를 생략할 수 있습니다.`,
    TITLE_13: `13. 포인트`,
    DETAIL_13: `1. 이용자는 외부결제서비스 등 회사가 별도로 지정하는 방법으로 본 서비스 내에서 사용할 수 있는 서비스 내 통화 '포인트'를 구매할 수 있습니다. 포인트 구매 단가는 회사가 결정하며, 이용자에게 사전 통지 없이 변경할 수 있습니다.
2. 회사는 캠페인 등에 의해 이용자에게 포인트를 무상으로 부여할 수 있습니다. 유상포인트와 무상포인트가 존재할 경우 무상포인트부터 소비됩니다.
3. 이용자는 포인트를 타인과 교환하거나 현금 그 외의 경제적 이익(단, 회사가 허용하는 경우를 제외합니다.)과 교환해서는 안 됩니다.
4. 포인트는 이유 여하를 불문하고 환불할 수 없습니다. 다만, 법령상 필요한 경우는 제외합니다.
5. 마지막으로 상품을 출품 또는 구입한 후 1년 이상 경과한 이용자에 대하여 회사는 해당 이용자의 동의 없이 무상으로 남은 포인트를 소각할 수 있습니다.
6. 다음과 같은 경우에 포인트 결제 승인이 거부될 수 있습니다.
      1. 결제수단을 이용하여 법률에 위반되는 행위를 하는 경우
      2. 신청항목의 필수적 기재사항이 누락되거나 잘못 작성한 경우
      3. 만 19세 미만의 미성년자인 이용자가 법정대리인의 동의 없이 신청하거나, 법정대리인이 취소를 요청하는 경우
      4. 회원 본인 명의의 계정이 아니거나 결제수단 소유자의 동의 또는 허락 없이 결제수단을 무단으로 사용하여 승인을 요청한 경우
      5. 그 밖에 이용자의 귀책사유로 인해 승인이 불가능하다고 판단되는 경우
7. 포인트에는 이자수익이 없습니다.`,
    TITLE_14: `14. 토큰(CBLT)의 취급`,
    DETAIL_14: `1. 회사는 이용자에 대하여 토큰(CLBT)를 무상으로 배포할 수 있습니다. 토큰(CLBT)의 배포 기준이나 시기, 수량 등은 회사가 정하며, 이용자에 대한 사전 통지 없이 변경할 수 있습니다.
2. 토큰(CLBT)을 이용하기 위해서 별도의 지갑과 연동하는 것이 필요합니다.`,
    TITLE_15: `15. 본 서비스의 제공, 중지, 종료`,
    DETAIL_15: `1. 이용자는 본 서비스를 이용함에 있어 필요한 기기, 통신 환경 및 전력 등을 이용자의 비용과 책임으로 준비해야 합니다.
2. 회사는 본 서비스의 전부 또는 일부를 회사가 필요하다고 판단하는 조건을 충족한 이용자에 한정하여 제공할 수 있습니다.
3. 회사는 회사가 필요하다고 판단되는 경우, 언제든지 본 서비스의 전부 또는 일부 내용을 변경하거나 그 제공을 중지 및 종료할 수 있습니다. 단, 해당 회원에게 사전에 해지사유를 통지하고 그에 대한 의견 진술의 기회를 부여할 수 있습니다. 또한 회사는 이로 인해 이용자에게 발생한 손해에 대하여 일체의 책임을 지지 않습니다.`,
    TITLE_16: `16. 타사 서비스`,
    DETAIL_16: `본 서비스의 이용에 있어 회사가 지정하는 디지털 에셋 및 암호 자산의 보관 기능(이하 '지갑'이라 합니다.) 및 스마트 컨트랙트 시스템(지갑이라고 총칭하며 이하 '타사 서비스'라 합니다.) 기타 회사가 지정하는 서비스를 이용해야 하며, 이들 타사 서비스는 회사 이외의 사업자에 의해 제공됩니다. 타사 서비스에 관한 책임은 해당 타사 서비스를 제공하는 사업자가 부담하는 것으로 하며, 회사는 일체의 책임을 지지 않습니다.`,
    TITLE_17: `17. 금지사항`,
    DETAIL_17: `1. 이용자는 본 서비스를 이용함에 있어 다음과 같은 행위를 하여서는 안 됩니다.
    1. 법령 또는 선량한 풍속 기타 사회질서에 위반되는 행위 또는 이를 위반할 우려가 있는 행위
    2. 진정으로 매매계약을 체결할 의사 없이 출품 또는 구매하는 행위
    3. 회사 또는 제3자(다른 이용자를 포함합니다.)의 저작권, 상표권, 특허권 등의 지적재산권, 기타 권리를 침해하는 행위
    4. 회사 또는 제3자의 서버 또는 네트워크 시스템의 기능을 파괴하거나 지장을 주는 행위
    5. 회사의 본 서비스 운영을 방해할 우려가 있는 행위
    6. 악성적인 접근 행위나 제3자의 계정 이용 행위 또는 이를 시도하는 행위
    7. 포인트를 부정하게 취득, 이용하는 행위
    8. 본 서비스의 다른 이용자 또는 기타 제3자에게 불이익, 손해, 불쾌감을 주는 행위
    9. 다른 이용자의 행세를 하는 행위
    10. 다른 이용자에 관한 개인정보 등을 수집 또는 축적하는 행위
    11. 선전, 광고, 권유, 영업 및 기타 영리를 목적으로 하는 행위
    12. 안면이 없는 이용자 간의 만남을 목적으로 하는 행위
    13. 반사회적 세력에 대하여 직접 또는 간접으로 이익을 공여하거나 그 밖의 협력을 하는 행위
    14. 기타 본 서비스가 예정하고 있는 이용목적과 다른 목적으로 본 서비스를 이용하는 행위
    15. 본 조의 금지행위를 할 목적으로 본 서비스를 복수의 계정으로 사용하는 행위
    16. 기타 회사가 부적절하다고 판단하는 행위
2. 회사는 이용자가 본 약관 등을 위반하거나 위반할 우려가 있다고 회사가 인정했을 경우, 해당 회원에게 사전에 사유를 통지하고 해당 위반 행위의 시정을 위한 적절한 조치(계정 삭제를 포함하며 이에 한정되지 않습니다.)를 취할 수 있습니다. 회사는 이 과정에서 이용자에게 위반행위에 대한 의견 진술의 기회를 부여할 수 있습니다. `,
    TITLE_18: `18. 부정한상품/금지상품에 대하여`,
    DETAIL_18: `1. 이용자는 다음에 해당하는 상품을 생성하거나 출품할 수 없습니다.
    1. 저작권 기타 제3자의 권리를 침해하는 것
    2. 포르노 사진, 노골적인 성적 표현, 기타 외설적인 내용을 포함하는 것.
    3. 폭력적, 기이한 사진 등 일반 이용자가 불쾌하게 느낄 수 있는 것.
    4. 법령, 선량한 풍속 기타 사회질서에 반하거나 그 우려가 있는 내용을 포함하는 것.
    5. 인종, 국적, 신조, 성별, 신분 등에 따른 차별로 이어지는 표현을 포함하는 것.
    6. 자살, 자살행위, 약물남용, 불법약물사용 등을 유인 또는 조장하는 표현을 포함하는 것.
    7. 회사 또는 특정 기업, 법인, 단체, 지역, 개인에 대한 비방 중상·불만, 차별 발언 등의 부적절한 표현을 포함하는 것.
    8. 범죄 수익의 이전과 관련된 것.
    9. 그 외에 회사가 본 서비스의 이용 목적에 비추어 부적절하다고 판단하는 것.
2. 이용자는 전 항 외에 다음과 같은 상품을 생성하거나 출품할 수 없습니다. 
    1. 유가증권
    2. 암호자산
    3. 부동산의 소유권, 자동차의 소유권, 기타 이전에 관해서 등기제도나 등록제도가 존재하는 상품과 연관되는 NFT
    4. 그 외에 법령상 매매 또는 이전에 면허, 등록, 신고, 등기가 필요한 상품
    5. 그 외에 민법 제98조에서 정하는 유체물과 연관한 NFT
3. 회사는 전 2항에 해당하는 상품이 생성, 출품되었다고 판단한 경우 사전에 이용자에 통지하지 않고 상품을 삭제 또는 그 외의 방법으로 위법행위를 시정하기 위한 적절한 조치를 취할 수 있습니다.`,
    TITLE_19: `19. 보증`,
    DETAIL_19: `이용자는 회사에 대하여 다음 각 호의 사항을 표명하고 확약해야 합니다.

1. 이용자가 폭력단, 폭력단 관계 기업, 총회꾼 또는 이에 준하는 자 또는 그 구성(이하 총칭하여 '반사회적 세력'이라 합니다.)이 아니며, 앞으로도 그렇지 않을 것.
2. 이용자가 법인 등 단체인 경우에 있어서, 이용자의 임원(업무집행사원, 이사, 집행임원 또는 이것들에 준하는 사람을 말합니다.)이 반사회적 세력이 아니고, 향후도 그렇지 않을 것.
3. 반사회적 세력으로 하여금 자신의 명의를 이용하게 하여 이 계약을 체결하지 않을 것.
4. 이용자 또는 제3자를 이용하여 이 계약에 관해 다음의 행위를 하지 말 것.
      1. 회사에 대한 위협적인 언동 또는 폭력을 사용하는 행위
      2. 위계 또는 위력으로 회사의 업무를 방해하거나 신용을 훼손하는 행위
5. 반사회적 세력으로부터 명목 여하를 불문하고 자본·자금 등을 받아들이는 행위를 하지 않을 것
6. 반사회적 세력에 대하여 명목 여하를 불문하고 자본·자금 등을 제공하는 행위를 하지 않을 것
7. 기타 전 각 호에 준하는 행위를 하지 말 것`,
    TITLE_20: `20. 본 서비스 제공의 중지, 중단`,
    DETAIL_20: `1. 회사는 다음 어느 하나의 사유가 발생하였다고 판단한 경우, 이용자에게 사전에 통지하는 바 없이 본 서비스의 전부 또는 일부의 제공을 중지 또는 중단할 수 있습니다.
    1. 본 서비스에 관한 시스템 등의 보수점검 또는 갱신을 하는 경우
    2. 지진, 낙뢰, 화재, 정전 또는 천재지변 등의 불가항력으로 인하여 본 서비스 제공이 곤란해진 경우
    3. 타사 서비스의 전부 또는 일부의 제공이 정지·중단 등인 경우
    4. 본 서비스와 관련된 시스템 등 또는 통신회선 등이 사고 기타 사유로 정지된 경우
    5. 기타 회사가 본 서비스 제공이 곤란하다고 판단했을 경우
2. 회사는 본 서비스의 제공 중지로 인한 이용자 또는 제3자가 입은 어떠한 불이익 또는 손해에 대하여도 책임지지 않습니다.`,
    TITLE_21: `21. 이용자의 책임`,
    DETAIL_21: `1. 이용자는 이용자 자신의 책임과 비용 부담으로 본 서비스를 이용하는 것으로 하며, 본 서비스에서 행한 일체의 행위 및 그 결과에 대해  제3자로부터 클레임, 청구 또는 소송 등의 제기(이하, 이들을 총칭하여 '클레임 등'이라 합니다.)를 받은 경우, 스스로의 책임과 비용으로 이에 대응해야 합니다. 회사가 상품을 생성함은 본 약관에서 규정하는 이용자의 책임을 부정하는 것은 아닙니다.
2. 이용자는 본 서비스를 이용한 것에 기인하여(회사가 이러한 이용을 원인으로 하는 클레임을 제3자로부터 받은 경우를 포함합니다.) 회사가 직접적 또는 간접적으로 어떠한 손해(변호사 비용의 부담을 포함합니다.)를 입었을 경우 회사의 청구에 따라 즉시 이를 배상하여야 합니다.
3. 본 서비스가 제공하는 모든 자산 거래에 발생하는 세금(과세되는 모든 경우를 포함합니다.)을 결정할 책임은 전적으로 이용자에게 있습니다. 회사는 본 서비스상의 이용으로 인해 이용자에게 발생하는 과세의무에 대한 책임을 지지 않습니다.`,
    TITLE_22: `22. 비보증`,
    DETAIL_22: `회사는 본 서비스에 사실상 또는 법률상 하자(보안 등에 관한 결함, 에러, 버그, 권리침해 등을 포함합니다.)가 없을 것을, 또한 안전성, 신뢰성, 정확성, 완전성, 유효성 및 특정 목적에 적합함을 명시적으로도 묵시적으로도 보증하지 않습니다. 회사는 이용자가 출품한 NFT 아이템 및 클레이튼의 기술상 문제에 대해서도 보증하지 않습니다.`,
    TITLE_23: `23. 회사의 면책사항`,
    DETAIL_23: `1. 회사는 본 서비스에 기인해 이용자에게 발생한 모든 손해에 대해 일체의 책임을 지지 않습니다. 단, 이용계약이 전자상거래 등에서의 소비자 보호에 관한 법률 등의 적용을 받는 경우 본 항의 면책규정은 적용되지 않으며, 회사는 이용자가 본 서비스를 이용하며 입은 손해를 배상할 책임을 집니다(회사의 책임을 질 수 없는 사유로 인한 것일 때를 제외합니다.).
2. 전항 단서에서 정하는 경우에도, 회사는 회사의 과실(중과실을 제외합니다.)에 의한 채무 불이행 또는 불법 행위에 의해 이용자에게 생긴 손해 중 특별한 사정으로부터 생긴 손해(회사 또는 유저가 손해 발생에 대해 예견하거나, 예견할 수 있었을 경우를 포함합니다.)에 대해 일체의 책임을 지지 않습니다. 또한, 회사의 과실(중과실을 제외합니다.)에 의한 채무 불이행 또는 불법 행위에 의해 이용자에게 생긴 손해의 배상은 이용자로부터 해당 손해가 발생한 달에 수령한 수수료의 액수를 상한으로 합니다.
3. 회사는 본 서비스에 관하여 이용자와 다른 이용자 또는 제3자 간에 발생한 거래, 연락 또는 분쟁 등에 대하여 일절 책임을 지지 않습니다.
4. 회사는 상품이 정당한 권리자의 저작권, 상표권, 특허권 등의 지적 재산권, 그 외 권리를 침해하고 있지 않음을 보증하고 있지 않습니다.
5. 법령의 신설·개정 등에 의해 본 서비스가 종료되어도 그로 인해 이용자에게 발생한 손해에 대하여 회사는 일체의 책임을 지지 않습니다.`,
    TITLE_24: `24. 개인정보의 취급`,
    DETAIL_24: `회사는 본 서비스를 제공함에 따라 취득하는 이용자의 개인정보에 대해 회사의 개인정보처리방침에 따라 취급합니다.`,
    TITLE_25: `25. 통지 및 연락`,
    DETAIL_25: `1. 이용자와 회사와의 통지 또는 연락은, 회사가 정하는 방법에 의해서 실시하는 것으로 합니다.
2. 회사는 별도로 정하는 방식에 따른 변경 신고가 없는 한, 현재 등록되어 있는 이용자의 연락처 그 외 이용자의 정보가 유효한 것으로 간주해 해당 연락처에 통지 또는 연락을 실시합니다.
3. 전항의 변경 신고가 이루어지지 않은 것으로 인해 회사로부터의 통지가 지연 또는 도달하지 않은 경우에도 통상 도달하는 때에 이용자에게 도달한 것으로 간주합니다.`,
    TITLE_26: `26. 권리의무의 양도 금지`,
    DETAIL_26: `이용자는 회사와의 서면에 의한 사전 승낙 없이 이용계약상의 지위 및 본 약관에 의한 권리 및 의무를 제3자에게 양도하거나 담보로 제공할 수 없습니다.`,
    TITLE_27: `27. 문의`,
    DETAIL_27: `본 서비스에 관한 문의는 support@cobalt.zone으로 받고 있습니다.`,
    TITLE_28: `28. 지식재산권 보호`,
    DETAIL_28: `1. 본 서비스(규약, 고지, 기사, 비디오, 오디오, 화상, 아카이브, 정보, 자료, 상표 또는 로고를 포함하며 이에 한하지 않습니다.)에 표시되는 모든 컨텐츠의 지식재산권은, 회사 또는 제3자의 라이선스에 의해서 보호되고 있습니다.
2. 본 서비스 또는 제3자인 라이센서로부터 사전 서면 동의 없이 누구도 위의 애플리케이션 및 콘텐츠를 사용, 수정, 역컴파일, 재생, 공개, 배포, 발행할 수 없습니다`,
    TITLE_29: `29. 준거법 및 재판관할`,
    DETAIL_29: `1. 본 약관의 해석은 대한민국법령을 준거법으로 합니다.
2. 본 서비스에 관하여 분쟁이 생긴 경우, 동경지방재판소 혹은 동경간이재판소를 제1심의 전속적 합의관할로 합니다. `,
    UPDATED: `2021. 08. 01 제정`,
  };

  static readonly ja = {
    TITLE: `サービス利用規約`,

    DESC: `POST VOYAGER PTE.LTD.（以下、「当社」といいます。）は、このウェブサイト上で提供するサービスCobalt（以下、「本サービス」といいます。）について、この利用規約（以下「本規約」といいます）により、当社とお客さまとの間の権利義務関係について規定します。
本規約は、お客さまが本サービスを利用するにあたっての、当社とお客さまとの間に適用される契約内容となります。
当社は、本規約の全文をお読みいただいたうえで、本規約に同意していただいたお客様に対してのみ、本サービスの利用許諾を行います。`,

    TITLE_1: `1. 本サービスについて`,

    DETAIL_1: `本サービスは、ブロックチェーン上のデジタルアイテムや音声データ、デジタルアート、動画データ等のNFT（Non-Fungible Token）を売買できるプラットフォームです。お客様間の出品、購入等に関して、すべてお客様の自己責任にて行っていただきます。当社は、お客様の出品が正当な権利に基づくものであること等について、保証しません。また、当社は、自ら売買を行うものではなく、売買の委託を受けるものでもなく、また、売買の遂行にも一切関与するものではありません。`,
    TITLE_2: `2. 定義`,

    DETAIL_2: `1. 「商品」とは、本サービスにおいて取引の目的となるデジタルアイテムや音声データ、デジタルアート、動画データ等のNFTをいいます。,
2. 「商品代金」とは、出品者が本サービス上において商品を出品する際に登録する当該商品の価格をいい、クレイまたはポイントで表示されます。
3. 「売上金」とは、商品代金からプラットフォーム手数料およびクリエイター印税を控除した価格をいいます。
4. 「お客様」とは、本規約に同意のうえ、本サービスを利用する者をいいます。
5. 「出品者」とは、本サービスを通じて商品を出品するお客様をいいます。
6. 「購入者」とは、本サービスを通じて商品を購入するお客様をいいます。
7. 「クリエイター」とは、商品を創作した人（著作権者を含み、これに限られない）等をいいます。
8. 「クリエイター印税」とは、本サービス内で商品が転売されるたびにクエリエイターに支払われる報酬のことをいいます。
9. 「デジタル所有権」とは、NFTの秘密鍵を管理し、NFTの移転等をブロックチェーン上で行える権利を意味し、民法上の所有権を意味しません。
10. 「不正商品・禁止商品」とは、データ改ざん、不正なツールの使用その他の不適切な方法で作成された商品および本規約において当社が禁止する事項に該当する商品をいいます。
11. 「ポイント」とは、本サービス内で使用できる１ポイント＝１USドルのサービス内通貨のことをいいます。`,
    TITLE_3: `3. 規約の適用`,

    DETAIL_3: `1. 本規約は、お客様と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。,
2. 当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といい、本規約とあわせて「本規約等」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
3. 本規約の規定が前項の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
4. 本規約等の一部が法令に基づき無効または合意しなかったものと判断された場合であっても、本規約等のその他の規定はなお有効とします。また、本規約等の全部若しくは一部が特定のお客様との間で無効とされ、取り消され、または合意しなかったと判断された場合であっても、本規約等はその他のお客様との間ではなお有効とします。`,

    TITLE_4: `4. 規約への同意`,

    DETAIL_4: `1. お客様は、予め本規約等に同意した上で本サービスを利用するものとします。,
本規約等に同意いただけない場合、お客様は本サービスを利用することはできませんので、その場合は直ちに本サービスの利用を中止してください。
2. お客様が本サービス利用を開始した時点で、お客様は、本規約等に同意したものとみなされます。また、このとき、お客様と当社との間で、本規約等の諸規定に従った契約（以下、「利用契約」といいます。）が成立します。
3. 未成年者は、本サービスを利用することができません。
4. お客様が単独で法律行為をする能力を有しない方である場合、法定代理人の同意を得た上で、本サービスを利用してください。本サービスを利用する場合、法定代理人による本規約等への同意を得たものとみなします。
5. 本サービス利用時に未成年または単独で法律行為をする能力を有しない方（以下あわせて「制限行為能力者等」といいます。）であったお客様が成年に達した後または行為能力を取得した後に本サービスを利用した場合、当該お客様が未成年者等であった間の本サービスに関する一切の法律行為および利用行為を追認したものとみなします。`,

    TITLE_5: `5. 本規約等の変更`,

    DETAIL_5: `1. 当社は、次に掲げる場合には、本規約等を、個別のお客様の同意なく変更することができます。,
・本規約等の変更が、お客様の一般の利益に適合するとき。
・本規約等の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。
当社は、前項による本規約等の変更にあたり、変更後の本規約等の効力発生日までに、本規約等を変更する旨および変更後の本規約等の内容とその効力発生日を本サービスに掲示する方法その他当社が適切と判断する方法2. によりお客様に通知します。
3. 変更後の本規約等は、周知された効力発生日からその効力を生じるものとします。
4. お客様が本規約等の変更後に本サービスをご利用された場合は、変更後の本規約等に同意したものとみなします。`,

    TITLE_6: `6. アカウントの管理`,

    DETAIL_6: `1. お客さまは、本サービスの利用に際してお客様ご自身に関する情報（以下、「登録情報」といいます。）を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければなりません。登録情報には、メールアドレスの他当社が指定する情報が必要となります。,
2. お客様が前項の修正等の措置を取らなかった場合、またはお客様が入力した登録情報が不正確・不完全であったことにより、お客様が不利益を被った場合であっても、当社は一切の責任を負わないものとします。
3. お客様は、アカウントについて、自己の責任において管理するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
4. 当社は、本サービスの利用があった場合、アカウントにより識別される本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、アカウントにより識別される本人に帰属するものとします。
5. アカウントが盗用されまたは第三者に利用されていることが判明した場合、お客様は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
6. お客様は、アカウントの不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。
7. 当社は、お客様が以下の各号のいずれかに該当する場合または該当するおそれがある場合、あらかじめお客様に通知することなく、アカウントの作成を承諾しないこと、アカウントを停止もしくは削除すること、お客様と当社間での本サービスに関する契約を解除すること、その他当社が必要かつ適切と合理的に判断する措置を講じることができるものとします。当社は、本項に基づき行なった行為によりお客様に生じた損害について一切の責任を負いません。
     1. 暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力集団、またはこれらと密接な関わり（資金その他の便益提供行為を含みますが、これらに限 られません。）を有する者もしくはこれらに準じる者である場合
     2. 当社の信用を毀損する場合またはそのおそれがあると当社が認めた場合
     3. 適用のある法令、本規約または個別利用規約に違反しまたは違反するおそれがあると当社が認めた場合
     4. 手数料等の金銭債務の不履行があった場合
     5. 当社からの連絡に対し、一定期間返答がない場合
     6. お客様の本サービスに対する最終のアクセスから1年以上が経過した場合
     7. 前6号の他、お客様との信頼関係が失われた場合など、合理的な判断基準に基づき、当社が本サービスの提供が適切でないと判断した場合
     8. お客様の本サービスにおけるすべての権利は、理由を問わず、アカウントが削除された時点で消滅します。また、アカウントの削除が行われた場合、アカウントの復旧はできませんのでご注意ください。
     9. 本サービスのアカウントは、お客様に一身専属的に帰属します。お客様の本サービスにおけるすべての利用権は、本規約等に別の定めがある場合を除き、第三者に譲渡、貸与または相続させることはできません。`,

    TITLE_7: `7. 手数料`,

    DETAIL_7: `本サービスの利用には、当社が別途指定するプラットフォーム手数料および換金時の手数料が発生します。また、クレイのGAS代、ポイント購入時および売上金の清算時に発生する手数料は、お客様の負担となります。`,

    TITLE_8: `8. 商品の生成及び出品`,

    DETAIL_8: `1. お客様は、商品を生成した後、商品代金をクレイまたはUSドルで設定し、商品を出品することができます。出品後は、商品代金の表示方法は変更できません。,
2. 本サービス上での商品代金の表示については、お客様が設定した表示方法により表示されます。
3. 出品者は、第9条第1項で定める売買契約の成立前まで出品を取り消すことができます。なお、出品者は、売買契約成立後は当該売買契約をキャンセルすることはできません。
4. 出品者は、不正商品・禁止商品を生成及び出品してはなりません。
5. 出品者が本規約等に違反した出品を行った場合、または出品者が真に売買契約を締結する意思のない出品を行ったと当社が判断した場合その他当社が不適当と判断した場合、当社は、出品者に事前に通知することなく、出品を無効とすることができます。出品が無効となった場合、当社は当該出品に対する購入を無効とすることができます。
6. 当社は、商品に関して第三者が通報する窓口を設置することができます。当社は、かかる通報に基づき、当社が相応しくないと判断した場合、当社の裁量において、あらかじめお客様の承諾を得ることなく、商品の出品を無効とすることができます。`,

    TITLE_9: `9. 購入`,

    DETAIL_9: `1. 本サービス上に出品されている商品について、購入者により購入ボタンが押された時点で、当該商品についての出品者と購入者との間の売買契約が同時に成立し、当該商品のデジタル所有権が出品者から購入者へ移転します。,
2. 前項の売買契約成立後は、出品者および購入者は売買契約をキャンセルすることはできないものとし、法律に定めがある場合を除き、いかなる理由があっても成立した取引の無効または取り消しを主張しないものとします。`,

    TITLE_10: `10. 商品代金の支払い、売上金の受領について`,

    DETAIL_10: `1. 出品者は、当社に購入者からの商品代金の支払いの代理受領権限を付与するものとし、当社が購入者から出品代金を受領したときに、購入者の商品代金の支払債務は消滅するものとします。,
2. 売買契約が成立した場合、購入者は、当社が別途指定する方法により、商品代金を当社に対して支払うものとします。
3. 当社は、商品の譲渡その他売買契約に基づく義務出品者が履行したと当社が判断した場合、前項の規定により購入者より受領した商品代金から手数料およびクリエイター印税を控除した売上金について、出品者のアカウントに計上するものとし、次項に定める売上金の出金申請が行われたことを条件として、出品者に対して速やかに支払うものとします。
4. 出品者は当社に対し、第2項により当社が受領した売上金について、当社が別途指定する手続きに従い、出品者のアカウントに売上金が計上された日から180日以内に出勤申請を行うものとします。出金に要する手数料は出品者の負担とします。
5. 出品者は、売上金でポイントを購入することができます。
6. 第2項および3項の規定にかかわらず、本規約等の違反その他当社が定める一定の理由に当てはまる場合は、当社は出品者による売上金の受領を留保することができます。この場合、当社の調査が完了しない限り、代金支払いの留保は継続するものとします。
7. 当社は、第4項の規定により売上金を出品者に対して支払うにあたり、出品者が当社に対して負担する債務を、当該債務の支払期限を問わず、対当額をもって相殺することができます。
8. 当社は、以下の場合、出品者が第4項の規定による売上金の支払いを受領する権利を放棄したものとみなし、これを没収または手数料を差し引いた金額を振込先銀行口座へ振り込むことができます。
     1. 当社が第4項の規定による支払いの振込先銀行口座を指定するよう出品者に通知したにもかかわらず、出品者が、かかる通知後1年以内に、有効な振込先銀行口座を指定しない場合
     2. 第4項の規定に定める期間内に売上金の出金申請を行わない場合
     3. 当社が本サービスを終了する時点で、未受領の商品代金総額が振込手数料に満たない場合`,

    TITLE_11: `11. クリエイターについて`,

    DETAIL_11: `1. クリエイターは、商品の出品にあたり、自らが当該商品の著作権者、著作隣接権者、その他権利者等（以下「著作権者等」という）であり、または著作権者等から正当な権利許諾を受けており、当該商品について著作権その他第三者の権利を侵害していないことを保証するものとします。,
2. クリエイターは、別途当社が定める方法により、クリエイター印税を設定することができます。`,

    TITLE_12: `12. 商品の知的財産権`,

    DETAIL_12: `1. 商品の著作権を含む知的財産権は購入者に譲渡されず、クリエイターまたは正当な権利保有者に帰属します。,
2. 商品を下記の目的で利用または提供することは、知的財産権の侵害に当たり、禁止されます。
     1. 家庭内での利用の範囲を超えて他人に提供すること
     2. 商用目的で利用すること
     3. 商品に含まれる画像や動画、音楽等を改変や加工して利用すること
     ただし、当社が提携している事業者が提供するコンテンツでの商品の利用は行っていただけます。
3. 商品は、購入者に限り自身のローカル上のパソコンや、その他記憶デバイス等へダウンロードし保存することを許可します。また、購入者が非商用目的で利用することを許可します。
4. 購入者は商品のデジタル所有権を取得しますが、著作権を保持するクリエイターはその制作活動において作品の展示やポートフォリオなどで作品を紹介することを許諾するものとします。
5. 当社は、商品について、本サービスの円滑な提供、システムの構築・改良・メンテナンスおよび本サービスの広告・宣伝等に必要な範囲で使用（掲載、編集を含み、これに限られません。）することが出来るものとします。
6. 当社は、前項に基づき商品を使用する場合、商品データの一部を省略・リサイズ・編集し、クリエイター名表示を省略することができるものとします。`,

    TITLE_13: `13. ポイント`,

    DETAIL_13: `1. お客様は、外部決済サービス等の当社が別途指定する方法により、本サービス内で使用できるサービス内通貨「ポイント」を購入することができます。ポイントの購入単価は当社が決め、お客様への事前の通知なく変更することができます。,
2. 当社はキャンペーンなどによって、お客様にポイントを無償で付与することがあります。有償のポイントと無償のポイントが存在する場合、無償のポイントから先に消費されます。
3. お客様は、ポイントを他人と交換したり、現金その他の経済的利益（ただし、当社が許容する場合を除きます）と交換したりしてはなりません。
4. ポイントは、理由のいかんを問わず払い戻すことはできません。ただし、法令上必要な場合はこの限りではありません。
5. 最後に商品を出品または購入してから1年以上経過しているお客様については、当社は、当該お客様の同意なく、無償で残存ポイントを償却することができます。`,

    TITLE_14: `14. トークン（CBLT）の扱い`,

    DETAIL_14: `1. 当社は、お客様に対しトークン（CBLT）を無償で配布することができます。トークン（CBLT）の配布基準や時期、数量等は当社が決め、お客様への事前の通知なく変更することができます。,
2. トークン（CBLT）を利用するためには、別途WALLET連携が必要です。`,

    TITLE_15: `15. 本サービスの提供、中止、終了`,

    DETAIL_15: `1. お客様は、本サービスを利用するにあたり、必要な機器、通信環境および電力等を、お客様の費用と責任で用意しなければなりません。,
2. 当社は、本サービスの全部または一部を、当社が必要と判断する条件を満たしたお客様に限定して提供することができるものとします。
3. 当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本サービスの全部または一部の内容を変更し、または、その提供を中止・終了することができるものとします。また、当社は、これによってお客様に生じた損害について一切の責任を負いません。`,

    TITLE_16: `16. 他社サービス`,

    DETAIL_16: `本サービスの利用にあたっては、当社の指定するデジタルアセットおよび暗号資産の保管機能（以下、「ウォレット」といいます。）およびスマートコントラクトシステム（ウォレットと総称して、以下「他社サービス」といいます。）その他当社が指定するサービスの利用が必要となりますが、これら他社サービスは当社以外の事業者により提供されます。他社サービスに関する責任は当該他社サービスを提供する事業者が負担するものとし、当社は一切の責任を負いません。`,

    TITLE_17: `17. 禁止事項`,

    DETAIL_17: `1.お客様は、本サービスの利用にあたり、次に掲げる行為を行ってはなりません。,
     1. 法令または公序良俗に違反する行為、またはこれらに違反する恐れのある行為
     2. 真に売買契約を締結する意思なく出品または購入する行為
     3. 当社または第三者（他のお客様を含みます）の著作権、商標権、特許権等の知的財産権、その他権利を侵害する行為
     4. 当社または第三者のサーバーまたはネットワークシステムの機能を破壊したり、支障を与えたりする行為
     5. 当社のサービスの運営を妨害するおそれのある行為
     6. 不正アクセス行為、第三者のアカウントの利用行為、またはこれらを試みる行為
     7. ポイントを不正に取得、利用する行為
     8. 本サービスの他のお客様またはその他の第三者に不利益、損害、不快感を与える行為
     9. 他のお客様に成りすます行為
     10. 他のお客様に関する個人情報等を収集または蓄積する行為
     11. 宣伝、広告、勧誘、営業その他営利を目的とする行為
     12. 面識のないお客様同士の出会いを目的とする行為
     13. 反社会的勢力に対して直接または間接に利益を供与し、またはその他の協力をする行為
     14. その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
     15. 本条の禁止行為をおこなう目的で、本サービスを複数のアカウントにより使用する行為
     16. その他、当社が不適切と判断する行為
2. 当社は、お客様が本規約等に違反しまたは違反するおそれがあると当社が認めた場合、事前にお客様に通知することなく、当該違反行為の是正のために適切な措置（アカウントの削除を含みこれに限られません。）をとることができます。`,

    TITLE_18: `18. 不正商品・禁止商品について`,

    DETAIL_18: `1. お客様は、下記に該当する商品を生成および出品することができません。,
     1. 著作権その他第三者の権利を侵害するもの
     2. ポルノ写真、露骨な性的表現、その他猥褻な内容を含むもの。
     3. 暴力的、グロテスクな写真など、一般のお客様が不快に感じるもの。
     4. 法令、公序良俗に反する、またはそのおそれのある内容を含むもの
     5. 人種、国籍、信条、性別、身分などによる差別につながる表現を含むもの。
     6. 自殺、自殺行為、薬物乱用、違法薬物使用などを誘引または助長する表現を含むもの。
     7. 当社または特定の企業、法人、団体、地域、個人に対しての誹謗中傷・苦情、差別発言などの不適切な表現を含むもの。
     8. 犯罪収益の移転に関連するもの
     9. その他運営側が本サービスの予定する利用目的に照らし、不適切と判断するもの。
2. 前項のほか、お客様は、下記に該当する商品を生成および出品することはできません。
     1. 有価証券
     2. 暗号資産
     3. 不動産の所有権、自働車の所有権、その他、移転に際して登記制度や登録制度が存在する物品に紐づくNFT
     4. その他、法令上、売買又は移転に免許、登録、届出、登記が必要になる商品
     5. その他、広く民法第85条に定める「有体物」に紐づくNFT
3. 当社は、前2項に該当する商品が生成・出品されていると当社が判断した場合、事前にお客様に通知することなく、商品の削除その他違法行為の是正のために適切な措置をとることができます。`,

    TITLE_19: `19. 表明保証`,

    DETAIL_19: `お客様は、当社に対し、次の各号の事項を表明し、また確約するものとします。,

1. 自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者またはその構成（以下総称して「反社会的勢力」といいます。）ではなく、今後もそうではないこと。
2. 自らが法人等団体である場合において、自らの役員（業務を執行する社員、取締役、執行役またはこれらに準ずる者をいいます。）が反社会的勢力ではく、今後もそうではないこと。
3. 反社会的勢力に自己の名義を利用させ、この契約を締結するものでないこと。
4. 自らまたは第三者を利用して、この契約に関して次の行為をしないこと。
ア 当社に対する脅迫的な言動または暴力を用いる行為
イ 偽計または威力を用いて当社の業務を妨害し、または信用を毀損する行為
5. 反社会的勢力から名目の如何を問わず、資本・資金等を受け入れる行為をしないこと
6. 反社会的勢力に対して名目の如何を問わず、資本・資金等を提供する行為をしないこと
7. その他前各号に準じる行為をしないこと`,

    TITLE_20: `20. 本サービスの提供の停止・中断`,

    DETAIL_20: `1. 当社は、以下のいずれかの事由があると判断した場合、お客様に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。,
     1. 本サービスにかかるシステム等の保守点検または更新を行う場合
     2. 地震、落雷、火災、停電または天災等の不可抗力により、本サービスの提供が困難となった場合
     3. 他社サービスの全部または一部の提供が停止・中断等した場合
     4. 本サービスにかかるシステム等または通信回線等が事故その他の事由により停止した場合
     5. その他、当社が本サービスの提供が困難と判断した場合
2. 当社は、本サービスの提供の停止または中断により、お客様または第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。`,

    TITLE_21: `21. お客様の責任`,

    DETAIL_21: `1. お客様は、お客様ご自身の責任と費用負担において本サービスを利用するものとし、本サービスにおいて行った一切の行為およびその結果について一切の責任を負う（第三者からクレーム、請求または訴訟等の提起(以下、これらを総称して「クレーム等」といいます。)が行われた場合に、自らの責任と費用負担によりこれに対応することを含みます。）ものとします。当社による商品の生成は、本規約に定めるお客様の責任を否定するものではありません。,
2. お客様は、本サービスを利用したことに起因して（当社がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当社が直接的または間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを賠償しなければなりません。`,

    TITLE_22: `22. 非保証`,

    DETAIL_22: `当社は、本サービスに事実上または法律上の瑕疵（セキュリティ等に関する欠陥、エラーやバグ、権利侵害等を含みます。）がないこと、並びに安全性、信頼性、正確性、完全性、有効性および特定の目的への適合性を明示的にも黙示的にも保証しておりません。`,

    TITLE_23: `23. 当社の免責事項`,

    DETAIL_23: `1. 当社は、本サービスに起因してお客様に生じたあらゆる損害について一切の責任を負いません。ただし、利用契約が消費者契約法に定める消費者契約となる場合、本項の免責規定は適用されず、当社はお客様が本サービスの利用に関して被った損害を賠償する責任を負います（当社の責めに帰することができない事由によるものであるときを除きます。）,
2. 前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりお客様に生じた損害の賠償は、お客様から当該損害が発生した月に受領した手数料の額を上限とします。
3. 当社は、本サービスに関して、お客様と他のお客様または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
4. 当社は、商品が正当な権利者の著作権、商標権、特許権等の知的財産権、その他権利を侵害していないことを保証しておりません。
5. 法令の新設・改正等により本サービスが終了しても、それによってお客様に生じた損害について一切の責任を負いません。`,

    TITLE_24: `24. 個人情報の取扱い`,

    DETAIL_24: `当社は、本サービスの提供に伴い取得するお客様の個人情報については、当社「プライバシーポリシー」に従い適切に取り扱います。`,

    TITLE_25: `25. 通知または連絡`,

    DETAIL_25: `1. お客様と当社との間の通知または連絡は、当社の定める方法によって行うものとします。,
2. 当社は、お客様から、当社が別途定める方式に従った変更届出がない限り、現在登録されている連絡先その他お客様の情報が有効なものとみなして当該連絡先へ通知または連絡を行います。
3. 前項の変更届出がなされてないことにより当社からの通知が遅延または不着となった場合であっても、これらは、通常到達すべき時にお客様へ到達したものとみなします。`,

    TITLE_26: `26. 権利義務の譲渡の禁止`,

    DETAIL_26: `お客様は、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。`,

    TITLE_27: `27. お問合せ先`,

    DETAIL_27: `本サービスに関するお客さまの当社へのお問合せは、support@cobalt.zoneまでお願いします。`,

    TITLE_28: `28. 知的財産権保護`,

    DETAIL_28: `1. 本サービス（規約、告知、記事、ビデオ、オーディオ、画像、アーカイブ、情報、資料、商標またはロゴを含む）に表示されるすべてのコンテンツの知的財産権は、当社または第三者のライセンスによって保護されています。,
2. 本サービスまたは第三者のライセンサーからの事前の書面による同意なしに誰も上記のアプリケーションおよびコンテンツを使用、改変、逆コンパイル、再生、公開、配布、発行することはできません。`,

    TITLE_29: `29. 準拠法・裁判管轄`,

    DETAIL_29: `1. 本規約の解釈にあたっては、日本法を準拠法とします。,
2. 本サービスに関して紛争が生じた場合には、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。`,

    UPDATED: `2021年8月1日制定`,
  };
  static readonly en = {
    TITLE: `Terms and conditions`,
    DESC: `POST VOYAGER PTE.LTD. (hereinafter referred to as ""Company"") defines the rights and obligations between the Company and its users through these Terms and Conditions of Use (hereinafter referred to as ""Company"") for the Services Cobalt provided on this website.
These terms and conditions apply to the contract between the company and the user in using this service.
The company shall permit the use of this service only to users who have read and agreed to the full text of this service.`,
    TITLE_1: `1. About this service`,
    DETAIL_1: `This service is a platform where you can buy and sell non-fungible tokens (NFTs) such as digital items or voice data, digital art, and video data on blockchain. Users are responsible for all submissions and purchases between users. The company does not guarantee whether the user's presentation is based on legitimate rights, etc. In addition, the company does not sell itself, is not entrusted with the sale, and is not involved in the performance of the sale.`,
    TITLE_2: `2. Definitions`,
    DETAIL_2: `1. 'Product' refers to NFTs such as digital items, voice data, digital art, and video data that are the purpose of this service.
2. The term ""product payment"" means the price of the goods registered by the seller when submitting the goods on this service, expressed in clay or points.
3. 'Sales money' refers to the price deducted from the payment of the product to the platform fee and creator's royalty.
4. The term ""user"" means a person who uses this service after agreeing to the terms and conditions.
5. 'Seller' means a user who submits a product through this service.
6. The term 'buyer' means a user who purchases a product through this service.
7. 'Creator' refers to the person who created the product (including copyright holders, but not limited to it).
8. 'Creator Incentives' is a reward paid to the creator whenever the product is resold within this service.
9. 'Digital ownership' means the right to manage NFT's secret keys and perform NFT transfers on the blockchain and does not mean ownership under civil law.
10. The term ""fraud or prohibited goods"" means products prepared by inappropriate means such as data tampering, the use of fraudulent tools, and items prohibited by the company in this Agreement.
11. 'Point' means an in-service currency of $1 point = $1US available within this service.`,
    TITLE_3: `3. Application of Terms and Conditions`,
    DETAIL_3: `1. These Terms and Conditions apply to all relationships between you and the Company regarding the use of these services.
2. In addition to these terms and conditions, the company sometimes makes various regulations such as rules of use (hereinafter referred to as ""individual regulations"") regarding this service. These individual regulations, regardless of their name, are part of these terms and conditions.
3. If the provisions of these Terms and Conditions contradict the provisions of the individual provisions of the preceding paragraph, the provisions of the individual provisions shall prevail, unless otherwise specified in the individual provisions.
4. Even if some of these terms and conditions, etc., are deemed invalid or not to have been agreed under the statute, other regulations listed in these terms and conditions shall be valid. In addition, these terms and conditions are valid between the Company and other users, even if all or some of these terms and conditions are deemed invalid, cancelled or not agreed upon between certain users. `,
    TITLE_4: `4. Agreement to Terms and Conditions`,
    DETAIL_4: `1. Users use this service after agreeing to the terms and conditions in advance.
2. If you cannot agree to this agreement, please stop using this service immediately because the user will not be able to use this service.
3. At the time the user starts using this service, the user is deemed to have agreed to the terms and conditions. At this time, a contract (hereinafter referred to as ""use contract"") under various regulations, such as these terms and conditions, shall be established between the user and the company.
4. This service is not available to minors.
5. If the user is not capable of conducting legal activities alone, he/she may use this service after obtaining consent from his/her legal representative. In the case of using this service, the legal representative shall be deemed to have agreed to this Agreement, etc.
6. If a user who was a minor or has no ability to practice law alone (hereinafter referred to as a ""restricted person, etc."") uses this service after he or she reaches adulthood or after obtaining the ability to do so, he or she shall be deemed to have added all legal activities and activities regarding this service.
7. This service is not available to users in Singapore.`,
    TITLE_5: `5. Changes to Terms and Conditions`,
    DETAIL_5: `1. The company may change these terms and conditions, etc. without the consent of the user in the following cases:
1. When a change in these terms, etc. is appropriate for the general interest of the user.
2. When the modification of these terms, etc. does not contradict the purpose of the contract, and is reasonable in light of the necessity of the modification, the considerability of the contents after the modification, and other circumstances related to the modification.
2. When the company changes this Agreement, etc. under the preceding paragraph, it shall notify the user of the reason for changing this Agreement, the details of the change, and the date of its validity to this service, or any other company deems appropriate.
3. These terms and conditions, etc. after modification shall take effect from the date of effect of the notice.
4. If a user uses this service after changing the terms and conditions, etc., he/she shall be deemed to have agreed to the terms and conditions, etc. after the change.`,
    TITLE_6: `6. Account Management`,
    DETAIL_6: `1. When a user registers information about himself/herself (hereinafter referred to as ""registration information"") when using this service, the user shall provide truth, accuracy, and complete information and shall always modify it to be up-to-date. Properties require information specified by the company in addition to the mail address.
2. The company shall not be held responsible even if the user has not taken measures, such as modification of the preceding paragraph, or if the user has been disadvantaged due to inaccurate or incomplete registration information entered by the user.
3. The user shall manage the account under his/her responsibility, and shall not let a third party use it, lend it, transfer it, change its name, sell it, etc.
4. When using this service, the company may treat it as being used by the person identified through the account, and the consequences of the use and all responsibilities shall be attributed to the person identified through the account.
5. If an account is found to be stolen or used by a third party, the user shall immediately notify the company of the fact and comply with the company's instructions.
6. In the event of a loss to the company or a third party due to fraudulent use of the account, the user shall compensate the company and the third party for the loss.
7. The company may take other measures reasonably deemed necessary and appropriate, such as not allowing the user to create an account without prior notice, suspending or deleting the account, or canceling the contract for this service between the user and the company: The company shall not be held responsible for any damages caused by the user caused by the act conducted based on this paragraph.
     1. A person who has, or is not limited to, a member of, a gang, a gang affiliated enterprise, a general assembly, etc., a social movement advocacy, political activity advocacy, a special intelligence gang, or a close relationship (including, but not limited to, funds and other beneficial activities).
     2. In the event of impairing the company's credibility or in the event that the company recognizes that there is concern there is
     3. Where the company recognizes that it violates or is likely to violate the applicable statutes, these terms and conditions, or individual terms of use;
     4. In case of non-compliance with monetary obligations, such as fees, etc.;
     5. When there is no answer to the contact from the company for a certain period of time.
     6. When more than one year has elapsed from the date on which the user last accessed this service;
     7. Where the company determines that it is inappropriate for the company to provide this service in accordance with reasonable criteria for determination, such as the loss of a trust relationship with users due to reasons other than subparagraph 6.
8. All rights users have in using this service will be extinguished at the time the account is deleted, regardless of the reason. Also, if you delete an account, you will not be able to recover it, so be careful.
9. The account of this service is attributable to the user in full. All rights of use used by a user in this service shall not be transferred, leased or inherited to a third party except as otherwise provided in these terms and conditions, etc.`,
    TITLE_7: `7. Fees`,
    DETAIL_7: `In using this service, there will be fees for refunds and refunds to platform companies designated separately by the company. In addition, fees incurred when clearing the GAS costs, point purchases, and sales of clay are user-bearing.`,
    TITLE_8: `8. Generation and entry of goods`,
    DETAIL_8: `1. After the product is created, the user can submit the product by setting the payment to Clay or US dollars. You cannot change the way the product is displayed after submission.
2. The payment of goods in this service will be displayed according to the display method set by the user.
3. Sellers may cancel the entry until the conclusion of the sales contract prescribed in Article 9 (1) is made. In addition, the seller cannot cancel the contract after the contract has been established.
4. Sellers shall not create or submit any fraudulent or prohibited goods as defined in Article 18.
5. If the seller makes an entry in violation of these terms, etc., or if the seller determines that he/she has not actually signed a contract for sale, the company may invalidate the entry without notifying the seller in advance. If the item is invalid, the company may invalidate the transaction.
6. The company may set up a route where a third party can raise objections to the product. If the company determines that the company is not suitable based on the relevant objection, it may invalidate the product's entry without obtaining the user's consent in advance at the discretion of the company.`,
    TITLE_9: `9. Purchase`,
    DETAIL_9: `1. The digital ownership of the goods is transferred from the seller to the buyer at the same time as the buyer presses the Purchase button on the goods submitted in this service.
2. After the contract of sale under the preceding paragraph has been established, Seller and Buyer shall not cancel the contract of sale and shall not claim invalidation or cancellation of the transaction for any reason except as provided in law.`,
    TITLE_10: `10. Payment of goods, receipt of sales`,
    DETAIL_10: `1. The Seller grants the Company the power to collect the goods paid by the Buyer on behalf of the Buyer, and the Buyer's payment of the Goods obligation is extinguished when the Company receives the Sales Payment from the Buyer.
2. In the event of a contract for sale, the buyer pays the product to the company by separately designating it.
3. If the company determines that the seller has fulfilled its obligations under the transfer or other contract of sale, the company shall account for the sales amount deducted from the buyer's payment of the goods received under the provisions of the preceding paragraph. The account will be processed promptly after receiving a withdrawal application from the seller for the sales referred to in paragraph 4.
4. Seller shall apply for withdrawal from Seller's account within 180 days of the date the Seller's account is accounted for in accordance with the procedure separately designated by the Company for the sales received by the Company pursuant to paragraph (2). The seller will pay the necessary fees for withdrawal.
5. Sellers can purchase points with sales money.
6. Regardless of the provisions of paragraphs (2) and (3), the company may withhold the withdrawal of sales requested by the seller in case of violation of these terms, etc. or for certain reasons determined by the company. In this case, the sales withdrawal will continue unless the company's investigation is completed.
7. The Company may, under the provisions of paragraph (4), set aside the obligations borne by the Seller to the Seller in paying the Sales to the Seller, regardless of the term of payment of such debt.
8. In the following cases, the company may consider the seller to have forfeited the right to receive the payment of the sales under paragraph (4) and transfer the amount deducted from the fees to the bank account:
     1. If the Seller does not designate a valid bank account within one year of the relevant notice, even though the Company has notified the Seller to designate a bank account under paragraph (4).
     2. Where an application for withdrawal of sales proceeds is not made within the period prescribed in paragraph (4).
     3. Where the total amount of goods not received falls short of the deposit fee at the time the company terminates this service;`,
    TITLE_11: `11. Creator`,
    DETAIL_11: `1. In submitting a product, the creator himself/herself is the copyright holder of the product. We hereby certify that we have obtained due rights from this or the copyright holder and that we have not infringed on the rights of any other third party on such goods.
2. Creators can set the creator's royalty in a separate company-set way.`,
    TITLE_12: `12. Intellectual property rights of goods`,
    DETAIL_12: `1. Intellectual property rights, including copyright of the goods, are not transferred to the purchaser and are attributed to the creator or the rightful holder.
2. The use or provision of goods for the following purposes is prohibited for infringement of intellectual property rights.
     1. Provided to others beyond the scope of use in the home;
     2. For commercial use
     3. In the case of changing or processing images, videos, music, etc. included in the product. However, it is possible to use the contents provided by the business partner to which the company is affiliated.
3. Products can only be downloaded and stored by the buyer on their local computer and other memory devices. We also authorize buyers to use it for non-commercial purposes.
4. The buyer acquires digital ownership of the product, but the copyrighted creator allows the production activity to introduce the work through exhibitions and portfolios.
5. The company shall be able to use the goods to the extent necessary for smooth provision of this service, system establishment, improvement, maintenance, and advertising and advertising of this service (including publication, editing, but not limited thereto).
6. If the company uses the product in accordance with the previous paragraph, it may omit the display of the creator name by omitting, re-size, or editing part of the product data.`,
    TITLE_13: `13. Points`,
    DETAIL_13: `1. Users can purchase ""points"" in-service currency that can be used within this service by separately designating by the company, such as external payment services. The company determines the unit price of the point purchase and can be changed without prior notice to the user.
2. The company can grant free points to users by campaign, etc. If there are paid points and free points, they are consumed from free points.
3. Users shall not exchange points with others or exchange them for cash or other economic benefits (except where the company permits it).
4. Points cannot be refunded for any reason. However, except when necessary under the statute.
5. The company may incinerate the remaining points for free of charge for users who have passed more than one year after final submitting or final purchase.`,
    TITLE_14: `14. Handling tokens (CBLTs)`,
    DETAIL_14: `1. The company can distribute tokens (CLBTs) free of charge to users. The company determines the distribution criteria, timing, quantity, etc. of tokens (CLBT), and can change them without prior notice to users.
2. In order to use the token (CLBT), you need to link it with a separate wallet.`,
    TITLE_15: `15. Provision, stop, and shutdown of service`,
    DETAIL_15: `1. The user shall prepare the necessary equipment, communication environment, and power at the user's cost and responsibility in using this service.
2. The company may provide all or part of this service to users who meet the conditions deemed necessary by the company.
3. If the company deems it necessary, the company may change all or some of the contents of this service or suspend or terminate the provision thereof at any time without informing the user in advance. In addition, the company shall not be held responsible for any damages caused to the users.`,
    TITLE_16: `16. Third-party services`,
    DETAIL_16: `In the use of this service, the company's designated digital asset and cryptographic assets (hereinafter referred to as "wallet") and smart contract systems (hereinafter referred to as "wallet"), other company-designated services, are provided by non-company operators. The responsibility for third-party services shall be borne by the operator who provides such third-party services, and the company shall not be held responsible.`,
    TITLE_17: `17. Prohibition`,
    DETAIL_17: `1. Users shall not perform the following activities in using this service:
     1. An act that violates laws or good morals, or is likely to violate social order;
     2. An act of submitting or purchasing a sale contract without the intention of entering into it;
     3. infringement of intellectual property rights, such as copyright, trademark, patent rights, etc. of the Company or any third party (including other users); and other rights;
     4. An act that destroys or interferes with the capabilities of a company or third party server or network system.
     5. An act that may interfere with the operation of the Company's main services.
     6. Malicious access or third-party account use or attempting to do so
     7. Obtaining and exploiting points fraudulently
     8. An act that causes disadvantages, damages, or displeasure to other users or third parties of this service;
     9. The act of posing as another user
     10. Collecting or accumulating personal information, etc. about other users;
     11. An act for propaganda, advertising, recommendation, sales and other profit-making purposes;
     12. An act aimed at meeting unacquainted users
     13. Providing benefits or other cooperation directly or indirectly to antisocial forces;
     14. Any other use of this service for purposes other than the intended use of this service;
     15. Using this service as multiple accounts for the purpose of prohibiting this section;
     16. Other acts deemed inappropriate by the company;
2. If the company acknowledges that the user violates or is likely to violate these terms and conditions, the company may take appropriate measures (including deletion of the account) to correct the violation without notifying the user in advance.`,
    TITLE_18: `18. For unclean/prohibited products`,
    DETAIL_18: `1. Users are not allowed to create or submit any of the following:
     1. Copyright Other Violations of Third Party Rights
     2. including pornography, explicit sexual expression, and other obscene content.
     3. Something that ordinary users can feel unpleasant, such as violent and bizarre photos.
     4. including matters that are contrary to laws and regulations, good customs and other social order or are concerned about;
     5. including expressions leading to discrimination by race, nationality, creed, gender, status, etc.
     6. Including expressions that attract or encourage suicide, suicide, drug abuse, illegal drug use, etc.
     7. including inappropriate expressions of slander, complaints, discriminatory remarks, etc. against a company or a particular enterprise, corporation, organization, region or individual.
     8. relating to the transfer of criminal proceeds.
     9. In addition, the company deems it inappropriate in light of the purpose of using this service.
2. Users are not allowed to create or submit the following products except in the preceding paragraph: 
     1. Securities
     2. Cryptographic asset
     3. NFT relating to the property's ownership, ownership of motor vehicles, or other goods in which the registration or registration system exists;
     4. Other products that are required to be licensed, registered, reported or registered by statute or before sale.
     5. Other NFTs related to fluids prescribed in Article 98 of the Civil Code;
3. If the company determines that a product falling under paragraph (2) has been generated or submitted, it may take appropriate measures to correct an offence by deleting the product or other means without prior notice to the user.`,
    TITLE_19: `19. Assurance`,
    DETAIL_19: `The user shall express and pledge the following matters to the company:

1. A gang, a gang-related enterprise, a general assemblyman or equivalent, or its organization (hereinafter collectively referred to as an ""anti-Not and never will be.
2. In the case of an organization, such as a corporation, it refers to its own executive officer (a person equivalent to the executive officer, director, executive officer, or others).Not this antisocial force, and not in the future.
3. Do not allow antisocial forces to use their name to enter into this contract.
4. Do not do the following to this Agreement on your own or by using a third party:
     1. The use of threatening language or violence against the company.
     2. An act of obstructing or undermining the company's business with hierarchy or power;
5. Do not accept capital, funds, etc. from antisocial forces regardless of name;
6. No act of providing capital, funds, etc. to antisocial forces, regardless of name;
7. Do not act in accordance with the previous subparagraphs.`,
    TITLE_20: `20. suspension, discontinuation of this service delivery`,
    DETAIL_20: `1. If the company determines that any of the following reasons have occurred, it may suspend or suspend the provision of all or part of this service without prior notice to the user:
     1. In the case of a repair inspection or renewal of a system, etc. related to this service;
     2. Where it is difficult to provide this service due to force majeure, such as earthquake, lightning, fire, power outage, or natural disasters;
     3. Where the provision of all or part of third-party services is suspended, discontinued, etc.
     4. Where a system, etc. related to this service, or a communication line, etc. is suspended due to an accident or other reason;
     5. If the company finds it difficult to provide this service,
2. The company shall not be liable for any disadvantages or damages suffered by the user or third party due to the suspension of this service.`,
    TITLE_21: `21. User's responsibility`,
    DETAIL_21: `1. The user shall use this service for his/her own responsibility and expense, and shall respond to any actions and consequences made by this service by a third party (hereinafter collectively referred to as ""Claim, etc.""). The generation of the Company's goods does not negate the user's responsibility under these terms and conditions.
2. Users shall compensate for any damages directly or indirectly caused by the use of this service (including the burden of attorney's expenses, Include cases in which the company receives claims from third parties that cause the use of users.).
3. The user is solely responsible for determining the taxes incurred in all asset transactions provided by this service (including any cases that can be taxed). The company shall not be responsible for the tax obligations incurred by users due to the use of this service.`,
    TITLE_22: `22. Non-guaranteed`,
    DETAIL_22: `The Company does not explicitly or implicitly guarantee that there will be virtually or legally no defects in this service (including security defects, errors, bugs, infringement of rights, etc.) and that safety, reliability, accuracy, completeness, validity and fit for a particular purpose.`,
    TITLE_23: `23. Company's indemnification matters`,
    DETAIL_23: `1. The company shall not be liable for any damages caused by this service to the user. However, if a service contract is subject to the Consumer Protection (Fair Trading) Act, etc., the exemption provisions of this paragraph shall not apply, and the company shall be responsible for compensating damages caused by the users using this service (except when the company cannot take responsibility).
2. In the case prescribed in the preceding proviso, the Company shall not be liable for any damages caused by default or unlawful acts by the Company (excluding gross negligence) resulting from a particular circumstance (including the occurrence of damages by the Company or the User). In addition, the compensation for damages caused by default or illegal acts caused by the company's negligence (excluding gross negligence) shall be the upper limit on the amount of fees received in the month in which such damages occurred from the user.
3. The company shall not be held responsible for any transactions, contacts, disputes, etc. between users and other users or third parties regarding this service.
4. The company does not guarantee that the goods are not infringing on the copyright, trademark, patent, etc. of the rightful person, or any other rights.
5. Even if this service is terminated by the establishment or revision of statutes, the company shall not be responsible for any damages caused by the users.`,
    TITLE_24: `24. Handling of personal information`,
    DETAIL_24: `The company treats the users' personal information acquired by providing this service in accordance with the company's personal information processing policy.`,
    TITLE_25: `25. Notifications and Contact`,
    DETAIL_25: `1. Notification or contact between users and the company shall be made by the method determined by the company.
2. The company shall notify or contact the user's contact information, considering that the user's contact information is valid unless there is a change report according to the method specified separately.
3. Even if the notice from the company has not been delayed or reached due to the failure to report changes under the previous paragraph, it is deemed that the user has reached the time of arrival.`,
    TITLE_26: `26. No transfer of rights obligations`,
    DETAIL_26: `Users shall not transfer their status and rights and obligations under these terms or conditions to third parties or provide them as collateral without prior approval by written agreement with the company.`,
    TITLE_27: `27. Contact`,
    DETAIL_27: `Inquiries about this service are received at support@cobalt.zone`,
    TITLE_28: `28. Intellectual Property Protection`,
    DETAIL_28: `1. The intellectual property rights of all content displayed in this service (including conventions, notices, articles, videos, audio, video, archives, information, materials, trademarks, or logos) are protected by a company or third party license.
2. No one shall use, modify, reverse compile, play, disclose, distribute or publish the above applications and contents without prior written consent from this Service or a third party license.`,
    TITLE_29: `29. Compliance laws and jurisdictions`,
    DETAIL_29: `1. The interpretation of these terms and conditions shall be governed by the laws of the Republic of Korea.
2. In the event of a dispute over this service, the Tokyo District Court or the Tokyo Inter-Tai Court shall be the sole jurisdiction of the first trial.`,
    UPDATED: `2021. 08. 01`,
  };
}
