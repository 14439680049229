/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDebounce, useEffectOnce } from 'react-use';
import ScreenInfo from 'src/context/screen_constants';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, CommonInput, devOutlineF } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { JOIN_SCREEN_LAYOUT, PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  getRef?: (instance: HTMLInputElement | null) => void;
  value: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  fontSize?: number;
  placeHolder?: string;
  defaultValue?: string;
  disabled?: boolean;
  inputType?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  isShowErrorIcAndBorder?: boolean;
  errorIcMarginRight?: number;
  isShowOkIc?: boolean;
  autoComplete?: boolean;
  isBold?: boolean;
  hasImgBtn?: boolean;
};
const commonIcMarginRight = ScreenInfo.isMobile() ? 8 : 14;

export function SRoundInputText({
  getRef,
  value,
  width = '100%',
  height = 44,
  borderRadius = 14,
  fontSize = 14,
  placeHolder,
  defaultValue,
  disabled = false,
  inputType,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  autoFocus = false,
  isShowErrorIcAndBorder = false,
  errorIcMarginRight = ScreenInfo.isMobile() ? 36 : 48,
  isShowOkIc = false,
  autoComplete = true,
  isBold = false,
  hasImgBtn = true,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [debouncedSearchTxt, setDebouncedSearchTxt] = useState(value);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [sInputFocus, setInputFocus] = useState<boolean>(false);

  /**************************************
   * !! ref
   **************************************/
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isInpufFocusRef = useRef<boolean>(false);

  /**************************************
   * !! hooks
   **************************************/
  const [,] = useDebounce(
    () => {
      setIsTyping(false);
      if (value === debouncedSearchTxt) return;
      setDebouncedSearchTxt(value);
      DBGMSG(`debouncedInputText:${value}`);
    },
    1000,
    [value]
  );

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  return (
    <AnimatedFlexRowDiv
      style={Object.assign(
        {
          width: width,
          height: height,
          borderRadius: borderRadius,
          justifyContent: 'flex-start',
          // overflow: 'hidden',
        },
        // error일 경우 border focus 효과 없음
        sInputFocus && !isShowErrorIcAndBorder ? PropsSRoundEnabled : PropsSRoundDisabled,
        disabled
          ? {
              backgroundColor: R.colors.lightGrey,
              // borderWidth: 1,
              borderColor: R.colors.lightGrey,
            }
          : undefined,
        // error일 경우 border red border 표시
        isShowErrorIcAndBorder
          ? {
              borderColor: R.colors.pastelRed,
              borderWidth: 1,
              borderStyle: 'solid',
            }
          : undefined
      )}
    >
      <CommonInput
        style={{
          boxSizing: 'border-box',
          fontSize: fontSize,
          fontWeight: isBold ? 'bold' : 'normal',
          width: '100%',
          paddingBottom: 3,
          paddingLeft: 14,
          paddingRight: 14,
          // ...devOutlineFF,
        }}
        ref={(input) => {
          inputRef.current = input;
          getRef && getRef(input);
        }}
        type={inputType}
        disabled={disabled}
        value={value}
        autoFocus={autoFocus}
        placeholder={placeHolder}
        autoComplete={autoComplete ? 'on' : 'off'}
        onFocus={() => {
          // DBGMSG('Input onFocus');
          isInpufFocusRef.current = true;
          setInputFocus(true);
          onFocus && onFocus();
        }}
        onBlur={() => {
          // DBGMSG('Input onBlur');
          isInpufFocusRef.current = false;
          setInputFocus(false);
          onBlur && onBlur();
        }}
        onChange={(e) => {
          DBGMSG(`onChange: ${e.target.value}`);
          setIsTyping(true);
          onChange && onChange(e);
        }}
        onKeyPress={(e) => {
          DBGMSG(`onKeyPress code:${e.code} key:${e.key}`);
          onKeyDown && onKeyDown(e);
        }}
        // onKeyDown={(e) => {
        //   DBGMSG(`onKeyDown: ${e.key}`);
        //   if (e.key === 'Enter') {
        //     DBGMSG('Enter');
        //     // if (searchTxt.length !== 0) handleSearch(searchTxt);
        //   }
        //   onKeyDown && onKeyDown(e);
        // }}
        // returnKeyType="search"
        // clearButtonMode="while-editing"
      />

      {/* ok 표시 */}
      {isShowOkIc && (
        <Img
          src={[R.images.join_ic_confirm3x, JOIN_SCREEN_LAYOUT.confirmIcSize, JOIN_SCREEN_LAYOUT.confirmIcSize]}
          containerStyle={{ position: 'absolute', height: JOIN_SCREEN_LAYOUT.formSize.height, right: commonIcMarginRight, alignItems: 'center' }}
        />
      )}

      {/* error 표시 */}
      {/* hasImgBtn : input 내부 오른쪽에 이미지/버튼 유무에 따라 error표시 위치가 달라짐 */}
      {isShowErrorIcAndBorder && (
        <Img
          src={[R.images.join_ic_error3x, JOIN_SCREEN_LAYOUT.confirmIcSize, JOIN_SCREEN_LAYOUT.confirmIcSize]}
          containerStyle={{
            position: 'absolute',
            height: JOIN_SCREEN_LAYOUT.formSize.height,
            right: hasImgBtn === false ? commonIcMarginRight : errorIcMarginRight,
            alignItems: 'center',
          }}
        />
      )}
    </AnimatedFlexRowDiv>
  );
}
