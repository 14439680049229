/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useEffect } from 'react';
import { CbtStompMsgCode_e, messageService, ReceiveStompMsg } from 'src/rx/messageService';

export function useSubscribeStompMsg({ onMsg, targetCodeList }: { onMsg: (stompMsg: ReceiveStompMsg) => void; targetCodeList: CbtStompMsgCode_e[] }) {
  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((message) => {
      if (message.kind === 'ReceiveStompMsg') {
        if (targetCodeList.includes(message.code)) onMsg(message);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
}
