/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useEffectOnce } from 'react-use';
import ScreenInfo from 'src/context/screen_constants';
import { MarketAssetRead_i, DisplaySrc_t } from 'src/model/model';
import { CbtAssetType_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { devOutlineF, FlexRowDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgAdv } from 'src/ui/base_component/ImgAdv';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  playerSrc: DisplaySrc_t;
  width?: number;
  height?: number;
  scaleIfAudio?: number;
  disableFullScreenImg?: boolean;
  onImgFullscreen?: (src: string) => void;
  // isAnim?: boolean;
  onClick?: () => void;
};

/**************************************
 * !! constant
 **************************************/

const DT_ICON_SIZE = 28;
const MB_ICON_SIZE = 24;
const ICON_SIZE = ScreenInfo.isMobile() ? MB_ICON_SIZE : DT_ICON_SIZE;

/**************************************
 * !! constant
 **************************************/
const WIDTH = 276;
const HEIGHT = 276;

/**************************************
 * !! styled component
 **************************************/

export function AssetDisplayOptattr({
  playerSrc,
  width = WIDTH,
  height = HEIGHT,
  scaleIfAudio = 1,
  disableFullScreenImg = false,
  onImgFullscreen,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  // const [sIsPlay, setIsPlay] = useState(false);
  const [sFullImgViewer, setFullImgViewer] = useState<{ isOpen: boolean; src?: string }>({ isOpen: false });

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! render function
   **************************************/
  const render = () => {
    let img: string | undefined;
    let src: string | undefined;
    let assetType: CbtAssetType_e | undefined;

    switch (playerSrc.kind) {
      case 'OptImage':
        assetType = CbtAssetType_e.IMAGE;
        img = playerSrc.img;
        break;
      case 'OptAudio':
        assetType = CbtAssetType_e.AUDIO;
        src = playerSrc.audio;
        break;
      case 'OptVideo':
        assetType = CbtAssetType_e.VIDEO;
        src = playerSrc.video;
        break;
    }

    if (!assetType) return null;

    switch (assetType) {
      case CbtAssetType_e.IMAGE:
        if (!img) return null;
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              cursor: 'pointer',
              //...devOutlineF,
            }}
            onClick={() => {
              DBGMSG(`onClick: ${img}`);
              // setFullScreenViewerOnOff(true);
              if (!img) return;
              onImgFullscreen && onImgFullscreen(img);
              // setFullScreenImgSrc(img);
              setFullImgViewer({ isOpen: true, src: img });
            }}
          >
            <ImgAdv
              src={[img, undefined, undefined]}
              containerStyle={{
                flex: '0 0 auto',
                // ...devOutlineF,
              }}
              imgStyle={{
                width: width,
                height: height,
                overflow: 'hidden',
                objectFit: 'contain',
                // ...devOutlineF,
              }}
              onClick={() => {}}
            />
            {!disableFullScreenImg && (
              <Img src={[R.images.common_ic_enlarge3x, ICON_SIZE, ICON_SIZE]} containerStyle={{ position: 'absolute', right: 4, top: 4 }} />
            )}
          </FlexRowDiv>
        );

      case CbtAssetType_e.VIDEO:
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              alignItems: 'center',
              justifyContent: 'center',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            <video
              src={src}
              controls
              width={width}
              height={height}
              // poster={img}
              style={{ outline: 'none', objectFit: 'contain' }}
            />
          </FlexRowDiv>
        );

      case CbtAssetType_e.AUDIO:
        return (
          <FlexRowDiv
            style={{
              width: width,
              height: height,
              flex: '0 0 auto',
              alignItems: 'center',
              justifyContent: 'center',
              //...devOutlineF,
            }}
            onClick={() => {}}
          >
            <Img src={[R.images.asset_detail_iv_audio_added3x, width, height]} />
            <audio src={src} controls style={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', outline: 'none' }} />
          </FlexRowDiv>
        );
    }
  };

  return (
    <>
      {render()}
      {/* 전체보기 */}
      {sFullImgViewer.isOpen && sFullImgViewer.src && (
        <Lightbox wrapperClassName="custom_modal_image" mainSrc={sFullImgViewer.src} onCloseRequest={() => setFullImgViewer({ isOpen: false })} />
      )}
    </>
  );
}
