/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import ScreenInfo, { MOBILE_CONTENT_PADDING_TOP, MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useDlg } from 'src/hooks/useDlg';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { RPC_ASSETMGR_ISSUE_ASSET_LIST, RPC_ASSET_CREATOR_GROUP_VIEW } from 'src/model/rpcType';
import { CbtAssetCardInfo_i, CbtAttributeType_e, CbtIssueType_e, CbtPgInfo_i } from 'src/model/rpcModel';
import R from 'src/res/R';
import { CbtStompMsgCode_e, messageService } from 'src/rx/messageService';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { AssetCardForList, AST_CARD_CONTAINER_HEIGHT_FOR_LIST, AST_CARD_CONTAINER_WIDTH_FOR_LIST } from 'src/ui/common_component/AssetCardForList';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter, COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { COMMON_CARD_COLUMN_CNT, CLAY, DEFAUL_PAGE_ITEM_CNT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import { useAuth } from 'src/hooks/useAuth';
import { useSubscribeStompMsg } from 'src/hooks/useSubscribeStompMsg';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MyIssueAstgDetailScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sNextPageStatus, setNextPageStatus] = useState<string>('');
  const [sLoading, setLoading] = useState<boolean>();

  /**************************************
   * !! ref
   **************************************/
  const cacheRef = useRef<{
    lastRx: RPC_ASSETMGR_ISSUE_ASSET_LIST.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_ASSETMGR_ISSUE_ASSET_LIST.Rx['ast_list'];
  }>();
  const qsRef = useRef<{ sid: number; entity_id: number }>();
  const nextPageLoadingRef = useRef(false);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`MarketPlace AssetGroup - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hMyPageState = useUserDetailMineState();
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hAuth = useAuth();

  useSubscribeStompMsg({
    onMsg: (stompMsg) => {
      DBGMSG(`onMsg`);
      hMyPageState.resetIssueAst();
      return;
    },
    targetCodeList: [
      //
      CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS,
      CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_FAIL,
    ],
  });

  /**************************************
   * !! animation
   **************************************/
  const restoreCache = () => {
    if (!hMyPageState.state.issue.ast) return false;

    cacheRef.current = {
      lastRx: hMyPageState.state.issue.ast.lastRx,
      lastPgInfo: hMyPageState.state.issue.ast.lastPgInfo,
      list: hMyPageState.state.issue.ast.list,
    };
    return true;
  };

  const saveCache = ({
    lastRx,
    lastPgInfo,
    list,
  }: {
    lastRx: RPC_ASSETMGR_ISSUE_ASSET_LIST.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_ASSETMGR_ISSUE_ASSET_LIST.Rx['ast_list'];
  }) => {
    cacheRef.current = {
      lastRx,
      lastPgInfo,
      list,
    };
  };

  const clearCache = () => {
    cacheRef.current = undefined;
  };

  useEffect(() => {
    DBGMSG(`useEffect hMyPageState.state.issue.astNeedUpdate`);
    if (hMyPageState.state.issue.astNeedUpdate) {
      clearCache();
      fetchData();
    } else {
    }
    return () => {};
  }, [hMyPageState.state.issue.astNeedUpdate]);

  useEffect(() => {
    DBGMSG(`useEffect cacheRef.current?.lastRx?.issue_tcnt`);
    DBGMSG('useEffect() release');
    if (!qsRef.current) return;
    if (!cacheRef.current) return;
    if (!cacheRef.current.lastRx) return;
    DBGMSG(`changeIssueAstgTcnt: ${cacheRef.current.lastRx.issue_tcnt}`);
    hMyPageState.__changeIssueAstgTcnt({ entity_id: qsRef.current.entity_id, sid: qsRef.current.sid }, cacheRef.current.lastRx.issue_tcnt);
    return () => {};
  }, [cacheRef.current?.lastRx?.issue_tcnt]);

  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string; entity_id?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid && query.entity_id) {
      DBGMSG(`$$$$QS sid: ${query.sid}, entity_id: ${query.entity_id}`);
      const sid = parseInt(query.sid);
      const entity_id = parseInt(query.entity_id);
      qsRef.current = { sid, entity_id };

      if (hMyPageState.state.issue.astNeedUpdate) {
        // 캐시 삭제
        clearCache();
        fetchData();
      } else {
        // 캐시 복구
        restoreCache();
      }
    } else {
      // key 없이 접근
      return;
    }

    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async () => {
    if (sLoading) {
      return;
    }
    if (!qsRef.current) {
      return;
    }
    setLoading(true);
    try {
      const res = await hRpc.cobaltRpc.rpcAssetmgrIssueAssetList({
        entity_id: qsRef.current.entity_id,
        sid: qsRef.current.sid,
        pg_info: { row_cnt: DEFAUL_PAGE_ITEM_CNT, cursor: null },
      });
      saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: res.ast_list });

      hMyPageState.act.changeState({
        issue: {
          ...hMyPageState.state.issue,
          astNeedUpdate: false,
          ast: cacheRef.current,
        },
      });
    } catch (err) {
      hRpc.hanleCommonError({ err });
    } finally {
      setLoading(false);
    }
  };

  // 다음페이지 요청
  const fetchNextPage = async () => {
    if (!qsRef.current) {
      return;
    }

    if (!cacheRef.current) {
      DBGMSGW(`cacheRef.current is undefined`);
      nextPageLoadingRef.current = false;
      return;
    }

    const cursor = cacheRef.current.lastPgInfo.cursor;
    const lastPgInfo = cacheRef.current.lastPgInfo;
    const oldList = cacheRef.current.list;

    // cursor 가 있을경우 다른 조건은 무시됨 - https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699118
    if (cursor) {
      try {
        setNextPageStatus('loading');
        const res = await hRpc.cobaltRpc.rpcAssetmgrIssueAssetList({
          entity_id: qsRef.current.entity_id,
          sid: qsRef.current.sid,
          pg_info: { ...lastPgInfo, cursor },
        });

        if (res.ast_list !== null && res.ast_list.length !== 0) {
          saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: [...oldList, ...res.ast_list] });
        } else {
          saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: [...oldList] });
        }
        hMyPageState.act.changeState({
          issue: {
            ...hMyPageState.state.issue,
            ast: cacheRef.current,
          },
        });

        setNextPageStatus('');
      } catch (err) {
        DBGMSG(`fetchNextPage err`, err);
        setNextPageStatus('loading fail');
      }
    } else {
    }
  };

  const getLastRowId = () => {
    if (!cacheRef.current) return;
    if (cacheRef.current.lastPgInfo.last_row_id === -1 && cacheRef.current.lastPgInfo.cursor !== null) {
      DBGMSGW(`lastPgInfoRef.current.last_row_id === -1 && lastPgInfoRef.current.cursor !== null`);
    }
    return cacheRef.current.lastPgInfo.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  // 스크롤 리스너
  const scrollListener = (e: ScrollParams) => {
    // DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (!cacheRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'cacheRef.current is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    // DBGMSG(`clientHeight: ${clientHeight}, scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}`);

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return nextPageLoadingRef.current is true `);
      return;
    }

    nextPageLoadingRef.current = true;
    fetchNextPage()
      .then(() => {
        DBGMSG('$$$$PAGE_SCROLL', 'fetchNextPage then');
      })
      .catch(() => {
        DBGMSG('$$$$PAGE_SCROLL', 'fetchNextPage catch');
      })
      .finally(() => {
        nextPageLoadingRef.current = false;
      });
  };

  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  const handleMintMoreOnClick = async () => {
    if (!cacheRef.current) return;
    if (!cacheRef.current.lastRx) return;

    const nft_img = Utils.svc.getAssetMainImgFromAttrList(cacheRef.current.lastRx.attr_list);
    const assetType = Utils.svc.getAssetTypeFromAttrList(cacheRef.current.lastRx.attr_list);

    if (!nft_img) return;

    hDlgFactory.showMintMoreJsx({
      defaultOwner: hAuth.authCommonRes?.ucode ?? '',
      totolCnt: cacheRef.current.lastRx.issue_tcnt,
      mainImg: nft_img,
      assetType,
      onClose: () => {
        hDlgFactory.hideMintMoreJsx();
      },
      onMintClick: ({ mintCnt, owner }) => {
        if (!cacheRef.current) return;
        if (!cacheRef.current.lastRx) return;

        DBGMSG(`onMintClick`);
        hRpc.cobaltRpc
          .rpcAssetCreatorAdd({
            sid: cacheRef.current.lastRx.sid,
            supply: mintCnt,
            owner: owner,
            issue_type: CbtIssueType_e.ADDITION,
            entity_id: cacheRef.current.lastRx.entity_id,
            attr_values: cacheRef.current.lastRx.attr_list.map((attr) => {
              switch (attr.type) {
                case CbtAttributeType_e.IMAGE:
                case CbtAttributeType_e.AUDIO:
                case CbtAttributeType_e.VIDEO:
                  return {
                    ...attr,
                    value: Utils.ipfs.getIpfsHash(attr.value),
                  };
              }
              return {
                ...attr,
              };
            }),
          })
          .then((res) => {});

        hDlgFactory.closeMintMoreJsx();

        hDlgFactory.showAsyncGuideJsx({
          title: hR.strings.DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TITLE,
          text: hR.strings.DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TXT,
          onOk: () => {
            hDlgFactory.closeAsyncGuideJsx();

            if (!qsRef.current) return;

            // if (!qsRef.current) return;
            // hHistory.replace(Nav.brewSchemaRScreenPath({ sid: qsRef.current.sid }));
          },
        });
      },
    });
  };

  /**************************************
   * !! render function
   **************************************/
  const cardRender = (item: CbtAssetCardInfo_i) => {
    return (
      <AssetCardForList
        assetInfo={item}
        isAnim
        onClick={() => {
          DBGMSG('AssetCardForList onClick!!');
          if (!qsRef.current) {
            DBGMSGW('error qsRef.current');
            return;
          }
          DBGMSG(`qsRef.current: ${JSON.stringify(qsRef.current, undefined, 4)}`);
          hHistory.push(Nav.brewMyIssueAssetRScreenPath({ sid: qsRef.current.sid, entity_id: qsRef.current.entity_id, asset_id: item.asset_id }));
        }}
      />
    );
  };

  const cellRenderer = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    if (!hMyPageState.state.issue.ast) return null;
    const index = rowIndex * COMMON_CARD_COLUMN_CNT + columnIndex;
    if (index >= hMyPageState.state.issue.ast.list.length) return null;

    const data = hMyPageState.state.issue.ast.list[index];

    const item: CbtAssetCardInfo_i = {
      asset_id: data.ast_info.asset_id,
      asset_key: data.ast_info.asset_key,
      col_info: data.ast_info.col_info,
      nft_name: data.ast_info.nft_name,
      nft_img: data.ast_info.nft_img,
      nft_aud: data.ast_info.nft_aud,
      nft_vid: data.ast_info.nft_vid,
      owner: data.ast_info.owner,
      blocked: hMyPageState.state.issue.ast?.lastRx.blocked,
    };

    // DBGMSG(`typeof item: ${typeof item}`);
    if (ScreenInfo.isMobile()) {
      return (
        // 중요!
        <div key={key} style={style}>
          <MobileScalableFlexColumn>{cardRender(item)}</MobileScalableFlexColumn>
        </div>
      );
    } else {
      return (
        // 중요!
        <div key={key} style={style}>
          {cardRender(item)}
        </div>
      );
    }
  };

  // Asset 목록 렌더링
  const renderAssetList = () => {
    DBGMSG('renderList');
    if (!hMyPageState.state.issue.ast) return null;
    return (
      <FlexColumnDiv
        style={{
          width: AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT,
          alignSelf: 'center',
          userSelect: 'none',
          ...devOutline,
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <FlexColumnDiv>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <Grid
                      onScroll={(params) => {
                        onChildScroll(params);
                        throttleScrollListener(params);
                      }}
                      isScrolling={isScrolling}
                      autoHeight
                      cellRenderer={cellRenderer}
                      columnWidth={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale}
                      columnCount={COMMON_CARD_COLUMN_CNT}
                      height={height}
                      rowHeight={AST_CARD_CONTAINER_HEIGHT_FOR_LIST * ScreenInfo.scale}
                      rowCount={Math.ceil(hMyPageState.state.issue.ast!.list.length / COMMON_CARD_COLUMN_CNT)}
                      scrollTop={scrollTop}
                      width={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT}
                    />
                  </div>
                )}
              </AutoSizer>
            </FlexColumnDiv>
          )}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/
  if (sLoading) return <DataFetching />;

  DBGMSG('render');

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <FlexColumnDiv
            style={{
              backgroundColor: R.colors.bgGrey,
              width: MOBILE_WIDTH,
              alignItems: 'flex-start',
              paddingTop: MOBILE_CONTENT_PADDING_TOP,
              paddingLeft: MOBILE_HORIZONTAL_PADDING,
              paddingRight: MOBILE_HORIZONTAL_PADDING,
              ...devOutline,
            }}
          >
            {/* Back 버튼 */}
            <BackBtn />

            {/* 타이틀 */}
            <FlexRowDiv style={{ alignItems: 'baseline', height: 24, alignSelf: 'stretch' }}>
              <TextCon text={hMyPageState.state.issue.ast?.lastRx.asset_name ?? ''} size={16} color={R.colors.black} isBold />
              <WIDTH size={3} />
              <TextCon text={`(${hMyPageState.state.issue.ast?.lastRx.issue_tcnt ?? ''})`} size={12} color={R.colors.black} />
            </FlexRowDiv>

            {/* 추가발행 버튼 */}
            {hMyPageState.state.issue.ast?.lastRx.can_addition === true && (
              <>
                <HEIGHT size={12} />
                <RoundTxtBtn
                  text={hR.strings.AC_ASSET_GROUP_MINT_MORE_BTN}
                  width={129}
                  height={40}
                  fontSize={12}
                  containerStyle={{ marginLeft: 'auto' }}
                  enable={hMyPageState.state.issue.ast?.lastRx.blocked === false}
                  onClick={() => {
                    handleMintMoreOnClick();
                  }}
                />
              </>
            )}
          </FlexColumnDiv>
        </MobileScalableFlexColumn>

        <HEIGHT size={6} />
        {renderAssetList()}
        <HEIGHT size={46} />
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      </>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          ...devOutline,
        }}
      >
        <HEIGHT size={40} />

        {/* Back 버튼 */}
        <BackBtn />

        {/* 타이틀 */}
        <FlexRowDiv style={{ alignItems: 'baseline', height: 50, alignSelf: 'stretch' }}>
          <TextCon text={hMyPageState.state.issue.ast?.lastRx.asset_name ?? ''} size={30} color={R.colors.black} isBold />
          <WIDTH size={5} />
          <TextCon text={`(${hMyPageState.state.issue.ast?.lastRx.issue_tcnt ?? ''})`} size={20} color={R.colors.black} />
          {hMyPageState.state.issue.ast?.lastRx.can_addition === true && (
            <RoundTxtBtn
              text={hR.strings.AC_ASSET_GROUP_MINT_MORE_BTN}
              width={141}
              containerStyle={{ marginLeft: 'auto' }}
              enable={hMyPageState.state.issue.ast?.lastRx.can_addition === true}
              onClick={() => {
                handleMintMoreOnClick();
              }}
            />
          )}
        </FlexRowDiv>

        <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

        {renderAssetList()}
      </FlexColumnDiv>
    );
}
