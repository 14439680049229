/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { UserBoughtCard, UserBoughtCardProps } from 'src/ui/screen/AssetMgr/card/UserBoughtCard';
import { SOLDCARD_WIDTH, SOLDCARD_HEIGHT } from 'src/ui/screen/AssetMgr/card/UserSoldCard';
import { SOLD_CARD_PADDING } from 'src/ui/screen/AssetMgr/card/UserSoldCardForList';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
export const PURCHASED_CARD_CONTAINER_WIDTH = SOLDCARD_WIDTH + SOLD_CARD_PADDING * 2;
export const PURCHASED_CARD_CONTAINER_HEIGHT = SOLDCARD_HEIGHT + SOLD_CARD_PADDING * 2;

export function UserBoughtCardForList(props: UserBoughtCardProps) {
  return (
    <FlexDiv
      style={{
        padding: SOLD_CARD_PADDING,
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineF,
      }}
    >
      <UserBoughtCard {...props} />
    </FlexDiv>
  );
}
