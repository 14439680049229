/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { AnimatedFlexRowDiv, devOutlineF, FlexColumnDiv, FlexDiv, VLINE } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { useMouseEnterEffectType1 } from 'src/hooks/useMouseEnterEffectType1';
import R from 'src/res/R';
import { COMMON_BOX_HORIZONTAL_PADDING, CLAY } from 'src/ui/layout_constant';
import ScreenInfo from 'src/context/screen_constants';
import { Constants } from 'src/res/constants';
import { Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

// export const ATTRIBUTE_NAME_WIDTH = '36%';
export const ATTRIBUTE_NAME_WIDTH = 178;

type SchemaAttributeProps = {
  width?: number | string;
  name?: string;
  value?: string;
  nameColor?: string;
  valueColor?: string;
  isAnim?: boolean;
  disabled?: boolean;
  borderWidth?: number;
  valueOnClick?: (v: string) => void;
};

export function SchemaAttribute({
  width = '100%',
  name = '-',
  value = '-',
  nameColor = R.colors.blueGrey,
  valueColor = R.colors.black,
  isAnim = false,
  disabled = false,
  borderWidth = 1,
  valueOnClick,
}: SchemaAttributeProps) {
  const { hAnimProps, mouseEnter, mouseLeave } = useMouseEnterEffectType1();

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv
        style={{
          width: width,
          paddingBottom: 10,

          // ...devOutlineF,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              width: width,
              alignSelf: 'stretch',
              alignItems: 'center',
              height: 60,
              backgroundColor: disabled ? R.colors.lightGrey : R.colors.bgGrey,
              borderWidth: borderWidth,
              borderStyle: 'solid',
              borderColor: R.colors.line,
              borderRadius: 15,
              // ...devOutline,
              // paddingLeft: 14,
              // paddingRight: 14,
            },
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
        >
          <TextCon
            text={Utils.svc.convertIfReqAttrName(name)}
            size={CLAY.FORM_FONT_SIZE}
            color={R.colors.black}
            isBold
            containerStyle={{
              //
              width: 128,
              paddingLeft: 12,
              paddingRight: 12,
              // ...devOutlineF,
            }}
          />
          <VLINE size={40} color={R.colors.veryLightGrey2} />
          <TextCon text={value} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} containerStyle={{ paddingLeft: 12 }} />
        </AnimatedFlexRowDiv>
      </FlexColumnDiv>
    );
  } else {
    return (
      <FlexColumnDiv
        style={{
          width: width,
          paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
          paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
          paddingBottom: 10,

          // ...devOutlineF,
        }}
      >
        <AnimatedFlexRowDiv
          style={Object.assign(
            {
              alignItems: 'center',
              height: 68,
              backgroundColor: disabled ? R.colors.lightGrey : R.colors.bgGrey,
              borderWidth: borderWidth,
              borderStyle: 'solid',
              borderColor: R.colors.line,
              borderRadius: 15,
              // ...devOutline,
              // paddingLeft: 14,
              // paddingRight: 14,
            },
            isAnim ? hAnimProps : undefined
          )}
          onMouseEnter={isAnim ? mouseEnter : undefined}
          onMouseLeave={isAnim ? mouseLeave : undefined}
        >
          <TextCon
            text={Utils.svc.convertIfReqAttrName(name)}
            size={14}
            color={R.colors.black}
            isBold
            containerStyle={{
              //
              width: ATTRIBUTE_NAME_WIDTH,
              paddingLeft: 14,
              paddingRight: 14,
              ...devOutline,
            }}
          />
          <FlexDiv style={{ height: 50, width: 1, backgroundColor: R.colors.veryLightGrey2 }} />
          <TextCon text={value} size={14} color={R.colors.black} containerStyle={{ paddingLeft: 14 }} />
        </AnimatedFlexRowDiv>
      </FlexColumnDiv>
    );
  }
}
