/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { AST_CARD_PADDING_H_FOR_LIST } from 'src/ui/common_component/AssetCardForList';
import { AssetGroupCard, AssetCardGroupProps } from 'src/ui/common_component/AssetGroupCard';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/

// todo refactoring
export function AssetGroupCardForList(props: AssetCardGroupProps) {
  return (
    <FlexDiv
      style={{
        padding: AST_CARD_PADDING_H_FOR_LIST,
        justifyContent: 'center',
        alignItems: 'center',
        ...devOutline,
      }}
    >
      <AssetGroupCard {...props} />
    </FlexDiv>
  );
}
