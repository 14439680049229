import { Subject } from 'rxjs';

export enum CbtStompMsgType_e {
  USER_NOTI = 'USER_NOTI',
  ECHO = 'ECHO',
}
export enum CbtStompMsgCode_e {
  GENERAL = 'GENERAL',
  ASSET_COLLECTION_CREATE_SUCCESS = 'ASSET_COLLECTION_CREATE_SUCCESS',
  ASSET_COLLECTION_CREATE_FAIL = 'ASSET_COLLECTION_CREATE_FAIL',
  ASSET_COLLECTION_EDIT_SUCCESS = 'ASSET_COLLECTION_EDIT_SUCCESS',
  ASSET_COLLECTION_EDIT_FAIL = 'ASSET_COLLECTION_EDIT_FAIL',
  ASSET_SCHEMA_CREATE_SUCCESS = 'ASSET_SCHEMA_CREATE_SUCCESS',
  ASSET_SCHEMA_CREATE_FAIL = 'ASSET_SCHEMA_CREATE_FAIL',
  ASSET_SCHEMA_EDIT_SUCCESS = 'ASSET_SCHEMA_EDIT_SUCCESS',
  ASSET_SCHEMA_EDIT_FAIL = 'ASSET_SCHEMA_EDIT_FAIL',
  ASSET_CREATE_SUCCESS = 'ASSET_CREATE_SUCCESS',
  ASSET_CREATE_FAIL = 'ASSET_CREATE_FAIL',
  ASSET_ADDITIONAL_CREATE_SUCCESS = 'ASSET_ADDITIONAL_CREATE_SUCCESS',
  ASSET_ADDITIONAL_CREATE_FAIL = 'ASSET_ADDITIONAL_CREATE_FAIL',
  BILL_CHARGE_POINT_SUCCESS = 'BILL_CHARGE_POINT_SUCCESS',
  BILL_CHARGE_POINT_FAIL = 'BILL_CHARGE_POINT_FAIL',
}

interface NormalMsg {
  kind: 'NormalMsg';
  text: string;
}

export enum AppEventType {
  StompDisconnected,
}
interface AppEventMsg {
  kind: 'AppEventMsg';
  type: AppEventType;
}
export interface SendStompMsg extends CbtStomp_i {
  kind: 'PublishStompMsg';
}

export interface ReceiveStompMsg extends CbtStomp_i {
  kind: 'ReceiveStompMsg';
}

export interface CbtStomp_i {
  type: CbtStompMsgType_e;
  code: CbtStompMsgCode_e;
  content: string;
  from: string;
  to: string;
  time: number;
}

const subject = new Subject<AppEventMsg | NormalMsg | ReceiveStompMsg | SendStompMsg>();

export const messageService = {
  sendMessage: (msg: AppEventMsg | NormalMsg | ReceiveStompMsg | SendStompMsg) => subject.next(msg),
  onMessage: () => subject.asObservable(),
};
