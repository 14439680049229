/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useEffect } from 'react';
import { DBGMSG } from 'src/util/utils';

export function useMakePressable(
  targetList: {
    elementKey: string;
    elementRefList: any[];
    onInsidePress: (args: { e: MouseEvent; hitRef: any }) => void;
    onOutsidePress: (e: MouseEvent) => void;
  }[]
) {
  useEffect(() => {
    const handleMousedownEvent = (event: MouseEvent) => {
      for (const target of targetList) {
        // DBGMSG(`$$$$MAKE_CLICKABLE ${target.elementKey}`);
        let isHit = false;
        let hitRef: any = undefined;
        for (const ref of target.elementRefList) {
          if (ref.current) {
            if (ref.current.contains(event.target)) {
              // DBGMSG(`$$$$MAKE_CLICKABLE ${target.elementKey} - contain`);
              isHit = true;
              hitRef = ref;
            } else {
              // DBGMSG(`$$$$MAKE_CLICKABLE ${target.elementKey} - not contain`);
            }
          }
        }
        // DBGMSG(`$$$$MAKE_CLICKABLE ${target.elementKey} - isHit:${isHit}`);
        if (isHit) {
          // DBGMSG(`$$$$MAKE_CLICKABLE ${target.elementKey} - onInsideClick`);
          target.onInsidePress({ e: event, hitRef: hitRef });
        } else {
          // DBGMSG(`$$$$MAKE_CLICKABLE ${target.elementKey} - onOutsideClick`);
          target.onOutsidePress(event);
        }
      }
    };
    // DBGMSG(`$$$$MAKE_CLICKABLE addEventListener`);
    document.addEventListener('mousedown', handleMousedownEvent);
    return () => {
      // DBGMSG(`$$$$MAKE_CLICKABLE removeEventListener`);
      document.removeEventListener('mousedown', handleMousedownEvent);
    };
  }, [targetList]);
}
