/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CbtMarketSearchSort_e } from 'src/model/model';
import { CbtAssetType_e, CbtAttributeType_e, CbtCurrency_e, CbtMarketSearchCurrencyFilter_t, CbtUserLanguage_e } from 'src/model/rpcModel';

export class StaticStrings {
  static readonly TEST1 = 'test1';
  static readonly DOLLAR = '$';
  static readonly YEN = '¥';

  static readonly COBALT_URL = 'https://cobalt.zone';
  static readonly SETTINGS_ENGLISH = 'English';
  static readonly SETTINGS_JAPANESE = '日本語';
  static readonly SETTINGS_KOREAN = '한국어';

  static readonly SETTINGS_USD = 'USD';
  static readonly SETTINGS_JPY = 'JPY';

  static readonly MARKETPLACE_SEARCH_COLLECTION_FILTER_POKECOLO = 'Pokecolo';
  static readonly MARKETPLACE_SEARCH_COLLECTION_FILTER_CARROT = 'Carrot';
  static readonly MARKETPLACE_SEARCH_COLLECTION_FILTER_STICHY = 'Stichy';

  static readonly CURRENCY_CPOINT = 'POINT';
  static readonly CURRENCY_CUSD = 'USD';
  static readonly CURRENCY_KLAY = 'KLAY';
  static readonly CURRENCY_CBLT = 'CBLT';

  // 문자열 리소스 placeholder
  static readonly PLACEHOLDER_SALEID = '{판매ID}';
  static readonly PLACEHOLDER_CNT = '{개수}';
  static readonly PLACEHOLDER_PRICE = '{가격}';
  static readonly PLACEHOLDER_ATTR_TTYPE = '{속성타입}';
  static readonly PLACEHOLDER_RES_SIZE = '{사이즈}';
  static readonly PLACEHOLDER_FILE_EXT_LIST = '{파일형식리스트}';
  static readonly PLACEHOLDER_FILE_SIZE_IN_MB = '{파일사이즈MB}';
  static readonly PLACEHOLDER_COLLECTION_NAME = '{컬렉션이름}';
  static readonly PLACEHOLDER_SCHEMA_NAME = '{스키마이름}';
  static readonly PLACEHOLDER_ASSET_NAME = '{에셋이름}';

  // 필수 속성의 표시이름
  static readonly NFT_REQ_ATTRNAME_NAME = 'Name';
  static readonly NFT_REQ_ATTRNAME_IMAGE = 'Image';
  static readonly NFT_REQ_ATTRNAME_AUDIO = 'Audio';
  static readonly NFT_REQ_ATTRNAME_VIDEO = 'Video';

  static getCurrencyUnit(currency: CbtCurrency_e): string {
    switch (currency) {
      case CbtCurrency_e.cUSD:
        return StaticStrings.CURRENCY_CUSD;
      case CbtCurrency_e.KLAY:
        return StaticStrings.CURRENCY_KLAY;
    }
    return 'unknown';
  }
}

export default abstract class Strings {
  abstract readonly LANG: CbtUserLanguage_e;

  // ------------------------------------------------
  // 공통
  // ------------------------------------------------
  abstract readonly COMMON_BACK: string;
  abstract readonly COMMON_COLLECTION: string;
  abstract readonly COMMON_COLLECTION_NAME: string;
  abstract readonly COMMON_DISP_NAME: string;
  abstract readonly COMMON_ROYALTY: string;
  abstract readonly COMMON_WEBSITE: string;
  abstract readonly COMMON_DESC: string;
  abstract readonly COMMON_ADMIN: string;
  abstract readonly COMMON_CANNOT_EDIT: string;
  abstract readonly COMMON_ASSET_NAME: string;
  abstract readonly COMMON_ASSET_MINT_CNT: string;
  abstract readonly COMMON_ASSET_MINT_NO_MORE: string;
  abstract readonly COMMON_ASSET_MINT_MORE: string;
  abstract readonly COMMON_ASSET_TYPE: string;
  abstract readonly COMMON_ASSET_TYPE_IMAGE: string;
  abstract readonly COMMON_ASSET_TYPE_VIDEO: string;
  abstract readonly COMMON_ASSET_TYPE_AUDIO: string;
  abstract readonly COMMON_SCHEMA: string;
  abstract readonly COMMON_SCHEMA_NAME: string;
  abstract readonly COMMON_ATTR: string;
  abstract readonly COMMON_ATTR_NAME: string;
  abstract readonly COMMON_ATTR_TYPE: string;
  abstract readonly COMMON_ATTR_TYPE_TEXT: string;
  abstract readonly COMMON_ATTR_TYPE_NUMBER: string;
  abstract readonly COMMON_ATTR_TYPE_IMAGE: string;
  abstract readonly COMMON_ATTR_TYPE_VIDEO: string;
  abstract readonly COMMON_ATTR_TYPE_AUDIO: string;
  abstract readonly COMMON_ATTR_TYPE_IMAGE_DETAIL: string;
  abstract readonly COMMON_ATTR_TYPE_VIDEO_DETAIL: string;
  abstract readonly COMMON_ATTR_TYPE_AUDIO_DETAIL: string;
  abstract readonly COMMON_ASSET: string;
  abstract readonly COMMON_PRICE_HISTORY_LOWEST: string;
  abstract readonly COMMON_PRICE_HISTORY_HIGHEST: string;
  abstract readonly COMMON_PRICE_HISTORY_TX_CNT: string;
  abstract readonly COMMON_ASSET_TRADING_HISTORY: string;
  abstract readonly COMMON_ASSET_TRADING_HISTORY_EVENT: string;
  abstract readonly COMMON_ASSET_TRADING_HISTORY_PRICE: string;
  abstract readonly COMMON_ASSET_TRADING_HISTORY_FROM: string;
  abstract readonly COMMON_ASSET_TRADING_HISTORY_TO: string;
  abstract readonly COMMON_ASSET_TRADING_HISTORY_DATE: string;
  abstract readonly COMMON_ASSET_TRADING_HISTORY_NO_DATA: string;
  abstract readonly COMMON_BUY_BTN: string;
  abstract readonly COMMON_CREATOR: string;
  abstract readonly COMMON_OWNER: string;
  abstract readonly COMMON_SELLER: string;
  abstract readonly COMMON_BUYER: string;
  abstract readonly COMMON_PRICE: string;
  abstract readonly COMMON_TX_HASH: string;
  abstract readonly COMMON_VIEW_ON_BLOCKS: string;
  abstract readonly COMMON_MENU_INVENTORY: string;
  abstract readonly COMMON_MENU_MINTED: string;
  abstract readonly COMMON_MENU_ON_SALE: string;
  abstract readonly COMMON_MENU_SOLD: string;
  abstract readonly COMMON_MENU_PURCHASED: string;
  abstract readonly COMMON_EMAIL: string;
  abstract readonly COMMON_EMAIL_PLACEHOLDER: string;
  abstract readonly COMMON_EMAIL_INVALID: string;
  abstract readonly COMMON_PASSWORD: string;
  abstract readonly COMMON_PASSWORD_PLACEHOLDER: string;
  abstract readonly COMMON_SEARCH_PLACEHOLDER: string;
  abstract readonly COMMON_SEARCH_ORDERBY_LISTING_NEWEST: string;
  abstract readonly COMMON_SEARCH_ORDERBY_LISTING_OLDEST: string;
  abstract readonly COMMON_SEARCH_ORDERBY_PRICE_HIGHEST: string;
  abstract readonly COMMON_SEARCH_ORDERBY_PRICE_LOWEST: string;
  abstract readonly COMMON_PASSWORD_GUIDE_1: string;
  abstract readonly COMMON_PASSWORD_GUIDE_2: string;
  abstract readonly COMMON_PASSWORD_GUIDE_3: string;
  abstract readonly COMMON_PASSWORD_GUIDE_4: string;
  abstract readonly COMMON_OK_BTN_TXT: string;
  abstract readonly COMMON_REPORT: string;
  abstract readonly COMMON_INQUERY: string;
  abstract readonly COMMON_SALE_BTN: string;
  abstract readonly COMMON_SALE_EDIT_BTN: string;
  abstract readonly COMMON_NOTI_TITLE: string;

  // ------------------------------------------------
  // HEADER
  // ------------------------------------------------
  abstract readonly HEADER_SETTINGS_LANG: string;
  abstract readonly HEADER_SETTINGS_CURRENCY: string;
  abstract readonly HEADER_MENU_EXPLORE: string;
  abstract readonly HEADER_MENU_ASSET_CREATOR: string;
  abstract readonly HEADER_MENU_MARKET_PLACE: string;
  abstract readonly HEADER_LOGIN_BTN: string;
  abstract readonly HEADER_KAIKAS_WALLET_CONNECT: string;
  abstract readonly HEADER_MYPAGE: string;
  abstract readonly HEADER_LOGOUT: string;

  // ------------------------------------------------
  // FOOTER
  // ------------------------------------------------
  abstract readonly FOOTER_TERMS: string;
  abstract readonly FOOTER_PRIVACY: string;
  abstract readonly FOOTER_REFUND_POLICY: string;
  abstract readonly FOOTER_RAW1: string;
  abstract readonly FOOTER_RAW2: string;
  abstract readonly FOOTER_SUPPORT_EMAIL: string;
  abstract readonly FOOTER_SUPPORT_EMAIL_ADDRESS: string;
  abstract readonly FOOTER_COPYRIGHT: string;
  abstract readonly FOOTER_INQUERY: string;

  // ------------------------------------------------
  // Explorer 화면
  // ------------------------------------------------
  abstract readonly EXP_MAIN_BANNER_BTN: string;
  abstract readonly EXP_BANNER_COLLECTION_TITLE: string;
  abstract readonly EXP_BANNER_CARROT_TITLE: string;
  abstract readonly EXP_BANNER_POKECOLO_TITLE: string;
  abstract readonly EXP_BANNER_EVENT_TITLE: string;
  abstract readonly EXP_BANNER_EVENT_CBLT: string;
  abstract readonly EXP_SUB_BANNER_BTN: string;
  abstract readonly EXP_STATUS_BOARD_ASSET_CNT: string;
  abstract readonly EXP_STATUS_BOARD_SALE_CNT: string;
  abstract readonly EXP_STATUS_BOARD_TX_CNT: string;
  abstract readonly EXP_STATUS_BOARD_DOLLAR_CNT: string;
  abstract readonly EXP_RECOMMEND_ASSET_SECTION_TITLE: string;
  abstract readonly EXP_NEW_ASSET_SECTION_TITLE: string;
  abstract readonly EXP_NEW_ASSET_VIEW_ALL: string;
  abstract readonly EXP_COLLECTION_SECTION_TITLE: string;

  // ------------------------------------------------
  // AssetCreator
  // ------------------------------------------------
  abstract readonly AC_COLLECTION_LIST_TITLE_IF_EMTPY: string;
  abstract readonly AC_COLLECTION_LIST_DESC_1: string;
  abstract readonly AC_COLLECTION_LIST_DESC_2: string;
  abstract readonly AC_COLLECTION_LIST_TITLE: string;
  abstract readonly AC_COLLECTION_LIST_NEW: string;
  abstract readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_1: string;
  abstract readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_2: string;
  abstract readonly AC_COLLECTION_CREATE_BACK: string;
  abstract readonly AC_COLLECTION_CREATE_TITLE: string;
  abstract readonly AC_COLLECTION_CREATE_IMAGE: string;
  abstract readonly AC_COLLECTION_CREATE_IMAGE_UPLOAD_SPEC: string;
  abstract readonly AC_COLLECTION_CREATE_CANNOT_EDIT: string;
  abstract readonly AC_COLLECTION_CREATE_NAME: string;
  abstract readonly AC_COLLECTION_CREATE_NAME_GUIDE: string;
  abstract readonly AC_COLLECTION_CREATE_NAME_DUPLICATED: string;
  abstract readonly AC_COLLECTION_CREATE_DISPNAME: string;
  abstract readonly AC_COLLECTION_CREATE_DISPNAME_GUIDE: string;
  abstract readonly AC_COLLECTION_CREATE_ROYALTY: string;
  abstract readonly AC_COLLECTION_CREATE_ROYALTY_GUIDE: string;
  abstract readonly AC_COLLECTION_CREATE_WEBSITE: string;
  abstract readonly AC_COLLECTION_CREATE_DESC: string;
  abstract readonly AC_COLLECTION_CREATE_ADMIN: string;
  abstract readonly AC_COLLECTION_CREATE_ADMIN_INVALID: string;
  abstract readonly AC_COLLECTION_CREATE_BTN: string;
  abstract readonly AC_COLLECTION_CREATE_COL_IMG_TOOLTIP: string;
  abstract readonly AC_COLLECTION_CREATE_TOOLTIP_DISPNAME: string;
  abstract readonly AC_COLLECTION_CREATE_TOOLTIP_ROYALTY: string;
  abstract readonly DLG_EXCEED_COLLECTION_LIMIT: string;
  abstract readonly AC_COLLECTION_UPDATE_TITLE: string;
  abstract readonly AC_COLLECTION_UPDATE_BTN: string;
  abstract readonly AC_COLLECTION_READ_BACK: string;
  abstract readonly AC_COLLECTION_READ_TITLE: string;
  abstract readonly AC_COLLECTION_READ_EDIT_BTN: string;
  abstract readonly AC_COLLECTION_READ_OWNER: string;
  abstract readonly AC_COLLECTION_READ_COLLECTION_NAME: string;
  abstract readonly AC_COLLECTION_READ_DISP_NAME: string;
  abstract readonly AC_COLLECTION_READ_ROYALTY: string;
  abstract readonly AC_COLLECTION_READ_WEBSITE: string;
  abstract readonly AC_COLLECTION_READ_DESC: string;
  abstract readonly AC_COLLECTION_READ_ADMIN: string;
  abstract readonly AC_COLLECTION_READ_SCHEMA: string;
  abstract readonly AC_COLLECTION_READ_SCHEMA_DESC_1: string;
  abstract readonly AC_COLLECTION_READ_SCHEMA_DESC_2: string;
  abstract readonly AC_COLLECTION_READ_SCHEMA_ATTR_CNT: string;
  abstract readonly AC_SCHEMA_CREATE_BACK: string;
  abstract readonly AC_SCHEMA_CREATE_TITLE: string;
  abstract readonly AC_SCHEMA_CREATE_NAME: string;
  abstract readonly AC_SCHEMA_CREATE_NAME_GUIDE: string;
  abstract readonly AC_SCHEMA_CREATE_NAME_DUPLICATED: string;
  abstract readonly AC_SCHEMA_CREATE_CANNOT_EDIT: string;
  abstract readonly AC_SCHEMA_CREATE_TYPE: string;
  abstract readonly AC_SCHEMA_CREATE_TYPE_IMAGE: string;
  abstract readonly AC_SCHEMA_CREATE_TYPE_VIDEO: string;
  abstract readonly AC_SCHEMA_CREATE_TYPE_AUDIO: string;
  abstract readonly AC_SCHEMA_CREATE_DISP_NAME: string;
  abstract readonly AC_SCHEMA_CREATE_DISP_NAME_GUIDE: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_GUIDE_1: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_GUIDE_2: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_NEW: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_NEW_TYPE: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_NAME_DUPLICATED: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_NAME_RESERVED: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_TYPE_TEXT: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_TYPE_NUMBER: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO: string;
  abstract readonly AC_SCHEMA_CREATE_ATTR_ADD_BTN: string;
  abstract readonly AC_SCHEMA_CREATE_BTN: string;
  abstract readonly AC_SCHEMA_CREATE_TOOLTIP_DISPNAME: string;
  abstract readonly DLG_EXCEED_SCHEMA_LIMIT: string;
  abstract readonly AC_SCHEMA_UPDATE_TITLE: string;
  abstract readonly AC_SCHEMA_UPDATE_BTN: string;
  abstract readonly AC_SCHEMA_READ_BACK: string;
  abstract readonly AC_SCHEMA_READ_ATTR_CNT: string;
  abstract readonly AC_SCHEMA_READ_EDIT_BTN: string;
  abstract readonly AC_SCHEMA_READ_TITLE: string;
  abstract readonly AC_SCHEMA_READ_ATTR_NAME: string;
  abstract readonly AC_SCHEMA_READ_ATTR_TYPE: string;
  abstract readonly AC_SCHEMA_READ_ATTR_TYPE_TEXT: string;
  abstract readonly AC_SCHEMA_READ_ATTR_TYPE_NUMBER: string;
  abstract readonly AC_SCHEMA_READ_ATTR_TYPE_IMAGE_DETAIL: string;
  abstract readonly AC_SCHEMA_READ_ATTR_TYPE_VIDEO_DETAIL: string;
  abstract readonly AC_SCHEMA_READ_ATTR_TYPE_AUDIO_DETAIL: string;
  abstract readonly AC_SCHEMA_READ_ASSET: string;
  abstract readonly AC_SCHEMA_READ_NEW_ASSET_DESC: string;
  abstract readonly AC_SCHEMA_READ_NEW_ASSET: string;
  abstract readonly AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT: string;
  abstract readonly AC_ASSET_GROUP_MINT_MORE_BTN: string;
  abstract readonly AC_ASSET_GROUP_DLG_TITLE: string;
  abstract readonly AC_ASSET_GROUP_DLG_MINT_OWNER: string;
  abstract readonly AC_ASSET_GROUP_DLG_MINT_CNT: string;
  abstract readonly AC_ASSET_GROUP_DLG_MINT_CNT_GUIDE: string;
  abstract readonly AC_ASSET_GROUP_DLG_MINT_TOTAL: string;
  abstract readonly AC_ASSET_GROUP_DLG_MINT_BTN: string;
  abstract readonly AC_ASSET_CREATE_BACK: string;
  abstract readonly AC_ASSET_CREATE_TITLE: string;
  abstract readonly AC_ASSET_CREATE_PREVIEW: string;
  abstract readonly AC_ASSET_CREATE_OWNER: string;
  abstract readonly AC_ASSET_CREATE_MINT_CNT: string;
  abstract readonly AC_ASSET_CREATE_MINT_CNT_GUIDE: string;
  abstract readonly AC_ASSET_CREATE_MINT_NO_MORE: string;
  abstract readonly AC_ASSET_CREATE_MINT_MORE: string;
  abstract readonly AC_ASSET_CREATE_ATTR: string;
  abstract readonly AC_ASSET_CREATE_ATTR_TYPE_NFTNAME: string;
  abstract readonly AC_ASSET_CREATE_ATTR_TYPE_TEXT: string;
  abstract readonly AC_ASSER_CREATE_ATTR_TYPE_NUMBER: string;
  abstract readonly AC_ASSET_CREATE_ATTR_TYPE_IMAGE_DETAIL: string;
  abstract readonly AC_ASSET_CREATE_ATTR_TYPE_VIDEO_DETAIL: string;
  abstract readonly AC_ASSET_CREATE_ATTR_TYPE_AUDIO_DETAIL: string;
  abstract readonly AC_ASSET_CREATE_FILE: string;
  abstract readonly AC_ASSET_CREATE_FILE_UPLOAD_SPEC: string;
  abstract readonly AC_ASSET_CREATE_FILE_IMAGE_SPEC_TOOLTIP: string;
  abstract readonly AC_ASSET_CREATE_FILE_VIDEO_THUMB_IMAGE_SPEC_TOOLTIP: string;
  abstract readonly AC_ASSET_CREATE_FILE_AUDIO_COVER_IMAGE_SPEC_TOOLTIP: string;
  abstract readonly AC_ASSET_CREATE_FILE_AUDIO_SPEC_TOOLTIP: string;
  abstract readonly AC_ASSET_CREATE_FILE_VIDEO_SPEC_TOOLTIP: string;
  abstract readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_TITLE: string;
  abstract readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_DESC: string;
  abstract readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_TITLE: string;
  abstract readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_DESC: string;
  abstract readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_TITLE: string;
  abstract readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_DESC: string;
  abstract readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TITLE: string;
  abstract readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TEXT: string;
  abstract readonly AC_ASSET_CREATE_TERMS1_TITLE: string;
  abstract readonly AC_ASSET_CREATE_TERMS1_DETAIL_1: string;
  abstract readonly AC_ASSET_CREATE_TERMS1_DETAIL_2: string;
  abstract readonly AC_ASSET_CREATE_TERMS2_TITLE: string;
  abstract readonly AC_ASSET_CREATE_TERMS2_DETAIL: string;
  abstract readonly AC_ASSET_CREATE_BTN: string;
  abstract readonly AC_ASSET_CREATE_TOOLTIP_MINT: string;
  abstract readonly AC_ASSET_READ_BACK: string;
  abstract readonly AC_ASSET_READ_OWNER: string;
  abstract readonly AC_ASSET_READ_CREATOR: string;
  abstract readonly AC_ASSET_READ_ASSET_ID: string;
  abstract readonly AC_ASSET_READ_ASSET_TYPE: string;
  abstract readonly AC_ASSET_READ_MINT_CNT: string;
  abstract readonly AC_ASSET_READ_MINT_NO_MORE: string;
  abstract readonly AC_ASSET_READ_MINT_MORE: string;
  abstract readonly AC_ASSET_READ_BEFORE_SALE: string;
  abstract readonly AC_ASSET_READ_SALE_BTN: string;
  abstract readonly AC_ASSET_READ_ON_SALE: string;
  abstract readonly AC_ASSET_READ_SALE_EDIT_BTN: string;
  abstract readonly AC_ASSET_READ_TRADING_HISTORY: string;
  abstract readonly AC_ASSET_READ_TRADING_HISTORY_EVENT: string;
  abstract readonly AC_ASSET_READ_TRADING_HISTORY_PRICE: string;
  abstract readonly AC_ASSET_READ_TRADING_HISTORY_FROM: string;
  abstract readonly AC_ASSET_READ_TRADING_HISTORY_TO: string;
  abstract readonly AC_ASSET_READ_TRADING_HISTORY_DATE: string;
  abstract readonly AC_ASSET_READ_TRADING_HISTORY_NO_DATA: string;
  abstract readonly DLG_SELL_TITLE: string;
  abstract readonly DLG_SELL_TITLE_EDIT: string;
  abstract readonly DLG_SELL_PRICE: string;
  abstract readonly DLG_SELL_MINIMUM_LIMIT: string;
  abstract readonly DLG_SELL_FEE: string;
  abstract readonly DLG_SELL_ROYALTY: string;
  abstract readonly DLG_SELL_PLATFORM_FEE: string;
  abstract readonly DLG_SELL_CALC_PRICE: string;
  abstract readonly DLG_SELL_OK_BTN: string;
  abstract readonly DLG_SELL_KLAY_ADDRESS_REG_GUIDE: string;
  abstract readonly DLG_SELL_KLAY_ADDRESS: string;
  abstract readonly DLG_SELL_KLAY_ADDRESS_REWARD: string;
  abstract readonly DLG_SELL_KLAY_ADDRESS_REWARD_GUIDE: string;
  abstract readonly DLG_SELL_STOP_BTN: string;
  abstract readonly DLG_SELL_AGREE_POLICY_TITLE: string;
  abstract readonly DLG_SELL_AGREE_POLICY_TEXT: string;
  abstract readonly DLG_KAIKAS_ONLY_DESKTOP_SELL: string;
  abstract readonly DLG_KAIKAS_ONLY_DESKTOP_BUY: string;
  abstract readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TITLE: string;
  abstract readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TXT: string;
  abstract readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TITLE: string;
  abstract readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TXT: string;
  abstract readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TITLE: string;
  abstract readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TXT: string;
  abstract readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TITLE: string;
  abstract readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TXT: string;
  abstract readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TITLE: string;
  abstract readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TXT: string;
  abstract readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TITLE: string;
  abstract readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TXT: string;
  abstract readonly AC_BLOCKED_TITLE: string;
  abstract readonly AC_BLOCKED_GUIDE_ROW_1: string;
  abstract readonly AC_BLOCKED_GUIDE_ROW_2: string;
  abstract readonly AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT: string;
  abstract readonly AC_BLOCKED_BTN: string;

  // ------------------------------------------------
  // Market place
  // ------------------------------------------------
  abstract readonly MARKET_LIST_SEARCH_PLACEHOLDER: string;
  abstract readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_NEWEST: string;
  abstract readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_OLDEST: string;
  abstract readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_HIGHEST: string;
  abstract readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_LOWEST: string;
  abstract readonly MARKET_LIST_SEARCH_FILTER_COLLECTION: string;
  abstract readonly MARKET_LIST_SEARCH_FILTER_CURRENCY: string;
  abstract readonly ASSET_CARD_BUY_BTN: string;
  abstract readonly MARKET_ASSET_READ_BACK: string;
  abstract readonly MARKET_ASSET_READ_OWNER: string;
  abstract readonly MARKET_ASSET_READ_CREATOR: string;
  abstract readonly MARKET_ASSET_READ_SOLD: string;
  abstract readonly MARKET_ASSET_READ_BUYER: string;
  abstract readonly MARKET_ASSET_READ_BUY_BTN: string;
  abstract readonly MARKET_ASSET_READ_ASSET_ID: string;
  abstract readonly MARKET_ASSET_READ_SALE_ID: string;
  abstract readonly MARKET_ASSET_READ_ASSET_TYPE: string;
  abstract readonly MARKET_ASSET_READ_MINT_CNT: string;
  abstract readonly MARKET_ASSET_READ_MINT_NO_MORE: string;
  abstract readonly MARKET_ASSET_READ_MINT_MORE: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY_SUB: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY_LOWEST: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY_HIGHEST: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY_TX_CNT: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_CNT: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_AVGPRC: string;
  abstract readonly MARKET_ASSET_READ_PRICE_HISTORY_NO_DATA: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY_PRICE: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY_FROM: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY_TO: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY_DATE: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY_NO_DATA: string;
  abstract readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT_SALE: string;
  abstract readonly MARKET_COPY_LINK_BTN: string;
  abstract readonly MARKET_SHARE_TWITTER_BTN: string;
  abstract readonly DLG_BUY_TITLE: string;
  abstract readonly DLG_BUY_COLLECTION: string;
  abstract readonly DLG_BUY_ASSET_NAME: string;
  abstract readonly DLG_BUY_MINT_CNT: string;
  abstract readonly DLG_BUY_SCHEMA: string;
  abstract readonly DLG_BUY_SELLER: string;
  abstract readonly DLG_BUY_PRICE: string;
  abstract readonly DLG_BUY_BTN: string;
  abstract readonly MARKET_COLLECTION_READ_BACK: string;
  abstract readonly MARKET_COLLECTION_READ_COLLECTION: string;
  abstract readonly MARKET_COLLECTION_READ_OWNER: string;
  abstract readonly MARKET_COLLECTION_READ_COLLECTION_NAME: string;
  abstract readonly MARKET_COLLECTION_READ_DISP_NAME: string;
  abstract readonly MARKET_COLLECTION_READ_ROYALTY: string;
  abstract readonly MARKET_COLLECTION_READ_WEBSITE: string;
  abstract readonly MARKET_COLLECTION_READ_DESC: string;
  abstract readonly MARKET_COLLECTION_READ_ADMIN: string;
  abstract readonly MARKET_COLLECTION_READ_ASSET_LIST_TITLE: string;
  abstract readonly MARKET_SCHEMA_READ_BACK: string;
  abstract readonly MARKET_SCHEMA_READ_ATTR_CNT: string;
  abstract readonly MARKET_SCHEMA_READ_TITLE: string;
  abstract readonly MARKET_SCHEMA_READ_ATTR_NAME: string;
  abstract readonly MARKET_SCHEMA_READ_ATTR_TYPE: string;
  abstract readonly MARKET_SCHEMA_READ_ASSET_LIST_TITLE: string;
  abstract readonly MARKET_ACCOUNT_READ_VIEW_ON_BLOCKS: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_INVENTORY: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_CREATED: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_ON_SALE: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_SOLD: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_PURCHASED: string;
  abstract readonly MARKET_ACCOUNT_READ_SEARCH_PLACEHOLDER: string;
  abstract readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_NEWEST: string;
  abstract readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_OLDEST: string;
  abstract readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_HIGHEST: string;
  abstract readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_LOWEST: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_INVEN_CNT: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_SOLD_ASSET_ID: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_SOLD_DATE: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_SOLD_SELLER: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_SOLD_BUYER: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_SOLD_PRICE: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_SOLD_TX_HASH: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_ASSET_ID: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_DATE: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_SELLER: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_BUYER: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_PRICE: string;
  abstract readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_TX_HASH: string;
  abstract readonly MARKET_REPORT_BTN: string;
  abstract readonly MARKET_DLG_REPORT_TITLE: string;
  abstract readonly MARKET_DLG_REPORT_CASE_1: string;
  abstract readonly MARKET_DLG_REPORT_CASE_2: string;
  abstract readonly MARKET_DLG_REPORT_CASE_3: string;
  abstract readonly MARKET_DLG_REPORT_CASE_4: string;
  abstract readonly MARKET_DLG_REPORT_CASE_5: string;
  abstract readonly MARKET_DLG_REPORT_BTN: string;
  abstract readonly MARKET_DLG_REPORT_SUCCESS: string;
  abstract readonly MARKET_ASSET_CARD_BLOCK: string;
  abstract readonly MARKET_DLG_ERROR_ALREADY_OWNER: string;
  abstract readonly DLG_WILL_MOVE_PAYMENTPAGE_TITLE: string;
  abstract readonly DLG_WILL_MOVE_PAYMENTPAGE_TEXT: string;
  abstract readonly MARKET_PAYMENT_TITLE: string;
  abstract readonly MARKET_PAYMENT_ASSET: string;
  abstract readonly MARKET_PAYMENT_POINT: string;
  abstract readonly MARKET_PAYMENT_POINT_TOOLTIP: string;
  abstract readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT1: string;
  abstract readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT2: string;
  abstract readonly MARKET_PAYMENT_MY_POINT_BALANCE: string;
  abstract readonly MARKET_PAYMENT_LACK_POINT_AMOUNT: string;
  abstract readonly MARKET_PAYMENT_CHARGE_POINT: string;
  abstract readonly MARKET_PAYMENT_CHARGE_POINT_GUIDE: string;
  abstract readonly MARKET_PAYMENT_PRICE: string;
  abstract readonly MARKET_PAYMENT_AGREE_ALL: string;
  abstract readonly MARKET_PAYMENT_AGREE_TERMS_OF_USE: string;
  abstract readonly MARKET_PAYMENT_AGREE_REFUND_POLICY: string;
  abstract readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY: string;
  abstract readonly MARKET_PAYMENT_AGREE_TERM_OF_USE_LINK: string;
  abstract readonly MARKET_PAYMENT_AGREE_REFUND_POLICY_LINK: string;
  abstract readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY_LINK: string;
  abstract readonly MARKET_PAYMENT_BTN: string;
  abstract readonly DLG_CHARGE_LIST_BTN: string;
  abstract readonly DLG_CHARGE_LIST_TITLE: string;
  abstract readonly DLG_CHARGE_FEE_DISCOUNT: string;
  abstract readonly DLG_CHARGE_LIST_MY_POINT_BALANCE: string;
  abstract readonly DLG_CHARGE_LIST_TERMS: string;
  abstract readonly DLG_CHARGE_LIST_REFUND_POLICY: string;
  abstract readonly DLG_CHARGE_LIST_PRIVACY: string;
  abstract readonly DLG_CHARGE_LIST_RAW1: string;
  abstract readonly DLG_CHARGE_LIST_RAW2: string;
  abstract readonly DLG_RUNNING_SMART_CONTRACT: string;
  abstract readonly DLG_BUY_SUCCESS_TITLE: string;
  abstract readonly DLG_BUY_SUCCESS_TEXT: string;
  abstract readonly DLG_BUY_FAILURE_TITLE: string;
  abstract readonly DLG_BUY_FAILURE_TEXT: string;
  abstract readonly DLG_RUNNING_BUY_PROCESS_TITLE: string;
  abstract readonly DLG_RUNNING_BUY_PROCESS_TEXT: string;
  abstract readonly DLG_CHARGE_SUCC: string;
  abstract readonly DLG_CHARGE_FAIL: string;

  // ------------------------------------------------
  // Mypage
  // ------------------------------------------------
  abstract readonly MY_VIEW_ON_BLOCKS: string;
  abstract readonly MY_MY_BALANCE_TITLE: string;
  abstract readonly MY_MY_BALANCE_BTN: string;
  abstract readonly MY_KLAY_ADDRESS_TITLE: string;
  abstract readonly MY_KLAY_ADDRESS_TITLE_TOOLTIP: string;
  abstract readonly MY_KLAY_ADDRESS_INPUT_PLACEHOLDER: string;
  abstract readonly MY_KLAY_ADDRESS_INPUT_REG_BTN: string;
  abstract readonly MY_KLAY_ADDRESS_INPUT_CHANGE_BTN: string;
  abstract readonly MY_KLAY_ADDRESS_INPUT_DONE_BTN: string;
  abstract readonly MY_KLAY_ADDRESS_INPUT_INVALID: string;
  abstract readonly MY_KLAY_ADDRESS_INPUT_TOOLTIP: string;
  abstract readonly MY_BALANCE_KLAY_LABEL: string;
  abstract readonly MY_BALANCE_CPOINT_LABEL: string;
  abstract readonly MY_BALANCE_CUSD_LABEL: string;
  abstract readonly MY_BALANCE_CBLT_LABEL: string;
  abstract readonly MY_BALANCE_CPOINT_CHARGE: string;
  abstract readonly MY_BALANCE_CUSD_WITHDRAWAL: string;
  abstract readonly MY_BALANCE_CBLT_TOOLTIP: string;
  abstract readonly MY_MENU_INVENTORY: string;
  abstract readonly MY_MENU_CREATED: string;
  abstract readonly MY_MENU_FOR_SALE: string;
  abstract readonly MY_MENU_SOLD: string;
  abstract readonly MY_MENU_PURCHASED: string;
  abstract readonly MY_MENU_FAVORITE: string;
  abstract readonly MY_MENU_MYACCOUNT: string;
  abstract readonly MY_SEARCH_PLACEHOLDER: string;
  abstract readonly MY_SEARCH_ORDERBY_LISTING_NEWEST: string;
  abstract readonly MY_SEARCH_ORDERBY_LISTING_OLDEST: string;
  abstract readonly MY_SEARCH_ORDERBY_PRICE_HIGHEST: string;
  abstract readonly MY_SEARCH_ORDERBY_PRICE_LOWEST: string;
  abstract readonly MY_MENU_INVENTORY_STOP_BTN: string;
  abstract readonly MY_MENU_INVENTORY_NO_DATA: string;
  abstract readonly MY_MENU_ISSUE_NO_DATA: string;
  abstract readonly MY_MENU_SALE_NO_DATA: string;
  abstract readonly MY_MENU_SOLD_NO_DATA: string;
  abstract readonly MY_MENU_BOUGHT_NO_DATA: string;
  abstract readonly MY_MENU_FAVORITE_NO_DATA: string;
  abstract readonly MY_MENU_SEARCH_NOT_FOUND: string;
  abstract readonly MY_MENU_ON_SALE_EDIT_BTN: string;
  abstract readonly MY_MENU_SOLD_ASSET_ID: string;
  abstract readonly MY_MENU_SOLD_DATE: string;
  abstract readonly MY_MENU_SOLD_SELLER: string;
  abstract readonly MY_MENU_SOLD_BUYER: string;
  abstract readonly MY_MENU_SOLD_PRICE: string;
  abstract readonly MY_MENU_SOLD_ROYALTY: string;
  abstract readonly MY_MENU_SOLD_PLATFORM_FEE: string;
  abstract readonly MY_MENU_SOLD_PROFIT: string;
  abstract readonly MY_MENU_SOLD_REWARD: string;
  abstract readonly MY_MENU_SOLD_REWARD_TOOLTIP: string;
  abstract readonly MY_MENU_SOLD_TX_HASH: string;
  abstract readonly MY_MENU_PURCHASED_ASSET_ID: string;
  abstract readonly MY_MENU_PURCHASED_DATE: string;
  abstract readonly MY_MENU_PURCHASED_SELLER: string;
  abstract readonly MY_MENU_PURCHASED_BUYER: string;
  abstract readonly MY_MENU_PURCHASED_PRICE: string;
  abstract readonly MY_MENU_PURCHASED_REWARD: string;
  abstract readonly MY_MENU_PURCHASED_REWARD_TOOLTIP: string;
  abstract readonly MY_MENU_PURCHASED_TX_HASH: string;
  abstract readonly MY_MENU_MYACCOUNT_SETTINGS: string;
  abstract readonly MY_MENU_MYACCOUNT_SETTINGS_EMAIL: string;
  abstract readonly MY_MENU_MYACCOUNT_EMAIL_AUTH_COMPLETE: string;
  abstract readonly MY_MENU_MYACCOUNT_SETTINGS_ID: string;
  abstract readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD: string;
  abstract readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD_GUIDE: string;
  abstract readonly MY_MENU_MYACCOUNT_SETTINGS_FISH_SECURE_CODE: string;
  abstract readonly MY_MENU_MYACCOUNT_SETTINGS_CHANGE_BTN: string;
  abstract readonly MY_PWCHANGE_TITLE: string;
  abstract readonly MY_PWCHANGE_CUR_PASSWORD: string;
  abstract readonly MY_PWCHANGE_CUR_PASSWORD_PLACEHOLDER: string;
  abstract readonly MY_PWCHANGE_CUR_PASSWORD_WRONG: string;
  abstract readonly MY_PWCHANGE_NEW_PASSWORD: string;
  abstract readonly MY_PWCHANGE_PASSWORD_GUIDE_1: string;
  abstract readonly MY_PWCHANGE_PASSWORD_GUIDE_2: string;
  abstract readonly MY_PWCHANGE_PASSWORD_GUIDE_3: string;
  abstract readonly MY_PWCHANGE_PASSWORD_GUIDE_4: string;
  abstract readonly MY_PWCHANGE_NEW_PASSWORD_PLACEHOLDER: string;
  abstract readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM: string;
  abstract readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_PLACEHOLDER: string;
  abstract readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_NO_MATCH: string;
  abstract readonly MY_PWCHANGE_OK_BTN: string;
  abstract readonly MY_PWCHANGE_OK_MSG_1: string;
  abstract readonly MY_PWCHANGE_OK_MSG_2: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_TITLE: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_NEXT_PLACEHOLDE: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD_WRONG: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_NEXT: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_NEW: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_NEW_PLACEHOLDER: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_OK_BTN: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_1: string;
  abstract readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_2: string;
  abstract readonly MY_PAYOUT_DLG_TITLE: string;
  abstract readonly MY_PAYOUT_DLG_USD_BALANCE: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_AMOUNT: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_AMOUNT_SUB: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_AMOUNT_ALL_BTN: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_AMOUNT_MINIMUM: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_AMOUNT_INSUFFICIENT: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_ACCOUNT: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_ACCOUNT_AUTH_DONE: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_SUB: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_KAIKAS: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE_LINK: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_GUIDE: string;
  abstract readonly MY_PAYOUT_DLG_SECTION_GUIDE_DETAIL: string;
  abstract readonly MY_PAYOUT_DLG_APPLY_BTN: string;
  abstract readonly MY_PAYPAL_DLG_TITLE: string;
  abstract readonly MY_PAYPAL_DLG_SECTION_EMAIL: string;
  abstract readonly MY_PAYPAL_DLG_SECTION_EMAIL_PLACEHOLDER: string;
  abstract readonly MY_PAYPAL_DLG_SECTION_EMAIL_ALREADY: string;
  abstract readonly MY_PAYPAL_DLG_SECTION_AUTH_CODE: string;
  abstract readonly MY_CBLT_FEE_DLG_TITLE: string;
  abstract readonly MY_CBLT_FEE_DLG_SUB_TITLE: string;
  abstract readonly MY_CBLT_FEE_DLG_SUB_DESC: string;
  abstract readonly MY_CBLT_FEE_DLG_BTN: string;
  abstract readonly MY_PAYOUT_DLG_APPLY_DONE: string;
  abstract readonly MY_PAYOUT_DLG_APPLY_DONE_2: string;
  abstract readonly DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY: string;
  abstract readonly DLG_PAYOUT_ID_CHANGE_TITLE: string;
  abstract readonly DLG_PAYOUT_ID_CHANGE_CHANGE_BTN: string;
  abstract readonly DLG_PAYOUT_ID_CHANGE_CHANGE_CLOSE: string;

  // ------------------------------------------------
  // Join
  // ------------------------------------------------
  abstract readonly JOIN_TITLE: string;
  abstract readonly JOIN_EMAIL: string;
  abstract readonly JOIN_EMAIL_PLACEHOLDER: string;
  abstract readonly JOIN_EMAIL_INVALID: string;
  abstract readonly JOIN_EMAIL_DUPLICATED: string;
  abstract readonly JOIN_EMAIL_AUTH: string;
  abstract readonly JOIN_EMAIL_AUTH_PLACEHOLDER: string;
  abstract readonly JOIN_EMAIL_AUTH_SEND: string;
  abstract readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_1: string;
  abstract readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_2: string;
  abstract readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_1: string;
  abstract readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_2: string;
  abstract readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_3: string;
  abstract readonly JOIN_EMAIL_AUTH_RESEND: string;
  abstract readonly JOIN_EMAIL_AUTH_BTN: string;
  abstract readonly JOIN_EMAIL_AUTH_SUCC: string;
  abstract readonly JOIN_EMAIL_AUTH_INVALID: string;
  abstract readonly JOIN_PASSWORD: string;
  abstract readonly JOIN_PASSWORD_PLACEHOLDER: string;
  abstract readonly JOIN_PASSWORD_GUIDE_1: string;
  abstract readonly JOIN_PASSWORD_GUIDE_2: string;
  abstract readonly JOIN_PASSWORD_GUIDE_3: string;
  abstract readonly JOIN_PASSWORD_GUIDE_4: string;
  abstract readonly JOIN_PASSWORD_CONFIRM: string;
  abstract readonly JOIN_PASSWORD_CONFIRM_PLACEHOLDER: string;
  abstract readonly JOIN_PASSWORD_CONFIRM_INVALID: string;
  abstract readonly JOIN_FISH_SECURE_CODE: string;
  abstract readonly JOIN_FISH_SECURE_CODE_GUIDE_1: string;
  abstract readonly JOIN_FISH_SECURE_CODE_GUIDE_2: string;
  abstract readonly JOIN_FISH_SECURE_CODE_GUIDE_3: string;
  abstract readonly JOIN_FISH_SECURE_CODE_PLACEHOLDER: string;
  abstract readonly JOIN_AGE_OVER_20: string;
  abstract readonly JOIN_USAGE_TERM: string;
  abstract readonly JOIN_USAGE_TERM_BTN: string;
  abstract readonly JOIN_BTN: string;

  // ------------------------------------------------
  // Login
  // ------------------------------------------------
  abstract readonly LOGIN_TITLE: string;
  abstract readonly LOGIN_SECURE_GUIDE: string;
  abstract readonly LOGIN_EMAIL: string;
  abstract readonly LOGIN_EMAIL_PLACEHOLDER: string;
  abstract readonly LOGIN_FAIL_MSG: string;
  abstract readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_1: string;
  abstract readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_2: string;
  abstract readonly LOGIN_PASSWORD: string;
  abstract readonly LOGIN_PASSWORD_PLACEHOLDER: string;
  abstract readonly LOGIN_BTN: string;
  abstract readonly LOGIN_FIND_PASSWORD: string;
  abstract readonly LOGIN_JOIN: string;
  abstract readonly LOGIN_RESET_PASSWORD: string;
  abstract readonly LOGIN_RESET_EMAIL: string;
  abstract readonly LOGIN_RESET_EMAIL_PLACEHOLDER: string;
  abstract readonly LOGIN_RESET_OK_BTN: string;
  abstract readonly LOGIN_RESET_OK_MSG_1: string;
  abstract readonly LOGIN_RESET_OK_MSG_2: string;

  // ------------------------------------------------
  // UserBan
  // ------------------------------------------------
  abstract readonly USERBAN_TITLE: string;
  abstract readonly USERBAN_DESC: string;
  abstract readonly USERBAN_REASON_TITLE: string;
  abstract readonly USERBAN_REASON_DESC: string;
  abstract readonly USERBAN_REASON_1: string;
  abstract readonly USERBAN_REASON_1_DESC: string;
  abstract readonly USERBAN_REASON_2: string;
  abstract readonly USERBAN_REASON_2_DESC: string;
  abstract readonly USERBAN_REASON_3: string;
  abstract readonly USERBAN_REASON_3_DESC: string;
  abstract readonly USERBAN_REASON_4: string;
  abstract readonly USERBAN_REASON_4_DESC: string;
  abstract readonly USERBAN_REASON_5: string;
  abstract readonly USERBAN_REASON_5_DESC: string;
  abstract readonly USERBAN_REASON_GUIDE: string;

  // ------------------------------------------------
  // Wallet
  // ------------------------------------------------
  abstract readonly WALLET_REWARD_ADDR_CHAGNE_TITLE: string;
  abstract readonly WALLET_REWARD_ADDR_CHAGNE_DESC: string;
  abstract readonly WALLET_REWARD_ADDR_CHAGNE_BTN: string;
  abstract readonly WALLET_REWARD_ADDR_CLOSE_BTN: string;
  abstract readonly WALLET_REWARD_ADDR_CHANGE_DONE: string;
  abstract readonly WALLET_REWARD_ADDR_CHANGE_DONE_DESC: string;
  abstract readonly WALLET_REWARD_ADDR_CHANGE_DONE_OK: string;
  abstract readonly WALLET_CONNECT: string;
  abstract readonly WALLET_CONNECT_BTN: string;
  abstract readonly WALLET_CYPRESS_NOT_AVAILABLE_IN_ALPHA: string;
  abstract readonly WALLET_BAOBAB_NOT_AVAILABLE_IN_LIVE: string;
  
  // ------------------------------------------------
  // 거래내역
  // ------------------------------------------------
  abstract readonly PROP_HISTORY_TITLE: string;
  abstract readonly PROP_HISTORY_POINT_INCOME: string;
  abstract readonly PROP_HISTORY_POINT_SPEND: string;
  abstract readonly PROP_HISTORY_POINT_BALANCE: string;
  abstract readonly PROP_HISTORY_POINT_QUERY_TYPE_ALL: string;
  abstract readonly PROP_HISTORY_POINT_QUERY_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_POINT_QUERY_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_POINT_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_POINT_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_USD_INCOME: string;
  abstract readonly PROP_HISTORY_USD_SPEND: string;
  abstract readonly PROP_HISTORY_USD_BALANCE: string;
  abstract readonly PROP_HISTORY_USD_QUERY_TYPE_ALL: string;
  abstract readonly PROP_HISTORY_USD_QUERY_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_USD_QUERY_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_USD_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_USD_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_KLAY_INCOME: string;
  abstract readonly PROP_HISTORY_KLAY_SPEND: string;
  abstract readonly PROP_HISTORY_KLAY_BALANCE: string;
  abstract readonly PROP_HISTORY_KLAY_QUERY_TYPE_ALL: string;
  abstract readonly PROP_HISTORY_KLAY_QUERY_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_KLAY_QUERY_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_KLAY_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_KLAY_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_CBLT_INCOME: string;
  abstract readonly PROP_HISTORY_CBLT_SPEND: string;
  abstract readonly PROP_HISTORY_CBLT_BALANCE: string;
  abstract readonly PROP_HISTORY_CBLT_QUERY_TYPE_ALL: string;
  abstract readonly PROP_HISTORY_CBLT_QUERY_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_CBLT_QUERY_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_CBLT_TYPE_INCOME: string;
  abstract readonly PROP_HISTORY_CBLT_TYPE_SPEND: string;
  abstract readonly PROP_HISTORY_SUCC: string;
  abstract readonly PROP_HISTORY_FAIL: string;
  abstract readonly PROP_HISTORY_QUERY_BTN: string;
  abstract readonly PROP_HISTORY_EMPTY_DATA: string;
  abstract readonly PROP_HISTORY_QUERY_NOT_FONUD: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_101: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_102: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_103: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_201: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_202: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_203: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_204: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_205: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_301: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_302: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_303: string;
  abstract readonly PROP_HISTORY_TOKENDATA_TYPE_304: string;

  // ------------------------------------------------
  // 문의하기
  // ------------------------------------------------
  abstract readonly INQUERY_TITLE: string;
  abstract readonly INQUERY_TYPE: string;
  abstract readonly INQUERY_TYPE_PROBLEM: string;
  abstract readonly INQUERY_TYPE_TASK: string;
  abstract readonly INQUERY_TYPE_QUESTION: string;
  abstract readonly INQUERY_TYPE_INCIDENT: string;
  abstract readonly INQUERY_NAME: string;
  abstract readonly INQUERY_NAME_PLACEHOLDER: string;
  abstract readonly INQUERY_EMAIL: string;
  abstract readonly INQUERY_EMAIL_PLACEHOLDER: string;
  abstract readonly INQUERY_SUBJECT: string;
  abstract readonly INQUERY_SUBJECT_PLACEHOLDER: string;
  abstract readonly INQUERY_BODY_PALCEHOLDER: string;
  abstract readonly INQUERY_BTN: string;
  abstract readonly INQUERY_SUCCESS: string;

  // ------------------------------------------------
  // 포인트 충전
  // ------------------------------------------------
  abstract readonly DLG_CHARGE_TITLE: string;
  abstract readonly DLG_CHARGE_UNIT_PRICE: string;
  abstract readonly DLG_CHARGE_FEE: string;
  abstract readonly DLG_CHARGE_TOTAL_PRICE: string;
  abstract readonly DLG_CHARGE_FOOTER_TERMS: string;
  abstract readonly DLG_CHARGE_FOOTER_PRIVACY: string;
  abstract readonly DLG_CHARGE_FOOTER_REFUND_POLICY: string;

  // 기타
  abstract readonly COMMON_UNIT_YEAR: string;
  abstract readonly COMMON_UNIT_MONTH: string;
  abstract readonly COMMON_UNIT_DAY: string;
  abstract readonly COMMON_UNIT_AM: string;
  abstract readonly COMMON_UNIT_PM: string;

  getAssetType(assetType: CbtAssetType_e): string {
    switch (assetType) {
      case CbtAssetType_e.IMAGE:
        return this.COMMON_ASSET_TYPE_IMAGE;
      case CbtAssetType_e.VIDEO:
        return this.COMMON_ASSET_TYPE_VIDEO;
      case CbtAssetType_e.AUDIO:
        return this.COMMON_ASSET_TYPE_AUDIO;
    }
    return 'unknown';
  }

  getSearchOrderBy(filter: CbtMarketSearchSort_e): string {
    switch (filter) {
      case CbtMarketSearchSort_e.ListingNewest:
        return this.MARKET_LIST_SEARCH_ORDERBY_LISTING_NEWEST;
      case CbtMarketSearchSort_e.ListingOldest:
        return this.MARKET_LIST_SEARCH_ORDERBY_LISTING_OLDEST;
      case CbtMarketSearchSort_e.PriceHighest:
        return this.MARKET_LIST_SEARCH_ORDERBY_PRICE_HIGHEST;
      case CbtMarketSearchSort_e.PriceLowest:
        return this.MARKET_LIST_SEARCH_ORDERBY_PRICE_LOWEST;
    }
    return 'unknown';
  }

  // getCollectionFilter(filter: CbtMarketSearchCollectionFilter_e): string {
  //   switch (filter) {
  //     case CbtMarketSearchCollectionFilter_e.All:
  //       // return this.MARKET_LIST_SEARCH_COLLECTION_FILTER_ALL;
  //       return this.COMMON_COLLECTION;
  //     case CbtMarketSearchCollectionFilter_e.Pokecolo:
  //       return StaticStrings.MARKETPLACE_SEARCH_COLLECTION_FILTER_POKECOLO;
  //     case CbtMarketSearchCollectionFilter_e.Carrot:
  //       return StaticStrings.MARKETPLACE_SEARCH_COLLECTION_FILTER_CARROT;
  //     case CbtMarketSearchCollectionFilter_e.Stichy:
  //       return StaticStrings.MARKETPLACE_SEARCH_COLLECTION_FILTER_STICHY;
  //   }
  //   return 'unknown';
  // }

  getCurrencyFilter(filter: CbtMarketSearchCurrencyFilter_t): string {
    switch (filter) {
      case CbtCurrency_e.ALL:
        return this.MARKET_LIST_SEARCH_FILTER_CURRENCY;
      case CbtCurrency_e.cPOINT:
        return StaticStrings.CURRENCY_CUSD;
      case CbtCurrency_e.KLAY:
        return StaticStrings.CURRENCY_KLAY;
    }
    return 'unknown';
  }

  getAttributeTypeText(attType: CbtAttributeType_e): string {
    switch (attType) {
      case CbtAttributeType_e.TEXT:
        return this.AC_SCHEMA_READ_ATTR_TYPE_TEXT;
      case CbtAttributeType_e.NUMBER:
        return this.AC_SCHEMA_READ_ATTR_TYPE_NUMBER;
      case CbtAttributeType_e.IMAGE:
        return this.COMMON_ATTR_TYPE_IMAGE;
      case CbtAttributeType_e.VIDEO:
        return this.COMMON_ATTR_TYPE_VIDEO;
      case CbtAttributeType_e.AUDIO:
        return this.COMMON_ATTR_TYPE_AUDIO;
    }
  }

  getAttributeTypeDetailText(attType: CbtAttributeType_e): string {
    switch (attType) {
      case CbtAttributeType_e.TEXT:
        return '';
      case CbtAttributeType_e.NUMBER:
        return '';
      case CbtAttributeType_e.IMAGE:
        return this.AC_SCHEMA_READ_ATTR_TYPE_IMAGE_DETAIL;
      case CbtAttributeType_e.VIDEO:
        return this.AC_SCHEMA_READ_ATTR_TYPE_VIDEO_DETAIL;
      case CbtAttributeType_e.AUDIO:
        return this.AC_SCHEMA_READ_ATTR_TYPE_AUDIO_DETAIL;
    }
  }
}
