/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { useHistory } from 'react-router';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF, FlexRowDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { CLAY } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

type Props = {
  dest?: string;
};

export function BackBtn(props: Props) {
  const hR = useLocalSettings();
  const hHistory = useHistory();
  return (
    <FlexRowDiv
      style={{
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineF,
      }}
      onClick={() => {
        DBGMSG('back');
        if (props.dest) {
          hHistory.replace(props.dest);
        } else {
          hHistory.goBack();
        }
      }}
    >
      <Img src={[R.images.common_ic_arrow_back3x, CLAY.BACKBTN_SIZE, CLAY.BACKBTN_SIZE]} />
      <TextCon text={hR.strings.AC_COLLECTION_CREATE_BACK} size={CLAY.BACKBTN_FONT_SIZE} color={R.colors.black} isBold />
    </FlexRowDiv>
  );
}
