/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CbtUserLanguage_e } from 'src/model/rpcModel';
import Strings from 'src/res/strings';

// prettier-ignore
export class StringsKr extends Strings {
  readonly LANG = CbtUserLanguage_e.KO;

  // ------------------------------------------------
  // 공통
  // ------------------------------------------------
  readonly COMMON_BACK = `뒤로`;
  readonly COMMON_COLLECTION = `컬렉션`;
  readonly COMMON_COLLECTION_NAME = `컬렉션 이름`;
  readonly COMMON_DISP_NAME = `디스플레이 이름`;
  readonly COMMON_ROYALTY = `인세`;
  readonly COMMON_WEBSITE = `웹 사이트 URL`;
  readonly COMMON_DESC = `설명`;
  readonly COMMON_ADMIN = `관리자 계정`;
  readonly COMMON_CANNOT_EDIT = `수정 불가`;
  readonly COMMON_ASSET_NAME = `에셋 이름`;
  readonly COMMON_ASSET_MINT_CNT = `발행 매수`;
  readonly COMMON_ASSET_MINT_NO_MORE = `추가 발행 없음`;
  readonly COMMON_ASSET_MINT_MORE = `추가 발행 가능`;
  readonly COMMON_ASSET_TYPE = `에셋 유형`;
  readonly COMMON_ASSET_TYPE_IMAGE = `이미지`;
  readonly COMMON_ASSET_TYPE_VIDEO = `비디오`;
  readonly COMMON_ASSET_TYPE_AUDIO = `오디오`;
  readonly COMMON_SCHEMA = `스키마`;
  readonly COMMON_SCHEMA_NAME = `스키마 이름`;
  readonly COMMON_ATTR = `속성과 유형`;
  readonly COMMON_ATTR_NAME = `속성 이름`;
  readonly COMMON_ATTR_TYPE = `속성 유형`;
  readonly COMMON_ATTR_TYPE_TEXT = `텍스트`;
  readonly COMMON_ATTR_TYPE_NUMBER = `숫자`;
  readonly COMMON_ATTR_TYPE_IMAGE = `이미지`;
  readonly COMMON_ATTR_TYPE_VIDEO = `비디오`;
  readonly COMMON_ATTR_TYPE_AUDIO = `오디오`;
  readonly COMMON_ATTR_TYPE_IMAGE_DETAIL = `(PNG, JPG, GIF, APNG)`;
  readonly COMMON_ATTR_TYPE_VIDEO_DETAIL = `(MP4)`;
  readonly COMMON_ATTR_TYPE_AUDIO_DETAIL = `(MP3)`;
  readonly COMMON_ASSET = `에셋`;
  readonly COMMON_PRICE_HISTORY_LOWEST = `최소 거래 가격`;
  readonly COMMON_PRICE_HISTORY_HIGHEST = `최대 거래 가격`;
  readonly COMMON_PRICE_HISTORY_TX_CNT = `총 거래된 수`;
  readonly COMMON_ASSET_TRADING_HISTORY = `거래 내역`;
  readonly COMMON_ASSET_TRADING_HISTORY_EVENT = `Event`;
  readonly COMMON_ASSET_TRADING_HISTORY_PRICE = `Price`;
  readonly COMMON_ASSET_TRADING_HISTORY_FROM = `From`;
  readonly COMMON_ASSET_TRADING_HISTORY_TO = `To`;
  readonly COMMON_ASSET_TRADING_HISTORY_DATE = `Date`;
  readonly COMMON_ASSET_TRADING_HISTORY_NO_DATA = `아직 거래 내역이 없습니다.`;
  readonly COMMON_BUY_BTN = `구매하기`;
  readonly COMMON_CREATOR = `원작자`;
  readonly COMMON_OWNER = `소유자`;
  readonly COMMON_SELLER = `판매자`;
  readonly COMMON_BUYER = `구매자`;
  readonly COMMON_PRICE = `가격`;
  readonly COMMON_TX_HASH = `Tx Hash`;
  readonly COMMON_VIEW_ON_BLOCKS = `View on Blocks`;
  readonly COMMON_MENU_INVENTORY = `인벤토리`;
  readonly COMMON_MENU_MINTED = `발행에셋`;
  readonly COMMON_MENU_ON_SALE = `판매중`;
  readonly COMMON_MENU_SOLD = `판매됨`;
  readonly COMMON_MENU_PURCHASED = `구매함`;
  readonly COMMON_EMAIL = `이메일`;
  readonly COMMON_EMAIL_PLACEHOLDER = `이메일 입력`;
  readonly COMMON_EMAIL_INVALID = `이메일 형식이 올바르지 않습니다.`;
  readonly COMMON_PASSWORD = `비밀번호`;
  readonly COMMON_PASSWORD_PLACEHOLDER = `비밀번호 입력`;
  readonly COMMON_SEARCH_PLACEHOLDER = `검색`;
  readonly COMMON_SEARCH_ORDERBY_LISTING_NEWEST = `최신순`;
  readonly COMMON_SEARCH_ORDERBY_LISTING_OLDEST = `오래된순`;
  readonly COMMON_SEARCH_ORDERBY_PRICE_HIGHEST = `높은가격순`;
  readonly COMMON_SEARCH_ORDERBY_PRICE_LOWEST = `낮은가격순`;
  readonly COMMON_PASSWORD_GUIDE_1 = `* 영문 대문자 포함`;
  readonly COMMON_PASSWORD_GUIDE_2 = `* 영문 소문자 포함`;
  readonly COMMON_PASSWORD_GUIDE_3 = `* 숫자 포함`;
  readonly COMMON_PASSWORD_GUIDE_4 = `* 10자 이상`;
  readonly COMMON_OK_BTN_TXT = `확인`;
  readonly COMMON_REPORT = `신고하기`;
  readonly COMMON_INQUERY = `문의하기`;
  readonly COMMON_SALE_BTN = `판매하기`;
  readonly COMMON_SALE_EDIT_BTN = `판매 편집`;
  readonly COMMON_NOTI_TITLE = `알림`;
  
  // ------------------------------------------------
  // 공통헤더
  // ------------------------------------------------
  readonly HEADER_SETTINGS_LANG = `언어`;
  readonly HEADER_SETTINGS_CURRENCY = `통화`;
  readonly HEADER_MENU_EXPLORE = `Explorer`;
  readonly HEADER_MENU_ASSET_CREATOR = `Asset Creator`;
  readonly HEADER_MENU_MARKET_PLACE = `Marketplace`;
  readonly HEADER_LOGIN_BTN = `로그인`;
  readonly HEADER_KAIKAS_WALLET_CONNECT = `지갑 연결하기`;
  readonly HEADER_MYPAGE = `마이페이지`;
  readonly HEADER_LOGOUT = `로그아웃`;

  // ------------------------------------------------
  // 공통푸터
  // ------------------------------------------------
  readonly FOOTER_TERMS = `서비스 이용약관`;
  readonly FOOTER_PRIVACY = `개인정보처리방침`;
  readonly FOOTER_REFUND_POLICY = `환불정책`;
  readonly FOOTER_RAW1 = `금융상품거래법`;
  readonly FOOTER_RAW2 = `자금결제법`;
  readonly FOOTER_SUPPORT_EMAIL = `고객지원 이메일`;
  readonly FOOTER_SUPPORT_EMAIL_ADDRESS = `support@cobalt.zone`;
  readonly FOOTER_COPYRIGHT = `© Post Voyager. All rights reserved.`;
  readonly FOOTER_INQUERY = `문의하기`;

  // ------------------------------------------------
  // Explorer 화면
  // ------------------------------------------------
  readonly EXP_MAIN_BANNER_BTN = `나만의\n에셋 발행하기`;
  readonly EXP_BANNER_COLLECTION_TITLE = `공식 컬렉션`;
  readonly EXP_BANNER_EVENT_TITLE = `이벤트`;
  readonly EXP_BANNER_CARROT_TITLE = `NFT by CARROT`;
  readonly EXP_BANNER_POKECOLO_TITLE = `ポケコロアートワーク`;
  readonly EXP_BANNER_EVENT_CBLT = `리워드 3배 지급!`;
  readonly EXP_SUB_BANNER_BTN = `보기 >`;
  readonly EXP_STATUS_BOARD_ASSET_CNT = `발행된 에셋`;
  readonly EXP_STATUS_BOARD_SALE_CNT = `오늘 판매 등록 수`;
  readonly EXP_STATUS_BOARD_TX_CNT = `오늘 거래된 수`;
  readonly EXP_STATUS_BOARD_DOLLAR_CNT = `오늘 거래액`;
  readonly EXP_RECOMMEND_ASSET_SECTION_TITLE = `추천 에셋`;
  readonly EXP_NEW_ASSET_SECTION_TITLE = `새로운 에셋`;
  readonly EXP_NEW_ASSET_VIEW_ALL = `전체 보기`;
  readonly EXP_COLLECTION_SECTION_TITLE = `공식 컬렉션`;

  // ------------------------------------------------
  // AssetCreator
  // ------------------------------------------------
readonly AC_COLLECTION_LIST_TITLE_IF_EMTPY = `컬렉션 생성하기`;
readonly AC_COLLECTION_LIST_DESC_1 = `에셋을 발행하려면 먼저 컬렉션을 생성해야 합니다. `;
readonly AC_COLLECTION_LIST_DESC_2 = `컬렉션은 에셋들을 하나의 그룹으로 묶어서 관리할 수 있도록 해줍니다.`;
readonly AC_COLLECTION_LIST_TITLE = `내 컬렉션`;
readonly AC_COLLECTION_LIST_NEW = `컬렉션 생성하기`;
readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_1 = `생성 중입니다.`;
readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_2 = `잠시만 기다려주세요.`;
readonly AC_COLLECTION_CREATE_BACK =this.COMMON_BACK;
readonly AC_COLLECTION_CREATE_TITLE = `컬렉션 생성하기`;
readonly AC_COLLECTION_CREATE_IMAGE = `컬렉션 이미지`;
readonly AC_COLLECTION_CREATE_IMAGE_UPLOAD_SPEC = `이미지 업로드 형식`;
readonly AC_COLLECTION_CREATE_CANNOT_EDIT = this.COMMON_CANNOT_EDIT;
readonly AC_COLLECTION_CREATE_NAME = this.COMMON_COLLECTION_NAME;
readonly AC_COLLECTION_CREATE_NAME_GUIDE = `(알파벳・숫자만 가능, 20자 이내, 중복불가)`;
readonly AC_COLLECTION_CREATE_NAME_DUPLICATED = `이미 등록된 컬렉션 이름입니다.`;
readonly AC_COLLECTION_CREATE_DISPNAME = this.COMMON_DISP_NAME;
readonly AC_COLLECTION_CREATE_DISPNAME_GUIDE = `(20자 이내)`;
readonly AC_COLLECTION_CREATE_ROYALTY = this.COMMON_ROYALTY;
readonly AC_COLLECTION_CREATE_ROYALTY_GUIDE = `(0~{MAX})%`;
readonly AC_COLLECTION_CREATE_WEBSITE = this.COMMON_WEBSITE;
readonly AC_COLLECTION_CREATE_DESC = this.COMMON_DESC;
readonly AC_COLLECTION_CREATE_ADMIN = this.COMMON_ADMIN;
readonly AC_COLLECTION_CREATE_ADMIN_INVALID = `유효하지 않은 계정입니다.`;
readonly AC_COLLECTION_CREATE_BTN = `컬렉션 생성하기`;
readonly AC_COLLECTION_CREATE_COL_IMG_TOOLTIP = `• 해상도 : 가로 세로 200px 이상\n• 파일 형식 : PNG, JPG, GIF, APNG\n• 최대 2MB`;
readonly AC_COLLECTION_CREATE_TOOLTIP_DISPNAME = `다른 사용자에게 보여지는 컬렉션 이름`;
readonly AC_COLLECTION_CREATE_TOOLTIP_ROYALTY = `거래 시 지급되는 로얄티`;
readonly AC_COLLECTION_UPDATE_TITLE = `컬렉션`;
readonly AC_COLLECTION_UPDATE_BTN = `편집완료`;
readonly AC_COLLECTION_READ_BACK = this.COMMON_BACK;
readonly AC_COLLECTION_READ_TITLE = this.COMMON_COLLECTION;
readonly AC_COLLECTION_READ_EDIT_BTN = `편집하기`;
readonly AC_COLLECTION_READ_OWNER = this.COMMON_OWNER;
readonly AC_COLLECTION_READ_COLLECTION_NAME = this.COMMON_COLLECTION_NAME;
readonly AC_COLLECTION_READ_DISP_NAME = this.COMMON_DISP_NAME;
readonly AC_COLLECTION_READ_ROYALTY = this.COMMON_ROYALTY;
readonly AC_COLLECTION_READ_WEBSITE = this.COMMON_WEBSITE;
readonly AC_COLLECTION_READ_DESC = this.COMMON_DESC;
readonly AC_COLLECTION_READ_ADMIN = this.COMMON_ADMIN;
readonly AC_COLLECTION_READ_SCHEMA = this.COMMON_SCHEMA;
readonly AC_COLLECTION_READ_SCHEMA_DESC_1 = `스키마는 에셋이 가질 속성의 종류를 정의합니다.`;
readonly AC_COLLECTION_READ_SCHEMA_DESC_2 = `컬렉션 내에 여러 개의 스키마를 생성할 수 있습니다.`;
readonly AC_COLLECTION_READ_SCHEMA_ATTR_CNT = `개의 속성`;
readonly AC_SCHEMA_CREATE_BACK = this.COMMON_BACK;
readonly AC_SCHEMA_CREATE_TITLE = `스키마 생성하기`;
readonly AC_SCHEMA_CREATE_NAME = this.COMMON_SCHEMA_NAME;
readonly AC_SCHEMA_CREATE_NAME_GUIDE = `(알파벳・숫자만 가능, 20자 이내, 중복불가)`;
readonly AC_SCHEMA_CREATE_NAME_DUPLICATED = `이미 등록된 스키마 이름입니다.`;
readonly AC_SCHEMA_CREATE_CANNOT_EDIT = this.COMMON_CANNOT_EDIT;
readonly AC_SCHEMA_CREATE_TYPE = this.COMMON_ASSET_TYPE;
readonly AC_SCHEMA_CREATE_TYPE_IMAGE = this.COMMON_ASSET_TYPE_IMAGE;
readonly AC_SCHEMA_CREATE_TYPE_VIDEO = this.COMMON_ASSET_TYPE_VIDEO;
readonly AC_SCHEMA_CREATE_TYPE_AUDIO = this.COMMON_ASSET_TYPE_AUDIO;
readonly AC_SCHEMA_CREATE_DISP_NAME = this.COMMON_DISP_NAME;
readonly AC_SCHEMA_CREATE_DISP_NAME_GUIDE = `(20자 이내)`;
readonly AC_SCHEMA_CREATE_ATTR = this.COMMON_ATTR;
readonly AC_SCHEMA_CREATE_ATTR_GUIDE_1 = `속성 이름은 알파벳・숫자만 가능, 20자 이내,`;
readonly AC_SCHEMA_CREATE_ATTR_GUIDE_2 = `중복 불가`;
readonly AC_SCHEMA_CREATE_ATTR_NEW = `새로운 속성`;
readonly AC_SCHEMA_CREATE_ATTR_NEW_TYPE = `새로운 속성 유형`;
readonly AC_SCHEMA_CREATE_ATTR_NAME_DUPLICATED = `속성 이름이 중복됩니다.`;
readonly AC_SCHEMA_CREATE_ATTR_NAME_RESERVED = `사용할 수 없는 속성 이름입니다.`;
readonly AC_SCHEMA_CREATE_ATTR_TYPE_TEXT = this.COMMON_ATTR_TYPE_TEXT;
readonly AC_SCHEMA_CREATE_ATTR_TYPE_NUMBER = this.COMMON_ATTR_TYPE_NUMBER;
readonly AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE = this.COMMON_ATTR_TYPE_IMAGE;
readonly AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO = this.COMMON_ATTR_TYPE_VIDEO;
readonly AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO = this.COMMON_ATTR_TYPE_AUDIO;
readonly AC_SCHEMA_CREATE_ATTR_ADD_BTN = `새로운 속성 추가하기`;
readonly AC_SCHEMA_CREATE_BTN = `스키마 생성하기`;
readonly AC_SCHEMA_CREATE_TOOLTIP_DISPNAME = `다른 사용자에게 보여지는 스키마 이름`;
readonly AC_SCHEMA_UPDATE_TITLE = this.COMMON_SCHEMA;
readonly AC_SCHEMA_UPDATE_BTN = `편집 완료`;
readonly AC_SCHEMA_READ_BACK = this.COMMON_BACK;
readonly AC_SCHEMA_READ_ATTR_CNT = `개의 속성`;
readonly AC_SCHEMA_READ_EDIT_BTN = `편집하기`;
readonly AC_SCHEMA_READ_TITLE = this.COMMON_SCHEMA;
readonly AC_SCHEMA_READ_ATTR_NAME = this.COMMON_ATTR_NAME;
readonly AC_SCHEMA_READ_ATTR_TYPE = this.COMMON_ATTR_TYPE;
readonly AC_SCHEMA_READ_ATTR_TYPE_TEXT = this.COMMON_ATTR_TYPE_TEXT;
readonly AC_SCHEMA_READ_ATTR_TYPE_NUMBER = this.COMMON_ATTR_TYPE_NUMBER;
readonly AC_SCHEMA_READ_ATTR_TYPE_IMAGE_DETAIL = this.COMMON_ATTR_TYPE_IMAGE_DETAIL;
readonly AC_SCHEMA_READ_ATTR_TYPE_VIDEO_DETAIL = this.COMMON_ATTR_TYPE_VIDEO_DETAIL;
readonly AC_SCHEMA_READ_ATTR_TYPE_AUDIO_DETAIL = this.COMMON_ATTR_TYPE_AUDIO_DETAIL;
readonly AC_SCHEMA_READ_ASSET = this.COMMON_ASSET;
readonly AC_SCHEMA_READ_NEW_ASSET_DESC = `에셋은 스키마의 속성이 자동으로 설정되며 속성에 맞춰 발행할 수 있습니다.`;
readonly AC_SCHEMA_READ_NEW_ASSET = `에셋 발행하기`;
readonly AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT = `{개수}개 발행`;
readonly AC_ASSET_GROUP_MINT_MORE_BTN = `추가발행`;
readonly AC_ASSET_GROUP_DLG_TITLE = `추가 발행하기`;
readonly AC_ASSET_GROUP_DLG_MINT_OWNER = `에셋 소유자`;
readonly AC_ASSET_GROUP_DLG_MINT_CNT = `추가 발행매수`;
readonly AC_ASSET_GROUP_DLG_MINT_CNT_GUIDE = `(최대 10개)`;
readonly AC_ASSET_GROUP_DLG_MINT_TOTAL = `총 발행 매수`;
readonly AC_ASSET_GROUP_DLG_MINT_BTN = `발행하기`;
readonly AC_ASSET_CREATE_BACK = this.COMMON_BACK;
readonly AC_ASSET_CREATE_TITLE = `에셋 발행하기`;
readonly AC_ASSET_CREATE_PREVIEW = `미리보기`;
readonly AC_ASSET_CREATE_OWNER = `에셋 소유자`;
readonly AC_ASSET_CREATE_MINT_CNT = `발행 매수`;
readonly AC_ASSET_CREATE_MINT_CNT_GUIDE = `(최대 10개)`;
readonly AC_ASSET_CREATE_MINT_NO_MORE = this.COMMON_ASSET_MINT_NO_MORE;
readonly AC_ASSET_CREATE_MINT_MORE = this.COMMON_ASSET_MINT_MORE;
readonly AC_ASSET_CREATE_ATTR = this.COMMON_ATTR;
readonly AC_ASSET_CREATE_ATTR_TYPE_NFTNAME = `텍스트 (20자 이내)`;
readonly AC_ASSET_CREATE_ATTR_TYPE_TEXT = this.COMMON_ATTR_TYPE_TEXT;
readonly AC_ASSER_CREATE_ATTR_TYPE_NUMBER = this.COMMON_ATTR_TYPE_NUMBER;
readonly AC_ASSET_CREATE_ATTR_TYPE_IMAGE_DETAIL = this.COMMON_ATTR_TYPE_IMAGE_DETAIL;
readonly AC_ASSET_CREATE_ATTR_TYPE_VIDEO_DETAIL = this.COMMON_ATTR_TYPE_VIDEO_DETAIL;
readonly AC_ASSET_CREATE_ATTR_TYPE_AUDIO_DETAIL = this.COMMON_ATTR_TYPE_AUDIO_DETAIL;
readonly AC_ASSET_CREATE_FILE = `파일 선택`;
readonly AC_ASSET_CREATE_FILE_UPLOAD_SPEC = `{속성타입} 업로드 형식`;
readonly AC_ASSET_CREATE_FILE_IMAGE_SPEC_TOOLTIP = `• 해상도 : 가로 세로 600px 이상\n• 파일 형식 : PNG, JPG, GIF, APNG\n• 최대 10MB`;
readonly AC_ASSET_CREATE_FILE_VIDEO_THUMB_IMAGE_SPEC_TOOLTIP = `• 해상도 : 가로 세로 600px 이상\n• 파일 형식 : PNG, JPG\n• 최대 5MB`;
readonly AC_ASSET_CREATE_FILE_AUDIO_COVER_IMAGE_SPEC_TOOLTIP = `• 해상도 : 가로 세로 400px 이상\n• 파일 형식 : PNG, JPG, GIF, APNG\n• 최대 5MB`;
readonly AC_ASSET_CREATE_FILE_AUDIO_SPEC_TOOLTIP = `• 파일 형식 : MP3\n• 최대 10MB`;
readonly AC_ASSET_CREATE_FILE_VIDEO_SPEC_TOOLTIP = `• 해상도 : 가로 세로 600px 이상\n• 파일 형식 : MP4\n• 최대 10MB`;
readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_TITLE = `최소 사이즈를 확인해주세요.`;
readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_DESC = `가로 세로 사이즈 {사이즈}px 이상만\n업로드할 수 있습니다.`;
readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_TITLE = `파일 형식을 확인해주세요.`;
readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_DESC = `{파일형식리스트}만\n업로드할 수 있습니다.`;
readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_TITLE = `최대 크기를 확인해주세요.`;
readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_DESC = `최대 {파일사이즈MB}MB까지 업로드할 수 있습니다.`;
readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TITLE = `파일 업로드 중`;
readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TEXT = `잠시만 기다려 주세요`;
readonly AC_ASSET_CREATE_TERMS1_TITLE = `에셋 발행을 위해 아래 정보를 수집 및 이용하는 것에 동의합니다.`;
readonly AC_ASSET_CREATE_TERMS1_DETAIL_1 = `• 수집항목: 에셋 이름, 설명, 파일 (이미지 등)\n• 수집목적: 에셋 발행 및 관리`;
readonly AC_ASSET_CREATE_TERMS1_DETAIL_2 = `• 보유 및 이용기간: 영구 (규정위반, 요청 시 파기)\n• 동의를 거부할 경우 에셋을 발행할 수 없습니다.`;
readonly AC_ASSET_CREATE_TERMS2_TITLE = `아래 유의사항을 확인하였으며, 이를 준수하는데 동의합니다.`;
readonly AC_ASSET_CREATE_TERMS2_DETAIL = `• 본인은 제3자의 지적재산권, 인권, 개인정보 등 타인의 권리를 침해하지 않습니다.\n• 본인은 본인의 개인정보를 활용하는 경우 본인의 개인정보가 제3자에게 공개, 활용, 제공 등이 될 수 있음을 인지하며 이에 동의합니다.\n• 본인은 이용약관 및 운영정책에 반하는 에셋을 발행하지 않으며 발행하는 에셋에 대한 모든 책임은 본인에게 있음을 확인하고 동의합니다.\n• 본인은 에셋에 부적절한 이미지나 영상이 포함될 경우 고지 없이 삭제될 수 있음을 인지하며 이에 동의합니다.`;
readonly AC_ASSET_CREATE_BTN = `에셋 발행하기`;
readonly AC_ASSET_CREATE_TOOLTIP_MINT = `한번에 최대 10개까지 발행 가능`;
readonly AC_ASSET_READ_BACK = `뒤로`;
readonly AC_ASSET_READ_OWNER = `소유자`;
readonly AC_ASSET_READ_CREATOR = `원작자`;
readonly AC_ASSET_READ_ASSET_ID = `에셋 ID`;
readonly AC_ASSET_READ_ASSET_TYPE = `에셋 유형`;
readonly AC_ASSET_READ_MINT_CNT = `발행 매수`;
readonly AC_ASSET_READ_MINT_NO_MORE = this.COMMON_ASSET_MINT_NO_MORE;
readonly AC_ASSET_READ_MINT_MORE = this.COMMON_ASSET_MINT_MORE;
readonly AC_ASSET_READ_BEFORE_SALE = `이 에셋은 아직 판매 전입니다.`;
readonly AC_ASSET_READ_SALE_BTN = `판매하기`;
readonly AC_ASSET_READ_ON_SALE = `이 에셋은 {판매ID}로 판매 중입니다. `;
readonly AC_ASSET_READ_SALE_EDIT_BTN = `판매 편집`;
readonly AC_ASSET_READ_TRADING_HISTORY = this.COMMON_ASSET_TRADING_HISTORY;
readonly AC_ASSET_READ_TRADING_HISTORY_EVENT = this.COMMON_ASSET_TRADING_HISTORY_EVENT;
readonly AC_ASSET_READ_TRADING_HISTORY_PRICE = this.COMMON_ASSET_TRADING_HISTORY_PRICE;
readonly AC_ASSET_READ_TRADING_HISTORY_FROM = this.COMMON_ASSET_TRADING_HISTORY_FROM;
readonly AC_ASSET_READ_TRADING_HISTORY_TO = this.COMMON_ASSET_TRADING_HISTORY_TO;
readonly AC_ASSET_READ_TRADING_HISTORY_DATE = this.COMMON_ASSET_TRADING_HISTORY_DATE;
readonly AC_ASSET_READ_TRADING_HISTORY_NO_DATA = this.COMMON_ASSET_TRADING_HISTORY_NO_DATA;
readonly DLG_EXCEED_COLLECTION_LIMIT = `컬렉션 생성 개수가 초과되었습니다.\n최대 5개까지 생성 가능합니다.`;
readonly DLG_EXCEED_SCHEMA_LIMIT = `스키마 생성 개수가 초과되었습니다.\n최대 100개까지 생성 가능합니다.`;
readonly DLG_SELL_TITLE = `에셋 판매하기`;
readonly DLG_SELL_TITLE_EDIT = `판매 편집하기`;
readonly DLG_SELL_PRICE = `판매 가격`;
readonly DLG_SELL_MINIMUM_LIMIT = `10 {화폐단위} 이상만 가능합니다.`;
readonly DLG_SELL_FEE = `수수료`;
readonly DLG_SELL_ROYALTY = `컬렉션 인세`;
readonly DLG_SELL_PLATFORM_FEE = `플랫폼 수수료`;
readonly DLG_SELL_CALC_PRICE = `예상 판매 수익`;
readonly DLG_SELL_OK_BTN = `판매하기`;
readonly DLG_SELL_KLAY_ADDRESS_REG_GUIDE = `KLAY 판매 수익 및 리워드를 받기 위한 주소를 등록해주세요.`;
readonly DLG_SELL_KLAY_ADDRESS = `판매 수익을 받을 주소`;
readonly DLG_SELL_KLAY_ADDRESS_REWARD = `리워드 받을 주소`;
readonly DLG_SELL_KLAY_ADDRESS_REWARD_GUIDE = `리워드 CBLT를 받을 주소를 마이페이지에서 등록해주세요.`;
readonly DLG_SELL_STOP_BTN = `판매중지`;
readonly DLG_SELL_AGREE_POLICY_TITLE = `아래 유의사항을 확인하였으며, 이를 준수하는데 동의합니다.`;
readonly DLG_SELL_AGREE_POLICY_TEXT = `• 구매자(구매자로부터 전매를 받은 자를 포함한다.)에 한해 자신의 로컬 컴퓨터 및 기타 기억기기 등으로 다운로드하여 저장할 수 있습니다.\n• 구매자가 상품을 비상업적목적으로 이용하는 것 (예 : 자신이 착용할 목적으로 상품을 T셔츠에 프린트한다.)을 허가합니다.\n• 회사는 상품에 대해서 본 서비스의 원활한 제공, 시스템의 구축·개량·유지보수 및 본 서비스의 광고·선전 등에 필요한 범위에서 사용할 수 있으며, 상품 데이터의 일부를 생략, 리사이즈, 편집하여 원작자명 표시를 생략할 수 있습니다.\n• 다른 서비스에 상품의 복제물 또는 유사 사본을 출품하지 않습니다.\n• 본 서비스가 제공하는 모든 자산 거래에 발생하는 세금(과세되는 모든 경우를 포함합니다.)을 결정할 책임은 전적으로 이용자에게 있습니다. 회사는 본 서비스상의 이용으로 인해 이용자에게 발생하는 과세의무에 대한 책임을 지지 않습니다.`;
readonly DLG_KAIKAS_ONLY_DESKTOP_SELL = `주소 등록은 카이카스 지갑 연결을 통해 가능합니다.\nPC 웹 브라우저를 이용해 지갑을 연결해주세요.`;
readonly DLG_KAIKAS_ONLY_DESKTOP_BUY = `구매하기는 카이카스 지갑 연결을 통해 가능합니다.\nPC 웹 브라우저를 이용해 구매해주세요.`;
readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TITLE = `에셋 발행이 요청되었습니다.\n발행하는데 1~3분 정도 소요됩니다.`;
readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TXT = `(발행되면 목록에 표시됩니다.)`;
readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TITLE = `추가 발행이 요청되었습니다.\n발행하는데 1~3분 정도 소요됩니다.`;
readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TXT = `(실패한 경우 목록에서 사라집니다.)`;
readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TITLE = `컬렉션 생성이 요청되었습니다.\n생성하는데 1~3분 정도 소요됩니다.`;
readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TXT = `(실패한 경우 목록에서 사라집니다.)`;
readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TITLE = `컬렉션 편집이 요청되었습니다.`;
readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TXT = `(실패한 경우 편집 전으로 유지됩니다.)`;
readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TITLE = `스키마 생성이 요청되었습니다.\n생성하는데 1~3분 정도 소요됩니다.`;
readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TXT = `(실패한 경우 목록에서 사라집니다.)`;
readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TITLE = `스키마 편집이 요청되었습니다.\n완료하는데 1분 정도 소요됩니다.`;
readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TXT = `(실패한 경우 편집 전으로 유지됩니다.)`;
readonly AC_BLOCKED_TITLE = `에셋 발행이 처음이신가요?`;
readonly AC_BLOCKED_GUIDE_ROW_1 = `에셋은 Cobalt의 승인을 받은 원작자만 발행이 가능합니다. Cobalt의 승인을 받으려면 신원 확인(KYC)과 심사가 필요합니다.`;
readonly AC_BLOCKED_GUIDE_ROW_2 = `아래의 [문의하기 > 원작자 신청]을 통해 연락 주시면 메일로 안내드리도록 하겠습니다.`;
readonly AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT = `[문의하기 > 원작자 신청]`;
readonly AC_BLOCKED_BTN = `문의하기 `;

  // ------------------------------------------------
  // Market place
  // ------------------------------------------------
  readonly MARKET_LIST_SEARCH_PLACEHOLDER = `에셋 이름 검색`;
  readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_NEWEST = this.COMMON_SEARCH_ORDERBY_LISTING_NEWEST;
  readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_OLDEST = this.COMMON_SEARCH_ORDERBY_LISTING_OLDEST;
  readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_HIGHEST = this.COMMON_SEARCH_ORDERBY_PRICE_HIGHEST;
  readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_LOWEST = this.COMMON_SEARCH_ORDERBY_PRICE_LOWEST;
  readonly MARKET_LIST_SEARCH_FILTER_COLLECTION = `컬렉션`;
  readonly MARKET_LIST_SEARCH_FILTER_CURRENCY = `판매 통화`;
  readonly ASSET_CARD_BUY_BTN = this.COMMON_BUY_BTN;
  readonly MARKET_ASSET_READ_BACK = this.COMMON_BACK;
  readonly MARKET_ASSET_READ_OWNER = this.COMMON_SELLER;
  readonly MARKET_ASSET_READ_CREATOR = this.COMMON_CREATOR;
  readonly MARKET_ASSET_READ_SOLD = `판매 완료되었습니다.`;
  readonly MARKET_ASSET_READ_BUYER = this.COMMON_BUYER;
  readonly MARKET_ASSET_READ_BUY_BTN = this.COMMON_BUY_BTN;
  readonly MARKET_ASSET_READ_ASSET_ID = `에셋 ID`;
  readonly MARKET_ASSET_READ_SALE_ID = `판매ID`;
  readonly MARKET_ASSET_READ_ASSET_TYPE = this.COMMON_ASSET_TYPE;
  readonly MARKET_ASSET_READ_MINT_CNT = this.COMMON_ASSET_MINT_CNT;
  readonly MARKET_ASSET_READ_MINT_NO_MORE = this.COMMON_ASSET_MINT_NO_MORE;
  readonly MARKET_ASSET_READ_MINT_MORE = this.COMMON_ASSET_MINT_MORE;
  readonly MARKET_ASSET_READ_PRICE_HISTORY = `거래 가격 그래프`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_SUB = `이 그래프는 해당 에셋과 동일하게 발행된 모든 에셋 판매에 기반합니다. `;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_LOWEST = this.COMMON_PRICE_HISTORY_LOWEST;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_HIGHEST = this.COMMON_PRICE_HISTORY_HIGHEST;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TX_CNT = this.COMMON_PRICE_HISTORY_TX_CNT;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_CNT = `거래 건수 : `;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_AVGPRC = `평균가 : `;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_NO_DATA = "아직 거래 가격 그래프가 없습니다.";
  readonly MARKET_ASSET_READ_TRADING_HISTORY = this.COMMON_ASSET_TRADING_HISTORY;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT = this.COMMON_ASSET_TRADING_HISTORY_EVENT;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_PRICE = this.COMMON_ASSET_TRADING_HISTORY_PRICE;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_FROM = this.COMMON_ASSET_TRADING_HISTORY_FROM;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_TO = this.COMMON_ASSET_TRADING_HISTORY_TO;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_DATE = this.COMMON_ASSET_TRADING_HISTORY_DATE;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_NO_DATA = this.COMMON_ASSET_TRADING_HISTORY_NO_DATA;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT_SALE = `판매`;
  readonly MARKET_COPY_LINK_BTN=`Copy Link`;
  readonly MARKET_SHARE_TWITTER_BTN=`Twitter`;
  readonly DLG_BUY_TITLE = `에셋 구매하기`;
  readonly DLG_BUY_COLLECTION = this.COMMON_COLLECTION;
  readonly DLG_BUY_ASSET_NAME = this.COMMON_ASSET_NAME;
  readonly DLG_BUY_MINT_CNT = this.COMMON_ASSET_MINT_CNT;
  readonly DLG_BUY_SCHEMA = this.COMMON_SCHEMA;
  readonly DLG_BUY_SELLER = this.COMMON_SELLER;
  readonly DLG_BUY_PRICE = this.COMMON_PRICE;
  readonly DLG_BUY_BTN = this.COMMON_BUY_BTN;
  readonly MARKET_COLLECTION_READ_BACK = this.COMMON_BACK;
  readonly MARKET_COLLECTION_READ_COLLECTION = this.COMMON_COLLECTION;
  readonly MARKET_COLLECTION_READ_OWNER = this.COMMON_OWNER;
  readonly MARKET_COLLECTION_READ_COLLECTION_NAME = this.COMMON_COLLECTION_NAME;
  readonly MARKET_COLLECTION_READ_DISP_NAME = this.COMMON_DISP_NAME;
  readonly MARKET_COLLECTION_READ_ROYALTY = this.COMMON_ROYALTY;
  readonly MARKET_COLLECTION_READ_WEBSITE = this.COMMON_WEBSITE;
  readonly MARKET_COLLECTION_READ_DESC = this.COMMON_DESC;
  readonly MARKET_COLLECTION_READ_ADMIN = this.COMMON_ADMIN;
  readonly MARKET_COLLECTION_READ_ASSET_LIST_TITLE = `컬렉션의 에셋`;
  readonly MARKET_SCHEMA_READ_BACK = this.COMMON_BACK;
  readonly MARKET_SCHEMA_READ_ATTR_CNT = `개의 속성`;
  readonly MARKET_SCHEMA_READ_TITLE = this.COMMON_SCHEMA;
  readonly MARKET_SCHEMA_READ_ATTR_NAME = this.COMMON_ATTR_NAME;
  readonly MARKET_SCHEMA_READ_ATTR_TYPE = this.COMMON_ATTR_TYPE;
  readonly MARKET_SCHEMA_READ_ASSET_LIST_TITLE = `이 스키마를 사용하는 에셋`;
  readonly MARKET_ACCOUNT_READ_VIEW_ON_BLOCKS = this.COMMON_VIEW_ON_BLOCKS;
  readonly MARKET_ACCOUNT_READ_MENU_INVENTORY = this.COMMON_MENU_INVENTORY;
  readonly MARKET_ACCOUNT_READ_MENU_CREATED = this.COMMON_MENU_MINTED;
  readonly MARKET_ACCOUNT_READ_MENU_ON_SALE = this.COMMON_MENU_ON_SALE;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD = this.COMMON_MENU_SOLD;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED = this.COMMON_MENU_PURCHASED;
  readonly MARKET_ACCOUNT_READ_SEARCH_PLACEHOLDER = this.COMMON_SEARCH_PLACEHOLDER;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_NEWEST = this.COMMON_SEARCH_ORDERBY_LISTING_NEWEST;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_OLDEST = this.COMMON_SEARCH_ORDERBY_LISTING_OLDEST;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_HIGHEST = this.COMMON_SEARCH_ORDERBY_PRICE_HIGHEST;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_LOWEST = this.COMMON_SEARCH_ORDERBY_PRICE_LOWEST;
  readonly MARKET_ACCOUNT_READ_MENU_INVEN_CNT = `{개수}개 소유`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_ASSET_ID = `에셋 ID`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_DATE = `판매일시`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_SELLER = this.COMMON_SELLER;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_BUYER = this.COMMON_BUYER;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_PRICE = `판매가격`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_TX_HASH = this.COMMON_TX_HASH;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_ASSET_ID = `에셋 ID`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_DATE = `구매일시`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_SELLER = this.COMMON_SELLER;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_BUYER = this.COMMON_BUYER;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_PRICE = `구매가격`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_TX_HASH = this.COMMON_TX_HASH;
  readonly MARKET_REPORT_BTN = `신고하기`;
  readonly MARKET_DLG_REPORT_TITLE = `신고 사유를 선택해주세요.`;
  readonly MARKET_DLG_REPORT_CASE_1 = `성적인 에셋`;
  readonly MARKET_DLG_REPORT_CASE_2 = `폭력적 또는 혐오스러운 에셋`;
  readonly MARKET_DLG_REPORT_CASE_3 = `증오 또는 학대하는 에셋`;
  readonly MARKET_DLG_REPORT_CASE_4 = `권리를 침해하는 에셋`;
  readonly MARKET_DLG_REPORT_CASE_5 = `기타`;
  readonly MARKET_DLG_REPORT_BTN = `신고하기`;
  readonly MARKET_DLG_REPORT_SUCCESS = `신고가 완료되었습니다.`;
  readonly MARKET_ASSET_CARD_BLOCK = `해당 에셋은 신고 횟수가 많아\n비공개 처리되었습니다.`;
  readonly MARKET_DLG_ERROR_ALREADY_OWNER = `소유하고 있는 에셋입니다.`;
  readonly DLG_WILL_MOVE_PAYMENTPAGE_TITLE = `결제 페이지로 이동합니다.`;
  readonly DLG_WILL_MOVE_PAYMENTPAGE_TEXT = `잠시만 기다려주세요.`;
  readonly MARKET_PAYMENT_TITLE = `결제하기`;
  readonly MARKET_PAYMENT_ASSET = `구매 에셋`;
  readonly MARKET_PAYMENT_POINT = `POINT 결제`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP = `POINT 결제란?`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT1 = `USD로 POINT를 충전한 후 결제에 사용하는 방식`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT2 = `1 USD = 1 POINT`;
  readonly MARKET_PAYMENT_MY_POINT_BALANCE = `보유 POINT`;
  readonly MARKET_PAYMENT_LACK_POINT_AMOUNT = `부족 POINT`;
  readonly MARKET_PAYMENT_CHARGE_POINT = `POINT 충전`;
  readonly MARKET_PAYMENT_CHARGE_POINT_GUIDE = `부족한 POINT를 충전하고 결제해보세요`;
  readonly MARKET_PAYMENT_PRICE = `결제 금액`;
  readonly MARKET_PAYMENT_AGREE_ALL = `전체 동의하기`;
  readonly MARKET_PAYMENT_AGREE_TERMS_OF_USE = `이용약관을 확인하고 동의합니다.`;
  readonly MARKET_PAYMENT_AGREE_REFUND_POLICY = `환불정책을 확인하고 동의합니다.`;
  readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY = `개인정보처리방침을 확인하고 동의합니다.`;
  readonly MARKET_PAYMENT_AGREE_TERM_OF_USE_LINK = `이용약관 보기`;
  readonly MARKET_PAYMENT_AGREE_REFUND_POLICY_LINK = `환불적책 보기`;
  readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY_LINK = `개인정보처리방침 보기`;
  readonly MARKET_PAYMENT_BTN = `결제하기`;
  readonly DLG_CHARGE_LIST_BTN = `충전하기`;
  readonly DLG_CHARGE_LIST_TITLE = `POINT 충전하기`;
  readonly DLG_CHARGE_FEE_DISCOUNT = `수수료 {할인율} 할인`;
  readonly DLG_CHARGE_LIST_MY_POINT_BALANCE = `POINT 잔고`;
  readonly DLG_CHARGE_LIST_TERMS = this.FOOTER_TERMS;
  readonly DLG_CHARGE_LIST_REFUND_POLICY = this.FOOTER_REFUND_POLICY;
  readonly DLG_CHARGE_LIST_PRIVACY = this.FOOTER_PRIVACY;
  readonly DLG_CHARGE_LIST_RAW1 = this.FOOTER_RAW1;
  readonly DLG_CHARGE_LIST_RAW2 = this.FOOTER_RAW2;
  readonly DLG_RUNNING_SMART_CONTRACT = `지갑에서 거래를 진행해주세요.`;
  readonly DLG_BUY_SUCCESS_TITLE = `결제가 완료되었습니다.`;
  readonly DLG_BUY_SUCCESS_TEXT = `마이페이지 인벤토리에서 확인 가능합니다.`;
  readonly DLG_BUY_FAILURE_TITLE = `결제에 실패했습니다.`;
  readonly DLG_BUY_FAILURE_TEXT = `다시 시도 바랍니다.`;
  readonly DLG_RUNNING_BUY_PROCESS_TITLE = `결제 진행 중입니다.`;
  readonly DLG_RUNNING_BUY_PROCESS_TEXT = `잠시만 기다려주세요.`;
  readonly DLG_CHARGE_SUCC = `포인트를 충전했습니다.`;
  readonly DLG_CHARGE_FAIL = `포인트 충전에 실패했습니다.`;

  // ------------------------------------------------
  // Mypage
  // ------------------------------------------------
  readonly MY_VIEW_ON_BLOCKS = this.COMMON_VIEW_ON_BLOCKS;
  readonly MY_MY_BALANCE_TITLE = `내 잔고`;
  readonly MY_MY_BALANCE_BTN = `내역 보기`;
  readonly MY_KLAY_ADDRESS_TITLE = `지갑 등록 주소`;
  readonly MY_KLAY_ADDRESS_TITLE_TOOLTIP = `KLAY 판매 수익 및 리워드 CBLT를 받기 위한 주소`;
  readonly MY_KLAY_ADDRESS_INPUT_PLACEHOLDER = `KLAY 판매 수익 및 리워드 CBLT를 받을 주소를 등록해주세요.`;
  readonly MY_KLAY_ADDRESS_INPUT_REG_BTN = `등록하기`;
  readonly MY_KLAY_ADDRESS_INPUT_CHANGE_BTN = `변경하기`;
  readonly MY_KLAY_ADDRESS_INPUT_DONE_BTN = `확인`;
  readonly MY_KLAY_ADDRESS_INPUT_INVALID = `주소 형식이 올바르지 않습니다.`;
  readonly MY_KLAY_ADDRESS_INPUT_TOOLTIP = `KLAY 지갑 이외의 주소 또는 오입력의 경우에는 판매 수익 및 리워드 CBLT가 소실될 수 있으니 주의해주세요.`;
  readonly MY_BALANCE_KLAY_LABEL = `KLAY 잔고`;
  readonly MY_BALANCE_CBLT_LABEL = `CBLT 잔고`;
  readonly MY_BALANCE_CUSD_LABEL = `USD 잔고`;
  readonly MY_BALANCE_CPOINT_LABEL = `POINT 잔고`;
  readonly MY_BALANCE_CPOINT_CHARGE = `충전하기`;
  readonly MY_BALANCE_CUSD_WITHDRAWAL = `보내기`;
  readonly MY_BALANCE_CBLT_TOOLTIP = `생태계 기여도에 따라 배포되는 토큰`;
  readonly MY_MENU_INVENTORY = this.COMMON_MENU_INVENTORY;
  readonly MY_MENU_CREATED = this.COMMON_MENU_MINTED;
  readonly MY_MENU_FOR_SALE = this.COMMON_MENU_ON_SALE;
  readonly MY_MENU_SOLD = this.COMMON_MENU_SOLD;
  readonly MY_MENU_PURCHASED = this.COMMON_MENU_PURCHASED;
  readonly MY_MENU_FAVORITE = `즐겨찾기`;
  readonly MY_MENU_MYACCOUNT = `내 계정`;
  readonly MY_SEARCH_PLACEHOLDER = this.COMMON_SEARCH_PLACEHOLDER;
  readonly MY_SEARCH_ORDERBY_LISTING_NEWEST = this.COMMON_SEARCH_ORDERBY_LISTING_NEWEST;
  readonly MY_SEARCH_ORDERBY_LISTING_OLDEST = this.COMMON_SEARCH_ORDERBY_LISTING_OLDEST;
  readonly MY_SEARCH_ORDERBY_PRICE_HIGHEST = this.COMMON_SEARCH_ORDERBY_PRICE_HIGHEST;
  readonly MY_SEARCH_ORDERBY_PRICE_LOWEST = this.COMMON_SEARCH_ORDERBY_PRICE_LOWEST;
  readonly MY_MENU_INVENTORY_STOP_BTN = `판매 중지`;
  readonly MY_MENU_INVENTORY_NO_DATA = `아직 인벤토리가 없습니다.`;
  readonly MY_MENU_ISSUE_NO_DATA = `아직 발행에셋이 없습니다.`;
  readonly MY_MENU_SALE_NO_DATA = `아직 판매중인 에셋이 없습니다.`;
  readonly MY_MENU_SOLD_NO_DATA = `아직 판매된 에셋이 없습니다.`;
  readonly MY_MENU_BOUGHT_NO_DATA = `아직 구매한 에셋이 없습니다.`;
  readonly MY_MENU_FAVORITE_NO_DATA = `아직 즐겨찾기한 에셋이 없습니다.`;
  readonly MY_MENU_SEARCH_NOT_FOUND = `검색 결과가 없습니다.`;
  readonly MY_MENU_ON_SALE_EDIT_BTN = `판매 편집`;
  readonly MY_MENU_SOLD_ASSET_ID = `에셋 ID`;
  readonly MY_MENU_SOLD_DATE = `판매일시`;
  readonly MY_MENU_SOLD_SELLER = this.COMMON_SELLER;
  readonly MY_MENU_SOLD_BUYER = this.COMMON_BUYER;
  readonly MY_MENU_SOLD_PRICE = `판매가격`;
  readonly MY_MENU_SOLD_ROYALTY = `컬렉션 인세`;
  readonly MY_MENU_SOLD_PLATFORM_FEE = `플랫폼 수수료`;
  readonly MY_MENU_SOLD_PROFIT = `수익`;
  readonly MY_MENU_SOLD_REWARD = `리워드`;
  readonly MY_MENU_SOLD_REWARD_TOOLTIP = `판매 기여로 배포된 토큰`;
  readonly MY_MENU_SOLD_TX_HASH = this.COMMON_TX_HASH;
  readonly MY_MENU_PURCHASED_ASSET_ID = `에셋 ID`;
  readonly MY_MENU_PURCHASED_DATE = `구매일시`;
  readonly MY_MENU_PURCHASED_SELLER = this.COMMON_SELLER;
  readonly MY_MENU_PURCHASED_BUYER = this.COMMON_BUYER;
  readonly MY_MENU_PURCHASED_PRICE = `구매가격`;
  readonly MY_MENU_PURCHASED_REWARD = `리워드`;
  readonly MY_MENU_PURCHASED_REWARD_TOOLTIP = `구매 기여로 배포된 토큰`;
  readonly MY_MENU_PURCHASED_TX_HASH = this.COMMON_TX_HASH;
  readonly MY_MENU_MYACCOUNT_SETTINGS = `계정 관리`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_EMAIL = `이메일`;
  readonly MY_MENU_MYACCOUNT_EMAIL_AUTH_COMPLETE = `인증 완료`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_ID = `ID`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD = `비밀번호`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD_GUIDE = `계정 보안을 위해 주기적인 변경 권유`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_FISH_SECURE_CODE = `피싱 방지 코드`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_CHANGE_BTN = `변경하기`;
  readonly MY_PWCHANGE_TITLE = `비밀번호 변경`;
  readonly MY_PWCHANGE_CUR_PASSWORD = `현재 비밀번호`;
  readonly MY_PWCHANGE_CUR_PASSWORD_PLACEHOLDER = `현재 비밀번호 입력`;
  readonly MY_PWCHANGE_CUR_PASSWORD_WRONG = `현재 비밀번호와 일치하지 않습니다.`;
  readonly MY_PWCHANGE_NEW_PASSWORD = `새 비밀번호`;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_1 = this.COMMON_PASSWORD_GUIDE_1;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_2 = this.COMMON_PASSWORD_GUIDE_2;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_3 = this.COMMON_PASSWORD_GUIDE_3;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_4 = this.COMMON_PASSWORD_GUIDE_4;
  readonly MY_PWCHANGE_NEW_PASSWORD_PLACEHOLDER = `새 비밀번호 입력`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM = `새 비밀번호 확인`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_PLACEHOLDER = `새 비밀번호 확인`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_NO_MATCH = `비밀번호가 서로 일치하지 않습니다.`;
  readonly MY_PWCHANGE_OK_BTN = `확인`;
  readonly MY_PWCHANGE_OK_MSG_1 = `비밀번호가 변경되었습니다.`;
  readonly MY_PWCHANGE_OK_MSG_2 = `10초 뒤에 자동으로 또는 [확인]을 누르면 마이페이지로 이동합니다.`;
  readonly MY_FISH_SECURE_CODE_CHANGE_TITLE = `피싱 방지 코드 변경`;
  readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD = `비밀번호 확인`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEXT_PLACEHOLDE = `비밀번호 입력`;
  readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD_WRONG = `현재 비밀번호와 일치하지 않습니다.`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEXT = `다음`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEW = `새 코드`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEW_PLACEHOLDER = `새 코드 입력`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_BTN = `확인`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_1 = `입력한 코드로 변경되었습니다.`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_2 = `10초 뒤에 자동으로 또는 [확인]을 누르면 마이페이지로 이동합니다.`;
  readonly MY_PAYOUT_DLG_TITLE = `USD 보내기`;
  readonly MY_PAYOUT_DLG_USD_BALANCE = `USD 잔고`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT = `보내는 금액`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_SUB = `(최소 100 USD)`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_ALL_BTN = `전액 신청`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_MINIMUM = `100 USD 이상만 가능합니다.`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_INSUFFICIENT = `USD 잔고가 부족합니다.`;
  readonly MY_PAYOUT_DLG_SECTION_ACCOUNT = `보내는 계좌`;
  readonly MY_PAYOUT_DLG_SECTION_ACCOUNT_AUTH_DONE = `계좌 인증 완료`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE = `플랫폼 수수료`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_SUB = `(USD / CBLT 중 선택 지불)`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_KAIKAS = `CBLT를 지불하려면 지갑을 연결해주세요.`;
  readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE = `이체 수수료`;
  readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE_LINK = `PayPal 수수료 정책 보기`;
  readonly MY_PAYOUT_DLG_SECTION_GUIDE = `신청안내`;
  readonly MY_PAYOUT_DLG_SECTION_GUIDE_DETAIL = `• USD를 보내려면 PayPal 계좌 인증이 필요합니다. 인증 절차를 메일로 보내드리니 입력하신 PayPal 메일을 확인해주세요.\n• 계좌 인증이 완료되면 수수료를 제외한 금액을 보내드립니다.`;
  readonly MY_PAYOUT_DLG_APPLY_BTN = `신청하기`;
  readonly MY_PAYPAL_DLG_TITLE = `계좌 정보`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL = `PayPal 이메일`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL_PLACEHOLDER = `이메일 입력`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL_ALREADY = `이미 계좌 인증된 이메일입니다. `;
  readonly MY_PAYPAL_DLG_SECTION_AUTH_CODE = `이메일 인증하기`;
  readonly MY_CBLT_FEE_DLG_TITLE = `수수료 지불하기`;
  readonly MY_CBLT_FEE_DLG_SUB_TITLE = `Kaikas 지갑에서 5 CBLT를 지불해주세요.`;
  readonly MY_CBLT_FEE_DLG_SUB_DESC = `USD 보내기 실패 시 지불된 CBLT는 환불됩니다.`;
  readonly MY_CBLT_FEE_DLG_BTN = `지불하기`;
  readonly MY_PAYOUT_DLG_APPLY_DONE = `USD 보내기 신청이 접수되었습니다.\n계좌 인증을 위해 입력하신 PayPal 메일을 확인해주세요.`;
  readonly MY_PAYOUT_DLG_APPLY_DONE_2 = `USD 보내기 신청이 접수되었습니다`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY = `CBLT 지불은 카이카스 지갑 연결를 통해 가능합니다.\nPC 웹 브라우저를 이용해 지갑을 연결해주세요.`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY_HIGHLIGH = `PC 웹 브라우저`;
  readonly DLG_PAYOUT_ID_CHANGE_TITLE = `이미 인증된 계좌가 있습니다.\n변경하려면 다시 인증을 받아야합니다.\n변경하시겠습니까?`;
  readonly DLG_PAYOUT_ID_CHANGE_CHANGE_BTN = `변경하기`;
  readonly DLG_PAYOUT_ID_CHANGE_CHANGE_CLOSE = `닫기`;

  // ------------------------------------------------
  // Join
  // ------------------------------------------------
  readonly JOIN_TITLE = `회원가입`;
  readonly JOIN_EMAIL = this.COMMON_EMAIL;
  readonly JOIN_EMAIL_PLACEHOLDER = this.COMMON_EMAIL_PLACEHOLDER;
  readonly JOIN_EMAIL_INVALID = this.COMMON_EMAIL_INVALID;
  readonly JOIN_EMAIL_DUPLICATED = `이미 등록된 이메일 주소입니다.`;
  readonly JOIN_EMAIL_AUTH = `이메일 인증하기`;
  readonly JOIN_EMAIL_AUTH_PLACEHOLDER = `인증 번호 입력`;
  readonly JOIN_EMAIL_AUTH_SEND = `인증 메일 발송`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_1 = `인증 메일이 발송 되었습니다.`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_2 = `메일함에서 인증 번호를 확인한 후 입력하고 인증하기 버튼을 눌러주세요.`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_1 = `인증 메일은 발송 시점으로부터 24시간 동안만 유효하며, 재발송 시 기존 인증 번호는 만료됩니다.`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_2 = `반드시 마지막에 수신된 메일을 확인 바랍니다.`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_3 = `메일이 도착하지 않았다면 스팸함을 확인해 주시기 바랍니다. `;
  readonly JOIN_EMAIL_AUTH_RESEND = `인증 메일 재발송`;
  readonly JOIN_EMAIL_AUTH_BTN = `인증하기`;
  readonly JOIN_EMAIL_AUTH_SUCC = `인증완료`;
  readonly JOIN_EMAIL_AUTH_INVALID = `인증 번호가 일치하지 않습니다.`;
  readonly JOIN_PASSWORD = this.COMMON_PASSWORD;
  readonly JOIN_PASSWORD_PLACEHOLDER = this.COMMON_PASSWORD_PLACEHOLDER;
  readonly JOIN_PASSWORD_GUIDE_1 = this.COMMON_PASSWORD_GUIDE_1;
  readonly JOIN_PASSWORD_GUIDE_2 = this.COMMON_PASSWORD_GUIDE_2;
  readonly JOIN_PASSWORD_GUIDE_3 = this.COMMON_PASSWORD_GUIDE_3;
  readonly JOIN_PASSWORD_GUIDE_4 = this.COMMON_PASSWORD_GUIDE_4;
  readonly JOIN_PASSWORD_CONFIRM = `비밀번호 확인`;
  readonly JOIN_PASSWORD_CONFIRM_PLACEHOLDER = `비밀번호 확인`;
  readonly JOIN_PASSWORD_CONFIRM_INVALID = `비밀번호가 서로 일치하지 않습니다.`;
  readonly JOIN_FISH_SECURE_CODE = `피싱 방지 코드`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_1 = `* 피싱 방지 코드란`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_2 = `Cobalt를 사칭한 메일 계정을 이용한 피싱 등, 발생 가능한 보안 문제 피해를 방지하기 위해서 사용자가 직접 설정하는 피싱 방지 코드로 당사자가 확인 가능한 코드로 입력해주세요.`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_3 = `Cobalt에서 정상적으로 보낸 모든 메일에는 항상 피싱 방지 코드가 첨부됩니다.`;
  readonly JOIN_FISH_SECURE_CODE_PLACEHOLDER = `코드 입력`;
  readonly JOIN_AGE_OVER_20 = `만 20세 이상입니다.`;
  readonly JOIN_USAGE_TERM = `약관을 확인하고 동의합니다. `;
  readonly JOIN_USAGE_TERM_BTN = `이용약관 보기 `;
  readonly JOIN_BTN = `가입하기`;

  // ------------------------------------------------
  // Login
  // ------------------------------------------------
  readonly LOGIN_TITLE = `로그인`;
  readonly LOGIN_SECURE_GUIDE = `방문한 URL이 올바른지 체크해주세요.`;
  readonly LOGIN_EMAIL = this.COMMON_EMAIL;
  readonly LOGIN_EMAIL_PLACEHOLDER = this.COMMON_EMAIL_PLACEHOLDER;
  readonly LOGIN_FAIL_MSG = `이메일 혹은 비밀번호의 형식이 잘못되었습니다.`;
  readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_1 = `너무 많은 로그인 시도를 하여 계정이 잠금 상태가 되었습니다. `;
  readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_2 = `비밀번호 찾기를 통해 해결할 수 있습니다.`;
  readonly LOGIN_PASSWORD = this.COMMON_PASSWORD;
  readonly LOGIN_PASSWORD_PLACEHOLDER = this.COMMON_PASSWORD_PLACEHOLDER;
  readonly LOGIN_BTN = `로그인`;
  readonly LOGIN_FIND_PASSWORD = `비밀번호 찾기`;
  readonly LOGIN_JOIN = `회원가입하기`;
  readonly LOGIN_RESET_PASSWORD = `비밀번호 찾기`;
  readonly LOGIN_RESET_EMAIL = `이메일`;
  readonly LOGIN_RESET_EMAIL_PLACEHOLDER = `이메일 입력`;
  readonly LOGIN_RESET_OK_BTN = `확인`;
  readonly LOGIN_RESET_OK_MSG_1 = `입력한 이메일로 임시 비밀번호가 발송되었습니다.`;
  readonly LOGIN_RESET_OK_MSG_2 = `10초 뒤에 자동으로 또는 [확인]을 누르면 로그인으로 이동합니다.`;

  // ------------------------------------------------
  // UserBan
  // ------------------------------------------------
  readonly USERBAN_TITLE = `계정 사용이 정지되었습니다.`;
  readonly USERBAN_DESC = `운영 정책을 반복적으로 위반하거나 중대한 위반 행위가 확인될 경우 계정 사용이 정지됩니다. \n아무리 작은 위반 사항이라도 계속 누적될 경우 정지될 수 있습니다.`;
  readonly USERBAN_REASON_TITLE = `계정 사용이 정지 되는 이유`;
  readonly USERBAN_REASON_DESC = `계정 사용이 정지되는 일반적인 이유는 다음과 같습니다.`;
  readonly USERBAN_REASON_1 = `∙ 운영 정책 위반`;
  readonly USERBAN_REASON_1_DESC = `서비스 이용약관 및 운영 정책을 따르지 않았을 경우`;
  readonly USERBAN_REASON_2 = `∙ 신고 누적`;
  readonly USERBAN_REASON_2_DESC = `신고 사유에 해당하는 에셋을 발행하여 신고 횟수가 누적된 경우`;
  readonly USERBAN_REASON_3 = `∙ 계정 해킹 방지`;
  readonly USERBAN_REASON_3_DESC = `다른 사용자가 계정에 로그인하거나 사용한 경우`;
  readonly USERBAN_REASON_4 = `∙ 명의 도용`;
  readonly USERBAN_REASON_4_DESC = `개인이나 조직의 명의를 도용하여 계정을 만든 경우`;
  readonly USERBAN_REASON_5 = `∙ 악용할 목적으로 여러 계정 사용`;
  readonly USERBAN_REASON_5_DESC = `정책을 위반할 목적으로 여러 계정을 만들거나 사용한 경우`;
  readonly USERBAN_REASON_GUIDE = `이러한 이유로 Cobalt에서 최선의 판단을 적용하여 사용자 계정을 정지할 수 있습니다.\n기타 문의사항이 있을 경우 {문의하기}를 통해 연락 바랍니다.`;

  // ------------------------------------------------
  // Wallet
  // ------------------------------------------------
  readonly WALLET_REWARD_ADDR_CHAGNE_TITLE = `현재 연결된 지갑 주소와\n이미 등록된 지갑 주소가 다릅니다.\n연결된 지갑 주소로 등록 주소를 변경하시겠습니까?`;
  readonly WALLET_REWARD_ADDR_CHAGNE_DESC = `등록된 주소로 판매 수익 및 리워드를 받을 수 있습니다.`;
  readonly WALLET_REWARD_ADDR_CHAGNE_BTN = `변경하기`;
  readonly WALLET_REWARD_ADDR_CLOSE_BTN = `닫기`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE = `KLAY 지갑 등록 주소가 변경되었습니다.`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE_DESC = `(등록된 주소는 마이페이지를 통해 확인 가능합니다.)`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE_OK = this.COMMON_OK_BTN_TXT;
  readonly WALLET_CONNECT = `지갑 연결하기`;
  readonly WALLET_CONNECT_BTN = `연결하기`;
  readonly WALLET_CYPRESS_NOT_AVAILABLE_IN_ALPHA = `Cypress: Klaytn Mainnet에 연결할 수 없습니다.`;
  readonly WALLET_BAOBAB_NOT_AVAILABLE_IN_LIVE = `Baobab: Klaytn Testnet에 연결할 수 없습니다.`;

  // ------------------------------------------------
  // 거래내역
  // ------------------------------------------------
  readonly PROP_HISTORY_TITLE = `내역`;
  readonly PROP_HISTORY_POINT_INCOME = `총 충전 POINT`;
  readonly PROP_HISTORY_POINT_SPEND = `총 사용 POINT`;
  readonly PROP_HISTORY_POINT_BALANCE = `POINT 잔고`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_ALL = `전체`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_INCOME = `충전 내역`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_SPEND = `사용 내역`;
  readonly PROP_HISTORY_POINT_TYPE_INCOME = `충전`;
  readonly PROP_HISTORY_POINT_TYPE_SPEND = `사용`;
  readonly PROP_HISTORY_USD_INCOME = `총 받은 USD`;
  readonly PROP_HISTORY_USD_SPEND = `총 보낸 USD`;
  readonly PROP_HISTORY_USD_BALANCE = `USD 잔고`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_ALL = `전체`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_INCOME = `받은 내역`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_SPEND = `보낸 내역`;
  readonly PROP_HISTORY_USD_TYPE_INCOME = `받음`;
  readonly PROP_HISTORY_USD_TYPE_SPEND = `보냄`;
  readonly PROP_HISTORY_KLAY_INCOME = `총 받은 KLAY`;
  readonly PROP_HISTORY_KLAY_SPEND = `총 사용 KLAY`;
  readonly PROP_HISTORY_KLAY_BALANCE = `KLAY 잔고`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_ALL = `전체`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_INCOME = `받은 내역`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_SPEND = `사용 내역`;
  readonly PROP_HISTORY_KLAY_TYPE_INCOME = `받음`;
  readonly PROP_HISTORY_KLAY_TYPE_SPEND = `사용`;
  readonly PROP_HISTORY_CBLT_INCOME = `총 받은 CBLT`;
  readonly PROP_HISTORY_CBLT_SPEND = `총 사용 CBLT`;
  readonly PROP_HISTORY_CBLT_BALANCE = `CBLT 잔고`;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_ALL = `전체`;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_INCOME = `받은 내역`;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_SPEND = `사용 내역`;
  readonly PROP_HISTORY_CBLT_TYPE_INCOME = `받음`;
  readonly PROP_HISTORY_CBLT_TYPE_SPEND = `사용`;
  readonly PROP_HISTORY_SUCC = `성공`;
  readonly PROP_HISTORY_FAIL = `실패`;
  readonly PROP_HISTORY_QUERY_BTN = `조회`;
  readonly PROP_HISTORY_EMPTY_DATA = `아직 내역이 없습니다.`;
  readonly PROP_HISTORY_QUERY_NOT_FONUD = `조회된 내역이 없습니다.`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_101 = `에셋 구매`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_102 = `Paymentwall Cash Out`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_103 = `cash out 출금 수수료`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_201 = `에셋 판매 수익`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_202 = `창작자 인세 수익`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_203 = `에셋 판매 리워드`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_204 = `에셋 구매 리워드`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_205 = `에셋 판매 창작자 리워드`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_301 = `POINT 충전`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_302 = `Admin으로부터 보상 POINT 충전`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_303 = `Admin 테스트 충전`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_304 = `POINT 충전`;

  // ------------------------------------------------
  // 문의하기
  // ------------------------------------------------
  readonly INQUERY_TITLE = `문의하기`;
  readonly INQUERY_TYPE = `문의 유형`;
  readonly INQUERY_TYPE_PROBLEM = `불편 신고(버그 및 사용자 신고)`;
  readonly INQUERY_TYPE_TASK = `원작자 신청`;
  readonly INQUERY_TYPE_QUESTION = `질문`;
  readonly INQUERY_TYPE_INCIDENT = `환불 관련`;
  readonly INQUERY_NAME = `이름`;
  readonly INQUERY_NAME_PLACEHOLDER = `문의자 이름 입력`;
  readonly INQUERY_EMAIL = `이메일`;
  readonly INQUERY_EMAIL_PLACEHOLDER = `답변 받으실 이메일 입력`;
  readonly INQUERY_SUBJECT = `문의 내용`;
  readonly INQUERY_SUBJECT_PLACEHOLDER = `문의 제목`;
  readonly INQUERY_BODY_PALCEHOLDER = `문의 내용을 자세히 작성해주세요.`;
  readonly INQUERY_BTN = `보내기`;
  readonly INQUERY_SUCCESS = `문의가 접수되었습니다.`;

  // ------------------------------------------------
  // 포인트 충전
  // ------------------------------------------------
  readonly DLG_CHARGE_TITLE = `결제하기`;
  readonly DLG_CHARGE_UNIT_PRICE = `상품 금액`;
  readonly DLG_CHARGE_FEE = `수수료`;
  readonly DLG_CHARGE_TOTAL_PRICE = `결제 금액`;
  readonly DLG_CHARGE_FOOTER_TERMS = this.FOOTER_TERMS;
  readonly DLG_CHARGE_FOOTER_PRIVACY = this.FOOTER_PRIVACY;
  readonly DLG_CHARGE_FOOTER_REFUND_POLICY = this.FOOTER_REFUND_POLICY;

  // 기타
  readonly COMMON_UNIT_YEAR = `년`;
  readonly COMMON_UNIT_MONTH = `월`;
  readonly COMMON_UNIT_DAY = `일`;
  readonly COMMON_UNIT_AM = `오전`;
  readonly COMMON_UNIT_PM = `오후`;
}
