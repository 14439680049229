/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, HTMLProps, ReactChild, ReactFragment, ReactPortal } from 'react';
import { useScreenWidthHelper } from 'src/hooks/useScreenWidthHelper';
import { devOutlineF, FlexRowDiv } from 'src/ui/base_component/etc';
import { Text, TextProps } from 'src/ui/base_component/Text';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export interface TextConProps extends TextProps {
  key?: string | number;
  // ref?: any;
  text?: string;
  containerStyle?: CSSProperties;
  textStyle?: any;
  onConMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  onConMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
  onConClick?: HTMLProps<HTMLDivElement>['onClick'];

  // children?: React.ReactChild;
  children?: ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
  ellipsisWidth?: number;
}

export default function TextCon(props: TextConProps) {
  // const { scale } = useScreenWidthHelper();
  // const scaledProps: TextConProps = {
  //   ...props,
  //   size: props.size ? props.size * scale : undefined,
  // };

  return (
    <FlexRowDiv
      key={props.key}
      style={{
        cursor: props.onConClick && props.text && props.text.length !== 0 ? 'pointer' : 'inherit',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...devOutline,
        ...props.containerStyle,
        content: 'contents',
      }}
      onMouseEnter={props.onConMouseEnter}
      onMouseLeave={props.onConMouseLeave}
      onClick={props.onConClick}
    >
      <Text
        {...props}
        style={Object.assign(
          {},
          props.textStyle,
          props.ellipsisWidth && { width: props.ellipsisWidth, overflow: 'hidden', textOverflow: 'ellipsis' }
        )}
      >
        {props.text ? props.text : props.children}
      </Text>
    </FlexRowDiv>
  );
}
