/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CbtUserLanguage_e } from 'src/model/rpcModel';
import Strings from 'src/res/strings';

// prettier-ignore
export class StringsJp extends Strings {
  readonly LANG = CbtUserLanguage_e.JA;

  // ------------------------------------------------
  // 공통
  // ------------------------------------------------
  readonly COMMON_BACK = `戻る`;
  readonly COMMON_COLLECTION = `コレクション`;
  readonly COMMON_COLLECTION_NAME = `コレクション名`;
  readonly COMMON_DISP_NAME = `ディスプレイ名`;
  readonly COMMON_ROYALTY = `ロイヤリティ`;
  readonly COMMON_WEBSITE = `ウェブサイトURL`;
  readonly COMMON_DESC = `説明`;
  readonly COMMON_ADMIN = `管理者アカウント`;
  readonly COMMON_CANNOT_EDIT = `修正不可`;
  readonly COMMON_ASSET_NAME = `アセット名`;
  readonly COMMON_ASSET_MINT_CNT = `発行枚数`;
  readonly COMMON_ASSET_MINT_NO_MORE = `追加発行なし`;
  readonly COMMON_ASSET_MINT_MORE = `追加発行可能`;
  readonly COMMON_ASSET_TYPE = `アセットタイプ`;
  readonly COMMON_ASSET_TYPE_IMAGE = `画像`;
  readonly COMMON_ASSET_TYPE_VIDEO = `ビデオ`;
  readonly COMMON_ASSET_TYPE_AUDIO = `オーディオ`;
  readonly COMMON_SCHEMA = `スキーマ`;
  readonly COMMON_SCHEMA_NAME = `スキーマ名`;
  readonly COMMON_ATTR = `属性とタイプ`;
  readonly COMMON_ATTR_NAME = `属性名`;
  readonly COMMON_ATTR_TYPE = `属性タイプ`;
  readonly COMMON_ATTR_TYPE_TEXT = `テキスト`;
  readonly COMMON_ATTR_TYPE_NUMBER = `数字`;
  readonly COMMON_ATTR_TYPE_IMAGE = `画像`;
  readonly COMMON_ATTR_TYPE_VIDEO = `ビデオ`;
  readonly COMMON_ATTR_TYPE_AUDIO = `オーディオ`;
  readonly COMMON_ATTR_TYPE_IMAGE_DETAIL = `(PNG, JPG, GIF, APNG)`;
  readonly COMMON_ATTR_TYPE_VIDEO_DETAIL = `(MP4)`;
  readonly COMMON_ATTR_TYPE_AUDIO_DETAIL = `(MP3)`;
  readonly COMMON_ASSET = `アセット`;
  readonly COMMON_PRICE_HISTORY_LOWEST = `最低取引価格`;
  readonly COMMON_PRICE_HISTORY_HIGHEST = `最高取引価格`;
  readonly COMMON_PRICE_HISTORY_TX_CNT = `総取引数`;
  readonly COMMON_ASSET_TRADING_HISTORY = `取引履歴`;
  readonly COMMON_ASSET_TRADING_HISTORY_EVENT = `取引内容`;
  readonly COMMON_ASSET_TRADING_HISTORY_PRICE = `価格`;
  readonly COMMON_ASSET_TRADING_HISTORY_FROM = `販売者`;
  readonly COMMON_ASSET_TRADING_HISTORY_TO = `購入者`;
  readonly COMMON_ASSET_TRADING_HISTORY_DATE = `日時`;
  readonly COMMON_ASSET_TRADING_HISTORY_NO_DATA = `まだ取引履歴がありません。`;
  readonly COMMON_BUY_BTN = `購入する`;
  readonly COMMON_CREATOR = `クリエイター`;
  readonly COMMON_OWNER = `オーナー`;
  readonly COMMON_SELLER = `販売者`;
  readonly COMMON_BUYER = `購入者`;
  readonly COMMON_PRICE = `価格`;
  readonly COMMON_TX_HASH = `トランザクションハッシュ`;
  readonly COMMON_VIEW_ON_BLOCKS = `ブロックチェーンで確認`;
  readonly COMMON_MENU_INVENTORY = `インベントリ`;
  readonly COMMON_MENU_MINTED = `発行アセット`;
  readonly COMMON_MENU_ON_SALE = `販売中`;
  readonly COMMON_MENU_SOLD = `販売済み`;
  readonly COMMON_MENU_PURCHASED = `購入済み`;
  readonly COMMON_EMAIL = `メールアドレス`;
  readonly COMMON_EMAIL_PLACEHOLDER = `メールアドレス入力`;
  readonly COMMON_EMAIL_INVALID = `メール形式が正しくありません。`;
  readonly COMMON_PASSWORD = `パスワード`;
  readonly COMMON_PASSWORD_PLACEHOLDER = `パスワード入力`;
  readonly COMMON_SEARCH_PLACEHOLDER = `検索`;
  readonly COMMON_SEARCH_ORDERBY_LISTING_NEWEST = `最新順`;
  readonly COMMON_SEARCH_ORDERBY_LISTING_OLDEST = `古い順`;
  readonly COMMON_SEARCH_ORDERBY_PRICE_HIGHEST = `価格が高い順`;
  readonly COMMON_SEARCH_ORDERBY_PRICE_LOWEST = `価格が安い順`;
  readonly COMMON_PASSWORD_GUIDE_1 = `※英文の大文字を含む`;
  readonly COMMON_PASSWORD_GUIDE_2 = `※英文の小文字を含む`;
  readonly COMMON_PASSWORD_GUIDE_3 = `※数字を含む`;
  readonly COMMON_PASSWORD_GUIDE_4 = `※10文字以上`;
  readonly COMMON_OK_BTN_TXT = `確認`;
  readonly COMMON_REPORT = `報告`;
  readonly COMMON_INQUERY = `お問い合わせ`;
  readonly COMMON_SALE_BTN = `販売する`;
  readonly COMMON_SALE_EDIT_BTN = `販売編集`;
  readonly COMMON_NOTI_TITLE = `お知らせ`;

  // ------------------------------------------------
  // 공통헤더
  // ------------------------------------------------
  readonly HEADER_SETTINGS_LANG = `言語`;
  readonly HEADER_SETTINGS_CURRENCY = `通貨`;
  readonly HEADER_MENU_EXPLORE = `Explorer`;
  readonly HEADER_MENU_ASSET_CREATOR = `Asset Creator`;
  readonly HEADER_MENU_MARKET_PLACE = `Marketplace`;
  readonly HEADER_LOGIN_BTN = `ログイン`;
  readonly HEADER_KAIKAS_WALLET_CONNECT = `ウォレット連携`;
  readonly HEADER_MYPAGE = `マイページ`;
  readonly HEADER_LOGOUT = `ログアウト`;

  // ------------------------------------------------
  // 공통푸터
  // ------------------------------------------------
  readonly FOOTER_TERMS = `サービス利用規約`;
  readonly FOOTER_PRIVACY = `プライバシーポリシー`;
  readonly FOOTER_REFUND_POLICY = `返金ポリシー`;
  readonly FOOTER_RAW1 = `金融商品取引法`;
  readonly FOOTER_RAW2 = `資金決済法`;
  readonly FOOTER_SUPPORT_EMAIL = `カスタマーサポートメール`;
  readonly FOOTER_SUPPORT_EMAIL_ADDRESS = `support@cobalt.zone`;
  readonly FOOTER_COPYRIGHT = `© Post Voyager. All rights reserved.`;
  readonly FOOTER_INQUERY = `お問い合わせ`;

  // ------------------------------------------------
  // Explorer 화면
  // ------------------------------------------------
  readonly EXP_MAIN_BANNER_BTN = `自分の\nアセット発行`;
  readonly EXP_BANNER_COLLECTION_TITLE = `コレクション`;
  readonly EXP_BANNER_EVENT_TITLE = `イベント`;
  readonly EXP_BANNER_CARROT_TITLE = `NFT by CARROT`;
  readonly EXP_BANNER_POKECOLO_TITLE = `ポケコロアートワーク`;
  readonly EXP_BANNER_EVENT_CBLT = `リワード3倍支給！`;
  readonly EXP_SUB_BANNER_BTN = `VIEW >`;
  readonly EXP_STATUS_BOARD_ASSET_CNT = `発行されたアセット`;
  readonly EXP_STATUS_BOARD_SALE_CNT = `今日の販売登録数`;
  readonly EXP_STATUS_BOARD_TX_CNT = `今日の取引回数`;
  readonly EXP_STATUS_BOARD_DOLLAR_CNT = `今日の取引額`;
  readonly EXP_RECOMMEND_ASSET_SECTION_TITLE = `おすすめのアセット`;
  readonly EXP_NEW_ASSET_SECTION_TITLE = `新しいアセット`;
  readonly EXP_NEW_ASSET_VIEW_ALL = `もっと見る`;
  readonly EXP_COLLECTION_SECTION_TITLE = `コレクション`;

  // ------------------------------------------------
  // AssetCreator
  // ------------------------------------------------
  readonly AC_COLLECTION_LIST_TITLE_IF_EMTPY = `コレクションを作成する`;
  readonly AC_COLLECTION_LIST_DESC_1 = `アセットを発行するにはまずコレクションを作ってください。`;
  readonly AC_COLLECTION_LIST_DESC_2 = `コレクションはアセットを一つにまとめて管理できます。`;
  readonly AC_COLLECTION_LIST_TITLE = `マイコレクション`;
  readonly AC_COLLECTION_LIST_NEW = `コレクションを作成する`;
  readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_1 = `作成中です。`;
  readonly AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_2 = `少しお待ちください。`;
  readonly AC_COLLECTION_CREATE_BACK = this.COMMON_BACK;
  readonly AC_COLLECTION_CREATE_TITLE = `コレクションを作成する`;
  readonly AC_COLLECTION_CREATE_IMAGE = `コレクション画像`;
  readonly AC_COLLECTION_CREATE_IMAGE_UPLOAD_SPEC = `画像アップロード形式`;
  readonly AC_COLLECTION_CREATE_CANNOT_EDIT = this.COMMON_CANNOT_EDIT;
  readonly AC_COLLECTION_CREATE_NAME = this.COMMON_COLLECTION_NAME;
  readonly AC_COLLECTION_CREATE_NAME_GUIDE = `(英文字・数字のみ、20文字以内、重複不可)`;
  readonly AC_COLLECTION_CREATE_NAME_DUPLICATED = `既に登録されているコレクション名です。`;
  readonly AC_COLLECTION_CREATE_DISPNAME = this.COMMON_DISP_NAME;
  readonly AC_COLLECTION_CREATE_DISPNAME_GUIDE = `(20文字以内)`;
  readonly AC_COLLECTION_CREATE_ROYALTY = this.COMMON_ROYALTY;
  readonly AC_COLLECTION_CREATE_ROYALTY_GUIDE = `(0~{MAX})%`;
  readonly AC_COLLECTION_CREATE_WEBSITE = this.COMMON_WEBSITE;
  readonly AC_COLLECTION_CREATE_DESC = this.COMMON_DESC;
  readonly AC_COLLECTION_CREATE_ADMIN = this.COMMON_ADMIN;
  readonly AC_COLLECTION_CREATE_ADMIN_INVALID = `無効なアカウントです。`;
  readonly AC_COLLECTION_CREATE_BTN = `コレクションを作成する`;
  readonly AC_COLLECTION_CREATE_COL_IMG_TOOLTIP = `• 解像度 200x200px 以上\n• ファイル形式 : PNG, JPG, GIF, APNG\n• 最大アップロードサイズ2MB`;
  readonly AC_COLLECTION_CREATE_TOOLTIP_DISPNAME = `他のユーザーに表示されるコレクション名`;
  readonly AC_COLLECTION_CREATE_TOOLTIP_ROYALTY = `取引時に支払われるロイヤリティ`;
  readonly AC_COLLECTION_UPDATE_TITLE = `コレクション`;
  readonly AC_COLLECTION_UPDATE_BTN = `編集完了`;
  readonly AC_COLLECTION_READ_BACK = this.COMMON_BACK;
  readonly AC_COLLECTION_READ_TITLE = this.COMMON_COLLECTION;
  readonly AC_COLLECTION_READ_EDIT_BTN = `編集する`;
  readonly AC_COLLECTION_READ_OWNER = this.COMMON_OWNER;
  readonly AC_COLLECTION_READ_COLLECTION_NAME = this.COMMON_COLLECTION_NAME;
  readonly AC_COLLECTION_READ_DISP_NAME = this.COMMON_DISP_NAME;
  readonly AC_COLLECTION_READ_ROYALTY = this.COMMON_ROYALTY;
  readonly AC_COLLECTION_READ_WEBSITE = this.COMMON_WEBSITE;
  readonly AC_COLLECTION_READ_DESC = this.COMMON_DESC;
  readonly AC_COLLECTION_READ_ADMIN = this.COMMON_ADMIN;
  readonly AC_COLLECTION_READ_SCHEMA = this.COMMON_SCHEMA;
  readonly AC_COLLECTION_READ_SCHEMA_DESC_1 = `スキーマはアセットが持つ属性の種類を定義します。`;
  readonly AC_COLLECTION_READ_SCHEMA_DESC_2 = `コレクション内に複数のスキーマを作成することができます。`;
  readonly AC_COLLECTION_READ_SCHEMA_ATTR_CNT = `の属性`;
  readonly AC_SCHEMA_CREATE_BACK = this.COMMON_BACK;
  readonly AC_SCHEMA_CREATE_TITLE = `スキーマを作成する`;
  readonly AC_SCHEMA_CREATE_NAME = this.COMMON_SCHEMA_NAME;
  readonly AC_SCHEMA_CREATE_NAME_GUIDE = `(英文字・数字のみ、20文字以内、重複不可)`;
  readonly AC_SCHEMA_CREATE_NAME_DUPLICATED = `既に登録されているスキーマ名です。`;
  readonly AC_SCHEMA_CREATE_CANNOT_EDIT = this.COMMON_CANNOT_EDIT;
  readonly AC_SCHEMA_CREATE_TYPE = this.COMMON_ASSET_TYPE;
  readonly AC_SCHEMA_CREATE_TYPE_IMAGE = this.COMMON_ASSET_TYPE_IMAGE;
  readonly AC_SCHEMA_CREATE_TYPE_VIDEO = this.COMMON_ASSET_TYPE_VIDEO;
  readonly AC_SCHEMA_CREATE_TYPE_AUDIO = this.COMMON_ASSET_TYPE_AUDIO;
  readonly AC_SCHEMA_CREATE_DISP_NAME = this.COMMON_DISP_NAME;
  readonly AC_SCHEMA_CREATE_DISP_NAME_GUIDE = `(20文字以内)`;
  readonly AC_SCHEMA_CREATE_ATTR = this.COMMON_ATTR;
  readonly AC_SCHEMA_CREATE_ATTR_GUIDE_1 = `属性名は英文字・数字のみ、20文字以内、`;
  readonly AC_SCHEMA_CREATE_ATTR_GUIDE_2 = `重複不可`;
  readonly AC_SCHEMA_CREATE_ATTR_NEW = `新しい属性`;
  readonly AC_SCHEMA_CREATE_ATTR_NEW_TYPE = `新しい属性タイプ`;
  readonly AC_SCHEMA_CREATE_ATTR_NAME_DUPLICATED = `属性名が重複しています。`;
  readonly AC_SCHEMA_CREATE_ATTR_NAME_RESERVED = `使用できない属性名です。`;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_TEXT = this.COMMON_ATTR_TYPE_TEXT;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_NUMBER = this.COMMON_ATTR_TYPE_NUMBER;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_IMAGE = this.COMMON_ATTR_TYPE_IMAGE;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_VIDEO = this.COMMON_ATTR_TYPE_VIDEO;
  readonly AC_SCHEMA_CREATE_ATTR_TYPE_AUDIO = this.COMMON_ATTR_TYPE_AUDIO;
  readonly AC_SCHEMA_CREATE_ATTR_ADD_BTN = `新しい属性を追加する。`;
  readonly AC_SCHEMA_CREATE_BTN = `スキーマを作成する`;
  readonly AC_SCHEMA_CREATE_TOOLTIP_DISPNAME = `他のユーザーに表示されるコレクション名`;
  readonly AC_SCHEMA_UPDATE_TITLE = this.COMMON_SCHEMA;
  readonly AC_SCHEMA_UPDATE_BTN = `編集完了`;
  readonly AC_SCHEMA_READ_BACK = this.COMMON_BACK;
  readonly AC_SCHEMA_READ_ATTR_CNT = `の属性`;
  readonly AC_SCHEMA_READ_EDIT_BTN = `編集する`;
  readonly AC_SCHEMA_READ_TITLE = this.COMMON_SCHEMA;
  readonly AC_SCHEMA_READ_ATTR_NAME = this.COMMON_ATTR_NAME;
  readonly AC_SCHEMA_READ_ATTR_TYPE = this.COMMON_ATTR_TYPE;
  readonly AC_SCHEMA_READ_ATTR_TYPE_TEXT = this.COMMON_ATTR_TYPE_TEXT;
  readonly AC_SCHEMA_READ_ATTR_TYPE_NUMBER = this.COMMON_ATTR_TYPE_NUMBER;
  readonly AC_SCHEMA_READ_ATTR_TYPE_IMAGE_DETAIL = this.COMMON_ATTR_TYPE_IMAGE_DETAIL;
  readonly AC_SCHEMA_READ_ATTR_TYPE_VIDEO_DETAIL = this.COMMON_ATTR_TYPE_VIDEO_DETAIL;
  readonly AC_SCHEMA_READ_ATTR_TYPE_AUDIO_DETAIL = this.COMMON_ATTR_TYPE_AUDIO_DETAIL;
  readonly AC_SCHEMA_READ_ASSET = this.COMMON_ASSET;
  readonly AC_SCHEMA_READ_NEW_ASSET_DESC = `アセットはスキーマの属性が自動的に設定され、タイプに合わせて発行できます。`;
  readonly AC_SCHEMA_READ_NEW_ASSET = `アセットを発行する`;
  readonly AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT = `{개수}個発行`;
  readonly AC_ASSET_GROUP_MINT_MORE_BTN = `追加発行`;
  readonly AC_ASSET_GROUP_DLG_TITLE = `追加で発行する`;
  readonly AC_ASSET_GROUP_DLG_MINT_OWNER = `アセットオーナー`;
  readonly AC_ASSET_GROUP_DLG_MINT_CNT = `追加発行枚数`;
  readonly AC_ASSET_GROUP_DLG_MINT_CNT_GUIDE = `(最大10個)`;
  readonly AC_ASSET_GROUP_DLG_MINT_TOTAL = `総発行数`;
  readonly AC_ASSET_GROUP_DLG_MINT_BTN = `発行する`;
  readonly AC_ASSET_CREATE_BACK = this.COMMON_BACK;
  readonly AC_ASSET_CREATE_TITLE = `アセットを発行する`;
  readonly AC_ASSET_CREATE_PREVIEW = `プレビュー`;
  readonly AC_ASSET_CREATE_OWNER = `アセットオーナー`;
  readonly AC_ASSET_CREATE_MINT_CNT = `発行枚数`;
  readonly AC_ASSET_CREATE_MINT_CNT_GUIDE = `(最大10枚)`;
  readonly AC_ASSET_CREATE_MINT_NO_MORE = this.COMMON_ASSET_MINT_NO_MORE;
  readonly AC_ASSET_CREATE_MINT_MORE = this.COMMON_ASSET_MINT_MORE;
  readonly AC_ASSET_CREATE_ATTR = this.COMMON_ATTR;
  readonly AC_ASSET_CREATE_ATTR_TYPE_NFTNAME = `テキスト (20文字以内)`;
  readonly AC_ASSET_CREATE_ATTR_TYPE_TEXT = this.COMMON_ATTR_TYPE_TEXT;
  readonly AC_ASSER_CREATE_ATTR_TYPE_NUMBER = this.COMMON_ATTR_TYPE_NUMBER;
  readonly AC_ASSET_CREATE_ATTR_TYPE_IMAGE_DETAIL = this.COMMON_ATTR_TYPE_IMAGE_DETAIL;
  readonly AC_ASSET_CREATE_ATTR_TYPE_VIDEO_DETAIL = this.COMMON_ATTR_TYPE_VIDEO_DETAIL;
  readonly AC_ASSET_CREATE_ATTR_TYPE_AUDIO_DETAIL = this.COMMON_ATTR_TYPE_AUDIO_DETAIL;
  readonly AC_ASSET_CREATE_FILE = `ファイル選択`;
  readonly AC_ASSET_CREATE_FILE_UPLOAD_SPEC = `{속성타입}アップロード形式`;
  readonly AC_ASSET_CREATE_FILE_IMAGE_SPEC_TOOLTIP = `• 解像度: 600x600px 以上\n• ファイル形式 : PNG, JPG, GIF, APNG\n• 最大アップロードサイズ10MB`;
  readonly AC_ASSET_CREATE_FILE_VIDEO_THUMB_IMAGE_SPEC_TOOLTIP = `• 解像度: 600x600px 以上\n• ファイル形式 : PNG, JPG\n• 最大アップロードサイズ5MB`;
  readonly AC_ASSET_CREATE_FILE_AUDIO_COVER_IMAGE_SPEC_TOOLTIP = `• 解像度: 400x400px 以上\n• ファイル形式 : PNG, JPG, GIF, APNG\n• 最大アップロードサイズ5MB`;
  readonly AC_ASSET_CREATE_FILE_AUDIO_SPEC_TOOLTIP = `• ファイル形式 :MP3\n• 最大アップロードサイズ10MB`;
  readonly AC_ASSET_CREATE_FILE_VIDEO_SPEC_TOOLTIP = `• 解像度: 600x600px 以上\n• ファイル形式 : MP4\n• 最大アップロードサイズ10MB`;
  readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_TITLE = `最小サイズを確認してください。`;
  readonly AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_DESC = `最低{사이즈}px以上\nアップロードできます。`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_TITLE = `ファイル形式を確認してください。`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_DESC = `{파일형식리스트}のみ\nアップロードできます。`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_TITLE = `最大サイズを確認してください。`;
  readonly AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_DESC = `最大{파일사이즈MB}MBまでアップロードできます。`;
  readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TITLE = `ファイルアップロード中`;
  readonly AC_ASSET_CREATE_DLG_FILE_UPLOADING_TEXT = `少しお待ちください。`;
  readonly AC_ASSET_CREATE_TERMS1_TITLE = `アセット発行のため、以下の情報の収集及び利用に同意します。 `;
  readonly AC_ASSET_CREATE_TERMS1_DETAIL_1 = `• 収集項目: アセット名、説明、ファイル(画像など)\n• 収集目的:アセット発行、管理`;
  readonly AC_ASSET_CREATE_TERMS1_DETAIL_2 = `• 保有及び利用期間: 永久(規定違反や削除申請を受けた際、破棄を行います)\n• 同意しない場合アセットは発行できません。`;
  readonly AC_ASSET_CREATE_TERMS2_TITLE = `以下の内容を確認し、これに同意します。 `;
  readonly AC_ASSET_CREATE_TERMS2_DETAIL = `• 私は第3者の知的財産権、人権、個人情報など他人の権利を侵害しません。\n• 私は自身の個人情報が活用される場合、第3者に公開、活用、提供される場合があることを理解し、これに同意します。\n• 私は利用規約および、運営方針に反するアセットを発行しないこと、発行するアセットに対するすべての責任は自身にあることを確認し、同意します。\n• 私はアセットに不適切な画像や、映像が含まれる場合、告知なく削除される場合があることを理解し、これに同意します。`;
  readonly AC_ASSET_CREATE_BTN = `アセットを発行する`;
  readonly AC_ASSET_CREATE_TOOLTIP_MINT = `1回最大10枚発行可能`;
  readonly AC_ASSET_READ_BACK = `戻る`;
  readonly AC_ASSET_READ_OWNER = `オーナー`;
  readonly AC_ASSET_READ_CREATOR = `クリエイター`;
  readonly AC_ASSET_READ_ASSET_ID = `アセットID`;
  readonly AC_ASSET_READ_ASSET_TYPE = `アセットタイプ`;
  readonly AC_ASSET_READ_MINT_CNT = `発行枚数`;
  readonly AC_ASSET_READ_MINT_NO_MORE = this.COMMON_ASSET_MINT_NO_MORE;
  readonly AC_ASSET_READ_MINT_MORE = this.COMMON_ASSET_MINT_MORE;
  readonly AC_ASSET_READ_BEFORE_SALE = `このアセットはまだ販売前です。`;
  readonly AC_ASSET_READ_SALE_BTN = `販売する`;
  readonly AC_ASSET_READ_ON_SALE = `このアセットは{판매ID}で販売中です。 `;
  readonly AC_ASSET_READ_SALE_EDIT_BTN = `販売編集`;
  readonly AC_ASSET_READ_TRADING_HISTORY = this.COMMON_ASSET_TRADING_HISTORY;
  readonly AC_ASSET_READ_TRADING_HISTORY_EVENT = this.COMMON_ASSET_TRADING_HISTORY_EVENT;
  readonly AC_ASSET_READ_TRADING_HISTORY_PRICE = this.COMMON_ASSET_TRADING_HISTORY_PRICE;
  readonly AC_ASSET_READ_TRADING_HISTORY_FROM = this.COMMON_ASSET_TRADING_HISTORY_FROM;
  readonly AC_ASSET_READ_TRADING_HISTORY_TO = this.COMMON_ASSET_TRADING_HISTORY_TO;
  readonly AC_ASSET_READ_TRADING_HISTORY_DATE = this.COMMON_ASSET_TRADING_HISTORY_DATE;
  readonly AC_ASSET_READ_TRADING_HISTORY_NO_DATA = this.COMMON_ASSET_TRADING_HISTORY_NO_DATA;
  readonly DLG_EXCEED_COLLECTION_LIMIT = `コレクションの作成数を超えました。\n最大5個まで作成できます。`;
  readonly DLG_EXCEED_SCHEMA_LIMIT = `スキーマ作成数を超えました。\n最大100個まで作成できます。`;
  readonly DLG_SELL_TITLE = `アセットを販売する`;
  readonly DLG_SELL_TITLE_EDIT = `販売を編集する`;
  readonly DLG_SELL_PRICE = `販売価格`;
  readonly DLG_SELL_MINIMUM_LIMIT = `10{화폐단위}以上である必要があります。`;
  readonly DLG_SELL_FEE = `手数料`;
  readonly DLG_SELL_ROYALTY = `コレクションロイヤリティ`;
  readonly DLG_SELL_PLATFORM_FEE = `プラットフォーム手数料`;
  readonly DLG_SELL_CALC_PRICE = `予想販売収益`;
  readonly DLG_SELL_OK_BTN = `販売する`;
  readonly DLG_SELL_KLAY_ADDRESS_REG_GUIDE = `KLAYの販売代金とリワードを受け取るためのアドレスを登録してください。`;
  readonly DLG_SELL_KLAY_ADDRESS = `販売代金を受け取るアドレス`;
  readonly DLG_SELL_KLAY_ADDRESS_REWARD = `リワードを受け取るアドレス`;
  readonly DLG_SELL_KLAY_ADDRESS_REWARD_GUIDE = `リワードCBLTを受け取るアドレスをマイページから登録してください。`;
  readonly DLG_SELL_STOP_BTN = `販売中止`;
  readonly DLG_SELL_AGREE_POLICY_TITLE = `以下の注意事項を確認し、守ることに同意します。`;
  readonly DLG_SELL_AGREE_POLICY_TEXT = `• 購入者（購入者から転売を受けた者を含む）に限り自身のローカル上のパソコンやその他記憶デバイス等へ商品をダウンロードし保存することができます。\n• 購入者が商品を非商用目的で利用すること（例：自身が着用する目的で商品をTシャツにプリントアウトする。）を許可します。\n• 当社は、本サービスの円滑な提供、システムの構築・改良・メンテナンスおよび本サービスの広告・宣伝等に必要な範囲で使用することができます、またその場合、商品データの一部を省略・リサイズ・編集し、クリエイター名表示を省略することがあります。\n• 他のNFTマーケットにおいて商品の複製物または類似複製物を出品しません。\n• お客様は、本サービスの利用に基づき税金が発生する場合、自らの責任と費用負担によりこれに対応するものとします。当社は、本サービスの利用によりお客様に発生する課税義務に対して、一切の責任を負いません。`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_SELL = `Kaikasウォレットを連携することでアドレス登録ができます。\nPCのウェブブラウザからウォレットを連携してください。`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_BUY = `Kaikasウォレットを連携することで購入することができます。\nPCのウェブブラウザから購入してください。`;
    readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TITLE = `アセットを発行しています。\n発行まで1~3分ほどかかります。`;
  readonly DLG_ASYNC_ASSET_CREATE_DLG_REQ_TXT = `(発行が完了したらリストに表示されます。)`;
  readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TITLE = `コレクションを作成しています。\n作成まで1~3分ほどかかります。`;
  readonly DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TXT = `(失敗するとリストから消えます。)`;
  readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TITLE = `追加発行を行います。\n発行まで1~3分ほどかかります。`;
  readonly DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TXT = `(失敗するとリストから消えます。)`;
  readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TITLE = `コレクションを編集しています。`;
  readonly DLG_ASYNC_COLLECTION_EDIT_DLG_REQ_TXT = `(失敗すると編集前に戻ります。)`;
  readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TITLE = `スキーマを作成しています。\n作成まで1~3分ほどかかります。`;
  readonly DLG_ASYNC_SCHEMA_CREATE_DLG_REQ_TXT = `(失敗するとリストから消えます。)`;
  readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TITLE = `スキーマを編集しています。\n完了まで1分ほどかかります。`;
  readonly DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TXT = `(失敗すると編集前に戻ります。)`;
  readonly AC_BLOCKED_TITLE = `アセットの発行は初めてですか？`;
  readonly AC_BLOCKED_GUIDE_ROW_1 = `アセットはCobaltの承認を得たクリエイターのみ発行できます。Cobaltの承認を得るためには身元確認(KYC)と審査が必要です。`;
  readonly AC_BLOCKED_GUIDE_ROW_2 = `以下の「お問い合わせ＞クリエイターの申し出」までご連絡いただければ、メールにてご案内いたします。`;
  readonly AC_BLOCKED_GUIDE_ROW_2_HIGHLIGHT = `「お問い合わせ＞クリエイターの申し出」`;
  readonly AC_BLOCKED_BTN = `お問い合わせ`;

  // ------------------------------------------------
  // Market place
  // ------------------------------------------------
  readonly MARKET_LIST_SEARCH_PLACEHOLDER = `アセット検索`;
  readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_NEWEST = this.COMMON_SEARCH_ORDERBY_LISTING_NEWEST;
  readonly MARKET_LIST_SEARCH_ORDERBY_LISTING_OLDEST = this.COMMON_SEARCH_ORDERBY_LISTING_OLDEST;
  readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_HIGHEST = this.COMMON_SEARCH_ORDERBY_PRICE_HIGHEST;
  readonly MARKET_LIST_SEARCH_ORDERBY_PRICE_LOWEST = this.COMMON_SEARCH_ORDERBY_PRICE_LOWEST;
  readonly MARKET_LIST_SEARCH_FILTER_COLLECTION = `コレクション`;
  readonly MARKET_LIST_SEARCH_FILTER_CURRENCY = `販売通貨`;
  readonly ASSET_CARD_BUY_BTN = this.COMMON_BUY_BTN;
  readonly MARKET_ASSET_READ_BACK = this.COMMON_BACK;
  readonly MARKET_ASSET_READ_OWNER = this.COMMON_SELLER;
  readonly MARKET_ASSET_READ_CREATOR = `クリエイター`;
  readonly MARKET_ASSET_READ_SOLD = `販売が完了しました。`;
  readonly MARKET_ASSET_READ_BUYER = this.COMMON_BUYER;
  readonly MARKET_ASSET_READ_BUY_BTN = this.COMMON_BUY_BTN;
  readonly MARKET_ASSET_READ_ASSET_ID = `アセットID`;
  readonly MARKET_ASSET_READ_SALE_ID = `販売ID`;
  readonly MARKET_ASSET_READ_ASSET_TYPE = this.COMMON_ASSET_TYPE;
  readonly MARKET_ASSET_READ_MINT_CNT = this.COMMON_ASSET_MINT_CNT;
  readonly MARKET_ASSET_READ_MINT_NO_MORE = this.COMMON_ASSET_MINT_NO_MORE;
  readonly MARKET_ASSET_READ_MINT_MORE = this.COMMON_ASSET_MINT_MORE;
  readonly MARKET_ASSET_READ_PRICE_HISTORY = `取引価格履歴`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_SUB = `取引価格履歴は当該アセットと同じ方法で発行されたすべてのアセット販売に基づきます。`;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_LOWEST = this.COMMON_PRICE_HISTORY_LOWEST;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_HIGHEST = this.COMMON_PRICE_HISTORY_HIGHEST;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TX_CNT = this.COMMON_PRICE_HISTORY_TX_CNT;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_CNT = `取引件数 : `;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_TOOLTIP_AVGPRC = `平均価格 : `;
  readonly MARKET_ASSET_READ_PRICE_HISTORY_NO_DATA = `まだ取引価格履歴がありません。`;
  readonly MARKET_ASSET_READ_TRADING_HISTORY = this.COMMON_ASSET_TRADING_HISTORY;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT = this.COMMON_ASSET_TRADING_HISTORY_EVENT;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_PRICE = this.COMMON_ASSET_TRADING_HISTORY_PRICE;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_FROM = this.COMMON_ASSET_TRADING_HISTORY_FROM;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_TO = this.COMMON_ASSET_TRADING_HISTORY_TO;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_DATE = this.COMMON_ASSET_TRADING_HISTORY_DATE;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_NO_DATA = this.COMMON_ASSET_TRADING_HISTORY_NO_DATA;
  readonly MARKET_ASSET_READ_TRADING_HISTORY_EVENT_SALE = `販売`;
  readonly MARKET_COPY_LINK_BTN=`Copy Link`;
  readonly MARKET_SHARE_TWITTER_BTN=`Twitter`;
  readonly DLG_BUY_TITLE = `アセットを購入する`;
  readonly DLG_BUY_COLLECTION = this.COMMON_COLLECTION;
  readonly DLG_BUY_ASSET_NAME = this.COMMON_ASSET_NAME;
  readonly DLG_BUY_MINT_CNT = this.COMMON_ASSET_MINT_CNT;
  readonly DLG_BUY_SCHEMA = this.COMMON_SCHEMA;
  readonly DLG_BUY_SELLER = this.COMMON_SELLER;
  readonly DLG_BUY_PRICE = this.COMMON_PRICE;
  readonly DLG_BUY_BTN = this.COMMON_BUY_BTN;
  readonly MARKET_COLLECTION_READ_BACK = this.COMMON_BACK;
  readonly MARKET_COLLECTION_READ_COLLECTION = this.COMMON_COLLECTION;
  readonly MARKET_COLLECTION_READ_OWNER = this.COMMON_OWNER;
  readonly MARKET_COLLECTION_READ_COLLECTION_NAME = this.COMMON_COLLECTION_NAME;
  readonly MARKET_COLLECTION_READ_DISP_NAME = this.COMMON_DISP_NAME;
  readonly MARKET_COLLECTION_READ_ROYALTY = this.COMMON_ROYALTY;
  readonly MARKET_COLLECTION_READ_WEBSITE = this.COMMON_WEBSITE;
  readonly MARKET_COLLECTION_READ_DESC = this.COMMON_DESC;
  readonly MARKET_COLLECTION_READ_ADMIN = this.COMMON_ADMIN;
  readonly MARKET_COLLECTION_READ_ASSET_LIST_TITLE = `コレクションのアセット`;
  readonly MARKET_SCHEMA_READ_BACK = this.COMMON_BACK;
  readonly MARKET_SCHEMA_READ_ATTR_CNT = `の属性`;
  readonly MARKET_SCHEMA_READ_TITLE = this.COMMON_SCHEMA;
  readonly MARKET_SCHEMA_READ_ATTR_NAME = this.COMMON_ATTR_NAME;
  readonly MARKET_SCHEMA_READ_ATTR_TYPE = this.COMMON_ATTR_TYPE;
  readonly MARKET_SCHEMA_READ_ASSET_LIST_TITLE = `このスキーマを使用するアセット`;
  readonly MARKET_ACCOUNT_READ_VIEW_ON_BLOCKS = this.COMMON_VIEW_ON_BLOCKS;
  readonly MARKET_ACCOUNT_READ_MENU_INVENTORY = this.COMMON_MENU_INVENTORY;
  readonly MARKET_ACCOUNT_READ_MENU_CREATED = this.COMMON_MENU_MINTED;
  readonly MARKET_ACCOUNT_READ_MENU_ON_SALE = this.COMMON_MENU_ON_SALE;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD = this.COMMON_MENU_SOLD;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED = this.COMMON_MENU_PURCHASED;
  readonly MARKET_ACCOUNT_READ_SEARCH_PLACEHOLDER = this.COMMON_SEARCH_PLACEHOLDER;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_NEWEST = this.COMMON_SEARCH_ORDERBY_LISTING_NEWEST;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_OLDEST = this.COMMON_SEARCH_ORDERBY_LISTING_OLDEST;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_HIGHEST = this.COMMON_SEARCH_ORDERBY_PRICE_HIGHEST;
  readonly MARKET_ACCOUNT_READ_SEARCH_ORDERBY_PRICE_LOWEST = this.COMMON_SEARCH_ORDERBY_PRICE_LOWEST;
  readonly MARKET_ACCOUNT_READ_MENU_INVEN_CNT = `所持数{개수}`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_ASSET_ID = `アセットID`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_DATE = `販売日時`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_SELLER = this.COMMON_SELLER;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_BUYER = this.COMMON_BUYER;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_PRICE = `販売価格`;
  readonly MARKET_ACCOUNT_READ_MENU_SOLD_TX_HASH = this.COMMON_TX_HASH;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_ASSET_ID = `アセットID`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_DATE = `購入日時`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_SELLER = this.COMMON_SELLER;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_BUYER = this.COMMON_BUYER;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_PRICE = `購入価格`;
  readonly MARKET_ACCOUNT_READ_MENU_PURCHASED_TX_HASH = this.COMMON_TX_HASH;
  readonly MARKET_REPORT_BTN = this.COMMON_REPORT;
  readonly MARKET_DLG_REPORT_TITLE = `報告理由を選択してください。`;
  readonly MARKET_DLG_REPORT_CASE_1 = `性的なコンテンツのアセット`;
  readonly MARKET_DLG_REPORT_CASE_2 = `暴力的または嫌悪感のあるアセット`;
  readonly MARKET_DLG_REPORT_CASE_3 = `憎悪または虐待のアセット`;
  readonly MARKET_DLG_REPORT_CASE_4 = `権利を侵害するアセット`;
  readonly MARKET_DLG_REPORT_CASE_5 = `その他`;
  readonly MARKET_DLG_REPORT_BTN = this.COMMON_REPORT;
  readonly MARKET_DLG_REPORT_SUCCESS = `報告が完了しました。`;
  readonly MARKET_ASSET_CARD_BLOCK = `当アセットは報告回数が多いため\n非公開としてロックされました。`;
  readonly MARKET_DLG_ERROR_ALREADY_OWNER = `所有しているアセットです。`;
  readonly DLG_WILL_MOVE_PAYMENTPAGE_TITLE = `決済ページに移動します。`;
  readonly DLG_WILL_MOVE_PAYMENTPAGE_TEXT = `少しお待ちください。`;
  readonly MARKET_PAYMENT_TITLE = `決済する`;
  readonly MARKET_PAYMENT_ASSET = `購入アセット`;
  readonly MARKET_PAYMENT_POINT = `POINT決済`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP = `POINT決済とは？`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT1 = `USDでPOINTをチャージし、決済に使う方式`;
  readonly MARKET_PAYMENT_POINT_TOOLTIP_TXT2 = `1 USD = 1 POINT`;
  readonly MARKET_PAYMENT_MY_POINT_BALANCE = `保有POINT`;
  readonly MARKET_PAYMENT_LACK_POINT_AMOUNT = `不足POINT`;
  readonly MARKET_PAYMENT_CHARGE_POINT = `POINTチャージ`;
  readonly MARKET_PAYMENT_CHARGE_POINT_GUIDE = `足りないPOINTをチャージし、決済してください。`;
  readonly MARKET_PAYMENT_PRICE = `決済金額`;
  readonly MARKET_PAYMENT_AGREE_ALL = `すべて同意する`;
  readonly MARKET_PAYMENT_AGREE_TERMS_OF_USE = `利用規約を確認し、同意します。`;
  readonly MARKET_PAYMENT_AGREE_REFUND_POLICY = `返金ポリシーを確認し、同意します。`;
  readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY = `プライバシーポリシーを確認し、同意します。`;
  readonly MARKET_PAYMENT_AGREE_TERM_OF_USE_LINK = `利用規約を見る`;
  readonly MARKET_PAYMENT_AGREE_REFUND_POLICY_LINK = `返金ポリシーを見る`;
  readonly MARKET_PAYMENT_AGREE_PRIVACY_POLICY_LINK = `プライバシーポリシーを見る`;
  readonly MARKET_PAYMENT_BTN = `決済する`;
  readonly DLG_CHARGE_LIST_BTN = `チャージする`;
  readonly DLG_CHARGE_LIST_TITLE = `POINTをチャージする。`;
  readonly DLG_CHARGE_FEE_DISCOUNT = `手数料{할인율}割引`;
  readonly DLG_CHARGE_LIST_MY_POINT_BALANCE = `POINT 残高`;
  readonly DLG_CHARGE_LIST_TERMS = this.FOOTER_TERMS;
  readonly DLG_CHARGE_LIST_REFUND_POLICY = this.FOOTER_REFUND_POLICY;
  readonly DLG_CHARGE_LIST_PRIVACY = this.FOOTER_PRIVACY;
  readonly DLG_CHARGE_LIST_RAW1 = this.FOOTER_RAW1;
  readonly DLG_CHARGE_LIST_RAW2 = this.FOOTER_RAW2;
  readonly DLG_RUNNING_SMART_CONTRACT=  `ウォレットから取引を行ってください。`;
  readonly DLG_BUY_SUCCESS_TITLE=  `決済が完了しました。`;
  readonly DLG_BUY_SUCCESS_TEXT=  `マイページのインベントリから確認できます。`;
  readonly DLG_BUY_FAILURE_TITLE=  `決済に失敗しました。`;
  readonly DLG_BUY_FAILURE_TEXT=  `再試行してください。`;
  readonly DLG_RUNNING_BUY_PROCESS_TITLE=  `決済中です。`;
  readonly DLG_RUNNING_BUY_PROCESS_TEXT=  `少しお待ちください。`;
  readonly DLG_CHARGE_SUCC = `POINTをチャージしました。`;
  readonly DLG_CHARGE_FAIL = `POINTチャージに失敗しました。`;

  // ------------------------------------------------
  // Mypage
  // ------------------------------------------------
  readonly MY_VIEW_ON_BLOCKS = this.COMMON_VIEW_ON_BLOCKS;
  readonly MY_MY_BALANCE_TITLE = `残高`;
  readonly MY_MY_BALANCE_BTN = `内訳を見る`;
  readonly MY_KLAY_ADDRESS_TITLE = `ウォレットアドレス`;
  readonly MY_KLAY_ADDRESS_TITLE_TOOLTIP = `KLAYの販売代金とリワードCBLTを受け取るためのアドレス`;
  readonly MY_KLAY_ADDRESS_INPUT_PLACEHOLDER = `KLAYの販売代金とリワードCBLTを受け取るためのアドレスを登録してください。`;
  readonly MY_KLAY_ADDRESS_INPUT_REG_BTN = `登録する`;
  readonly MY_KLAY_ADDRESS_INPUT_CHANGE_BTN = `変更する`;
  readonly MY_KLAY_ADDRESS_INPUT_DONE_BTN = `確認`;
  readonly MY_KLAY_ADDRESS_INPUT_INVALID = `アドレス形式が正しくありません。`;
  readonly MY_KLAY_ADDRESS_INPUT_TOOLTIP = `KLAYウォレット以外のアドレスを登録したり、入力ミスがある場合、販売代金及びリワードが消失する可能性がありますのでご注意ください。`;
  readonly MY_BALANCE_KLAY_LABEL = `KLAY 残高`;
  readonly MY_BALANCE_CBLT_LABEL = `CBLT 残高`;
  readonly MY_BALANCE_CUSD_LABEL = `USD 残高`;
  readonly MY_BALANCE_CPOINT_LABEL = `POINT 残高`;
  readonly MY_BALANCE_CPOINT_CHARGE = `チャージ`;
  readonly MY_BALANCE_CUSD_WITHDRAWAL = `お引出し`;
  readonly MY_BALANCE_CBLT_TOOLTIP = `エコシステムへの貢献度によるリワードトークンです。`;
  readonly MY_MENU_INVENTORY = this.COMMON_MENU_INVENTORY;
  readonly MY_MENU_CREATED = this.COMMON_MENU_MINTED;
  readonly MY_MENU_FOR_SALE = this.COMMON_MENU_ON_SALE;
  readonly MY_MENU_SOLD = this.COMMON_MENU_SOLD;
  readonly MY_MENU_PURCHASED = this.COMMON_MENU_PURCHASED;
  readonly MY_MENU_FAVORITE = `お気に入り`;
  readonly MY_MENU_MYACCOUNT = `マイアカウント`;
  readonly MY_SEARCH_PLACEHOLDER = this.COMMON_SEARCH_PLACEHOLDER;
  readonly MY_SEARCH_ORDERBY_LISTING_NEWEST = this.COMMON_SEARCH_ORDERBY_LISTING_NEWEST;
  readonly MY_SEARCH_ORDERBY_LISTING_OLDEST = this.COMMON_SEARCH_ORDERBY_LISTING_OLDEST;
  readonly MY_SEARCH_ORDERBY_PRICE_HIGHEST = this.COMMON_SEARCH_ORDERBY_PRICE_HIGHEST;
  readonly MY_SEARCH_ORDERBY_PRICE_LOWEST = this.COMMON_SEARCH_ORDERBY_PRICE_LOWEST;
  readonly MY_MENU_INVENTORY_STOP_BTN = `販売中止`;
  readonly MY_MENU_INVENTORY_NO_DATA = `まだインベントリがありません。`;
  readonly MY_MENU_ISSUE_NO_DATA = `まだ発行されたアセットがありません。`;
  readonly MY_MENU_SALE_NO_DATA = `まだ販売中のアセットがありません。`;
  readonly MY_MENU_SOLD_NO_DATA = `まだ販売されたアセットがありません。`;
  readonly MY_MENU_BOUGHT_NO_DATA = `まだ購入したアセットがありません。`;
  readonly MY_MENU_FAVORITE_NO_DATA = `まだお気に入り登録されたアセットがありません。`;
  readonly MY_MENU_SEARCH_NOT_FOUND = `検索結果がありません。`;
  readonly MY_MENU_ON_SALE_EDIT_BTN = `販売編集`;
  readonly MY_MENU_SOLD_ASSET_ID = `アセットID`;
  readonly MY_MENU_SOLD_DATE = `販売日時`;
  readonly MY_MENU_SOLD_SELLER = this.COMMON_SELLER;
  readonly MY_MENU_SOLD_BUYER = this.COMMON_BUYER;
  readonly MY_MENU_SOLD_PRICE = `販売価格`;
  readonly MY_MENU_SOLD_ROYALTY = `コレクションロイヤリティ`;
  readonly MY_MENU_SOLD_PLATFORM_FEE = `プラットフォーム手数料`;
  readonly MY_MENU_SOLD_PROFIT = `収益`;
  readonly MY_MENU_SOLD_REWARD = `リワード`;
  readonly MY_MENU_SOLD_REWARD_TOOLTIP = `販売に寄与して配布されたトークン`;
  readonly MY_MENU_SOLD_TX_HASH = this.COMMON_TX_HASH;
  readonly MY_MENU_PURCHASED_ASSET_ID = `アセットID`;
  readonly MY_MENU_PURCHASED_DATE = `購入日時`;
  readonly MY_MENU_PURCHASED_SELLER = this.COMMON_SELLER;
  readonly MY_MENU_PURCHASED_BUYER = this.COMMON_BUYER;
  readonly MY_MENU_PURCHASED_PRICE = `購入価格`;
  readonly MY_MENU_PURCHASED_REWARD = `リワード`;
  readonly MY_MENU_PURCHASED_REWARD_TOOLTIP = `購入貢献で配布されたトークン`;
  readonly MY_MENU_PURCHASED_TX_HASH = this.COMMON_TX_HASH;
  readonly MY_MENU_MYACCOUNT_SETTINGS = `アカウント管理`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_EMAIL = `メールアドレス`;
  readonly MY_MENU_MYACCOUNT_EMAIL_AUTH_COMPLETE = `認証完了`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_ID = `ID`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD = `パスワード`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_PASSWORD_GUIDE = `アカウント保護のため定期的に変更することをおすすめします。`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_FISH_SECURE_CODE = `フィッシング対策コード`;
  readonly MY_MENU_MYACCOUNT_SETTINGS_CHANGE_BTN = `変更する`;
  readonly MY_PWCHANGE_TITLE = `パスワード変更`;
  readonly MY_PWCHANGE_CUR_PASSWORD = `現在のパスワード`;
  readonly MY_PWCHANGE_CUR_PASSWORD_PLACEHOLDER = `現在のパスワード入力`;
  readonly MY_PWCHANGE_CUR_PASSWORD_WRONG = `現在のパスワードが一致していません。`;
  readonly MY_PWCHANGE_NEW_PASSWORD = `新しいパスワード`;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_1 = this.COMMON_PASSWORD_GUIDE_1;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_2 = this.COMMON_PASSWORD_GUIDE_2;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_3 = this.COMMON_PASSWORD_GUIDE_3;
  readonly MY_PWCHANGE_PASSWORD_GUIDE_4 = this.COMMON_PASSWORD_GUIDE_4;
  readonly MY_PWCHANGE_NEW_PASSWORD_PLACEHOLDER = `新しいパスワード入力`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM = `新しいパスワード確認`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_PLACEHOLDER = `新しいパスワード確認`;
  readonly MY_PWCHANGE_NEW_PASSWORD_CONFIRM_NO_MATCH = `パスワードが一致していません。`;
  readonly MY_PWCHANGE_OK_BTN = `確認`;
  readonly MY_PWCHANGE_OK_MSG_1 = `パスワードが変更されました。`;
  readonly MY_PWCHANGE_OK_MSG_2 = `10秒後、または【確認】ボタンを押すとマイページに移動します。`;
  readonly MY_FISH_SECURE_CODE_CHANGE_TITLE = `フィッシング対策コード変更`;
  readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD = `パスワード確認`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEXT_PLACEHOLDE = `パスワード入力`;
  readonly MY_FISH_SECURE_CODE_CHANGE_PASSWORD_WRONG = `現在のパスワードが一致していません。`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEXT = `次へ`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEW = `新しいコード`;
  readonly MY_FISH_SECURE_CODE_CHANGE_NEW_PLACEHOLDER = `新しいコード入力`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_BTN = `確認`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_1 = `入力したコードに変更されました。`;
  readonly MY_FISH_SECURE_CODE_CHANGE_OK_MSG_2 = `10秒後、または【確認】ボタンを押すとマイページに移動します。`;
  readonly MY_PAYOUT_DLG_TITLE = `USDお引き出し`;
  readonly MY_PAYOUT_DLG_USD_BALANCE = `USD残高`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT = `引き出す金額`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_SUB = `(最低 100 USD)`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_ALL_BTN = `全額`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_MINIMUM = `100USD以上のみ可能です`;
  readonly MY_PAYOUT_DLG_SECTION_AMOUNT_INSUFFICIENT = `USD残高が足りません`;
  readonly MY_PAYOUT_DLG_SECTION_ACCOUNT = `引き出す口座`;
  readonly MY_PAYOUT_DLG_SECTION_ACCOUNT_AUTH_DONE = `口座確認完了`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE = `プラットフォーム手数料`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_SUB = `(USD/CBLTの中で選択)`;
  readonly MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_KAIKAS = `CBLTで支払うためにはウォレットを連携してください。`;
  readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE = `振替手数料`;
  readonly MY_PAYOUT_DLG_SECTION_PAYPAL_FEE_LINK = `PayPal手数料政策を見る`;
  readonly MY_PAYOUT_DLG_SECTION_GUIDE = `申し込み案内`;
  readonly MY_PAYOUT_DLG_SECTION_GUIDE_DETAIL = `＊USDを引き出すためにはPayPal口座の確認が必要です。確認手続きについてメールにてご案内いたしますので、入力したPayPalメールのご確認をお願いします。\n＊口座の確認が完了したら手数料を引いた金額をお送りします。`;
  readonly MY_PAYOUT_DLG_APPLY_BTN = `申し込む`;
  readonly MY_PAYPAL_DLG_TITLE = `口座情報`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL = `PayPalメールアドレス`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL_PLACEHOLDER = `メールアドレス入力`;
  readonly MY_PAYPAL_DLG_SECTION_EMAIL_ALREADY = `口座の確認が完了されたメールアドレスです。`;
  readonly MY_PAYPAL_DLG_SECTION_AUTH_CODE = `メールアドレスの認証`;
  readonly MY_CBLT_FEE_DLG_TITLE = `手数料の支払い`;
  readonly MY_CBLT_FEE_DLG_SUB_TITLE = `Kaikasウォレットで５CBLTを支払ってください`;
  readonly MY_CBLT_FEE_DLG_SUB_DESC = `USDの引き出しに失敗すると支払ったCBLTは払い戻されます。`;
  readonly MY_CBLT_FEE_DLG_BTN = `支払う`;
  readonly MY_PAYOUT_DLG_APPLY_DONE = `USDの引き出し申し込みを受付しました。\n口座確認のため、入力したPayPalメールをご確認ください。`;
  readonly MY_PAYOUT_DLG_APPLY_DONE_2 = `USDの引き出しが受付されました。`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY = `CBLTでの支払いはKaikasウォレットの連携が必要です。\nPCウェブサイトを利用してウォレットを連携してください。`;
  readonly DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY_HIGHLIGH = `PCウェブサイト`;
  readonly DLG_PAYOUT_ID_CHANGE_TITLE = `認証済みの口座があります。\n変更には改めて認証手続きが必要です。\n変更しますか？`;
  readonly DLG_PAYOUT_ID_CHANGE_CHANGE_BTN = `変更する`;
  readonly DLG_PAYOUT_ID_CHANGE_CHANGE_CLOSE = `閉じる`;

  // ------------------------------------------------
  // Join
  // ------------------------------------------------
  readonly JOIN_TITLE = `会員登録`;
  readonly JOIN_EMAIL = this.COMMON_EMAIL;
  readonly JOIN_EMAIL_PLACEHOLDER = this.COMMON_EMAIL_PLACEHOLDER;
  readonly JOIN_EMAIL_INVALID = this.COMMON_EMAIL_INVALID;
  readonly JOIN_EMAIL_DUPLICATED = `すでに登録されているメールアドレスです。`;
  readonly JOIN_EMAIL_AUTH = `メールアドレスを認証する`;
  readonly JOIN_EMAIL_AUTH_PLACEHOLDER = `認証コード入力`;
  readonly JOIN_EMAIL_AUTH_SEND = `認証メールを送る`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_1 = `認証メールを送信しました。`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE1_2 = `受信メールから認証コードをご確認いただき、コードを入力して認証するボタンを押してください。`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_1 = `※認証メールの有効期限は発行から24時間です。再発行する場合、すでに発行された認証番号は無効になります。`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_2 = `必ず最後に受信したメールをご確認ください。`;
  readonly JOIN_EMAIL_AUTH_SEND_GUIDE2_3 = `メールが届かない場合、迷惑メールボックスに振り分けられていないかご確認ください。`;
  readonly JOIN_EMAIL_AUTH_RESEND = `認証メールを再送する`;
  readonly JOIN_EMAIL_AUTH_BTN = `認証する`;
  readonly JOIN_EMAIL_AUTH_SUCC = `認証完了`;
  readonly JOIN_EMAIL_AUTH_INVALID = `認証コードが正しくありません。`;
  readonly JOIN_PASSWORD = this.COMMON_PASSWORD;
  readonly JOIN_PASSWORD_PLACEHOLDER = this.COMMON_PASSWORD_PLACEHOLDER;
  readonly JOIN_PASSWORD_GUIDE_1 = this.COMMON_PASSWORD_GUIDE_1;
  readonly JOIN_PASSWORD_GUIDE_2 = this.COMMON_PASSWORD_GUIDE_2;
  readonly JOIN_PASSWORD_GUIDE_3 = this.COMMON_PASSWORD_GUIDE_3;
  readonly JOIN_PASSWORD_GUIDE_4 = this.COMMON_PASSWORD_GUIDE_4;
  readonly JOIN_PASSWORD_CONFIRM = `パスワード確認`;
  readonly JOIN_PASSWORD_CONFIRM_PLACEHOLDER = `パスワード確認`;
  readonly JOIN_PASSWORD_CONFIRM_INVALID = `パスワードが一致していません。`;
  readonly JOIN_FISH_SECURE_CODE = `フィッシング対策コード`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_1 = `※フィッシング対策コードとは`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_2 = `Cobaltを名乗るメールアカウントからのフィッシングなど、セキュリティ対策として自分が確認できるフィッシング対策コードを設定してください。`;
  readonly JOIN_FISH_SECURE_CODE_GUIDE_3 = `Cobaltから正常に送られたすべてのメールには必ずフィッシング対策コードが含まれています。`;
  readonly JOIN_FISH_SECURE_CODE_PLACEHOLDER = `コード入力`;
  readonly JOIN_AGE_OVER_20 = `満20歳以上です。`;
  readonly JOIN_USAGE_TERM = `利用規約を確認し、同意します。`;
  readonly JOIN_USAGE_TERM_BTN = `利用規約を確認する`;
  readonly JOIN_BTN = `会員登録`;

  // ------------------------------------------------
  // Login
  // ------------------------------------------------
  readonly LOGIN_TITLE = `ログイン`;
  readonly LOGIN_SECURE_GUIDE = `URLが正しいかご確認ください。`;
  readonly LOGIN_EMAIL = this.COMMON_EMAIL;
  readonly LOGIN_EMAIL_PLACEHOLDER = this.COMMON_EMAIL_PLACEHOLDER;
  readonly LOGIN_FAIL_MSG = `メールアドレスまたはパスワードが正しくありません。`;
  readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_1 = `過剰なログイン試行回数が原因でアカウントがロックされました。`;
  readonly LOGIN_TOO_MANY_INCORRECT_TRY_MSG_2 = `パスワードを再設定して解決できます。`;
  readonly LOGIN_PASSWORD = this.COMMON_PASSWORD;
  readonly LOGIN_PASSWORD_PLACEHOLDER = this.COMMON_PASSWORD_PLACEHOLDER;
  readonly LOGIN_BTN = `ログイン`;
  readonly LOGIN_FIND_PASSWORD = `パスワード再設定`;
  readonly LOGIN_JOIN = `会員登録`;
  readonly LOGIN_RESET_PASSWORD = `パスワード再設定`;
  readonly LOGIN_RESET_EMAIL = `メールアドレス`;
  readonly LOGIN_RESET_EMAIL_PLACEHOLDER = `メールアドレス入力`;
  readonly LOGIN_RESET_OK_BTN = `確認`;
  readonly LOGIN_RESET_OK_MSG_1 = `入力したメールアドレスに仮パスワードをお送りしました。`;
  readonly LOGIN_RESET_OK_MSG_2 = `10秒後、または【確認】ボタンを押すとログイン画面に移動します。`;

  // ------------------------------------------------
  // UserBan
  // ------------------------------------------------
  readonly USERBAN_TITLE = `アカウントがロックされました。`;
  readonly USERBAN_DESC = `運営ポリシーを繰り返し違反したり、重大な違反行為が見つかった場合、アカウントの利用が停止されます。\n小さな違反でも回数が多い場合、ロックされることがあります。`;
  readonly USERBAN_REASON_TITLE = `アカウントロックの理由`;
  readonly USERBAN_REASON_DESC = `アカウントは以下の理由でロックされることがあります。`;
  readonly USERBAN_REASON_1 = `・運営方針違反`;
  readonly USERBAN_REASON_1_DESC = `サービスの利用規約及び運営方針に従わなかった場合`;
  readonly USERBAN_REASON_2 = `・報告の累積回数が一定数を超過`;
  readonly USERBAN_REASON_2_DESC = `報告事由に当たるアセットを発行し、数回報告された場合`;
  readonly USERBAN_REASON_3 = `・アカウントのハッキング防止`;
  readonly USERBAN_REASON_3_DESC = `他のユーザーがアカウントにログインした場合`;
  readonly USERBAN_REASON_4 = `・なりすまし`;
  readonly USERBAN_REASON_4_DESC = `個人や組織の名前を盗用しアカウントを作った場合`;
  readonly USERBAN_REASON_5 = `・悪用の目的で複数のアカウントを利用`;
  readonly USERBAN_REASON_5_DESC = `ポリシーを違反する目的で複数のアカウントを作ったり、使用した場合`;
  readonly USERBAN_REASON_GUIDE = `このような理由からCobaltでは最善の判断を適用し、ユーザーアカウントを停止することができます。\nその他のお問い合わせがある場合、{お問い合わせ}からご連絡ください。`;

  // ------------------------------------------------
  // Wallet
  // ------------------------------------------------
  readonly WALLET_REWARD_ADDR_CHAGNE_TITLE = `今連携されているウォレットのアドレスと\nすでに登録されているアドレスが違います。\n今連携されたアドレスに更新しますか？`;
  readonly WALLET_REWARD_ADDR_CHAGNE_DESC = `登録されているアドレスに販売代金やリワードを受け取ることができます。`;
  readonly WALLET_REWARD_ADDR_CHAGNE_BTN = `変更する`;
  readonly WALLET_REWARD_ADDR_CLOSE_BTN = `閉じる`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE = `KLAYのウォレットアドレスが変更されました。`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE_DESC = `(登録アドレスはマイページから確認できます。）`;
  readonly WALLET_REWARD_ADDR_CHANGE_DONE_OK = this.COMMON_OK_BTN_TXT;
  readonly WALLET_CONNECT = `ウォレットを連携する`;
  readonly WALLET_CONNECT_BTN = `連携する`;
  readonly WALLET_CYPRESS_NOT_AVAILABLE_IN_ALPHA = `Cypress: Klaytn Mainnetに接続できません。`;
  readonly WALLET_BAOBAB_NOT_AVAILABLE_IN_LIVE = `Baobab: Klaytn Testnetに接続できません。`;

  // ------------------------------------------------
  // 거래내역
  // ------------------------------------------------
  readonly PROP_HISTORY_TITLE = `内訳`;
  readonly PROP_HISTORY_POINT_INCOME = `チャージされたPOINTの合計`;
  readonly PROP_HISTORY_POINT_SPEND = `使用されたPOINTの合計`;
  readonly PROP_HISTORY_POINT_BALANCE = `POINT残高`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_ALL = `すべて`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_INCOME = `チャージ内訳`;
  readonly PROP_HISTORY_POINT_QUERY_TYPE_SPEND = `使用内訳`;
  readonly PROP_HISTORY_POINT_TYPE_INCOME = `チャージ`;
  readonly PROP_HISTORY_POINT_TYPE_SPEND = `使用`;
  readonly PROP_HISTORY_USD_INCOME = `受取ったUSDの合計`;
  readonly PROP_HISTORY_USD_SPEND = `渡したUSDの合計`;
  readonly PROP_HISTORY_USD_BALANCE = `USD残高`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_ALL = `すべて`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_INCOME = `受取内訳`;
  readonly PROP_HISTORY_USD_QUERY_TYPE_SPEND = `使用内訳`;
  readonly PROP_HISTORY_USD_TYPE_INCOME = `受取`;
  readonly PROP_HISTORY_USD_TYPE_SPEND = `送信`;
  readonly PROP_HISTORY_KLAY_INCOME = `受取ったKLAYの合計`;
  readonly PROP_HISTORY_KLAY_SPEND = `使用されたKLAYの合計`;
  readonly PROP_HISTORY_KLAY_BALANCE = `KLAY残高`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_ALL = `すべて`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_INCOME = `受取内訳`;
  readonly PROP_HISTORY_KLAY_QUERY_TYPE_SPEND = `使用内訳`;
  readonly PROP_HISTORY_KLAY_TYPE_INCOME = `受取`;
  readonly PROP_HISTORY_KLAY_TYPE_SPEND = `使用`;
  readonly PROP_HISTORY_CBLT_INCOME = `受取ったCBLTの合計`;
  readonly PROP_HISTORY_CBLT_SPEND = `使用されたCBLTの合計`;
  readonly PROP_HISTORY_CBLT_BALANCE = `CBLT残高`;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_ALL = `すべて`;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_INCOME = `受取内訳`;
  readonly PROP_HISTORY_CBLT_QUERY_TYPE_SPEND = `使用内訳`;
  readonly PROP_HISTORY_CBLT_TYPE_INCOME = `受取`;
  readonly PROP_HISTORY_CBLT_TYPE_SPEND = `使用`;
  readonly PROP_HISTORY_SUCC = `成功`;
  readonly PROP_HISTORY_FAIL = `失敗`;
  readonly PROP_HISTORY_QUERY_BTN = `照会`;
  readonly PROP_HISTORY_EMPTY_DATA = `まだ内訳がありません。`;
  readonly PROP_HISTORY_QUERY_NOT_FONUD = `照会された履歴がありません。`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_101 = `アセット購入`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_102 = `Paymentwall Cash Out`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_103 = `cash out 出金手数料`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_201 = `アセット販売収益`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_202 = `創作者印税収益`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_203 = `アセット販売リワード`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_204 = `アセット購入リワード`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_205 = `アセット販売創作者リワード`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_301 = `POINTチャージ`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_302 = `AdminによるリワードPOINTチャージ`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_303 = `Adminテストチャージ`;
  readonly PROP_HISTORY_TOKENDATA_TYPE_304 = `POINTチャージ`;

  // ------------------------------------------------
  // 문의하기
  // ------------------------------------------------
  readonly INQUERY_TITLE = `お問い合わせ`;
  readonly INQUERY_TYPE = `お問い合わせのタイプ`;
  readonly INQUERY_TYPE_PROBLEM = `不具合申告(バグ及びユーザー申告)`;
  readonly INQUERY_TYPE_TASK = `クリエイターの申し出`;
  readonly INQUERY_TYPE_QUESTION = `質問`;
  readonly INQUERY_TYPE_INCIDENT = `返金について`;
  readonly INQUERY_NAME = `お名前`;
  readonly INQUERY_NAME_PLACEHOLDER = `お問い合わせする方のお名前`;
  readonly INQUERY_EMAIL = `メールアドレス`;
  readonly INQUERY_EMAIL_PLACEHOLDER = `回答を受信するメールアドレスを入力`;
  readonly INQUERY_SUBJECT = `お問い合わせの内容`;
  readonly INQUERY_SUBJECT_PLACEHOLDER = `お問い合わせのタイトル`;
  readonly INQUERY_BODY_PALCEHOLDER = `お問い合わせ内容を詳しくご記入ください。`;
  readonly INQUERY_BTN = `送信`;
  readonly INQUERY_SUCCESS = `お問い合わせを受け付けました。`;

  // ------------------------------------------------
  // 포인트 충전
  // ------------------------------------------------
  readonly DLG_CHARGE_TITLE = `決済する`;
  readonly DLG_CHARGE_UNIT_PRICE = `販売価格`;
  readonly DLG_CHARGE_FEE = `手数料`;
  readonly DLG_CHARGE_TOTAL_PRICE = `決済金額`;
  readonly DLG_CHARGE_FOOTER_TERMS = this.FOOTER_TERMS;
  readonly DLG_CHARGE_FOOTER_PRIVACY = this.FOOTER_PRIVACY;
  readonly DLG_CHARGE_FOOTER_REFUND_POLICY = this.FOOTER_REFUND_POLICY;

  // 기타
  readonly COMMON_UNIT_YEAR = `jp년`;
  readonly COMMON_UNIT_MONTH = `jp월`;
  readonly COMMON_UNIT_DAY = `jp일`;
  readonly COMMON_UNIT_AM = `jp오전`;
  readonly COMMON_UNIT_PM = `jp오후`;

}
