/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties, HTMLProps, useState } from 'react';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF } from 'src/ui/base_component/etc';
import { RoundTxtBtnAdv } from 'src/ui/common_component/button/RoundTxtBtnAdv';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  text?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  containerStyle?: CSSProperties;
  enable?: boolean;
  onClick: HTMLProps<HTMLDivElement>['onClick'];
  forceHighlight?: boolean;
};

export function RoundTxtBtn3({
  text = 'text',
  width,
  height,
  fontSize: fontsize,
  onClick,
  containerStyle,
  enable = true,
  forceHighlight = false,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseOver, setMouseOver] = useState(false);

  return (
    <RoundTxtBtnAdv
      label={text}
      width={width}
      height={height}
      fontSize={fontsize}
      enable={enable}
      isBold
      fontColor={(function () {
        if (forceHighlight) return R.colors.purpleishBlue;
        if (enable) {
          return sMouseOver ? R.colors.purpleishBlue : R.colors.black;
        } else {
          return R.colors.black;
        }
      })()}
      bgColor={'white'}
      borderColor={(function () {
        if (forceHighlight) return R.colors.purpleishBlue;
        if (enable) {
          return sMouseOver ? R.colors.purpleishBlue : R.colors.line;
        } else {
          return R.colors.line;
        }
      })()}
      onClick={
        enable
          ? (e) => {
              onClick && onClick(e);
            }
          : undefined
      }
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
      }}
      containerStyle={containerStyle}
    />
  );
}
