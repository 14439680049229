function dbgmsg(msg) {
  console.debug(msg);
}

// kaikas 이용 가능여부를 체크
export function existKlaytn() {
  if (typeof window.klaytn !== "undefined") {
    console.debug(`window.klaytn: ${window.klaytn}`);
    return true;
  } else {
    console.debug(`window.klaytn: ${window.klaytn}`);
    return false;
  }
}

export function existCaver() {
  if (typeof window.caver !== "undefined") {
    console.debug(`window.caver: ${window.caver}`);
    return true;
  } else {
    console.debug(`window.caver: ${window.caver}`);
    return false;
  }
}

export function getKlaytn() {
  if (!existKlaytn) return;
  return window.klaytn;
}

export function getCaver() {
  if (!existCaver) return;
  return window.caver;
}

// export function isKaikas() {
//   if (!existKlaytn) return;

//   const klaytn = getKlaytn();
//   console.debug(`klaytn.isKaikas:${klaytn.isKaikas}`);

//   return klaytn.isKaikas;
// }

export default dbgmsg;