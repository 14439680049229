import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { FlexColumnDiv, HEIGHT } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { DBGMSG } from 'src/util/utils';

export type AsyncGuideJsxProps = {
  title?: string;
  text?: string;
  onOk?: () => void;
};

// Desktop
const DT_DLG_WIDTH = 380;
const DT_DLG_MIN_HEIGHT = 232;
const DT_PADDING = 40;
const DT_DEFULAT_FONT = 14;
const DT_TITLE_FONT = 16;
const DT_TITLE_MARGIN_BOTTOM = 10;
const DT_TEXT_FONT = 14;
const DT_BTN_WIDTH = 300;
const DT_BTN_HEIGHT = 48;

// Mobile
const MB_DLG_WIDTH = 250;
const MB_DLG_MIN_HEIGHT = 232;
const MB_PADDING = 20;
const MB_DEFULAT_FONT = 10;
const MB_TITLE_FONT = 14;
const MB_TITLE_MARGIN_BOTTOM = 10;
const MB_TEXT_FONT = 12;
const MB_BTN_WIDTH = 200;
const MB_BTN_HEIGHT = 40;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const DLG_MIN_HEIGHT = ScreenInfo.isMobile() ? MB_DLG_MIN_HEIGHT : DT_DLG_MIN_HEIGHT;
const DLG_DEFAULT_PADDING = ScreenInfo.isMobile() ? MB_PADDING : DT_PADDING;
const DEFULAT_FONT = ScreenInfo.isMobile() ? MB_DEFULAT_FONT : DT_DEFULAT_FONT;
const TITLE_FONT = ScreenInfo.isMobile() ? MB_TITLE_FONT : DT_TITLE_FONT;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const TEXT_FONT = ScreenInfo.isMobile() ? MB_TEXT_FONT : DT_TEXT_FONT;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;

export const AsyncGuideJsx = (dlg: AsyncGuideJsxProps) => {
  const hR = useLocalSettings();

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        minHeight: DLG_MIN_HEIGHT,
        // height: 90,
        padding: DLG_DEFAULT_PADDING,
        marginLeft: ScreenInfo.isMobile() ? 35 : undefined,
        marginRight: ScreenInfo.isMobile() ? 35 : undefined,
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineFF,
        // marginTop: ScreenInfo.isMobile() ? 79 : undefined,
        // marginBottom: ScreenInfo.isMobile() ? 79 : undefined,
      }}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      <FlexColumnDiv style={{ alignItems: 'center' }}>
        <TextCon text={dlg.title} color={R.colors.black} size={TITLE_FONT} isBold textStyle={{ textAlign: 'center' }} />
        <HEIGHT size={TITLE_MARGIN_BOTTOM} />
        <TextCon text={dlg.text} color={R.colors.blueGrey} size={TEXT_FONT} />
      </FlexColumnDiv>
      <HEIGHT size={30} />
      <RoundTxtBtn
        text={hR.strings.COMMON_OK_BTN_TXT}
        width={BTN_WIDTH}
        height={BTN_HEIGHT}
        fontSize={DEFULAT_FONT}
        // containerStyle={{ position: 'absolute', bottom: DLG_DEFAULT_PADDING }}
        onClick={() => {
          dlg.onOk && dlg.onOk();
        }}
      />
    </FlexColumnDiv>
  );
};
