/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CSSProperties, HTMLProps } from 'react';
import { config, useSpring } from 'react-spring';
import R from 'src/res/R';
import { DBGMSG } from 'src/util/utils';

export function useMouseEnterEffectType1() {
  const defProps: CSSProperties = {
    // backgroundColor: R.colors.bgGrey,
    marginTop: 0,
    boxShadow: `0 0 0px 0 rgba(0, 0, 0, 0)`,
  };
  const [hAnimProps, set] = useSpring(() => {
    return {
      ...defProps,
      config: { ...config.gentle, duration: 0 },
    };
  });

  const mouseEnter: HTMLProps<HTMLDivElement>['onMouseEnter'] = () => {
    DBGMSG('mouseEnter');
    set({
      // backgroundColor: 'white',
      marginTop: -1,
      boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
    });
  };

  const mouseLeave: HTMLProps<HTMLDivElement>['onMouseLeave'] = () => {
    DBGMSG('mouseLeave');
    set({
      ...defProps,
    });
  };
  return {
    hAnimProps,
    mouseEnter,
    mouseLeave,
  };
}
