/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CbtMarketSearchSort_e } from 'src/model/model';
import { RootState } from 'src/redux/modules';
import userDetailSlice, { UserDetailMenuType_e, UserDetailState_t } from 'src/redux/modules/userDetailModule';

export function useUserDetailState() {
  const userDetailState = useSelector((state: RootState) => state.userDetailState);
  const dispatch = useDispatch();

  const setState = useCallback(
    (args: UserDetailState_t) => {
      dispatch(userDetailSlice.actions.setState(args));
    },
    [dispatch]
  );
  const changeState = useCallback(
    (args: Partial<UserDetailState_t>) => {
      dispatch(userDetailSlice.actions.changeState({ ...args }));
    },
    [dispatch, userDetailState]
  );
  const clearState = useCallback(() => {
    dispatch(userDetailSlice.actions.clear());
  }, [dispatch]);

  const queryClear = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        querySearch: '',
        querySortOrder: CbtMarketSearchSort_e.ListingNewest,
      })
    );
  }, [dispatch]);

  const changeMenu = useCallback(
    (menu: UserDetailMenuType_e) => {
      dispatch(
        userDetailSlice.actions.changeState({
          menu,
        })
      );
    },
    [dispatch]
  );

  // 인벤토리 상태 초기화
  const resetInventoryAstg = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        inventory: {
          astgNeedUpdate: true,
          astg: undefined,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailState]);

  // 인벤토리 상태 AST만 초기화
  const resetInventoryAst = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        inventory: {
          ...userDetailState.inventory,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailState]);

  // 발행에셋 상태 초기화
  const resetIssueAstg = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        issue: {
          astgNeedUpdate: true,
          astg: undefined,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailState]);

  // 발행에셋 상태 AST만 초기화
  const resetIssueAst = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        issue: {
          ...userDetailState.issue,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailState]);

  // 판매중 상태 초기화
  const resetSaleList = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        sale: {
          listNeedUpdate: true,
          list: undefined,
          lastRx: undefined,
          lastPgInfo: undefined,
        },
      })
    );
  }, [dispatch, userDetailState]);

  // 판매됨 상태 초기화
  const resetSoldList = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        sold: {
          listNeedUpdate: true,
          list: undefined,
          lastRx: undefined,
          lastPgInfo: undefined,
        },
      })
    );
  }, [dispatch, userDetailState]);

  // 구매함 상태 초기화
  const resetBoughtList = useCallback(() => {
    dispatch(
      userDetailSlice.actions.changeState({
        bought: {
          listNeedUpdate: true,
          list: undefined,
          lastRx: undefined,
          lastPgInfo: undefined,
        },
      })
    );
  }, [dispatch, userDetailState]);

  return {
    queryClear,
    changeMenu,

    // 인벤토리
    resetInventoryAstg,
    resetInventoryAst,

    // 발행
    resetIssueAstg,
    resetIssueAst,

    // 판매중
    resetSaleList,

    // 판매됨
    resetSoldList,

    // 구매함
    resetBoughtList,

    act: {
      setState,
      changeState,
      clearState,
    },
    state: userDetailState,
  };
}
