// import classNames from 'classnames/bind';
import { findLastKey } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikas } from 'src/hooks/useKaikas';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { CbtCurrencyInfo_i, CbtCurrency_e, CbtPayoutAccountStatus_e, CbtPayoutFeeType_e, CbtResPriceInfo_i } from 'src/model/rpcModel';
import { RPC_USER_ACCOUNT_INFO_MUTABLE } from 'src/model/rpcType';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { RadioBtn } from 'src/ui/common_component/button/RadioBtn';
import { CURRENCY_ROUND_DECIMAL_POINT_CBLT, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { DBGMSG, Utils } from 'src/util/utils';

// import styles from 'src/ui/dlg/jsx/WithdrawJsx.module.scss';

// const cx = classNames.bind(styles);
// const cx = classNames('testtest2');

export type PayoutJsxProps = {
  usdBalance: CbtResPriceInfo_i;
  onOk?: () => void;
  enableOkBtn?: boolean;
  // payout_account?: string;
};

const SectionTitle = ({ main, sub, isAuthStatus = false }: { main: string; sub?: string; isAuthStatus?: boolean }) => {
  return (
    <div className={'common_section_title'}>
      <span className={'main'}>{main}</span>
      {sub && <span className={'sub'}>{sub}</span>}
      {isAuthStatus && (
        <>
          <span className={'auth_status'}>계좌 인증 완료</span>
          <img src={R.images.cashout_ic_confirm3x} className={'auth_status_img'} alt={''} />
        </>
      )}
    </div>
  );
};
export const PayoutJsx = (dlg: PayoutJsxProps) => {
  /**************************************
   * !! state
   **************************************/
  const [sPrice, setPrice] = useState<string>('');
  const [sPriceValidation, setPriceValidation] = useState<string>();
  const [sInputFocus, setInputFocus] = useState(false);
  const [sPlatformFeeMethod, setPlatformFeeMethod] = useState<CbtPayoutFeeType_e>(CbtPayoutFeeType_e.USD);
  // 기존의 계좌 인증된 인출하기 정보
  const [sOrgPayoutAccount, setOrgPayoutAccount] = useState<RPC_USER_ACCOUNT_INFO_MUTABLE.Rx['payout_account']>(null);

  // for testing
  // deleteme
  // const [sOrgPayoutAccount, setOrgPayoutAccount] = useState<RPC_USER_ACCOUNT_INFO_MUTABLE.Rx['payout_account']>({
  //   payout_id: 'choi_sanghoon@cocone.co.jp',
  //   status: CbtPayoutAccountStatus_e.PAYPAL_AUTH,
  // });
  // 새로 추가된 이메일 정보 (이메일 인증단계 까지 완료된)
  const [sNewPayout, setNewPayout] = useState<string | undefined>();
  const [sApplyBtnEnable, setApplyBtnEnable] = useState(false);

  /**************************************
   * !! hooks
   **************************************/
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();
  const hKaikas = useKaikas();
  const hKaikasSvc = useKaikasSvc();
  const hAuth = useAuth();
  const hUserDetailMineState = useUserDetailMineState();
  const hRpc = useRpc();

  // 현재 USD 잔고
  const balanceUSD = Utils.currency.getPriceForDP({
    prcInfo: dlg.usdBalance,
    decimal: dlg.usdBalance.curr_info.currency === CbtCurrency_e.CBLT ? CURRENCY_ROUND_DECIMAL_POINT_CBLT : CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
    noComma: true,
  });

  useEffect(() => {
    if (!hUserDetailMineState.state.user?.userInfo?.payout_account) {
      return;
    }
    setOrgPayoutAccount(hUserDetailMineState.state.user.userInfo.payout_account);
  }, [hUserDetailMineState.state.user]);

  useEffect(() => {
    if (sPrice.length === 0) {
      setApplyBtnEnable(false);
      return;
    }
    if (sPriceValidation !== undefined) {
      setApplyBtnEnable(false);
      return;
    }
    if (!getPayoutAccount()) {
      setApplyBtnEnable(false);
      return;
    }
    setApplyBtnEnable(true);
  }, [sPrice, sPriceValidation, sNewPayout, sOrgPayoutAccount]);

  /**************************************
   * !! arrow function
   **************************************/
  const validate = ({ amount }: { amount: number }) => {
    if (isProd()) {
      const MINIMUM_USD = 100;
      if (amount < MINIMUM_USD) {
        setPriceValidation(hR.strings.MY_PAYOUT_DLG_SECTION_AMOUNT_MINIMUM);
        return;
      }
    }

    DBGMSG(`balanceUSD: ${balanceUSD}`);
    DBGMSG(`parseFloat(balanceUSD): ${parseFloat(balanceUSD)}`);
    if (amount > parseFloat(balanceUSD)) {
      setPriceValidation(hR.strings.MY_PAYOUT_DLG_SECTION_AMOUNT_INSUFFICIENT);
      return;
    }
    setPriceValidation(undefined);
  };

  const regPayPalAccount = () => {
    DBGMSG('paypal');
    hDlgFactory.showRegPaypal({
      orgPayoutAccount: getPayoutAccount(),
      onOk: (addr: string) => {
        hDlgFactory.hideRegPaypal();
        setNewPayout(addr);
        // todo 업데이트
      },
      onclose: () => {
        hDlgFactory.hideRegPaypal();
      },
    });
  };

  const getPayoutAccount = () => {
    if (sNewPayout) {
      return sNewPayout;
    } else if (sOrgPayoutAccount && sOrgPayoutAccount.status === CbtPayoutAccountStatus_e.PAYPAL_AUTH) {
      return sOrgPayoutAccount.payout_id;
    } else {
      return undefined;
    }
  };

  // 신청접수 완료
  const applyDone = () => {
    hDlgFactory.showOkDlgJsx({
      text: sNewPayout !== undefined ? hR.strings.MY_PAYOUT_DLG_APPLY_DONE : hR.strings.MY_PAYOUT_DLG_APPLY_DONE_2,
      textSize: 16,
      textBold: true,
      btnTxt: hR.strings.COMMON_OK_BTN_TXT,
      onOk: () => {
        hDlgFactory.hideOkDlgJsx();
        dlg.onOk && dlg.onOk();
      },
    });
  };

  /**************************************
   * !! render function
   **************************************/
  const renderPayoutAccount = () => {
    // 표시할 Payout 정보가 없으면
    // if (!sNewPayout && !sOrgPayoutAccount) {
    if (!getPayoutAccount()) {
      return (
        <>
          <img src={R.images.creator_btn_add3x} className={'img_plus'} alt="" />
          <img src={R.images.cashout_iv_paypal_logo3x} className={'img_pappal_logo'} alt="" />
        </>
      );
    }

    return (
      <>
        {ScreenInfo.isMobile() ? (
          <div className={'flex_column_div'} style={{ alignItems: 'flex-start' }}>
            <img src={R.images.cashout_iv_paypal_logo3x} className={'img_pappal_logo'} style={{ marginBottom: 10 }} alt="" />
            <div className={'paypal_account'}>{getPayoutAccount()}</div>
          </div>
        ) : (
          <div className={'flex_row_div'} style={{ alignItems: 'center' }}>
            <img src={R.images.cashout_iv_paypal_logo3x} className={'img_pappal_logo'} style={{}} alt="" />
            <div className={'paypal_account'}>{getPayoutAccount()}</div>
          </div>
        )}

        <div className={'edit_btn'}>
          <img
            src={R.images.cashout_ic_edit3x}
            alt={''}
            className={'edit_btn'}
            onClick={() => {
              if (sOrgPayoutAccount && sNewPayout === undefined) {
                hDlgFactory.showYesNo({
                  text: hR.strings.DLG_PAYOUT_ID_CHANGE_TITLE,
                  yes: hR.strings.DLG_PAYOUT_ID_CHANGE_CHANGE_BTN,
                  no: hR.strings.DLG_PAYOUT_ID_CHANGE_CHANGE_CLOSE,
                  onYes: () => {
                    regPayPalAccount();
                    hDlgFactory.hideYesNo();
                  },
                  onNo: () => {
                    hDlgFactory.hideYesNo();
                  },
                });
              } else {
                regPayPalAccount();
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderKaikas = () => {
    if (hKaikas.isConnectedKaikas() && hKaikas.kaikasState.user) {
      return (
        <div
          className={'kaikas_enablebtn_or_address'}
          onClick={() => {
            if (!hKaikas.kaikasState.user) return;
            window.open(
              Utils.string.brewKlaytnUserAccountUrl({
                accountAddress: hKaikas.kaikasState.user.selectedAddress,
                isMainnet: hKaikas.getNetworkVersion() === 'MAINNET',
              })
            );
          }}
        >
          <div className={'kaikas_on_ic'} />
          {Utils.string.shortKlatynAddress(hKaikas.kaikasState.user.selectedAddress)}
        </div>
      );
    } else {
      return (
        <div
          className={'kaikas_enablebtn_or_address'}
          onClick={async () => {
            try {
              await hKaikasSvc.kaikasConnectAndRewardAddrReg(true);
            } catch (e) {}
          }}
        >
          {hR.strings.HEADER_KAIKAS_WALLET_CONNECT}
        </div>
      );
    }
  };

  return (
    <div
      className={ScreenInfo.isMobile() ? 'payout_jsx_m' : 'payout_jsx'}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      <div className={'dlg_title'}>{hR.strings.MY_PAYOUT_DLG_TITLE}</div>
      <div className={'dlg_sub_title'}>{hR.strings.MY_PAYOUT_DLG_USD_BALANCE}</div>
      <div className={'balance'}>{balanceUSD}</div>

      {/* 보내는 금액 */}
      <div className={'section_import'}>
        <SectionTitle main={hR.strings.MY_PAYOUT_DLG_SECTION_AMOUNT} sub={hR.strings.MY_PAYOUT_DLG_SECTION_AMOUNT_SUB} />
        <div className={'flex_row_div'}>
          <div className={sInputFocus ? 'amount_input_focus' : 'amount_input'}>
            <input
              value={sPrice}
              placeholder={'0'}
              onFocus={() => {
                setInputFocus(true);
              }}
              onBlur={() => {
                setInputFocus(false);
              }}
              onChange={(e) => {
                setPriceValidation(undefined);
                if (e.target.value.length === 0) {
                  setPrice('');
                  return;
                }

                const regex: RegExp = Constants.REGEXP_IS_VALID_USD_AMOUNT;
                const regexTestRet = regex.test(e.target.value);
                DBGMSG(`$$$REGEX ${regex}: ${e.target.value} ${regexTestRet}`);
                if (!regexTestRet) {
                  DBGMSG(`$$$REGEX regexTestRet: ${regexTestRet}`);
                  return;
                }
                setPrice(e.target.value);
                validate({ amount: parseFloat(e.target.value) });
              }}
            />
          </div>
          <div
            className={'all_button'}
            onClick={() => {
              DBGMSG('all btn click');
              setPrice(balanceUSD);
              validate({ amount: parseFloat(balanceUSD) });
            }}
          >
            {hR.strings.MY_PAYOUT_DLG_SECTION_AMOUNT_ALL_BTN}
          </div>
        </div>
        <div className={'validation'}>{sPriceValidation}</div>
      </div>

      {/* 보내는 계좌 */}
      <div className={'section_account'}>
        <SectionTitle
          main={hR.strings.MY_PAYOUT_DLG_SECTION_ACCOUNT}
          isAuthStatus={sNewPayout === undefined && sOrgPayoutAccount?.status === CbtPayoutAccountStatus_e.PAYPAL_AUTH}
        />
        <div
          className={'box'}
          style={{ cursor: getPayoutAccount() ? 'auto' : 'pointer' }}
          onClick={() => {
            // 등록되어 있지 않을경우
            getPayoutAccount() === undefined && regPayPalAccount();
          }}
        >
          {renderPayoutAccount()}
        </div>
      </div>

      {/* 플랫폼 수수료 */}
      <div className={'section_platform_fee'}>
        <SectionTitle main={hR.strings.MY_PAYOUT_DLG_SECTION_PLATFORM_FEE} sub={hR.strings.MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_SUB} />
        <div
          className={'box'}
          style={{
            borderColor:
              sPlatformFeeMethod === CbtPayoutFeeType_e.CBLT && !(hKaikas.isConnectedKaikas() && hKaikas.kaikasState.user)
                ? R.colors.pastelRed
                : R.colors.line,
          }}
        >
          <div
            className={'radio_btn'}
            onClick={() => {
              DBGMSG('USD');
              setPlatformFeeMethod(CbtPayoutFeeType_e.USD);
            }}
          >
            <RadioBtn checked={sPlatformFeeMethod === CbtPayoutFeeType_e.USD} />
            <div className={'txt'}>5 USD</div>
          </div>
          <div
            className={'radio_btn'}
            onClick={() => {
              DBGMSG('CBLT');
              setPlatformFeeMethod(CbtPayoutFeeType_e.CBLT);
              if (!ScreenInfo.isDesktop()) {
                hDlgFactory.showSimple({
                  text: hR.strings.DLG_KAIKAS_ONLY_DESKTOP_CBLT_PAY,
                  onClose: () => {
                    setPlatformFeeMethod(CbtPayoutFeeType_e.USD);
                    hDlgFactory.hideSimple();
                  },
                });
              }
            }}
          >
            <RadioBtn checked={sPlatformFeeMethod === CbtPayoutFeeType_e.CBLT} />
            <div className={'txt'}>5 CBLT</div>
            {sPlatformFeeMethod === CbtPayoutFeeType_e.CBLT && renderKaikas()}
          </div>
        </div>
        {sPlatformFeeMethod === CbtPayoutFeeType_e.CBLT && !(hKaikas.isConnectedKaikas() && hKaikas.kaikasState.user) && (
          <div className={'validation'}>{hR.strings.MY_PAYOUT_DLG_SECTION_PLATFORM_FEE_KAIKAS}</div>
        )}
      </div>

      {/* 이체 수수료 */}
      <div className={'section_paypal_fee'}>
        <SectionTitle main={hR.strings.MY_PAYOUT_DLG_SECTION_PAYPAL_FEE} />
        <div className={'box'}>
          <div className={'txt'}>{hR.strings.MY_PAYOUT_DLG_SECTION_PAYPAL_FEE_LINK}</div>
          <img src={R.images.common_btn_tooltip_normal3x} className={'tooltip'} alt="" />
        </div>
      </div>

      {/* 신청안내 */}
      <div className={'section_guide'}>
        <SectionTitle main={hR.strings.MY_PAYOUT_DLG_SECTION_GUIDE} />
        <div className={'box'}>
          <div className={'guide'}>{hR.strings.MY_PAYOUT_DLG_SECTION_GUIDE_DETAIL}</div>
        </div>
      </div>

      {/* 신청하기버튼 */}
      <div className={'apply_btn'}>
        <div
          className={sApplyBtnEnable ? 'apply_btn_enable' : 'apply_btn_disable'}
          // className={'apply_btn_enable'}
          onClick={async () => {
            const payout_id = getPayoutAccount();
            if (!payout_id) return;

            // 화폐정보
            const curr_info: CbtCurrencyInfo_i = {
              currency: CbtCurrency_e.cUSD,
              decimal: 6,
            };

            // payout 요청 금액
            const price = Utils.currency.reflectInCurrInfo({
              priceTxt: sPrice,
              curr_info: curr_info,
            });

            // request id
            let req_id: string;
            try {
              const res = await hRpc.cobaltRpc.rpcUserAccountPayoutRequest({
                payout_id,
                amount: { curr_info, price },
                fee_type: sPlatformFeeMethod,
                lang: hR.lang,
              });
              req_id = res.req_id;
            } catch (e) {
              hRpc.hanleCommonError(e);
              return;
            }

            // CBLT 수수료 지급을 선택했을 경우
            if (sPlatformFeeMethod === CbtPayoutFeeType_e.CBLT) {
              // CBLT 수수료 지불하기 다이얼로그
              hDlgFactory.showCbltFee({
                reqId: req_id,
                onOk: async ({ isSuccess, bl_tx_hash }) => {
                  DBGMSG('showCbltFee.onOk :isSuccess-', isSuccess);
                  hDlgFactory.hideCbltFee();

                  if (isSuccess && bl_tx_hash) {
                    // 수수료 지불에 성공
                    try {
                      const res = await hRpc.cobaltRpc.rpcUserAccountPayoutFinrequest({
                        req_id: req_id,
                        bl_tx_hash: bl_tx_hash,
                      });
                      applyDone();
                      hUserDetailMineState.act.changeState({ user: { needUpdate: true } });
                    } catch (e) {
                      hRpc.hanleCommonError(e);
                      return;
                    }
                  } else {
                    // 수수료 지불에 실패
                    return;
                  }
                },
              });
            } else {
              applyDone();
            }
          }}
        >
          {hR.strings.MY_PAYOUT_DLG_APPLY_BTN}
        </div>
      </div>
    </div>
  );
};
