/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import { config, useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { DBGMSG } from 'src/util/utils';
import { SCircelAddBtn } from 'src/ui/common_component/button/SCircelAddBtn';
import {
  COLLECTION_CARD_LAYOUT,
  PropsCommonBox,
  SPRING_PROPS_ANIM_1_Default,
  SPRING_PROPS_ANIM_1_OnMouseEnter,
  SPRING_PROPS_ANIM_1_OnMouseLeave,
} from 'src/ui/layout_constant';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  isAnim?: boolean;
  onClick: () => void;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

export function CollectionCardNew({ onClick, isAnim = false }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseEnter, setMouseEnter] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    console.debug('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // console.debug('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  // const isActive = curMenu === menu;

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  // DBGMSG('render');
  return (
    <FlexDiv
      style={{
        padding: COLLECTION_CARD_LAYOUT.paddingForList,
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineF,
      }}
    >
      <AnimatedFlexColumnDiv
        style={{
          width: COLLECTION_CARD_LAYOUT.width,
          height: COLLECTION_CARD_LAYOUT.height,
          cursor: 'pointer',
          ...PropsCommonBox,
          ...hMouseProps,
          // ...devOutlineF,
        }}
        onMouseEnter={() => {
          // DBGMSG(`onMouseEnter: `);
          isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
          setMouseEnter(true);
        }}
        onMouseLeave={() => {
          // DBGMSG(`onMouseLeave: `);
          isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
          setMouseEnter(false);
        }}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <FlexColumnDiv style={{ alignItems: 'center' }}>
          <div style={{ height: 40 }} />
          {/*  Collection 대표이미지 */}
          <SCircelAddBtn forceEffect={sMouseEnter} />

          <div style={{ height: 20 }} />

          <FlexRowDiv
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: COLLECTION_CARD_LAYOUT.collecionNameWidth,
              // ...devOutlineF,
            }}
          >
            <TextCon color={'#121f33'} isBold size={14} text={hR.strings.AC_COLLECTION_CREATE_TITLE} />
          </FlexRowDiv>
          <div style={{ height: 20 }} />
        </FlexColumnDiv>
      </AnimatedFlexColumnDiv>
    </FlexDiv>
  );
}
