/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useEffect } from 'react';
import { useHistory } from 'react-router';
import { config, useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { SoldCard_i as UserSoldCardInfo_i } from 'src/model/model';
import { CbtColCertifyType_e } from 'src/model/rpcModel';
import { UserDetailMenuType_e } from 'src/redux/modules/userDetailModule';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';
import { LabelValue } from 'src/ui/common_component/LabelValue';
import { CLAY, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/

const DT_LEFT_CARD_WIDTH = 276;
const DT_LEFT_CARD_HEIGHT = 364;
const DT_BETWEEN = 30;
const DT_RIGHT_BOX_WIDTH = 582;
const DT_RIGHT_BOX_HEIGHT = 386;
const DT_SOLD_CARD_WIDTH = DT_LEFT_CARD_WIDTH + DT_BETWEEN + DT_RIGHT_BOX_WIDTH;
const DT_SOLD_CARD_HEIGHT = DT_RIGHT_BOX_HEIGHT;
const DT_CARD_TOP_PADDING = 20;
const DT_CARD_HPADDING = 20;
const DT_CARD_BODY_WIDTH = DT_LEFT_CARD_WIDTH - DT_CARD_HPADDING * 2;
const DT_COL_IMG_SIZE = 24;
const DT_COL_IMG_VPADDING = 20;
const DT_COL_NAME_FOTNSIZE = 14;
const DT_ASSET_NAME_HEIGHT = 20;
const DT_ASSET_NAME_FONTSIZE = 14;
const DT_ASSET_NAME_MARGIN_BOTTOM = 24;
const DT_RIGHT_BOX_COMMON_PADDING = 24;
const DT_RIGHT_BOX_FONT_HEIGHT = 24;
const DT_RIGHT_BOX_FONT_SIZE = 14;
const DT_RIGHT_BOX_TITLE_WIDTH = 175;
const DT_RIGHT_BOX_VALUE_WIDTH = 359; // 44 + 339 - 24;

const MB_LEFT_CARD_WIDTH = 280;
const MB_LEFT_CARD_HEIGHT = 354;
const MB_BETWEEN = 8;
const MB_RIGHT_BOX_WIDTH = CLAY.BODY_WIDTH;
const MB_RIGHT_BOX_HEIGHT = 472;
const MB_SOLD_CARD_WIDTH = CLAY.BODY_WIDTH;
const MB_SOLD_CARD_HEIGHT = MB_LEFT_CARD_HEIGHT + MB_BETWEEN + MB_RIGHT_BOX_HEIGHT;
const MB_CARD_TOP_PADDING = 16;
const MB_CARD_HPADDING = 16;
const MB_CARD_BODY_WIDTH = MB_LEFT_CARD_WIDTH - MB_CARD_HPADDING * 2;
const MB_COL_IMG_SIZE = 20;
const MB_COL_IMG_VPADDING = 16;
const MB_COL_NAME_FOTNSIZE = 12;
const MB_ASSET_NAME_HEIGHT = 18;
const MB_ASSET_NAME_FONTSIZE = 12;
const MB_ASSET_NAME_MARGIN_BOTTOM = 20;
const MB_RIGHT_BOX_COMMON_PADDING = 16;
const MB_RIGHT_BOX_FONT_HEIGHT = 24;
const MB_RIGHT_BOX_FONT_SIZE = 12;
const MB_RIGHT_BOX_TITLE_WIDTH = CLAY.BODY_WIDTH - MB_RIGHT_BOX_COMMON_PADDING;
const MB_RIGHT_BOX_VALUE_WIDTH = CLAY.BODY_WIDTH - MB_RIGHT_BOX_COMMON_PADDING;

export const SOLDCARD_LEFT_CARD_WIDTH = ScreenInfo.isMobile() ? MB_LEFT_CARD_WIDTH : DT_LEFT_CARD_WIDTH;
export const SOLDCARD_LEFT_CARD_HEIGHT = ScreenInfo.isMobile() ? MB_LEFT_CARD_HEIGHT : DT_LEFT_CARD_HEIGHT;
export const SOLDCARD_BETWEEN = ScreenInfo.isMobile() ? MB_BETWEEN : DT_BETWEEN;
export const SOLDCARD_RIGHT_BOX_WIDTH = ScreenInfo.isMobile() ? MB_RIGHT_BOX_WIDTH : DT_RIGHT_BOX_WIDTH;
export const SOLDCARD_RIGHT_BOX_HEIGHT = ScreenInfo.isMobile() ? MB_RIGHT_BOX_HEIGHT : DT_RIGHT_BOX_HEIGHT;
export const SOLDCARD_WIDTH = ScreenInfo.isMobile() ? MB_SOLD_CARD_WIDTH : DT_SOLD_CARD_WIDTH;
export const SOLDCARD_HEIGHT = ScreenInfo.isMobile() ? MB_SOLD_CARD_HEIGHT : DT_SOLD_CARD_HEIGHT;
export const SOLDCARD_LEFT_CARD_BODY_WIDTH = ScreenInfo.isMobile() ? MB_CARD_BODY_WIDTH : DT_CARD_BODY_WIDTH;
export const SOLDCARD_LEFT_CARD_TOP_PADDING = ScreenInfo.isMobile() ? MB_CARD_TOP_PADDING : DT_CARD_TOP_PADDING;
export const SOLDCARD_LEFT_CARD_H_PADDING = ScreenInfo.isMobile() ? MB_CARD_HPADDING : DT_CARD_HPADDING;
export const SOLDCARD_COL_IMG_SIZE = ScreenInfo.isMobile() ? MB_COL_IMG_SIZE : DT_COL_IMG_SIZE;
export const SOLDCARD_COL_IMG_VPADDING = ScreenInfo.isMobile() ? MB_COL_IMG_VPADDING : DT_COL_IMG_VPADDING;
export const SOLDCARD_COL_NAME_FOTNSIZE = ScreenInfo.isMobile() ? MB_COL_NAME_FOTNSIZE : DT_COL_NAME_FOTNSIZE;
export const SOLDCARD_ASSET_NAME_HEIGHT = ScreenInfo.isMobile() ? MB_ASSET_NAME_HEIGHT : DT_ASSET_NAME_HEIGHT;
export const SOLDCARD_ASSET_NAME_FONTSIZE = ScreenInfo.isMobile() ? MB_ASSET_NAME_FONTSIZE : DT_ASSET_NAME_FONTSIZE;
export const SOLDCARD_ASSET_NAME_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_ASSET_NAME_MARGIN_BOTTOM : DT_ASSET_NAME_MARGIN_BOTTOM;
export const SOLDCARD_RIGHT_BOX_COMMON_PADDING = ScreenInfo.isMobile() ? MB_RIGHT_BOX_COMMON_PADDING : DT_RIGHT_BOX_COMMON_PADDING;
export const SOLDCARD_RIGHT_BOX_FONT_HEIGHT = ScreenInfo.isMobile() ? MB_RIGHT_BOX_FONT_HEIGHT : DT_RIGHT_BOX_FONT_HEIGHT;
export const SOLDCARD_RIGHT_BOX_FONT_SIZE = ScreenInfo.isMobile() ? MB_RIGHT_BOX_FONT_SIZE : DT_RIGHT_BOX_FONT_SIZE;
export const SOLDCARD_RIGHT_BOX_TITLE_WIDTH = ScreenInfo.isMobile() ? MB_RIGHT_BOX_TITLE_WIDTH : DT_RIGHT_BOX_TITLE_WIDTH;
export const SOLDCARD_RIGHT_BOX_VALUE_WIDTH = ScreenInfo.isMobile() ? MB_RIGHT_BOX_VALUE_WIDTH : DT_RIGHT_BOX_VALUE_WIDTH;

/**************************************
 * !! type
 **************************************/
export type UserSoldCardProps = {
  soldInfo: UserSoldCardInfo_i;
  isAnim?: boolean;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function UserSoldCard({ soldInfo, isAnim = false, onClick }: UserSoldCardProps) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hHistory = useHistory();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return {
      backgroundColor: 'white',
      marginTop: 0,
      boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
      config: { ...config.gentle, duration: 0 },
    };
  });

  // DBGMSG('render');
  return (
    <FlexRowDiv style={{ width: SOLDCARD_WIDTH, flexWrap: 'wrap' }}>
      {/* 왼쪽 에셋카드 */}
      <AnimatedFlexColumnDiv
        style={{
          width: SOLDCARD_LEFT_CARD_WIDTH,
          height: SOLDCARD_LEFT_CARD_HEIGHT,
          cursor: onClick ? 'pointer' : 'inherit',
          borderRadius: 15,
          ...hMouseProps,
          // ...devOutline,
        }}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          onClick && onClick(e);
        }}
      >
        {/* 상단부 */}
        <FlexColumnDiv style={{ alignItems: 'center' }}>
          {/*  Collection display 이름 */}
          <FlexRowDiv
            style={{
              alignItems: 'center',
              width: SOLDCARD_LEFT_CARD_BODY_WIDTH,
              marginTop: SOLDCARD_LEFT_CARD_TOP_PADDING,
              // ...devOutlineF,
              //
            }}
          >
            <FlexDiv style={{ height: SOLDCARD_COL_IMG_SIZE }}>
              {soldInfo.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
                <Img src={[R.images.common_ic_verify3x, SOLDCARD_COL_IMG_SIZE, SOLDCARD_COL_IMG_SIZE]} containerStyle={{ marginRight: 4 }} />
              )}
            </FlexDiv>
            <TextCon
              color={R.colors.black}
              isBold
              size={SOLDCARD_COL_NAME_FOTNSIZE}
              text={soldInfo.col_info.dp_name}
              containerStyle={{}}
              textStyle={{ width: SOLDCARD_LEFT_CARD_BODY_WIDTH - SOLDCARD_COL_IMG_SIZE - 4, overflow: 'hidden', textOverflow: 'ellipsis' }}
            />
          </FlexRowDiv>

          <HEIGHT size={SOLDCARD_COL_IMG_VPADDING} />

          {/*  Asset 대표이미지 */}
          <AssetDisplayThumbReqattr
            playerSrc={Utils.svc.brewAssetDisplayThumbReqattr({ assetType: Utils.svc.getAssetType(soldInfo), img: soldInfo.nft_img })}
            width={SOLDCARD_LEFT_CARD_BODY_WIDTH}
            height={SOLDCARD_LEFT_CARD_BODY_WIDTH}
            whereToUse={WhereToUse_e.ForNormalCard}
          />
        </FlexColumnDiv>

        {/* 하단부 */}
        <FlexColumnDiv
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {/*  Asset 이름 */}
          <FlexRowDiv
            style={{
              height: SOLDCARD_ASSET_NAME_HEIGHT,
              alignItems: 'center',
              width: SOLDCARD_LEFT_CARD_BODY_WIDTH,
              marginBottom: SOLDCARD_ASSET_NAME_MARGIN_BOTTOM,
              // ...devOutlineF,
            }}
          >
            <TextCon
              color={R.colors.black}
              isBold
              size={SOLDCARD_ASSET_NAME_FONTSIZE}
              // text={'에셋 이름이 길면 이렇게 처리해주세요...'}
              text={soldInfo.nft_name}
            />
          </FlexRowDiv>
        </FlexColumnDiv>
      </AnimatedFlexColumnDiv>

      {!ScreenInfo.isMobile() && <WIDTH size={SOLDCARD_BETWEEN} />}

      {/* 오른쪽 판매정보 */}
      <AnimatedFlexColumnDiv
        style={{
          width: SOLDCARD_RIGHT_BOX_WIDTH,
          height: SOLDCARD_RIGHT_BOX_HEIGHT,
          // cursor: onClick ? 'pointer' : 'inherit',
          borderRadius: 15,
          // boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.05)`,
          ...hMouseProps,
          ...devOutline,
          marginTop: ScreenInfo.isMobile() ? SOLDCARD_BETWEEN : undefined,
        }}
      >
        <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        <TextCon
          text={`${soldInfo.sale_id}`}
          color={R.colors.purpleishBlue}
          isBold
          size={14}
          containerStyle={{ height: 20, marginLeft: SOLDCARD_RIGHT_BOX_COMMON_PADDING }}
          onConClick={(e)=>{
            onClick && onClick(e);
          }}
        />
        <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        <HLINE size={`100%`} />
        <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        <FlexColumnDiv style={{ marginLeft: SOLDCARD_RIGHT_BOX_COMMON_PADDING }}>
          {/* 에셋 아이디 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD_ASSET_ID}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={`${soldInfo.asset_id}`}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 판매일시 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD_DATE}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={Utils.date.brewFomatString(soldInfo.txDate)}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.black}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 판매자 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD_SELLER}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={soldInfo.seller}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            valueOnClick={() => {
              hHistory.push(Nav.brewUserDetailScreenPath({ ucode: soldInfo.seller, menu: UserDetailMenuType_e.INVEN }));
            }}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 구매자 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD_BUYER}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={soldInfo.buyer}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            valueOnClick={() => {
              hHistory.push(Nav.brewUserDetailScreenPath({ ucode: soldInfo.buyer, menu: UserDetailMenuType_e.INVEN }));
            }}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* 판매가격 */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD_PRICE}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={`${Utils.currency.getPriceForDP({ prcInfo: soldInfo.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${
              Utils.svc.getCurrencyDpInfo(soldInfo.prc_info.curr_info.currency).unit
            }`}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.black}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />

          {/* Tx Hash */}
          <LabelValue
            flexDir={ScreenInfo.isMobile() ? 'column' : 'row'}
            title={hR.strings.MARKET_ACCOUNT_READ_MENU_SOLD_TX_HASH}
            titleSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            valueSize={SOLDCARD_RIGHT_BOX_FONT_SIZE}
            titleWidth={SOLDCARD_RIGHT_BOX_TITLE_WIDTH}
            titleHeight={SOLDCARD_RIGHT_BOX_FONT_HEIGHT}
            value={Utils.string.shortKlatynTxHash(soldInfo.tx_hash)}
            valueWidth={SOLDCARD_RIGHT_BOX_VALUE_WIDTH}
            valueColor={R.colors.purpleishBlue}
            valueOnClick={() => {
              window.open(soldInfo.tx_hash_url);
            }}
            valueMouseOverTitle={soldInfo.tx_hash_url}
          />
          <HEIGHT size={SOLDCARD_RIGHT_BOX_COMMON_PADDING} />
        </FlexColumnDiv>
      </AnimatedFlexColumnDiv>
    </FlexRowDiv>
  );
}
