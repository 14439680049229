import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { DBGMSG } from 'src/util/utils';

export type PaymentwallResultJsxProps = {
  paymentwall_result: 'succ' | 'fail';
  transaction_id?: string;
  onOk?: () => void;
};

// Desktop
const DT_DLG_WIDTH = 380;
const DT_DLG_HEIGHT = 290;
const DT_PADDING = 40;
const DT_DEFULAT_FONT = 14;
const DT_TITLE_FONT = 16;
const DT_TITLE_MARGIN_BOTTOM = 5;
const DT_TITLE_MARGIN_TOP = 20;
const DT_BTN_WIDTH = 300;
const DT_BTN_HEIGHT = 48;
const DT_BTN_MARGIN_TOP = 40;
const DT_TOP_SPACE_FLEX = 1;
const DT_BOTTOM_SPACE_FLEX = 3;

// Mobile
const MB_DLG_WIDTH = 250;
const MB_DLG_HEIGHT = 232;
const MB_PADDING = 20;
const MB_DEFULAT_FONT = 10;
const MB_TITLE_FONT = 16;
const MB_TITLE_MARGIN_BOTTOM = 16;
const MB_TITLE_MARGIN_TOP = 16;
const MB_BTN_WIDTH = 200;
const MB_BTN_HEIGHT = 40;
const MB_BTN_MARGIN_TOP = 20;
const MB_TOP_SPACE_FLEX = 1;
const MB_BOTTOM_SPACE_FLEX = 1;

const DLG_WIDTH = ScreenInfo.isMobile() ? MB_DLG_WIDTH : DT_DLG_WIDTH;
const DLG_HEIGHT = ScreenInfo.isMobile() ? MB_DLG_HEIGHT : DT_DLG_HEIGHT;
const DLG_DEFAULT_PADDING = ScreenInfo.isMobile() ? MB_PADDING : DT_PADDING;
const DEFULAT_FONT = ScreenInfo.isMobile() ? MB_DEFULAT_FONT : DT_DEFULAT_FONT;
const TITLE_FONT = ScreenInfo.isMobile() ? MB_TITLE_FONT : DT_TITLE_FONT;
const TITLE_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_BOTTOM : DT_TITLE_MARGIN_BOTTOM;
const TITLE_MARGIN_TOP = ScreenInfo.isMobile() ? MB_TITLE_MARGIN_TOP : DT_TITLE_MARGIN_TOP;
const BTN_WIDTH = ScreenInfo.isMobile() ? MB_BTN_WIDTH : DT_BTN_WIDTH;
const BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BTN_HEIGHT : DT_BTN_HEIGHT;
const BTN_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BTN_MARGIN_TOP : DT_BTN_MARGIN_TOP;
const TOP_SPACE_FLEX = ScreenInfo.isMobile() ? MB_TOP_SPACE_FLEX : DT_TOP_SPACE_FLEX;
const BOTTOM_SPACE_FLEX = ScreenInfo.isMobile() ? MB_BOTTOM_SPACE_FLEX : DT_BOTTOM_SPACE_FLEX;

export const PaymentwallResultJsx = (dlg: PaymentwallResultJsxProps) => {
  const hR = useLocalSettings();

  return (
    <FlexColumnDiv
      style={{
        width: DLG_WIDTH,
        height: DLG_HEIGHT,
        padding: DLG_DEFAULT_PADDING,
        marginLeft: ScreenInfo.isMobile() ? 35 : undefined,
        marginRight: ScreenInfo.isMobile() ? 35 : undefined,
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineFF,
        // marginTop: ScreenInfo.isMobile() ? 79 : undefined,
        // marginBottom: ScreenInfo.isMobile() ? 79 : undefined,
      }}
      onClick={(e) => {
        DBGMSG('onDialog');
        e.stopPropagation();
      }}
    >
      <Img src={[dlg.paymentwall_result === 'succ' ? R.images.point_ic_success3x : R.images.point_ic_fail3x, 50, 50]} />
      <HEIGHT size={TITLE_MARGIN_TOP} />
      <TextCon
        text={dlg.paymentwall_result === 'succ' ? hR.strings.DLG_CHARGE_SUCC : hR.strings.DLG_CHARGE_FAIL}
        size={TITLE_FONT}
        color={R.colors.black}
        isBold
      />
      <HEIGHT size={TITLE_MARGIN_BOTTOM} />
      <TextCon text={dlg.transaction_id} size={DEFULAT_FONT} color={R.colors.black} />

      <HEIGHT size={BTN_MARGIN_TOP} />

      <RoundTxtBtn
        text={hR.strings.COMMON_OK_BTN_TXT}
        width={BTN_WIDTH}
        height={BTN_HEIGHT}
        fontSize={DEFULAT_FONT}
        // containerStyle={{ position: 'absolute', bottom: DLG_DEFAULT_PADDING }}
        onClick={() => {
          dlg.onOk && dlg.onOk();
        }}
      />
    </FlexColumnDiv>
  );
};
