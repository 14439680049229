import React from 'react';
import { HEIGHT, FlexRowDiv } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { CommonColumnBox } from 'src/ui/layout_constant';
import { Utils } from 'src/util/utils';
import { TrdGraphItem_t } from 'src/ui/screen/Marketplace/MarketAssetRScreen';

// 차트 X축 틱
export const PriceHistoryChartXAxisTick = ({ x, y, stroke, payload }: any) => {
  // DBGMSG(props);
  // const date = Utils.date.getDate(payload.value);
  const formattedDate = Utils.date.brewFomatString(payload.value * 1000, 'yy.MM.dd');
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={40}
        y={10}
        dy={16}
        textAnchor="end"
        fill={R.colors.blueGrey}
        style={{
          fontFamily: R.fonts.SpoqaJP,
          fontWeight: 'bold',
          fontSize: 16,
          color: R.colors.blueGrey,
        }}
      >
        {formattedDate}
      </text>
    </g>
  );
};

// 차트 Y축 틱
export const PriceHistoryChartYAxisTick = ({ x, y, stroke, payload }: any) => {
  if (payload.value === 0) return null;
  // DBGMSG(props);
  // const date = Utils.date.getDate(payload.value);
  // const formattedDate = Utils.date.brewFomatString(payload.value * 1000, 'yy.MM.dd');
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={60}
        y={5}
        dy={0}
        textAnchor="end"
        fill={R.colors.blueGrey}
        style={{
          fontFamily: R.fonts.SpoqaJP,
          fontWeight: 'bold',
          fontSize: 16,
          color: R.colors.blueGrey,
        }}
      >
        {(payload.value as number).toLocaleString()}
      </text>
    </g>
  );
};

// 차트 마우스 오버 툴팁
export const PriceHistoryChartTooltip = (tooltipProps: any) => {
  // DBGMSG(tooltipProps);
  if (tooltipProps.active && tooltipProps.payload && tooltipProps.payload.length) {
    const formattedDate = Utils.date.brewFomatString(tooltipProps.label * 1000, 'yyyy.MM.dd');

    // if (typeof tooltipProps.payload[0].payload.uv !== 'number') return null;

    if (!tooltipProps.payload[0].payload) {
      return null;
    }

    const itemInfo: TrdGraphItem_t = tooltipProps.payload[0].payload;

    return (
      // <div className="custom-tooltip">
      //   <p className="label">{`${label} : ${payload[0].value}`}</p>
      //   {/* <p className="intro">{getIntroOfPage(label)}</p> */}
      //   <p className="desc">Anything you want can be displayed here.</p>
      // </div>
      <CommonColumnBox style={{ width: 210, height: 86, padding: 18 }}>
        <FlexRowDiv style={{ height: 20 }}>
          <TextCon size={14} text={itemInfo.tooltip.cntLabel} color={R.colors.black} />
          <TextCon size={14} text={itemInfo.tooltip.cntValue} isBold color={R.colors.black} />
        </FlexRowDiv>
        <HEIGHT size={10} />
        <FlexRowDiv style={{ height: 20 }}>
          <TextCon size={14} text={itemInfo.tooltip.avgPrcLabel} color={R.colors.black} />
          <TextCon size={14} text={itemInfo.tooltip.avgPrcValue} isBold color={R.colors.black} />
          <TextCon size={14} text={' USD'} isBold color={R.colors.black} />
        </FlexRowDiv>
      </CommonColumnBox>
    );
  }

  return null;
};
