const fonts = {
  // SpoqaJP_Thin: "SpoqaJP-Thin",
  // SpoqaJP_Light: "SpoqaJP-Light",
  // SpoqaJP: "SpoqaJP-Regular",
  // SpoqaJP_Bold: "SpoqaJP-Bold",

  SpoqaJP: "Spoqa Han Sans JP",
  NotoSerif: "Noto Serif",
};

export default fonts;
