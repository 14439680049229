/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import ScreenInfo, { MOBILE_HORIZONTAL_PADDING } from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { usePropInfo } from 'src/hooks/usePropInfo';
import { useRpc } from 'src/hooks/useRpc';
import { CbtPgInfo_i } from 'src/model/rpcModel';
import { CbtPropQueryType_e, CbtPropType_e, RPC_USER_ACCOUNT_POINT_HISTORY } from 'src/model/rpcType';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, HLINE, VLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { PriceSimple } from 'src/ui/common_component/user/PriceSimple';
import { ProperyOverview } from 'src/ui/common_component/user/PropertyOverview';
import { ProperyQuery } from 'src/ui/common_component/user/PropertyQuery';
import { CLAY, CommonColumnBox, CommonRowBox, CURRENCY_ROUND_DECIMAL_POINT_DEFAULT } from 'src/ui/layout_constant';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

export const getDefaultStart = () => {
  const nowDate = Utils.date.now();
  const startDate = Utils.date.dateSubMonth(nowDate, 3);
  const startYYYYMMDD = Utils.date.brewYYYYMMDD(startDate);
  return parseInt(startYYYYMMDD);
};
export const getDefaultEnd = () => {
  const nowDate = Utils.date.now();
  const endYYYYMMDD = Utils.date.brewYYYYMMDD(nowDate);
  return parseInt(endYYYYMMDD);
};

type Props = {};
// Inventory 목록 렌더링
export function PointHistoryList(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sQuery, setQuery] = useState<Omit<RPC_USER_ACCOUNT_POINT_HISTORY.Tx, 'list'>>({
    type: CbtPropQueryType_e.All,
    start: getDefaultStart(),
    end: getDefaultEnd(),
  });
  const [sNextPageStatus, setNextPageStatus] = useState<string>('');
  const [sFirstPageLoading, setFirstPageLoading] = useState<boolean>();
  const [sList, setList] = useState<RPC_USER_ACCOUNT_POINT_HISTORY.Rx['list']>();
  const [sLastRx, setLastRx] = useState<RPC_USER_ACCOUNT_POINT_HISTORY.Rx>();

  /**************************************
   * !! ref
   **************************************/
  const cacheRef = useRef<{
    lastRx: RPC_USER_ACCOUNT_POINT_HISTORY.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_USER_ACCOUNT_POINT_HISTORY.Rx['list'];
  }>();

  const firstPageLoadingRef = useRef(false);
  const nextPageLoadingRef = useRef(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();
  const hPropInfo = usePropInfo();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    return () => {};
  }, []);
  // const restoreCache = () => {
  //   if (
  //     //
  //     !hUserDetailState.state.sale.lastRx ||
  //     !hUserDetailState.state.sale.lastPgInfo ||
  //     !hUserDetailState.state.sale.list
  //   )
  //     return false;

  //   cacheRef.current = {
  //     lastRx: hUserDetailState.state.sale.lastRx,
  //     lastPgInfo: hUserDetailState.state.sale.lastPgInfo,
  //     list: hUserDetailState.state.sale.list,
  //   };
  //   return true;
  // };

  const saveCache = ({
    lastRx,
    lastPgInfo,
    list,
  }: {
    lastRx: RPC_USER_ACCOUNT_POINT_HISTORY.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_USER_ACCOUNT_POINT_HISTORY.Rx['list'];
  }) => {
    cacheRef.current = {
      lastRx,
      lastPgInfo,
      list,
    };
  };

  const clearCache = () => {
    cacheRef.current = undefined;
  };

  // 마운팅됬을때(다른 메뉴에서 인벤토리 메뉴선택시)
  // or 최초 마이페이지 접근시
  useEffect(() => {
    DBGMSG('useEffect()');
    clearCache();
    fetchDataWithQuery(sQuery);

    return () => {
      DBGMSG('useEffect()');
    };
  }, []);

  /**************************************
   * !! arrow functions
   **************************************/
  const validateDateRangeOnStartChange = (start: number) => {
    try {
      const startDate = Utils.date.getDateFromYYYYMMDD(start.toString());
      const endDate = Utils.date.getDateFromYYYYMMDD(sQuery.end.toString());
      if (!startDate || !endDate) {
        return;
      }

      // start가 end 보다 클경우
      const diffInDays = Utils.date.diffIndDays(endDate, startDate);
      if (diffInDays < 0) {
        setQuery({ ...sQuery, start, end: start });
        return;
      }

      // 90일을 초과했을경우
      if (diffInDays > 90) {
        const maxEndDate = Utils.date.dateAddMonth(startDate, 3);

        const maxEnd = parseInt(Utils.date.brewYYYYMMDD(maxEndDate));
        setQuery({ ...sQuery, start, end: maxEnd });
        return;
      }
      setQuery({ ...sQuery, start });
    } catch (err) {}
  };

  const validateDateRangeOnEndChange = (end: number) => {
    try {
      const startDate = Utils.date.getDateFromYYYYMMDD(sQuery.start.toString());
      const endDate = Utils.date.getDateFromYYYYMMDD(end.toString());
      if (!startDate || !endDate) {
        return;
      }
      // start가 end 보다 클경우
      const diffInDays = Utils.date.diffIndDays(endDate, startDate);
      if (diffInDays < 0) {
        setQuery({ ...sQuery, start: end, end });
        return;
      }
      // 90일을 초과했을경우
      if (diffInDays > 90) {
        const minStartDate = Utils.date.dateSubMonth(endDate, 3);

        const minStart = parseInt(Utils.date.brewYYYYMMDD(minStartDate));
        setQuery({ ...sQuery, start: minStart, end });
        return;
      }
      setQuery({ ...sQuery, end });
    } catch (err) {}
  };

  const fetchDataWithQuery = async ({
    //
    type,
    start,
    end,
  }: {
    type?: CbtPropQueryType_e;
    start: number;
    end: number;
  }) => {
    if (firstPageLoadingRef.current) return;

    setFirstPageLoading(true);
    firstPageLoadingRef.current = true;

    try {
      const res = await hRpc.cobaltRpc.rpcUserAccountPointHistory({
        type,
        start,
        end,
      });
      saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: res.list });
      setLastRx(res);
      setList(res.list);
      DBGMSG(res.list);
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    } finally {
      setFirstPageLoading(false);
      firstPageLoadingRef.current = false;
    }
  };

  const fethcByType = async ({ type }: { type?: CbtPropQueryType_e }) => {
    if (!sQuery) return;
    await fetchDataWithQuery({ ...sQuery, type });
  };

  const fetchByDateRange = async ({ start, end }: { start: number; end: number }) => {
    await fetchDataWithQuery({ ...sQuery, start, end });
  };

  // 다음페이지 요청
  const fetchMineNextPage = async () => {
    if (!cacheRef.current) {
      DBGMSGW(`cacheRef.current is undefined`);
      return;
    }

    if (!sQuery) {
      DBGMSGW(`sQuery is undefined`);
      return;
    }

    const cursor = cacheRef.current.lastPgInfo.cursor;
    const lastPgInfo = cacheRef.current.lastPgInfo;
    const oldList = cacheRef.current.list;

    // cursor 가 있을경우 다른 조건은 무시됨 - https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699118
    try {
      setNextPageStatus('loading');
      const res = await hRpc.cobaltRpc.rpcUserAccountPointHistory({
        start: sQuery.start,
        end: sQuery.end,
        pg_info: { ...lastPgInfo },
      });

      if (res.list === null || res.list.length === 0) {
        saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: [...oldList] });
      } else {
        saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: [...oldList, ...res.list] });
      }

      setList(cacheRef.current.list);

      setNextPageStatus('');
    } catch (err) {
      setNextPageStatus('loading fail');
    }
  };

  const getLastRowId = () => {
    if (!cacheRef.current) return;
    if (cacheRef.current.lastPgInfo.last_row_id === -1 && cacheRef.current.lastPgInfo.cursor !== null) {
      DBGMSGW(`lastPgInfoRef.current.last_row_id === 1 && lastPgInfoRef.current.cursor !== null`);
    }
    return cacheRef.current.lastPgInfo.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  const scrollListener = (e: ScrollParams) => {
    // DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (!cacheRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'cacheRef.current is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return nextPageLoadingRef.current is true `);
      return;
    }

    if (atBottom) {
      nextPageLoadingRef.current = true;
      fetchMineNextPage()
        .then(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchMineNextPage succ');
        })
        .catch(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchMineNextPage fail');
        })
        .finally(() => {
          nextPageLoadingRef.current = false;
        });
    }
  };

  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  /**************************************
   * !! render function
   **************************************/
  // 판매중 아이템 렌더링
  const forSaleItemRenderer = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    if (!sList) return null;
    const index = rowIndex;
    if (index >= sList.length) return null;

    const info = sList[index];

    const zIndex = sList.length - index;

    const isIncome = info.type === CbtPropType_e.Income;
    const color = isIncome ? R.colors.green : R.colors.tomato;

    const sectionWidth = ScreenInfo.contentsWidth / 4;

    const dynamicHeight = getDynamicRowHeight(index);
    const isDateDividerNeededRet = isDateDividerNeeded(index);

    if (ScreenInfo.isMobile()) {
      return (
        <div key={key} style={style}>
          {/* <MobileScalableFlexColumn> */}
          <FlexColumnDiv
            style={{
              height: dynamicHeight,
              // ...devOutlineF
            }}
          >
            {isDateDividerNeededRet && (
              <MobileScalableFlexColumn>
                <FlexColumnDiv
                  style={{
                    height: 52,
                    // ...devOutlineF
                    justifyContent: 'flex-end',
                  }}
                >
                  {/* todo month로 변경 */}
                  <TextCon
                    text={Utils.date.brewFomatString(info.time, 'yyyy.MM.dd')}
                    size={14}
                    color={R.colors.black}
                    isBold
                    containerStyle={{ height: 20 }}
                  />
                  <HEIGHT size={7} />
                </FlexColumnDiv>
              </MobileScalableFlexColumn>
            )}
            <MobileScalableFlexColumn>
              <CommonRowBox style={{ height: 84, width: 280, marginTop: 5, marginBottom: 5 }}>
                {/* 사용/충전 */}
                <FlexRowDiv
                  style={{
                    width: 58,
                    // ...devOutlineF
                  }}
                >
                  <div
                    style={{
                      alignSelf: 'center',
                      width: 4,
                      height: 34,
                      backgroundColor: color,
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20,
                    }}
                  />
                  <WIDTH size={8} />
                  <TextCon
                    text={`${isIncome ? hR.strings.PROP_HISTORY_POINT_TYPE_INCOME : hR.strings.PROP_HISTORY_POINT_TYPE_SPEND}`}
                    color={color}
                    size={10}
                    isBold
                  />
                </FlexRowDiv>
                {/* date / 결제번호 / +- 포인트양 */}
                <FlexRowDiv
                  style={{
                    width: 161,
                    alignItems: 'center',
                    // ...devOutlineF
                  }}
                >
                  <VLINE size={50} />
                  <WIDTH size={10} />
                  <FlexColumnDiv>
                    <TextCon text={`${Utils.date.brewFomatString(info.time)}`} color={R.colors.blueGrey} size={10} />
                    <TextCon text={`${info.data.txid}`} color={R.colors.black} size={10} />
                    <FlexRowDiv>
                      <TextCon text={`${isIncome ? '+' : '-'}`} color={R.colors.black} size={10} />
                      <WIDTH size={1} />
                      <PriceSimple prc_info={info.quantity} priceFontSize={10} unitFontSize={10} isBold={false} />
                    </FlexRowDiv>
                  </FlexColumnDiv>
                </FlexRowDiv>
                <FlexRowDiv
                  style={{
                    width: 59,
                    alignItems: 'center',
                    // justifyContent: 'center',
                    // ...devOutlineF
                  }}
                >
                  <VLINE size={50} />
                  <FlexColumnDiv style={{ flex: 1, alignItems: 'center' }}>
                    <Img src={[info.ok ? R.images.history_ic_success3x : R.images.history_ic_fail3x, 16, 16]} />
                    <HEIGHT size={4} />
                    <TextCon text={`${info.ok ? hR.strings.PROP_HISTORY_SUCC : hR.strings.PROP_HISTORY_FAIL}`} color={R.colors.black} size={10} />
                  </FlexColumnDiv>
                </FlexRowDiv>
              </CommonRowBox>
            </MobileScalableFlexColumn>
          </FlexColumnDiv>

          {/* </MobileScalableFlexColumn> */}
        </div>
      );
    } else
      return (
        // 중요!
        <div key={key} style={style}>
          <FlexColumnDiv style={{ height: dynamicHeight, justifyContent: 'flex-end' }}>
            {isDateDividerNeededRet && (
              <>
                {/* todo month로 변경 */}
                <TextCon text={Utils.date.brewFomatString(info.time, 'yyyy.MM.dd')} size={18} color={R.colors.black} isBold />
                <HEIGHT size={5} />
              </>
            )}
            <CommonRowBox style={{ height: 120, width: ScreenInfo.contentsWidth, marginTop: 5, marginBottom: 5 }}>
              {/* 충전/사용  */}
              <FlexRowDiv style={{ width: sectionWidth }}>
                <div
                  style={{
                    alignSelf: 'center',
                    width: 6,
                    height: 50,
                    backgroundColor: color,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                  }}
                />
                <WIDTH size={30} />
                <TextCon
                  text={`${isIncome ? hR.strings.PROP_HISTORY_POINT_TYPE_INCOME : hR.strings.PROP_HISTORY_POINT_TYPE_SPEND}`}
                  color={color}
                  size={18}
                  isBold
                />
              </FlexRowDiv>
              {/* 날자 / 결제번호 */}
              <FlexRowDiv
                style={{
                  width: sectionWidth,
                  alignItems: 'center',
                  // ...devOutlineF
                }}
              >
                <VLINE size={'50%'} />
                <WIDTH size={30} />
                <FlexColumnDiv>
                  <TextCon text={`${Utils.date.brewFomatString(info.time)}`} color={R.colors.blueGrey} size={14} />
                  <TextCon text={`${info.data.txid}`} color={R.colors.black} size={14} />
                </FlexColumnDiv>
              </FlexRowDiv>
              {/* + - 포인트양 */}
              <FlexRowDiv
                style={{
                  width: sectionWidth,
                  alignItems: 'center',
                  // ...devOutlineF
                }}
              >
                <VLINE size={'50%'} />
                <WIDTH size={30} />
                <TextCon text={`${isIncome ? '+' : '-'}`} color={R.colors.black} size={16} />
                <WIDTH size={3} />
                <TextCon
                  text={`${Utils.currency.getPriceForDP({ prcInfo: info.quantity, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })}`}
                  color={R.colors.black}
                  size={16}
                />
                <WIDTH size={3} />
                <TextCon text={`${Utils.svc.getCurrencyUnit(info.quantity.curr_info.currency)}`} color={R.colors.black} size={16} />
              </FlexRowDiv>
              {/* 성공/실패 여부 */}
              <FlexRowDiv
                style={{
                  width: sectionWidth,
                  alignItems: 'center',
                  // ...devOutlineF
                }}
              >
                <VLINE size={'50%'} />
                <WIDTH size={30} />
                <Img src={[info.ok ? R.images.history_ic_success3x : R.images.history_ic_fail3x, 24, 24]} />
                <WIDTH size={6} />
                <TextCon text={`${info.ok ? hR.strings.PROP_HISTORY_SUCC : hR.strings.PROP_HISTORY_FAIL}`} color={R.colors.black} size={16} />
              </FlexRowDiv>
            </CommonRowBox>
          </FlexColumnDiv>
        </div>
      );
  };

  const getDynamicRowHeight = (idx: number) => {
    if (isDateDividerNeeded(idx)) {
      if (ScreenInfo.isMobile()) {
        return (94 + 52) * ScreenInfo.scale;
      } else {
        return (130 + 67) * ScreenInfo.scale;
      }
    } else {
      if (ScreenInfo.isMobile()) {
        return 94 * ScreenInfo.scale;
      } else {
        return 130 * ScreenInfo.scale;
      }
    }
  };

  const isDateDividerNeeded = (idx: number) => {
    if (!sList) {
      return false;
    }

    // 잘못된 범위
    if (sList.length <= idx || idx < 0) {
      return false;
    }

    // 첫번째 항목
    if (idx === 0) {
      return true;
    }

    // 1개 일때
    if (sList.length === 1) {
      return true;
    }

    // const startIdx = 0;
    // const lastIdx = sList.length - 1;

    const prevIdx = idx - 1;

    // 첫번째 항목
    if (prevIdx < 0) {
      DBGMSGW(`isDateDividerNeeded what`);
    }

    const prevItem = sList[prevIdx];
    const targetItem = sList[idx];
    const prevItemDate = Utils.date.getDate(prevItem.time);
    const targetItemDate = Utils.date.getDate(targetItem.time);

    if (prevItemDate === undefined || targetItemDate === undefined) {
      return false;
    }

    // todo month로 변경
    const isSame = Utils.date.isSameDay(prevItemDate, targetItemDate);
    // DBGMSG('isDateDividerNeeded isSame: ', isSame);
    if (isSame) {
      return false;
    } else {
      return true;
    }
  };

  const renderAssetList = () => {
    if (!sList) return null;

    return (
      <FlexColumnDiv
        style={{
          width: ScreenInfo.contentsWidth * ScreenInfo.scale,
          alignSelf: 'center',
          userSelect: 'none',
          // ...devOutlineF,
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <FlexColumnDiv>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <Grid
                      onScroll={(params) => {
                        onChildScroll(params);
                        throttleScrollListener(params);
                      }}
                      isScrolling={isScrolling}
                      autoHeight
                      cellRenderer={forSaleItemRenderer}
                      columnWidth={ScreenInfo.contentsWidth * ScreenInfo.scale}
                      columnCount={1}
                      height={height}
                      // noContentRenderer={this._noContentRenderer}
                      overscanColumnCount={5}
                      overscanRowCount={5}
                      rowHeight={({ index }) => {
                        return getDynamicRowHeight(index);
                      }}
                      // rowCount={Math.ceil(collectionList.length)}
                      rowCount={sList!.length}
                      // rowCount={1}
                      // scrollToColumn={scrollToColumn}
                      // scrollToRow={scrollToRow}
                      scrollTop={scrollTop}
                      width={ScreenInfo.contentsWidth * ScreenInfo.scale}
                    />
                  </div>
                )}
              </AutoSizer>
            </FlexColumnDiv>
          )}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  const reanderListWrapper = () => {
    if (sFirstPageLoading || sFirstPageLoading === undefined) {
      return (
        <MobileScalableFlexColumn>
          <FlexColumnDiv style={{ minHeight: 400, width: ScreenInfo.contentsWidth }}>
            <DataFetching />
          </FlexColumnDiv>
        </MobileScalableFlexColumn>
      );
    } else {
      if (!sList) {
        DBGMSGW('what');
        return null;
      }

      if (sList.length === 0) {
        return (
          <MobileScalableFlexColumn>
            <FlexColumnDiv style={{ minHeight: 400, width: ScreenInfo.contentsWidth, justifyContent: 'center', alignItems: 'center' }}>
              <TextCon
                text={hR.strings.PROP_HISTORY_QUERY_NOT_FONUD}
                isBold
                color={R.colors.blueGrey}
                textStyle={{ textAlign: 'center' }}
                size={CLAY.DEFAULT_FONT_SIZE}
              />
            </FlexColumnDiv>
          </MobileScalableFlexColumn>
        );
      } else {
        return renderAssetList();
      }
    }
  };

  if (!sLastRx) {
    return null;
  }

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <CommonColumnBox
            style={{
              width: 280,
              height: 330,
              alignItems: 'stretch',
              marginLeft: MOBILE_HORIZONTAL_PADDING,
              marginRight: MOBILE_HORIZONTAL_PADDING,
            }}
          >
            <ProperyOverview
              //
              balanceTxt={hR.strings.PROP_HISTORY_POINT_BALANCE}
              incomeTxt={hR.strings.PROP_HISTORY_POINT_INCOME}
              spendTxt={hR.strings.PROP_HISTORY_POINT_SPEND}
              balance={sLastRx.balance}
              income={sLastRx.income}
              spend={sLastRx.spend}
            />
            <HLINE size={'100%'} />
            <ProperyQuery
              queryTypeTxt={{
                all: hR.strings.PROP_HISTORY_POINT_QUERY_TYPE_ALL,
                income: hR.strings.PROP_HISTORY_POINT_QUERY_TYPE_INCOME,
                spend: hR.strings.PROP_HISTORY_POINT_QUERY_TYPE_SPEND,
              }}
              queryType={sQuery.type}
              onQueryType={(selectedType) => {
                setQuery({ ...sQuery, type: selectedType });
              }}
              onQueryBtnClick={() => {
                // todo if start > end
                fetchDataWithQuery(sQuery);
              }}
              start={sQuery.start}
              onStart={(start) => {
                validateDateRangeOnStartChange(start);
              }}
              end={sQuery.end}
              onEnd={(end) => {
                validateDateRangeOnEndChange(end);
              }}
            />
          </CommonColumnBox>
        </MobileScalableFlexColumn>
        {reanderListWrapper()}
      </>
    );
  } else {
    return (
      <>
        <CommonColumnBox
          style={{
            width: ScreenInfo.contentsWidth,
            height: 267,
            alignItems: 'stretch',
            zIndex: 2,
          }}
        >
          <ProperyOverview
            //
            balanceTxt={hR.strings.PROP_HISTORY_POINT_BALANCE}
            incomeTxt={hR.strings.PROP_HISTORY_POINT_INCOME}
            spendTxt={hR.strings.PROP_HISTORY_POINT_SPEND}
            balance={sLastRx.balance}
            income={sLastRx.income}
            spend={sLastRx.spend}
          />
          <HLINE size={'100%'} />
          <ProperyQuery
            queryTypeTxt={{
              all: hR.strings.PROP_HISTORY_POINT_QUERY_TYPE_ALL,
              income: hR.strings.PROP_HISTORY_POINT_QUERY_TYPE_INCOME,
              spend: hR.strings.PROP_HISTORY_POINT_QUERY_TYPE_SPEND,
            }}
            queryType={sQuery.type}
            onQueryType={(selectedType) => {
              setQuery({ ...sQuery, type: selectedType });
            }}
            onQueryBtnClick={() => {
              // todo if start > end
              fetchDataWithQuery(sQuery);
            }}
            start={sQuery.start}
            onStart={(start) => {
              validateDateRangeOnStartChange(start);
            }}
            end={sQuery.end}
            onEnd={(end) => {
              validateDateRangeOnEndChange(end);
            }}
          />
        </CommonColumnBox>

        {reanderListWrapper()}
      </>
    );
  }
}
