/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CSSProperties, HTMLProps } from 'react';
import { config, useSpring } from 'react-spring';
import { DBGMSG } from 'src/util/utils';

export type animProps = {
  defProps: CSSProperties;
  enterProps: CSSProperties;
};

export function useMouseEnterEffect({ defProps, enterProps }: animProps) {
  const [hAnimProps, set] = useSpring(() => {
    return {
      ...defProps,
      config: { ...config.gentle, duration: 50 },
    };
  });

  const mouseEnter: HTMLProps<HTMLDivElement>['onMouseEnter'] = () => {
    DBGMSG('mouseEnter');
    set({
      ...enterProps,
      // ...defProps,
    });
  };
  
  const mouseLeave: HTMLProps<HTMLDivElement>['onMouseLeave'] = () => {
    DBGMSG('mouseLeave');
    set({
      // ...enterProps,
      ...defProps,
    });
  };
  return {
    hAnimProps,
    mouseEnter,
    mouseLeave,
  };
}
