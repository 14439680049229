/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useQsLang } from 'src/hooks/useQsLang';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { CLAY } from 'src/ui/layout_constant';
import { renderMultiLine, renderSectionTitle, renderTitle } from 'src/ui/screen/User/UserRefundPolicyScreen';
import { TERMS_OF_USE_DESC_FONTSIZE } from 'src/ui/screen/User/UserTermsOfUseScreen';
import { Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserPrivacyPolicyScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`PrivacyPolicy - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hR = useLocalSettings();

  /**************************************
   * !! hooks
   **************************************/
  useQsLang();

  const render = () => {
    if (hR.lang === CbtUserLanguage_e.KO) {
      return (
        <>
          {renderTitle(R.privacyPolicy.ko.TITLE)}
          {renderMultiLine(R.privacyPolicy.ko.DESC)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_1)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_1)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_2)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_2)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_3)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_3)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_4)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_4)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_5)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_5)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_6)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_6)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_7)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_7)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_8)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_8)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_9)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_9)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_10)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_10)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_11)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_11)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_12)}
          {renderMultiLine(R.privacyPolicy.ko.DETAIL_12)}
          {renderSectionTitle(R.privacyPolicy.ko.TITLE_13)}

          <TextCon text={R.privacyPolicy.ko.DETAIL_13_1} size={TERMS_OF_USE_DESC_FONTSIZE} color={R.colors.black} />
          <FlexRowDiv>
            <TextCon
              text={Utils.string.parsePlaceholder(R.privacyPolicy.ko.DETAIL_13_2, 'support@cobalt.zone').begin}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
            />

            <TextCon
              text={`support@cobalt.zone`}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.purpleishBlue}
              isUnderline
              onConClick={() => {
                window.location.href = `mailto:${Constants.SUPPORT_EMAIL_ADDRESS}`;
              }}
            />
            <TextCon
              text={Utils.string.parsePlaceholder(R.privacyPolicy.ko.DETAIL_13_2, 'support@cobalt.zone').end}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
            />
          </FlexRowDiv>

          <HEIGHT size={30} />
          <TextCon text={R.privacyPolicy.ko.UPDATED} size={TERMS_OF_USE_DESC_FONTSIZE} color={R.colors.black} />
        </>
      );
    } else if (hR.lang === CbtUserLanguage_e.JA) {
      return (
        <>
          {renderTitle(R.privacyPolicy.ja.TITLE)}
          {renderMultiLine(R.privacyPolicy.ja.DESC)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_1)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_1)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_2)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_2)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_3)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_3)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_4)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_4)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_5)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_5)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_6)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_6)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_7)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_7)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_8)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_8)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_9)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_9)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_10)}
          {renderMultiLine(R.privacyPolicy.ja.DETAIL_10)}
          {renderSectionTitle(R.privacyPolicy.ja.TITLE_11)}

          <TextCon text={R.privacyPolicy.ja.DETAIL_11_1} size={TERMS_OF_USE_DESC_FONTSIZE} color={R.colors.black} />
          <FlexRowDiv>
            <TextCon
              text={Utils.string.parsePlaceholder(R.privacyPolicy.ja.DETAIL_11_2, 'support@cobalt.zone').begin}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
            />

            <TextCon
              text={`support@cobalt.zone`}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.purpleishBlue}
              isUnderline
              onConClick={() => {
                window.location.href = `mailto:${Constants.SUPPORT_EMAIL_ADDRESS}`;
              }}
            />
            <TextCon
              text={Utils.string.parsePlaceholder(R.privacyPolicy.ja.DETAIL_11_2, 'support@cobalt.zone').end}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
            />
          </FlexRowDiv>

          <HEIGHT size={30} />
          <TextCon text={R.privacyPolicy.ja.UPDATED} size={TERMS_OF_USE_DESC_FONTSIZE} color={R.colors.black} />
        </>
      );
    } else {
      return (
        <>
          {renderTitle(R.privacyPolicy.en.TITLE)}
          {renderMultiLine(R.privacyPolicy.en.DESC)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_1)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_1)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_2)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_2)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_3)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_3)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_4)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_4)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_5)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_5)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_6)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_6)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_7)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_7)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_8)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_8)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_9)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_9)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_10)}
          {renderMultiLine(R.privacyPolicy.en.DETAIL_10)}
          {renderSectionTitle(R.privacyPolicy.en.TITLE_11)}

          <TextCon text={R.privacyPolicy.en.DETAIL_11_1} size={TERMS_OF_USE_DESC_FONTSIZE} color={R.colors.black} />
          <FlexRowDiv>
            <TextCon
              text={Utils.string.parsePlaceholder(R.privacyPolicy.en.DETAIL_11_2, 'support@cobalt.zone').begin}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
            />

            <TextCon
              text={`support@cobalt.zone`}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.purpleishBlue}
              isUnderline
              onConClick={() => {
                window.location.href = `mailto:${Constants.SUPPORT_EMAIL_ADDRESS}`;
              }}
            />
            <TextCon
              text={Utils.string.parsePlaceholder(R.privacyPolicy.en.DETAIL_11_2, 'support@cobalt.zone').end}
              size={TERMS_OF_USE_DESC_FONTSIZE}
              color={R.colors.black}
            />
          </FlexRowDiv>

          <HEIGHT size={30} />
          <TextCon text={R.privacyPolicy.en.UPDATED} size={TERMS_OF_USE_DESC_FONTSIZE} color={R.colors.black} />
        </>
      );
    }
  };
  return (
    <MobileScalableFlexColumn>
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: CLAY.PAGE_WIDTH,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          paddingLeft: CLAY.HPADDING,
          paddingRight: CLAY.HPADDING,
          ...devOutline,
        }}
      >
        {render()}
        <HEIGHT size={200} />
      </FlexColumnDiv>
      {ScreenInfo.isMobile() && <CommonFooter />}
    </MobileScalableFlexColumn>
  );
}
