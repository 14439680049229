/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useFileCheck } from 'src/hooks/useFileCheck';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { useSubscribeStompMsg } from 'src/hooks/useSubscribeStompMsg';
import { AttributeInfoWithValue_i, DisplaySrcForm_t, DisplaySrc_t } from 'src/model/model';
import { CbtAssetType_e, CbtAttributeType_e, CbtAttributeValue_i, CbtEditionType_e, CbtIssueType_e } from 'src/model/rpcModel';
import { RPC_ASSET_CREATOR_SCHEMA_VIEW } from 'src/model/rpcType';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { CbtStompMsgCode_e } from 'src/rx/messageService';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { AssetDisplay } from 'src/ui/common_component/AssetDisplay/AssetDisplay';
import { AssetDisplayThumb, WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RadioBtn } from 'src/ui/common_component/button/RadioBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { SchemaAttributeInput } from 'src/ui/common_component/SchemaAttributeInput';
import { SRoundInputNumber } from 'src/ui/common_component/SRoundInputNumber';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { Title } from 'src/ui/common_component/Title';
import { CLAY, CommonColumnBox, COMMON_BOX_HORIZONTAL_PADDING } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { fileUpload } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const ASSETDISPLAY_THUMB_CNT = ScreenInfo.isMobile() ? 2 : 4;

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MyAssetGroupCScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sFetchedData, setFetchedData] = useState<RPC_ASSET_CREATOR_SCHEMA_VIEW.Rx>();
  const [sIsOverLimit, setIsOverLimit] = useState(false);

  // ------------
  const [sDisplaySrcFormList, setDisplaySrcFormList] = useState<DisplaySrcForm_t[]>();
  const [sDisplaySrcList, setDisplaySrcList] = useState<DisplaySrc_t[]>();
  const [sSelectedDisplayThumbIdx, setSelectedDisplayThumbIdx] = useState(0); // 선택되어 있는 thumb idx
  const [sSliderCurIdx, setSliderCurIdx] = useState(0); // Slider의 idx
  // ------------

  const [sOwner, setOwner] = useState<string>(''); // 에셋 소유자
  const [sMintCnt, setMintCnt] = useState<string>('1'); // 발행 매수
  const [sEditionType, setEditionType] = useState(CbtEditionType_e.LIMITED); // 추가 발행 가능 여부
  const [sAttributeList, setAttributeList] = useState<(AttributeInfoWithValue_i & { inputElementRef?: HTMLInputElement })[]>([]); // 속성
  const [sCheckBoxTerms1, setCheckBoxTerms1] = useState(false); // 동의 1
  const [sCheckBoxTerms2, setCheckBoxTerms2] = useState(false); // 동의 2

  // 생성버튼
  const [sCreateBtnEnabled, setCreateBtnEnabled] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const qsRef = useRef<{ sid: number }>();
  const fileInputRefList = useRef<(HTMLInputElement | null)[]>([]);
  const assetDisplayThumbListSliderRef = useRef<Slider>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hFileChecker = useFileCheck();
  // useCheckUserCertTpAndBlock();

  useSubscribeStompMsg({
    onMsg: (stompMsg) => {
      DBGMSG(`onMsg`);
      reFetchData();
    },
    targetCodeList: [CbtStompMsgCode_e.ASSET_SCHEMA_EDIT_SUCCESS],
  });

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid) {
      DBGMSG(`$$$$QS schema_id: ${query.sid}`);
      const sid = parseInt(query.sid);
      qsRef.current = { sid };
      fetchData(sid);
    } else {
      hHistory.replace(Nav.AssetCreatorScreen);
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    setIsOverLimit(false);
    if (sMintCnt.length > 0) {
      if (parseInt(sMintCnt) <= 10 && parseInt(sMintCnt) > 0) {
        setCreateBtnEnabled(true);
      } else {
        setIsOverLimit(true);
        setCreateBtnEnabled(false);
      }
    } else {
      setCreateBtnEnabled(false);
    }
    return () => {};
  }, [sMintCnt]);

  useEffect(() => {
    if (sIsOverLimit) {
      setCreateBtnEnabled(false);
      return;
    }

    if (!sCheckBoxTerms1 || !sCheckBoxTerms2) {
      DBGMSG('setCreateBtnEnabled(false);');
      setCreateBtnEnabled(false);
      return;
    }

    const reqAttList = sAttributeList.filter((att) => Utils.svc.isReqAttrName(att.name));
    const isNotEmpty = reqAttList.every((att) => att.value !== undefined);
    if (isNotEmpty) {
      DBGMSG('setCreateBtnEnabled(true);');
      setCreateBtnEnabled(true);
    } else {
      DBGMSG('setCreateBtnEnabled(false);');
      setCreateBtnEnabled(false);
    }

    return () => {};
  }, [sAttributeList, sCheckBoxTerms1, sCheckBoxTerms2, sIsOverLimit]);

  /**************************************
   * !! arrow function
   **************************************/
  const reFetchData = () => {
    if (!qsRef.current) {
      return;
    }
    fetchData(qsRef.current.sid);
  };

  const fetchData = async (sid: number) => {
    const res = await hRpc.cobaltRpc.rpcAssetCreatorSchemaView({ sid });
    DBGMSG(res);
    setFetchedData(res);

    // 파일 input 리스트 null 초기화
    for (let i = 0; i < res.schm_info.attributes.length; i++) {
      fileInputRefList.current.push(null);
    }

    // 전체 속성 리스트
    setAttributeList(res.schm_info.attributes.map((att) => ({ ...att })));
    DBGMSG(res.schm_info.attributes);

    // 플레이 가능한 속성 리스트의 입력폼
    const playerSrcFormList = Utils.svc.brewDisplaySrcFormList({ assetType: res.schm_info.asset_type, attrList: res.schm_info.attributes });
    DBGMSG(playerSrcFormList);
    setDisplaySrcFormList(playerSrcFormList);

    // 디폴트 소유자
    setOwner(hAuth.authCommonRes?.ucode ?? '');
  };

  // 디스플레이 소스 업데이트
  const updateDisplaySrc = (targetAttrName: string, srcUrl?: string) => {
    if (!sDisplaySrcFormList) return;

    let isReqAttr = false;

    // 필수 속성인지 체크
    if (
      targetAttrName === Constants.NFT_REQ_ATTRNAME_IMAGE ||
      targetAttrName === Constants.NFT_REQ_ATTRNAME_AUDIO ||
      targetAttrName === Constants.NFT_REQ_ATTRNAME_VIDEO
    ) {
      isReqAttr = true;
    }

    if (isReqAttr) {
      // 필수 속성인 경우
      // 1. find
      const foundReqDisplaySrc = sDisplaySrcFormList.find((src) => {
        if (src.kind === 'ReqImage' || src.kind === 'ReqAudio' || src.kind === 'ReqVideo') {
          return true;
        }
        return false;
      });
      if (!foundReqDisplaySrc) {
        DBGMSGW(`foundReqDisplaySrc is not found`);
        return;
      }

      // 2. set
      switch (foundReqDisplaySrc.kind) {
        case 'ReqImage':
          foundReqDisplaySrc.img = srcUrl;
          break;
        case 'ReqAudio':
          if (targetAttrName === Constants.NFT_REQ_ATTRNAME_IMAGE) {
            foundReqDisplaySrc.img = srcUrl;
          } else if (targetAttrName === Constants.NFT_REQ_ATTRNAME_AUDIO) {
            foundReqDisplaySrc.audio = srcUrl;
          } else {
            DBGMSGW('error');
          }
          break;
        case 'ReqVideo':
          if (targetAttrName === Constants.NFT_REQ_ATTRNAME_IMAGE) {
            foundReqDisplaySrc.img = srcUrl;
          } else if (targetAttrName === Constants.NFT_REQ_ATTRNAME_VIDEO) {
            foundReqDisplaySrc.video = srcUrl;
          } else {
            DBGMSGW('error');
          }
          break;
      }
    } else {
      // 추가 속성인 경우
      // 1. find
      const foundOptDisplaySrc = sDisplaySrcFormList.find((src) => {
        if (src.kind === 'OptImage' || src.kind === 'OptAudio' || src.kind === 'OptVideo') {
          return src.name === targetAttrName;
        }
        return false;
      });
      if (!foundOptDisplaySrc) {
        DBGMSGW(`foundOptDisplaySrc is not found`);
        return;
      }

      DBGMSG(foundOptDisplaySrc);

      // 2. set
      switch (foundOptDisplaySrc.kind) {
        case 'OptImage':
          foundOptDisplaySrc.img = srcUrl;
          break;
        case 'OptAudio':
          foundOptDisplaySrc.audio = srcUrl;
          break;
        case 'OptVideo':
          foundOptDisplaySrc.video = srcUrl;
          break;
      }
      DBGMSG(foundOptDisplaySrc);
    }

    setDisplaySrcFormList(sDisplaySrcFormList);
    DBGMSG(sDisplaySrcFormList);

    // 플레이 가능한 리스트만 필터링
    const displaySrcList = Utils.svc.filterDisplaySrcList(sDisplaySrcFormList);

    setDisplaySrcList(displaySrcList);
    DBGMSG(displaySrcList);
  };

  // 생성
  const handleCreateBtn = async () => {
    if (!sFetchedData) return;

    const filteredList1 = sAttributeList.filter((att) => att.value !== undefined);
    const filteredList2 = filteredList1.map<CbtAttributeValue_i>((att) => ({ name: att.name, value: att.value! }));

    try {
      await hRpc.cobaltRpc.rpcAssetCreatorAdd({
        sid: sFetchedData.schm_info.sid,
        supply: parseInt(sMintCnt),
        owner: sOwner,
        issue_type: CbtIssueType_e.INIT,
        edition_type: sEditionType,
        attr_values: filteredList2,
      });

      hDlgFactory.showAsyncGuideJsx({
        title: hR.strings.DLG_ASYNC_ASSET_CREATE_DLG_REQ_TITLE,
        text: hR.strings.DLG_ASYNC_ASSET_CREATE_DLG_REQ_TXT,
        onOk: () => {
          hDlgFactory.closeAsyncGuideJsx();
          if (!qsRef.current) return;
          hHistory.replace(Nav.brewSchemaRScreenPath({ sid: qsRef.current.sid }));
        },
      });
    } catch (e) {}
  };

  const handleFileInputOnChange = async ({
    e,
    attr,
    idx,
  }: {
    e: React.ChangeEvent<HTMLInputElement> | undefined;
    attr: AttributeInfoWithValue_i & {
      inputElementRef?: HTMLInputElement | undefined;
    };
    idx: number;
  }) => {
    if (!e) return;
    if (!sFetchedData) return;

    hDlgFactory.showFileUploading();
    // hDlg.act.showDlgLoading({});

    const { localUrl, fileObj, type } = await Utils.file.loadInputFile(e);
    DBGMSG(`$$$$INPUT  localUrl: ${localUrl}`);
    DBGMSG(`$$$$INPUT file.name: ${fileObj.name}`);
    DBGMSG(`$$$$INPUT file.size: ${fileObj.size.toLocaleString()}`);
    DBGMSG(`$$$$INPUT file.type: ${fileObj.type}`);
    DBGMSG(`$$$$INPUT      type: ${type}`);

    let result = false;
    switch (attr.type) {
      case CbtAttributeType_e.IMAGE:
        if (Utils.svc.isReqAttrName(attr.name) && sFetchedData.schm_info.asset_type === CbtAssetType_e.VIDEO) {
          result = await hFileChecker.checkFileSpec({
            target: { localUrl, fileObj, fileType: type },
            spec: { imgWidth: 600, imgHeight: 600, sizeInMB: 5, fileTypeList: ['png', 'jpg'] },
          });
        } else if (Utils.svc.isReqAttrName(attr.name) && sFetchedData.schm_info.asset_type === CbtAssetType_e.AUDIO) {
          result = await hFileChecker.checkFileSpec({
            target: { localUrl, fileObj, fileType: type },
            spec: { imgWidth: 400, imgHeight: 400, sizeInMB: 5, fileTypeList: ['png', 'jpg', 'gif', 'apng'] },
          });
        } else {
          result = await hFileChecker.checkFileSpec({
            target: { localUrl, fileObj, fileType: type },
            spec: { imgWidth: 600, imgHeight: 600, sizeInMB: 10, fileTypeList: ['png', 'jpg', 'gif', 'apng'] },
          });
        }
        break;
      case CbtAttributeType_e.AUDIO:
        result = await hFileChecker.checkFileSpec({
          target: { localUrl, fileObj, fileType: type },
          spec: { sizeInMB: 10, fileTypeList: ['mp3'] },
        });
        break;
      case CbtAttributeType_e.VIDEO:
        result = await hFileChecker.checkFileSpec({
          target: { localUrl, fileObj, fileType: type },
          spec: { vidWidth: 600, vidHeight: 600, sizeInMB: 100, fileTypeList: ['mp4'] },
        });
        break;
    }
    if (result) {
      fileUpload(fileObj)
        .then((res) => {
          DBGMSG(res);
          sAttributeList[idx].value = res;
          setAttributeList([...sAttributeList]);
          updateDisplaySrc(attr.name, localUrl);
          // hDlg.act.closeDlgLoading();
          hDlgFactory.hideFileUploading();
        })
        .catch((e) => {
          DBGMSGW(e);
        });
    } else {
      if (fileInputRefList.current[idx]) fileInputRefList.current[idx]!.value = '';
      // hDlg.act.closeDlgLoading();
      hDlgFactory.hideFileUploading();
    }
  };

  const handleInputOnChange = ({
    e,
    attr,
    idx,
  }: {
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    attr: AttributeInfoWithValue_i & {
      inputElementRef?: HTMLInputElement | undefined;
    };
    idx: number;
  }) => {
    // Number 일때
    if (e.target.value.length > 0 && attr.type === CbtAttributeType_e.NUMBER) {
      // 숫자만 가능
      const regex: RegExp = Constants.REGEXP_IS_VALID_NUM;
      const regexTestRet = regex.test(e.target.value);
      DBGMSG(`$$$REGEX ${regex}: ${e.target.value} ${regexTestRet}`);
      if (!regexTestRet) {
        DBGMSG(`$$$REGEX regexTestRet: ${regexTestRet}`);
        return;
      }
    }

    // Text 일때
    else if (attr.type === CbtAttributeType_e.TEXT) {
      // nft 이름일경우
      if (Utils.svc.isReqAttrName(attr.name)) {
        if (e.target.value.length > 20) {
          return;
        }
      }
    }

    sAttributeList[idx].value = e.target.value;
    setAttributeList([...sAttributeList]);
  };

  /**************************************
   * !! render function
   **************************************/

  const renderAttributeForm = () => {
    if (!sFetchedData) {
      return null;
    }

    return (
      <>
        {sAttributeList.map((attr, idx) => {
          DBGMSG(`name: ${attr.name}`);
          DBGMSG(`type: ${attr.type}`);

          switch (attr.type) {
            case CbtAttributeType_e.TEXT:
            case CbtAttributeType_e.NUMBER:
              return (
                <SchemaAttributeInput
                  idx={idx}
                  attrName={attr.name}
                  attrValue={`${attr.value ?? ''}` as string | undefined}
                  attrType={attr.type}
                  onBlur={() => {}}
                  onChange={(e) => {
                    handleInputOnChange({ e, attr, idx });
                  }}
                  onKeyDown={(e) => {}}
                />
              );

            case CbtAttributeType_e.IMAGE:
            case CbtAttributeType_e.AUDIO:
            case CbtAttributeType_e.VIDEO:
              return (
                <FlexColumnDiv>
                  <SchemaAttributeInput
                    assetType={sFetchedData.schm_info.asset_type}
                    attrName={attr.name}
                    attrType={attr.type}
                    attrValue={attr.value}
                    onFileSelect={() => {
                      if (fileInputRefList.current[idx]) {
                        const inputRef = fileInputRefList.current[idx];
                        if (inputRef !== null) {
                          inputRef.click();
                        }
                      }
                    }}
                    isFileSelected={sAttributeList[idx].value !== undefined}
                    onDeleteClick={() => {
                      sAttributeList[idx].value = undefined;
                      setAttributeList([...sAttributeList]);
                      updateDisplaySrc(attr.name, undefined);

                      setSelectedDisplayThumbIdx(0);
                    }}
                  />
                  <input
                    ref={(element) => {
                      fileInputRefList.current[idx] = element;
                    }}
                    style={{ display: 'none' }}
                    type={'file'}
                    accept={(function (type: CbtAttributeType_e) {
                      switch (type) {
                        case CbtAttributeType_e.IMAGE:
                          return Constants.NFT_IMG_ACCEPT_FILETYPE.map((ext) => '.' + ext).join(', ');
                        case CbtAttributeType_e.AUDIO:
                          return Constants.NFT_AUDIO_ACCEPT_FILETYPE.map((ext) => '.' + ext).join(', ');
                        case CbtAttributeType_e.VIDEO:
                          return Constants.NFT_VIDEO_ACCEPT_FILETYPE.map((ext) => '.' + ext).join(', ');
                      }
                    })(attr.type)}
                    onChange={async (e) => {
                      handleFileInputOnChange({ e, attr, idx });
                    }}
                  />
                </FlexColumnDiv>
              );
          }
        })}
      </>
    );
  };

  const renderPolicy = () => {
    return hR.strings.AC_ASSET_CREATE_TERMS2_DETAIL.split('\n').map((substr) => {
      return (
        <>
          <FlexRowDiv style={{ alignItems: 'flex-start' }}>
            <TextCon
              text={substr.slice(0, 2)}
              size={ScreenInfo.isMobile() ? 10 : 12}
              color={R.colors.blueGrey}
              lineSpace={ScreenInfo.isMobile() ? 15 : 22}
              containerStyle={
                {
                  //  ...devOutlineFG
                }
              }
            />
            <TextCon
              text={substr.slice(2)}
              size={ScreenInfo.isMobile() ? 10 : 12}
              color={R.colors.blueGrey}
              // textStyle={{}}
              lineSpace={ScreenInfo.isMobile() ? 15 : 22}
              containerStyle={
                {
                  //  ...devOutlineFG
                }
              }
            />
          </FlexRowDiv>
          <HEIGHT size={5} />
        </>
      );
    });
  };

  /**************************************
   * !! render conf
   **************************************/

  if (!sDisplaySrcFormList) {
    return null;
  }

  if (!sFetchedData) return <DataFetching />;

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <FlexColumnDiv
            style={{
              backgroundColor: R.colors.bgGrey,
              width: MOBILE_WIDTH,
              alignItems: 'flex-start',
              paddingTop: 20,
              paddingLeft: CLAY.HPADDING,
              paddingRight: CLAY.HPADDING,
              ...devOutline,
            }}
          >
            <BackBtn />
            <Title title={hR.strings.AC_ASSET_CREATE_TITLE} />

            <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

            {/* 박스 */}
            <CommonColumnBox style={{ width: 280, alignItems: 'flex-start' }}>
              <HEIGHT size={34} />
              {/******************************************
               * AssetDisplay
               *******************************************/}
              <FlexColumnDiv
                style={{
                  width: 248,
                  height: 248,
                  alignSelf: 'center',
                  borderRadius: 10,
                  borderColor: R.colors.line,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  overflow: 'hidden',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: R.colors.bgGrey,
                  // ...devOutlineF,
                }}
              >
                {sDisplaySrcList !== undefined && sDisplaySrcList.length > 0 ? (
                  <AssetDisplay
                    key={`${sSelectedDisplayThumbIdx}`}
                    playerSrc={sDisplaySrcList[sSelectedDisplayThumbIdx]}
                    width={248}
                    height={248}
                    scaleIfAudio={0.9}
                  />
                ) : (
                  (function () {
                    switch (sFetchedData?.schm_info.asset_type) {
                      case CbtAssetType_e.AUDIO:
                        return (
                          <AssetDisplay key={''} playerSrc={{ kind: 'ReqAudio', audio: '', img: '' }} width={236} height={236} scaleIfAudio={0.9} />
                        );
                      default:
                        return <TextCon text={hR.strings.AC_ASSET_CREATE_PREVIEW} size={14} isBold color={R.colors.cloudyBlue} />;
                    }
                  })()
                )}
              </FlexColumnDiv>
              {/* AssetDisplay end */}

              {/*******************************************
               * AssetDisplay Thumb 리스트
               *******************************************/}
              {sDisplaySrcList !== undefined && sDisplaySrcList.length > 0 && (
                <>
                  <HEIGHT size={16} />

                  <FlexRowDiv style={{ width: 280, justifyContent: 'center', alignItems: 'center' }}>
                    <ImgBtn
                      src={[sSliderCurIdx === 0 ? R.images.common_btn_back_disabled3x : R.images.common_btn_back_abled3x, 30, 30]}
                      onClick={() => {
                        if (sSliderCurIdx === 0) return;
                        assetDisplayThumbListSliderRef.current?.slickPrev();
                      }}
                    />
                    <FlexColumnDiv
                      // {...hDrag()}
                      style={{
                        width: 180,
                        overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤?이 생김
                        justifyContent: 'center',
                        // ...devOutlineF,
                      }}
                    >
                      <Slider
                        // ref={assetDisplayThumbListSliderRef}
                        infinite={false}
                        speed={400}
                        slidesToShow={2}
                        swipeToSlide={true}
                        slidesToScroll={1}
                        beforeChange={(cur, next) => {
                          DBGMSG(`cur:${cur} next:${next}`);
                          setSliderCurIdx(next);
                        }}
                        afterChange={(cur) => {
                          DBGMSG(`cur:${cur}`);
                          setSliderCurIdx(cur);
                        }}
                      >
                        {sDisplaySrcList.map((displaySrc, idx) => {
                          return (
                            <AssetDisplayThumb
                              whereToUse={WhereToUse_e.ForSlider}
                              key={`${idx}`}
                              width={80}
                              height={80}
                              playerSrc={displaySrc}
                              onClick={() => {
                                setSelectedDisplayThumbIdx(idx);
                              }}
                              selected={sSelectedDisplayThumbIdx === idx}
                            />
                          );
                        })}
                      </Slider>
                    </FlexColumnDiv>
                    <ImgBtn
                      src={[
                        sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length
                          ? R.images.common_btn_next_disabled3x
                          : R.images.common_btn_next_abled3x,

                        30,
                        30,
                      ]}
                      onClick={() => {
                        if (sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length) return;
                        assetDisplayThumbListSliderRef.current?.slickNext();
                      }}
                    />
                  </FlexRowDiv>
                </>
              )}
              {/* AssetDisplay 리스트 end */}

              <HEIGHT size={34} />

              {/* 에셋 소유자 */}
              <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_ASSET_CREATE_OWNER} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                </FlexRowDiv>
                <HEIGHT size={CLAY.FORM_INPUT_TOP_MARGIN} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sOwner}
                  onChange={(e) => {
                    setOwner(e.target.value);
                  }}
                  // disabled={true}
                />

                <HEIGHT size={20} />
              </FlexColumnDiv>

              <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
                {/* 발행 매수 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_CNT} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_CNT_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                  <WIDTH size={5} />
                  {/* 툴팁 ic */}
                  <TooltipImg tooltipString={hR.strings.AC_ASSET_CREATE_TOOLTIP_MINT} placement={'right-start'} />
                </FlexRowDiv>
                <HEIGHT size={CLAY.FORM_INPUT_TOP_MARGIN} />
                <SRoundInputNumber
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sMintCnt}
                  placeHolder={'0'}
                  onChange={(e) => {
                    const regex: RegExp = Constants.REGEXP_IS_VALID_INT;
                    const regexTestRet = regex.test(e.target.value);
                    DBGMSG(`$$$REGEX ${regex}: ${e.target.value} ${regexTestRet}`);
                    if (!regexTestRet) {
                      DBGMSG(`$$$REGEX regexTestRet: ${regexTestRet}`);
                      return;
                    }
                    setMintCnt(e.target.value);
                  }}
                />
                <HEIGHT size={12} />
                <FlexRowDiv>
                  {/* 추가 발행여부 radio 버튼 */}
                  <FlexRowDiv
                    style={{
                      //
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      ...devOutline,
                    }}
                    onClick={() => {
                      DBGMSG('추가 발행 없음');
                      setEditionType(CbtEditionType_e.LIMITED);
                    }}
                  >
                    <RadioBtn checked={sEditionType === CbtEditionType_e.LIMITED} />
                    <WIDTH size={4} />
                    <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_NO_MORE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                  </FlexRowDiv>
                  <WIDTH size={53} />
                  <FlexRowDiv
                    style={{
                      //
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      ...devOutline,
                    }}
                    onClick={() => {
                      DBGMSG('추가 발행 가능');
                      setEditionType(CbtEditionType_e.REPRINTING);
                    }}
                  >
                    <RadioBtn checked={sEditionType === CbtEditionType_e.REPRINTING} />
                    <WIDTH size={4} />
                    <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_MORE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                  </FlexRowDiv>
                </FlexRowDiv>
                <HEIGHT size={20} />

                {/* 속성과 유형 */}
                <TextCon text={hR.strings.AC_ASSET_CREATE_ATTR} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />

                <HEIGHT size={12} />

                {/* 속성 입력 폼 */}
                {renderAttributeForm()}

                <HEIGHT size={20} />

                {/* 정보수집, 동의 1 */}
                <FlexColumnDiv style={{ width: 280 - 24 }}>
                  <FlexRowDiv
                    style={{
                      alignItems: 'flex-start',
                      // ...devOutlineF
                    }}
                  >
                    <ImgBtn
                      src={[sCheckBoxTerms1 ? R.images.join_btn_check_abled3x : R.images.join_btn_check_disabled3x, 18, 18]}
                      containerStyle={{
                        //  transform: 'translate(0px,-1px)',
                        paddingTop: 3,
                        paddingRight: 5,
                        paddingBottom: 5,
                      }}
                      onClick={() => {
                        setCheckBoxTerms1(!sCheckBoxTerms1);
                      }}
                    />
                    <WIDTH size={6} />
                    <TextCon text={hR.strings.AC_ASSET_CREATE_TERMS1_TITLE} color={R.colors.black} size={11} isBold />
                  </FlexRowDiv>
                  <HEIGHT size={10} />
                  <FlexColumnDiv style={{ borderRadius: 15, backgroundColor: R.colors.grayBox, padding: 10 }}>
                    <TextCon
                      text={`${hR.strings.AC_ASSET_CREATE_TERMS1_DETAIL_1}\n${hR.strings.AC_ASSET_CREATE_TERMS1_DETAIL_2}`}
                      color={R.colors.blueGrey}
                      size={10}
                      textStyle={{ lineHeight: 2 }}
                    />
                  </FlexColumnDiv>
                </FlexColumnDiv>

                <HEIGHT size={24} />

                {/* 유의사항, 동의 2 */}
                <FlexColumnDiv style={{ width: 280 - 24 }}>
                  <FlexRowDiv
                    style={{
                      alignItems: 'flex-start',
                      // ...devOutlineF
                    }}
                  >
                    <ImgBtn
                      src={[sCheckBoxTerms2 ? R.images.join_btn_check_abled3x : R.images.join_btn_check_disabled3x, 18, 18]}
                      containerStyle={{
                        //  transform: 'translate(0px,-1px)',
                        paddingTop: 3,
                        paddingRight: 5,
                        paddingBottom: 5,
                      }}
                      onClick={() => {
                        setCheckBoxTerms2(!sCheckBoxTerms2);
                      }}
                    />
                    <WIDTH size={6} />
                    <TextCon text={hR.strings.AC_ASSET_CREATE_TERMS2_TITLE} color={R.colors.black} size={11} isBold />
                  </FlexRowDiv>
                  <HEIGHT size={10} />
                  <FlexColumnDiv style={{ borderRadius: 15, backgroundColor: R.colors.grayBox, padding: 10 }}>
                    {/* <TextCon text={hR.strings.AC_ASSET_CREATE_TERMS2_DETAIL} color={R.colors.blueGrey} size={10} textStyle={{ lineHeight: 2 }} /> */}
                    {renderPolicy()}
                  </FlexColumnDiv>
                </FlexColumnDiv>

                <HEIGHT size={40} />
                <RoundTxtBtn
                  width={256}
                  height={44}
                  fontSize={12}
                  text={hR.strings.AC_ASSET_CREATE_BTN}
                  enable={sCreateBtnEnabled}
                  containerStyle={{ alignSelf: 'center' }}
                  onClick={() => {
                    handleCreateBtn();
                  }}
                />
                <HEIGHT size={34} />
              </FlexColumnDiv>
            </CommonColumnBox>
            <HEIGHT size={60} />
          </FlexColumnDiv>

          <CommonFooter />
        </MobileScalableFlexColumn>
      </>
    );
  }
  // *******************************
  // DESKTOP
  // *******************************
  else
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          ...devOutline,
        }}
      >
        <BackBtn />

        <Title title={hR.strings.AC_ASSET_CREATE_TITLE} />

        <HEIGHT size={30} />
        <CommonColumnBox style={{ width: '100%', alignItems: 'flex-start' }}>
          <HEIGHT size={40} />
          {/******************************************
           * AssetDisplay
           *******************************************/}
          <FlexColumnDiv
            style={{
              width: 236,
              height: 236,
              alignSelf: 'center',
              borderRadius: 10,
              borderColor: R.colors.line,
              borderStyle: 'solid',
              borderWidth: 1,
              overflow: 'hidden',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: R.colors.bgGrey,
              // ...devOutlineF,
            }}
          >
            {sDisplaySrcList !== undefined && sDisplaySrcList.length > 0 ? (
              <AssetDisplay
                key={`${sSelectedDisplayThumbIdx}`}
                playerSrc={sDisplaySrcList[sSelectedDisplayThumbIdx]}
                width={236}
                height={236}
                scaleIfAudio={0.9}
              />
            ) : (
              (function () {
                switch (sFetchedData?.schm_info.asset_type) {
                  case CbtAssetType_e.AUDIO:
                    return <AssetDisplay key={''} playerSrc={{ kind: 'ReqAudio', audio: '', img: '' }} width={236} height={236} scaleIfAudio={0.9} />;
                  default:
                    return <TextCon text={hR.strings.AC_ASSET_CREATE_PREVIEW} size={14} isBold color={R.colors.cloudyBlue} />;
                }
              })()
            )}
          </FlexColumnDiv>
          {/* AssetDisplay end */}

          {/*******************************************
           * AssetDisplay Thumb 리스트
           *******************************************/}
          {sDisplaySrcList !== undefined && sDisplaySrcList.length > 0 && (
            <>
              <HEIGHT size={40} />

              <FlexRowDiv style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}>
                <ImgBtn
                  src={[sSliderCurIdx === 0 ? R.images.common_btn_back_disabled3x : R.images.common_btn_back_abled3x, 30, 30]}
                  onClick={() => {
                    if (sSliderCurIdx === 0) return;
                    assetDisplayThumbListSliderRef.current?.slickPrev();
                  }}
                />
                <WIDTH size={18} />
                <FlexColumnDiv
                  // {...hDrag()}
                  style={{
                    width: 450,
                    overflow: 'hidden', // 없으면 slider에 의해 x축 스크롤?이 생김
                    justifyContent: 'center',
                    // ...devOutlineF,
                  }}
                >
                  <Slider
                    ref={assetDisplayThumbListSliderRef}
                    infinite={false}
                    speed={400}
                    slidesToShow={4}
                    swipeToSlide={true}
                    slidesToScroll={1}
                    beforeChange={(cur, next) => {
                      DBGMSG(`cur:${cur} next:${next}`);
                      setSliderCurIdx(next);
                    }}
                    afterChange={(cur) => {
                      DBGMSG(`cur:${cur}`);
                      setSliderCurIdx(cur);
                    }}
                  >
                    {sDisplaySrcList.map((displaySrc, idx) => {
                      return (
                        <AssetDisplayThumb
                          whereToUse={WhereToUse_e.ForSlider}
                          key={`${idx}`}
                          width={100}
                          height={100}
                          playerSrc={displaySrc}
                          onClick={() => {
                            setSelectedDisplayThumbIdx(idx);
                          }}
                          selected={sSelectedDisplayThumbIdx === idx}
                        />
                      );
                    })}
                  </Slider>
                </FlexColumnDiv>
                <WIDTH size={18} />
                <ImgBtn
                  src={[
                    sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length
                      ? R.images.common_btn_next_disabled3x
                      : R.images.common_btn_next_abled3x,
                    30,
                    30,
                  ]}
                  onClick={() => {
                    if (sSliderCurIdx + ASSETDISPLAY_THUMB_CNT >= sDisplaySrcList.length) return;
                    assetDisplayThumbListSliderRef.current?.slickNext();
                  }}
                />
              </FlexRowDiv>
            </>
          )}
          {/* AssetDisplay 리스트 end */}

          <HEIGHT size={40} />
          <FlexRowDiv style={{ ...devOutline }}>
            <FlexColumnDiv
              style={{
                width: ScreenInfo.contentsWidth / 2,
                paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
                paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
                // ...devOutlineF,
              }}
            >
              {/* 에셋 소유자 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_ASSET_CREATE_OWNER} size={14} color={R.colors.black} isBold />
                <WIDTH size={3} />
                <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />
              </FlexRowDiv>
              <HEIGHT size={14} />
              <SRoundInputText
                value={sOwner}
                onChange={(e) => {
                  setOwner(e.target.value);
                }}
                // disabled={true}
              />
              <HEIGHT size={24} />
            </FlexColumnDiv>

            <FlexColumnDiv
              style={{
                width: ScreenInfo.contentsWidth / 2,
                paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
                paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
                ...devOutline,
              }}
            >
              {/* 발행 매수 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_CNT} size={14} color={R.colors.black} isBold />
                <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_CNT_GUIDE} size={14} color={R.colors.black} />
                <WIDTH size={3} />
                <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />
                <WIDTH size={5} />
                {/* 툴팁 ic */}
                <TooltipImg tooltipString={hR.strings.AC_ASSET_CREATE_TOOLTIP_MINT} placement={'right-start'} />
              </FlexRowDiv>

              <HEIGHT size={14} />

              <FlexRowDiv style={{ ...devOutline }}>
                <SRoundInputNumber
                  value={sMintCnt}
                  width={179}
                  placeHolder={'0'}
                  onChange={(e) => {
                    //DBGMSG(e.target.value);
                    const regex: RegExp = Constants.REGEXP_IS_VALID_INT;
                    const regexTestRet = regex.test(e.target.value);
                    DBGMSG(`$$$REGEX ${regex}: ${e.target.value} ${regexTestRet}`);
                    if (!regexTestRet) {
                      DBGMSG(`$$$REGEX regexTestRet: ${regexTestRet}`);
                      return;
                    }

                    setMintCnt(e.target.value);
                  }}
                />
                {/* 추가 발행여부 radio 버튼 */}
                <FlexRowDiv
                  style={{
                    //
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 14,
                    paddingRight: 14,
                    cursor: 'pointer',
                    ...devOutline,
                  }}
                  onClick={() => {
                    DBGMSG('추가 발행 없음');
                    setEditionType(CbtEditionType_e.LIMITED);
                  }}
                >
                  <RadioBtn checked={sEditionType === CbtEditionType_e.LIMITED} />
                  <WIDTH size={4} />
                  <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_NO_MORE} size={14} color={R.colors.black} />
                </FlexRowDiv>
                <WIDTH size={53} />
                <FlexRowDiv
                  style={{
                    //
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 14,
                    paddingRight: 14,
                    cursor: 'pointer',
                    ...devOutline,
                  }}
                  onClick={() => {
                    DBGMSG('추가 발행 가능');
                    setEditionType(CbtEditionType_e.REPRINTING);
                  }}
                >
                  <RadioBtn checked={sEditionType === CbtEditionType_e.REPRINTING} />
                  <WIDTH size={4} />
                  <TextCon text={hR.strings.AC_ASSET_CREATE_MINT_MORE} size={14} color={R.colors.black} />
                </FlexRowDiv>
              </FlexRowDiv>
              {sIsOverLimit ? (
                <>
                  <TextCon
                    text={hR.strings.AC_ASSET_CREATE_TOOLTIP_MINT}
                    size={CLAY.VALIDATION_MSG_FONT_SIZE}
                    color={R.colors.pastelRed}
                    containerStyle={{ height: 24, alignSelf: 'flex-start' }}
                  />
                </>
              ) : (
                <HEIGHT size={24} />
              )}
            </FlexColumnDiv>
          </FlexRowDiv>

          {/* 속성과 유형 */}
          <FlexRowDiv
            style={{
              width: ScreenInfo.contentsWidth / 2,
              paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
              paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
              // ...devOutlineF,
            }}
          >
            <TextCon text={hR.strings.AC_ASSET_CREATE_ATTR} size={14} color={R.colors.black} isBold />
          </FlexRowDiv>
          <HEIGHT size={14} />
          <FlexRowDiv
            style={{
              flexWrap: 'wrap',
              width: 1194,
            }}
          >
            {/* 속성 입력 폼 */}
            {renderAttributeForm()}
          </FlexRowDiv>

          <HEIGHT size={42} />

          {/* 정보수집, 동의 1 */}
          <FlexColumnDiv style={{ paddingLeft: COMMON_BOX_HORIZONTAL_PADDING, paddingRight: COMMON_BOX_HORIZONTAL_PADDING, width: '100%' }}>
            <FlexRowDiv>
              <ImgBtn
                src={[sCheckBoxTerms1 ? R.images.join_btn_check_abled3x : R.images.join_btn_check_disabled3x, 22, 22]}
                containerStyle={{
                  //  transform: 'translate(0px,-1px)',
                  paddingTop: 5,
                  paddingRight: 5,
                  paddingBottom: 5,
                }}
                onClick={() => {
                  setCheckBoxTerms1(!sCheckBoxTerms1);
                }}
              />
              <WIDTH size={8} />
              <TextCon text={hR.strings.AC_ASSET_CREATE_TERMS1_TITLE} color={R.colors.black} size={12} isBold />
            </FlexRowDiv>
            <HEIGHT size={4} />
            <FlexRowDiv style={{ marginLeft: 30, borderRadius: 15, backgroundColor: R.colors.grayBox, padding: 14 }}>
              <TextCon
                text={hR.strings.AC_ASSET_CREATE_TERMS1_DETAIL_1}
                color={R.colors.blueGrey}
                size={12}
                textStyle={{ lineHeight: 2 }}
                containerStyle={{ width: 567 - 14 }}
              />
              <TextCon text={hR.strings.AC_ASSET_CREATE_TERMS1_DETAIL_2} color={R.colors.blueGrey} size={12} textStyle={{ lineHeight: 2 }} />
            </FlexRowDiv>
          </FlexColumnDiv>

          <HEIGHT size={24} />

          {/* 유의사항, 동의 2 */}
          <FlexColumnDiv style={{ paddingLeft: COMMON_BOX_HORIZONTAL_PADDING, paddingRight: COMMON_BOX_HORIZONTAL_PADDING, width: '100%' }}>
            <FlexRowDiv>
              <ImgBtn
                src={[sCheckBoxTerms2 ? R.images.join_btn_check_abled3x : R.images.join_btn_check_disabled3x, 22, 22]}
                containerStyle={{
                  //  transform: 'translate(0px,-1px)',
                  paddingTop: 5,
                  paddingRight: 5,
                  paddingBottom: 5,
                }}
                onClick={() => {
                  setCheckBoxTerms2(!sCheckBoxTerms2);
                }}
              />
              <WIDTH size={8} />
              <TextCon text={hR.strings.AC_ASSET_CREATE_TERMS2_TITLE} color={R.colors.black} size={12} isBold />
            </FlexRowDiv>
            <HEIGHT size={4} />
            <FlexColumnDiv style={{ marginLeft: 30, borderRadius: 15, backgroundColor: R.colors.grayBox, padding: 14 }}>
              {renderPolicy()}
            </FlexColumnDiv>
          </FlexColumnDiv>

          <HEIGHT size={40} />
          <RoundTxtBtn
            width={281}
            height={48}
            text={hR.strings.AC_ASSET_CREATE_BTN}
            enable={sCreateBtnEnabled}
            containerStyle={{ alignSelf: 'center' }}
            onClick={() => {
              handleCreateBtn();
            }}
          />

          <HEIGHT size={40} />
        </CommonColumnBox>
        <HEIGHT size={120} />
        <video />
      </FlexColumnDiv>
    );
}
