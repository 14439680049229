/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

export class RefundPolicyStrings {
  static readonly ko = {
    TITLE: `환불정책`,
    TITLE_1: `디지털 에셋 상품`,
    DETAIL_1: `• 디지털 에셋의 생성 또는 거래시 발생하는 모든 트랜잭션은 되돌릴 수 없습니다.
• 회사는 본 서비스를 통해 수행되는 디지털 에셋의 지급 또는 거래로 인해 발생할 수 있는 모든 손해에 대해 귀하 또는 제3자에 대한 어떠한 책임도 지지 않습니다. 단, 해당 거래가 소비자보호법 등의 법령에 의해 보호되는 경우는 제외하며, 이 경우 
   이용규약에서 정하는 바에 따릅니다. 
• 거래(트랜잭션)가 완료되면 디지털 에셋의 디지털 소유권은 구매자가 갖게 되고, 판매자는 이를 취소할 수 없습니다.
• 본 서비스를 통해 구매할 수 있는 디지털 에셋 상품에 대해서는 환불이 불가능합니다.`,
    TITLE_2: `POINT`,
    DETAIL_2: `• POINT의 구매 단가는 당사가 결정하며, 사전 통지 없이 변경될 수 있습니다.
• 고객은 POINT를 타인과 교환하거나 현금 및 기타 경제적 이익(단, 회사가 허용하는 경우는 제외합니다.)과 교환해서는 안됩니다.
• POINT는 이유 여하를 막론하고 환불이 불가능합니다. 다만, 법령상 필요한 경우는 이에 한하지 않습니다.
• 마지막으로 상품을 출품 또는 구입한 후 1년 이상 경과하고 있는 고객에 대해서 당사는 해당 고객의 동의 없이 무상으로 남은 POINT를 상각 할 수 있습니다.`,
  };

  static readonly ja = {
    TITLE: `返金ポリシー`,
    TITLE_1: `デジタルアセット商品`,

    DETAIL_1: `• 当社は、本サービスを通じて遂行されるデジタルアセットの支払いまたは取引（トランザクション）によって発生するすべての損害に対して、お客様または第三者に対するいかなる責任も負いません。
• 取引が完了すると、デジタルアセットのデジタル所有権は購入者へ移転し、出品者は取引をキャンセルできません。
• 本サービスを通じて購入したデジタルアセットの払い戻しはできません。`,

    TITLE_2: `POINT`,

    DETAIL_2: `• POINTの購入単価は当社が決定し、お客様への事前の通知なく変更することができます。
• お客様は、POINTを他人と交換したり、現金その他の経済的利益（ただし、当社が許容する場合を除きます）と交換してはなりません。
• POINTは、理由のいかんを問わず払い戻すことはできません。 ただし、法令上必要な場合はこの限りでありません。
• 最後にデジタルアセットを出品または購入してから1年以上経過しているお客様については、当社は、当該お客様の同意なく、無償で残存POINTを償却することができます。`,
  };

  static readonly en = {
    TITLE: `Refund policy`,
    TITLE_1: `Digital asset product`,
    DETAIL_1: `• We are not responsible to you or any third party for any damage caused by the payment or transaction of digital resources performed through this Service.
• After the transaction is completed, the digital ownership of the digital resource is transferred to the buyer and the seller cannot cancel the transaction.
• Digital resources purchased through this service cannot be refunded.`,
    TITLE_2: `POINT`,
    DETAIL_2: `• The unit price of the POINT is determined by us and can be changed without prior notice to the customer.
• You must not exchange the POINT with anyone else or exchange it for cash or other economic benefits (except as permitted by our company).
• The POINT cannot be refunded for any reason. With the exception of the case that it is required by law.
• For customers who have elapsed listings or purchases of digital resources for more than a year, we may write off the remaining points without their consent.`,
  };
}
