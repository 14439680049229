/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, HTMLProps, ReactChild, ReactFragment, ReactPortal } from 'react';
import { devOutlineF, FlexColumnDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import { Text, TextProps } from 'src/ui/base_component/Text';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

interface TextConMultiProps extends TextProps {
  key?: string | number;
  // ref?: any;
  text: string[];
  containerStyle?: CSSProperties;
  textStyle?: any;
  onConMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  onConMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
  onConClick?: HTMLProps<HTMLDivElement>['onClick'];

  // children?: React.ReactChild;
  children?: ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
}

export default function TextConMulti(props: TextConMultiProps) {
  return (
    <FlexColumnDiv
      key={props.key}
      style={{
        cursor: props.onConClick ? 'pointer' : 'inherit',
        justifyContent: 'center',
        alignItems: 'flex-start',
        ...devOutline,
        ...props.containerStyle,
        content: 'contents',
      }}
      onMouseEnter={props.onConMouseEnter}
      onMouseLeave={props.onConMouseLeave}
      onClick={props.onConClick}
    >
      {props.text.map((text, idx) => {
        return (
          <Text {...props} style={{ ...props.textStyle }}>
            {text ? text : props.children}
          </Text>
        );
      })}
    </FlexColumnDiv>
  );
}
