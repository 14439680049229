/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { DBGMSG, DBGMSGW, InputFileType_t, Utils } from 'src/util/utils';

export function useFileCheck() {
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();

  const checkFileSpec = async ({
    target,
    spec,
  }: {
    target: { localUrl: string; fileObj: File; fileType: InputFileType_t };
    spec: {
      imgWidth?: number;
      imgHeight?: number;
      vidWidth?: number;
      vidHeight?: number;
      sizeInMB: number;
      fileTypeList: InputFileType_t[];
    };
  }) => {
    DBGMSG(`$$$$checkFileSpec`);
    /**
     * step1: 파일 확장자 체크
     */
    if (!spec.fileTypeList.includes(target.fileType)) {
      hDlgFactory.showOkDlgJsx({
        title: hR.strings.AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_TITLE,
        text: Utils.string.replacePlaceholder({
          target: hR.strings.AC_ASSET_CREATE_DLG_ERROR_FILE_TYPE_DESC,
          placeholder: R.strings.PLACEHOLDER_FILE_EXT_LIST,
          txt: spec.fileTypeList.map((ext) => ext.toUpperCase()).join(', '),
        }),
        btnTxt: hR.strings.COMMON_OK_BTN_TXT,
        onOk: () => {
          hDlgFactory.hideOkDlgJsx();
        },
      });

      return false;
    }

    /**
     * step2: 파일 용량 체크
     */
    if (Utils.file.cvtByteToMB(target.fileObj.size) > spec.sizeInMB) {
      hDlgFactory.showOkDlgJsx({
        title: hR.strings.AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_TITLE,
        text: Utils.string.replacePlaceholder({
          target: hR.strings.AC_ASSET_CREATE_DLG_ERROR_FILE_SIZE_DESC,
          placeholder: R.strings.PLACEHOLDER_FILE_SIZE_IN_MB,
          txt: `${spec.sizeInMB}`,
        }),
        btnTxt: hR.strings.COMMON_OK_BTN_TXT,
        onOk: () => {
          hDlgFactory.hideOkDlgJsx();
        },
      });

      return false;
    }

    /**
     * step3: 이미지 해상도체크
     */
    if (spec.imgWidth && spec.imgHeight) {
      try {
        const imgRes = await Utils.file.getImageResResolution(target.localUrl);
        DBGMSG(`imgRes.width: ${imgRes.width}`);
        DBGMSG(`imgRes.height: ${imgRes.height}`);
        DBGMSG(`spec.height: ${spec.imgWidth}`);
        DBGMSG(`spec.height: ${spec.imgHeight}`);
        if (imgRes.width < spec.imgWidth || imgRes.height < spec.imgHeight) {
          DBGMSG(`showDlgOk`);
          hDlgFactory.showOkDlgJsx({
            title: hR.strings.AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_TITLE,
            text: Utils.string.replacePlaceholder({
              target: hR.strings.AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_DESC,
              placeholder: R.strings.PLACEHOLDER_RES_SIZE,
              txt: `${spec.imgWidth}`,
            }),
            btnTxt: hR.strings.COMMON_OK_BTN_TXT,
            onOk: () => {
              hDlgFactory.hideOkDlgJsx();
            },
          });

          return false;
        }
      } catch (error) {
        DBGMSGW(error);
        return false;
      }
    }

    /**
     * step4: 비디오 해상도체크
     */
    if (spec.vidWidth && spec.vidHeight) {
      try {
        const vidRes = await Utils.file.getVideoResResolution(target.localUrl);
        DBGMSG(`width: ${vidRes.width}`);
        DBGMSG(`height: ${vidRes.height}`);
        if (vidRes.width < spec.vidWidth || vidRes.height < spec.vidHeight) {
          hDlgFactory.showOkDlgJsx({
            title: hR.strings.AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_TITLE,
            text: Utils.string.replacePlaceholder({
              target: hR.strings.AC_ASSET_CREATE_DLG_ERROR_RES_SIZE_DESC,
              placeholder: R.strings.PLACEHOLDER_RES_SIZE,
              txt: `${spec.vidWidth}`,
            }),
            btnTxt: hR.strings.COMMON_OK_BTN_TXT,
            onOk: () => {
              hDlgFactory.hideOkDlgJsx();
            },
          });

          return false;
        }
      } catch (error) {
        DBGMSGW(error);
        return false;
      }
    }

    return true;
  };

  return {
    checkFileSpec,
  };
}
