/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DisplayCurrency_e } from 'src/model/model';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import Strings from 'src/res/strings';
import { StringsEn } from 'src/res/strings_en';
import { StringsJp } from 'src/res/strings_jp';
import { StringsKr } from 'src/res/strings_kr';

/****************************************/
// state
/****************************************/
type LocalSettingsState = {
  isInit: boolean;
  lang: CbtUserLanguage_e;
  currency: DisplayCurrency_e;
  strings: Strings;
};

export const stringsEn = new StringsEn();
export const stringsJp = new StringsJp();
export const stringsKr = new StringsKr();

const initialState: LocalSettingsState = {
  isInit: false,
  lang: CbtUserLanguage_e.EN,
  currency: DisplayCurrency_e.USD,
  strings: stringsEn, // 문자열
};

const localSettingsSlice = createSlice({
  name: 'localSettings',
  initialState: initialState,
  reducers: {
    clear: (state) => {
      state = { ...initialState };
      return state;
    },
    setIsInit: (state) => {
      state = { ...state, isInit: true };
    },
    changeLang: (state, action: PayloadAction<{ lang: CbtUserLanguage_e }>) => {
      switch (action.payload.lang) {
        case CbtUserLanguage_e.EN:
          state = { ...state, lang: action.payload.lang, strings: stringsEn };
          break;
        case CbtUserLanguage_e.JA:
          state = { ...state, lang: action.payload.lang, strings: stringsJp };
          break;
        case CbtUserLanguage_e.KO:
          state = { ...state, lang: action.payload.lang, strings: stringsKr };
          break;
      }
      return state;
    },
    changeCurrency: (state, action: PayloadAction<{ currency: DisplayCurrency_e }>) => {
      switch (action.payload.currency) {
        case DisplayCurrency_e.USD:
          state = { ...state, currency: action.payload.currency };
          break;
        case DisplayCurrency_e.JPY:
          state = { ...state, currency: action.payload.currency };
          break;
      }
      return state;
    },
  },
});

export default localSettingsSlice;
