/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, devOutlineFB, FlexColumnDiv, HEIGHT } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { CLAY } from 'src/ui/layout_constant';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  title?: string;
  value?: string | string[];
  titleColor?: string;
  valueColor?: string;
  titleSize?: number;
  valueSize?: number;
  titleHeight?: number | string | undefined;
  valueHeight?: number | string | undefined;
  between?: number;
  valueOnClick?: (v: string) => void;
};

export function VLabelValue({
  title = 'title',
  value = 'value',
  titleColor = R.colors.blueGrey,
  valueColor = R.colors.black,
  titleSize = CLAY.FORM_FONT_SIZE,
  valueSize = CLAY.FORM_FONT_SIZE,
  titleHeight = CLAY.FORM_FONT_HEIGHT,
  valueHeight = CLAY.FORM_FONT_HEIGHT,
  between = 0,
  valueOnClick,
}: Props) {
  return (
    <FlexColumnDiv style={{ ...devOutline }}>
      {/* Title */}
      <TextCon
        size={titleSize}
        color={titleColor}
        text={title}
        containerStyle={{
          //
          height: titleHeight,
          justifyContent: 'flex-start',
          ...devOutline,
        }}
      />
      <HEIGHT size={between} />

      {/* Value */}
      {typeof value === 'string' ? (
        <TextCon
          size={valueSize}
          color={valueColor}
          isBold
          text={value}
          // textStyle={{ width: '100%' }}
          containerStyle={{
            //
            minHeight: valueHeight,
            wordBreak:'break-all',
            justifyContent: 'flex-start',
            ...devOutline,
          }}
          onConClick={
            valueOnClick
              ? () => {
                  valueOnClick(value);
                }
              : undefined
          }
        />
      ) : (
        value.map((v) => {
          return (
            <TextCon
              size={valueSize}
              color={valueColor}
              isBold
              text={v}
              containerStyle={{
                //
                minHeight: valueHeight,
                justifyContent: 'flex-start',
                ...devOutline,
              }}
              onConClick={
                valueOnClick
                  ? () => {
                      valueOnClick(v);
                    }
                  : undefined
              }
            />
          );
        })
      )}
    </FlexColumnDiv>
  );
}
