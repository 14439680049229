/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface KaikasUser_i {
  selectedAddress: string;
  networkVersion: number;
}
export interface KaikasDev_i {
  isEnalbed: boolean;
  isApproved: boolean;
  isUnlocked: boolean;
}

/****************************************/
// state
/****************************************/
type KaikasState_t = {
  isEnable: boolean;
  user?: KaikasUser_i;
  dev?: KaikasDev_i;
  klaytn: any;
  caver: any;
};

const initialState: KaikasState_t = {
  isEnable: false,
  klaytn: undefined,
  caver: undefined,
};

const kaikasSlice = createSlice({
  name: 'kaikas',
  initialState: initialState,
  reducers: {
    clear: (state) =>{
      state = { ...initialState };
      return state;
    },
    setEnable: (state) => {
      state = { ...state, isEnable: true };
      return state;
    },
    setDisable: (state) => {
      state = { ...state, isEnable: false };
      return state;
    },
    setKlaytnCaverObj: (state, action: PayloadAction<{ klaytn: any; caver: any }>) => {
      state = { ...state, klaytn: action.payload.klaytn, caver: action.payload.caver };
      return state;
    },
    setKaikasUser: (state, action: PayloadAction<KaikasUser_i | undefined>) => {
      state = { ...state, user: action.payload };
      return state;
    },
    clearKaikasUser: (state) => {
      state = {
        ...state,
        user: undefined,
      };
      return state;
    },
    setKaikasDev: (state, action: PayloadAction<KaikasDev_i | undefined>) => {
      state = { ...state, dev: action.payload };
      return state;
    },
    clearKaikasDev: (state) => {
      state = {
        ...state,
        dev: undefined,
      };
      return state;
    },
  },
});

export default kaikasSlice;
