/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/modules';
import colListSlice, { ColStateModule_t } from 'src/redux/modules/colStateModule';

export function useColListState() {
  const colListState = useSelector((state: RootState) => state.colListState);
  const dispatch = useDispatch();

  const setMarketPlaceState = useCallback(
    (args: ColStateModule_t) => {
      dispatch(colListSlice.actions.setState(args));
    },
    [dispatch]
  );
  const changeMarketPlaceState = useCallback(
    (args: Partial<ColStateModule_t>) => {
      dispatch(colListSlice.actions.changeState({ ...args }));
    },
    [dispatch]
  );
  const clearMarketPlaceState = useCallback(() => {
    dispatch(colListSlice.actions.clear());
  }, [dispatch]);

  return {
    act: {
      setMarketPlaceState,
      changeMarketPlaceState,
      clearMarketPlaceState,
    },
    state: colListState,
  };
}
