/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlg } from 'src/hooks/useDlg';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikas } from 'src/hooks/useKaikas';
import { useKaikasSvc } from 'src/hooks/useKaikasSvc';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMakePressable } from 'src/hooks/useMakePressable';
import { useRpc } from 'src/hooks/useRpc';
import { DisplaySrc_t, MarketAssetCard_i } from 'src/model/model';
import { CbtAssetType_e, CbtColCertifyType_e, CbtCurrency_e, CbtReqPriceInfo_i } from 'src/model/rpcModel';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import TxtBtnWithMouseOverAni from 'src/ui/base_component/TxtBtnWithMouseOverAni';
import { AssetDisplayThumb, WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { AssetDisplayThumbReqattr } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumbReqattr';
import { BuyBtn } from 'src/ui/common_component/button/BuyBtn';
import { FavoriteBtn } from 'src/ui/common_component/button/FavoriteBtn';
import { RoundTxtBtnAdvType2 } from 'src/ui/common_component/button/RoundTxtBtnAdvType2';
import {
  CommonColumnBox,
  CURRENCY_ROUND_DECIMAL_POINT_DEFAULT,
  PropsCommonBox,
  SPRING_PROPS_ANIM_1_Default,
  SPRING_PROPS_ANIM_1_OnMouseEnter,
  SPRING_PROPS_ANIM_1_OnMouseLeave,
} from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/

const DT_CARD_WIDTH = 276;
const DT_CARD_HEIGHT = 446;
const DT_PADDING = 20;
const DT_COL_NM_FONTSIZE = 14;
const DT_AST_NM_FONTSIZE = 14;
const DT_PRICE_FONTSIZE = 16;
const DT_AST_NM_HEIGHT = 20;
const DT_AST_NM_MARGIN_BOTTOM = 10;
const DT_PRICE_HEIGHT = 24;
const DT_PRICE_UNIT_FONTSIZE = 12;
const DT_BODY_WIDTH = 236;
const DT_COL_IC_WIDTH = 24;
const DT_COL_IC_HEIGHT = 24;
const DT_CURRENCY_IC_WIDTH = 18;
const DT_CURRENCY_IC_HEIGHT = 20;
const DT_BUY_BTN_WIDTH = 158;
const DT_BUY_BTN_HEIGHT = 34;
const DT_BUY_BTN_FONTSIZE = 12;
const DT_BUY_BTN_MARGIN_RIGHT = 10;
const DT_INTEREST_BTN_WIDTH = 34;
const DT_INTEREST_BTN_HEIGHT = 34;
const DT_BTN_MARGIN_TOP = 20;

const MB_CARD_WIDTH = 280;
const MB_CARD_HEIGHT = 420;
const MB_PADDING = 16;
const MB_COL_NM_FONTSIZE = 12;
const MB_AST_NM_FONTSIZE = 12;
const MB_PRICE_FONTSIZE = 14;
const MB_AST_NM_HEIGHT = 18;
const MB_AST_NM_MARGIN_BOTTOM = 7;
const MB_PRICE_HEIGHT = 20;
const MB_PRICE_UNIT_FONTSIZE = 10;
const MB_BODY_WIDTH = 248;
const MB_COL_IC_WIDTH = 20;
const MB_COL_IC_HEIGHT = 20;
const MB_CURRENCY_IC_WIDTH = 16;
const MB_CURRENCY_IC_HEIGHT = 18;
const MB_BUY_BTN_WIDTH = 182;
const MB_BUY_BTN_HEIGHT = 28;
const MB_BUY_BTN_FONTSIZE = 10;
const MB_BUY_BTN_MARGIN_RIGHT = 10;
const MB_INTEREST_BTN_WIDTH = 28;
const MB_INTEREST_BTN_HEIGHT = 28;
const MB_BTN_MARGIN_TOP = 16;

export const MARKET_ASSET_CARD_WIDTH = ScreenInfo.isMobile() ? MB_CARD_WIDTH : DT_CARD_WIDTH;
export const MARKET_ASSET_CARD_HEIGHT = ScreenInfo.isMobile() ? MB_CARD_HEIGHT : DT_CARD_HEIGHT;
const PADDING = ScreenInfo.isMobile() ? MB_PADDING : DT_PADDING;
const AST_NM_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_AST_NM_MARGIN_BOTTOM : DT_AST_NM_MARGIN_BOTTOM;
const COL_NM_FONTSIZE = ScreenInfo.isMobile() ? MB_COL_NM_FONTSIZE : DT_COL_NM_FONTSIZE;
const AST_NM_FONTSIZE = ScreenInfo.isMobile() ? MB_AST_NM_FONTSIZE : DT_AST_NM_FONTSIZE;
const PRICE_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_FONTSIZE : DT_PRICE_FONTSIZE;
const AST_NM_HEIGHT = ScreenInfo.isMobile() ? MB_AST_NM_HEIGHT : DT_AST_NM_HEIGHT;
const PRICE_HEIGHT = ScreenInfo.isMobile() ? MB_PRICE_HEIGHT : DT_PRICE_HEIGHT;
const PRICE_UNIT_FONTSIZE = ScreenInfo.isMobile() ? MB_PRICE_UNIT_FONTSIZE : DT_PRICE_UNIT_FONTSIZE;
export const MARKET_ASSET_CARD_BODY_WIDTH = ScreenInfo.isMobile() ? MB_BODY_WIDTH : DT_BODY_WIDTH;
const COL_IC_WIDTH = ScreenInfo.isMobile() ? MB_COL_IC_WIDTH : DT_COL_IC_WIDTH;
const COL_IC_HEIGHT = ScreenInfo.isMobile() ? MB_COL_IC_HEIGHT : DT_COL_IC_HEIGHT;
const CURRENCY_IC_WIDTH = ScreenInfo.isMobile() ? MB_CURRENCY_IC_WIDTH : DT_CURRENCY_IC_WIDTH;
const CURRENCY_IC_HEIGHT = ScreenInfo.isMobile() ? MB_CURRENCY_IC_HEIGHT : DT_CURRENCY_IC_HEIGHT;
const BUY_BTN_WIDTH = ScreenInfo.isMobile() ? MB_BUY_BTN_WIDTH : DT_BUY_BTN_WIDTH;
const BUY_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_BUY_BTN_HEIGHT : DT_BUY_BTN_HEIGHT;
const BUY_BTN_FONTSIZE = ScreenInfo.isMobile() ? MB_BUY_BTN_FONTSIZE : DT_BUY_BTN_FONTSIZE;
const BUY_BTN_MARGIN_RIGHT = ScreenInfo.isMobile() ? MB_BUY_BTN_MARGIN_RIGHT : DT_BUY_BTN_MARGIN_RIGHT;
const INTEREST_BTN_WIDTH = ScreenInfo.isMobile() ? MB_INTEREST_BTN_WIDTH : DT_INTEREST_BTN_WIDTH;
const INTEREST_BTN_HEIGHT = ScreenInfo.isMobile() ? MB_INTEREST_BTN_HEIGHT : DT_INTEREST_BTN_HEIGHT;
const BTN_MARGIN_TOP = ScreenInfo.isMobile() ? MB_BTN_MARGIN_TOP : DT_BTN_MARGIN_TOP;

const DT_ETC_DROPDOWN_INPUT_WIDTH = 119;
const DT_ETC_DROPDOWN_MENU_HEIGHT = 30;
const DT_ETC_DROPDOWN_MENU_PADIING_LEFT = 10;
const DT_ETC_DROPDOWN_MENU_FONTSIZE = 12;
const DT_ETC_DROPDOWN_PADDING_V = 15;

const MB_ETC_DROPDOWN_INPUT_WIDTH = 120;
const MB_ETC_DROPDOWN_MENU_HEIGHT = 27;
const MB_ETC_DROPDOWN_MENU_PADIING_LEFT = 12;
const MB_ETC_DROPDOWN_MENU_FONTSIZE = 10;
const MB_ETC_DROPDOWN_PADDING_V = 41 - 27;

export const ETC_DROPDOWN_INPUT_WIDTH = ScreenInfo.isMobile() ? MB_ETC_DROPDOWN_INPUT_WIDTH : DT_ETC_DROPDOWN_INPUT_WIDTH;
export const ETC_DROPDOWN_MENU_HEIGHT = ScreenInfo.isMobile() ? MB_ETC_DROPDOWN_MENU_HEIGHT : DT_ETC_DROPDOWN_MENU_HEIGHT;
export const ETC_DROPDOWN_MENU_PADIING_LEFT = ScreenInfo.isMobile() ? MB_ETC_DROPDOWN_MENU_PADIING_LEFT : DT_ETC_DROPDOWN_MENU_PADIING_LEFT;
export const ETC_DROPDOWN_MENU_FONTSIZE = ScreenInfo.isMobile() ? MB_ETC_DROPDOWN_MENU_FONTSIZE : DT_ETC_DROPDOWN_MENU_FONTSIZE;
export const ETC_DROPDOWN_PADDING_V = ScreenInfo.isMobile() ? MB_ETC_DROPDOWN_PADDING_V : DT_ETC_DROPDOWN_PADDING_V;

/**************************************
 * !! type
 **************************************/
export type MarketAssetCardProps = {
  data: MarketAssetCard_i;
  isAnim?: boolean;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
  onBuyClick?: HTMLProps<HTMLDivElement>['onClick'];
  onLikeClick?: HTMLProps<HTMLDivElement>['onClick'];
  onMainImgClick?: HTMLProps<HTMLDivElement>['onClick'];
  enableSaleEdit?: boolean; // 엣세의 소유자일때에만 on 해야함
  onSaleStop?: () => void;
  onSaleEdit?: (args: { prc_info: CbtReqPriceInfo_i }) => void;
  onBuySuccess?: () => void;
  enableBuyBtn?: boolean;
};

export function MarektAssetCard({
  idx,
  data,
  isAnim = false,
  onClick,
  onBuyClick,
  onLikeClick,
  onMainImgClick,
  enableSaleEdit = false,
  onSaleStop,
  onSaleEdit,
  onBuySuccess,
  enableBuyBtn = true,
}: MarketAssetCardProps & { idx?: number }) {
  /**************************************
   * !! state
   **************************************/
  const [sReportMenuOpen, setReportMenuOpen] = useState(false);
  const [sTempBuySuccess, setTempBuySuccess] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const reportBtnRef = useRef(null);
  const reportPopupBtnRef = useRef(null);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hHistory = useHistory();
  const hDlgFactory = useDlgFactory();
  const hKaikasConnectWrapper = useKaikasSvc();

  useMakePressable([
    {
      elementKey: 'report',
      elementRefList: [reportBtnRef, reportPopupBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        DBGMSG('report Click');
        if (reportBtnRef.current === hitRef.current) {
          setReportMenuOpen((prev) => !prev);
        }
        e.stopPropagation();
      },
      onOutsidePress: (e) => {
        sReportMenuOpen && setReportMenuOpen(false);
      },
    },
  ]);

  const hDlg = useDlg();
  const hRpc = useRpc();
  const hAuth = useAuth();
  const hKaikas = useKaikas();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    return () => {};
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const handleBuyBtnOnClick = async () => {
    if (data.prc_info.curr_info.currency === CbtCurrency_e.KLAY)
      hKaikasConnectWrapper.handleBuyBtnOnClick({
        sale_id: data.sale_id,
        onBuySuccess: () => {
          onBuySuccess && onBuySuccess();
        },
      });
    else if (data.prc_info.curr_info.currency === CbtCurrency_e.cPOINT) {
      if (!hAuth.isLogin) {
        hHistory.push(Nav.UserLoginScreen);
        return;
      }

      hDlgFactory.showBuyJsx({
        sale_id: data.sale_id,
        onClose: () => {
          hDlgFactory.hideBuyJsx();
        },
        onSuccess: () => {
          hDlgFactory.hideBuyJsx();
          onBuySuccess && onBuySuccess();
        },
      });
    }
  };

  const getUsdDollar = () => {
    if (!data.prc_info.sub_price_list) return '';
    if (data.prc_info.sub_price_list.length === 0) return '';
    if (data.prc_info.sub_price_list[0].curr_info.currency !== CbtCurrency_e.USD) return '';

    return `(≈ ${Utils.currency.getPriceForDP({ prcInfo: data.prc_info.sub_price_list[0], decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })} ${
      R.strings.CURRENCY_CUSD
    })`;
  };

  /**************************************
   * !! render function
   **************************************/
  const renderBottom = () => {
    if (enableSaleEdit) {
      return (
        // 판매 편집
        <>
          <RoundTxtBtnAdvType2
            enable={data.blocked === false}
            text={hR.strings.COMMON_SALE_EDIT_BTN}
            width={MARKET_ASSET_CARD_BODY_WIDTH}
            height={BUY_BTN_HEIGHT}
            fontSize={BUY_BTN_FONTSIZE}
            onClick={(e) => {
              hDlgFactory.showSellJsx({
                // fetched: res,
                req: {
                  mode: 'EDIT',
                  tx: { sale_id: data.sale_id },
                },
                onSuccess: ({ prc_info }) => {
                  DBGMSG('onSale');
                  DBGMSG(prc_info);
                  // reload();
                  hDlgFactory.hideSellJsx();
                  onSaleEdit && onSaleEdit({ prc_info });
                },
                onSaleStop: () => {
                  DBGMSG('onStop');
                  hDlgFactory.hideSellJsx();
                  // reload();
                  onSaleStop && onSaleStop();
                },
                onClose: () => {
                  DBGMSG('onClose');
                  hDlgFactory.hideSellJsx();
                },
              });

              e.stopPropagation();
            }}
          />
        </>
      );
    } else {
      // if (sTempBuySuccess) {
      //   return (
      //     <>
      //       <TextCon text={'구매완료됨'} size={16} />
      //     </>
      //   );
      // } else {
      return (
        <>
          <BuyBtn
            width={BUY_BTN_WIDTH}
            height={BUY_BTN_HEIGHT}
            fontsize={BUY_BTN_FONTSIZE}
            enable={enableBuyBtn && data.blocked !== true}
            onClick={(e) => {
              DBGMSG('BuyBtn');
              handleBuyBtnOnClick();
              onBuyClick && onBuyClick(e);
              e.stopPropagation();
            }}
            containerStyle={{ marginRight: BUY_BTN_MARGIN_RIGHT }}
          />

          {/* favorite 버튼 */}
          <FavoriteBtn
            saleId={data.sale_id}
            width={INTEREST_BTN_WIDTH}
            height={INTEREST_BTN_HEIGHT}
            defaultIsFavorite={data.favorite}
            enable={data.sold !== true}
            rounded={true}
          />

          {/* 신고하기 버튼 */}
          <FlexDiv
            ref={reportBtnRef}
            onClick={(e) => {
              DBGMSG('etcbtn');
              e.stopPropagation();
            }}
          >
            <ImgBtn src={[R.images.common_btn_etc3x, INTEREST_BTN_WIDTH, INTEREST_BTN_WIDTH]} />
          </FlexDiv>

          <Overlay
            target={reportBtnRef.current}
            show={sReportMenuOpen}
            placement="bottom-start"
            // placement="auto"
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  zIndex: idx,
                }}
              >
                <CommonColumnBox
                  ref={reportPopupBtnRef}
                  style={{
                    // position: 'absolute',
                    // top: BUY_BTN_HEIGHT,
                    // left: BUY_BTN_WIDTH + BUY_BTN_MARGIN_RIGHT + LIKE_BTN_WIDTH,
                    // bottom: 0,
                    // right: 0,
                    width: ETC_DROPDOWN_INPUT_WIDTH,
                    // height: DROPDOWN_INPUT_HEIGHT,
                    // ...devOutlineFG,
                  }}
                >
                  <HEIGHT size={ETC_DROPDOWN_PADDING_V} />
                  <TxtBtnWithMouseOverAni
                    size={ETC_DROPDOWN_MENU_FONTSIZE}
                    color={R.colors.black}
                    isBold
                    text={hR.strings.MARKET_REPORT_BTN}
                    onMouseDefalutProp={{ backgroundColor: 'white' }}
                    onMouseEnterProp={{ backgroundColor: R.colors.paleGrey }}
                    onMouseLeaveProp={{ backgroundColor: 'white' }}
                    containerStyle={{
                      width: '100%',
                      height: ETC_DROPDOWN_MENU_HEIGHT,
                      paddingLeft: ETC_DROPDOWN_MENU_PADIING_LEFT,
                      justifyContent: 'center',
                      // ...devOutlineF
                    }}
                    onClick={(e) => {
                      DBGMSG('report');

                      hDlgFactory.showReport({
                        saleId: data.sale_id,
                        onReport: (select) => {
                          DBGMSG(`TODO Report RPC call: ${select}`);
                        },
                        onHide: () => {
                          hDlgFactory.hideReport();
                        },
                      });
                      sReportMenuOpen && setReportMenuOpen(false);
                      e.stopPropagation();
                    }}
                  />

                  <HEIGHT size={ETC_DROPDOWN_PADDING_V} />
                </CommonColumnBox>
              </div>
            )}
          </Overlay>
        </>
      );
    }
    // }
  };

  /**************************************
   * !! render conf
   **************************************/
  let assetDpInfo: DisplaySrc_t;

  if (data.asset_type === CbtAssetType_e.AUDIO) {
    assetDpInfo = { kind: 'ReqAudio', img: data.nft_img, audio: 'null' };
  } else if (data.asset_type === CbtAssetType_e.VIDEO) {
    assetDpInfo = { kind: 'ReqVideo', img: data.nft_img, video: 'null' };
  } else {
    assetDpInfo = { kind: 'ReqImage', img: data.nft_img };
  }

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  // console.debug('render');
  return (
    <AnimatedFlexColumnDiv
      style={{
        width: MARKET_ASSET_CARD_WIDTH,
        height: MARKET_ASSET_CARD_HEIGHT,
        cursor: onClick ? 'pointer' : 'inherit',
        ...PropsCommonBox,
        ...hMouseProps,
        // ...devOutlineF,
      }}
      onMouseEnter={() => {
        // DBGMSG(`onMouseEnter: `);
        // set({ backgroundColor: R.colors.paleGrey, transform: 'scale(1.005)' });
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
      }}
      onMouseLeave={() => {
        // DBGMSG(`onMouseLeave: `);
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
      }}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClick && onClick(e);
      }}
    >
      {/* 상단부 */}
      <FlexColumnDiv style={{ alignItems: 'center' }}>
        {/*  Collection 이름 */}
        <FlexRowDiv
          style={{ alignItems: 'center', width: MARKET_ASSET_CARD_BODY_WIDTH, marginTop: PADDING, ...devOutline }}
          onClick={(e) => {
            // DBGMSG(`${data.col_info.dp_name} collection click`);
            // hHistory.push(Nav.brewMarketCollectionRScreenPath({ cid: data.col_info.cid }));
            // e.stopPropagation();
          }}
        >
          {/* collection icon */}
          <FlexDiv style={{ height: COL_IC_HEIGHT }}>
            {data.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
              <Img src={[R.images.common_ic_verify3x, COL_IC_WIDTH, COL_IC_HEIGHT]} containerStyle={{ marginRight: 4 }} />
            )}
          </FlexDiv>

          {/* collection disp name */}
          <TextCon
            color={R.colors.black}
            isBold
            size={COL_NM_FONTSIZE}
            text={data.col_info.dp_name}
            ellipsisWidth={DT_BODY_WIDTH - COL_IC_WIDTH - 4}
            containerStyle={{}}
          />
        </FlexRowDiv>

        <HEIGHT size={PADDING} />

        <FlexColumnDiv
          style={{
            //
            width: MARKET_ASSET_CARD_BODY_WIDTH,
            height: MARKET_ASSET_CARD_BODY_WIDTH,
          }}
        >
          {/*  Asset 대표이미지 */}
          <AssetDisplayThumb
            whereToUse={WhereToUse_e.ForMarketAssetCard}
            playerSrc={assetDpInfo}
            width={MARKET_ASSET_CARD_BODY_WIDTH}
            height={MARKET_ASSET_CARD_BODY_WIDTH}
            hiddenBorder
            isBlock={data.blocked}
          />

          {/* SOLD OUT 표시 */}
          {data.sold && (
            <Img
              src={[R.images.common_iv_soldout3x, MARKET_ASSET_CARD_BODY_WIDTH, MARKET_ASSET_CARD_BODY_WIDTH]}
              containerStyle={{ position: 'absolute', left: 0, top: 0 }}
            />
          )}
        </FlexColumnDiv>
        <HEIGHT size={PADDING} />
      </FlexColumnDiv>

      <FlexColumnDiv
        style={{
          alignItems: 'center',
        }}
      >
        {/*  Asset 이름 */}
        <FlexRowDiv
          style={{
            alignItems: 'center',
            width: MARKET_ASSET_CARD_BODY_WIDTH,
            height: AST_NM_HEIGHT,
            ...devOutline,
          }}
        >
          <TextCon
            color={R.colors.black}
            isBold
            size={AST_NM_FONTSIZE}
            // text={'에셋 이름이 길면 이렇게 처리해주세요...'}
            text={data.nft_name}
          />
        </FlexRowDiv>

        <HEIGHT size={AST_NM_MARGIN_BOTTOM} />

        {/*  Asset 가격 */}
        <FlexRowDiv
          style={{
            width: MARKET_ASSET_CARD_BODY_WIDTH,
            height: PRICE_HEIGHT,
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {/* 화폐 아이콘 */}
          <Img src={[Utils.svc.getCurrencyDpInfo(data.prc_info.curr_info.currency).icSrc, CURRENCY_IC_WIDTH, CURRENCY_IC_HEIGHT]} />
          <WIDTH size={3} />

          {/* 가격 (달러) */}
          <FlexRowDiv
            style={{
              alignItems: 'baseline',
              ...devOutline,
            }}
          >
            <TextCon
              // color={assetInfo.priceInfo.currency === CbtCurrency_e.ETH ? R.colors.purpleishBlue : R.colors.klaytn}
              color={R.colors.black}
              isBold
              size={PRICE_FONTSIZE}
              text={`${Utils.currency.getPriceForDP({ prcInfo: data.prc_info, decimal: CURRENCY_ROUND_DECIMAL_POINT_DEFAULT })}`}
            />
            <WIDTH size={3} />
            <TextCon
              // color={assetInfo.priceInfo.currency === CbtCurrency_e.ETH ? R.colors.purpleishBlue : R.colors.klaytn}
              color={R.colors.black}
              isBold
              size={PRICE_UNIT_FONTSIZE}
              text={Utils.svc.getCurrencyDpInfo(data.prc_info.curr_info.currency).unit}
            />
            <WIDTH size={3} />
            {data.prc_info.curr_info.currency === CbtCurrency_e.KLAY && (
              <TextCon
                //
                color={R.colors.black}
                size={PRICE_UNIT_FONTSIZE}
                text={getUsdDollar()}
              />
            )}
          </FlexRowDiv>
        </FlexRowDiv>

        <HEIGHT size={BTN_MARGIN_TOP} />

        <FlexRowDiv style={{ width: MARKET_ASSET_CARD_BODY_WIDTH, height: BUY_BTN_HEIGHT, marginBottom: PADDING, ...devOutline }}>
          {renderBottom()}
        </FlexRowDiv>
      </FlexColumnDiv>
    </AnimatedFlexColumnDiv>
  );
}
