/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CbtMarketSearchSort_e } from 'src/model/model';
import { CbtReqPriceInfo_i } from 'src/model/rpcModel';
import { RPC_ASSETMGR_INVENTORY_ASSET_LIST, RPC_ASSETMGR_ISSUE_LIST, RPC_ASSETMGR_SALE_LIST } from 'src/model/rpcType';
import { RootState } from 'src/redux/modules';
import userDetailMineSlice, { UserDetailMineMenuType_e, UserDetailMineState_t } from 'src/redux/modules/userDetailMineModule';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

export function useUserDetailMineState() {
  const userDetailMineState = useSelector((state: RootState) => state.userDetailMineState);
  const dispatch = useDispatch();

  const setState = useCallback(
    (args: UserDetailMineState_t) => {
      dispatch(userDetailMineSlice.actions.setState(args));
    },
    [dispatch]
  );

  const changeState = useCallback(
    (args: Partial<UserDetailMineState_t>) => {
      dispatch(userDetailMineSlice.actions.changeState({ ...args }));
    },
    [dispatch, userDetailMineState]
  );

  const clearState = useCallback(() => {
    dispatch(userDetailMineSlice.actions.clear());
  }, [dispatch]);

  const queryClear = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        querySearch: '',
        querySortOrder: CbtMarketSearchSort_e.ListingNewest,
      })
    );
  }, [dispatch]);

  const changeMenu = useCallback(
    (menu: UserDetailMineMenuType_e) => {
      dispatch(
        userDetailMineSlice.actions.changeState({
          menu,
        })
      );
    },
    [dispatch]
  );

  // 인벤토리 상태 초기화
  const resetInventoryAstg = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        inventory: {
          astgNeedUpdate: true,
          astg: undefined,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  // 인벤토리 상태 AST만 초기화
  const resetInventoryAst = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        inventory: {
          ...userDetailMineState.inventory,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  // 인벤토리 상태 AST 특정 카드의 판매정보 변경
  const changeInventoryAstSaleInfo = (
    target: { asset_id: number; asset_key: string },
    newSaleInfo: { sale_id: number; sid: number; entity_id: number; asset_id: number }
  ) => {
    if (!userDetailMineState.inventory.ast) {
      DBGMSGW(`myPageState.inventory.ast is undefind`);
      return;
    }
    if (userDetailMineState.inventory.ast.list.length === 0) {
      DBGMSGW(`myPageState.inventory.ast.list.length === 0`);
      return;
    }
    const foundIdx = userDetailMineState.inventory.ast.list.findIndex(
      (ast) => ast.ast_info.asset_id === target.asset_id && ast.ast_info.asset_key === target.asset_key
    );

    if (foundIdx === -1) {
      DBGMSGW(`foundIdx === -1`);
      return;
    }

    const newList = Utils.deepCopy<RPC_ASSETMGR_INVENTORY_ASSET_LIST.Rx['ast_list']>(userDetailMineState.inventory.ast.list);
    // 판매정보 업데이트
    newList[foundIdx].sale_id = newSaleInfo.sale_id;
    newList[foundIdx].status = newSaleInfo.sale_id === 0 ? 0 : 101; // 판매중

    changeState({
      inventory: {
        ...userDetailMineState.inventory,
        ast: {
          ...userDetailMineState.inventory.ast,
          list: newList,
        },
      },
    });
  };

  // 발행에셋 상태 초기화
  const resetIssueAstg = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        issue: {
          astgNeedUpdate: true,
          astg: undefined,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  // 발행에셋 상태 AST만 초기화
  const resetIssueAst = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        issue: {
          ...userDetailMineState.issue,
          astNeedUpdate: true,
          ast: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  const __changeIssueAstgTcnt = (target: { entity_id: number; sid: number }, tcnt: number) => {
    if (!userDetailMineState.issue.astg) {
      DBGMSGW(`myPageState.issue.ast is undefind`);
      return;
    }
    if (userDetailMineState.issue.astg.list.length === 0) {
      DBGMSGW(`myPageState.issue.ast.list.length === 0`);
      return;
    }
    const foundIdx = userDetailMineState.issue.astg.list.findIndex((astg) => astg.entity_id === target.entity_id && astg.schm_info.sid === target.sid);

    if (foundIdx === -1) {
      DBGMSGW(`foundIdx === -1`);
      return;
    }

    const newList = Utils.deepCopy<RPC_ASSETMGR_ISSUE_LIST.Rx['astg_list']>(userDetailMineState.issue.astg.list);
    // 판매정보 업데이트
    newList[foundIdx].issue_tcnt = tcnt;
    DBGMSG(newList);

    changeState({
      issue: {
        ...userDetailMineState.issue,
        astg: {
          ...userDetailMineState.issue.astg,
          list: newList,
        },
      },
    });
  };

  // 판매중 상태 초기화
  const resetSaleList = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        sale: {
          listNeedUpdate: true,
          list: undefined,
          lastRx: undefined,
          lastPgInfo: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  const changeSaleListSaleInfo = (target: { sale_id: number }, sale_info: { saleStop?: boolean; prc_info?: CbtReqPriceInfo_i }) => {
    if (!userDetailMineState.sale) {
      DBGMSGW(`myPageState.issue.ast is undefind`);
      return;
    }

    if (!userDetailMineState.sale.list) {
      DBGMSGW(`myPageState.issue.ast is undefind`);
      return;
    }
    if (userDetailMineState.sale.list.length === 0) {
      DBGMSGW(`myPageState.issue.ast.list.length === 0`);
      return;
    }
    const foundIdx = userDetailMineState.sale.list.findIndex((ast) => ast.sale_id === target.sale_id);

    if (foundIdx === -1) {
      DBGMSGW(`foundIdx === -1`);
      return;
    }

    const newList = Utils.deepCopy<RPC_ASSETMGR_SALE_LIST.Rx['sale_list']>(userDetailMineState.sale.list);
    // 판매정보 업데이트
    if (sale_info.prc_info) {
      newList[foundIdx].prc_info = sale_info.prc_info;
      changeState({
        sale: {
          ...userDetailMineState.sale,
          list: newList,
        },
      });
    } else if (sale_info.saleStop === true) {
      changeState({
        sale: {
          ...userDetailMineState.sale,
          list: [...newList.slice(0, foundIdx), ...newList.slice(foundIdx + 1)],
        },
      });
    }
  };

  // 판매됨 상태 초기화
  const resetSoldList = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        sold: {
          listNeedUpdate: true,
          list: undefined,
          lastRx: undefined,
          lastPgInfo: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  // 구매함 상태 초기화
  const resetBoughtList = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        bought: {
          listNeedUpdate: true,
          list: undefined,
          lastRx: undefined,
          lastPgInfo: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  // 즐겨찾기 상태 초기화
  const resetFavoriteList = useCallback(() => {
    dispatch(
      userDetailMineSlice.actions.changeState({
        favorite: {
          listNeedUpdate: true,
          list: undefined,
          lastRx: undefined,
          lastPgInfo: undefined,
        },
      })
    );
  }, [dispatch, userDetailMineState]);

  return {
    queryClear,
    changeMenu,

    // 인벤토리
    resetInventoryAstg,
    resetInventoryAst,
    changeInventoryAstSaleInfo,

    // 발행
    resetIssueAstg,
    resetIssueAst,
    __changeIssueAstgTcnt,

    // 판매중
    resetSaleList,
    changeSaleListSaleInfo,

    // 판매됨
    resetSoldList,

    // 구매함
    resetBoughtList,

    // 즐겨찾기
    resetFavoriteList,

    act: {
      setState,
      changeState,
      clearState,
    },
    state: userDetailMineState,
  };
}
