/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React from 'react';
import { animated } from 'react-spring';
import R from 'src/res/R';
import { devOutlineF, FlexRowDiv } from 'src/ui/base_component/etc';
import { TextProps } from 'src/ui/base_component/Text';
import { TextConProps } from 'src/ui/base_component/TextCon';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

const AText = styled(animated.span)`
  font-family: ${(props: TextProps) => props.fontFamily || R.fonts.SpoqaJP};
  margin-right: ${(props: TextProps) => (props.isItelic ? '2px' : '0px')};
  font-size: ${(props: TextProps) => `${props.size}px`};
  font-weight: ${(props: TextProps) => (props.isBold ? 'bold' : 'normal')};
  font-style: ${(props: TextProps) => (props.isItelic ? 'italic' : 'normal')};
  text-decoration: ${(props: TextProps) => (props.isUnderline ? 'underline' : 'none')};
  color: ${(props: TextProps) => props.color ?? R.colors.rn.blue};
  letter-spacing: ${(props: TextProps) => `${props.letterSpacing}px`};
  overflow-wrap: break-word;
  user-select: ${(props: TextProps) => (props.useSelectNone ? 'none' : 'auto')};
`;

export default function AnimatedText(props: TextConProps) {
  return (
    <FlexRowDiv
      key={props.key}
      style={{
        cursor: props.onConClick ? 'pointer' : 'inherit',
        justifyContent: 'center',
        alignItems: 'center',
        ...devOutline,
        ...props.containerStyle,
      }}
      onMouseEnter={props.onConMouseEnter}
      onMouseLeave={props.onConMouseLeave}
      onClick={props.onConClick}
    >
      <AText
        {...props}
        style={{
          //
          // width: '100%',
          ...props.textStyle,
        }}
        // title={'test blabla'}
      >
        {props.text ? props.text : props.children}
      </AText>
    </FlexRowDiv>
  );
}
