/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDebounce, useEffectOnce } from 'react-use';
import R from 'src/res/R';
import { AnimatedFlexRowDiv, devOutlineF } from 'src/ui/base_component/etc';
import { PropsSRoundDisabled, PropsSRoundEnabled } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';
import styled from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

// type InputWithBorderPros = {
//   fontSize: number;
// };

const InputWithBorder = styled.textarea`
  font-family: ${R.fonts.SpoqaJP};
  color: ${R.colors.black};
  border-width: 0px;
  background-color: ${R.colors.transparent};
  ::placeholder {
    color: ${R.colors.cloudyBlue};
  }
  &:focus {
    outline: none;
  }
`;

type Props = {
  width: number;
  height?: number;
  minHeight?: number;
  resizeNone?: boolean;
  borderRadius?: number | string;
  fontSize?: number;
  placeHolder?: string;
  value?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
};

export function SRoundInputTextArea({
  width,
  height,
  minHeight,
  resizeNone,
  borderRadius = 14,
  fontSize = 14,
  placeHolder,
  value,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
}: Props) {
  /**************************************
   * !! state
   **************************************/
  const [debouncedSearchTxt, setDebouncedSearchTxt] = useState(value);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [sIsCollectionInputFocus, setIsCollectionInputFocus] = useState<boolean>(false);

  /**************************************
   * !! ref
   **************************************/
  const collectionInputRef = useRef<HTMLTextAreaElement | null>(null);
  const isCollectionInputFocusRef = useRef<boolean>(false);

  /**************************************
   * !! hooks
   **************************************/
  const [,] = useDebounce(
    () => {
      setIsTyping(false);
      if (value === debouncedSearchTxt) return;
      setDebouncedSearchTxt(value);
      DBGMSG(`debouncedInputText:${value}`);
    },
    1000,
    [value]
  );

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/

  DBGMSG('render');
  return (
    <AnimatedFlexRowDiv
      style={Object.assign(
        {
          minWidth: width,
          height: height,
          maxWidth: width,
          minHeight: minHeight,
          borderRadius: borderRadius,
          justifyContent: 'flex-start',
        },
        sIsCollectionInputFocus ? PropsSRoundEnabled : PropsSRoundDisabled
      )}
    >
      {/* input */}
      <InputWithBorder
        // fontSize={fontSize}
        style={{
          minWidth: width - 14 - 14,
          minHeight: minHeight,
          fontSize: fontSize,
          paddingTop: 14,
          paddingBottom: 14,
          paddingLeft: 14,
          paddingRight: 14,
          resize: resizeNone ? 'none' : 'vertical',
          // ...devOutlineFF,
        }}
        ref={(input) => {
          collectionInputRef.current = input;
        }}
        // type={'text'}
        value={value}
        placeholder={placeHolder}
        onFocus={() => {
          DBGMSG('Input onFocus');
          isCollectionInputFocusRef.current = true;
          setIsCollectionInputFocus(true);
          onFocus && onFocus();
        }}
        onBlur={() => {
          DBGMSG('Input onBlur');
          isCollectionInputFocusRef.current = false;
          setIsCollectionInputFocus(false);
          onBlur && onBlur();
        }}
        onChange={(e) => {
          DBGMSG(`onChange`);
          setIsTyping(true);
          onChange && onChange(e);
        }}
        onKeyDown={(e) => {
          DBGMSG(`onKeyDown: ${e.key}`);
          if (e.key === 'Enter') {
            DBGMSG('Enter');
            // if (searchTxt.length !== 0) handleSearch(searchTxt);
          }
          onKeyDown && onKeyDown(e);
        }}
        // returnKeyType="search"
        // clearButtonMode="while-editing"
      />
    </AnimatedFlexRowDiv>
  );
}
