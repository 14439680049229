/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 * mymemo
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/****************************************/
// state
/****************************************/
type MyMemoState = {
  [keyName: string]: number | undefined;
};
const initialState: MyMemoState = {};

const myMemoSlice = createSlice({
  name: 'memo',
  initialState: initialState,
  reducers: {
    clear: (state) => {
      state = { ...initialState };
      return state;
    },
    setValue: (state, action: PayloadAction<{ key: string; value: number }>) => {
      state = { ...state, [action.payload.key]: action.payload.value };
      return state;
    },
  },
});

export default myMemoSlice;
