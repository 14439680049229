/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect } from 'react';
import { useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { CbtAssetType_e, CbtCreationStatus_e, CbtSchemaCardInfo_i } from 'src/model/rpcModel';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, HEIGHT, SBorderOnDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import TextCon from 'src/ui/base_component/TextCon';
import {
  PropsCommonBox,
  PropsSRoundDisabled,
  PropsSRoundEnabled,
  SCHEMA_CARD_LAYOUT,
  SPRING_PROPS_ANIM_1_Default,
  SPRING_PROPS_ANIM_1_OnMouseEnter,
  SPRING_PROPS_ANIM_1_OnMouseLeave,
} from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export type SchemaCardProps = {
  schemaInfo: CbtSchemaCardInfo_i;
  width?: number | string;
  height?: number | string;
  isAnim?: boolean;
  onClick?: () => void;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

export function SchemaCard({ schemaInfo, isAnim = false, onClick }: SchemaCardProps) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  // const menuTxt = useMemo(() => {
  //   switch (menu) {
  //     case Menu.Explorer:
  //       return 'Explorer';
  //     case Menu.AssetCreator:
  //       return 'AssetCreator';
  //     case Menu.Marketplace:
  //       return 'Marketplace';
  //   }
  // }, [menu]);
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    console.debug('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // console.debug('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  // const isActive = curMenu === menu;
  let assetTypeImg: string | null = null;
  switch (schemaInfo.asset_type) {
    case CbtAssetType_e.IMAGE:
      assetTypeImg = R.images.common_ic_img_schema3x;
      break;
    case CbtAssetType_e.VIDEO:
      assetTypeImg = R.images.common_ic_video_schema3x;
      break;
    case CbtAssetType_e.AUDIO:
      assetTypeImg = R.images.common_ic_audio_schema3x;
      break;
  }

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  // console.debug('render');
  return (
    <AnimatedFlexColumnDiv
      style={{
        width: SCHEMA_CARD_LAYOUT.width,
        height: SCHEMA_CARD_LAYOUT.height,
        cursor: onClick ? 'pointer' : 'auto',
        ...PropsCommonBox,
        ...hMouseProps,
        // ...devOutlineF,
      }}
      onMouseEnter={() => {
        // DBGMSG(`onMouseEnter: `);
        // set({ backgroundColor: R.colors.paleGrey, transform: 'scale(1.005)' });
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
      }}
      onMouseLeave={() => {
        // DBGMSG(`onMouseLeave: `);
        isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
      }}
      onClick={() => {
        DBGMSG(`onClick`);
        onClick && onClick();
      }}
    >
      <FlexColumnDiv style={{ flex: 1, justifyContent: 'center', alignItems: 'center', ...devOutline }}>
        <FlexColumnDiv
          style={Object.assign(
            {
              width: SCHEMA_CARD_LAYOUT.innerBoxWidth,
              height: SCHEMA_CARD_LAYOUT.innerBoxHeight,
              borderRadius: SCHEMA_CARD_LAYOUT.innerBoxRadius,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: schemaInfo.status === CbtCreationStatus_e.InPreparation ? '#f9f9f9' : R.colors.bgGrey,
            },
            schemaInfo.status === CbtCreationStatus_e.InPreparation
              ? { ...PropsSRoundDisabled, backgroundColor: '#f9f9f9', borderWidth: 0 }
              : { ...PropsSRoundEnabled, backgroundColor: R.colors.bgGrey }
          )}
        >
          {schemaInfo.status === CbtCreationStatus_e.InPreparation ? (
            <>
              <Img src={[R.images.creator_ic_loading1x, SCHEMA_CARD_LAYOUT.icSize, SCHEMA_CARD_LAYOUT.icSize]} />
              <HEIGHT size={8} />
              <TextCon text={schemaInfo.dp_name} color={R.colors.black} size={SCHEMA_CARD_LAYOUT.nameFontSize} isBold />
              <HEIGHT size={4} />
              <TextCon
                text={`${hR.strings.AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_1}${hR.strings.AC_COLLECTION_LIST_CREATING_IN_PROCESS_MSG_2}`}
                color={R.colors.blueGrey}
                size={SCHEMA_CARD_LAYOUT.guideFontSize}
              />
            </>
          ) : (
            <>
              <Img src={[assetTypeImg, SCHEMA_CARD_LAYOUT.icSize, SCHEMA_CARD_LAYOUT.icSize]} />
              <HEIGHT size={8} />
              <TextCon text={schemaInfo.dp_name} color={R.colors.black} size={SCHEMA_CARD_LAYOUT.nameFontSize} isBold />
            </>
          )}
        </FlexColumnDiv>
      </FlexColumnDiv>
    </AnimatedFlexColumnDiv>
  );
}
