/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RPC_HOME_INFO
} from 'src/model/rpcType';

/****************************************/
// state
/****************************************/
export type ExplorerState_t = {
  needUpdate: boolean;
  explorerInfo?: RPC_HOME_INFO.Rx;
};

const initialState: ExplorerState_t = {
  needUpdate: true,
  explorerInfo: undefined,
};

const explorerSlice = createSlice({
  name: 'ExplorerState_t',
  initialState: initialState,
  reducers: {
    clear: (state) => {
      state = { ...initialState };
      return state;
    },
    setState: (state, action: PayloadAction<ExplorerState_t>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    changeState: (state, action: PayloadAction<Partial<ExplorerState_t>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export default explorerSlice;
