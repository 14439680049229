/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo, { MOBILE_CONTENT_PADDING_TOP, MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useForceRender } from 'src/hooks/useForceRender';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { CbtAssetCardInfo_i } from 'src/model/rpcModel';
import { RPC_ASSET_CREATOR_GROUP_VIEW } from 'src/model/rpcType';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { CLAY } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { CbltHistoryList } from 'src/ui/screen/User/history/CbltHistoryList';
import { KlayHistoryList } from 'src/ui/screen/User/history/KlayHistoryList';
import { PointHistoryList } from 'src/ui/screen/User/history/PointHistoryList';
import { UsdHistoryList } from 'src/ui/screen/User/history/UsdHistoryList';
import { DBGMSG, DBGMSGW } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export enum MenuType_e {
  POINT = 'POINT',
  USD = 'USD',
  KLAY = 'KLAY',
  CBLT = 'CBLT',
}

export interface MyPropertyHistoryScreenQS {
  menu: MenuType_e;
}

export function MyPropertyHistoryScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/
  const qsRef = useRef<MyPropertyHistoryScreenQS>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`MyBalance - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hForceRender = useForceRender();
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { menu?: string } = qs.parse(hLocation.search, {
      ignoreQueryPrefix: true,
    });

    if (query.menu) {
      DBGMSG(`$$$$QS menu: ${query.menu}`);

      const menu = parseQS(query.menu);
      if (!menu) {
        DBGMSGW('unknowk menu');
        return () => {};
      }
      qsRef.current = { menu };
      hForceRender.forceRender();
    } else {
      // key 없이 접근
      hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.POINT }));
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, [hLocation.search]);

  /**************************************
   * !! arrow function
   **************************************/
  const parseQS = (qsMenu: string) => {
    switch (qsMenu) {
      case MenuType_e.POINT:
        return MenuType_e.POINT;
      case MenuType_e.USD:
        return MenuType_e.USD;
      case MenuType_e.KLAY:
        return MenuType_e.KLAY;
      case MenuType_e.CBLT:
        return MenuType_e.CBLT;
      default:
        return undefined;
    }
  };

  const getMenuTxt = (menu: MenuType_e) => {
    switch (menu) {
      case MenuType_e.POINT:
        return `POINT`;
      case MenuType_e.USD:
        return `USD`;
      case MenuType_e.KLAY:
        return `KLAY`;
      case MenuType_e.CBLT:
        return `CBLT`;
    }
  };

  // const getCurrency = (menu: MenuType_e) => {
  //   switch (menu) {
  //     case MenuType_e.POINT:
  //       return CbtCurrency_e.cPOINT;
  //     case MenuType_e.USD:
  //       return CbtCurrency_e.cUSD;
  //     case MenuType_e.KLAY:
  //       return CbtCurrency_e.KLAY;
  //     case MenuType_e.CBLT:
  //       return CbtCurrency_e.CBLT;
  //   }
  // };

  /**************************************
   * !! render function
   **************************************/
  const renderList = () => {
    if (!qsRef.current) return null;

    switch (qsRef.current.menu) {
      case MenuType_e.POINT:
        return <PointHistoryList />;
      case MenuType_e.USD:
        return <UsdHistoryList />;
      case MenuType_e.KLAY:
        return <KlayHistoryList />;
      case MenuType_e.CBLT:
        return <CbltHistoryList />;
    }
  };

  const renderMenuMB = ({ menu, onClick }: { menu: MenuType_e; onClick: () => void }) => {
    if (!qsRef.current) return;

    const menuTxt = getMenuTxt(menu);
    const selectedBgColor = menu === qsRef.current.menu ? R.colors.purpleishBlue : R.colors.white;
    const selectedFontColor = menu === qsRef.current.menu ? R.colors.white : R.colors.blueGrey;
    const selectedBorderColor = menu === qsRef.current.menu ? undefined : R.colors.veryLightGrey2;
    const selectedBorderWidth = menu === qsRef.current.menu ? 0 : 1;

    return (
      <FlexRowDiv
        style={{
          flex: '0 0 auto',
          width: 80,
          height: 34,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: selectedBgColor,
          borderWidth: selectedBorderWidth,
          borderRadius: 10,
          borderColor: selectedBorderColor,
          borderStyle: 'solid',
          cursor: 'pointer',
        }}
        onClick={() => {
          onClick();
        }}
      >
        <TextCon text={menuTxt} color={selectedFontColor} size={12} isBold />
      </FlexRowDiv>
    );
  };

  const renderMenuDT = ({ menu, onClick }: { menu: MenuType_e; onClick: () => void }) => {
    if (!qsRef.current) return;

    const menuTxt = getMenuTxt(menu);
    const selectedBgColor = menu === qsRef.current.menu ? R.colors.blueBlue : R.colors.purpleishBlue;
    const selectedFontColor = menu === qsRef.current.menu ? R.colors.white : R.colors.paleGrey;
    return (
      <FlexRowDiv
        style={{
          alignSelf: 'stretch',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: selectedBgColor,
          borderRadius: 15,
          cursor: 'pointer',
        }}
        onClick={() => {
          onClick();
        }}
      >
        <TextCon text={menuTxt} color={selectedFontColor} size={15} isBold />
      </FlexRowDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/
  // if (!sFetchedData) return <DataFetching />;

  if (!qsRef.current) {
    // qs 없이 접근
    // 잘못된 접근
    return null;
  }

  DBGMSG('render');

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <FlexColumnDiv
            style={{
              backgroundColor: R.colors.bgGrey,
              width: MOBILE_WIDTH,
              alignItems: 'flex-start',
              paddingTop: MOBILE_CONTENT_PADDING_TOP,
              paddingLeft: MOBILE_HORIZONTAL_PADDING,
              paddingRight: MOBILE_HORIZONTAL_PADDING,
              ...devOutline,
            }}
          >
            {/* Back 버튼 */}
            <BackBtn />

            {/* 타이틀 */}
            <FlexRowDiv style={{ alignItems: 'baseline', height: 24, alignSelf: 'stretch' }}>
              <TextCon text={hR.strings.PROP_HISTORY_TITLE} size={16} color={R.colors.black} isBold />
            </FlexRowDiv>
            <HEIGHT size={20} />
            {/* 메뉴 */}
            <FlexRowDiv
              style={{
                width: MOBILE_WIDTH,
                paddingLeft: MOBILE_HORIZONTAL_PADDING,
                marginLeft: -MOBILE_HORIZONTAL_PADDING,
                marginRight: -MOBILE_HORIZONTAL_PADDING,
                overflowY: 'scroll',
              }}
            >
              <FlexRowDiv
                style={{
                  width: MOBILE_HORIZONTAL_PADDING + 320 + 18,
                }}
              >
                {renderMenuMB({
                  menu: MenuType_e.POINT,
                  onClick: () => {
                    hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.POINT }));
                  },
                })}
                <WIDTH size={6} />
                {renderMenuMB({
                  menu: MenuType_e.USD,
                  onClick: () => {
                    hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.USD }));
                  },
                })}
                <WIDTH size={6} />
                {renderMenuMB({
                  menu: MenuType_e.KLAY,
                  onClick: () => {
                    hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.KLAY }));
                  },
                })}
                <WIDTH size={6} />
                {renderMenuMB({
                  menu: MenuType_e.CBLT,
                  onClick: () => {
                    hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.CBLT }));
                  },
                })}
              </FlexRowDiv>
            </FlexRowDiv>

            <HEIGHT size={20} />
          </FlexColumnDiv>
        </MobileScalableFlexColumn>
        {renderList()}
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      </>
    );
  } else {
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          ...devOutline,
        }}
      >
        <HEIGHT size={40} />

        {/* Back 버튼 */}
        <BackBtn />

        {/* 타이틀 */}
        <FlexRowDiv style={{ alignItems: 'baseline', height: 50, alignSelf: 'stretch' }}>
          <TextCon text={hR.strings.PROP_HISTORY_TITLE} size={30} color={R.colors.black} isBold />
        </FlexRowDiv>

        <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

        {/* 메뉴 */}
        <FlexRowDiv
          style={{
            width: ScreenInfo.contentsWidth,
            height: 60,
            backgroundColor: R.colors.purpleishBlue,
            borderRadius: 15,
          }}
        >
          {renderMenuDT({
            menu: MenuType_e.POINT,
            onClick: () => {
              hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.POINT }));
            },
          })}
          {renderMenuDT({
            menu: MenuType_e.USD,
            onClick: () => {
              hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.USD }));
            },
          })}
          {renderMenuDT({
            menu: MenuType_e.KLAY,
            onClick: () => {
              hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.KLAY }));
            },
          })}
          {renderMenuDT({
            menu: MenuType_e.CBLT,
            onClick: () => {
              hHistory.replace(Nav.brewMyPropertyHistoryScreenPath({ menu: MenuType_e.CBLT }));
            },
          })}
        </FlexRowDiv>

        <HEIGHT size={20} />

        {renderList()}
      </FlexColumnDiv>
    );
  }
}
