/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { CSSProperties, HTMLProps, useEffect } from 'react';
import { config, useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import R from 'src/res/R';
import { devOutlineF } from 'src/ui/base_component/etc';
import TxtBtnWithMouseOverAni from 'src/ui/base_component/TxtBtnWithMouseOverAni';
import { CLAY } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  title: string;
  size?: number;
  height?: number;
  containerStyle?: CSSProperties;
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function Title({ title, onClick, size = CLAY.TITLE_FONT_SIZE, height = CLAY.TITLE_HEIGHT, containerStyle }: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    // DBGMSG('useEffect() init');

    return () => {
      // DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return {
      color: R.colors.black,
      config: { ...config.gentle },
    };
  });

  // DBGMSG('render');
  return (
    <TxtBtnWithMouseOverAni
      text={title}
      size={size}
      color={R.colors.black}
      // textStyle={hMouseProps}
      isBold
      containerStyle={{
        height: height,
        cursor: onClick ? 'pointer' : 'auto',
        justifyContent: 'center',
        ...containerStyle,
        // ...devOutlineF
      }}
      // onMouseDefalutProp={}
      // onMouseEnterProp={}
      // onMouseLeaveProp={}
      onClick={(e) => {
        DBGMSG(`onClick`);
        onClick && onClick(e);
      }}
    />
  );
}
