/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { FlexColumnDiv, FlexDiv, TooltipBox } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { CLAY } from 'src/ui/layout_constant';
import styled from 'styled-components';

export const ToolTipBoxContainer = styled(FlexDiv)`
  object-fit: cover;
`;

type Props = {
  tooltipJSX: JSX.Element;
  offsetLeftIfNotMobile?: number;
};

export function TooltipBoxContainer({ tooltipJSX, offsetLeftIfNotMobile }: Props) {
  const hR = useLocalSettings();
  return (
    <FlexColumnDiv
      style={{
        // tooltip offset의 기준 역할
        width: '100%',
        backgroundColor: R.colors.black,
        height: 0,
        alignItems: 'center',
      }}
    >
      <TooltipBox
        style={{
          top: !ScreenInfo.isMobile() ? 0 : undefined,
          left: !ScreenInfo.isMobile() ? offsetLeftIfNotMobile || 0 + 5 : undefined,
          bottom: ScreenInfo.isMobile() ? 5 : undefined,
          zIndex: 1,
          position: 'absolute',
          paddingTop: CLAY.TOOLTIP_VPADDING,
          paddingBottom: CLAY.TOOLTIP_VPADDING,
          paddingLeft: CLAY.TOOLTIP_HPADDING,
          paddingRight: CLAY.TOOLTIP_HPADDING,
        }}
      >
        {tooltipJSX}
      </TooltipBox>
    </FlexColumnDiv>
  );
}
