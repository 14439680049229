/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import ScreenInfo from 'src/context/screen_constants';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMakePressable } from 'src/hooks/useMakePressable';
import { DisplaySrc_t } from 'src/model/model';
import { CbtAssetGroupCardInfo_i, CbtAssetType_e, CbtColCertifyType_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { ASSET_CARD_HEIGHT, ASSET_CARD_WIDTH } from 'src/ui/common_component/AssetCard';
import { AssetDisplayThumb, WhereToUse_e } from 'src/ui/common_component/AssetDisplay/AssetDisplayThumb';
import { MARKET_ASSET_CARD_BODY_WIDTH } from 'src/ui/common_component/MarketAssetCard';
import {
  PropsCommonBox,
  SPRING_PROPS_ANIM_1_Default,
  SPRING_PROPS_ANIM_1_OnMouseEnter,
  SPRING_PROPS_ANIM_1_OnMouseLeave,
} from 'src/ui/layout_constant';
import { DBGMSG, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export type AssetCardGroupProps = {
  idx?: number;
  assetGroupInfo: CbtAssetGroupCardInfo_i;
  assetType: CbtAssetType_e;
  width?: number | string;
  height?: number | string;
  isAnim?: boolean;
  onClick?: () => void;
  enableReportBtn?: boolean;
};

/**************************************
 * !! styled component
 **************************************/

// todo refactoring

export function AssetGroupCard({
  //
  assetGroupInfo,
  assetType,
  isAnim = false,
  enableReportBtn = false,
  onClick,
}: AssetCardGroupProps) {
  /**************************************
   * !! state
   **************************************/
  const [sReportMenuOpen, setReportMenuOpen] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const reportBtnRef = useRef(null);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  useMakePressable([
    {
      elementKey: 'report',
      elementRefList: [reportBtnRef],
      onInsidePress: ({ e, hitRef }) => {
        DBGMSG('report Click');
        if (reportBtnRef.current === hitRef.current) {
          setReportMenuOpen((prev) => !prev);
        }
        e.stopPropagation();
      },
      onOutsidePress: (e) => {
        sReportMenuOpen && setReportMenuOpen(false);
      },
    },
  ]);

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/

  // const AssetType = useMemo(() => {
  //   let temp: CbtAssetType_e;
  //   if (assetGroupInfo.nft_vid) temp = CbtAssetType_e.VIDEO;
  //   else if (assetGroupInfo.nft_aud) temp = CbtAssetType_e.AUDIO;
  //   else temp = CbtAssetType_e.IMAGE;
  //   return temp;
  // }, [assetGroupInfo.nft_aud, assetGroupInfo.nft_vid]);

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  let assetDpInfo: DisplaySrc_t;

  if (assetGroupInfo.asset_type === CbtAssetType_e.AUDIO) {
    assetDpInfo = { kind: 'ReqAudio', img: assetGroupInfo.nft_img, audio: 'null' };
  } else if (assetGroupInfo.asset_type === CbtAssetType_e.VIDEO) {
    assetDpInfo = { kind: 'ReqVideo', img: assetGroupInfo.nft_img, video: 'null' };
  } else {
    assetDpInfo = { kind: 'ReqImage', img: assetGroupInfo.nft_img };
  }
  // Utils.svc.brewAssetDisplaySrc({ img: assetGroupInfo.nft_img, assetType: assetType });

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  if (ScreenInfo.isMobile()) {
    return (
      <FlexColumnDiv style={{ alignItems: 'center' }}>
        {assetGroupInfo.issue_tcnt > 1 && (
          <>
            <AnimatedFlexColumnDiv
              style={{
                position: 'absolute',
                top: 30,
                width: ASSET_CARD_WIDTH - 20,
                height: ASSET_CARD_HEIGHT - 20,
                cursor: onClick ? 'pointer' : 'auto',
                ...PropsCommonBox,
                ...hMouseProps,
                // ...devOutlineF,
              }}
            />
            <AnimatedFlexColumnDiv
              style={{
                position: 'absolute',
                top: 15,
                width: ASSET_CARD_WIDTH - 10,
                height: ASSET_CARD_HEIGHT - 10,
                cursor: onClick ? 'pointer' : 'auto',
                ...PropsCommonBox,
                ...hMouseProps,
                // ...devOutlineF,
              }}
            />
          </>
        )}

        <AnimatedFlexColumnDiv
          style={{
            width: ASSET_CARD_WIDTH,
            height: ASSET_CARD_HEIGHT,
            cursor: onClick ? 'pointer' : 'auto',
            ...PropsCommonBox,
            ...hMouseProps,
            // ...devOutlineF,
          }}
          onMouseEnter={() => {
            // DBGMSG(`onMouseEnter: `);
            // set({ backgroundColor: R.colors.paleGrey, transform: 'scale(1.005)' });
            isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
          }}
          onMouseLeave={() => {
            // DBGMSG(`onMouseLeave: `);
            isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
          }}
          onClick={() => {
            DBGMSG('onClick');
            onClick && onClick();
          }}
        >
          {/* 상단부 */}
          <FlexColumnDiv style={{ alignItems: 'center' }}>
            {/*  Collection 이름 */}
            <FlexRowDiv style={{ alignItems: 'center', width: 248, marginTop: 16, ...devOutline }}>
              {/* collection icon */}
              <FlexDiv style={{ height: 20 }}>
                {assetGroupInfo.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
                  <Img src={[R.images.common_ic_verify3x, 20, 20]} containerStyle={{ marginRight: 4 }} />
                )}
              </FlexDiv>

              {/* collection disp name */}
              <TextCon
                color={R.colors.black}
                isBold
                size={12}
                // text={'컬렉션 이름이 길면 이렇게 처리...'}
                text={assetGroupInfo.col_info.dp_name}
                containerStyle={{}}
              />
            </FlexRowDiv>

            <HEIGHT size={16} />

            {/*  Asset 대표이미지 */}
            <AssetDisplayThumb
              whereToUse={WhereToUse_e.ForMyAssetCard}
              playerSrc={assetDpInfo}
              width={MARKET_ASSET_CARD_BODY_WIDTH}
              height={MARKET_ASSET_CARD_BODY_WIDTH}
              // hiddenBorder
              //
              isBlock={assetGroupInfo.blocked === true}
            />
          </FlexColumnDiv>

          {/* 하단부 */}
          <FlexColumnDiv
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              left: 0,
              alignItems: 'center',
              // ...devOutlineF,
            }}
          >
            {/*  Asset 이름 */}
            <FlexRowDiv
              style={{
                height: 18,
                alignItems: 'center',
                width: 248,
                // marginTop: 20,
                ...devOutline,
              }}
            >
              <TextCon
                color={R.colors.black}
                isBold
                size={12}
                // text={'에셋 이름이 길면 이렇게 처리해주세요...'}
                text={assetGroupInfo.nft_name}
              />
            </FlexRowDiv>

            {/* 발행 개수 */}
            <FlexRowDiv
              style={{
                height: 20,
                width: 248,
                marginTop: 10,
                ...devOutline,
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineF
                }}
              >
                <TextCon
                  color={R.colors.purpleishBlue}
                  size={12}
                  text={Utils.string.parsePlaceholder(hR.strings.AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT, R.strings.PLACEHOLDER_CNT).begin}
                />
                <TextCon color={R.colors.purpleishBlue} size={14} text={`${assetGroupInfo.issue_tcnt}`} isBold />
                <TextCon
                  color={R.colors.purpleishBlue}
                  size={12}
                  text={Utils.string.parsePlaceholder(hR.strings.AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT, R.strings.PLACEHOLDER_CNT).end}
                />
              </FlexRowDiv>
              {enableReportBtn && (
                <FlexDiv
                  ref={reportBtnRef}
                  onClick={(e) => {
                    DBGMSG('etcbtn');
                    e.stopPropagation();
                  }}
                  style={{
                    marginLeft: 'auto',
                    // ...devOutlineF
                  }}
                >
                  <ImgBtn src={[R.images.common_btn_etc3x, 28, 28]} />
                </FlexDiv>
              )}
            </FlexRowDiv>

            <HEIGHT size={20} />
          </FlexColumnDiv>
        </AnimatedFlexColumnDiv>
      </FlexColumnDiv>
    );
  } else {
    return (
      <FlexColumnDiv style={{ alignItems: 'center' }}>
        {assetGroupInfo.issue_tcnt > 1 && (
          <>
            <AnimatedFlexColumnDiv
              style={{
                position: 'absolute',
                top: 30,
                width: ASSET_CARD_WIDTH - 20,
                height: ASSET_CARD_HEIGHT - 20,
                cursor: onClick ? 'pointer' : 'auto',
                ...PropsCommonBox,
                ...hMouseProps,
                // ...devOutlineF,
              }}
            />
            <AnimatedFlexColumnDiv
              style={{
                position: 'absolute',
                top: 15,
                width: ASSET_CARD_WIDTH - 10,
                height: ASSET_CARD_HEIGHT - 10,
                cursor: onClick ? 'pointer' : 'auto',
                ...PropsCommonBox,
                ...hMouseProps,
                // ...devOutlineF,
              }}
            />
          </>
        )}

        <AnimatedFlexColumnDiv
          style={{
            width: ASSET_CARD_WIDTH,
            height: ASSET_CARD_HEIGHT,
            cursor: onClick ? 'pointer' : 'auto',
            ...PropsCommonBox,
            ...hMouseProps,
            // ...devOutlineF,
          }}
          onMouseEnter={() => {
            // DBGMSG(`onMouseEnter: `);
            // set({ backgroundColor: R.colors.paleGrey, transform: 'scale(1.005)' });
            isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
          }}
          onMouseLeave={() => {
            // DBGMSG(`onMouseLeave: `);
            isAnim && set(SPRING_PROPS_ANIM_1_OnMouseLeave);
          }}
          onClick={() => {
            DBGMSG('onClick');
            onClick && onClick();
          }}
        >
          {/* 상단부 */}
          <FlexColumnDiv style={{ alignItems: 'center' }}>
            {/*  Collection 이름 */}
            <FlexRowDiv style={{ alignItems: 'center', width: 236, marginTop: 20, ...devOutline }}>
              <FlexDiv style={{ height: 24 }}>
                {assetGroupInfo.col_info.cert_tp === CbtColCertifyType_e.OFFICIAL && (
                  <Img src={[R.images.common_ic_verify3x, 24, 24]} containerStyle={{ marginRight: 4 }} />
                )}
              </FlexDiv>
              <TextCon
                color={R.colors.black}
                isBold
                size={14}
                // text={'컬렉션 이름이 길면 이렇게 처리...'}
                text={`${assetGroupInfo.col_info.dp_name}`}
                containerStyle={{}}
                textStyle={{ width: 236 - 24 - 4, overflow: 'hidden', textOverflow: 'ellipsis' }}
              />
            </FlexRowDiv>

            <HEIGHT size={20} />

            {/*  Asset 대표이미지 */}
            <AssetDisplayThumb
              whereToUse={WhereToUse_e.ForMyAssetCard}
              playerSrc={assetDpInfo}
              width={236}
              height={236}
              hiddenBorder //
              isBlock={assetGroupInfo.blocked === true}
            />
          </FlexColumnDiv>

          {/* 하단부 */}
          <FlexColumnDiv
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              left: 0,
              alignItems: 'center',
              // ...devOutlineF,
            }}
          >
            {/*  Asset 이름 */}
            <FlexRowDiv
              style={{
                height: 20,
                alignItems: 'center',
                width: MARKET_ASSET_CARD_BODY_WIDTH,
                // marginTop: 20,
                ...devOutline,
              }}
            >
              <TextCon
                color={R.colors.black}
                isBold
                size={14}
                // text={'에셋 이름이 길면 이렇게 처리해주세요...'}
                text={assetGroupInfo.nft_name}
              />
            </FlexRowDiv>

            {/* 발행 개수 */}
            <FlexRowDiv
              style={{
                height: 24,
                width: MARKET_ASSET_CARD_BODY_WIDTH,
                marginTop: 10,
                ...devOutline,
              }}
            >
              <FlexRowDiv
                style={{
                  alignItems: 'baseline',
                  // ...devOutlineF
                }}
              >
                <TextCon
                  color={R.colors.purpleishBlue}
                  size={14}
                  text={Utils.string.parsePlaceholder(hR.strings.AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT, R.strings.PLACEHOLDER_CNT).begin}
                />
                <TextCon color={R.colors.purpleishBlue} size={16} text={`${assetGroupInfo.issue_tcnt}`} isBold />
                <TextCon
                  color={R.colors.purpleishBlue}
                  size={14}
                  text={Utils.string.parsePlaceholder(hR.strings.AC_SCHEMA_READ_NEW_ASSET_ISSUE_COUNT, R.strings.PLACEHOLDER_CNT).end}
                />
              </FlexRowDiv>

              {enableReportBtn && (
                <FlexDiv
                  ref={reportBtnRef}
                  onClick={(e) => {
                    DBGMSG('etcbtn');
                    e.stopPropagation();
                  }}
                  style={{
                    marginLeft: 'auto',
                    // ...devOutlineF
                  }}
                >
                  <ImgBtn src={[R.images.common_btn_etc3x, 34, 34]} />
                </FlexDiv>
              )}
            </FlexRowDiv>

            <HEIGHT size={22} />
          </FlexColumnDiv>
        </AnimatedFlexColumnDiv>
      </FlexColumnDiv>
    );
  }
}
