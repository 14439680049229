/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import AppConfJson from './AppConf.json';

export enum Phase {
  ALPHA = 'alpha',
  BETA = 'beta',
  PROD = 'prod',
  UNKNOWN = 'unknown',
  DEFAULT = UNKNOWN,
}

interface _URLS {
  web: string;
  rpc: string;
}

export const RPC_CONTEXT = 'nftex';

let HOST_URLS: _URLS = { web: '', rpc: '' };
let PHASE: Phase = Phase.DEFAULT;

function initAppEnv() {
  const PROTOCOL = window.location.protocol; // it must be https
  const HOSTNAME = window.location.hostname;
  const HOST = window.location.host;

  // hostname에 따라
  // PHASE 자동 설정
  // RPC, 리소스 URL 설정
  switch (HOSTNAME) {
    case 'localhost':
      PHASE = Phase.ALPHA;
      HOST_URLS = addProtocol(AppConfJson.host.alpha);
      // PHASE = Phase.PROD;
      // HOST_URLS = addProtocol(AppConfJson.host.prod);
      HOST_URLS.web = `${PROTOCOL}//${HOST}`;
      break;
    case AppConfJson.host.alpha.web:
      PHASE = Phase.ALPHA;
      HOST_URLS = addProtocol(AppConfJson.host.alpha);
      break;
    case AppConfJson.host.beta.web:
      PHASE = Phase.BETA;
      HOST_URLS = addProtocol(AppConfJson.host.beta);
      break;
    case AppConfJson.host.prod.web:
      PHASE = Phase.PROD;
      HOST_URLS = addProtocol(AppConfJson.host.prod);
      break;
    case AppConfJson.host.next.web:
      PHASE = Phase.PROD;
      HOST_URLS = addProtocol(AppConfJson.host.prod);
      break;
    default:
      PHASE = Phase.ALPHA;
      HOST_URLS = addProtocol(AppConfJson.host.alpha);
      HOST_URLS.web = `${PROTOCOL}//${HOST}`;
      break;
  }

  DBGMSG(`$$$$INIT $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$`);
  DBGMSG(`$$$$INIT APP NAME: ${AppConfJson.name}`);
  DBGMSG(`$$$$INIT App VERIOSN: ${AppConfJson.version}`);
  DBGMSG(`$$$$INIT APP VERSIONCODE: ${AppConfJson.versionCode}`);
  DBGMSG(`$$$$INIT APP PHASE: ${PHASE}`);
  DBGMSG(`$$$$INIT APP URL.web: ${HOST_URLS.web}`);
  DBGMSG(`$$$$INIT APP URL.rpc: ${HOST_URLS.rpc}`);
  DBGMSG(`$$$$INIT DEVICE INFO: ${JSON.stringify(Utils.device.reactDeviceDetect.deviceDetect(), undefined, 4)}`);
  DBGMSG(`$$$$INIT window.location: ${JSON.stringify(window.location, undefined, 4)}`);
  DBGMSG(`$$$$INIT process.env.NODE_ENV: ${process.env.NODE_ENV}`);
  DBGMSG(`$$$$INIT $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$`);
  DBGMSGW(`Cobalt ${AppConfJson.version} ${AppConfJson.versionCode}`);
}

// https or http 현재 location과 동일하게 설정됨
function addProtocol(urls: _URLS): _URLS {
  const PROTOCOL = window.location.protocol;
  return {
    web: `${PROTOCOL}//${urls.web}`,
    rpc: `${PROTOCOL}//${urls.rpc}`,
    // web: `https://${urls.web}`,
    // rpc: `https://${urls.rpc}`,
    // res: `https://${urls.res}`,
  };
}

initAppEnv();

export const AppConf = {
  APPNAME: AppConfJson.name,
  VERSION: AppConfJson.version,
  VERSION_CODE: AppConfJson.versionCode,
  VERSION_NOTE: AppConfJson.versionNote,
  HOST_URLS,
  PHASE,
};

export const NATIVE_APPID = {
  HSD: { iOS: 60001, Android: 60005 },
  POKE: { iOS: 20000, Android: 20005, Android_AU: 20006 },
};

export function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function isAlpha() {
  return PHASE === Phase.ALPHA;
}

export function isBeta() {
  return PHASE === Phase.BETA;
}

export function isProd() {
  return PHASE === Phase.PROD;
}