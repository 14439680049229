/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { ServerECode_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import TextConMulti from 'src/ui/base_component/TextConMulti';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { RoundTxtBtnDone } from 'src/ui/common_component/button/RoundTxtBtnDone';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { CommonColumnBox, JOIN_SCREEN_LAYOUT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function UserJoinScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sEmail, setEmail] = useState<string>('');
  const [sEmailValidation, setEmailValidation] = useState<{
    isDupError?: boolean; // undefined: 체크전, true: 중복 에러일경우 , false: 중복 에러 아닐경우
    isTypeError?: boolean; // undefined: 체크전, true: 이메일 형식 잘못됬을 경우, false: 이메일 형식 정상인경우
    emsg?: string; // UI상 표시할 에러메세지
  }>({});

  const [sEmailAuthCode, setEmailAuthCode] = useState<string>(''); // 매직넘버 1029@!
  const [sEmailAuthStatus, setEmailAuthStatus] = useState<{
    authStatus: 'BEFORE_SENDMAIL' | 'AFTER_SENDMAIL' | 'COMPLETE';
    authFailMsg?: string; // 인증 오류 메세제
  }>({ authStatus: 'BEFORE_SENDMAIL' });

  const [sPassword, setPassword] = useState<string>('');
  const [sPasswordFocus, setPasswordFocus] = useState(false);
  const [sPasswordValidation, setPasswordValidation] = useState<{
    isOk: boolean;
    check1: boolean; // 영문 대문자 포함
    check2: boolean; // 영문 소문자 포함
    check3: boolean; // 숫자 포함
    check4: boolean; // 10자 이상
  }>({
    isOk: false,
    check1: false,
    check2: false,
    check3: false,
    check4: false,
  });

  const [sPasswordConfirm, setPasswordConfirm] = useState<string>('');
  const [sPasswordConfirmValidation, setPasswordConfirmValidation] = useState<{
    isOk: boolean;
    emsg?: string;
  }>({ isOk: false });
  const [sPasswordConfirmFocus, setPasswordConfirmFocus] = useState(false);
  const [sPasswordVisible, setPasswordVisible] = useState(false);
  const [sPasswordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const [sFishCode, setFishCode] = useState<string>('');
  const [sFishCodeFocus, setFishCodeFocus] = useState(false);
  const [sCheckAgeOver20, setCheckAgeOver20] = useState(false);
  const [sCheckTerms, setCheckTerms] = useState(false);

  const [sJoinBtnEnable, setJoinBtnEnable] = useState(false);
  const [sVerifyBtnEnable, setVerifyBtnEnable] = useState(true);

  /**************************************
   * !! ref
   **************************************/
  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const passwordConfirmInputRef = useRef<HTMLInputElement | null>(null);
  const fishCodeInputRef = useRef<HTMLInputElement | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`User Join - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hAuth = useAuth();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  // 가입버튼 활성화
  useEffect(() => {
    DBGMSG('useEffect()');
    if (
      sEmailValidation.isTypeError !== true &&
      sEmailValidation.isDupError !== true &&
      sEmailAuthStatus.authStatus === 'COMPLETE' &&
      sPasswordValidation.isOk &&
      sPasswordConfirmValidation.isOk &&
      sFishCode.length > 0 &&
      sCheckAgeOver20 &&
      sCheckTerms
    ) {
      setJoinBtnEnable(true);
    } else {
      setJoinBtnEnable(false);
    }
    return () => {};
  }, [
    sCheckAgeOver20,
    sCheckTerms,
    sEmailAuthStatus.authStatus,
    sEmailValidation.isDupError,
    sEmailValidation.isTypeError,
    sFishCode.length,
    sPasswordConfirmValidation.isOk,
    sPasswordValidation.isOk,
  ]);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  // 같은지 확인
  const checkPasswordConfirm = (p1: string, p2: string) => {
    if (p2.length === 0) {
      setPasswordConfirmValidation({ isOk: true });
      return;
    }
    if (p1 !== p2) {
      setPasswordConfirmValidation({ isOk: false, emsg: hR.strings.JOIN_PASSWORD_CONFIRM_INVALID });
    } else {
      setPasswordConfirmValidation({ isOk: true });
    }
  };

  const validateEmail = (email: string) => {
    if (email.length > 0) {
      // email형식 체크
      if (!Constants.REGEXP_IS_EMAIL.test(email)) {
        DBGMSG(`$$$REGEX email type error`);
        setEmailValidation({ isTypeError: true, emsg: hR.strings.JOIN_EMAIL_INVALID });
        return;
      }
      setEmailValidation({ isTypeError: false });
      setEmailAuthStatus({ authStatus: 'BEFORE_SENDMAIL', authFailMsg: undefined });
    } else {
      // 입력이 없을 경우 reset
      setEmailValidation({});
    }
  };

  /**************************************
   * !! render function
   **************************************/
  const renderEmailAuth = () => {
    switch (sEmailAuthStatus.authStatus) {
      case 'BEFORE_SENDMAIL':
        return (
          <>
            <TextCon
              text={hR.strings.JOIN_EMAIL_AUTH}
              size={JOIN_SCREEN_LAYOUT.formFontSize}
              color={R.colors.black}
              isBold
              containerStyle={{ alignSelf: 'flex-start' }}
            />
            <HEIGHT size={JOIN_SCREEN_LAYOUT.formTopMargin} />
            <FlexRowDiv style={{ alignSelf: 'stretch', justifyContent: 'space-between' }}>
              <form>
                <SRoundInputText
                  fontSize={JOIN_SCREEN_LAYOUT.formFontSize}
                  width={JOIN_SCREEN_LAYOUT.authFormSize.width}
                  height={JOIN_SCREEN_LAYOUT.authFormSize.height}
                  value={sEmailAuthCode}
                  // disabled
                  placeHolder={hR.strings.JOIN_EMAIL_AUTH_PLACEHOLDER}
                  onChange={(e) => {
                    setEmailAuthCode(e.target.value);
                  }}
                  autoComplete={false}
                />
              </form>

              <RoundTxtBtn
                fontSize={JOIN_SCREEN_LAYOUT.authBtnFontSize}
                width={JOIN_SCREEN_LAYOUT.authBtnSize.width}
                height={JOIN_SCREEN_LAYOUT.authBtnSize.height}
                text={hR.strings.JOIN_EMAIL_AUTH_SEND}
                onClick={() => {
                  // 이메일 인증메일 전송 RPC 요청
                  hRpc.cobaltRpc
                    .rpcUserCertifyEmail({ email: sEmail, lang: hR.lang })
                    .then((res) => {
                      setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL' });
                    })
                    .catch((e) => {
                      const rpcErrorInfo = getRpcErrorInfo(e);
                      if (rpcErrorInfo) {
                        switch (rpcErrorInfo.ecode) {
                          // 중복된 이메일 아이디가 있는경우
                          case ServerECode_e.ALREADY_OCCUPIED_EMAIL_ADDR:
                            setEmailAuthStatus({ authStatus: 'BEFORE_SENDMAIL' });
                            setEmailValidation({ isDupError: true, emsg: hR.strings.JOIN_EMAIL_DUPLICATED });
                            break;
                          default:
                            DBGMSGW(`unknown ecode CHECKME:${rpcErrorInfo.ecode}`);
                        }
                      }
                    });
                }}
              />
            </FlexRowDiv>
            <HEIGHT size={24} />
          </>
        );
      case 'AFTER_SENDMAIL':
        return (
          <>
            <TextCon
              text={hR.strings.JOIN_EMAIL_AUTH}
              size={JOIN_SCREEN_LAYOUT.formFontSize}
              color={R.colors.black}
              isBold
              containerStyle={{ alignSelf: 'flex-start' }}
            />
            <HEIGHT size={JOIN_SCREEN_LAYOUT.formTopMargin} />
            <FlexRowDiv style={{ alignSelf: 'stretch', justifyContent: 'space-between' }}>
              <form>
                <SRoundInputText
                  fontSize={JOIN_SCREEN_LAYOUT.formFontSize}
                  width={JOIN_SCREEN_LAYOUT.authFormSize.width}
                  height={JOIN_SCREEN_LAYOUT.authFormSize.height}
                  value={sEmailAuthCode}
                  placeHolder={hR.strings.JOIN_EMAIL_AUTH_PLACEHOLDER}
                  onChange={(e) => {
                    setEmailAuthCode(e.target.value);
                    setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL' });
                  }}
                  autoComplete={false}
                  isShowErrorIcAndBorder={sEmailAuthStatus.authFailMsg !== undefined}
                  hasImgBtn={false}
                />
              </form>
              <RoundTxtBtn
                fontSize={JOIN_SCREEN_LAYOUT.authBtnFontSize}
                width={JOIN_SCREEN_LAYOUT.authBtnSize.width}
                height={JOIN_SCREEN_LAYOUT.authBtnSize.height}
                enable={sVerifyBtnEnable}
                text={hR.strings.JOIN_EMAIL_AUTH_BTN}
                onClick={() => {
                  hRpc.cobaltRpc
                    .rpcUserCertifyEmailTry({ email: sEmail, auth_code: sEmailAuthCode })
                    .then((res) => {
                      setEmailAuthStatus({ authStatus: 'COMPLETE' });
                    })
                    .catch((e) => {
                      const errInfo = getRpcErrorInfo(e);
                      if (errInfo) {
                        DBGMSGW(JSON.stringify(errInfo, undefined, 4));

                        // 에러메세지 출력
                        setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL', authFailMsg: hR.strings.JOIN_EMAIL_AUTH_INVALID });

                        // 5초동안 버튼을 비활성화
                        setVerifyBtnEnable(false);
                        setTimeout(() => {
                          setVerifyBtnEnable(true);
                        }, Constants.EMAIL_AUTH_CODE_RETRY_DELAY_MS);
                      }
                    });
                }}
              />
            </FlexRowDiv>
          </>
        );
      case 'COMPLETE':
        return (
          <>
            <TextCon
              text={hR.strings.JOIN_EMAIL_AUTH}
              size={JOIN_SCREEN_LAYOUT.formFontSize}
              color={R.colors.black}
              isBold
              containerStyle={{ alignSelf: 'flex-start' }}
            />
            <HEIGHT size={JOIN_SCREEN_LAYOUT.formTopMargin} />
            <FlexRowDiv style={{ alignSelf: 'stretch', justifyContent: 'space-between' }}>
              <form>
                <SRoundInputText
                  fontSize={JOIN_SCREEN_LAYOUT.formFontSize}
                  width={JOIN_SCREEN_LAYOUT.authFormSize.width}
                  height={JOIN_SCREEN_LAYOUT.authFormSize.height}
                  value={sEmailAuthCode}
                  disabled
                  onChange={(e) => {
                    setEmailAuthCode(e.target.value);
                  }}
                  autoComplete={false}
                />
              </form>
              <RoundTxtBtnDone
                fontSize={JOIN_SCREEN_LAYOUT.authBtnFontSize}
                width={JOIN_SCREEN_LAYOUT.authBtnSize.width}
                height={JOIN_SCREEN_LAYOUT.authBtnSize.height}
                label={hR.strings.JOIN_EMAIL_AUTH_SUCC}
              />
            </FlexRowDiv>
            <HEIGHT size={24} />
          </>
        );
    }
  };

  /* 인증메일 안내 */
  const renderEmailAuthGuide = () => {
    return (
      <>
        <HEIGHT size={JOIN_SCREEN_LAYOUT.validationMsgTopMargin} />
        {sEmailAuthStatus.authFailMsg ? (
          // 인증 번호가 일치하지 않습니다.
          <TextCon
            text={hR.strings.JOIN_EMAIL_AUTH_INVALID}
            size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
            color={R.colors.pastelRed}
            containerStyle={{ alignSelf: 'flex-start' }}
          />
        ) : (
          <TextConMulti
            text={[hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE1_1, hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE1_2]}
            size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
            color={R.colors.pastelRed}
            containerStyle={{ alignSelf: 'flex-start' }}
          />
        )}
        <HEIGHT size={12} />
        <FlexColumnDiv
          style={{
            borderRadius: 15,
            backgroundColor: R.colors.lightGrey,
            padding: 14,
            alignSelf: 'stretch',
            // ...devOutlineF
          }}
        >
          <FlexRowDiv>
            <TextCon
              text={'*'}
              size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
              color={R.colors.blueGrey}
              containerStyle={{ alignSelf: 'flex-start', width: 10 }}
            />
            <TextCon
              text={hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE2_1}
              size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
              color={R.colors.blueGrey}
              containerStyle={{ alignSelf: 'flex-start' }}
            />
          </FlexRowDiv>
          <HEIGHT size={5} />
          <TextCon
            text={`    ${hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE2_2}`}
            size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
            color={R.colors.blueGrey}
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={5} />
          <FlexRowDiv>
            <TextCon
              text={'*'}
              size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
              color={R.colors.blueGrey}
              containerStyle={{ alignSelf: 'flex-start', width: 10 }}
            />
            <TextCon
              text={hR.strings.JOIN_EMAIL_AUTH_SEND_GUIDE2_3}
              size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
              color={R.colors.blueGrey}
              containerStyle={{ alignSelf: 'flex-start' }}
            />
          </FlexRowDiv>
          <HEIGHT size={5} />
          <FlexRowDiv>
            <TextCon
              text={''}
              size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
              color={R.colors.blueGrey}
              containerStyle={{ alignSelf: 'flex-start', width: 10 }}
            />
            <TextCon
              text={hR.strings.JOIN_EMAIL_AUTH_RESEND}
              size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
              color={R.colors.purpleishBlue}
              isBold
              containerStyle={{ alignSelf: 'flex-start' }}
              onConClick={() => {
                // todo email 재발송 RPC 호출
                hRpc.cobaltRpc.rpcUserCertifyEmail({ email: sEmail, lang: hR.lang }).then((res) => {
                  setEmailAuthStatus({ authStatus: 'AFTER_SENDMAIL' });
                });
              }}
            />
          </FlexRowDiv>
        </FlexColumnDiv>
        <HEIGHT size={JOIN_SCREEN_LAYOUT.formTopMargin} />
      </>
    );
  };

  // 서비스 이용약관
  const renderTermsLink = () => {
    return (
      <TextCon
        text={hR.strings.FOOTER_TERMS}
        size={JOIN_SCREEN_LAYOUT.checkFontSize}
        color={R.colors.purpleishBlue}
        isUnderline
        isBold
        onConClick={() => {
          window.open(Nav.brewUserTermsOfUseScreenPath({ lang: hR.lang }));
        }}
      />
    );
  };

  // 개인정보 처리방침
  const renderPrivacyLink = () => {
    return (
      <TextCon
        text={hR.strings.FOOTER_PRIVACY}
        size={JOIN_SCREEN_LAYOUT.checkFontSize}
        color={R.colors.purpleishBlue}
        isUnderline
        isBold
        onConClick={() => {
          window.open(Nav.brewUserPrivacyPolicyScreenPath({ lang: hR.lang }));
        }}
      />
    );
  };

  const commonRender = () => {
    return (
      <FlexColumnDiv
        style={{
          // backgroundColor: R.colors.rn.black1,
          alignItems: 'center',
          paddingTop: JOIN_SCREEN_LAYOUT.topMargin,
          ...devOutline,
        }}
      >
        <CommonColumnBox
          style={{
            //
            width: JOIN_SCREEN_LAYOUT.boxWidth,
            // justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: JOIN_SCREEN_LAYOUT.horizontalPadding,
            paddingRight: JOIN_SCREEN_LAYOUT.horizontalPadding,
          }}
        >
          <HEIGHT size={JOIN_SCREEN_LAYOUT.topPadding} />

          {/* 타이틀 */}
          <TextCon text={hR.strings.JOIN_TITLE} color={R.colors.black} isBold size={JOIN_SCREEN_LAYOUT.titleFontSize} />

          <HEIGHT size={JOIN_SCREEN_LAYOUT.titleBottomMargin} />

          {/* EMAIL */}
          <TextCon
            text={hR.strings.JOIN_EMAIL}
            size={JOIN_SCREEN_LAYOUT.formFontSize}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={JOIN_SCREEN_LAYOUT.formInputTopMargin} />
          <FlexRowDiv style={{ alignSelf: 'stretch' }}>
            <form>
              <SRoundInputText
                fontSize={JOIN_SCREEN_LAYOUT.formFontSize}
                width={JOIN_SCREEN_LAYOUT.formSize.width}
                height={JOIN_SCREEN_LAYOUT.formSize.height}
                value={sEmail}
                inputType={'email'}
                placeHolder={hR.strings.JOIN_EMAIL_PLACEHOLDER}
                onChange={(e) => {
                  setEmail(e.target.value);

                  // 입력내용이 변하면 reset
                  setEmailValidation({});

                  setEmailAuthStatus({ authStatus: 'BEFORE_SENDMAIL', authFailMsg: undefined });
                  validateEmail(e.target.value);
                }}
                // email형식 체크 시점
                onBlur={() => {}}
                isShowErrorIcAndBorder={sEmailValidation.isDupError === true} // 중복된 이메일 error 인 경우
                isShowOkIc={sEmailValidation.isTypeError === false} // 이메일 형식 오류가 없을 경우
                autoComplete={false}
                hasImgBtn={false}
              />
            </form>
          </FlexRowDiv>

          {sEmailValidation.emsg && (
            <>
              <HEIGHT size={JOIN_SCREEN_LAYOUT.validationMsgTopMargin} />
              <TextCon
                text={sEmailValidation.emsg}
                size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
                color={R.colors.pastelRed}
                containerStyle={{ alignSelf: 'flex-start' }}
              />
            </>
          )}

          <HEIGHT size={JOIN_SCREEN_LAYOUT.formTopMargin} />

          {/* 이메일 인증하기 */}
          {sEmailValidation.isTypeError === false && renderEmailAuth()}
          {sEmailAuthStatus.authStatus === 'AFTER_SENDMAIL' && renderEmailAuthGuide()}

          {/* PASSWORD */}
          <TextCon
            text={hR.strings.JOIN_PASSWORD}
            size={JOIN_SCREEN_LAYOUT.formFontSize}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={JOIN_SCREEN_LAYOUT.formInputTopMargin} />
          <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'center' }}>
            <form>
              <SRoundInputText
                fontSize={JOIN_SCREEN_LAYOUT.formFontSize}
                width={JOIN_SCREEN_LAYOUT.formSize.width}
                height={JOIN_SCREEN_LAYOUT.formSize.height}
                getRef={(inputRef) => {
                  passwordInputRef.current = inputRef;
                }}
                value={sPassword}
                inputType={sPasswordVisible ? 'text' : 'password'}
                placeHolder={hR.strings.JOIN_PASSWORD_PLACEHOLDER}
                autoComplete={false}
                onChange={(e) => {
                  setPassword(e.target.value);

                  const UpperCaseRegex = /[A-Z]/;
                  const LowerCaseRegex = /[a-z]/;
                  const NumRegex = /[0-9]/;

                  const check1 = UpperCaseRegex.test(e.target.value);
                  const check2 = LowerCaseRegex.test(e.target.value);
                  const check3 = NumRegex.test(e.target.value);
                  const check4 = e.target.value.length >= 10;

                  setPasswordValidation({
                    isOk: check1 && check2 && check3 && check4,
                    check1: check1,
                    check2: check2,
                    check3: check3,
                    check4: check4,
                  });
                  checkPasswordConfirm(e.target.value, sPasswordConfirm);
                }}
                onFocus={() => {
                  setPasswordFocus(true);
                }}
                onBlur={() => {
                  setPasswordFocus(false);
                }}
                isShowErrorIcAndBorder={sPassword.length > 0 && !sPasswordValidation.isOk}
              />
            </form>
            {sPasswordValidation.isOk && (
              <Img
                src={[R.images.join_ic_confirm3x, JOIN_SCREEN_LAYOUT.confirmIcSize, JOIN_SCREEN_LAYOUT.confirmIcSize]}
                containerStyle={{ position: 'absolute', right: ScreenInfo.isMobile() ? 36 : 48 }}
              />
            )}
            <ImgBtn
              src={[
                sPasswordVisible ? R.images.common_btn_visible3x : R.images.common_btn_not_visible3x,
                JOIN_SCREEN_LAYOUT.pwViewIcSize,
                JOIN_SCREEN_LAYOUT.pwViewIcSize,
              ]}
              containerStyle={{ position: 'absolute', right: 1, padding: 7 }}
              onClick={() => {
                setPasswordVisible(!sPasswordVisible);
                passwordInputRef.current?.setSelectionRange(sPassword.length, sPassword.length);
                passwordInputRef.current?.focus();
              }}
            />
          </FlexRowDiv>

          {sPassword.length > 0 && !sPasswordValidation.isOk && (
            <>
              <HEIGHT size={JOIN_SCREEN_LAYOUT.validationMsgTopMargin} />
              <FlexColumnDiv
                style={{
                  alignSelf: 'stretch',
                  borderRadius: 15,
                  backgroundColor: R.colors.lightGrey,
                  padding: 14,
                }}
              >
                <TextCon
                  text={hR.strings.JOIN_PASSWORD_GUIDE_1}
                  size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
                  color={sPasswordValidation.check1 ? R.colors.purpleishBlue : R.colors.blueGrey}
                  isBold={sPasswordValidation.check1}
                />
                <HEIGHT size={2} />
                <TextCon
                  text={hR.strings.JOIN_PASSWORD_GUIDE_2}
                  size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
                  color={sPasswordValidation.check2 ? R.colors.purpleishBlue : R.colors.blueGrey}
                  isBold={sPasswordValidation.check2}
                />
                <HEIGHT size={2} />
                <TextCon
                  text={hR.strings.JOIN_PASSWORD_GUIDE_3}
                  size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
                  color={sPasswordValidation.check3 ? R.colors.purpleishBlue : R.colors.blueGrey}
                  isBold={sPasswordValidation.check3}
                />
                <HEIGHT size={2} />
                <TextCon
                  text={hR.strings.JOIN_PASSWORD_GUIDE_4}
                  size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
                  color={sPasswordValidation.check4 ? R.colors.purpleishBlue : R.colors.blueGrey}
                  isBold={sPasswordValidation.check4}
                />
              </FlexColumnDiv>
            </>
          )}

          <HEIGHT size={JOIN_SCREEN_LAYOUT.formTopMargin} />

          {/* PASSWORD 확인 */}
          <TextCon
            text={hR.strings.JOIN_PASSWORD_CONFIRM}
            size={JOIN_SCREEN_LAYOUT.formFontSize}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={JOIN_SCREEN_LAYOUT.formInputTopMargin} />
          <FlexRowDiv style={{ alignSelf: 'stretch', alignItems: 'center' }}>
            <form>
              <SRoundInputText
                fontSize={JOIN_SCREEN_LAYOUT.formFontSize}
                width={JOIN_SCREEN_LAYOUT.formSize.width}
                height={JOIN_SCREEN_LAYOUT.formSize.height}
                getRef={(inputRef) => {
                  passwordConfirmInputRef.current = inputRef;
                }}
                // disabled={sPasswordValidation.isOk === false}
                value={sPasswordConfirm}
                inputType={sPasswordConfirmVisible ? 'text' : 'password'}
                placeHolder={hR.strings.JOIN_PASSWORD_CONFIRM_PLACEHOLDER}
                autoComplete={false}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                  checkPasswordConfirm(sPassword, e.target.value);
                }}
                onFocus={() => {
                  setPasswordConfirmFocus(true);
                }}
                onBlur={() => {
                  setPasswordConfirmFocus(false);
                }}
              />
            </form>
            {sPasswordConfirm.length > 0 && sPasswordConfirmValidation.isOk && sPasswordValidation.isOk && (
              <Img
                src={[R.images.join_ic_confirm3x, JOIN_SCREEN_LAYOUT.confirmIcSize, JOIN_SCREEN_LAYOUT.confirmIcSize]}
                containerStyle={{ position: 'absolute', right: ScreenInfo.isMobile() ? 36 : 48 }}
              />
            )}
            <ImgBtn
              src={[
                sPasswordConfirmVisible ? R.images.common_btn_visible3x : R.images.common_btn_not_visible3x,
                JOIN_SCREEN_LAYOUT.pwViewIcSize,
                JOIN_SCREEN_LAYOUT.pwViewIcSize,
              ]}
              containerStyle={{ position: 'absolute', right: 1, padding: 7 }}
              onClick={() => {
                setPasswordConfirmVisible(!sPasswordConfirmVisible);
                passwordConfirmInputRef.current?.focus();
              }}
            />
          </FlexRowDiv>
          {sPasswordConfirmValidation && !sPasswordConfirmValidation.isOk && sPasswordConfirmValidation.emsg && (
            <>
              <HEIGHT size={JOIN_SCREEN_LAYOUT.validationMsgTopMargin} />
              <TextCon
                text={sPasswordConfirmValidation.emsg}
                size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
                color={R.colors.pastelRed}
                containerStyle={{ alignSelf: 'flex-start' }}
              />
            </>
          )}

          <HEIGHT size={JOIN_SCREEN_LAYOUT.formTopMargin} />

          {/* 피싱 방지 코드 */}
          <TextCon
            text={hR.strings.JOIN_FISH_SECURE_CODE}
            size={JOIN_SCREEN_LAYOUT.formFontSize}
            color={R.colors.black}
            isBold
            containerStyle={{ alignSelf: 'flex-start' }}
          />
          <HEIGHT size={JOIN_SCREEN_LAYOUT.formInputTopMargin} />
          <form>
            <SRoundInputText
              fontSize={JOIN_SCREEN_LAYOUT.formFontSize}
              width={JOIN_SCREEN_LAYOUT.formSize.width}
              height={JOIN_SCREEN_LAYOUT.formSize.height}
              getRef={(inputRef) => {
                fishCodeInputRef.current = inputRef;
              }}
              value={sFishCode}
              placeHolder={hR.strings.JOIN_FISH_SECURE_CODE_PLACEHOLDER}
              onChange={(e) => {
                setFishCode(e.target.value);
              }}
              onFocus={() => {
                setFishCodeFocus(true);
              }}
              onBlur={() => {
                setFishCodeFocus(false);
              }}
              autoComplete={false}
            />
          </form>
          {sFishCodeFocus && (
            <>
              <HEIGHT size={JOIN_SCREEN_LAYOUT.validationMsgTopMargin} />
              <FlexColumnDiv
                style={{
                  alignSelf: 'stretch',
                  borderRadius: 15,
                  backgroundColor: R.colors.lightGrey,
                  padding: 14,
                }}
              >
                <TextCon
                  text={hR.strings.JOIN_FISH_SECURE_CODE_GUIDE_1}
                  size={JOIN_SCREEN_LAYOUT.validationMsgFontSize}
                  color={R.colors.purpleishBlue}
                  isBold
                />
                <HEIGHT size={2} />
                <TextCon text={hR.strings.JOIN_FISH_SECURE_CODE_GUIDE_2} size={JOIN_SCREEN_LAYOUT.validationMsgFontSize} color={R.colors.blueGrey} />
                <TextCon text={hR.strings.JOIN_FISH_SECURE_CODE_GUIDE_3} size={JOIN_SCREEN_LAYOUT.validationMsgFontSize} color={R.colors.blueGrey} />
              </FlexColumnDiv>
            </>
          )}

          <HEIGHT size={JOIN_SCREEN_LAYOUT.formInputTopMargin - 5} />

          {/* 만 20세 이상입니다. */}
          <FlexRowDiv
            style={{
              alignSelf: 'stretch',
              alignItems: 'center',
              // ...devOutlineF
            }}
          >
            <ImgBtn
              src={[
                sCheckAgeOver20 ? R.images.join_btn_check_abled3x : R.images.join_btn_check_disabled3x,
                JOIN_SCREEN_LAYOUT.checkBoxSize,
                JOIN_SCREEN_LAYOUT.checkBoxSize,
              ]}
              containerStyle={{
                //  transform: 'translate(0px,-1px)',
                paddingTop: 5,
                paddingRight: 5,
                paddingBottom: 5,
              }}
              onClick={() => {
                setCheckAgeOver20(!sCheckAgeOver20);
              }}
            />
            <WIDTH size={6} />
            <TextCon text={hR.strings.JOIN_AGE_OVER_20} size={JOIN_SCREEN_LAYOUT.checkFontSize} color={R.colors.black} isBold />
          </FlexRowDiv>

          {/* 약관 동의 */}
          <FlexRowDiv
            style={{
              alignSelf: 'stretch',
              alignItems: 'center',
              // ...devOutlineF
            }}
          >
            <ImgBtn
              src={[
                sCheckTerms ? R.images.join_btn_check_abled3x : R.images.join_btn_check_disabled3x,
                JOIN_SCREEN_LAYOUT.checkBoxSize,
                JOIN_SCREEN_LAYOUT.checkBoxSize,
              ]}
              containerStyle={{
                //  transform: 'translate(0px,-1px)',
                paddingTop: 5,
                paddingRight: 5,
                paddingBottom: 5,
              }}
              onClick={() => {
                setCheckTerms(!sCheckTerms);
              }}
            />
            <WIDTH size={6} />
            <TextCon text={hR.strings.JOIN_USAGE_TERM} size={JOIN_SCREEN_LAYOUT.checkFontSize} color={R.colors.black} isBold />

            {!ScreenInfo.isMobile() && (
              <>
                <WIDTH size={3} />
                {renderTermsLink()}
                <WIDTH size={6} />
                {renderPrivacyLink()}
              </>
            )}
          </FlexRowDiv>
          {ScreenInfo.isMobile() && (
            <FlexRowDiv
              style={{
                alignSelf: 'stretch',
                alignItems: 'flex-start',
                marginLeft: JOIN_SCREEN_LAYOUT.checkBoxSize + 5 + 3,
                // ...devOutlineF
              }}
            >
              <WIDTH size={3} />
              {renderTermsLink()}
              <WIDTH size={6} />
              {renderPrivacyLink()}
            </FlexRowDiv>
          )}

          <HEIGHT size={JOIN_SCREEN_LAYOUT.joinBtnVeticalMargin} />

          {/* 가입하기 버튼 */}
          <RoundTxtBtn
            text={hR.strings.JOIN_BTN}
            fontSize={JOIN_SCREEN_LAYOUT.joinBtFontSize}
            width={JOIN_SCREEN_LAYOUT.joinBtnSize.width}
            height={JOIN_SCREEN_LAYOUT.joinBtnSize.height}
            enable={sJoinBtnEnable}
            onClick={async () => {
              DBGMSG('JOIN');
              const sha256digestHex = await Utils.crypto.sha256(sPassword);
              DBGMSG(sha256digestHex);

              hRpc.cobaltRpc
                .rpcUserSignup({
                  email: sEmail,
                  password: sha256digestHex,
                  ap_cd: sFishCode,
                  lang: hR.lang,
                })
                .then((res) => {
                  hRpc.cobaltRpc.rpcUserLogin({ email: sEmail, password: sha256digestHex, lang: hR.lang }).then(async (res) => {
                    if (res.ucode === null) {
                      DBGMSGW('error');
                      return;
                    }
                    if (res.token === null) {
                      DBGMSGW('error');
                      return;
                    }
                    if (res.mkey === null) {
                      DBGMSGW('error');
                      return;
                    }

                    hAuth.authActs.login({
                      email: sEmail,
                      authCommonRes: {
                        ucode: res.ucode,
                        token: res.token,
                        mkey: res.mkey,
                        cert_tp: res.cert_tp,
                      },
                    });
                    //   //
                    //   email: sEmail,
                    //   ucode: res.ucode,
                    //   prof: res.prof,
                    //   token: res.token,
                    //   mkey: res.mkey,
                    //   reward_addr: res.reward_addr,
                    // });
                    hHistory.push(Nav.HomeScreen);
                  });
                });
            }}
          />
          <HEIGHT size={JOIN_SCREEN_LAYOUT.joinBtnVeticalMargin} />
        </CommonColumnBox>
        <HEIGHT size={JOIN_SCREEN_LAYOUT.bottomMargin} />

        {/* 공통푸터 */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </FlexColumnDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    return commonRender();
  } else {
    return <MobileScalableFlexColumn>{commonRender()}</MobileScalableFlexColumn>;
  }
}
