/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { animated, config, useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import { AnimatedFlexColumnDiv, devOutlineF, FlexColumnDiv, FlexRowDiv } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { useMobileSizeHelper } from 'src/hooks/useMobileWidthHelper';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { CLAY } from 'src/ui/layout_constant';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type LineAuthorizationRes = {
  code: string;
  state: string;
  // friendship_status_changed:boolean; ??
};

type LineAuthorizationErrRes = {
  error: string;
  error_description?: number;
  state?: string;
};

type LineAccessTokenRes = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: number;
  token_type: string;
};

type LineProfileRes = {
  displayName: string;
  pictureUrl: string;
  statusMessage: string;
  userId: string;
};

type Props = {};

export function TestLoginScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [lineAuthorizationInfo, setLineAuthorizationInfo] = useState<LineAuthorizationRes>();
  const [lineAccessTokenInfo, setLineAccessTokenInfo] = useState<LineAccessTokenRes>();
  const [lineProfileInfo, setLineProfileInfo] = useState<LineProfileRes>();

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hHistory = useHistory();
  const location = useLocation();
  const { screenWidth, windowSize } = useMobileSizeHelper();

  /**************************************
   * !! animation
   **************************************/
  const aprops = useSpring({ opacity: 1, from: { opacity: 0.3 }, config: { duration: 250 } });
  const aprops2 = useSpring({ number: 1, from: { number: 0.3 }, config: { duration: 250, friction: 1 } });
  // const aprops3 = useSpring({
  //   to: async (next, cancel) => {
  //     await next({ backgroundColor: '#ffaaee' });
  //     await next({ backgroundColor: 'rgb(14,26,19)' });
  //     await next({ backgroundColor: '#ffaaee' });
  //   },
  //   from: {},
  // });
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { code?: string; state?: string; error?: string; error_description?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.code && query.state) {
      // 성공
      DBGMSG(`code: ${query.code}`);
      DBGMSG(`state: ${query.state}`);
      setLineAuthorizationInfo({ code: query.code, state: query.state });
    } else if (query.error) {
      // 실패
      return;
    } else {
      // key 없이 접근
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {
  });

  /**************************************
   * !! arrow function
   **************************************/
  const lineLogin = (locale: 'ko' | 'ja' | 'en') => {
    const STATE = Utils.randomUUID();

    // https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1234567890&redirect_uri=https%3A%2F%2Fexample.com%2Fauth&state=12345abcde&scope=profile%20openid&nonce=09876xyz

    // window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${Constants.LINE_CHANNEL_ID}&redirect_uri=${Constants.LINE_CALLBACK_URL}&state=${STATE}&scope=profile&nonce=09876xyz&ui_locales=${locale}`;
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  DBGMSG('render');
  return (
    <FlexColumnDiv
      style={{
        padding: 20,
        // backgroundColor: R.colors.rn.black,
        flexDirection: 'column',
        paddingTop: CLAY.COMMON_HEADER_HEIGHT,
        alignItems: 'flex-start',
        width: screenWidth,
        ...devOutline,
        // ...aprops,
      }}
    >
      <TextCon text={'Dsset Line 로그인'} size={30} containerStyle={{ height: 50 }} color={R.colors.rn.black} />

      {/* <AnimatedTxt text={aprops2.number} containerStyle={{ height: 50 }} color={R.colors.rn.black} textStyle={{ textAlign: 'left' }} /> */}
      <ImgBtn
        src={[R.images.lineLogin1x, 303, 88]}
        onClick={() => {
          lineLogin('ko');
        }}
      />
      <FlexRowDiv>
        <TextCon 
          text={'한국어'}
          containerStyle={{ width: 100, height: 50 }}
          color={R.colors.rn.black}
          onConClick={() => {
            lineLogin('ko');
          }}
        />
        <TextCon 
          text={'일본어'}
          containerStyle={{ width: 100, height: 50 }}
          color={R.colors.rn.black}
          onConClick={() => {
            lineLogin('ja');
          }}
        />
        <TextCon 
          text={'영어'}
          containerStyle={{ width: 100, height: 50 }}
          color={R.colors.rn.black}
          onConClick={() => {
            lineLogin('en');
          }}
        />
      </FlexRowDiv>
      <TextCon 
        text={'RESET'}
        containerStyle={{ height: 40, backgroundColor: R.colors.rn.green, padding: 5 }}
        isBold
        color={R.colors.rn.black}
        onConClick={() => {
          // window.location.href = Constants.LINE_CALLBACK_URL;
        }}
      />
      <AnimatedFlexColumnDiv style={{ ...aprops }}>
        <FlexRowDiv>
          <TextCon size={14} text={'code'} containerStyle={{ width: 100, height: 50 }} color={R.colors.rn.orange} />
          <TextCon size={14} text={lineAuthorizationInfo?.code ?? ''} containerStyle={{ height: 50 }} color={R.colors.rn.orange} />
        </FlexRowDiv>
        <FlexRowDiv>
          <TextCon size={14} text={'state'} containerStyle={{ width: 100, height: 50 }} color={R.colors.rn.orange} />
          <TextCon size={14} text={lineAuthorizationInfo?.state ?? ''} containerStyle={{ height: 50 }} color={R.colors.rn.orange} />
        </FlexRowDiv>
      </AnimatedFlexColumnDiv>

      {lineAuthorizationInfo && (
        <TextCon 
          text={'REQUEST ACCESS TOKEN'}
          color={R.colors.rn.black}
          isBold
          containerStyle={{
            marginTop: 20,
            height: 40,
            padding: 5,
            backgroundColor: R.colors.rn.green,
          }}
          onConClick={() => {
            if (!lineAuthorizationInfo) return;

            // axios
            //   .post(
            //     Constants.LINE_GETTING_ACCESS_TOKEN_URL,
            //     qs.stringify({
            //       grant_type: 'authorization_code',
            //       code: lineAuthorizationInfo.code,
            //       redirect_uri: Constants.LINE_CALLBACK_URL,
            //       client_id: Constants.LINE_CHANNEL_ID,
            //       client_secret: Constants.LINE_CLIENT_SECRET,
            //     }),
            //     {
            //       headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded',
            //       },
            //     }
            //   )
            //   .then((res) => {
            //     DBGMSG(res.data);
            //     const data: LineAccessTokenRes = res.data;
            //     DBGMSG(`access_token: ${data.access_token}`);
            //     DBGMSG(`expires_in: ${data.expires_in}`);
            //     DBGMSG(`refresh_token: ${data.refresh_token}`);
            //     DBGMSG(`scope: ${data.scope}`);
            //     DBGMSG(`token_type: ${data.token_type}`);

            //     setLineAccessTokenInfo(data);
            //   })
            //   .catch((e) => {
            //     DBGMSGW(e);
            //   });
          }}
        />
      )}

      {/* <Txt size={14} color={R.colors.rn.orange} text={JSON.stringify(lineAccessTokenInfo, undefined, 4)} textStyle={{ textAlign: 'left' }} containerStyle={{ alignItems: 'flex-start' }} /> */}
      {lineAccessTokenInfo && <TextCon size={14} color={R.colors.rn.orange} text={JSON.stringify(lineAccessTokenInfo)} textStyle={{ textAlign: 'left' }} containerStyle={{ alignItems: 'flex-start' }} />}
      {lineAccessTokenInfo && (
        <TextCon 
          text={'GET PROFILE'}
          color={R.colors.rn.black}
          isBold
          containerStyle={{ marginTop: 20, height: 40, padding: 5, backgroundColor: R.colors.rn.green }}
          onConClick={() => {
            // axios
            //   .get(Constants.LINE_GETTING_PROFILE_URL, {
            //     headers: {
            //       Authorization: `Bearer ${lineAccessTokenInfo.access_token}`,
            //     },
            //   })
            //   .then((res) => {
            //     DBGMSG(res.data);
            //     const data: LineProfileRes = res.data;
            //     DBGMSG(`displayName: ${data.displayName}`);
            //     DBGMSG(`pictureUrl: ${data.pictureUrl}`);
            //     DBGMSG(`statusMessage: ${data.statusMessage}`);
            //     DBGMSG(`userId: ${data.userId}`);

            //     setLineProfileInfo(data);
            //   })
            //   .catch((e) => {
            //     DBGMSGW(e);
            //   });
          }}
        />
      )}
      {/* <Txt size={14} color={R.colors.rn.orange} text={JSON.stringify(lineProfileInfo ?? '', undefined, 4)} textStyle={{ textAlign: 'left' }} containerStyle={{ alignItems: 'flex-start' }} /> */}
      <TextCon size={14} color={R.colors.rn.orange} text={JSON.stringify(lineProfileInfo)} textStyle={{ textAlign: 'left' }} containerStyle={{ alignItems: 'flex-start' }} />
      <>
        <Img src={[lineProfileInfo?.pictureUrl ?? null, 300, 300]} />
        {/* <Txt size={14} color={R.colors.rn.black} text={lineProfileInfo?.displayName} textStyle={{ textAlign: 'left' }} /> */}
        {/* <Txt size={14} color={R.colors.rn.black} text={lineProfileInfo?.statusMessage} textStyle={{ textAlign: 'left' }} /> */}
      </>
    </FlexColumnDiv>
  );
}
