/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, useRef, useState } from 'react';
import { FlexDiv } from 'src/ui/base_component/etc';
import { DBGMSG } from 'src/util/utils';
import styled from 'styled-components';

export const ToolTipBoxContainer = styled(FlexDiv)`
  object-fit: cover;
`;

type Props = {
  src: [string | null, number | string, number | string | undefined];
  onClick?: () => void;
  tooltipJSX: JSX.Element;
  containerStyle?: CSSProperties;
};

export function ImgBtnWithTooltip(props: Props) {
  const [sIsShowTooltip, setIsShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLImageElement>(null);

  // console.debug('render');
  return (
    <FlexDiv
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        ...props.containerStyle,
        cursor: 'pointer',
        // ...devOutlineF,
      }}
      onClick={(e) => {
        DBGMSG(`${props.src[0]}`);
        if (props.onClick) {
          props.onClick();
          // e.stopPropagation();
        }

        if (tooltipRef.current) {
          DBGMSG(`tooltipRef.current: ${tooltipRef.current}`);
          DBGMSG(`tooltipRef.current.offsetTop: ${tooltipRef.current.offsetTop}`);
          DBGMSG(`tooltipRef.current.offsetLeft: ${tooltipRef.current.offsetLeft}`);
          DBGMSG(`tooltipRef.current.offsetWidth: ${tooltipRef.current.offsetWidth}`);
          DBGMSG(`tooltipRef.current.offsetParent?.clientTop: ${tooltipRef.current.offsetParent?.clientTop}`);
          DBGMSG(`tooltipRef.current.offsetParent?.clientWidth: ${tooltipRef.current.offsetParent?.clientWidth}`);
          DBGMSG(`tooltipRef.current.offsetParent?.clientLeft: ${tooltipRef.current.offsetParent?.clientLeft}`);
        }
      }}
    >
      <img
        ref={tooltipRef}
        src={props.src[0] === null ? `https://placehold.it/${props.src[1]}x${props.src[2]}` : props.src[0]}
        alt=""
        style={{ width: props.src[1], height: props.src[2], boxSizing: 'border-box' }}
        onMouseEnter={() => {
          setIsShowTooltip(true);
        }}
        onMouseLeave={() => {
          setIsShowTooltip(false);
        }}
      />
      {props.tooltipJSX && sIsShowTooltip && tooltipRef.current && (
        <ToolTipBoxContainer
          style={{
            position: 'absolute',
            top: tooltipRef.current.offsetTop,
            left: tooltipRef.current.offsetLeft + tooltipRef.current.offsetWidth + 6,
            zIndex: 10000,
          }}
        >
          {/* <TooltipBox><TextCon>asdfasdfasdfasldkfjal;skdfjla;skdjf;laskdjf;laksdjf;laksdjf</TextCon></TooltipBox> */}
          {props.tooltipJSX}
        </ToolTipBoxContainer>
      )}
    </FlexDiv>
  );
}
