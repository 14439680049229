/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties } from 'react';
import { devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import R from 'src/res/R';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

export interface TxtProps {
  key?: string | number;
  ref?: any;
  text?: string;
  size?: number | string;
  color?: string;
  isBold?: boolean;
  isItelic?: boolean;
  isUnderline?: boolean;
  fontFamily?: string;
  letterSpacing?: number | string;
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
}

export default function _Txt(props: TxtProps) {
  return (
    <FlexDiv
      key={props.key}
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexBasis: 'auto',
        flexShrink: 0,
        // overflow: 'hidden', // width, height이 정해졌을때, overflow되는 text 보이지 않게함
        ...devOutline,
        ...props.containerStyle,
      }}
    >
      <span
        style={{
          fontFamily: props.fontFamily || R.fonts.SpoqaJP,
          // whiteSpace: 'nowrap', // default 1줄
          // textAlign: 'center',
          marginRight: props.isItelic ? 2 : 0,
          fontSize: props.size,
          fontWeight: props.isBold ? 'bold' : 'normal',
          fontStyle: props.isItelic ? 'italic' : 'normal',
          textDecoration: props.isUnderline ? 'underline' : 'none',
          color: props.color ?? R.colors.rn.yellow,
          letterSpacing: props.letterSpacing,
          ...devOutline,
          ...props.textStyle,
          // wordWrap: '',
          whiteSpace: 'pre-wrap', // default 1줄
          textAlign: 'center',
        }}
      >
        {props.text}
      </span>
    </FlexDiv>
  );
}
