
// don't modify manually
// this file is automatically generated by brewImages.ts

import asset_detail_iv_audio_added1x from 'src/res/images/asset-detail-iv-audio-added.png';
import asset_detail_iv_audio_added2x from 'src/res/images/asset-detail-iv-audio-added@2x.png';
import asset_detail_iv_audio_added3x from 'src/res/images/asset-detail-iv-audio-added@3x.png';
import asset_detail_iv_audio1x from 'src/res/images/asset-detail-iv-audio.png';
import asset_detail_iv_audio2x from 'src/res/images/asset-detail-iv-audio@2x.png';
import asset_detail_iv_audio3x from 'src/res/images/asset-detail-iv-audio@3x.png';
import asset_ic_bookmark_abled1x from 'src/res/images/asset-ic-bookmark-abled.png';
import asset_ic_bookmark_abled2x from 'src/res/images/asset-ic-bookmark-abled@2x.png';
import asset_ic_bookmark_abled3x from 'src/res/images/asset-ic-bookmark-abled@3x.png';
import asset_ic_bookmark_disabled1x from 'src/res/images/asset-ic-bookmark-disabled.png';
import asset_ic_bookmark_disabled2x from 'src/res/images/asset-ic-bookmark-disabled@2x.png';
import asset_ic_bookmark_disabled3x from 'src/res/images/asset-ic-bookmark-disabled@3x.png';
import asset_ic_copy1x from 'src/res/images/asset-ic-copy.png';
import asset_ic_copy2x from 'src/res/images/asset-ic-copy@2x.png';
import asset_ic_copy3x from 'src/res/images/asset-ic-copy@3x.png';
import asset_ic_etc1x from 'src/res/images/asset-ic-etc.png';
import asset_ic_etc2x from 'src/res/images/asset-ic-etc@2x.png';
import asset_ic_etc3x from 'src/res/images/asset-ic-etc@3x.png';
import asset_ic_share1x from 'src/res/images/asset-ic-share.png';
import asset_ic_share2x from 'src/res/images/asset-ic-share@2x.png';
import asset_ic_share3x from 'src/res/images/asset-ic-share@3x.png';
import asset_ic_twitter1x from 'src/res/images/asset-ic-twitter.png';
import asset_ic_twitter2x from 'src/res/images/asset-ic-twitter@2x.png';
import asset_ic_twitter3x from 'src/res/images/asset-ic-twitter@3x.png';
import calender_left1x from 'src/res/images/calender_left.png';
import calender_left2x from 'src/res/images/calender_left@2x.png';
import calender_left3x from 'src/res/images/calender_left@3x.png';
import calender_right1x from 'src/res/images/calender_right.png';
import calender_right2x from 'src/res/images/calender_right@2x.png';
import calender_right3x from 'src/res/images/calender_right@3x.png';
import carrot_collection1x from 'src/res/images/carrot_collection.png';
import cashout_ic_confirm1x from 'src/res/images/cashout-ic-confirm.png';
import cashout_ic_confirm2x from 'src/res/images/cashout-ic-confirm@2x.png';
import cashout_ic_confirm3x from 'src/res/images/cashout-ic-confirm@3x.png';
import cashout_ic_edit1x from 'src/res/images/cashout-ic-edit.png';
import cashout_ic_edit2x from 'src/res/images/cashout-ic-edit@2x.png';
import cashout_ic_edit3x from 'src/res/images/cashout-ic-edit@3x.png';
import cashout_iv_cblt1x from 'src/res/images/cashout-iv-cblt.png';
import cashout_iv_cblt2x from 'src/res/images/cashout-iv-cblt@2x.png';
import cashout_iv_cblt3x from 'src/res/images/cashout-iv-cblt@3x.png';
import cashout_iv_paypal_logo1x from 'src/res/images/cashout-iv-paypal-logo.png';
import cashout_iv_paypal_logo2x from 'src/res/images/cashout-iv-paypal-logo@2x.png';
import cashout_iv_paypal_logo3x from 'src/res/images/cashout-iv-paypal-logo@3x.png';
import common_btn_audio_mute1x from 'src/res/images/common-btn-audio-mute.png';
import common_btn_audio_mute2x from 'src/res/images/common-btn-audio-mute@2x.png';
import common_btn_audio_mute3x from 'src/res/images/common-btn-audio-mute@3x.png';
import common_btn_audio_play1x from 'src/res/images/common-btn-audio-play.png';
import common_btn_audio_play2x from 'src/res/images/common-btn-audio-play@2x.png';
import common_btn_audio_play3x from 'src/res/images/common-btn-audio-play@3x.png';
import common_btn_back_abled1x from 'src/res/images/common-btn-back-abled.png';
import common_btn_back_abled2x from 'src/res/images/common-btn-back-abled@2x.png';
import common_btn_back_abled3x from 'src/res/images/common-btn-back-abled@3x.png';
import common_btn_back_disabled1x from 'src/res/images/common-btn-back-disabled.png';
import common_btn_back_disabled2x from 'src/res/images/common-btn-back-disabled@2x.png';
import common_btn_back_disabled3x from 'src/res/images/common-btn-back-disabled@3x.png';
import common_btn_bookmark_abled1x from 'src/res/images/common-btn-bookmark-abled.png';
import common_btn_bookmark_abled2x from 'src/res/images/common-btn-bookmark-abled@2x.png';
import common_btn_bookmark_abled3x from 'src/res/images/common-btn-bookmark-abled@3x.png';
import common_btn_bookmark_disabled1x from 'src/res/images/common-btn-bookmark-disabled.png';
import common_btn_bookmark_disabled2x from 'src/res/images/common-btn-bookmark-disabled@2x.png';
import common_btn_bookmark_disabled3x from 'src/res/images/common-btn-bookmark-disabled@3x.png';
import common_btn_bookmark_hover1x from 'src/res/images/common-btn-bookmark-hover.png';
import common_btn_bookmark_hover2x from 'src/res/images/common-btn-bookmark-hover@2x.png';
import common_btn_bookmark_hover3x from 'src/res/images/common-btn-bookmark-hover@3x.png';
import common_btn_close1x from 'src/res/images/common-btn-close.png';
import common_btn_close2x from 'src/res/images/common-btn-close@2x.png';
import common_btn_close3x from 'src/res/images/common-btn-close@3x.png';
import common_btn_drop_down1x from 'src/res/images/common-btn-drop-down.png';
import common_btn_drop_down2x from 'src/res/images/common-btn-drop-down@2x.png';
import common_btn_drop_down3x from 'src/res/images/common-btn-drop-down@3x.png';
import common_btn_etc1x from 'src/res/images/common-btn-etc.png';
import common_btn_etc2x from 'src/res/images/common-btn-etc@2x.png';
import common_btn_etc3x from 'src/res/images/common-btn-etc@3x.png';
import common_btn_next_abled1x from 'src/res/images/common-btn-next-abled.png';
import common_btn_next_abled2x from 'src/res/images/common-btn-next-abled@2x.png';
import common_btn_next_abled3x from 'src/res/images/common-btn-next-abled@3x.png';
import common_btn_next_disabled1x from 'src/res/images/common-btn-next-disabled.png';
import common_btn_next_disabled2x from 'src/res/images/common-btn-next-disabled@2x.png';
import common_btn_next_disabled3x from 'src/res/images/common-btn-next-disabled@3x.png';
import common_btn_not_visible1x from 'src/res/images/common-btn-not-visible.png';
import common_btn_not_visible2x from 'src/res/images/common-btn-not-visible@2x.png';
import common_btn_not_visible3x from 'src/res/images/common-btn-not-visible@3x.png';
import common_btn_tooltip_hover1x from 'src/res/images/common-btn-tooltip-hover.png';
import common_btn_tooltip_hover2x from 'src/res/images/common-btn-tooltip-hover@2x.png';
import common_btn_tooltip_hover3x from 'src/res/images/common-btn-tooltip-hover@3x.png';
import common_btn_tooltip_normal1x from 'src/res/images/common-btn-tooltip-normal.png';
import common_btn_tooltip_normal2x from 'src/res/images/common-btn-tooltip-normal@2x.png';
import common_btn_tooltip_normal3x from 'src/res/images/common-btn-tooltip-normal@3x.png';
import common_btn_visible1x from 'src/res/images/common-btn-visible.png';
import common_btn_visible2x from 'src/res/images/common-btn-visible@2x.png';
import common_btn_visible3x from 'src/res/images/common-btn-visible@3x.png';
import common_ic_arrow_back1x from 'src/res/images/common-ic-arrow-back.png';
import common_ic_arrow_back2x from 'src/res/images/common-ic-arrow-back@2x.png';
import common_ic_arrow_back3x from 'src/res/images/common-ic-arrow-back@3x.png';
import common_ic_audio_asset1x from 'src/res/images/common-ic-audio-asset.png';
import common_ic_audio_asset2x from 'src/res/images/common-ic-audio-asset@2x.png';
import common_ic_audio_asset3x from 'src/res/images/common-ic-audio-asset@3x.png';
import common_ic_audio_schema1x from 'src/res/images/common-ic-audio-schema.png';
import common_ic_audio_schema2x from 'src/res/images/common-ic-audio-schema@2x.png';
import common_ic_audio_schema3x from 'src/res/images/common-ic-audio-schema@3x.png';
import common_ic_cblt1x from 'src/res/images/common-ic-cblt.png';
import common_ic_cblt2x from 'src/res/images/common-ic-cblt@2x.png';
import common_ic_cblt3x from 'src/res/images/common-ic-cblt@3x.png';
import common_ic_enlarge1x from 'src/res/images/common-ic-enlarge.png';
import common_ic_enlarge2x from 'src/res/images/common-ic-enlarge@2x.png';
import common_ic_enlarge3x from 'src/res/images/common-ic-enlarge@3x.png';
import common_ic_img_schema1x from 'src/res/images/common-ic-img-schema.png';
import common_ic_img_schema2x from 'src/res/images/common-ic-img-schema@2x.png';
import common_ic_img_schema3x from 'src/res/images/common-ic-img-schema@3x.png';
import common_ic_klay1x from 'src/res/images/common-ic-klay.png';
import common_ic_klay2x from 'src/res/images/common-ic-klay@2x.png';
import common_ic_klay3x from 'src/res/images/common-ic-klay@3x.png';
import common_ic_noti_fail1x from 'src/res/images/common-ic-noti-fail.png';
import common_ic_noti_fail2x from 'src/res/images/common-ic-noti-fail@2x.png';
import common_ic_noti_fail3x from 'src/res/images/common-ic-noti-fail@3x.png';
import common_ic_noti_success1x from 'src/res/images/common-ic-noti-success.png';
import common_ic_noti_success2x from 'src/res/images/common-ic-noti-success@2x.png';
import common_ic_noti_success3x from 'src/res/images/common-ic-noti-success@3x.png';
import common_ic_point_11x from 'src/res/images/common-ic-point-1.png';
import common_ic_point_12x from 'src/res/images/common-ic-point-1@2x.png';
import common_ic_point_13x from 'src/res/images/common-ic-point-1@3x.png';
import common_ic_point_21x from 'src/res/images/common-ic-point-2.png';
import common_ic_point_22x from 'src/res/images/common-ic-point-2@2x.png';
import common_ic_point_23x from 'src/res/images/common-ic-point-2@3x.png';
import common_ic_point_31x from 'src/res/images/common-ic-point-3.png';
import common_ic_point_32x from 'src/res/images/common-ic-point-3@2x.png';
import common_ic_point_33x from 'src/res/images/common-ic-point-3@3x.png';
import common_ic_point_41x from 'src/res/images/common-ic-point-4.png';
import common_ic_point_42x from 'src/res/images/common-ic-point-4@2x.png';
import common_ic_point_43x from 'src/res/images/common-ic-point-4@3x.png';
import common_ic_point_51x from 'src/res/images/common-ic-point-5.png';
import common_ic_point_52x from 'src/res/images/common-ic-point-5@2x.png';
import common_ic_point_53x from 'src/res/images/common-ic-point-5@3x.png';
import common_ic_point_61x from 'src/res/images/common-ic-point-6.png';
import common_ic_point_62x from 'src/res/images/common-ic-point-6@2x.png';
import common_ic_point_63x from 'src/res/images/common-ic-point-6@3x.png';
import common_ic_point1x from 'src/res/images/common-ic-point.png';
import common_ic_point2x from 'src/res/images/common-ic-point@2x.png';
import common_ic_point3x from 'src/res/images/common-ic-point@3x.png';
import common_ic_search1x from 'src/res/images/common-ic-search.png';
import common_ic_search2x from 'src/res/images/common-ic-search@2x.png';
import common_ic_search3x from 'src/res/images/common-ic-search@3x.png';
import common_ic_verify1x from 'src/res/images/common-ic-verify.png';
import common_ic_verify2x from 'src/res/images/common-ic-verify@2x.png';
import common_ic_verify3x from 'src/res/images/common-ic-verify@3x.png';
import common_ic_video_asset1x from 'src/res/images/common-ic-video-asset.png';
import common_ic_video_asset2x from 'src/res/images/common-ic-video-asset@2x.png';
import common_ic_video_asset3x from 'src/res/images/common-ic-video-asset@3x.png';
import common_ic_video_schema1x from 'src/res/images/common-ic-video-schema.png';
import common_ic_video_schema2x from 'src/res/images/common-ic-video-schema@2x.png';
import common_ic_video_schema3x from 'src/res/images/common-ic-video-schema@3x.png';
import common_ic_video1x from 'src/res/images/common-ic-video.png';
import common_ic_video2x from 'src/res/images/common-ic-video@2x.png';
import common_ic_video3x from 'src/res/images/common-ic-video@3x.png';
import common_ic_warning1x from 'src/res/images/common-ic-warning.png';
import common_ic_warning2x from 'src/res/images/common-ic-warning@2x.png';
import common_ic_warning3x from 'src/res/images/common-ic-warning@3x.png';
import common_iv_audio1x from 'src/res/images/common-iv-audio.png';
import common_iv_audio2x from 'src/res/images/common-iv-audio@2x.png';
import common_iv_audio3x from 'src/res/images/common-iv-audio@3x.png';
import common_iv_kaikas_wallet1x from 'src/res/images/common-iv-kaikas-wallet.png';
import common_iv_kaikas_wallet2x from 'src/res/images/common-iv-kaikas-wallet@2x.png';
import common_iv_kaikas_wallet3x from 'src/res/images/common-iv-kaikas-wallet@3x.png';
import common_iv_profile_thumbnail_default1x from 'src/res/images/common-iv-profile-thumbnail-default.png';
import common_iv_profile_thumbnail_default2x from 'src/res/images/common-iv-profile-thumbnail-default@2x.png';
import common_iv_profile_thumbnail_default3x from 'src/res/images/common-iv-profile-thumbnail-default@3x.png';
import common_iv_soldout1x from 'src/res/images/common-iv-soldout.png';
import common_iv_soldout2x from 'src/res/images/common-iv-soldout@2x.png';
import common_iv_soldout3x from 'src/res/images/common-iv-soldout@3x.png';
import common_ic_running1x from 'src/res/images/common_ic_running.png';
import creator_btn_add1x from 'src/res/images/creator-btn-add.png';
import creator_btn_add2x from 'src/res/images/creator-btn-add@2x.png';
import creator_btn_add3x from 'src/res/images/creator-btn-add@3x.png';
import creator_btn_delete1x from 'src/res/images/creator-btn-delete.png';
import creator_btn_delete2x from 'src/res/images/creator-btn-delete@2x.png';
import creator_btn_delete3x from 'src/res/images/creator-btn-delete@3x.png';
import creator_ic_plus1x from 'src/res/images/creator-ic-plus.png';
import creator_ic_plus2x from 'src/res/images/creator-ic-plus@2x.png';
import creator_ic_plus3x from 'src/res/images/creator-ic-plus@3x.png';
import creator_iv_audio1x from 'src/res/images/creator-iv-audio.png';
import creator_iv_audio2x from 'src/res/images/creator-iv-audio@2x.png';
import creator_iv_audio3x from 'src/res/images/creator-iv-audio@3x.png';
import creator_ic_loading1x from 'src/res/images/creator_ic_loading.png';
import detail_iv_audio_thumbnail1x from 'src/res/images/detail-iv-audio-thumbnail.png';
import detail_iv_audio_thumbnail2x from 'src/res/images/detail-iv-audio-thumbnail@2x.png';
import detail_iv_audio_thumbnail3x from 'src/res/images/detail-iv-audio-thumbnail@3x.png';
import explore_iv_asset1x from 'src/res/images/explore-iv-asset.png';
import explore_iv_asset2x from 'src/res/images/explore-iv-asset@2x.png';
import explore_iv_asset3x from 'src/res/images/explore-iv-asset@3x.png';
import explore_iv_banner_11x from 'src/res/images/explore-iv-banner-1.png';
import explore_iv_banner_12x from 'src/res/images/explore-iv-banner-1@2x.png';
import explore_iv_banner_13x from 'src/res/images/explore-iv-banner-1@3x.png';
import explore_iv_banner_21x from 'src/res/images/explore-iv-banner-2.png';
import explore_iv_banner_22x from 'src/res/images/explore-iv-banner-2@2x.png';
import explore_iv_banner_23x from 'src/res/images/explore-iv-banner-2@3x.png';
import explore_iv_banner_31x from 'src/res/images/explore-iv-banner-3.png';
import explore_iv_banner_32x from 'src/res/images/explore-iv-banner-3@2x.png';
import explore_iv_banner_33x from 'src/res/images/explore-iv-banner-3@3x.png';
import explore_iv_banner_41x from 'src/res/images/explore-iv-banner-4.png';
import explore_iv_banner_42x from 'src/res/images/explore-iv-banner-4@2x.png';
import explore_iv_banner_43x from 'src/res/images/explore-iv-banner-4@3x.png';
import explore_iv_dollar1x from 'src/res/images/explore-iv-dollar.png';
import explore_iv_dollar2x from 'src/res/images/explore-iv-dollar@2x.png';
import explore_iv_dollar3x from 'src/res/images/explore-iv-dollar@3x.png';
import explore_iv_listing1x from 'src/res/images/explore-iv-listing.png';
import explore_iv_listing2x from 'src/res/images/explore-iv-listing@2x.png';
import explore_iv_listing3x from 'src/res/images/explore-iv-listing@3x.png';
import explore_iv_sale1x from 'src/res/images/explore-iv-sale.png';
import explore_iv_sale2x from 'src/res/images/explore-iv-sale@2x.png';
import explore_iv_sale3x from 'src/res/images/explore-iv-sale@3x.png';
import explore_iv_txs1x from 'src/res/images/explore-iv-txs.png';
import explore_iv_txs2x from 'src/res/images/explore-iv-txs@2x.png';
import explore_iv_txs3x from 'src/res/images/explore-iv-txs@3x.png';
import gnb_btn_buy_dsset_point1x from 'src/res/images/gnb-btn-buy-dsset-point.png';
import gnb_btn_buy_dsset_point2x from 'src/res/images/gnb-btn-buy-dsset-point@2x.png';
import gnb_btn_buy_dsset_point3x from 'src/res/images/gnb-btn-buy-dsset-point@3x.png';
import gnb_btn_language_hover1x from 'src/res/images/gnb-btn-language-hover.png';
import gnb_btn_language_hover2x from 'src/res/images/gnb-btn-language-hover@2x.png';
import gnb_btn_language_hover3x from 'src/res/images/gnb-btn-language-hover@3x.png';
import gnb_btn_language1x from 'src/res/images/gnb-btn-language.png';
import gnb_btn_language2x from 'src/res/images/gnb-btn-language@2x.png';
import gnb_btn_language3x from 'src/res/images/gnb-btn-language@3x.png';
import gnb_btn_menu_close1x from 'src/res/images/gnb-btn-menu-close.png';
import gnb_btn_menu_close2x from 'src/res/images/gnb-btn-menu-close@2x.png';
import gnb_btn_menu_close3x from 'src/res/images/gnb-btn-menu-close@3x.png';
import gnb_btn_menu1x from 'src/res/images/gnb-btn-menu.png';
import gnb_btn_menu2x from 'src/res/images/gnb-btn-menu@2x.png';
import gnb_btn_menu3x from 'src/res/images/gnb-btn-menu@3x.png';
import gnb_btn_mypage1x from 'src/res/images/gnb-btn-mypage.png';
import gnb_btn_mypage2x from 'src/res/images/gnb-btn-mypage@2x.png';
import gnb_btn_mypage3x from 'src/res/images/gnb-btn-mypage@3x.png';
import gnb_btn_notification_hover1x from 'src/res/images/gnb-btn-notification-hover.png';
import gnb_btn_notification_hover2x from 'src/res/images/gnb-btn-notification-hover@2x.png';
import gnb_btn_notification_hover3x from 'src/res/images/gnb-btn-notification-hover@3x.png';
import gnb_btn_notification1x from 'src/res/images/gnb-btn-notification.png';
import gnb_btn_notification2x from 'src/res/images/gnb-btn-notification@2x.png';
import gnb_btn_notification3x from 'src/res/images/gnb-btn-notification@3x.png';
import gnb_logo_beta1x from 'src/res/images/gnb-logo-beta.png';
import gnb_logo_beta2x from 'src/res/images/gnb-logo-beta@2x.png';
import gnb_logo_beta3x from 'src/res/images/gnb-logo-beta@3x.png';
import gnb_logo1x from 'src/res/images/gnb-logo.png';
import gnb_logo2x from 'src/res/images/gnb-logo@2x.png';
import gnb_logo3x from 'src/res/images/gnb-logo@3x.png';
import history_ic_calendar1x from 'src/res/images/history-ic-calendar.png';
import history_ic_calendar2x from 'src/res/images/history-ic-calendar@2x.png';
import history_ic_calendar3x from 'src/res/images/history-ic-calendar@3x.png';
import history_ic_fail1x from 'src/res/images/history-ic-fail.png';
import history_ic_fail2x from 'src/res/images/history-ic-fail@2x.png';
import history_ic_fail3x from 'src/res/images/history-ic-fail@3x.png';
import history_ic_search1x from 'src/res/images/history-ic-search.png';
import history_ic_search2x from 'src/res/images/history-ic-search@2x.png';
import history_ic_search3x from 'src/res/images/history-ic-search@3x.png';
import history_ic_success1x from 'src/res/images/history-ic-success.png';
import history_ic_success2x from 'src/res/images/history-ic-success@2x.png';
import history_ic_success3x from 'src/res/images/history-ic-success@3x.png';
import join_btn_check_abled1x from 'src/res/images/join-btn-check-abled.png';
import join_btn_check_abled2x from 'src/res/images/join-btn-check-abled@2x.png';
import join_btn_check_abled3x from 'src/res/images/join-btn-check-abled@3x.png';
import join_btn_check_disabled1x from 'src/res/images/join-btn-check-disabled.png';
import join_btn_check_disabled2x from 'src/res/images/join-btn-check-disabled@2x.png';
import join_btn_check_disabled3x from 'src/res/images/join-btn-check-disabled@3x.png';
import join_ic_confirm1x from 'src/res/images/join-ic-confirm.png';
import join_ic_confirm2x from 'src/res/images/join-ic-confirm@2x.png';
import join_ic_confirm3x from 'src/res/images/join-ic-confirm@3x.png';
import join_ic_error1x from 'src/res/images/join-ic-error.png';
import join_ic_error2x from 'src/res/images/join-ic-error@2x.png';
import join_ic_error3x from 'src/res/images/join-ic-error@3x.png';
import lineLogin1x from 'src/res/images/lineLogin.png';
import loading_Spin_07s_201px1x from 'src/res/images/loading/Spin-07s-201px.png';
import login_ic_lock1x from 'src/res/images/login-ic-lock.png';
import login_ic_lock2x from 'src/res/images/login-ic-lock@2x.png';
import login_ic_lock3x from 'src/res/images/login-ic-lock@3x.png';
import market_btn_align_uncheck1x from 'src/res/images/market-btn-align-uncheck.png';
import market_btn_align_uncheck2x from 'src/res/images/market-btn-align-uncheck@2x.png';
import market_btn_align_uncheck3x from 'src/res/images/market-btn-align-uncheck@3x.png';
import mypage_btn_cashout_history_normal1x from 'src/res/images/mypage-btn-cashout-history-normal.png';
import mypage_btn_cashout_history_normal2x from 'src/res/images/mypage-btn-cashout-history-normal@2x.png';
import mypage_btn_cashout_history_normal3x from 'src/res/images/mypage-btn-cashout-history-normal@3x.png';
import mypage_btn_edit_profile1x from 'src/res/images/mypage-btn-edit-profile.png';
import mypage_btn_edit_profile2x from 'src/res/images/mypage-btn-edit-profile@2x.png';
import mypage_btn_edit_profile3x from 'src/res/images/mypage-btn-edit-profile@3x.png';
import pay_btn_check_abled1x from 'src/res/images/pay-btn-check-abled.png';
import pay_btn_check_abled2x from 'src/res/images/pay-btn-check-abled@2x.png';
import pay_btn_check_abled3x from 'src/res/images/pay-btn-check-abled@3x.png';
import pay_btn_check_disabled1x from 'src/res/images/pay-btn-check-disabled.png';
import pay_btn_check_disabled2x from 'src/res/images/pay-btn-check-disabled@2x.png';
import pay_btn_check_disabled3x from 'src/res/images/pay-btn-check-disabled@3x.png';
import pay_btn_point_popup_hover1x from 'src/res/images/pay-btn-point-popup-hover.png';
import pay_btn_point_popup_hover2x from 'src/res/images/pay-btn-point-popup-hover@2x.png';
import pay_btn_point_popup_hover3x from 'src/res/images/pay-btn-point-popup-hover@3x.png';
import pay_btn_point_popup1x from 'src/res/images/pay-btn-point-popup.png';
import pay_btn_point_popup2x from 'src/res/images/pay-btn-point-popup@2x.png';
import pay_btn_point_popup3x from 'src/res/images/pay-btn-point-popup@3x.png';
import point_btn_paymentwall_logo1x from 'src/res/images/point-btn-paymentwall-logo.png';
import point_btn_paymentwall_logo2x from 'src/res/images/point-btn-paymentwall-logo@2x.png';
import point_btn_paymentwall_logo3x from 'src/res/images/point-btn-paymentwall-logo@3x.png';
import point_ic_fail1x from 'src/res/images/point-ic-fail.png';
import point_ic_fail2x from 'src/res/images/point-ic-fail@2x.png';
import point_ic_fail3x from 'src/res/images/point-ic-fail@3x.png';
import point_ic_success1x from 'src/res/images/point-ic-success.png';
import point_ic_success2x from 'src/res/images/point-ic-success@2x.png';
import point_ic_success3x from 'src/res/images/point-ic-success@3x.png';
import point_tv_fee1x from 'src/res/images/point-tv-fee.png';
import point_tv_fee2x from 'src/res/images/point-tv-fee@2x.png';
import point_tv_fee3x from 'src/res/images/point-tv-fee@3x.png';
import sample_assetSample11x from 'src/res/images/sample/assetSample1.png';
import sample_assetSample12x from 'src/res/images/sample/assetSample1@2x.png';
import sample_assetSample13x from 'src/res/images/sample/assetSample1@3x.png';
import sample_assetSample21x from 'src/res/images/sample/assetSample2.png';
import sample_assetSample22x from 'src/res/images/sample/assetSample2@2x.png';
import sample_assetSample23x from 'src/res/images/sample/assetSample2@3x.png';
import sample_assetSample31x from 'src/res/images/sample/assetSample3.png';
import sample_assetSample32x from 'src/res/images/sample/assetSample3@2x.png';
import sample_assetSample33x from 'src/res/images/sample/assetSample3@3x.png';
import sample_assetSample41x from 'src/res/images/sample/assetSample4.png';
import sample_assetSample42x from 'src/res/images/sample/assetSample4@2x.png';
import sample_assetSample43x from 'src/res/images/sample/assetSample4@3x.png';
import sample_assetSample51x from 'src/res/images/sample/assetSample5.png';
import sample_assetSample52x from 'src/res/images/sample/assetSample5@2x.png';
import sample_assetSample53x from 'src/res/images/sample/assetSample5@3x.png';
import sample_assetSample61x from 'src/res/images/sample/assetSample6.png';
import sample_assetSample62x from 'src/res/images/sample/assetSample6@2x.png';
import sample_assetSample63x from 'src/res/images/sample/assetSample6@3x.png';
import sample_assetSample71x from 'src/res/images/sample/assetSample7.png';
import sample_assetSample72x from 'src/res/images/sample/assetSample7@2x.png';
import sample_assetSample73x from 'src/res/images/sample/assetSample7@3x.png';
import sample_assetSample81x from 'src/res/images/sample/assetSample8.png';
import sample_assetSample82x from 'src/res/images/sample/assetSample8@2x.png';
import sample_assetSample83x from 'src/res/images/sample/assetSample8@3x.png';
import sample_collection_img_sample11x from 'src/res/images/sample/collection-img-sample1.png';
import sample_collection_img_sample12x from 'src/res/images/sample/collection-img-sample1@2x.png';
import sample_collection_img_sample13x from 'src/res/images/sample/collection-img-sample1@3x.png';
import sample_collection_img_sample21x from 'src/res/images/sample/collection-img-sample2.png';
import sample_collection_img_sample22x from 'src/res/images/sample/collection-img-sample2@2x.png';
import sample_collection_img_sample23x from 'src/res/images/sample/collection-img-sample2@3x.png';
import sample_collection_img_sample31x from 'src/res/images/sample/collection-img-sample3.png';
import sample_collection_img_sample32x from 'src/res/images/sample/collection-img-sample3@2x.png';
import sample_collection_img_sample33x from 'src/res/images/sample/collection-img-sample3@3x.png';
import sample_collection_img_sample41x from 'src/res/images/sample/collection-img-sample4.png';
import sample_collection_img_sample42x from 'src/res/images/sample/collection-img-sample4@2x.png';
import sample_collection_img_sample43x from 'src/res/images/sample/collection-img-sample4@3x.png';
import sample_profile_thumbnail21x from 'src/res/images/sample/profile-thumbnail2.png';
import sample_profile_thumbnail22x from 'src/res/images/sample/profile-thumbnail2@2x.png';
import sample_profile_thumbnail23x from 'src/res/images/sample/profile-thumbnail2@3x.png';
import sample_profile_thumbnail31x from 'src/res/images/sample/profile-thumbnail3.png';
import sample_profile_thumbnail32x from 'src/res/images/sample/profile-thumbnail3@2x.png';
import sample_profile_thumbnail33x from 'src/res/images/sample/profile-thumbnail3@3x.png';
import logoSvg from 'src/res/images/logo.svg';

const images = {
  asset_detail_iv_audio_added1x,
  asset_detail_iv_audio_added2x,
  asset_detail_iv_audio_added3x,
  asset_detail_iv_audio1x,
  asset_detail_iv_audio2x,
  asset_detail_iv_audio3x,
  asset_ic_bookmark_abled1x,
  asset_ic_bookmark_abled2x,
  asset_ic_bookmark_abled3x,
  asset_ic_bookmark_disabled1x,
  asset_ic_bookmark_disabled2x,
  asset_ic_bookmark_disabled3x,
  asset_ic_copy1x,
  asset_ic_copy2x,
  asset_ic_copy3x,
  asset_ic_etc1x,
  asset_ic_etc2x,
  asset_ic_etc3x,
  asset_ic_share1x,
  asset_ic_share2x,
  asset_ic_share3x,
  asset_ic_twitter1x,
  asset_ic_twitter2x,
  asset_ic_twitter3x,
  calender_left1x,
  calender_left2x,
  calender_left3x,
  calender_right1x,
  calender_right2x,
  calender_right3x,
  carrot_collection1x,
  cashout_ic_confirm1x,
  cashout_ic_confirm2x,
  cashout_ic_confirm3x,
  cashout_ic_edit1x,
  cashout_ic_edit2x,
  cashout_ic_edit3x,
  cashout_iv_cblt1x,
  cashout_iv_cblt2x,
  cashout_iv_cblt3x,
  cashout_iv_paypal_logo1x,
  cashout_iv_paypal_logo2x,
  cashout_iv_paypal_logo3x,
  common_btn_audio_mute1x,
  common_btn_audio_mute2x,
  common_btn_audio_mute3x,
  common_btn_audio_play1x,
  common_btn_audio_play2x,
  common_btn_audio_play3x,
  common_btn_back_abled1x,
  common_btn_back_abled2x,
  common_btn_back_abled3x,
  common_btn_back_disabled1x,
  common_btn_back_disabled2x,
  common_btn_back_disabled3x,
  common_btn_bookmark_abled1x,
  common_btn_bookmark_abled2x,
  common_btn_bookmark_abled3x,
  common_btn_bookmark_disabled1x,
  common_btn_bookmark_disabled2x,
  common_btn_bookmark_disabled3x,
  common_btn_bookmark_hover1x,
  common_btn_bookmark_hover2x,
  common_btn_bookmark_hover3x,
  common_btn_close1x,
  common_btn_close2x,
  common_btn_close3x,
  common_btn_drop_down1x,
  common_btn_drop_down2x,
  common_btn_drop_down3x,
  common_btn_etc1x,
  common_btn_etc2x,
  common_btn_etc3x,
  common_btn_next_abled1x,
  common_btn_next_abled2x,
  common_btn_next_abled3x,
  common_btn_next_disabled1x,
  common_btn_next_disabled2x,
  common_btn_next_disabled3x,
  common_btn_not_visible1x,
  common_btn_not_visible2x,
  common_btn_not_visible3x,
  common_btn_tooltip_hover1x,
  common_btn_tooltip_hover2x,
  common_btn_tooltip_hover3x,
  common_btn_tooltip_normal1x,
  common_btn_tooltip_normal2x,
  common_btn_tooltip_normal3x,
  common_btn_visible1x,
  common_btn_visible2x,
  common_btn_visible3x,
  common_ic_arrow_back1x,
  common_ic_arrow_back2x,
  common_ic_arrow_back3x,
  common_ic_audio_asset1x,
  common_ic_audio_asset2x,
  common_ic_audio_asset3x,
  common_ic_audio_schema1x,
  common_ic_audio_schema2x,
  common_ic_audio_schema3x,
  common_ic_cblt1x,
  common_ic_cblt2x,
  common_ic_cblt3x,
  common_ic_enlarge1x,
  common_ic_enlarge2x,
  common_ic_enlarge3x,
  common_ic_img_schema1x,
  common_ic_img_schema2x,
  common_ic_img_schema3x,
  common_ic_klay1x,
  common_ic_klay2x,
  common_ic_klay3x,
  common_ic_noti_fail1x,
  common_ic_noti_fail2x,
  common_ic_noti_fail3x,
  common_ic_noti_success1x,
  common_ic_noti_success2x,
  common_ic_noti_success3x,
  common_ic_point_11x,
  common_ic_point_12x,
  common_ic_point_13x,
  common_ic_point_21x,
  common_ic_point_22x,
  common_ic_point_23x,
  common_ic_point_31x,
  common_ic_point_32x,
  common_ic_point_33x,
  common_ic_point_41x,
  common_ic_point_42x,
  common_ic_point_43x,
  common_ic_point_51x,
  common_ic_point_52x,
  common_ic_point_53x,
  common_ic_point_61x,
  common_ic_point_62x,
  common_ic_point_63x,
  common_ic_point1x,
  common_ic_point2x,
  common_ic_point3x,
  common_ic_search1x,
  common_ic_search2x,
  common_ic_search3x,
  common_ic_verify1x,
  common_ic_verify2x,
  common_ic_verify3x,
  common_ic_video_asset1x,
  common_ic_video_asset2x,
  common_ic_video_asset3x,
  common_ic_video_schema1x,
  common_ic_video_schema2x,
  common_ic_video_schema3x,
  common_ic_video1x,
  common_ic_video2x,
  common_ic_video3x,
  common_ic_warning1x,
  common_ic_warning2x,
  common_ic_warning3x,
  common_iv_audio1x,
  common_iv_audio2x,
  common_iv_audio3x,
  common_iv_kaikas_wallet1x,
  common_iv_kaikas_wallet2x,
  common_iv_kaikas_wallet3x,
  common_iv_profile_thumbnail_default1x,
  common_iv_profile_thumbnail_default2x,
  common_iv_profile_thumbnail_default3x,
  common_iv_soldout1x,
  common_iv_soldout2x,
  common_iv_soldout3x,
  common_ic_running1x,
  creator_btn_add1x,
  creator_btn_add2x,
  creator_btn_add3x,
  creator_btn_delete1x,
  creator_btn_delete2x,
  creator_btn_delete3x,
  creator_ic_plus1x,
  creator_ic_plus2x,
  creator_ic_plus3x,
  creator_iv_audio1x,
  creator_iv_audio2x,
  creator_iv_audio3x,
  creator_ic_loading1x,
  detail_iv_audio_thumbnail1x,
  detail_iv_audio_thumbnail2x,
  detail_iv_audio_thumbnail3x,
  explore_iv_asset1x,
  explore_iv_asset2x,
  explore_iv_asset3x,
  explore_iv_banner_11x,
  explore_iv_banner_12x,
  explore_iv_banner_13x,
  explore_iv_banner_21x,
  explore_iv_banner_22x,
  explore_iv_banner_23x,
  explore_iv_banner_31x,
  explore_iv_banner_32x,
  explore_iv_banner_33x,
  explore_iv_banner_41x,
  explore_iv_banner_42x,
  explore_iv_banner_43x,
  explore_iv_dollar1x,
  explore_iv_dollar2x,
  explore_iv_dollar3x,
  explore_iv_listing1x,
  explore_iv_listing2x,
  explore_iv_listing3x,
  explore_iv_sale1x,
  explore_iv_sale2x,
  explore_iv_sale3x,
  explore_iv_txs1x,
  explore_iv_txs2x,
  explore_iv_txs3x,
  gnb_btn_buy_dsset_point1x,
  gnb_btn_buy_dsset_point2x,
  gnb_btn_buy_dsset_point3x,
  gnb_btn_language_hover1x,
  gnb_btn_language_hover2x,
  gnb_btn_language_hover3x,
  gnb_btn_language1x,
  gnb_btn_language2x,
  gnb_btn_language3x,
  gnb_btn_menu_close1x,
  gnb_btn_menu_close2x,
  gnb_btn_menu_close3x,
  gnb_btn_menu1x,
  gnb_btn_menu2x,
  gnb_btn_menu3x,
  gnb_btn_mypage1x,
  gnb_btn_mypage2x,
  gnb_btn_mypage3x,
  gnb_btn_notification_hover1x,
  gnb_btn_notification_hover2x,
  gnb_btn_notification_hover3x,
  gnb_btn_notification1x,
  gnb_btn_notification2x,
  gnb_btn_notification3x,
  gnb_logo_beta1x,
  gnb_logo_beta2x,
  gnb_logo_beta3x,
  gnb_logo1x,
  gnb_logo2x,
  gnb_logo3x,
  history_ic_calendar1x,
  history_ic_calendar2x,
  history_ic_calendar3x,
  history_ic_fail1x,
  history_ic_fail2x,
  history_ic_fail3x,
  history_ic_search1x,
  history_ic_search2x,
  history_ic_search3x,
  history_ic_success1x,
  history_ic_success2x,
  history_ic_success3x,
  join_btn_check_abled1x,
  join_btn_check_abled2x,
  join_btn_check_abled3x,
  join_btn_check_disabled1x,
  join_btn_check_disabled2x,
  join_btn_check_disabled3x,
  join_ic_confirm1x,
  join_ic_confirm2x,
  join_ic_confirm3x,
  join_ic_error1x,
  join_ic_error2x,
  join_ic_error3x,
  lineLogin1x,
  loading_Spin_07s_201px1x,
  login_ic_lock1x,
  login_ic_lock2x,
  login_ic_lock3x,
  market_btn_align_uncheck1x,
  market_btn_align_uncheck2x,
  market_btn_align_uncheck3x,
  mypage_btn_cashout_history_normal1x,
  mypage_btn_cashout_history_normal2x,
  mypage_btn_cashout_history_normal3x,
  mypage_btn_edit_profile1x,
  mypage_btn_edit_profile2x,
  mypage_btn_edit_profile3x,
  pay_btn_check_abled1x,
  pay_btn_check_abled2x,
  pay_btn_check_abled3x,
  pay_btn_check_disabled1x,
  pay_btn_check_disabled2x,
  pay_btn_check_disabled3x,
  pay_btn_point_popup_hover1x,
  pay_btn_point_popup_hover2x,
  pay_btn_point_popup_hover3x,
  pay_btn_point_popup1x,
  pay_btn_point_popup2x,
  pay_btn_point_popup3x,
  point_btn_paymentwall_logo1x,
  point_btn_paymentwall_logo2x,
  point_btn_paymentwall_logo3x,
  point_ic_fail1x,
  point_ic_fail2x,
  point_ic_fail3x,
  point_ic_success1x,
  point_ic_success2x,
  point_ic_success3x,
  point_tv_fee1x,
  point_tv_fee2x,
  point_tv_fee3x,
  sample_assetSample11x,
  sample_assetSample12x,
  sample_assetSample13x,
  sample_assetSample21x,
  sample_assetSample22x,
  sample_assetSample23x,
  sample_assetSample31x,
  sample_assetSample32x,
  sample_assetSample33x,
  sample_assetSample41x,
  sample_assetSample42x,
  sample_assetSample43x,
  sample_assetSample51x,
  sample_assetSample52x,
  sample_assetSample53x,
  sample_assetSample61x,
  sample_assetSample62x,
  sample_assetSample63x,
  sample_assetSample71x,
  sample_assetSample72x,
  sample_assetSample73x,
  sample_assetSample81x,
  sample_assetSample82x,
  sample_assetSample83x,
  sample_collection_img_sample11x,
  sample_collection_img_sample12x,
  sample_collection_img_sample13x,
  sample_collection_img_sample21x,
  sample_collection_img_sample22x,
  sample_collection_img_sample23x,
  sample_collection_img_sample31x,
  sample_collection_img_sample32x,
  sample_collection_img_sample33x,
  sample_collection_img_sample41x,
  sample_collection_img_sample42x,
  sample_collection_img_sample43x,
  sample_profile_thumbnail21x,
  sample_profile_thumbnail22x,
  sample_profile_thumbnail23x,
  sample_profile_thumbnail31x,
  sample_profile_thumbnail32x,
  sample_profile_thumbnail33x,
  logoSvg
}

export default images
