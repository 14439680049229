/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useTitle } from 'react-use';
import ScreenInfo, { DESKTOP_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlg } from 'src/hooks/useDlg';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { RPC_ASSET_CREATOR_SCHEMA_EDIT_VIEW } from 'src/model/rpcType';
import { CbtAttributeInfo_i } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, TooltipBox, WIDTH } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import { ImgBtnWithTooltip } from 'src/ui/base_component/ImgBtnWithTooltip';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipBoxContainer } from 'src/ui/base_component/TooltipBoxContainer';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SchemaAttribute } from 'src/ui/common_component/SchemaAttribute';
import { SchemaAttributeAdd } from 'src/ui/common_component/SchemaAttributeAdd';
import { SchemaAttributeForm } from 'src/ui/common_component/SchemaAttributeForm';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { Title } from 'src/ui/common_component/Title';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';
import {
  CommonColumnBox,
  COMMON_BOX_HORIZONTAL_PADDING,
  CLAY,
  MY_SCHEMA_C_SCREEN_LAYOUT,
  schemaDispNameTooltipWidth,
} from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { AppConf, isProd } from 'src/conf/AppConf';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/
type Props = {};

export function MySchemaUScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sData, setData] = useState<RPC_ASSET_CREATOR_SCHEMA_EDIT_VIEW.Rx>();
  const [sSchemaDisplayName, setSchemaDisplayName] = useState<string>(''); // 디스플레이 이름
  const [sAttributeList, setAttributeList] = useState<CbtAttributeInfo_i[]>([]); // 기존 속성 정보
  const [sNewAttributeList, setNewAttributeList] = useState<
    // 새로 추가된 속성정보
    (Partial<CbtAttributeInfo_i> & {
      validation?: {
        status: boolean;
        emsg: string;
      };
    })[]
  >([]); // 속성 이름과 유형
  const [sCurOpenDropDownIdx, setCurOpenDropDownIdx] = useState<number>(); // 추가된 속성의 유형 선택 드롭박스 메뉴

  const [sEditBtnEnabled, setEditBtnEnabled] = useState(false);

  // tooltip show
  const [sDpNameTootipShow, setDpNameTootipShow] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const qsRef = useRef<{ sid: number }>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  // useCheckUserCertTpAndBlock();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid) {
      DBGMSG(`$$$$QS sid: ${query.sid}`);
      const sid = parseInt(query.sid);
      qsRef.current = { sid };
      fetchData(sid);
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    // 디스플레이 이름 입력 안했을 경우
    if (sSchemaDisplayName.length === 0) {
      setEditBtnEnabled(false);
      return () => {};
    }

    // 수정사항이 없는경우
    if (sNewAttributeList.length === 0 && sSchemaDisplayName === sData?.schm_info.dp_name) {
      setEditBtnEnabled(false);
      return () => {};
    }

    // 추가된 속성 체크
    if (sNewAttributeList.length > 0) {
      // validation 상태만 체크
      // 주의: 이름을 입력하지 않은경우도 ok
      const isValidated = sNewAttributeList.every((attr) => {
        // 이름과 유형중 둘중 하나라도 설정하지 않으면 해당 속성은 무시됨
        if (attr.validation?.status === false) return false;
        return true;
      });

      if (isValidated) setEditBtnEnabled(true);
      else setEditBtnEnabled(false);
      return () => {};
    } else {
      setEditBtnEnabled(true);
      return () => {};
    }
  }, [sNewAttributeList, sSchemaDisplayName]);

  useEffectOnce(() => {});

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async (schemaId: number) => {
    const res = await hRpc.cobaltRpc.rpcAssetCreatorSchemaEditView(schemaId);
    setData(res);
    setAttributeList(res.schm_info.attributes);
    setSchemaDisplayName(res.schm_info.dp_name);
    DBGMSG(res.schm_info.dp_name);
  };

  const isDupName = ({ idx, name }: { idx: number; name: string }) => {
    // 중복 체크 - 기존 속성들과 비교
    const dupItemInOrg = sAttributeList.find((attr) => {
      return attr.name === name;
    });

    // 중복 체크 - 추가된 속성들과 비교
    const dupItemInNew = sNewAttributeList.find((attr, _idx) => {
      // attr.name undefined 이거나 빈문자'' 일경우 제외
      return attr.name !== undefined && attr.name.length > 0 && attr.name === name && idx !== _idx;
    });

    if (dupItemInOrg || dupItemInNew) return true;
    return false;
  };

  const setErrorMsg = ({ idx, isDup, isReserved }: { idx: number; isDup: boolean; isReserved: boolean }) => {
    if (isDup || isReserved) {
      sNewAttributeList[idx].validation = {
        status: false,
        emsg: isReserved ? hR.strings.AC_SCHEMA_CREATE_ATTR_NAME_RESERVED : hR.strings.AC_SCHEMA_CREATE_ATTR_NAME_DUPLICATED,
      };
    } else {
      sNewAttributeList[idx].validation = undefined;
    }
  };

  const handleAttNameOnChange = ({ inputValue, idx }: { inputValue: string; idx: number }) => {
    if (inputValue.length > 20) return;

    // 정규식 체크
    const regex = Constants.REGEXP_IS_ALPHA_NUM_SPACE;
    if (!regex.test(inputValue)) {
      DBGMSG('reges false: ' + inputValue);
      return;
    }
    DBGMSG('reges true: ' + inputValue);

    const isDup = isDupName({ idx, name: inputValue }); // 중복 체크
    const isReserved = Utils.svc.isReservedAttName(inputValue); // 필수속성명(reserved)인지 체크
    setErrorMsg({ idx, isDup, isReserved });

    // 정규식만 체크되면 그대로 반영
    sNewAttributeList[idx].name = inputValue;
    setNewAttributeList([...sNewAttributeList]);

    DBGMSG(sNewAttributeList);
  };

  const handleEditBtn = async () => {
    if (!sData) {
      DBGMSGW('error');
      return;
    }

    const newAttList: CbtAttributeInfo_i[] = [];

    sNewAttributeList.forEach(({ name, type }) => {
      if (name !== undefined && type !== undefined) {
        newAttList.push({ name, type });
      }
    });

    DBGMSG(newAttList);

    try {
      await hRpc.cobaltRpc.rpcAssetCreatorSchemaEdit({ sid: sData.schm_info.sid, dp_name: sSchemaDisplayName, attributes: newAttList });

      hDlgFactory.showAsyncGuideJsx({
        title: hR.strings.DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TITLE,
        text: hR.strings.DLG_ASYNC_SCHEMA_EDIT_DLG_REQ_TXT,
        onOk: () => {
          hDlgFactory.closeAsyncGuideJsx();
          hHistory.replace(Nav.brewCollectionRScreenPath({ cid: sData.schm_info.cid }));
        },
      });
    } catch (e) {}

    // hHisotry.goBack();
  };

  /**************************************
   * !! render function
   **************************************/
  const renderNewAttributeList = () => {
    return (
      <>
        {sNewAttributeList.map((att, idx) => {
          return (
            <FlexColumnDiv key={idx}>
              <SchemaAttributeForm
                //
                idx={idx}
                width={ScreenInfo.isMobile() ? CLAY.FORM_SIZE.WIDTH : ScreenInfo.contentsWidth / 2}
                name={att.name ?? ''}
                type={att.type}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
                onBlur={() => {
                  DBGMSG('onBlur');
                  DBGMSG(sNewAttributeList[idx]);
                  if (!sNewAttributeList[idx]) return;
                  if (!sNewAttributeList[idx].name) return;
                  const trimedName = sNewAttributeList[idx].name!.trimEnd();

                  sNewAttributeList[idx].name = trimedName;
                  setNewAttributeList([...sNewAttributeList]);

                  const isDup = isDupName({ idx, name: trimedName }); // 중복 체크
                  const isReserved = Utils.svc.isReservedAttName(trimedName); // 필수속성명(reserved)인지 체크
                  setErrorMsg({ idx, isDup, isReserved });
                }}
                onChange={(e) => {
                  handleAttNameOnChange({ inputValue: e.target.value, idx });
                }}
                onKeyDown={(e) => {}}
                isDropDownOpen={sCurOpenDropDownIdx === idx}
                onDropDownOpenClick={() => {
                  setCurOpenDropDownIdx(idx);
                }}
                onTypeSelect={(selectedType) => {
                  DBGMSG(`selectedType:${selectedType}`);
                  sNewAttributeList[idx].type = selectedType;
                  setNewAttributeList([...sNewAttributeList]);
                  setCurOpenDropDownIdx(undefined);
                }}
                onDeleteClick={() => {
                  DBGMSG([...sNewAttributeList.slice(0, idx)]);
                  DBGMSG([...sNewAttributeList.slice(idx + 1)]);
                  setNewAttributeList([...sNewAttributeList.slice(0, idx), ...sNewAttributeList.slice(idx + 1)]);
                }}
                onOutsideClick={() => {
                  DBGMSG(`onOutsideClick`);
                  setCurOpenDropDownIdx(undefined);
                }}
              />
              {/* validation 체크 실패 메세지 */}
              {att.validation && att.validation.status === false && (
                <TextCon
                  text={att.validation.emsg}
                  size={CLAY.FORM_CHK_MSG_FONT_SIZE}
                  color={R.colors.pastelRed}
                  containerStyle={{
                    paddingLeft: ScreenInfo.isMobile() ? 0 : COMMON_BOX_HORIZONTAL_PADDING,
                    marginBottom: CLAY.FORM_BETWEEN_SPACE,
                  }}
                />
              )}
            </FlexColumnDiv>
          );
        })}
      </>
    );
  };

  /**************************************
   * !! render conf
   **************************************/
  if (!sData) return null;

  if (ScreenInfo.isMobile()) {
    return (
      <MobileScalableFlexColumn
        // key를 변경해 다시 마운팅되도록 함
        // 추가 속성 갯수, 에셋 타입이 변경되면 높이가 변하기때문에 다시 높이를 계산해야함
        key={`${sNewAttributeList.length}${sAttributeList}`}
      >
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: ScreenInfo.contentsWidth,
            alignItems: 'flex-start',
            paddingTop: 20,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          {/* Back 버튼 */}
          {/* <BackBtn dest={Nav.brewCollectionRScreenPath({ id: sCollectionId! })} /> */}

          {/* 타이틀 */}
          <Title title={`${hR.strings.AC_SCHEMA_UPDATE_TITLE} - ${sData.schm_info.dp_name}`} />

          <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

          {/* 박스 */}
          <CommonColumnBox style={{ width: MY_SCHEMA_C_SCREEN_LAYOUT.boxWidth, alignItems: 'flex-start' }}>
            <HEIGHT size={34} />
            {/* 스키마 이름 입력 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexColumnDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                </FlexRowDiv>
              </FlexColumnDiv>
              <HEIGHT size={CLAY.FORM_INPUT_TOP_MARGIN} />
              <SRoundInputText
                fontSize={CLAY.FORM_FONT_SIZE}
                width={CLAY.FORM_SIZE.WIDTH}
                height={CLAY.FORM_SIZE.HEIGHT}
                value={sData.schm_info.name}
                disabled={true}
              />
            </FlexColumnDiv>
            <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />
            {/* 디스플레이 이름 툴팁 */}
            {sDpNameTootipShow && (
              <TooltipBoxContainer
                offsetLeftIfNotMobile={105}
                tooltipJSX={
                  <>
                    <TextCon size={CLAY.TOOLTIP_FONT_SIZE} color={R.colors.black} text={hR.strings.AC_SCHEMA_CREATE_TOOLTIP_DISPNAME} />
                  </>
                }
              />
            )}
            {/* 디스플레이 이름 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                <WIDTH size={3} />
                <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                <WIDTH size={5} />
                <ImgBtn
                  src={[R.images.common_btn_tooltip_normal3x, CLAY.TOOLTIP_IC_SIZE, CLAY.TOOLTIP_IC_SIZE]}
                  onConMouseEnter={(e) => {
                    DBGMSG(`onConMouseEnter`);
                    setDpNameTootipShow(true);
                  }}
                  onConMouseLeave={(e) => {
                    DBGMSG(`onConMouseLeave`);
                    setDpNameTootipShow(false);
                  }}
                />
              </FlexRowDiv>

              <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
              <SRoundInputText
                fontSize={CLAY.FORM_FONT_SIZE}
                width={CLAY.FORM_SIZE.WIDTH}
                height={CLAY.FORM_SIZE.HEIGHT}
                value={sSchemaDisplayName}
                onBlur={() => {
                  setSchemaDisplayName((prev) => prev.trimEnd());
                }}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 20) {
                    setSchemaDisplayName(e.target.value);
                  }
                }}
              />
            </FlexColumnDiv>
            <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />
            {/* 에셋 유형 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_TYPE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <WIDTH size={3} />
              </FlexRowDiv>
              <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
              <FlexColumnDiv
                style={{
                  width: 256,
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddingLeft: 12,
                  height: 50,
                  backgroundColor: R.colors.lightGrey,
                  borderRadius: 15,
                  // ...devOutline,
                  // paddingLeft: 14,
                  // paddingRight: 14,
                }}
              >
                <TextCon text={hR.strings.getAssetType(sData.schm_info.asset_type)} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
              </FlexColumnDiv>
            </FlexColumnDiv>
            <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />
            {/* 기존 속성 이름과 유형 */}
            <FlexRowDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <FlexColumnDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                <FlexRowDiv style={{ flexWrap: 'wrap' }}>
                  <TextCon
                    text={`(${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_1} ${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_2})`}
                    size={CLAY.FORM_FONT_SIZE}
                    color={R.colors.black}
                  />
                  {/* <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_2} size={CL.formFontSize} color={R.colors.black} /> */}
                  {/* <TextCon text={')'} size={CL.formFontSize} color={R.colors.black} /> */}
                </FlexRowDiv>
              </FlexColumnDiv>
            </FlexRowDiv>
            <HEIGHT size={CLAY.FORM_INPUT_TOP_MARGIN} />
            {/* 기존 속성 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              {sAttributeList.map((att, idx) => {
                const typeTxt: string = hR.strings.getAttributeTypeText(att.type);

                return (
                  <FlexDiv key={idx}>
                    <SchemaAttribute width={CLAY.FORM_SIZE.WIDTH} name={att.name} value={typeTxt} disabled borderWidth={0} />
                  </FlexDiv>
                );
              })}
            </FlexColumnDiv>
            {/* 추가 속성 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>{renderNewAttributeList()}</FlexColumnDiv>
            {/* 속성 추가 버튼 */}
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <SchemaAttributeAdd
                width={CLAY.FORM_SIZE.WIDTH}
                onAdd={() => {
                  DBGMSG('onAdd');
                  setNewAttributeList([...sNewAttributeList, { name: undefined, type: undefined, validation: undefined }]);
                }}
              />
            </FlexColumnDiv>
            {/* 스키마 수정하기 버튼 */}
            <HEIGHT size={34} />
            <FlexColumnDiv style={{ paddingLeft: 12, paddingRight: 12 }}>
              <RoundTxtBtn
                width={CLAY.TEXT_BTN_SIZE_IN_BOX.WIDTH}
                height={CLAY.TEXT_BTN_SIZE_IN_BOX.HEIGHT}
                fontSize={CLAY.TEXT_BTN_FONT_SIZE_IN_BOX}
                text={hR.strings.AC_SCHEMA_UPDATE_BTN}
                enable={sEditBtnEnabled}
                containerStyle={{}}
                onClick={() => {
                  handleEditBtn();
                }}
              />
            </FlexColumnDiv>
            <HEIGHT size={34} />
          </CommonColumnBox>
        </FlexColumnDiv>
        <HEIGHT size={60} />
        <CommonFooter />
      </MobileScalableFlexColumn>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          alignItems: 'flex-start',
          paddingTop: CLAY.TITLE_PADDING_TOP,
          ...devOutline,
        }}
      >
        <BackBtn />

        {/* 타이틀 */}
        <Title title={`${hR.strings.AC_SCHEMA_UPDATE_TITLE} - ${sData.schm_info.dp_name}`} />

        <HEIGHT size={30} />
        <CommonColumnBox style={{ width: DESKTOP_WIDTH, alignItems: 'flex-start' }}>
          <HEIGHT size={30} />
          <FlexRowDiv style={{ ...devOutline }}>
            <FlexColumnDiv
              style={{
                width: DESKTOP_WIDTH / 2,
                paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
                paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
                // ...devOutlineF,
              }}
            >
              {/* 스키마 이름 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME} size={14} color={R.colors.black} isBold />
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_NAME_GUIDE} size={14} color={R.colors.black} />
                <WIDTH size={3} />
                <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />
                <WIDTH size={5} />
                {/* <Img src={[R.images.common_btn_tooltip_normal3x, 20, 20]} /> */}
              </FlexRowDiv>
              <HEIGHT size={14} />
              <SRoundInputText disabled value={sData.schm_info.name} placeHolder={hR.strings.AC_SCHEMA_CREATE_CANNOT_EDIT} />
              <HEIGHT size={24} />

              {/* 에셋 유형 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_TYPE} size={14} color={R.colors.black} isBold />
                <WIDTH size={3} />
                <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />
                <WIDTH size={5} />
                {/* <Img src={[R.images.common_btn_tooltip_normal3x, 20, 20]} /> */}
              </FlexRowDiv>
              <HEIGHT size={14} />
              <FlexRowDiv
                style={{
                  alignItems: 'center',
                  height: 68,
                  backgroundColor: R.colors.lightGrey,
                  borderWidth: 0,
                  borderStyle: 'solid',
                  borderColor: R.colors.line,
                  borderRadius: 15,
                  // ...devOutline,
                  paddingLeft: 14,
                  // paddingRight: 14,
                }}
              >
                <TextCon text={hR.strings.getAssetType(sData.schm_info.asset_type)} size={14} color={R.colors.black} />
              </FlexRowDiv>
              <HEIGHT size={24} />
            </FlexColumnDiv>

            <FlexColumnDiv
              style={{
                width: DESKTOP_WIDTH / 2,
                paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
                paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
                // ...devOutlineF,
              }}
            >
              {/* 디스플레이 이름 */}
              <FlexRowDiv>
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME} size={14} color={R.colors.black} isBold />
                <TextCon text={hR.strings.AC_SCHEMA_CREATE_DISP_NAME_GUIDE} size={14} color={R.colors.black} />
                <WIDTH size={3} />
                <TextCon text={'*'} size={14} color={R.colors.purpleishPink} />
                <WIDTH size={5} />
                <ImgBtnWithTooltip
                  src={[R.images.common_btn_tooltip_normal3x, 20, 20]}
                  tooltipJSX={
                    <TooltipBox
                      style={{ width: schemaDispNameTooltipWidth(hR.lang), paddingTop: 10, paddingBottom: 10, paddingLeft: 14, paddingRight: 14 }}
                    >
                      <TextCon size={12} color={R.colors.black} text={hR.strings.AC_SCHEMA_CREATE_TOOLTIP_DISPNAME} containerStyle={{ height: 21 }} />
                    </TooltipBox>
                  }
                />
              </FlexRowDiv>

              <HEIGHT size={14} />
              <SRoundInputText
                fontSize={CLAY.FORM_FONT_SIZE}
                width={CLAY.FORM_SIZE.WIDTH}
                height={CLAY.FORM_SIZE.HEIGHT}
                value={sSchemaDisplayName}
                onFocus={() => {
                  setCurOpenDropDownIdx(undefined);
                }}
                onBlur={() => {
                  setSchemaDisplayName((prev) => prev.trimEnd());
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 20) {
                    setSchemaDisplayName(e.target.value);
                  }
                }}
              />
              <HEIGHT size={24} />
            </FlexColumnDiv>
          </FlexRowDiv>

          {/* 속성과 유형 */}
          <FlexRowDiv
            style={{
              width: DESKTOP_WIDTH / 2,
              paddingLeft: COMMON_BOX_HORIZONTAL_PADDING,
              paddingRight: COMMON_BOX_HORIZONTAL_PADDING,
              // ...devOutlineF,
            }}
          >
            <TextCon text={hR.strings.AC_SCHEMA_CREATE_ATTR} size={14} color={R.colors.black} isBold />
            <TextCon
              text={`(${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_1} ${hR.strings.AC_SCHEMA_CREATE_ATTR_GUIDE_2})`}
              size={14}
              color={R.colors.black}
            />
            {/* <WIDTH size={3} /> */}
            {/* <Txt text={'*'} size={14} color={R.colors.purpleishPink} /> */}
            {/* <WIDTH size={5} /> */}
            {/* <Img src={[R.images.common_btn_tooltip_normal3x, 20, 20]} /> */}
          </FlexRowDiv>
          <HEIGHT size={14} />
          <FlexRowDiv
            style={{
              // ...devOutlineF,
              // alignSelf: 'stretch',
              flexWrap: 'wrap',
              width: 1194,
              // marginLeft: -1,
              // marginRight: -1,
            }}
          >
            {/* 기존 속성 */}
            {sAttributeList.map((att, idx) => {
              const typeTxt: string = hR.strings.getAttributeTypeText(att.type);

              return (
                <FlexDiv key={idx}>
                  <SchemaAttribute width={DESKTOP_WIDTH / 2} name={att.name} value={typeTxt} disabled borderWidth={0} />
                </FlexDiv>
              );
            })}

            {/* 추가된 속성 */}
            {renderNewAttributeList()}

            {/* 속성 추가 버튼 */}
            <SchemaAttributeAdd
              width={DESKTOP_WIDTH / 2}
              onAdd={() => {
                DBGMSG('onAdd');
                setNewAttributeList([...sNewAttributeList, { name: undefined, type: undefined }]);
              }}
            />
          </FlexRowDiv>

          <HEIGHT size={30} />

          {/* 편집완료 버튼 */}
          <RoundTxtBtn
            width={294}
            height={48}
            fontSize={14}
            enable={sEditBtnEnabled}
            text={hR.strings.AC_SCHEMA_UPDATE_BTN}
            containerStyle={{ alignSelf: 'center' }}
            onClick={() => {
              handleEditBtn();
            }}
          />
          <HEIGHT size={40} />
        </CommonColumnBox>
        <HEIGHT size={206} />
      </FlexColumnDiv>
    );
}
