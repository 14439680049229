/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { PrivacyPolicyStrings } from 'src/res/PrivacyPolicyStrings';
import { RefundPolicyStrings } from 'src/res/RefundPolicyStrings';
import { StaticStrings } from 'src/res/strings';
import { TermsOfUseStrings } from 'src/res/TermsOfUseStrings';
import colors from './colors';
import fonts from './fonts';
import images from './images';

const public_res = {
  jpg: 'nft_res/nft_res_sample.jpg' as const,
  png: 'nft_res/nft_res_sample.png' as const,
  gif: 'nft_res/nft_res_sample.gif' as const,
  apng: 'nft_res/nft_res_sample.apng' as const,
  mp3: 'nft_res/nft_res_sample.mp3' as const,
  aac: 'nft_res/nft_res_sample.aac' as const,
  mp4: 'nft_res/nft_res_sample.mp4' as const,
  explorerSampe1_png: 'nft_res/explorerSample1.png' as const,
  explorerSampe2_png: 'nft_res/explorerSample2.png' as const,
  explorerSampe2_mp4: 'nft_res/explorerSample2.mp4' as const,
  explorerSampe3_png: 'nft_res/explorerSample3.png' as const,
  explorerSampe4_png: 'nft_res/explorerSample4.png' as const,
  explorerSampe5_png: 'nft_res/explorerSample5.png' as const,
  explorerSampe6_png: 'nft_res/explorerSample6.png' as const,
  explorerSampe7_png: 'nft_res/explorerSample7.png' as const,
  explorerSampe8_png: 'nft_res/explorerSample8.png' as const,
  collectionImgSample_png: 'nft_res/collectionImgSample.png' as const,
};

const staticResource = {
  colors, // 컬러
  strings: StaticStrings,
  termsOfUse: { ko: { ...TermsOfUseStrings.ko }, ja: { ...TermsOfUseStrings.ja }, en: { ...TermsOfUseStrings.en } },
  privacyPolicy: { ko: { ...PrivacyPolicyStrings.ko }, ja: { ...PrivacyPolicyStrings.ja }, en: { ...PrivacyPolicyStrings.en } },
  refundPolicy: { ko: { ...RefundPolicyStrings.ko } , ja: { ...RefundPolicyStrings.ja }, en: { ...RefundPolicyStrings.en } },
  images, // 이미지
  fonts, // 폰트
  public_res,
};

export default staticResource;

// lab) constant를 namespace로
// export namespace Res {
//   export class Colors {
//     public static BLUSH = '#eca99c';
//     public static WHITE1 = '#c8c8c8';
//   }
// }

// console.debug(Res.Colors.BLUSH);
