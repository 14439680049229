/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { CSSProperties, HTMLProps, MouseEventHandler, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Overlay } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import ScreenInfo from 'src/context/screen_constants';
import { useMakePressable } from 'src/hooks/useMakePressable';
import R from 'src/res/R';
import { FlexColumnDiv, FlexDiv, TooltipBox } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import { CLAY } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const DT_TOOLTIP_FONTSIZE = 12;
const DT_TOOLTIP_PADDING_V = 10;
const DT_TOOLTIP_PADDING_H = 8;
const DT_TOOLTIP_OFFSET = 10;

const MB_TOOLTIP_FONTSIZE = 10;
const MB_TOOLTIP_PADDING_V = 8;
const MB_TOOLTIP_PADDING_H = 8;
const MB_TOOLTIP_OFFSET = 6;

const TOOLTIP_FONTSIZE = ScreenInfo.isMobile() ? MB_TOOLTIP_FONTSIZE : DT_TOOLTIP_FONTSIZE;
const TOOLTIP_PADDING_V = ScreenInfo.isMobile() ? MB_TOOLTIP_PADDING_V : DT_TOOLTIP_PADDING_V;
const TOOLTIP_PADDING_H = ScreenInfo.isMobile() ? MB_TOOLTIP_PADDING_H : DT_TOOLTIP_PADDING_H;
const TOOLTIP_OFFSET = ScreenInfo.isMobile() ? MB_TOOLTIP_OFFSET : DT_TOOLTIP_OFFSET;

type Props = {
  containerStyle?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onConMouseEnter?: HTMLProps<HTMLDivElement>['onMouseEnter'];
  onConMouseLeave?: HTMLProps<HTMLDivElement>['onMouseLeave'];
  onShow?: (isShow: boolean) => void;
  forceShow?: boolean;
  placement?: Placement;
  jsx: JSX.Element;
  tooltipJsx?: JSX.Element;
  tooltipString?: string;
  offset?: number;
};

export function TooltipJsx({
  //
  containerStyle,
  onClick,
  onConMouseEnter,
  onConMouseLeave,
  placement,
  jsx,
  tooltipJsx,
  tooltipString,
  onShow,
  forceShow = false,
}: Props) {
  const [sShowOverlay, setShowOverlay] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    onShow && onShow(sShowOverlay);
  }, [onShow, sShowOverlay]);

  useMakePressable([
    {
      elementKey: 'tooltip',
      elementRefList: [targetRef],
      onInsidePress: ({ e, hitRef }) => {
        DBGMSG('tooltip Click');
        if (targetRef.current === hitRef.current) {
          if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) return;
          setShowOverlay((prev) => !prev);
        }
        e.stopPropagation();
      },
      onOutsidePress: (e) => {
        sShowOverlay && setShowOverlay(false);
      },
    },
  ]);

  return (
    <FlexColumnDiv
      ref={targetRef}
      style={{
        ...containerStyle,
        cursor: 'pointer',
      }}
      onMouseEnter={(e) => {
        if (ScreenInfo.isMobile()) return;
        onConMouseEnter && onConMouseEnter(e);
        setShowOverlay(true);
      }}
      onMouseLeave={(e) => {
        if (ScreenInfo.isMobile()) return;
        onConMouseLeave && onConMouseLeave(e);
        setShowOverlay(false);
      }}
      onClick={(e) => {
        onClick && onClick(e);
      }}
    >
      {jsx}
      <Overlay target={targetRef.current} show={forceShow || sShowOverlay} placement={placement}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              zIndex: 1,
            }}
          >
            <TooltipBox
              style={{
                margin: TOOLTIP_OFFSET,
                marginTop: (function () {
                  switch (placement) {
                    case 'right-start':
                      return 0;
                    case 'right':
                      return TOOLTIP_OFFSET;
                    default:
                      return TOOLTIP_OFFSET;
                  }
                })(),
                marginRight: (function () {
                  switch (placement) {
                    case 'bottom-end':
                      return 0;
                    default:
                      return TOOLTIP_OFFSET;
                  }
                })(),
                marginLeft: (function () {
                  switch (placement) {
                    case 'bottom-start':
                      return 0;
                    default:
                      return TOOLTIP_OFFSET;
                  }
                })(),
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: TOOLTIP_PADDING_H,
                paddingRight: TOOLTIP_PADDING_H,
                paddingTop: TOOLTIP_PADDING_V,
                paddingBottom: TOOLTIP_PADDING_V,
                zIndex: 1,
              }}
            >
              {tooltipJsx}
              {tooltipString && <TextCon text={tooltipString} color={R.colors.black} size={TOOLTIP_FONTSIZE} />}
            </TooltipBox>
          </div>
        )}
      </Overlay>
    </FlexColumnDiv>
  );
}
