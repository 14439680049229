/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import ScreenInfo, { MOBILE_CONTENT_PADDING_TOP, MOBILE_HORIZONTAL_PADDING, MOBILE_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlg } from 'src/hooks/useDlg';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { RPC_ASSET_CREATOR_GROUP_VIEW } from 'src/model/rpcType';
import { CbtAssetCardInfo_i, CbtAttributeType_e, CbtIssueType_e } from 'src/model/rpcModel';
import R from 'src/res/R';
import { messageService, CbtStompMsgCode_e } from 'src/rx/messageService';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { AssetCardForList, AST_CARD_CONTAINER_HEIGHT_FOR_LIST, AST_CARD_CONTAINER_WIDTH_FOR_LIST } from 'src/ui/common_component/AssetCardForList';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { CommonFooter, COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { COMMON_CARD_COLUMN_CNT, CLAY, DEFAUL_PAGE_ITEM_CNT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { useSubscribeStompMsg } from 'src/hooks/useSubscribeStompMsg';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

type Props = {};

export function MyAssetGroupListScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sFetchedData, setFetchedData] = useState<RPC_ASSET_CREATOR_GROUP_VIEW.Rx>();
  const [sAssetCardList, setAssetCardList] = useState<CbtAssetCardInfo_i[]>([]);
  const [sNextPageStatus, setNextPageStatus] = useState<string>('');

  /**************************************
   * !! ref
   **************************************/
  const assetCardListRef = useRef<CbtAssetCardInfo_i[]>([]);
  const fetchedDataRef = useRef<RPC_ASSET_CREATOR_GROUP_VIEW.Rx>();
  const nextPageLoadingRef = useRef(false);
  const qsRef = useRef<{ sid: number; entity_id: number }>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const location = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hAuth = useAuth();
  // useCheckUserCertTpAndBlock();

  // useSubscribeStompMsg({
  //   onMsg: (stompMsg) => {
  //     DBGMSG(`onMsg`);
  //     if (!qsRef.current) return;
  //     fetchData({ sid: qsRef.current.sid, entity_id: qsRef.current.entity_id });
  //     return;
  //   },
  //   targetCodeList: [
  //     //
  //     CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS,
  //     CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_FAIL,
  //   ],
  // });

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    // query string parsing
    const query: { sid?: string; entity_id?: string } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.sid && query.entity_id) {
      DBGMSG(`$$$$QS sid: ${query.sid}, entity_id: ${query.entity_id}`);
      const sid = parseInt(query.sid);
      const entity_id = parseInt(query.entity_id);
      qsRef.current = { sid, entity_id };
      fetchData({ sid, entity_id });
    } else {
      // key 없이 접근
      return;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    const subscription = messageService.onMessage().subscribe((message) => {
      if (!qsRef.current) return;

      switch (message.kind) {
        case 'ReceiveStompMsg':
          if (message.code === CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_FAIL || message.code === CbtStompMsgCode_e.ASSET_ADDITIONAL_CREATE_SUCCESS) {
            fetchData({ sid: qsRef.current.sid, entity_id: qsRef.current.entity_id });
          }
          // code(행동)은 각 screen이 구독하여 결정
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const fetchData = async ({ sid, entity_id }: { sid: number; entity_id: number }) => {
    const res = await hRpc.cobaltRpc.rpcAssetCreatorGroupView({ entity_id, sid, pg_info: { row_cnt: DEFAUL_PAGE_ITEM_CNT, cursor: null } });
    setFetchedData(res);
    setAssetCardList(res.ast_list);
    fetchedDataRef.current = res;
    assetCardListRef.current = res.ast_list;
  };

  const handleMintMoreOnClick = () => {
    if (!sFetchedData) return;
    const mainImg = Utils.svc.getReqImgAttrValue(sFetchedData.astg_info.attr_list);
    if (!mainImg) return;

    hDlgFactory.showMintMoreJsx({
      defaultOwner: hAuth.authCommonRes?.ucode ?? '',
      totolCnt: sFetchedData.astg_info.issue_tcnt,
      mainImg,
      assetType: sFetchedData.astg_info.schm_info.asset_type,
      onClose: () => {
        hDlgFactory.hideMintMoreJsx();
      },
      onMintClick: ({ mintCnt, owner }) => {
        if (!qsRef.current) return;
        if (!hAuth.authCommonRes) return;

        DBGMSG(`onMintClick`);
        hRpc.cobaltRpc
          .rpcAssetCreatorAdd({
            sid: sFetchedData.astg_info.schm_info.sid,
            supply: mintCnt,
            owner: owner,
            issue_type: CbtIssueType_e.ADDITION,
            entity_id: sFetchedData.astg_info.entity_id,
            attr_values: sFetchedData.astg_info.attr_list.map((attr) => {
              switch (attr.type) {
                case CbtAttributeType_e.IMAGE:
                case CbtAttributeType_e.AUDIO:
                case CbtAttributeType_e.VIDEO:
                  return {
                    ...attr,
                    value: Utils.ipfs.getIpfsHash(attr.value),
                  };
              }
              return {
                ...attr,
              };
            }),
          })
          .then((res) => {});

        hDlgFactory.closeMintMoreJsx();

        hDlgFactory.showAsyncGuideJsx({
          title: hR.strings.DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TITLE,
          text: hR.strings.DLG_ASYNC_ASSET_CREATE_MORE_DLG_REQ_TXT,
          onOk: () => {
            hDlgFactory.closeAsyncGuideJsx();
            // if (!qsRef.current) return;
            // hHistory.replace(Nav.brewSchemaRScreenPath({ sid: qsRef.current.sid }));
          },
        });
      },
    });
  };

  const getLastRowId = () => {
    if (!fetchedDataRef.current) return;
    if (!fetchedDataRef.current.pg_info) return;
    return fetchedDataRef.current.pg_info.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  // 다음페이지 요청
  const fetchItemListMore = async () => {
    if (!fetchedDataRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'sFetcheAstListDataRef is undefined');
      return Promise.reject();
    }

    if (sAssetCardList.length === 0) {
      DBGMSG('$$$$PAGE_SCROLL', 'sAssetCardList.length === 0');
      return Promise.reject();
    }

    if (!qsRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'qsRef.current is undefined');
      return;
    }

    if (!assetCardListRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'assetCardListRef.current is undefined');
      return;
    }

    nextPageLoadingRef.current = true;

    const pg_info = fetchedDataRef.current.pg_info;

    try {
      setNextPageStatus('loading');
      const res = await hRpc.cobaltRpc.rpcAssetCreatorGroupView({ entity_id: qsRef.current.entity_id, sid: qsRef.current.sid, pg_info });
      setFetchedData(res);
      assetCardListRef.current = [...assetCardListRef.current, ...res.ast_list];
      setAssetCardList(assetCardListRef.current);
      setNextPageStatus('');
      nextPageLoadingRef.current = false;
      fetchedDataRef.current = res;
    } catch (err) {
      setNextPageStatus('loading fail');
      nextPageLoadingRef.current = false;
    }
  };

  // 스크롤 리스너
  const scrollListener = (e: ScrollParams) => {
    // DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (!fetchedDataRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'sFetcheAstListDataRef is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;
    // DBGMSG('$$$$PAGE_SCROLL', `clientHeight: ${clientHeight}, scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}`);
    // DBGMSG('$$$$PAGE_SCROLL', `${scrollTop + clientHeight} >= ${scrollHeight - COMMON_FOONTER_HEIGHT}`);
    // DBGMSG('$$$$PAGE_SCROLL', `atBottom: ${atBottom} `);

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return triggeredRef.current is true `);
      return;
    }

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    if (atBottom) {
      fetchItemListMore()
        .then(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage then');
        })
        .catch(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage catch');
        });
    }
  };

  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  /**************************************
   * !! render function
   **************************************/
  const cardRender = (item: CbtAssetCardInfo_i) => {
    return (
      <AssetCardForList
        assetInfo={item}
        isAnim
        onClick={() => {
          DBGMSG('AssetCardForList onClick!!');
          if (!qsRef.current) {
            DBGMSGW('error qsRef.current');
            return;
          }
          DBGMSG(`qsRef.current: ${JSON.stringify(qsRef.current, undefined, 4)}`);
          hHistory.push(Nav.brewMyIssueAssetRScreenPath({ sid: qsRef.current.sid, entity_id: qsRef.current.entity_id, asset_id: item.asset_id }));
        }}
      />
    );
  };

  const cellRenderer = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    let index: number;
    let item: CbtAssetCardInfo_i;
    index = rowIndex * COMMON_CARD_COLUMN_CNT + columnIndex;
    item = sAssetCardList[index];

    if (index >= sAssetCardList.length) return null;

    // DBGMSG(`typeof item: ${typeof item}`);
    if (ScreenInfo.isMobile()) {
      return (
        // 중요!
        <div key={key} style={style}>
          <MobileScalableFlexColumn>{cardRender(item)}</MobileScalableFlexColumn>
        </div>
      );
    } else {
      return (
        // 중요!
        <div key={key} style={style}>
          {cardRender(item)}
        </div>
      );
    }
  };

  // Asset 목록 렌더링
  const renderAssetList = () => {
    DBGMSG('renderList');
    return (
      <FlexColumnDiv
        style={{
          width: AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT,
          alignSelf: 'center',
          userSelect: 'none',
          ...devOutline,
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <FlexColumnDiv>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <Grid
                      onScroll={(params) => {
                        onChildScroll(params);
                        throttleScrollListener(params);
                      }}
                      isScrolling={isScrolling}
                      autoHeight
                      cellRenderer={cellRenderer}
                      columnWidth={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale}
                      columnCount={COMMON_CARD_COLUMN_CNT}
                      height={height}
                      rowHeight={AST_CARD_CONTAINER_HEIGHT_FOR_LIST * ScreenInfo.scale}
                      rowCount={Math.ceil(sAssetCardList.length / COMMON_CARD_COLUMN_CNT)}
                      scrollTop={scrollTop}
                      width={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT}
                    />
                  </div>
                )}
              </AutoSizer>
            </FlexColumnDiv>
          )}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  /**************************************
   * !! render conf
   **************************************/
  if (!sFetchedData) return <DataFetching />;

  DBGMSG('render');
  const title = sFetchedData.ast_list[0]?.nft_name ?? '';

  if (ScreenInfo.isMobile()) {
    return (
      <>
        <MobileScalableFlexColumn>
          <FlexColumnDiv
            style={{
              backgroundColor: R.colors.bgGrey,
              width: MOBILE_WIDTH,
              alignItems: 'flex-start',
              paddingTop: MOBILE_CONTENT_PADDING_TOP,
              paddingLeft: MOBILE_HORIZONTAL_PADDING,
              paddingRight: MOBILE_HORIZONTAL_PADDING,
              ...devOutline,
            }}
          >
            {/* Back 버튼 */}
            <BackBtn />

            {/* 타이틀 */}
            <FlexRowDiv style={{ alignItems: 'baseline', height: 24, alignSelf: 'stretch' }}>
              <TextCon text={title} size={16} color={R.colors.black} isBold />
              <WIDTH size={3} />
              <TextCon text={`(${sFetchedData.astg_info.issue_tcnt})`} size={12} color={R.colors.black} />
            </FlexRowDiv>
            {sFetchedData.astg_info.can_addition === true && (
              <>
                <HEIGHT size={12} />
                <RoundTxtBtn
                  text={hR.strings.AC_ASSET_GROUP_MINT_MORE_BTN}
                  width={129}
                  height={40}
                  fontSize={12}
                  containerStyle={{ marginLeft: 'auto' }}
                  enable={sFetchedData.astg_info.can_addition === true}
                  onClick={() => {
                    handleMintMoreOnClick();
                  }}
                />
              </>
            )}
          </FlexColumnDiv>
        </MobileScalableFlexColumn>

        <HEIGHT size={6} />
        {renderAssetList()}
        <HEIGHT size={46} />
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      </>
    );
  } else
    return (
      <FlexColumnDiv
        style={{
          backgroundColor: R.colors.bgGrey,
          width: ScreenInfo.contentsWidth,
          alignItems: 'flex-start',
          ...devOutline,
        }}
      >
        <HEIGHT size={40} />

        {/* Back 버튼 */}
        <BackBtn />

        {/* 타이틀 */}
        <FlexRowDiv style={{ alignItems: 'baseline', height: 50, alignSelf: 'stretch' }}>
          <TextCon text={title} size={30} color={R.colors.black} isBold />
          <WIDTH size={5} />
          <TextCon text={`(${sFetchedData.astg_info.issue_tcnt})`} size={20} color={R.colors.black} />
          {sFetchedData.astg_info.can_addition === true && (
            <RoundTxtBtn
              text={hR.strings.AC_ASSET_GROUP_MINT_MORE_BTN}
              width={141}
              containerStyle={{ marginLeft: 'auto' }}
              enable={sFetchedData.astg_info.can_addition === true}
              onClick={() => {
                handleMintMoreOnClick();
              }}
            />
          )}
        </FlexRowDiv>

        <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

        {renderAssetList()}
      </FlexColumnDiv>
    );
}
