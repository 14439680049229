// import classNames from 'classnames/bind';
import React from 'react';
import ScreenInfo from 'src/context/screen_constants';
import R from 'src/res/R';

// import styles from 'src/ui/dlg/jsx/WithdrawJsx.module.scss';

// const cx = classNames.bind(styles);
// const cx = classNames('testtest2');

export type SimpleJsxProps = {
  text: string;
  onClose?: () => void;
};

export const SimpleJsx = (dlg: SimpleJsxProps) => {
  return (
    <div
      className={ScreenInfo.isMobile() ? 'simple_jsx_m' : 'simple_jsx'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <img
        className={'close_btn'}
        src={R.images.common_btn_close3x}
        alt={''}
        onClick={() => {
          dlg.onClose && dlg.onClose();
        }}
      />
      <div className={'text'}>{dlg.text}</div>
    </div>
  );
};
