/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { useMemo } from 'react';
import { AppConf } from 'src/conf/AppConf';
import { DisplayCurrency_e } from 'src/model/model';
import { CbtUserLanguage_e } from 'src/model/rpcModel';
import { DBGMSG, Utils } from 'src/util/utils';

const STORAGE_LOCAL_SETTINGS_KEY = `${AppConf.APPNAME}_${AppConf.PHASE}_local_settings`;

// local settings
export interface LocalSettings_i {
  lang: CbtUserLanguage_e;
  currency: DisplayCurrency_e;
  time: number;
}

export function useLocalSettingsStorage() {
  const _getSettings = () => {
    return Utils.storage.loadLocalStorage<LocalSettings_i>(STORAGE_LOCAL_SETTINGS_KEY);
    // return Utils.storage.loadSessionStorage<LocalSettings_i>(STORAGE_LOCAL_SETTINGS_KEY);
  };
  const _saveSettings = (localSettings: LocalSettings_i) => {
    return Utils.storage.saveLocalStorage<LocalSettings_i>(STORAGE_LOCAL_SETTINGS_KEY, localSettings);
    // return Utils.storage.saveSessionStorage<LocalSettings_i>(STORAGE_LOCAL_SETTINGS_KEY, localSettings);
  };
  const _clearSettings = () => {
    return Utils.storage.clearLocalStorage(STORAGE_LOCAL_SETTINGS_KEY);
    // return Utils.storage.clearSessionStorage(STORAGE_LOCAL_SETTINGS_KEY);
  };

  const defaultSettings = useMemo<LocalSettings_i>(() => {
    return { lang: CbtUserLanguage_e.EN, currency: DisplayCurrency_e.USD, time: 0 };
  }, []);

  const wrapper = {
    /**
     * 설정 정보
     */
    getSettings: () => {
      const org = _getSettings();
      return org ?? defaultSettings;
    },

    /**
     * 설정 정보
     */
    setSettings: (localSettgins: Partial<LocalSettings_i>) => {
      DBGMSG(`$$$$ Local Settings: ${JSON.stringify(localSettgins, undefined, 2)}`);
      const org = _getSettings();
      if (org) _saveSettings({ ...org, ...localSettgins, time: new Date().getTime() });
      else _saveSettings({ ...defaultSettings, ...localSettgins, time: new Date().getTime() });
    },

    /**
     * 설정 정보 유무 체크
     */
    isExistSettings: () => {
      if (_getSettings()) {
        return true;
      }
      return false;
    },

    // debug 용
    debugPrint: () => {
      DBGMSG(`$$$$ Local Settings: ${JSON.stringify(_getSettings(), undefined, 2)}`);
    },
  };

  return {
    ...wrapper,
  };
}
