/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CbtMarketSearchSort_e } from 'src/model/model';
import { CbtCurrency_e, CbtMarketSearchCurrencyFilter_t, CbtPgInfo_i } from 'src/model/rpcModel';
import { RPC_MP_SALE_LIST } from 'src/model/rpcType';

/****************************************/
// state
/****************************************/
export type MarketPlaceState_t = {
  needUpdate: boolean;
  querySearch: string;
  querySortOrder: CbtMarketSearchSort_e;
  queryColIdFilter?: number;
  queryCurrencyFilter: CbtMarketSearchCurrencyFilter_t;
  resultSale_list: RPC_MP_SALE_LIST.Rx['sale_list'];
  lastPgInfo?: CbtPgInfo_i;
};

export const MarketPlaceInitialState: MarketPlaceState_t = {
  needUpdate: true,
  querySearch: '',
  querySortOrder: CbtMarketSearchSort_e.ListingNewest,
  queryColIdFilter: undefined,
  queryCurrencyFilter: CbtCurrency_e.ALL,
  resultSale_list: [],
  lastPgInfo: undefined,
};

const marketPlaceSlice = createSlice({
  name: 'marketPlaceState',
  initialState: MarketPlaceInitialState,
  reducers: {
    clear: (state) => {
      state = { ...MarketPlaceInitialState };
      return state;
    },
    setState: (state, action: PayloadAction<MarketPlaceState_t>) => {
      state = { ...state, ...action.payload, needUpdate: false };
      return state;
    },
    changeState: (state, action: PayloadAction<Partial<MarketPlaceState_t>>) => {
      state = { ...state, ...action.payload, needUpdate: false };
      return state;
    },
  },
});

export default marketPlaceSlice;
