/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useColListState } from 'src/hooks/useColListState';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useKaikas } from 'src/hooks/useKaikas';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useMarketPlaceState } from 'src/hooks/useMarketPlaceState';
import { useRpc } from 'src/hooks/useRpc';
import { CbtMarketSearchSort_e, MarketAssetCard_i } from 'src/model/model';
import { CbtColCertifyType_e, CbtCurrency_e, CbtMarketSearchCurrencyFilter_t, CbtPgInfoSortOrder_e, CbtPgInfo_i } from 'src/model/rpcModel';
import { RPC_MP_SALE_LIST } from 'src/model/rpcType';
import { MarketPlaceInitialState } from 'src/redux/modules/marketPlaceStateModule';
import R from 'src/res/R';
import { devOutlineF, devOutlineFB, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { CollectionCombo } from 'src/ui/common_component/CollectionCombo';
import { CommonFooter, COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { CurrencyCombo } from 'src/ui/common_component/CurrencyCombo';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import {
  MarketAssetCardForList,
  MARKET_ASSET_CARD_CONTAINER_HEIGHT_FOR_LIST,
  MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST,
} from 'src/ui/common_component/MarketAssetCardForList';
import { MarketSearchInput } from 'src/ui/common_component/MarketSearchInput';
import { CLAY, COMMON_CARD_COLUMN_CNT, DEFAUL_PAGE_ITEM_CNT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! type
 **************************************/

/**************************************
 * !! constant
 **************************************/
const DT_SEARCH_MARGIN_BOTTOM = 20;
const DT_COLLECTION_COMBO_MARGIN_BOTTOM = 25;

const MB_SEARCH_MARGIN_BOTTOM = 12;
const MB_COLLECTION_COMBO_MARGIN_BOTTOM = 28;

const SEARCH_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_SEARCH_MARGIN_BOTTOM : DT_SEARCH_MARGIN_BOTTOM;
const COLLECTION_COMBO_MARGIN_BOTTOM = ScreenInfo.isMobile() ? MB_COLLECTION_COMBO_MARGIN_BOTTOM : DT_COLLECTION_COMBO_MARGIN_BOTTOM;

type Props = {};

//todo
export type MarketPlaceScreenQS = {
  cid?: number;
  cname?: string;
};

export function MarketPlaceScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sNextPageStatus, setNextPageStatus] = useState<string>('');
  const [sFirstPageLoading, setFirstPageLoading] = useState<boolean>();

  /**************************************
   * !! ref
   **************************************/
  const nextPageLoadingRef = useRef(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const lastPgInfoRef = useRef<CbtPgInfo_i>();
  const sale_listRef = useRef<RPC_MP_SALE_LIST.Rx['sale_list']>();

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`MarketPlace - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hMarketPlace = useMarketPlaceState();
  const hColListState = useColListState();
  const hLocation = useLocation();
  const hHistory = useHistory();
  const hR = useLocalSettings();
  const hDlgFactory = useDlgFactory();
  const hKaikas = useKaikas();
  const hAuth = useAuth();
  const hRpc = useRpc();

  /**************************************
   * !! animation
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  const initData = async () => {
    await fetchDefaultData();
    await fetchColList();
    hHistory.replace(Nav.MarketPlaceScreen); // query string을 변경함
  };

  useEffect(() => {
    DBGMSG('useEffect() init');

    // key 없이 접근
    if (hMarketPlace.state.needUpdate) {
      initData();
    } else {
      sale_listRef.current = hMarketPlace.state.resultSale_list;
      lastPgInfoRef.current = hMarketPlace.state.lastPgInfo;
    }

    return () => {
      DBGMSG('useEffect() release');
    };
  }, [hMarketPlace.state.needUpdate]);

  /**************************************
   * !! arrow function
   **************************************/

  const fetchColList = async () => {
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleCollectionCertlist({});
      hColListState.act.changeMarketPlaceState({
        col_list: res.col_list.filter((col) => col.cert_tp === CbtColCertifyType_e.OFFICIAL),
      });
    } catch (err) {}
  };

  // 첫로딩, 아무런 설정 없이 로딩
  const fetchDefaultData = async () => {
    setFirstPageLoading(true);
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleList({
        pg_info: { row_cnt: DEFAUL_PAGE_ITEM_CNT, cursor: null },
      });
      DBGMSG('hMarketPlace.act', 'setMarketPlaceState');
      hMarketPlace.act.setMarketPlaceState({
        ...MarketPlaceInitialState,
        resultSale_list: res.sale_list,
        lastPgInfo: res.pg_info,
        needUpdate: false,
      });
      sale_listRef.current = res.sale_list;
      lastPgInfoRef.current = res.pg_info;
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    } finally {
      setFirstPageLoading(false);
    }
  };

  const getSort = (querySortOrderBy: CbtMarketSearchSort_e | undefined) => {
    if (querySortOrderBy === undefined) {
      return undefined;
    }
    let by = '';
    let order: CbtPgInfoSortOrder_e;

    switch (querySortOrderBy) {
      case CbtMarketSearchSort_e.ListingNewest:
      case CbtMarketSearchSort_e.ListingOldest:
        by = 'list';
        break;
      case CbtMarketSearchSort_e.PriceHighest:
      case CbtMarketSearchSort_e.PriceLowest:
        by = 'price';
        break;
    }
    switch (querySortOrderBy) {
      case CbtMarketSearchSort_e.ListingNewest:
      case CbtMarketSearchSort_e.PriceHighest:
        order = CbtPgInfoSortOrder_e.DESC;
        break;
      case CbtMarketSearchSort_e.ListingOldest:
      case CbtMarketSearchSort_e.PriceLowest:
        order = CbtPgInfoSortOrder_e.ASC;
        break;
    }

    return {
      by,
      order,
    };
  };

  // 검색조건 설정후 첫페이지 로딩
  const fetchDataWithQuery = async ({
    querySearch,
    queryColIdFilter,
    queryCurrencyFilter,
    querySortOrder,
  }: {
    querySearch?: string;
    querySortOrder?: CbtMarketSearchSort_e;
    queryColIdFilter?: number;
    queryCurrencyFilter?: CbtMarketSearchCurrencyFilter_t;
  }) => {
    setFirstPageLoading(true);

    const search = querySearch;
    const cid = queryColIdFilter;
    const pg_info: CbtPgInfo_i = {
      cursor: null,
      row_cnt: 12,
      sort: getSort(querySortOrder),
    };
    const currency = queryCurrencyFilter === CbtCurrency_e.ALL ? undefined : queryCurrencyFilter;
    try {
      const res = await hRpc.cobaltRpc.rpcMpSaleList({ search, cid, currency, pg_info });
      DBGMSG('hMarketPlace.act', 'changeMarketPlaceState');
      hMarketPlace.act.changeMarketPlaceState({
        //
        ...MarketPlaceInitialState,
        querySortOrder,
        querySearch: search,
        queryColIdFilter: queryColIdFilter,
        queryCurrencyFilter: queryCurrencyFilter,
        resultSale_list: res.sale_list,
        lastPgInfo: res.pg_info,
        needUpdate: false,
      });
      sale_listRef.current = res.sale_list;
      lastPgInfoRef.current = res.pg_info;
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    } finally {
      setFirstPageLoading(false);
    }
  };

  const getLastRowId = () => {
    if (!lastPgInfoRef.current) return;
    if (lastPgInfoRef.current.last_row_id === -1 && lastPgInfoRef.current.cursor !== null) {
      DBGMSGW(`lastPgInfoRef.current.last_row_id === 1 && lastPgInfoRef.current.cursor !== null`);
    }
    return lastPgInfoRef.current.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  // 다음페이지 요청
  const fetchNextPage = async () => {
    if (!sale_listRef.current) {
      DBGMSGW(`hMarketPlace.state.lastPgInfo is undefined`);
      nextPageLoadingRef.current = false;
      return;
    }

    if (!lastPgInfoRef.current) {
      DBGMSGW(`hMarketPlace.state.lastPgInfo is undefined`);
      nextPageLoadingRef.current = false;
      return;
    }

    // cursor 가 있을경우 다른 조건은 무시됨 - https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699118
    if (lastPgInfoRef.current.cursor) {
      try {
        setNextPageStatus('loading');
        const res = await hRpc.cobaltRpc.rpcMpSaleList({
          pg_info: { ...lastPgInfoRef.current, cursor: lastPgInfoRef.current.cursor },
        });

        DBGMSG(`$$$$PAGE_LIST resultSale_list`, hMarketPlace.state.resultSale_list);
        DBGMSG(`$$$$PAGE_LIST res.sale_list`, res.sale_list);

        sale_listRef.current = [...sale_listRef.current, ...res.sale_list];

        DBGMSG('hMarketPlace.act', 'changeMarketPlaceState');
        hMarketPlace.act.changeMarketPlaceState({
          resultSale_list: sale_listRef.current,
          lastPgInfo: res.pg_info,
        });
        lastPgInfoRef.current = res.pg_info;
        setNextPageStatus('');
        nextPageLoadingRef.current = false;
      } catch (err) {
        setNextPageStatus('loading fail');
        nextPageLoadingRef.current = false;
      }
    } else {
      nextPageLoadingRef.current = false;
    }
  };

  const fethcBySearchTxt = async ({ search }: { search?: string }) => {
    DBGMSG(hMarketPlace.state);
    await fetchDataWithQuery({ ...hMarketPlace.state, querySearch: search });
  };

  const fetchByCurrencyFilter = async ({ currency }: { currency?: CbtMarketSearchCurrencyFilter_t }) => {
    await fetchDataWithQuery({ ...hMarketPlace.state, queryCurrencyFilter: currency });
  };

  const fetchByColIdFilter = async ({ queryColIdFilter }: { queryColIdFilter?: number }) => {
    await fetchDataWithQuery({ ...hMarketPlace.state, queryColIdFilter: queryColIdFilter });
  };

  const fetchBySort = async ({ sort }: { sort: CbtMarketSearchSort_e }) => {
    await fetchDataWithQuery({ ...hMarketPlace.state, querySortOrder: sort });
  };

  // 스크롤 리스너
  const scrollListener = (e: ScrollParams) => {
    // DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (hMarketPlace.state.needUpdate === true) {
      DBGMSG('$$$$PAGE_SCROLL', 'isFirstCall is true');
      return;
    }

    if (hMarketPlace.state.lastPgInfo === undefined) {
      DBGMSG('$$$$PAGE_SCROLL', 'lastPgInfo is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    // DBGMSG(`clientHeight: ${clientHeight}, scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}`);

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;
    // DBGMSG('$$$$PAGE_SCROLL', `clientHeight: ${clientHeight}, scrollHeight: ${scrollHeight}, scrollTop: ${scrollTop}`);
    // DBGMSG('$$$$PAGE_SCROLL', `${scrollTop + clientHeight} >= ${scrollHeight - COMMON_FOONTER_HEIGHT}`);
    // DBGMSG('$$$$PAGE_SCROLL', `atBottom: ${atBottom} `);

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return nextPageLoadingRef.current is true `);
      return;
    }

    if (atBottom) {
      nextPageLoadingRef.current = true;
      fetchNextPage()
        .then(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage then');
        })
        .catch(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchAstListDataNextPage catch');
        });
    }
  };

  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  /**************************************
   * !! render function
   **************************************/
  // 에셋 카드 렌더링
  const cellRenderer = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    const idx = rowIndex * COMMON_CARD_COLUMN_CNT + columnIndex;
    if (idx >= hMarketPlace.state.resultSale_list.length) return null;

    const data = hMarketPlace.state.resultSale_list[idx];
    const item: MarketAssetCard_i = {
      sale_id: data.sale_id,
      asset_id: data.ast_info.asset_id,
      asset_type: Utils.svc.getAssetType({ nft_img: data.ast_info.nft_img, nft_aud: data.ast_info.nft_aud, nft_vid: data.ast_info.nft_vid }),
      nft_name: data.ast_info.nft_name,
      col_info: {
        cid: data.ast_info.col_info.cid,
        cert_tp: data.ast_info.col_info.cert_tp,
        dp_name: data.ast_info.col_info.dp_name,
      },
      nft_img: data.ast_info.nft_img,
      favorite: data.favorite,
      owner: data.ast_info.owner,
      prc_info: data.prc_info,
    };

    // DBGMSG(`typeof item: ${typeof item}`);
    const zIndex = hMarketPlace.state.resultSale_list.length - idx;

    return (
      // 중요!
      <div
        key={key}
        style={{
          ...style,
        }}
      >
        <MobileScalableFlexColumn>
          <MarketAssetCardForList
            idx={zIndex}
            data={item}
            isAnim
            onClick={() => {
              DBGMSG('AssetCardForList onClick!!');
              const sale_id = item.sale_id;
              hHistory.push(Nav.brewMarketAssetRScreenPath({ sale_id }));
            }}
            onBuyClick={() => {}}
            onBuySuccess={() => {
              DBGMSG('onBuySuccess');
              fetchDefaultData();
            }}
          />
        </MobileScalableFlexColumn>
      </div>
    );
  };

  // Asset 목록 렌더링
  const renderAssetList = () => {
    DBGMSG('renderList');
    return (
      <FlexColumnDiv
        style={{
          width: MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT,
          alignSelf: 'center',
          userSelect: 'none',
          // zIndex: 0,
          // ...devOutlineFB,
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <FlexColumnDiv>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <Grid
                      onScroll={(params) => {
                        onChildScroll(params);
                        throttleScrollListener(params);
                      }}
                      isScrolling={isScrolling}
                      autoHeight
                      cellRenderer={cellRenderer}
                      columnWidth={MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale}
                      columnCount={COMMON_CARD_COLUMN_CNT}
                      height={height}
                      rowHeight={MARKET_ASSET_CARD_CONTAINER_HEIGHT_FOR_LIST * ScreenInfo.scale}
                      rowCount={Math.ceil(hMarketPlace.state.resultSale_list.length / COMMON_CARD_COLUMN_CNT)}
                      // rowCount={1}
                      // scrollToColumn={scrollToColumn}
                      // scrollToRow={scrollToRow}
                      scrollTop={scrollTop}
                      width={MARKET_ASSET_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT}
                    />
                  </div>
                )}
              </AutoSizer>
            </FlexColumnDiv>
          )}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  const rednerMarketAssetList = () => {
    if (sFirstPageLoading) {
      return <DataFetching />;
    } else if (sFirstPageLoading === undefined) {
      if (hMarketPlace.state.resultSale_list.length !== 0) {
        return renderAssetList();
      }
      return (
        <FlexColumnDiv style={{ minHeight: 350, justifyContent: 'center', alignItems: 'center' }}>
          <TextCon
            text={`\'${hMarketPlace.state.querySearch}\' ...`}
            isBold
            color={R.colors.blueGrey}
            textStyle={{ textAlign: 'center' }}
            size={CLAY.DEFAULT_FONT_SIZE}
          />
        </FlexColumnDiv>
      );
    } else {
      if (
        hMarketPlace.state.resultSale_list.length === 0
        //  &&
        //  hMarketPlace.state.querySearch.length !== 0
      ) {
        return (
          <FlexColumnDiv
            style={{
              // ...devOutlineF,
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 300,
            }}
          >
            <TextCon
              text={hR.strings.MY_MENU_SEARCH_NOT_FOUND}
              isBold
              color={R.colors.blueGrey}
              textStyle={{ textAlign: 'center' }}
              size={CLAY.DEFAULT_FONT_SIZE}
            />
          </FlexColumnDiv>
        );
      } else if (hMarketPlace.state.resultSale_list.length === 0) {
        return (
          <FlexColumnDiv>
            <TextCon text={``} isBold color={R.colors.blueGrey} textStyle={{ textAlign: 'center' }} size={CLAY.DEFAULT_FONT_SIZE} />
          </FlexColumnDiv>
        );
      } else {
        return renderAssetList();
      }
    }
  };

  /**************************************
   * !! render conf
   **************************************/

  DBGMSG('render');
  return (
    <>
      <MobileScalableFlexColumn key={hMarketPlace.state.resultSale_list.length}>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          {/* 검색 */}
          <MarketSearchInput
            inputText={hMarketPlace.state.querySearch}
            inputSort={hMarketPlace.state.querySortOrder}
            getRef={(ref) => {
              searchInputRef.current = ref;
            }}
            onDebouncedChange={async (txt) => {
              DBGMSG(`onDebouncedChange Search!: ${txt}`);
              await fethcBySearchTxt({ search: txt });
              searchInputRef.current && searchInputRef.current.focus();
            }}
            onSort={(sort) => {
              DBGMSG(`onSort Search!: ${sort}`);
              DBGMSG('hMarketPlace.act', 'changeMarketPlaceState');
              hMarketPlace.act.changeMarketPlaceState({ querySortOrder: sort });
              fetchBySort({ sort });
            }}
            onSearch={async () => {
              DBGMSG(`onSearch Search!`);
              await fethcBySearchTxt({ search: hMarketPlace.state.querySearch });
              searchInputRef.current && searchInputRef.current.focus();
            }}
            onChange={(e) => {
              if (hMarketPlace.state.resultSale_list.length > 0) {
                setFirstPageLoading(undefined);
              }
              // 검색어가 변경되면 바로 state를 변경
              DBGMSG('hMarketPlace.act', 'changeMarketPlaceState');
              hMarketPlace.act.changeMarketPlaceState({ querySearch: e.target.value });
            }}
          />
          <HEIGHT size={SEARCH_MARGIN_BOTTOM} />
          <FlexRowDiv>
            {/* 컬렉션 */}
            {hColListState.state.col_list && (
              <>
                <CollectionCombo
                  // todo
                  enable={hColListState.state.col_list.length !== 0}
                  col_list={hColListState.state.col_list}
                  currentColId={hMarketPlace.state.queryColIdFilter}
                  onSelect={async (filter) => {
                    DBGMSG(`currencyFilter onSelect Search: ${filter}`);
                    await fetchByColIdFilter({ queryColIdFilter: filter });
                  }}
                />
                <WIDTH size={8} />
              </>
            )}
            {/* 판매 통화 */}
            <CurrencyCombo
              enable={sFirstPageLoading !== true}
              currencyFilter={hMarketPlace.state.queryCurrencyFilter}
              onSelect={async (filter) => {
                DBGMSG(`currencyFilter onSelect Search: ${filter}`);
                await fetchByCurrencyFilter({ currency: filter });
              }}
            />
          </FlexRowDiv>
        </FlexColumnDiv>
      </MobileScalableFlexColumn>
      <MobileScalableFlexColumn>
        <HEIGHT size={COLLECTION_COMBO_MARGIN_BOTTOM} />
      </MobileScalableFlexColumn>

      {/* <HEIGHT size={300} /> */}
      {rednerMarketAssetList()}

      {ScreenInfo.isMobile() && (
        <MobileScalableFlexColumn>
          <CommonFooter />
        </MobileScalableFlexColumn>
      )}
    </>
  );
}
