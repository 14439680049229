/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 * redux module
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CbtReportReason_e } from 'src/model/rpcModel';
import { DBGMSGW } from 'src/util/utils';

// jsxMulit 다이얼로그
export interface DlgStateJsxMulti_i {
  key?: string;
  showHideTrigger: boolean;
  onBackdropClick?: () => void;
  jsx: JSX.Element;
  paddingTop?: number;
  bgTransparent?: boolean;
}

/****************************************/
// state
/****************************************/
type DlgState = {
  isScrollLock: boolean;
  dlgJsxMultiList: DlgStateJsxMulti_i[];
};

const initialState: DlgState = {
  isScrollLock: false,
  dlgJsxMultiList: [],
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialState,
  reducers: {
    /***************************
     *
     **************************/

    clear: (state) => {
      state = { ...initialState };
      return state;
    },

    showJsxMulti: (state, action: PayloadAction<Omit<DlgStateJsxMulti_i, 'showHideTrigger'>>) => {
      state.dlgJsxMultiList.push({ showHideTrigger: true, ...action.payload });
      state.isScrollLock = true;
      return state;
    },

    closeJsxMultiLast: (state) => {
      state.dlgJsxMultiList.pop();
      if (state.dlgJsxMultiList.length === 0) {
        state.isScrollLock = false;
      }
      return state;
    },

    hideJsxMultiLast: (state) => {
      if (state.dlgJsxMultiList.length > 0) {
        const lastIdx = state.dlgJsxMultiList.length - 1;
        state.dlgJsxMultiList[lastIdx].showHideTrigger = false;
      }

      return state;
    },

    hideJsxMultiByKey: (state, action: PayloadAction<{ key: string }>) => {
      if (state.dlgJsxMultiList.length > 0) {
        const foundIdx = state.dlgJsxMultiList.findIndex((dlg) => dlg.key === action.payload.key);
        if (foundIdx === -1) {
          DBGMSGW(`key:${action.payload.key} not found`);
        } else {
          state.dlgJsxMultiList[foundIdx].showHideTrigger = false;
        }
      }

      return state;
    },

    closeJsxMultiByKey: (state, action: PayloadAction<{ key: string }>) => {
      if (state.dlgJsxMultiList.length > 0) {
        const foundIdx = state.dlgJsxMultiList.findIndex((dlg) => dlg.key === action.payload.key);
        if (foundIdx === -1) {
          DBGMSGW(`key:${action.payload.key} not found`);
        } else {
          state.dlgJsxMultiList = state.dlgJsxMultiList.filter((dlg) => dlg.key !== action.payload.key);
        }
      }

      if (state.dlgJsxMultiList.length === 0) {
        state.isScrollLock = false;
      }

      return state;
    },
  },
});

export default dialogSlice;
