/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ScreenInfo, { MOBILE_WIDTH } from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { ClientType_e } from 'src/hooks/useScreenWidthHelper';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT, HLINE, WIDTH } from 'src/ui/base_component/etc';
import { Img } from 'src/ui/base_component/Img';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import TextCon from 'src/ui/base_component/TextCon';
import { RoundTxtBtn4 } from 'src/ui/common_component/button/RoundTxtBtn4';
import { COMMONHEADER_LAYOUT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';

const DT_COMMON_FOONTER_HEIGHT = 276;
const MB_COMMON_FOONTER_HEIGHT = 266;
export const COMMON_FOONTER_HEIGHT = ScreenInfo.isMobile() ? MB_COMMON_FOONTER_HEIGHT : DT_COMMON_FOONTER_HEIGHT;

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  applyScaleManually?: boolean;
};

export function CommonFooter({ applyScaleManually = false }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sEmailMouseEnter, setEmailMouseEnter] = useState(false);

  /**************************************
   * !! ref
   **************************************/
  const labGoCntRef = useRef(0);

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hHistory = useHistory();
  const hDlgFactory = useDlgFactory();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    return () => {};
  }, []);

  /**************************************
   * !! arrow function
   **************************************/
  const handleInqueryBtn = () => {
    hDlgFactory.showInquery({
      onSuccess: () => {
        hDlgFactory.hideInquery();
        hDlgFactory.showSimpleOk({ text: hR.strings.INQUERY_SUCCESS });
      },
      onClose: () => {
        hDlgFactory.hideInquery();
      },
    });
  };

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  if (ScreenInfo.clientType === ClientType_e.Desktop || ScreenInfo.clientType === ClientType_e.Tablet) {
    return (
      <FlexColumnDiv style={{ width: COMMONHEADER_LAYOUT.width, height: COMMON_FOONTER_HEIGHT }}>
        <HEIGHT size={39} />
        <FlexColumnDiv
          style={{
            width: COMMONHEADER_LAYOUT.width,
            // ...devOutlineF,
          }}
        >
          <FlexRowDiv
            style={{
              alignSelf: 'stretch',
              //  ...devOutlineFB
            }}
          >
            <ImgBtn
              src={[R.images.gnb_logo3x, 132, 40]}
              onClick={() => {
                if (labGoCntRef.current > 4) {
                  hHistory.push(Nav.LabScreen);
                  labGoCntRef.current = 0;
                } else {
                  labGoCntRef.current = labGoCntRef.current + 1;
                }
              }}
            />
            <RoundTxtBtn4
              text={hR.strings.FOOTER_INQUERY}
              height={40}
              width={145}
              fontSize={12}
              onClick={(e) => {
                handleInqueryBtn();
              }}
              containerStyle={{ marginLeft: 'auto' }}
            />
          </FlexRowDiv>
          <HEIGHT size={20} />
          <FlexRowDiv style={{}}>
            <>
              <TextCon
                text={hR.strings.FOOTER_TERMS}
                color={R.colors.blueGrey}
                size={12}
                isBold
                containerStyle={{ paddingRight: 15 }}
                onConClick={() => {
                  hHistory.push(Nav.UserTermsOfUseScreen);
                }}
              />
              <TextCon text={'|'} color={R.colors.blueGrey} size={12} isBold />
              <TextCon
                text={hR.strings.FOOTER_PRIVACY}
                color={R.colors.blueGrey}
                size={12}
                isBold
                containerStyle={{ paddingRight: 15, paddingLeft: 15 }}
                onConClick={() => {
                  hHistory.push(Nav.UserPrivacyPolicyScreen);
                }}
              />
              <TextCon text={'|'} color={R.colors.blueGrey} size={12} isBold />
              <TextCon
                text={hR.strings.FOOTER_REFUND_POLICY}
                color={R.colors.blueGrey}
                size={12}
                isBold
                containerStyle={{ paddingRight: 15, paddingLeft: 15 }}
                onConClick={() => {
                  hHistory.push(Nav.UserRefundPolicyScreen);
                }}
              />
            </>
            {/* <TextCon text={'|'} color={R.colors.blueGrey} size={12} isBold />
            <TextCon
              text={hR.strings.FOOTER_RAW1}
              color={R.colors.blueGrey}
              size={12}
              isBold
              containerStyle={{ paddingRight: 15, paddingLeft: 15 }}
              onConClick={() => {}}
            />
            <TextCon text={'|'} color={R.colors.blueGrey} size={12} isBold />
            <TextCon
              text={hR.strings.FOOTER_RAW2}
              color={R.colors.blueGrey}
              size={12}
              isBold
              containerStyle={{ paddingRight: 15, paddingLeft: 15 }}
              onConClick={() => {}}
            /> */}
          </FlexRowDiv>
          <HEIGHT size={12} />
          <FlexRowDiv>
            <TextCon text={`${hR.strings.FOOTER_SUPPORT_EMAIL}`} color={R.colors.blueGrey} size={12} />
            <WIDTH size={5} />
            <TextCon
              text={`${hR.strings.FOOTER_SUPPORT_EMAIL_ADDRESS}`}
              color={sEmailMouseEnter ? R.colors.purpleishBlue : R.colors.blueGrey}
              isUnderline={sEmailMouseEnter}
              size={12}
              onConClick={() => {
                window.location.href = `mailto:${Constants.SUPPORT_EMAIL_ADDRESS}`;
              }}
              onConMouseEnter={() => {
                setEmailMouseEnter(true);
              }}
              onConMouseLeave={() => {
                setEmailMouseEnter(false);
              }}
            />
          </FlexRowDiv>
          <HEIGHT size={50} />
          <HLINE size={'100%'} />
          <HEIGHT size={20} />
          <TextCon text={hR.strings.FOOTER_COPYRIGHT} color={R.colors.blueGrey} size={12} />
        </FlexColumnDiv>
      </FlexColumnDiv>
    );
  } else {
    return (
      <FlexColumnDiv
        style={{
          width: MOBILE_WIDTH,
          transformOrigin: (function () {
            if (applyScaleManually) {
              return '0% 0%';
            } else {
              return 'none';
            }
          })(),
          transform: (function () {
            if (applyScaleManually) {
              return `scale(${ScreenInfo.scale})`;
            } else {
              return 'none';
            }
          })(),
        }}
      >
        <HLINE size={`100%`} />
        <FlexRowDiv
          style={{
            backgroundColor: R.colors.grayBox,
            width: MOBILE_WIDTH,
            // height: 266,
            // ...devOutlineFB,
          }}
        >
          <FlexColumnDiv
            style={{
              padding: 20,
              width: MOBILE_WIDTH,
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              // ...devOutlineFB
            }}
          >
            <FlexRowDiv style={{ alignSelf: 'stretch' }}>
              <Img src={[R.images.gnb_logo3x, 92, 28]} />
              <RoundTxtBtn4
                text={hR.strings.FOOTER_INQUERY}
                height={28}
                width={97}
                fontSize={10}
                onClick={(e) => {
                  handleInqueryBtn();
                }}
                containerStyle={{ marginLeft: 'auto' }}
              />
            </FlexRowDiv>
            <HEIGHT size={16} />
            <>
              <TextCon
                text={hR.strings.FOOTER_TERMS}
                color={R.colors.blueGrey}
                size={8}
                isBold
                onConClick={() => {
                  hHistory.push(Nav.UserTermsOfUseScreen);
                }}
              />
              <HEIGHT size={8} />
              <TextCon
                text={hR.strings.FOOTER_PRIVACY}
                color={R.colors.blueGrey}
                size={8}
                isBold
                onConClick={() => {
                  hHistory.push(Nav.UserPrivacyPolicyScreen);
                }}
              />
              <HEIGHT size={8} />
              <TextCon
                text={hR.strings.FOOTER_REFUND_POLICY}
                color={R.colors.blueGrey}
                size={8}
                isBold
                onConClick={() => {
                  hHistory.push(Nav.UserRefundPolicyScreen);
                }}
              />
            </>

            <HEIGHT size={12} />
            <FlexRowDiv>
              <TextCon text={hR.strings.FOOTER_SUPPORT_EMAIL} color={R.colors.blueGrey} size={8} />
              <WIDTH size={6} />
              <TextCon
                text={hR.strings.FOOTER_SUPPORT_EMAIL_ADDRESS}
                color={R.colors.blueGrey}
                size={8}
                onConClick={() => {
                  window.location.href = `mailto:${Constants.SUPPORT_EMAIL_ADDRESS}`;
                }}
              />
            </FlexRowDiv>
            <HEIGHT size={20} />
            <HLINE size={'100%'} />
            <HEIGHT size={12} />
            <TextCon text={hR.strings.FOOTER_COPYRIGHT} color={R.colors.blueGrey} size={8} />
            <HEIGHT size={20} />
          </FlexColumnDiv>
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  }
}
