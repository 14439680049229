/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AppConf, isProd } from 'src/conf/AppConf';
import ScreenInfo, { DESKTOP_WIDTH } from 'src/context/screen_constants';
import { useAuth } from 'src/hooks/useAuth';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useFileCheck } from 'src/hooks/useFileCheck';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import { useRpc } from 'src/hooks/useRpc';
import { ServerECode_e } from 'src/model/rpcModel';
import Constants from 'src/res/constants';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv, FlexRowDiv, HEIGHT, WIDTH } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';
import { BackBtn } from 'src/ui/common_component/button/BackBtn';
import { RoundTxtBtn } from 'src/ui/common_component/button/RoundTxtBtn';
import { SCollectionImgSettingsBtn } from 'src/ui/common_component/button/SCollectionImgSettingsBtn';
import { CommonFooter } from 'src/ui/common_component/CommonFooter';
import { SRoundInputText } from 'src/ui/common_component/SRoundInputText';
import { SRoundInputTextArea } from 'src/ui/common_component/SRoundInputTextArea';
import { SRoundInputWithBtn } from 'src/ui/common_component/SRoundInputWithBtn';
import { Title } from 'src/ui/common_component/Title';
import { CLAY, CommonColumnBox } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { fileUpload, getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { useCheckUserCertTpAndBlock } from 'src/hooks/useCheckUserCertTpAndBlock';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

export class MYCOLC_LAY {
  static readonly BOX_WIDTH = ScreenInfo.isMobile() ? 280 : DESKTOP_WIDTH;
  static readonly COLUMN_WIDTH = ScreenInfo.isMobile() ? DESKTOP_WIDTH : DESKTOP_WIDTH / 2;
  static readonly COL_IMG_VPADDING = ScreenInfo.isMobile() ? 34 : 40;
  static readonly COL_IMG_SIZE = ScreenInfo.isMobile() ? 162 : 160;
  static readonly BOTTOM_MARGIN = ScreenInfo.isMobile() ? 60 : 120;
}

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {};

export function MyCollectionCScreen(props: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sCollectionImg, setCollectionImg] = useState<{ src: string; uploadedHash: string }>(); // 컬렉션 이미지
  const [sCollectionName, setCollectionName] = useState<string>(''); // 컬렉션 이름
  const [sCollectionNameValidation, setCollectionNameValidation] = useState<{
    isDupError: boolean; // undefined: 체크전, true: 중복 에러일경우 , false: 중복 에러 아닐경우
  }>({ isDupError: false });
  const [sDisplayName, setDisplayName] = useState<string>(''); // 디스플레이 이름
  const [sRoyalty, setRoyalty] = useState<string>(''); // 인세
  const [sWebsiteUrl, setWebsiteUrl] = useState<string>(''); // 웹사이트 URL
  const [sDescription, setDescription] = useState<string>(''); // 설명
  const [sAdminList, setAdminList] = useState<string[]>([]); // 관리자 계정

  const [sCreateBtnEnabled, setCreateBtnEnabled] = useState(false);

  const [sShowColImgTooltip, setShowColImgTooltip] = useState(false);
  const [sInvalidAdminList, setInvalidAdminList] = useState<string[]>([]);

  /**************************************
   * !! ref
   **************************************/
  const collectionImgInputRef = useRef<HTMLInputElement>(null);
  const collectionNameInputRef = useRef<HTMLInputElement | null>(null);

  /**************************************
   * !! hooks
   **************************************/
  useTitle(`AssetCreator - ${AppConf.APPNAME} ${isProd() ? '' : AppConf.PHASE}`);
  const hHistory = useHistory();
  const hAuth = useAuth();
  const hR = useLocalSettings();
  const hRpc = useRpc();
  const hDlgFactory = useDlgFactory();
  const hFileChecker = useFileCheck();
  const hStorage = useLocalStorage();
  // useCheckUserCertTpAndBlock();

  /**************************************
   * !! animation
   **************************************/
  // const [borderAnimProps, setBorderAnimProps] = useSpring(() => ({ borderColor: R.colors.rn.black6 }));

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    DBGMSG('useEffect() init');

    return () => {
      DBGMSG('useEffect() release');
    };
  }, []);

  useEffect(() => {
    DBGMSG(sInvalidAdminList);

    // create 버튼 활성화 체크
    if (
      sCollectionName.length > 0 && // 컬렉션 이름
      sDisplayName.length > 0 && // 컬렉션 디스플레이 이름
      sRoyalty.length > 0 && // 인세
      sCollectionImg !== undefined && // 컬렉션 이미지
      sInvalidAdminList.length === 0
      //
    ) {
      setCreateBtnEnabled(true);
    } else {
      setCreateBtnEnabled(false);
    }
  }, [sCollectionName, sDisplayName, sRoyalty, sCollectionImg, sInvalidAdminList]);

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  const commonRender = () => {
    return (
      <>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: ScreenInfo.contentsWidth,
            alignItems: 'flex-start',
            paddingTop: CLAY.TITLE_PADDING_TOP,
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
            ...devOutline,
          }}
        >
          {/* Back 버튼 */}
          <BackBtn dest={Nav.AssetCreatorScreen} />

          {/* 타이틀 */}
          <Title title={hR.strings.AC_COLLECTION_CREATE_TITLE} />

          <HEIGHT size={CLAY.TITLE_BOTTOM_MARGIN} />

          {/* 박스 */}
          <CommonColumnBox style={{ width: MYCOLC_LAY.BOX_WIDTH, alignItems: 'flex-start' }}>
            <HEIGHT size={MYCOLC_LAY.COL_IMG_VPADDING} />

            {/* 컬렉션 이미지 */}
            <FlexColumnDiv
              style={{
                alignSelf: 'center',
                // ...devOutlineF,
              }}
            >
              <FlexRowDiv>
                <TextCon size={14} color={R.colors.black} isBold text={hR.strings.AC_COLLECTION_CREATE_IMAGE} />
                <TextCon size={14} color={R.colors.purpleishPink} text={'*'} />
                <WIDTH size={3} />
                <TextCon
                  size={14}
                  color={sShowColImgTooltip ? R.colors.purpleishBlue : R.colors.cloudyBlue}
                  text={hR.strings.AC_COLLECTION_CREATE_IMAGE_UPLOAD_SPEC}
                />
                <WIDTH size={3} />
                <TooltipImg
                  onShow={(isShow) => {
                    setShowColImgTooltip(isShow);
                  }}
                  placement={'right-start'}
                  tooltipString={hR.strings.AC_COLLECTION_CREATE_COL_IMG_TOOLTIP}
                />
              </FlexRowDiv>
              <HEIGHT size={24} />
              <SCollectionImgSettingsBtn
                size={MYCOLC_LAY.COL_IMG_SIZE}
                img={sCollectionImg?.src}
                onClick={(e) => {
                  DBGMSG(collectionImgInputRef.current);
                  // collectionImgInputRef.current?.click();
                  collectionImgInputRef.current?.click();
                }}
              />
              <input
                ref={collectionImgInputRef}
                type={'file'}
                style={{ display: 'none' }}
                accept={Constants.NFT_IMG_ACCEPT_FILETYPE.map((ext) => '.' + ext).join(', ')}
                onChange={async (e) => {
                  const { localUrl, fileObj, type } = await Utils.file.loadInputFile(e);
                  DBGMSG(`$$$$INPUT  localUrl: ${localUrl}`);
                  DBGMSG(`$$$$INPUT file.name: ${fileObj.name}`);
                  DBGMSG(`$$$$INPUT file.size: ${fileObj.size.toLocaleString()}`);
                  DBGMSG(`$$$$INPUT file.type: ${fileObj.type}`);
                  DBGMSG(`$$$$INPUT      type: ${type}`);

                  const result = await hFileChecker.checkFileSpec({
                    target: { localUrl, fileObj, fileType: type },
                    spec: { imgWidth: 200, imgHeight: 200, sizeInMB: 2, fileTypeList: ['png', 'jpg', 'gif', 'apng'] },
                  });

                  if (result) {
                    fileUpload(fileObj)
                      .then((res) => {
                        DBGMSG(res);
                        setCollectionImg({ src: localUrl, uploadedHash: res });
                      })
                      .catch((e) => {
                        DBGMSGW(e);
                      });
                  } else {
                    if (collectionImgInputRef.current) collectionImgInputRef.current.value = '';
                  }
                }}
              />
            </FlexColumnDiv>

            <HEIGHT size={MYCOLC_LAY.COL_IMG_VPADDING} />

            <FlexRowDiv style={{ width: MYCOLC_LAY.BOX_WIDTH, flexWrap: 'wrap' }}>
              <FlexColumnDiv
                style={{
                  width: MYCOLC_LAY.COLUMN_WIDTH,
                  paddingLeft: CLAY.BOX_PADDING,
                  paddingRight: CLAY.BOX_PADDING,
                  // ...devOutlineF,
                }}
              >
                {/* 컬렉션 이름 */}
                <FlexDiv style={{ flexDirection: ScreenInfo.isMobile() ? 'column' : 'row' }}>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_NAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <FlexRowDiv>
                    <TextCon text={hR.strings.AC_COLLECTION_CREATE_NAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                    <WIDTH size={3} />
                    <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                  </FlexRowDiv>
                </FlexDiv>

                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  getRef={(ele) => {
                    collectionNameInputRef.current = ele;
                  }}
                  value={sCollectionName}
                  placeHolder={hR.strings.AC_COLLECTION_CREATE_CANNOT_EDIT}
                  onBlur={() => {
                    setCollectionName((prev) => prev.trimEnd());
                  }}
                  onChange={(e) => {
                    if (e.target.value.length > 20) {
                      return;
                    }

                    const regex = Constants.REGEXP_IS_ALPHA_NUM_SPACE;

                    if (regex.test(e.target.value)) {
                      setCollectionName(e.target.value);
                      setCollectionNameValidation({ isDupError: false });
                    }
                  }}
                />
                {sCollectionNameValidation.isDupError && (
                  <>
                    <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                    <TextCon
                      text={hR.strings.AC_COLLECTION_CREATE_NAME_DUPLICATED}
                      size={CLAY.FORM_CHK_MSG_FONT_SIZE}
                      color={R.colors.pastelRed}
                      containerStyle={{ alignSelf: 'flex-start' }}
                    />
                  </>
                )}
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 디스플레이 이름 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_DISPNAME} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_DISPNAME_GUIDE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                  <WIDTH size={5} />
                  <TooltipImg placement={'right-start'} tooltipString={hR.strings.AC_COLLECTION_CREATE_TOOLTIP_DISPNAME} />
                </FlexRowDiv>

                <HEIGHT size={14} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sDisplayName}
                  onBlur={() => {
                    setDisplayName((prev) => prev.trimEnd());
                  }}
                  onChange={(e) => {
                    if (e.target.value.length <= 20) {
                      setDisplayName(e.target.value);
                    }
                  }}
                />
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 인세 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_ROYALTY} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                  <TextCon
                    text={`${Utils.string.parsePlaceholder(hR.strings.AC_COLLECTION_CREATE_ROYALTY_GUIDE, '{MAX}').begin}${
                      hStorage.getData().max_royalty
                    }${Utils.string.parsePlaceholder(hR.strings.AC_COLLECTION_CREATE_ROYALTY_GUIDE, '{MAX}').end}`}
                    size={CLAY.FORM_FONT_SIZE}
                    color={R.colors.black}
                  />
                  <WIDTH size={3} />
                  <TextCon text={'*'} size={CLAY.FORM_FONT_SIZE} color={R.colors.purpleishPink} />
                  <WIDTH size={5} />
                  <TooltipImg placement={'right-start'} tooltipString={hR.strings.AC_COLLECTION_CREATE_TOOLTIP_ROYALTY} />
                </FlexRowDiv>

                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sRoyalty}
                  placeHolder={hR.strings.AC_COLLECTION_CREATE_CANNOT_EDIT}
                  onChange={(e) => {
                    const regex = Constants.REGEXP_IS_NUM_WITHIN_0_TO_10;
                    if (regex.test(e.target.value)) {
                      setRoyalty(e.target.value);
                    }
                  }}
                />
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 웹 사이트 URL */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_WEBSITE} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                </FlexRowDiv>

                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputText
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  value={sWebsiteUrl}
                  onChange={(e) => {
                    if (e.target.value.length > 100) return;
                    setWebsiteUrl(e.target.value);
                  }}
                />
              </FlexColumnDiv>

              <FlexColumnDiv
                style={{
                  width: MYCOLC_LAY.COLUMN_WIDTH,
                  paddingLeft: CLAY.BOX_PADDING,
                  paddingRight: CLAY.BOX_PADDING,
                  // ...devOutlineF,
                }}
              >
                {ScreenInfo.isMobile() && <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />}

                {/* 설명 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_DESC} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                </FlexRowDiv>
                <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                <SRoundInputTextArea
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  value={sDescription}
                  minHeight={110}
                  onChange={(e) => {
                    DBGMSG(e.target.value);
                    setDescription(e.target.value);
                  }}
                />
                <HEIGHT size={CLAY.FORM_BETWEEN_SPACE} />

                {/* 관리자 설정 */}
                <FlexRowDiv>
                  <TextCon text={hR.strings.AC_COLLECTION_CREATE_ADMIN} size={CLAY.FORM_FONT_SIZE} color={R.colors.black} isBold />
                </FlexRowDiv>
                <HEIGHT size={14} />
                <SRoundInputWithBtn
                  fontSize={CLAY.FORM_FONT_SIZE}
                  width={CLAY.FORM_SIZE.WIDTH}
                  height={CLAY.FORM_SIZE.HEIGHT}
                  defaultValue={hAuth.authState.authCommonRes?.ucode}
                  btnType={'+'}
                  onBtnClick={() => {
                    setAdminList([...sAdminList, '']);
                  }}
                  disabled={true}
                />
                {sAdminList.map((admin, idx) => {
                  return (
                    <>
                      <HEIGHT size={10} />
                      <SRoundInputWithBtn
                        fontSize={CLAY.FORM_FONT_SIZE}
                        width={CLAY.FORM_SIZE.WIDTH}
                        height={CLAY.FORM_SIZE.HEIGHT}
                        btnType={'-'}
                        defaultValue={sAdminList[idx]}
                        onBtnClick={() => {
                          DBGMSG(`idx:${idx}`);
                          DBGMSG([...sAdminList.slice(0, idx)]);
                          DBGMSG([...sAdminList.slice(idx + 1)]);
                          setAdminList([...sAdminList.slice(0, idx), ...sAdminList.slice(idx + 1)]);
                          setInvalidAdminList([]);
                        }}
                        onChange={(e) => {
                          setAdminList([...sAdminList.slice(0, idx), e.target.value, ...sAdminList.slice(idx + 1)]);
                          // setCollectionName(e.target.value);
                          setInvalidAdminList([]);
                        }}
                      />
                      {sInvalidAdminList.length > 0 && sInvalidAdminList.includes(admin) && (
                        <>
                          <HEIGHT size={CLAY.FORM_CHK_MSG_TOP_MARGIN} />
                          <TextCon
                            text={hR.strings.AC_COLLECTION_CREATE_ADMIN_INVALID}
                            size={CLAY.FORM_CHK_MSG_FONT_SIZE}
                            color={R.colors.pastelRed}
                            containerStyle={{ alignSelf: 'flex-start' }}
                          />
                        </>
                      )}
                    </>
                  );
                })}
              </FlexColumnDiv>
            </FlexRowDiv>

            <HEIGHT size={CLAY.TEXT_BTN_VMARGIN_IN_BOX} />

            {/* Collection 생성 버튼 */}
            <RoundTxtBtn
              width={CLAY.TEXT_BTN_SIZE_IN_BOX.WIDTH}
              height={CLAY.TEXT_BTN_SIZE_IN_BOX.HEIGHT}
              fontSize={CLAY.TEXT_BTN_FONT_SIZE_IN_BOX}
              enable={sCreateBtnEnabled}
              text={hR.strings.AC_COLLECTION_CREATE_BTN}
              containerStyle={{ alignSelf: 'center' }}
              onClick={async () => {
                DBGMSG('create');

                try {
                  await hRpc.cobaltRpc.rpcAssetCreatorCollectionAdd({
                    name: sCollectionName,
                    dp_img_url: sCollectionImg!.uploadedHash,
                    dp_name: sDisplayName,
                    royalty: parseInt(sRoyalty),
                    svc_url: sWebsiteUrl,
                    desc: sDescription,
                    admin_list: sAdminList,
                  });

                  hDlgFactory.showAsyncGuideJsx({
                    title: hR.strings.DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TITLE,
                    text: hR.strings.DLG_ASYNC_COLLECTION_CREATE_DLG_REQ_TXT,
                    onOk: () => {
                      hDlgFactory.closeAsyncGuideJsx();
                      hHistory.replace(Nav.AssetCreatorScreen);
                    },
                  });
                } catch (e) {
                  const errInfo = getRpcErrorInfo(e);
                  if (errInfo) {
                    switch (errInfo.ecode) {
                      case ServerECode_e.DUPLICATED_ASSET_COLLECTION_NAME:
                        setCollectionNameValidation({ isDupError: true });
                        collectionNameInputRef.current?.focus();
                        break;
                      case ServerECode_e.EXCEED_ASSET_COLLECTION_LIMIT_COUNT:
                        hDlgFactory.showSimpleOk({ text: hR.strings.DLG_EXCEED_COLLECTION_LIMIT });
                        break;
                      case ServerECode_e.CANNOT_FIND_USER:
                        DBGMSG(errInfo.emsg);
                        if (typeof errInfo.emsg === 'string') {
                          DBGMSG(errInfo.emsg);
                          const invalidList = errInfo.emsg.split(',');
                          setInvalidAdminList(invalidList);
                        }

                        break;
                      default:
                        DBGMSGW(`${errInfo.ecode}`);
                        hDlgFactory.showSimpleOk({ text: `${errInfo.ecode}: ${errInfo.emsg}` });
                        break;
                    }
                  }
                }
              }}
            />
            <HEIGHT size={CLAY.TEXT_BTN_VMARGIN_IN_BOX} />
          </CommonColumnBox>
          <HEIGHT size={MYCOLC_LAY.BOTTOM_MARGIN} />
        </FlexColumnDiv>
        {/* 공통푸터 */}
        {ScreenInfo.isMobile() && <CommonFooter />}
      </>
    );
  };

  /**************************************
   * !! render conf
   **************************************/

  if (ScreenInfo.isDesktop() || ScreenInfo.isTablet()) {
    return commonRender();
  } else {
    return <MobileScalableFlexColumn>{commonRender()}</MobileScalableFlexColumn>;
  }
}
