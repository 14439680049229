/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import React, { ReactChild, ReactFragment, ReactPortal, useEffect, useRef, useState } from 'react';
import { useEffectOnce, useMount } from 'react-use';
import ScreenInfo, { MOBILE_WIDTH } from 'src/context/screen_constants';
import R from 'src/res/R';
import { devOutlineF, devOutlineFB, devOutlineFG, FlexColumnDiv, FlexDiv } from 'src/ui/base_component/etc';
import { DBGMSG } from 'src/util/utils';
import { CSSProperties } from 'styled-components';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  children?: ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
  height?: number;
  getHeight?: (arg: { orgHeight: number; scaledHeight: number }) => void;
};

export default function MobileScalableFlexColumn(props: Props) {
  const [sHeight, setHeight] = useState<number>();
  const scaledDivRef = useRef<HTMLDivElement>(null);
  const parentDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sHeight && parentDivRef.current && scaledDivRef.current) {
      props.getHeight && props.getHeight({ orgHeight: sHeight, scaledHeight: parentDivRef.current.clientHeight });
    }
    return () => {};
  }, [props, sHeight]);

  // 컴포넌트가 마운트 되면 높이(dynamic)를 가져와 Height에 설정
  useEffect(() => {
    // DBGMSG(`MobileScalableFlexColumn original clientHeight: ${scaledDivRef.current?.clientHeight}`);
    // 한번만 실행되어야함
    if (scaledDivRef.current) {
      // if (sHeight === undefined && scaledDivRef.current) {
      DBGMSG(`MobileScalableFlexColumn setHeight: ${scaledDivRef.current.clientHeight}`);
      setHeight(scaledDivRef.current.clientHeight);
    }
  }, []);

  // DBGMSG('MobileScalableFlexColumn render');
  if (ScreenInfo.isMobile()) {
    return (
      // 자식 컴포넌트가 scale 되면 부모 컴포넌트를 resize해줌
      <FlexDiv
        ref={parentDivRef}
        style={{
          width: MOBILE_WIDTH * ScreenInfo.scale,
          height: sHeight ? sHeight * ScreenInfo.scale : undefined,
          // overflow: 'hidden', // 아래에 여백이 생김... 왜 그러지?, 일단 hidden으로 자름
          // backgroundColor: R.colors.devColor1a,
          // ...devOutlineF,
          zIndex: 1,
        }}
      >
        {/* scale 만큼 확대한다 */}
        <FlexColumnDiv
          ref={scaledDivRef}
          style={{
            height: sHeight,
            transformOrigin: '0% 0%',
            transform: `scale(${ScreenInfo.scale},${ScreenInfo.scale})`,
            alignItems: 'flex-start',
            // backgroundColor: R.colors.devColor1a,
            // ...devOutlineFG,
          }}
        >
          {props.children}
        </FlexColumnDiv>
      </FlexDiv>
    );
  } else {
    return (
      <FlexDiv
        style={{
          zIndex: 1,
          // backgroundColor: R.colors.devColor2a,
          // ...devOutlineFB,
        }}
      >
        {props.children}
      </FlexDiv>
    );
  }
}
