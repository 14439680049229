/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import { devOutlineF, FlexDiv, FlexRowDiv, WIDTH } from 'src/ui/base_component/etc';
import TextCon from 'src/ui/base_component/TextCon';
import R from 'src/res/R';
import { TooltipIc } from 'src/ui/base_component/TooltipIc';
import { TooltipImg } from 'src/ui/base_component/TooltipImg';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  title?: string;
  value?: { nOf: number; total: number; can_addition?: boolean; mintMore: string };
  flexDir?: 'row' | 'column';
  titleColor?: string;
  value1Color?: string;
  value2Color?: string;
  titleSize?: number;
  value1Size?: number;
  value2Size?: number;
  titleHeight?: number | string | undefined;
  valueHeight?: number | string | undefined;
  titleWidth?: number | string | undefined;
  valueWidth?: number | string | undefined;
  between?: number;
};

export function LabelValueMint({
  title = 'title',
  value = { nOf: 0, total: 0, mintMore: '' },
  flexDir = 'row',
  titleColor = R.colors.blueGrey,
  value1Color = R.colors.black,
  value2Color = R.colors.pastelRed,
  titleSize = 14,
  value1Size = 14,
  value2Size = 14,
  titleHeight = undefined,
  valueHeight = undefined,
  titleWidth = undefined,
  valueWidth = undefined,
  between = 0,
}: Props) {
  return (
    <FlexDiv style={{ flexDirection: flexDir, ...devOutline }}>
      {/* Title */}
      <TextCon size={titleSize} color={titleColor} text={title} containerStyle={{ height: titleHeight, width: titleWidth, ...devOutline }} />
      <WIDTH size={between} />

      {/* Value */}
      <FlexRowDiv style={{ minHeight: valueHeight, width: valueWidth, alignItems: 'center', justifyContent: 'flex-start' }}>
        <TextCon
          size={value1Size}
          color={value1Color}
          isBold
          text={`${value.nOf}`}
          // textStyle={{ width: '100%' }}
          containerStyle={{ ...devOutline }}
        />
        <TextCon
          size={value1Size}
          color={value1Color}
          text={` of ${value.total}`}
          // textStyle={{ width: '100%' }}
          containerStyle={{ ...devOutline }}
        />
        <WIDTH size={6} />

        {value.can_addition && <TooltipImg tooltipString={value.mintMore} placement={'right-start'} />}
        {/* <TextCon
          size={value2Size}
          color={value2Color}
          text={`${value.mintMore}`}
          // textStyle={{ width: '100%' }}
          containerStyle={{ ...devOutline }}
        /> */}
      </FlexRowDiv>
    </FlexDiv>
  );
}
