/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import { useEffectOnce } from 'react-use';
import { AnimatedFlexColumnDiv, devOutlineF, FlexDiv } from 'src/ui/base_component/etc';
import { SAddBtn } from 'src/ui/common_component/button/SAddBtn';
import { PropsCommonBox, SCHEMA_CARD_LAYOUT, SPRING_PROPS_ANIM_1_Default, SPRING_PROPS_ANIM_1_OnMouseEnter } from 'src/ui/layout_constant';
import { DBGMSG } from 'src/util/utils';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  isAnim?: boolean;
  onClick: () => void;
};

/**************************************
 * !! constant
 **************************************/

/**************************************
 * !! styled component
 **************************************/

export function SchemaCardNew({ onClick, isAnim = false }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sMouseEnter, setMouseEnter] = useState(false);

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/

  /**************************************
   * !! useEffect
   **************************************/
  useEffect(() => {
    console.debug('useEffect() init');
    // if (isActive) set({ color: R.colors.textMouseEnter });
    // else set({ color: R.colors.textMouseLeave });

    return () => {
      // console.debug('useEffect() release');
    };
  }, []);
  useEffectOnce(() => {
    
  });

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/
  // const isActive = curMenu === menu;

  /**************************************
   * !! animation
   **************************************/
  const [hMouseProps, set] = useSpring(() => {
    return SPRING_PROPS_ANIM_1_Default;
  });

  console.debug('render');
  return (
    <FlexDiv
      style={{
        padding: SCHEMA_CARD_LAYOUT.paddingForList,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AnimatedFlexColumnDiv
        style={{
          width: SCHEMA_CARD_LAYOUT.width,
          height: SCHEMA_CARD_LAYOUT.height,
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
          ...PropsCommonBox,
          ...hMouseProps,
          // ...devOutlineF,
        }}
        onMouseEnter={() => {
          // DBGMSG(`onMouseEnter: `);
          isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
          setMouseEnter(true);
        }}
        onMouseLeave={() => {
          // DBGMSG(`onMouseLeave: `);
          isAnim && set(SPRING_PROPS_ANIM_1_OnMouseEnter);
          setMouseEnter(false);
        }}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <SAddBtn
          width={SCHEMA_CARD_LAYOUT.innerBoxWidth}
          height={SCHEMA_CARD_LAYOUT.innerBoxHeight}
          radius={SCHEMA_CARD_LAYOUT.innerBoxRadius}
          onClick={() => {
            DBGMSG('new schema');
          }}
          forceEffect={sMouseEnter}
        />
      </AnimatedFlexColumnDiv>
    </FlexDiv>
  );
}
