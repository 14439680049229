import { ClientType_e } from 'src/hooks/useScreenWidthHelper';
import { DBGMSG, Utils } from 'src/util/utils';

// DESKTOP 공통 레이아웃
export const DESKTOP_WIDTH = 1194; // 최소 너비

// TABLET 공통 레이아웃
const TABLET_WIDTH = 720; // 최소 너비

// MOBILE 공통 레이아웃
export const MOBILE_CONTENT_WIDTH = 280; // 최소 너비
export const MOBILE_WIDTH = 320; // 모바일 너비 기준
export const MOBILE_HORIZONTAL_PADDING = 20; // 모바일 좌우 여백
export const MOBILE_CONTENT_PADDING_TOP = 20; // 모바일 좌우 여백

// export enum ClientType_e {
//   Desktop = 'Desktop',
//   Tablet = 'Tablet',
//   Mobile = 'Mobile',
// }

// client type
let clientType: ClientType_e;
if (Utils.device.isDesktop) {
  clientType = ClientType_e.Desktop;
} else if (Utils.device.isTablet) {
  clientType = ClientType_e.Tablet;
} else {
  clientType = ClientType_e.Mobile;
}

// window width
let windowInnerWidth: number;
switch (clientType) {
  case ClientType_e.Desktop:
  case ClientType_e.Tablet:
  case ClientType_e.Mobile:
    windowInnerWidth = window.innerWidth;
    break;
}

// window height
let windowInnerHeight: number;
switch (clientType) {
  case ClientType_e.Desktop:
  case ClientType_e.Tablet:
  case ClientType_e.Mobile:
    windowInnerHeight = window.innerHeight;
    break;
}

// 좌우 패딩을 제외한 width
let contentsWidth: number;
switch (clientType) {
  case ClientType_e.Desktop:
  case ClientType_e.Tablet:
    contentsWidth = DESKTOP_WIDTH;
    break;
  case ClientType_e.Mobile:
    contentsWidth = MOBILE_WIDTH - MOBILE_HORIZONTAL_PADDING * 2;
    break;
}

// scale
let scale: number;
switch (clientType) {
  case ClientType_e.Desktop:
  case ClientType_e.Tablet:
    scale = 1;
    break;
  case ClientType_e.Mobile:
    //
    scale = windowInnerWidth / 320;
    break;
}

// export const ScreenSizeContext = createContext<{
//   windowSize: number;
//   clientType: ClientType_e;
//   scale: number;
// }>({
//   windowSize: window.innerWidth,
//   clientType: SSC_ClientType,
//   scale: SSC_scale,
// });

DBGMSG(`$$$$ScreenInfo (window.innerWidth): ${window.innerWidth}`);
DBGMSG(`$$$$ScreenInfo (clientType): ${clientType}`);
DBGMSG(`$$$$ScreenInfo (scale): ${scale}`);

const ScreenInfo = {
  clientType,
  windowInnerWidth,
  windowInnerHeight,
  contentsWidth,
  scale,
  isDesktop: () => clientType === ClientType_e.Desktop,
  isTablet: () => clientType === ClientType_e.Tablet,
  isMobile: () => clientType === ClientType_e.Mobile,
};

export default ScreenInfo;
