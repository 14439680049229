/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import { throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AutoSizer, Grid, GridCellProps, ScrollParams, WindowScroller } from 'react-virtualized';
import ScreenInfo from 'src/context/screen_constants';
import { useDlgFactory } from 'src/hooks/useDlgFactory';
import { useUserDetailMineState } from 'src/hooks/useUserDetailMineState';
import { useRpc } from 'src/hooks/useRpc';
import { useUserDetailState } from 'src/hooks/useUserDetailState';
import { CbtMarketSearchSort_e } from 'src/model/model';
import { CbtAssetGroupCardInfo_i, CbtPgInfoSortOrder_e, CbtPgInfo_i } from 'src/model/rpcModel';
import { RPC_ASSETMGR_ISSUE_LIST } from 'src/model/rpcType';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexRowDiv, HEIGHT } from 'src/ui/base_component/etc';
import MobileScalableFlexColumn from 'src/ui/base_component/MobileScalableFlexColumn';
import TextCon from 'src/ui/base_component/TextCon';
import { AST_CARD_CONTAINER_HEIGHT_FOR_LIST, AST_CARD_CONTAINER_WIDTH_FOR_LIST } from 'src/ui/common_component/AssetCardForList';
import { AssetGroupCardForList } from 'src/ui/common_component/AssetGroupCardForList';
import { COMMON_FOONTER_HEIGHT } from 'src/ui/common_component/CommonFooter';
import { DataFetching } from 'src/ui/common_component/DataFetching';
import { MarketSearchInput } from 'src/ui/common_component/MarketSearchInput';
import { CLAY, COMMON_CARD_COLUMN_CNT, DEFAUL_PAGE_ITEM_CNT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';
import { ACCOUNT_SEARCH_MARGIN_BOTTOM } from 'src/ui/screen/AssetMgr/UserDetailScreen';
import { getRpcErrorInfo } from 'src/util/rpcUtil';
import { DBGMSG, DBGMSGW, Utils } from 'src/util/utils';
import { useLocalSettings } from 'src/hooks/useLocalSettings';

type Props = {
  ucode: string;
};
// issue 목록 렌더링
export function UserIssueAstgList({ ucode }: Props) {
  /**************************************
   * !! state
   **************************************/
  const [sNextPageStatus, setNextPageStatus] = useState<string>('');
  const [sFirstPageLoading, setFirstPageLoading] = useState<boolean>();

  /**************************************
   * !! ref
   **************************************/
  const cacheRef = useRef<{
    lastRx: RPC_ASSETMGR_ISSUE_LIST.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_ASSETMGR_ISSUE_LIST.Rx['astg_list'];
  }>();

  const firstPageLoadingRef = useRef(false);
  const nextPageLoadingRef = useRef(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  /**************************************
   * !! hook
   **************************************/
  const hHistory = useHistory();
  const hRpc = useRpc();
  const hUserDetailState = useUserDetailState();
  const hDlgFactory = useDlgFactory();
  const hR = useLocalSettings();

  /**************************************
   * !! useEffect
   **************************************/
  const restoreCache = () => {
    if (!hUserDetailState.state.issue.astg) return false;

    cacheRef.current = {
      lastRx: hUserDetailState.state.issue.astg.lastRx,
      lastPgInfo: hUserDetailState.state.issue.astg.lastPgInfo,
      list: hUserDetailState.state.issue.astg.list,
    };
    return true;
  };

  const saveCache = ({
    lastRx,
    lastPgInfo,
    list,
  }: {
    lastRx: RPC_ASSETMGR_ISSUE_LIST.Rx;
    lastPgInfo: CbtPgInfo_i;
    list: RPC_ASSETMGR_ISSUE_LIST.Rx['astg_list'];
  }) => {
    cacheRef.current = {
      lastRx,
      lastPgInfo,
      list,
    };
  };

  const clearCache = () => {
    cacheRef.current = undefined;
  };

  // 마운팅됬을때(다른 메뉴에서 인벤토리 메뉴선택시)
  // or 최초 마이페이지 접근시
  useEffect(() => {
    DBGMSG('useEffect()');
    // 인벤토리 에셋 상세 목록 상태 초기화
    hUserDetailState.resetIssueAst();

    if (hUserDetailState.state.issue.astgNeedUpdate) {
      // 캐시 삭제
      clearCache();
      fetchDefaultData();
    } else {
      // 캐시 복구
      restoreCache();
      setFirstPageLoading(false);
    }

    return () => {
      DBGMSG('useEffect()');
    };
  }, []);

  useEffect(() => {
    DBGMSG('useEffect()');
    if (hUserDetailState.state.issue.astgNeedUpdate) {
      // 캐시 삭제
      clearCache();
      fetchDefaultData();
    }

    return () => {
      DBGMSG('useEffect()');
    };
  }, [hUserDetailState.state.issue.astgNeedUpdate]);

  /**************************************
   * !! arrow functions
   **************************************/
  // fetch 판매중 아이템 리스트
  // const fetchDefaultData = async (args: { ucode?: string; search?: string } | undefined) => {
  const fetchDefaultData = async () => {
    if (firstPageLoadingRef.current) return;

    setFirstPageLoading(true);
    firstPageLoadingRef.current = true;

    try {
      const res = await hRpc.cobaltRpc.rpcAssetmgrIssueList({
        ucode,
        pg_info: { row_cnt: DEFAUL_PAGE_ITEM_CNT, cursor: null },
      });

      saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: res.astg_list });

      hUserDetailState.act.changeState({
        issue: {
          ...hUserDetailState.state.issue,
          astgNeedUpdate: false,
          astg: cacheRef.current,
        },
      });
    } catch (err) {
      hRpc.hanleCommonError({ err });
    } finally {
      setFirstPageLoading(false);
      firstPageLoadingRef.current = false;
    }

    return;
  };

  const fetchDataWithQuery = async ({
    //
    querySearch,
    querySortOrderBy,
  }: {
    querySearch?: string;
    querySortOrderBy?: CbtMarketSearchSort_e;
  }) => {
    if (firstPageLoadingRef.current) return;

    setFirstPageLoading(true);
    firstPageLoadingRef.current = true;

    const search = querySearch;
    const pg_info: CbtPgInfo_i = {
      cursor: null,
      row_cnt: 12,
      sort: {
        by: (function () {
          switch (querySortOrderBy) {
            case CbtMarketSearchSort_e.ListingNewest:
            case CbtMarketSearchSort_e.ListingOldest:
              return 'list';
            case CbtMarketSearchSort_e.PriceHighest:
            case CbtMarketSearchSort_e.PriceLowest:
              return 'price';
          }
          return '';
        })(),
        order: (function () {
          switch (querySortOrderBy) {
            case CbtMarketSearchSort_e.ListingNewest:
            case CbtMarketSearchSort_e.PriceHighest:
              return CbtPgInfoSortOrder_e.DESC;
            case CbtMarketSearchSort_e.ListingOldest:
            case CbtMarketSearchSort_e.PriceLowest:
              return CbtPgInfoSortOrder_e.ASC;
          }
          return CbtPgInfoSortOrder_e.DESC;
        })(),
      },
    };

    try {
      const res = await hRpc.cobaltRpc.rpcAssetmgrIssueList({ search, ucode, pg_info });
      saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: res.astg_list });
      hUserDetailState.act.changeState({
        issue: {
          ...hUserDetailState.state.issue,
          astgNeedUpdate: false,
          astg: cacheRef.current,
        },
      });
    } catch (e) {
      const errinfo = getRpcErrorInfo(e);
      if (errinfo) {
        hDlgFactory.showSimpleOk({ text: `${errinfo.reqInfo?.url}\n${errinfo.ecode}: ${errinfo.emsg}` });
      }
    } finally {
      setFirstPageLoading(false);
      firstPageLoadingRef.current = false;
    }
  };

  const fethcBySearchTxt = async ({ search }: { search?: string }) => {
    await fetchDataWithQuery({ ...hUserDetailState.state, querySearch: search });
  };

  const fetchBySort = async ({ sort }: { sort: CbtMarketSearchSort_e }) => {
    await fetchDataWithQuery({ ...hUserDetailState.state, querySortOrderBy: sort });
  };

  // 다음페이지 요청
  const fetchMineNextPage = async () => {
    if (!cacheRef.current) {
      DBGMSGW(`cacheRef.current is undefined`);
      return;
    }

    const cursor = cacheRef.current.lastPgInfo.cursor;
    const lastPgInfo = cacheRef.current.lastPgInfo;
    const oldList = cacheRef.current.list;

    // cursor 가 있을경우 다른 조건은 무시됨 - https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699118
    if (cursor) {
      try {
        setNextPageStatus('loading');
        const res = await hRpc.cobaltRpc.rpcAssetmgrIssueList({
          ucode,
          pg_info: { ...lastPgInfo, cursor },
        });

        const newList = [...oldList, ...res.astg_list];
        saveCache({ lastRx: res, lastPgInfo: res.pg_info, list: newList });

        hUserDetailState.act.changeState({
          issue: {
            ...hUserDetailState.state.issue,
            astg: cacheRef.current,
          },
        });

        setNextPageStatus('');
      } catch (err) {
        setNextPageStatus('loading fail');
      }
    } else {
    }
  };

  const getLastRowId = () => {
    if (!cacheRef.current) return;
    if (cacheRef.current.lastPgInfo.last_row_id === -1 && cacheRef.current.lastPgInfo.cursor !== null) {
      DBGMSGW(`lastPgInfoRef.current.last_row_id === 1 && lastPgInfoRef.current.cursor !== null`);
    }
    return cacheRef.current.lastPgInfo.last_row_id;
  };

  const hasMorePage = () => {
    const lastRowId = getLastRowId();
    if (!lastRowId) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId !== -1;
  };

  const isLastPage = () => {
    const lastRowId = getLastRowId();
    if (lastRowId === undefined) {
      DBGMSGW('lastRowId is undefined');
      return false;
    }

    return lastRowId === -1;
  };

  const scrollListener = (e: ScrollParams) => {
    // DBGMSG('$$$$PAGE_SCROLL');
    // clientHeight 화면 높이
    // scrollHeight 스크롤 높이
    // scrollTop 현재 스크롤 위치
    if (!('clientHeight' in e && 'scrollHeight' in e && 'scrollTop' in e)) {
      // DBGMSG(`FATAL scrollListener`);
      DBGMSG('$$$$PAGE_SCROLL');
      return;
    }

    if (!cacheRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', 'cacheRef.current is undefined');
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e;

    const atBottom = scrollTop + clientHeight >= scrollHeight - COMMON_FOONTER_HEIGHT;
    if (!atBottom) return;

    // 다음페이지가 없을경우 return
    if (isLastPage() === true) {
      DBGMSG('$$$$PAGE_SCROLL', `: no more page`);
      return;
    }

    // 다음 페이지 요청중일때 중복요청 return
    if (nextPageLoadingRef.current) {
      DBGMSG('$$$$PAGE_SCROLL', `return nextPageLoadingRef.current is true `);
      return;
    }

    if (atBottom) {
      nextPageLoadingRef.current = true;
      fetchMineNextPage()
        .then(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchMineNextPage succ');
        })
        .catch(() => {
          DBGMSG('$$$$PAGE_SCROLL', 'fetchMineNextPage fail');
        })
        .finally(() => {
          nextPageLoadingRef.current = false;
        });
    }
  };

  // 스크롤 리스너 throttle
  const throttleScrollListener = throttle(scrollListener, 100);

  /**************************************
   * !! render function
   **************************************/
  // issue 아이템 렌더링
  const cellRender = ({
    key, // Unique key within array of rows
    columnIndex, // Index of row within collection
    rowIndex, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: GridCellProps) => {
    if (!hUserDetailState.state.issue.astg) return null;
    const index = rowIndex * COMMON_CARD_COLUMN_CNT + columnIndex;
    if (index >= hUserDetailState.state.issue.astg.list.length) return null;

    const issueAstgInfo = hUserDetailState.state.issue.astg.list[index];

    const item: CbtAssetGroupCardInfo_i = {
      entity_id: issueAstgInfo.entity_id,
      col_info: issueAstgInfo.col_info,
      nft_name: issueAstgInfo.nft_name,
      nft_img: issueAstgInfo.nft_img,
      issue_tcnt: issueAstgInfo.issue_tcnt,
      asset_type: issueAstgInfo.schm_info.asset_type,
    };

    const zIndex = hUserDetailState.state.issue.astg.list.length - index;

    // DBGMSG(`typeof item: ${typeof item}`);
    return (
      // 중요!
      <div key={key} style={style}>
        <MobileScalableFlexColumn>
          <AssetGroupCardForList
            idx={zIndex}
            assetGroupInfo={item}
            assetType={issueAstgInfo.schm_info.asset_type}
            isAnim
            enableReportBtn={true}
            onClick={() => {
              // DBGMSG('AssetCardForList onClick!!');
              // const entity_id = (item as CbtAssetGroupCardInfo_i).entity_id;
              // const sid = sFetchedData!.schm_info.sid;
              // DBGMSG(`entity_id: ${entity_id}`);
              // hHistory.push(Nav.brewAssetGroupListScreenPath({ sid, entity_id }));
              // if (isMine) {
              //   // hHistory.push(Nav.brewAssetGroupListScreenPath({ sid: issueAstgInfo.schm_info.sid, entity_id: issueAstgInfo.entity_id }));
              //   hHistory.push(Nav.brewMyIssueAstgListScreenPath({ sid: issueAstgInfo.schm_info.sid, entity_id: issueAstgInfo.entity_id }));
              // } else {
              hHistory.push(Nav.brewUserIssueAstgListScreenPath({ sid: issueAstgInfo.schm_info.sid, entity_id: issueAstgInfo.entity_id, ucode }));
              // }
            }}
          />
        </MobileScalableFlexColumn>
      </div>
    );
  };

  const renderAssetList = () => {
    if (!hUserDetailState.state.issue.astg) return null;

    return (
      <FlexColumnDiv
        style={{
          width: AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT,
          alignSelf: 'center',
          userSelect: 'none',
          // ...devOutlineF,
        }}
      >
        <WindowScroller scrollElement={window}>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <FlexColumnDiv>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div ref={registerChild}>
                    <Grid
                      onScroll={(params) => {
                        onChildScroll(params);
                        throttleScrollListener(params);
                      }}
                      isScrolling={isScrolling}
                      autoHeight
                      cellRenderer={cellRender}
                      columnWidth={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale}
                      columnCount={COMMON_CARD_COLUMN_CNT}
                      height={height}
                      rowHeight={AST_CARD_CONTAINER_HEIGHT_FOR_LIST * ScreenInfo.scale}
                      rowCount={Math.ceil(hUserDetailState.state.issue.astg!.list.length / COMMON_CARD_COLUMN_CNT)}
                      scrollTop={scrollTop}
                      width={AST_CARD_CONTAINER_WIDTH_FOR_LIST * ScreenInfo.scale * COMMON_CARD_COLUMN_CNT}
                    />
                  </div>
                )}
              </AutoSizer>
            </FlexColumnDiv>
          )}
        </WindowScroller>
        {/* 다음페이지 로딩표시 */}
        <FlexRowDiv
          style={{
            height: 40,
            willChange: 'scroll-position',
            justifyContent: 'center',
            alignItems: 'center',
            // ...devOutlineF,
          }}
        >
          {sNextPageStatus === 'loading' && <DataFetching containerWidth={40} containerHeight={40} width={40} height={40} />}
        </FlexRowDiv>
      </FlexColumnDiv>
    );
  };

  const reanderListWrapper = () => {
    if (sFirstPageLoading) {
      return (
        <FlexColumnDiv style={{ minHeight: 350, justifyContent: 'center', alignItems: 'center' }}>
          <DataFetching />
        </FlexColumnDiv>
      );
    } else if (sFirstPageLoading === undefined) {
      if (!hUserDetailState.state.issue.astg) {
        return null;
      }

      if (hUserDetailState.state.issue.astg.list.length !== 0) {
        return renderAssetList();
      }
      return (
        <FlexColumnDiv style={{ minHeight: 350, justifyContent: 'center', alignItems: 'center' }}>
          <TextCon
            text={hR.strings.MY_MENU_SEARCH_NOT_FOUND}
            isBold
            color={R.colors.blueGrey}
            textStyle={{ textAlign: 'center' }}
            size={CLAY.DEFAULT_FONT_SIZE}
          />
        </FlexColumnDiv>
      );
    } else {
      if (!hUserDetailState.state.issue.astg) {
        return null;
      }

      if (hUserDetailState.state.querySearch.length > 0 && hUserDetailState.state.issue.astg.list.length === 0) {
        return (
          <FlexColumnDiv style={{ minHeight: 350, justifyContent: 'center', alignItems: 'center' }}>
            <TextCon
              text={hR.strings.MY_MENU_SEARCH_NOT_FOUND}
              isBold
              color={R.colors.blueGrey}
              textStyle={{ textAlign: 'center' }}
              size={CLAY.DEFAULT_FONT_SIZE}
            />
          </FlexColumnDiv>
        );
      } else if (hUserDetailState.state.issue.astg.list.length === 0) {
        return (
          <FlexColumnDiv style={{ minHeight: 350, justifyContent: 'center', alignItems: 'center' }}>
            <TextCon text={hR.strings.MY_MENU_ISSUE_NO_DATA} isBold color={R.colors.blueGrey} textStyle={{ textAlign: 'center' }} size={CLAY.DEFAULT_FONT_SIZE} />
          </FlexColumnDiv>
        );
      } else {
        return renderAssetList();
      }
    }
  };

  return (
    <>
      <MobileScalableFlexColumn>
        <FlexColumnDiv
          style={{
            backgroundColor: R.colors.bgGrey,
            width: CLAY.PAGE_WIDTH,
            alignItems: 'flex-start',
            paddingLeft: CLAY.HPADDING,
            paddingRight: CLAY.HPADDING,
          }}
        >
          <MarketSearchInput
            inputText={hUserDetailState.state.querySearch}
            inputSort={hUserDetailState.state.querySortOrder}
            getRef={(ref) => {
              searchInputRef.current = ref;
            }}
            onDebouncedChange={async (txt) => {
              DBGMSG(`onDebouncedChange Search!: ${txt}`);
              await fethcBySearchTxt({ search: txt });
              searchInputRef.current && searchInputRef.current.focus();
            }}
            onSort={(sort) => {
              DBGMSG(`onSort Search!: ${sort}`);
              hUserDetailState.act.changeState({ querySortOrder: sort });
              fetchBySort({ sort });
            }}
            onSearch={async () => {
              DBGMSG(`onSearch Search!`);
              await fethcBySearchTxt({ search: hUserDetailState.state.querySearch });
              searchInputRef.current && searchInputRef.current.focus();
            }}
            dropDownMenuList={[
              //
              CbtMarketSearchSort_e.ListingNewest, // 최신순
              CbtMarketSearchSort_e.ListingOldest, // 오래된순
            ]}
            onChange={(e) => {
              setFirstPageLoading(undefined);
              hUserDetailState.act.changeState({ querySearch: e.target.value });
            }}
          />
          <HEIGHT size={ACCOUNT_SEARCH_MARGIN_BOTTOM} />
        </FlexColumnDiv>
      </MobileScalableFlexColumn>
      {reanderListWrapper()}
    </>
  );
}
