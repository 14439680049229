/**
 * @copyright (C) Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳 ( choi_sanghoon@cocone.co.jp )
 */

import { CbtSaleStatus_e } from 'src/model/rpcType';
import Constants from 'src/res/constants';

/**
 * !http request method
 */
export enum REQ_METHOD {
  POST = 'post',
  GET = 'get',
}

/**
 * ! rpc 요청
 */
export interface CmnTxReqInfo_i {
  url: string;
  method: REQ_METHOD;
  tx: object;
  token?: string;
  postRun?: (rx: any) => any;
  isMultpartFormData?: boolean;
}

/**
 * ! 공통 응답
 * https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699120
 */
export interface CommonRx_i {
  success: boolean; // api 처리 성공여부
  ecode: number; // 에러 발생 시, 에러 코드 *에러 발생하지 않았으면 0
  emsg: string | null; // 에러 발생 시, 에러 메시지
  req_time: number; // 서버 요청 확인(서버에 요청이 도착한) 시간

  ukey: string | null; // 사용자 식별값 : mid와 관련
  ucode: string | null; // 사용자 user_code : 회원 등록시 랜덤 생성된 공개용 사용자 id
  token: string | null; // 서비스를 통한 로그인(인증) 후, 로그인 세션 유지를 위한 access_token  로그인 이후에 요청 http header의 Authorization에 설정 필요
  mkey: string | null; // 사용자 전용 알림 메시지 구독용 식별키 (over WebSocket)
  cert_tp: CbtUserCertTp_i; // 1, 11이면 Asset Creator 메뉴 접근 가능
  // prof: string | null; // 사용자 프로필 이미지 url 사용자가 설정을 안하면 빈값(null / "") 일 수 있다, 이 경우, front-end쪽에서 기본 백그라운드 컬러로 설정해줌
  // reward_addr: string | null; // 사용자 판매 대금/리워드 입금 Klaytn 주소, 사용자가 설정을 안하면 빈값(null / "") 일 수 있다

  errors: string | null;
}

/**
 * ! 에러 코드
 */
export enum ServerECode_e {
  // 공통 에러 코드 - https://portal.cocone.jp/confluence/display/PROJB/%5B02.00.05%5D+Error+Code
  Error = 6000,
  ParamMissing = 6100,
  ParamInvalid = 6101,
  DistLock = 6200,
  CANNOT_FIND_USER = 6301, // 가입되지 않은 이메일
  INVALID_KLAYTN_ADDRESS = 6305, // 사용자의 reward_addr 등, (미설정한 경우가 아닌)klaytn 주소가 유효하지 않을 경우

  REQUIRED_LOGIN = 100,
  BLOCKED_USER = 104,
  BANNED_USER = 105,
  AUTHENTICATION_EXPIRATION = 112,
  AUTHENTICATION_INVALID_TOKEN = 113,

  // 회원 api관련 에러 코드 - https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699167
  ERR_KLAYTN_CREATE_ACCOUNT = 102,
  ALREADY_OCCUPIED_EMAIL_ADDR = 103,
  CERTIFY_EMAIL_FAILED_BY_WRONG_ADDRESS = 115,
  CERTIFY_EMAIL_FAILED_BY_CODE_EXPIRED = 116,
  CERTIFY_EMAIL_FAILED_BY_INVALID_CODE = 117,
  NEED_TO_CERTIFY_EMAIL = 120,
  INVALID_USER_PASSWORD = 121,
  TRY_LOGIN_TOO_MANY = 122,
  LOCKED_USER_PASSWORD = 123,

  // 자산 api관련 에러 코드 - https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=96311476
  EXCEED_ASSET_COLLECTION_LIMIT_COUNT = 201,
  DUPLICATED_ASSET_COLLECTION_NAME = 202,
  EXCEED_ASSET_SCHEMA_LIMIT_COUNT = 205,
  DUPLICATED_ASSET_SCHEMA_NAME = 206,

  // 마켓 플레이스 API 관련 에러코드  - https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=97602958
  ERR_MP_TRY_PURCHASE_BY_SELLER_SELF = 231,

  //
}
// ############################################
// 공통 데이터 모델
// https://portal.cocone.jp/confluence/pages/viewpage.action?pageId=94699118
// ############################################
// asset type
export enum CbtAssetType_e {
  IMAGE = 1,
  AUDIO = 2,
  VIDEO = 3,
}

// 필수 속성명
export type CbtReqAttributeName_t =
  | typeof Constants.NFT_REQ_ATTRNAME_NAME
  | typeof Constants.NFT_REQ_ATTRNAME_IMAGE
  | typeof Constants.NFT_REQ_ATTRNAME_AUDIO
  | typeof Constants.NFT_REQ_ATTRNAME_VIDEO;

// 스키마 속성의 타입
export enum CbtAttributeType_e {
  TEXT = 1, // 텍스트
  NUMBER = 2, // 숫자
  IMAGE = 3, // 이미지
  AUDIO = 4, // 오디오
  VIDEO = 5, // 비디오
}

// 자산 발행 타입
export enum CbtIssueType_e {
  INIT = 1, // 최초발행
  ADDITION = 2, // 추가발행
}

// 추가 발행 방식
export enum CbtEditionType_e {
  LIMITED = 1,
  REPRINTING = 2, //추가발행가능
}

// 컬렉션 CertifyType type
export enum CbtColCertifyType_e {
  UNOFFICIAL = 0,
  OFFICIAL = 1,
}

// 사용자 설정 언어
export enum CbtUserLanguage_e {
  EN = 'en',
  JA = 'ja',
  KO = 'ko',
}

// 에셋 신고 이유
export enum CbtReportReason_e {
  SEXUAL = 1,
  VIOLENT_OR_DISGUSTING = 2,
  HATE_OR_ABUSE = 3,
  INFRINGEMENT_OF_RIGHT = 4,
  ETC = 11,
}

// 화폐 종류
export enum CbtCurrency_e {
  KLAY = 'KLAY',
  cPOINT = 'cPOINT',
  cUSD = 'cUSD',
  CBLT = 'CBLT',
  USD = 'USD',
  UNKNOWN = 'UNKNOWN',
  ALL = 'ALL',
}

// 결제 수단으로서의 타입
export enum CbtPayMethodType {
  NONE = 0, // 결제수단으로 사용되지 않음
  MAIN = 1, // 1차 결제 수단(메인 결제 수단)
  SUB = 2, // 2차 결제 수단(보조 결제 수단)
}

// 표시 타입
export enum CbtDpType {
  NONE = 0, // 표시안함
  IC_AND_PRICE = 1, // 기본 (아이콘, 가격 표시에 모두 사용)
  IC_ONLY = 2, // 아이콘만 표시
  PRICE_ONLY = 3, // 가격만 표시
}

// 화폐정보
export interface CbtCurrencyInfo_i {
  currency: CbtCurrency_e;
  decimal: number;
  pay_method_type?: CbtPayMethodType; // todo 오류 안나게,, undefined 로 임시로,,
  dp_type?: CbtDpType;
  sub_curr_list?: null | Omit<CbtCurrencyInfo_i, 'sub_curr_list'>[];
}

// 요청에 사용되는 가격 모델
export interface CbtReqPriceInfo_i {
  price: string;
  curr_info: Omit<CbtCurrencyInfo_i, 'sub_curr_list' | 'pay_method_type' | 'dp_type'>;
}

// 응답에 사용되는 가격 모델
export interface CbtResPriceInfo_i {
  price: string;
  curr_info: CbtCurrencyInfo_i;
  sub_price_list?:
    | null
    | {
        price: string;
        curr_info: Omit<CbtCurrencyInfo_i, 'sub_curr_list'>;
      }[];
}

export enum CbtPG_e {
  Paymentwall = 1,
}

export enum CbtPgInfoSortOrder_e {
  ASC = 'asc', // default
  DESC = 'desc',
}
// 페이징 정보
export interface CbtPgInfo_i {
  row_cnt?: number;
  last_row_id?: number;
  cursor: string | null;
  page_no?: number;
  sort?: {
    by: string;
    order: CbtPgInfoSortOrder_e;
  };
  filter?: {
    by: string;
    key_list: string[];
  };
}

//  마켓 플레이스 통화
export type CbtMarketSearchCurrencyFilter_t = CbtCurrency_e.KLAY | CbtCurrency_e.cPOINT | CbtCurrency_e.ALL;

// 문의하기 유형
export enum CbtInqueryType_e {
  Problem = 1,
  Task,
  Question,
  Incident,
}

// point 상품
export interface CbtPrd_i {
  prd_id: string;
  nm: string;
  desc: string;
  prc_info: CbtResPriceInfo_i;
  unit_info: CbtResPriceInfo_i;
  fee_info: CbtResPriceInfo_i;
  fee_pct: string;
  dis_fee_pct: string | null;
  chg_info: CbtReqPriceInfo_i;
}

// 유저의 CERT_TP 상태
export enum CbtUserCertTp_i {
  Normal = 0,
  Certificated = 1,
  Partners = 11,
}

// ############################################
// 공통 데이터 모델 end
// ############################################

// 컬렉션/스키마 생성 상태
export enum CbtCreationStatus_e {
  InPreparation = 0, // 생성 완료 대기 중
  Available = 1, // 정상 : 생성완료
}

// 속성 이름, 값
export interface CbtAttributeValue_i {
  name: string;
  value: string;
}
// 스키마의 속성정보
export interface CbtAttributeInfo_i {
  name: string;
  type: CbtAttributeType_e;
}

// 스키마의 속성정보
export interface CbtAttributeInfoFull_i {
  name: string;
  type: CbtAttributeType_e;
  value: string;
}

// 컬렉션 카드 정보
export interface CbtCollectionCardInfo_i {
  cid: number;
  cert_tp: CbtColCertifyType_e;
  dp_name: string;
  dp_img_url: string;
  status?: CbtCreationStatus_e;
}

// 컬렉션 상세 정보
export interface CbtCollectionDetailInfo_i {
  cid: number;
  cert_tp: CbtColCertifyType_e;
  creator: CbtUserInfo_i;
  name: string;
  dp_name: string;
  dp_img_url: string;
  royalty: number;
  svc_url: string | null;
  desc: string | null;
  admin_list: string[];
}

// 유저정보
export interface CbtUserInfo_i {
  ucode: string;
  prof: string | null;
}

// 스키마 카드 정보
export interface CbtSchemaCardInfo_i {
  sid: number;
  dp_name: string;
  asset_type: CbtAssetType_e;
  status?: CbtCreationStatus_e;
}

// 스키마 상세 정보
export interface CbtSchemaDetailInfo_i {
  cid: number;
  sid: number;
  asset_type: CbtAssetType_e;
  name: string;
  dp_name: string;
  attributes: CbtAttributeInfo_i[];
}

// 에셋 그룹 카드 정보
export interface CbtAssetGroupCardInfo_i {
  entity_id: number;
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  nft_name: string;
  nft_img: string;
  nft_aud?: string | null;
  nft_vid?: string | null;
  asset_type?: CbtAssetType_e;
  issue_tcnt: number;
  own_cnt?: number;
  blocked?: boolean;
}

// 에셋 카드 정보
export interface CbtAssetCardInfo_i {
  asset_id: number;
  asset_key: string;
  col_info: {
    cid: number;
    cert_tp: CbtColCertifyType_e;
    dp_name: string;
  };
  nft_name: string;
  nft_img: string;
  nft_aud?: string | null;
  nft_vid?: string | null;
  owner: string;
  // 판매정보
  sale_id?: number;
  sid?: number;
  entity_id?: number;
  blocked?: boolean;
  saleStatus?: CbtSaleStatus_e;
}

export enum CbtTxStatus_e {
  UNKNOWN = 0,
  ONSALE = 101,
  SALE_CANCELE = 102,
  ONTX = 111,
  COMPLETE = 121,
}

export enum CbtPayoutAccountStatus_e {
  NO_AUTH = 0,
  EMAIL_AUTH = 10,
  PAYPAL_AUTH = 20,
}

export enum CbtPayoutFeeType_e {
  USD = 1,
  CBLT = 2,
}
