/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React from 'react';
import R from 'src/res/R';
import { devOutlineF, FlexColumnDiv, FlexDiv } from 'src/ui/base_component/etc';
import { ImgBtn } from 'src/ui/base_component/ImgBtn';
import { CollectionCard, CollectionCardProps } from 'src/ui/common_component/CollectionCard';
import { COLLECTION_CARD_LAYOUT } from 'src/ui/layout_constant';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

/**************************************
 * !! constant
 **************************************/
export function CollectionCardForSlider(
  props: CollectionCardProps & {
    //
    leftBtnEnable: boolean;
    rightBtnEnable: boolean;
    leftBtnOnClick: () => void;
    rightBtnOnClick: () => void;
    isSliding: boolean;
  }
) {
  return (
    <FlexColumnDiv
      style={{
        padding: COLLECTION_CARD_LAYOUT.paddingForList,
        justifyContent: 'center',
        alignItems: 'center',
        // ...devOutlineF,
      }}
    >
      <CollectionCard {...props} />

      {/* {!props.isSliding && (
        <>
          <ImgBtn
            src={[R.images.common_btn_back_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingLeft: 5,
              left: 0,
              opacity: props.leftBtnEnable ? 1 : 0,
            }}
            onClick={() => {
              props.leftBtnOnClick();
            }}
          />

          <ImgBtn
            src={[R.images.common_btn_next_abled3x, 30, 30]}
            containerStyle={{
              position: 'absolute',
              padding: 10,
              paddingRight: 5,
              right: 0,
              opacity: props.rightBtnEnable ? 1 : 0,
            }}
            onClick={() => {
              props.rightBtnOnClick();
            }}
          />
        </>
      )} */}
    </FlexColumnDiv>
  );
}
