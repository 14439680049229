/**
 * @copyright (C), Copyright 2021. COCONE CORPORATION. All rights Reserved.
 * @author 崔祥勳: ' choi_sanghoon@cocone.co.jp ',
 */

import React, { HTMLProps } from 'react';
import { useHistory } from 'react-router';
import { useLocalSettings } from 'src/hooks/useLocalSettings';
import R from 'src/res/R';
import { devOutlineF } from 'src/ui/base_component/etc';
import { RoundTxtBtn4 } from 'src/ui/common_component/button/RoundTxtBtn4';
import { RoundTxtBtnAdvWithAnim } from 'src/ui/common_component/button/RoundTxtBtnAdvWithAnim';
import { LOGINOUT_BTN_LAYOUT } from 'src/ui/layout_constant';
import { Nav } from 'src/ui/screen/Nav';

const devOutline = {
  ...devOutlineF,
  borderColor: undefined,
  borderWidth: 0,
};

type Props = {
  onClick?: HTMLProps<HTMLDivElement>['onClick'];
};

export function LoginBtn({ onClick }: Props) {
  /**************************************
   * !! state
   **************************************/

  /**************************************
   * !! ref
   **************************************/

  /**************************************
   * !! hooks
   **************************************/
  const hR = useLocalSettings();
  const hHistory = useHistory();

  /**************************************
   * !! useEffect
   **************************************/

  /**************************************
   * !! arrow function
   **************************************/

  /**************************************
   * !! render function
   **************************************/

  /**************************************
   * !! render conf
   **************************************/

  /**************************************
   * !! animation
   **************************************/
  // console.debug('render');
  return (
    <RoundTxtBtn4
      width={LOGINOUT_BTN_LAYOUT.width}
      height={LOGINOUT_BTN_LAYOUT.height}
      fontSize={LOGINOUT_BTN_LAYOUT.fontSize}
      text={hR.strings.HEADER_LOGIN_BTN}
      onClick={(e) => {
        hHistory.push(Nav.UserLoginScreen);
        onClick && onClick(e);
      }}
    />
  );
}
